import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CostReportingDetailsComponent } from './cost-reporting-details.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  exports: [
    CostReportingDetailsComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    NgMultiSelectDropDownModule
  ],
  declarations: [
    CostReportingDetailsComponent
  ],
  providers: [],
})
export class CostReportingDetailsModule { }
