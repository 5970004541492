import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {VariablesService} from "./vars.service";
import {DataService} from "./data.service";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
@Injectable({
    providedIn: 'root'
})
export class ApprovalsService {
    response;
    requestSortType = "pending_approval";
    innerResponse;
    approvalData;
    approvalHotelData;
    approvalFacilitiesFeaturedavailabile = false;
    approvalBookingInfo;
    approvalPaxes;
    flightApprovalDetailsSub: Subscription;
    approvalsList;
    filteredApprovalsList;
    approvedArray;
    expiredArray;
    pendingArray;
    disapprovedArray;
    approvalsFlightList;
    filteredApprovalsFlightList;
    approvedArrayFlight;
    pendingArrayFlight;
    disapprovedArrayFlight;
    expiredArrayFlight;
    sortType = "pending_approval";
    allApprovalsSubscription: Subscription;
    requestsList = [];
    requesterFilteredApprovalsList;
    requesterApprovedArray;
    requesterExpiredArray;
    requesterPendingArray;
    requesterDisapprovedArray;
    requesterWithdrawnArray;
    requestsFlightList = [];
    requesterFilteredApprovalsFlightList;
    requesterApprovedArrayFlight;
    requesterPendingArrayFlight;
    requesterDisapprovedArrayFlight;
    requesterExpiredArrayFlight;
    requesterWithdrawnArrayFlight;
    approvalLabelOptions;
    constructor(private http: HttpClient,
                public router: Router,
                private dataService: DataService,
                public VarsService: VariablesService) {
    }

    getAllWorkspaceApprovalsBadge(): number {
        const badge = Number(localStorage.getItem('workspaceApprovalsBadge')) || 0;
        const badgeFlight = Number(localStorage.getItem('workspaceApprovalsBadgeFlight')) || 0;
        return badge + badgeFlight;
    }

    // this api is used when the approver asske for more info
    flightApprovalRequestMoreInfo(approvalId, comment) {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.flightApprovalRequestMoreInfo}?approvalId=` +
            approvalId +
            "&comment=" +
            comment,
            ""
        );
    }

    getFlightApprovalDetails(approvalId) {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.flightApprovalDetails}?approvalId=` +
            approvalId,
            ""
        );
    }
    requiresApprovalCheck(key) {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.requiresApprovalCheck}?rateKey=` +
            encodeURIComponent(key),
            ""
        );
    }

    getFlightListApproversApprovals(status) {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.flightListApproversApprovals}?status=` +
            status,
            ""
        );
    }

    withdrawFlightApprovalRequest(approvalId) {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.withdrawFlightApprovalRequest}?approvalId=` +
            approvalId,
            ""
        );
    }

    getListRequesterApprovals() {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.listRequesterApprovals}`,
            ""
        );
    }
    getListRequesterFlightApprovals() {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.listRequesterFlightApprovals}`,
            ""
        );
    }

    approveTrip(approvalId, commentAndIgnoreRatePriceChange, isFlight) {
        if (isFlight) {
            return this.http.post(
                `${environment.apiBase}${this.VarsService.apiUrls.approvalApproveFlight}?approvalId=` +
                approvalId +
                commentAndIgnoreRatePriceChange,
                ""
            );
        } else {
            return this.http.post(
                `${environment.apiBase}${this.VarsService.apiUrls.approvalApprove}?approvalId=` +
                approvalId +
                commentAndIgnoreRatePriceChange,
                ""
            );
        }
    }
    withdrawApprovalRequest(approvalId) {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.withdrawApprovalRequest}?approvalId=` +
            approvalId,
            ""
        );
    }
    disapproveTrip(approvalId, commentAndReason, isFlight) {
        if (isFlight) {
            return this.http.post(
                `${environment.apiBase}${this.VarsService.apiUrls.approvalDisapproveFlight}?approvalId=` +
                approvalId +
                commentAndReason,
                ""
            );
        } else {
            return this.http.post(
                `${environment.apiBase}${this.VarsService.apiUrls.approvalDisapprove}?approvalId=` +
                approvalId +
                commentAndReason,
                ""
            );
        }
    }
    getApprovalDetails(approvalId) {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.approvalDetails}?approvalId=` +
            approvalId,
            ""
        );
    }
    approvalSubmitMoreInfo(approvalId, comment) {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.approvalSubmitMoreInfo}?approvalId=` +
            approvalId +
            "&comment=" +
            comment,
            ""
        );
    }
    flightApprovalSubmitMoreInfo(approvalId, comment) {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.flightApprovalSubmitMoreInfo}?approvalId=` +
            approvalId +
            "&comment=" +
            comment,
            ""
        );
    }
    approvalRequestMoreInfo(approvalId, comment) {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.approvalRequestMoreInfo}?approvalId=` +
            approvalId +
            "&comment=" +
            comment,
            ""
        );
    }

    getListApproversApprovals(status) {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.listApproversApprovals}?status=` +
            status,
            ""
        );
    }

    getFlightApprovalDetailsInfo(approvalId) {
        this.flightApprovalDetailsSub = this.getFlightApprovalDetails(approvalId).subscribe(
            (data) => {
                this.response = data;
                if (this.response.errorCode === 0) {
                    this.approvalData = this.response.data;
                   /* this.flightApprovalPaxes =
                        this.data.data.approvalRequest.bookRequest.adults.length - 1;
                    if (this.data.data.approvalRequest.bookRequest.children) {
                        this.flightApprovalPaxes +=
                            this.data.data.approvalRequest.bookRequest.children?.length;
                    }*/
                   /* this.summaryDetailsDepart =
                        this.data.data.airItineraryValidationResponse.airItinerary.originDestinationOptionsGroups[0].summary;
                    if (
                        this.data.data.approvalRequest.bookRequest.searchRequest.roundTrip
                    ) {
                        this.summaryDetailsReturn =
                            this.data.data.airItineraryValidationResponse.airItinerary.originDestinationOptionsGroups[1].summary;
                    }*/
                } else {
                    this.dataService.messages(this.response.message, "error");
                }
            },
            (error) => {
                this.dataService.messages(environment.messages.error, "error");
            }
        );
    }

    unSubscribeFlightApprovalDetailsInfo(){
        if(this.flightApprovalDetailsSub){
            this.flightApprovalDetailsSub.unsubscribe();
        }
    }

    unSubscribeAllApprovalsSubscription(){
        if(this.allApprovalsSubscription){
            this.allApprovalsSubscription.unsubscribe();
        }
    }


    withdrawFlightApprovalRequestFunc(approvalId) {
        this.withdrawFlightApprovalRequest(approvalId).subscribe(
            (data) => {
                this.response = data;
                if (this.response.errorCode === 0) {
                    this.approvalData = this.response.data;
                    const url = this.router.url.split("/");
                    if (url[url.length - 1] === "requests") {
                        this.getAllListRequesterApprovals();
                    }
                    this.dataService.modalOf();
                    this.dataService.BottomSheetOf();
                } else {
                    this.dataService.errorMsg = this.response.message;
                }
            },
            (error) => {
                this.dataService.errorMsg = environment.messages.error;
            }
        );
    }

    getTravelTypeLabel(itineraries): string {
        const length = itineraries.length;

        if (length === 1) {
            return this.dataService.oneWay;
        }

        if (length === 2) {
            const departureAirport = itineraries[0].searchSegmentSummary.departureAirport.iata;
            const arrivalAirport = itineraries[1].searchSegmentSummary.arrivalAirport.iata;

            return departureAirport === arrivalAirport ? this.dataService.roundTrip : this.dataService.multiCity;
        }

        return this.dataService.multiCity;
    }



    globalListApproversApprovals(status) {
        this.dataService.hotellistLoader = true;
        this.allApprovalsSubscription = this.getListApproversApprovals(status).subscribe(
            (data) => {
                this.response = data;
                if (this.response.errorCode === 0) {
                    this.approvalsList = this.response.data;
                    this.filteredApprovalsList = this.approvalsList;
                    switch (status) {
                        case "PENDING_APPROVAL":
                            this.pendingArray = this.approvalsList;
                            break;
                        case "DISAPPROVED":
                            this.disapprovedArray = this.approvalsList;
                            break;
                        case "APPROVED":
                            this.approvedArray = this.approvalsList;
                            break;
                        case "EXPIRED":
                            this.expiredArray = this.approvalsList;
                            break;
                    }

                    this.getFlightListApproversApprovalsByStatus(status);

                } else {
                    this.dataService.hotellistLoader = false;
                    this.dataService.messages(this.response.message, "error");
                }
            },
            (error) => {
                this.dataService.hotellistLoader = false;
                this.dataService.messages(environment.messages.error, "error");
            }
        );
    }

    getFlightListApproversApprovalsByStatus(status: string){
        this.getFlightListApproversApprovals(status).subscribe(
            (dataFlight) => {
                this.innerResponse = dataFlight;
                if (this.innerResponse.errorCode === 0) {
                    this.approvalsFlightList = this.innerResponse.data;
                    this.filteredApprovalsFlightList = this.approvalsFlightList;
                    this.filteredApprovalsList = this.approvalsList;

                    switch (status) {
                        case "PENDING_APPROVAL":
                            this.pendingArrayFlight = this.approvalsFlightList;
                            this.filteredApprovalsList = this.pendingArray.slice();
                            this.filteredApprovalsFlightList = this.pendingArrayFlight.slice();
                            break;
                        case "DISAPPROVED":
                            this.disapprovedArrayFlight = this.approvalsFlightList;
                            this.filteredApprovalsList = this.disapprovedArray.slice();
                            this.filteredApprovalsFlightList = this.disapprovedArrayFlight.slice();
                            break;
                        case "APPROVED":
                            this.approvedArrayFlight = this.approvalsFlightList;
                            this.filteredApprovalsList = this.approvedArray.slice();
                            this.filteredApprovalsFlightList = this.approvedArrayFlight.slice();
                            break;
                        case "EXPIRED":
                            this.expiredArrayFlight = this.approvalsFlightList;
                            this.filteredApprovalsList = this.expiredArray.slice();
                            this.filteredApprovalsFlightList = this.expiredArrayFlight.slice();
                            break;
                    }

                    localStorage.setItem(
                        "workspaceApprovalsBadgeFlight",
                        this.pendingArrayFlight?.length ?? 0
                    );

                    localStorage.setItem(
                        "workspaceApprovalsBadge",
                        this.pendingArray?.length ?? 0
                    );
                    this.dataService.hotellistLoader = false;
                } else {
                    this.dataService.hotellistLoader = false;
                    this.dataService.messages(this.innerResponse.message, "error");
                }
            },
            (error) => {
                this.dataService.hotellistLoader = false;
                this.dataService.messages(environment.messages.error, "error");
            }
        );
    }




    getAllListRequesterApprovals() {
        this.dataService.hotellistLoader = true;
        this.getListRequesterApprovals().subscribe(
            (data) => {
                this.response = data;
                if (this.response.errorCode === 0) {
                    this.requestsList = this.response.data;
                    this.requesterFilteredApprovalsList = this.requestsList;

                    // Filter the main requests list by status and store in respective arrays
                    const statusArrays = {
                        approved: this.requestsList.filter(item => item.status.toLowerCase() === "approved"),
                        pending_approval: this.requestsList.filter(item => item.status.toLowerCase() === "pending_approval"),
                        disapproved: this.requestsList.filter(item => item.status.toLowerCase() === "disapproved"),
                        expired: this.requestsList.filter(item => item.status.toLowerCase() === "expired"),
                        withdrawn: this.requestsList.filter(item => item.status.toLowerCase() === "withdrawn")
                    };

                    // Assign each status array to its corresponding property
                    this.requesterApprovedArray = statusArrays.approved;
                    this.requesterPendingArray = statusArrays.pending_approval;
                    this.requesterDisapprovedArray = statusArrays.disapproved;
                    this.requesterExpiredArray = statusArrays.expired;
                    this.requesterWithdrawnArray = statusArrays.withdrawn;

                    // Set the filtered list based on the current request sort type
                    this.requesterFilteredApprovalsList = (statusArrays[this.requestSortType] || []).slice();

                    this.getListRequesterFlightApprovalsCall();
                } else {
                    this.dataService.hotellistLoader = false;
                    this.dataService.messages(this.response.message, "error");
                }
            },
            (error) => {
                this.dataService.hotellistLoader = false;
                this.dataService.messages(environment.messages.error, "error");
            }
        );
    }

    getListRequesterFlightApprovalsCall(){
        this.getListRequesterFlightApprovals().subscribe(
            (dataFlight) => {
                this.response = dataFlight;
                if (this.response.errorCode === 0) {
                    this.requestsFlightList = this.response.data;
                    this.requesterFilteredApprovalsFlightList =
                        this.requestsFlightList;


                    // Filter the flight requests list by status and store in respective arrays
                    const statusArraysFlight = {
                        approved: this.requestsFlightList.filter(item => item.status.toLowerCase() === "approved"),
                        pending_approval: this.requestsFlightList.filter(item => item.status.toLowerCase() === "pending_approval"),
                        disapproved: this.requestsFlightList.filter(item => item.status.toLowerCase() === "disapproved"),
                        expired: this.requestsFlightList.filter(item => item.status.toLowerCase() === "expired"),
                        withdrawn: this.requestsFlightList.filter(item => item.status.toLowerCase() === "withdrawn")
                    };

                    // Assign each status array to its corresponding property
                    this.requesterApprovedArrayFlight = statusArraysFlight.approved;
                    this.requesterPendingArrayFlight = statusArraysFlight.pending_approval;
                    this.requesterDisapprovedArrayFlight = statusArraysFlight.disapproved;
                    this.requesterExpiredArrayFlight = statusArraysFlight.expired;
                    this.requesterWithdrawnArrayFlight = statusArraysFlight.withdrawn;

                    // Set the filtered list based on the current request sort type
                    this.requesterFilteredApprovalsFlightList = (statusArraysFlight[this.requestSortType] || []).slice();

                    this.dataService.hotellistLoader = false;
                } else {
                    this.dataService.hotellistLoader = false;
                    this.dataService.messages(this.response.message, "error");
                }
            },
            (error) => {
                this.dataService.hotellistLoader = false;
                this.dataService.messages(environment.messages.error, "error");
            }
        );

    }

    withdrawApprovalRequestFunc(approvalId) {
        this.withdrawApprovalRequest(approvalId).subscribe(
            (data) => {
                this.response = data;
                if (this.response.errorCode === 0) {
                    this.approvalData = this.response.data;
                    this.approvalBookingInfo = this.response.data.bookingInfo;
                    this.approvalHotelData = this.response.data.bookingInfo.hotel;
                    const paxesArray =
                        this.response.data.bookingInfo.searchRequest.paxes[0].split(",");
                    this.approvalPaxes = Number(paxesArray[0]) + paxesArray.length - 2;
                    this.approvalLabelOptions = {
                        color: "#ffffff",
                        fontWeight: "bold",
                        fontSize: "12px",
                        text:
                            "$" +
                            Math.round(
                                this.approvalBookingInfo.hotel.rooms[0].rates[0]
                                    .averageNightlySellingPrice
                            ),
                    };
                    this.approvalHotelData.myFacilities.map((item) => {
                        if (item.featured) {
                            this.approvalFacilitiesFeaturedavailabile = true;
                        }
                    });
                    const url = this.router.url.split("/");
                    if (url[url.length - 1] === "requests") {
                        this.getAllListRequesterApprovals();
                    }
                    this.dataService.modalOf();
                    this.dataService.BottomSheetOf();
                } else {
                    this.dataService.errorMsg = this.response.message;
                }
            },
            (error) => {
                this.dataService.errorMsg = environment.messages.error;
            }
        );
    }

    getApprovalDetailsInfo(approvalId) {
        this.getApprovalDetails(approvalId).subscribe(
            (data) => {
                this.response = data;
                if (this.response.errorCode === 0) {
                    this.approvalData = this.response.data;
                    this.approvalBookingInfo = this.response.data.bookingInfo;
                    this.approvalHotelData = this.response.data.bookingInfo.hotel;
                    const paxesArray =
                        this.response.data.bookingInfo.searchRequest.paxes[0].split(",");
                    this.approvalPaxes = Number(paxesArray[0]) + paxesArray.length - 2;
                    this.approvalLabelOptions = {
                        color: "#ffffff",
                        fontWeight: "bold",
                        fontSize: "12px",
                        text:
                            "$" +
                            Math.round(
                                this.approvalBookingInfo.hotel.rooms[0].rates[0]
                                    .averageNightlySellingPrice
                            ),
                    };
                    this.approvalHotelData.myFacilities.map((item) => {
                        if (item.featured) {
                            this.approvalFacilitiesFeaturedavailabile = true;
                        }
                    });
                } else {
                    this.dataService.messages(this.response.message, "error");
                }
            },
            (error) => {
                this.dataService.messages(environment.messages.error, "error");
            }
        );
    }
    requestMoreInfoMobile(approvalId, comment) {
        this.dataService.errorMsg = "";
        if (comment === undefined || comment === null || comment === "") {
            this.dataService.errorMsg = "Please enter you message";
            return;
        }
        this.approvalRequestMoreInfo(approvalId, comment).subscribe(
            (data) => {
                this.response = data;
                if (this.response.errorCode === 0) {
                    this.getApprovalDetailsInfo(approvalId);
                    this.dataService.BottomSheetOf();
                    this.dataService.submitting = false;
                } else {
                    this.dataService.errorMsg = this.response.message;
                    this.dataService.submitting = false;
                }
            },
            (error) => {
                this.dataService.errorMsg = environment.messages.error;
                this.dataService.submitting = false;
            }
        );
    }

    getItineraryTooltip(item: any): string {
        if (!item?.pricingDetails?.itineraries) return '';

        const itineraries = item.pricingDetails.itineraries;
        let tooltipText = `${itineraries[0].searchSegmentSummary.departureAirport.city} - ${itineraries[0].searchSegmentSummary.arrivalAirport.city}`;

        if (itineraries.length === 2) {
            tooltipText += ` - ${itineraries[1].searchSegmentSummary.arrivalAirport.city}`;
        } else if (itineraries.length > 2) {
            tooltipText += ' ...';
        }

        return tooltipText;
    }

}














