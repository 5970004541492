import { Component, ElementRef, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { SidenavService } from '../../../workspace-dashboard/sidenav.service';
import { onMainContentChange } from '../../../workspace-dashboard/animations/animations';
import { Router } from '@angular/router';
import { AuthServiceMain } from '../../../../auth/auth.service';
import { VariablesService } from '../../../../services/vars.service';
import * as moment from 'moment';
import { Title } from "@angular/platform-browser";
import {ApprovalsService} from "../../../../services/approvals.service";

@Component({
  selector: 'app-requests-list',
  templateUrl: './requests-list.component.html',
  styleUrls: ['./requests-list.component.scss'],
  animations: [onMainContentChange],
  host: {
    '(body:click)': 'onClickBody($event)',
  },
})
export class RequestsListComponent implements OnInit {

  constructor(private titleService: Title,
              private eref: ElementRef,
              public dataService: DataService,
              private sidenavService: SidenavService,
              public router: Router,
              public approvalsService: ApprovalsService,
              public auth: AuthServiceMain,
              public vars: VariablesService) {
    this.titleService.setTitle('My travel requests list | SafarHub');
    this.sidenavService.sideNavState$.subscribe(res => {
      this.dataService.onSideNavChange = res;
    });
  }
  data;
  approvalActions = false;
  selectedIndex;
  flightApprovalActions = false;
  selectedIndexFlight;
  ngOnInit(): void {
    this.approvalsService.getAllListRequesterApprovals();
  }
  onClickBody(event) {
    if (this.eref.nativeElement.querySelector('.approval-drop-down' + this.selectedIndex) && !this.eref.nativeElement.querySelector('.approval-drop-down' + this.selectedIndex).contains(event.target)) {
      this.approvalActions = false;
    }
    if (this.eref.nativeElement.querySelector('.flight-approval-drop-down' + this.selectedIndexFlight) && !this.eref.nativeElement.querySelector('.flight-approval-drop-down' + this.selectedIndexFlight).contains(event.target)) {
      this.flightApprovalActions = false;
    }
  }
  filterBy(filter) {
    this.approvalsService.requestSortType = filter;
    if (filter === 'approved') {
      this.approvalsService.requesterFilteredApprovalsList = this.approvalsService.requesterApprovedArray.slice();
      this.approvalsService.requesterFilteredApprovalsFlightList = this.approvalsService.requesterApprovedArrayFlight.slice();
    } else if (filter === 'pending_approval') {
      this.approvalsService.requesterFilteredApprovalsList = this.approvalsService.requesterPendingArray.slice();
      this.approvalsService.requesterFilteredApprovalsFlightList = this.approvalsService.requesterPendingArrayFlight.slice();
    } else if (filter === 'disapproved') {
      this.approvalsService.requesterFilteredApprovalsList = this.approvalsService.requesterDisapprovedArray.slice();
      this.approvalsService.requesterFilteredApprovalsFlightList = this.approvalsService.requesterDisapprovedArrayFlight.slice();
    } else if (filter === 'expired') {
      this.approvalsService.requesterFilteredApprovalsList = this.approvalsService.requesterExpiredArray.slice();
      this.approvalsService.requesterFilteredApprovalsFlightList = this.approvalsService.requesterExpiredArrayFlight.slice();
    } else if (filter === 'withdrawn') {
      this.approvalsService.requesterFilteredApprovalsList = this.approvalsService.requesterWithdrawnArray.slice();
      this.approvalsService.requesterFilteredApprovalsFlightList = this.approvalsService.requesterWithdrawnArrayFlight.slice();
    } else {
      this.approvalsService.requesterFilteredApprovalsList = this.approvalsService.requestsList.slice();
      this.approvalsService.requesterFilteredApprovalsFlightList = this.approvalsService.requestsFlightList.slice();
    }
  }

  withdrawApprovalRequestPopup(approvalId) {
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@withdrawApprovalRequest:Withdraw Approval Request`, 'withdrawApprovalRequest', approvalId);
    } else {
      this.dataService.setBottomSheet('withdrawApprovalRequest', approvalId);
    }
  }
  withdrawApprovalRequestFlight(approvalId) {
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@withdrawApprovalRequest:Withdraw Approval Request`, 'withdrawApprovalRequestFlight', approvalId);
    } else {
      this.dataService.setBottomSheet('withdrawApprovalRequestFlight', approvalId);
    }
  }
  refreshList() {
    this.approvalsService.getAllListRequesterApprovals();
  }

}
