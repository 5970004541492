import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthServiceMain } from "src/app/auth/auth.service";
import { DataService } from "src/app/services/data.service";
import { VariablesService } from "src/app/services/vars.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CostManagementService } from "src/app/services/cost-management.service";
import { environment } from "src/environments/environment";
import { Calendar } from "primeng/calendar";
import { StatisticsService } from "src/app/services/statistics.service";
import {
  StatisticsFilter,
  Statistics,
} from "src/app/shared/model/statistics/statistics";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit, OnDestroy {
  loader: boolean = false;
  isSubmitted: boolean = false;
  btnLoader: boolean = false;
  travelers: any[] = [];
  costCenters: any[] = [];
  labels: any[] = [];
  selectedTravelers: any[] = [];
  selectedCostCenters: any[] = [];
  selectedLabels: any[] = [];
  statisticsSubscription: any;
  travelerDropdownSettings: IDropdownSettings = {};
  costCentersDropdownSettings: IDropdownSettings = {};
  labelsDropdownSettings: IDropdownSettings = {};
  ar = {
    firstDayOfWeek: 0,
    dayNames: [
      "الأحد",
      "الإثنين",
      "الثلاثاء",
      "الأربعاء",
      "الخميس",
      "الجمعة",
      "السبت",
    ],
    dayNamesShort: [
      "الأحد",
      "الإثنين",
      "الثلاثاء",
      "الأربعاء",
      "الخميس",
      "الجمعة",
      "السبت",
    ],
    dayNamesMin: [
      "الأحد",
      "الإثنين",
      "الثلاثاء",
      "الأربعاء",
      "الخميس",
      "الجمعة",
      "السبت",
    ],
    monthNames: [
      "يناير",
      "فبراير",
      "مارس",
      "ابريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أوكتوبر",
      "نوفمبر",
      "ديسمبر",
    ],
    monthNamesShort: [
      "يناير",
      "فبراير",
      "مارس",
      "ابريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أوكتوبر",
      "نوفمبر",
      "ديسمبر",
    ],
    today: "اليوم",
    clear: "حذف",
    dateFormat: "mm/dd/yy",
    weekHeader: "الأسبوع",
  };
  en = {
    firstDayOfWeek: 0,
    dayNames: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    today: "Today",
    clear: "Clear",
    dateFormat: "mm/dd/yy",
    weekHeader: "Wk",
  };
  lang;
  bookingStatistics: Statistics = new Statistics();
  statisticsFilter: StatisticsFilter = new StatisticsFilter();
  date: Date[] | undefined;
  maxDate: Date;
  isValidRange: boolean = true;
  displayedColumns = displayedColumns;

  constructor(
    public vars: VariablesService,
    public router: Router,
    public dataService: DataService,
    public costManagementService: CostManagementService,
    public statisticsService: StatisticsService,
    public auth: AuthServiceMain
  ) {
    this.statisticsFilter = new StatisticsFilter();
  }

  ngOnDestroy(): void {
    if (this.statisticsSubscription) {
      this.statisticsSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.getLanguage();
    this.maxDate = new Date();
    this.getAsyncData();
    this.prepareDropDownSettings();
  }

  getLanguage() {
    if (localStorage.getItem("locale") === "ar") {
      this.lang = this.ar;
    } else {
      this.lang = this.en;
    }
  }

  getAsyncData() {
    this.loader = true;
    Promise.all([this.getTraveler(), this.getCostCenters(), this.getLabels()])
      .then((result: any) => {
        this.travelers = result[0]?.data ?? [];
        this.costCenters = result[1]?.data ?? [];
        this.labels = result[2]?.data ?? [];

        if (result[0].errorCode) {
          this.handleFailed(result[0].message);
        }

        if (result[1].errorCode) {
          this.handleFailed(result[1].message);
        }

        if (result[2].errorCode) {
          this.handleFailed(result[2].message);
        }

        const guest = { description: $localize`:@@guest:Guest`, id: "guest" };
        this.travelers.push(guest);
      })
      .catch((error) => {
        this.handleFailed(error.message);
      })
      .finally(() => {
        this.loader = false;
      });
  }

  handleFailed(error) {
    this.dataService.messages(error ?? environment.messages.error, "error");
  }

  getTeams() {
    return this.dataService.getTeamsList().toPromise();
  }

  getTraveler() {
    return this.dataService.getCompanyUsersList().toPromise();
  }

  getCostCenters() {
    return this.costManagementService.getCostCenters().toPromise();
  }

  getLabels() {
    return this.costManagementService.getLabels().toPromise();
  }

  generateReport() {
    this.isSubmitted = true;
    if (!this.date || !this.isValidRange) {
      return;
    }

    this.btnLoader = true;

    this.statisticsFilter.travelersIds = this.selectedTravelers.map(
      (att) => att.id
    );
    this.statisticsFilter.costCentersIds = this.selectedCostCenters.map(
      (att) => att.id
    );
    this.statisticsFilter.labelsIds = this.selectedLabels.map((att) => att.id);
    this.statisticsFilter.startDate = this.getFormattedDate(this.date[0]);
    this.statisticsFilter.endDate = !this.date[1]
      ? this.getFormattedDate(this.date[0])
      : this.getFormattedDate(this.date[1]);

    this.statisticsService
      .getBookingsStatistics(this.statisticsFilter)
      .subscribe(
        (result: any) => {
          if (result.errorCode) {
            this.handleFailed(result.message);
            return;
          }

          this.bookingStatistics = result.data;
          this.isSubmitted = false;
        },
        (error) => {
          this.handleFailed(error.message);
        }
      )
      .add(() => {
        this.btnLoader = false;
      });
  }

  toggleCalendar(calendar: Calendar) {
    calendar.overlayVisible = false;
  }

  onSelectDate() {
    if (!this.date[1]) {
      return;
    }

    this.isValidRange = !this.isDateRangeMoreThanOneYear(this.date);
  }

  exportCSV(dt) {
    // Add BOM for UTF-8 encoding
    const BOM = "\uFEFF";

    // Get the formatted data
    const transactions = dt._value.map((transaction) => {
      // Apply date formatting for bookingDate or any other date fields
      return {
        ...transaction,
        bookingDate: this.wrapInQuotes(
          this.dataService.formatDateMMMdy(transaction.bookingDate)
        ),
        startDate: this.wrapInQuotes(
          this.dataService.formatDateMMMdy(transaction.startDate)
        ),
        endDate: this.wrapInQuotes(
          this.dataService.formatDateMMMdy(transaction.endDate)
        ),
      };
    });

    // Convert transactions to CSV format
    const csvContent = this.convertToCSV(transactions);

    // Create a downloadable CSV file with BOM
    const blob = new Blob([BOM + csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    // Trigger download
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute("download", "Reports.csv");
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  // Helper function to convert JSON data to CSV
  convertToCSV(data: any[]) {
    const headers = Object.keys(data[0]).join(",") + "\n";
    const rows = data.map((row) => Object.values(row).join(",")).join("\n");
    return headers + rows;
  }

  // Function to wrap text in quotes if it contains a comma (such as in the data field)
  wrapInQuotes(value: string): string {
    if (value && value.includes(",")) {
      return `"${value}"`; // Wrap in quotes
    }
    return value;
  }

  isDateRangeMoreThanOneYear(dates: Date[]): boolean {
    const startDate = dates[0];
    const endDate = dates[1];
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
    const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
    return timeDifference > oneYearInMilliseconds;
  }

  getFormattedDate(date: Date = null): string {
    if (!date) {
      return "";
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  prepareDropDownSettings() {
    const sharedDDLSettings = {
      singleSelection: false,
      idField: "id",
      itemsShowLimit: 3,
      limitSelection: 5,
      allowSearchFilter: true,
    };

    this.travelerDropdownSettings = {
      ...sharedDDLSettings,
      textField: "description",
      searchPlaceholderText: $localize`:@@searchBtn:Search`,
      noFilteredDataAvailablePlaceholderText: $localize`:@@noFilteredData:No Filtered data available`,
    };
    this.costCentersDropdownSettings = {
      ...sharedDDLSettings,
      textField: "name",
      searchPlaceholderText: $localize`:@@searchBtn:Search`,
      noFilteredDataAvailablePlaceholderText: $localize`:@@noFilteredData:No Filtered data available`,
    };
    this.labelsDropdownSettings = {
      ...sharedDDLSettings,
      textField: "name",
      searchPlaceholderText: $localize`:@@searchBtn:Search`,
      noFilteredDataAvailablePlaceholderText: $localize`:@@noFilteredData:No Filtered data available`,
    };
  }
}

const displayedColumns = [
  { header: "Booking Date", field: "bookingDate" },
  { header: "Traveler FName", field: "travelerFirstName" },
  { header: "Traveler LName", field: "travelerLastName" },
  { header: "Traveler Email", field: "travelerEmail" },
  { header: "Booker", field: "booker" },
  { header: "Traveler Type", field: "travelerType" },
  { header: "Start Date", field: "startDate" },
  { header: "End Date", field: "endDate" },
  { header: "Service", field: "service" },
  { header: "Status", field: "status" },
  { header: "Amount", field: "amount" },
  { header: "Refund", field: "refund" },
  { header: "Currency", field: "currencyCode" },
  { header: "Policy Name", field: "policyName" },
  { header: "In policy", field: "inPolicy" },
  { header: "Approval Required", field: "approvalRequired" },
  { header: "Approver Name", field: "approverName" },
  { header: "Destination Country", field: "destinationCountry" },
  { header: "Destination City", field: "destinationCity" },
  { header: "Ref Number", field: "refNumber" },
  { header: "Cost Center", field: "costCenter" },
  { header: "Labels", field: "labels" },
];
