<div class="manage-booking">
  <div *ngIf="restApi.isBooking && booking">
    <div class="container mt-5">
      <div class="d-flex">
        <h2
          class="green-text bolder p-2 font-24"
          [ngClass]="{
            'mr-auto': restApi.isSiteLtr(),
            'ml-auto': restApi.isSiteRtl()
          }"
        >
          <i
            class="icon-success"
            [ngClass]="{
              'mr-2': restApi.isSiteLtr(),
              'ml-2': restApi.isSiteRtl()
            }"
          >
          </i>
          <span
            *ngIf="!restApi.inactiveWorkspace && bookingType === 'cancelled'"
            i18n="@@activeBookingCanceled"
            >Your booking is canceled</span
          >
          <span
            *ngIf="!restApi.inactiveWorkspace && bookingType === 'completed'"
            i18n="@@activeBookingCompleted"
            >Your booking is completed</span
          >
          <span
            *ngIf="!restApi.inactiveWorkspace && bookingType === 'confirmed'"
            i18n="@@activeBookingConfirmed"
            >Your booking is confirmed</span
          >
          <span
            *ngIf="restApi.inactiveWorkspace && bookingType === 'cancelled'"
            i18n="@@inActiveBookingCanceled"
            >Booking is canceled</span
          >
          <span
            *ngIf="restApi.inactiveWorkspace && bookingType === 'completed'"
            i18n="@@inActiveBookingCompleted"
            >Booking is completed</span
          >
          <span
            *ngIf="restApi.inactiveWorkspace && bookingType === 'confirmed'"
            i18n="@@inActiveBookingConfirmed"
            >Booking is confirmed</span
          >
        </h2>
        <button
          class="btn btn-link karla-font font-16"
          [ngClass]="{
            'pr-3': restApi.isSiteLtr(),
            'pl-3': restApi.isSiteRtl()
          }"
          (click)="restApi.showHotelPrint = true; print()"
          *ngIf="restApi.desktopView"
          i18n="@@print"
        >
          Print
        </button>
        <button
          (click)="openSendBookingVoucherPopUp()"
          class="btn btn-link karla-font font-16"
          *ngIf="restApi.desktopView && !restApi.inactiveWorkspace"
          i18n="@@email"
        >
          Email
        </button>
      </div>
      <div class="row">
        <div class="col-md-8">
          <div class="hotel-item-small rounded border mb-5 overflow-hidden">
            <div class="hotel-image" *ngIf="booking && booking.hotelImages">
              <img
                width="1000"
                height="667"
                loading="lazy"
                *ngIf="booking.hotelImages && booking.hotelImages[0]"
                [ngClass]="{
                  'rounded-left': restApi.isSiteLtr(),
                  'rounded-right': restApi.isSiteRtl()
                }"
                [src]="booking.hotelImages[0].url"
                alt="hotel image"
              />

              <img
                width="279"
                height="239"
                [ngClass]="{
                  'rounded-left': restApi.isSiteLtr(),
                  'rounded-right': restApi.isSiteRtl()
                }"
                loading="lazy"
                class="no-hotel-mages"
                *ngIf="!booking.hotelImages[0]"
                src="assets/img/no_image.png"
              />
            </div>
            <div class="hotel-info" *ngIf="booking">
              <h4 class="primaray-text bolder font-18">
                {{ booking.hotelName }}
              </h4>
              <div
                *ngIf="booking.hotelRating"
                class="gray-text font-16 font-weight-normal"
              >
                {{ booking.hotelRating }}-<ng-container
                  *ngIf="booking.hotelRating <= 2"
                  i18n="@@oneStar"
                  >star</ng-container
                ><ng-container *ngIf="booking.hotelRating > 2" i18n="@@stars"
                  >star</ng-container
                >
              </div>
              <div class="address">
                {{ booking.hotelAddress ? booking.hotelAddress : ""
                }}{{ booking.hotelAddress && booking.cityName ? "  " : ""
                }}{{ booking.cityName ? booking.cityName : ""
                }}{{ booking.stateCode && booking.cityName ? ", " : ""
                }}{{ booking.stateCode ? booking.stateCode : ""
                }}{{
                  (booking.stateCode || booking.cityName) && booking.countryName
                    ? ", "
                    : ""
                }}{{ booking.countryName ? booking.countryName : "" }}
              </div>
            </div>
          </div>
          <div class="mb-5"></div>
          <div class="section-info radius mb-4 mb-lg-5">
            <div class="section-header">
              <span
                class="primaray-text text-uppercase bolder"
                i18n="@@bookingSummary"
                >Booking Summary
              </span>
            </div>
            <div class="section-body gray-text-dark p-5">
              <div class="">
                <h4 class="mt-0 mb-3 bolder font-16" i18n="@@guestDetails">
                  Guest Details
                </h4>
                <div class="gray-text-dark">
                  <p>
                    <ng-container i18n="@@confirmationNumber"
                      >Confirmation Number</ng-container
                    >: {{ booking.referenceNumber }}
                  </p>
                  <p>
                    <ng-container i18n="@@firstNamePlaceHolder"
                      >First Name</ng-container
                    >: {{ booking.guestFirstName }}
                  </p>
                  <p>
                    <ng-container i18n="@@lastNamePlaceHolder"
                      >Last Name</ng-container
                    >: {{ booking.guestLastName }}
                  </p>
                  <p>
                    <ng-container i18n="@@emailAddress"
                      >Email Address</ng-container
                    >: {{ booking.emailAddress }}
                  </p>
                  <p>
                    <ng-container i18n="@@phoneNumber"
                      >Phone Number</ng-container
                    >: {{ booking.phoneNumber }}
                  </p>
                </div>
              </div>
              <div class="border-top mt-4 pt-4">
                <h4 class="mt-0 mb-3 bolder font-16" i18n="@@roomDetails">
                  Room Details
                </h4>
                <div class="gray-text-dark">
                  <p *ngIf="booking.roomName">
                    <ng-container i18n="@@roomName">Room Name</ng-container>:
                    {{ booking.roomName }}
                  </p>
                  <p *ngIf="booking.boardDescription">
                    {{ booking.boardDescription }}
                  </p>
                  <div class="" *ngIf="booking.freeWifi || booking.freeParking">
                    <p *ngIf="booking.freeWifi" i18n="@@freeWiFi">Free WiFi</p>
                    <p *ngIf="booking.freeParking" i18n="@@freeparking">
                      Free parking
                    </p>
                  </div>
                </div>
              </div>
              <div class="border-top mt-4 pt-4">
                <h4 class="mt-0 mb-3 bolder font-16" i18n="@@stayDetails">
                  Stay Details
                </h4>
                <div class="gray-text-dark">
                  <p *ngIf="checkin">
                    <ng-container i18n="@@checkInNoDash">Check in</ng-container
                    >&nbsp;: {{ checkin }}
                  </p>
                  <p *ngIf="checkout">
                    <ng-container i18n="@@checkOutNoDash"
                      >Check out</ng-container
                    >&nbsp;: {{ checkout }}
                  </p>
                  <div class="mb-3" *ngIf="nights">
                    <ng-container i18n="@@totalStay">Total Stay</ng-container
                    >&nbsp;:
                    {{ nights }}
                    <div class="d-inline">
                      <ng-container *ngIf="nights === 1" i18n="@@nightNoNumber"
                        >night</ng-container
                      >
                      <ng-container
                        *ngIf="nights === 2"
                        i18n="@@twoNightsNoNumber"
                        >nights</ng-container
                      >
                      <ng-container *ngIf="nights > 2" i18n="@@nights"
                        >nights</ng-container
                      >
                    </div>
                  </div>
                  <div
                    class="mb-3"
                    *ngIf="booking.paxes.roomInfos[0].numberOfAdults"
                  >
                    <ng-container i18n="@@adults">Adults</ng-container>&nbsp;:
                    {{ booking.paxes.roomInfos[0].numberOfAdults }}
                    <div class="d-inline text-lowercase">
                      <ng-container
                        *ngIf="booking.paxes.roomInfos[0].numberOfAdults === 1"
                        i18n="@@adult"
                        >adult</ng-container
                      >
                      <ng-container
                        *ngIf="booking.paxes.roomInfos[0].numberOfAdults === 2"
                        i18n="@@twoAdults"
                        >adults</ng-container
                      >
                      <ng-container
                        *ngIf="booking.paxes.roomInfos[0].numberOfAdults > 2"
                        i18n="@@adults"
                        >adults</ng-container
                      >
                    </div>
                  </div>

                  <div
                    class="mb-3"
                    *ngIf="booking.paxes.roomInfos[0].childAges.length"
                  >
                    <ng-container i18n="@@children">Children</ng-container
                    >&nbsp;:
                    {{ booking.paxes.roomInfos[0].childAges.length }}
                    <div class="d-inline text-lowercase">
                      <ng-container
                        *ngIf="
                          booking.paxes.roomInfos[0].childAges.length === 1
                        "
                        i18n="@@child"
                        >child</ng-container
                      >
                      <ng-container
                        *ngIf="
                          booking.paxes.roomInfos[0].childAges.length === 2
                        "
                        i18n="@@twoChildren"
                        >children</ng-container
                      >
                      <ng-container
                        *ngIf="booking.paxes.roomInfos[0].childAges.length > 2"
                        i18n="@@children"
                        >children</ng-container
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="border-top mt-4 pt-4"
                *ngIf="bookingType == 'confirmed' && booking.specialRequestText"
              >
                <h4 class="mt-0 mb-3 bolder font-16" i18n="@@specialRequests">
                  Special Requests
                </h4>
                <div class="text-medium">{{ booking.specialRequestText }}</div>
              </div>

              <div
                class="border-top mt-4 pt-4"
                *ngIf="facilities && facilities.length > 0"
              >
                <h4 class="mt-0 mb-3 bolder font-16" i18n="@@amenities">
                  Amenities
                </h4>
                <div class="amenities-list gray-primaray-text">
                  <p class="gray-text-dark" *ngFor="let facility of facilities">
                    <span
                      class="icon-check bolder"
                      [ngClass]="{
                        'pr-2': restApi.isSiteLtr(),
                        'pl-2': restApi.isSiteRtl()
                      }"
                    ></span
                    >{{ facility.description }}
                  </p>
                </div>
              </div>
              <div class="border-top mt-4 pt-4" *ngIf="booking.providerComment">
                <h4 class="mt-0 mb-3 bolder font-16" i18n="@@additionalInfo">
                  Additional Info.
                </h4>
                <p
                  class="gray-text-dark"
                  [innerHTML]="booking.providerComment"
                ></p>
              </div>
              <div
                class="border-top mt-4 pt-4"
                *ngIf="bookingType != 'completed'"
              >
                <h4
                  class="mt-0 mb-3 bolder font-16"
                  i18n="@@cancellationPolicies"
                >
                  Cancellation Policies
                </h4>
                <div *ngIf="booking.refundable">
                  <div
                    class="policy"
                    *ngFor="let policy of booking.cancellationPolicies"
                  >
                    {{ policy.description }}
                  </div>
                </div>
                <div
                  class="non-refundable"
                  *ngIf="!booking.refundable"
                  i18n="@@nonrefundable"
                >
                  Non-refundable
                </div>
                <a
                  *ngIf="
                    bookingType == 'confirmed' &&
                    allowCancellation &&
                    !restApi.inactiveWorkspace
                  "
                  role="button"
                  (click)="cancel()"
                  class="btn btn-link cancel karla-font"
                  i18n="@@cancelBooking"
                >
                  Cancel Booking
                </a>
              </div>
            </div>
            <div *ngIf="restApi.mobileView" class="mt-3">
              <button
                class="btn btn-link karla-font font-16"
                [ngClass]="{
                  'pr-3': restApi.isSiteLtr(),
                  'pl-3': restApi.isSiteRtl()
                }"
                (click)="restApi.showHotelPrint = true; print()"
                i18n="@@print"
              >
                Print
              </button>
              <button
                *ngIf="!restApi.inactiveWorkspace"
                (click)="shareByEmailMobile()"
                class="btn btn-link karla-font font-16"
                i18n="@@email"
              >
                Email
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="white-box p-0 mb-4">
            <div class="p-3">
              <h5 class="font-18 bolder mb-3" i18n="@@priceSummary">
                Price Summary
              </h5>
              <ng-container *ngIf="booking">
                <div class="daily" *ngFor="let daily of booking.dailtyRates">
                  <div class="d-flex pb-1 text-medium">
                    <span
                      [ngClass]="{
                        'mr-auto': restApi.isSiteLtr(),
                        'ml-auto': restApi.isSiteRtl()
                      }"
                      >{{ restApi.formatDateEEMMMdy(daily.date) }}</span
                    >
                    <span
                      class="bolder"
                      [ngClass]="{ 'bright-purple': booking.primeBooking }"
                    >
                      <span class="currency">{{
                        daily.sellingPriceConvertedCurrency
                      }}</span
                      ><span class="amount"> {{
                        daily.sellingPriceConverted | number
                      }} </span>
                    </span>
                  </div>
                </div>
              </ng-container>
              <div class="discount" *ngIf="!booking.taxIncluded && booking.tax">
                <div class="d-flex pb-1 text-medium">
                  <span
                    [ngClass]="{
                      'mr-auto': restApi.isSiteLtr(),
                      'ml-auto': restApi.isSiteRtl()
                    }"
                    i18n="@@taxAndFees"
                    >Tax and fees</span
                  >
                  <span class="bolder"
                    ><span class="currency">$</span
                    ><span class="amount">{{ booking.tax }}</span></span
                  >
                </div>
              </div>
              <div
                class="discount d-flex text-medium"
                *ngIf="booking.travelerId && booking.travelerId === 'guest'"
              >
                <span
                  [ngClass]="{
                    'mr-auto': restApi.isSiteLtr(),
                    'ml-auto': restApi.isSiteRtl()
                  }"
                  i18n="@@bookingFee"
                >
                  Booking fee</span
                >
                <span
                  class="bolder"
                  [ngClass]="{
                    'ml-auto': restApi.isSiteLtr(),
                    'mr-auto': restApi.isSiteRtl()
                  }"
                  >${{ booking.guestFee | number : "1.2-2" }}</span
                >
              </div>
            </div>
            <div class="white-box-footer rounded-bottom p-3">
              <div class="d-flex font-22 bolder">
                <span class="primaray-text" i18n="@@Total"> Total </span>
                <span
                  class="primaray-text"
                  [ngClass]="{
                    'ml-auto': restApi.isSiteLtr(),
                    'mr-auto': restApi.isSiteRtl()
                  }"
                >
                  <span class="currency">{{ booking.sellingPriceConvertedCurrency }}</span
                  ><span class="amount"> {{ booking.sellingPriceConverted }} </span></span
                >
              </div>
            </div>
          </div>
          <div class="white-box p-0 mb-4">
            <div class="p-3">
              <h5 class="font-18 bolder mb-3" i18n="@@actualChargedAmount">
                Actual charged amount
              </h5>
              <div
                class="booking_box_content"
              >
              {{booking.chargeAmountCurrency}} {{booking.chargeAmount}} 
              </div>
            </div>
          </div>
          <div
            class="white-box p-0 mb-4"
            *ngIf="booking.rewardAmount && bookingType !== 'cancelled'"
          >
            <div
              class="white-box-purple-header p-3 rounded-top text-white font-18 font-weight-bold"
            >
              <ng-container i18n="@@rewards">Rewards</ng-container>:
              <ng-container *ngIf="restApi.isSiteLtr()">$</ng-container
              >{{ booking.rewardAmount | number : "1.2-2"
              }}<ng-container *ngIf="restApi.isSiteRtl()">$</ng-container>
            </div>
            <div
              class="px-3 pt-3 pb-4 gray-primaray-text font-16"
              i18n="@@manageHotelUponCompleting"
            >
              You will earn ${{ booking.rewardAmount | number : "1.2-2" }} upon
              completing this stay
            </div>
          </div>
          <div class="white-box p-0 mb-5">
            <div class="p-3">
              <h5 class="font-18 bolder mb-3" i18n="@@paymentInfo">
                Payment info
              </h5>
              <div
                class="booking_box_content"
                *ngIf="booking.payment.channelProvider == 'AMAZON_PAYMENT_SERVICE'"
              >
                <div>
                  {{ booking.payment.brand }}
                  <ng-container i18n="@@endsWith">ends with</ng-container>
                  {{ booking.payment.lastFour }}
                </div>
              </div>

            </div>
          </div>
          <div
            class="white-box p-0 mt-5 mb-5"
            *ngIf="bookingType == 'cancelled'"
          >
            <div class="p-3">
              <h5 class="font-18 bolder mb-3" i18n="@@refundInfo">
                Refund info
              </h5>
              <div>
                <ng-container i18n="@@cancelPenalty"
                  >Cancellation Penalty</ng-container
                >:
                {{
                  booking.customerRefundAmountConvertedCurrency
                }} {{
                  booking.customerRefundAmountConverted - booking.sellingPriceConverted
                    | number : "1.2-2"
                }}
              </div>
              <div>
                <ng-container i18n="@@totalRefund">Total refund</ng-container>:
                {{
                  booking.customerRefundAmountConvertedCurrency
                }} {{ booking.customerRefundAmountConverted }}
              </div>
            </div>
          </div>
          <div
            class="white-box p-0 mt-5 mb-5"
            *ngIf="bookingType == 'completed' && booking.customerFeedback"
          >
            <div class="p-3">
              <h5 class="font-18 bolder mb-3" i18n="@@yourReview">
                Your Review
              </h5>
              <p class="gray-text-dark">{{ booking.customerFeedback }}</p>
              <div class="gray-text text-medium font-weight-normal">
                <ng-container
                  *ngFor="let i of [].constructor(booking.customerRating)"
                >
                  <span class="icon-star-full active-review"></span>
                </ng-container>
              </div>
            </div>
          </div>
          <h5 class="gray-text-dark bolder mb-3" i18n="@@location">Location</h5>
          <div *ngIf="booking">
            <div class="map-side-details">
              <span>
                <button
                  class="button btn-link border toggleView map-illustration not_button mb-md-5 mb-0 btn-auto"
                  [ngClass]="{
                    'mr-md-auto': restApi.isSiteLtr(),
                    'ml-md-auto': restApi.isSiteRtl()
                  }"
                  (click)="mapPopUp()"
                >
                  <span
                    ><ng-container i18n="@@viewMap">view map</ng-container
                    ><i
                      class="icon-locator"
                      [ngClass]="{
                        'pl-2': restApi.isSiteLtr(),
                        'pr-2': restApi.isSiteRtl()
                      }"
                    ></i
                  ></span>
                </button>
              </span>
            </div>
          </div>
          <br />
          <div *ngIf="restApi.isCompany() && restApi.showExpensifyIcon">
            <img
              (click)="restApi.showUploadExpensesPopUp(booking.referenceNumber)"
              [ngClass]="{
                'pr-3': restApi.isSiteLtr(),
                'pl-3': restApi.isSiteRtl()
              }"
              width="150"
              height="23"
              loading="lazy"
              class="max-100 initial-height mb-3 pointer"
              src="/assets/img/expensify-logo--reverse.png"
            />
            <p
              class="gray-text mb-1"
              *ngIf="this.restApi.expensifyLastModifiedDate"
              [innerHTML]="formatDate(this.restApi.expensifyLastModifiedDate)"
            ></p>
            <p class="gray-text" *ngIf="this.restApi.expensifyLastReportName">
              Updated report name: {{ this.restApi.expensifyLastReportName }}
            </p>
            <p
              class="blue-text font-weight-bold pointer"
              (click)="restApi.showUploadExpensesPopUp(booking.referenceNumber)"
              i18n="@@uploadExpense"
            >
              Upload Expense
            </p>
          </div>
          <app-cost-reporting-details
            [costCentersIds]="booking?.costCenter ? [booking?.costCenter] : ''"
            [labelsIds]="booking?.labels ? booking?.labels : []"
          >
          </app-cost-reporting-details>
        </div>
      </div>
    </div>
  </div>
</div>
