import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { FormControl, NgModel } from "@angular/forms";
import { throwError, of, empty, Observable } from "rxjs";
import { TagInputComponent as SourceTagInput } from "ngx-chips";
import { map, startWith } from "rxjs/operators";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";

declare let fbAsyncInit: Function;
declare let window: any;
declare let FB: any;
export interface Email {
  address: string;
}
export class User {
  constructor(
    public description: string,
    public id: string,
    public email: string,
    public selected?: boolean
  ) {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("search-page");
    if (selected === undefined) {
      selected = false;
    }
  }
}
@Component({
  selector: "app-company-share-hotel",
  templateUrl: "./company-share-hotel.component.html",
  styleUrls: ["./company-share-hotel.component.scss"],
})
export class CompanyShareHotelComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public dataService: DataService,
    public router: Router
  ) {}
  copyText = "Copy";
  data;
  sendEmailFlag = false;
  hotelLink;
  userIdGroup = "";
  placeholder = true;
  submitting = false;
  elem;
  encodedTwitterUrl;
  shareHotelImage;
  shareHotelAddress;
  shareHotelName;
  submitted = false;
  hotelFacebookURL;
  hotelTwitterURL;
  facebookShareLinkHotel;
  paxes;

  userControl = new FormControl();
  separatorKeysCodes = [ENTER, COMMA];
  addOnBlur: false;
  dataUsers;
  showMembersAutoCompletePopUp = false;
  users = [];
  selectedUsers: User[] = new Array<User>();
  mobileTempSelectedUsers: User[] = new Array<User>();
  filteredUsers: Observable<User[]>;
  lastFilter: string = "";
  teamId;
  @ViewChild("userInput") userInput: ElementRef;
  urlArray;
  refFromURLValue;
  identifierFromURLValue;
  emailFromURL = "email";
  emailFromURLValue;
  ngOnInit() {
    if (this.dataService.desktopView) {
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("workspace-modal");
    }
    if (("" + this.router.url).indexOf("identifier") > 0) {
      this.identifierFromURLValue = ("" + this.router.url).substring(
        ("" + this.router.url).indexOf("identifier=") + 11
      );
      this.refFromURLValue = ("" + this.router.url).substring(
        ("" + this.router.url).indexOf("?") + 5,
        ("" + this.router.url).indexOf("&")
      );
    } else {
      this.route.queryParams.subscribe((params) => {
        this.emailFromURLValue = params[this.emailFromURL];
      });

      this.identifierFromURLValue = ("" + this.router.url).substring(
        ("" + this.router.url).indexOf("&email=") + 7,
        ("" + this.router.url).indexOf("&lastname")
      );
      this.refFromURLValue = ("" + this.router.url).substring(
        ("" + this.router.url).indexOf("?") + 5,
        ("" + this.router.url).indexOf("&")
      );
    }
    this.usersAutoComplete();
    this.shareHotelImage = localStorage.getItem("shareHotelImage");
    this.shareHotelName = localStorage.getItem("shareHotelName");
    this.shareHotelAddress = localStorage.getItem("shareHotelAddress");
    this.hotelLink = `${environment.companyHost}` + this.router.url;

    if (("" + this.hotelLink).indexOf("&utm_source") > 0) {
      this.hotelFacebookURL =
        ("" + this.hotelLink).substring(
          0,
          ("" + this.hotelLink).indexOf("&utm_source")
        ) + "&utm_source=facebook&utm_medium=post&utm_campaign=socialsharing";
      this.hotelTwitterURL =
        ("" + this.hotelLink).substring(
          0,
          ("" + this.hotelLink).indexOf("&utm_source")
        ) + "&utm_source=twitter&utm_medium=post&utm_campaign=socialsharing";
      this.paxes = ("" + this.hotelLink).substring(
        ("" + this.hotelLink).indexOf("&paxes"),
        ("" + this.hotelLink).indexOf("&utm_source")
      );
    } else {
      this.hotelFacebookURL =
        this.hotelLink +
        "&utm_source=facebook&utm_medium=post&utm_campaign=socialsharing";
      this.hotelTwitterURL =
        this.hotelLink +
        "&utm_source=twitter&utm_medium=post&utm_campaign=socialsharing";
      this.paxes = ("" + this.hotelLink).substring(
        ("" + this.hotelLink).indexOf("&paxes"),
        this.hotelLink.length
      );
    }
    const checkInOutDate = this.hotelFacebookURL.substring(
      ("" + this.hotelLink).indexOf("&checkIn"),
      ("" + this.hotelLink).indexOf("&checkOut") + 20
    );
    this.hotelTwitterURL = this.hotelTwitterURL.replace(checkInOutDate, "");
    this.hotelTwitterURL = this.hotelTwitterURL.replace(this.paxes, "");

    sessionStorage.setItem("host", `${environment.companyHost}`);
    sessionStorage.setItem("appId", `${environment.apiIdFB}`);
    this.dataService.loadScript("https://connect.facebook.net/es_LA/sdk.js");

    this.encodedTwitterUrl = encodeURIComponent(
      environment.companyHost +
        "/social-card.jsp?image=" +
        this.shareHotelImage +
        "&description=" +
        encodeURIComponent(this.shareHotelAddress) +
        "&title=" +
        encodeURIComponent(this.shareHotelName) +
        "&destination=" +
        this.hotelTwitterURL
    );
    this.facebookShareLinkHotel =
      environment.companyHost +
      "/social-card.jsp?image=" +
      this.shareHotelImage +
      "&description=" +
      encodeURIComponent(this.shareHotelAddress) +
      "&title=" +
      encodeURIComponent(this.shareHotelName) +
      "&destination=" +
      this.hotelFacebookURL;
  }

  add(event: MatChipInputEvent): void {
    if (
      this.dataService.BottomSheetName === "companySendBookingVoucher" ||
      this.dataService.modalName === "companySendBookingVoucher"
    ) {
      const value = (event.value || "").trim();

      const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

      if (value && EMAIL_REGEXP.test(value)) {
        let outOfListUser = new User(value, value, value, true);
        this.selectedUsers.push(outOfListUser);
        this.mobileTempSelectedUsers.push(outOfListUser);
      }

      const input = document.getElementById("userInput") as HTMLInputElement;
      if (input) {
        input.value = "";
      }

      this.userControl.setValue(null);
    }
  }
  facebookShare() {
    window.fbAsyncInit = () => {
      FB.init({
        appId: environment.apiIdFB, // Your appId
        xfbml: true,
        version: "v3.3",
      });
    };

    //
    FB.ui(
      {
        method: "feed",
        link: this.facebookShareLinkHotel,
        description: "a small description associated with your post",
        hashtag: "#triphoptravel",
        display: "popup",
      },
      // callback
      (response) => {
        if (response && !response.error_message) {
          if (window.ga) {
            /*  if (vars.enableDebug) {
              }*/
            window.ga(
              "AdminTracker.send",
              "event",
              "Stats",
              "Referral",
              "Invite-by-Facebook",
              1
            );
            window.ga(
              "DevTracker.send",
              "event",
              "Stats",
              "Referral",
              "Invite-by-Facebook",
              1
            );
          }
        } else {
        }
      }
    );
  }
  openPopUp() {
    window.open(
      "https://twitter.com/intent/tweet?text=&url=" + this.encodedTwitterUrl,
      "twitter",
      "height=600,width=450"
    );
  }
  copy(url) {
    url.select();
    document.execCommand("copy");
    url.setSelectionRange(0, 0);
    this.copyText = "Copied";
    setTimeout(() => this.changeText(), 3000);
  }
  changeText() {
    this.copyText = "Copy";
  }

  sendEmailVocher() {
    this.submitted = true;
    this.submitting = true;
    if (this.selectedUsers.length === 0) {
      this.submitting = false;
      return;
    }
    this.submitting = true;
    setTimeout(() => {
      if (this.selectedUsers.length === 0) {
        this.submitting = false;
      }
      let emailsArray = "";
      let stringEmails = "";
      this.selectedUsers.forEach((email, index) => {
        if (index === 0) {
          emailsArray = "?emails=" + this.selectedUsers[index].email;
          stringEmails = this.selectedUsers[index].email;
        } else {
          emailsArray += "&emails=" + this.selectedUsers[index].email;
          stringEmails += ", " + this.selectedUsers[index].email;
        }
      });
      this.dataService
        .shareBookingByEmail(emailsArray, this.refFromURLValue)
        .subscribe(
          (data) => {
            this.data = data;
            if (this.data.errorCode === 0) {
              this.dataService.alerts = [];
              this.sendEmailFlag = true;
              this.selectedUsers = [];
              this.userIdGroup = "";
              this.submitting = false;
              this.dataService.messages(
                $localize`:@@voucherSent:A voucher has been sent to` +
                  " " +
                  stringEmails,
                "success"
              );
              this.submitted = false;
              this.dataService.modalOf();
              this.dataService.BottomSheetOf();
            } else {
              this.submitting = false;
              this.dataService.messages(this.data.message, "error");
              this.dataService.modalOf();
            }
          },
          (error) => {
            this.submitting = false;
            this.dataService.messages(environment.messages.error, "error");
            this.dataService.modalOf();
          }
        );
    }, 2000);
  }

  sendEmail() {
    this.submitted = true;
    this.submitting = true;
    if (this.selectedUsers.length === 0) {
      this.submitting = false;
      return;
    }
    this.submitting = true;
    setTimeout(() => {
      if (this.selectedUsers.length === 0) {
        this.submitting = false;
      }
      for (let i = 0; i < this.selectedUsers.length; i++) {
        if (i === this.selectedUsers.length - 1) {
          this.userIdGroup += this.selectedUsers[i].id;
        } else {
          this.userIdGroup += this.selectedUsers[i].id + "&userIds =";
        }
      }
      this.dataService
        .companyShareHotels(
          this.dataService.hotelCodeToShare,
          this.userIdGroup,
          this.paxes
        )
        .subscribe(
          (data) => {
            this.data = data;
            if (this.data.errorCode === 0) {
              this.dataService.alerts = [];
              this.sendEmailFlag = true;
              this.selectedUsers = [];
              this.userIdGroup = "";
              this.submitting = false;
              this.dataService.SnakBar(
                $localize`:@@inviteOnWay:Your invitation is on its way`
              );
              this.submitted = false;
              this.dataService.modalOf();
              this.dataService.BottomSheetOf();
            } else {
              this.submitting = false;
              this.dataService.messages(this.data.message, "error");
              this.dataService.modalOf();
            }
          },
          (error) => {
            this.submitting = false;
            this.dataService.messages(environment.messages.error, "error");
            this.dataService.modalOf();
          }
        );
    }, 2000);
  }

  usersAutoComplete() {
    this.dataService.getCompanyUsersList().subscribe(
      (dataList) => {
        this.dataUsers = dataList;
        if (this.dataUsers.errorCode === 0) {
          this.dataUsers = this.dataUsers.data;

          this.dataUsers.forEach((element) => {
            this.users.push(
              new User(element.description, element.id, element.email)
            );
          });
          this.filteredUsers = this.userControl.valueChanges.pipe(
            startWith<string | User[]>(""),
            map((value) =>
              typeof value === "string" ? value : this.lastFilter
            ),
            map((filter) => this.filter(filter))
          );
        } else {
          this.dataService.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.dataService.messages(environment.messages.error, "error");
      }
    );
  }

  filter(filter: string): User[] {
    this.lastFilter = filter;
    if (filter) {
      return this.users.filter((option) => {
        return (
          option.description.toLowerCase().includes(filter.toLowerCase()) ||
          option.email.toLowerCase().includes(filter.toLowerCase())
        );
      });
    } else {
      return this.users.slice();
    }
  }
  displayFn(user: User): string {
    return user && user.description ? user.description : "";
  }
  reset() {
    this.userInput.nativeElement.value = "";
    this.userControl.setValue(null);
    this.lastFilter = "";
    this.filteredUsers = this.userControl.valueChanges.pipe(
      startWith<string | User[]>(""),
      map((value) => (typeof value === "string" ? value : this.lastFilter)),
      map((filter) => this.filter(filter))
    );
  }
  resetBlur() {
    this.userInput.nativeElement.value = "";
    this.userControl.setValue(null);
  }
  optionClicked(event: Event, user: User) {
    this.userInput.nativeElement.value = "";
    this.userControl.setValue(null);
    event.stopPropagation();
    this.toggleSelection(user);
  }
  toggleSelection(user: User) {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedUsers.push(user);
    } else {
      this.users.forEach((element) => {
        if (user.id === element.id) {
          element.selected = false;
        }
      });
      const i = this.selectedUsers.findIndex(
        (value) =>
          value.description === user.description && value.id === user.id
      );
      this.selectedUsers.splice(i, 1);
    }
  }
  optionClickedMobile(event: Event, user: User) {
    this.userInput.nativeElement.value = "";
    this.userControl.setValue(null);
    event.stopPropagation();
    this.toggleSelectionTemp(user);
  }
  toggleSelectionTemp(user: User) {
    user.selected = !user.selected;
    if (user.selected) {
      this.mobileTempSelectedUsers.push(user);
    } else {
      const i = this.mobileTempSelectedUsers.findIndex(
        (value) =>
          value.description === user.description && value.id === user.id
      );
      this.mobileTempSelectedUsers.splice(i, 1);
    }
  }
  saveSelectedUsers() {
    this.selectedUsers = [];
    this.mobileTempSelectedUsers.forEach((element) => {
      this.selectedUsers.push(element);
    });
  }
  showMembersPopUp() {
    if (this.dataService.mobileView) {
      this.mobileTempSelectedUsers = [];
      this.showMembersAutoCompletePopUp = true;
      this.dataService.disableBodyScroll();
      this.selectedUsers.forEach((element, index) => {
        this.mobileTempSelectedUsers.push(element);
        this.mobileTempSelectedUsers[index].selected = true;
      });
      setTimeout(() => {
        const input = document.getElementById("userInput") as HTMLInputElement;
        input.focus();
        input.select();
      }, 200);
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("members-popup");
      return;
    }
  }
  hidePopUp() {
    this.showMembersAutoCompletePopUp = false;
    this.dataService.enableBodyScroll();
    this.mobileTempSelectedUsers.forEach((element) => {
      element.selected = false;
    });
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("members-popup");
  }
  cancelAddMembers() {
    if (this.dataService.desktopView) {
      this.dataService.modalOf();
    } else {
      this.router.navigate([this.router.url.replace("/add-members", "")]);
    }
  }
}
