import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../../../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { AuthServiceMain } from "../../../../auth/auth.service";
import { environment } from "../../../../../environments/environment";
import { map, startWith } from "rxjs/operators";
import * as moment from "moment";
import { UserService } from "../../../../services/user.service";
import { WalletManagementService } from "src/app/services/wallet-services/wallet-management.service";
export interface Policy {
  name: string;
  policyId: string;
}
export interface Team {
  name: string;
  teamId: string;
}
export class Payment {
  constructor(
    public description: string,
    public id: string,
    public selected?: boolean
  ) {
    if (selected === undefined) {
      selected = false;
    }
  }
}
export class Wallet {
  constructor(
    public description: string,
    public id: string,
    public selected?: boolean
  ) {
    if (selected === undefined) {
      selected = false;
    }
  }
}
@Component({
  selector: "app-user-update",
  templateUrl: "./user-update.component.html",
  styleUrls: ["./user-update.component.scss"],
})
export class UserUpdateComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public auth: AuthServiceMain,
    private formBuilder: FormBuilder,
    public dataService: DataService,
    public router: Router,
    public userService: UserService,
    public walletManagementService: WalletManagementService
  ) {}
  applyBlur = true;
  errorMessage;
  errorMessagePay;
  usersEditForm: FormGroup;
  paymentControl = new FormControl();
  submitted = false;
  submitting = false;
  // selectedUserTeam;
  // selectedUserPolicy;
  urlArray;
  userId;
  previousURL;
  data;
  dataTeam;
  dataPolicy;
  // travelApprovalValue = false;
  // isAdmin = null;
  submittingPayment = false;
  separatorKeysCodes = [ENTER, COMMA];
  addOnBlur: false;
  showPaymentsAutoCompletePopUp = false;
  payments = [];
  selectedPayments: Payment[] = new Array<Payment>();
  mobileTempSelectedPayments: Payment[] = new Array<Payment>();
  filteredPayments: Observable<Payment[]>;
  lastFilter: string = "";
  editUserPayments = false;
  @ViewChild("paymentInput") paymentInput: ElementRef;
  // sendPolicyId;
  // sendTeamId;
  dataPay;

  policyControl = new FormControl();
  showPolicyAutoCompletePopUp = false;
  selectedPolicy;
  options = [];
  filteredPolicies: Observable<any[]>;
  lastFilterPolicy: string = "";
  editTeamPolicy = false;
  NoPolicy: Policy = {
    name: $localize`:@@unassigned:Unassigned`,
    policyId: null,
  };
  @ViewChild("policyInput") policyInput: ElementRef;
  @ViewChild("walletInput") walletInput: ElementRef;
  walletControl = new FormControl();
  teamOptions = [];
  teamControl = new FormControl();
  showTeamAutoCompletePopUp = false;
  selectedTeam;
  userNewDetails;
  filteredTeams: Observable<any[]>;
  lastFilterTeam: string = "";
  editUserTeam = false;
  focusInputDesktop;
  defaultTeamInfo;
  NoTeam: Team = { name: $localize`:@@unassigned:Unassigned`, teamId: "0 " };
  @ViewChild("teamInput") teamInput: ElementRef;
  focusInputMobile;
  getAllPaymentFlag = false;
  restrictUser = false;
  teamLoader;
  policyLoader;
  // adminLoader;
  paymentLoader;
  travelLoader;
  /* checkClass(){
    if (this.dataService.desktopView) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('workspace-page');
    }
  }*/
  //Wallets Variables
  walletData;
  wallets = [];
  selectedWallets: Wallet[] = new Array<Wallet>();
  filteredWallets: Observable<Wallet[]>;
  mobileTempSelectedWallets: Wallet[] = new Array<Wallet>();
  getAllWalletFlag = false;
  showWalletsAutoCompletePopUp = false;
  addNewWallet = $localize`:@@addNewWallet:Add New Wallet`;
  userWallets;
  companyWallets;
  editUserWallets = false;
  submittingWallet = false;
  errorMessageWallet;
  lastFilterWallet: string = "";

  ngOnInit(): void {
    this.dataService.createEditTeamPage = false;
    // this.checkClass();
    this.options.push(this.NoPolicy);
    this.defaultTeamInfo = JSON.parse(
      sessionStorage.getItem("defaultTeamInfo")
    );
    if (this.defaultTeamInfo) {
      this.NoTeam = {
        name: $localize`:@@unassigned:Unassigned`,
        teamId: this.defaultTeamInfo.id,
      };
    }
    this.teamOptions.push(this.NoTeam);
    this.usersEditForm = this.formBuilder.group(
      {
        // travelApproval: [''],
        isAdmin: [""],
      },
      {}
    );
    this.urlArray = this.router.url.split("/");
    this.userId = this.urlArray[this.urlArray.length - 2];
    const currentUrl = this.route.snapshot.url; // Get the current URL segments
    this.previousURL = currentUrl.slice(0, currentUrl.length - 1).join("/"); // Remove the last segment

    this.fetchEditFormData();
    this.policyAutoComplete();
    this.walletsAutoComplete();
    this.teamAutoComplete();
  }
  fetchEditFormData() {
    this.dataService.getCompanyUserDetails(this.userId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.companyUserInfo = this.data.data;
          if (
            this.dataService.companyUserInfo &&
            this.dataService.companyUserInfo.teamId
          ) {
            /* this.dataService.getTeamDetails(this.dataService.companyUserInfo.membership.companyMembership.teamId).subscribe(
              dataTeam => {
                this.dataTeam = dataTeam;
                if (this.dataTeam.errorCode === 0) {
                  this.dataService.teamInfo = this.dataTeam.data;
                  this.selectedTeam = {name: this.dataService.teamInfo.name, teamId: this.dataService.teamInfo.id};
                  this.teamControl.setValue(this.selectedTeam);
                } else {
                  this.errorMessage = this.dataTeam.message;
                }
              }, error => {
                this.errorMessage = environment.messages.error;
              }
            );*/
            this.selectedTeam = {
              name: this.dataService.companyUserInfo.teamName,
              teamId: this.dataService.companyUserInfo.teamId,
            };
            this.teamControl.setValue(this.selectedTeam);
          } else {
            this.selectedTeam = this.NoTeam;
            this.teamControl.setValue(this.selectedTeam);
          }
          if (
            this.dataService.companyUserInfo &&
            this.dataService.companyUserInfo.policyId
          ) {
            /*    this.dataService.getPolicyDetails(this.dataService.companyUserInfo.membership.companyMembership.policyId).subscribe(
              dataPolicy => {
                this.dataPolicy = dataPolicy;
                if (this.dataPolicy.errorCode === 0) {
                  this.selectedUserPolicy = this.dataPolicy.data;
                  this.selectedPolicy = {name: this.selectedUserPolicy.policy.name, policyId: this.selectedUserPolicy.policy.policyId};
                  this.policyControl.setValue(this.selectedPolicy);
                } else {
                  this.errorMessage = this.dataPolicy.message;
                }
              }, error => {

                this.errorMessage = environment.messages.error;
              }
            );*/
            this.selectedPolicy = {
              name: this.dataService.companyUserInfo.effectivePolicyName,
              policyId: this.dataService.companyUserInfo.policyId,
            };
            this.policyControl.setValue(this.selectedPolicy);
          } else {
            this.selectedPolicy = this.NoPolicy;
            this.policyControl.setValue(this.selectedPolicy);
          }

          if (
            this.dataService.companyUserInfo &&
            this.dataService.companyUserInfo.personalPaymentMethodsDisabled
          ) {
            this.restrictUser =
              this.dataService.companyUserInfo.personalPaymentMethodsDisabled;
          }
          /*  if (this.dataService.companyUserInfo && this.dataService.companyUserInfo.membership.companyMembership.blanketApproval) {
            this.travelApprovalValue = this.dataService.companyUserInfo.membership.companyMembership.blanketApproval;
          }*/
          if (
            this.dataService.companyUserInfo &&
            this.dataService.companyUserInfo.role
          ) {
            if (
              this.dataService.companyUserInfo.role.toLowerCase() === "admin"
            ) {
              this.dataService.isAdmin = true;
              this.usersEditForm.controls.isAdmin.setValue(true);
            }
          }
          this.autoCompletePayments(
            this.dataService.companyUserInfo.companyCards
          );
          this.getUserWallets();
        } else {
          //   this.dataService.messages(this.data.message, 'error');
          this.errorMessage = this.data.message;
        }
      },
      (error) => {
        //  this.dataService.messages(environment.messages.error, 'error');
        this.errorMessage = environment.messages.error;
      }
    );
  }
  private filterTeam(name: string): Team[] {
    this.lastFilterTeam = name;
    if (name) {
      return this.teamOptions.filter(
        (option) =>
          option.name.toLowerCase().indexOf(name.toLowerCase()) === 0 &&
          option.name !== "Unassigned" &&
          option.name !== $localize`:@@unassigned:Unassigned`
      );
    } else {
      return this.teamOptions.slice();
    }
  }
  teamAutoComplete() {
    // this.checkClass();
    this.teamOptions = [];
    this.teamOptions.push(this.NoTeam);
    this.dataService.getTeamsList().subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          if (this.data.data) {
            this.data.data.forEach((element) => {
              this.teamOptions.push({ name: element.name, teamId: element.id });
              this.filteredTeams = this.teamControl.valueChanges.pipe(
                startWith(""),
                map((value) =>
                  typeof value === "string" ? value : this.lastFilterTeam
                ),
                map((name) =>
                  name ? this.filterTeam(name) : this.teamOptions.slice()
                )
              );
            });
          } else {
            this.filteredTeams = this.teamControl.valueChanges.pipe(
              startWith(""),
              map((value) =>
                typeof value === "string" ? value : this.lastFilterTeam
              ),
              map((name) =>
                name ? this.filterTeam(name) : this.teamOptions.slice()
              )
            );
          }
        } else {
          // this.dataService.messages(this.data.message, 'error');
          this.errorMessage = this.data.message;
        }
      },
      (error) => {
        //  this.dataService.messages(environment.messages.error, 'error');
        this.errorMessage = environment.messages.error;
      }
    );
  }
  policyAutoComplete() {
    // this.checkClass();
    this.dataService.policyList().subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.options = [];
          this.options.push(this.NoPolicy);
          if (this.data.data) {
            this.data.data.forEach((element) => {
              this.options.push({
                name: element.policy.name,
                policyId: element.policy.policyId,
              });
              this.filteredPolicies = this.policyControl.valueChanges.pipe(
                startWith(""),
                map((value) =>
                  typeof value === "string" ? value : this.lastFilterPolicy
                ),
                map((name) =>
                  name ? this.filterPolicy(name) : this.options.slice()
                )
              );
            });
          } else {
            this.filteredPolicies = this.policyControl.valueChanges.pipe(
              startWith(""),
              map((value) =>
                typeof value === "string" ? value : this.lastFilterPolicy
              ),
              map((name) =>
                name ? this.filterPolicy(name) : this.options.slice()
              )
            );
          }
        } else {
          //  this.dataService.messages(this.data.message, 'error');
          this.errorMessage = this.data.message;
        }
      },
      (error) => {
        // this.dataService.messages(environment.messages.error, 'error');
        this.errorMessage = environment.messages.error;
      }
    );
  }
  private filterPolicy(name: string): Policy[] {
    this.lastFilterPolicy = name;
    if (name) {
      return this.options.filter(
        (option) =>
          option.name.toLowerCase().indexOf(name.toLowerCase()) === 0 &&
          option.name !== "Unassigned" &&
          option.name !== $localize`:@@unassigned:Unassigned`
      );
    } else {
      return this.options.slice();
    }
  }
  displayFnPolicy(policy: Policy): string {
    return policy && policy.name ? policy.name : "";
  }
  displayFnTeam(team: Team): string {
    return team && team.name ? team.name : "";
  }
  resetPolicy() {
    setTimeout(() => {
      if (this.applyBlur) {
        if (typeof this.policyControl.value !== "object") {
          if (
            this.policyControl.value === null ||
            this.policyControl.value === ""
          ) {
            this.removeUserPolicy();
            this.desktopPolicyBlur();
            this.policyInput.nativeElement.value = "";
            this.lastFilterPolicy = "";
            this.selectedPolicy = this.NoPolicy;
            this.policyControl.setValue(this.selectedPolicy);
          } else {
            this.policyInput.nativeElement.value = "";
            this.policyControl.setValue(null);
            this.lastFilterPolicy = "";
            this.selectedPolicy = null;
            if (
              this.dataService.companyUserInfo &&
              this.dataService.companyUserInfo.policyId
            ) {
              /* this.dataService.getPolicyDetails(this.dataService.companyUserInfo.membership.companyMembership.policyId).subscribe(
            dataPolicy => {
              this.dataPolicy = dataPolicy;
              if (this.dataPolicy.errorCode === 0) {
                this.selectedUserPolicy = this.dataPolicy.data;
                this.selectedPolicy = {
                  name: this.selectedUserPolicy.policy.name,
                  policyId: this.selectedUserPolicy.policy.policyId
                };
                this.policyControl.setValue(this.selectedPolicy);
              } else {
                this.errorMessage = this.dataPolicy.message;
              }
            }, error => {
              this.errorMessage = environment.messages.error;
            }
          );*/
              this.selectedPolicy = {
                name: this.dataService.companyUserInfo.effectivePolicyName,
                policyId: this.dataService.companyUserInfo.policyId,
              };
              this.policyControl.setValue(this.selectedPolicy);
            } else {
              this.selectedPolicy = this.NoPolicy;
              this.policyControl.setValue(this.selectedPolicy);
            }
          }
          this.filteredPolicies = this.policyControl.valueChanges.pipe(
            startWith(""),
            map((value) =>
              typeof value === "string" ? value : this.lastFilterPolicy
            ),
            map((name) =>
              name ? this.filterPolicy(name) : this.options.slice()
            )
          );
        }
        this.editTeamPolicy = false;
      }
    }, 200);
  }
  resetTeam() {
    setTimeout(() => {
      if (this.applyBlur) {
        if (typeof this.teamControl.value !== "object") {
          if (
            this.teamControl.value === null ||
            this.teamControl.value === ""
          ) {
            if (this.dataService.companyUserInfo.teamId) {
              //  this.dataService.removeMemberFromTeam(this.dataService.companyUserInfo.membership.companyMembership.teamId, this.userId);
              this.dataService
                .removeUsersFromTeam(
                  this.dataService.companyUserInfo.teamId,
                  this.userId
                )
                .subscribe(
                  (data) => {
                    this.data = data;
                    if (this.data.errorCode === 0) {
                      this.desktopTeamBlur();
                      this.dataService.getCompanyUserInfo(this.userId);
                    } else {
                      this.dataService.messages(this.data.message, "error");
                    }
                  },
                  (error) => {
                    this.dataService.messages(
                      environment.messages.error,
                      "error"
                    );
                  }
                );
            }
            this.teamInput.nativeElement.value = "";
            this.lastFilterTeam = "";
            this.selectedTeam = this.NoTeam;
            this.teamControl.setValue(this.selectedTeam);
          } else {
            this.teamInput.nativeElement.value = "";
            this.teamControl.setValue(null);
            this.lastFilterTeam = "";
            this.selectedTeam = null;
            if (
              this.dataService.companyUserInfo &&
              this.dataService.companyUserInfo.teamId
            ) {
              /*  this.dataService.getTeamDetails(this.dataService.companyUserInfo.membership.companyMembership.teamId).subscribe(
              dataTeam => {
                this.dataTeam = dataTeam;
                if (this.dataTeam.errorCode === 0) {
                  this.dataService.teamInfo = this.dataTeam.data;
                  this.selectedTeam = {name: this.dataService.teamInfo.name, teamId: this.dataService.teamInfo.id};
                  this.teamControl.setValue(this.selectedTeam);
                } else {
                  this.errorMessage = this.dataTeam.message;
                }
              }, error => {
                this.errorMessage = environment.messages.error;
              }
            );*/
              this.selectedTeam = {
                name: this.dataService.companyUserInfo.teamName,
                teamId: this.dataService.companyUserInfo.teamId,
              };
              this.teamControl.setValue(this.selectedTeam);
            } else {
              this.selectedTeam = this.NoTeam;
              this.teamControl.setValue(this.selectedTeam);
            }
          }
          this.filteredTeams = this.teamControl.valueChanges.pipe(
            startWith(""),
            map((value) =>
              typeof value === "string" ? value : this.lastFilterTeam
            ),
            map((name) =>
              name ? this.filterTeam(name) : this.teamOptions.slice()
            )
          );
        }
        this.editUserTeam = false;
      }
    }, 200);
  }
  restoreSelectedUser() {
    this.teamControl.setValue(this.selectedTeam);
    this.editUserTeam = false;
    this.desktopTeamBlur();
  }
  restoreSelectedPolicy() {
    this.policyControl.setValue(this.selectedPolicy);
    this.editTeamPolicy = false;
    this.desktopPolicyBlur();
  }
  onEnter() {
    if (typeof this.teamControl.value === "object") {
      this.optionClickedTeam(null, this.teamControl.value);
    } else if (
      this.teamControl.value === null ||
      this.teamControl.value === ""
    ) {
      this.resetTeam();
    }
  }
  onEnterPolicy() {
    if (typeof this.policyControl.value === "object") {
      this.optionClickedPolicy(this.policyControl.value);
    } else if (
      this.policyControl.value === null ||
      this.policyControl.value === ""
    ) {
      this.resetPolicy();
    }
  }
  optionClickedPolicy(policy: Policy) {
    this.selectedPolicy = policy;
    this.hidePopUp();
    if (this.selectedPolicy && this.selectedPolicy.policyId === null) {
      this.removeUserPolicy();
    } else if (this.selectedPolicy && this.selectedPolicy.policyId) {
      this.editUser("policy");
    }
  }
  optionClickedTeam(event: Event, team: Team) {
    this.selectedTeam = team;
    this.hidePopUp();
    if (
      this.selectedTeam &&
      (this.selectedTeam.teamId === null || this.selectedTeam.teamId === "0")
    ) {
      if (this.dataService.companyUserInfo.teamId) {
        this.teamLoader = true;
        this.dataService
          .removeUsersFromTeam(
            this.dataService.companyUserInfo.teamId,
            this.userId
          )
          .subscribe(
            (data) => {
              this.data = data;
              if (this.data.errorCode === 0) {
                this.teamLoader = false;
                this.desktopTeamBlur();
                this.dataService.getCompanyUserInfo(this.userId);
              } else {
                this.teamLoader = false;
                this.dataService.messages(this.data.message, "error");
              }
            },
            (error) => {
              this.teamLoader = false;
              this.dataService.messages(environment.messages.error, "error");
            }
          );
      } else {
        // from unassigned to unassigned
        this.desktopTeamBlur();
      }
    } else if (this.selectedTeam && this.selectedTeam.teamId) {
      this.editUser("team");
    }
  }

  desktopTeamBlur() {
    if (this.dataService.desktopView) {
      const input = document.getElementById(
        "teamInputHtml"
      ) as HTMLInputElement;
      if (input) {
        input.blur();
      }
    }
  }
  desktopPolicyBlur() {
    if (this.dataService.desktopView) {
      const input = document.getElementById(
        "policyInputHtml"
      ) as HTMLInputElement;
      if (input) {
        input.blur();
      }
    }
  }
  autoCompletePayments(paymentArray) {
    this.dataService.companyListPaymentMethods().subscribe(
      (data) => {
        this.dataPay = data;
        if (this.dataPay.errorCode === 0) {
          this.payments = [];
          this.dataService.companyPaymentMethods = this.dataPay.data;
          this.getAllPaymentFlag = true;
          this.dataService.companyPaymentMethods.forEach((element) => {
            if (element.card.alias) {
              this.payments.push(
                new Payment(element.card.alias, element.card.id)
              );
            } else {
              this.payments.push(
                new Payment(
                  element.card.brand + " " + element.card.lastFour,
                  element.card.id
                )
              );
            }
          });
          if (paymentArray) {
            paymentArray.forEach((userPayment) => {
              this.payments.forEach((element) => {
                if (userPayment.id === element.id) {
                  element.selected = true;
                  this.selectedPayments.push(
                    new Payment(element.description, element.id, true)
                  );
                }
              });
            });
          }
          if (this.selectedPayments.length) {
            this.payments.forEach((element) => {
              this.selectedPayments.forEach((elementSelected) => {
                if (elementSelected.id === element.id) {
                  element.selected = true;
                }
              });
            });
          }

          this.filteredPayments = this.paymentControl.valueChanges.pipe(
            startWith<string | Payment[]>(""),
            map((value) =>
              typeof value === "string" ? value : this.lastFilter
            ),
            map((filter) => this.filterPayment(filter))
          );
        } else {
        }
      },
      (error) => {}
    );
  }

  toggleSelectionPayment(payment: Payment) {
    payment.selected = !payment.selected;
    if (payment.selected) {
      this.selectedPayments.push(payment);
    } else {
      this.payments.forEach((element) => {
        if (payment.id === element.id) {
          element.selected = false;
        }
      });
      const i = this.selectedPayments.findIndex(
        (value) =>
          value.description === payment.description && value.id === payment.id
      );
      this.selectedPayments.splice(i, 1);
    }
  }
  filterPayment(filter: string): Payment[] {
    this.lastFilter = filter;
    if (filter) {
      return this.payments.filter((option) => {
        return (
          option.description.toLowerCase().indexOf(filter.toLowerCase()) === 0
        );
      });
    } else {
      return this.payments.slice();
    }
  }
  displayFnPayment(payment: Payment): string {
    return payment && payment.description ? payment.description : "";
  }
  resetPayment() {
    this.paymentInput.nativeElement.value = "";
    this.paymentControl.setValue(null);
    this.lastFilter = "";
    /* this.filteredPayments = this.paymentControl.valueChanges.pipe(
      startWith<string | Payment[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(filter => this.filterPayment(filter))
    );*/
    this.autoCompletePayments(null);
  }
  resetPaymentBlur() {
    this.paymentInput.nativeElement.value = "";
    this.paymentControl.setValue(null);
  }
  optionClickedPayment(event: Event, payment: Payment) {
    this.paymentInput.nativeElement.value = "";
    this.paymentControl.setValue(null);
    event.stopPropagation();
    this.toggleSelectionPayment(payment);
  }
  optionClickedMobilePayment(event: Event, payment: Payment) {
    this.paymentInput.nativeElement.value = "";
    this.paymentControl.setValue(null);
    event.stopPropagation();
    this.toggleSelectionTempPayment(payment);
  }
  toggleSelectionTempPayment(payment: Payment) {
    payment.selected = !payment.selected;
    if (payment.selected) {
      this.mobileTempSelectedPayments.push(payment);
    } else {
      this.payments.forEach((element) => {
        if (payment.id === element.id) {
          element.selected = false;
        }
      });
      const i = this.mobileTempSelectedPayments.findIndex(
        (value) =>
          value.description === payment.description && value.id === payment.id
      );
      this.mobileTempSelectedPayments.splice(i, 1);
    }
  }
  saveSelectedPayments() {
    this.selectedPayments = [];
    this.mobileTempSelectedPayments.forEach((element) => {
      this.selectedPayments.push(element);
    });
  }
  showPaymentsPopUp() {
    if (this.dataService.mobileView) {
      this.mobileTempSelectedPayments = [];
      this.showPaymentsAutoCompletePopUp = true;
      this.dataService.disableBodyScroll();
      this.selectedPayments.forEach((element, index) => {
        this.mobileTempSelectedPayments.push(element);
        this.mobileTempSelectedPayments[index].selected = true;
      });
      setTimeout(() => {
        const input = document.getElementById(
          "paymentInput"
        ) as HTMLInputElement;
        input.focus();
        input.select();
      }, 200);
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("members-popup");
      return;
    }
  }
  hidePopUp() {
    this.showTeamAutoCompletePopUp = false;
    this.showPolicyAutoCompletePopUp = false;
    this.showPaymentsAutoCompletePopUp = false;
    this.showWalletsAutoCompletePopUp = false;
    this.dataService.enableBodyScroll();
    this.mobileTempSelectedPayments.forEach((element) => {
      element.selected = false;
    });
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("members-popup");
  }
  showPolicyPopUp() {
    this.showPolicyAutoCompletePopUp = true;
    this.dataService.disableBodyScroll();
    if (this.selectedPolicy) {
      this.policyControl.setValue(this.selectedPolicy);
    }
    setTimeout(() => {
      const input = document.getElementById("policyInput") as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }
  showTeamPopUp() {
    this.showTeamAutoCompletePopUp = true;
    this.dataService.disableBodyScroll();
    if (this.selectedTeam) {
      this.teamControl.setValue(this.selectedTeam);
    }
    setTimeout(() => {
      const input = document.getElementById("teamInput") as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }
  cancelEditUserPayments() {
    this.selectedPayments = [];

    if (this.dataService.companyUserInfo.companyCards) {
      this.dataService.companyUserInfo.companyCards.forEach((userPayment) => {
        this.payments.forEach((element) => {
          element.selected = false;
          if (userPayment.id === element.id) {
            element.selected = true;
            this.selectedPayments.push(
              new Payment(element.description, element.id, true)
            );
          }
        });
      });
    } else {
      this.payments.forEach((element) => {
        element.selected = false;
      });
    }
    this.editUserPayments = false;
  }
  saveEditUserPayments() {
    this.submittingPayment = true;
    let mainPaymentsArray = [];
    let selectedPaymentsArray = [];
    let addPayments = [];
    let removePayments = [];

    if (this.dataService.companyUserInfo.companyCards) {
      this.dataService.companyUserInfo.companyCards.forEach((element) => {
        mainPaymentsArray.push(element.id);
      });
    }
    this.selectedPayments.forEach((element) => {
      selectedPaymentsArray.push(element.id);
    });
    selectedPaymentsArray.forEach((selected) => {
      if (!mainPaymentsArray.includes(selected)) {
        addPayments.push(selected);
      }
    });
    mainPaymentsArray.forEach((main) => {
      if (!selectedPaymentsArray.includes(main)) {
        removePayments.push(main);
      }
    });
    if (addPayments.length) {
      let add = "";
      addPayments.forEach((id, index) => {
        if (index === 0) {
          add = encodeURIComponent(id);
        } else {
          add += "&paymentMethodIds=" + encodeURIComponent(id);
        }
      });
      this.dataService.addPaymentsToUser(this.userId, add).subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.dataService.getCompanyUserInfo(this.userId);
            this.editUserPayments = false;
            this.submittingPayment = false;
          } else {
            // this.dataService.messages(this.data.message, 'error');
            this.errorMessagePay = this.data.message;
            this.submittingPayment = false;
          }
        },
        (error) => {
          //  this.dataService.messages(environment.messages.error, 'error');
          this.errorMessagePay = environment.messages.error;
          this.submittingPayment = false;
        }
      );
    }
    if (removePayments.length) {
      let remove = "";
      removePayments.forEach((id, index) => {
        if (index === 0) {
          remove += encodeURIComponent(id);
        } else {
          remove += "&paymentMethodIds=" + encodeURIComponent(id);
        }
      });
      this.dataService.removePaymentsFromUser(this.userId, remove).subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.dataService.getCompanyUserInfo(this.userId);
            this.editUserPayments = false;
            this.submittingPayment = false;
          } else {
            //  this.dataService.messages(this.data.message, 'error');
            this.errorMessagePay = this.data.message;
            this.submittingPayment = false;
          }
        },
        (error) => {
          //  this.dataService.messages(environment.messages.error, 'error');
          this.errorMessagePay = environment.messages.error;
          this.submittingPayment = false;
        }
      );
    }
    if (addPayments.length === 0 && removePayments.length === 0) {
      this.editUserPayments = false;
      this.submittingPayment = false;
    }
  }
  /* getUserTeam() {
      this.dataService.getTeamDetails(this.dataService.companyUserInfo.membership.companyMembership.teamId).subscribe(
        dataTeam => {
          this.dataTeam = dataTeam;
          if (this.dataTeam.errorCode === 0) {
            this.dataService.teamInfo = this.dataTeam.data;
            this.selectedUserTeam = this.dataService.teamInfo;
            this.usersEditForm.get('teamName').setValue(this.selectedUserTeam.id);
          } else {
            this.dataService.messages(this.dataTeam.message, 'error');
          }
        }, error => {
          this.dataService.messages(environment.messages.error, 'error');
        }
      );
    }*/
  /*  getUserPolicy() {
  this.dataService.getPolicyDetails(this.dataService.companyUserInfo.membership.companyMembership.policyId).subscribe(
    dataPolicy => {
      this.dataPolicy = dataPolicy;
      if (this.dataPolicy.errorCode === 0) {
        this.selectedUserPolicy = this.dataPolicy.data;
        this.usersEditForm.get('policyName').setValue(this.selectedUserPolicy.policy.policyId);
      } else {
        this.dataService.messages(this.dataPolicy.message, 'error');
      }
    }, error => {
      this.dataService.messages(environment.messages.error, 'error');
    }
  );
}*/

  mobileFocus() {
    if (
      this.showPaymentsAutoCompletePopUp ||
      this.showTeamAutoCompletePopUp ||
      this.showPolicyAutoCompletePopUp ||
      this.showWalletsAutoCompletePopUp
    ) {
      setTimeout(() => {
        if (this.showPaymentsAutoCompletePopUp) {
          this.focusInputMobile = document.getElementById(
            "paymentInput"
          ) as HTMLInputElement;
        }
        if (this.showTeamAutoCompletePopUp) {
          this.focusInputMobile = document.getElementById(
            "teamInput"
          ) as HTMLInputElement;
        }
        if (this.showPolicyAutoCompletePopUp) {
          this.focusInputMobile = document.getElementById(
            "policyInput"
          ) as HTMLInputElement;
        }
        if (this.showWalletsAutoCompletePopUp) {
          this.focusInputMobile = document.getElementById(
            "walletInput"
          ) as HTMLInputElement;
        }
        this.focusInputMobile.focus();
        this.focusInputMobile.select();
      }, 200);
    }
  }
  removeUserPolicy() {
    this.policyLoader = true;
    this.dataService.removeUserPolicy(this.userId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.policyLoader = false;
          this.submitting = false;
          this.dataService.getCompanyUserInfo(this.userId);
          if (this.dataService.desktopView) {
            const input = document.getElementById(
              "policyInputHtml"
            ) as HTMLInputElement;
            input.blur();
          }
        } else {
          this.submitting = false;
          this.policyLoader = false;
          this.errorMessage = this.data.message;
        }
      },
      (error) => {
        this.submitting = false;
        // this.dataService.messages(environment.messages.error, 'error');
        this.policyLoader = false;
        this.errorMessage = environment.messages.error;
      }
    );
  }
  desktopPaymentFocus() {
    if (this.dataService.desktopView) {
      setTimeout(() => {
        this.focusInputDesktop = document.getElementById(
          "desktopPaymentInput"
        ) as HTMLInputElement;
        this.focusInputDesktop.focus();
        this.focusInputDesktop.select();
      }, 200);
    }
  }
  desktopTeamFocus() {
    if (this.dataService.desktopView) {
      setTimeout(() => {
        this.teamControl.setValue("");
        this.editUserTeam = true;
        this.applyBlur = true;
      }, 300);
    }
  }
  desktopPolicyFocus() {
    if (this.dataService.desktopView) {
      setTimeout(() => {
        this.policyControl.setValue("");
        this.editTeamPolicy = true;
        this.applyBlur = true;
      }, 300);
    }
  }
  confirmAdminChange() {
    this.dataService.isAdmin = !this.dataService.isAdmin;
    const obj = {
      userId: this.userId,
      userName:
        this.dataService.companyUserInfo.firstName +
        " " +
        this.dataService.companyUserInfo.lastName,
    };
    if (this.dataService.desktopView) {
      if (this.dataService.isAdmin === true) {
        this.dataService.setModal("Set as admin", "addAdmin", obj);
      } else if (this.dataService.isAdmin === false) {
        this.dataService.setModal("Remove admin", "removeAdmin", obj);
      }
    } else {
      if (this.dataService.isAdmin === true) {
        this.dataService.setBottomSheet("addAdmin", obj);
      } else if (this.dataService.isAdmin === false) {
        this.dataService.setBottomSheet("removeAdmin", obj);
      }
    }
  }
  editUser(fieldName) {
    this.policyLoader = false;
    // this.adminLoader = false;
    this.teamLoader = false;
    this.travelLoader = false;
    this.errorMessage = "";
    this.errorMessagePay = "";
    this.submitted = true;
    this.submitting = true;
    if (fieldName === "team") {
      this.teamLoader = true;
    } else if (fieldName === "policy") {
      this.policyLoader = true;
    } else if (fieldName === "admin") {
      //  this.adminLoader = true;
    } else if (fieldName === "travel") {
      this.travelLoader = true;
    }
    // this.userNewDetails = '?userId=' + this.userId + '&blanketApproval=' + this.travelApprovalValue;
    this.userNewDetails = "?userId=" + this.userId;
    if (this.selectedPolicy.policyId) {
      this.userNewDetails += "&policyId=" + this.selectedPolicy.policyId;
    }
    if (this.selectedTeam.teamId && this.selectedTeam.teamId !== "0") {
      this.userNewDetails += "&teamId=" + this.selectedTeam.teamId;
    }
    if (this.dataService.isAdmin !== null && !this.auth.isOwner()) {
      this.userNewDetails += "&admin=" + this.dataService.isAdmin;
    }

    this.dataService.updateCompanyUser(this.userNewDetails).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.policyLoader = false;
          // this.adminLoader = false;
          this.teamLoader = false;
          this.travelLoader = false;
          this.submitting = false;
          this.dataService.getCompanyUserInfo(this.userId);
          if (this.dataService.desktopView) {
            const inputTeam = document.getElementById(
              "teamInputHtml"
            ) as HTMLInputElement;
            inputTeam.blur();
            const input = document.getElementById(
              "policyInputHtml"
            ) as HTMLInputElement;
            input.blur();
          }
        } else {
          this.submitting = false;
          this.policyLoader = false;
          // this.adminLoader = false;
          this.teamLoader = false;
          this.travelLoader = false;
          this.errorMessage = this.data.message;
        }
      },
      (error) => {
        this.submitting = false;
        this.policyLoader = false;
        // this.adminLoader = false;
        this.teamLoader = false;
        this.travelLoader = false;
        //   this.dataService.messages(environment.messages.error, 'error');
        this.errorMessage = environment.messages.error;
      }
    );
  }
  cancelEditTempPayments() {
    this.mobileTempSelectedPayments = [];
    this.payments.forEach((element) => {
      element.selected = false;
      this.selectedPayments.forEach((elementSelected) => {
        if (elementSelected.id === element.id) {
          element.selected = true;
        }
      });
    });
    this.selectedPayments.forEach((element, index) => {
      this.mobileTempSelectedPayments.push(element);
      this.mobileTempSelectedPayments[index].selected = true;
    });
  }
  disablePersonalPayments() {
    this.restrictUser = !this.restrictUser;
    this.paymentLoader = true;
    this.dataService
      .disablePersonalPayments(this.userId, this.restrictUser)
      .subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.paymentLoader = false;
            this.dataService.getCompanyUserInfo(this.userId);
          } else {
            this.paymentLoader = false;
            this.errorMessage = this.data.message;
          }
        },
        (error) => {
          this.paymentLoader = false;
          this.errorMessage = environment.messages.error;
        }
      );
  }
  /* formatDate(date) {
    return ' ' + moment(date).format('MMMM') + ' ' + moment(date).format('DD') + ', ' + moment(date).format('YYYY');
  }*/

  //Wallets Methods
  showWalletsPopUp() {
    if (this.dataService.mobileView) {
      this.mobileTempSelectedWallets = [];
      this.showWalletsAutoCompletePopUp = true;
      this.dataService.disableBodyScroll();
      this.selectedWallets.forEach((element, index) => {
        this.mobileTempSelectedWallets.push(element);
        this.mobileTempSelectedWallets[index].selected = true;
      });
      setTimeout(() => {
        const input = document.getElementById(
          "walletInput"
        ) as HTMLInputElement;
        input.focus();
        input.select();
      }, 200);
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("members-popup");
      return;
    }
  }

  desktopWalletFocus() {
    if (this.dataService.desktopView) {
      setTimeout(() => {
        this.focusInputDesktop = document.getElementById(
          "desktopWalletInput"
        ) as HTMLInputElement;
        this.focusInputDesktop.focus();
        this.focusInputDesktop.select();
      }, 200);
    }
  }

  optionClickedWallet(event: Event, wallet: Wallet) {
    this.walletInput.nativeElement.value = "";
    this.walletControl.setValue(null);
    event.stopPropagation();
    this.toggleSelectionWallet(wallet);
  }

  toggleSelectionWallet(wallet: Wallet) {
    wallet.selected = !wallet.selected;
    if (wallet.selected) {
      this.selectedWallets.push(wallet);
    } else {
      this.wallets.forEach((element) => {
        if (wallet.id === element.id) {
          element.selected = false;
        }
      });
      const i = this.selectedWallets.findIndex(
        (value) =>
          value.description === wallet.description && value.id === wallet.id
      );
      this.selectedWallets.splice(i, 1);
    }
  }

  resetWallet() {
    this.walletInput.nativeElement.value = "";
    this.walletControl.setValue(null);
    this.lastFilterWallet = "";
    this.walletsAutoComplete();
  }

  resetWalletBlur() {
    this.walletInput.nativeElement.value = "";
    this.walletControl.setValue(null);
  }

  displayFnWallet(wallet: Wallet): string {
    return wallet && wallet.description ? wallet.description : "";
  }

  cancelEditUserWallets() {
    this.selectedWallets = [];

    if (this.userWallets) {
      this.userWallets.forEach((userWallet) => {
        this.wallets.forEach((element) => {
          element.selected = false;
          if (userWallet.id === element.id) {
            element.selected = true;
            this.selectedWallets.push(
              new Wallet(element.description, element.id, true)
            );
          }
        });
      });
    } else {
      this.wallets.forEach((element) => {
        element.selected = false;
      });
    }
    this.editUserWallets = false;
  }

  getUserWallets() {
    this.walletManagementService.getUserWallets(this.userId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.userWallets = this.data.data;
          if (this.userWallets) {
            this.userWallets.forEach((element) => {
              if (element.name) {
                this.selectedWallets.push(
                  new Wallet(element.name, element.id, true)
                );
              } else {
                this.selectedWallets.push(
                  new Wallet(
                    element.description + " " + element.id,
                    element.id,
                    true
                  )
                );
              }
            });

            this.selectedWallets = this.selectedWallets.filter(
              (wallet, index, self) =>
                index === self.findIndex((w) => w.id === wallet.id)
            );
          }
          if (this.userId) {
            this.walletsAutoComplete();
          }
        } else {
          this.errorMessage = this.data.message;
        }
      },
      (error) => {
        this.errorMessage = environment.messages.error;
      }
    );
  }

  saveEditUserWallets() {
    this.submittingWallet = true;
    let mainWalletsArray = [];
    let selectedWalletsArray = [];
    let addWallets = [];
    let removeWallets = [];
    this.errorMessageWallet = "";

    if (!this.userWallets) {
      this.getUserWallets();
    }
    if (this.userWallets) {
      this.userWallets.forEach((element) => {
        mainWalletsArray.push(element.id);
      });
    }
    this.selectedWallets.forEach((element) => {
      selectedWalletsArray.push(element.id);
    });
    selectedWalletsArray.forEach((selected) => {
      if (!mainWalletsArray.includes(selected)) {
        addWallets.push(selected);
      }
    });
    mainWalletsArray.forEach((main) => {
      if (!selectedWalletsArray.includes(main)) {
        removeWallets.push(main);
      }
    });
    this.checkRemoveAddWallets(addWallets, removeWallets);
    if (addWallets.length === 0 && removeWallets.length === 0) {
      this.editUserWallets = false;
      this.submittingWallet = false;
    }
  }

  checkRemoveAddWallets(addWallets, removeWallets) {
    if (removeWallets.length && addWallets.length) {
      this.removeandAddWalletsUser(addWallets, removeWallets);
    } else if (addWallets.length && !removeWallets.length) {
      let add = "";
      addWallets.forEach((id, index) => {
        if (index === 0) {
          add = encodeURIComponent(id);
        } else {
          add += "&walletIds=" + encodeURIComponent(id);
        }
      });

      this.addWalletstoUser(add);
    } else if (!addWallets.length && removeWallets.length) {
      let remove = "";
      removeWallets.forEach((id, index) => {
        if (index === 0) {
          remove += encodeURIComponent(id);
        } else {
          remove += "&walletIds=" + encodeURIComponent(id);
        }
      });
      this.removeWalletsfromUser(remove);
    }
  }

  removeandAddWalletsUser(addWallets, removeWallets) {
    let remove = "";
    removeWallets.forEach((id, index) => {
      if (index === 0) {
        remove += encodeURIComponent(id);
      } else {
        remove += "&walletIds=" + encodeURIComponent(id);
      }
    });

    this.removeWalletsfromUser(remove);

    let add = "";
    addWallets.forEach((id, index) => {
      if (index === 0) {
        add = encodeURIComponent(id);
      } else {
        add += "&walletIds=" + encodeURIComponent(id);
      }
    });

    this.addWalletstoUser(add);
  }

  addWalletstoUser(add: any) {
    this.walletManagementService.addWalletsToUser(this.userId, add).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.getUserWallets();
          this.editUserWallets = false;
          this.submittingWallet = false;
        } else {
          this.errorMessageWallet = this.data.message;
          this.submittingWallet = false;
        }
      },
      (error) => {
        this.errorMessageWallet = environment.messages.error;
        this.submittingWallet = false;
      }
    );
  }

  removeWalletsfromUser(remove: any) {
    this.walletManagementService
      .removeWalletsFromUser(this.userId, remove)
      .subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.getUserWallets();
            this.editUserWallets = false;
            this.submittingWallet = false;
          } else {
            this.errorMessageWallet = this.data.message;
            this.submittingWallet = false;
          }
        },
        (error) => {
          this.errorMessageWallet = environment.messages.error;
          this.submittingWallet = false;
        }
      );
  }

  filterWallet(filter: string): Wallet[] {
    this.lastFilterWallet = filter;
    if (filter) {
      return this.wallets.filter((option) => {
        return (
          option.description.toLowerCase().indexOf(filter.toLowerCase()) === 0
        );
      });
    } else {
      return this.wallets.slice();
    }
  }

  optionClickedMobileWallet(event: Event, wallet: Wallet) {
    this.walletInput.nativeElement.value = "";
    this.walletControl.setValue(null);
    event.stopPropagation();
    this.toggleSelectionTempWallet(wallet);
  }

  toggleSelectionTempWallet(wallet: Wallet) {
    wallet.selected = !wallet.selected;
    if (wallet.selected) {
      this.mobileTempSelectedWallets.push(wallet);
    } else {
      this.wallets.forEach((element) => {
        if (wallet.id === element.id) {
          element.selected = false;
        }
      });
      const i = this.mobileTempSelectedWallets.findIndex(
        (value) =>
          value.description === wallet.description && value.id === wallet.id
      );
      this.mobileTempSelectedWallets.splice(i, 1);
    }
  }

  walletsAutoComplete() {
    this.walletManagementService.getActiveWalletList().subscribe((data) => {
      this.walletData = data;
      if (this.walletData.errorCode === 0) {
        this.companyWallets = this.walletData.data;
        this.getAllWalletFlag = true;
        this.wallets = [];
        this.companyWallets.forEach((element) => {
          if (element.name) {
            this.wallets.push(new Wallet(element.name, element.id));
          } else {
            this.wallets.push(new Wallet(element.description, element.id));
          }
        });

        if (this.userId || this.selectedWallets.length) {
          this.wallets.forEach((element) => {
            this.selectedWallets.forEach((elementSelected) => {
              if (elementSelected.id === element.id) {
                element.selected = true;
              }
            });
          });
        }

        this.filteredWallets = this.walletControl.valueChanges.pipe(
          startWith<string | Wallet[]>(""),
          map((value) =>
            typeof value === "string" ? value : this.lastFilterWallet
          ),
          map((filter) => this.filterWallet(filter))
        );
      }
    });
  }

  saveSelectedWallets() {
    this.selectedWallets = [];
    this.mobileTempSelectedWallets.forEach((element) => {
      this.selectedWallets.push(element);
    });
  }

  cancelEditTempWallets() {
    this.mobileTempSelectedWallets = [];
    this.wallets.forEach((element) => {
      element.selected = false;
      this.selectedWallets.forEach((elementSelected) => {
        if (elementSelected.id === element.id) {
          element.selected = true;
        }
      });
    });
    this.selectedWallets.forEach((element, index) => {
      this.mobileTempSelectedWallets.push(element);
      this.mobileTempSelectedWallets[index].selected = true;
    });
  }
}
