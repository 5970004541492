import { Component, OnInit } from '@angular/core';
import {AuthServiceMain} from '../../auth/auth.service';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';
import {environment} from "../../../environments/environment";
import {ApprovalsService} from "../../services/approvals.service";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-manage-workspace-popup',
  templateUrl: './manage-workspace-popup.component.html',
  styleUrls: ['./manage-workspace-popup.component.scss']
})
export class ManageWorkspacePopupComponent implements OnInit {

  constructor(public auth: AuthServiceMain,
              public dataService: DataService,
              public approvalsService: ApprovalsService,
              public router: Router,
              public userService: UserService) {
  }
  env = environment;
  ngOnInit(): void {
 /*   if (!this.auth.companyInfo) {
      this.auth.fetchCompanyData();
    }*/
    setTimeout( () => {

      if (this.auth.isLogedIn && !this.auth.companyInfo) {
        this.auth.fetchCompanyData();
      }

    }, 1000);
  }

  closeMenu() {
    this.dataService.manageWorkspaceMenuActive = false;
    this.dataService.landingWorkspaceMenuActive = false;
    this.dataService.userWorkspaceMenuActive = false;
    this.dataService.enableBodyScroll();
  }
}
