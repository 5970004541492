<div class="bg-white actions" *ngIf="dataService.editProfileImage" [ngClass]="{'position-absolute': dataService.desktopView}">
  <div class="font-14 pt-3 gray-text px-4" [ngClass]="{'border rounded actions-container ': dataService.desktopView}">
    <label class="mb-0" for="file1">
      <p class="pointer file1 mb-0" i18n="@@changeImage">Change Image</p>
    </label>
    <input type="file" name="file1" id="file1"  class="form-control"
           (change)=" onFileSelect($event)" accept="image/x-png,image/gif,image/jpeg"/>

    <hr/>
    <p class=" mb-0 mb-lg-3 pointer" (click)=" removeProfileImage()" i18n="@@removeImage">Remove Image</p>
  </div>
</div>
