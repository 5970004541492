import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PaymentValidationService {

    private bins = {
        mada: '446404|440795|440647|421141|474491|588845|968208|457997|457865|468540|468541|468542|468543|417633|446393|636120|968201|410621|409201|403024|458456|462220|968205|455708|484783|588848|455036|968203|486094|486095|486096|504300|440533|489318|489319|445564|968211|410685|406996|432328|428671|428672|428673|968206|446672|543357|434107|407197|407395|42689700|412565|431361|604906|521076|588850|968202|529415|535825|543085|524130|554180|549760|968209|524514|529741|537767|535989|536023|513213|442463|520058|558563|605141|968204|422817|422818|422819|410834|428331|483010|483011|483012|589206|968207|406136|419593|439954|407520|530060|531196|420132|242030|22402030',
        meeza: '507803[0-6][0-9]|507808[3-9][0-9]|507809[0-9][0-9]|507810[0-2][0-9]'
    };

    private apsPaymentErrors = {
        invalid_card: $localize`:@@invalidCard:Invalid Card`,
        invalid_card_length: $localize`:@@invalidCardLength:Invalid Card Length`,
        card_empty: $localize`:@@cardNumberEmpty:Card Number Empty`,
        invalid_card_holder_name: $localize`:@@invalidCardHolderName:Invalid Card Holder Name`,
        invalid_card_cvv: $localize`:@@invalidCardCVV:Invalid Card CVV`,
        invalid_expiry_date: $localize`:@@invalidExpiryDate:Invalid Expiry Date`,
        invalid_expiry_year: $localize`:@@invalidExpiryYear:Invalid Expiry Year`,
        invalid_expiry_month: $localize`:@@invalidExpiryMonth:Invalid Expiry Month`
    };

    validateCard(cardNumber: string) {
        let cardType = '';
        let cardValidity = true;
        let message = '';
        let cardLength = 0;

        if (cardNumber) {
            cardNumber = cardNumber.replace(/ /g, '').replace(/-/g, '');
            const visaRegex = new RegExp('^4[0-9]{0,15}$');
            const mastercardRegex = new RegExp('^5[0-5][0-9]{0,16}$|^2[2-7][0-9]{0,16}$');
            const amexRegex = new RegExp('^3$|^3[47][0-9]{0,13}$');
            const madaRegex = new RegExp('^' + this.bins.mada);
            const meezaRegex = new RegExp(this.bins.meeza, 'gm');

            if (cardNumber.match(madaRegex)) {
                cardType = 'mada';
                cardLength = 16;
            } else if (cardNumber.match(meezaRegex)) {
                cardType = 'meeza';
                cardLength = 19;
            } else if (cardNumber.match(visaRegex)) {
                cardType = 'visa';
                cardLength = 16;
            } else if (cardNumber.match(mastercardRegex)) {
                cardType = 'mastercard';
                cardLength = 16;
            } else if (cardNumber.match(amexRegex)) {
                cardType = 'amex';
                cardLength = 15;
            } else {
                cardValidity = false;
                message = this.apsPaymentErrors.invalid_card;
            }

            if (cardNumber.length < 15) {
                cardValidity = false;
                message = this.apsPaymentErrors.invalid_card_length;
            } else {
                const cardValidByAlgorithm = this.validateCardNumber(cardNumber);
                if (!cardValidByAlgorithm) {
                    cardValidity = false;
                    message = this.apsPaymentErrors.invalid_card;
                }
            }
        } else {
            message = this.apsPaymentErrors.card_empty;
            cardValidity = false;
        }

        return {
            cardType,
            validity: cardValidity,
            msg: message,
            cardLength
        };
    }

    validateCardNumberByAlgorithm(cardNumber: string) {
        let checksum = 0;
        let j = 1;

        for (let i = cardNumber.length - 1; i >= 0; i--) {
            let calc = 0;
            calc = Number(cardNumber.charAt(i)) * j;

            if (calc > 9) {
                checksum += 1;
                calc -= 10;
            }

            checksum += calc;

            j = (j === 1) ? 2 : 1;
        }

        return (checksum % 10) === 0;
    }

    validateCardNumber(cardNumber: string) {
        const regex = new RegExp("^[0-9]{13,19}$");
        if (!regex.test(cardNumber)) {
            return false;
        }
        return this.validateCardNumberByAlgorithm(cardNumber);
    }

    validateHolderName(cardHolderName: string) {
        let validity = true;
        let message = '';
        cardHolderName = cardHolderName.trim();

        if (cardHolderName.length > 51 || cardHolderName.length === 0) {
            validity = false;
            message = this.apsPaymentErrors.invalid_card_holder_name;
        }

        const regex = new RegExp("^[a-zA-Z- '.]+$");
        if (!regex.test(cardHolderName)) {
            validity = false;
            message = this.apsPaymentErrors.invalid_card_holder_name;
        }

        return {
            validity,
            msg: message
        };
    }

    validateCVV(cardCVV: string, cardType: string) {
        let validity = true;
        let message = '';

        if (cardCVV.length > 0) {
            cardCVV = cardCVV.trim();
            if (cardType.length) {
                if (cardCVV.length === 3 && cardCVV !== '000') {
                    validity = true;
                    message = '';
                } else if (cardCVV.length === 4 && cardType === 'amex' && cardCVV !== '0000') {
                    validity = true;
                    message = '';
                } else {
                    validity = false;
                    message = this.apsPaymentErrors.invalid_card_cvv;
                }
            } else {
                validity = false;
                message = this.apsPaymentErrors.invalid_card_cvv;
            }
        }

        return {
            validity,
            msg: message
        };
    }

    validateCardExpiry(cardExpiryMonth: string, cardExpiryYear: string) {
        let validity = true;
        let message = '';

        if (!cardExpiryMonth) {
            validity = false;
            message = this.apsPaymentErrors.invalid_expiry_month;
        } else if (!cardExpiryYear) {
            validity = false;
            message = this.apsPaymentErrors.invalid_expiry_year;
        } else if (parseInt(cardExpiryMonth) <= 0 || parseInt(cardExpiryMonth) > 12) {
            validity = false;
            message = this.apsPaymentErrors.invalid_expiry_month;
        } else {
            const curDate = new Date();
            const expDate = new Date(parseInt('20' + cardExpiryYear), parseInt(cardExpiryMonth) - 1, 30);
            if (expDate.getTime() < curDate.getTime()) {
                message = this.apsPaymentErrors.invalid_expiry_date;
                validity = false;
            }
        }

        return {
            validity,
            msg: message
        };
    }
}
