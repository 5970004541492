<div class="flight-booking-form">
  <h1
    class="green-text bolder mb-md-2 mb-3"
    [ngClass]="{ 'px-3': Service.mobileView }"
  >
    <i class="icon-locked"></i
    ><ng-container i18n="@@secureBooking">Secure Booking</ng-container
    >&nbsp;<span class="smaller"
      ><ng-container i18n="@@almostDone"
        >(You are almost done)</ng-container
      ></span
    >
  </h1>
  <h4 class="orange-text mb-2" [ngClass]="{ 'px-3': Service.mobileView }">
    <i class="icon-warning"></i>&nbsp;<ng-container i18n="@@actFast"
      >Act fast! Pricing and availability may change!</ng-container
    >
  </h4>

  <div *ngIf="Service.flightData && Service.mobileView">

    <!--------------flight summary here----------------->
    <app-flight-summary [flightSummary]="Service.flightData.details"></app-flight-summary>
    <!--------------flight summary here----------------->
  </div>

  <form
    [formGroup]="Service.flightBookingForm"
    (ngSubmit)="onSubmit()"
    id="bookingForm"
  >
    <div
      class="section-info mb-4 mt-4"
      [ngClass]="{ radius: Service.desktopView }"
    >
      <div class="section-header">
        <span class="primaray-text font-weight-bold"
          ><i class="icon-user"></i>&nbsp;<ng-container i18n="@@travelerDetails"
            >Traveler Details</ng-container
          ></span
        >
      </div>

      <div class="section-body p-5">
        <h5 class="bolder font-16">
          <ng-container i18n="@@traveler1Adult">Traveler 1: Adult</ng-container
          >&nbsp;<span class="font-14" i18n="@@primaryContact"
            >(Primary contact)</span
          >
        </h5>


        <div
          class="row gutters-10 mb-4"
          *ngIf="this.travelerId && this.travelerId !== 'guest'"
        >
          <div class="col-sm-6">
            <div class="d-flex align-items-center form-control">
              {{ traveler?.travelerName }}
            </div>
          </div>
        </div>
        <div
          class="row gutters-10"
          *ngIf="this.travelerId && this.travelerId === 'guest'"
        >
          <div
            class="form-group col-sm-6 col-md-6"
            [ngClass]="{
              'pr-lg-2': Service.isSiteLtr(),
              'pl-lg-2': Service.isSiteRtl()
            }"
          >
            <input
              pattern="^[A-Za-z -]+$"
              class="form-control"
              formControlName="FirstName"
              type="text"
              name="FirstName"
              i18n-placeholder="@@firstNamePlaceHolder"
              placeholder="First Name"
              autocomplete="new-password"
              [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }"
            />

            <div
              *ngIf="
                submitted && f.FirstName.errors && f.FirstName.errors.required
              "
              class="invalid-feedback"
              i18n="@@requiredFirstNameError"
            >
              First Name is required
            </div>
          </div>

          <div
            class="form-group col-sm-6 col-md-6"
            [ngClass]="{
              'pr-lg-2': Service.isSiteLtr(),
              'pl-lg-2': Service.isSiteRtl()
            }"
          >
            <input
              pattern="^[A-Za-z -]+$"
              class="form-control"
              formControlName="LastName"
              type="text"
              name="LastName"
              i18n-placeholder="@@lastNamePlaceHolder"
              placeholder="Last Name"
              autocomplete="new-password"
              [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }"
            />
            <div
              *ngIf="
                submitted && f.LastName.errors && f.LastName.errors.required
              "
              class="invalid-feedback"
              i18n="@@requiredLastNameError"
            >
              Last name is required
            </div>
          </div>
        </div>
        <div class="row gutters-10">
          <div class="col-sm-6">
            <p
              class="font-16 gray-text-dark mb-2 mt-3 required"
              i18n="@@emailAddress"
            >
              Email address
            </p>
            <div class="form-group with-icon">
              <input
                class="form-control"
                formControlName="email"
                type="text"
                name="email"
                autocomplete="new-password"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              />
              <i class="icon-email"></i>
            </div>
            <div
              *ngIf="submitted && f.email.errors"
              class="smaller text-danger"
            >
              <div *ngIf="f.email.errors.required" i18n="@@requiredEmailError">
                email is required
              </div>
              <div *ngIf="f.email.errors.email" i18n="@@invalidEmail">
                Invalid email
              </div>
            </div>
          </div>
        </div>

        <div class="row gutters-10">
          <div class="col-sm-6">
            <p
              class="font-16 gray-text-dark mb-2 mt-3 required"
              i18n="@@countryCode"
            >
              Country code
            </p>
            <div ngbDropdown class="form-group">
              <div
                ngbDropdownToggle
                class="form-control select-country-code clickable-cursor select-custom-arrow"
                (click)="this.Service.SelectCountryCode()"
              >
                {{ this.Service.defultCountry.code }} ({{
                  this.Service.defultCountry.dial_code
                }})
              </div>
              <div
                class="country-code-dropdown"
                *ngIf="this.Service.showCountryDrop"
                ngbDropdownMenu
              >
                <app-country-code></app-country-code>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <p
              class="font-16 gray-text-dark mb-2 mt-3 required"
              i18n="@@phoneNumber"
            >
              Phone number
            </p>
            <div class="form-group with-icon">
              <input
                class="form-control"
                formControlName="phone"
                pattern="[[\+]{0,1}(\d{0,20}|[\(][\+]{0,1}\d{2,}[\20)]*\d{5,20}|\d{2,6}[\-]{1}\d{2,20}[\-]*\d{3,20})]*"
                type="tel"
                name="phone"
                autocomplete="new-password"
                [ngClass]="{
                  'is-invalid': submitted && f.phone.errors,
                  'text-right': Service.isSiteRtl()
                  }"
              />
              <i class="icon-phone"></i>
            </div>
            <div
              *ngIf="submitted && f.phone.errors"
              class="smaller text-danger"
            >
              <div *ngIf="f.phone.errors.required" i18n="@@phoneNumberRequired">
                Phone number is required
              </div>
              <div
                *ngIf="f.phone.errors && !f.phone.errors.required"
                i18n="@@invalidPhoneNumber"
              >
                Invalid phone number
              </div>
            </div>
          </div>
        </div>
        <ng-container>
            <ng-container *ngIf="genderRequired !== false">
               <div class="row gutters-10">
              <div class="col-sm-6">
                <p class="font-16 gray-text-dark mb-2 mt-3 required"
                  i18n="@@gender">
                  Gender
                </p>
                <select
                  class="form-control mt-2 select-custom-arrow"
                  formControlName="gender"
                  name="gender"
                  [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                  <option value="{{ item.id }}" *ngFor="let item of vars.genderArray">
                    {{ item.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && f.gender.errors"
                  class="smaller text-danger">
                  <div *ngIf="f.gender.errors.required" i18n="@@genderRequired">
                    Gender is required
                  </div>
                </div>
              </div>
            </div>
            </ng-container>

            <ng-container *ngIf="dateOfBirthRequired !== false">
               <div class="row gutters-10">
              <div class="col-sm-6">
                <p class="font-16 gray-text-dark mb-2 mt-3"
                   [ngClass]="{'required': dateOfBirthRequired !== false}"
                   i18n="@@dob">
                  Date of birth
                </p>
                <div class="row date-of-birth">
                  <div class="col-5 position-relative">
                    <select
                      class="form-control select-custom-arrow"
                      formControlName="monthOfBirth"
                      name="months"
                      (change)="changeMonth()"
                      [ngClass]="{
                        'is-invalid': submitted && f.monthOfBirth.errors,
                        'placeholder-text': !selectedMonth}">
                      <option
                        class="placeholder-text"
                        value="null"
                        disabled
                        i18n="@@month">
                        Month
                      </option>
                      <option
                        value="{{ item.id }}"
                        *ngFor="let item of vars.monthsArray">
                        {{ item.name }}
                      </option>
                    </select>

                  </div>
                  <div class="col-3 px-0 position-relative">
                    <select
                      class="form-control select-custom-arrow"
                      formControlName="dayOfBirth"
                      name="days"
                      (change)="changeDay()"
                      [ngClass]="{
                        'is-invalid': submitted && f.dayOfBirth.errors,
                        'placeholder-text': !selectedDay
                      }">
                      <option
                        class="placeholder-text"
                        value="null"
                        disabled
                        i18n="@@day">
                        Day
                      </option>
                      <option value="{{ item.id }}" *ngFor="let item of vars.daysArray">
                        {{ item.id }}
                      </option>
                    </select>

                  </div>
                  <div class="col-4 position-relative">
                    <select
                      class="form-control select-custom-arrow"
                      formControlName="yearOfBirth"
                      name="years"
                      (change)="changeYear()"
                      [ngClass]="{
                        'is-invalid': submitted && f.yearOfBirth.errors,
                        'placeholder-text': !selectedYear
                      }"
                    >
                      <option
                        class="placeholder-text"
                        value="null"
                        disabled
                        i18n="@@year"
                      >
                        Year
                      </option>
                      <option value="{{ item }}" *ngFor="let item of vars.yearArray">
                        {{ item }}
                      </option>
                    </select>

                  </div>
                </div>
                <div
                  *ngIf="
                    submitted &&
                    ((f.monthOfBirth.errors && f.monthOfBirth.errors.required) ||
                      (f.dayOfBirth.errors && f.dayOfBirth.errors.required) ||
                      (f.yearOfBirth.errors && f.yearOfBirth.errors.required))
                  "
                  class="smaller text-danger"
                  i18n="@@dobRequired"
                >
                  Date of birth is required
                </div>
                <p class="smaller text-danger">{{ bodErrorMsg }}</p>
              </div>
            </div>
            </ng-container>
          <div class="row gutters-10">
            <div class="col-sm-6">
              <p
                      class="font-16 gray-text-dark mb-2 mt-3 required"
                      i18n="@@nationality"
              >
                Nationality
              </p>
              <div class="form-type-select form-name-countrycode">

                <select
                        name="nationality"
                        formControlName="nationality"
                        class="form-control select-custom-arrow placeholder-text-field"
                        [ngClass]="{
                          'is-invalid': submitted && f.nationality.errors
                        }"
                >

                  <option
                          *ngFor="let country of vars.countries; let i = index"
                          [value]="country.code"
                  >
                    {{ country.name }}
                  </option>
                </select>
                <div
                        *ngIf="submitted && f.nationality.errors"
                        class="smaller text-danger"
                >
                  <div
                          *ngIf="f.nationality.errors.required"
                          i18n="@@nationalityRequired"
                  >
                    Nationality is required
                  </div>
                </div>
              </div>
            </div>
          </div>
            <ng-container *ngIf="this.documentRequired">


                <div class="row gutters-10">
                  <div class="col-sm-6">
                    <p
                      class="font-16 gray-text-dark mb-2 mt-3 required"
                      i18n="@@passportCountry"
                    >
                      Passport issuing country
                    </p>
                    <div class="form-type-select form-name-countrycode">

                      <select
                        name="countryCode"
                        formControlName="passportIssueCountryCode"
                        class="form-control select-custom-arrow placeholder-text-field"
                        (change)="passportCountryChange($event)"
                        [ngClass]="{
                          'is-invalid': submitted && f.passportIssueCountryCode.errors
                        }"
                      >

                        <option
                          *ngFor="let country of vars.countries; let i = index"
                          [value]="country.code"
                        >
                          {{ country.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted && f.passportIssueCountryCode.errors"
                        class="smaller text-danger"
                      >
                        <div
                          *ngIf="f.passportIssueCountryCode.errors.required"
                          i18n="@@passportCountryRequired"
                        >
                          Passport issuing country is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row gutters-10">
                  <div class="col-sm-6">
                    <p
                      class="font-16 gray-text-dark mb-2 mt-3 required"
                      i18n="@@passportNumber">
                      Passport Number
                    </p>

                    <input
                      class="form-control"
                      formControlName="passportNumber"
                      type="text"
                      name="passportNumber"
                      [ngClass]="{ 'is-invalid': submitted && f.passportNumber.errors }"
                      autocomplete="off"
                    />

                    <div
                      *ngIf="submitted && f.passportNumber.errors"
                      class="smaller text-danger"
                    >
                      <div
                        *ngIf="f.passportNumber.errors.required"
                        i18n="@@passportNumberRequired"
                      >
                        Passport number is required
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <p
                      class="font-16 gray-text-dark mb-2 mt-3 required"
                      i18n="@@passportExpiryDate" >
                      Passport expiry date
                    </p>


                    <div
                      class="form-control position-relative"
                      [ngClass]="{ 'is-invalid': submitted && f.passportExpiry.errors }"
                    >
                      <input
                        formControlName="passportExpiry"
                        matInput
                        [matDatepicker]="picker"
                        [min]="minDate"
                        class="form-control pointer"
                        autocomplete="off"
                        (click)="picker.open()"
                        (dateChange)="formatExpiryDate('change', $event)"
                        style="opacity: 0"
                      />
                      <span
                        class="position-absolute format-date"
                        (click)="picker.open()"
                        >{{ formatExpiryDateText }}</span
                      >
                      <mat-datepicker #picker></mat-datepicker>
                      <!--    </mat-form-field>-->
                    </div>
                    <div
                      *ngIf="submitted && f.passportExpiry.errors"
                      class="smaller text-danger"
                    >
                      <div
                        *ngIf="f.passportExpiry.errors.required"
                        i18n="@@passportExpiryDateRequired"
                      >
                        Passport expiry date is required
                      </div>
                    </div>
                  </div>
                </div>
            </ng-container>
        </ng-container>
        <div class="row gutters-10">
          <div class="col-sm-6">
            <p
              class="font-16 gray-text-dark mb-2 mt-3"
              i18n="@@flyerProgramOne"
            >
              Frequent flyer program
            </p>
            <select
              class="form-control mt-2 select-custom-arrow mb-3"
              formControlName="flyerProgram"
              name="flyerProgram"
              (change)="programChange($event)"
              (focus)="getFrequentFlyerPrograms()"
            >
              <option [value]="item.code" *ngFor="let item of programsList">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row gutters-10">
          <div class="col-sm-6">
            <p class="font-16 gray-text-dark mb-2 mt-3" i18n="@@programNumber">
              Program number
            </p>

            <input
              class="form-control"
              formControlName="programNumber"
              name="programNumber"
              [ngClass]="{ 'is-invalid': submitted && f.programNumber.errors }"
            />
          </div>
        </div>
        <div
          class="border-top pt-5 mt-5"
          *ngFor="
            let item of [].constructor(otherAdultsTravelers);
            let i = index
          "
        >
          <h5 class="bolder font-16" i18n="@@travelerXAdult">
            Traveler {{ i + 2 }}: Adult
          </h5>
          <p class="font-14 gray-text" i18n="@@flightBookingMsgPassport">
            Traveler names must match government-issued photo ID exactly.
          </p>
          <div class="row gutters-10">
            <div
              class="form-group col-sm-6 col-md-6"
              [ngClass]="{
                'pr-lg-2': Service.isSiteLtr(),
                'pl-lg-2': Service.isSiteRtl()
              }"
            >
              <p
                class="font-16 gray-text-dark mb-2 mt-3 required"
                i18n="@@firstNamePlaceHolder"
              >
                First name
              </p>
              <input
                pattern="^[A-Za-z -]+$"
                class="form-control"
                formControlName="FirstName{{ i }}"
                autocomplete="off"
                type="text"
                name="FirstName{{ i }}"
                [ngClass]="{
                  'is-invalid': submitted && !isValid('FirstName' + i)
                }"
              />
              <div
                *ngIf="submitted && !isValid('FirstName' + i)"
                class="smaller text-danger"
                i18n="@@requiredFirstNameError"
              >
                First Name is required
              </div>
            </div>

            <div
              class="form-group col-sm-6 col-md-6"
              [ngClass]="{
                'pr-lg-2': Service.isSiteLtr(),
                'pl-lg-2': Service.isSiteRtl()
              }"
            >
              <p
                class="font-16 gray-text-dark mb-2 mt-3 required"
                i18n="@@lastNamePlaceHolder"
              >
                Last name
              </p>
              <input
                pattern="^[A-Za-z -]+$"
                class="form-control"
                formControlName="LastName{{ i }}"
                type="text"
                name="LastName{{ i }}"
                [ngClass]="{
                  'is-invalid': submitted && !isValid('LastName' + i)
                }"
              />
              <div
                *ngIf="submitted && !isValid('LastName' + i)"
                class="smaller text-danger"
                i18n="@@requiredLastNameError"
              >
                Last name is required
              </div>
            </div>
          </div>

          <div class="row gutters-10">
            <div class="col-sm-6">
              <p
                class="font-16 gray-text-dark mb-2 mt-3 required"
                i18n="@@gender"
              >
                Gender
              </p>
              <select
                class="form-control mt-2 select-custom-arrow"
                formControlName="gender{{ i }}"
                name="gender{{ i }}"
                [ngClass]="{
                  'is-invalid': submitted && !isValid('gender' + i)
                }"
              >
                <option value="{{ item.id }}" *ngFor="let item of vars.genderArray">
                  {{ item.name }}
                </option>
              </select>
              <div
                *ngIf="submitted && !isValid('gender' + i)"
                class="smaller text-danger"
                i18n="@@genderRequired"
              >
                Gender is required
              </div>
            </div>
          </div>

          <div class="row gutters-10">
            <div class="col-sm-6">
              <p class="font-16 gray-text-dark mb-2 mt-3 required" i18n="@@dob">
                Date of birth
              </p>
              <div class="row date-of-birth">
                <div class="col-5 position-relative">
                  <select
                    class="form-control select-custom-arrow"
                    formControlName="monthOfBirth{{ i }}"
                    name="months{{ i }}"
                    (change)="adultsChangeMonth(i)"
                    [ngClass]="{
                      'is-invalid': submitted && !isValid('monthOfBirth' + i),
                      'placeholder-text': !adultsSelectedMonth[i]
                    }"
                  >
                    <option
                      class="placeholder-text"
                      value="null"
                      disabled
                      i18n="@@month"
                    >
                      Month
                    </option>
                    <option
                      value="{{ item.id }}"
                      *ngFor="let item of vars.monthsArray"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <span
                    *ngIf="!adultsSelectedMonth[i]"
                    class="gray-text position-absolute label-DOB"
                    i18n="@@month"
                    >Month</span
                  >
                </div>
                <div class="col-3 px-0 position-relative">
                  <select
                    class="form-control select-custom-arrow"
                    formControlName="dayOfBirth{{ i }}"
                    name="days{{ i }}"
                    (change)="adultsChangeDay(i)"
                    [ngClass]="{
                      'is-invalid': submitted && !isValid('dayOfBirth' + i),
                      'placeholder-text': !adultsSelectedDay[i]
                    }"
                  >
                    <option
                      class="placeholder-text"
                      value="null"
                      disabled
                      i18n="@@day"
                    >
                      Day
                    </option>
                    <option
                      value="{{ item.id }}"
                      *ngFor="let item of vars.daysArray"
                    >
                      {{ item.id }}
                    </option>
                  </select>
                  <span
                    *ngIf="!adultsSelectedDay[i]"
                    class="gray-text position-absolute label-DOB day"
                    i18n="@@day"
                    >Day</span
                  >
                </div>
                <div class="col-4 position-relative">
                  <select
                    class="form-control select-custom-arrow"
                    formControlName="yearOfBirth{{ i }}"
                    name="years{{ i }}"
                    (change)="adultsChangeYear(i)"
                    [ngClass]="{
                      'is-invalid': submitted && !isValid('yearOfBirth' + i),
                      'placeholder-text': !adultsSelectedYear[i]
                    }"
                  >
                    <option
                      class="placeholder-text"
                      value="null"
                      disabled
                      i18n="@@year"
                    >
                      Year
                    </option>
                    <option value="{{ item }}" *ngFor="let item of vars.yearArray">
                      {{ item }}
                    </option>
                  </select>
                  <span
                    *ngIf="!adultsSelectedYear[i]"
                    class="gray-text position-absolute label-DOB year"
                    i18n="@@year"
                    >Year</span
                  >
                </div>
              </div>
              <div
                *ngIf="
                  submitted &&
                  (!isValid('monthOfBirth' + i) ||
                    !isValid('dayOfBirth' + i) ||
                    !isValid('yearOfBirth' + i))
                "
                class="smaller text-danger"
                i18n="@@dobRequired"
              >
                Date of birth is required
              </div>
              <p class="smaller text-danger">{{ adultsDOBErrorMsg[i] }}</p>
            </div>
          </div>

          <div class="row gutters-10">
            <div class="col-sm-6">
              <p
                class="font-16 gray-text-dark mb-2 mt-3 required"
                i18n="@@passportCountry"
              >
                Passport issuing country
              </p>
              <div class="form-type-select form-name-countrycode">

                <select
                  name="countryCode{{ i }}"
                  formControlName="passportIssueCountryCode{{ i }}"
                  class="form-control select-custom-arrow placeholder-text-field"
                  (change)="passportCountryChange($event)"
                  [ngClass]="{
                    'is-invalid':
                      submitted && !isValid('passportIssueCountryCode' + i)
                  }"
                >
                  <ng-container
                    *ngFor="let country of vars.countries; let i = index"
                  >
                    <option [value]="country.code">{{ country.name }}</option>
                  </ng-container>
                </select>
                <div
                  *ngIf="submitted && !isValid('passportIssueCountryCode' + i)"
                  class="smaller text-danger"
                  i18n="@@passportCountryRequired"
                >
                  Passport issuing country is required
                </div>
              </div>
            </div>
          </div>

          <div class="row gutters-10">
            <div class="col-sm-6">
              <p
                class="font-16 gray-text-dark mb-2 mt-3 required"
                i18n="@@passportNumber"
              >
                Passport Number
              </p>

              <input
                class="form-control"
                formControlName="passportNumber{{ i }}"
                type="text"
                name="passportNumber{{ i }}"
                [ngClass]="{
                  'is-invalid': submitted && !isValid('passportNumber' + i)
                }"
                autocomplete="off"
              />

              <div
                *ngIf="submitted && !isValid('passportNumber' + i)"
                class="smaller text-danger"
                i18n="@@passportNumberRequired"
              >
                Passport number is required
              </div>
            </div>

            <div class="col-sm-6">
              <p
                class="font-16 gray-text-dark mb-2 mt-3 required"
                i18n="@@passportExpiryDate"
              >
                Passport expiry date
              </p>
              <div [formGroupName]="i">
                <!-- must use form group to make different date picker for each user --->
                <div
                  class="form-control position-relative"
                  [ngClass]="{
                    'is-invalid': submitted && !this.adultsExpiryDate[i]
                  }"
                >
                  <input
                    formControlName="adultsPassportExpiry"
                    matInput
                    [matDatepicker]="picker"
                    [min]="minDate"
                    class="form-control pointer"
                    autocomplete="off"
                    (click)="picker.open()"
                    (dateChange)="adultsFormatExpiryDate($event, i)"
                    style="opacity: 0"
                  />
                  <span class="position-absolute format-date">{{
                    adultFormatExpiryDateText[i]
                  }}</span>
                  <mat-datepicker #picker></mat-datepicker>
                  <!--    </mat-form-field>-->
                </div>
              </div>
              <div
                *ngIf="submitted && !this.adultsExpiryDate[i]"
                class="smaller text-danger"
                i18n="@@passportExpiryDateRequired"
              >
                Passport expiry date is required
              </div>
            </div>
          </div>

          <div class="row gutters-10">
            <div class="col-sm-6">
              <p
                class="font-16 gray-text-dark mb-2 mt-3"
                i18n="@@flyerProgramOne"
              >
                Frequent flyer program
              </p>
              <select
                class="form-control mt-2 select-custom-arrow mb-3"
                formControlName="flyerProgram{{ i }}"
                name="flyerProgram{{ i }}"
                (change)="programChange($event)"
                (focus)="getFrequentFlyerPrograms()"
              >
                <option [ngValue]="item.code" *ngFor="let item of programsList">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row gutters-10">
            <div class="col-sm-6">
              <p
                class="font-16 gray-text-dark mb-2 mt-3"
                i18n="@@programNumber"
              >
                Program number
              </p>

              <input
                class="form-control"
                formControlName="programNumber{{ i }}"
                pattern="[[\+]{0,1}(\d{0,20}|[\(][\+]{0,1}\d{2,}[\20)]*\d{5,20}|\d{2,6}[\-]{1}\d{2,20}[\-]*\d{3,20})]*"
                name="programNumber{{ i }}"
              />
            </div>
          </div>

          <div class="row gutters-10">
            <div
              class="form-group col-sm-6 col-md-6"
              [ngClass]="{
                'pr-lg-2': Service.isSiteLtr(),
                'pl-lg-2': Service.isSiteRtl()
              }"
            >
              <p class="font-16 gray-text-dark mb-2 mt-3" i18n="@@mealRequest">
                Meal request
              </p>
              <input
                class="form-control"
                formControlName="mealRequest{{ i }}"
                type="text"
                name="mealRequest{{ i }}"
              />
              <p class="font-12 gray-text" i18n="@@flightBookingSpecial">
                Upon availability. Please contact the airline to confirm special
                assistance requests.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-info mb-4" [ngClass]="{ radius: Service.desktopView }">
      <div class="section-header">
        <span class="primaray-text font-weight-bold"
          ><i class="icon-credit-card-alt"></i>&nbsp;<ng-container
            i18n="@@paymentDetails"
            >Payment Details</ng-container
          ></span
        >
      </div>

      <div class="section-body gray-text-dark p-5">
        <div class="row">
          <div
                  [ngClass]="{ 'custom-col-width': this.Service.sideNavState }"
                  class="col-md-7 col7-custom"
          >
            <h5 i18n="@@choosePaymentMethod">Please choose a payment method</h5>

            <div class="form-type-radio">
              <label class="radio-inline" for="new_card_payment_method">
                <input
                        (change)="onItemChange($event)"
                        checked="checked"
                        class="form-checkbox"
                        id="new_card_payment_method"
                        name="payment_method"
                        type="radio"
                        value="new_card"
                />
                <span i18n="@@creditDebitCard">Credit / Debit Card </span></label
              >
            </div>

            <select
                    (focus)="callSavedCardsMethod()"
                    (change)="savedCardChange()"
                    [(ngModel)]="selectedCard"
                    [ngClass]="{
              'is-invalid': noSelectedCard,
                'd-block':
                  this.Auth.isLogedIn &&
                  paymentType === 'new_card'
              }"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control mb-3 d-none"
            >
              <option *ngIf="(savedCardList | async)?.length"
                      i18n="@@selectPaymentCard"
                      value="selectPayment"
                      disabled
              >
                Select payment card
              </option>
              <ng-container *ngFor="let method of savedCardList | async; let i = index">
                <option *ngIf="method.primary === false" [value]="method.token">
                  {{ method.alias }} - {{ method.brand }} <ng-container i18n="@@endsWith">ending with</ng-container>
                  {{ method.lastFour }}
                </option>
              </ng-container>
              <ng-container
                      *ngFor="let method of savedCardList | async; let i = index"
              >
                <option
                        *ngIf="method.primary === true"
                        [ngValue]="method.token"
                        [selected]="true"
                >
                  {{ method.alias }} - {{ method.brand }} <ng-container i18n="@@endsWith">ending with</ng-container>
                  {{ method.lastFour }}
                </option>
              </ng-container>
              <option *ngIf="!(savedCardList | async)?.length"
                      i18n="@@noPaymentMethod"
                      value="noPayment"
                      disabled
              >
                You don’t have payment method
              </option>
              <option *ngIf="!Service.isSafari()"
                      [ngClass]="{
                  'd-none':
                    this.travelerId &&
                    this.travelerId !== 'guest' &&
                    this.traveler?.personalPaymentMethodsDisabled
                }"
                      class="blue-text"
                      i18n="@@newPaymentMethod"
                      value="new">
                Add new payment method
              </option>
            </select>

            <a *ngIf="Service.isSafari()"
               href="/user/payment"
               target="_blank"
               i18n="@@newPaymentMethod">Add new payment method</a>

            <div *ngIf="personalCardSelected | async"
                 class="mt-2">
              <div class="form-group">
                <label i18n="@@securityCode">
                  CVV
                </label>
                <input  maxlength="4"
                        type="text"
                        name="cvv"
                        formControlName="cvv"
                        class="form-control w-33"
                        placeholder=""
                        autocomplete="off"
                        [ngClass]="{ 'is-invalid': inValidCVV || f.cvv.errors }"
                />
              </div>

            </div>
            <div
                    [ngClass]="{ 'd-block': paymentType !== 'new_card' }"
                    class="paypal-text mt-2 d-none"
                    i18n="@@makeSurePaymDetails">
              Make sure to fill all the details then press the checkout button at
              the bottom, you will be redirected to complete the payment. After
              you complete, you will come back to SafarHub to view your booking
              confirmation.
            </div>
          </div>

          <div
                  [ngClass]="{
            'border-left': Service.isSiteLtr(),
            'border-right': Service.isSiteRtl(),
            'custom-col-width': this.Service.sideNavState
          }"
                  class="col-md-5 col5-custom border-sm-0">
            <app-accepted-cards-section></app-accepted-cards-section>
          </div>

        </div>
      </div>




    </div>

    <app-flight-terms [flightPricing]="Service.flightPricing"></app-flight-terms>

    <div *ngIf="Service.flightData && Service.mobileView" class="container mb-4">

      <!-----------here price summay---------->
      <app-flight-pricing [pricing]="Service.flightPricing"></app-flight-pricing>
      <!-----------here price summay---------->

        <app-cost-reporting-manage></app-cost-reporting-manage>


<!--      <div-->
<!--        class="white-box p-0"-->
<!--        *ngIf="-->
<!--          this.Service.flightData.details.userData &&-->
<!--          this.Service.flightData.details.userData.rewardAmount-->
<!--        "-->
<!--      >-->
<!--        <div-->
<!--          class="white-box-purple-header p-3 rounded-top text-white font-18 font-weight-bold"-->
<!--        >-->
<!--          <ng-container i18n="@@rewards">Rewards</ng-container>:-->
<!--          <ng-container *ngIf="Service.isSiteLtr()">$</ng-container-->
<!--          >{{-->
<!--            this.Service.flightData.details.userData.rewardAmount-->
<!--              | number : "1.2-2"-->
<!--          }}<ng-container *ngIf="Service.isSiteRtl()">$</ng-container>-->
<!--        </div>-->
<!--        <div-->
<!--          class="px-3 pt-3 pb-4 gray-primaray-text font-16"-->
<!--          i18n="@@uponCompletingFlightBooking"-->
<!--        >-->
<!--          You will earn ${{-->
<!--            this.Service.flightData.details.userData.rewardAmount-->
<!--              | number : "1.2-2"-->
<!--          }}-->
<!--          upon completing this trip-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div [ngClass]="{ 'px-3': Service.mobileView }">
      <div class="form-action">
        <div class="hotel-details-side d-block d-md-none"></div>

        <div
          class="book_button_container"
          [ngClass]="{
            'd-block':
              !requiresApprovalData
          }"
        >
          <div class="row">
            <div class="col-md-4" *ngIf="Service.flightBookingForm">
              <div
                class="d-none"
                [ngClass]="{ 'd-block': paymentType === 'new_card' }"
              >
                <button
                  class="green_btn btn-block book-btn"
                  [disabled]="
                    this.Service.flightBookingSubmit ||
                    this.requiresApprovalData
                  "
                  [ngClass]="{ 'disabled-button': requiresApprovalData }"
                  i18n="@@confirmBooking"
                >
                  CONFIRM BOOKING
                </button>
              </div>


            </div>
          </div>

        </div>
      </div>

        <div
          class="border rounded mt-5 font-16 px-3 px-lg-5 pt-4 pt-lg-5 pb-4 gray-text-dark"
          *ngIf="
            requiresApprovalData &&
            requiresApprovalData.limitExceeded &&
            !submittedApproval
          "
        >
          <p>
            <ng-container i18n="@@approvalMsgFlight1"
              >This booking exceeds your travel policy. Approval request will be
              sent to</ng-container
            >&nbsp;<span class="font-weight-bold"
              >{{ requiresApprovalData.firstName }}
              {{ requiresApprovalData.lastName }}</span
            ><ng-container i18n="@@approvalMsgFlight2"
              >. Your flight will be automatically booked once
              approved.</ng-container
            >
          </p>

          <p class="bold-600 mt-4 mb-2" *ngIf="Auth.userInfo">
            <ng-container i18n="@@travelPolicy">Travel Policy</ng-container
            >&nbsp;
            <span class="font-weight-normal font-14"
              >({{ Auth.userInfo.effectivePolicyName }})</span
            >
          </p>

          <ng-container
            *ngIf="
              this.rate &&
              this.rate.details &&
              this.rate.details.travelerPricings[0].userData &&
              !this.rate.details.travelerPricings[0].userData.flightBookingNotAllowed
            "
          >
            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="
                  this.rate.details &&
                  this.rate.details.travelerPricings[0].userData &&
                  !this.rate.details.travelerPricings[0].userData.priceLimitPerPassengerViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="
                  this.rate.details.travelerPricings[0].userData.priceLimitPerPassengerViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <span i18n="@@maxPrice" class="font-16 gray-text"
                >Max price:</span
              >

              <span class="gray-text mb-1 px-2" *ngIf="requiresApprovalData"
                >{{requiresApprovalData.limitCurrency}} {{ requiresApprovalData.limit }}</span
              >
            </p>

            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="
                  this.rate.details &&
                  this.rate.details.travelerPricings[0].userData &&
                  !this.rate.details.travelerPricings[0].userData.maximumCabinClassViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="this.rate.details.travelerPricings[0].userData.maximumCabinClassViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>

              <span i18n="@@fareClass" class="font-16 gray-text"
                >Fare class</span
              >
            </p>

            <p
              class="mb-1 gray-text"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="
                  this.rate.details &&
                  this.rate.details.travelerPricings[0].userData &&
                  !this.rate.details.travelerPricings[0].userData.maximumNumberOfTripsViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="this.rate.details.travelerPricings[0].userData.maximumNumberOfTripsViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>

              <span i18n="@@maxNoOfTrips" class="font-16 gray-text"
                >Max trip</span
              >
            </p>
          </ng-container>
          <ng-container
            *ngIf="
              this.rate.details &&
              this.rate.details.travelerPricings[0].userData &&
              this.rate.details.travelerPricings[0].userData.flightBookingNotAllowed
            "
          >
            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="this.rate.details.travelerPricings[0].userData.flightBookingNotAllowed"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <span i18n="@@hotelBookingAllowed" class="font-16 gray-text"
                >Flight booking allowed</span
              >
            </p>
          </ng-container>

          <div
            class="col-sm-5 px-0 mt-5 min-width-220"
            [ngClass]="{
              'pl-lg-5 ml-auto': Service.isSiteLtr(),
              'pr-lg-5 mr-auto': Service.isSiteRtl()
            }"
          >
            <button
              class="blue_btn btn-block font-16 py-1 sharp-font"
              [ngClass]="{ 'm-progress': submittingApproval }"
              i18n="@@requestapproval"
            >
              Request Approval
            </button>
          </div>
        </div>
        <div
          class="border rounded mt-5 font-16 px-3 px-lg-5 pt-4 pt-lg-5 pb-4 gray-text-dark"
          *ngIf="
            requiresApprovalData &&
            !requiresApprovalData.limitExceeded &&
            !submittedApproval
          "
        >
          <p>
            <ng-container i18n="@@approvalMsg3"
              >Booking approval is required. Approval request will be sent
              to</ng-container
            >&nbsp;
            <span class="font-weight-bold"
              >{{ requiresApprovalData.firstName }}
              {{ requiresApprovalData.lastName }}</span
            ><ng-container i18n="@@approvalMsgFlight2"
              >. Your flight will be automatically booked once
              approved.</ng-container
            >
          </p>

          <p class="bold-600 mt-4 mb-2" *ngIf="Auth.userInfo">
            <ng-container i18n="@@travelPolicy">Travel Policy</ng-container
            >&nbsp;
            <span class="font-weight-normal font-14"
              >({{ Auth.userInfo.effectivePolicyName }})</span
            >
          </p>

          <ng-container
            *ngIf="
              this.rate &&
              this.rate.details &&
              this.rate.details.travelerPricings[0].userData &&
              !this.rate.details.travelerPricings[0].userData.flightBookingNotAllowed
            "
          >
            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="
                  !this.rate.details.travelerPricings[0].userData.priceLimitPerPassengerViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="
                  this.rate.details.travelerPricings[0].userData.priceLimitPerPassengerViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <span i18n="@@maxPrice" class="font-16 gray-text"
                >Max price:</span
              >

              <span class="gray-text mb-1 px-2" *ngIf="requiresApprovalData"
                >{{requiresApprovalData.limitCurrency}} {{ requiresApprovalData.limit }}</span
              >
            </p>

            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="!this.rate.details.travelerPricings[0].userData.maximumCabinClassViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="this.rate.details.travelerPricings[0].userData.maximumCabinClassViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>

              <span i18n="@@fareClass" class="font-16 gray-text"
                >Fare class</span
              >
            </p>

            <p
              class="mb-1 gray-text"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="!this.rate.details.travelerPricings[0].userData.maximumNumberOfTripsViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="this.rate.details.travelerPricings[0].userData.maximumNumberOfTripsViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>

              <span i18n="@@maxNoOfTrips" class="font-16 gray-text"
                >Max trip</span
              >
            </p>
          </ng-container>
          <ng-container
            *ngIf="
              this.rate.details.travelerPricings[0].userData &&
              this.rate.details.travelerPricings[0].userData.flightBookingNotAllowed
            "
          >
            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="
                  this.rate.details.travelerPricings[0].userData.flightBookingNotAllowed
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <span i18n="@@hotelBookingAllowed" class="font-16 gray-text"
                >Flight booking allowed</span
              >
            </p>
          </ng-container>

          <div
            class="col-sm-5 px-0 mt-5 min-width-220"
            [ngClass]="{
              'pl-lg-5 ml-auto': Service.isSiteLtr(),
              'pr-lg-5 mr-auto': Service.isSiteRtl()
            }"
          >
            <button
              class="blue_btn btn-block font-16 py-1 sharp-font"
              [ngClass]="{ 'm-progress': submittingApproval }"
              i18n="@@requestapproval"
            >
              Request Approval
            </button>
          </div>
        </div>



      <div class="text-danger font-16 pt-3" *ngIf="this.Service.errorMsg">{{ this.Service.errorMsg }}</div>
    </div>
  </form>
</div>
