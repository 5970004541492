
<div class="company-login main-container border-0 pb-5"
     [ngClass]="{'gray-pass-bg' : dataService.desktopView}"
    *ngIf="!(dataService.showFullLoginForm | async)">

  <div class="container pb-5" [ngClass]="{'desktop-design text-center': dataService.desktopView, 'mb-5 pt-5 pt-lg-2': dataService.mobileView}" *ngIf="!this.dataService.signInErrorMsg">
    <img width="471" height="422" loading="lazy" class="fixed-img rounded" src="assets/img/triphop-Signup-photo.jpg" *ngIf="dataService.desktopView">
          <div class="login-form workspace-registration-container px-lg-5 pt-5 corporate-box"
               [ngClass]="{'white-box position-relative' : dataService.desktopView, 'mx-auto': dataService.mobileView,
               'mr-auto': dataService.isSiteLtr(), 'ml-auto': dataService.isSiteRtl()}">
            <div class="text-center">
              <h4 class="font-24 pt-md-4 mb-3 font-weight-bold text-center" i18n="@@signIn1">
                Sign in to your company
              </h4>
            </div>
            <p class="gray-text font-16 pb-4 mb-3 text-center px-4 workspace-registration-container mx-auto" i18n="@@signIn2">Enter your work email address to access your workspace.</p>
            <app-company-login-form></app-company-login-form>

      </div>
  </div>

<app-login-error-page *ngIf="this.dataService.signInErrorMsg"></app-login-error-page>
</div>

<app-workspace-login *ngIf="(dataService.showFullLoginForm | async)"></app-workspace-login>
