
<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
                         [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <!--   <div class="gray-pass-bg border-0 py-3 pl-3" *ngIf="dataService.mobileView">
           <a class="font-18 blue-text font-18 primaray-text font-weight-bold" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);">Settings</a> <span class="font-18 gray-text-dark"> / </span>

           <a class="font-18 blue-text font-18 primaray-text font-weight-bold" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/billing']);">Billing</a>
           <span class="font-18 gray-text-dark"> / Billing History </span>
         </div>-->

      <div class="container pt-5 pb-0 pb-lg-5">
        <div class="mx-lg-5">
          <!--    <div class="row"  *ngIf="dataService.desktopView">
                <div class="col">
                  <div class="pb-5 mb-3" *ngIf="dataService.desktopView">
                    <a class="font-18 blue-text text-underline" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);">Settings</a> <span class="font-18 gray-text-dark"> / </span>
                    <a class="font-18 blue-text text-underline" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/billing']);">Billing</a>
                    <span class="font-18 gray-text-dark"> / Billing History </span>
                  </div>
                </div>
              </div>-->
          <div [ngClass]="{'custom-container': dataService.desktopView}">
          <div class="row mb-5" >
            <div class="col-12 font-18 gray-primaray-text bold-600" i18n="@@myFavoriteHotels">My Favorite Hotels</div>
          </div>
            <app-favorites-control></app-favorites-control>
          </div>


        </div>
      </div>



    </div>
  </mat-sidenav-content>
</mat-sidenav-container>








