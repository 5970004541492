<div class="py-5" *ngIf="showData">
  <div class="voucher">
    <div class="container">
      <div class="voucher_top inline">
        <h2 class="primaray-text font-24 bolder mb-4">
          <i
            *ngIf="Service.isSiteLtr()"
            class="icon-left-arrow align-middle pr-2"
            (click)="back()"
          ></i>
          <i
            *ngIf="Service.isSiteRtl()"
            class="icon-right-arrow-cancel1 align-middle pl-2"
            (click)="back()"
          ></i>

          <ng-container i18n="@@cancelAt">Cancel your booking at</ng-container>
          {{ booking.hotelName }}
        </h2>
      </div>
    </div>
  </div>

  <div class="cancelForm container">
    <div class="row">
      <div class="col-md-8">
        <div class="section-info radius mb-3">
          <div class="section-header">
            <span
              class="primaray-text text-uppercase bolder font-16"
              i18n="@@bookingSummary"
              >BOOKING SUMMARY</span
            >
          </div>

          <div class="section-body gray-text-dark p-5">
            <div
              *ngIf="bookingType == 'cancelled' || bookingType == 'confirmed'"
            >
              <h4
                class="mt-0 mb-3 bolder font-16"
                i18n="@@cancellationPolicies"
              >
                Cancellation Policies
              </h4>
              <div>
                <div
                  class="pb-4 mb-4 border-bottom text-medium"
                  *ngIf="booking.refundable"
                >
                  <div
                    class="policy"
                    *ngFor="let policy of booking.cancellationPolicies"
                  >
                    {{ policy.description }}
                  </div>
                </div>
                <div
                  class="non-refundable"
                  *ngIf="!booking.refundable"
                  i18n="@@nonrefundable"
                >
                  Non-refundable
                </div>
              </div>
            </div>

            <div>
              <h4 class="mt-0 mb-3 bolder font-16" i18n="@@stayDetails">
                Stay Details
              </h4>
              <div class="pb-4 mb-4">
                <p>
                  <ng-container i18n="@@roomName">Room Name</ng-container>:
                  {{ booking.roomName }}
                </p>
                <p>
                  <ng-container i18n="@@confirmationNumber"
                    >Confirmation Number</ng-container
                  >
                  : {{ booking.referenceNumber }}
                </p>
                <p>
                  <ng-container i18n="@@checkInNoDash">Check in</ng-container>:
                  {{ checkin }}
                </p>
                <p>
                  <ng-container i18n="@@checkOutNoDash">Check out</ng-container
                  >: {{ checkout }}
                </p>
                <p>{{ booking.boardDescription }}</p>
                <div>
                  <ng-container i18n="@@guests">Guests</ng-container>:
                  {{ booking.paxes.roomInfos[0].numberOfAdults }}
                  <div class="d-inline text-lowercase">
                    <ng-container
                      *ngIf="booking.paxes.roomInfos[0].numberOfAdults === 1"
                      i18n="@@adult"
                      >adult</ng-container
                    >
                    <ng-container
                      *ngIf="booking.paxes.roomInfos[0].numberOfAdults === 2"
                      i18n="@@twoAdults"
                      >adults</ng-container
                    >
                    <ng-container
                      *ngIf="booking.paxes.roomInfos[0].numberOfAdults > 2"
                      i18n="@@adults"
                      >adults</ng-container
                    >
                  </div>
                  <div
                    *ngIf="
                      booking.paxes.roomInfos[0].childAges &&
                      booking.paxes.roomInfos[0].childAges.length > 0
                    "
                  >
                    ,
                    {{ booking.paxes.roomInfos[0].childAges.length }}
                    <div class="d-inline text-lowercase">
                      <ng-container
                        *ngIf="
                          booking.paxes.roomInfos[0].childAges.length === 1
                        "
                        i18n="@@child"
                        >child</ng-container
                      >
                      <ng-container
                        *ngIf="
                          booking.paxes.roomInfos[0].childAges.length === 2
                        "
                        i18n="@@twoChildren"
                        >children</ng-container
                      >
                      <ng-container
                        *ngIf="booking.paxes.roomInfos[0].childAges.length > 2"
                        i18n="@@children"
                        >children</ng-container
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-block d-md-none">
              <div class="pb-4 mb-4 border-bottom">
                <h4 class="mt-0 mb-3 bolder font-18" i18n="@@priceSummary">
                  Price Summary
                </h4>

                <ng-container *ngIf="!booking.dailyRates">
                  <div class="daily" *ngFor="let daily of booking.dailtyRates">
                    <div class="d-flex pb-1">
                      <span
                        [ngClass]="{
                          'mr-auto': Service.isSiteLtr(),
                          'ml-auto': Service.isSiteRtl()
                        }"
                      >
                        {{ Service.formatDateEEMMMdy(daily.date) }}</span
                      >
                      <span class="bolder">
                        <span class="currency">{{
                          daily.sellingPriceConvertedCurrency
                        }}</span
                        ><span class="amount">
                          {{ daily.sellingPriceConverted | number }}
                        </span></span
                      >
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!booking.dailtyRates">
                  <div class="daily" *ngFor="let daily of booking.dailtyRates">
                    <div class="d-flex pb-1">
                      <span
                        [ngClass]="{
                          'mr-auto': Service.isSiteLtr(),
                          'ml-auto': Service.isSiteRtl()
                        }"
                      >
                        {{ Service.formatDateEEMMMdy(daily.date) }}</span
                      >
                      <span class="bolder">
                        <span class="currency">$</span
                        ><span class="amount">{{
                          booking.averageNightlySellingPrice
                        }}</span>
                      </span>
                    </div>
                  </div>
                </ng-container>

                <div
                  class="discount"
                  *ngIf="!booking.taxIncluded && booking.tax"
                >
                  <div class="d-flex pb-1">
                    <span
                      [ngClass]="{
                        'mr-auto': Service.isSiteLtr(),
                        'ml-auto': Service.isSiteRtl()
                      }"
                      i18n="@@taxAndFees"
                      >Tax and fees</span
                    >
                    <span class="bolder"
                      ><span class="currency">$</span
                      ><span class="amount">{{ booking.tax }}</span></span
                    >
                  </div>
                </div>
                <div class="discount" *ngIf="booking.userDiscount">
                  <div class="d-flex pb-1 primaray-text text-medium bolder">
                    <span i18n="@@discount"> Discount</span>
                    <span class="bolder ml-auto"
                      ><span class="currency">-$</span
                      ><span class="amount">{{
                        booking.discountAmount | number : "1.2-2"
                      }}</span></span
                    >
                  </div>
                </div>
                <div
                  class="discount"
                  *ngIf="booking.coupon && booking.discountAmount > 0"
                >
                  <div class="d-flex pb-1 primaray-text text-medium">
                    <span class="bolder" i18n="@@coupon"> Coupon</span>
                    <span class="bolder ml-auto"
                      ><span class="currency">-$</span
                      ><span class="amount">{{
                        booking.discountAmount | number : "1.2-2"
                      }}</span></span
                    >
                  </div>
                </div>

                <div
                  class="discount d-flex text-medium"
                  *ngIf="booking.travelerId && booking.travelerId === 'guest'"
                >
                  <span
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                    i18n="@@bookingFee"
                  >
                    Booking fee</span
                  >
                  <span
                    class="bolder"
                    [ngClass]="{
                      'ml-auto': Service.isSiteLtr(),
                      'mr-auto': Service.isSiteRtl()
                    }"
                    >{{booking.guestFeeConvertedCurrency}} {{ booking.guestFee | number : "1.2-2" }}</span
                  >
                </div>
              </div>

              <div class="pb-4 mb-4 border-bottom">
                <h4 class="mt-0 mb-3 bolder font-18" i18n="@@paymentInfo">
                  Payment info
                </h4>

                <div
                  class="booking_box_content"
                  *ngIf="booking.payment.channelProvider == 'AMAZON_PAYMENT_SERVICE'"
                >
                  <div>
                    {{ booking.payment.brand }}
                    <ng-container i18n="@@endsWith">ends with</ng-container>
                    {{ booking.payment.lastFour }}
                  </div>
                </div>


              </div>

              <h4 class="mt-0 mb-3 bolder font-18" i18n="@@refundInfo">
                Refund info
              </h4>
              <div class="booking_box_content">
                <div class="d-flex pb-1 font-16">
                  <span
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                    i18n="@@cancelPenalty"
                    >Cancellation Penalty
                  </span>
                  <span class="bolder">
                    <span class="currency">{{
                      cancellation.penaltyConvertedCurrency
                    }}</span>
                    <span class="amount">
                      {{ cancellation.penaltyConverted }}
                    </span>
                  </span>
                </div>
                <div class="d-flex pb-1 font-16">
                  <span
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                    i18n="@@totalRefund"
                    >Total refund
                  </span>
                  <span class="bolder">
                    <span class="currency">{{
                      cancellation.refundAmountConvertedCurrency
                    }}</span>
                    <span class="amount">
                      {{ cancellation.refundAmountConverted }}
                    </span>
                  </span>
                </div>
                <div class="pb-4 mb-4 border-bottom">
                  <h4
                    class="mt-0 mb-3 bolder font-18"
                    i18n="@@actualRefundAmount"
                  >
                    Actual refund amount
                  </h4>
                  <div class="booking_box_content">
                    {{ cancellation.refundChargeAmountCurrency }}
                    {{ cancellation.refundChargeAmount | number }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h4 class="gray-text-dark bolder mb-4" i18n="@@sureToCancel">
            Are you sure you want to cancel your booking?
          </h4>
          <div class="form-group">
            <label i18n="@@cancelReason">
              Please select the reason for cancellation
            </label>
            <select
              class="form-control mt-2"
              name="cancellation_reason"
              (change)="selectChangeHandler($event)"
              required
            >
              <option value="" selected i18n="@@selectReason">
                Select a reason
              </option>
              <option value="My plans changed" selected i18n="@@cancelOption1">
                My plans changed
              </option>
              <option value="I found a better hotel" i18n="@@cancelOption2">
                I found a better hotel
              </option>
              <option value="I just want to cancel" i18n="@@cancelOption3">
                I just want to cancel
              </option>
              <option value="Other" i18n="@@declineArray4">Other</option>
            </select>
          </div>
          <div class="row mt-5">
            <div class="col-md-5">
              <button
                class="btn btn-block btn-outline-blue mb-3 mb-md-0 font-16"
                (click)="cancel()"
                [disabled]="submitting"
                [ngClass]="{ 'm-progress': submitting }"
                i18n="@@yesCancel"
              >
                Yes, cancel my booking
              </button>
            </div>

            <div class="col-md-5">
              <button
                class="loader_button btn-block blue_btn font-16"
                [disabled]="submitted"
                (click)="back()"
                i18n="@@noAndBack"
              >
                No, take me back
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="d-none d-md-block">
          <div class="white-box p-0 mt-5">
            <div class="p-3">
              <h5 class="font-18 bolder mb-3" i18n="@@priceSummary">
                Price Summary
              </h5>
              <ng-container *ngIf="!booking.dailyRates">
                <div class="daily" *ngFor="let daily of booking.dailtyRates">
                  <div class="d-flex pb-1">
                    <span
                      [ngClass]="{
                        'mr-auto': Service.isSiteLtr(),
                        'ml-auto': Service.isSiteRtl()
                      }"
                    >
                      {{ Service.formatDateEEMMMdy(daily.date) }}</span
                    >
                    <span class="bolder">
                      <span class="currency">{{
                        daily.sellingPriceConvertedCurrency
                      }}</span
                      ><span class="amount">
                        {{ daily.sellingPriceConverted | number }}
                      </span>
                    </span>
                  </div>
                </div>
              </ng-container>

              <div
                class="discount d-flex text-medium"
                *ngIf="booking.travelerId && booking.travelerId === 'guest'"
              >
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  i18n="@@bookingFee"
                >
                  Booking fee</span
                >
                <span
                  class="bolder"
                  [ngClass]="{
                    'ml-auto': Service.isSiteLtr(),
                    'mr-auto': Service.isSiteRtl()
                  }"
                  >{{booking.guestFeeConvertedCurrency}} {{ booking.guestFee | number : "1.2-2" }}</span
                >
              </div>

              <ng-container *ngIf="!booking.dailtyRates">
                <div class="daily" *ngFor="let daily of booking.dailtyRates">
                  <div class="d-flex pb-1">
                    <span
                      [ngClass]="{
                        'mr-auto': Service.isSiteLtr(),
                        'ml-auto': Service.isSiteRtl()
                      }"
                    >
                      {{ Service.formatDateEEMMMdy(daily.date) }}</span
                    >
                    <span class="bolder">
                      <span class="currency">$</span
                      ><span class="amount">{{
                        booking.averageNightlySellingPrice
                      }}</span>
                    </span>
                  </div>
                </div>
              </ng-container>

              <div class="discount" *ngIf="!booking.taxIncluded && booking.tax">
                <div class="d-flex pb-1">
                  <span
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                    i18n="@@taxAndFees"
                  >
                    Tax and fees</span
                  >
                  <span class="bolder"
                    ><span class="currency">$</span
                    ><span class="amount">{{ booking.tax }}</span></span
                  >
                </div>
              </div>
              <div class="discount" *ngIf="booking.discount">
                <div class="d-flex pb-1 primaray-text">
                  <span
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                    i18n="@@discount"
                  >
                    Discount</span
                  >
                  <span class="bolder"
                    ><span class="currency">-$</span
                    ><span class="amount">{{
                      booking.discount.amount
                    }}</span></span
                  >
                </div>
              </div>

              <div
                class="discount"
                *ngIf="booking.coupon && booking.discountAmount > 0"
              >
                <div class="d-flex pb-1 primaray-text">
                  <span
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                    i18n="@@coupon"
                  >
                    Coupon</span
                  >
                  <span class="bolder"
                    ><span class="currency">-$</span
                    ><span class="amount">{{
                      booking.discountAmount | number : "1.2-2"
                    }}</span></span
                  >
                </div>
              </div>
            </div>
            <div class="white-box-footer rounded-bottom p-3">
              <div class="d-flex font-22 bolder">
                <span
                  class="primaray-text"
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                >
                  <ng-container i18n="@@Total">Total</ng-container>:
                </span>
                <span class="primaray-text">
                  <span class="currency">{{
                    booking.sellingPriceConvertedCurrency
                  }}</span>
                  <span class="amount">
                    {{ booking.sellingPriceConverted }}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="white-box p-0 mt-5 mb-5">
            <div class="p-3">
              <h5 class="font-18 bolder mb-3" i18n="@@paymentInfo">
                Payment info
              </h5>

              <div
                class="booking_box_content"
                *ngIf="booking.payment.channelProvider == bookingWalletsService.booking.payment.amazon"
              >
                <div>
                  {{ booking.payment.brand }}
                  <ng-container i18n="@@endsWith">ends with</ng-container>
                  {{ booking.payment.lastFour }}
                </div>
              </div>
              <div class="booking_box_content"
                   *ngIf="booking.payment.channelProvider == bookingWalletsService.booking.payment.wallet">
                <div i18n="@@companyWallet">
                  Company wallet
                </div>
              </div>

            </div>
          </div>

          <div class="white-box p-3 mb-5">
            <h5 class="font-18 bolder mb-3" i18n="@@refundInfo">Refund info</h5>
            <div class="booking_box_content font-16">
              <div class="d-flex pb-1">
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  i18n="@@cancelPenalty"
                  >Cancellation Penalty
                </span>
                <span class="bolder">
                  <span class="currency">{{
                    cancellation.penaltyConvertedCurrency
                  }}</span>
                  <span class="amount">
                    {{ cancellation.penaltyConverted }}
                  </span>
                </span>
              </div>
              <div class="d-flex pb-1 font-16">
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  i18n="@@totalRefund"
                  >Total refund
                </span>
                <span class="bolder">
                  <span class="currency">{{
                    cancellation.refundAmountConvertedCurrency
                  }}</span>
                  <span class="amount">
                    {{ cancellation.refundAmountConverted }}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="white-box p-3 mb-5">
            <h4 class="mt-0 mb-3 bolder font-18" i18n="@@actualRefundAmount">
              Actual refund amount
            </h4>
            <div class="booking_box_content">
              {{ cancellation.refundChargeAmountCurrency }}
              {{ cancellation.refundChargeAmount | number }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
