import {Component, Injectable, OnInit,} from '@angular/core';
import {DataService} from '../data.service';
import {ApprovalsService} from "../approvals.service";

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class BottomSheetComponent implements OnInit {

  constructor(public Service: DataService,
              public approvalsService: ApprovalsService) {
  }
  bottomSheetTexts = {
    NewPaymentCard: $localize`:@@addNewCard:Add New Card`,
    removeTeam: $localize`:@@removeTeam:Delete Team`,
    removeMember: $localize`:@@removeMember:Remove Member`,
    removePolicy: $localize`:@@removePolicy:Delete Policy`,
    removeCompanyPayment: $localize`:@@deleteCard:Delete Card`,
    editAlias: $localize`:@@editCardAlias:Edit Card Alias`,
    tripApproval: $localize`:@@tripApproval:Trip Approval`,
    changePrice: $localize`:@@warningPriceChanged:Warning, Price changed`,
    tripDisapproval: $localize`:@@declineTrip:Decline trip`,
    editBilling: $localize`:@@editBillingCard:Edit Billing Card`,
    editUserCard: $localize`:@@editCard:Edit Card`,
    editBillingPlan: $localize`:@@billingPlans:Billing Plans`,
    deleteSubscription: $localize`:@@deleteSubscription:Delete Subscription`,
    switchPlan: $localize`:@@switchToPlanBilling:Switch to ${this.Service.workspacePlan} Billing`,
    ForgotPassword: $localize`:@@ForgotPassword:Forgot Password`,
    userReactivationFees: $localize`:@@reactivateUser:User Reactivation Fees`,
    reactivateAccount: $localize`:@@activateMySubscription:Activate My Subscription`,
    companyHotelShare: $localize`:@@shareHotel:Share a hotel`,
    companySendBookingVoucher: $localize`:@@shareVoucher:Email to another address`,
    SendBookingVoucher: $localize`:@@shareVoucher:Email to another address`,
    removeProfileImage: $localize`:@@removeProfileImage:Remove Profile Image`,
    uploadExpenses: $localize`:@@uploadExpenses:Upload Expenses`,
    withdrawApprovalRequest: $localize`:@@withdrawApprovalRequest:Withdraw Approval Request`,
    withdrawApprovalRequestFlight: $localize`:@@withdrawApprovalRequest:Withdraw Approval Request`,
    addAdmin: $localize`:@@setAsAdmin:Set as admin`,
    removeAdmin: $localize`:@@removeAdmin:Remove admin`,
    deleteRewardsProgram: $localize`:@@deleteRewardsProg:Delete Rewards Program`,
    bothApprovalDisapproval: $localize`:@@tripApproval:Trip Approval`,
    travelDocument: $localize`:@@travelDoc:Travel Document`,
    flyerProgram: $localize`:@@flyerProgramOne:Frequent Flyer Program`,
    memberInfo: $localize`:@@memberInfo:Member info`,
    deleteDoc: $localize`:@@removeTravelDoc:Remove Travel Document`,
    deleteProgram: $localize`:@@removeFlyerProg:Remove Flyer Program`,
    requestDemo: $localize`:@@landingRequestBtn:Request Demo`,
    cancelInvitation: $localize`:@@withdrawInviteTitle:Withdraw Invitation`,
    memberPassword: $localize`:@@changePassword:Change Password`,
    editCompanyInfo: $localize`:@@companyInfo:Company Info`,
    editCompanyMainAddress: $localize`:@@officeAddress:Office address`,
    addCompanyOtherAddress: $localize`:@@officeBranchesAdd:Add office branch`,
    editCompanyOtherAddress: $localize`:@@officeBranches:Office branches`,
    costActivation: $localize`:@@costActivation:Cost Activation`
  };


  ngOnInit() {
  }
}
