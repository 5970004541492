import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ResetPasswordComponent} from '../controls/reset-password/reset-password.component';
import { SharedModule } from '../shared/shared.module';
import { FavoritesControlComponent } from '../controls/favorites-control/favorites-control.component';
import { HotelFlightTripsComponent} from '../trips/hotel-flight-trips/hotel-flight-trips.component';
/*import { PaymentMethodsComponent } from '../payment/payment-methods/payment-methods.component';*/
import { ManageBookingComponent} from '../controls/manage-booking/manage-booking.component';
import { ManageFlightBookingComponent} from '../controls/manage-flight-booking/manage-flight-booking.component';
import { CancelBookingComponent} from '../controls/cancel-booking/cancel-booking.component';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@angular/material';
import { CostReportingDetailsModule } from '../controls/cost-reporting-details/cost-reporting-details.module';
@NgModule({
  imports: [
    MatDatepickerModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    RouterModule,
    CostReportingDetailsModule
  ],
  declarations: [
    ResetPasswordComponent,
    FavoritesControlComponent, HotelFlightTripsComponent,
    ManageFlightBookingComponent, ManageBookingComponent, CancelBookingComponent
  ],
  exports: [
    ResetPasswordComponent,
    FavoritesControlComponent, HotelFlightTripsComponent,
    ManageFlightBookingComponent, ManageBookingComponent, CancelBookingComponent

  ]

})
export class SharedUserModule { }
