<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="payment-list main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pt-4 pt-lg-5 pb-0 pb-lg-5">
        <div class="mx-lg-5">
          <div class="row">
            <div class="col">
              <div class="pb-4 pb-lg-5 mb-3">
                <a
                  class="font-16 blue-text text-underline"
                  (click)="
                    this.router.navigate([
                     '/settings'
                    ])
                  "
                  i18n="@@menuItemSettings"
                  >Settings</a
                >
                <span class="font-16 gray-text-dark">
                  / <ng-container i18n="@@cards">Cards</ng-container>
                </span>
              </div>
            </div>
          </div>
          <div class="row pb-5">
            <div class="col-md-6 d-flex flex-column">
              <div>
                <p class="gray-text-dark font-18">
                  <span
                    *ngIf="this.auth.companyInfo && this.auth.companyInfo.name"
                    i18n="@@compCardMsg1"
                    >{{ this.auth.companyInfo.name }} employees who sign up to
                    SafarHub for Work can charge the payment method(s)
                    below</span
                  >
                </p>
              </div>
              <div
                class="mt-auto min-width-workspace-btn"
                [ngClass]="{
                  'mr-auto': dataService.desktopView && dataService.isSiteLtr(),
                  'ml-auto': dataService.desktopView && dataService.isSiteRtl()
                }"
              >
                <button
                  (click)="addNewPayment()"
                  class="btn btn-outline-blue font-14 px-5 sharp-font bold-500"
                  [ngClass]="{ 'btn-block': dataService.desktopView }"
                  i18n="@@newCard"
                >
                  New Card
                </button>
              </div>
            </div>
            <div class="col-md-6" *ngIf="dataService.desktopView">
              <div
                class="rounded-bottom p-5 right-box-style company-section-with-thick-border thick-top"
                [ngClass]="{
                  'ml-auto': dataService.isSiteLtr(),
                  'mr-auto': dataService.isSiteRtl()
                }"
              >
                <p
                  class="primaray-text bold-600 mb-0 font-16"
                  i18n="@@teamsPayments"
                >
                  Teams payments
                </p>
                <p class="mb-0 gray-text-dark font-14" i18n="@@compCardMsg2">
                  You can assign payment methods to different teams, if no team
                  is assigned to a payment methods, then it can be used by all
                  employees
                </p>
              </div>
            </div>
          </div>
          <div
            class="container py-5 mb-5"
            *ngIf="
              this.dataService.companyPaymentList &&
              this.dataService.companyPaymentList.length > 0 &&
              this.dataService.companyPaymentExpiryList.length > 0
            "
          >
            <div
              class="row px-lg-3 py-4 hover-row-list font-14 items-full-list"
              [ngClass]="{
                'border-bottom': dataService.desktopView,
                'rounded border mb-4': dataService.mobileView
              }"
              *ngFor="
                let item of this.dataService.companyPaymentList;
                let i = index
              "
            >
              <div
                class="col-lg-5 d-flex"
                [ngClass]="{
                  'h-100 justify-content-center align-items-center':
                    dataService.desktopView
                }"
              >
                <div
                  class="card_type card_type_{{ item.card.brand }}"
                  [ngClass]="{
                    'max-width-card': dataService.mobileView,
                    'text-right mr-4':
                      dataService.desktopView && dataService.isSiteLtr(),
                    'text-left ml-4':
                      dataService.desktopView && dataService.isSiteRtl()
                  }"
                ></div>
                <div
                  [ngClass]="{
                    'mr-auto': dataService.isSiteLtr(),
                    'ml-auto': dataService.isSiteRtl()
                  }"
                >
                  <div class="mb-1" *ngIf="item.card.alias">
                    <span>{{ item.card.alias }}</span>
                  </div>
                  <div class="mb-1" *ngIf="!item.card.alias">
                    <span i18n="@@noAlias">No alias</span>
                  </div>
                  <p class="gray-text mb-0" dir="ltr">
                    **** **** **** {{ item.card.lastFour }}
                  </p>
                  <div *ngIf="dataService.mobileView" class="min-height-20">
                    <p
                      *ngIf="this.dataService.companyPaymentExpiryList[i]"
                      class="text-danger mb-0"
                    >
                      <i
                        class="icon-warning-msg"
                        [ngClass]="{
                          'mr-2': dataService.isSiteLtr(),
                          'ml-2': dataService.isSiteRtl()
                        }"
                      ></i
                      ><ng-container i18n="@@expired">Expired</ng-container>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <p class="pt-4 pt-lg-0 mb-1 gray-text">
                  <span class="gray-text pr-2" *ngIf="dataService.mobileView"
                    ><ng-container i18n="@@team">Team</ng-container>:</span
                  ><span
                    [ngClass]="{
                      'text-bg font-12 px-3 py-2': dataService.mobileView
                    }"
                    >{{ item.description }}</span
                  >
                </p>
                <p
                  *ngIf="
                    this.dataService.companyPaymentExpiryList[i] &&
                    dataService.desktopView
                  "
                  class="text-danger mb-0"
                >
                  <i
                    class="icon-warning-msg"
                    [ngClass]="{
                      'mr-2': dataService.isSiteLtr(),
                      'ml-2': dataService.isSiteRtl()
                    }"
                  ></i
                  ><ng-container i18n="@@expired">Expired</ng-container>
                </p>
              </div>
              <div class="col-lg-2 pt-2">
                <div class="d-flex">
                  <div
                    class="actions"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                  >
                    <i
                      class="drop-down{{
                        i
                      }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                      (click)="showActions = !showActions; selectedIndex = i"
                    ></i>
                    <div
                      class="border shadow font-14 position-absolute bg-white drop"
                      *ngIf="showActions && i === selectedIndex"
                    >
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        (click)="
                          editAlias(
                            item.card.id,
                            item.card.paymentGatewayProvider,
                            item.card.alias
                          )
                        "
                        i18n="@@edit"
                      >
                        Edit
                      </p>
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        (click)="removePayment(item.card.id)"
                        i18n="@@deleteBtn"
                      >
                        Delete
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
