<div [ngClass]="{'container pb-5': Service.mobileView}">


          <div class="create-team my-5 my-lg-2">
            <div class="input-group row no-gutters ">
              <div class="col" (click)="placeholder = false">

                <div class="tag-container">
                  <tag-input [ngModel]="" [modelAsStrings]="true"
                             #tagInput
                             name="emails"
                             #email="ngModel"
                             [errorMessages]="errorMessages"
                             [validators]="validators"
                             [editable]='true'
                             [separatorKeyCodes]="[32,188,186,13,9]"
                             [placeholder]="''"
                             [secondaryPlaceholder]="''"
                             [addOnPaste]="true"
                             [addOnBlur]="true"
                             [onAdding]="onAddedFunc"
                             [blinkIfDupe]="true"
                             [ripple]="false"
                             [ngClass]="{ 'pl-3': Service.isSiteLtr(), 'pr-3': Service.isSiteRtl(), 'error': submitted && this.emails.length === 0 }"
                             class="tag-input-field border rounded"
                             required >

                    <ng-template let-item="item" let-index="index">
                      <div class="d-flex" style="direction: ltr"><p class="dotted-text">{{ item }}</p> <delete-icon (click)="tagInput.removeItem(item, index); removeFromArray(item, index)"></delete-icon></div>
                    </ng-template>

                  </tag-input>
                </div>
                <span class="placeholder-text position-absolute gray-text" *ngIf="placeholder"
                      [ngClass]="{'font-11': Service.isSiteRtl()}" i18n="@@insertEmail">Enter Emails (press enter to insert multi emails)</span>


              </div>
              <!--   <div class=" col-md-3 input-group-append">
                   <button (click)="sendEmail()" class="btn btn-block btn-primary text-center pl-5 pr-5 mt-3 mt-lg-0"
                           [ngClass]="{'m-progress': submitting }">Send Invitations</button>

                 </div>-->
            </div>

            <div *ngIf="submitted" >
              <div *ngIf="this.emails.length === 0" class="empty-emails" i18n="@@emailsRequired">Emails are required</div>
            </div>



            <!--  <mat-form-field  class="mb-3 border w-100 chips-container rounded" [ngClass]="{ 'red-border': submitted && !selectedUser }"
                      *ngIf="Service.desktopView" (click)="userInput.select()">
       <input id="userInputHtml" class="min-height mx-0 my-0"
              [ngClass]="{'pl-4': Service.isSiteLtr(), 'pr-4': Service.isSiteRtl()}"
              #userInput type="text" matInput [formControl]="userControl" [matAutocomplete]="autoUser"
              (blur)="reset();" >
       <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayFn">
         <mat-option style="padding: 0" *ngFor="let user of filteredUsers | async" [value]="user">
           <div (click)="optionClicked($event, user)" class="mat-checkbox-container">
             {{user.description}}<br/><span class="font-12 gray-text">{{user.email}}</span>
           </div>
         </mat-option>
       </mat-autocomplete>
     </mat-form-field>

            <div class="form-control rounded border" *ngIf="Service.mobileView" (click)="showUsersPopUp()">
              <p *ngIf="selectedUser" class="mt-2 pt-1">{{selectedUser.description}}</p>
            </div>-->
          </div>

  <div class="form-actions clearfix" (click)="submitVoucher()">
    <button class=" btn-block btn blue_btn font-14 sharp-font bold-500" [ngClass]="{'m-progress': submitting}" i18n="@@submitBtn">Submit</button>
  </div>
  <p class="text-danger mt-3" *ngIf="errorMsg">{{errorMsg}}</p>


  </div>









<div class="justify-content-end create-team" >

  <div [ngClass]="{'in action-popup':showUsersAutoCompletePopUp}">
    <div [ngClass]="{'header':showUsersAutoCompletePopUp}" >
      <div *ngIf="showUsersAutoCompletePopUp">
        <div *ngIf="showUsersAutoCompletePopUp">

          <div class="info" [ngClass]="{'pay-and-member' : showUsersAutoCompletePopUp }">
            <span i18n="@@guestName">Guest Name</span>
          </div>
          <div class="close" (click)="showUsersAutoCompletePopUp = false; this.Service.enableBodyScroll();" i18n="@@cancelBtn">Cancel</div>
        </div>
      </div>




      <div class="position-relative mobile-search-field" *ngIf="showUsersAutoCompletePopUp">
        <div class="form-group mb-3">
          <mat-form-field class="w-100 chips-container border rounded">
            <input class="bg-white min-height mx-0 my-0"
                   [ngClass]="{'pl-4': Service.isSiteLtr(), 'pr-4': Service.isSiteRtl()}"
                   #userInput id="userInput" type="text" matInput [formControl]="userControl" [matAutocomplete]="autoUser" (blur)="reset()">
            <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayFn">
              <mat-option style="padding: 0" *ngFor="let user of filteredUsers | async" [value]="user">
                <div (click)="optionClicked($event, user)" class="mat-checkbox-container">
                  {{user.description}}<br/><span class="font-12 gray-text">{{user.email}}</span>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </div>
      </div>





    </div>



  </div>
</div>














