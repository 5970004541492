<div class="fare font-13">
    <div class="mb-3 d-flex align-items-center" *ngIf="fareDetailsBySegment.includedCheckedBagsPredict as includedCheckedBagsPredict">
        <img [src]="includedCheckedBagsPredict.included ? 'assets/img/fares-icons/checked-baggage-green.svg' : 'assets/img/fares-icons/checked-baggage-grey.svg'"  />
        <p class="mb-0 px-2" [ngClass]="includedCheckedBagsPredict.included ? 'green-text' : 'white-black-gray'">
            {{includedCheckedBagsPredict.description}}
        </p>
    </div>
    <div class="mb-3 d-flex align-items-center white-black-gray" *ngIf="!fareDetailsBySegment.includedCheckedBagsPredict">
        <img src="assets/img/fares-icons/checked-baggage-grey.svg" />
        <p class="mb-0 px-2" i18n="@@checkedBaggageNotIncluded">Checked baggage not included</p>
    </div>

    <!------------------------->

    <div class="mb-3 d-flex align-items-center" *ngIf="(fareDetailsBySegment.changes?.allowed !== undefined && fareDetailsBySegment.changes) as changes">
        <img [src]="changes.allowed ? 'assets/img/fares-icons/change-green.svg' : 'assets/img/fares-icons/change-grey.svg'"  />
        <p class="mb-0 px-2" [ngClass]="changes.allowed ? 'green-text' : 'white-black-gray'">
            {{changes.description}}
        </p>
    </div>
    <div class="mb-3 d-flex align-items-center white-black-gray" *ngIf="!fareDetailsBySegment.changes || fareDetailsBySegment.changes?.allowed === undefined">
        <img src="assets/img/fares-icons/change-grey.svg" />
        <p class="mb-0 px-2" i18n="@@changeNotAllowed">Change not allowed</p>
    </div>


    <!------------------------->

    <div class="d-flex"
         [ngClass]="{'align-items-start': cancellation.beforeDepartureDescription && cancellation.afterDepartureDescription,
         'align-items-center': (cancellation.beforeDepartureDescription && !cancellation.afterDepartureDescription) ||
         (!cancellation.beforeDepartureDescription && cancellation.afterDepartureDescription)}"
         *ngIf="fareDetailsBySegment.cancellation as cancellation">
        <img [ngClass]="{'pt-1': cancellation.beforeDepartureDescription && cancellation.afterDepartureDescription}" [src]="cancellation.refundable ? 'assets/img/fares-icons/refund-green.svg' : 'assets/img/fares-icons/refund-grey.svg'"  />
        <p class="mb-0 px-2" [ngClass]="cancellation.refundable ? 'green-text' : 'white-black-gray'">
            <ng-container *ngIf="cancellation.description">{{cancellation.description}}</ng-container>
            <ng-container *ngIf="!cancellation.description">
                <span class="d-block" *ngIf="cancellation.beforeDepartureDescription">
                    <span i18n="@@beforeDeparture">Before departure</span>: {{cancellation.beforeDepartureDescription}}
                </span>
                <span class="d-block" *ngIf="cancellation.afterDepartureDescription">
                    <span i18n="@@afterDeparture">After departure</span>: {{cancellation.afterDepartureDescription}}
                </span>
            </ng-container>
        </p>
    </div>
    <div class="d-flex align-items-center white-black-gray" *ngIf="!fareDetailsBySegment.cancellation">
        <img src="assets/img/fares-icons/refund-grey.svg" />
        <p class="mb-0 px-2" i18n="@@nonrefundable">Non-refundable</p>
    </div>


</div>
