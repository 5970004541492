import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators,} from "@angular/forms";
import {DataService} from "../../../services/data.service";
import {ScrollToConfigOptions, ScrollToService,} from "@nicky-lenaers/ngx-scroll-to";
import * as moment from "moment";
import {GoogleAnalyticsService} from "../../../google-analytics.service";
import {AuthServiceMain} from "../../../auth/auth.service";
import {environment} from "../../../../environments/environment";
import {GoogleAnalytics4Service} from "../../../google-analytics4.service";
import {VariablesService} from "../../../services/vars.service";
import {CostBookingStateService} from "src/app/services/states/cost-booking-state.service";
import {PayType} from "../../../shared/enums/pay-type.enum";
import {BehaviorSubject, Subscription} from "rxjs";
import {Card} from "../../../shared/model/payment/card";
import {ApprovalsService} from "../../../services/approvals.service";
import {BookingWalletsService} from "../../../services/booking/booking-wallets.service";
import {ChannelProvider} from "../../../shared/enums/channel-provider.enum";


declare let ga: Function; // Declare ga as a function
declare let window: any;

@Component({
  selector: "app-workspace-booking-form",
  templateUrl: "./workspace-booking-form.component.html",
  styleUrls: ["./workspace-booking-form.component.scss"],
})
export class WorkspaceBookingFormComponent implements OnInit, OnDestroy {
  amazonErrorCodes = [500, 502,503,504,505,48];
  errorCodeFromUrl: string;
  env = environment;
  readMore = false;
  getApprovalsList;
  userInfohasProperty = false;
  paymentType = "new_card";
  payTypeMethod: PayType = PayType.SavedCards;
  bookingForm: FormGroup;
  paymentID;
  submitted = false;
  data;
  selectedCard = "";
  errorMsg = "";
  specialReq = false;
  previousSavedCardListLength = 0; // Track previous length
  savedCardList: BehaviorSubject<Card[]> = new BehaviorSubject<Card[]>([]);
  savedCard;
  PaxesCount: any;
  rate;
  diffInDays;
  TripAdviserRating;
  tripAdviserdata;
  previousUrl = sessionStorage.getItem("previousUrl");
  @ViewChild("priceElem", { static: false }) priceElem?: ElementRef;
  userControl = new FormControl();
  selectedUser;
  lastFilter: string = "";
  dataUsers;
  requiresApprovalData;
  submittingApproval;
  submittedApproval;
  voucherUrl;
  travellerEffectivePolicyName: null;
  phonePlaceHolder = $localize`:@@phoneNumber:Phone number`;
  reviewTitle =
      $localize`:@@reviewSummary:Review summary` +
      "<span>(" +
      $localize`:@@poweredByGoogle:Powered by Google` +
      ") </span>";
  userInfoObj = JSON.parse(localStorage.getItem("userInfo"));
  travelerId;
  traveler;
  inValidCVV = false;
  noSelectedCard: boolean = false;
  private subscriptions = new Subscription();
  bookSubscription: Subscription;
  cardsSubscription: Subscription;
  requireApprovalSubscription: Subscription;
  approvalsListSubscription: Subscription;
  infoSubscription: Subscription;
  personalCardSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @ViewChild("userInput") userInput: ElementRef;
  constructor(
      public bookingWalletsService: BookingWalletsService,
      public approvalsService: ApprovalsService,
      private route: ActivatedRoute,
      public vars: VariablesService,
      public router: Router,
      private formBuilder: FormBuilder,
      public Service: DataService,
      public Auth: AuthServiceMain,
      private scrollToService: ScrollToService,
      public googleAnalyticsService: GoogleAnalyticsService,
      public googleAnalytics4Service: GoogleAnalytics4Service,
      public costBookingState: CostBookingStateService
  ) {
    this.Service.getCookie("shareasaleSSCID");
  }

  ngOnInit() {
     this.selectedUser = this.userInfoObj;
     this.phonePlaceHolder = "";
     const companyObj = JSON.parse(localStorage.getItem("companyInfo"));
     this.voucherUrl = "/availability/voucher";
     this.Service.loaderText = $localize`:@@bookingYourRoom:Booking your room ..`;
     this.errorMsg = "";

    this.route.queryParams.subscribe(params => {
      this.errorCodeFromUrl = params['errorcode'];
      console.log('Error Code:', this.errorCodeFromUrl);
    });

    if(this.amazonErrorCodes.includes(Number(this.errorCodeFromUrl))){
      this.Service.errorMsg = $localize`:@@errorCodeFromUrl:An error occurred during the payment process.`;
    } else {
      this.Service.errorMsg = "";
    }

     this.bookingForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      LastName: ["", Validators.required],
      phone: ["", Validators.required],
      password: [""],
      cvv: ["",
        [Validators.maxLength(4),
          Validators.pattern(/^[0-9]*$/)]
      ],
      FirstName: ["", Validators.required],
      specialRequests: ["", [Validators.maxLength(499)]],
    });

    const PaxesArray: any = this.Service.params.paxes.split(",");
    this.PaxesCount = PaxesArray.length + Number(PaxesArray[0]) - 1;
    this.Service.bookingSubmit = false;
    this.submittingApproval = false;
    this.Service.alerts = [];

    this.travelerId = sessionStorage.getItem("travelerId");
    if (this.Auth.isOwner() || this.Auth.isAdmin()) {
      if (
          this.travelerId &&
          this.travelerId !== "" ||
          this.travelerId !== "guest"
      ) {
          this.getTravellerDetails();
      }
    } else {
      if (this.bookingForm.controls.FirstName.value === "") {
        this.bookingForm.get("FirstName").setValue(this.Auth.userInfo.name);
      }
      if (this.bookingForm.controls.LastName.value === "") {
        this.bookingForm.get("LastName").setValue(this.Auth.userInfo.lastName);
      }
      this.traveler = {
        travelerPersonalPaymentMethodsDisabled:
        this.Auth.userInfo.personalPaymentMethodsDisabled,
        travelerName:
            this.Auth.userInfo.name + " " + this.Auth.userInfo.lastName,
        travellerEffectivePolicyName: this.Auth.userInfo.effectivePolicyName
      };
      let travelerPhone = "travelerPhone";
      let dialCode = "";
      if (this.Auth.userInfo.phoneNumberCountryCode) {
        let selected = this.vars.countries.filter(
            (item) =>
                item.dial_code === "+" + this.Auth.userInfo.phoneNumberCountryCode
        );
        if (selected.length > 0) {
          dialCode = this.Auth.userInfo.phoneNumberCountryCode;
        } else {
          selected = this.vars.countries.filter(
              (item) => item.code === this.Auth.userInfo.phoneNumberCountryCode
          );
          dialCode = selected[0].dial_code.replace("+", "");
        }
        this.traveler[travelerPhone] =
            dialCode + this.Auth.userInfo.phoneNumber;
        this.bookingForm.get("phone").setValue(this.traveler.travelerPhone);
      } else {
        this.bookingForm.get("phone").setValue(this.Auth.userInfo.phoneNumber);
      }
      if (this.bookingForm.controls.email.value === "") {
        this.bookingForm.get("email").setValue(this.Auth.userInfo.email);
      }
    }

    this.rate = JSON.parse(sessionStorage.getItem("rate"));

    this.checkRequireApproval();

    this.diffInDays = moment
        .utc(this.Service.params.checkOut)
        .diff(moment.utc(this.Service.params.checkIn), "days");

    if (sessionStorage.getItem("tripAdviserdata")) {
      this.tripAdviserdata = JSON.parse(
          sessionStorage.getItem("tripAdviserdata")
      );
      this.TripAdviserRating = (this.tripAdviserdata.data.rating * 100) / 5;
    }
    this.bookingWalletsService.getBookingWalletsList(this.travelerId);
    this.callSavedCardsMethod();

    window.dataLayer.push({
      event: "fireRemarketing",
      ecomm_prodid: this.Service.hotelData.giataId,
      ecomm_pagetype: "cart",
      ecomm_totalvalue: this.rate.finalSellingPrice,
      hrental_id: this.Service.hotelData.giataId,
      hrental_pagetype: "conversionintent",
    });

    if (sessionStorage.getItem("voucurevist")) {
      sessionStorage.removeItem("voucurevist");
    }
  }

  triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: "bookingForm",
      duration: 650,
      easing: "easeOutElastic",
      offset: 20,
    };
    this.scrollToService.scrollTo(config);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.bookingForm.controls;
  }

  onSubmit() {
    if (this.Auth.isManager() || this.Auth.isAdmin() || this.Auth.isOwner()) {
      this.getApprovalsList = true;
    }

    if (this.selectedCard === 'selectPayment' || this.selectedCard === 'noPayment' || this.selectedCard === 'new') {
      this.noSelectedCard = true;
    }

    if (this.bookingWalletsService.selectedWallet === 'selectWallet' || this.bookingWalletsService.selectedWallet === 'noWallet') {
      this.bookingWalletsService.noSelectedWallet = true;
    }

    this.inValidCVV = false;
    if (this.payTypeMethod === PayType.SavedCards &&
        this.bookingForm.controls.cvv.value === "" &&
        this.personalCardSelected.value) {
      this.inValidCVV = true;
      this.Service.scrollTop();
    } else {
      this.inValidCVV = false;
    }

    let header = "";
    if (this.Service.sscid) {
      header = this.Service.sscid;
    }
    this.errorMsg = "";
    this.submitted = true;
    this.Service.alerts = [];

    const isValidCostReporting = this.costBookingState
        .setIsSubmittedAndCheckValidation(this.submitted)
        .valueOf();

    if (this.bookingForm.invalid || !this.selectedUser ||
        (this.noSelectedCard && this.payTypeMethod === PayType.SavedCards) ||
        (this.bookingWalletsService.noSelectedWallet && this.payTypeMethod === PayType.Wallet) ||
        this.inValidCVV || !isValidCostReporting) {
      this.triggerScrollTo();
      this.Service.scrollTop();
      return;
    }



    const dataObj = {} as any;
    dataObj.phone = this.bookingForm.controls.phone.value;
    dataObj.rateKey = this.Service.newRate.rateKey;
    dataObj.remark = this.bookingForm.controls.specialRequests.value;

    if (
        this.costBookingState?.selectedCostCenters &&
        this.costBookingState?.selectedCostCenters.length
    ) {
      dataObj.costCenterId = this.costBookingState?.selectedCostCenters[0]?.id;
    }

    if (
        this.costBookingState?.selectedLabels &&
        this.costBookingState?.selectedLabels.length
    ) {
      dataObj.labelsIds = this.costBookingState.selectedLabels?.map(
          (att) => att.id
      );
    }

      dataObj.guestUserId = this.travelerId;
      if (this.travelerId === "guest") {
        dataObj.guestEmail = this.bookingForm.controls.email.value;
        dataObj.guestFirstName = this.bookingForm.controls.FirstName.value;
        dataObj.guestLastName = this.bookingForm.controls.LastName.value;
      }


     if (this.payTypeMethod === PayType.SavedCards) {
        dataObj.apsToken = this.selectedCard;
       dataObj.paymentGatewayProvider = ChannelProvider.Amazon;
      if(this.personalCardSelected.value){
        dataObj.cvv = this.bookingForm.controls.cvv.value;
      }

    } else if(this.payTypeMethod === PayType.Wallet){
       dataObj.beWalletId = this.bookingWalletsService.selectedWallet;
       dataObj.paymentGatewayProvider = ChannelProvider.BeWallet;
     }
     console.log(dataObj);
    this.bookNow(dataObj, header);
  }


  onItemChange(value) {
    this.paymentType = value.target.defaultValue;
    console.log(value.target.defaultValue);
    this.noSelectedCard = false;
    this.inValidCVV = false;
    this.bookingWalletsService.noSelectedWallet = false;
    if(value.target.defaultValue === "wallets"){
      this.payTypeMethod = PayType.Wallet;
      this.bookingWalletsService.payTypeMethod = PayType.Wallet;
    } else {
      this.payTypeMethod = PayType.SavedCards;
      this.bookingWalletsService.payTypeMethod = PayType.SavedCards;
    }
    console.log(value);
  }

  savedCardChange() {
    if (this.selectedCard !== 'selectPayment' && this.selectedCard !== 'noPayment' && this.selectedCard !== 'new') {
      this.noSelectedCard = false;
    }
    console.log('here chnage');
    if (this.selectedCard !== "new") {
      this.payTypeMethod = PayType.SavedCards;
      this.isSelectedCardPersonal(this.selectedCard);
    } else {
      this.personalCardSelected.next(false);
      const url = '/user/payment';
      console.log('here in open url not safari');
      window.open(url, '_blank');

    }
  }

  isSelectedCardPersonal(token: string){
    const selectedCardByUser = this.savedCardList.value.find(card => card.token === token);

      if (selectedCardByUser?.companyCard) {
        this.personalCardSelected.next(false);
      } else {
        this.personalCardSelected.next(true);
      }
  }

  tripAdvisorTrigger() {
    this.googleAnalyticsService.eventEmitter(
        "Stats",
        "TripAdvisor",
        "click",
        1
    );
  }

  openSSL() {
    window.open(
        "https://www.ssl.com/team/a5c-1bauoij/site_seals/0532d156-58ecc6a5/site_report",
        "site_report",
        "height=500, width=400, top=100, left=100"
    );
    return false;
  }



  callSavedCardsMethod(){
    this.cardsSubscription = this.Service.savedCard(this.travelerId).subscribe((success) => {
      this.savedCard = success;

      if (this.savedCard.errorCode === 0) {
        this.payTypeMethod = PayType.SavedCards;

        if (
            this.savedCard.data &&
            this.savedCard.data.cards &&
            this.savedCard.data.cards.length > 0
        ) {
          this.payTypeMethod = PayType.SavedCards;

          const amazonCardsOnly = this.savedCard?.data?.cards
              ?.filter((element) => {
                return element.paymentGatewayProvider === ChannelProvider.Amazon;
              }) || [];



          // Debugging logs to check the length of the array and previous length
          console.log('Current amazonCardsOnly length:', amazonCardsOnly.length);
          console.log('Previous amazonCardsOnly length:', this.previousSavedCardListLength);


          // Check if array length has changed
          if (amazonCardsOnly.length !== this.previousSavedCardListLength) {
            this.selectedCard = 'selectPayment';
            // Length has changed, trigger next
            this.savedCardList.next(amazonCardsOnly);
            // Update the previous length
            this.previousSavedCardListLength = amazonCardsOnly.length;
          }
        } else {
          this.selectedCard = 'noPayment';
          //this.payTypeMethod = PayType.Amazon;
        }
        this.Service.bookingPage = false;
      } else {
        //this.payTypeMethod = PayType.Amazon;
        this.Service.bookingPage = false;
      }

    });

    this.subscriptions.add(this.cardsSubscription);
  }

  getTravellerDetails(){
    this.infoSubscription = this.Service.getCompanyUserDetails(this.travelerId).subscribe((suc) => {
      this.data = suc;
      if (this.data.errorCode === 0) {
        if (this.bookingForm.controls.FirstName.value === "") {
          this.bookingForm
              .get("FirstName")
              .setValue(this.data.data.firstName);
        }
        if (this.bookingForm.controls.LastName.value === "") {
          this.bookingForm
              .get("LastName")
              .setValue(this.data.data.lastName);
        }
        if (this.bookingForm.controls.email.value === "") {
          this.bookingForm.get("email").setValue(this.data.data.email);
        }
        this.traveler = {
          travelerPersonalPaymentMethodsDisabled:
          this.data.data.personalPaymentMethodsDisabled,
          travelerName:
              this.data.data.firstName + " " + this.data.data.lastName,
          travellerEffectivePolicyName: this.data.data.effectivePolicyName
        };
        let travelerPhone = "travelerPhone";
        let dialCode = "";
        if (this.data.data.phoneNumberCountryCode) {
          let selected = this.vars.countries.filter(
              (item) =>
                  item.dial_code === "+" + this.data.data.phoneNumberCountryCode
          );
          if (selected.length > 0) {
            dialCode = this.data.data.phoneNumberCountryCode;
          } else {
            selected = this.vars.countries.filter(
                (item) => item.code === this.data.data.phoneNumberCountryCode
            );
            dialCode = selected[0].dial_code.replace("+", "");
          }
          this.traveler[travelerPhone] =
              dialCode + this.data.data.phoneNumber;
          this.bookingForm
              .get("phone")
              .setValue(this.traveler.travelerPhone);
        }
      }
    });
    this.subscriptions.add(this.infoSubscription);
  }

  bookNow(dataObj, header){
    if (!this.requiresApprovalData) {
      this.Service.bookingSubmit = true;
    } else {
      this.submittingApproval = true;
    }
    this.bookSubscription = this.Service.bookingForm(dataObj, header).subscribe(
        (data) => {
          this.data = data;
          this.errorMsg = this.data.message;
          if (this.data.errorCode === 0) {
            this.Service.errorMsg = "";
            if (this.requiresApprovalData) {
              this.submittedApproval = true;
              this.router.navigate([
                "/user/requests/" +
                this.data.data.id,
              ]);
              this.submittingApproval = false;
              if (this.getApprovalsList) {
                this.updateApprovalsBadge();
              }
              return;
            }

            if (
                this.Service.RemoteConfg &&
                this.Service.RemoteConfg.enable_share_a_sale &&
                this.Service.RemoteConfg.enable_share_a_sale._value === "true" &&
                this.Service.sscid
            ) {
              this.Service.loadImg(
                  this.data.data.referenceNumber,
                  this.Service.sscid
              );
            }

            this.addGoogleEvents();
            sessionStorage.setItem("voucher", JSON.stringify(this.data));
            this.router.navigate([this.voucherUrl], {
              queryParams: {
                ref: this.data.data.referenceNumber,
                email: this.data.data.emailAddress,
                lastname: this.data.data.guestLastName,
              },
            });

          } else if (this.data.errorCode === 20) {
            this.router.navigateByUrl(this.previousUrl);
            this.Service.messagesOverRoute(this.errorMsg, "warning");
            this.Service.bookingSubmit = false;
            this.submittingApproval = false;
          } else if (this.data.errorCode === 54) {
            this.Service.setModal(
                '<i class="icon-warning"></i> warning',
                "completeDuplicateBooking",
                this.data.message
            );
            this.Service.bookingSubmit = false;
            this.submittingApproval = false;

          } else if (this.data.errorCode >= 500 && this.data.errorCode <= 599){
            if (this.data.errorCode === 503){
              window.location.href = this.data.data;
            } else {
              this.Service.errorMsg = $localize`:@@errorPayment:Error while processing the payment`;
              this.Service.bookingSubmit = false;
              this.submittingApproval = false;
            }
          } else {
            this.Service.errorMsg = this.errorMsg;
            this.googleAnalyticsService.eventEmitter(
                "EventStatus",
                "Booking-CC",
                "Failed",
                1
            );
            this.Service.bookingSubmit = false;
            this.submittingApproval = false;
          }
        },
        (error) => {
          this.Service.errorMsg = this.errorMsg;
          this.Service.bookingSubmit = false;
          this.submittingApproval = false;
        }
    );
    this.subscriptions.add(this.bookSubscription);
  }

  updateApprovalsBadge(){
    this.approvalsListSubscription = this.approvalsService.getListApproversApprovals(
        "PENDING_APPROVAL"
    ).subscribe(
        (dataApprove) => {
          this.data = dataApprove;
          if (this.data.errorCode === 0) {
            const pendingArray = this.data.data.filter(
                (item) =>
                    item.status.toLowerCase() === "pending_approval"
            );
            localStorage.setItem(
                "workspaceApprovalsBadge",
                pendingArray.length
            );
          } else {
            this.Service.messages(this.data.message, "error");
          }
          // Unsubscribe after data is received
          this.approvalsListSubscription?.unsubscribe();
        },
        (error) => {
          // Unsubscribe on error as well
          this.approvalsListSubscription?.unsubscribe();
          this.Service.messages(environment.messages.error, "error");
        }
    );
    //this.subscriptions.add(this.approvalsListSubscription);
  }

  addGoogleEvents(){
    this.googleAnalyticsService.eventEmitter(
        "EventStatus",
        "Booking-CC",
        "Successful",
        1
    );
    ga("ecommerce:clear");
    this.googleAnalyticsService.addTransaction(
        this.data.data.referenceNumber,
        this.Service.newRate.finalSellingPrice
    );
    // GA4
    let location = "";
    if (this.data.data.cityName) {
      location = this.data.data.cityName;
    }
    if (this.data.data.stateName) {
      location += ", " + this.data.data.stateName;
    }
    if (this.data.data.countryName) {
      location += ", " + this.data.data.countryName;
    }
    this.googleAnalytics4Service.addTransaction(
        this.data.data.currency.currency,
        this.data.data.referenceNumber,
        this.Service.newRate.finalSellingPrice,
        this.data.data.companyId,
        name,
        location,
        "Hotel",
        "Self"
    );
    // end of GA4
    this.googleAnalyticsService.addItem(
        this.data.data.referenceNumber,
        this.Service.newRate.finalSellingPrice,
        this.Service.hotelData.name
    );
  }

  checkRequireApproval(){
    this.Service.needRequestApproval = false;
    this.requireApprovalSubscription = this.approvalsService.requiresApprovalCheck(this.rate.rateKey).subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.requiresApprovalData = this.data.data;
            if (this.requiresApprovalData) {
              this.Service.needRequestApproval = true;
            }
          }
        }
    );
    this.subscriptions.add(this.requireApprovalSubscription);
  }

  inValidPaymentCard(): boolean{
    return this.noSelectedCard && this.payTypeMethod === PayType.SavedCards;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if(this.bookingWalletsService.bookingWalletsSubscription){
      this.bookingWalletsService.bookingWalletsSubscription.unsubscribe();
    }
  }
}
