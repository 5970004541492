<div
  class="workspace-login main-container"
  [ngClass]="{ 'gray-pass-bg border-0': dataService.desktopView }"
>
  <div
    class="container pb-5"
    [ngClass]="{
      'desktop-design text-center': dataService.desktopView,
      'mb-5': dataService.mobileView
    }"
  >
    <img
      width="471"
      height="422"
      loading="lazy"
      class="fixed-img rounded"
      src="assets/img/triphop-Signup-photo.jpg"
      *ngIf="dataService.desktopView"
    />
    <div
      class="login-form workspace-registration-container px-lg-5 py-5 corporate-box d-flex align-items-center"
      [ngClass]="{
        'white-box position-relative': dataService.desktopView,
        'mx-auto': dataService.mobileView,
        'mr-auto': dataService.isSiteLtr() && dataService.desktopView,
        'ml-auto': dataService.isSiteRtl() && dataService.desktopView
      }"
    >
      <div class="w-100">
        <ng-container *ngIf="!errorMsg">
          <h4
            class="font-20 mb-0 bold-600 pb-5 text-center text-danger"
            i18n="@@loginError1"
          >
            Your company domain is not registered
          </h4>
          <p
            class="gray-text font-16 pb-0 pb-lg-4 mb-0 mb-lg-3 text-center px-4"
            i18n="@@loginError2"
          >
            Contact your travel manager
          </p>
        </ng-container>
        <ng-container *ngIf="errorMsg">
          <h4
            class="font-24 mb-0 font-weight-bold pb-5 text-center text-danger"
          >
            {{ errorMsg }}
          </h4>
        </ng-container>
        <div class="row mt-4 d-flex justify-content-center">
          <div class="col-md-10"></div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-md-10">
            <button
              *ngIf="router.url !== '/slack/login'"
              class="mt-3 mb-2 btn-block btn font-14 blue-text btn-outline-blue font-weight-normal px-5 d-flex workspace-btn-padding"
              [ngClass]="{
                'ml-auto': dataService.isSiteLtr(),
                'mr-auto': dataService.isSiteRtl()
              }"
              (click)="
                this.dataService.signInErrorMsg = false;
                this.router.navigate([this.previousURL])
              "
            >
              <span class="mx-auto" i18n="@@backBtn">Back</span>
            </button>
            <button
              *ngIf="router.url === '/slack/login'"
              class="mt-3 mb-2 btn-block btn font-14 blue-text btn-outline-blue font-weight-normal px-5 d-flex workspace-btn-padding"
              [ngClass]="{
                'ml-auto': dataService.isSiteLtr(),
                'mr-auto': dataService.isSiteRtl()
              }"
              (click)="this.dataService.signInErrorMsg = false"
            >
              <span class="mx-auto" i18n="@@backBtn">Back</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
