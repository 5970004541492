export class SearchUser {
    description: string;
    id: string;
    email: string;
    teamName?: string;
    selected?: boolean;
    firstName?: string;
    lastName?: string;
    inactive?: boolean;
    profileImageBackgroundHexColor?: string;
    profileImage?: string;

    constructor({
                    description,
                    id,
                    email,
                    teamName,
                    selected = false,
                    firstName,
                    lastName,
                    inactive,
                    profileImageBackgroundHexColor,
                    profileImage,
                }: {
        description: string;
        id: string;
        email: string;
        teamName?: string;
        selected?: boolean;
        firstName?: string;
        lastName?: string;
        inactive?: boolean;
        profileImageBackgroundHexColor?: string;
        profileImage?: string;
    }) {
        this.description = description;
        this.id = id;
        this.email = email;
        this.teamName = teamName;
        this.selected = selected;
        this.firstName = firstName;
        this.lastName = lastName;
        this.inactive = inactive;
        this.profileImageBackgroundHexColor = profileImageBackgroundHexColor;
        this.profileImage = profileImage;
    }
}
