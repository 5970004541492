<div class="pb-3" [ngClass]="{'container': dataService.desktopView, 'pt-5': dataService.mobileView}">
<div class="d-flex mb-4">
  <i class="icon-warning-msg pt-1" [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
  <p class="gray-text-dark font-16 line-height-text"><ng-container i18n="@@DeclineBookMsg">Are you sure you want to decline the trip for</ng-container>
    <span class="font-weight-bold" [ngClass]="{'pl-2': dataService.isSiteLtr(), 'pr-2': dataService.isSiteRtl()}">{{this.requesterName}}</span></p>

</div>
<div class="container">
  <form [formGroup]="disapproveForm" name="userDeactivateForm" class="mb-5">

    <p class="font-weight-bold font-14 sharp-font" i18n="@@reason">Reason</p>

    <div class="form-group font-16 mb-0 gray-text-dark " *ngFor="let reason of reasonsArray">
      <input [ngClass]="{'mt-2 input-mobile': dataService.mobileView, 'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"
             type="radio" value="{{reason.text}}" id="{{reason.id}}" name="reasons"  formControlName="reasons"
             (change)="selectReason(reason.text)"/>
      <label  [ngClass]="{'radio-mobile': dataService.mobileView}" for="{{reason.id}}">{{reason.text}}</label>
    </div>
  </form>
  <div class="d-flex justify-content-end" *ngIf="dataService.desktopView">
    <button class="btn-outline-gray btn px-5 font-14" (click)="this.dataService.modalOf(); " i18n="@@cancelBtn"> Cancel </button>
    <button class="blue_btn px-5" [ngClass]="{'m-progress': submitting, 'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"
            (click)="this.confirmTripDisapproval(this.approvalId)" i18n="@@declineBtn">
      Decline
    </button>
  </div>
  <div *ngIf="dataService.mobileView">
    <button class="blue_btn px-5 w-100 mb-3" [ngClass]="{'m-progress': submitting }"
            (click)="this.confirmTripDisapproval(this.approvalId)" i18n="@@declineBtn">
      Decline
    </button>
    <button class="btn-outline-gray btn px-5 font-14 w-100" (click)=" this.dataService.BottomSheetOf();" i18n="@@cancelBtn"> Cancel </button>
  </div>
</div>
  <div class="text-danger mt-3" [ngClass]="{'text-right': dataService.desktopView }" *ngIf="this.errorMsg">{{this.errorMsg}}</div>
</div>
