import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {environment} from '../../../environments/environment';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-savings-calculator',
  templateUrl: './savings-calculator.component.html',
  styleUrls: ['./savings-calculator.component.scss']
})
export class SavingsCalculatorComponent implements OnInit {

  constructor(public dataService: DataService) { }
  width = 0;
  backIndex;
  counter = 0;
  listOfQuestionsText = [];
  data;
  errorMsg;
  fromValue;
  toValue;
  listOfEachQuestionSuggestions = [];
  finalAnswers = [];
  answerControl = new FormControl();
  ngOnInit(): void {

    this.dataService.getSavingsCalculatorQuestions().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          for (let i in this.data.data.questions) {
            this.listOfQuestionsText.push(this.data.data.questions[i].text);
            this.listOfEachQuestionSuggestions[i] = [];
            for (let y in this.data.data.questions[i].suggestions) {
                this.listOfEachQuestionSuggestions[i].push(this.data.data.questions[i].suggestions[y]);
            }
          }
        } else {
          this.errorMsg = this.data.message;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
      }
    );
  }
 reset() {
   this.width = 0;
   this.counter = 0;
   this.finalAnswers = [];
 }
  cal(index, isBack) {
    this.width = (100 / (this.listOfQuestionsText.length + 1)) * index;
    this.backIndex = index - 1;

    if (isBack) {
      this.counter--;
    } else {
      this.counter++;
      if (index <= this.listOfQuestionsText.length + 1 && index > 1) {
        const selectedAnswerObj = {
          questionId: index - 1,
          suggestionId: this.answerControl.value
        };
        if (this.finalAnswers[index - 2]) {
          this.finalAnswers[index - 2] = selectedAnswerObj;
        } else {
          this.finalAnswers.push(selectedAnswerObj);
        }

      }
    }
    if(this.finalAnswers[this.backIndex]) {
      this.answerControl.setValue(this.finalAnswers[this.backIndex].suggestionId);
    } else {
      this.answerControl.setValue(null);
    }
    if (this.counter > this.listOfQuestionsText.length) {
      const answers = {
        answers: this.finalAnswers
      };
      this.dataService.calculateSavings(answers).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.fromValue = this.data.data.HOTELS.from;
            this.toValue = this.data.data.HOTELS.to;
          } else {
            this.errorMsg = this.data.message;
          }
        }, error => {
          this.errorMsg = environment.messages.error;
        }
      );
    }
  }

}
