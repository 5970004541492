import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataService } from "../../../services/data.service";
import { Router } from "@angular/router";
import { AuthServiceMain } from "../../../auth/auth.service";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-company-login-form",
  templateUrl: "./company-login-form.component.html",
  styleUrls: ["./company-login-form.component.scss"],
})
export class CompanyLoginFormComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    public dataService: DataService,
    public router: Router,
    public auth: AuthServiceMain
  ) {}
  loginForm: FormGroup;
  submitted = false;
  submitting = false;
  errorMsg = "";
  data;

  ngOnInit(): void {
    this.dataService.signInErrorMsg = false;
    this.loginForm = this.formBuilder.group(
      {
        email: ["", [Validators.required, Validators.email]],
      },
      {}
    );
  }

  get c() {
    return this.loginForm.controls;
  }

  onSubmitLogin() {
    this.submitted = true;
    this.submitting = true;
    if (this.loginForm.invalid) {
      this.submitting = false;
      return;
    }
    this.dataService.companyEmail = this.loginForm.controls.email.value;
    this.dataService
      .getCompanyByEmail(this.loginForm.controls.email.value)
      .subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            if (this.data.data) {
              this.dataService.companyId = this.data.data.id;
              this.dataService.companyName = this.data.data.name;
              this.dataService.companyDomain = this.data.data.domain;
              if (this.router.url === "/slack/login") {
                this.dataService.companyIdForSlackLogin = this.dataService.companyId;
              } else {
                this.dataService.showFullLoginForm.next(true);
                //this.router.navigate(["/" + this.dataService.companyId + "/login"]);
              }
            } else {
              this.submitting = false;
              this.dataService.signInErrorMsg = true;
            }
          } else {
            this.submitting = false;
            this.dataService.signInErrorMsg = true;
          }
        },
        (error) => {
          this.submitting = false;
          this.errorMsg = environment.messages.error;
        }
      );
  }
}
