import { Component, ElementRef, OnInit } from "@angular/core";
import { onMainContentChange } from "../../../workspace-dashboard/animations/animations";
import { FormBuilder } from "@angular/forms";
import { DataService } from "../../../../services/data.service";
import { SidenavService } from "../../../workspace-dashboard/sidenav.service";
import { Router } from "@angular/router";
import { AuthServiceMain } from "../../../../auth/auth.service";
import { Options } from "ng5-slider";
import { environment } from "../../../../../environments/environment";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-workspace-rewards-settings",
  templateUrl: "./workspace-rewards-settings.component.html",
  styleUrls: ["./workspace-rewards-settings.component.scss"],
  animations: [onMainContentChange],
})
export class WorkspaceRewardsSettingsComponent implements OnInit {
  constructor(
    private titleService: Title,
    private formBuilder: FormBuilder,
    private eref: ElementRef,
    public dataService: DataService,
    private sidenavService: SidenavService,
    public router: Router,
    public auth: AuthServiceMain
  ) {
    this.titleService.setTitle("Rewards setting | SafarHub");
    this.sidenavService.sideNavState$.subscribe((res) => {
      this.dataService.onSideNavChange = res;
    });
  }
  isHotelRewardsChecked = false;
  isFlightRewardsChecked = false;
  hotelRewardsValue = 0;
  flightRewardsValue = 0;
  optionsHotels: Options = {
    floor: 0,
    ceil: 100,
    step: 1,
    animate: false,
    showOuterSelectionBars: true,
    disabled: true,
  };
  optionsFlights: Options = {
    floor: 0,
    ceil: 100,
    step: 1,
    animate: false,
    showOuterSelectionBars: true,
    disabled: true,
  };
  submitting;
  data;
  startEdit;
  currencyCode: string = "SAR";
  maximumAmountPerHotelBooking: number;
  maximumAmountPerFlightBooking: number;
  flightErrorMsg;
  hotelErrorMsg;
  ngOnInit(): void {
    if (this.dataService.isSiteRtl()) {
      this.optionsHotels.rightToLeft = true;
      this.optionsFlights.rightToLeft = true;
    }

    if (this.auth.companyInfo.rewardProgram) {
      this.dataService.showSliders = true;
      this.dataService.showSliders = true;
      this.hotelRewardsValue =
      this.auth.companyInfo.rewardProgram.hotelSavingsPercentage ? this.auth.companyInfo.rewardProgram.hotelSavingsPercentage * 100 : 0;
      this.flightRewardsValue =
      this.auth.companyInfo.rewardProgram.flightSavingsPercentage ? this.auth.companyInfo.rewardProgram.flightSavingsPercentage * 100 : 0;
      this.maximumAmountPerFlightBooking =
        this.auth.companyInfo.rewardProgram.maximumFlightReward;
      this.maximumAmountPerHotelBooking =
        this.auth.companyInfo.rewardProgram.maximumHotelReward;
    } else {
      this.dataService.showSliders = false;
    }

    if(this.auth.companyInfo.rewardProgram){
    if (this.auth.companyInfo.rewardProgram.hotelSavingsPercentage) {
      this.isHotelRewardsChecked = true;
      this.optionsHotels = Object.assign({}, this.optionsHotels, {
        disabled: !this.isHotelRewardsChecked,
      });
    }
    if (this.auth.companyInfo.rewardProgram.flightSavingsPercentage) {
      this.isFlightRewardsChecked = true;
      this.optionsFlights = Object.assign({}, this.optionsFlights, {
        disabled: !this.isFlightRewardsChecked,
      });
    }      
    }

  }
  enableRewards(rewardType) {
    this.startEdit = true;
    if (rewardType === "hotels") {
      //  this.isHotelRewardsChecked = !this.isHotelRewardsChecked;
      this.optionsHotels = Object.assign({}, this.optionsHotels, {
        disabled: !this.isHotelRewardsChecked,
      });
      /*  if (!this.isHotelRewardsChecked) {
        this.hotelRewardsValue =  0;
      }*/
    } else {
      // this.isFlightRewardsChecked = !this.isFlightRewardsChecked;
      this.optionsFlights = Object.assign({}, this.optionsFlights, {
        disabled: !this.isFlightRewardsChecked,
      });
      /*  if (!this.isFlightRewardsChecked) {
        this.flightRewardsValue = 0;
      }*/
    }
  }

  numbersOnly(event: any) {
    const inputValue = event.target.value;
    // Replace any non-numeric characters
    event.target.value = inputValue.replace(/[^0-9]/g, "");
  }

  createMyProgramAction() {
    this.isHotelRewardsChecked = false;
    this.isFlightRewardsChecked = false;
    this.hotelRewardsValue = 0;
    this.flightRewardsValue = 0;
    this.dataService.showSliders = true;
    this.dataService.alerts = [];
  }

  createOrEditRewards(process) {
    this.dataService.alerts = [];
    this.submitting = true;
    this.flightErrorMsg = "";
    this.hotelErrorMsg = "";
    if (this.isHotelRewardsChecked && !this.hotelRewardsValue) {
      this.submitting = false;
      this.hotelErrorMsg = $localize`:@@plsCorrectHotelReward:Please select a correct hotel reward percentage`;
      return;
    }
    if (this.isFlightRewardsChecked && !this.flightRewardsValue) {
      this.submitting = false;
      this.flightErrorMsg = $localize`:@@plsCorrectFlightReward:Please select a correct flight reward percentage`;
      return;
    }
    if (!this.isHotelRewardsChecked && this.auth.companyInfo.rewardProgram) {
      this.hotelRewardsValue = 0;
      this.maximumAmountPerHotelBooking = null;
    }
    if (!this.isFlightRewardsChecked && this.auth.companyInfo.rewardProgram) {
      this.flightRewardsValue = 0;
      this.maximumAmountPerFlightBooking = null;
    }

    // const flightPercentage = this.flightRewardsValue / 100;
    // const hotelPercentage = this.hotelRewardsValue / 100;
    let rewardObj: any = {
      flightSavingsPercentage: this.flightRewardsValue != null &&  this.flightRewardsValue != 0? this.flightRewardsValue / 100: null,
      hotelSavingsPercentage: this.hotelRewardsValue != null && this.hotelRewardsValue != 0 ? this.hotelRewardsValue / 100 : null,
      currencyCode: this.currencyCode,
      maximumHotelReward: this.maximumAmountPerHotelBooking != null ? this.maximumAmountPerHotelBooking.toString().replace(/[^0-9]/g, '') : null,
      maximumFlightReward: this.maximumAmountPerFlightBooking != null ? this.maximumAmountPerFlightBooking.toString().replace(/[^0-9]/g, '') : null,
    };

    if(!this.maximumAmountPerHotelBooking && this.isHotelRewardsChecked){
      this.submitting = false;
      this.hotelErrorMsg = $localize`:@@hotelMaximumAmountPerBookingRequired:Hotel Maximum amount per booking is required`;
      return;
    }else if(this.maximumAmountPerHotelBooking){
      rewardObj.maximumHotelReward = this.maximumAmountPerHotelBooking.toString().replace(/[^0-9]/g, '');
    }

    if(!this.maximumAmountPerFlightBooking && this.isFlightRewardsChecked){
      this.submitting = false;
      this.flightErrorMsg = $localize`:@@flightMaximumAmountPerBookingRequired:Flight Maximum amount per booking is required`;
      return;
    }else if(this.maximumAmountPerFlightBooking){
      rewardObj.maximumFlightReward = this.maximumAmountPerFlightBooking.toString().replace(/[^0-9]/g, '');
    }

    this.dataService.saveRewardProgram(rewardObj).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.submitting = false;
          const rewardObj = {
            flightSavingsPercentage: this.data.data.flightSavingsPercentage,
            hotelSavingsPercentage: this.data.data.hotelSavingsPercentage,
            currencyCode: this.data.data.currencyCode,
            maximumHotelReward: this.data.data.maximumHotelReward,
            maximumFlightReward: this.data.data.maximumFlightReward,
          };
          this.maximumAmountPerHotelBooking = this.data.data.maximumHotelReward;
          this.maximumAmountPerFlightBooking =
            this.data.data.maximumFlightReward;
          this.auth.companyInfo.rewardProgram = rewardObj;
          localStorage.setItem(
            "companyInfo",
            JSON.stringify(this.auth.companyInfo)
          );
          this.dataService.fetchCompanyData.next();

          //this.auth.fetchCompanyData();
          this.startEdit = false;
          if (process === "create") {
            this.dataService.messages(
              $localize`:@@rewardProgramCreated:Reward program was created successfully`,
              "success"
            );
          } else {
            this.dataService.messages(
              $localize`:@@rewardProgramUpdated:Reward program was updated successfully`,
              "success"
            );
          }
        } else {
          this.dataService.messages(this.data.message, "error");
          this.submitting = false;
        }
      },
      (error) => {
        this.dataService.messages(environment.messages.error, "error");
        this.submitting = false;
      }
    );
  }

  deleteRewardsProgram() {
    const flightPercentage = this.flightRewardsValue / 100;
    const hotelPercentage = this.hotelRewardsValue / 100;
    const rewardObj = {
      flightSavingsPercentage: flightPercentage,
      hotelSavingsPercentage: hotelPercentage,
      currencyCode: this.currencyCode,
      maximumHotelReward: this.maximumAmountPerHotelBooking,
      maximumFlightReward: this.maximumAmountPerFlightBooking,
    };
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@deleteRewardsProg:Delete Rewards Program`,
        "deleteRewardsProgram",
        rewardObj
      );
    } else {
      this.dataService.setBottomSheet("deleteRewardsProgram", rewardObj);
    }
  }
}
