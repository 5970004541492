import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { AuthServiceMain } from "./auth/auth.service";
import { ActivatedRoute, NavigationStart } from "@angular/router";
import { DataService } from "./services/data.service";
import { Router, NavigationEnd } from "@angular/router";
import { environment } from "../environments/environment";
import { HostListener } from "@angular/core";
declare const document: any;

import { slideInAnimation } from "./route-animation";

import { RouterOutlet } from "@angular/router";

//
import "rxjs-compat/add/operator/filter";

import { FireService } from "./services/firebase.service";
import { Subscription } from "rxjs";
export let browserRefresh = false;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit {
  utms;
  isPromoPopupUTM = true;
  data;
  message;
  previousUrl: string;
  CurrentUrl: string;
  showOverlay = true;
  subscription: Subscription;
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    public FireService: FireService,
    public authService: AuthServiceMain,
    private route: ActivatedRoute,
    public Service: DataService,
    public router: Router
  ) {
    document.documentElement.setAttribute("lang", this.locale);
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
    // this.Service.loadScriptFirebase()
    router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((e) => {
        let Current;
        Current = e;
        this.Service.GlobelPreviousUrl = this.CurrentUrl;
        this.CurrentUrl = Current.url;
      });
  }
  public mostPopular = [
    {
      itemId: "1",
      image_title: "Las Vegas",
      url: "assets/img/Popular/Las-Vegas.jpg",
      no_of_hotels: "380 hotels",
      searchCode: "ChIJ0X31pIK3voARo3mz1ebVzDo",
    },

    {
      itemId: "2",

      image_title: "Chicago",
      url: "assets/img/Popular/chicago.jpg",
      no_of_hotels: "360 hotels",
      searchCode: "ChIJ7cv00DwsDogRAMDACa2m4K8",
    },
    {
      itemId: "3",

      image_title: "Nashville",
      url: "assets/img/Popular/Nashville.jpg",
      no_of_hotels: "217 hotels",
      searchCode: "ChIJPZDrEzLsZIgRoNrpodC5P30",
    },
    {
      itemId: "4",

      image_title: "New Orleans",
      url: "assets/img/Popular/new-orleans.jpg",
      no_of_hotels: "278 hotels",
      searchCode: "ChIJZYIRslSkIIYRtNMiXuhbBts",
    },
    {
      itemId: "5",

      image_title: "New York",
      url: "assets/img/Popular/NewYork.jpg",
      no_of_hotels: "960 hotels",
      searchCode: "ChIJOwg_06VPwokRYv534QaPC8g",
    },
    {
      itemId: "6",

      image_title: "San Diego",
      url: "assets/img/Popular/san-diego2.jpg",
      no_of_hotels: "327 hotels",
      searchCode: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
    },
    {
      itemId: "7",

      image_title: "Myrtle Beach",
      url: "assets/img/Popular/Myrtle-Beach.jpg",
      no_of_hotels: "560 hotels",
      searchCode: "ChIJASFVO5VoAIkRGJbQtRWxD7w",
    },
    {
      itemId: "8",

      image_title: "San Francisco",
      url: "assets/img/Popular/SanFrancisco.jpg",
      no_of_hotels: "346 hotels",
      searchCode: "ChIJIQBpAG2ahYAR_6128GcTUEo",
    },

    {
      itemId: "9",

      image_title: "Washington, DC",
      url: "assets/img/Popular/washington-dc.jpg",
      no_of_hotels: "298 hotels",
      searchCode: "ChIJW-T2Wt7Gt4kRKl2I1CJFUsI",
    },
  ];
  title = "triphop-website-revamp";
  innerWidth;
  iswhitelist = false;
  ngOnInit() {
    const bodyElm = document.getElementsByTagName("body")[0];
    if (
      (localStorage.getItem("locale") &&
        localStorage.getItem("locale") === "ar") ||
      this.locale.includes("ar")
    ) {
      this.Service.siteDirection = "Rtl";
    } else {
      this.Service.siteDirection = "Ltr";
    }
    bodyElm.classList.add(
      "site-dir-" + this.Service.siteDirection.toLowerCase()
    );
    const htmlTag = document.getElementsByTagName("html")[0];
    this.Service.corporateLocale = htmlTag.getAttribute("lang");

    setTimeout(() => {
      this.showOverlay = false;
    }, 2000);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (environment.activateZendesk && this.getPagesForZendesk()) {
          setTimeout(
            () =>
              this.Service.loadScript(
                "https://static.zdassets.com/ekr/snippet.js?key=" +
                  environment.zendeskKey
              ),
            environment.ZendeskTimeOut
          );
        } else {
          this.Service.removeZendeskScript();
        }
        this.authService.checkPagePermission();
        this.Service.selectedTrip = "";
        this.Service.alerts = [];
        if (
          !this.router.url.includes("flights/availability") &&
          !this.router.url.includes("/availability/booking") &&
          !this.router.url.includes("/hold-room") &&
          !this.router.url.includes("availability/hotel")
        ) {
          this.Service.alertsOverRoute = [];
        }
        if (
          this.Service.isCompany() &&
          (this.router.url.includes("/availability/booking") ||
            this.router.url.includes("profile"))
        ) {
          const body = document.getElementsByTagName("body")[0];
          body.classList.add("booking-page");
        } else {
          const body = document.getElementsByTagName("body")[0];
          body.classList.remove("booking-page");
        }
        const urlArray = this.router.url.split("/");
        if (
          !(
            (this.router.url.includes("availability") &&
              !this.router.url.includes("/availability/booking")) ||
            urlArray.length === 2
          )
        ) {
          const body = document.getElementsByTagName("body")[0];
          body.classList.remove("search-page");
        }
        this.iswhitelist =
          environment.PromoPopup.whitelist.indexOf(window.location.pathname) >
          -1;
        this.Service.urlWithoutParams = window.location.pathname;
        if (!environment.production) {
        }
        setTimeout(() => {
          (<any>window).ga("send", "pageview", {
            page: this.Service.urlWithoutParams,
          });
        }, 1000);
      }
    });
    this.Service.holdRoom = false;
    const dis = this;
    window.addEventListener("storage", function (e) {
      if (e.key === "user" || e.key === "confirmPrime") {
        localStorage.removeItem("confirmPrime");
        dis.authService.fetchUserData();
        setTimeout(() => {
          dis.authService.autoLogin();
        }, 750);
      }
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 400);

      const widget = document.getElementById("launcher");
      if (!this.router.url.includes("/availability/hotel") && widget) {
        widget.classList.add("hide-widget");
      } else if (widget) {
        widget.classList.remove("hide-widget");
      }
    });

    this.authService.autoLogin();
    this.innerWidth = window.innerWidth;
    if (innerWidth <= 992) {
      this.Service.mobileView = true;
      this.Service.desktopView = false;
    } else {
      this.Service.mobileView = false;
      this.Service.desktopView = true;
    }
    this.route.queryParams.subscribe((params) => {
      this.utms = params;
      if (Object.keys(this.utms).length > 0) {
        for (const utm in this.utms) {
          if (this.utms.hasOwnProperty(utm) && utm.indexOf("utm_") > -1) {
            localStorage.setItem(utm, this.utms[utm]);
          }
          if (this.utms.hasOwnProperty("token")) {
            this.authService.handleAuthentication(this.utms.token);
            this.authService.fetchUserData();
            const Objparams = { ...this.route.snapshot.queryParams };
            delete Objparams.token;
            this.Service.GlobelPreviousUrl = window.location.pathname;
            this.Service.urlWithoutParams = window.location.pathname;
            this.Service.GlobelPreviousUrlFunc();
            this.router.navigate([this.Service.urlWithoutParams], {
              queryParams: Objparams,
            });
          }
        }
      }

      for (const UTMblockUtm in this.utms) {
        for (const blockUtm in environment.PromoPopup.blockPopupUtms) {
          if (
            blockUtm === UTMblockUtm &&
            environment.PromoPopup.blockPopupUtms[blockUtm].indexOf(
              this.utms[UTMblockUtm]
            ) > -1
          ) {
            this.isPromoPopupUTM = false;
            localStorage.setItem("closedpopUp", "true");

            //  localStorage.setItem('stop_popup')
          }
        }
      }

      if (
        localStorage.getItem("closedpopUp") ||
        localStorage.getItem("leads_email") ||
        localStorage.getItem("stop_popup") ||
        localStorage.getItem("user")
      ) {
        this.Service.closedPopUp = false;
      }
      if (localStorage.getItem("leads_email") || localStorage.getItem("user")) {
        this.authService.isLead = true;

        this.Service.hideSubcont = false;
      } else {
        this.authService.isLead = false;
      }

      if (
        environment.PromoPopup.enableLandingPopup &&
        this.isPromoPopupUTM &&
        this.iswhitelist &&
        this.authService.isLogedIn === false &&
        this.Service.hideSubcont
      ) {
        this.Service.SubscribeBoxShow = true;
      }

      if (
        environment.PromoPopup.enableLandingPopup &&
        this.isPromoPopupUTM &&
        this.iswhitelist &&
        this.authService.isLogedIn === false &&
        this.Service.closedPopUp
      ) {
        this.Service.PromoBoxShow = true;
        setTimeout(() => {
          this.Service.setModal("", "PromoBox", "");

          this.Service.PromoBox = true;

          if (this.Service.modalName === "PromoBox") {
            const body = document.getElementsByTagName("body")[0];
            body.classList.add("modal-promo");
          }
        }, environment.PromoPopup.landingPopupTimeoutInSeconds * 1000);
      }

      if (this.utms.mid) {
        this.Service.checkMail(this.utms.mid).subscribe((data) => {
          this.data = data;
          if (this.data.errorCode === 0 && this.data.data) {
            localStorage.setItem("leads_email", this.data.data.email);
            this.authService.isLead = true;
            this.Service.leadEmail = this.data.data.email;
            this.Service.loginType = this.data.data.loginType;
          } else {
          }
          this.Service.leadChangeFunc();
        });
      }
    });
    this.FireService.initializeAppFirebase();
    if (this.authService.isPrime) {
      this.Service.isPass().subscribe((data) => {
        let res: any = data;
        if (!res.data) {
          this.authService.fetchUserData();
        }
      });
    }
  }
  prepareRoute(outlet: RouterOutlet) {
    return outlet.activatedRouteData.state;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.Service.NotifymodalOf();
  }

  getPagesForZendesk() {
    let showZendeskInThisPage = false;
    let pages = [
      "user/trips",
      "hotel/booking/details",
      "flight/booking/details",
      "availability/booking",
      "flights/availability/booking",
      "user/profile",
      "user/payment",
    ];
    pages.forEach((pageUrl) => {
      if (this.router.url.includes(pageUrl)) {
        showZendeskInThisPage = true;
      }
    });
    return showZendeskInThisPage;
  }
}
