import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-flight-rewards',
  templateUrl: './flight-rewards.component.html',
  styleUrls: ['./flight-rewards.component.scss']
})
export class FlightRewardsComponent implements OnInit {

  constructor() { }
  @Input() rewardAmount;
  @Input() rewardCurrency;
  ngOnInit(): void {
  }

}
