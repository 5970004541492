<div class="container create-team pb-5">
  <div [ngClass]="{'w-75' : this.dataService.desktopView}">







    <p class="gray-primaray-text bold-500 mb-2 mt-4 sharp-font" i18n="@@expensifyPolicy">Expensify Policy</p>
    <mat-form-field  class="mb-3 border w-100 chips-container rounded"
                     *ngIf="dataService.desktopView" (click)="policyInput.select()">
      <input class="min-height mx-0 my-0"
             [ngClass]="{' pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
             #policyInput type="text" matInput [formControl]="policyControl" [matAutocomplete]="autoPolicy" (blur)="resetPolicy()">
      <mat-autocomplete #autoPolicy="matAutocomplete" [displayWith]="displayFnPolicy">
        <mat-option style="padding: 0" *ngFor="let item of filteredPolicies | async" [value]="item">
          <div (click)="optionClickedPolicy($event, item);" class="mat-checkbox-container">
            {{item.name}}
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="form-control rounded border" *ngIf="dataService.mobileView" (click)="showPolicyPopUp()">
      <p *ngIf="selectedPolicy" class="mt-2 pt-1">{{selectedPolicy.name}}</p>
    </div>


















    <p class="gray-primaray-text bold-500 mb-2 mt-4 sharp-font" i18n="@@expensifyReportName">Expensify Report Name</p>
    <mat-form-field  class="mb-3 border w-100 chips-container rounded"
                     *ngIf="dataService.desktopView" (click)="itemInput.select()">
      <input class="min-height mx-0 my-0"
             [ngClass]="{' pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
             #trigger="matAutocompleteTrigger"  #itemInput type="text" matInput [formControl]="inputControl" [matAutocomplete]="auto"
             (blur)="reportNameText()">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option style="padding: 0" *ngFor="let item of filteredItems | async" [value]="item">
          <div (click)="optionClicked($event, item);" class="mat-checkbox-container">
            {{item.reportName}}
          </div>
        </mat-option>
        <mat-option style="padding: 0" *ngIf="showCreateNew">
          <div (click)="trigger.closePanel();" class="mat-checkbox-container">
            {{itemInput.value}} <ng-container i18n="@@creatNewReport">(Creat new report)</ng-container>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="form-control rounded border" *ngIf="dataService.mobileView" (click)="showPopUp()">
      <p *ngIf="selectedItem" class="mt-2 pt-1">{{selectedItem.reportName}}</p>
    </div>



<!--<div *ngIf="this.expensesData">
  <p class="gray-text mb-1 mt-4">Expenses Type: {{this.expensesData.expensesType}}</p>
  <p class="gray-text mb-1">Expenses Type: {{this.expensesData.expensesName}}</p>
  <p class="gray-text">Expenses Type: {{this.expensesData.expensesAmount}}</p>
</div>-->
  </div>

  <br><br>
  <div class="d-flex justify-content-end" *ngIf="dataService.desktopView">
    <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" (click)="dataService.modalOf();" i18n="@@cancelBtn">Cancel </button>
    <button class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5" (click)="upload()" [ngClass]="{'m-progress': submitting,
    'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@upload">Upload</button>
  </div>

  <div *ngIf="dataService.mobileView">
    <div class="d-flex justify-content-end">
      <button class="btn blue_btn font-14 sharp-font bold-500 py-1 mb-4 px-5 w-100" (click)="upload()" [ngClass]="{'m-progress': submitting}" i18n="@@upload">Upload</button>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5 w-100" (click)="dataService.BottomSheetOf()" i18n="@@cancelBtn">Cancel </button>
    </div>
  </div>
<p class="mt-3 text-danger" [ngClass]="{'text-right': dataService.desktopView}">{{this.errorMsg}}</p>
</div>




<div class="justify-content-end create-team" >

  <div [ngClass]="{'in action-popup':showAutoCompletePopUp || showPolicyAutoCompletePopUp}">
    <div [ngClass]="{'header':showAutoCompletePopUp || showPolicyAutoCompletePopUp}" >
      <div *ngIf="showAutoCompletePopUp || showPolicyAutoCompletePopUp">
        <div *ngIf="showAutoCompletePopUp || showPolicyAutoCompletePopUp">

          <div class="info">
            <span *ngIf="showAutoCompletePopUp" i18n="@@expensifyReportName">Expensify Report Name</span>
            <span *ngIf="showPolicyAutoCompletePopUp" i18n="@@expensifyPolicy">Expensify Policy</span>
          </div>
         <!-- <div class="done" >
            <span (click)="optionClickedMobile(); hidePopUp();">Done</span>
          </div>-->
          <div class="close" (click)="showAutoCompletePopUp = false; showPolicyAutoCompletePopUp = false; this.dataService.enableBodyScroll();">Cancel</div>
        </div>
      </div>




      <div class="position-relative mobile-search-field" *ngIf="showPolicyAutoCompletePopUp">
        <div class="form-group mb-3">
          {{selectedPolicy}}
          <mat-form-field class="w-100 chips-container border rounded">
            <input class="bg-white min-height mx-0 my-0"
                   [ngClass]="{' pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                   #policyInput id="policyInput" type="text" matInput [formControl]="policyControl" [matAutocomplete]="autoPolicy">
            <mat-autocomplete #autoPolicy="matAutocomplete" [displayWith]="displayFnPolicy">
              <mat-option style="padding: 0" *ngFor="let item of filteredPolicies | async" [value]="item">
                <div (click)="optionClickedPolicy($event, item)" class="mat-checkbox-container">
                  {{item.name}}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="position-relative mobile-search-field" *ngIf="showAutoCompletePopUp">
        <div class="form-group mb-3">
          <mat-form-field class="w-100 chips-container border rounded">
            <input class="bg-white min-height mx-0 my-0"
                   [ngClass]="{' pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                   #itemInput id="itemInput" type="text" matInput [formControl]="inputControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option style="padding: 0" *ngFor="let item of filteredItems | async" [value]="item">
                <div (click)="optionClicked($event, item)" class="mat-checkbox-container">
                  {{item.reportName}}
                </div>
              </mat-option>

              <mat-option style="padding: 0" *ngIf="showCreateNew">
                <div (click)="optionClicked($event, null)" class="mat-checkbox-container">
                  {{itemInput.value}} <ng-container i18n="@@creatNewReport">(Creat new report)</ng-container>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </div>
      </div>



    </div>



  </div>
</div>
