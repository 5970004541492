import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../../../services/data.service";

@Component({
  selector: 'app-hotel-policy-details-section',
  templateUrl: './hotel-policy-details-section.component.html',
  styleUrls: ['./hotel-policy-details-section.component.scss']
})
export class HotelPolicyDetailsSectionComponent implements OnInit {
  @Input() showPolicyDetails;
  @Input() policyDetails;
  @Input() availabilityMedianPrice;
  @Input() availabilityMedianCurrency;
  @Input() hasException;
  @Input() exceptionCurrency;
  @Input() exceptionValue;
  @Input() policyCurrency;
  Math = Math;
  constructor(public Service: DataService) { }

  ngOnInit(): void {
  }

}
