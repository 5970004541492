<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
    [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pb-5 pt-4 pt-lg-5">
        <div class="row mx-lg-4">

          <div class="col">
            <div class="pb-4 pb-lg-5 mb-3">
              <a class="font-16 blue-text text-underline" (click)="this.router.navigate(['/settings']);"
                i18n="@@menuItemSettings">Settings</a>
              <span class="font-16 gray-text-dark "> / <ng-container i18n="@@employeeRewards">Employee Rewards
                </ng-container> </span>
            </div>
          </div>

        </div>
        <p [ngClass]="{'mx-5 mb-5': dataService.desktopView, 'mx-2 mb-3': dataService.mobileView}" class="font-18 gray-primaray-text" i18n="@@rewardsProgram">{{auth.companyInfo.name}} Rewards
          Program</p>
        <div class="main-dashboard-content mb-3 pb-3"
          *ngIf="!auth.companyInfo.rewardProgram && !dataService.showSliders">
          <div class="mt-3 mx-lg-0 px-4 px-lg-5 py-4 company-section-with-thick-border border" [ngClass]="{'minus-margin': dataService.desktopView,
                            'rounded-right': dataService.desktopView && dataService.isSiteLtr(), 'rounded-left': dataService.desktopView && dataService.isSiteRtl(),
                            'thick-top rounded-bottom': dataService.mobileView}">
            <div class="row py-3 h-100 justify-content-center align-items-center">
              <div class="col-md-9">
                <p class="font-18 bold-600 primaray-text" i18n="@@rewardsProgMsg1">You do not have an active rewards
                  program.</p>
                <p><span class="font-16 gray-text-dark" i18n="@@rewardsProgMsg2">Rewards program incentivize your
                    employees
                    to book lower prices than the travel policy cap which result in more travel spend savings.</span>
                </p>
              </div>
              <div class="col-md-3"
                [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">
                <button class="btn btn-outline-blue font-14 px-5 sharp-font bold-500 w-90"
                  [ngClass]="{'w-100 mt-2':
                dataService.mobileView, 'btn-outline-blue': auth.companyInfo && auth.companyInfo.inactive === false, 'btn-outline-gray': auth.companyInfo && auth.companyInfo.inactive}"
                  (click)="createMyProgramAction()" i18n="@@createProg">Create My
                  Program
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row mx-lg-4 pb-5">
          <div class="col-lg-6" [ngClass]="{'px-2': dataService.mobileView}">
            <div class="row w-100" [ngClass]="{'mx-0': dataService.mobileView}">
              <div class="main-dashboard-content" *ngIf="auth.companyInfo.rewardProgram || dataService.showSliders">
                <div class="ml-3" [ngClass]="{
                  'mr-3 ml-0': dataService.mobileView && dataService.isSiteRtl()
                }">
                  <div>
                    <div [ngClass]="{
          'rounded border workspace-rounded-container':
            dataService.desktopView,
          'pl-4': dataService.desktopView && dataService.isSiteLtr(),
          'pr-4': dataService.desktopView && dataService.isSiteRtl(),
          'mb-5': dataService.mobileView
        }">
                      <div class="row">
                        <div class="min-left-side-col-width">
                          <div class="container">
                            <div class="d-flex">
                              <p class="font-18 pt-3" i18n="@@hotelRewardsLabel">Hotel Rewards</p>

                              <mat-slide-toggle class="mt-3" [(ngModel)]="isHotelRewardsChecked" [ngClass]="{
                                'pl-4': dataService.isSiteLtr(),
                                'pr-4': dataService.isSiteRtl()
                              }" (click)="
                              enableRewards('hotels');                       
                              " (change)=" enableRewards('hotels')">
                              </mat-slide-toggle>
                            </div>
                            <div>
                              <div class="form-item mt-2 pb-1 d-flex">
                                <label [ngClass]="{'checkbox-mobile': dataService.mobileView}" for="hotel-rewards"
                                  class="gray-text-dark font-16" i18n="@@hotelRewards">Rewards employees for savings
                                  on
                                  hotel booking</label>
                              </div>
                              <div class="d-flex gray-text">
                                <div class="color-border box bold-600 rounded font-12 ng-tns-c551-1 py-1 text-center"
                                  [ngClass]="{'disabled-slider placeholder-text': !isHotelRewardsChecked, 'green-text': isHotelRewardsChecked,
                         'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}">{{hotelRewardsValue}}%
                                </div>
                                <ng-container i18n="@@fromTotalBelowCap">From the total amount below policy cap
                                </ng-container>
                              </div>
                              <div class="custom-slider" [ngClass]="{'disabled-slider': !isHotelRewardsChecked}">
                                <ng5-slider [(value)]="hotelRewardsValue" [options]="optionsHotels"
                                  (userChangeEnd)="startEdit = true"></ng5-slider>
                              </div>
                            </div>
                            <div class="form-group px-0 font-16">
                              <label for="maximumHotelBooking" class="gray-text-dark"
                                i18n="@@maximumAmountPerBooking">Maximum amount per booking</label>
                              <input (input)="numbersOnly($event); startEdit = true" [disabled]="!isHotelRewardsChecked"
                                id="maximumHotelBooking" name="maximumHotelBooking"
                                [(ngModel)]="maximumAmountPerHotelBooking" type="text"
                                class="form-control amount-maximum-width" />
                            </div>
                            <p class="text-danger smaller">{{hotelErrorMsg}}</p>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="min-left-side-col-width container px-0 px-md-0">
                    <div class="pt-lg-5">
                      <div>
                        <div [ngClass]="{
                          'mb-5 rounded border workspace-rounded-container pt-3':
                            dataService.desktopView,
                          'pl-4': dataService.desktopView && dataService.isSiteLtr(),
                          'pr-4': dataService.desktopView && dataService.isSiteRtl(),
                          'mb-5': dataService.mobileView
                        }">
                          <div class="d-flex">
                            <span class="font-18" i18n="@@flightRewardsLabel">Flight Rewards</span>
                            <mat-slide-toggle class="mt-1" [(ngModel)]="isFlightRewardsChecked" [ngClass]="{
                              'pl-4': dataService.isSiteLtr(),
                              'pr-4': dataService.isSiteRtl()
                            }" (click)="
                            enableRewards('flights');                             
                            " (change)=" enableRewards('flights')">
                            </mat-slide-toggle>
                          </div>
                          <div class="row pr-4">

                            <div class="pl-4">
                              <div class="form-item mt-3 pb-1 d-flex">
                                <label [ngClass]="{'checkbox-mobile': dataService.mobileView}" for="flight-rewards"
                                  class="gray-text-dark font-16" i18n="@@flightRewards">Rewards employees for savings
                                  on
                                  flight booking</label>
                              </div>
                              <div class="d-flex gray-text">
                                <div class="color-border box bold-600 rounded font-12 ng-tns-c551-1 py-1 text-center"
                                  [ngClass]="{'disabled-slider placeholder-text': !isFlightRewardsChecked, 'green-text': isFlightRewardsChecked,
                         'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}">{{flightRewardsValue}}%
                                </div>
                                <ng-container i18n="@@fromTotalBelowCap">From the total amount below policy cap
                                </ng-container>
                              </div>
                              <div class="custom-slider" [ngClass]="{'disabled-slider': !isFlightRewardsChecked}">
                                <ng5-slider [(value)]="flightRewardsValue" [options]="optionsFlights"
                                  (userChangeEnd)="startEdit = true"></ng5-slider>
                              </div>
                            </div>

                          </div>

                          <div class="form-group px-0 font-16">
                            <label for="maximumFlightBooking" class="gray-text-dark"
                              i18n="@@maximumAmountPerBooking">Maximum amount per booking</label>
                            <input (input)="numbersOnly($event); startEdit = true" [disabled]="!isFlightRewardsChecked"
                              id="maximumFlightBooking" name="maximumFlightBooking"
                              [(ngModel)]="maximumAmountPerFlightBooking" type="text"
                              class="form-control amount-maximum-width" />
                          </div>
                          <p class="text-danger smaller">{{flightErrorMsg}}</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <!-- Currency Code Input -->
                <div class="form-group" [ngClass]="{'mt-5 mx-4': dataService.desktopView, 'mx-3': dataService.mobileView}">
                  <label for="currency" class="gray-text-dark font-16" i18n="@@rewardsCurrancyCode">Rewards program currency</label>
                  <input id="currency" name="currency" [(ngModel)]="currencyCode" type="text" class="form-control amount-maximum-width"
                    disabled />
                </div>

                <div [ngClass]="{'mx-0': dataService.mobileView}"
                 class="row d-flex justify-content-end align-items-end mb-3 mt-5 mx-0"
                  *ngIf="!auth.companyInfo.rewardProgram">
                  <div class="col-lg-5 order-lg-1">
                    <button class="mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0"
                      [disabled]="!isHotelRewardsChecked && !isFlightRewardsChecked"
                      [ngClass]="{'m-progress': submitting}" (click)="createOrEditRewards('create')">
                      <span class="mx-auto font-14" i18n="@@createProgram">Create Program</span>
                    </button>
                  </div>
                  <div class="col-lg-5 order-lg-0">
                    <button (click)="dataService.showSliders = false"
                      class="mt-3 btn-block btn btn-outline-gray px-5 d-flex"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      <span class="mx-auto gray-text-dark font-14 sharp-font bold-500" i18n="@@cancelBtn">Cancel</span>
                    </button>
                  </div>
                </div>

                <div class="row d-flex justify-content-end mb-3 mt-5 mx-0" [ngClass]="{'mx-0': dataService.mobileView}" *ngIf="auth.companyInfo.rewardProgram">
                  <div class="col-lg-5 order-lg-1">
                    <button class="mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0" [disabled]="!startEdit"
                      [ngClass]="{'m-progress': submitting}" (click)="createOrEditRewards('edit')">
                      <span class="mx-auto font-14" i18n="@@save">Save</span>
                    </button>
                  </div>
                  <div class="col-lg-5 order-lg-0">
                    <button (click)="deleteRewardsProgram()" class="mt-3 btn-block btn btn-outline-red px-5 d-flex"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      <span class="mx-auto font-14 sharp-font bold-500" i18n="@@deleteBtn">Delete</span>
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-6"
            *ngIf="dataService.desktopView && (auth.companyInfo.rewardProgram  || dataService.showSliders)">
            <div class="rounded-bottom p-4 font-14 right-box-style company-section-with-thick-border thick-top"
              [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
              <p class="gray-primaray-text bold-600 mb-1 font-16 mb-5" i18n="@@howRewardsWorks">How Rewards
                Works</p>
              <p class="gray-text-dark" i18n="@@howRewardsWorks1">1- Create your travel policy and set cap
                for hotel/flight</p>
              <p class="gray-text-dark" i18n="@@howRewardsWorks2">2- For any booking lower than the policy
                cap, employee get percentage of the savings in form of dollar rewards</p>
              <p class="gray-text-dark" i18n="@@howRewardsWorks3">3- Rewards get accredited to employee
                wallet once the trip is completed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>