import {Component, OnDestroy, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { AuthServiceMain } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Title } from "@angular/platform-browser";
import { DatePipe } from '@angular/common';
import {Subscription} from "rxjs";
@Component({
  selector: 'app-manage-flight-booking',
  templateUrl: './manage-flight-booking.component.html',
  styleUrls: ['./manage-flight-booking.component.scss']
})
export class ManageFlightBookingComponent implements OnInit, OnDestroy {

  constructor(public datePipe: DatePipe, private titleService: Title, private http: HttpClient, public router: Router,
    public Service: DataService, private route: ActivatedRoute, private logedIn: AuthServiceMain) {
    this.titleService.setTitle('My trip details | SafarHub');
    this.Service.componentMethodCalled$.subscribe(
      () => {

        if (this.logedIn.isLogedIn && this.Service.isCompany() && this.logedIn.companyInfo && this.logedIn.companyInfo.inactive) {
          this.Service.inactiveWorkspace = true;
        }
        if (this.logedIn.isLogedIn && this.Service.isCompany() && this.logedIn.companyInfo && this.logedIn.companyInfo.inactive === false) {
          this.Service.showExpensifyInBooking();
        }

      });
  }
  refFromURL = 'ref';
  refFromURLValue;
  identifierFromURL = 'identifier';
  identifierFromURLValue;
  type = 'LAST_NAME';
  retrieveObj;
  dataBooking;
  bookingDetails;
  bookingType;
  retrieveBookingSubscription: Subscription;
  ngOnInit(): void {
    this.Service.showPrint = false;
    this.route.queryParams.subscribe(params => {
      this.refFromURLValue = params[this.refFromURL];
      this.identifierFromURLValue = params[this.identifierFromURL];
    });

    if (this.refFromURLValue && this.identifierFromURLValue) {
      this.Service.useForm = false;


      if (this.identifierFromURLValue.indexOf('@') > -1) {
        this.retrieveObj = {
          customerIdentifier: {
            type: 'EMAIL',
            value: this.identifierFromURLValue
          },
          referenceNumber: this.refFromURLValue
        };
      } else {
        this.retrieveObj = {
          customerIdentifier: {
            type: 'LAST_NAME',
            value: this.identifierFromURLValue
          },
          referenceNumber: this.refFromURLValue
        };
      }
      this.getBookingDetails(this.retrieveObj);
    }
  }

  getBookingDetails(retrieveObj) {

    if (this.logedIn?.companyInfo?.inactive) {
      this.Service.inactiveWorkspace = true;
    } else if (this.logedIn?.companyInfo?.inactive === false) {
      this.Service.showExpensifyInBooking();
    }
    this.retrieveBookingSubscription = this.Service.retrieveAirBooking(retrieveObj).subscribe(
      dataBooking => {
        this.dataBooking = dataBooking;
        if (this.dataBooking.errorCode === 0) {

          this.bookingDetails = this.dataBooking.data;
          this.Service.printData = JSON.stringify(this.bookingDetails);
          if (this.dataBooking.data.bookingStatus === 'CANCELLED') {
            this.bookingType = 'cancelled';
          } else {
            if (this.dataBooking.data.past) {
              this.bookingType = 'completed';
            } else {
              this.bookingType = 'confirmed';
            }
          }


        } else {
          this.Service.messages(this.dataBooking.message, 'error');
        }
      },
      error => {
        this.Service.messages(environment.messages.error, 'error');
      }
    );
  }
  print() {
    setTimeout(() => {
      window.print();
    }, 1000);


  }
  shareByEmailMobile() {
    if (this.Service.isCompany()) {
      this.Service.setBottomSheet('companySendBookingVoucher', '');
    } else {

    }
  }
  openSendBookingVoucherPopUp() {
    if (this.Service.isCompany()) {
      if (this.Service.desktopView) {
        this.Service.setModal($localize`:@@shareVoucher:Email to another address`, 'companySendBookingVoucher', '');
      } else {
        this.Service.setBottomSheet('companySendBookingVoucher', '');
      }
    } else {
      this.Service.setModal($localize`:@@shareVoucher:Email to another address`, 'SendBookingVoucher', '');
    }
  }

  ngOnDestroy() {
    if(this.retrieveBookingSubscription){
      this.retrieveBookingSubscription.unsubscribe();
    }
  }
}
