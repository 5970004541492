import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VariablesService } from "./vars.service";
import { ServiceBase } from './base.service';
import { CostCenterFilter } from '../shared/model/cost-management/cost-center';
import { LabelFilter } from '../shared/model/cost-management/label';

@Injectable({
  providedIn: 'root'
})
export class CostManagementService extends ServiceBase {
  costManegementApis;

  constructor(private http: HttpClient, public vars: VariablesService) {
    super(vars, vars.apiUrls.company);

    this.costManegementApis = vars.costManegementApis
  }

  createCostCenter(centerName: string, centerDescription: string = '') {
    const costCenterFilter: CostCenterFilter = new CostCenterFilter();
    costCenterFilter.centerName = centerName;
    costCenterFilter.centerDescription = centerDescription;

    this.setfilter(costCenterFilter);
    const url = this.getMethodUrl(this.costManegementApis.createCostCenter);
    return this.http.post(url, '');
  }

  updateCostCenter(costCenterId: string, centerName: string, centerDescription: string = '') {
    const costCenterFilter: CostCenterFilter = new CostCenterFilter();
    costCenterFilter.costCenterId = costCenterId;
    costCenterFilter.centerName = centerName;
    costCenterFilter.centerDescription = centerDescription;

    this.setfilter(costCenterFilter);
    const url = this.getMethodUrl(this.costManegementApis.updateCostCenter);
    return this.http.post(url, '');
  }

  setCostCenterStatus(costCenterId: string, status: string = '') {
    const costCenterFilter: CostCenterFilter = new CostCenterFilter();
    costCenterFilter.costCenterId = costCenterId;
    costCenterFilter.status = status;

    this.setfilter(costCenterFilter);
    const url = this.getMethodUrl(this.costManegementApis.setCostCenterStatus);
    return this.http.post(url, '');
  }

  getCostCenters() {
    const url = this.getMethodUrl(this.costManegementApis.getCostCenters);
    return this.http.post(url, '');
  }

  createLabel(labelName: string, labelDescription: string = '') {
    const labelFilter: LabelFilter = new LabelFilter();
    labelFilter.labelName = labelName;
    labelFilter.labelDescription = labelDescription;

    this.setfilter(labelFilter);
    const url = this.getMethodUrl(this.costManegementApis.createLabel);
    return this.http.post(url, '');
  }

  updateLabel(labelId: string, labelName: string, labelDescription: string = '') {
    const labelFilter: LabelFilter = new LabelFilter();
    labelFilter.labelId = labelId;
    labelFilter.labelName = labelName;
    labelFilter.labelDescription = labelDescription;

    this.setfilter(labelFilter);
    const url = this.getMethodUrl(this.costManegementApis.updateLabel);
    return this.http.post(url, '');
  }

  setLabelStatus(labelId: string, status: string = '') {
    const labelFilter: LabelFilter = new LabelFilter();
    labelFilter.labelId = labelId;
    labelFilter.status = status;

    this.setfilter(labelFilter);
    const url = this.getMethodUrl(this.costManegementApis.setLabelStatus);
    return this.http.post(url, '');
  }

  getLabels() {
    const url = this.getMethodUrl(this.costManegementApis.getLabels);
    return this.http.post(url, '');
  }

  getMandatory() {
    const url = this.getMethodUrl(this.costManegementApis.getMandatory);
    return this.http.post(url, '');
  }

  setMandatory(costCenterMandatoryOnBookingsAndApprovals: boolean, labelMandatoryOnBookingsAndApprovals: boolean) {
    this.setfilter({ costCenterMandatoryOnBookingsAndApprovals, labelMandatoryOnBookingsAndApprovals });
    const url = this.getMethodUrl(this.costManegementApis.setMandatory);
    return this.http.post(url, '');
  }

  getActiveCostCenters() {
    const url = this.getMethodUrl(this.costManegementApis.getActiveCostCenters);
    return this.http.post(url, '');
  }

  getActiveLabels() {
    const url = this.getMethodUrl(this.costManegementApis.getActiveLabels);
    return this.http.post(url, '');
  }
}