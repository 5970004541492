import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, OnInit, ViewChild, ElementRef, Input} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {FormBuilder, FormControl, FormGroup, NgModel, Validators} from '@angular/forms';
import { Observable, throwError, of, empty } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TagInputComponent as SourceTagInput } from 'ngx-chips';
import {AuthServiceMain} from "../../../../auth/auth.service";
export interface Team {
  name: string;
  teamId: string;
}
@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.scss']
})
export class InviteUsersComponent implements OnInit {

  constructor(public auth: AuthServiceMain, private formBuilder: FormBuilder, public dataService: DataService, public router: Router) {}
  options = [];
  teamControl = new FormControl();
  separatorKeysCodes = [ENTER, COMMA];
  addOnBlur: false;
  showTeamAutoCompletePopUp = false;
  selectedTeam;
  filteredTeams: Observable<any[]>;
  lastFilterTeam: string = '';
  editUserTeam = false;
  defaultTeamInfo;
  // NoTeam: Team = {name: 'No Team' , teamId: null};
  NoTeam: Team = {name: $localize`:@@unassigned:Unassigned` , teamId: '0'};
  @ViewChild('teamInput') teamInput: ElementRef;
  data;
  showClearIcon = false;
  sendEmailFlag = false;
  emails = [] ;
  sendData = {};
  emailsGroup = '';
  placeholder = true;
  submitting = false;
  shareHotelImage;
  shareHotelAddress;
  shareHotelName;
  submitted = false;
  errorMsg;
  isAdmin = false;
  teamList;
 // usersInviteForm: FormGroup;
  @ViewChild('tagInput', {static: true})
  tagInput: SourceTagInput;
  public validators = [ this.must_be_email.bind(this) ];
  public errorMessages = {
    must_be_email: $localize`:@@emailFormat:Please be sure to use a valid email format`
  };
  public onAddedFunc = this.beforeAdd.bind(this);
  private addFirstAttemptFailed = false;
  private must_be_email(control: FormControl) {

    if (this.addFirstAttemptFailed && !this.validateEmail(control.value)) {
      return { must_be_email: true };
    }
    return null;
  }

  private removeFromArray(item, index) {
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  private beforeAdd(tag) {

    if (typeof tag === 'object') {
      tag = tag.value;
    }
    if (!this.validateEmail(tag)) {
      if (!this.addFirstAttemptFailed) {
        this.tagInput.setInputValue(tag);
      }
      return throwError(this.errorMessages.must_be_email);
    }

    this.addFirstAttemptFailed = false;
    this.emails.push(tag);
    return of(tag);
  }
  private validateEmail(text: string) {
    const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    return (text && EMAIL_REGEXP.test(text));
  }
  // End of Input Tag

  ngOnInit() {
    if (this.dataService.desktopView) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('workspace-page');
    }
    this.defaultTeamInfo = JSON.parse(sessionStorage.getItem('defaultTeamInfo'));
    if (this.defaultTeamInfo) {
      this.NoTeam = {name: $localize`:@@unassigned:Unassigned` , teamId: this.defaultTeamInfo.id};
    }
    this.options.push(this.NoTeam);
    this.teamAutoComplete();
   // this.dataService.getCompanyTeamsList();
   // this.defaultTeamInfo = JSON.parse(sessionStorage.getItem('defaultTeamInfo'));
  /*  this.usersInviteForm = this.formBuilder.group({
      teamName: [''],
    }, {
    });*/
   // this.usersInviteForm.get('teamName').setValue(0);
  }
  teamAutoComplete(){

    // start here - change to add create new team option
    if (this.dataService.desktopView) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('workspace-page');
    }
    this.options = [];
    this.options.push(this.NoTeam);
    this.filteredTeams = this.teamControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : this.lastFilterTeam),
        map(name => name ? this.filterTeam(name) : this.options.slice())
      );


    // end here - change to add create new team option
    this.dataService.getTeamsList().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          if (this.data.data) {
            this.teamList = this.data.data;
            this.data.data.forEach((element) => {
              this.options.push({name: element.name, teamId: element.id});
              this.filteredTeams = this.teamControl.valueChanges
                .pipe(
                  startWith(''),
                  map(value => typeof value === 'string' ? value : this.lastFilterTeam),
                  map(name => name ? this.filterTeam(name) : this.options.slice())
                );
            });
          } else{
            this.filteredTeams = this.teamControl.valueChanges
              .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : this.lastFilterTeam),
                map(name => name ? this.filterTeam(name) : this.options.slice())
              );
          }
        } else {
          this.dataService.messages(this.data.message, 'error');
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
      }
    );
  }
  private filterTeam(name: string): Team[] {
    this.lastFilterTeam = name;
    if (name) {
      return this.options.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) === 0 && option.name !== 'Unassigned');
    } else {
      return this.options.slice();
    }
  }

  displayFnTeam(team: Team): string {
    return team && team.name ? team.name : '';
  }
  showIcon() {
    setTimeout(() => {
      this.showClearIcon = true;
    }, 210);
    this.teamAutoComplete();
  }
  resetTeam() {
    setTimeout(() => {
      if (typeof this.teamControl.value !== 'object') {
        this.teamInput.nativeElement.value = '';
        this.teamControl.setValue(null);
        this.lastFilterTeam = '';
        this.selectedTeam = null;
        this.filteredTeams = this.teamControl.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : this.lastFilterTeam),
            map(name => name ? this.filterTeam(name) : this.options.slice())
          );
      }
      this.showClearIcon = false
    }, 200);
  }

  optionClickedTeam(event: Event, team: Team) {
    this.selectedTeam = team;
    this.hidePopUp();
  }
  hidePopUp() {
    this.showTeamAutoCompletePopUp = false;
    this.dataService.enableBodyScroll();
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('members-popup');
  }
  showTeamPopUp() {
    this.showTeamAutoCompletePopUp = true;
    this.dataService.disableBodyScroll();
    if (this.selectedTeam) {
      this.teamControl.setValue(this.selectedTeam);
    }
    setTimeout(() => {
      const input = document.getElementById('teamInput') as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }
 // get f() { return this.usersInviteForm.controls; }
  sendEmail() {
    this.dataService.alerts = [];
    this.submitted = true;
    this.submitting = true;
    if (this.emails.length === 0) {
      this.submitting = false;
      return;
    }
    setTimeout(() => {
      if (this.selectedTeam && this.selectedTeam.teamId && this.selectedTeam.teamId !== '0') {
        this.sendData = {
          emails: this.emails,
          teamId: this.selectedTeam.teamId,
          setAsAdmin: this.isAdmin
        };
      } else {
        this.sendData = {
          emails: this.emails,
          setAsAdmin: this.isAdmin
        };
      }
      this.dataService.inviteToCompany(this.sendData).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.tagInput.items = [];
            this.sendEmailFlag = true;
            this.emails = [] ;
            this.errorMsg = '';
            this.submitting = false;
            this.dataService.SnakBar($localize`:@@inviteOnWay:Your invitation is on its way`);
            this.submitted = false;
            this.dataService.modalOf();
            this.dataService.getPendingUsers();
          } else {
            this.errorMsg = this.data.message;
            this.submitting = false;
            this.dataService.scrollTop();

          }

        },
        error => {
          this.submitting = false;
          this.errorMsg = environment.messages.error;
          this.dataService.scrollTop();
        }
      );
    }, 2000);
  }
  cancelInvite() {
  /*  if (this.dataService.desktopView) {
      this.dataService.modalOf();
    } else {*/
      this.router.navigate([this.router.url.replace('/invite' , '')]);
   // }
  }
}
