<div *ngIf="costState.labels.length && !loader" class="row mx-lg-4 pb-5"
  [ngClass]="{'pb-0 mb-0': dataService.mobileView}">
  <div class="col-lg-6">
    <div class="row">
      <div class="col">
        <p class="font-20 gray-primaray-text mb-4" i18n="@@labels">
          Labels
        </p>
        <mat-checkbox [checked]="isLabelMandatory" color="primary" (change)="mandotaryChange($event)"
          >
          <span class="font-12" i18n="@@labelMandatory">
            Label is mandotary for all bookings
          </span>
        </mat-checkbox>
        <div class="main-dashboard-content mt-3 mb-0 pb-0">
          <div class="items-full-list container" [ngClass]="{'mobile-max-width ml-2': dataService.mobileView}">
            <div class="row">
              <div class="col-lg-9">
                <div class="row font-16-to-14 font-weight-bold filtering blue-text rounded pb-2">
                  <div class="col-auto py-3 px-1">
                    <span class="pointer px-lg-1 pr-5 inactive"
                      [ngClass]="{'active' : costState.isActiveLabels, 'border-right': dataService.isSiteLtr(), 'border-left': dataService.isSiteRtl()}"
                      (click)="costState.filterLabelsBy()" i18n="@@active">
                      Active
                    </span>
                  </div>
                  <div class="col-auto py-3 px-2">
                    <span class="pointer px-lg-4"
                      [ngClass]="{'active' : !costState.isActiveLabels, 'mobile-padding-left': dataService.mobileView}"
                      (click)="costState.filterLabelsBy(false)" i18n="@@inActive">
                      Archived
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div [ngClass]="costState.hasLabelsScroll ? 'list-container' : ''">
              <div class="gray-primaray-text font-14" [ngClass]="costState.hasLabelsScroll ? 'ml-22' : 'ml-26'"
                *ngFor="let item of costState.filteredLabels; let i = index">
                <div class="row hover-row-list py-4 mx-0"
                  [ngClass]="{'pointer': auth.companyInfo && auth.companyInfo.inactive === false, 'prevent-pointer-event': auth.companyInfo && auth.companyInfo.inactive === true, 'border-bottom': dataService.desktopView, 'border rounded shadow-sm mb-4': dataService.mobileView}">
                  <div [ngClass]="costState.hasLabelsScroll ? 'col-lg-9' : 'col-lg-10'">
                    <div class="d-flex ml-0 pl-0" [ngClass]="costState.hasLabelsScroll ? 'pl-23' : ''">
                      <div class="dotted-text ml-0">
                        <p class="mb-0 gray-primaray-text">
                          {{item.name}}
                        </p>
                        <span
                          class="gray-text mb-0 dotted-text dotted-text-direction text-sm">{{item.description}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="max-col-with mx-1" [ngClass]="costState.hasLabelsScroll ? 'col-lg-2' : 'col-lg-1'">
                    <div class="d-flex" [ngClass]="costState.hasLabelsScroll && dataService.desktopView ? 'mr-23' : ''"
                      *ngIf="auth.companyInfo && auth.companyInfo.inactive === false">
                      <div class=" ml-auto actions"
                        [ngClass]="{'mobile-actions': dataService.mobileView, 'margin-top-5': dataService.mobileView && !item.description.length}">
                        <i class="drop-down{{i}} icon-three-dots font-18 ellipsis gray-text pointer p-0"
                          (click)="$event.stopPropagation(); showActions = !showActions; selectedIndex = i"></i>
                        <div class="border shadow font-14 position-absolute bg-white drop"
                          *ngIf="showActions && i === selectedIndex">
                          <p class="pointer py-2"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            (click)="$event.stopPropagation(); manageItem(item)" i18n="@@edit">
                            Edit
                          </p>
                          <p class="pointer py-2"
                            *ngIf="!costState.isActiveLabels"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            (click)="$event.stopPropagation(); changeActivation(item)"
                            i18n="@@activate">
                            Activate
                          </p>
                          <p class="pointer py-2"
                            *ngIf="costState.isActiveLabels"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            (click)="$event.stopPropagation(); changeActivation(item)"
                            i18n="@@archive">
                            Archive
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h5 *ngIf="!costState.filteredLabels.length && !loader" i18n="@@noDataFound">No data found</h5>
            <div class="d-flex justify-content-end mt-5" [ngClass]="costState.hasLabelsScroll ? 'pt-3': 'pt-1'">
              <button class="blue_btn px-5 solid-border"
                [ngClass]="{'btn-outline-blue': auth.companyInfo && auth.companyInfo.inactive === false, 'btn-outline-gray': auth.companyInfo && auth.companyInfo.inactive}"
                (click)="manageItem()" i18n="@@addLabel">
                Add Label
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6" *ngIf="dataService.desktopView">
    <div class="rounded-bottom p-4 font-14 right-box-style company-section-with-thick-border thick-top"
      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
      <p class="gray-primaray-text bold-600 mb-1 font-16 mb-3" i18n="@@labels">
        Labels
      </p>
      <p class="gray-text-dark" i18n="@@labelsNote">
        Labels can cover a wide range of categories, including internal kickoffs, events, projects, and more.
      </p>
    </div>
  </div>
</div>
<div *ngIf="!costState.labels.length && !loader" class="main-dashboard-content mt-3 mb-3 pb-3">
  <div class="mt-5 mx-lg-0 px-4 px-lg-5 py-4 company-section-with-thick-border border" [ngClass]="{'minus-margin': dataService.desktopView,
                    'rounded-right': dataService.desktopView && dataService.isSiteLtr(), 'rounded-left': dataService.desktopView && dataService.isSiteRtl(),
                    'thick-top rounded-bottom': dataService.mobileView}">
    <div class="row py-3 h-100 justify-content-center align-items-center">
      <div class="col-md-9 d-flex">
        <div>
          <p class="font-18 bold-600 primaray-text" i18n="@@labels">Labels</p>
          <span class="font-16 gray-text-dark" i18n="@@labelEmpty">Utilize labels to efficiently categorize travel
            expenditures and enhance reporting capabilities. Labels can cover a wide range of categories, including
            internal kickoffs, events, projects, and more.</span>
        </div>
      </div>
      <div class="col-md-3" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">
        <button class="blue_btn solid-border w-75"
          [ngClass]="{'btn-outline-blue': auth.companyInfo && auth.companyInfo.inactive === false, 'btn-outline-gray': auth.companyInfo && auth.companyInfo.inactive}"
          (click)="manageItem()" i18n="@@addLabel">
          Add Label
        </button>
      </div>
    </div>
  </div>
</div>