<div class="about_us mt-5">
  <div class="container custom-container">
    <h1 class="gray-primaray-text bold-500 border-bottom pb-5 mb-5 sharp-font font-24" i18n="@@aboutPage1">Pay Less For Any Hotel. Anywhere, Anytime.
    </h1>
    <p class="gray-text-dark pb-5 font-18-to-16" i18n="@@aboutPage2">

      SafarHub was founded in 2017 with one objective: to offer travelers a simple, stress-free hotel booking experience with the best price guarantee and the technology to alert you on the best deal possible.
    </p>



    <div class="border-bottom pb-lg-5">
      <div class="row" >
        <div class="col-sm-6 col-md-3 ">

            <img width="174" height="174" class="mx-auto d-block" [ngClass]="{'mr-3': Service.isSiteLtr(), 'ml-3': Service.isSiteRtl()}" loading="lazy" src="./assets/img/about-2.png" alt="manage cash">

        </div>
        <div class="col-sm-6 col-md-9">
          <div class="overflow-hidden">
            <h2 class="bold-500 mt-5 mb-4 sharp-font gray-primaray-text font-20" i18n="@@aboutPage3">Saving Time and Money</h2>
            <p class="gray-text-dark mb-5 mb-lg-0 font-18" i18n="@@aboutPage4">
              With SafarHub’s patented AI technology, travelers will receive personalized alerts so they can lock in the best hotel rate possible. SafarHub believes that discovering and booking the best hotel at the best price should be fast and painless.            </p>

          </div>
        </div>
      </div>
    </div>

    <div class="pt-5 border-bottom pb-lg-5 mb-5">
      <div class="row" >
        <div class="col-sm-6 col-md-3 ">

            <img width="174" height="174" class="mx-auto d-block search-hotel-img initial-height" [ngClass]="{'mr-3': Service.isSiteLtr(), 'ml-3': Service.isSiteRtl()}" loading="lazy" src="./assets/img/about-3.png" alt="search hotels">

        </div>
        <div class="col-sm-6 col-md-9">
          <div class="overflow-hidden">
            <h2 class="bold-500 mt-5 mb-4 sharp-font gray-primaray-text font-20" i18n="@@aboutPage5"> Best Price, Guaranteed</h2>
            <p class="gray-text-dark mb-5 mb-lg-0 font-18" >
              <ng-container i18n="@@aboutPage6">Love to feel good about saving cash? Not only will SafarHub save you time and money, but you are provided with the best price, guaranteed. As a subscriber of SafarHub, you will be the first to find out about unpublished and hidden deals before anyone else.</ng-container>
<br /><br />
              <ng-container i18n="@@aboutPage7">SafarHub’s promise is to offer the best hotel deals imaginable. You’ll feel confident in knowing that with SafarHub, you’ll receive no-gimmick personalized offers tailored to your needs.</ng-container>

            </p>
          </div>
        </div>
      </div>
    </div>



   <!-- <div *ngIf="!this.logedIn.isLogedIn && !(router.url.indexOf('mobile') >= 0)"  class="pt-5 dark-blue-border rounded pb-4 mb-5 px-3 px-lg-5">
      <div class="row" >
        <div class="col-sm-4 col-md-8 ">
          <div class="overflow-hidden">
            <p class="gray-primaray-text  text-center font-18-to-16"
               [ngClass]="{'pl-0 pl-lg-4 text-lg-left': Service.isSiteLtr(), 'pr-0 pr-lg-4 text-lg-right': Service.isSiteRtl()}">Become a member today and discover Hidden and Unpublished hotel rates. It’s free!</p>
          </div>
        </div>
        <div class="col-sm-8 col-md-4">
          <div class="overflow-hidden" [ngClass]="{' mr-0 mr-lg-4': Service.isSiteLtr(), ' ml-0 ml-lg-4': Service.isSiteRtl()}">
            <button (click)="this.Service.openRegistrationDialogue()" class=" btn btn-primary btn-block mb-4 font-16" >Sign Up Now</button>
          </div>
        </div>
      </div>
    </div>-->

  </div>
</div>
