import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { VariablesService } from "src/app/services/vars.service";
import { WalletManagementService } from "src/app/services/wallet-services/wallet-management.service";
import { Wallet } from "src/app/shared/model/payment/wallet";

@Component({
  selector: "app-edit-wallet-payment-form",
  templateUrl: "./edit-wallet-payment-form.component.html",
  styleUrls: ["./edit-wallet-payment-form.component.scss"],
})
export class EditWalletPaymentFormComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() name: string;
  @Input() description: string;
  @Input() isInactive: boolean;

  walletInfoForm: FormGroup;
  validationMessage: string = "";
  data: any;
  submitted: boolean = false;
  submitting = false;
  editWalletSubscription: any;

  constructor(
    public dataService: DataService,
    private fb: FormBuilder,
    public router: Router,
    public vars: VariablesService,
    private walletManagementService: WalletManagementService
  ) {
    this.walletInfoForm = this.fb.group({
      name: ["", [Validators.required]],
      description: [""],
    });
  }

  ngOnDestroy(): void {
    if(this.editWalletSubscription){
      this.editWalletSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.walletInfoForm.patchValue({
      name: this.name, // Set your desired value here
      description: this.description,
    });
  }

  lettersOnly(event: any) {
    const inputValue = event.target.value;
    // Replace anything that is not a letter or space
    event.target.value = inputValue.replace(/[^A-Za-z\s]/g, "");
  }

  get formControls() {
    return this.walletInfoForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.submitting = true;
    var wallet: Wallet = new Wallet();

    wallet.name = this.walletInfoForm.get("name")?.value;
    wallet.description = this.walletInfoForm.get("description")?.value;
    wallet.id = this.id;
    wallet.isInactive = this.isInactive;

    const nameValidation = this.validateName(wallet.name);

    if (!nameValidation.validity) {
      this.submitted = false;
      this.submitting = false;
      this.validationMessage = nameValidation.msg;
      return;
    } 

    this.editWalletSubscription = this.walletManagementService
      .editWallet(wallet)
      .subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.submitted = true;
            this.submitting = false;
            this.close();
          }else{
            this.submitting = false;
            this.submitted = false;
            this.validationMessage = this.data.message;
          }
        },
        (error) => {
          this.close();
        }
      );
  }

  close() {
    this.walletInfoForm.reset();
    this.dataService.modalOf();
    this.dataService.BottomSheetOf();
  }

  validateName(name: string) {
    let validity = true;
    let message = "";
    name = name.trim();

    if (name.length === 0) {
      validity = false;
      message = $localize`:@@nameRequired:Name is Required`;
    }

    return {
      validity,
      msg: message,
    };
  }
}
