// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  shareAsale: true,
  routeToWorkSpaceHomePage: true,
  showFlightSearchForConsumer: true,
  showConsumerPassPlansStep: false,
  showConsumerPassPaymentStep: false,
  rewardsOnSystemLevel: true,
  activateZendesk: true,
  zendeskKey: 'c79253fc-a430-474e-8740-3e2d5491fc03',
  ZendeskTimeOut: 2000,
  amazonPayFortTokenizationUrl: 'https://sbcheckout.payfort.com/FortAPI/paymentPage',
  urlOriginForAmazon: 'https://api.triphop.com',
  amazonPaymentServiceRequest: {
    merchant_identifier: 'NDFVibKU',
    access_code: 'B0AWFXwKoicrF2IBlfEM',
    language: 'en',
    service_command: 'TOKENIZATION',
    return_url: 'https://api.triphop.com/amazon/payment/company/add_amazon_card'
  },
  messages: {
    amazonUnexpectedResponse: $localize`:@@amazonUnexpectedResponse: Error in adding payment method.`,
    error: $localize`:@@letsTryAgain:Something went wrong. Let's try that again.`,
    ACCOUNT_NOT_VERIFIED: 'Your account hasn\'t been verified yet. Please check your email for verification instructions.',
    ADD_WALLET: 'You need to add a payment method in order to redeem your Cash Back.' +
      'Add your preferred Cash Back payment method here',
    WALLET_SAVED: 'Your Cash Back payment method has been saved.',
    PAYMENT_CARD_SAVED: $localize`:@@yourCardSaved:Your Card has been saved.`,
    UPDATED_PROFILE: 'Your profile has been updated.',
    UPDATED_ADDRESS: 'Your address has been updated.',
    //  VOUCHER_SENT: 'A voucher has been sent to your ' + this.voucherEmail + ' address.',
    NO_AVAILABILITY: $localize`:@@noAvailability:Unfortunately, there are no rooms available on selected dates. Rates availability can change frequently try to change the selected dates or the numbers of guests and try again.`,
    PRICE_GUARANTEE_SUCCESS: 'Claim submitted successfully, our team will contact you via email.',
    PRICE_GUARANTEE_ERROR: 'There was a problem in submitting your request,. Let\'s try that again.',
    THANKS_CONTACT_US: 'Thank you! one of our team will get in touch with you as soon as possible.',
    CONTACT_US_ERROR: 'There was a problem in submitting your request,. Let\'s try that again.',
    VERIFICATION_SENT: 'We sent a verification code to your email address.',
    USER_HAS_CASHBACK: 'Welcome to Triphop. We’re glad you’re here! We’ve credited your account with $%total USD which' +
      ' will be redeemable on your next hotel stay valued at greater than $200 USD',
    USER_HAS_DISCOUNT: 'Don\'t forget - We owe you $%amount off your first booking.',
    ACCOUNT_VERIFIED: 'Congratulations! You now have a verified Triphop account!',
    ACCOUNT_VERIFICATION_ERROR: 'We weren’t able to send your verification email. Please try again',
    PASSWORD_RESET_SUCCESSFUL: 'Your password has been reset.',
    PasswordAssigned: 'Your password has been assigned.',
    THANKS_REVIEW : 'Thanks for your review!',
    PASSWORD_RESET_ERROR: 'Sorry, that\'s an invalid user code. Please try again.',
    noHotelsFound: 'Whoops, we could not find any hotel!',

    priceChangeMsg:   '<b>We hate it when this happens!</b><br/>The price of this room has changed. Rates can change frequently, please check below for the updated price and book now.',

    holdFailureMessage:'We’re sorry, something wrong happened. Please try again or contact our customer support',
    holdConfirmed:'Your room is on hold till',
    holdCanceled:'Your room hold was canceled',
    holdExpired:'Your room hold has expired on',
    holdBooked:'Your reservation is confirmed and paid in full',

    holdCanceledCTA:'Find a Room',
    holdBookedCTA:'View My Reservation',

  },


  countries: [
    {name: 'Afghanistan', code: 'AF'},
    {name: 'land Islands', code: 'AX'},
    {name: 'Albania', code: 'AL'},
    {name: 'Algeria', code: 'DZ'},
    {name: 'American Samoa', code: 'AS'},
    {name: 'AndorrA', code: 'AD'},
    {name: 'Angola', code: 'AO'},
    {name: 'Anguilla', code: 'AI'},
    {name: 'Antarctica', code: 'AQ'},
    {name: 'Antigua and Barbuda', code: 'AG'},
    {name: 'Argentina', code: 'AR'},
    {name: 'Armenia', code: 'AM'},
    {name: 'Aruba', code: 'AW'},
    {name: 'Australia', code: 'AU'},
    {name: 'Austria', code: 'AT'},
    {name: 'Azerbaijan', code: 'AZ'},
    {name: 'Bahamas', code: 'BS'},
    {name: 'Bahrain', code: 'BH'},
    {name: 'Bangladesh', code: 'BD'},
    {name: 'Barbados', code: 'BB'},
    {name: 'Belarus', code: 'BY'},
    {name: 'Belgium', code: 'BE'},
    {name: 'Belize', code: 'BZ'},
    {name: 'Benin', code: 'BJ'},
    {name: 'Bermuda', code: 'BM'},
    {name: 'Bhutan', code: 'BT'},
    {name: 'Bolivia', code: 'BO'},
    {name: 'Bosnia and Herzegovina', code: 'BA'},
    {name: 'Botswana', code: 'BW'},
    {name: 'Bouvet Island', code: 'BV'},
    {name: 'Brazil', code: 'BR'},
    {name: 'British Indian Ocean Territory', code: 'IO'},
    {name: 'Brunei Darussalam', code: 'BN'},
    {name: 'Bulgaria', code: 'BG'},
    {name: 'Burkina Faso', code: 'BF'},
    {name: 'Burundi', code: 'BI'},
    {name: 'Cambodia', code: 'KH'},
    {name: 'Cameroon', code: 'CM'},
    {name: 'Canada', code: 'CA'},
    {name: 'Cape Verde', code: 'CV'},
    {name: 'Cayman Islands', code: 'KY'},
    {name: 'Central African Republic', code: 'CF'},
    {name: 'Chad', code: 'TD'},
    {name: 'Chile', code: 'CL'},
    {name: 'China', code: 'CN'},
    {name: 'Christmas Island', code: 'CX'},
    {name: 'Cocos (Keeling) Islands', code: 'CC'},
    {name: 'Colombia', code: 'CO'},
    {name: 'Comoros', code: 'KM'},
    {name: 'Congo', code: 'CG'},
    {name: 'Congo, The Democratic Republic of the', code: 'CD'},
    {name: 'Cook Islands', code: 'CK'},
    {name: 'Costa Rica', code: 'CR'},
    {name: 'Côte d Ivoire', code: 'CI'},
    {name: 'Croatia', code: 'HR'},
    {name: 'Cuba', code: 'CU'},
    {name: 'Cyprus', code: 'CY'},
    {name: 'Czech Republic', code: 'CZ'},
    {name: 'Denmark', code: 'DK'},
    {name: 'Djibouti', code: 'DJ'},
    {name: 'Dominica', code: 'DM'},
    {name: 'Dominican Republic', code: 'DO'},
    {name: 'Ecuador', code: 'EC'},
    {name: 'Egypt', code: 'EG'},
    {name: 'El Salvador', code: 'SV'},
    {name: 'Equatorial Guinea', code: 'GQ'},
    {name: 'Eritrea', code: 'ER'},
    {name: 'Estonia', code: 'EE'},
    {name: 'Ethiopia', code: 'ET'},
    {name: 'Falkland Islands (Malvinas)', code: 'FK'},
    {name: 'Faroe Islands', code: 'FO'},
    {name: 'Fiji', code: 'FJ'},
    {name: 'Finland', code: 'FI'},
    {name: 'France', code: 'FR'},
    {name: 'French Guiana', code: 'GF'},
    {name: 'French Polynesia', code: 'PF'},
    {name: 'French Southern Territories', code: 'TF'},
    {name: 'Gabon', code: 'GA'},
    {name: 'Gambia', code: 'GM'},
    {name: 'Georgia', code: 'GE'},
    {name: 'Germany', code: 'DE'},
    {name: 'Ghana', code: 'GH'},
    {name: 'Gibraltar', code: 'GI'},
    {name: 'Greece', code: 'GR'},
    {name: 'Greenland', code: 'GL'},
    {name: 'Grenada', code: 'GD'},
    {name: 'Guadeloupe', code: 'GP'},
    {name: 'Guam', code: 'GU'},
    {name: 'Guatemala', code: 'GT'},
    {name: 'Guernsey', code: 'GG'},
    {name: 'Guinea', code: 'GN'},
    {name: 'Guinea-Bissau', code: 'GW'},
    {name: 'Guyana', code: 'GY'},
    {name: 'Haiti', code: 'HT'},
    {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
    {name: 'Holy See (Vatican City State)', code: 'VA'},
    {name: 'Honduras', code: 'HN'},
    {name: 'Hong Kong', code: 'HK'},
    {name: 'Hungary', code: 'HU'},
    {name: 'Iceland', code: 'IS'},
    {name: 'India', code: 'IN'},
    {name: 'Indonesia', code: 'ID'},
    {name: 'Iran, Islamic Republic Of', code: 'IR'},
    {name: 'Iraq', code: 'IQ'},
    {name: 'Ireland', code: 'IE'},
    {name: 'Isle of Man', code: 'IM'},
    {name: 'Israel', code: 'IL'},
    {name: 'Italy', code: 'IT'},
    {name: 'Jamaica', code: 'JM'},
    {name: 'Japan', code: 'JP'},
    {name: 'Jersey', code: 'JE'},
    {name: 'Jordan', code: 'JO'},
    {name: 'Kazakhstan', code: 'KZ'},
    {name: 'Kenya', code: 'KE'},
    {name: 'Kiribati', code: 'KI'},
    {name: 'Korea (North)', code: 'KP'},
    {name: 'Korea, (South)', code: 'KR'},
    {name: 'Kuwait', code: 'KW'},
    {name: 'Kyrgyzstan', code: 'KG'},
    {name: 'Lao PDR', code: 'LA'},
    {name: 'Latvia', code: 'LV'},
    {name: 'Lebanon', code: 'LB'},
    {name: 'Lesotho', code: 'LS'},
    {name: 'Liberia', code: 'LR'},
    {name: 'Libyan Arab Jamahiriya', code: 'LY'},
    {name: 'Liechtenstein', code: 'LI'},
    {name: 'Lithuania', code: 'LT'},
    {name: 'Luxembourg', code: 'LU'},
    {name: 'Macao', code: 'MO'},
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
    {name: 'Madagascar', code: 'MG'},
    {name: 'Malawi', code: 'MW'},
    {name: 'Malaysia', code: 'MY'},
    {name: 'Maldives', code: 'MV'},
    {name: 'Mali', code: 'ML'},
    {name: 'Malta', code: 'MT'},
    {name: 'Marshall Islands', code: 'MH'},
    {name: 'Martinique', code: 'MQ'},
    {name: 'Mauritania', code: 'MR'},
    {name: 'Mauritius', code: 'MU'},
    {name: 'Mayotte', code: 'YT'},
    {name: 'Mexico', code: 'MX'},
    {name: 'Micronesia, Federated States of', code: 'FM'},
    {name: 'Moldova, Republic of', code: 'MD'},
    {name: 'Monaco', code: 'MC'},
    {name: 'Mongolia', code: 'MN'},
    {name: 'Montenegro', code: 'ME'},
    {name: 'Montserrat', code: 'MS'},
    {name: 'Morocco', code: 'MA'},
    {name: 'Mozambique', code: 'MZ'},
    {name: 'Myanmar', code: 'MM'},
    {name: 'Namibia', code: 'NA'},
    {name: 'Nauru', code: 'NR'},
    {name: 'Nepal', code: 'NP'},
    {name: 'Netherlands', code: 'NL'},
    {name: 'Netherlands Antilles', code: 'AN'},
    {name: 'New Caledonia', code: 'NC'},
    {name: 'New Zealand', code: 'NZ'},
    {name: 'Nicaragua', code: 'NI'},
    {name: 'Niger', code: 'NE'},
    {name: 'Nigeria', code: 'NG'},
    {name: 'Niue', code: 'NU'},
    {name: 'Norfolk Island', code: 'NF'},
    {name: 'Northern Mariana Islands', code: 'MP'},
    {name: 'Norway', code: 'NO'},
    {name: 'Oman', code: 'OM'},
    {name: 'Pakistan', code: 'PK'},
    {name: 'Palau', code: 'PW'},
    {name: 'Palestinian Territory, Occupied', code: 'PS'},
    {name: 'Panama', code: 'PA'},
    {name: 'Papua New Guinea', code: 'PG'},
    {name: 'Paraguay', code: 'PY'},
    {name: 'Peru', code: 'PE'},
    {name: 'Philippines', code: 'PH'},
    {name: 'Pitcairn', code: 'PN'},
    {name: 'Poland', code: 'PL'},
    {name: 'Portugal', code: 'PT'},
    {name: 'Puerto Rico', code: 'PR'},
    {name: 'Qatar', code: 'QA'},
    {name: 'Reunion', code: 'RE'},
    {name: 'Romania', code: 'RO'},
    {name: 'Russian Federation', code: 'RU'},
    {name: 'RWANDA', code: 'RW'},
    {name: 'Saint Helena', code: 'SH'},
    {name: 'Saint Kitts and Nevis', code: 'KN'},
    {name: 'Saint Lucia', code: 'LC'},
    {name: 'Saint Pierre and Miquelon', code: 'PM'},
    {name: 'Saint Vincent and the Grenadines', code: 'VC'},
    {name: 'Samoa', code: 'WS'},
    {name: 'San Marino', code: 'SM'},
    {name: 'Sao Tome and Principe', code: 'ST'},
    {name: 'Saudi Arabia', code: 'SA'},
    {name: 'Senegal', code: 'SN'},
    {name: 'Serbia', code: 'RS'},
    {name: 'Seychelles', code: 'SC'},
    {name: 'Sierra Leone', code: 'SL'},
    {name: 'Singapore', code: 'SG'},
    {name: 'Slovakia', code: 'SK'},
    {name: 'Slovenia', code: 'SI'},
    {name: 'Solomon Islands', code: 'SB'},
    {name: 'Somalia', code: 'SO'},
    {name: 'South Africa', code: 'ZA'},
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
    {name: 'Spain', code: 'ES'},
    {name: 'Sri Lanka', code: 'LK'},
    {name: 'Sudan', code: 'SD'},
    {name: 'Suriname', code: 'SR'},
    {name: 'Svalbard and Jan Mayen', code: 'SJ'},
    {name: 'Swaziland', code: 'SZ'},
    {name: 'Sweden', code: 'SE'},
    {name: 'Switzerland', code: 'CH'},
    {name: 'Syrian Arab Republic', code: 'SY'},
    {name: 'Taiwan, Province of China', code: 'TW'},
    {name: 'Tajikistan', code: 'TJ'},
    {name: 'Tanzania, United Republic of', code: 'TZ'},
    {name: 'Thailand', code: 'TH'},
    {name: 'Timor-Leste', code: 'TL'},
    {name: 'Togo', code: 'TG'},
    {name: 'Tokelau', code: 'TK'},
    {name: 'Tonga', code: 'TO'},
    {name: 'Trinidad and Tobago', code: 'TT'},
    {name: 'Tunisia', code: 'TN'},
    {name: 'Turkey', code: 'TR'},
    {name: 'Turkmenistan', code: 'TM'},
    {name: 'Turks and Caicos Islands', code: 'TC'},
    {name: 'Tuvalu', code: 'TV'},
    {name: 'Uganda', code: 'UG'},
    {name: 'Ukraine', code: 'UA'},
    {name: 'United Arab Emirates', code: 'AE'},
    {name: 'United Kingdom', code: 'GB'},
    {name: 'United States', code: 'US'},
    {name: 'United States Minor Outlying Islands', code: 'UM'},
    {name: 'Uruguay', code: 'UY'},
    {name: 'Uzbekistan', code: 'UZ'},
    {name: 'Vanuatu', code: 'VU'},
    {name: 'Venezuela', code: 'VE'},
    {name: 'Viet Nam', code: 'VN'},
    {name: 'Virgin Islands, British', code: 'VG'},
    {name: 'Virgin Islands, U.S.', code: 'VI'},
    {name: 'Wallis and Futuna', code: 'WF'},
    {name: 'Western Sahara', code: 'EH'},
    {name: 'Yemen', code: 'YE'},
    {name: 'Zambia', code: 'ZM'},
    {name: 'Zimbabwe', code: 'ZW'}
  ],
  states: [
    {
      name: 'Alabama',
      code: 'AL'
    },
    {
      name: 'Alaska',
      code: 'AK'
    },
    {
      name: 'American Samoa',
      code: 'AS'
    },
    {
      name: 'Arizona',
      code: 'AZ'
    },
    {
      name: 'Arkansas',
      code: 'AR'
    },
    {
      name: 'California',
      code: 'CA'
    },
    {
      name: 'Colorado',
      code: 'CO'
    },
    {
      name: 'Connecticut',
      code: 'CT'
    },
    {
      name: 'Delaware',
      code: 'DE'
    },
    {
      name: 'District Of Columbia',
      code: 'DC'
    },
    {
      name: 'Federated States Of Micronesia',
      code: 'FM'
    },
    {
      name: 'Florida',
      code: 'FL'
    },
    {
      name: 'Georgia',
      code: 'GA'
    },
    {
      name: 'Guam',
      code: 'GU'
    },
    {
      name: 'Hawaii',
      code: 'HI'
    },
    {
      name: 'Idaho',
      code: 'ID'
    },
    {
      name: 'Illinois',
      code: 'IL'
    },
    {
      name: 'Indiana',
      code: 'IN'
    },
    {
      name: 'Iowa',
      code: 'IA'
    },
    {
      name: 'Kansas',
      code: 'KS'
    },
    {
      name: 'Kentucky',
      code: 'KY'
    },
    {
      name: 'Louisiana',
      code: 'LA'
    },
    {
      name: 'Maine',
      code: 'ME'
    },
    {
      name: 'Marshall Islands',
      code: 'MH'
    },
    {
      name: 'Maryland',
      code: 'MD'
    },
    {
      name: 'Massachusetts',
      code: 'MA'
    },
    {
      name: 'Michigan',
      code: 'MI'
    },
    {
      name: 'Minnesota',
      code: 'MN'
    },
    {
      name: 'Mississippi',
      code: 'MS'
    },
    {
      name: 'Missouri',
      code: 'MO'
    },
    {
      name: 'Montana',
      code: 'MT'
    },
    {
      name: 'Nebraska',
      code: 'NE'
    },
    {
      name: 'Nevada',
      code: 'NV'
    },
    {
      name: 'New Hampshire',
      code: 'NH'
    },
    {
      name: 'New Jersey',
      code: 'NJ'
    },
    {
      name: 'New Mexico',
      code: 'NM'
    },
    {
      name: 'New York',
      code: 'NY'
    },
    {
      name: 'North Carolina',
      code: 'NC'
    },
    {
      name: 'North Dakota',
      code: 'ND'
    },
    {
      name: 'Northern Mariana Islands',
      code: 'MP'
    },
    {
      name: 'Ohio',
      code: 'OH'
    },
    {
      name: 'Oklahoma',
      code: 'OK'
    },
    {
      name: 'Oregon',
      code: 'OR'
    },
    {
      name: 'Palau',
      code: 'PW'
    },
    {
      name: 'Pennsylvania',
      code: 'PA'
    },
    {
      name: 'Puerto Rico',
      code: 'PR'
    },
    {
      name: 'Rhode Island',
      code: 'RI'
    },
    {
      name: 'South Carolina',
      code: 'SC'
    },
    {
      name: 'South Dakota',
      code: 'SD'
    },
    {
      name: 'Tennessee',
      code: 'TN'
    },
    {
      name: 'Texas',
      code: 'TX'
    },
    {
      name: 'Utah',
      code: 'UT'
    },
    {
      name: 'Vermont',
      code: 'VT'
    },
    {
      name: 'Virgin Islands',
      code: 'VI'
    },
    {
      name: 'Virginia',
      code: 'VA'
    },
    {
      name: 'Washington',
      code: 'WA'
    },
    {
      name: 'West Virginia',
      code: 'WV'
    },
    {
      name: 'Wisconsin',
      code: 'WI'
    },
    {
      name: 'Wyoming',
      code: 'WY'
    }
  ],
  isoCountries: {
    AF: 'Afghanistan',
    AX: 'Aland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua And Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia And Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo, Democratic Republic',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: 'Cote D\'Ivoire',
    HR: 'Croatia',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands (Malvinas)',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island & Mcdonald Islands',
    VA: 'Holy See (Vatican City State)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran, Islamic Republic Of',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle Of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KR: 'Korea',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Lao People\'s Democratic Republic',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libyan Arab Jamahiriya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia, Federated States Of',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    AN: 'Netherlands Antilles',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territory, Occupied',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    BL: 'Saint Barthelemy',
    SH: 'Saint Helena',
    KN: 'Saint Kitts And Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin',
    PM: 'Saint Pierre And Miquelon',
    VC: 'Saint Vincent And Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome And Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia And Sandwich Isl.',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard And Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad And Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks And Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UM: 'United States Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Viet Nam',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    WF: 'Wallis And Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe'
  },

  // URL of development API
  csvFileUrl: 'https://admin.triphop.com/cdn/corporate/bulk-invite-sample.csv',
  host: 'http://localhost:4200/',
  companyHost: 'http://localhost:4200/',
  apiUrl: 'https://api.triphop.com/v2/api-docs',
  apiBase: 'https://api.triphop.com',
  slackApiBase: 'https://api.triphop.com:8788',
  termsUrl: 'https://safarhub.com/terms/',
  privacyUrl: 'https://safarhub.com/privacy-policy/',
  termsUrlAR: 'https://safarhub.com/ar/terms-ar/',
  privacyUrlAR: 'https://safarhub.com/ar/privacy-policy-ar/',
  releaseNumber: '2.0RC7',
  //facebookPixel: '999058260231112',
  apiIdFB: '318940141857777',
  slackAppId: 'A01GV57EB9T',
  // hotjarId: 750622,
  hotjarId:2568695,
  quoraPixel: 'ab7c4dbada6344d783e28994095a63f4',
  paypal: 'AVtPq9UHSsiI2d5cqYKJL6BkIXPTDzGIEzrWgWeoeuWhLelTfm4QA9-SZvWtN5RQ846o_RRW3PhaU3uR',
  PAYPAL_AUTHEND: 'sandbox',
  ratesExpiryDurationMS: 60000,
  flightRateDurationMS: 600000,
  stripeKey: 'pk_test_51LFBldFk9Pfb8sDKeUuPoY1AapmKgVrXR5aV1LNwGiicLeCjBttFspK4fBhbBDMSdrpC3P0Srk5Pi7CNB7DGPmdI00qK6aHznl',
  // stripeKey: 'pk_test_egWgqbYzT8wlQkQS5Pr6FzwO00gX6eLX34',
 //  stripeKey: 'pk_test_rwLuXd6APtYNwOKwq9NwTFC0',


  googlePayEnv:'TEST',
  referralPrograms: {
    inviterReferralProgram: 'ReferralProgram::1',
    defaultReferralProgram: '',
    defaultReferrerId: 'User::TriphopPromo',
  },
  apiUrls: {
    collect_lead: '/access/collect_lead',
    lookup: '/booking/lookup',
    login: '/access/login',
    userInfo: '/user/info',
    socialMedia: '/access/social_login?socialNetwork=',
    logOut: '/user/logout',
    signUp: '/access/signup',
    checkmail: '/access/check_email',
    forgetPassword: '/access/forget_password',
    availability: '/booking/v2/availability',
    contactUs: '/access/contact_us',
    retrieve: '/booking/retrieve_booking',
    priceGuarantee: '/misc/price_guarantee_claim',
    nearby: '/booking/nearby',
    tripAdviser: '/booking/v2/ta_hotel_review_summ',
    userInvite: '/user/invite',
    refererDetails: '/access/referer_details',
    refInfo: '/access/ref',
    checkCancellationFees: '/booking/calculate_cancellation_fee',
    leaveBookingFeedback: '/booking/submit_feedback',
    sendVoucher: '/booking/send_voucher',
    cancel: '/booking/cancel_booking',
    userDashboard: '/user/dashboard',
    userUpdate: '/user/update',
    changeEmail: '/user/change_email',
    changePassword: '/user/change_password',
    listWallets: '/user/redemption_channels',
    deleteWallets: '/user/delete_redemption_channel',
    editWallet: '/user/update_redemption_channel',
    recheck: '/booking/recheck',
    booking: '/booking/v2/book',
    bookingHold: '/booking/book_hold',

    checkCashback: '/user/bc_locked_cashback',
    addWallet: '/user/add_redemption_channel',
    savedCard: '/user/payment_methods',

    applyCoupon: '/misc/apply_coupon',
    clearCoupon: '/misc/clear_coupon',
    discounts: '/user/discounts',
    clearDiscount: '/user/clear_discount',
    applyDiscount: '/user/apply_discount',
    listPayments: '/user/payment_methods',

    updatePayment: '/user/update_card',
    deletePayment: '/user/delete_card',
    listBookings: '/user/bookings',
    checkRedemptionFees: '/user/check_cashback_redeem_fees',
    listCashback: '/user/list_cashback_statement',
    redeemCashback: '/user/redeem_cashback',
    promoContent: '/misc/promo_content',
    leadsAccess: '/access/collect_lead',
    mailId: '/misc/mail_id_info',
    resendVerificationCode: '/user/send_user_verification_email',
    fav: '/user/fav',
    getFavorites: '/user/get_favorites',
    verify: '/access/verify_email',
    resetPassword: '/access/reset_password',
    addPayment: '/user/add_card',
    shareHotels: '/misc/share_hotels',
    searchHistory: '/user/search_history',
    favDelete: '/user/fav_delete',
    linkProfile: '/access/link_profile',
    linkUserSocialProfile: '/user/link_profile',
    sendAccountLink: '/access/send_account_link',
    linkAccountVerification: '/access/link_account_verification',
    holdRate: '/booking/hold_rate',
    retrieveHold: '/booking/retrieve_hold',
    primeSubscribe: '/user/prime_subscribe',
    primeChangePlan: '/user/prime_change_plan',
    primePlans: '/access/prime_plans',
    primeCancel: '/user/prime_cancel',


},
  PromoPopup: {
    landingPopupTimeoutInSeconds: 5,
    enableLandingPopup: false,

    whitelist: ['availability/hotel', 'availability'],
    blacklist: [],

    blockPopupUtms: {

      utm_source: 'mailchimp,mandrill',
      utm_medium: 'email',
    }


  },

  ReferralProgram: {
// program should start with campaign,medium then source
    'ReferralProgram::1': 'cam,med,src',


    'ReferralProgram::2': 'cam2,med2,src2',


    'ReferralProgram::3': 'camp,,',


    'ReferralProgram::4': ',,src',


    'ReferralProgram::5': ',med,',


    'ReferralProgram::6': 'camp,med,',


  },
  firebaseConfigTimeOut:1000,
 /* firebaseConfig:{
    apiKey: "AIzaSyAYpJnT76n_LPEgM4MCt5o6F90XMIa2z_s",
    authDomain: "triphop-1132.firebaseapp.com",
    databaseURL: "https://triphop-1132.firebaseio.com",
    projectId: "triphop-1132",
    storageBucket: "triphop-1132.appspot.com",
    messagingSenderId: "680484857667",
    appId: "1:680484857667:web:1f5e839b7765f9912671f6",
    measurementId: "G-QR6B0YDVPZ"
  },*/
 /* firebaseConfig:{
    apiKey: "AIzaSyBhLyyWiNJgxejTwCD-FToTydxhCr-qCWE",
    authDomain: "safarhubpro.firebaseapp.com",
    projectId: "safarhubpro",
    storageBucket: "safarhubpro.appspot.com",
    messagingSenderId: "711449167233",
    appId: "1:711449167233:web:1b7bc49f16aec6cf8f5480",
    measurementId: "G-WD5PQPBG88"
  }*/
  /*firebaseConfig:{
    apiKey: "AIzaSyAZ98nxvcj5YiQvquuInntNK3Oes6Ht6JU",
    authDomain: "safarhub-d96c0.firebaseapp.com",
    projectId: "safarhub-d96c0",
    storageBucket: "safarhub-d96c0.appspot.com",
    messagingSenderId: "1056646148233",
    appId: "1:1056646148233:web:c8dd5b3ef7f2bbc6875658",
    measurementId: "G-QD1F8M87ZZ"
  }*/
  firebaseConfig:{
    apiKey: "AIzaSyDY1hf44SOzk0la72lvZ3NFijjHtpjys8c",
    authDomain: "safar-hub.firebaseapp.com",
    projectId: "safar-hub",
    storageBucket: "safar-hub.appspot.com",
    messagingSenderId: "1021863076191",
    appId: "1:1021863076191:web:07057158d5cff68842d6ea",
    measurementId: "G-6R6R3D8Q1B"
  }
};
