import { Component, OnInit } from '@angular/core';
import {ApprovalsService} from "../../../../services/approvals.service";
import {DataService} from "../../../../services/data.service";
import {UserService} from "../../../../services/user.service";

@Component({
  selector: 'app-approval-operations',
  templateUrl: './approval-operations.component.html',
  styleUrls: ['./approval-operations.component.scss']
})
export class ApprovalOperationsComponent implements OnInit {

  constructor(public approvalsService: ApprovalsService,
              public dataService: DataService,
              public userService: UserService) { }

  ngOnInit(): void {
  }

}
