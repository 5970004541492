<form [formGroup]="bookingForm" (ngSubmit)="submitAddCardForm()" id="bookingForm">

<div class="section-body gray-text-dark p-4 mt-1">
    <div class="row">
      <div class="col-md-7">


        <div class="mt-2 mb-4">

          <div class="form-group">
            <label *ngIf="this.Service.desktopView" i18n="@@cardPlaceholder"> Cardholder Name </label>
            <input *ngIf="this.Service.desktopView" (keyup.enter)="submitAddCardForm()" type="text" name="HolderName"  formControlName="HolderName" class="form-control" placeholder=""  autocomplete="off"   [ngClass]="{ 'is-invalid': cardholder || f.HolderName.errors  }"  >
            <input *ngIf="this.Service.mobileView" type="text" name="HolderName"  formControlName="HolderName" class="form-control" i18n-placeholder="@@cardPlaceholder" placeholder="Cardholder Name"  autocomplete="off"  [ngClass]="{ 'is-invalid': cardholder || f.HolderName.errors } " >

            <div *ngIf="cardholder" class="text-danger mt-1 font-12"  i18n="@@cardPlaceholderRequired">
              Cardholder Name is required
            </div>

            <div *ngIf="f.HolderName.errors" class="smaller text-danger mt-1" i18n="@@cardPlaceholderInvalid">
              Invalid Cardholder Name
            </div>

          </div>



          <div class="" >
            <p *ngIf="this.Service.desktopView" i18n="@@enterCardDetails"> Enter card details here </p>
            <div class="form-control">
              <div id="card-element" class="field">
              </div>
            </div>

            <div  class="font-12 mt-1 text-danger">
              {{stripeError}}

            </div>
          </div>


          <div class="form-group mt-3">
            <label *ngIf="this.Service.desktopView" i18n="@@cardAlias"> Card Alias (optional) </label>
            <input *ngIf="this.Service.desktopView" (keyup.enter)="submitAddCardForm()" type="text" name="cardAlias"  formControlName="cardAlias" class="form-control" placeholder=""  autocomplete="off">
            <input *ngIf="this.Service.mobileView" (keyup.enter)="submitAddCardForm()" type="text" name="cardAlias"  formControlName="cardAlias" class="form-control" i18n-placeholder="@@cardAlias" placeholder="Card Alias (optional)"  autocomplete="off">
          </div>



        </div>


        <div class="border-sm-0" [ngClass]="{'border-left': Service.isSiteLtr(), 'border-right text-right': Service.isSiteRtl()}" *ngIf="Service.mobileView">
          <h3 class="pt-3 pb-3 pt-lg-0 pb-lg-2 gray-primaray-text text-medium font-weight-bold" i18n="@@WeAccept">We accept</h3>
          <ul class="list-inline card-type-list">
            <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/visa.png"  alt="visa"></li>
            <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/mastercard.png" alt="mastercard"></li>
            <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/american_express.png" alt="americal express"></li>
            <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/discover.png" alt="discover"></li>
            <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/jcb.png" alt="jcb"></li>
            <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/diners_club.png" alt="diners club"></li>
          </ul>

          <h3 class="gray-primaray-text text-medium font-weight-bold" i18n="@@countOnUs">You can count on us</h3>
          <ul class="checks">
            <li class="mb-1" i18n="@@secureData">Data is transmitted securely</li>
            <li class="mb-1" i18n="@@protectInfo">We protect your personal information</li>
          </ul>

          <div class="d-none d-md-block">
            <a style="border: none; margin-bottom: 1rem; zoom: 0.6; cursor: pointer;" ><img width="130px" src="https://cdn.ssl.com/assets/ssl_seal_1-3dfcdf69a9e544495dbcf366a74ee5c4baca7e4f8018f7ddfa22f2de9bcf464d.png" alt="Ssl seal"></a>
            <div i18n="@@infoExchanged">Information exchanged is encrypted before transmission.</div>
          </div>
        </div>

        <div class="form-actions d-flex justify-content-md-start justify-content-center mt-5 mt-md-0" *ngIf="!(Service.isCompany() && Service.mobileView)">
          <button type="button" class="btn-outline-blue btn cancel font-14"
                  [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}"
                  (click)="close()" i18n="@@cancelBtn"> Cancel</button>
          <button type="button" [ngClass]="{'m-progress': submitting }" class="loader_button btn blue_btn blue_btn_form form-submit" (click)="submitAddCardForm()" i18n="@@save">Save</button>
        </div>
        <div class="form-actions justify-content-md-start justify-content-center mt-5 mt-md-0" *ngIf="Service.isCompany() && Service.mobileView">
          <button type="button" [ngClass]="{'m-progress': submitting }" class="loader_button btn blue_btn blue_btn_form form-submit w-100 mb-3" (click)="submitAddCardForm()" i18n="@@save">Save</button>
          <button type="button" class="btn-outline-blue btn cancel w-100" (click)="close()" i18n="@@cancelBtn"> Cancel</button>
        </div>
        <p class="mb-0 mt-3 text-danger" *ngIf=" this.errorMsg">{{this.errorMsg}}</p>
      </div>

      <div class="col-md-5 border-sm-0" *ngIf="Service.desktopView" [ngClass]="{'border-left': Service.isSiteLtr(), 'border-right': Service.isSiteRtl()}">
        <h3 class="pt-3 pb-3 pt-lg-0 pb-lg-2 gray-primaray-text text-medium" i18n="@@WeAccept">We accept</h3>
        <ul class="list-inline card-type-list">
          <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/visa.png"  alt="visa"></li>
          <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/mastercard.png" alt="mastercard"></li>
          <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/american_express.png" alt="americal express"></li>
          <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/discover.png" alt="discover"></li>
          <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/jcb.png" alt="jcb"></li>
          <li class="list-inline-item"><img width="48" height="30" loading="lazy" src="assets/img/payment_methods/diners_club.png" alt="diners club"></li>
        </ul>

        <h3 class="gray-primaray-text text-medium" i18n="@@countOnUs">You can count on us</h3>
        <ul class="checks">
          <li class="mb-1" i18n="@@secureData">Data is transmitted securely</li>
          <li class="mb-1" i18n="@@protectInfo">We protect your personal information</li>
        </ul>

        <div class="d-none d-md-block">
          <a style="border: none; margin-bottom: 1rem; zoom: 0.6; cursor: pointer;" ><img width="130px" src="https://cdn.ssl.com/assets/ssl_seal_1-3dfcdf69a9e544495dbcf366a74ee5c4baca7e4f8018f7ddfa22f2de9bcf464d.png" alt="Ssl seal"></a>
          <div class="" i18n="@@infoExchanged">Information exchanged is encrypted before transmission.</div>
        </div>
      </div>

    </div>



  </div>
</form>
