export class Statistics {
    totals: StatisticsTotals = new StatisticsTotals();
    hotelBookingsTotals: StatisticsTotals = new StatisticsTotals();
    airBookingsTotals: StatisticsTotals = new StatisticsTotals();
    filters: StatisticsFilter = new StatisticsFilter();
    transactions: StatisticsTransactions[] = [];
}

export class StatisticsTotals {
    totalAmount?: number;
    totalRefunds?: number;
    totalBookings?: number;
}

export class StatisticsFilter {
    startDate: string = "";
    endDate: string = "";
    travelersIds: string[] = [];
    costCentersIds: string[] = [];
    labelsIds: string[] = [];
}

export class StatisticsTransactions {
    bookingDate?: string = null;
    travelerFirstName?: string = null;
    travelerLastName?: string = null;
    travelerEmail?: string = null;
    booker?: string = null;
    travelerType?: string = null;
    startDate?: string = null;
    endDate?: string = null;
    service?: string = null;
    status?: string = null;
    amount?: number = null;
    refund?: number = null;
    policyName?: string = null;
    inPolicy?: string = null;
    approvalRequired?: string = null;
    approverName?: string = null;
    destinationCountry?: string = null;
    destinationCity?: string = null;
    refNumber?: string = null;
    costCenter?: string = null;
    labels?: string[] = null;
}