
<div class="main-push-notification white-box animate-C" [ngClass]="{'active': Service.ShowPushNotificationsPopfade}">

<div class="text-center font-18 gray-text-dark">
  <img  src="assets/img/notification-bill.png" class="mb-4" />
  <br />
  <h4 class="font-18 mb-4 font-weight-bold">Get SafarHub browser notifications</h4>
  <p>
    Browser notifications allow you to get your important notifications when you are not active in SafarHub.com

  </p>

  <div class="mt-5 mb-4">
    To turn them on, click “allow”  on the next screen

  </div>
  <button (click)="this.FireService.getPermissionMessaging()" type="button" class="blue_btn mb-4 btn btn-block" >Continue</button>
  <button class="blue-text btn-link btn btn-block" (click)="Service.PushNotificationsPopUpOf()">Maybe Later</button>

</div>

</div>


<div   (click)="this.Service.PushNotificationsPopUpOf()" class="modal-backdrop show"></div>
