import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {VariablesService} from '../../services/vars.service';

@Component({
  selector: 'app-send-verification-email',
  templateUrl: './send-verification-email.component.html',
  styleUrls: ['./send-verification-email.component.scss']
})
export class SendVerificationEmailComponent implements OnInit {
data;
  ActiveSnakBar = false;
  snakBarText = '';
  constructor(public dataService: DataService, public router: Router, public varsService: VariablesService) { }

  ngOnInit(): void {
    this.dataService.alerts = [];
  }
  sendCompanyVerificationEmail(email) {
    this.dataService.sendCompanyVerificationEmail(email).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.SnakBar();
        } else{
          this.dataService.messages(this.varsService.messages.COMPANY_SENT_VERIFICATION_EMAIL_ERROR, 'error');
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
      }
    );
  }
  SnakBar(){
    this.ActiveSnakBar = true;
    setTimeout(() => {
      this.ActiveSnakBar = false;

    }, 3000);
  }
}
