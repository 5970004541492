<div class="white-box mt-4 mb-4 pb-4"
     *ngIf="(costCenters && costCenters.length) || (labels && labels.length)">
  <div class="d-flex justify-content-start">
    <h3 class="font-weight-bold" i18n="@@costReporting">Cost Reporting</h3>
  </div>

  <div class="form-group form-type-select mt-2 mb-3">
    <p class="gray-text mb-0 pb-1" i18n="@@costCenter">Cost Center</p>
    <ng-multiselect-dropdown name="cost" [(ngModel)]="selectedCostCenters" (onSelect)="costCenterChange()"
      (onDeSelect)="costCenterChange()" (onSelectAll)="costCenterChange()" (onDeSelectAll)="costCenterChange()"
      [settings]="costCentersDropdownSettings" [data]="costCenters" placeholder="Select cost center" i18n-placeholder="@@selectCostCenter" class="w-100">
    </ng-multiselect-dropdown>


    <div *ngIf="isSubmitted && !isValidCenter" class="text-danger mt-1" i18n="@@costReportingRequired">
      Cost center required
    </div>
  </div>

  <div class="form-group form-type-select">
    <p class="gray-text mb-0 pb-1" i18n="@@labels">Label(s)</p>
    <ng-multiselect-dropdown name="label" [(ngModel)]="selectedLabels" (onSelect)="labelsChange()"
      (onDeSelect)="labelsChange()" (onSelectAll)="labelsChange()" (onDeSelectAll)="labelsChange()"
      [settings]="labelsDropdownSettings" [data]="labels" placeholder="Select label" i18n-placeholder="@@selectLabel" class="w-100">
    </ng-multiselect-dropdown>

    <div *ngIf="isSubmitted && !isValidLabels" class="text-danger mt-1" i18n="@@labelsRequired">
      Label(s) required
    </div>
  </div>
</div>
