import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login-error-page',
  templateUrl: './login-error-page.component.html',
  styleUrls: ['./login-error-page.component.scss']
})
export class LoginErrorPageComponent implements OnInit {

  constructor( public dataService: DataService, public router: Router) { }
  @Input() errorMsg: string;
  previousURL;
  ngOnInit(): void {
    if (this.router.url.includes('/slack')) {
      this.previousURL = '/slack';
    } else {
      this.previousURL = '/login';
    }
  }
}
