<div [ngClass]="{'container pt-3': dataService.mobileView}">

<div *ngIf="this.dataService.modalName === 'deleteDoc' || this.dataService.BottomSheetName === 'deleteDoc'">
  <div class="justify-content-center">
    <div class="d-flex mb-4">
      <i class="icon-warning-msg pt-1" [ngClass]="{ 'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
      <p class="gray-text-dark font-16" i18n="@@removeDocMsg">Are you sure you want to delete this travel document? This action cannot be undone!</p>
    </div>
    <div class="d-flex justify-content-end" *ngIf="dataService.desktopView">
      <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" (click)="this.dataService.modalOf()" i18n="@@cancelBtn">Cancel </button>
      <button class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5" [ngClass]="{'m-progress': submitting, 'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}" (click)="this.deleteDoc();" i18n="@@deleteBtn">Delete</button>
    </div>
    <div *ngIf="dataService.mobileView">
      <button class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5 w-100 mb-3" [ngClass]="{'m-progress': submitting }" (click)="this.deleteDoc();" i18n="@@deleteBtn">Delete</button>
      <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5 w-100 " (click)="this.dataService.modalOf(); dataService.BottomSheetOf()" i18n="@@cancelBtn">Cancel </button>
    </div>
  </div>
  <p class="text-danger" [ngClass]="{'text-right': dataService.desktopView}">{{errorMessage}}</p>
</div>

<div *ngIf="this.dataService.modalName === 'deleteProgram' || this.dataService.BottomSheetName === 'deleteProgram'">
  <div class="justify-content-center">
    <div class="d-flex mb-4">
      <i class="icon-warning-msg pt-1" [ngClass]="{ 'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
      <p class="gray-text-dark font-16" i18n="@@removeProgMsg">Are you sure you want to delete this flyer program? This action cannot be undone!</p>
    </div>
    <div class="d-flex justify-content-end" *ngIf="dataService.desktopView">
      <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" (click)="this.dataService.modalOf()" i18n="@@cancelBtn">Cancel </button>
      <button class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5" [ngClass]="{'m-progress': submitting,
      'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}" (click)="this.deleteProgram();" i18n="@@deleteBtn">Delete</button>
    </div>
    <div *ngIf="dataService.mobileView">
      <button class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5 w-100 mb-3" [ngClass]="{'m-progress': submitting }" (click)="this.deleteProgram();" i18n="@@deleteBtn">Delete</button>
      <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5 w-100" (click)="this.dataService.modalOf(); dataService.BottomSheetOf()" i18n="@@cancelBtn">Cancel </button>
    </div>
  </div>
  <p class="text-danger" [ngClass]="{'text-right': dataService.desktopView}">{{errorMessage}}</p>
</div>
</div>
