
<div class="main-container border-0 pb-5" [ngClass]="{'gray-pass-bg' : dataService.desktopView}">

  <div class="container pb-5" [ngClass]="{'desktop-design': dataService.desktopView, 'mb-5 pt-5': dataService.mobileView}">
    <img width="481" height="406" loading="lazy" class="fixed-img" src="assets/img/Safarhub-signup-img.jpg" *ngIf="dataService.desktopView">
    <div class="custom-container workspace-registration-container" [ngClass]="{'ml-auto' : dataService.desktopView && dataService.isSiteLtr(), 'mr-auto' : dataService.desktopView && dataService.isSiteRtl(), 'mx-auto': dataService.mobileView}">
      <div class="login-form position-relative ml-auto px-lg-5 pt-5 corporate-box d-flex align-items-center" [ngClass]="{'white-box position-relative' : dataService.desktopView, 'mx-auto': dataService.mobileView,
      'mr-auto': dataService.desktopView && dataService.isSiteLtr(), 'ml-auto': dataService.desktopView && dataService.isSiteRtl()}">

        <div class="w-100">
          <h4 class="font-24 mb-0 font-weight-bold pb-5 text-center " i18n="@@verify1">Verify your email</h4>
          <p class="gray-text font-16 pb-0 pb-lg-4 mb-0 mb-lg-3 text-center px-4" i18n="@@verify2">We’ve sent you an email with a verification link.</p>
          <p class="mt-5 text-center font-16 pb-0 pb-lg-4 mb-0 mb-lg-3 px-4" [ngClass]="{'d-flex': dataService.desktopView}">
            <span [ngClass]="{'ml-auto': dataService.isSiteLtr() && dataService.desktopView, 'mr-auto': dataService.isSiteRtl() && dataService.desktopView}" i18n="@@verify3">Didn’t receive the link? </span><br *ngIf="dataService.mobileView"/>
            <a class="blue-text" [ngClass]="{'ml-3 mr-auto': dataService.isSiteLtr() && dataService.desktopView, 'mr-3 ml-auto': dataService.isSiteRtl() && dataService.desktopView}"
               (click)="sendCompanyVerificationEmail(this.dataService.companyEmail)" i18n="@@verify4">re-send</a></p>

        </div>
      </div>

    </div>
  </div>

</div>

<div  [ngClass]="{'active': this.ActiveSnakBar}"  class="snackbar"> <p class="text-center" i18n="@@verifySnackBarMsg">Verification email was sent to {{this.dataService.companyEmail}} successfully</p></div>

