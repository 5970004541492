<div class="table-header mb-4 rounded p-3" *ngIf="flightSummary">
    <div *ngFor="let flight of flightSummary.itineraries,  let x = index">
        <p class="mb-0"><span class="font-20 primaray-text font-weight-bold"
                              [innerHTML]="Service.formatDateEEMMMdd(flight.searchSegmentSummary.departureDate)"></span></p>
        <p><span class="gray-text-dark font-16"> {{flight.searchSegmentSummary.departureAirport.city}} <span
                class="text-lowercase" i18n="@@to">to</span> {{flight.searchSegmentSummary.arrivalAirport.city}}</span></p>

        <div class="white-box px-0 py-3 mb-4">
            <div class="row h-100">
                <div class="col-1 pt-1" [ngClass]="{'pr-0': Service.isSiteLtr(), 'pl-0': Service.isSiteRtl()}">
                </div>
                <div class="col-1 px-0">
                    <img width="26.5" height="27" loading="lazy" class="max-100 initial-height img-min-width"
                         [src]="flight.searchSegmentSummary.airlineInfoSummary.marketingAirlineLogoUrl"
                         onError="this.src='/assets/img/airline.png'" />
                </div>
                <div class="col-10 position-relative"
                     [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}">
                    <p class="font-18 primaray-text font-weight-bold mb-0">
                <span class="mb-0 primaray-text"
                      [innerHTML]="this.Service.formatDateTime12Hours(flight.searchSegmentSummary.departureDate)">10:15pm</span> -
                        <span class="mb-0 primaray-text"
                              [innerHTML]="this.Service.formatDateTime12Hours(flight.searchSegmentSummary.arrivalDate)">10:15pm</span>
                        <span class="font-14 font-weight-normal"
                              *ngIf="Service.checkNextDay(flight.searchSegmentSummary.departureDate, flight.searchSegmentSummary.arrivalDate) === 1"
                              i18n="@@nextDay"> (Next day)</span>
                        <span class="font-14 font-weight-normal"
                              *ngIf="Service.checkNextDay(flight.searchSegmentSummary.departureDate, flight.searchSegmentSummary.arrivalDate) > 1">
                  +{{Service.checkNextDay(flight.searchSegmentSummary.departureDate, flight.searchSegmentSummary.arrivalDate)}}</span>
                        <span class="font-14 font-weight-normal"
                              *ngIf="Service.checkPreviousDay(flight.searchSegmentSummary.departureDate, flight.searchSegmentSummary.arrivalDate)"
                              i18n="@@prevDay"> (Previous day)</span>
                    </p>


                    <div class="d-flex">
                        <p class="gray-text-dark font-14 text-nowrap">
                            <span>{{flight.searchSegmentSummary.departureAirport.iata}}</span><span class="super-vertical-align"> ____
                  </span><span>{{flight.searchSegmentSummary.arrivalAirport.iata}}</span></p>
                        <p class="mb-0 primaray-text pt-small"
                           [ngClass]="{'ml-3': Service.isSiteLtr(), 'mr-3': Service.isSiteRtl()}"><span
                                [innerHTML]="this.Service.calDuration(flight.searchSegmentSummary.journeyDurationInMins)">6h 45m</span>
                            <span [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}"
                                  *ngIf="flight.searchSegmentSummary.totalStops === 1">(<ng-container i18n="@@oneStop">1
                      stop</ng-container>)</span>
                            <span [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}"
                                  *ngIf="flight.searchSegmentSummary.totalStops === 2">(<ng-container i18n="@@twoStops">2
                      stops</ng-container>)</span>
                            <span [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}"
                                  *ngIf="flight.searchSegmentSummary.totalStops > 2">({{flight.searchSegmentSummary.totalStops}}&nbsp;<ng-container
                                    i18n="@@stops">stops</ng-container>)</span>
                        </p>
                        <p class="gray-text-dark ml-2 pt-small text-lowercase" *ngIf="flight.searchSegmentSummary.totalStops === 0">
                            (<ng-container i18n="@@nonStop">nonstop</ng-container>)</p>
                    </div>

                    <div class="gray-text" *ngIf="flight.searchSegmentSummary.airlineInfoSummary.operatedBy.length">
                        <p class="mb-0"
                           *ngFor="let operator of flight.searchSegmentSummary.airlineInfoSummary.operatedBy ,  let indexOperator = index">
                            {{operator}}
                        </p>
                    </div>


                </div>
            </div>
        </div>
    </div>

    <p class="text-right mb-0 mt-3">
        <span class="blue-text pointer"
              (click)="showFlightDetails()"
              i18n="@@showTripDetails">
            Show trip details
        </span>
    </p>
</div>
