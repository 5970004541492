import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {DataService} from '../../../services/data.service';
import {AuthServiceMain} from '../../../auth/auth.service';

@Component({
  selector: 'app-user-profile-image',
  templateUrl: './user-profile-image.component.html',
  styleUrls: ['./user-profile-image.component.scss']
})
export class UserProfileImageComponent implements OnInit {

  constructor(public dataService: DataService, public auth: AuthServiceMain) { }
  image = new FormData();
  data;
  dataUser;
  ngOnInit(): void {
  }
  removeProfileImage() {
    this.dataService.editProfileImage = !this.dataService.editProfileImage;
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@removeProfileImage:Remove Profile Image`, 'removeProfileImage', '');
    } else {
      this.dataService.setBottomSheet('removeProfileImage', '');
    }
  }
  onFileSelect(event) {
    this.dataService.editProfileImage = !this.dataService.editProfileImage;
    if (event.target.files.length > 0) {
      this.dataService.submitting = true;
      if (this.image.get('profileImage')) {
        this.image.delete('profileImage');
      }
      this.image.append('profileImage', event.target.files[0]) ;
      this.dataService.setProfileImage(this.image).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            // this.imageUrl =  this.data.data.logoUrl;
            this.dataService.submitting = false;
            this.dataService.showDefaultUserImage = false;
            this.auth.userInfo.imageUrl = this.data.data.profileImage;
            this.dataService.userProfileImageWithRandomText = this.auth.userInfo.imageUrl + '?' + (new Date()).getTime();
            localStorage.setItem('userInfo', JSON.stringify(this.auth.userInfo));
            this.dataService.BottomSheetOf();
            this.dataService.modalOf();
            this.dataService.fetchUserData.next();
          } else {
            this.dataService.submitting = false;
            this.dataService.messages(this.data.message, 'error');
          }
        }, error => {
          this.dataService.submitting = false;
          this.dataService.messages(environment.messages.error, 'error');
        }
      );
    }
  }
}
