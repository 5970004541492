export class User {
    constructor(
        public description: string,
        public id: string,
        public email: string,
        public teamName?: string,
        public selected?: boolean
    ) {
        if (selected === undefined) {
            selected = false;
        }
    }
}
