    <div class="p-4 mb-4"
      [ngClass]="{
        'rounded border': dataService.desktopView,
        'table-header': dataService.mobileView
      }">
      <p class="font-16 gray-primaray-text" i18n="@@reviewTermsPolicy">
        Review terms & policy
      </p>
      <p class="gray-text-dark" i18n="@@reviewTicket">
        Please review ticket trip details before completing the payment.
      </p>
      <p class="gray-text-dark" i18n="@@checkTravelerName">
        Check traveler name spelling. Airlines rules require names to exactly
        match government-issued photo ID.
      </p>


      <p class="font-16 mt-5 gray-primaray-text" i18n="@@cancellation1">
        Cancellation
      </p>

      <p class="gray-text-dark mb-0" i18n="@@flightCancellation">
        We do not charge any change or cancellation fee. When the airline charge
        for such fee based on their rules and polices, the cost will be passed
        on you.
      </p>

      <p class="font-16 mt-5 gray-primaray-text" i18n="@@reservationTerms">
        Reservation Terms
      </p>
      <p>
        <ng-container i18n="@@completeBookAgree">By completing this booking, you agree to the</ng-container>&nbsp;
          <a [href]="dataService.isLocaleArabic ? env.termsUrlAR : env.termsUrl" target="_blank">
              <ng-container i18n="@@termsAndConditions">terms and conditions</ng-container>
              <small><i class="fas fa-external-link-alt"></i></small>
          </a>&nbsp;<ng-container i18n="@@ofSafarHub">of SafarHub.</ng-container>
      </p>
      <p class="gray-text-dark mb-0" i18n="@@youWillChargedFLightBooking">
        You will be charged {{flightPricing.flightOffers[0].chargeAmountCurrency}} {{flightPricing.flightOffers[0].chargeAmount | number : '1.0-2'}}
        </p>
    </div>

