<div class="hotel-details">
  <div
    [ngClass]="{
      active: this.Service.ratesExpiry,
      'add-bottom-value':
        this.Service.showCookiesMsg && this.Service.ratesExpiry
    }"
    class="snackbar d-block d-md-flex"
  >
    <p>
      <i
        class="icon-warning"
        [ngClass]="{ 'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl() }"
      ></i
      >&nbsp;<ng-container i18n="@@refreshHotel"
        >Refresh this hotel page to get the latest rates.</ng-container
      >
    </p>
    <a
      class="text-uppercase col-md col-12 d-block px-0 d-block text-left"
      [ngClass]="{
        'text-md-right': Service.isSiteLtr(),
        'text-md-left': Service.isSiteRtl()
      }"
      (click)="refreshPage()"
    >
      <i class="icon-refresh font-16 mr-2"></i>&nbsp;<ng-container
        i18n="@@refresh"
        >refresh</ng-container
      ></a
    >
  </div>

  <div class="top-search">
    <div class="container">
      <div
        [ngClass]="{
          'text-md-right': Service.isSiteLtr(),
          'text-md-left': Service.isSiteRtl()
        }"
      >
        <ul class="search-summary list-unstyled">
          <li class="item dates">
            {{ Params.checkIn | date : "EE, MMM d " }} -
            {{ Params.checkOut | date : "EE, MMM d " }}
          </li>
          <li class="item people_number">
            {{ PaxesArray[0] }}
            <span class="night-cont text-lowercase">
              <ng-container *ngIf="PaxesArray[0] === '1'" i18n="@@adult"
                >Adult</ng-container
              >
              <ng-container *ngIf="PaxesArray[0] === '2'" i18n="@@twoAdults"
                >Adults</ng-container
              >
              <ng-container *ngIf="PaxesArray[0] > 2" i18n="@@adults"
                >Adults</ng-container
              >
            </span>
            <span *ngIf="PaxesArray.length > 1"
              >, {{ PaxesArray.length - 1 }}
              <span class="night-cont text-lowercase">
                <ng-container *ngIf="PaxesArray.length - 1 === 1" i18n="@@child"
                  >Child</ng-container
                >
                <ng-container
                  *ngIf="PaxesArray.length - 1 === 2"
                  i18n="@@twoChildren"
                  >Children</ng-container
                >
                <ng-container
                  *ngIf="PaxesArray.length - 1 > 2"
                  i18n="@@children"
                  >Children</ng-container
                >
              </span>
            </span>
          </li>
          <li class="item button">
            <button
              [ngClass]="{ active: !isCollapsed }"
              class="btn-link collapseSearch"
              (click)="isCollapsed = !isCollapsed"
              [attr.aria-expanded]="!isCollapsed"
              aria-controls="collapseSearch"
            >
              <ng-container i18n="@@edit">Edit</ng-container>&nbsp;<i
                class="icon-down"
              ></i>
            </button>
          </li>
        </ul>
      </div>
      <div id="collapseSearch" [ngbCollapse]="isCollapsed">
        <div class="row mt-3 justify-content-end">
          <div
            class="search_box position-relative"
            [ngClass]="{
              'col-10': !Service.isCompany(),
              'col-11': Service.isCompany()
            }"
            *ngIf="this.Service.desktopView"
          >
            <app-search-controller></app-search-controller>
          </div>
          <div
            class="search_box p-md-5 position-relative col-12"
            *ngIf="this.Service.mobileView"
          >
            <app-search-controller-mobile></app-search-controller-mobile>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="{ container: Service.isCompany() && Service.desktopView }">
    <div [ngClass]="{ 'mx-lg-4': Service.isCompany() && Service.desktopView }">
      <div *ngIf="errorData === true">
        <div class="container custom_container text-center">
          <h2 class="primaray-text font-weight-bold" i18n="@@noSearchCriteria">
            No results match your search criteria. Please modify your search and
            try again
          </h2>
          <img
            width="515"
            height="368"
            loading="lazy"
            class="d-inline-block initial-height"
            src="assets/img/wait.png"
            alt="wait"
          />
        </div>
      </div>
      <div *ngIf="!errorData" class="container mb-5">
        <app-loaders></app-loaders>
        <div *ngIf="!this.Service.hotelPage">
          <div class="row mb-5" *ngIf="hotel">
            <div class="col-lg-9 col-md-8">
              <div class="row no-gutters">
                <div class="col">
                  <h2 class="primaray-text font-weight-bold d-inline">
                    {{ hotel.name }}
                  </h2>
                  <div
                    class="hotel-stars-text mt-3 mb-3 gray-text-dark d-inline-block ml-3 font-weight-bold"
                    *ngIf="hotel.rating >= 1"
                  >
                    {{ hotel.rating }}-<span class="night-cont" i18n="@@stars"
                      >star</span
                    >
                  </div>
                </div>
                <div
                  class="hotelShare user_menu dropdown col-2 pt-3"
                  [ngClass]="{
                    'text-right': Service.isSiteLtr(),
                    'text-left': Service.isSiteRtl()
                  }"
                  *ngIf="this.Service.desktopView"
                >
                  <span
                    (click)="
                      this.Service.toggleFavoriteHotel(hotel.code);
                      this.Service.isFavoriteHotel =
                        !this.Service.isFavoriteHotel
                    "
                    *ngIf="this.logedIn.isLogedIn"
                  >
                    <span
                      class="font-20"
                      [ngClass]="{
                        'icon-colored-heart': this.Service.isFavoriteHotel,
                        'icon-heart': !this.Service.isFavoriteHotel,
                        'pr-2': Service.isSiteLtr(),
                        'pl-2': Service.isSiteRtl()
                      }"
                    >
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </span>
                  </span>
                  <span
                    (click)="
                      this.Service.addNonLoggedInFavId = hotel.code;
                      this.Service.isLoginTitleFavorite = true;
                      this.Service.openloginDialogue()
                    "
                    *ngIf="!this.logedIn.isLogedIn"
                    ><span class="icon-heart pr-2 font-20"
                      ><span class="path1"></span
                      ><span class="path2"></span></span
                  ></span>
                  <span (click)="openHotelSharePopUp(hotel)">
                    <i class="icon-share2 gray-text bold font-18 rounded">
                      <span
                        class="tooltip-share position-absolute rounded karla-font"
                        i18n="@@share"
                        >Share</span
                      >
                    </i>
                  </span>
                </div>
                <div
                  class="hotelShare user_menu dropdown col-2 pt-2"
                  *ngIf="this.Service.mobileView"
                  [ngClass]="{
                    'text-right': Service.isSiteLtr(),
                    'text-left': Service.isSiteRtl()
                  }"
                >
                  <span (click)="openHotelSharePopUp(hotel)"
                    ><i class="icon-share2 gray-text bold font-18"></i
                  ></span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="address-inner gray-text col-auto">
                  <i class="icon-locator gray-text"></i>
                  {{ hotel.address ? hotel.address : "," }},
                  {{ hotel.cityName ? hotel.cityName : ""
                  }}{{ hotel.stateCode && hotel.cityName ? ", " : ""
                  }}{{ hotel.stateCode ? hotel.stateCode : ""
                  }}{{
                    (hotel.stateCode || hotel.cityName) && hotel.countryName
                      ? ", "
                      : ""
                  }}{{ hotel.countryName ? hotel.countryName : "" }}
                </div>
              </div>
              <div class="mb-3">
                <swiper
                  [config]="facilitiesConfig"
                  class="facilities-main-slider"
                >
                  <ng-container *ngFor="let item of hotel.myFacilities">
                    <ng-container *ngIf="item.featured">
                      <div
                        class="facilities-main-item d-inline-block p-2 white-box"
                      >
                        <span class="d-block">
                          <i [class]="'icon-' + item.hrsCode"></i>
                          {{ item.title }}
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>
                </swiper>
              </div>
              <div class="position-relative">
                <div
                  class="hotelShare like user_menu dropdown"
                  *ngIf="this.Service.mobileView"
                  [ngClass]="{
                    'text-right': Service.isSiteLtr(),
                    'text-left': Service.isSiteRtl()
                  }"
                >
                  <span
                    (click)="
                      this.Service.addNonLoggedInFavId = hotel.code;
                      this.Service.isLoginTitleFavorite = true;
                      this.Service.openloginDialogue()
                    "
                    *ngIf="!this.logedIn.isLogedIn"
                    ><span class="icon-heart pr-2 font-20"
                      ><span class="path1"></span
                      ><span class="path2"></span></span
                  ></span>

                  <span
                    (click)="
                      this.Service.toggleFavoriteHotel(hotel.code);
                      this.Service.isFavoriteHotel =
                        !this.Service.isFavoriteHotel
                    "
                    *ngIf="this.logedIn.isLogedIn"
                  >
                    <span
                      class="font-20"
                      [ngClass]="{
                        'icon-colored-heart': this.Service.isFavoriteHotel,
                        'icon-heart': !this.Service.isFavoriteHotel,
                        'pr-2': Service.isSiteLtr(),
                        'pl-2': Service.isSiteRtl()
                      }"
                    >
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </span>
                  </span>
                </div>
                <div
                  class="position-relative mb-5"
                  *ngIf="hotel.images.length > 0"
                >
                  <swiper
                    class="swiper-container"
                    [config]="config"
                    fxFlex="auto"
                    id="lightgallery"
                    (indexChange)="onIndexChange($event)"
                  >
                    <div
                      *ngFor="let image of this.hotelImages; let i = index"
                      class="swiper-slide main light-item"
                      [attr.data-src]="image.url"
                    >
                      <div
                        fxLayout="column"
                        fxLayoutAlign="center  center"
                        fxFlexFill
                        class=""
                        *ngIf="
                          image.url !== '' && image.url.includes('https://')
                        "
                      >
                        <figure
                          onError="this.src='assets/img/no_image.png'"
                          [attr.data-src]="image.url"
                          [ngStyle]="{
                            'background-image': 'url(' + image.url + ')'
                          }"
                        ></figure>
                      </div>
                      <div
                        fxLayout="column"
                        fxLayoutAlign="center  center"
                        fxFlexFill
                        class=""
                        *ngIf="
                          image.url !== '' && !image.url.includes('https://')
                        "
                      >
                        <figure
                          [attr.data-src]="image.url"
                          [ngStyle]="{
                            'background-image':
                              'url(' +
                              image.url.replace('http://', 'https://') +
                              ')'
                          }"
                        ></figure>
                      </div>
                    </div>
                  </swiper>
                  <div class="slider-next main-slider-arrow">
                    <i class="icon-chevron-right"></i>
                  </div>
                  <div class="slider-prev main-slider-arrow">
                    <i class="icon-chevron-left"></i>
                  </div>
                  <div (click)="openLg()" class="more_button">
                    <div class="d-md-block d-none images_count text-center">
                      {{ selectedIndex }} / {{ hotelImages?.length }}
                    </div>
                    <div
                      *ngIf="Service.mobileView"
                      class="viewAll"
                      i18n="@@viewAllPhotos"
                    >
                      View All Photos
                    </div>
                    <div
                      class="viewAll text-center"
                      *ngIf="Service.desktopView"
                    >
                      <span class="icon-circle pr-1 primaray-text"></span>
                      <span class="icon-circle pr-1 primaray-text"></span>
                      <span class="icon-circle primaray-text"></span>
                    </div>
                  </div>
                </div>

                <div class="text-center" *ngIf="hotel.images.length === 0">
                  <img
                    width="279"
                    height="239"
                    loading="lazy"
                    class="no-hotel-mages"
                    src="assets/img/no_image.png"
                  />
                </div>
              </div>
            </div>

            <div
              class="col-lg-3 col-md-4"
              [ngClass]="{
                'extra-top-padding':
                  Service.desktopView && checkFacility(hotel.myFacilities),
                'extra-top-padding-no-facilities':
                  Service.desktopView && !checkFacility(hotel.myFacilities)
              }"
            >
              <div
                class="white-box mb-4 text-center"
                *ngIf="hotel.rooms && hotel.rooms.length != 0"
              >
                <div class="price w-100">
                  <div
                    class="current_price text-center clearfix"
                    [ngClass]="{
                      'prime-color-purple':
                        logedIn.isLead &&
                        hotel.lowestSellingPriceRate
                          .averageNightlySellingPriceConverted &&
                        !this.Service.isCompany()
                    }"
                    *ngIf="hotel.lowestSellingPriceRate"
                  >
                    <del
                      class="old-price font-20 line d-inline-block"
                      [ngClass]="{
                        'mr-2': Service.isSiteLtr(),
                        'ml-2': Service.isSiteRtl()
                      }"
                      *ngIf="
                        logedIn.isLead &&
                        hotel.lowestSellingPriceRate &&
                        hotel.lowestSellingPriceRate
                          .publicAverageNightlySellingPrice &&
                        hotel.lowestSellingPriceRate
                          .averageNightlySellingPriceConverted &&
                        !this.Service.isCompany()
                      "
                    >
                      <span>{{hotel.lowestSellingPriceRate
                          .averageNightlySellingPriceConvertedCurrency}}</span
                      ><span>{{
                        hotel.lowestSellingPriceRate
                          .publicAverageNightlySellingPrice | number : "1.0-0"
                      }}</span>

                      <br />
                    </del>

                    <ng-container
                      *ngIf="
                        logedIn.isLead &&
                        hotel.lowestSellingPriceRate
                          .averageNightlySellingPriceConverted &&
                        hotel.lowestSellingPriceRate.averageNightlySellingPrice
                      "
                    >
                      <span class="amount-currency">{{currency}}</span>
                      <span class="amount-currency"> {{
                        hotel.lowestSellingPriceRate
                          .averageNightlySellingPriceConverted | number : "1.0-0"
                      }} </span>

                      <span class="nightWord" i18n="@@slashNight">/night</span>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        !logedIn.isLead &&
                        hotel.lowestSellingPriceRate.averageNightlySellingPrice
                      "
                    >
                      <span class="amount-currency">{{currency}}</span>
                      <span class="amount-currency"> {{
                        hotel.lowestSellingPriceRate.averageNightlySellingPrice
                          | number : "1.0-0"
                      }} </span>
                      <span class="nightWord" i18n="@@slashNight">/night</span>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        logedIn.isLead &&
                        hotel.lowestSellingPriceRate
                          .averageNightlySellingPriceConverted &&
                        !hotel.lowestSellingPriceRate.averageNightlySellingPrice
                      "
                    >
                      <span class="amount-currency">{{currency}}</span>
                      <span class="amount-currency"> {{
                        hotel.lowestSellingPriceRate
                          .averageNightlySellingPriceConverted | number : "1.0-0"
                      }} </span>
                      <span class="nightWord" i18n="@@slashNight">/night</span>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        logedIn.isLead &&
                        !hotel.lowestSellingPriceRate
                          .averageNightlySellingPriceConverted &&
                        hotel.lowestSellingPriceRate.averageNightlySellingPrice
                      "
                    >
                      <span class="amount-currency">{{currency}}</span>
                      <span class="amount-currency"> {{
                        hotel.lowestSellingPriceRate.averageNightlySellingPrice
                          | number : "1.0-0"
                      }} </span>
                      <span class="nightWord" i18n="@@slashNight">/night</span>
                    </ng-container>

                    <br />
                  </div>

                  <div
                    class="tax mt-2 text-center"
                    [ngClass]="{
                      'text-right': Service.isSiteLtr(),
                      'text-left': Service.isSiteRtl()
                    }"
                    *ngIf="
                      hotel.lowestSellingPriceRate &&
                      hotel.lowestSellingPriceRate.taxIncluded &&
                      (hotel.lowestSellingPriceRate
                        .averageNightlySellingPrice ||
                        logedIn.isLead)
                    "
                    i18n="@@taxAndFeesIncluded"
                  >
                    Tax and fees included
                  </div>
                  <div
                    class="text-danger text-center"
                    *ngIf="hotel?.userData?.bookingWindowViolated ||
                                      hotel?.userData?.starRatingViolated ||
                                      hotel?.userData?.medianPriceViolated ||
                                      hotel?.userData?.exceedsPolicyLimit ||
                                      policyDetails?.hotelBookingNotAllowed === true"
                  >
                  <span matTooltip="{{hotelsListService.getHotelPolicyToolTip(hotel?.userData)}}"
                        [matTooltipClass]="Service.isSiteRtl() ? 'rtl-tooltip' : 'default-tooltip'"
                        matTooltipClass="custom-tooltip"

                  i18n="@@outOfPolicy">
                    Out of policy
                  </span>
                  </div>
                </div>
                <button
                  *ngIf="
                    hotel.lowestSellingPriceRate.averageNightlySellingPrice;
                    else ViewRooms
                  "
                  class="green_btn btn-block mt-4"
                  [ngx-scroll-to]="'#rooms'"
                  i18n="@@chooseRoom"
                >
                  Choose Room
                </button>
                <ng-template #ViewRooms>
                  <button
                    class="green_btn btn-block mt-4"
                    [ngx-scroll-to]="'#rooms'"
                    i18n="@@viewRooms"
                  >
                    View Rooms
                  </button>
                </ng-template>
              </div>

              <div
                class="white-box mb-4 pb-4 pt-3 text-center position-relative"
                *ngIf="hotel.googleReview"
              >
                <div class="google-review-cont">
                  <div
                    class="super-big font-weight-bold gray-primaray-text align-middle d-inline-block"
                  >
                    {{ hotel.googleReview.rating }}
                  </div>
                  <div
                    class="d-inline-block align-middle"
                    [ngClass]="{
                      'text-left': Service.isSiteLtr(),
                      'text-right': Service.isSiteRtl()
                    }"
                  >
                    <div class="gray-text-dark font-weight-bold">
                      {{ hotel.googleReview.ratingDescription }}
                    </div>
                    <div class="rating-stars-google d-inline-block">
                      <div
                        class="rating-active"
                        [style.width.%]="
                          hotel.googleReview.ratingPercentage * 100
                        "
                      ></div>
                    </div>
                    <br />
                    <button
                      (click)="
                        this.Service.setModal(
                          reviewTitle,
                          'GoogleReview',
                          hotel
                        )
                      "
                      class="btn-link d-inline-block"
                    >
                      <span
                        [ngClass]="{
                          'mr-1': Service.isSiteLtr(),
                          'ml-1': Service.isSiteRtl()
                        }"
                        >{{
                          hotel.googleReview.userRatingsTotal
                            | number : "" : "en-US"
                        }}</span
                      ><ng-container i18n="@@reviews">reviews</ng-container>
                    </button>
                  </div>

                  <div
                    class="water-mark-google gray-text font-12"
                    i18n="@@poweredByGoogle"
                  >
                    Powered by Google
                  </div>
                </div>
              </div>

              <div
                *ngIf="tripAdviserdata && tripAdviserdata.data"
                class="white-box p-3 mb-4"
              >
                <div class="tripadvisor tripadvisor-container text-center">
                  <img
                    width="60"
                    height="35"
                    loading="lazy"
                    class="d-inline-block align-middle mt-1"
                    src="assets/img/tripadvisor_logo.png"
                  />
                  <div
                    class="trip-adviser-cont position-relative d-inline-block align-middle mt-1 ml-2"
                  >
                    <div class="trip-adviser-holder d-inline-block">
                      <div class="trip-adviser-c"></div>
                      <div class="trip-adviser-c"></div>
                      <div class="trip-adviser-c"></div>
                      <div class="trip-adviser-c"></div>
                      <div class="trip-adviser-c"></div>
                    </div>
                    <div
                      [style.width.%]="TripAdviserRating"
                      class="trip-adviser-holder d-inline-block position-absolute"
                    >
                      <div class="trip-adviser-d"></div>
                      <div class="trip-adviser-d"></div>
                      <div class="trip-adviser-d"></div>
                      <div class="trip-adviser-d"></div>
                      <div class="trip-adviser-d"></div>
                    </div>
                  </div>
                  <div class="reviews mt-2">
                    <a
                      [href]="tripAdviserdata.data.link"
                      target="_blank"
                      class="underkined"
                      (click)="tripAdvisorTrigger()"
                      i18n="@@basedOnReviews"
                      >Based on
                      {{
                        tripAdviserdata.data.totalNumberOfReviews
                          | number : "" : "en-US"
                      }}
                      Reviews
                    </a>
                  </div>
                </div>
              </div>

              <div *ngIf="hotel" class="mb-4">
                <div class="map-side-details">
                  <button
                    class="button btn-link toggleView map-illustration not_button mb-md- mb-0 btn-auto"
                    [ngClass]="{
                      'mr-md-auto': Service.isSiteLtr(),
                      'ml-md-auto': Service.isSiteRtl()
                    }"
                    (click)="mapPopUp(true)"
                  >
                    <span>
                      <ng-container i18n="@@viewMap">view map</ng-container>
                      <i
                        class="icon-locator-blue"
                        [ngClass]="{
                          'pl-2': Service.isSiteLtr(),
                          'pr-2': Service.isSiteRtl()
                        }"
                      ></i>
                    </span>
                  </button>
                </div>
                <button
                  class="btn btn-outline-blue btn-block d-block d-md-none open-map-blue"
                  (click)="mapPopUp(false)"
                  i18n="@@seeWhatNear"
                >
                  See what's near you
                </button>
              </div>
            </div>
          </div>

          <div class="section-info mb-5" *ngIf="this.Service.desktopView">
            <ngb-tabset>
              <ngb-tab i18n-title="@@hotelOverview" title="Hotel Overview">
                <ng-template ngbTabContent>
                  <div class="desc" *ngIf="hotel">
                    <div
                      class="moredata"
                      *ngIf="hotel.description && readMore !== true"
                      [innerHTML]="hotel.description | slice : 0 : 250"
                    ></div>
                    <div
                      *ngIf="hotel.description && readMore === true"
                      [innerHTML]="hotel.description"
                    ></div>
                    <button
                      (click)="readMore = !readMore"
                      class="btn-link mb-2"
                    >
                      <span *ngIf="readMore === false" i18n="@@readMore">
                        Read More
                      </span>
                    </button>
                  </div>

                  <h4 *ngIf="hotel && hotel.myFacilities" i18n="@@amenities">
                    Amenities
                  </h4>
                  <div
                    class="facititle row"
                    *ngIf="hotel && hotel.myFacilities"
                  >
                    <div
                      class="col-md-4"
                      *ngFor="let Amenity of hotel.myFacilities"
                    >
                      <div class="list-info">
                        {{ Amenity.title }}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-tab>

              <ngb-tab *ngIf="this.Params.searchType !== 'GIATA_HOTEL'">
                <ng-template ngbTabTitle>
                  <div i18n="@@nearBy" (click)="getNearbyPlaces()">Nearby</div>
                </ng-template>
                <ng-template ngbTabContent>
                  <div class="row">
                    <div class="col-md-7">
                      <agm-map
                        class="inner-map"
                        [latitude]="hotel.latitude"
                        [longitude]="hotel.longitude"
                        [zoom]="16"
                        [disableDefaultUI]="false"
                        [zoomControl]="true"
                      >
                        <agm-marker
                          [latitude]="hotel.latitude"
                          [longitude]="hotel.longitude"
                          iconUrl="assets/img/Union.png"
                        >
                        </agm-marker>
                      </agm-map>
                    </div>
                    <div class="col-md-5">
                      <div class="hotel-info-right-landmarks">
                        <div class="landmarks-list">
                          <div
                            class="d-flex border-bottom p-2"
                            *ngFor="let item of nearByList"
                          >
                            <span
                              class="w-50"
                              [ngClass]="{
                                'mr-auto': Service.isSiteLtr(),
                                'ml-auto': Service.isSiteRtl()
                              }"
                            >
                              {{ item.name }}</span
                            >
                            <span>
                              ({{ item.distanceFromPropertyInMiles }} Mile /
                              {{ item.distanceFromPropertyInKilometers }}
                              KM)</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-tab>
            </ngb-tabset>
          </div>

          <div class="section-info mb-5" *ngIf="this.Service.mobileView">
            <div class="section-header" i18n="@@hotelOverview">
              Hotel Overview
            </div>

            <div class="section-body p-4" *ngIf="hotel">
              <div
                class="moredata"
                *ngIf="hotel.description && readMore !== true"
                [innerHTML]="hotel.description | slice : 0 : 250"
              ></div>
              <div
                *ngIf="hotel.description && readMore === true"
                [innerHTML]="hotel.description"
              ></div>
              <button (click)="readMore = !readMore" class="btn-link">
                <span
                  *ngIf="readMore === false; else ReadMore"
                  i18n="@@readMore"
                >
                  Read More
                </span>

                <span>
                  <ng-template #ReadMore i18n="@@readLess">
                    Read Less
                  </ng-template>
                </span>
              </button>
            </div>
          </div>

          <div
            class="section-info mb-5"
            *ngIf="this.Service.mobileView && hotel && hotel.myFacilities"
          >
            <div class="section-header" i18n="@@amenities">Amenities</div>
            <div class="section-body p-4" *ngIf="hotel && hotel.myFacilities">
              <div class="facititle row">
                <div
                  class="col-md-4"
                  *ngFor="let Amenity of hotel.myFacilities"
                >
                  <div class="list-info">
                    {{ Amenity.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="border mb-5 p-4" *ngIf="hotel.covidSafetyInformation">
            <div class="d-flex p-0 p-lg-3">
              <img
                width="40"
                height="43"
                loading="lazy"
                alt="health measure"
                [ngClass]="{
                  'mr-3 mr-lg-4': Service.isSiteLtr(),
                  'ml-3 ml-lg-4': Service.isSiteRtl()
                }"
                src="/assets/img/health-measure.png"
                title="health measure"
              />
              <div class="gray-primaray-text">
                <p
                  class="font-18 font-weight-bold mb-1"
                  i18n="@@healthSafetyMeasures"
                >
                  Health & safety measures
                </p>
                <p class="mb-0">
                  <ng-container i18n="@@healthSafetyMeasuresMsg"
                    >This property has taken extra health and hygiene measures
                    and adheres to</ng-container
                  >
                  {{ hotel.covidSafetyInformation.description }}
                </p>
              </div>
            </div>
          </div>


<div [ngClass]="{'w-75': Service.desktopView}">
  <app-hotel-policy-details-section
          [showPolicyDetails]="showPolicyDetails"
          [policyDetails]="policyDetails"
          [availabilityMedianPrice]="availabilityMedianPrice"
          [availabilityMedianCurrency]="availabilityMedianCurrency"
          [hasException]="hasException"
          [exceptionCurrency]="exceptionCurrency"
          [exceptionValue]="exceptionValue"
          [policyCurrency]="policyCurrency">
  </app-hotel-policy-details-section>
</div>


          <div
            class="reward-box border p-4 mb-4 position-relative"
            [ngClass]="{
              'w-75': Service.desktopView,
              'rounded-right': Service.isSiteLtr(),
              'rounded-left': Service.isSiteRtl()
            }"
            *ngIf="
              policyDetails &&
              policyDetails.hotelBookingNotAllowed === false &&
              companyPolicyHotelRules &&
              companyPolicyHotelRules.avgNightlyPriceLimit &&
              logedIn.companyInfo.rewardProgram &&
              logedIn.companyInfo.rewardProgram.hotelSavingsPercentage &&
              showRewardsBox
            "
          >
            <i
              class="icon-close-light gray-text font-12 pointer position-absolute p-2"
              (click)="showRewardsBox = false"
            ></i>
            <p class="font-18 bold-600 gray-primaray-text" i18n="@@earnRewards">
              Earn Rewards
            </p>
            <p class="gray-text-dark m-width" i18n="@@getDollarRewards">
              Earn rewards when you book a hotel under your company's travel policy. For every dollar saved, you receive {{logedIn.companyInfo.rewardProgram.hotelSavingsPercentage * 100  | number: '1.0-2'}}% as a reward.
            </p>
<!--            <p class="gray-primaray-text mb-0">-->
<!--              <ng-container i18n="@@travelPolicy">Travel Policy</ng-container>:&nbsp;-->
<!--              <ng-container *ngIf="!this.exceptionCurrency">{{policyCurrency}}&nbsp;</ng-container>-->
<!--              <ng-container *ngIf="!this.exceptionValue">{{companyPolicyHotelRules.avgNightlyPriceLimit}}&nbsp;</ng-container>-->
<!--              <ng-container *ngIf="this.exceptionCurrency">{{exceptionCurrency}}&nbsp;</ng-container>-->
<!--              <ng-container *ngIf="this.exceptionValue">{{exceptionValue}}&nbsp;</ng-container>-->
<!--              <ng-container i18n="@@slashNight">/night</ng-container>-->
<!--            </p>-->
          </div>

          <div
            class="section-info"
            id="rooms"
            *ngIf="hotel && this.Service.desktopView"
          >
            <div
              class="room-item"
              *ngFor="let roomS of hotel.rooms; let i = index"
            >
              <div class="section-header" *ngIf="i === 0">
                <div class="row">
                  <div class="col-md-4"><b i18n="@@rooms">Rooms</b></div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-7">
                        <b i18n="@@features">Features</b>
                      </div>
                      <div class="col-md-5"><b i18n="@@price">Price</b></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="section-body pr-5 pl-5 pt-5 pb-4"
                *ngIf="i < nuberofroom"
              >
                <div class="row">
                  <div class="col-md-4">
                    <div *ngIf="hotel && roomS.images">
                      <div
                        class="room_img_container mb-3 overflow-hidden position-relative"
                        *ngIf="
                          roomS.images[0].url &&
                          roomS.images[0].url.includes('https://')
                        "
                      >
                        <img
                          (click)="showRoomInfo(roomS.name, 'roomInfo', roomS)"
                          [src]="roomS.images[0].url"
                        />

                        <span
                          class="position-absolute text-white pr-2 pb-1 pt-2"
                          ><i class="icon-image"></i>
                          {{ roomS.images.length }}</span
                        >
                      </div>
                      <div
                        class="room_img_container mb-3 overflow-hidden position-relative"
                        *ngIf="
                          roomS.images[0].url &&
                          !roomS.images[0].url.includes('https://')
                        "
                      >
                        <img
                          (click)="showRoomInfo(roomS.name, 'roomInfo', roomS)"
                          [src]="
                            roomS.images[0].url.replace('http://', 'https://')
                          "
                        />

                        <span
                          class="position-absolute text-white pr-2 pb-1 pt-2"
                          ><i class="icon-image"></i>
                          {{ roomS.images.length }}</span
                        >
                      </div>
                    </div>

                    <h3>{{ roomS.name }}</h3>
                    <button
                      class="btn-link"
                      (click)="showRoomInfo(roomS.name, 'roomInfo', roomS)"
                      i18n="@@showRoomInfo"
                    >
                      Show room info
                    </button>
                  </div>
                  <div class="col-md-8">
                    <div
                      class="hotel-rate"
                      *ngFor="
                        let rate of roomS.rates | slice : 0 : 2;
                        let ba = index
                      "
                    >
                      <div class="row">
                        <div class="col-md-6 col-lg-7" data-th="Board">
                          <ul class="list-unstyled mb-0">
                            <li class="board mb-2">
                              <i
                                [ngClass]="'icon-' + rate.boardCode"
                                *ngIf="rate.hrsBoardCode != 'RO'"
                              >
                              </i
                              >{{ rate.boardName }}
                            </li>
                            <li class="free_wifi" *ngIf="rate.freeWifi">
                              <i
                                class="icon-18 font-14"
                                [ngClass]="{
                                  'mr-2': Service.isSiteLtr(),
                                  'ml-2': Service.isSiteRtl()
                                }"
                              ></i>
                              <ng-container i18n="@@freeWiFi"
                                >Free WiFi</ng-container
                              >
                            </li>
                            <li class="free_parking" *ngIf="rate.freeParking">
                              <i
                                class="icon-19 8 font-14"
                                [ngClass]="{
                                  'mr-2': Service.isSiteLtr(),
                                  'ml-2': Service.isSiteRtl()
                                }"
                              ></i>
                              <ng-container i18n="@@freeparking"
                                >Free parking</ng-container
                              >
                            </li>

                            <li></li>
                            <li *ngIf="rate.promotions">
                              <ul class="list-unstyled">
                                <li
                                  class="gift"
                                  *ngFor="let promo of rate.promotions"
                                >
                                  <i class="fa fa-gift"></i
                                  >{{ promo.description }}
                                </li>
                              </ul>
                            </li>
                          </ul>

                          <div *ngIf="rate.refundable">
                            <div
                              *ngIf="rate.cancellationPolicies"
                              class="cancelations"
                            >
                              <div
                                class=""
                                *ngIf="rate.cancellationPolicies[0].description"
                                title="{{
                                  rate.cancellationPolicies[0].description
                                }}"
                              >
                                {{
                                  rate.cancellationPolicies[0].shortDescription
                                }}
                              </div>
                            </div>

                            <div
                              *ngIf="!rate.cancellationPolicies"
                              class="cancelations"
                            >
                              <div i18n="@@freeCancellation">
                                Free cancellation
                              </div>
                            </div>
                          </div>
                          <div
                            class="non-refundable mb-2"
                            *ngIf="!rate.refundable"
                            i18n="@@nonrefundable"
                          >
                            Non-refundable
                          </div>
                        </div>
                        <div
                          class="col-md-6 col-lg-5"
                          [ngClass]="{
                            'text-right': Service.isSiteLtr(),
                            'text-left': Service.isSiteRtl()
                          }"
                        >
                          <div
                            class="night-price h-100 position-relative"
                            data-th="Nightly Price"
                          >
                            <div class="cell bookbtnCell">
                              <div
                                class="hotel_price_info"
                                [ngClass]="{
                                  'pb-7':
                                    !this.logedIn.isLead &&
                                    rate.averageNightlySellingPriceConverted &&
                                    Service.desktopView
                                }"
                              >
                                <div
                                  *ngIf="
                                    rate.originalAverageNightlySellingPrice
                                  "
                                  class="discount_rates with_half_padding"
                                >
                                {{currency}} {{
                                    rate.originalAverageNightlySellingPrice
                                      | number : "2"
                                  }}
                                </div>
                                <div class="price w-100">
                                  <div
                                    class="current_price mt-3 mb-2 clearfix"
                                    [ngClass]="{
                                      'text-left text-lg-right':
                                        Service.isSiteLtr(),
                                      'text-right text-lg-left':
                                        Service.isSiteRtl(),
                                      'prime-color-purple':
                                        logedIn.isLead &&
                                        rate.averageNightlySellingPriceConverted &&
                                        !this.Service.isCompany()
                                    }"
                                  >
                                    <del
                                      class="old-price font-20 line d-inline-block"
                                      [ngClass]="{
                                        'mr-2': Service.isSiteLtr(),
                                        'ml-2': Service.isSiteRtl()
                                      }"
                                      *ngIf="
                                        logedIn.isLead &&
                                        rate.publicAverageNightlySellingPrice &&
                                        rate.averageNightlySellingPriceConverted &&
                                        !this.Service.isCompany()
                                      "
                                    >
                                      <span>{{rate
                          .averageNightlySellingPriceConvertedCurrency}}</span
                                      ><span>{{
                                        rate.publicAverageNightlySellingPrice
                                          | number : "1.0-0"
                                      }}</span>

                                      <br />
                                    </del>

                                    <ng-container
                                      *ngIf="
                                        logedIn.isLead &&
                                        rate.averageNightlySellingPriceConverted &&
                                        rate.averageNightlySellingPrice
                                      "
                                    >
                                      <span class="amount-currency">{{currency}}</span>
                                      <span class="amount-currency"> {{
                                        rate.averageNightlySellingPriceConverted
                                          | number : "1.0-0"
                                      }} </span>

                                      <span
                                        class="nightWord"
                                        i18n="@@slashNight"
                                        >/night</span
                                      >
                                    </ng-container>
                                    <ng-container
                                      *ngIf="
                                        !logedIn.isLead &&
                                        rate.averageNightlySellingPrice
                                      "
                                    >
                                      <span class="amount-currency">{{currency}}</span>
                                      <span class="amount-currency"> {{
                                        rate.averageNightlySellingPrice
                                          | number : "1.0-0"
                                      }} </span>
                                      <span
                                        class="nightWord"
                                        i18n="@@slashNight"
                                        >/night</span
                                      >
                                    </ng-container>

                                    <ng-container
                                      *ngIf="
                                        logedIn.isLead &&
                                        rate.averageNightlySellingPriceConverted &&
                                        !rate.averageNightlySellingPrice
                                      "
                                    >
                                      <span class="amount-currency">{{currency}}</span>
                                      <span class="amount-currency"> {{
                                        rate.averageNightlySellingPriceConverted
                                          | number : "1.0-0"
                                      }} </span>
                                      <span
                                        class="nightWord"
                                        i18n="@@slashNight"
                                        >/night</span
                                      >
                                    </ng-container>

                                    <ng-container
                                      *ngIf="
                                        logedIn.isLead &&
                                        !rate.averageNightlySellingPriceConverted &&
                                        rate.averageNightlySellingPrice
                                      "
                                    >
                                      <span class="amount-currency">{{currency}}</span>
                                      <span class="amount-currency"> {{
                                        rate.averageNightlySellingPrice
                                          | number : "1.0-0"
                                      }} </span>
                                      <span
                                        class="nightWord"
                                        i18n="@@slashNight"
                                        >/night</span
                                      >
                                    </ng-container>
                                    <br />
                                  </div>
                                  <div
                                    class="tax mt-2"
                                    [ngClass]="{
                                      'text-right': Service.isSiteLtr(),
                                      'text-left': Service.isSiteRtl(),
                                      'no-margin-bottom':
                                        Service.isCompany() &&
                                        rate.userData &&
                                        rate.userData.exceedsPolicyLimit
                                    }"
                                    *ngIf="
                                      rate.taxIncluded &&
                                      (rate.averageNightlySellingPrice ||
                                        logedIn.isLead)
                                    "
                                    i18n="@@taxAndFeesIncluded"
                                  >
                                    Tax and fees included
                                  </div>
                                  <div
                                    class="text-danger"
                                    [ngClass]="{
                                      'text-right': Service.isSiteLtr(),
                                      'text-left': Service.isSiteRtl()
                                    }"
                                    *ngIf="rate?.userData?.bookingWindowViolated ||
                                      rate?.userData?.starRatingViolated ||
                                      rate?.userData?.medianPriceViolated ||
                                      rate?.userData?.exceedsPolicyLimit ||
                                      policyDetails?.hotelBookingNotAllowed === true"

                                  >
                                  <span matTooltip="{{hotelsListService.getHotelPolicyToolTip(rate?.userData)}}"
                                        [matTooltipClass]="Service.isSiteRtl() ? 'rtl-tooltip' : 'default-tooltip'"
                                        matTooltipClass="custom-tooltip"
                                  i18n="@@outOfPolicy">
                                  Out of policy
                                  </span>
                                  </div>
                                </div>
                                <ng-container
                                  *ngIf="
                                    logedIn.isLead ||
                                    rate.averageNightlySellingPrice
                                  "
                                >
                                  <button
                                    class="green_btn mt-2"
                                    *ngIf="
                                      !logedIn.isLead ||
                                        logedIn.isPrime ||
                                        (!rate.savings && rate.sellingPrice) ||
                                        this.Service.isCompany();
                                      else leadBtn
                                    "
                                    (click)="
                                      this.Service.booking(
                                        rate,
                                        roomS.name,
                                        hotel,
                                        tripAdviserdata,
                                        $event
                                      )
                                    "
                                    i18n="@@bookNow"
                                  >
                                    Book now
                                  </button>
                                  <ng-template #leadBtn>
                                    <button
                                      class="green_btn mt-2"
                                      (click)="
                                        this.Service.closedGruopuserPopup(
                                          rate,
                                          roomS.name,
                                          hotel,
                                          tripAdviserdata,
                                          $event,
                                          this.Params.checkIn,
                                          this.Params.checkOut
                                        )
                                      "
                                      i18n="@@bookNow"
                                    >
                                      Book now
                                    </button>
                                  </ng-template>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                          <div
                            *ngIf="rate.userData && rate.userData.rewardAmount"
                            style="min-height: 29px"
                          ></div>

                          <div
                            class="reward-promo d-flex position-absolute"
                            *ngIf="rate.userData && rate.userData.rewardAmount"
                          >
                            <div class="promo-triangle"></div>
                            <div
                              class="promo-text text-white font-12 px-4 pt-2"
                              i18n="@@earnInRewardsHotelDetails"
                            >
                              Earn {{rate.userData.convertedRewardCurrencyCode + ' ' + (rate.userData.convertedRewardAmount | number : "1.0-0")}} in Rewards
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="showAllRates === i" class="pt-2">
                      <div
                        class="hotel-rate pt-4 position-relative"
                        *ngFor="
                          let rate2 of roomS.rates
                            | slice : 2 : roomS.rates.length;
                          let d = index
                        "
                      >
                        <div class="row">
                          <div class="col-md-6 col-lg-7" data-th="Board">
                            <ul class="list-unstyled mb-0">
                              <li class="board mb-2">
                                <i
                                  class="material-icons"
                                  *ngIf="rate2.hrsBoardCode != 'RO'"
                                >
                                </i
                                >{{ rate2.boardName }}
                              </li>

                              <li class="free_wifi" *ngIf="rate2.freeWifi">
                                <i
                                  class="icon-18 font-14"
                                  [ngClass]="{
                                    'mr-2': Service.isSiteLtr(),
                                    'ml-2': Service.isSiteRtl()
                                  }"
                                ></i>
                                <ng-container i18n="@@freeWiFi"
                                  >Free WiFi</ng-container
                                >
                              </li>
                              <li
                                class="free_parking"
                                *ngIf="rate2.freeParking"
                              >
                                <i
                                  class="icon-19 8 font-14"
                                  [ngClass]="{
                                    'mr-2': Service.isSiteLtr(),
                                    'ml-2': Service.isSiteRtl()
                                  }"
                                ></i>
                                <ng-container i18n="@@freeparking"
                                  >Free parking</ng-container
                                >
                              </li>

                              <li></li>
                              <li *ngIf="rate2.promotions">
                                <ul class="list-unstyled">
                                  <li
                                    class="gift"
                                    *ngFor="let promo of rate2.promotions"
                                  >
                                    <i class="fa fa-gift"></i
                                    >{{ promo.description }}
                                  </li>
                                </ul>
                              </li>
                            </ul>

                            <div *ngIf="rate2.refundable">
                              <div
                                *ngIf="rate2.cancellationPolicies"
                                class="cancelations"
                              >
                                <div
                                  class=""
                                  *ngIf="
                                    rate2.cancellationPolicies[0].description
                                  "
                                  title="{{
                                    rate2.cancellationPolicies[0].description
                                  }}"
                                >
                                  {{
                                    rate2.cancellationPolicies[0]
                                      .shortDescription
                                  }}
                                </div>
                              </div>
                              <div
                                *ngIf="!rate2.cancellationPolicies"
                                class="cancelations"
                              >
                                <div>Free cancellation</div>
                              </div>
                            </div>
                            <div
                              class="non-refundable mb-2"
                              *ngIf="!rate2.refundable"
                              i18n="@@nonrefundable"
                            >
                              Non-refundable
                            </div>
                          </div>
                          <div
                            class="col-md-6 col-lg-5"
                            [ngClass]="{
                              'text-right': Service.isSiteLtr(),
                              'text-left': Service.isSiteRtl()
                            }"
                          >
                            <div
                              class="night-price h-100 position-relative"
                              data-th="Nightly Price"
                            >
                              <div class="cell bookbtnCell">
                                <div
                                  class="hotel_price_info"
                                  [ngClass]="{
                                    'pb-7':
                                      !this.logedIn.isLead &&
                                      rate2.averageNightlySellingPriceConverted &&
                                      Service.desktopView
                                  }"
                                >
                                  <div class="price">
                                    <div
                                      class="current_price mb-2 mt-3 clearfix"
                                      [ngClass]="{
                                        'text-left text-lg-right':
                                          Service.isSiteLtr(),
                                        'text-right text-lg-left':
                                          Service.isSiteRtl(),
                                        'prime-color-purple':
                                          logedIn.isLead &&
                                          rate2.averageNightlySellingPriceConverted &&
                                          !this.Service.isCompany()
                                      }"
                                    >
                                      <del
                                        class="old-price font-20 line d-inline-block"
                                        [ngClass]="{
                                          'mr-2': Service.isSiteLtr(),
                                          'ml-2': Service.isSiteRtl()
                                        }"
                                        *ngIf="
                                          logedIn.isLead &&
                                          rate2.publicAverageNightlySellingPrice &&
                                          rate2.averageNightlySellingPriceConverted &&
                                          !this.Service.isCompany()
                                        "
                                      >
                                        <span>{{rate2
                          .averageNightlySellingPriceConvertedCurrency}}</span
                                        ><span>{{
                                          rate2.publicAverageNightlySellingPrice
                                            | number : "1.0-0"
                                        }}</span>

                                        <br />
                                      </del>

                                      <ng-container
                                        *ngIf="
                                          logedIn.isLead &&
                                          rate2.averageNightlySellingPriceConverted &&
                                          rate2.averageNightlySellingPrice
                                        "
                                      >
                                        <span class="amount-currency">{{currency}}</span>
                                        <span class="amount-currency"> {{
                                          rate2.averageNightlySellingPriceConverted
                                            | number : "1.0-0"
                                        }} </span>

                                        <span
                                          class="nightWord"
                                          i18n="@@slashNight"
                                          >/night</span
                                        >
                                      </ng-container>
                                      <ng-container
                                        *ngIf="
                                          !logedIn.isLead &&
                                          rate2.averageNightlySellingPrice
                                        "
                                      >
                                        <span class="amount-currency">{{currency}}</span>
                                        <span class="amount-currency"> {{
                                          rate2.averageNightlySellingPrice
                                            | number : "1.0-0"
                                        }} </span>
                                        <span
                                          class="nightWord"
                                          i18n="@@slashNight"
                                          >/night</span
                                        >
                                      </ng-container>

                                      <ng-container
                                        *ngIf="
                                          logedIn.isLead &&
                                          rate2.averageNightlySellingPriceConverted &&
                                          !rate2.averageNightlySellingPrice
                                        "
                                      >
                                        <span class="amount-currency">{{currency}}</span>
                                        <span class="amount-currency"> {{
                                          rate2.averageNightlySellingPriceConverted
                                            | number : "1.0-0"
                                        }} </span>
                                        <span
                                          class="nightWord"
                                          i18n="@@slashNight"
                                          >/night</span
                                        >
                                      </ng-container>

                                      <ng-container
                                        *ngIf="
                                          logedIn.isLead &&
                                          !rate2.averageNightlySellingPriceConverted &&
                                          rate2.averageNightlySellingPrice
                                        "
                                      >
                                        <span class="amount-currency">{{currency}}</span>
                                        <span class="amount-currency"> {{
                                          rate2.averageNightlySellingPrice
                                            | number : "1.0-0"
                                        }} </span>
                                        <span
                                          class="nightWord"
                                          i18n="@@slashNight"
                                          >/night</span
                                        >
                                      </ng-container>

                                      <br />
                                    </div>
                                    <div
                                      class="tax mt-2"
                                      [ngClass]="{
                                        'text-right': Service.isSiteLtr(),
                                        'text-left': Service.isSiteRtl(),
                                        'no-margin-bottom':
                                          Service.isCompany() &&
                                          rate2.userData &&
                                          rate2.userData.exceedsPolicyLimit
                                      }"
                                      *ngIf="
                                        rate2.taxIncluded &&
                                        (rate2.averageNightlySellingPrice ||
                                          logedIn.isLead)
                                      "
                                      i18n="@@taxAndFeesIncluded"
                                    >
                                      Tax and fees included
                                    </div>
                                    <div
                                      class="text-danger"
                                      [ngClass]="{
                                        'text-right': Service.isSiteLtr(),
                                        'text-left': Service.isSiteRtl()
                                      }"
                                      *ngIf="rate2?.userData?.bookingWindowViolated ||
                                      rate2?.userData?.starRatingViolated ||
                                      rate2?.userData?.medianPriceViolated ||
                                      rate2?.userData?.exceedsPolicyLimit ||
                                      policyDetails?.hotelBookingNotAllowed === true"
                                    >
                                       <span matTooltip="{{hotelsListService.getHotelPolicyToolTip(rate2?.userData)}}"
                                             [matTooltipClass]="Service.isSiteRtl() ? 'rtl-tooltip' : 'default-tooltip'"
                                             matTooltipClass="custom-tooltip"
                                             i18n="@@outOfPolicy">
                                  Out of policy
                                  </span>
                                    </div>
                                  </div>
                                  <ng-container
                                    *ngIf="
                                      logedIn.isLead ||
                                      rate2.averageNightlySellingPrice
                                    "
                                  >
                                    <button
                                      class="green_btn mt-2"
                                      *ngIf="
                                        !logedIn.isLead ||
                                          logedIn.isPrime ||
                                          (!rate2.savings &&
                                            rate2.sellingPrice) ||
                                          this.Service.isCompany();
                                        else leadBtn
                                      "
                                      (click)="
                                        this.Service.booking(
                                          rate2,
                                          roomS.name,
                                          hotel,
                                          tripAdviserdata,
                                          $event
                                        )
                                      "
                                      i18n="@@bookNow"
                                    >
                                      Book now
                                    </button>
                                    <ng-template #leadBtn>
                                      <button
                                        class="green_btn mt-2"
                                        (click)="
                                          this.Service.closedGruopuserPopup(
                                            rate2,
                                            roomS.name,
                                            hotel,
                                            tripAdviserdata,
                                            $event,
                                            this.Params.checkIn,
                                            this.Params.checkOut
                                          )
                                        "
                                        i18n="@@bookNow"
                                      >
                                        Book now
                                      </button>
                                    </ng-template>
                                  </ng-container>

                                  <span
                                    *ngIf="
                                      (checkHold(rate2.hold) || rate2.hold) &&
                                      !this.Service.isCompany()
                                    "
                                  >
                                    <br />
                                    <span class="HoldRoom-sep my-md-2 my-0">
                                      or
                                    </span>
                                    <br />
                                    <button
                                      class="btn btn-outline-blue HoldRoom-btn sharp-font px-3"
                                      (click)="
                                        this.Service.HoldRoom(
                                          rate2,
                                          roomS.name,
                                          hotel,
                                          tripAdviserdata
                                        )
                                      "
                                    >
                                      Hold for free
                                    </button>
                                  </span>

                                  <div
                                    *ngIf="
                                      logedIn.isLead &&
                                      !rate2.savings &&
                                      rate2.averageNightlySellingPriceConverted
                                    "
                                    class="prime-color font-weight-bold font-14 mb-3 mt-2"
                                    [ngClass]="{
                                      'text-right': Service.isSiteLtr(),
                                      'text-left': Service.isSiteRtl()
                                    }"
                                  >
                                    EXCLUSIVE PRICE
                                  </div>



                                </div>
                              </div>
                              <div
                                class="position-relative hidden-rate"
                                *ngIf="
                                  !this.logedIn.isLead &&
                                  rate2.averageNightlySellingPriceConverted
                                "
                              >
                                <div
                                  class="prime-color-purple mt-2 font-15 clickable-cursor"
                                  (click)="showPrivateDescFunClick()"
                                  [ngClass]="{
                                    'text-right': Service.isSiteLtr(),
                                    'text-left': Service.isSiteRtl()
                                  }"
                                >
                                  Hidden Rate
                                  <br />

                                  <div
                                    class="prime-bg-purple text-white font-12 d-inline-block rounded px-4 py-2"
                                  >
                                    Unlock Now
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                                *ngIf="rate2.userData && rate2.userData.rewardAmount"
                                style="min-height: 29px"
                        ></div>

                        <div
                                class="reward-promo d-flex position-absolute custom-promo"
                                *ngIf="rate2.userData && rate2.userData.rewardAmount"
                        >
                          <div class="promo-triangle"></div>
                          <div
                                  class="promo-text text-white font-12 px-4 pt-2"
                                  i18n="@@earnInRewardsHotelDetails"
                          >
                            Earn {{rate2.userData.convertedRewardCurrencyCode + ' ' + (rate2.userData.convertedRewardAmount | number : "1.0-0")}} in Rewards
                          </div>
                        </div>



                      </div>
                    </div>
                  </div>

                  <div
                    class="show-more-less"
                    *ngIf="showAllRates !== i && roomS.rates.length > 2"
                  >
                    <button
                      (click)="changeRateValue(i)"
                      class="btn btn-link btn-light-bg py-0"
                    >
                      <ng-container i18n="@@showMoreRoomRates"
                        >Show more room rates</ng-container
                      >&nbsp;<i class="icon-up-arrow"></i
                      ><span class="showMore"></span>
                    </button>
                  </div>
                  <div class="show-more-less" *ngIf="showAllRates === i">
                    <button
                      (click)="showAllRates = null"
                      class="btn btn-link btn-light-bg less py-0"
                    >
                      <ng-container i18n="@@showLessRoomRates"
                        >Show less room rates</ng-container
                      >&nbsp;<i class="icon-up-arrow"></i
                      ><span class="showLess"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="section-info"
            id="rooms"
            *ngIf="hotel && this.Service.mobileView"
          >
            <div
              class="room-item"
              *ngFor="let room of hotel.rooms; let i = index"
            >
              <div class="section-header room-name-header rounded-top mt-5">
                <div class="row gutters-5">
                  <div class="col">
                    <div
                      class="py-2 pr-0"
                      [ngClass]="{
                        'pl-3': Service.isSiteLtr(),
                        'pr-3': Service.isSiteRtl()
                      }"
                    >
                      <div
                        class="primaray-text font-weight-bold"
                        *ngIf="room.images"
                      >
                        <span *ngIf="room.name.length > 34"
                          >{{ room.name | slice : 0 : 34 }}...</span
                        >
                        <span *ngIf="room.name.length <= 34">{{
                          room.name
                        }}</span>
                      </div>
                      <div
                        class="primaray-text font-weight-bold"
                        *ngIf="!room.images"
                      >
                        <span *ngIf="room.name.length > 70"
                          >{{ room.name | slice : 0 : 70 }}...</span
                        >
                        <span *ngIf="room.name.length <= 70">{{
                          room.name
                        }}</span>
                      </div>

                      <div class="room-details-link">
                        <a
                          class="btn-link"
                          (click)="showRoomInfo(room.name, 'roomInfo', room)"
                          i18n="@@showRoomInfo"
                          >Show room info</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-5" *ngIf="hotel && room.images">
                    <div>
                      <div
                        class="room_img_container mb-0 mb-md-3 overflow-hidden position-relative"
                        *ngIf="hotel && room.images[0].url"
                      >
                        <div
                          class="roomImg"
                          (click)="showRoomInfo(room.name, 'roomInfo', room)"
                          [style.backgroundImage]="
                            'url(' + room.images[0].url + ')'
                          "
                        ></div>

                        <span
                          class="position-absolute text-white pr-2 pb-1 pt-2"
                          ><i class="icon-image"></i>
                          {{ room.images.length }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section-body py-5">
                <div class="col-lg-8">
                  <div
                    class="hotel-rate"
                    *ngFor="
                      let rate of room.rates | slice : 0 : 2;
                      let q = index
                    "
                  >
                    <div class="row">
                      <div class="col-md-6 col-lg-7" data-th="Board">
                        <ul class="list-unstyled">
                          <li class="board mb-2">
                            <i
                              [ngClass]="'icon-' + rate.boardCode"
                              *ngIf="rate.hrsBoardCode != 'RO'"
                            >
                            </i
                            >{{ rate.boardName }}
                          </li>

                          <li class="free_wifi" *ngIf="rate.freeWifi">
                            <i
                              class="icon-18 font-14"
                              [ngClass]="{
                                'mr-2': Service.isSiteLtr(),
                                'ml-2': Service.isSiteRtl()
                              }"
                            ></i>
                            <ng-container i18n="@@freeWiFi"
                              >Free WiFi</ng-container
                            >
                          </li>
                          <li class="free_parking" *ngIf="rate.freeParking">
                            <i
                              class="icon-19 8 font-14"
                              [ngClass]="{
                                'mr-2': Service.isSiteLtr(),
                                'ml-2': Service.isSiteRtl()
                              }"
                            ></i>
                            <ng-container i18n="@@freeparking"
                              >Free parking</ng-container
                            >
                          </li>

                          <li></li>

                          <li *ngIf="rate.promotions">
                            <ul class="list-unstyled">
                              <li
                                class="gift"
                                *ngFor="let promo of rate.promotions"
                              >
                                <i class="fa fa-gift"></i
                                >{{ promo.description }}
                              </li>
                            </ul>
                          </li>
                        </ul>

                        <div *ngIf="rate.refundable">
                          <div
                            *ngIf="rate.cancellationPolicies"
                            class="cancelations"
                          >
                            <div
                              class=""
                              *ngIf="rate.cancellationPolicies[0].description"
                              title="{{
                                rate.cancellationPolicies[0].description
                              }}"
                            >
                              {{
                                rate.cancellationPolicies[0].shortDescription
                              }}
                            </div>
                          </div>
                          <div
                            *ngIf="!rate.cancellationPolicies"
                            class="cancelations"
                          >
                            <div>Free cancellation</div>
                          </div>
                        </div>
                        <div
                          class="non-refundable mb-2"
                          *ngIf="!rate.refundable"
                          i18n="@@nonrefundable"
                        >
                          Non-refundable
                        </div>
                      </div>
                      <div
                        class="col-md-6 col-lg-5"
                        [ngClass]="{
                          'text-right': Service.isSiteLtr(),
                          'text-left': Service.isSiteRtl()
                        }"
                      >
                        <div
                          class="night-price h-100 position-relative"
                          data-th="Nightly Price"
                        >
                          <div class="cell bookbtnCell">
                            <div
                              class="hotel_price_info"
                              [ngClass]="{
                                'pb-7':
                                  !this.logedIn.isLead &&
                                  rate.averageNightlySellingPriceConverted &&
                                  Service.desktopView
                              }"
                            >
                              <div
                                *ngIf="rate.originalAverageNightlySellingPrice"
                                class="discount_rates with_half_padding"
                              >
                                {{currency}} {{
                                  rate.originalAverageNightlySellingPrice
                                    | number : "2"
                                }}
                              </div>
                              <div class="row">
                                <div class="price col-12"></div>
                                <div class="col-12 mt-4">
                                  <div
                                    [ngClass]="{
                                      'text-right': Service.isSiteLtr(),
                                      'text-left': Service.isSiteRtl()
                                    }"
                                  >
                                    <div class="primaray-text">
                                      <div
                                        class="current_price font-24"
                                        [ngClass]="{
                                          'prime-color-purple':
                                            logedIn.isLead &&
                                            rate.averageNightlySellingPriceConverted &&
                                            !this.Service.isCompany()
                                        }"
                                      >
                                        <del
                                          class="old-price font-16 line d-inline-block"
                                          [ngClass]="{
                                            'mr-2': Service.isSiteLtr(),
                                            'ml-2': Service.isSiteRtl()
                                          }"
                                          *ngIf="
                                            logedIn.isLead &&
                                            rate.publicAverageNightlySellingPrice &&
                                            rate.averageNightlySellingPriceConverted &&
                                            !this.Service.isCompany()
                                          "
                                        >
                                          <span>{{rate
                          .averageNightlySellingPriceConvertedCurrency}}</span
                                          ><span>{{
                                            rate.publicAverageNightlySellingPrice
                                              | number : "1.0-0"
                                          }}</span>
                                        </del>
                                        <ng-container
                                          *ngIf="
                                            logedIn.isLead &&
                                            rate.averageNightlySellingPriceConverted &&
                                            rate.averageNightlySellingPrice
                                          "
                                        >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            >{{currency}}</span
                                          >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            > {{
                                              rate.averageNightlySellingPriceConverted
                                                | number : "1.0-0"
                                            }} </span
                                          >

                                          <span
                                            class="font-16"
                                            i18n="@@slashNight"
                                            >/night</span
                                          >
                                        </ng-container>
                                        <ng-container
                                          *ngIf="
                                            !logedIn.isLead &&
                                            rate.averageNightlySellingPrice
                                          "
                                        >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            >{{currency}}</span
                                          >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            > {{
                                              rate.averageNightlySellingPrice
                                                | number : "1.0-0"
                                            }} </span
                                          >
                                          <span
                                            class="font-16"
                                            i18n="@@slashNight"
                                            >/night</span
                                          >
                                        </ng-container>
                                        <ng-container
                                          *ngIf="
                                            logedIn.isLead &&
                                            rate.averageNightlySellingPriceConverted &&
                                            !rate.averageNightlySellingPrice
                                          "
                                        >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            >{{currency}}</span
                                          >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            > {{
                                              rate.averageNightlySellingPriceConverted
                                                | number : "1.0-0"
                                            }} </span
                                          >
                                          <span
                                            class="font-16"
                                            i18n="@@slashNight"
                                            >/night</span
                                          >
                                        </ng-container>
                                        <ng-container
                                          *ngIf="
                                            logedIn.isLead &&
                                            !rate.averageNightlySellingPriceConverted &&
                                            rate.averageNightlySellingPrice
                                          "
                                        >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            >{{currency}}</span
                                          >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            > {{
                                              rate.averageNightlySellingPrice
                                                | number : "1.0-0"
                                            }} </span
                                          >
                                          <span
                                            class="font-16"
                                            i18n="@@slashNight"
                                            >/night</span
                                          >
                                        </ng-container>
                                      </div>
                                    </div>
                                    <div
                                      class="tax mt-2 font-12"
                                      [ngClass]="{
                                        'text-right': Service.isSiteLtr(),
                                        'text-left': Service.isSiteRtl(),
                                        'no-margin-bottom':
                                          Service.isCompany() &&
                                          rate.userData &&
                                          rate.userData.exceedsPolicyLimit
                                      }"
                                      *ngIf="
                                        rate.taxIncluded &&
                                        (rate.averageNightlySellingPrice ||
                                          logedIn.isLead)
                                      "
                                      i18n="@@taxAndFeesIncluded"
                                    >
                                      Tax and fees included
                                    </div>
                                    <div
                                      class="text-danger"
                                      [ngClass]="{
                                        'text-right': Service.isSiteLtr(),
                                        'text-left': Service.isSiteRtl()
                                      }"
                                      *ngIf="rate?.userData?.bookingWindowViolated ||
                                      rate?.userData?.starRatingViolated ||
                                      rate?.userData?.medianPriceViolated ||
                                      rate?.userData?.exceedsPolicyLimit ||
                                      policyDetails?.hotelBookingNotAllowed === true"
                                    >
                                       <span matTooltip="{{hotelsListService.getHotelPolicyToolTip(rate?.userData)}}"
                                             [matTooltipClass]="Service.isSiteRtl() ? 'rtl-tooltip' : 'default-tooltip'"
                                             matTooltipClass="custom-tooltip"
                                             i18n="@@outOfPolicy">
                                  Out of policy
                                  </span>
                                    </div>
                                  </div>
                                  <ng-container
                                    *ngIf="
                                      logedIn.isLead ||
                                      rate.averageNightlySellingPrice
                                    "
                                  >
                                    <button
                                      class="green_btn mt-2 px-5"
                                      *ngIf="
                                        !logedIn.isLead ||
                                          logedIn.isPrime ||
                                          (!rate.savings &&
                                            rate.sellingPrice) ||
                                          this.Service.isCompany();
                                        else leadBtn
                                      "
                                      (click)="
                                        this.Service.booking(
                                          rate,
                                          room.name,
                                          hotel,
                                          tripAdviserdata,
                                          $event
                                        )
                                      "
                                      i18n="@@bookNow"
                                    >
                                      Book now
                                    </button>
                                    <ng-template #leadBtn>
                                      <button
                                        class="green_btn mt-2 px-5"
                                        (click)="
                                          this.Service.closedGruopuserPopup(
                                            rate,
                                            room.name,
                                            hotel,
                                            tripAdviserdata,
                                            $event,
                                            this.Params.checkIn,
                                            this.Params.checkOut
                                          )
                                        "
                                        i18n="@@bookNow"
                                      >
                                        Book now
                                      </button>
                                    </ng-template>
                                  </ng-container>
                                  <span
                                    *ngIf="
                                      (checkHold(rate.hold) || rate.hold) &&
                                      !this.Service.isCompany()
                                    "
                                  >
                                    <br />
                                    <span class="HoldRoom-sep my-md-2 my-0">
                                      or
                                    </span>
                                    <br />
                                    <button
                                      class="btn btn-outline-blue HoldRoom-btn sharp-font px-5"
                                      (click)="
                                        this.Service.HoldRoom(
                                          rate,
                                          room.name,
                                          hotel,
                                          tripAdviserdata
                                        )
                                      "
                                    >
                                      Hold for free
                                    </button>
                                  </span>
                                  <div
                                    *ngIf="
                                      logedIn.isLead &&
                                      !rate.savings &&
                                      rate.averageNightlySellingPriceConverted
                                    "
                                    class="prime-color font-weight-bold font-14 mb-3 mt-2"
                                    [ngClass]="{
                                      'text-right': Service.isSiteLtr(),
                                      'text-left': Service.isSiteRtl()
                                    }"
                                  >
                                    EXCLUSIVE PRICE
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          *ngIf="rate.userData && rate.userData.rewardAmount"
                          style="min-height: 29px"
                        ></div>
                        <div
                          class="reward-promo d-flex position-absolute"
                          *ngIf="rate.userData && rate.userData.rewardAmount"
                        >
                          <div class="promo-triangle"></div>
                          <div
                            class="promo-text text-white font-12 px-4 pt-2"
                            i18n="@@earnInRewardsHotelDetails"
                          >
                            Earn {{rate.userData.convertedRewardCurrencyCode + ' ' + (rate.userData.convertedRewardAmount | number : "1.0-0")}} in Rewards
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pt-2" *ngIf="showAllRates === i">
                    <div
                      class="hotel-rate pt-4 position-relative custom-promo"
                      *ngFor="
                        let rate2 of room.rates | slice : 2 : room.rates.length;
                        let q = index
                      "
                    >
                      <div class="row">
                        <div class="col-md-6 col-lg-7" data-th="Board">
                          <ul class="list-unstyled mb-0">
                            <li class="board mb-2">
                              <i
                                class="material-icons"
                                *ngIf="rate2.hrsBoardCode != 'RO'"
                              >
                              </i
                              >{{ rate2.boardName }}
                            </li>

                            <li class="free_wifi" *ngIf="rate2.freeWifi">
                              <i
                                class="icon-18 font-14"
                                [ngClass]="{
                                  'mr-2': Service.isSiteLtr(),
                                  'ml-2': Service.isSiteRtl()
                                }"
                              ></i>
                              <ng-container i18n="@@freeWiFi"
                                >Free WiFi</ng-container
                              >
                            </li>
                            <li class="free_parking" *ngIf="rate2.freeParking">
                              <i
                                class="icon-19 8 font-14"
                                [ngClass]="{
                                  'mr-2': Service.isSiteLtr(),
                                  'ml-2': Service.isSiteRtl()
                                }"
                              ></i>
                              <ng-container i18n="@@freeparking"
                                >Free parking</ng-container
                              >
                            </li>

                            <li></li>

                            <li *ngIf="rate2.promotions">
                              <ul class="list-unstyled">
                                <li
                                  class="gift"
                                  *ngFor="let promo of rate2.promotions"
                                >
                                  <i class="fa fa-gift"></i
                                  >{{ promo.description }}
                                </li>
                              </ul>
                            </li>
                          </ul>

                          <div *ngIf="rate2.refundable">
                            <div
                              *ngIf="rate2.cancellationPolicies"
                              class="cancelations"
                            >
                              <div
                                class=""
                                *ngIf="
                                  rate2.cancellationPolicies[0].description
                                "
                                title="{{
                                  rate2.cancellationPolicies[0].description
                                }}"
                              >
                                {{
                                  rate2.cancellationPolicies[0].shortDescription
                                }}
                              </div>
                            </div>
                            <div
                              *ngIf="!rate2.cancellationPolicies"
                              class="cancelations"
                            >
                              <div>Free cancellation</div>
                            </div>
                          </div>
                          <div
                            class="non-refundable mb-2"
                            *ngIf="!rate2.refundable"
                            i18n="@@nonrefundable"
                          >
                            Non-refundable
                          </div>
                        </div>
                        <div
                          class="col-md-6 col-lg-5"
                          [ngClass]="{
                            'text-right': Service.isSiteLtr(),
                            'text-left': Service.isSiteRtl()
                          }"
                        >
                          <div
                            class="night-price h-100 position-relative"
                            data-th="Nightly Price"
                          >
                            <div class="cell bookbtnCell">
                              <div class="row">
                                <div class="price col-12"></div>
                                <div class="col-12 mt-4">
                                  <div
                                    [ngClass]="{
                                      'text-right': Service.isSiteLtr(),
                                      'text-left': Service.isSiteRtl()
                                    }"
                                  >
                                    <div class="primaray-text">
                                      <div
                                        class="current_price font-24"
                                        [ngClass]="{
                                          'prime-color-purple':
                                            logedIn.isLead &&
                                            rate2.averageNightlySellingPriceConverted &&
                                            !this.Service.isCompany()
                                        }"
                                      >
                                        <del
                                          class="old-price font-16 line d-inline-block mr-2"
                                          *ngIf="
                                            logedIn.isLead &&
                                            rate2.publicAverageNightlySellingPrice &&
                                            rate2.averageNightlySellingPriceConverted &&
                                            !this.Service.isCompany()
                                          "
                                        >
                                          <span>{{rate2
                          .averageNightlySellingPriceConvertedCurrency}}</span
                                          ><span>{{
                                            rate2.publicAverageNightlySellingPrice
                                              | number : "1.0-0"
                                          }}</span>
                                        </del>

                                        <ng-container
                                          *ngIf="
                                            logedIn.isLead &&
                                            rate2.averageNightlySellingPriceConverted &&
                                            rate2.averageNightlySellingPrice
                                          "
                                        >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            >{{currency}}</span
                                          >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            > {{
                                              rate2.averageNightlySellingPriceConverted
                                                | number : "1.0-0"
                                            }} </span
                                          >

                                          <span
                                            class="font-16"
                                            i18n="@@slashNight"
                                            >/night</span
                                          >
                                        </ng-container>
                                        <ng-container
                                          *ngIf="
                                            !logedIn.isLead &&
                                            rate2.averageNightlySellingPrice
                                          "
                                        >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            >{{currency}}</span
                                          >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            > {{
                                              rate2.averageNightlySellingPrice
                                                | number : "1.0-0"
                                            }} </span
                                          >
                                          <span
                                            class="font-16"
                                            i18n="@@slashNight"
                                            >/night</span
                                          >
                                        </ng-container>

                                        <ng-container
                                          *ngIf="
                                            logedIn.isLead &&
                                            rate2.averageNightlySellingPriceConverted &&
                                            !rate2.averageNightlySellingPrice
                                          "
                                        >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            >{{currency}}</span
                                          >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            > {{
                                              rate2.averageNightlySellingPriceConverted
                                                | number : "1.0-0"
                                            }} </span
                                          >
                                          <span
                                            class="font-16"
                                            i18n="@@slashNight"
                                            >/night</span
                                          >
                                        </ng-container>

                                        <ng-container
                                          *ngIf="
                                            logedIn.isLead &&
                                            !rate2.averageNightlySellingPriceConverted &&
                                            rate2.averageNightlySellingPrice
                                          "
                                        >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            >{{currency}}</span
                                          >
                                          <span
                                            class="amount-currency font-weight-bold"
                                            > {{
                                              rate2.averageNightlySellingPrice
                                                | number : "1.0-0"
                                            }} </span
                                          >
                                          <span
                                            class="font-16"
                                            i18n="@@slashNight"
                                            >/night</span
                                          >
                                        </ng-container>
                                      </div>
                                    </div>

                                    <div
                                      class="tax mt-2 font-12"
                                      [ngClass]="{
                                        'text-right': Service.isSiteLtr(),
                                        'text-left': Service.isSiteRtl(),
                                        'no-margin-bottom':
                                          Service.isCompany() &&
                                          rate2.userData &&
                                          rate2.userData.exceedsPolicyLimit
                                      }"
                                      *ngIf="
                                        rate2.taxIncluded &&
                                        (rate2.averageNightlySellingPrice ||
                                          logedIn.isLead)
                                      "
                                      i18n="@@taxAndFeesIncluded"
                                    >
                                      Tax and fees included
                                    </div>
                                    <div
                                      class="text-danger"
                                      *ngIf="rate2?.userData?.bookingWindowViolated ||
                                      rate2?.userData?.starRatingViolated ||
                                      rate2?.userData?.medianPriceViolated ||
                                      rate2?.userData?.exceedsPolicyLimit ||
                                      policyDetails?.hotelBookingNotAllowed === true"
                                      [ngClass]="{
                                        'text-right': Service.isSiteLtr(),
                                        'text-left': Service.isSiteRtl()
                                      }"
                                    >
                                       <span matTooltip="{{hotelsListService.getHotelPolicyToolTip(rate2?.userData)}}"
                                             [matTooltipClass]="Service.isSiteRtl() ? 'rtl-tooltip' : 'default-tooltip'"
                                             matTooltipClass="custom-tooltip"
                                             i18n="@@outOfPolicy">
                                  Out of policy
                                  </span>
                                    </div>
                                  </div>
                                  <ng-container
                                    *ngIf="
                                      logedIn.isLead ||
                                      rate2.averageNightlySellingPrice
                                    "
                                  >
                                    <button
                                      class="green_btn mt-2 px-5"
                                      *ngIf="
                                        !logedIn.isLead ||
                                          logedIn.isPrime ||
                                          (!rate2.savings &&
                                            rate2.sellingPrice) ||
                                          this.Service.isCompany();
                                        else leadBtn
                                      "
                                      (click)="
                                        this.Service.booking(
                                          rate2,
                                          room.name,
                                          hotel,
                                          tripAdviserdata,
                                          $event
                                        )
                                      "
                                    >
                                      Book now
                                    </button>
                                    <ng-template #leadBtn>
                                      <button
                                        class="green_btn mt-2 px-5"
                                        (click)="
                                          this.Service.closedGruopuserPopup(
                                            rate2,
                                            room.name,
                                            hotel,
                                            tripAdviserdata,
                                            $event,
                                            this.Params.checkIn,
                                            this.Params.checkOut
                                          )
                                        "
                                        i18n="@@bookNow"
                                      >
                                        Book now
                                      </button>
                                    </ng-template>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="show-more-less"
                  *ngIf="showAllRates !== i && room.rates.length > 2"
                >
                  <button
                    (click)="changeRateValue(i)"
                    class="btn btn-block btn-link btn-light-bg py-0"
                  >
                    <ng-container i18n="@@showMoreRoomRates"
                      >Show more room rates</ng-container
                    >&nbsp;<i class="icon-up-arrow"></i
                    ><span class="showMore"></span>
                  </button>
                </div>
                <div class="show-more-less" *ngIf="showAllRates === i">
                  <button
                    (click)="showAllRates = null"
                    class="btn btn-block btn-link btn-light-bg less py-0"
                  >
                    <ng-container i18n="@@showLessRoomRates"
                      >Show less room rates</ng-container
                    >&nbsp;<i class="icon-up-arrow"></i
                    ><span class="showLess"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="text-center no-data-cont mt-4"
            *ngIf="hotel && hotel.rooms && this.Service.desktopView"
          >
            <a
              *ngIf="nuberofroom === 4 && hotel.rooms.length > 4"
              style="position: relative; z-index: 3"
              class="no_data_box center_text"
              (click)="nuberofroom = hotel.rooms.length"
              i18n="@@showMoreRooms"
            >
              Show More Rooms
            </a>
            <a
              *ngIf="nuberofroom > 4"
              style="position: relative; z-index: 3"
              class="no_data_box center_text"
              (click)="nuberofroom = 4"
              i18n="@@showLessRooms"
            >
              Show Less Rooms
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
