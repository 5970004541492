import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-billing-plans',
  templateUrl: './billing-plans.component.html',
  styleUrls: ['./billing-plans.component.scss']
})
export class BillingPlansComponent implements OnInit {

  constructor(public dataService: DataService) { }
data;
  plans;
  currentPlan;
  ngOnInit(): void {
    if (this.dataService.desktopView) {
      this.currentPlan = this.dataService.modalData;
    } else {
      this.currentPlan = this.dataService.BottomSheetData;
    }
    this.dataService.getWorkspacePlans().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.plans = this.data.data;
        } else {
          this.dataService.messages(this.data.message, 'error');
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
      }
    );
  }
  switchPlan(planName, planId) {
    this.dataService.workspacePlan = planName;
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@switchToPlanBilling1:Switch to ${planName}:name: Billing`, 'switchPlan', planId);
    } else {
      this.dataService.setBottomSheet($localize`:@@switchToPlanBilling1:Switch to ${planName}:name: Billing`, planId);
    }
  }
}
