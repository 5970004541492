<app-middle-button *ngIf="dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'initial-margin-value' : dataService.initialMarginValue && dataService.desktopView}"
    [@onMainContentChange]="dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content">
      <app-messages></app-messages>

      <div class="container pb-5 pt-4 pt-lg-5">
        <div class="row mx-lg-4">
          <div class="col">
            <div class="pb-4 pb-lg-5 mb-3">
              <a class="font-16 blue-text text-underline"
                (click)="router.navigate(['/settings']);"
                i18n="@@menuItemSettings">Settings</a>
              <span class="font-16 gray-text-dark "> /
                <ng-container i18n="@@costManagement">
                  Cost Management
                </ng-container>
              </span>
            </div>
          </div>
        </div>
        <app-cost-centers-list [isCostCenterMandatory]="isCostCenterMandatory" [isLabelMandatory]="isLabelMandatory"
          [loader]="loader"></app-cost-centers-list>
        <app-labels-list [isCostCenterMandatory]="isCostCenterMandatory" [isLabelMandatory]="isLabelMandatory"
          [loader]="loader"></app-labels-list>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>