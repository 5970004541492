import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AuthServiceMain } from "src/app/auth/auth.service";
import { SidenavService } from "src/app/corporate-travel/workspace-dashboard/sidenav.service";
import { DataService } from "src/app/services/data.service";
import { WalletManagementService } from "src/app/services/wallet-services/wallet-management.service";
import { CompanyArchiveStatus } from "src/app/shared/enums/company-archive-status";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-wallet-list",
  templateUrl: "./wallet-list.component.html",
  styleUrls: ["./wallet-list.component.scss"],
  host: {
    "(body:click)": "onClickBody($event)",
  },
})
export class WalletListComponent implements OnInit, OnDestroy {
  data: any;
  walletsList: any[];
  activeWalletsList: any[];
  archivedWalletsList: any[];
  isActive: boolean = true;
  showActions: boolean = false;
  selectedIndex: number;
  walletSubscription: any;
  companyArchiveStatus = CompanyArchiveStatus;

  constructor(
    private walletManagementService: WalletManagementService,
    private titleService: Title,
    private eref: ElementRef,
    public dataService: DataService,
    private sidenavService: SidenavService,
    public router: Router,
    public auth: AuthServiceMain
  ) {}

  ngOnDestroy(): void {
    if (this.walletSubscription) {
      this.walletSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.getWallets();

    //Subscribe to modalClosed event
    this.dataService.modalClosed.subscribe(() => {
      // Call your method when modal closes
      this.getWallets(this.isActive);
    });
  }

  onClickBody(event) {
    if (
      this.eref.nativeElement.querySelector(
        ".drop-down" + this.selectedIndex
      ) &&
      !this.eref.nativeElement
        .querySelector(".drop-down" + this.selectedIndex)
        .contains(event.target)
    ) {
      this.showActions = false;
    }
  }

  addNewWallet() {
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@addNewWallet:Add New Wallet`,
        "NewPaymentWallet",
        ""
      );
    } else {
      this.dataService.setBottomSheet("NewPaymentWallet", "");
    }
  }

  getWallets(isInactive: boolean = false) {
    this.walletSubscription = this.walletManagementService
      .getWalletList()
      .subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.walletsList = this.data.data;
            this.activeWalletsList = this.walletsList.filter(
              (att) => att.isInactive == false
            );
            this.archivedWalletsList = this.walletsList.filter(
              (att) => att.isInactive == true
            );
            this.getIsActiveList(isInactive);
          }
        },
        (error) => {
          if (error.includes("Http failure response")) {
            error = environment.messages.error;
          }
          this.dataService.messages(
            error ?? environment.messages.error,
            "error"
          );
        }
      );
  }

  getIsActiveList(isInactive: boolean = false) {
    this.isActive = isInactive;
    this.walletsList = isInactive
      ? this.archivedWalletsList
      : this.activeWalletsList;
  }

  editItem(item: any) {
    const walletData = {
      id: item.id,
      name: item.name,
      description: item.description,
      isInactive: item.isInactive,
    };

    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@editWallet:Edit Wallet`,
        "EditPaymentWallet",
        walletData
      );
    } else {
      this.dataService.setBottomSheet("EditPaymentWallet", walletData);
    }
  }

  changeActivation(item: any) {
    const isArchiving = item.isInactive === this.companyArchiveStatus.Active;

    const data = {
      id: item.id,
      status: !item.isInactive
        ? this.companyArchiveStatus.Active
        : this.companyArchiveStatus.Archived,
      name: item.name,
    };

    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@walletActivation:Wallet Activation`,
        "walletActivation",
        data
      );
    } else {
      this.dataService.setBottomSheet("walletActivation", data);
    }
  }
}
