
<div class="d-flex">
    <i class="icon-flight-item font-18 gray-text" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
    <p class="gray-primaray-text mb-2">
        {{flightItem.bookingResult.data.flightOffers[0].itineraries[0].searchSegmentSummary.arrivalAirport.city}} - {{dataService.formatDateEEMMMdy(flightItem.bookingResult.data.flightOffers[0].itineraries[0].searchSegmentSummary?.departureDate)}}
    </p>
</div>
<div class="position-relative mb-5" (click)="tripsService.viewTripsDetails(flightItem.hrsReferenceNumber, flightItem.email, flightItem.bookingType)">

    <div class="p-4 hotel-item rounded pointer" *ngFor="let itinerary of flightItem.bookingResult.data.flightOffers[0].itineraries">

        <div class="details">
            <div class="row">
                <div class="col-8">
                    <h2 class="gray-primaray-text bold-600 font-16 mb-2">{{itinerary.searchSegmentSummary.departureAirport.city}} - {{itinerary.searchSegmentSummary.arrivalAirport.city}}</h2>
                    <p class="primaray-text font-weight-bold mt-3">
                        <span class="mb-0 primaray-text" [innerHTML]="this.dataService.formatDateTime12Hours(itinerary.searchSegmentSummary.departureDate)">10:15pm</span> -
                        <span class="mb-0 primaray-text" [innerHTML]="this.dataService.formatDateTime12Hours(itinerary.searchSegmentSummary.arrivalDate)">10:15pm</span>
                        <span class="font-14 font-weight-normal" *ngIf="dataService.checkNextDay(itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate) === 1" i18n="@@nextDay"> (Next day)</span>
                        <span class="font-14 font-weight-normal" *ngIf="dataService.checkNextDay(itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate) > 1"> +{{dataService.checkNextDay(itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate)}}</span>
                        <span class="font-14 font-weight-normal" *ngIf="dataService.checkPreviousDay(itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate)" i18n="@@prevDay"> (Previous day)</span>
                    </p>
                    <p class="gray-text-dark font-14">
                        <span>{{itinerary.searchSegmentSummary.departureAirport.iata}}</span>
                        <span class="super-vertical-align"> ____ </span>
                        <span>{{itinerary.searchSegmentSummary.arrivalAirport.iata}}</span>
                    </p>


                </div>
                <div class="col-4" [ngClass]="{'text-right': dataService.mobileView}">
                    <div [ngClass]="{'h-100 d-flex pt-4': dataService.mobileView}">
                        <p class="mb-0 primaray-text" [ngClass]="{'my-auto ml-auto': dataService.mobileView}">
                            <span [innerHTML]="itinerary.duration | isoToMinutes : flightListService">6h 45m</span>
                            <br/>
                            <span *ngIf="itinerary.searchSegmentSummary.totalStops === 1">(<ng-container i18n="@@oneStop">1 stop</ng-container>)</span>
                            <span *ngIf="itinerary.searchSegmentSummary.totalStops === 2">(<ng-container i18n="@@twoStops">2 stops</ng-container>)</span>
                            <span *ngIf="itinerary.searchSegmentSummary.totalStops > 2">({{itinerary.searchSegmentSummary.totalStops}} <ng-container i18n="@@stops">stops</ng-container>)</span>
                            <span class="green-text" *ngIf="itinerary.searchSegmentSummary.totalStops === 0" i18n="@@nonStop">Nonstop</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="airlines">
                <p class="mb-0 gray-text-dark" *ngIf="itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines.length === 1">
                    {{itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines[0]}}
                </p>
                <p class="mb-0 gray-text-dark" *ngIf="itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines.length > 1">
                    <span i18n="@@multipleAirlines">Multiple Airlines: </span>
                    <span *ngFor="let airlineItem of itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines">
                        {{airlineItem}}
                    </span>
                </p>
            </div>
        </div>
    </div>



<!--        <div class="reward-promo d-flex position-absolute" [ngClass]="{'inactive-rewards': dataService.inactiveWorkspace}"-->
<!--             *ngIf="flightItem.bookingResult.data.flightOffers[0]?.travelerPricings[0]?.userData?.convertedRewardAmount">-->
<!--            <div class="promo-triangle"></div>-->
<!--            <div class="promo-text text-white font-12 px-3 pt-2">{{flightItem.bookingResult.data.flightOffers[0]?.travelerPricings[0]?.userData?.convertedRewardCurrencyCode}} {{flightItem.bookingResult.data.flightOffers[0]?.travelerPricings[0]?.userData?.convertedRewardAmount  |  number : '1.0-0'}} <ng-container i18n="@@inRewards">in Rewards</ng-container></div>-->
<!--        </div>-->

</div>
