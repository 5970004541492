<div class="main-container pb-5" [ngClass]="{'gray-pass-bg' : dataService.desktopView}">
  <div class="container mb-5 pt-5 pt-lg-2 pb-5">
    <div class="custom-container workspace-registration-container mx-auto" >
      <div class="text-center">
        <h4 class="font-24 pt-md-4 mb-3 font-weight-bold text-center" i18n="@@onBoard1">
          How many employees in your company?
        </h4>
      </div>
      <p class="gray-text font-16 pb-4 mb-3 text-center px-4" i18n="@@onBoard2">Please choose 1 item</p>


  <div class="row justify-content-center font-16">
    <div class="checkbox-radio-item-container col-6 col-md form-group px-4 px-lg-2" *ngFor="let employeesNumber of vars.numberOfEmployeesArray, let i = index">
      <input class="d-none" type="radio" id="{{i}}" name="gender" (change)="changeEmployeesNumber(employeesNumber.id)" />
      <label class="white-box border w-100 font-weight-bold d-flex justify-content-center align-items-center mb-0" for="{{i}}">
       <div class="text-center">
         <span class="d-block">{{employeesNumber.value}}</span>
         <span class="d-block font-12" i18n="@@onBoard3">employees</span>
       </div>
      </label>

    </div>


  </div>





  <div class="row mt-lg-5 cont-btn" >
    <div class="col-8 ml-auto d-flex align-items-end mx-auto">
      <button (click)="updateCompany()" [disabled]="disabledNext"  class="btn green_btn btn-block font-weight-normal font-14 px-5 mt-auto workspace-btn-padding">
        <span class="mx-auto" i18n="@@contBtn">Continue</span>
      <!--  <i class="icon-right-arrow-slider"></i>-->
      </button>
    </div>

  </div>
  <p class="mt-5 text-center"><a class="blue-text font-14" (click)="this.router.navigate(['/upload-logo']);" i18n="@@skipBtn"> skip</a></p>








</div>

  </div>
</div>



