<div class="main-dashboard-content" *ngIf="dataService.mobileView">
  <app-messages></app-messages>
  <div class="pt-4 pb-5" *ngIf="dataService.mobileView">
    <div class="mx-lg-5">
      <div *ngIf="this.auth.companyInfo" [ngClass]="{
          'pl-3': dataService.isSiteLtr(),
          'pr-3': dataService.isSiteRtl()
        }">
        <ng-container *ngIf="!policyId">
          <a class="font-16 blue-text text-underline" (click)="
              this.router.navigate([
                '/travel-policies'
              ])
            " i18n="@@menuItemPolicies">Travel Policies</a>
          <span class="font-16 gray-text-dark">
            / <ng-container i18n="@@newPolicy">New Policy</ng-container>
          </span>
        </ng-container>
        <ng-container *ngIf="policyId && this.policyName">
          <a class="font-16 blue-text text-underline" (click)="
              this.router.navigate([
                '/travel-policies'
              ])
            " i18n="@@menuItemPolicies">Travel Policies</a>
          <span class="font-16 gray-text-dark"> / {{ policyName }} </span>
        </ng-container>
      </div>

      <div class="pt-5">
        <app-create-policy></app-create-policy>
      </div>
    </div>
  </div>
</div>
<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container" *ngIf="this.dataService.desktopView">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }" [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    ">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pt-5 pb-5" *ngIf="dataService.desktopView">
        <div class="mx-lg-5">
          <div class="pb-5 mb-3" *ngIf="this.auth.companyInfo">
            <ng-container *ngIf="!policyId">
              <a class="font-16 blue-text text-underline" (click)="
                  this.router.navigate([
                    '/travel-policies'
                  ])
                " i18n="@@menuItemPolicies">Travel Policies</a>
              <span class="font-16 gray-text-dark">
                / <ng-container i18n="@@newPolicy">New Policy</ng-container>
              </span>
            </ng-container>
            <ng-container *ngIf="policyId && this.policyName">
              <a class="font-16 blue-text text-underline" (click)="
                  this.router.navigate([
                    '/travel-policies'
                  ])
                " i18n="@@menuItemPolicies">Travel Policies</a>
              <span class="font-16 gray-text-dark"> / {{ policyName }} </span>
            </ng-container>
          </div>
        </div>
        <div class="mx-lg-4">
          <div class="container pb-5">
            <form name="createPolicyForm" [formGroup]="createPolicyForm" [ngClass]="{
                'px-lg-3':
                  this.dataService.desktopView &&
                  !dataService.createEditPolicyPage
              }">
              <div [ngClass]="{
                  'w-75':
                    this.dataService.desktopView &&
                    !dataService.createEditPolicyPage,
                  'max-col-width':
                    this.dataService.desktopView &&
                    dataService.createEditPolicyPage
                }">
                <p class="gray-primaray-text mb-2" [ngClass]="{ 'font-16': dataService.createEditPolicyPage }"
                  i18n="@@name">
                  Name
                </p>
                <div class="name-row">
                  <div class="form-group with-icon position-relative"
                    *ngIf="dataService.desktopView || policyId === undefined"
                    [ngClass]="{ 'realtime-loader': nameLoader }">
                    <input #policyInput class="form-control" formControlName="policyName" autocomplete="off" type="text"
                      id="nameInputPolicy" [ngClass]="{
                        'is-invalid': submitted && f.policyName.errors,
                        'edit-field': policyId && !editPolicyName
                      }" (click)="editPolicyName = true" (blur)="editPolicy('name', null, null)"
                      (focus)="policyInput.select()" (keyup.enter)="enterActionFunc('name', null, null)" (keyup.escape)="
                        restorePolicy('name', null); policyInput.blur()
                      " />
                    <div *ngIf="submitted && f.policyName.errors" class="text-danger font-12 mb-2">
                      <div *ngIf="f.policyName.errors.required" i18n="@@policyNameRequired">
                        Policy name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-control edit-field" *ngIf="dataService.mobileView && policyId"
                  (click)="showPolicyNamePopUp()">
                  <p *ngIf="this.policyInfo && this.policyInfo.name" class="pt-3">
                    {{ this.policyInfo.name }}
                  </p>
                </div>
                <div class="mt-5 border px-0 pt-1 pb-0 mb-3 rounded section-width">
                  <h3 class="my-0 font-18 container py-4 position-relative pointer" [ngClass]="{
                      'pr-5': dataService.isSiteLtr(),
                      'pl-5': dataService.isSiteRtl()
                    }" id="flight" (click)="showSection('flight')">
                    <a i18n="@@flightPolicy">Flight Policy</a>
                    <p class="tooltip d-inline" *ngIf="showSelectedSectionFlight === 'flight'" [ngClass]="{
                        'pl-1': dataService.isSiteLtr(),
                        'pr-1': dataService.isSiteRtl()
                      }">
                      <i class="icon-warning-msg mlight-text font-14"></i>
                      <span class="tooltip-text border rounded bg-white p-3 font-12" i18n="@@flightInfo">If disabled,
                        approval will be required for any flight
                        booking.</span>
                    </p>
                    <mat-slide-toggle *ngIf="showSelectedSectionFlight === 'flight'"
                      formControlName="flightAllowedToggle" [ngClass]="{
                        'pl-4': dataService.isSiteLtr(),
                        'pr-4': dataService.isSiteRtl()
                      }" (click)="
                        disableFlightPolicy = !disableFlightPolicy;
                        $event.stopPropagation()
                      " (change)="editPolicy('flightAllowedToggle', null, null)">
                    </mat-slide-toggle>
                    <div [ngClass]="{
                        'toggle-loader realtime-loader': flightToggleLoader
                      }"></div>
                    <i class="section-arrow icon-down gray-text mt-2" [ngClass]="{
                        'rotate-bottom': showSelectedSectionFlight === 'flight',
                        'rotate-right': showSelectedSectionFlight !== 'flight'
                      }"></i>
                  </h3>
                  <div class="inner-section-width px-5" [ngClass]="{
                      'pl-4': dataService.isSiteLtr(),
                      'pr-4': dataService.isSiteRtl(),
                      'show-section': showSelectedSectionFlight === 'flight',
                      'hide-section': showSelectedSectionFlight !== 'flight',
                      disabled:
                        (policyId &&
                          this.policyInfo &&
                          this.policyInfo.flightBookingNotAllowed === true) ||
                        (!policyId && disableFlightPolicy === true)
                    }">
                    <p class="gray-primaray-text mb-2" [ngClass]="{
                        'font-16': dataService.createEditPolicyPage
                      }" i18n="@@fareClass">
                      Fare class
                    </p>
                    <div [ngClass]="{ 'realtime-loader': fairClassLoader }">
                      <select #fairClass formControlName="fairClass" class="form-control mt-2 select-custom-arrow mb-3"
                        name="fairClass" id="fairClass" [ngClass]="{
                          'edit-field': policyId && !editFairClass,
                          'no-bg-img': fairClassLoader
                        }" (click)="editFairClass = true" (change)="setSelectedFairClass()"
                        (keyup.enter)="enterActionFunc('fairClass', null, null)" (keyup.escape)="
                          restorePolicy('fairClass', null); fairClass.blur()
                        ">
                        <ng-container *ngFor="let item of vars.classType">
                          <option [value]="selectedFairClass" *ngIf="selectedFairClass === item.value"
                            [selected]="true">
                            {{ item.name }}
                          </option>
                          <option [value]="item.value" *ngIf="selectedFairClass !== item.value">
                            {{ item.name }}
                          </option>
                        </ng-container>
                      </select>
                    </div>

                    <a class="gray-primaray-text mb-2" [ngClass]="{
                      'font-16 mt-2': dataService.createEditPolicyPage,
                      'mt-1': dataService.createEditPolicyPage
                    }" i18n="@@dynamicPriceLimit">Dynamic price limit</a>
                    <p class="tooltip d-inline" *ngIf="showSelectedSectionFlight === 'flight'" [ngClass]="{
                        'pl-1': dataService.isSiteLtr(),
                        'pr-1': dataService.isSiteRtl()
                      }">
                      <i class="icon-warning-msg mlight-text font-14"></i>
                      <span class="tooltip-text border rounded bg-white p-3 font-12" i18n="@@dynamicPriceLimitInfo">Set
                        a dynamic price limit based on the flight route to maintain flexibility in your policy.</span>
                    </p>
                    <div class="row"
                      [ngClass]="{'mx-0': dataService.mobileView,show: showSelectedSectionFlight === 'flight',hide: showSelectedSectionFlight !== 'flight'}"
                      *ngIf="dataService.desktopView || policyId === undefined">
                      <div class="col-6 form-group mb-3 amount-field" [ngClass]="{
                        'realtime-loader': medianPriceLimitPercentageLoader,
                        'pr-0': dataService.isSiteLtr(),
                        'pl-0': dataService.isSiteRtl()
                      }">
                        <select #medianPriceLimitPercentage id="medianPriceLimitPercentage"
                          class="form-control select-custom-arrow" placeholder="Cuurency"
                          formControlName="medianPriceLimitPercentage" name="medianPriceLimitPercentage" [ngClass]="{
                          'is-invalid':
                            submitted && f.medianPriceLimitPercentage.errors,
                          'edit-field': policyId && !editMedianPriceLimitPercentage,
                          'no-bg-img':medianPriceLimitPercentageLoader
                        }" (blur)="
                          editPolicy('medianPriceLimitPercentage', null, null)
                        " (selectionchange)="
                          enterActionFunc(
                            'medianPriceLimitPercentage',
                            null,
                            null
                          )
                        ">
                          <ng-container *ngFor="let item of medianPriceLimitPercentageList">
                            <option [ngValue]="item.Value">
                              {{ item.Option }}
                            </option>
                          </ng-container>
                        </select>
                      </div>

                      <div class="col-6 form-group mb-3 amount-field" [ngClass]="{
                      'realtime-loader dynamic-price-loader': medianPriceLimitPercentageCategoryLoader
                    }">
                        <select #medianPriceLimitPercentageCategory id="medianPriceLimitPercentageCategory"
                          class="form-control select-custom-arrow" formControlName="medianPriceLimitPercentageCategory"
                          name="medianPriceLimitPercentageCategory" [ngClass]="{
                        'is-invalid':
                          submitted && f.medianPriceLimitPercentageCategory.errors,
                        'edit-field': policyId && !editMedianPriceLimitPercentageCategory,
                        'no-bg-img': medianPriceLimitPercentageCategoryLoader
                      }" (blur)="
                        editPolicy('medianPriceLimitPercentageCategory', null, null)
                      " (selectionchange)="
                        enterActionFunc(
                          'medianPriceLimitPercentageCategory',
                          null,
                          null
                        )
                      ">
                          <ng-container *ngFor="let item of medianPriceLimitPercentageOptionsList">
                            <option [ngValue]="item.Value">
                              {{ item.Name }}
                            </option>
                          </ng-container>
                        </select>
                        <i *ngIf="f.medianPriceLimitPercentageCategory.value && f.medianPriceLimitPercentage.value" class="icon-close-light ml-2 gray-text position-absolute max-no-trips delete-item pointer"
                        (click)="removeFlightDynamicPriceLimit()"></i>
                      </div>
                    </div>

                    <p class="gray-primaray-text mb-2" [ngClass]="{
                        'font-16 mt-2': dataService.createEditPolicyPage,
                        'mt-1': dataService.createEditPolicyPage
                      }" i18n="@@flightPriceCap">
                      Flight price cap
                    </p>
                    <div class="row"
                      [ngClass]="{'mx-0': dataService.mobileView,show: showSelectedSectionFlight === 'flight',hide: showSelectedSectionFlight !== 'flight'}"
                      *ngIf="dataService.desktopView || policyId === undefined">
                      <div class="col-6 form-group mb-3 amount-field" [ngClass]="{
                          'realtime-loader': priceCapLoader,
                          'pr-0': dataService.isSiteLtr(),
                          'pl-0': dataService.isSiteRtl()
                        }">
                        <input #flightLimit type="number" pattern="[0-9]+(\.[0-9][0-9]?)?"
                          formControlName="flightPolicyLimit" class="form-control" id="flightPolicyLimit"
                          (blur)="editPolicy('flightLimit', null, null); emptyPriceCurrency('flightLimit');" (keyup.enter)="
                              enterActionFunc('flightLimit', null, null)
                            " (keyup.escape)="
                              restorePolicy('flightLimit', null);
                              flightLimit.blur()
                            " [ngClass]="{
                              'is-invalid':
                                submitted &&
                                f.flightPolicyLimit &&
                                f.flightPolicyLimit.errors,
                              'edit-field': policyId && !editFlightLimit
                            }" (click)="editFlightLimit = true;" />
                      </div>
                      <div class="col-6 form-group mb-3 amount-field" [ngClass]="{
                          'realtime-loader': priceCapCurrencyLoader,
                          'pr-0': dataService.isSiteLtr(),
                          'pl-0': dataService.isSiteRtl()
                        }">
                        <select #flightPolicyLimitCurrency id="flightPolicyLimitCurrency"
                          class="form-control select-custom-arrow" placeholder="Cuurency"
                          formControlName="flightPolicyLimitCurrency" name="flightPolicyLimitCurrency" [ngClass]="{
                            'is-invalid':
                              submitted && f.flightPolicyLimitCurrency.errors,
                            'edit-field': policyId && !editFlightLimit,
                            'no-bg-img': priceCapCurrencyLoader
                          }" (blur)="
                            editPolicy('flightPolicyLimitCurrency', null, null)
                          " (selectionchange)="
                            enterActionFunc(
                              'flightPolicyLimitCurrency',
                              null,
                              null
                            )
                          ">
                          <ng-container *ngFor="let item of currenciesList">
                            <option [ngValue]="item.code">
                              {{ item.code }}
                            </option>
                          </ng-container>
                        </select>
                      </div>
                    </div>

                    <div *ngIf="
                        submitted &&
                        f.flightPolicyLimit &&
                        f.flightPolicyLimit.errors
                      " class="text-danger smaller">
                      <div *ngIf="f.flightPolicyLimit.errors.required">
                        Flight price cap is required
                      </div>
                    </div>
                    <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId && !priceCap"
                      (click)="showFlightPolicyLimitPopUp()">
                      <p *ngIf="
                          this.policyInfo &&
                          this.policyInfo.companyPolicyFlightRules &&
                          this.policyInfo.companyPolicyFlightRules
                            .internationalPriceLimitPerPassenger
                        " class="pt-3">
                        {{
                          this.policyInfo.companyPolicyFlightRules
                            .internationalPriceLimitPerPassenger
                        }}
                      </p>
                    </div>
                    <div class="form-group font-16 mb-3 gray-text-dark pt-0">
                      <input class="mt-2 input-mobile" type="checkbox" [ngClass]="{
                          'mr-2': dataService.isSiteLtr(),
                          'ml-2': dataService.isSiteRtl()
                        }" [checked]="priceCap" id="priceCap" name="priceCap"
                        (change)="priceCap = !priceCap; showNewFields()" />
                      <label class="checkbox-mobile" [ngClass]="{
                          'text-nowrap':
                            dataService.desktopView && dataService.isSiteLtr()
                        }" for="priceCap" i18n="@@domeAndInterPrice">Set price cap for domestic & International</label>
                    </div>
                    <div *ngIf="priceCap" [ngClass]="{
                        'pl-4': dataService.isSiteLtr(),
                        'pr-4': dataService.isSiteRtl()
                      }">
                      <div>
                        <p class="gray-text mb-2" [ngClass]="{
                            'font-16 mt-2': dataService.createEditPolicyPage,
                            'mt-1': dataService.createEditPolicyPage
                          }" i18n="@@interFlightPriceCap">
                          International flight price cap
                        </p>
                        <div class="form-group mb-3 amount-field row" *ngIf="
                            dataService.desktopView || policyId === undefined
                          " [ngClass]="{
                            'realtime-loader pe-30':
                              internationalPriceCapLoader,
                            show: showSelectedSectionFlight === 'flight',
                            hide: showSelectedSectionFlight !== 'flight'
                          }">
                          <div class="col-md-4">
                            <input #internationalLimit type="number" pattern="[0-9]+(\.[0-9][0-9]?)?"
                              name="internationalPriceCap" class="form-control" id="internationalPriceCap"
                              formControlName="internationalPriceCap" [ngClass]="{
                                'is-invalid':
                                  submitted && f.internationalPriceCap.errors,
                                'edit-field':
                                  policyId && !editInternationalLimit
                              }" (click)="editInternationalLimit = true" (blur)="
                                editPolicy('internationalLimit', null, null); emptyPriceCurrency('internationalLimit');
                              " (keyup.enter)="
                                enterActionFunc(
                                  'internationalLimit',
                                  null,
                                  null
                                )
                              " (keyup.escape)="
                                restorePolicy('internationalLimit', null);
                                internationalLimit.blur()
                              " />
                          </div>
                          <div class="col-md-8">
                            <select #internationalPriceCapCurrency id="internationalPriceCapCurrency"
                              class="form-control select-custom-arrow" placeholder="Cuurency"
                              formControlName="internationalPriceCapCurrency" name="internationalPriceCapCurrency"
                              [ngClass]="{
                                'is-invalid':
                                  submitted &&
                                  f.internationalPriceCapCurrency.errors,
                                'edit-field':
                                  policyId && !editInternationalLimit
                              }" (blur)="
                                editPolicy(
                                  'internationalPriceCapCurrency',
                                  null,
                                  null
                                )
                              " (selectionchange)="
                                enterActionFunc(
                                  'internationalPriceCapCurrency',
                                  null,
                                  null
                                )
                              ">
                              <ng-container *ngFor="let item of currenciesList">
                                <option [ngValue]="item.code">
                                  {{ item.code }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                        <div *ngIf="submitted && f.internationalPriceCap.errors" class="text-danger smaller">
                          <div *ngIf="f.internationalPriceCap.errors.required" i18n="@@interFlightCapRequired">
                            International flight price cap is required
                          </div>
                        </div>
                        <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId"
                          (click)="showInternationalPolicyLimitPopUp()">
                          <p *ngIf="
                              this.policyInfo &&
                              this.policyInfo.companyPolicyFlightRules &&
                              this.policyInfo.companyPolicyFlightRules
                                .internationalPriceLimitPerPassenger
                            " class="pt-3">
                            {{
                              this.policyInfo.companyPolicyFlightRules
                                .internationalPriceLimitPerPassenger
                            }}
                          </p>
                        </div>
                      </div>
                      <div>
                        <p class="gray-text mb-2" [ngClass]="{
                            'font-16 mt-2': dataService.createEditPolicyPage,
                            'mt-1': dataService.createEditPolicyPage
                          }" i18n="@@domPriceCap">
                          Domestic flight price cap
                        </p>
                        <div class="form-group mb-4 amount-field row" *ngIf="
                            dataService.desktopView || policyId === undefined
                          " [ngClass]="{
                            'realtime-loader pe-30': domesticPriceCapLoader,
                            show: showSelectedSectionFlight === 'flight',
                            hide: showSelectedSectionFlight !== 'flight'
                          }">
                          <div class="col-md-4">
                            <input #domesticLimit type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="domesticPriceCap"
                              class="form-control" id="domesticPriceCap" formControlName="domesticPriceCap" [ngClass]="{
                                'is-invalid':
                                  submitted &&
                                  f.domesticPriceCap &&
                                  f.domesticPriceCap.errors,
                                'edit-field': policyId && !editDomesticLimit
                              }" (click)="editDomesticLimit = true" (blur)="editPolicy('domesticLimit', null, null); emptyPriceCurrency('domesticLimit');"
                              (keyup.enter)="
                                enterActionFunc('domesticLimit', null, null)
                              " (keyup.escape)="
                                restorePolicy('domesticLimit', null);
                                domesticLimit.blur()
                              " />
                          </div>
                          <div class="col-md-8">
                            <select #domesticPriceCapCurrency id="domesticPriceCapCurrency" placeholder="Cuurency"
                              class="form-control select-custom-arrow" formControlName="domesticPriceCapCurrency"
                              name="domesticPriceCapCurrency" [ngClass]="{
                                'is-invalid':
                                  submitted &&
                                  f.domesticPriceCapCurrency &&
                                  f.domesticPriceCapCurrency.errors,
                                'edit-field': policyId && !editDomesticLimit
                              }" (blur)="
                                editPolicy(
                                  'domesticPriceCapCurrency',
                                  null,
                                  null
                                )
                              " (selectionchange)="
                                enterActionFunc(
                                  'domesticPriceCapCurrency',
                                  null,
                                  null
                                )
                              ">
                              <ng-container *ngFor="let item of currenciesList">
                                <option [ngValue]="item.code">
                                  {{ item.code }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                        <div *ngIf="
                            submitted &&
                            f.domesticPriceCap &&
                            f.domesticPriceCap.errors
                          " class="text-danger smaller mb-3">
                          <div *ngIf="f.domesticPriceCap.errors.required" i18n="@@domFlightCapRequired">
                            Domestic flight price is required
                          </div>
                        </div>
                        <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId"
                          (click)="showDomesticPolicyLimitPopUp()">
                          <p *ngIf="
                              this.policyInfo &&
                              this.policyInfo.companyPolicyFlightRules &&
                              this.policyInfo.companyPolicyFlightRules
                                .domesticPriceLimitPerPassenger
                            " class="pt-3">
                            {{
                              this.policyInfo.companyPolicyFlightRules
                                .domesticPriceLimitPerPassenger
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p class="gray-primaray-text mb-2" [ngClass]="{
                        'font-16': dataService.createEditPolicyPage
                      }" i18n="@@maxNoOfTrips">
                      Max number of trips
                    </p>
                    <div class="row" [ngClass]="{ 'mx-0': dataService.mobileView }">
                      <div class="col-6" [ngClass]="{
                          'pr-0': dataService.isSiteLtr(),
                          'pl-0': dataService.isSiteRtl()
                        }">
                        <div class="form-group mb-3 amount-field" *ngIf="
                            dataService.desktopView || policyId === undefined
                          " [ngClass]="{
                            'realtime-loader': tripsMaxNoLoader,
                            show: showSelectedSectionFlight === 'flight',
                            hide: showSelectedSectionFlight !== 'flight'
                          }">
                          <input #maximumNumberOfTrips type="number" pattern="[0-9]+(\.[0-9][0-9]?)?"
                            name="maximumNumberOfTrips" class="form-control" id="maximumNumberOfTrips"
                            formControlName="maximumNumberOfTrips" [ngClass]="{
                              'edit-field': policyId && !editMaxNoOfTrips
                            }" (click)="editMaxNoOfTrips = true" (blur)="
                              editPolicy('maximumNumberOfTrips', null, null)
                            " (keyup.enter)="
                              enterActionFunc(
                                'maximumNumberOfTrips',
                                null,
                                null
                              )
                            " (keyup.escape)="
                              restorePolicy('maximumNumberOfTrips', null);
                              maximumNumberOfTrips.blur()
                            " />
                        </div>
                      </div>
                      <div class="col-6">
                        <div [ngClass]="{
                            'realtime-loader': maxIntervalLoader,
                            invisible: policyId && !showIntervalList
                          }">
                          <select #maxInterval formControlName="maxInterval"
                            class="form-control select-custom-arrow interval mb-3" name="maxInterval" id="maxInterval"
                            [ngClass]="{
                              'edit-field': policyId && !editMaxInterval,
                              'no-bg-img': maxIntervalLoader
                            }" (click)="editMaxInterval = true" (change)="setSelectedMaxInterval()" (keyup.enter)="
                              enterActionFunc('maxInterval', null, null)
                            " (keyup.escape)="
                              restorePolicy('maxInterval', null);
                              maxInterval.blur()
                            ">
                            <ng-container *ngFor="let item of vars.intervals">
                              <option [value]="selectedMaxInterval" *ngIf="selectedMaxInterval === item.value"
                                [selected]="true">
                                {{ item.name }}
                              </option>
                              <option [value]="item.value" *ngIf="selectedMaxInterval !== item.value">
                                {{ item.name }}
                              </option>
                            </ng-container>
                          </select>
                          <i class="icon-close-light gray-text position-absolute max-no-trips delete-item pointer"
                            (click)="removeMaxNoOfTrips()"></i>
                        </div>
                      </div>
                    </div>

                    <p class="gray-primaray-text mb-2" [ngClass]="{
                      'font-16 mt-2': dataService.createEditPolicyPage,
                      'mt-1': dataService.createEditPolicyPage
                    }" i18n="@@bookingWindow">
                      Booking window
                    </p>
                    <div class="d-flex"
                      [ngClass]="{'mx-0': dataService.mobileView,show: showSelectedSectionFlight === 'flight',hide: showSelectedSectionFlight !== 'flight'}"
                      *ngIf="dataService.desktopView || policyId === undefined">
                      <div class="mb-3">
                        <div [ngClass]="{
                      'realtime-loader booking-window-loader': bookingWindowInDaysLoader,
                      'pl-0': dataService.isSiteLtr(),
                      'pl-0': dataService.isSiteRtl()
                    }">

                          <p><span i18n="@@bookingWindowInDaysNote1">Flight should be booked at least</span>
                            <input #bookingWindowInDays
                              class="form-control d-inline px-4 mx-2 bookingWindowInDaysWidth"
                              formControlName="bookingWindowInDays" autocomplete="off" type="text"
                              (input)="numbersOnly($event)" id="bookingWindowInDays" [ngClass]="{
                          'is-invalid': submitted && f.bookingWindowInDays.errors,
                          'edit-field': policyId && !editBookingWindowInDays
                        }" (click)="editBookingWindowInDaysName = true"
                              (blur)="editPolicy('bookingWindowInDays', null, null)"
                              (focus)="bookingWindowInDays.select()"
                              (keyup.enter)="enterActionFunc('bookingWindowInDays', null, null)" (keyup.escape)="
                          restorePolicy('name', null); bookingWindowInDays.blur()
                        " /><span i18n="@@bookingWindowInDaysNote2">day(s) in advance</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-5 border px-0 pt-1 pb-0 mb-3 rounded section-width">
                  <h3 class="my-0 font-18 container py-4 position-relative pointer" [ngClass]="{
                      'pr-5': dataService.isSiteLtr(),
                      'pl-5': dataService.isSiteRtl()
                    }" id="hotel" (click)="showSection('hotel')">
                    <a i18n="@@hotelPolicy">Hotel Policy</a>
                    <p class="tooltip d-inline" *ngIf="showSelectedSectionHotel === 'hotel'" [ngClass]="{
                        'pl-1': dataService.isSiteLtr(),
                        'pr-1': dataService.isSiteRtl()
                      }">
                      <i class="icon-warning-msg mlight-text font-14"></i>
                      <span class="tooltip-text border rounded bg-white p-3 font-12" i18n="@@hotelInfo">If disabled,
                        approval will be required for any hotel
                        booking.</span>
                    </p>
                    <mat-slide-toggle *ngIf="showSelectedSectionHotel === 'hotel'" formControlName="hotelAllowedToggle"
                      [ngClass]="{
                        'pl-4': dataService.isSiteLtr(),
                        'pr-4': dataService.isSiteRtl()
                      }" (click)="
                        disableHotelPolicy = !disableHotelPolicy;
                        $event.stopPropagation()
                      " (change)="editPolicy('hotelAllowedToggle', null, null)">
                    </mat-slide-toggle>
                    <div [ngClass]="{
                        'toggle-loader realtime-loader': hotelToggleLoader
                      }"></div>
                    <i class="section-arrow icon-down gray-text mt-2" [ngClass]="{
                        'rotate-bottom': showSelectedSectionHotel === 'hotel',
                        'rotate-right': showSelectedSectionHotel !== 'hotel'
                      }"></i>
                  </h3>
                  <div class="px-5 inner-section-width" [ngClass]="{
                      'pl-4': dataService.isSiteLtr(),
                      'pr-4': dataService.isSiteRtl(),
                      'show-section': showSelectedSectionHotel === 'hotel',
                      'hide-section': showSelectedSectionHotel !== 'hotel',
                      disabled:
                        (policyId &&
                          this.policyInfo &&
                          this.policyInfo.hotelBookingNotAllowed === true) ||
                        (!policyId && disableHotelPolicy === true)
                    }">

                    <a class="gray-primaray-text mb-2" [ngClass]="{
  'font-16 mt-2': dataService.createEditPolicyPage,
  'mt-1': dataService.createEditPolicyPage
}" i18n="@@dynamicPriceLimit">Dynamic price limit</a>
                    <p class="tooltip d-inline" *ngIf="showSelectedSectionHotel === 'hotel'" [ngClass]="{
    'pl-1': dataService.isSiteLtr(),
    'pr-1': dataService.isSiteRtl()
  }">
                      <i class="icon-warning-msg mlight-text font-14"></i>
                      <span class="tooltip-text border rounded bg-white p-3 font-12"
                        i18n="@@dynamicPriceLimitHotelInfo">Set a dynamic price limit based on the hotel destination to
                        maintain flexibility in your policy.</span>
                    </p>
                    <div class="row"
                      [ngClass]="{'mx-0': dataService.mobileView,show: showSelectedSectionHotel === 'hotel',hide: showSelectedSectionHotel !== 'hotel'}"
                      *ngIf="dataService.desktopView || policyId === undefined">
                      <div class="col-6 form-group mb-3 amount-field" [ngClass]="{
    'realtime-loader': hotelMedianPriceLimitPercentageLoader,
    'pr-0': dataService.isSiteLtr(),
    'pl-0': dataService.isSiteRtl()
  }">
                        <select #hotelMedianPriceLimitPercentage id="hotelMedianPriceLimitPercentage"
                          class="form-control select-custom-arrow" placeholder="Cuurency"
                          formControlName="hotelMedianPriceLimitPercentage" name="hotelMedianPriceLimitPercentage"
                          [ngClass]="{
      'is-invalid':
        submitted && f.hotelMedianPriceLimitPercentage.errors,
      'edit-field': policyId && !editMedianPriceLimitPercentage,
      'no-bg-img':hotelMedianPriceLimitPercentageLoader
    }" (blur)="
      editPolicy('hotelMedianPriceLimitPercentage', null, null)
    " (selectionchange)="
      enterActionFunc(
        'hotelMedianPriceLimitPercentage',
        null,
        null
      )
    ">
                          <ng-container *ngFor="let item of hotelMedianPriceLimitPercentageList">
                            <option [ngValue]="item.Value">
                              {{ item.Option }}
                            </option>
                          </ng-container>
                        </select>
                      </div>

                      <div class="col-6 form-group mb-3 amount-field" [ngClass]="{
  'realtime-loader dynamic-price-loader': hotelMedianPriceLimitPercentageCategoryLoader
}">
                        <select #hotelMedianPriceLimitPercentageCategory id="hotelMedianPriceLimitPercentageCategory"
                          class="form-control select-custom-arrow"
                          formControlName="hotelMedianPriceLimitPercentageCategory"
                          name="hotelMedianPriceLimitPercentageCategory" [ngClass]="{
    'is-invalid':
      submitted && f.hotelMedianPriceLimitPercentageCategory.errors,
    'edit-field': policyId && !editHotelMedianPriceLimitPercentageCategory,
    'no-bg-img': hotelMedianPriceLimitPercentageCategoryLoader
  }" (blur)="
    editPolicy('hotelMedianPriceLimitPercentageCategory', null, null)
  " (selectionchange)="
    enterActionFunc(
      'hotelMedianPriceLimitPercentageCategory',
      null,
      null
    )
  ">
                          <ng-container *ngFor="let item of hotelMedianPriceLimitPercentageOptionsList">
                            <option [ngValue]="item.Value">
                              {{ item.Name }}
                            </option>
                          </ng-container>
                        </select>
                        <i *ngIf="f.hotelMedianPriceLimitPercentageCategory.value && f.hotelMedianPriceLimitPercentage.value" class="icon-close-light gray-text position-absolute max-no-trips delete-item pointer"
                        (click)="removeHotelDynamicPriceLimit()"></i>
                      </div>
                    </div>

                    <p class="gray-primaray-text mb-2" [ngClass]="{
                        'font-16 mt-2': dataService.createEditPolicyPage,
                        'mt-1': dataService.createEditPolicyPage
                      }" i18n="@@avgNightlyPrice">
                      Hotel average nightly price limit
                    </p>

                    <div class="form-group mb-3 amount-field row"
                      *ngIf="dataService.desktopView || policyId === undefined" [ngClass]="{
                        'realtime-loader pe-30': limitLoader,
                        show: showSelectedSectionHotel === 'hotel',
                        hide: showSelectedSectionHotel !== 'hotel'
                      }">
                      <div class="col-md-6 pr-0">
                        <input #limitInput type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="policyLimit"
                          formControlName="policyLimit" class="form-control" id="limitInputPolicy"
                          (click)="editPolicyLimit = true" (blur)="
                            editPolicy('hotelLimit', null, null); emptyPriceCurrency('hotelLimit');
                            checkIfHaveValue()
                          " (keyup.enter)="
                            enterActionFunc('hotelLimit', null, null)
                          " (keyup.escape)="
                            restorePolicy('hotelLimit', null); limitInput.blur()
                          " [ngClass]="{
                            'is-invalid': submitted && f.policyLimit.errors,
                            'edit-field': policyId && !editPolicyLimit
                          }" />
                      </div>
                      <div class="col-md-6 pr-0">
                        <select #policyLimitCurrency class="form-control select-custom-arrow" placeholder="Cuurency"
                          formControlName="policyLimitCurrency" name="policyLimitCurrency" [ngClass]="{
                            'is-invalid':
                              submitted && f.policyLimitCurrency.errors,
                            'edit-field': policyId && !editPolicyLimit
                          }" (blur)="editPolicy('hotelLimitCurrency', null, null)" (selectionchange)="
                            enterActionFunc('hotelLimitCurrency', null, null)
                          ">
                          <ng-container *ngFor="let item of currenciesList">
                            <option [ngValue]="item.code">
                              {{ item.code }}
                            </option>
                          </ng-container>
                        </select>
                      </div>
                      <div *ngIf="
                          (submitted && f.policyLimit.errors) || defaultRequired
                        " class="text-danger smaller">
                        <div class="font-12 pt-1" *ngIf="
                            (f.policyLimit.errors &&
                              f.policyLimit.errors.required) ||
                            defaultRequired
                          " i18n="@@avgNightlyPriceRequired">
                          Hotel average nightly price limit & currency are
                          required
                        </div>
                      </div>
                    </div>
                    <div class="form-control edit-field" *ngIf="dataService.mobileView && policyId"
                      (click)="showPolicyLimitPopUp()">
                      <p *ngIf="
                          this.policyInfo &&
                          this.policyInfo.companyPolicyHotelRules &&
                          this.policyInfo.companyPolicyHotelRules
                            .avgNightlyPriceLimit
                        " class="pt-3">
                        {{
                          this.policyInfo.companyPolicyHotelRules
                            .avgNightlyPriceLimit
                        }}
                      </p>
                    </div>
                    <p class="font-12 gray-text mt-4" i18n="@@cityExcep">
                      You can add an exception per city
                    </p>
                    <div class="d-flex position-relative" *ngFor="
                        let city of citiesList;
                        let i = index;
                        trackBy: trackCity
                      ">
                      <div class="form-control city-item gray-text" [ngClass]="{
                          'edit-field': policyId,
                          'mr-3': dataService.mobileView
                        }">
                        <input type="text" value="{{ city.caption }}" class="gray-text custom-text pt-3" [ngClass]="{
                            'edit-field': policyId,
                            'pr-3':
                              dataService.mobileView && dataService.isSiteLtr(),
                            'pl-3':
                              dataService.mobileView && dataService.isSiteRtl()
                          }" readonly />
                      </div>
                      <div class="form-group mb-3 amount-field" *ngIf="
                          dataService.desktopView || policyId === undefined
                        " [ngClass]="{
                          'ml-1': dataService.isSiteLtr(),
                          'mr-1': dataService.isSiteRtl(),
                          show: showSelectedSectionHotel === 'hotel',
                          hide: showSelectedSectionHotel !== 'hotel'
                        }">
                        <input type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="place-{{ i }}"
                          class="form-control city-item-amount" formControlName="cityLimit{{ i }}" id="place-{{ i }}"
                          [ngClass]="{
                            'is-invalid':
                              submitted && !isValid('cityLimit' + i),
                            'edit-field': policyId && !editCitiesLimit[i]
                          }" (click)="editCitiesLimit[i] = true" (blur)="editPolicy('cityLimit', i, false)"
                          (keyup.enter)="enterActionFunc('cityLimit', i, false)" />
                        <div *ngIf="submitted && !isValid('cityLimit' + i)" class="text-danger smaller mb-2"
                          i18n="@@limitRequired">
                          Limit is required
                        </div>
                      </div>
                      <div class="form-group mb-3 amount-field" *ngIf="
                          dataService.desktopView || policyId === undefined
                        " [ngClass]="{
                          'ml-1': dataService.isSiteLtr(),
                          'mr-1': dataService.isSiteRtl(),
                          show: showSelectedSectionHotel === 'hotel',
                          hide: showSelectedSectionHotel !== 'hotel'
                        }">
                        <select name="placeCurrency-{{ i }}" id="placeCurrency-{{ i }}" placeholder="Cuurency"
                          class="form-control select-custom-arrow city-item-amount"
                          formControlName="cityLimitCurrency{{ i }}" [ngClass]="{
                            'is-invalid':
                              submitted && !isValid('cityLimitCurrency' + i),
                            'edit-field': policyId && !editCitiesLimit[i]
                          }" (blur)="editPolicy('cityLimitCurrency', i, false)" (selectionchange)="
                            enterActionFunc('cityLimitCurrency', i, false)
                          ">
                          <ng-container *ngFor="let item of currenciesList">
                            <option [ngValue]="item.code">
                              {{ item.code }}
                            </option>
                          </ng-container>
                        </select>
                        <div *ngIf="submitted && !isValid('cityLimitCurrency' + i)" class="text-danger smaller mb-2"
                          i18n="@@currencyRequired">
                          Limit Currency is required
                        </div>
                      </div>
                      <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId"
                        (click)="showCityPolicyLimitPopUp(i)">
                        <p *ngIf="
                            this.policyInfo &&
                            this.policyInfo.companyPolicyHotelRules &&
                            this.policyInfo.companyPolicyHotelRules.exceptions[
                              i
                            ]
                          " class="pt-3">
                          {{
                            this.policyInfo.companyPolicyHotelRules.exceptions[
                              i
                            ].avgNightlyPriceLimit
                          }}
                        </p>
                      </div>
                      <i class="icon-close-light gray-text position-absolute delete-item pointer"
                        (click)="removeCity(i)" [ngClass]="{
                          'realtime-loader city-limit': cityLoaders[i]
                        }"></i>
                    </div>
                    <p *ngIf="dataService.desktopView" class="blue-text pointer font-16"
                      (click)="addRequiredToDefaultValue()" i18n="@@addCity">
                      Add city
                    </p>
                    <p *ngIf="dataService.mobileView" class="blue-text pointer font-16"
                      (click)="addRequiredToDefaultValue()" i18n="@@addCity">
                      Add city
                    </p>
                    <div class="d-flex">
                      <mat-form-field class="w-100 autoComplete-max-width" *ngIf="showAutoCompleteField">
                        <div class="form-group m-0 with-icon">
                          <div class="form-control">
                            <input (keydown)="onChangeSearch()" (ngModelChange)="
                                this.SearchTextQuery.next($event)
                              " matInput [matAutocomplete]="auto" autocomplete="off" [(ngModel)]="this.caption"
                              [ngModelOptions]="{ standalone: true }" name="list" (focus)="autocompleteOnFocus($event)"
                              placeholder="select" type="text" id="list" class="py-3 mb-3" />
                            <span *ngIf="
                                caption !== undefined && caption.length === 0
                              " class="gray-text placeholder-search" i18n="@@enterCityOnly">Enter city name...</span>
                          </div>
                          <i class="icon-location gray-text"></i>
                          <mat-autocomplete #auto="matAutocomplete">
                            <ng-container *ngFor="
                                let autocompletetype of autocompletelist;
                                let y = index
                              ">
                              <div *ngIf="
                                  autocompletetype.name.toLowerCase() ===
                                  'cities'
                                ">
                                <ng-container *ngFor="
                                    let entity of autocompletetype.entities
                                  ">
                                  <mat-option data-id="{{ entity.code }}" tabindex="-1" [value]="entity.caption"
                                    (click)="
                                      setSearchText(
                                        entity,
                                        autocompletetype.name
                                      );
                                      showAutoCompleteField = false;
                                      focusField()
                                    " *ngIf="notSelected(entity.caption)">
                                    <span class="d-block">
                                      {{ entity.caption }}
                                    </span>
                                  </mat-option>
                                </ng-container>
                              </div>
                            </ng-container>
                          </mat-autocomplete>
                        </div>
                      </mat-form-field>
                      <div *ngIf="showAutoCompleteField" class="pt-3 px-3">
                        <i class="icon-cross gray-text font-12 pointer" (click)="emptyCityField()"></i>
                      </div>
                    </div>

                    <p class="gray-primaray-text mb-2" [ngClass]="{
                      'font-16 mt-2': dataService.createEditPolicyPage,
                      'mt-1': dataService.createEditPolicyPage
                    }" i18n="@@bookingWindow">
                      Booking window
                    </p>
                    <div class="d-flex"
                      [ngClass]="{'mx-0': dataService.mobileView,show: showSelectedSectionHotel === 'hotel',hide: showSelectedSectionHotel !== 'hotel'}"
                      *ngIf="dataService.desktopView || policyId === undefined">
                      <div class="mb-3">
                        <div [ngClass]="{
                      'realtime-loader booking-window-loader': hotelBookingWindowInDaysLoader,
                      'pl-0': dataService.isSiteLtr(),
                      'pl-0': dataService.isSiteRtl()
                    }">

                          <p><span i18n="@@bookingWindowInDaysHotelNote1">Hotel should be booked at least</span>
                            <input #hotelBookingWindowInDays
                              class="form-control d-inline px-4 mx-2 bookingWindowInDaysWidth"
                              formControlName="hotelBookingWindowInDays" autocomplete="off" type="text"
                              (input)="numbersOnly($event)" id="hoteBookingWindowInDays" [ngClass]="{
                          'is-invalid': submitted && f.hotelBookingWindowInDays.errors,
                          'edit-field': policyId && !editHotelBookingWindowInDays
                        }" (click)="editHotelBookingWindowInDays = true"
                              (blur)="editPolicy('hotelBookingWindowInDays', null, null)"
                              (focus)="hotelBookingWindowInDays.select()"
                              (keyup.enter)="enterActionFunc('hotelBookingWindowInDays', null, null)" (keyup.escape)="
                          restorePolicy('hotelBookingWindowInDays', null); hotelBookingWindowInDays.blur()
                        " /><span i18n="@@bookingWindowInDaysNote2">day(s) in advance</span></p>
                        </div>
                      </div>
                    </div>           
                      <div class="mb-4">                  
                        <div class="form-item form-type-radios form-name-rating">
                          <div class="d-flex">
                          <p class="font-16" i18n="@@maximumHotelStars">Maximum hotel stars</p>
                          <p class="tooltip d-inline" [ngClass]="{
                            'pl-1': dataService.isSiteLtr(),
                            'pr-1': dataService.isSiteRtl()
                          }">
                          <i class="icon-warning-msg mlight-text font-14"></i>
                          <span class="tooltip-text border rounded bg-white p-3 font-12" i18n="@@maximumHotelStarsInfo">Define the rule for hotel star ratings. If set to 5 stars, all hotels are permitted.</span>
                        </p>
                        </div>
                          <div class="d-flex"
                          [ngClass]="{'mx-0': dataService.mobileView,show: showSelectedSectionHotel === 'hotel',hide: showSelectedSectionHotel !== 'hotel'}"
                          *ngIf="dataService.desktopView || policyId === undefined">
                          <div class="mb-3">
                            <div [ngClass]="{
                          'realtime-loader max-stars-loader': hotelMaxStarsLoader,
                          'pl-0': dataService.isSiteLtr(),
                          'pl-0': dataService.isSiteRtl()
                        }">

                          <div class="d-flex rate">                    
                            <div class="form-radios mx-0">             
                              <div class="form-item form-type-star d-inline-block" *ngFor="let n of ratingValues"
                                   [ngClass]="{'active': starsNumber >= n}">
                                <input id="rating_{{n}}" name="maxStars" formControlName="maxStars"
                                       value="{{n}}" type="radio" (click)="setStarsNumber(n); editPolicy('hotelMaxStars', null, null); editHotelMaxStars = true">
                                <label for="rating_{{n}}"></label>
                              </div>
                            </div>
                          </div>

                            </div></div></div>

                        </div>
                      </div>
                    
                  </div>
                </div>
              </div>
              <p class="gray-primaray-text mb-1" [ngClass]="{
                  'font-16 mt-5': dataService.createEditPolicyPage,
                  'mt-4': dataService.createEditPolicyPage
                }" i18n="@@approval">
                Approval
              </p>
              <p class="gray-text font-12" i18n="@@requireApproval">
                Choose when travelers require approval
              </p>
              <div class="row font-16 options" [ngClass]="{
                  'justify-content-center': dataService.mobileView,
                  'left-m': dataService.desktopView
                }">
                <div class="checkbox-radio-item-container col-6 col-md form-group px-4 px-lg-2"
                  *ngFor="let option of options; let i = index">
                  <input class="d-none" type="radio" id="{{ i }}" name="plan" (click)="
                      selectedId = option.id; requireApprovalType(option.id)
                    " />
                  <label class="white-box border w-100 d-flex justify-content-center align-items-center mb-0" [ngClass]="{
                      'unselected green-text': option.id === selectedId,
                      'realtime-loader in-checkbox':
                        checkboxLoader && selectedId === option.id
                    }" for="{{ i }}">
                    <div class="text-center">
                      <span class="d-block font-12 mb-2">{{
                        option.name
                      }}</span>
                      <span class="d-block font-12 gray-text" *ngIf="option.note">{{ option.note }}</span>
                    </div>
                  </label>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-5" *ngIf="dataService.desktopView && policyId === undefined"
                [ngClass]="{
                  'max-col-width':
                    this.dataService.desktopView &&
                    dataService.createEditPolicyPage
                }">
                <button type="button" class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" [ngClass]="{
                    'cancel-btn-min-width': dataService.createEditPolicyPage
                  }" (click)="cancelCreate()" i18n="@@cancelBtn">
                  Cancel
                </button>
                <button type="button" class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5"
                  (click)="createPolicy()" [ngClass]="{
                    'm-progress': submitting,
                    'ml-3': dataService.isSiteLtr(),
                    'mr-3': dataService.isSiteRtl()
                  }" i18n="@@createPolicy">
                  Create Policy
                </button>
              </div>
              <div class="my-5" *ngIf="dataService.mobileView && policyId === undefined">
                <div class="d-flex justify-content-end">
                  <button type="button" class="btn blue_btn font-14 sharp-font bold-500 py-1 mb-4 px-5 w-100"
                    (click)="createPolicy()" [ngClass]="{ 'm-progress': submitting }" i18n="@@createPolicy">
                    Create Policy
                  </button>
                </div>
                <div class="d-flex justify-content-end">
                  <button type="button" class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5 w-100"
                    (click)="cancelCreate()" i18n="@@cancelBtn">
                    Cancel
                  </button>
                </div>
              </div>
              <div *ngIf="errorMessage" class="text-danger mt-2" [ngClass]="{
                  'text-right':
                    dataService.desktopView && !dataService.createEditPolicyPage
                }">
                {{ errorMessage }}
              </div>
            </form>
          </div>
          <div class="justify-content-end create-team">
            <div (click)="mobileFocus()" [ngClass]="{
                'in action-popup':
                  showPolicyNamePopUpFlag ||
                  showPolicyLimitPopUpFlag ||
                  showSearchPopUpautocompleteFlag ||
                  showCityPolicyLimitPopUpFlag[selectedCityIndex] ||
                  showFlightPriceCapFlag ||
                  showMedianPercentageFlag ||
                  showInternationalPriceCapFlag ||
                  showDomesticPriceCapFlag
              }">
              <div class="header">
                <div *ngIf="
                    showPolicyNamePopUpFlag ||
                    showPolicyLimitPopUpFlag ||
                    showSearchPopUpautocompleteFlag ||
                    showCityPolicyLimitPopUpFlag[selectedCityIndex] ||
                    showFlightPriceCapFlag ||
                    showMedianPercentageFlag ||
                    showInternationalPriceCapFlag ||
                    showDomesticPriceCapFlag
                  ">
                  <div class="info">
                    <span *ngIf="showPolicyNamePopUpFlag" i18n="@@policy">Policy</span>
                  </div>
                  <div class="info">
                    <span *ngIf="showFlightPriceCapFlag" i18n="@@flightPriceCap">Flight price Cap</span>
                  </div>
                  <div class="info">
                    <span *ngIf="showMedianPercentageFlag" i18n="@@dynamicPriceLimit">Dynamic price limit</span>
                  </div>
                  <div class="info">
                    <span *ngIf="showInternationalPriceCapFlag" i18n="@@interFlightPriceCap">International flight price
                      Cap</span>
                  </div>
                  <div class="info">
                    <span *ngIf="showDomesticPriceCapFlag" i18n="@@domPriceCap">Domestic flight price Cap</span>
                  </div>
                  <div class="info">
                    <span *ngIf="showCityPolicyLimitPopUpFlag[selectedCityIndex]" i18n="@@cityAvgNightPrice">City
                      average nightly price cap</span>
                  </div>
                  <div class="close" *ngIf="
                      showPolicyNamePopUpFlag ||
                      showPolicyLimitPopUpFlag ||
                      showCityPolicyLimitPopUpFlag[selectedCityIndex] ||
                      showSearchPopUpautocompleteFlag ||
                      showFlightPriceCapFlag ||
                      showMedianPercentageCapFlag ||
                      showInternationalPriceCapFlag ||
                      showDomesticPriceCapFlag
                    " (click)="hidePopUp()">
                    Cancel
                  </div>
                </div>
                <div class="position-relative mobile-search-field" *ngIf="showSearchPopUpautocompleteFlag">
                  <div class="form-group mb-3">
                    <input (keydown)="onChangeSearch()" (ngModelChange)="this.SearchTextQuery.next($event)" matInput
                      autocomplete="off" [(ngModel)]="this.caption" [ngModelOptions]="{ standalone: true }" name="list"
                      (focus)="autocompleteOnFocus($event)" type="text" i18n-placeholder="@@enterCityPolicy"
                      placeholder="Enter city..." class="py-3 mb-3 form-control" />
                  </div>
                </div>
              </div>
              <div class="body" *ngIf="showCityPolicyLimitPopUpFlag[selectedCityIndex] === true">
                <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
                  <div class="form-group position-relative">
                    <input type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="place-{{ selectedCityIndex }}"
                      class="form-control city-item-amount" formControlName="cityLimit{{ selectedCityIndex }}"
                      id="place-{{ selectedCityIndex }}" [ngClass]="{ 'is-invalid': cityLimitMobileErrorMsg }" />
                  </div>
                  <div class="text-danger smaller mb-2">
                    {{ cityLimitMobileErrorMsg }}
                  </div>
                  <button class="blue_btn btn py-1 px-5 mb-3 w-100 sharp-font bold-500"
                    (click)="editPolicy('cityLimit', selectedCityIndex, null)" i18n="@@done">
                    Done
                  </button>
                </form>
              </div>
              <div class="body" *ngIf="showPolicyNamePopUpFlag === true">
                <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
                  <div class="form-group position-relative">
                    <input id="nameInput" class="form-control" formControlName="policyName" autocomplete="off"
                      type="text" [ngClass]="{
                        'is-invalid': submitted && f.policyName.errors
                      }" />
                  </div>
                  <div *ngIf="submitted && f.policyName.errors" class="text-danger font-12 mb-2">
                    <div *ngIf="f.policyName.errors.required" i18n="@@policyNameRequired">
                      Policy name is required
                    </div>
                  </div>
                  <button class="blue_btn btn py-1 px-5 mb-3 w-100 sharp-font bold-500"
                    (click)="editPolicy('name', null, null)" i18n="@@done">
                    Done
                  </button>
                </form>
              </div>
              <div class="body" *ngIf="showPolicyLimitPopUpFlag === true">
                <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
                  <div class="form-group position-relative">
                    <input id="limitInput" type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="policyLimit"
                      formControlName="policyLimit" class="form-control" [ngClass]="{
                        'is-invalid': submitted && f.policyLimit.errors
                      }" />
                  </div>
                  <div *ngIf="submitted && f.policyLimit.errors" class="text-danger smaller mb-2">
                    <div class="font-12 pt-1" *ngIf="f.policyLimit.errors.required" i18n="@@avgNightlyPriceRequired">
                      Hotel average nightly price limit is required
                    </div>
                  </div>
                  <button class="blue_btn btn py-1 px-5 mb-3 w-100 sharp-font bold-500"
                    (click)="editPolicy('hotelLimit', null, null)" i18n="@@done">
                    Done
                  </button>
                </form>
              </div>
              <div class="body" *ngIf="showFlightPriceCapFlag === true">
                <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
                  <div class="form-group position-relative">
                    <input type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" formControlName="flightPolicyLimit"
                      class="form-control" id="flightPolicyLimit" [ngClass]="{
                        'is-invalid':
                          submitted &&
                          f.flightPolicyLimit &&
                          f.flightPolicyLimit.errors
                      }" />
                  </div>
                  <div *ngIf="submitted && f.flightPolicyLimit.errors" class="text-danger smaller mb-2">
                    <div *ngIf="f.flightPolicyLimit.errors.required" i18n="@@flightPriceRequired">
                      Flight price limit is required
                    </div>
                  </div>
                  <button class="blue_btn btn py-1 px-5 mb-3 w-100 sharp-font bold-500"
                    (click)="editPolicy('flightLimit', null, null)" i18n="@@done">
                    Done
                  </button>
                </form>
              </div>

              <div class="body" *ngIf="showMedianPercentageFlag === true">
                <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
                  <div class="form-group position-relative">
                    <input type="text" formControlName="medianPriceLimitPercentage" class="form-control"
                      id="medianPriceLimitPercentage" [ngClass]="{
                        'is-invalid':
                          submitted &&
                          f.medianPriceLimitPercentage &&
                          f.medianPriceLimitPercentage.errors
                      }" />
                  </div>
                  <!-- <div *ngIf="submitted && f.flightPolicyLimit.errors" class="text-danger smaller mb-2">
                    <div *ngIf="f.flightPolicyLimit.errors.required" i18n="@@flightPriceRequired">
                      Flight price limit is required
                    </div>
                  </div> -->
                  <button class="blue_btn btn py-1 px-5 mb-3 w-100 sharp-font bold-500"
                    (click)="editPolicy('medianPriceLimitPercentage', null, null)" i18n="@@done">
                    Done
                  </button>
                </form>
              </div>

              <div class="body" *ngIf="showInternationalPriceCapFlag === true">
                <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
                  <div class="form-group position-relative">
                    <input type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="internationalPriceCap"
                      class="form-control" id="internationalPriceCap" formControlName="internationalPriceCap" [ngClass]="{
                        'is-invalid':
                          submitted && f.internationalPriceCap.errors
                      }" />
                  </div>
                  <div *ngIf="submitted && f.internationalPriceCap.errors" class="text-danger smaller mb-2">
                    <div *ngIf="f.internationalPriceCap.errors.required" i18n="@@interFlightPriceLimit">
                      International flight price limit is required
                    </div>
                  </div>
                  <button class="blue_btn btn py-1 px-5 mb-3 w-100 sharp-font bold-500"
                    (click)="editPolicy('internationalLimit', null, null)" i18n="@@done">
                    Done
                  </button>
                </form>
              </div>
              <div class="body" *ngIf="showDomesticPriceCapFlag === true">
                <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
                  <div class="form-group position-relative">
                    <input type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="domesticPriceCap" class="form-control"
                      id="domesticPriceCap" formControlName="domesticPriceCap" [ngClass]="{
                        'is-invalid':
                          submitted &&
                          f.domesticPriceCap &&
                          f.domesticPriceCap.errors
                      }" />
                  </div>
                  <div *ngIf="
                      submitted &&
                      f.domesticPriceCap &&
                      f.domesticPriceCap.errors
                    " class="text-danger smaller mb-2">
                    <div *ngIf="f.domesticPriceCap.errors.required" i18n="@@domFlightPriceLimit">
                      Domestic flight price limit is required
                    </div>
                  </div>
                  <button class="blue_btn btn py-1 px-5 mb-3 w-100 sharp-font bold-500"
                    (click)="editPolicy('domesticLimit', null, null)" i18n="@@done">
                    Done
                  </button>
                </form>
              </div>
              <div class="body" *ngIf="showSearchPopUpautocompleteFlag === true">
                <div class="autocomplete_wrapper">
                  <ng-container *ngFor="
                      let autocompletetype of autocompletelist;
                      let y = index
                    ">
                    <div *ngIf="y === 0">
                      <mat-option data-id="{{ entity.code }}" *ngFor="let entity of autocompletetype.entities"
                        tabindex="-1" [value]="entity.caption" (click)="
                          setSearchText(entity, autocompletetype.name);
                          showSearchPopUpautocompleteFlag = false
                        ">
                        <span class="d-block">
                          {{ entity.caption }}
                        </span>
                      </mat-option>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>