<div class="container my-5" [ngClass]="{ 'd-none': Service.bookingPage }">
  <div class="row">
    <div class="col-md-8">
      <app-workspace-booking-form></app-workspace-booking-form>
    </div>

    <div class="col-md-4 hotel-details-side d-md-block d-none">
        <app-hotel-details></app-hotel-details>
    </div>
  </div>
</div>

<div
  [ngClass]="{ active: this.Service.ratesExpiry }"
  class="snackbar d-block d-md-flex"
>
  <p>
    <i
      class="icon-warning"
      [ngClass]="{ 'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl() }"
    ></i>
    &nbsp;<ng-container i18n="@@refreshHotel"
      >Refresh this hotel page to get the latest rates.</ng-container
    >
  </p>
  <a
    class="text-uppercase col-md d-block"
    [ngClass]="{
      'text-md-right': Service.isSiteLtr(),
      'text-md-left': Service.isSiteRtl()
    }"
    (click)="refreshPage()"
  >
    <i
      class="icon-refresh font-16"
      [ngClass]="{ 'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl() }"
    ></i
    >&nbsp;<ng-container i18n="@@refresh">refresh</ng-container></a
  >
</div>

<app-loaders></app-loaders>
