<div class="hotel-list" (window:scroll)="onWindowScroll($event)">
  <div class="top-search" *ngIf="listView">
    <div class="container">
      <div
        [ngClass]="{ 'mx-lg-5': Service.isCompany() && Service.desktopView }"
      >
        <div
          [ngClass]="{
            'text-md-right': Service.isSiteLtr(),
            'text-md-left': Service.isSiteRtl()
          }"
        >
          <ul class="search-summary list-unstyled padding-left-search">
            <li class="item location">
              <span
                *ngIf="this.Service.hotellistLoader"
                class="loading_icon_caption"
              ></span>

              <span *ngIf="data && data.data">{{
                data.data.searchCaption
              }}</span>
            </li>
            <li class="item dates">
              {{ queryParams.checkIn | date : "EE, MMM d " }} -
              {{ queryParams.checkOut | date : "EE, MMM d " }}
            </li>

            <li class="item people_number">
              {{ PaxesArray[0] }}
              <span class="night-cont text-lowercase">
                <ng-container *ngIf="PaxesArray[0] === '1'" i18n="@@adult"
                  >Adult</ng-container
                >
                <ng-container *ngIf="PaxesArray[0] === '2'" i18n="@@twoAdults"
                  >Adults</ng-container
                >
                <ng-container *ngIf="PaxesArray[0] > 2" i18n="@@adults"
                  >Adults</ng-container
                >
              </span>

              <span *ngIf="PaxesArray.length > 1"
                >, {{ PaxesArray.length - 1 }}
                <span class="night-cont text-lowercase">
                  <ng-container
                    *ngIf="PaxesArray.length - 1 === 1"
                    i18n="@@child"
                    >Child</ng-container
                  >
                  <ng-container
                    *ngIf="PaxesArray.length - 1 === 2"
                    i18n="@@twoChildren"
                    >Children</ng-container
                  >
                  <ng-container
                    *ngIf="PaxesArray.length - 1 > 2"
                    i18n="@@children"
                    >Children</ng-container
                  >
                </span>
              </span>
            </li>
            <li
              class="item button"
              [ngClass]="{ 'text-left': Service.isSiteRtl() }"
            >
              <button
                [ngClass]="{ active: !isCollapsed }"
                class="btn-link collapseSearch"
                (click)="isCollapsed = !isCollapsed"
                [attr.aria-expanded]="!isCollapsed"
                aria-controls="collapseSearch"
              >
                <ng-container i18n="@@edit">Edit</ng-container>&nbsp;<i
                  class="icon-down"
                ></i>
              </button>
            </li>
          </ul>
        </div>

        <div id="collapseSearch" [ngbCollapse]="isCollapsed">
          <div class="row mt-3 justify-content-end">
            <div
              class="search_box position-relative"
              [ngClass]="{
                'col-10': !Service.isCompany(),
                'col-12': Service.isCompany()
              }"
              *ngIf="this.Service.desktopView"
            >
              <app-search-controller></app-search-controller>
            </div>
            <div
              class="search_box p-md-5 position-relative col-12"
              *ngIf="this.Service.mobileView"
            >
              <app-search-controller-mobile></app-search-controller-mobile>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="{ container: Service.isCompany() && Service.desktopView }">
    <div [ngClass]="{ 'mx-lg-4': Service.isCompany() && Service.desktopView }">
      <div>
        <div
          *ngIf="this.Service.desktopView"
          class="listing hotels_listing mb-5"
        >
          <div class="container">
            <div class="row" *ngIf="listView">
              <div class="col-md-3">
                <div
                  class="filters"
                  [ngClass]="{
                    disabled:
                      errorData ||
                      (this.Service.hotellistLoader &&
                        Service.hotelFilter === false)
                  }"
                >
                  <button
                    class="button btn-link toggleView map-illustration not_button mb-md-5 mb-0 btn-auto"
                    (click)="this.Service.showFooter = false; mapViewtoggle()"
                    [ngClass]="{ disabled: showViewMap }"
                  >
                    <span i18n="@@viewMap">
                      view map
                      <i class="icon-locator"></i>
                    </span>
                  </button>

                  <div class="filter_group mb-5 mt-2">
                    <div
                      class="blue-text filters_clear not-mobile mb-3 font-weight-bold"
                      [ngClass]="{
                        visible: filteringActive,
                        invisible: !filteringActive
                      }"
                      (click)="clearFilters()"
                    >
                      <i class="fas fa-times-circle"></i>&nbsp;<ng-container
                        i18n="@@clearAllFilters"
                        >Clear all filters</ng-container
                      >
                    </div>
                    <h4 i18n="@@searchByName">Search by name</h4>
                    <div class="main">
                      <div class="form-group m-0 with-icon">
                        <input
                          class="form-control"
                          [(ngModel)]="SearchByNameText"
                          type="text"
                          i18n-placeholder="@@hotelName"
                          placeholder="Hotel Name"
                          (ngModelChange)="this.hotelSearchFilter.next($event)"
                        />
                        <i *ngIf="!SearchByNameText" class="icon-search"></i>
                        <i
                          *ngIf="SearchByNameText"
                          class="icon-close-light p-2 pointer"
                          (click)="
                            SearchByNameText = '';
                            this.hotelSearchFilter.next('')
                          "
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-item form-type-checkbox mb-4 pb-1"
                    *ngIf="Service.isCompany() && showWithinPolicyFilter"
                    [ngClass]="{ active: filterWithinPolicyCheck }"
                  >
                    <input
                      id="within-policy"
                      type="checkbox"
                      [(ngModel)]="filterWithinPolicy"
                      [checked]="filterWithinPolicy"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="
                        this.Service.hotelFilter = true;
                        filterWithinPolicyFunc()
                      "
                    />
                    <label
                      for="within-policy"
                      class="gray-text-dark"
                      i18n="@@showInPolicyHotelsOnly"
                      >Show in-policy hotels only</label
                    >
                  </div>
                  <div
                    class="filter_group mb-5 mt-2"
                    *ngIf="Amenities.length > 0"
                  >
                    <h4 i18n="@@popularFilters">Popular Filters</h4>
                    <div class="main">
                      <div>
                        <div class="form-checkboxes">
                          <div
                            class="form-item form-type-checkbox mb-2"
                            [ngClass]="{ active: filterFreeCancellationCheck }"
                          >
                            <input
                              id="freeCan44"
                              type="checkbox"
                              [(ngModel)]="filterFreeCancellation"
                              [checked]="filterFreeCancellation"
                              [ngModelOptions]="{ standalone: true }"
                              (change)="
                                this.Service.hotelFilter = true;
                                filterFreeCancellationFunc()
                              "
                            />
                            <label
                              for="freeCan44"
                              class="gray-text-dark"
                              i18n="@@freeCancellation"
                              >Free Cancellation</label
                            >
                          </div>
                          <div
                            class="form-item form-type-checkbox mb-2"
                            *ngFor="let Amenity of Amenities; let i = index"
                            [ngClass]="{ active: activecheckbox === i }"
                          >
                            <input
                              type="checkbox"
                              id="Q{{ Amenity.hrsCode }}"
                              [checked]="Amenity.isChecked"
                              (change)="dataChangedAmenities($event, i)"
                            />
                            <label
                              class="gray-text-dark"
                              for="Q{{ Amenity.hrsCode }}"
                              >{{ Amenity.title }}</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="filter_group mb-5 mt-2">
                    <h4 i18n="@@starRating" i18n="@@starRating">Star Rating</h4>
                    <div class="main">
                      <div>
                        <div class="form-checkboxes">
                          <div
                            class="form-item form-type-checkbox mb-2"
                            *ngFor="let ratings of ratingArray; let i = index"
                          >
                            <input
                              type="checkbox"
                              [id]="ratings.id"
                              [checked]="ratings.isChecked"
                              (change)="dataChanged($event, i)"
                            />
                            <label
                              class="gray-text-dark"
                              for="{{ ratings.id }}"
                              >{{ ratings.name }}</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="filter_group mb-5 mt-2"
                    *ngIf="displayGoogleRatingFilter"
                  >
                    <h4 i18n="@@guestsRating">Guests Rating</h4>
                    <div class="d-flex">
                      <span
                        class="font-weight-bold gray-primaray-text"
                        [ngClass]="{
                          'mr-auto': Service.isSiteLtr(),
                          'ml-auto': Service.isSiteRtl()
                        }"
                        >{{ ReviewValue }}<span *ngIf="isInteger">.0</span>
                        {{ ReviewValueText }}</span
                      >
                    </div>

                    <ng5-slider
                      [(value)]="ReviewValue"
                      [options]="optionsReview"
                      (userChange)="ReviewChange($event)"
                      (userChangeEnd)="ReviewChangeEnd($event)"
                    ></ng5-slider>
                  </div>

                  <div class="filter_group mb-5 mt-2">
                    <h4 i18n="@@priceRangeNightly">Price Range (Nightly)</h4>
                    <div class="d-flex">
                      <span
                        class="font-weight-bold gray-primaray-text"
                        [ngClass]="{
                          'mr-auto': Service.isSiteLtr(),
                          'ml-auto': Service.isSiteRtl()
                        }"
                        >{{ currency }} {{ minValue }}</span
                      >
                      <span class="font-weight-bold gray-primaray-text"
                        >{{ currency }} {{ maxValue
                        }}<span *ngIf="maxValue === 500">+</span></span
                      >
                    </div>

                    <ng5-slider
                      [(value)]="minValue"
                      [(highValue)]="maxValue"
                      [options]="optionsSlider"
                      (userChangeEnd)="PriceChangeEnd($event)"
                    ></ng5-slider>
                  </div>
                  <div class="filter_group mb-5 not-mobile">
                    <img
                      width="225"
                      height="57"
                      loading="lazy"
                      alt="Feefo logo"
                      border="0"
                      src="https://api.feefo.com/api/logo?merchantidentifier=triphop&template=Service-Stars-White-225x57.png"
                      title="Our customer Feefo rating"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-9" *ngIf="!errorData">
                <div
                  class="policy-details position-relative mb-4 py-3 px-4 company-section-with-thick-border border"
                  [ngClass]="{
                    'rounded-right': Service.isSiteLtr(),
                    'rounded-left': Service.isSiteRtl(),
                    'thick-top rounded-bottom': Service.mobileView
                  }"
                  *ngIf="policyDetails && showPolicyDetails"
                >
                  <i
                    class="icon-close-light gray-text font-12 pointer position-absolute"
                    [ngClass]="{
                      'mr-4 ml-auto': Service.isSiteLtr(),
                      'ml-4 mr-auto': Service.isSiteRtl()
                    }"
                    (click)="showPolicyDetails = false"
                  ></i>
                  <p class="mb-2 font-18 bold-600" i18n="@@travelPolicy">
                    Travel Policy
                  </p>
                  <p
                    *ngIf="policyDetails.hotelBookingNotAllowed === true"
                    i18n="@@approvalAlwaysRequiredHotel"
                  >
                    Approval is required for any hotel booking.
                  </p>
                  <div
                    class="d-flex"
                    *ngIf="
                      !policyDetails.hotelBookingNotAllowed ||
                      policyDetails.hotelBookingNotAllowed === false
                    "
                  >
                    <p
                      class="mb-0"
                      [ngClass]="{
                        'mr-5': Service.isSiteLtr(),
                        'ml-5': Service.isSiteRtl()
                      }"
                    >
                      <span
                        class="gray-primaray-text"
                        i18n="@@nightlyPriceLimitDetails"
                        >Nightly price limit:
                      </span>
                      <ng-container
                        *ngIf="
                          policyDetails.companyPolicyHotelRules
                            .avgNightlyPriceLimit && hasException
                        "
                      >
                        <span class="gray-text"
                          ><ng-container *ngIf="Service.isSiteLtr()">{{
                            exceptionCurrency
                          }}</ng-container>
                          {{ exceptionValue | number : "1.0-0" }}
                          <ng-container *ngIf="Service.isSiteRtl()">{{
                            exceptionCurrency
                          }}</ng-container></span
                        >
                      </ng-container>
                      <ng-container
                        *ngIf="
                          policyDetails.companyPolicyHotelRules
                            .avgNightlyPriceLimit && !hasException
                        "
                      >
                        <span class="gray-text">
                          <ng-container *ngIf="Service.isSiteLtr()">{{
                            policyCurrency
                          }}</ng-container
                          ><ng-container>
                            {{
                              policyDetails.companyPolicyHotelRules
                                .avgNightlyPriceLimit | number : "1.0-0"
                            }} </ng-container
                          ><ng-container *ngIf="Service.isSiteRtl()">{{
                            policyCurrency
                          }}</ng-container></span
                        >
                      </ng-container>
                      <span
                        class="gray-text"
                        *ngIf="
                          !policyDetails.companyPolicyHotelRules
                            .avgNightlyPriceLimit && !hasException
                        "
                      >
                        -
                      </span>
                    </p>
                    <p class="mb-0">
                      <span
                        class="gray-primaray-text"
                        i18n="@@ApprovalRequiredOn"
                        >Approval required on:
                      </span>
                      <span
                        class="gray-text"
                        *ngIf="
                          policyDetails.approvalProcessType ===
                          'NO_APPROVAL_REQUIRED'
                        "
                        i18n="@@noApproval"
                        >No approval</span
                      >
                      <span
                        class="gray-text"
                        *ngIf="
                          policyDetails.approvalProcessType ===
                          'RULES_VIOLATION_APPROVAL_REQUIRED'
                        "
                        i18n="@@outOfPolicy"
                        >Out of policy</span
                      >
                      <span
                        class="gray-text"
                        *ngIf="
                          policyDetails.approvalProcessType ===
                          'ALWAYS_APPROVAL_REQUIRED'
                        "
                        i18n="@@allTrips"
                        >All trips</span
                      >
                    </p>
                  </div>
                </div>

                <div class="mat-progress-bar-holder">
                  <div
                    class="sorting desktop"
                    [ngClass]="{ disabled: sortingEnabled }"
                  >
                    <div class="item text" i18n="@@sortBy">Sort by</div>
                    <div
                      class="item"
                      [ngClass]="{ active: ParentSort === 'default' }"
                    >
                      <a
                        class="reset"
                        (click)="sortFunc('default', 'default')"
                        i18n="@@ourFavorites"
                      >
                        Our Favorites
                      </a>
                    </div>
                    <div
                      class="item placeholder-text"
                      [ngClass]="{ active: ParentSort === 'guestRating' }"
                      *ngIf="!showGuestRating()"
                      i18n="@@guestRatingSort"
                    >
                      Guest Rating
                    </div>
                    <div
                      class="item"
                      [ngClass]="{ active: ParentSort === 'guestRating' }"
                      *ngIf="showGuestRating()"
                    >
                      <a
                        class="reset"
                        (click)="sortFunc('guestRating', 'guestRating')"
                        i18n="@@guestRatingSort"
                      >
                        Guest Rating
                      </a>
                    </div>

                    <div
                      ngbDropdown
                      class="item btn_to_link"
                      [ngClass]="{ active: ParentSort === 'ratingParent' }"
                    >
                      <span class="toggler sorter_label" ngbDropdownToggle
                        ><ng-container i18n="@@starRating"
                          >Star Rating</ng-container
                        >&nbsp;<i
                          class="icon-up-blue"
                          [ngClass]="{ 'for-company': Service.isCompany() }"
                        ></i
                      ></span>
                      <ul ngbDropdownMenu class="dropdown-menu">
                        <li
                          (click)="sortFunc('ratingHigh', 'ratingParent')"
                          [ngClass]="{
                            'active gray-text-dark': SortType === 'ratingHigh',
                            'font-weight-normal': SortType !== 'ratingHigh'
                          }"
                          i18n="@@lowToHigh"
                        >
                          Low to high
                        </li>
                        <li
                          (click)="sortFunc('ratingLow', 'ratingParent')"
                          [ngClass]="{
                            'active gray-text-dark': SortType === 'ratingLow',
                            'font-weight-normal': SortType !== 'ratingLow'
                          }"
                          i18n="@@highToLow"
                        >
                          High to low
                        </li>
                      </ul>
                    </div>
                    <div
                      ngbDropdown
                      class="item btn_to_link"
                      [ngClass]="{ active: ParentSort === 'PriceParent' }"
                    >
                      <span class="toggler sorter_label" ngbDropdownToggle>
                        <ng-container i18n="@@price">Price</ng-container
                        >&nbsp;<i
                          class="icon-up-blue"
                          [ngClass]="{ 'for-company': Service.isCompany() }"
                        ></i
                      ></span>
                      <ul ngbDropdownMenu class="dropdown-menu">
                        <li
                          [ngClass]="{
                            'active gray-text-dark': SortType === 'PriceHigh',
                            'font-weight-normal': SortType !== 'PriceHigh'
                          }"
                          (click)="sortFunc('PriceHigh', 'PriceParent')"
                          i18n="@@lowToHigh"
                        >
                          Low to high
                        </li>
                        <li
                          (click)="sortFunc('PriceLow', 'PriceParent')"
                          [ngClass]="{
                            'active gray-text-dark': SortType === 'PriceLow',
                            'font-weight-normal': SortType !== 'PriceLow'
                          }"
                          i18n="@@highToLow"
                        >
                          High to low
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <app-loaders></app-loaders>

                <div>
                  <div *ngIf="oneitemlistMode">
                    <div
                      class="oneitemlist-border rounded mb-4 not-available"
                      *ngIf="hoteloneItem.length === 0"
                    >
                      <div class="oneitemlist-header rounded-top p-2">
                        <span class="font-18">
                          <i class="icon-warning"></i>
                          {{ this.data.data.searchCaption }}

                          <span *ngIf="!ActiveOneItem">
                            <ng-container i18n="@@noAvailableDates"
                              >is not available for your travel
                              dates</ng-container
                            >&nbsp;–&nbsp;<span
                              (click)="openSearch()"
                              class="text-underline clickable-cursor"
                              ><ng-container i18n="@@tryNewDates"
                                >Try new dates!</ng-container
                              ></span
                            >
                          </span>
                        </span>
                      </div>
                    </div>

                    <div
                      [ngClass]="{ 'not-available': !ActiveOneItem }"
                      class="oneitemlist-border rounded mb-4"
                      *ngFor="let item of hoteloneItem; let i = index"
                    >
                      <div class="oneitemlist-header rounded-top p-2">
                        <span class="font-18">
                          <i
                            [ngClass]="
                              ActiveOneItem ? 'icon-success' : 'icon-warning'
                            "
                            class=""
                          ></i>
                          {{ item.name }}

                          <span *ngIf="!ActiveOneItem; else availableHotel">
                            <ng-container i18n="@@noAvailableDates"
                              >is not available for your travel
                              dates</ng-container
                            >&nbsp;–&nbsp;<span
                              (click)="openSearch()"
                              class="text-underline clickable-cursor"
                              ><ng-container i18n="@@tryNewDates"
                                >Try new dates!</ng-container
                              ></span
                            >
                          </span>

                          <ng-template
                            #availableHotel
                            i18n="@@isAvailableBookNow"
                          >
                            is available – Book Now!
                          </ng-template>
                        </span>
                      </div>
                      <div
                        class="not-mobile hotel-item rounded"
                        [attr.data-test]="item.rating"
                      >
                        <div class="row">
                          <div class="col-md-5 col-lg-4 hotel_image">
                            <a
                              target="_blank"
                              [routerLink]="[this.hotelNavigateUrl]"
                              [queryParams]="{
                                searchQuery: item.searchQuery,
                                checkIn: queryParams.checkIn,
                                checkOut: queryParams.checkOut,
                                paxes: queryParams.paxes,
                                travelerId: this.travelerId
                              }"
                            >
                              <img
                                *ngIf="
                                  item.images &&
                                  item.images[0] &&
                                  item.images[0].url.includes('https://')
                                "
                                [src]="item.images[0].url"
                                onError="this.src='assets/img/no_image.png'"
                                [ngClass]="{
                                  'rounded-left': Service.isSiteLtr(),
                                  'rounded-right': Service.isSiteRtl()
                                }"
                              />
                              <img
                                *ngIf="
                                  item.images &&
                                  item.images[0] &&
                                  !item.images[0].url.includes('https://')
                                "
                                [src]="
                                  item.images[0].url.replace(
                                    'http://',
                                    'https://'
                                  )
                                "
                                onError="this.src='assets/img/no_image.png'"
                                [ngClass]="{
                                  'rounded-left': Service.isSiteLtr(),
                                  'rounded-right': Service.isSiteRtl()
                                }"
                              />

                              <ng-container
                                *ngIf="
                                  !item.images ||
                                  !item.images[0] ||
                                  !item.images[0].url
                                "
                              >
                                <img
                                  width="279"
                                  height="239"
                                  loading="lazy"
                                  src="assets/img/no_image.png"
                                />
                              </ng-container>
                            </a>
                            <div *ngIf="logedIn.isLogedIn">
                              <span
                                class="position-absolute font-20"
                                *ngIf="
                                  !(
                                    item.additionalData &&
                                    item.additionalData.userData
                                  )
                                "
                                [ngClass]="{
                                  'icon-colored-heart':
                                    defaultFavForNoUserDataHotels[item.code],
                                  'icon-heart':
                                    !defaultFavForNoUserDataHotels[item.code]
                                }"
                                (click)="toggleFavoriteHotelList(item)"
                              >
                                <span class="path1"></span>
                                <span class="path2"></span>
                              </span>
                              <span
                                *ngIf="
                                  item.additionalData &&
                                  item.additionalData.userData
                                "
                              >
                                <span
                                  class="position-absolute font-20"
                                  [ngClass]="{
                                    'icon-colored-heart':
                                      item.additionalData.userData.favorite,
                                    'icon-heart':
                                      !item.additionalData.userData.favorite
                                  }"
                                  (click)="toggleFavoriteHotelList(item)"
                                >
                                  <span class="path1"></span>
                                  <span class="path2"></span>
                                </span>
                              </span>
                            </div>
                            <div *ngIf="!logedIn.isLogedIn">
                              <span
                                class="icon-heart position-absolute font-20"
                                (click)="
                                  this.Service.isLoginTitleFavorite = true;
                                  this.Service.openloginDialogue();
                                  this.Service.addNonLoggedInFavItem = item
                                "
                              >
                                <span class="path1"></span>
                                <span class="path2"></span>
                              </span>
                            </div>
                          </div>
                          <div class="col-md-7 col-lg-8">
                            <div
                              class="pt-4 pb-4 h-100"
                              [ngClass]="{
                                'pr-4': Service.isSiteLtr(),
                                'pl-4': Service.isSiteRtl()
                              }"
                            >
                              <div class="row h-100">
                                <div
                                  class="col-lg-7 col-md-6 d-flex align-items-start flex-column"
                                >
                                  <div class="hotel_info mb-auto">
                                    <div class="teaser_top">
                                      <h2 class="font-16">
                                        <a
                                          [routerLink]="[this.hotelNavigateUrl]"
                                          [queryParams]="{
                                            searchQuery: item.searchQuery,
                                            checkIn: queryParams.checkIn,
                                            checkOut: queryParams.checkOut,
                                            paxes: queryParams.paxes,
                                            travelerId: this.travelerId
                                          }"
                                          target="_blank"
                                          class="primaray-text font-weight-bold"
                                        >
                                          <span
                                            class="night-cont"
                                            *ngIf="
                                              item.name.length < 60;
                                              else sliced
                                            "
                                            >{{ item.name }}</span
                                          >
                                          <ng-template #sliced
                                            >{{ item.name | slice : 0 : 60 }}...
                                          </ng-template>
                                        </a>
                                      </h2>
                                      <div
                                        *ngIf="item.rating >= 1"
                                        class="hotel-stars-text mt-3 mb-3 gray-text-dark font-12 font-weight-bold"
                                      >
                                        {{ item.rating }}-<span
                                          class="night-cont"
                                          i18n="@@stars"
                                          >star</span
                                        >
                                      </div>

                                      <div class="address font-12">
                                        {{ item.address ? item.address : "," }},
                                        {{ item.cityName ? item.cityName : ""
                                        }}{{
                                          item.stateCode && item.cityName
                                            ? ", "
                                            : ""
                                        }}{{
                                          item.stateCode ? item.stateCode : ""
                                        }}{{
                                          (item.stateCode || item.cityName) &&
                                          item.countryName
                                            ? ", "
                                            : ""
                                        }}{{
                                          item.countryName
                                            ? item.countryName
                                            : ""
                                        }}
                                      </div>
                                    </div>
                                    <div
                                      class="google-review-cont d-inline-block py-3 mt-3 rounded"
                                      *ngIf="item.googleReview"
                                    >
                                      <div
                                        class="text-medium font-weight-bold gray-primaray-text align-middle d-inline-block"
                                      >
                                        {{ item.googleReview.rating }}
                                      </div>
                                      <div
                                        class="rating-stars-google d-inline-block"
                                      >
                                        <div
                                          class="rating-active"
                                          [style.width.%]="
                                            item.googleReview.ratingPercentage *
                                            100
                                          "
                                        ></div>
                                      </div>
                                      <br />

                                      <div
                                        class="text-sm gray-text-dark d-inline-block font-weight-bold separater-dot"
                                      >
                                        {{
                                          item.googleReview.ratingDescription
                                        }}
                                      </div>
                                      <span
                                        class="gray-text text-sm d-inline-block"
                                        ><span
                                          [ngClass]="{
                                            'mr-1': Service.isSiteLtr(),
                                            'ml-1': Service.isSiteRtl()
                                          }"
                                        >
                                          {{
                                            item.googleReview.userRatingsTotal
                                              | number : "" : "en-US"
                                          }}</span
                                        ><ng-container i18n="@@reviews"
                                          >reviews</ng-container
                                        ></span
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-5 cash-back-cont">
                                  <div
                                    *ngIf="
                                      item.lowestSellingPriceRate &&
                                      item.lowestSellingPriceRate
                                        .freeCancellation
                                    "
                                    class="cancelations position-absolute text-right font-15 mb-3"
                                    i18n="@@freeCancellation"
                                  >
                                    Free cancellation
                                  </div>

                                  <div
                                    class="price b position-relative pt-extra"
                                  >
                                    <div
                                      class="current_price clearfix"
                                      [ngClass]="{
                                        'text-right': Service.isSiteLtr(),
                                        'text-left': Service.isSiteRtl(),
                                        'prime-color-purple':
                                          logedIn.isLead &&
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConverted &&
                                          !this.Service.isCompany()
                                      }"
                                      *ngIf="item.lowestSellingPriceRate"
                                    >
                                      <del
                                        class="old-price font-20 line d-inline-block"
                                        [ngClass]="{
                                          'mr-2': Service.isSiteLtr(),
                                          'ml-2': Service.isSiteRtl()
                                        }"
                                        *ngIf="
                                          !this.Service.isCompany() &&
                                          logedIn.isLead &&
                                          item.lowestSellingPriceRate &&
                                          item.lowestSellingPriceRate
                                            .publicAverageNightlySellingPrice &&
                                          item.lowestSellingPriceRate
                                            .privateAverageNightlySellingPrice
                                        "
                                      >
                                        <span>{{
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConvertedCurrency
                                        }}</span
                                        ><span>
                                          {{
                                            item.lowestSellingPriceRate
                                              .publicAverageNightlySellingPrice
                                              | number : "1.0-0"
                                          }}
                                        </span>
                                      </del>

                                      <ng-container
                                        *ngIf="
                                          logedIn.isLead &&
                                          item.lowestSellingPriceRate
                                            .privateAverageNightlySellingPrice &&
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConverted
                                        "
                                      >
                                        <span class="amount-currency">{{
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConvertedCurrency
                                        }}</span>
                                        <span class="amount-currency">
                                          {{
                                            item.lowestSellingPriceRate
                                              .averageNightlySellingPriceConverted
                                              | number : "1.0-0"
                                          }}
                                        </span>

                                        <span
                                          class="nightWord"
                                          i18n="@@slashNight"
                                          >/night</span
                                        >
                                      </ng-container>
                                      <ng-container
                                        *ngIf="
                                          !logedIn.isLead &&
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConverted
                                        "
                                      >
                                        <span class="amount-currency">{{
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConvertedCurrency
                                        }}</span>
                                        <span class="amount-currency">
                                          {{
                                            item.lowestSellingPriceRate
                                              .averageNightlySellingPriceConverted
                                              | number : "1.0-0"
                                          }}
                                        </span>
                                        <span
                                          class="nightWord"
                                          i18n="@@slashNight"
                                          >/night</span
                                        >
                                      </ng-container>

                                      <ng-container
                                        *ngIf="
                                          logedIn.isLead &&
                                          item.lowestSellingPriceRate
                                            .privateAverageNightlySellingPrice &&
                                          !item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConverted
                                        "
                                      >
                                        <span class="amount-currency">{{
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConvertedCurrency
                                        }}</span>
                                        <span class="amount-currency">
                                          {{
                                            item.lowestSellingPriceRate
                                              .averageNightlySellingPriceConverted
                                              | number : "1.0-0"
                                          }}
                                        </span>
                                        <span
                                          class="nightWord"
                                          i18n="@@slashNight"
                                          >/night</span
                                        >
                                      </ng-container>

                                      <ng-container
                                        *ngIf="
                                          logedIn.isLead &&
                                          !item.lowestSellingPriceRate
                                            .privateAverageNightlySellingPrice &&
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConverted
                                        "
                                      >
                                        <span class="amount-currency">{{
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConvertedCurrency
                                        }}</span>
                                        <span class="amount-currency">
                                          {{
                                            item.lowestSellingPriceRate
                                              .averageNightlySellingPriceConverted
                                              | number : "1.0-0"
                                          }}
                                        </span>
                                        <span
                                          class="nightWord"
                                          i18n="@@slashNight"
                                          >/night</span
                                        >
                                      </ng-container>
                                    </div>

                                    <div
                                      class="tax mt-2"
                                      [ngClass]="{
                                        'text-right': Service.isSiteLtr(),
                                        'text-left': Service.isSiteRtl(),
                                        'no-margin-bottom':
                                          Service.isCompany() &&
                                          item.lowestSellingPriceRate &&
                                          item.lowestSellingPriceRate
                                            .userData &&
                                          item.lowestSellingPriceRate.userData
                                            .exceedsPolicyLimit
                                      }"
                                      *ngIf="
                                        item.lowestSellingPriceRate &&
                                        item.lowestSellingPriceRate
                                          .taxIncluded &&
                                        (item.lowestSellingPriceRate
                                          .averageNightlySellingPriceConverted ||
                                          logedIn.isLead)
                                      "
                                      i18n="@@taxAndFeesIncluded"
                                    >
                                      Tax and fees included
                                    </div>
                                    <div
                                      class="text-danger"
                                      [ngClass]="{
                                        'text-right': Service.isSiteLtr(),
                                        'text-left': Service.isSiteRtl()
                                      }"
                                      *ngIf="
                                        (item.lowestSellingPriceRate &&
                                          item.lowestSellingPriceRate
                                            .userData &&
                                          item.lowestSellingPriceRate.userData
                                            .exceedsPolicyLimit) ||
                                        (policyDetails &&
                                          policyDetails.hotelBookingNotAllowed ===
                                            true)
                                      "
                                      i18n="@@outOfPolicy"        matTooltip="{{
    item.lowestSellingPriceRate.userData.outOfPolicyDescriptions
      ? item.lowestSellingPriceRate.userData.outOfPolicyDescriptions[0]
      : ''
  }}"
                                      matTooltipClass="custom-tooltip"
                                    >
                                      Out of policy
                                    </div>

                                    <div
                                      class="book_button not-mobile mt-3 mb-3"
                                      *ngIf="
                                        item.lowestSellingPriceRate &&
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConverted;
                                        else ViewHotel
                                      "
                                    >
                                      <a
                                        class="green_btn btn-block px-2"
                                        [routerLink]="[this.hotelNavigateUrl]"
                                        [queryParams]="{
                                          searchQuery: item.searchQuery,
                                          checkIn: queryParams.checkIn,
                                          checkOut: queryParams.checkOut,
                                          paxes: queryParams.paxes,
                                          travelerId: this.travelerId
                                        }"
                                        target="_blank"
                                        i18n="@@chooseRoom"
                                        >Choose Room</a
                                      >
                                    </div>
                                    <ng-template #ViewHotel>
                                      <div
                                        class="book_button not-mobile mt-3 mb-3"
                                      >
                                        <a
                                          class="green_btn btn-block"
                                          [routerLink]="[this.hotelNavigateUrl]"
                                          [queryParams]="{
                                            searchQuery: item.searchQuery,
                                            checkIn: queryParams.checkIn,
                                            checkOut: queryParams.checkOut,
                                            paxes: queryParams.paxes,
                                            travelerId: this.travelerId
                                          }"
                                          target="_blank"
                                          i18n="@@viewHotel"
                                          >View Hotel</a
                                        >
                                      </div>
                                    </ng-template>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="infiniteScroll"
                    infiniteScroll
                    [infiniteScrollDistance]="1"
                    [infiniteScrollThrottle]="500"
                    (scrolled)="onScroll()"
                  >
                    <div *ngFor="let item of currentHotelList; let i = index">
                      <div
                        class="not-mobile hotel-item rounded mb-4"
                        [attr.data-test]="item.rating"
                      >
                        <div class="row">
                          <div class="col-md-5 col-lg-4 hotel_image">
                            <a
                              target="_blank"
                              [routerLink]="[this.hotelNavigateUrl]"
                              [queryParams]="{
                                searchQuery: item.searchQuery,
                                checkIn: queryParams.checkIn,
                                checkOut: queryParams.checkOut,
                                paxes: queryParams.paxes,
                                travelerId: this.travelerId
                              }"
                            >
                              <img
                                *ngIf="
                                  item.images &&
                                  item.images[0] &&
                                  item.images[0].url.includes('https://')
                                "
                                [src]="item.images[0].url"
                                onError="this.src='assets/img/no_image.png'"
                                [ngClass]="{
                                  'rounded-left': Service.isSiteLtr(),
                                  'rounded-right': Service.isSiteRtl()
                                }"
                              />
                              <img
                                *ngIf="
                                  item.images &&
                                  item.images[0] &&
                                  !item.images[0].url.includes('https://')
                                "
                                [src]="
                                  item.images[0].url.replace(
                                    'http://',
                                    'https://'
                                  )
                                "
                                onError="this.src='assets/img/no_image.png'"
                                [ngClass]="{
                                  'rounded-left': Service.isSiteLtr(),
                                  'rounded-right': Service.isSiteRtl()
                                }"
                              />

                              <ng-container
                                *ngIf="
                                  !item.images ||
                                  !item.images[0] ||
                                  !item.images[0].url
                                "
                              >
                                <img
                                  width="279"
                                  height="239"
                                  loading="lazy"
                                  src="assets/img/no_image.png"
                                />
                              </ng-container>
                            </a>
                            <div *ngIf="logedIn.isLogedIn">
                              <span
                                class="position-absolute font-20"
                                *ngIf="
                                  !(
                                    item.additionalData &&
                                    item.additionalData.userData
                                  )
                                "
                                [ngClass]="{
                                  'icon-colored-heart':
                                    defaultFavForNoUserDataHotels[item.code],
                                  'icon-heart':
                                    !defaultFavForNoUserDataHotels[item.code]
                                }"
                                (click)="toggleFavoriteHotelList(item)"
                              >
                                <span class="path1"></span>
                                <span class="path2"></span>
                              </span>
                              <span
                                *ngIf="
                                  item.additionalData &&
                                  item.additionalData.userData
                                "
                              >
                                <span
                                  class="position-absolute font-20"
                                  [ngClass]="{
                                    'icon-colored-heart':
                                      item.additionalData.userData.favorite,
                                    'icon-heart':
                                      !item.additionalData.userData.favorite
                                  }"
                                  (click)="toggleFavoriteHotelList(item)"
                                >
                                  <span class="path1"></span>
                                  <span class="path2"></span>
                                </span>
                              </span>
                            </div>
                            <div *ngIf="!logedIn.isLogedIn">
                              <span
                                class="icon-heart position-absolute font-20"
                                (click)="
                                  this.Service.isLoginTitleFavorite = true;
                                  this.Service.openloginDialogue();
                                  this.Service.addNonLoggedInFavItem = item
                                "
                              >
                                <span class="path1"></span>
                                <span class="path2"></span>
                              </span>
                            </div>
                          </div>
                          <div class="col-md-7 col-lg-8">
                            <div
                              class="pt-4 pb-4 h-100"
                              [ngClass]="{
                                'pr-4': Service.isSiteLtr(),
                                'pl-4': Service.isSiteRtl()
                              }"
                            >
                              <div class="row h-100">
                                <div
                                  class="col-lg-7 col-md-6 d-flex align-items-start flex-column"
                                >
                                  <div class="hotel_info mb-auto">
                                    <div class="teaser_top">
                                      <h2 class="font-16">
                                        <a
                                          [routerLink]="[this.hotelNavigateUrl]"
                                          [queryParams]="{
                                            searchQuery: item.searchQuery,
                                            checkIn: queryParams.checkIn,
                                            checkOut: queryParams.checkOut,
                                            paxes: queryParams.paxes,
                                            travelerId: this.travelerId
                                          }"
                                          target="_blank"
                                          class="primaray-text font-weight-bold"
                                        >
                                          <span
                                            class="night-cont"
                                            *ngIf="
                                              item.name.length < 60;
                                              else sliced
                                            "
                                            >{{ item.name }}</span
                                          >
                                          <ng-template #sliced
                                            >{{ item.name | slice : 0 : 60 }}...
                                          </ng-template>
                                        </a>
                                      </h2>
                                      <div
                                        *ngIf="item.rating >= 1"
                                        class="hotel-stars-text mt-3 mb-3 gray-text-dark font-12 font-weight-bold"
                                      >
                                        {{ item.rating }}-<span
                                          class="night-cont"
                                          i18n="@@stars"
                                          >star</span
                                        >
                                      </div>

                                      <div class="address font-12">
                                        {{ item.address ? item.address : "" }}
                                      </div>
                                    </div>
                                    <div
                                      class="google-review-cont d-inline-block py-3 mt-3 rounded"
                                      *ngIf="item.googleReview"
                                    >
                                      <div
                                        class="text-medium font-weight-bold gray-primaray-text align-middle d-inline-block"
                                      >
                                        {{ item.googleReview.rating }}
                                      </div>
                                      <div
                                        class="rating-stars-google d-inline-block"
                                      >
                                        <div
                                          class="rating-active"
                                          [style.width.%]="
                                            item.googleReview.ratingPercentage *
                                            100
                                          "
                                        ></div>
                                      </div>
                                      <br />

                                      <div
                                        class="text-sm gray-text-dark d-inline-block font-weight-bold separater-dot"
                                      >
                                        {{
                                          item.googleReview.ratingDescription
                                        }}
                                      </div>
                                      <span
                                        class="gray-text text-sm d-inline-block"
                                        ><span
                                          [ngClass]="{
                                            'mr-1': Service.isSiteLtr(),
                                            'ml-1': Service.isSiteRtl()
                                          }"
                                        >
                                          {{
                                            item.googleReview.userRatingsTotal
                                              | number : "" : "en-US"
                                          }}</span
                                        ><ng-container i18n="@@reviews"
                                          >reviews</ng-container
                                        ></span
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-5 cash-back-cont">
                                  <div
                                    *ngIf="
                                      item.lowestSellingPriceRate &&
                                      item.lowestSellingPriceRate
                                        .freeCancellation
                                    "
                                    class="cancelations position-absolute text-right font-15 mb-3"
                                    i18n="@@freeCancellation"
                                  >
                                    Free cancellation
                                  </div>

                                  <div
                                    class="price b position-relative pt-extra"
                                  >
                                    <div
                                      class="current_price clearfix"
                                      [ngClass]="{
                                        'text-right': Service.isSiteLtr(),
                                        'text-left': Service.isSiteRtl(),
                                        'prime-color-purple':
                                          logedIn.isLead &&
                                          item.lowestSellingPriceRate
                                            .privateAverageNightlySellingPrice &&
                                          !this.Service.isCompany()
                                      }"
                                      *ngIf="item.lowestSellingPriceRate"
                                    >
                                      <del
                                        class="old-price font-20 line d-inline-block"
                                        [ngClass]="{
                                          'mr-2': Service.isSiteLtr(),
                                          'ml-2': Service.isSiteRtl()
                                        }"
                                        *ngIf="
                                          !this.Service.isCompany() &&
                                          logedIn.isLead &&
                                          item.lowestSellingPriceRate &&
                                          item.lowestSellingPriceRate
                                            .publicAverageNightlySellingPrice &&
                                          item.lowestSellingPriceRate
                                            .privateAverageNightlySellingPrice
                                        "
                                      >
                                        <span>{{
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConvertedCurrency
                                        }}</span
                                        ><span>
                                          {{
                                            item.lowestSellingPriceRate
                                              .publicAverageNightlySellingPrice
                                              | number : "1.0-0"
                                          }}
                                        </span>
                                      </del>

                                      <ng-container
                                        *ngIf="
                                          logedIn.isLead &&
                                          item.lowestSellingPriceRate
                                            .privateAverageNightlySellingPrice &&
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConverted
                                        "
                                      >
                                        <span class="amount-currency">{{
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConvertedCurrency
                                        }}</span>
                                        <span class="amount-currency">
                                          {{
                                            item.lowestSellingPriceRate
                                              .averageNightlySellingPriceConverted
                                              | number : "1.0-0"
                                          }}
                                        </span>

                                        <span
                                          class="nightWord"
                                          i18n="@@slashNight"
                                          >/night</span
                                        >
                                      </ng-container>
                                      <ng-container
                                        *ngIf="
                                          !logedIn.isLead &&
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConverted
                                        "
                                      >
                                        <span class="amount-currency">{{
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConvertedCurrency
                                        }}</span>
                                        <span class="amount-currency">
                                          {{
                                            item.lowestSellingPriceRate
                                              .averageNightlySellingPriceConverted
                                              | number : "1.0-0"
                                          }}
                                        </span>
                                        <span
                                          class="nightWord"
                                          i18n="@@slashNight"
                                          >/night</span
                                        >
                                      </ng-container>

                                      <ng-container
                                        *ngIf="
                                          logedIn.isLead &&
                                          item.lowestSellingPriceRate
                                            .privateAverageNightlySellingPrice &&
                                          !item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConverted
                                        "
                                      >
                                        <span class="amount-currency">{{
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConvertedCurrency
                                        }}</span>
                                        <span class="amount-currency">
                                          {{
                                            item.lowestSellingPriceRate
                                              .averageNightlySellingPriceConverted
                                              | number : "1.0-0"
                                          }}
                                        </span>
                                        <span
                                          class="nightWord"
                                          i18n="@@slashNight"
                                          >/night</span
                                        >
                                      </ng-container>

                                      <ng-container
                                        *ngIf="
                                          logedIn.isLead &&
                                          !item.lowestSellingPriceRate
                                            .privateAverageNightlySellingPrice &&
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConverted
                                        "
                                      >
                                        <span class="amount-currency">{{
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConvertedCurrency
                                        }}</span>
                                        <span class="amount-currency">
                                          {{
                                            item.lowestSellingPriceRate
                                              .averageNightlySellingPriceConverted
                                              | number : "1.0-0"
                                          }}
                                        </span>
                                        <span
                                          class="nightWord"
                                          i18n="@@slashNight"
                                          >/night</span
                                        >
                                      </ng-container>
                                    </div>

                                    <div
                                      class="tax mt-2"
                                      [ngClass]="{
                                        'text-right': Service.isSiteLtr(),
                                        'text-left': Service.isSiteRtl(),
                                        'no-margin-bottom':
                                          Service.isCompany() &&
                                          item.lowestSellingPriceRate &&
                                          item.lowestSellingPriceRate
                                            .userData &&
                                          item.lowestSellingPriceRate.userData
                                            .exceedsPolicyLimit
                                      }"
                                      *ngIf="
                                        item.lowestSellingPriceRate &&
                                        item.lowestSellingPriceRate
                                          .taxIncluded &&
                                        (item.lowestSellingPriceRate
                                          .averageNightlySellingPriceConverted ||
                                          logedIn.isLead)
                                      "
                                      i18n="@@taxAndFeesIncluded"
                                    >
                                      Tax and fees included
                                    </div>
                                    <div
                                      class="text-danger"
                                      [ngClass]="{
                                        'text-right': Service.isSiteLtr(),
                                        'text-left': Service.isSiteRtl()
                                      }"
                                      *ngIf="
                                        (item.lowestSellingPriceRate &&
                                          item.lowestSellingPriceRate
                                            .userData &&
                                          item.lowestSellingPriceRate.userData
                                            .exceedsPolicyLimit) ||
                                        (policyDetails &&
                                          policyDetails.hotelBookingNotAllowed ===
                                            true)
                                      "
                                    >

                                     <span
                                             i18n="@@outOfPolicy"
                                             matTooltip="{{
    item.lowestSellingPriceRate.userData.outOfPolicyDescriptions
      ? item.lowestSellingPriceRate.userData.outOfPolicyDescriptions[0]
      : ''
  }}"
                                             matTooltipClass="custom-tooltip"
                                     >
                                   <!--   <span
                                        i18n="@@outOfPolicy"
                                        title="{{
                                          item.lowestSellingPriceRate.userData
                                            .outOfPolicyDescriptions
                                            ? item.lowestSellingPriceRate
                                                .userData
                                                .outOfPolicyDescriptions[0]
                                            : ''
                                        }}"
                                      >-->
                                        Out of policy
                                      </span>
                                    </div>
                                    <div
                                      class="primaray-text text-right mb-3"
                                      *ngIf="
                                        !logedIn.isLead &&
                                        item.lowestSellingPriceRate &&
                                        !item.lowestSellingPriceRate
                                          .averageNightlySellingPriceConverted
                                      "
                                    >
                                      <span class="font-weight-bold font-18"
                                        >Available only for Pass members
                                      </span>

                                      <br />
                                    </div>

                                    <div
                                      class="book_button not-mobile mt-3 mb-3"
                                      *ngIf="
                                        item.lowestSellingPriceRate &&
                                          item.lowestSellingPriceRate
                                            .averageNightlySellingPriceConverted;
                                        else ViewHotel
                                      "
                                    >
                                      <a
                                        class="green_btn btn-block px-2"
                                        [routerLink]="[this.hotelNavigateUrl]"
                                        [queryParams]="{
                                          searchQuery: item.searchQuery,
                                          checkIn: queryParams.checkIn,
                                          checkOut: queryParams.checkOut,
                                          paxes: queryParams.paxes,
                                          travelerId: this.travelerId
                                        }"
                                        target="_blank"
                                        i18n="@@chooseRoom"
                                        >Choose Room</a
                                      >
                                    </div>
                                    <ng-template #ViewHotel>
                                      <div
                                        class="book_button not-mobile mt-3 mb-3"
                                      >
                                        <a
                                          class="green_btn btn-block"
                                          [routerLink]="[this.hotelNavigateUrl]"
                                          [queryParams]="{
                                            searchQuery: item.searchQuery,
                                            checkIn: queryParams.checkIn,
                                            checkOut: queryParams.checkOut,
                                            paxes: queryParams.paxes,
                                            travelerId: this.travelerId
                                          }"
                                          target="_blank"
                                          i18n="@@viewHotel"
                                          >View Hotel</a
                                        >
                                      </div>
                                    </ng-template>
                                    <div
                                      *ngIf="
                                        logedIn.isLead &&
                                        item.lowestSellingPriceRate &&
                                        !item.lowestSellingPriceRate.savings &&
                                        item.lowestSellingPriceRate
                                          .privateAverageNightlySellingPrice
                                      "
                                      class="prime-color font-weight-bold font-14 mb-3 mt-2"
                                      [ngClass]="{
                                        'text-right': Service.isSiteLtr(),
                                        'text-left': Service.isSiteRtl()
                                      }"
                                    >
                                      EXCLUSIVE PRICE
                                    </div>

                                    <div
                                      class="position-relative hidden-rate"
                                      *ngIf="
                                        !this.logedIn.isLead &&
                                        item.lowestSellingPriceRate
                                          .privateAverageNightlySellingPrice
                                      "
                                    >
                                      <div
                                        class="prime-color-purple text-right mt-2 font-15 clickable-cursor"
                                        (click)="showPrivateDescFunClick()"
                                      >
                                        <!--  Hidden Rate-->
                                        <br />

                                        <div
                                          class="prime-bg-purple text-white font-12 d-inline-block rounded px-4 py-2"
                                        >
                                          View Pass Rates
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    *ngIf="
                                      item.lowestSellingPriceRate &&
                                      item.lowestSellingPriceRate.userData &&
                                      item.lowestSellingPriceRate.userData
                                        .rewardAmount
                                    "
                                    style="min-height: 33px"
                                  ></div>
                                </div>
                              </div>
                              <div
                                class="reward-promo d-flex position-absolute"
                                *ngIf="
                                  item.lowestSellingPriceRate &&
                                  item.lowestSellingPriceRate.userData &&
                                  item.lowestSellingPriceRate.userData
                                    .rewardAmount
                                "
                              >
                                <div class="promo-triangle"></div>
                                <div
                                  class="promo-text text-white font-12 px-4 pt-2"
                                  i18n="@@earnInRewards"
                                >
                                  Earn ${{
                                    item.lowestSellingPriceRate.userData
                                      .rewardAmount | number : "1.0-0"
                                  }}
                                  in Rewards
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="reward-box border py-4 mb-4 position-relative pl-4 pr-4"
                        [ngClass]="{
                          'pr-lg-5 rounded-right': Service.isSiteLtr(),
                          'pl-lg-5 rounded-left': Service.isSiteRtl()
                        }"
                        *ngIf="
                          i === 0 &&
                          companyPolicyHotelRules &&
                          companyPolicyHotelRules.avgNightlyPriceLimit &&
                          logedIn.companyInfo.rewardProgram &&
                          logedIn.companyInfo.rewardProgram
                            .hotelSavingsPercentage &&
                          showRewardsBox &&
                          policyDetails.hotelBookingNotAllowed === false
                        "
                      >
                        <i
                          class="icon-close-light position-absolute p-2 pointer"
                          (click)="showRewardsBox = false"
                        ></i>
                        <p
                          class="font-18 bold-600 gray-primaray-text"
                          i18n="@@earnRewards"
                        >
                          Earn Rewards
                        </p>
                        <p class="gray-text-dark" i18n="@@getDollarRewards">
                          Get dollar rewards when you book a hotel below your
                          company travel policy. For every dollar you save, you
                          get
                          {{
                            logedIn.companyInfo.rewardProgram
                              .hotelSavingsPercentage * 100
                          }}% as rewards.
                        </p>
                        <p class="gray-primaray-text mb-0">
                          <ng-container i18n="@@travelPolicy"
                            >Travel Policy</ng-container
                          >:&nbsp;<ng-container *ngIf="Service.isSiteLtr() && !this.exceptionCurrency">{{
                            policyCurrency
                          }}</ng-container
                          ><ng-container *ngIf="!this.exceptionValue">
                            {{
                              companyPolicyHotelRules.avgNightlyPriceLimit
                            }} </ng-container
                          >
                          <ng-container *ngIf="Service.isSiteRtl() && !this.exceptionCurrency">{{
                            policyCurrency
                          }}</ng-container
                          >
                          <ng-container *ngIf="Service.isSiteLtr() && this.exceptionCurrency">{{
                            exceptionCurrency
                          }}</ng-container
                          ><ng-container *ngIf="this.exceptionValue">
                            {{ exceptionValue }} </ng-container
                          ><ng-container *ngIf="Service.isSiteRtl() && this.exceptionCurrency">{{
                            exceptionCurrency
                          }}</ng-container
                          ><ng-container i18n="@@slashNight"
                            >/night</ng-container
                          >
                        </p>
                      </div>
                      <div
                        class="text-center"
                        *ngIf="
                          currentHotelList && currentHotelList.length === 0
                        "
                      >
                        <div class="centered with_padding">
                          <div class="">
                            <i
                              class="icon-filter font-22 mb-4 primaray-text"
                            ></i>
                          </div>
                          <div class="font-18" i18n="@@weAreSorrySearch">
                            We are sorry, we don't have any hotel that is
                            matching your filters
                          </div>
                          <div class="font-18" i18n="@@PleaseClearFliter">
                            Please tune your filters or clear them
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="
                    !errorData && filteringActive && filterreditems.length === 0
                  "
                >
                  <div class="container custom_container text-center">
                    <h2
                      class="primaray-text font-weight-bold"
                      i18n="@@noSearchCriteria"
                    >
                      No results match your filter criteria. Please modify your
                      filter and try again
                    </h2>
                    <button
                      class="font-20 mt-3 font-weight-normal btn blue-text karla-font"
                      (click)="clearFilters()"
                      i18n="@@clearAllFilters"
                    >
                      Clear all filter
                    </button>
                    <br />

                    <img
                      width="515"
                      height="368"
                      loading="lazy"
                      class="d-inline-block initial-height"
                      src="assets/img/wait.png"
                      alt="wait"
                    />
                  </div>
                </div>
              </div>
              <div
                class="col-md-9"
                *ngIf="
                  (errorData || (hotelList && hotelList.length === 0)) &&
                  !oneitemlistMode
                "
              >
                <div class="container custom_container text-center">
                  <h2
                    class="primaray-text font-weight-bold"
                    i18n="@@noSearchCriteria1"
                  >
                    No results match your search criteria. Please modify your
                    search and try again
                  </h2>

                  <img
                    width="515"
                    height="368"
                    loading="lazy"
                    class="d-inline-block initial-height"
                    src="assets/img/wait.png"
                    alt="wait"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="this.Service.mobileView && listView">
          <div class="container mb-5" *ngIf="!errorData">
            <div
              class="policy-details position-relative mb-4 py-3 px-4 company-section-with-thick-border border"
              [ngClass]="{
                'rounded-right': Service.isSiteLtr(),
                'rounded-left': Service.isSiteRtl()
              }"
              *ngIf="policyDetails && showPolicyDetails"
            >
              <i
                class="icon-close-light gray-text font-12 pointer position-absolute"
                [ngClass]="{
                  'mr-4 ml-auto': Service.isSiteLtr(),
                  'ml-4 mr-auto': Service.isSiteRtl()
                }"
                (click)="showPolicyDetails = false"
              ></i>
              <p class="font-18 bold-600" i18n="@@travelPolicy">
                Travel Policy
              </p>
              <p
                *ngIf="policyDetails.hotelBookingNotAllowed === true"
                i18n="@@approvalAlwaysRequiredHotel"
              >
                Approval is required for any hotel booking.
              </p>
              <div
                *ngIf="
                  !policyDetails.hotelBookingNotAllowed ||
                  policyDetails.hotelBookingNotAllowed === false
                "
              >
                <p
                  [ngClass]="{
                    'mr-5': Service.isSiteLtr(),
                    'ml-5': Service.isSiteRtl()
                  }"
                >
                  <span
                    class="gray-primaray-text"
                    i18n="@@nightlyPriceLimitDetails"
                    >Nightly price limit:
                  </span>
                  <ng-container
                    *ngIf="
                      policyDetails.companyPolicyHotelRules
                        .avgNightlyPriceLimit && hasException
                    "
                  >
                    <span class="gray-text">
                      <ng-container *ngIf="Service.isSiteLtr()">{{
                        exceptionCurrency
                      }}</ng-container>
                      {{ exceptionValue | number : "1.0-0" }}
                      <ng-container *ngIf="Service.isSiteRtl()">{{
                        exceptionCurrency
                      }}</ng-container></span
                    >
                  </ng-container>
                  <ng-container
                    *ngIf="
                      policyDetails.companyPolicyHotelRules
                        .avgNightlyPriceLimit && !hasException
                    "
                  >
                    <span class="gray-text"
                      ><ng-container *ngIf="Service.isSiteLtr()"
                        >{{ policyCurrency }} </ng-container
                      >{{
                        policyDetails.companyPolicyHotelRules
                          .avgNightlyPriceLimit | number : "1.0-0"
                      }}
                      <ng-container *ngIf="Service.isSiteRtl()">
                        {{ policyCurrency }}</ng-container
                      ></span
                    >
                  </ng-container>
                  <span
                    class="gray-text"
                    *ngIf="
                      !policyDetails.companyPolicyHotelRules
                        .avgNightlyPriceLimit && !hasException
                    "
                  >
                    -
                  </span>
                </p>
                <p>
                  <span class="gray-primaray-text" i18n="@@ApprovalRequiredOn"
                    >Approval required on:
                  </span>
                  <span
                    class="gray-text"
                    *ngIf="
                      policyDetails.approvalProcessType ===
                      'NO_APPROVAL_REQUIRED'
                    "
                    i18n="@@noApproval"
                    >No approval</span
                  >
                  <span
                    class="gray-text"
                    *ngIf="
                      policyDetails.approvalProcessType ===
                      'RULES_VIOLATION_APPROVAL_REQUIRED'
                    "
                    i18n="@@outOfPolicy"
                    >Out of policy</span
                  >
                  <span
                    class="gray-text"
                    *ngIf="
                      policyDetails.approvalProcessType ===
                      'ALWAYS_APPROVAL_REQUIRED'
                    "
                    i18n="@@allTrips"
                    >All trips</span
                  >
                </p>
              </div>
            </div>
            <div
              class="sort-filter-header"
              [ngClass]="{ active: activeScroll }"
            >
              <div
                class="d-flex pb-3 mb-3 border-bottom sort-header"
                [ngClass]="{ active: sortHeader }"
              >
                <div
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                >
                  <button
                    class="btn-link font-16 font-weight-bold"
                    (click)="this.Service.showFooter = false; mapViewtoggle()"
                    [ngClass]="{ disabled: showViewMapMobile }"
                  >
                    <span i18n="@@map">Map</span>&nbsp;<i
                      class="icon-locator"
                    ></i>
                  </button>
                </div>
                <div>
                  <button
                    class="btn-link font-16 font-weight-bold p-2 rounded sort-btn"
                    [ngClass]="{
                      active: activeSort,
                      'mr-4': Service.isSiteLtr(),
                      'ml-4': Service.isSiteRtl()
                    }"
                    (click)="sortPopUp = true; this.Service.disableBodyScroll()"
                  >
                    <span i18n="@@sort">Sort</span>&nbsp;<i
                      class="icon-sort"
                    ></i>
                  </button>

                  <button
                    class="btn-link font-16 font-weight-bold"
                    (click)="
                      activePopUp = true;
                      filterPopUp = true;
                      this.Service.disableBodyScroll()
                    "
                  >
                    <span i18n="@@filter">Filter</span>&nbsp;<i
                      class="icon-filter"
                    ></i>
                  </button>
                </div>
              </div>
              <div #filterMenu>
                <div *ngIf="filteringActive" class="mb-3 filters-slider">
                  <swiper [config]="config">
                    <span
                      class="selected-star-cont filterd-stars item"
                      *ngIf="starArry.length > 0"
                      (click)="ratingArrayRemove()"
                    >
                      <span
                        class="d-inline-block selected-star"
                        *ngFor="
                          let item of ratingArray | RatingDisplay : true;
                          let i = index
                        "
                      >
                        <span class="">,&nbsp;</span>{{ item.id }}
                      </span>
                      <span *ngIf="starArry.length > 0"
                        >-<ng-container i18n="@@stars"
                          >stars</ng-container
                        ></span
                      >
                      <i class="icon-error-msg"></i>
                    </span>

                    <span
                      class="item"
                      *ngFor="
                        let item of Amenities | RatingDisplay : true;
                        let i = index
                      "
                      (click)="AmenitiesRemove(item.hrsCode)"
                    >
                      {{ item.title }}

                      <i class="icon-error-msg"></i>
                    </span>

                    <span
                      (click)="freeCancellationRemove()"
                      class="item"
                      *ngIf="filterFreeCancellation"
                      ><ng-container i18n="@@freeCancellation"
                        >Free cancellation</ng-container
                      >&nbsp;<i class="icon-error-msg"></i
                    ></span>
                    <span
                      (click)="withPolicyFilterRemove()"
                      class="item"
                      *ngIf="Service.isCompany() && filterWithinPolicy"
                      ><ng-container i18n="@@withinPolicy"
                        >Within Policy</ng-container
                      >&nbsp;<i class="icon-error-msg"></i
                    ></span>

                    <span
                      class="item"
                      *ngIf="PriceArray.length"
                      (click)="PriceRemove()"
                    >
                      {{ currency }} {{ minValue }} - {{ currency }}
                      {{ maxValue }}
                      <i class="icon-error-msg"></i>
                    </span>

                    <span
                      class="item"
                      *ngIf="ReviewValue > 0"
                      (click)="ReviewValueRemove()"
                    >
                      {{ ReviewValue }}<span *ngIf="isInteger">.0</span>+
                      <span i18n="@@guestRating">guest rating</span>
                      <i class="icon-error-msg"></i>
                    </span>
                  </swiper>
                </div>
              </div>
            </div>

            <app-loaders></app-loaders>

            <div *ngIf="oneitemlistMode">
              <div
                [ngClass]="{ 'not-available': !ActiveOneItem }"
                class="oneitemlist-border rounded mb-4"
                *ngFor="let item of hoteloneItem; let i = index"
              >
                <div class="oneitemlist-header rounded-top p-2">
                  <span class="font-18">
                    <i
                      [ngClass]="
                        ActiveOneItem ? 'icon-success' : 'icon-warning'
                      "
                      class=""
                    ></i>
                    {{ item.name }}
                    <span *ngIf="!ActiveOneItem; else availableHotel">
                      is not available for your travel dates –
                      <span
                        (click)="openSearch()"
                        class="text-underline clickable-cursor"
                        >Try new dates!</span
                      ><ng-container i18n="@@noAvailableDates"
                        >is not available for your travel dates</ng-container
                      >&nbsp;–&nbsp;<span
                        (click)="openSearch()"
                        class="text-underline clickable-cursor"
                        ><ng-container i18n="@@tryNewDates"
                          >Try new dates!</ng-container
                        ></span
                      >
                    </span>
                    <ng-template #availableHotel i18n="@@isAvailableBookNow">
                      is available – Book Now!
                    </ng-template>
                  </span>
                </div>
                <div class="hotel-item-mobile-holder mb-4">
                  <div class="item-promo-hotel">
                    <div
                      class="position-relative rounded-top overflow-hidden image-holder"
                    >
                      <a
                        target="_blank"
                        [routerLink]="[this.hotelNavigateUrl]"
                        [queryParams]="{
                          searchQuery: item.searchQuery,
                          checkIn: queryParams.checkIn,
                          checkOut: queryParams.checkOut,
                          paxes: queryParams.paxes,
                          travelerId: this.travelerId
                        }"
                      >
                        <div
                          class="sign rounded-top-left"
                          *ngIf="item.exclusiveDeal"
                        >
                          <div class="inner" i18n="@@specialDeal">
                            Special Deal
                          </div>
                        </div>

                        <img
                          *ngIf="
                            item.images &&
                            item.images[0] &&
                            item.images[0].url.includes('https://')
                          "
                          [src]="item.images[0].url"
                          onError="this.src='assets/img/no_image.png'"
                          [ngClass]="{
                            'rounded-left': Service.isSiteLtr(),
                            'rounded-right': Service.isSiteRtl()
                          }"
                        />
                        <img
                          *ngIf="
                            item.images &&
                            item.images[0] &&
                            !item.images[0].url.includes('https://')
                          "
                          [src]="
                            item.images[0].url.replace('http://', 'https://')
                          "
                          onError="this.src='assets/img/no_image.png'"
                          [ngClass]="{
                            'rounded-left': Service.isSiteLtr(),
                            'rounded-right': Service.isSiteRtl()
                          }"
                        />

                        <ng-template
                          *ngIf="
                            !item.images ||
                            !item.images[0] ||
                            !item.images[0].url
                          "
                        >
                          <img
                            width="279"
                            height="239"
                            loading="lazy"
                            src="assets/img/no_image.png"
                          />
                        </ng-template>
                        <div
                          class="google-review-cont d-inline-block p-3 mt-3"
                          *ngIf="item.googleReview"
                        >
                          <div
                            class="text-medium font-weight-bold text-white align-middle d-inline-block"
                          >
                            {{ item.googleReview.rating }}
                          </div>
                          <div class="rating-stars-google d-inline-block">
                            <div
                              class="rating-active"
                              [style.width.%]="
                                item.googleReview.ratingPercentage * 100
                              "
                            ></div>
                          </div>
                          <span class="text-white text-sm d-inline-block"
                            ><span
                              [ngClass]="{
                                'mr-1': Service.isSiteLtr(),
                                'ml-1': Service.isSiteRtl()
                              }"
                            >
                              ({{
                                item.googleReview.userRatingsTotal
                                  | number : "" : "en-US"
                              }}</span
                            ><ng-container i18n="@@reviews"
                              >reviews</ng-container
                            >)</span
                          >
                        </div>
                      </a>
                      <div *ngIf="logedIn.isLogedIn">
                        <span
                          class="position-absolute font-20"
                          *ngIf="!item.additionalData"
                          [ngClass]="{
                            'icon-colored-heart':
                              defaultFavForNoUserDataHotels[i],
                            'icon-heart': !defaultFavForNoUserDataHotels[i]
                          }"
                          (click)="
                            toggleFavoriteHotelList(item);
                            defaultFavForNoUserDataHotels[i] =
                              !defaultFavForNoUserDataHotels[i]
                          "
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                        </span>
                        <span
                          *ngIf="
                            item.additionalData && item.additionalData.userData
                          "
                        >
                          <span
                            class="position-absolute font-20"
                            [ngClass]="{
                              'icon-colored-heart':
                                item.additionalData.userData.favorite,
                              'icon-heart':
                                !item.additionalData.userData.favorite
                            }"
                            (click)="toggleFavoriteHotelList(item)"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                        </span>
                      </div>
                      <div *ngIf="!logedIn.isLogedIn">
                        <span
                          class="icon-heart position-absolute font-20"
                          (click)="
                            this.Service.isLoginTitleFavorite = true;
                            this.Service.openloginDialogue();
                            this.Service.addNonLoggedInFavItem = item
                          "
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                        </span>
                      </div>
                    </div>

                    <div
                      class="p-3 border-bottom border-right border-left rounded-bottom"
                    >
                      <div class="pb-4 mb-1">
                        <h4
                          class="d-inline font-16 font-weight-bold mb-0 align-middle"
                        >
                          <a
                            class="primaray-text"
                            target="_blank"
                            [routerLink]="[this.hotelNavigateUrl]"
                            [queryParams]="{
                              searchQuery: item.searchQuery,
                              checkIn: queryParams.checkIn,
                              checkOut: queryParams.checkOut,
                              paxes: queryParams.paxes,
                              travelerId: this.travelerId
                            }"
                            >{{ item.name }}</a
                          >
                        </h4>
                        <div
                          *ngIf="item.rating > 0"
                          class="hotel-stars-text align-middle text-sm gray-text-dark d-inline-block"
                          [ngClass]="{
                            'ml-3 pl-3': Service.isSiteLtr(),
                            'mr-3 pr-3': Service.isSiteRtl()
                          }"
                        >
                          {{ item.rating }}-
                          <span class="night-cont" i18n="@@stars">star</span>
                        </div>
                      </div>
                      <a
                        target="_blank"
                        [routerLink]="[this.hotelNavigateUrl]"
                        [queryParams]="{
                          searchQuery: item.searchQuery,
                          checkIn: queryParams.checkIn,
                          checkOut: queryParams.checkOut,
                          paxes: queryParams.paxes,
                          travelerId: this.travelerId
                        }"
                        class="text-decoration-none"
                      >
                        <div class="row">
                          <div class="col">
                            <div
                              *ngIf="
                                item.lowestSellingPriceRate &&
                                item.lowestSellingPriceRate.freeCancellation
                              "
                              class="cancelations font-14 w-100"
                              i18n="@@freeCancellation"
                            >
                              Free cancellation
                            </div>
                          </div>
                        </div>
                        <div
                          [ngClass]="{
                            'text-right': Service.isSiteLtr(),
                            'text-left': Service.isSiteRtl()
                          }"
                        >
                          <del
                            class="old-price font-20 line d-inline-block"
                            [ngClass]="{
                              'mr-2': Service.isSiteLtr(),
                              'ml-2': Service.isSiteRtl()
                            }"
                            *ngIf="
                              !this.Service.isCompany() &&
                              logedIn.isLead &&
                              item.lowestSellingPriceRate &&
                              item.lowestSellingPriceRate
                                .publicAverageNightlySellingPrice &&
                              item.lowestSellingPriceRate
                                .privateAverageNightlySellingPrice
                            "
                          >
                            <span>{{
                              item.lowestSellingPriceRate
                                .averageNightlySellingPriceConvertedCurrency
                            }}</span
                            ><span>
                              {{
                                item.lowestSellingPriceRate
                                  .publicAverageNightlySellingPrice
                                  | number : "1.0-0"
                              }}
                            </span>

                            <br />
                          </del>
                          <div
                            class="current_price primaray-text d-inline-block clearfix"
                            [ngClass]="{
                              'mr-2 text-right': Service.isSiteLtr(),
                              'ml-2 text-left': Service.isSiteRtl()
                            }"
                            [ngClass]="{
                              'prime-color-purple':
                                logedIn.isLead &&
                                item.lowestSellingPriceRate
                                  .privateAverageNightlySellingPrice &&
                                !this.Service.isCompany()
                            }"
                            *ngIf="item.lowestSellingPriceRate"
                          >
                            <ng-container
                              *ngIf="
                                logedIn.isLead &&
                                item.lowestSellingPriceRate
                                  .privateAverageNightlySellingPrice &&
                                item.lowestSellingPriceRate
                                  .averageNightlySellingPriceConverted
                              "
                            >
                              <span
                                class="amount-currency font-weight-boldy font-30"
                                >{{
                                  item.lowestSellingPriceRate
                                    .averageNightlySellingPriceConvertedCurrency
                                }}</span
                              >
                              <span
                                class="amount-currency font-weight-bold font-30"
                              >
                                {{
                                  item.lowestSellingPriceRate
                                    .averageNightlySellingPriceConverted
                                    | number : "1.0-0"
                                }}
                              </span>

                              <span
                                class="nightWord font-18"
                                i18n="@@slashNight"
                                >/night</span
                              >
                            </ng-container>
                            <ng-container
                              *ngIf="
                                !logedIn.isLead &&
                                item.lowestSellingPriceRate
                                  .averageNightlySellingPriceConverted
                              "
                            >
                              <span
                                class="amount-currency font-weight-bold font-30"
                                >{{
                                  item.lowestSellingPriceRate
                                    .averageNightlySellingPriceConvertedCurrency
                                }}</span
                              >
                              <span
                                class="amount-currency font-weight-bold font-30"
                              >
                                {{
                                  item.lowestSellingPriceRate
                                    .averageNightlySellingPriceConverted
                                    | number : "1.0-0"
                                }}
                              </span>
                              <span
                                class="nightWord font-18"
                                i18n="@@slashNight"
                                >/night</span
                              >
                            </ng-container>

                            <ng-container
                              *ngIf="
                                logedIn.isLead &&
                                item.lowestSellingPriceRate &&
                                item.lowestSellingPriceRate
                                  .privateAverageNightlySellingPrice &&
                                !item.lowestSellingPriceRate
                                  .averageNightlySellingPriceConverted
                              "
                            >
                              <span
                                class="amount-currency font-weight-bold font-30"
                                >{{
                                  item.lowestSellingPriceRate
                                    .averageNightlySellingPriceConvertedCurrency
                                }}</span
                              >
                              <span
                                class="amount-currency font-weight-bold font-30"
                              >
                                {{
                                  item.lowestSellingPriceRate
                                    .averageNightlySellingPriceConverted
                                    | number : "1.0-0"
                                }}
                              </span>
                              <span
                                class="nightWord font-18"
                                i18n="@@slashNight"
                                >/night</span
                              >
                            </ng-container>
                            <ng-container
                              *ngIf="
                                logedIn.isLead &&
                                !item.lowestSellingPriceRate
                                  .privateAverageNightlySellingPrice &&
                                item.lowestSellingPriceRate
                                  .averageNightlySellingPriceConverted
                              "
                            >
                              <span
                                class="amount-currency font-weight-bold font-30"
                                >{{
                                  item.lowestSellingPriceRate
                                    .averageNightlySellingPriceConvertedCurrency
                                }}</span
                              >
                              <span
                                class="amount-currency font-weight-bold font-30"
                              >
                                {{
                                  item.lowestSellingPriceRate
                                    .averageNightlySellingPriceConverted
                                    | number : "1.0-0"
                                }}
                              </span>
                              <span
                                class="nightWord font-18"
                                i18n="@@slashNight"
                                >/night</span
                              >
                            </ng-container>
                          </div>
                          <div
                            class="tax mt-2"
                            [ngClass]="{
                              'text-right': Service.isSiteLtr(),
                              'text-left': Service.isSiteRtl(),
                              'no-margin-bottom':
                                Service.isCompany() &&
                                item.lowestSellingPriceRate &&
                                item.lowestSellingPriceRate.userData &&
                                item.lowestSellingPriceRate.userData
                                  .exceedsPolicyLimit
                            }"
                            *ngIf="
                              item.lowestSellingPriceRate &&
                              item.lowestSellingPriceRate.taxIncluded &&
                              (item.lowestSellingPriceRate
                                .averageNightlySellingPriceConverted ||
                                logedIn.isLead)
                            "
                            i18n="@@taxAndFeesIncluded"
                          >
                            Tax and fees included
                          </div>
                          <div
                            class="text-danger"
                            [ngClass]="{
                              'text-right': Service.isSiteLtr(),
                              'text-left': Service.isSiteRtl()
                            }"
                            *ngIf="
                              (item.lowestSellingPriceRate &&
                                item.lowestSellingPriceRate.userData &&
                                item.lowestSellingPriceRate.userData
                                  .exceedsPolicyLimit) ||
                              (policyDetails &&
                                policyDetails.hotelBookingNotAllowed === true)
                            "
                            i18n="@@outOfPolicy"
                          >
                            Out of policy
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="hotelListMobile">
              <div
                infiniteScroll
                [infiniteScrollDistance]="1"
                [infiniteScrollThrottle]="500"
                (scrolled)="onScroll()"
              >
                <div *ngFor="let item of currentHotelList; let i = index">
                  <!--mobile-->
                  <div class="hotel-item-mobile-holder mb-4">
                    <div class="item-promo-hotel">
                      <div
                        class="position-relative rounded-top overflow-hidden image-holder"
                      >
                        <a
                          target="_blank"
                          [routerLink]="[this.hotelNavigateUrl]"
                          [queryParams]="{
                            searchQuery: item.searchQuery,
                            checkIn: queryParams.checkIn,
                            checkOut: queryParams.checkOut,
                            paxes: queryParams.paxes,
                            travelerId: this.travelerId
                          }"
                        >
                          <div
                            class="sign rounded-top-left"
                            *ngIf="item.exclusiveDeal"
                          >
                            <div class="inner" i18n="@@specialDeal">
                              Special Deal
                            </div>
                          </div>
                          <img
                            *ngIf="
                              item.images &&
                              item.images[0] &&
                              item.images[0].url.includes('https://')
                            "
                            [src]="item.images[0].url"
                            onError="this.src='assets/img/no_image.png'"
                            [ngClass]="{
                              'rounded-left': Service.isSiteLtr(),
                              'rounded-right': Service.isSiteRtl()
                            }"
                          />
                          <img
                            *ngIf="
                              item.images &&
                              item.images[0] &&
                              !item.images[0].url.includes('https://')
                            "
                            [src]="
                              item.images[0].url.replace('http://', 'https://')
                            "
                            onError="this.src='assets/img/no_image.png'"
                            [ngClass]="{
                              'rounded-left': Service.isSiteLtr(),
                              'rounded-right': Service.isSiteRtl()
                            }"
                          />

                          <ng-template
                            *ngIf="
                              !item.images ||
                              !item.images[0] ||
                              !item.images[0].url
                            "
                          >
                            <img
                              width="279"
                              height="239"
                              loading="lazy"
                              src="assets/img/no_image.png"
                            />
                          </ng-template>

                          <div
                            class="google-review-cont d-inline-block p-3 mt-3"
                            *ngIf="item.googleReview"
                          >
                            <div
                              class="text-medium font-weight-bold text-white align-middle d-inline-block"
                            >
                              {{ item.googleReview.rating }}
                            </div>
                            <div class="rating-stars-google d-inline-block">
                              <div
                                class="rating-active"
                                [style.width.%]="
                                  item.googleReview.ratingPercentage * 100
                                "
                              ></div>
                            </div>
                            <span class="text-white text-sm d-inline-block"
                              ><span
                                [ngClass]="{
                                  'mr-1': Service.isSiteLtr(),
                                  'ml-1': Service.isSiteRtl()
                                }"
                              >
                                ({{
                                  item.googleReview.userRatingsTotal
                                    | number : "" : "en-US"
                                }}</span
                              ><ng-container i18n="@@reviews"
                                >reviews</ng-container
                              >)</span
                            >
                          </div>
                        </a>
                        <div *ngIf="logedIn.isLogedIn">
                          <span
                            class="position-absolute font-20"
                            *ngIf="!item.additionalData"
                            [ngClass]="{
                              'icon-colored-heart':
                                defaultFavForNoUserDataHotels[i],
                              'icon-heart': !defaultFavForNoUserDataHotels[i]
                            }"
                            (click)="
                              toggleFavoriteHotelList(item);
                              defaultFavForNoUserDataHotels[i] =
                                !defaultFavForNoUserDataHotels[i]
                            "
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                          <span
                            *ngIf="
                              item.additionalData &&
                              item.additionalData.userData
                            "
                          >
                            <span
                              class="position-absolute font-20"
                              [ngClass]="{
                                'icon-colored-heart':
                                  item.additionalData.userData.favorite,
                                'icon-heart':
                                  !item.additionalData.userData.favorite
                              }"
                              (click)="toggleFavoriteHotelList(item)"
                            >
                              <span class="path1"></span>
                              <span class="path2"></span>
                            </span>
                          </span>
                        </div>
                        <div *ngIf="!logedIn.isLogedIn">
                          <span
                            class="icon-heart position-absolute font-20"
                            (click)="
                              this.Service.isLoginTitleFavorite = true;
                              this.Service.openloginDialogue();
                              this.Service.addNonLoggedInFavItem = item
                            "
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                        </div>
                      </div>
                      <div
                        class="p-3 border-bottom border-right border-left rounded-bottom"
                      >
                        <div class="pb-4 mb-1">
                          <h4
                            class="d-inline font-16 font-weight-bold mb-0 align-middle"
                          >
                            <a
                              class="primaray-text"
                              target="_blank"
                              [routerLink]="[this.hotelNavigateUrl]"
                              [queryParams]="{
                                searchQuery: item.searchQuery,
                                checkIn: queryParams.checkIn,
                                checkOut: queryParams.checkOut,
                                paxes: queryParams.paxes,
                                travelerId: this.travelerId
                              }"
                              >{{ item.name }}</a
                            >
                          </h4>
                          <div
                            *ngIf="item.rating > 0"
                            class="hotel-stars-text align-middle text-sm gray-text-dark d-inline-block"
                            [ngClass]="{
                              'ml-3 pl-3': Service.isSiteLtr(),
                              'mr-3 pr-3': Service.isSiteRtl()
                            }"
                          >
                            {{ item.rating }}-
                            <span class="night-cont" i18n="@@stars">star</span>
                          </div>
                        </div>
                        <a
                          target="_blank"
                          [routerLink]="[this.hotelNavigateUrl]"
                          [queryParams]="{
                            searchQuery: item.searchQuery,
                            checkIn: queryParams.checkIn,
                            checkOut: queryParams.checkOut,
                            paxes: queryParams.paxes,
                            travelerId: this.travelerId
                          }"
                          class="text-decoration-none"
                        >
                          <div class="row">
                            <div class="col">
                              <div
                                *ngIf="
                                  item.lowestSellingPriceRate &&
                                  item.lowestSellingPriceRate.freeCancellation
                                "
                                class="cancelations font-14 w-100"
                                i18n="@@freeCancellation"
                              >
                                Free cancellation
                              </div>
                            </div>
                            <div
                              class="col"
                              [ngClass]="{
                                'text-right': Service.isSiteLtr(),
                                'text-left': Service.isSiteRtl()
                              }"
                              *ngIf="
                                this.logedIn.isLead &&
                                item.lowestSellingPriceRate
                                  .privateAverageNightlySellingPrice &&
                                !this.Service.isCompany()
                              "
                            >
                              <div
                                class="prime-border-purple border prime-color-purple font-weight-bold font-14 d-inline-block rounded px-3 py-2"
                              >
                                Your Hidden Rate
                              </div>
                            </div>
                          </div>
                          <div
                            [ngClass]="{
                              'text-right': Service.isSiteLtr(),
                              'text-left': Service.isSiteRtl()
                            }"
                          >
                            <del
                              class="old-price font-20 line d-inline-block"
                              [ngClass]="{
                                'mr-2': Service.isSiteLtr(),
                                'ml-2': Service.isSiteRtl()
                              }"
                              *ngIf="
                                !this.Service.isCompany() &&
                                logedIn.isLead &&
                                item.lowestSellingPriceRate &&
                                item.lowestSellingPriceRate
                                  .publicAverageNightlySellingPrice &&
                                item.lowestSellingPriceRate
                                  .privateAverageNightlySellingPrice
                              "
                            >
                              <span>{{
                                item.lowestSellingPriceRate
                                  .averageNightlySellingPriceConvertedCurrency
                              }}</span
                              ><span>
                                {{
                                  item.lowestSellingPriceRate
                                    .publicAverageNightlySellingPrice
                                    | number : "1.0-0"
                                }}
                              </span>

                              <br />
                            </del>
                            <div
                              class="current_price primaray-text d-inline-block clearfix"
                              [ngClass]="{
                                'mr-2 text-right': Service.isSiteLtr(),
                                'ml-2 text-left': Service.isSiteRtl(),
                                'prime-color-purple':
                                  logedIn.isLead &&
                                  item.lowestSellingPriceRate
                                    .privateAverageNightlySellingPrice &&
                                  !this.Service.isCompany()
                              }"
                              *ngIf="item.lowestSellingPriceRate"
                            >
                              <ng-container
                                *ngIf="
                                  logedIn.isLead &&
                                  item.lowestSellingPriceRate
                                    .privateAverageNightlySellingPrice &&
                                  item.lowestSellingPriceRate
                                    .averageNightlySellingPriceConverted
                                "
                              >
                                <span
                                  class="amount-currency font-weight-boldy font-30"
                                  >{{
                                    item.lowestSellingPriceRate
                                      .averageNightlySellingPriceConvertedCurrency
                                  }}</span
                                >
                                <span
                                  class="amount-currency font-weight-bold font-30"
                                >
                                  {{
                                    item.lowestSellingPriceRate
                                      .averageNightlySellingPriceConverted
                                      | number : "1.0-0"
                                  }}
                                </span>

                                <span
                                  class="nightWord font-18"
                                  i18n="@@slashNight"
                                  >/night</span
                                >
                              </ng-container>
                              <ng-container
                                *ngIf="
                                  !logedIn.isLead &&
                                  item.lowestSellingPriceRate
                                    .averageNightlySellingPriceConverted
                                "
                              >
                                <span
                                  class="amount-currency font-weight-bold font-30"
                                  >{{
                                    item.lowestSellingPriceRate
                                      .averageNightlySellingPriceConvertedCurrency
                                  }}</span
                                >
                                <span
                                  class="amount-currency font-weight-bold font-30"
                                >
                                  {{
                                    item.lowestSellingPriceRate
                                      .averageNightlySellingPriceConverted
                                      | number : "1.0-0"
                                  }}
                                </span>
                                <span
                                  class="nightWord font-18"
                                  i18n="@@slashNight"
                                  >/night</span
                                >
                              </ng-container>

                              <ng-container
                                *ngIf="
                                  logedIn.isLead &&
                                  item.lowestSellingPriceRate &&
                                  item.lowestSellingPriceRate
                                    .privateAverageNightlySellingPrice &&
                                  !item.lowestSellingPriceRate
                                    .averageNightlySellingPriceConverted
                                "
                              >
                                <span
                                  class="amount-currency font-weight-bold font-30"
                                  >{{
                                    item.lowestSellingPriceRate
                                      .averageNightlySellingPriceConvertedCurrency
                                  }}</span
                                >
                                <span
                                  class="amount-currency font-weight-bold font-30"
                                >
                                  {{
                                    item.lowestSellingPriceRate
                                      .averageNightlySellingPriceConverted
                                      | number : "1.0-0"
                                  }}
                                </span>
                                <span
                                  class="nightWord font-18"
                                  i18n="@@slashNight"
                                  >/night</span
                                >
                              </ng-container>
                              <ng-container
                                *ngIf="
                                  logedIn.isLead &&
                                  !item.lowestSellingPriceRate
                                    .privateAverageNightlySellingPrice &&
                                  item.lowestSellingPriceRate
                                    .averageNightlySellingPriceConverted
                                "
                              >
                                <span
                                  class="amount-currency font-weight-bold font-30"
                                  >{{
                                    item.lowestSellingPriceRate
                                      .averageNightlySellingPriceConvertedCurrency
                                  }}</span
                                >
                                <span
                                  class="amount-currency font-weight-bold font-30"
                                >
                                  {{
                                    item.lowestSellingPriceRate
                                      .averageNightlySellingPriceConverted
                                      | number : "1.0-0"
                                  }}
                                </span>
                                <span
                                  class="nightWord font-18"
                                  i18n="@@slashNight"
                                  >/night</span
                                >
                              </ng-container>
                            </div>
                            <div
                              class="tax mt-2"
                              [ngClass]="{
                                'text-right': Service.isSiteLtr(),
                                'text-left': Service.isSiteRtl(),
                                'no-margin-bottom':
                                  Service.isCompany() &&
                                  item.lowestSellingPriceRate &&
                                  item.lowestSellingPriceRate.userData &&
                                  item.lowestSellingPriceRate.userData
                                    .exceedsPolicyLimit
                              }"
                              *ngIf="
                                item.lowestSellingPriceRate &&
                                item.lowestSellingPriceRate.taxIncluded &&
                                (item.lowestSellingPriceRate
                                  .averageNightlySellingPriceConverted ||
                                  logedIn.isLead)
                              "
                              i18n="@@taxAndFeesIncluded"
                            >
                              Tax and fees included
                            </div>

                            <div
                              class="text-danger"
                              [ngClass]="{
                                'text-right': Service.isSiteLtr(),
                                'text-left': Service.isSiteRtl()
                              }"
                              *ngIf="
                                (item.lowestSellingPriceRate &&
                                  item.lowestSellingPriceRate.userData &&
                                  item.lowestSellingPriceRate.userData
                                    .exceedsPolicyLimit) ||
                                (policyDetails &&
                                  policyDetails.hotelBookingNotAllowed === true)
                              "
                              i18n="@@outOfPolicy"
                            >
                              Out of policy
                            </div>
                          </div>
                          <div
                            *ngIf="
                              item.lowestSellingPriceRate &&
                              item.lowestSellingPriceRate.userData &&
                              item.lowestSellingPriceRate.userData.rewardAmount
                            "
                            style="min-height: 29px"
                          ></div>
                          <div
                            class="reward-promo d-flex position-absolute"
                            *ngIf="
                              item.lowestSellingPriceRate &&
                              item.lowestSellingPriceRate.userData &&
                              item.lowestSellingPriceRate.userData.rewardAmount
                            "
                          >
                            <div class="promo-triangle"></div>
                            <div
                              class="promo-text text-white font-12 px-4 pt-2"
                              i18n="@@earnInRewards"
                            >
                              Earn ${{
                                item.lowestSellingPriceRate.userData
                                  .rewardAmount | number : "1.0-0"
                              }}
                              in Rewards
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    class="reward-box border p-4 mb-4 position-relative"
                    [ngClass]="{
                      'rounded-right': Service.isSiteLtr(),
                      'rounded-left': Service.isSiteRtl()
                    }"
                    *ngIf="
                      i === 0 &&
                      companyPolicyHotelRules &&
                      companyPolicyHotelRules.avgNightlyPriceLimit &&
                      logedIn.companyInfo.rewardProgram &&
                      logedIn.companyInfo.rewardProgram
                        .hotelSavingsPercentage &&
                      showRewardsBox &&
                      policyDetails.hotelBookingNotAllowed === false
                    "
                  >
                    <i
                      class="icon-close-light position-absolute p-2 pointer"
                      (click)="showRewardsBox = false"
                    ></i>
                    <p
                      class="font-18 bold-600 gray-primaray-text"
                      i18n="@@earnRewards"
                    >
                      Earn Rewards
                    </p>
                    <p class="gray-text-dark" i18n="@@getDollarRewards">
                      Get dollar rewards when you book a hotel below your
                      company travel policy. For every dollar you save, you get
                      {{
                        logedIn.companyInfo.rewardProgram
                          .hotelSavingsPercentage * 100
                      }}% as rewards.
                    </p>
                    <p class="gray-primaray-text mb-0">
                      <ng-container i18n="@@travelPolicy"
                        >Travel Policy</ng-container
                      >:&nbsp;<ng-container *ngIf="Service.isSiteLtr() && !this.exceptionCurrency">{{
                        policyCurrency
                      }}</ng-container
                      ><ng-container *ngIf="!this.exceptionValue">
                        {{
                          companyPolicyHotelRules.avgNightlyPriceLimit
                        }} </ng-container
                      >
                      <ng-container *ngIf="Service.isSiteRtl() && !this.exceptionCurrency">{{
                        policyCurrency
                      }}</ng-container
                      >
                      <ng-container *ngIf="Service.isSiteLtr() && this.exceptionCurrency">{{
                        exceptionCurrency
                      }}</ng-container
                      >
                      <ng-container *ngIf="this.exceptionValue">
                        {{ exceptionValue }} </ng-container
                      ><ng-container *ngIf="Service.isSiteRtl() && this.exceptionCurrency">
                        {{ exceptionCurrency }}</ng-container
                      ><ng-container i18n="@@slashNight">/night</ng-container>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="text-center"
              *ngIf="
                currentHotelList &&
                currentHotelList.length === 0 &&
                !oneitemlistMode
              "
            >
              <div class="centered with_padding">
                <div class="">
                  <i class="icon-filter font-22 mb-4 primaray-text"></i>
                </div>
                <div class="font-18" i18n="@@weAreSorrySearch">
                  We are sorry, we don't have any hotel that is matching your
                  filters
                </div>
                <div class="font-18" i18n="@@PleaseClearFliter">
                  Please tune your filters or clear them
                </div>
              </div>
            </div>
          </div>
          <div class="container custom_container text-center" *ngIf="errorData">
            <h2
              class="primaray-text font-weight-bold"
              i18n="@@noSearchCriteria1"
            >
              No results match your search criteria. Please modify your search
              and try again
            </h2>
            <img
              width="515"
              height="368"
              loading="lazy"
              class="d-inline-block w-100 initial-height"
              src="assets/img/wait.png"
              alt="wait"
            />
          </div>
        </div>
        <div class="map-full" *ngIf="!listView">
          <div
            class="form-group with-icon position-absolute search-label-map"
            *ngIf="this.Service.desktopView"
          >
            <input
              class="form-control"
              (keydown.enter)="autoCompliteFilterFunc()"
              [(ngModel)]="autoCompliteFilterText"
              type="text"
              (focus)="autocompleteOnFocus($event)"
              i18n-placeholder="@@hotelName"
              placeholder="Hotel Name"
              (ngModelChange)="this.autoCompliteFilter.next($event)"
            />
            <i
              [ngClass]="{ active: autoCompliteFilterActive }"
              (click)="autoCompliteFilterFunc()"
              class="icon-search gray-text clickable-cursor"
            ></i>
            <ul
              class="main dropdown-menu show list-unstyled dropdown-menu-search"
              *ngIf="autoCompliteMenuFilter"
            >
              <li
                class="gray-text-dark font-14"
                *ngFor="let item of filterreditemsByText; let i = index"
              >
                <span
                  (click)="autoCompliteClick(item, item.index)"
                  class="px-3 py-2 d-block clickable-cursor"
                  >{{ item.name }}</span
                >
              </li>
              <li
                class="gray-text-dark font-14"
                *ngIf="filterreditemsByText.length === 0"
              >
                <span
                  class="px-3 py-2 d-block clickable-cursor"
                  i18n="@@noMatchFound"
                  >No match found</span
                >
              </li>
            </ul>
          </div>
          <div class="map-loader" *ngIf="autoCompliteFilterActive">
            <div class="inner"><div class="spinner"></div></div>
          </div>
          <div
            class="map-full-header row content-row py-3 px-2 no-gutters"
            *ngIf="this.Service.desktopView"
          >
            <div class="col-auto border-right px-4">
              <div ngbDropdown class="position-relative">
                <div ngbDropdownToggle>
                  <h4
                    class="font-14 font-weight-bold gray-text-dark"
                    i18n="@@starRating"
                  >
                    Star Rating
                  </h4>
                  <div class="selected-star-cont font-16">
                    <div
                      class="d-inline-block selected-star"
                      *ngFor="let item of ratingArray | RatingDisplay : true"
                    >
                      <span class="">, &nbsp;</span>{{ item.name }}
                    </div>
                    <div class="d-inline-block">
                      <span
                        *ngIf="starArry.length === 0"
                        class="gray-text font-16"
                        >0&nbsp;<ng-container i18n="@@filters"
                          >filters</ng-container
                        ></span
                      >
                    </div>
                  </div>
                </div>
                <div class="main" ngbDropdownMenu>
                  <div class="p-3">
                    <div class="form-checkboxes">
                      <div
                        class="form-item form-type-checkbox mb-2"
                        *ngFor="let ratings of ratingArray; let i = index"
                      >
                        <input
                          type="checkbox"
                          id="{{ ratings.id }}"
                          [checked]="ratings.isChecked"
                          (change)="dataChanged($event, i)"
                        />
                        <label class="gray-text-dark" for="{{ ratings.id }}">{{
                          ratings.name
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-auto border-right px-4"
              *ngIf="displayGoogleRatingFilter"
            >
              <h4
                class="font-14 font-weight-bold gray-text-dark slider-filter"
                i18n="@@guestsRating"
              >
                Guests Rating
              </h4>
              <div class="d-flex">
                <span
                  class="font-weight-bold gray-primaray-text"
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  >{{ ReviewValue }}<span *ngIf="isInteger">.0</span>
                  {{ ReviewValueText }}</span
                >
              </div>

              <ng5-slider
                [(value)]="ReviewValue"
                [options]="optionsReview"
                (userChange)="ReviewChange($event)"
                (userChangeEnd)="ReviewChangeEnd($event)"
              ></ng5-slider>
            </div>

            <div class="col-auto border-right px-4">
              <h4
                class="font-14 font-weight-bold gray-text-dark slider-filter"
                i18n="@@priceRangeNightly"
              >
                Price Range (Nightly)
              </h4>
              <div class="d-flex">
                <span
                  class="font-weight-bold gray-primaray-text"
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  >{{ currency }} {{ minValue }}</span
                >
                <span class="font-weight-bold gray-primaray-text"
                  >{{ currency }} {{ maxValue
                  }}<span *ngIf="maxValue === 500">+</span></span
                >
              </div>

              <ng5-slider
                [(value)]="minValue"
                [(highValue)]="maxValue"
                [options]="optionsSlider"
                (userChangeEnd)="PriceChangeEnd($event)"
              ></ng5-slider>
            </div>

            <div class="col-auto border-right px-4">
              <div ngbDropdown class="position-relative">
                <div ngbDropdownToggle>
                  <h4
                    class="font-14 font-weight-bold gray-text-dark slider-filter"
                    i18n="@@popularFilters"
                  >
                    Popular Filters
                  </h4>
                  <span class="gray-text font-16"
                    >{{
                      AmenitiesArray.length + filterFreeCancellation
                    }}&nbsp;<ng-container i18n="@@filters"
                      >filters</ng-container
                    ></span
                  >
                </div>
                <div class="main" ngbDropdownMenu>
                  <div class="p-3">
                    <div class="form-checkboxes">
                      <div
                        class="form-item form-type-checkbox mb-2"
                        [ngClass]="{ active: filterFreeCancellationCheck }"
                      >
                        <input
                          id="freeCan"
                          type="checkbox"
                          [(ngModel)]="filterFreeCancellation"
                          [checked]="filterFreeCancellation"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="filterFreeCancellationFunc()"
                        />
                        <label
                          for="freeCan"
                          class="gray-text-dark"
                          i18n="@@freeCancellation"
                          >Free cancellation</label
                        >
                      </div>
                      <div
                        class="form-item form-type-checkbox mb-2"
                        *ngFor="let AmenityMap of Amenities; let i = index"
                        [ngClass]="{ active: activecheckbox === i }"
                      >
                        <input
                          type="checkbox"
                          id="Q{{ AmenityMap.hrsCode }}"
                          [checked]="AmenityMap.isChecked"
                          (change)="dataChangedAmenities($event, i)"
                        />
                        <label
                          class="gray-text-dark"
                          for="Q{{ AmenityMap.hrsCode }}"
                          >{{ AmenityMap.title }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-auto px-4">
              <div
                class="blue-text filters_clear not-mobile mb-3 font-weight-bold pt-4 mt-1"
                *ngIf="filteringActive"
                (click)="clearFilters()"
              >
                <i class="fas fa-times-circle"></i>&nbsp;<ng-container
                  i18n="@@clearAllFilters"
                  >Clear all filters</ng-container
                >
              </div>
            </div>

            <div
              class="col-auto px-2"
              [ngClass]="{
                'ml-auto': Service.isSiteLtr(),
                'mr-auto': Service.isSiteRtl()
              }"
            >
              <button
                class="btn btn-link karla-font font-weight-bold text-medium pt-3 mt-1"
                (click)="this.Service.showFooter = true; mapViewtoggle()"
              >
                <i
                  class="icon-cross"
                  [ngClass]="{
                    'mr-3': Service.isSiteLtr(),
                    'ml-3': Service.isSiteRtl()
                  }"
                ></i
                >&nbsp;<ng-container i18n="@@viewList">VIEW LIST</ng-container>
              </button>
            </div>
          </div>

          <div
            class="map-full-header d-flex border-bottom"
            *ngIf="this.Service.mobileView"
          >
            <div
              [ngClass]="{
                'mr-auto': Service.isSiteLtr(),
                'ml-auto': Service.isSiteRtl()
              }"
            >
              <button
                class="btn-link font-14 text-uppercase font-weight-bold"
                (click)="this.Service.showFooter = true; mapViewtoggle()"
              >
                <span i18n="@@closeMap">close map</span>&nbsp;<i
                  class="icon-locator"
                ></i>
              </button>
            </div>
            <div>
              <button
                class="btn-link font-14 text-uppercase font-weight-bold"
                (click)="activePopUp = true; filterPopUp = true"
              >
                <span i18n="@@filter">Filter</span>&nbsp;<i
                  class="icon-filter"
                ></i>
              </button>
            </div>
          </div>

          <div
            class="map-mobile-item"
            *ngIf="mapItemActive && this.Service.mobileView"
          >
            <div class="item-promo-hotel map-mobile" *ngIf="mapItem">
              <!--    mobile map-->
              <span
                class="close-mapItemActive position-absolute"
                (click)="closeHotelMap()"
                ><i class="icon-cross"></i
              ></span>

              <a
                [routerLink]="[this.hotelNavigateUrl]"
                [queryParams]="{
                  searchQuery: mapItem.searchQuery,
                  checkIn: queryParams.checkIn,
                  checkOut: queryParams.checkOut,
                  paxes: queryParams.paxes,
                  travelerId: this.travelerId
                }"
              >
                <div class="row no-gutters">
                  <div class="col-4">
                    <div class="position-relative image-holder h-100">
                      <div
                        class="sign rounded-top-left"
                        *ngIf="mapItem && mapItem.exclusiveDeal"
                      >
                        <div class="inner" i18n="@@specialDeal">
                          Special Deal
                        </div>
                      </div>
                      <img
                        class="h-100"
                        *ngIf="mapItem.images"
                        [src]="mapItem.images[0].url"
                      />
                      <div
                        class="google-review-cont d-inline-block p-2 mt-3"
                        *ngIf="mapItem.googleReview"
                      >
                        <div
                          class="text-medium font-weight-bold text-white align-middle d-inline-block"
                        >
                          {{ mapItem.googleReview.rating }}
                        </div>
                        <div class="rating-stars-google d-inline-block">
                          <div
                            class="rating-active"
                            [style.width.%]="
                              mapItem.googleReview.ratingPercentage * 100
                            "
                          ></div>
                        </div>

                        <span class="text-white text-sm d-inline-block"
                          ><span
                            [ngClass]="{
                              'mr-1': Service.isSiteLtr(),
                              'ml-1': Service.isSiteRtl()
                            }"
                          >
                            ({{
                              mapItem.googleReview.userRatingsTotal
                                | number : "" : "en-US"
                            }}</span
                          ><ng-container i18n="@@reviews">reviews</ng-container
                          >)</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="container py-2 h-100">
                      <div class="row no-gutters h-100">
                        <div class="col-12 align-self-start">
                          <h4
                            class="d-inline-block primaray-text font-weight-bold mb-0 align-middle"
                          >
                            {{ mapItem.name }}
                          </h4>
                          <div
                            class="hotel-stars-text align-middle text-sm gray-text-dark d-inline-block"
                            [ngClass]="{
                              'ml-2 pl-2': Service.isSiteLtr(),
                              'mr-2 pr-2': Service.isSiteRtl()
                            }"
                            *ngIf="mapItem.rating"
                          >
                            {{ mapItem.rating }}-<span
                              class="night-cont"
                              i18n="@@stars"
                              >star</span
                            >
                          </div>
                        </div>
                        <div
                          *ngIf="
                            mapItem.lowestSellingPriceRate &&
                            mapItem.lowestSellingPriceRate.freeCancellation
                          "
                          class="cancelations position-static align-self-center col-12 font-14 w-100"
                          i18n="@@freeCancellation"
                        >
                          Free cancellation
                        </div>
                        <div class="text-right col-12 align-self-end">
                          <div
                            class="current_price d-inline-block primaray-text clearfix"
                            [ngClass]="{
                              'mr-2 text-right': Service.isSiteLtr(),
                              'ml-2 text-left': Service.isSiteRtl(),
                              'prime-color-purple':
                                logedIn.isLead &&
                                mapItem.lowestSellingPriceRate
                                  .privateAverageNightlySellingPrice &&
                                !this.Service.isCompany()
                            }"
                            *ngIf="mapItem.lowestSellingPriceRate"
                          >
                            <del
                              class="old-price font-20 line d-inline-block"
                              [ngClass]="{
                                'mr-2': Service.isSiteLtr(),
                                'ml-2': Service.isSiteRtl()
                              }"
                              *ngIf="
                                !this.Service.isCompany() &&
                                logedIn.isLead &&
                                mapItem.lowestSellingPriceRate &&
                                mapItem.lowestSellingPriceRate
                                  .publicAverageNightlySellingPrice &&
                                mapItem.lowestSellingPriceRate
                                  .privateAverageNightlySellingPrice
                              "
                            >
                              <span>{{
                                mapItem.lowestSellingPriceRate
                                  .averageNightlySellingPriceConvertedCurrency
                              }}</span
                              ><span>
                                {{
                                  mapItem.lowestSellingPriceRate
                                    .publicAverageNightlySellingPrice
                                    | number : "1.0-0"
                                }}
                              </span>

                              <br />
                            </del>
                            <ng-container
                              *ngIf="
                                logedIn.isLead &&
                                mapItem.lowestSellingPriceRate
                                  .privateAverageNightlySellingPrice &&
                                mapItem.lowestSellingPriceRate
                                  .averageNightlySellingPriceConverted
                              "
                            >
                              <span
                                class="amount-currency font-24 font-weight-bold"
                                >{{
                                  mapItem.lowestSellingPriceRate
                                    .averageNightlySellingPriceConvertedCurrency
                                }}</span
                              >
                              <span
                                class="amount-currency font-24 font-weight-bold"
                              >
                                {{
                                  mapItem.lowestSellingPriceRate
                                    .averageNightlySellingPriceConverted
                                    | number : "1.0-0"
                                }}
                              </span>
                              <span
                                class="nightWord font-16"
                                i18n="@@slashNight"
                                >/night</span
                              >
                            </ng-container>
                            <ng-container
                              *ngIf="
                                !logedIn.isLead &&
                                mapItem.lowestSellingPriceRate
                                  .averageNightlySellingPriceConverted
                              "
                            >
                              <span
                                class="amount-currency font-20 font-weight-bold"
                                >{{
                                  mapItem.lowestSellingPriceRate
                                    .averageNightlySellingPriceConvertedCurrency
                                }}</span
                              >
                              <span
                                class="amount-currency font-20 font-weight-bold"
                              >
                                {{
                                  mapItem.lowestSellingPriceRate
                                    .averageNightlySellingPriceConverted
                                    | number : "1.0-0"
                                }}
                              </span>
                              <span
                                class="nightWord font-16"
                                i18n="@@slashNight"
                                >/night</span
                              >
                            </ng-container>

                            <ng-container
                              *ngIf="
                                logedIn.isLead &&
                                mapItem.lowestSellingPriceRate &&
                                mapItem.lowestSellingPriceRate
                                  .privateAverageNightlySellingPrice &&
                                !mapItem.lowestSellingPriceRate
                                  .averageNightlySellingPriceConverted
                              "
                            >
                              <span
                                class="amount-currency font-20 font-weight-bold"
                                >{{
                                  mapItem.lowestSellingPriceRate
                                    .averageNightlySellingPriceConvertedCurrency
                                }}</span
                              >
                              <span
                                class="amount-currency font-20 font-weight-bold"
                              >
                                {{
                                  mapItem.lowestSellingPriceRate
                                    .averageNightlySellingPriceConverted
                                    | number : "1.0-0"
                                }}
                              </span>
                              <span
                                class="nightWord font-16"
                                i18n="@@slashNight"
                                >/night</span
                              >
                            </ng-container>
                            <ng-container
                              *ngIf="
                                logedIn.isLead &&
                                !mapItem.lowestSellingPriceRate
                                  .privateAverageNightlySellingPrice &&
                                mapItem.lowestSellingPriceRate
                                  .averageNightlySellingPriceConverted
                              "
                            >
                              <span
                                class="amount-currency font-20 font-weight-bold"
                                >{{
                                  mapItem.lowestSellingPriceRate
                                    .averageNightlySellingPriceConvertedCurrency
                                }}</span
                              >
                              <span
                                class="amount-currency font-20 font-weight-bold"
                              >
                                {{
                                  mapItem.lowestSellingPriceRate
                                    .averageNightlySellingPriceConverted
                                    | number : "1.0-0"
                                }}
                              </span>
                              <span
                                class="nightWord font-16"
                                i18n="@@slashNight"
                                >/night</span
                              >
                            </ng-container>

                            <div
                              class="tax mt-2"
                              [ngClass]="{
                                'text-right': Service.isSiteLtr(),
                                'text-left': Service.isSiteRtl()
                              }"
                              *ngIf="
                                mapItem.lowestSellingPriceRate &&
                                mapItem.lowestSellingPriceRate.taxIncluded &&
                                (mapItem.lowestSellingPriceRate
                                  .averageNightlySellingPriceConverted ||
                                  logedIn.isLead)
                              "
                              i18n="@@taxAndFeesIncluded"
                            >
                              Tax and fees included
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div class="row no-gutters map-full-row">
            <!--    desktop map-->
            <div
              class="col-md-3"
              *ngIf="mapItemActive && this.Service.desktopView && mapItem"
            >
              <div
                *ngIf="mapHotelNotavailability"
                class="not-available-map-desktop"
              >
                <div class="oneitemlist-header rounded-top p-2">
                  <span class="font-18">
                    <i
                      [ngClass]="
                        ActiveOneItem ? 'icon-success' : 'icon-warning'
                      "
                      class=""
                    ></i>
                    {{ mapItem.name }}

                    <span>
                      <ng-container i18n="@@noAvailableDates"
                        >is not available for your travel dates</ng-container
                      >
                    </span>
                  </span>
                </div>
              </div>

              <div class="side-map-info">
                <div class="item-promo-hotel">
                  <div class="position-relative image-holder">
                    <div
                      class="sign rounded-top-left"
                      *ngIf="mapItem.exclusiveDeal"
                    >
                      <div class="inner" i18n="@@specialDeal">Special Deal</div>
                    </div>
                    <span
                      class="close-mapItemActive position-absolute"
                      (click)="closeHotelMap()"
                      ><i class="icon-cross"></i
                    ></span>
                    <a
                      target="_blank"
                      [routerLink]="[this.hotelNavigateUrl]"
                      [queryParams]="{
                        searchQuery: mapItem.searchQuery,
                        checkIn: queryParams.checkIn,
                        checkOut: queryParams.checkOut,
                        paxes: queryParams.paxes,
                        travelerId: this.travelerId
                      }"
                    >
                      <img
                        *ngIf="mapItem.images; else Mapplacholder"
                        [src]="mapItem.images[0].url"
                      />
                    </a>

                    <ng-template #Mapplacholder>
                      <img
                        width="279"
                        height="239"
                        loading="lazy"
                        src="assets/img/no_image.png"
                      />
                    </ng-template>
                    <div
                      class="google-review-cont d-inline-block p-3 mt-3"
                      *ngIf="mapItem.googleReview"
                    >
                      <div
                        class="text-medium font-weight-bold text-white align-middle d-inline-block"
                      >
                        {{ mapItem.googleReview.rating }}
                      </div>
                      <div class="rating-stars-google d-inline-block">
                        <div
                          class="rating-active"
                          [style.width.%]="
                            mapItem.googleReview.ratingPercentage * 100
                          "
                        ></div>
                      </div>

                      <span class="text-white text-sm d-inline-block"
                        ><span
                          [ngClass]="{
                            'mr-1': Service.isSiteLtr(),
                            'ml-1': Service.isSiteRtl()
                          }"
                        >
                          ({{
                            mapItem.googleReview.userRatingsTotal
                              | number : "" : "en-US"
                          }}</span
                        ><ng-container i18n="@@reviews">reviews</ng-container
                        >)</span
                      >
                    </div>
                  </div>

                  <div class="p-3">
                    <div class="border-bottom pb-4 mb-1">
                      <h4
                        class="d-inline-block gray-primaray-text font-weight-bold mb-0 align-middle"
                      >
                        {{ mapItem.name }}
                      </h4>
                      <div
                        *ngIf="mapItem.rating > 0"
                        class="hotel-stars-text align-middle text-sm gray-text-dark d-inline-block"
                        [ngClass]="{
                          'ml-3 pl-3': Service.isSiteLtr(),
                          'mr-3 pr-3': Service.isSiteRtl()
                        }"
                      >
                        {{ mapItem.rating }}-
                        <span
                          class="night-cont"
                          *ngIf="mapItem.rating > 1; else star"
                          >stars</span
                        >
                        <ng-template #star>star </ng-template>
                      </div>
                    </div>

                    <div
                      class="current_price clearfix"
                      [ngClass]="{
                        'mr-2 text-right': Service.isSiteLtr(),
                        'ml-2 text-left': Service.isSiteRtl(),
                        'prime-color-purple':
                          logedIn.isLead &&
                          mapItem.lowestSellingPriceRate
                            .privateAverageNightlySellingPrice &&
                          !this.Service.isCompany(),
                        'primaray-text':
                          !logedIn.isLead &&
                          !mapItem.lowestSellingPriceRate
                            .privateAverageNightlySellingPrice
                      }"
                      *ngIf="mapItem.lowestSellingPriceRate"
                    >
                      <del
                        class="old-price font-20 line d-inline-block"
                        [ngClass]="{
                          'mr-2': Service.isSiteLtr(),
                          'ml-2': Service.isSiteRtl()
                        }"
                        *ngIf="
                          !this.Service.isCompany() &&
                          logedIn.isLead &&
                          mapItem.lowestSellingPriceRate &&
                          mapItem.lowestSellingPriceRate
                            .publicAverageNightlySellingPrice &&
                          mapItem.lowestSellingPriceRate
                            .privateAverageNightlySellingPrice
                        "
                      >
                        <span>{{
                          mapItem.lowestSellingPriceRate
                            .averageNightlySellingPriceConvertedCurrency
                        }}</span
                        ><span>
                          {{
                            mapItem.lowestSellingPriceRate
                              .publicAverageNightlySellingPrice
                              | number : "1.0-0"
                          }}
                        </span>

                        <br />
                      </del>
                      <ng-container
                        *ngIf="
                          logedIn.isLead &&
                          mapItem.lowestSellingPriceRate
                            .privateAverageNightlySellingPrice &&
                          mapItem.lowestSellingPriceRate
                            .averageNightlySellingPriceConverted
                        "
                      >
                        <span
                          class="amount-currency font-weight-bold font-30-to-24"
                          >{{
                            mapItem.lowestSellingPriceRate
                              .averageNightlySellingPriceConvertedCurrency
                          }}</span
                        >
                        <span
                          class="amount-currency font-weight-bold font-30-to-24"
                        >
                          {{
                            mapItem.lowestSellingPriceRate
                              .averageNightlySellingPriceConverted
                              | number : "1.0-0"
                          }}
                        </span>

                        <span class="nightWord font-18" i18n="@@slashNight"
                          >/night</span
                        >
                      </ng-container>
                      <ng-container
                        *ngIf="
                          !logedIn.isLead &&
                          mapItem.lowestSellingPriceRate
                            .averageNightlySellingPriceConverted
                        "
                      >
                        <span
                          class="amount-currency font-weight-bold font-30-to-24"
                          >{{
                            mapItem.lowestSellingPriceRate
                              .averageNightlySellingPriceConvertedCurrency
                          }}</span
                        >
                        <span
                          class="amount-currency font-weight-bold font-30-to-24"
                        >
                          {{
                            mapItem.lowestSellingPriceRate
                              .averageNightlySellingPriceConverted
                              | number : "1.0-0"
                          }}
                        </span>
                        <span class="nightWord font-18" i18n="@@slashNight"
                          >/night</span
                        >
                      </ng-container>

                      <ng-container
                        *ngIf="
                          logedIn.isLead &&
                          mapItem.lowestSellingPriceRate &&
                          mapItem.lowestSellingPriceRate
                            .privateAverageNightlySellingPrice &&
                          !mapItem.lowestSellingPriceRate
                            .averageNightlySellingPriceConverted
                        "
                      >
                        <span
                          class="amount-currency font-weight-bold font-30-to-24"
                          >{{
                            mapItem.lowestSellingPriceRate
                              .averageNightlySellingPriceConvertedCurrency
                          }}</span
                        >
                        <span
                          class="amount-currency font-weight-bold font-30-to-24"
                        >
                          {{
                            mapItem.lowestSellingPriceRate
                              .averageNightlySellingPriceConverted
                              | number : "1.0-0"
                          }}
                        </span>
                        <span class="nightWord font-18" i18n="@@slashNight"
                          >/night</span
                        >
                      </ng-container>

                      <ng-container
                        *ngIf="
                          logedIn.isLead &&
                          !mapItem.lowestSellingPriceRate
                            .privateAverageNightlySellingPrice &&
                          mapItem.lowestSellingPriceRate
                            .averageNightlySellingPriceConverted
                        "
                      >
                        <span
                          class="amount-currency font-weight-bold font-30-to-24"
                          >{{
                            mapItem.lowestSellingPriceRate
                              .averageNightlySellingPriceConvertedCurrency
                          }}</span
                        >
                        <span
                          class="amount-currency font-weight-bold font-30-to-24"
                        >
                          {{
                            mapItem.lowestSellingPriceRate
                              .averageNightlySellingPriceConverted
                              | number : "1.0-0"
                          }}
                        </span>
                        <span class="nightWord font-18" i18n="@@slashNight"
                          >/night</span
                        >
                      </ng-container>
                    </div>
                    <div
                      class="tax mt-2"
                      [ngClass]="{
                        'text-right': Service.isSiteLtr(),
                        'text-left': Service.isSiteRtl()
                      }"
                      *ngIf="
                        mapItem.lowestSellingPriceRate &&
                        mapItem.lowestSellingPriceRate.taxIncluded &&
                        (mapItem.lowestSellingPriceRate
                          .averageNightlySellingPriceConverted ||
                          logedIn.isLead)
                      "
                      i18n="@@taxAndFeesIncluded"
                    >
                      Tax and fees included
                    </div>
                    <br />
                    <div class="mt-2">
                      <div
                        class="price-guarante d-inline-block inner-cancelations font-weight-bold font-14"
                        [ngClass]="{
                          'mr-4': Service.isSiteLtr(),
                          'ml-4': Service.isSiteRtl()
                        }"
                      >
                        Price Guarantee
                      </div>
                      <div
                        *ngIf="
                          mapItem.lowestSellingPriceRate &&
                          mapItem.lowestSellingPriceRate.freeCancellation
                        "
                        class="cancelations ml-2 d-inline-block font-14"
                        i18n="@@freeCancellation"
                      >
                        Free cancellation
                      </div>
                    </div>
                    <div
                      class="book_button not-mobile px-2"
                      *ngIf="
                        mapItem.lowestSellingPriceRate &&
                          mapItem.lowestSellingPriceRate
                            .averageNightlySellingPriceConverted;
                        else ViewHotel
                      "
                    >
                      <a
                        *ngIf="mapItem.lowestSellingPriceRate"
                        target="_blank"
                        class="green_btn btn-block mt-4 px-2"
                        [routerLink]="[this.hotelNavigateUrl]"
                        [queryParams]="{
                          searchQuery: mapItem.searchQuery,
                          checkIn: queryParams.checkIn,
                          checkOut: queryParams.checkOut,
                          paxes: queryParams.paxes,
                          travelerId: this.travelerId
                        }"
                        i18n="@@chooseRoom"
                        >Choose Room</a
                      >
                    </div>
                    <ng-template #ViewHotel>
                      <div class="book_button not-mobile">
                        <a
                          *ngIf="mapItem.lowestSellingPriceRate"
                          target="_blank"
                          class="green_btn btn-block mt-4"
                          [routerLink]="[this.hotelNavigateUrl]"
                          [queryParams]="{
                            searchQuery: mapItem.searchQuery,
                            checkIn: queryParams.checkIn,
                            checkOut: queryParams.checkOut,
                            paxes: queryParams.paxes,
                            travelerId: this.travelerId
                          }"
                          i18n="@@viewHotel"
                          >View Hotel</a
                        >
                      </div>
                    </ng-template>
                    <ul class="facilities-top-cont list-unstyled mt-4">
                      <li
                        *ngFor="let item of myFacilities | featured : true"
                        [class]="
                          'facilities-top gray-primaray-text font-16  facilities-' +
                          item.hrsCode
                        "
                      >
                        <div *ngIf="item.featured">
                          <i [class]="'icon-' + item.hrsCode"></i>
                          {{ item.title }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md">
              <button
                class="btn btn-outline-blue searchthisAreaBtn px-5"
                [ngClass]="{ 'm-progress': submitting }"
                *ngIf="searchthisAreaBtn"
                (click)="searcAreaMap()"
                i18n="@@searchThisArea"
              >
                Search this area
              </button>
              <agm-map
                (centerChange)="centerChange($event)"
                (zoomChange)="zoomChange($event)"
                [latitude]="MainLatitude"
                [longitude]="MainLongitude"
                [(zoom)]="zoom"
                [disableDefaultUI]="false"
                [zoomControl]="true"
              >
                <agm-marker-cluster
                  [styles]="clusterStyles"
                  [minimumClusterSize]="clusterOptions.minimumClusterSize"
                  [gridSize]="clusterOptions.gridSize"
                >
                  <ng-container
                    *ngFor="let loc of currentHotelList; let i = index"
                  >
                    <agm-marker
                      *ngIf="
                        loc.lowestSellingPriceRate
                          .privateAverageNightlySellingPrice &&
                        loc.lowestSellingPriceRate
                          .averageNightlySellingPriceConverted &&
                        logedIn.isLead
                      "
                      [label]="{
                        text:
                          '$' +
                          (loc.lowestSellingPriceRate
                            .averageNightlySellingPriceConverted
                            | number : '1.0-0'),
                        color: loc.color,
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: 'Karla'
                      }"
                      [latitude]="loc.latitude"
                      [longitude]="loc.longitude"
                      [zIndex]="loc.index"
                      [iconUrl]="loc.icon"
                      (markerClick)="clickedMarker(loc, i, $event)"
                    >
                    </agm-marker>
                    <agm-marker
                      *ngIf="
                        loc.lowestSellingPriceRate
                          .privateAverageNightlySellingPrice &&
                        loc.lowestSellingPriceRate
                          .averageNightlySellingPriceConverted &&
                        !logedIn.isLead
                      "
                      [label]="{
                        text:
                          '$' +
                          (loc.lowestSellingPriceRate
                            .averageNightlySellingPriceConverted
                            | number : '1.0-0'),
                        color: loc.color,
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: 'Karla'
                      }"
                      [latitude]="loc.latitude"
                      [longitude]="loc.longitude"
                      [zIndex]="loc.index"
                      [iconUrl]="loc.icon"
                      (markerClick)="clickedMarker(loc, i, $event)"
                    >
                    </agm-marker>
                    <agm-marker
                      *ngIf="
                        loc.lowestSellingPriceRate
                          .privateAverageNightlySellingPrice &&
                        !loc.lowestSellingPriceRate
                          .averageNightlySellingPriceConverted
                      "
                      [latitude]="loc.latitude"
                      [longitude]="loc.longitude"
                      [zIndex]="loc.index"
                      [iconUrl]="'assets/img/disabled-marker.png'"
                      (markerClick)="clickedMarker(loc, i, $event)"
                    >
                    </agm-marker>
                    <agm-marker
                      *ngIf="
                        !loc.lowestSellingPriceRate
                          .privateAverageNightlySellingPrice &&
                        loc.lowestSellingPriceRate
                          .averageNightlySellingPriceConverted
                      "
                      [label]="{
                        text:
                          '$' +
                          (loc.lowestSellingPriceRate
                            .averageNightlySellingPriceConverted
                            | number : '1.0-0'),
                        color: loc.color,
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: 'Karla'
                      }"
                      [latitude]="loc.latitude"
                      [longitude]="loc.longitude"
                      [zIndex]="loc.index"
                      [iconUrl]="loc.icon"
                      (markerClick)="clickedMarker(loc, i, $event)"
                    >
                    </agm-marker>
                  </ng-container>
                </agm-marker-cluster>
              </agm-map>
            </div>
          </div>
        </div>
        <div
          class="sort-popup-cont"
          [ngClass]="{ in: sortPopUp && this.Service.mobileView }"
        >
          <div class="sort-popup" [ngClass]="{ in: sortPopUp }">
            <div class="d-flex p-3">
              <div
                class="title primaray-text font-weight-bold font-16"
                [ngClass]="{
                  'mr-auto': Service.isSiteLtr(),
                  'ml-auto': Service.isSiteRtl()
                }"
                i18n="@@sortBy"
              >
                Sort by
              </div>
              <div>
                <i
                  class="icon-close-light primaray-text font-18"
                  (click)="sortPopUp = false; this.Service.enableBodyScroll()"
                ></i>
              </div>
            </div>
            <div class="sortPopUp">
              <ul class="list-unstyled filter-group">
                <li
                  [ngClass]="{ active: ParentSort === 'default' }"
                  (click)="
                    sortFunc('default', 'default');
                    sortPopUp = false;
                    this.Service.enableBodyScroll()
                  "
                  i18n="@@ourFavorites"
                >
                  Our Favorites
                </li>
                <li
                  *ngIf="showGuestRating()"
                  [ngClass]="{ active: ParentSort === 'guestRating' }"
                  (click)="
                    sortFunc('guestRating', 'guestRating');
                    sortPopUp = false;
                    this.Service.enableBodyScroll()
                  "
                  i18n="@@guestRatingSort"
                >
                  Guest Rating
                </li>
                <li
                  (click)="
                    sortFunc('PriceLow', '');
                    sortPopUp = false;
                    this.Service.enableBodyScroll()
                  "
                  [ngClass]="{ active: SortType === 'PriceLow' }"
                >
                  <ng-container i18n="@@price">Price</ng-container
                  >&nbsp;(<ng-container i18n="@@highToLow"
                    >high to low</ng-container
                  >)
                </li>
                <li
                  [ngClass]="{ active: SortType === 'PriceHigh' }"
                  (click)="
                    sortFunc('PriceHigh', '');
                    sortPopUp = false;
                    this.Service.enableBodyScroll()
                  "
                >
                  <ng-container i18n="@@price">Price</ng-container
                  >&nbsp;(<ng-container i18n="@@lowToHigh"
                    >low to high</ng-container
                  >)
                </li>
                <li
                  (click)="
                    sortFunc('ratingLow', '');
                    sortPopUp = false;
                    this.Service.enableBodyScroll()
                  "
                  [ngClass]="{ active: SortType === 'ratingLow' }"
                >
                  <ng-container i18n="@@starRating">Star Rating</ng-container
                  >&nbsp;(<ng-container i18n="@@highToLow"
                    >high to low</ng-container
                  >)
                </li>
                <li
                  (click)="
                    sortFunc('ratingHigh', '');
                    sortPopUp = false;
                    this.Service.enableBodyScroll()
                  "
                  [ngClass]="{ active: SortType === 'ratingHigh' }"
                >
                  <ng-container i18n="@@starRating">Star Rating</ng-container
                  >&nbsp;(<ng-container i18n="@@lowToHigh"
                    >low to high</ng-container
                  >)
                </li>
              </ul>
            </div>
          </div>
          <div
            class="overlay"
            (click)="sortPopUp = false; this.Service.enableBodyScroll()"
          ></div>
        </div>

        <div class="action-popup left-popup" [ngClass]="{ in: filterPopUp }">
          <div class="header">
            <div class="form-name-dates-summary picker-new-header">
              <div class="title" i18n="@@filter">Filter</div>
            </div>
          </div>
          <div class="body">
            <div>
              <div class="filter_group mb-5 mt-2">
                <h4 class="font-16 gray-text-dark" i18n="@@searchByName">
                  Search by name
                </h4>
                <div class="main">
                  <div class="form-group m-0 with-icon">
                    <input
                      class="form-control"
                      [(ngModel)]="SearchByNameText"
                      type="text"
                      placeholder="Hotel Name"
                      i18n-placeholder="@@hotelName"
                      (ngModelChange)="this.hotelSearchFilter.next($event)"
                    />
                    <i
                      *ngIf="SearchByNameText"
                      class="icon-close-light p-2 pointer"
                      (click)="
                        SearchByNameText = ''; this.hotelSearchFilter.next('')
                      "
                    ></i>
                  </div>
                </div>
              </div>

              <div class="filter_group mb-5 mt-2">
                <h4 class="font-16 gray-text-dark" i18n="@@starRating">
                  Star Rating
                </h4>

                <div class="main">
                  <div>
                    <div class="form-checkboxes row gutters-5">
                      <div
                        class="form-item form-type-checkbox mb-1 col mobile-stars-holder active"
                        *ngFor="
                          let rating of ratingArray | reverse;
                          let i = index
                        "
                        [ngClass]="{ active: activecheckbox === i }"
                      >
                        <div
                          class="border rounded filter-box"
                          [ngClass]="{ active: rating.isChecked }"
                        >
                          <div class="mobile-stars">
                            <label
                              class="gray-text font-14 py-2 px-2 mb-0"
                              for="{{ rating.id }}"
                              ><input
                                type="checkbox"
                                id="{{ rating.id }}"
                                [checked]="rating.isChecked"
                                (change)="dataChanged($event, i)" />{{
                                rating.number
                              }}
                              <div
                                class="star-holder d-inline-block position-relative"
                              ></div
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="filter_group mb-5 mt-2"
                *ngIf="displayGoogleRatingFilter"
              >
                <h4 class="font-16 gray-text-dark" i18n="@@guestsRating">
                  Guests Rating
                </h4>

                <div class="row gutters-5">
                  <div
                    class="form-item mb-1 col"
                    *ngFor="let Review of ReviewArray"
                  >
                    <div
                      class="border rounded filter-box text-center"
                      (click)="ReviewChanged(Review.value)"
                      [ngClass]="{
                        active: ReviewValue <= Review.value && ReviewValue !== 0
                      }"
                    >
                      <label class="gray-text font-14 py-2 px-2 mt-1 mb-1"
                        >{{ Review.name }}
                        <div
                          class="star-holder d-inline-block position-relative"
                        ></div
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="filter_group mb-5 mt-2">
                <h4 class="font-16 gray-text-dark" i18n="@@priceRangeNightly">
                  Price Range (Nightly)
                </h4>
                <div class="d-flex">
                  <span
                    class="font-weight-bold gray-primaray-text"
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                    >{{ currency }} {{ minValue }}</span
                  >
                  <span class="font-weight-bold gray-primaray-text"
                    >{{ currency }} {{ maxValue
                    }}<span *ngIf="maxValue === 500">+</span></span
                  >
                </div>

                <ng5-slider
                  [(value)]="minValue"
                  [(highValue)]="maxValue"
                  [options]="optionsSlider"
                  (userChangeEnd)="PriceChangeEnd($event)"
                ></ng5-slider>
              </div>
              <div class="form-checkboxes">
                <div
                  class="form-item form-type-ccheckbox mb-3"
                  *ngIf="Service.isCompany() && showWithinPolicyFilter"
                  [ngClass]="{ active: filterWithinPolicyCheck }"
                >
                  <input
                    id="within-policy-mobile"
                    type="checkbox"
                    [(ngModel)]="filterWithinPolicy"
                    [checked]="filterWithinPolicy"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="filterWithinPolicyFunc()"
                  />
                  <label
                    for="within-policy-mobile"
                    class="gray-text-dark"
                    [ngClass]="{
                      'ml-2': Service.isSiteLtr(),
                      'mr-2': Service.isSiteRtl()
                    }"
                    i18n="@@showInPolicyHotelsOnly"
                    >Show in-policy hotels only</label
                  >
                </div>
              </div>
              <div class="filter_group mb-5 mt-2">
                <h4 class="font-16 gray-text-dark" i18n="@@popularFilters">
                  Popular Filters
                </h4>

                <div class="main">
                  <div>
                    <div class="form-checkboxes">
                      <div
                        class="form-item form-type-checkbox mb-2"
                        [ngClass]="{ active: filterFreeCancellationCheck }"
                      >
                        <input
                          id="freeCanMobile"
                          type="checkbox"
                          [(ngModel)]="filterFreeCancellation"
                          [checked]="filterFreeCancellation"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="filterFreeCancellationFunc()"
                        />
                        <label
                          for="freeCanMobile"
                          class="gray-text-dark"
                          i18n="@@freeCancellation"
                          >Free cancellation</label
                        >
                      </div>

                      <div
                        class="form-item form-type-checkbox mb-2"
                        *ngFor="
                          let Amenity of Amenities | slice : 0 : numbertoShow;
                          let i = index
                        "
                        [ngClass]="{ active: activecheckbox === i }"
                      >
                        <input
                          type="checkbox"
                          id="Q{{ Amenity.hrsCode }}"
                          [checked]="Amenity.isChecked"
                          (change)="dataChangedAmenities($event, i)"
                        />
                        <label
                          class="gray-text-dark"
                          for="Q{{ Amenity.hrsCode }}"
                          >{{ Amenity.title }}</label
                        >
                      </div>
                    </div>

                    <button
                      *ngIf="numbertoShow === 3"
                      class="btn btn-link blue-text karla-font"
                      (click)="numbertoShow = Amenities.length"
                      i18n="@@showMore"
                    >
                      Show more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="sticky-footer sorter margin_bottom mobile_only pt-3 pb-3"
            [ngClass]="{
              'pl-3 pr-3': Service.isSiteLtr(),
              'pr-3 pl-3': Service.isSiteRtl()
            }"
          >
            <div class="row gutters-5">
              <div
                class="col-6"
                [ngClass]="{
                  'pl-2': Service.isSiteLtr(),
                  'pr-2': Service.isSiteRtl()
                }"
              >
                <div
                  class="btn btn-outline-blue btn-block font-14"
                  *ngIf="filterPopUp"
                  (click)="clearFilters()"
                  i18n="@@reset"
                >
                  Reset
                </div>
              </div>
              <div class="col-6">
                <div
                  class="btn blue_btn btn-block blue_btn_form font-14"
                  (click)="
                    activePopUp = false;
                    sortPopUp = false;
                    filterPopUp = false;
                    this.Service.enableBodyScroll()
                  "
                  i18n="@@done"
                >
                  Done
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          class="back-to-top"
          type="button"
          [ngClass]="{
            in_range: this.Service.showScrolltop,
            'add-bottom-value':
              this.Service.showCookiesMsg ||
              (this.Service.unlockRateFromTop &&
                this.Service.hideStickyBottomBannerRates)
          }"
          (click)="this.Service.scrollTop()"
        >
          <i class="icon-arrow-up2"></i>
        </button>
      </div>
    </div>
  </div>
</div>
