import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-edit-payment-alias',
  templateUrl: './edit-payment-alias.component.html',
  styleUrls: ['./edit-payment-alias.component.scss']
})
export class EditPaymentAliasComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, public dataService: DataService, public router: Router, public auth: AuthServiceMain) { }
  editAliasForm: FormGroup;
  submitted = false;
  submitting = false;
  errorMessage;
  data;
  paymentObject: {};
  ngOnInit(): void {

    this.editAliasForm = this.formBuilder.group({
      alias: [''],
    }, {});
    if (this.dataService.companyPaymentOldAlias) {
      this.editAliasForm.get('alias').setValue(this.dataService.companyPaymentOldAlias);
    }
  }
  get f() {
    return this.editAliasForm.controls;
  }
  submitAliasForm() {
    this.paymentObject = {
      paymentGatewayProvider: this.dataService.companyPaymentGatewayForAlias,
      id: this.dataService.companyPaymentIdForAlias,
      alias: this.editAliasForm.controls.alias.value
    };
    this.dataService.updateCompanyPayment(this.paymentObject).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.listPaymentService();
          this.dataService.modalOf();
          this.dataService.BottomSheetOf();
        } else {
          this.errorMessage = this.data.message;
        }
      },
      error => {
        this.errorMessage = environment.messages.error;
      }
    );

  }
}
