
<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
                         [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
    <!--  <div class="gray-pass-bg border-0 py-3 pl-3" *ngIf="dataService.mobileView">
        <a class="font-18 blue-text font-18 primaray-text font-weight-bold" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);">Settings</a>
        <span class="font-18 gray-text-dark font-weight-bold"> / </span>
        <a class="font-18 blue-text font-18 primaray-text font-weight-bold" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/account']);">Account</a>
        <span class="font-18 gray-text-dark font-weight-bold"> / Transfer Ownership </span>
      </div>-->

      <div class="container pt-4 pt-lg-5 pb-0 pb-lg-5">
        <div class="mx-lg-5">
          <div class="row">
            <div class="col">
              <div class="pb-5 mb-3">
                <a class="font-16 blue-text text-underline" (click)="this.router.navigate(['/settings']);" i18n="@@menuItemSettings">Settings</a>
                <span class="font-16 gray-text-dark "> / </span>
                <a class="font-16 blue-text text-underline" (click)="this.router.navigate(['/settings/account']);" i18n="@@account">Account</a>
                <span class="font-16 gray-text-dark "> / <ng-container i18n="@@transferOwnership">Transfer Ownership</ng-container> </span>
              </div>
            </div>

          </div>
        <p class="font-20 gray-primaray-text font-weight-bold" i18n="@@transferWorkOwner">Transfer Workspace Ownership</p>
          <div *ngIf="!showConfirmation">
        <p *ngIf="this.auth.companyInfo" class="mb-5 mt-4 pt-2 gray-text-dark font-18" i18n="@@transferWorkOwner1">You can transfer the ownership to any member registered with {{this.auth.companyInfo.name}}. This action cannot be undone</p>
        <p class="gray-text-dark font-18" i18n="@@transferWorkOwner2">Select a member to whom you want to have the ownership </p>
          <mat-form-field  class="mb-3 border w-100 chips-container rounded" [ngClass]="{ 'red-border': submitted && !selectedUser }"
                           *ngIf="dataService.desktopView" (click)="userInput.select()">
            <input class="min-height mx-0 my-0"
                   [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                   #userInput type="text" matInput [formControl]="userControl" [matAutocomplete]="autoUser"
                   (blur)="reset();" >
            <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayFn">
              <mat-option style="padding: 0" *ngFor="let user of filteredUsers | async" [value]="user">
                <div (click)="optionClicked($event, user)" class="mat-checkbox-container">
                  {{user.description}}<br/><span class="font-12 gray-text">({{user.email}})</span>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="form-control rounded border" *ngIf="dataService.mobileView" (click)="showUsersPopUp()">
            <p *ngIf="selectedUser" class="mt-2 pt-1">{{selectedUser.description}}</p>
          </div>
          <div *ngIf="submitted && !selectedUser" class="smaller text-danger" i18n="@@memberNameRequired">
            Member name is required
          </div>
          <div class="row d-flex mb-3 mt-5">
            <div class="col-lg-2 order-lg-1" [ngClass]="{'pl-lg-2': dataService.isSiteLtr(), 'pr-lg-2': dataService.isSiteRtl()}">
              <button class="mb-3 mb-lg-5  mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0" (click)="showConfirm()">
                <span class="mx-auto font-14" i18n="@@nextBtn">Next</span>
              </button>
            </div>
            <div class="col-lg-2 pr-lg-2 order-lg-0">
              <button (click)="this.router.navigate(['/settings/account']);"
                      class="mb-5 mt-3 btn-block btn btn-outline-gray px-5 d-flex" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                <span class="mx-auto gray-text-dark font-14 sharp-font bold-500" i18n="@@cancelBtn">Cancel</span>
              </button>
            </div>
          </div>
          </div>
          <div *ngIf="showConfirmation">
            <p class="mb-5 mt-4 pt-2 gray-text-dark font-18" *ngIf="selectedUser"><ng-container i18n="@@confirmTransfer">Enter your password to confirm the transfer the ownership to</ng-container> {{selectedUser.description}} {{selectedUser.email}}
            </p>
            <form [formGroup]="transferFrom" name="userPasswordForm" (ngSubmit)="transferOwnership()">
              <div class="form-group with-icon mb-0  form-type-password form-name-password">
                <input   autocapitalize="off"  autocomplete="off"  type="password" name="password"  class="form-control" formControlName="password" i18n-placeholder="@@currentPassword" placeholder="Current password" [type]="this.dataService.isTextFieldType ? 'password' : 'text'"
                         minlength="6" [ngClass]="{ 'is-invalid': submitted && c.password.errors }"/>
                <i class="pointer" (click)="this.dataService.togglePasswordFieldType()"  [ngClass]="this.dataService.isTextFieldType ? 'icon-eye' : 'icon-eye-blocked'"></i>


              </div>
              <div *ngIf="submitted && c.password.errors" class="text-danger smaller">
                <div *ngIf="c.password.errors.required" i18n="@@requiredPasswordError">Password is required</div>
                <div class="password-error-msg" *ngIf="!c.password.errors.required && c.password.value.length < 6" i18n="@@wrongPassword">Wrong password</div>
              </div>
            </form>
            <div class="row d-flex mb-3 mt-5">
              <div class="col-lg-2 order-lg-1" [ngClass]="{' pl-lg-2': dataService.isSiteLtr(), 'pr-lg-2': dataService.isSiteRtl()}">
                <button class="mb-3 mb-lg-5  mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0" [ngClass]="{'m-progress': submitting }" (click)="transferOwnership()">
                  <span class="mx-auto font-14" i18n="@@confirmBtn">Confirm</span>
                </button>
              </div>
              <div class="col-lg-2 pr-lg-2 order-lg-0">
                <button (click)="showConfirmation = false"
                        class="mb-5 mt-3 btn-block btn btn-outline-gray px-5 d-flex" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                  <span class="mx-auto gray-text-dark font-14 sharp-font bold-500" i18n="@@backBtn">Back</span>
                </button>
              </div>
            </div>
          </div>
      </div>
      </div>



    </div>
  </mat-sidenav-content>
</mat-sidenav-container>



<div class="justify-content-end create-team" >

  <div [ngClass]="{'in action-popup':showUsersAutoCompletePopUp}">
    <div [ngClass]="{'header':showUsersAutoCompletePopUp}" >
      <div *ngIf="showUsersAutoCompletePopUp">
        <div *ngIf="showUsersAutoCompletePopUp">

          <div class="info" [ngClass]="{'pay-and-member' : showUsersAutoCompletePopUp }">
            <span i18n="@@memberName">Member Name</span>
          </div>
          <div class="close" (click)="showUsersAutoCompletePopUp = false; this.dataService.enableBodyScroll();" i18n="@@cancelBtn">Cancel</div>
        </div>
      </div>




      <div class="position-relative mobile-search-field" *ngIf="showUsersAutoCompletePopUp">
        <div class="form-group mb-3">
          <mat-form-field class="w-100 chips-container border rounded">
            <input class="bg-white min-height mx-0 my-0"
                   [ngClass]="{' pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                   #userInput id="userInput" type="text" matInput [formControl]="userControl" [matAutocomplete]="autoUser" (blur)="reset()">
            <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayFn">
              <mat-option style="padding: 0" *ngFor="let user of filteredUsers | async" [value]="user">
                <div (click)="optionClicked($event, user)" class="mat-checkbox-container">
                  {{user.description}}<br/><span class="font-12 gray-text">{{user.email}}</span>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </div>
      </div>





    </div>



  </div>
</div>

