import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor( public router: Router,  public  Service: DataService) { }
  cYear;
  env = environment;
  ngOnInit() {

  this.cYear =   new Date().getFullYear()
  }

}
