import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  data;
  ereeorMSG = '';
  constructor(private http: HttpClient, public  router: Router, private formBuilder: FormBuilder, public  restApi: DataService ) { }
  forgotPassword: FormGroup;
  submitted = false;
  submitting = false;
  ngOnInit() {
    this.forgotPassword = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    }, {
    });
  }
  get f() { return this.forgotPassword.controls; }
  onSubmit() {
    this.submitting = true;
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgotPassword.invalid) {
      this.submitting = false;
      return false;
    }

    this.restApi.forgotPasswrod(this.forgotPassword.controls.email.value).subscribe(
      success => {
        this.data = success;

        if (this.data.errorCode === 0) {

          this.ereeorMSG = '';
          this.restApi.SnakBar(this.data.message);

          if(this.restApi.mobileView){


            if(this.restApi.urlWithoutParams !== '/pass/preorder/login')
            {
              this.router.navigate(['/user/login']);

            } else{
              this.restApi.modalOf();

            }


          } else {

            this.restApi.modalOf();

          }
          this.submitting = false;
        } else {
          this.submitting = false;
          this.ereeorMSG = this.data.message;


        }

      }, err=> {
        this.submitting = false;

        this.ereeorMSG = environment.messages.error


      }



    );


  }
}
