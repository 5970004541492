import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DataService } from "../../../../services/data.service";
import { Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { AuthServiceMain } from "../../../../auth/auth.service";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { VariablesService } from "../../../../services/vars.service";
import { SidenavService } from "../../../workspace-dashboard/sidenav.service";
import { onMainContentChange } from "../../../workspace-dashboard/animations/animations";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-create-edit-policy-page",
  templateUrl: "./create-edit-policy-page.component.html",
  styleUrls: ["./create-edit-policy-page.component.scss"],
  animations: [onMainContentChange],
})
export class CreateEditPolicyPageComponent implements OnInit {
  constructor(
    private titleService: Title,
    private sidenavService: SidenavService,
    public vars: VariablesService,
    public auth: AuthServiceMain,
    private formBuilder: FormBuilder,
    public dataService: DataService,
    public router: Router
  ) {
    this.sidenavService.sideNavState$.subscribe((res) => {
      this.dataService.onSideNavChange = res;
    });
  }
  disableHotelPolicy = false;
  disableFlightPolicy = false;
  showIntervalList = false;
  tempArrayForCreate = [];
  defaultRequired = false;
  editData;
  priceCap;
  Query = "";
  SearchTextQuery = new Subject<string>();
  caption = "";
  autocompletelist;
  showDropdown = false;
  citiesList = [];
  showAutoCompleteField = false;
  @Input() policyId: string;
  focusInputMobile;
  urlArray;
  createPolicyForm: FormGroup;
  submitting;
  submitted;
  policyInfo;
  data;
  editPolicyName = false;
  editPolicyLimit = false;
  editFairClass = false;
  editMaxInterval = false;
  editMaxNoOfTrips = false;
  editFlightLimit = false;
  editInternationalLimit = false;
  editDomesticLimit = false;
  editCitiesLimit = [];
  cityLoaders = [];
  preferencesList;
  errorMessage;
  showPolicyNamePopUpFlag = false;
  showPolicyLimitPopUpFlag = false;
  showSearchPopUpautocompleteFlag = false;
  showDomesticPriceCapFlag = false;
  showInternationalPriceCapFlag = false;
  showFlightPriceCapFlag = false;
  showCityPolicyLimitPopUpFlag = [];
  checkboxLoader;
  nameLoader;
  limitLoader;
  priceCapLoader;
  priceCapCurrencyLoader;
  fairClassLoader;
  tripsMaxNoLoader;
  maxIntervalLoader;
  hotelToggleLoader;
  flightToggleLoader;
  internationalPriceCapLoader;
  domesticPriceCapLoader;
  selectedCityIndex;
  cityLimitMobileErrorMsg;
  currenciesList = [];
  options: any = [
    {
      id: "NO_APPROVAL_REQUIRED",
      name: $localize`:@@noApproval:No approval`,
      isChecked: false,
    },
    {
      id: "RULES_VIOLATION_APPROVAL_REQUIRED",
      name: $localize`:@@outOfPolicy:Out of policy`,
      note: $localize`:@@recommended:Recommended`,
      isChecked: false,
    },
    {
      id: "ALWAYS_APPROVAL_REQUIRED",
      name: $localize`:@@allTrips:All trips`,
      isChecked: false,
    },
  ];
  selectedId = "RULES_VIOLATION_APPROVAL_REQUIRED";
  selectedFairClass = "ECONOMY";
  selectedMaxInterval = "MONTHLY";
  showSelectedSectionHotel = "";
  showSelectedSectionFlight = "";
  policyObj = {
    approvalProcessType: "",
    companyId: "",
    companyPolicyFlightRules: {
      domesticPriceLimitPerPassenger: 0,
      internationalPriceLimitPerPassenger: 0,
      maximumCabinClass: "",
      internationalPriceLimitPerPassengerCurrency: "USD",
      domesticPriceLimitPerPassengerCurrency: "USD",
    },
    companyPolicyHotelRules: {
      avgNightlyPriceLimit: 0,
      avgNightlyPriceLimitCurrency: "",
      exceptions: [],
    },
    name: "",
    flightBookingNotAllowed: false,
    hotelBookingNotAllowed: false,
  };
  policyName;
  ngOnInit(): void {
    this.dataService.createEditPolicyPage = true;
    this.getPreferences();
    this.initPage();
    this.initRoute();
    this.initForm();
    this.getPolicyData();
    this.initSearchQuery();
  }

  getPreferences() {
    this.dataService.getPreferencesList().subscribe(
      (result) => {
        this.preferencesList = result;
        if (this.preferencesList.errorCode === 0) {
          this.initData();
        } else {
          this.handleError();
        }
      },
      () => {}
    );
  }

  initData() {
    this.currenciesList = this.preferencesList.data.currencies;
  }

  handleError() {
    this.errorMessage = this.preferencesList.message;
  }

  initPage() {
    if (this.dataService.desktopView && this.dataService.createEditPolicyPage) {
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("workspace-page");
    }
  }

  initRoute() {
    this.urlArray = this.router.url.split("/");
    if (
      this.router.url.includes("edit") &&
      this.router.url.includes("travel-policies")
    ) {
      // as no directive in this case (page in mobile view not a modal)
      this.policyId = this.urlArray[this.urlArray.length - 2];
    }
  }

  initForm() {
    this.createPolicyForm = this.formBuilder.group(
      {
        policyName: ["", [Validators.required]],
        fairClass: ["ECONOMY"],
        maxInterval: ["MONTHLY"],
        flightPolicyLimit: [""],
        flightPolicyLimitCurrency: [""],
        policyLimit: [""],
        policyLimitCurrency: [""],
        maximumNumberOfTrips: [""],
        hotelAllowedToggle: [true],
        flightAllowedToggle: [true],
      },
      {}
    );
  }

  getPolicyData() {
    if (this.policyId) {
      this.dataService.getPolicyDetails(this.policyId).subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.policyName = this.data.data.policy.name;
            this.policyInfo = this.data.data.policy;
            this.policyInfo.flightBookingNotAllowed =
              this.data.data.policy.flightBookingNotAllowed;
            this.policyInfo.hotelBookingNotAllowed =
              this.data.data.policy.hotelBookingNotAllowed;
            if (this.policyInfo.flightBookingNotAllowed === true) {
              this.createPolicyForm.get("flightAllowedToggle").setValue(false);
            } else {
              this.createPolicyForm.get("flightAllowedToggle").setValue(true);
            }
            if (this.policyInfo.hotelBookingNotAllowed === true) {
              this.createPolicyForm.get("hotelAllowedToggle").setValue(false);
            } else {
              this.createPolicyForm.get("hotelAllowedToggle").setValue(true);
            }

            this.createPolicyForm
              .get("policyName")
              .setValue(this.data.data.policy.name);
            this.createPolicyForm
              .get("policyLimit")
              .setValue(
                this.data.data.policy.companyPolicyHotelRules
                  .avgNightlyPriceLimit
              );
            this.createPolicyForm
              .get("policyLimitCurrency")
              .setValue(
                this.data.data.policy.companyPolicyHotelRules
                  .avgNightlyPriceLimitCurrency
              );
            if (
              (this.data.data.policy.companyPolicyFlightRules
                .domesticPriceLimitPerPassenger ===
              this.data.data.policy.companyPolicyFlightRules
                .internationalPriceLimitPerPassenger) && (this.data.data.policy.companyPolicyFlightRules
                  .domesticPriceLimitPerPassengerCurrency ===
                this.data.data.policy.companyPolicyFlightRules
                  .internationalPriceLimitPerPassengerCurrency)
            ) {
              this.priceCap = false;
              this.createPolicyForm
                .get("flightPolicyLimit")
                .setValue(
                  this.data.data.policy.companyPolicyFlightRules
                    .internationalPriceLimitPerPassenger
                );
              this.createPolicyForm
                .get("flightPolicyLimitCurrency")
                .setValue(
                  this.data.data.policy.companyPolicyFlightRules
                    .internationalPriceLimitPerPassengerCurrency
                );
            } else {
              this.priceCap = true;
              this.createPolicyForm.controls["flightPolicyLimit"].disable();
              this.createPolicyForm.controls["flightPolicyLimitCurrency"].disable();
              this.createPolicyForm.addControl(
                "internationalPriceCap",
                new FormControl(
                  this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassenger
                )
              );
              this.createPolicyForm.addControl(
                "internationalPriceCapCurrency",
                new FormControl(
                  this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency
                )
              );
              this.createPolicyForm.addControl(
                "domesticPriceCap",
                new FormControl(
                  this.data.data.policy.companyPolicyFlightRules.domesticPriceLimitPerPassenger
                )
              );
              this.createPolicyForm.addControl(
                "domesticPriceCapCurrency",
                new FormControl(
                  this.data.data.policy.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency
                )
              );
            }
            this.selectedId = this.data.data.policy.approvalProcessType;
            this.selectedFairClass =
              this.data.data.policy.companyPolicyFlightRules.maximumCabinClass;

            if (
              this.data.data.policy.companyPolicyFlightRules
                .maximumNumberOfTrips
            ) {
              this.showIntervalList = true;
              this.selectedMaxInterval =
                this.data.data.policy.companyPolicyFlightRules.maximumNumberOfTripsInterval;
              this.createPolicyForm
                .get("maximumNumberOfTrips")
                .setValue(
                  this.data.data.policy.companyPolicyFlightRules
                    .maximumNumberOfTrips
                );
            }

            this.data.data.policy.companyPolicyHotelRules.exceptions.forEach(
              (element, index) => {
                this.createPolicyForm.addControl(
                  "cityLimit" + index,
                  new FormControl(
                    element.avgNightlyPriceLimit,
                    Validators.required
                  )
                );
                this.createPolicyForm.addControl(
                  "cityLimitCurrency" + index,
                  new FormControl(
                    element.avgNightlyPriceLimitCurrency,
                    Validators.required
                  )
                );
                this.citiesList.push({
                  caption: element.caption,
                  code: element.code,
                  type: element.type,
                });
              }
            );
          } else {
            this.dataService.messages(this.data.message, "error");
          }
        },
        (error) => {
          this.dataService.messages(environment.messages.error, "error");
        }
      );
      this.titleService.setTitle("Edit travel policy | SafarHub");
    }
    if (!this.policyId) {
      this.titleService.setTitle("Create travel policy | SafarHub");
    }
  }

  initSearchQuery() {
    this.SearchTextQuery.pipe(
      // get value
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.Query = value;
      this.autoCompleteList();
    });
  }

  requireApprovalType(name) {
    this.selectedId = name;
    if (this.policyId) {
      this.editPolicy("approvals", null, null);
    }
  }

  get f() {
    return this.createPolicyForm.controls;
  }

  setSelectedFairClass() {
    this.selectedFairClass = this.createPolicyForm.get("fairClass").value;
    if (this.policyId) {
      this.editPolicy("fairClass", null, null);
    }
  }

  setSelectedMaxInterval() {
    this.selectedMaxInterval = this.createPolicyForm.get("maxInterval").value;
    if (this.policyId) {
      this.editPolicy("maxInterval", null, null);
    }
  }

  showNewFields() {
    if (this.policyId) {
      if (this.dataService.desktopView) {
        if (this.priceCap) {
          this.createPolicyForm.controls['flightPolicyLimit'].disable();
          this.createPolicyForm.controls['flightPolicyLimitCurrency'].disable();
          if (
            this.data.data.policy.companyPolicyFlightRules
              .domesticPriceLimitPerPassenger ===
            this.data.data.policy.companyPolicyFlightRules
              .internationalPriceLimitPerPassenger
          ) {
            this.createPolicyForm.addControl(
              "internationalPriceCap",
              new FormControl(this.createPolicyForm.controls['flightPolicyLimit'].value)
            );
            this.createPolicyForm.addControl(
              "internationalPriceCapCurrency",
              new FormControl(this.createPolicyForm.controls['flightPolicyLimitCurrency'].value)
            );
            this.createPolicyForm.addControl(
              "domesticPriceCap",
              new FormControl(this.createPolicyForm.controls['flightPolicyLimit'].value)
            );
            this.createPolicyForm.addControl(
              "domesticPriceCapCurrency",
              new FormControl(this.createPolicyForm.controls['flightPolicyLimitCurrency'].value)
            );
            this.editInternationalLimit = true;
            this.editDomesticLimit = true;
          } else {
            this.createPolicyForm.addControl(
              "internationalPriceCap",
              new FormControl(
                this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassenger
              )
            );
            this.createPolicyForm.addControl(
              "internationalPriceCapCurrency",
              new FormControl(
                this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency
              )
            );
            this.createPolicyForm.addControl(
              "domesticPriceCap",
              new FormControl(
                this.data.data.policy.companyPolicyFlightRules.domesticPriceLimitPerPassenger
              )
            );
            this.createPolicyForm.addControl(
              "domesticPriceCapCurrency",
              new FormControl(
                this.data.data.policy.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency
              )
            );
          }
        } else {
          this.createPolicyForm.controls['flightPolicyLimit'].setValue(this.createPolicyForm.controls['internationalPriceCap'].value);
          this.createPolicyForm.controls['flightPolicyLimitCurrency'].setValue(this.createPolicyForm.controls['internationalPriceCapCurrency'].value);
          this.createPolicyForm.removeControl("internationalPriceCap");
          this.createPolicyForm.removeControl("domesticPriceCap");
          this.createPolicyForm.removeControl("domesticPriceCapCurrency");
          this.createPolicyForm.removeControl("internationalPriceCapCurrency");
          this.createPolicyForm.controls['flightPolicyLimit'].enable();
          this.createPolicyForm.controls['flightPolicyLimitCurrency'].enable();
          this.editPolicy('flightPolicyLimit', null, null);
          this.editFlightLimit = true;
          setTimeout(() => {
            const input = document.getElementById(
              "flightPolicyLimit"
            ) as HTMLInputElement;
            if (input) {
              input.focus();
            }
          }, 200);
        }
      }
    } else {
      if (this.priceCap) {
        this.createPolicyForm.controls['flightPolicyLimit'].disable();
          this.createPolicyForm.controls['flightPolicyLimitCurrency'].disable();
        this.createPolicyForm.addControl(
          "internationalPriceCap",
          new FormControl(this.createPolicyForm.controls['flightPolicyLimit'].value)
        );
        this.createPolicyForm.addControl(
          "internationalPriceCapCurrency",
          new FormControl(this.createPolicyForm.controls['flightPolicyLimitCurrency'].value)
        );
        this.createPolicyForm.addControl(
          "domesticPriceCap",
          new FormControl(this.createPolicyForm.controls['flightPolicyLimit'].value)
        );
        this.createPolicyForm.addControl(
          "domesticPriceCapCurrency",
          new FormControl(this.createPolicyForm.controls['flightPolicyLimitCurrency'].value)
        );
      } else {
        this.createPolicyForm.controls['flightPolicyLimit'].enable();
        this.createPolicyForm.controls['flightPolicyLimitCurrency'].enable();
        this.createPolicyForm.removeControl("internationalPriceCap");
        this.createPolicyForm.removeControl("domesticPriceCap");
        this.createPolicyForm.removeControl("internationalPriceCap");
        this.createPolicyForm.removeControl("domesticPriceCap");
      }
    }
  }

  cancelCreate() {
    if (
      this.dataService.desktopView &&
      !this.dataService.createEditPolicyPage
    ) {
      this.dataService.modalOf();
    } else {
      this.router.navigate([this.router.url.replace("/create", "")]);
    }
  }

  createPolicy() {
    this.submitted = true;
    this.submitting = true;
    if (this.createPolicyForm.invalid) {
      this.submitting = false;
      this.dataService.scrollTop();
      return;
    }
    this.policyObj.name = this.createPolicyForm.controls.policyName.value;
    this.policyObj.hotelBookingNotAllowed =
      !this.createPolicyForm.controls.hotelAllowedToggle.value;
    this.policyObj.flightBookingNotAllowed =
      !this.createPolicyForm.controls.flightAllowedToggle.value;
    this.policyObj.approvalProcessType = this.selectedId;
    this.policyObj.companyId = this.auth.companyInfo.id;
    this.policyObj.companyPolicyFlightRules.maximumCabinClass =
      this.selectedFairClass;
    if (!this.priceCap) {
      this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassenger =
        this.createPolicyForm.controls.flightPolicyLimit.value;
      this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassenger =
        this.createPolicyForm.controls.flightPolicyLimit.value;
        this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency =
        this.createPolicyForm.controls.flightPolicyLimitCurrency.value;
      this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency =
        this.createPolicyForm.controls.flightPolicyLimitCurrency.value;
    } else {
      this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassenger =
        this.createPolicyForm.controls.domesticPriceCap.value;
      this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency =
        this.createPolicyForm.controls.domesticPriceCapCurrency.value;
      this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency =
        this.createPolicyForm.controls.internationalPriceCapCurrency.value;
      this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassenger =
        this.createPolicyForm.controls.internationalPriceCap.value;
    }
    this.policyObj.companyPolicyHotelRules.avgNightlyPriceLimit =
      this.createPolicyForm.controls.policyLimit.value;
    this.policyObj.companyPolicyHotelRules.avgNightlyPriceLimitCurrency =
      this.createPolicyForm.controls.policyLimitCurrency.value;
    this.policyObj.companyPolicyHotelRules.exceptions = [];
    this.citiesList.forEach((element, index) => {
      const obj = {
        avgNightlyPriceLimit:
          this.createPolicyForm.controls["cityLimit" + index].value,
        avgNightlyPriceLimitCurrency:
          this.createPolicyForm.controls["cityLimitCurrency" + index].value,
        caption: element.caption,
        code: element.code,
        type: element.type,
      };
      this.policyObj.companyPolicyHotelRules.exceptions.push(obj);
    });
    if (
      !this.createPolicyForm.get("policyLimit").value &&
      this.citiesList.length
    ) {
      this.createPolicyForm
        .get("policyLimit")
        .setValidators([Validators.required]);
      this.createPolicyForm.get("policyLimit").updateValueAndValidity();
      this.submitting = false;
      this.editPolicyLimit = true;
      return;
    }
    if (
      !this.createPolicyForm.get("policyLimitCurrency").value &&
      this.citiesList.length
    ) {
      this.createPolicyForm
        .get("policyLimitCurrency")
        .setValidators([Validators.required]);
      this.createPolicyForm.get("policyLimitCurrency").updateValueAndValidity();
      this.submitting = false;
      this.editPolicyLimit = true;
      return;
    }
    if (this.createPolicyForm.get("maximumNumberOfTrips").value) {
      this.policyObj.companyPolicyFlightRules["maximumNumberOfTrips"] =
        this.createPolicyForm.get("maximumNumberOfTrips").value;
      this.policyObj.companyPolicyFlightRules["maximumNumberOfTripsInterval"] =
        this.selectedMaxInterval;
    }
    this.dataService.createPolicy(this.policyObj).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.cancelCreate();
        } else {
          this.submitting = false;
          this.errorMessage = this.data.message;
        }
      },
      (error) => {
        this.submitting = false;
        this.errorMessage = environment.messages.error;
      }
    );
  }
  mobileFocus() {
    if (this.showPolicyLimitPopUpFlag || this.showPolicyNamePopUpFlag) {
      setTimeout(() => {
        if (this.showPolicyLimitPopUpFlag) {
          this.focusInputMobile = document.getElementById(
            "limitInput"
          ) as HTMLInputElement;
        }
        if (this.showPolicyNamePopUpFlag) {
          this.focusInputMobile = document.getElementById(
            "nameInput"
          ) as HTMLInputElement;
        }
        this.focusInputMobile.focus();
        this.focusInputMobile.select();
      }, 200);
    }
  }
  hidePopUp() {
    this.cityLimitMobileErrorMsg = "";
    if (
      this.showCityPolicyLimitPopUpFlag[this.selectedCityIndex] &&
      !this.isValid("cityLimit" + this.selectedCityIndex)
    ) {
      this.cityLimitMobileErrorMsg = "Limit is required";
    } else if (this.createPolicyForm.invalid) {
      return;
    } else {
      this.showPolicyNamePopUpFlag = false;
      this.showPolicyLimitPopUpFlag = false;
      this.showSearchPopUpautocompleteFlag = false;
      this.showFlightPriceCapFlag = false;
      this.showInternationalPriceCapFlag = false;
      this.showDomesticPriceCapFlag = false;
      this.showCityPolicyLimitPopUpFlag[this.selectedCityIndex] = false;
      if (this.dataService.mobileView) {
        this.dataService.enableBodyScroll();
      }
    }
  }
  showPolicyNamePopUp() {
    this.showPolicyNamePopUpFlag = true;
    this.dataService.disableBodyScroll();
    this.createPolicyForm
      .get("policyName")
      .setValue(this.data.data.policy.name);
    setTimeout(() => {
      const input = document.getElementById("nameInput") as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }
  showDomesticPolicyLimitPopUp() {
    this.showDomesticPriceCapFlag = true;
    this.dataService.disableBodyScroll();
    this.createPolicyForm.controls["flightPolicyLimit"].disable();
    this.createPolicyForm.controls["flightPolicyLimitCurrency"].disable();
    if (this.createPolicyForm.get("domesticPriceCap")) {
      this.createPolicyForm
        .get("domesticPriceCap")
        .setValue(
          this.data.data.policy.companyPolicyFlightRules
            .domesticPriceLimitPerPassenger
        );
      this.createPolicyForm
        .get("domesticPriceCapCurrency")
        .setValue(
          this.data.data.policy.companyPolicyFlightRules
            .domesticPriceLimitPerPassengerCurrency
        );
    } else {
      this.createPolicyForm.addControl(
        "domesticPriceCap",
        new FormControl(
          this.data.data.policy.companyPolicyFlightRules.domesticPriceLimitPerPassenger
        )
      );
      this.createPolicyForm.addControl(
        "domesticPriceCapCurrency",
        new FormControl(
          this.data.data.policy.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency
        )
      );
    }
    setTimeout(() => {
      const input = document.getElementById(
        "domesticPriceCap"
      ) as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }
  showInternationalPolicyLimitPopUp() {
    this.showInternationalPriceCapFlag = true;
    this.dataService.disableBodyScroll();
    this.createPolicyForm.controls["flightPolicyLimit"].disable();
    this.createPolicyForm.controls["flightPolicyLimitCurrency"].disable();
    if (this.createPolicyForm.get("internationalPriceCap")) {
      this.createPolicyForm
        .get("internationalPriceCap")
        .setValue(
          this.data.data.policy.companyPolicyFlightRules
            .internationalPriceLimitPerPassenger
        );
      this.createPolicyForm
        .get("internationalPriceCapCurrency")
        .setValue(
          this.data.data.policy.companyPolicyFlightRules
            .internationalPriceLimitPerPassengerCurrency
        );
    } else {
      this.createPolicyForm.addControl(
        "internationalPriceCap",
        new FormControl(
          this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassenger
        )
      );
      this.createPolicyForm.addControl(
        "internationalPriceCapCurrency",
        new FormControl(
          this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency
        )
      );
    }

    setTimeout(() => {
      const input = document.getElementById(
        "internationalPriceCap"
      ) as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }

  showFlightPolicyLimitPopUp() {
    this.showFlightPriceCapFlag = true;
    this.dataService.disableBodyScroll();
    this.createPolicyForm.removeControl("internationalPriceCap");
    this.createPolicyForm.removeControl("domesticPriceCap");
    this.createPolicyForm.removeControl("domesticPriceCapCurrency");
    this.createPolicyForm.removeControl("internationalPriceCapCurrency");
    if (this.createPolicyForm.get("flightPolicyLimit")) {
      this.createPolicyForm
        .get("flightPolicyLimit")
        .setValue(
          this.data.data.policy.companyPolicyFlightRules
            .internationalPriceLimitPerPassenger
        );
    } else {
      this.createPolicyForm.addControl(
        "flightPolicyLimit",
        new FormControl(
          this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassenger
        )
      );
    }
    if (this.createPolicyForm.get("flightPolicyLimitCurrency")) {
      this.createPolicyForm
        .get("flightPolicyLimitCurrency")
        .setValue(
          this.data.data.policy.companyPolicyFlightRules
            .internationalPriceLimitPerPassengerCurrency
        );
    } else {
      this.createPolicyForm.addControl(
        "flightPolicyLimitCurrency",
        new FormControl(
          this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency
        )
      );
    }

    setTimeout(() => {
      const input = document.getElementById(
        "flightPolicyLimit"
      ) as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }
  showPolicyLimitPopUp() {
    this.showPolicyLimitPopUpFlag = true;
    this.dataService.disableBodyScroll();
    this.createPolicyForm
      .get("policyLimit")
      .setValue(
        this.data.data.policy.companyPolicyHotelRules.avgNightlyPriceLimit
      );
    this.createPolicyForm
      .get("policyLimitCurrency")
      .setValue(
        this.data.data.policy.companyPolicyHotelRules
          .avgNightlyPriceLimitCurrency
      );
    setTimeout(() => {
      const input = document.getElementById("limitInput") as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }
  restorePolicy(fieldName, fieldIndex) {
    if (this.policyId) {
      if (fieldName === "name") {
        this.createPolicyForm.get("policyName").setValue(this.policyInfo.name);
      }
      if (fieldName === "hotelLimit") {
        this.createPolicyForm
          .get("hotelLimit")
          .setValue(
            this.policyInfo.companyPolicyHotelRules.avgNightlyPriceLimit
          );
      }
      if (fieldName === "fairClass") {
        this.createPolicyForm
          .get("fairClass")
          .setValue(this.policyInfo.companyPolicyFlightRules.maximumCabinClass);
      }
      if (fieldName === "maxInterval") {
        this.createPolicyForm
          .get("maxInterval")
          .setValue(
            this.policyInfo.companyPolicyFlightRules
              .maximumNumberOfTripsInterval
          );
      }
      if (fieldName === "maximumNumberOfTrips") {
        this.createPolicyForm
          .get("maximumNumberOfTrips")
          .setValue(
            this.policyInfo.companyPolicyFlightRules.maximumNumberOfTrips
          );
      }
      if (fieldName === "flightLimit") {
        this.createPolicyForm
          .get("flightLimit")
          .setValue(
            this.policyInfo.companyPolicyFlightRules
              .internationalPriceLimitPerPassenger
          );
      }
      if (fieldName === "flightPolicyLimitCurrency") {
        this.createPolicyForm
          .get("flightPolicyLimitCurrency")
          .setValue(
            this.policyInfo.companyPolicyFlightRules
              .internationalPriceLimitPerPassengerCurrency
          );
      }
      if (fieldName === "internationalLimit") {
        this.createPolicyForm
          .get("internationalLimit")
          .setValue(
            this.policyInfo.companyPolicyFlightRules
              .internationalPriceLimitPerPassenger
          );
      }
      if (fieldName === "internationalPriceCapCurrency") {
        this.createPolicyForm
          .get("internationalPriceCapCurrency")
          .setValue(
            this.policyInfo.companyPolicyFlightRules
              .internationalPriceLimitPerPassengerCurrency
          );
      }
      if (fieldName === "domesticPriceCapCurrency") {
        this.createPolicyForm
          .get("domesticPriceCapCurrency")
          .setValue(
            this.policyInfo.companyPolicyFlightRules
              .domesticPriceLimitPerPassengerCurrency
          );
      }
      if (fieldName === "domesticLimit") {
        this.createPolicyForm
          .get("domesticLimit")
          .setValue(
            this.policyInfo.companyPolicyFlightRules
              .domesticPriceLimitPerPassenger
          );
      }
      if (
        fieldName === "cityLimit" &&
        this.policyInfo.companyPolicyHotelRules.exceptions[fieldIndex]
      ) {
        this.createPolicyForm
          .get("cityLimit" + fieldIndex)
          .setValue(
            this.policyInfo.companyPolicyHotelRules.exceptions[fieldIndex]
              .avgNightlyPriceLimit
          );
      }
      if (
        fieldName === "cityLimitCurrency" &&
        this.policyInfo.companyPolicyHotelRules.exceptions[fieldIndex]
      ) {
        this.createPolicyForm
          .get("cityLimitCurrency" + fieldIndex)
          .setValue(
            this.policyInfo.companyPolicyHotelRules.exceptions[fieldIndex]
              .avgNightlyPriceLimitCurrency
          );
      }
    }
  }

  removeMaxNoOfTrips() {
    this.showIntervalList = false;
    this.createPolicyForm.get("maximumNumberOfTrips").setValue(null);
    const input = document.getElementById(
      "maximumNumberOfTrips"
    ) as HTMLInputElement;
    input.blur();
    this.editPolicy("maximumNumberOfTrips", null, null);
  }

  editPolicy(fieldName, fieldIndex, removeItem) {
    if (this.policyId) {
      if (fieldName === "name") {
        if (
          this.createPolicyForm.controls.policyName.value ===
          this.policyInfo.name
        ) {
          this.editPolicyName = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "hotelLimit") {
        if (
          !this.policyInfo.companyPolicyHotelRules.exceptions ||
          !this.policyInfo.companyPolicyHotelRules.exceptions.length
        ) {
          this.emptyCityField();
        } else if (
          this.policyInfo.companyPolicyHotelRules.exceptions &&
          this.policyInfo.companyPolicyHotelRules.exceptions.length &&
          !this.createPolicyForm.get("policyLimit").value
        ) {
          this.createPolicyForm
            .get("policyLimit")
            .setValidators([Validators.required]);
          this.createPolicyForm.get("policyLimit").updateValueAndValidity();
          this.defaultRequired = true;
          this.editPolicyLimit = true;
          return;
        }
        if (
          this.createPolicyForm.controls.policyLimit.value ===
          this.policyInfo.companyPolicyHotelRules.avgNightlyPriceLimit
        ) {
          this.editPolicyLimit = false;
          this.defaultRequired = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "hotelLimitCurrency") {
        if (
          !this.policyInfo.companyPolicyHotelRules.exceptions ||
          !this.policyInfo.companyPolicyHotelRules.exceptions.length
        ) {
          this.emptyCityField();
        } else if (
          this.policyInfo.companyPolicyHotelRules.exceptions &&
          this.policyInfo.companyPolicyHotelRules.exceptions.length &&
          !this.createPolicyForm.get("policyLimitCurrency").value
        ) {
          this.createPolicyForm
            .get("policyLimitCurrency")
            .setValidators([Validators.required]);
          this.createPolicyForm
            .get("policyLimitCurrency")
            .updateValueAndValidity();
          this.defaultRequired = true;
          this.editPolicyLimit = true;
          return;
        }
        if (
          this.createPolicyForm.controls.policyLimitCurrency.value ===
          this.policyInfo.companyPolicyHotelRules.avgNightlyPriceLimitCurrency
        ) {
          this.editPolicyLimit = false;
          this.defaultRequired = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "fairClass") {
        if (
          this.createPolicyForm.controls.fairClass.value ===
          this.policyInfo.companyPolicyFlightRules.maximumCabinClass
        ) {
          this.editFairClass = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "flightLimit") {
        if (
          this.createPolicyForm.controls.flightPolicyLimit.value ===
            this.policyInfo.companyPolicyFlightRules
              .domesticPriceLimitPerPassenger &&
          this.createPolicyForm.controls.flightPolicyLimit.value ===
            this.policyInfo.companyPolicyFlightRules
              .internationalPriceLimitPerPassenger
        ) {
          this.editFairClass = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "flightPolicyLimitCurrency") {
        if (
          this.createPolicyForm.controls.flightPolicyLimitCurrency.value ===
            this.policyInfo.companyPolicyFlightRules
              .internationalPriceLimitPerPassengerCurrency
        ) {
          this.editFairClass = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "maxInterval") {
        if (
          this.createPolicyForm.controls.maxInterval.value ===
          this.policyInfo.companyPolicyFlightRules.maximumNumberOfTripsInterval
        ) {
          this.editMaxInterval = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "maximumNumberOfTrips") {
        if (
          this.createPolicyForm.controls.maximumNumberOfTrips.value ===
          this.policyInfo.companyPolicyFlightRules.maximumNumberOfTrips
        ) {
          this.editMaxNoOfTrips = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "internationalLimit") {
        if (
          this.createPolicyForm.controls.internationalPriceCap.value ===
          this.policyInfo.companyPolicyFlightRules
            .internationalPriceLimitPerPassenger
        ) {
          this.editInternationalLimit = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "internationalPriceCapCurrency") {
        if (
          this.createPolicyForm.controls.internationalPriceCapCurrency.value ===
          this.policyInfo.companyPolicyFlightRules
            .internationalPriceLimitPerPassengerCurrency
        ) {
          this.editInternationalLimit = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "domesticLimit") {
        if (
          this.createPolicyForm.controls.domesticPriceCap.value ===
          this.policyInfo.companyPolicyFlightRules
            .domesticPriceLimitPerPassenger
        ) {
          this.editDomesticLimit = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "domesticPriceCapCurrency") {
        if (
          this.createPolicyForm.controls.domesticPriceCapCurrency.value ===
          this.policyInfo.companyPolicyFlightRules
            .domesticPriceLimitPerPassengerCurrency
        ) {
          this.editDomesticLimit = false;
          this.hidePopUp();
          return;
        }
      }

      if (
        removeItem === false &&
        this.policyInfo.companyPolicyHotelRules.exceptions[fieldIndex]
      ) {
        if (
          this.createPolicyForm.controls["cityLimit" + fieldIndex].value ===
            this.policyInfo.companyPolicyHotelRules.exceptions[fieldIndex]
              .avgNightlyPriceLimit &&
          this.createPolicyForm.controls["cityLimitCurrency" + fieldIndex]
            .value ===
            this.policyInfo.companyPolicyHotelRules.exceptions[fieldIndex]
              .avgNightlyPriceLimitCurrency
        ) {
          this.editCitiesLimit[fieldIndex] = false;
          this.hidePopUp();
          return;
        }
      }
      this.submitted = true;
      this.errorMessage = "";
      this.submitting = true;
      if (this.createPolicyForm.invalid) {
        this.submitting = false;
        this.emptyCityField();
        return;
      }
      this.defaultRequired = false;
      if (fieldName === "name") {
        this.nameLoader = true;
      }
      if (fieldName === "hotelLimit") {
        this.limitLoader = true;
      }
      if (fieldName === "hotelLimitCurrency") {
        this.limitLoader = true;
      }
      if (fieldName === "fairClass") {
        this.fairClassLoader = true;
      }
      if (fieldName === "flightLimit") {
        this.priceCapLoader = true;
      }
      if (fieldName === "flightPolicyLimitCurrency") {
        this.priceCapCurrencyLoader = true;
      }
      if (fieldName === "internationalLimit") {
        this.internationalPriceCapLoader = true;
      }
      if (fieldName === "internationalPriceCapCurrency") {
        this.internationalPriceCapLoader = true;
      }
      if (fieldName === "domesticLimit") {
        this.domesticPriceCapLoader = true;
      }
      if (fieldName === "domesticPriceCapCurrency") {
        this.domesticPriceCapLoader = true;
      }
      if (fieldName === "cityLimit") {
        this.cityLoaders[fieldIndex] = true;
      }
      if (fieldName === "cityLimitCurrency") {
        this.cityLoaders[fieldIndex] = true;
      }
      if (fieldName === "approvals") {
        this.checkboxLoader = true;
      }
      if (fieldName === "maximumNumberOfTrips") {
        this.tripsMaxNoLoader = true;
      }

      if (fieldName === "maxInterval") {
        this.maxIntervalLoader = true;
      }
      if (fieldName === "hotelAllowedToggle") {
        this.hotelToggleLoader = true;
      }
      if (fieldName === "flightAllowedToggle") {
        this.flightToggleLoader = true;
      }

      this.policyObj.name = this.createPolicyForm.controls.policyName.value;
      this.policyObj.hotelBookingNotAllowed =
        !this.createPolicyForm.controls.hotelAllowedToggle.value;
      this.policyObj.flightBookingNotAllowed =
        !this.createPolicyForm.controls.flightAllowedToggle.value;
      this.policyObj.approvalProcessType = this.selectedId;
      this.policyObj.companyId = this.auth.companyInfo.id;
      this.policyObj.companyPolicyFlightRules.maximumCabinClass =
        this.selectedFairClass;
      if (!this.priceCap) {
        this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassenger =
          this.createPolicyForm.controls.flightPolicyLimit.value;
        this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassenger =
          this.createPolicyForm.controls.flightPolicyLimit.value;
      } else {
        if (this.createPolicyForm.controls.domesticPriceCap) {
          this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassenger =
            this.createPolicyForm.controls.domesticPriceCap.value;
        } else {
          this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassenger =
            this.policyInfo.companyPolicyFlightRules.domesticPriceLimitPerPassenger;
        }
        if (this.createPolicyForm.controls.internationalPriceCap) {
          this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassenger =
            this.createPolicyForm.controls.internationalPriceCap.value;
        } else {
          this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassenger =
            this.policyInfo.companyPolicyFlightRules.internationalPriceLimitPerPassenger;
        }
      }
      if (!this.priceCap) {
        this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency =
          this.createPolicyForm.controls.flightPolicyLimitCurrency.value;
        this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency =
          this.createPolicyForm.controls.flightPolicyLimitCurrency.value;
      } else {
        if (this.createPolicyForm.controls.domesticPriceCapCurrency) {
          this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency =
            this.createPolicyForm.controls.domesticPriceCapCurrency.value;
        } else {
          this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency =
            this.policyInfo.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency;
        }
        if (this.createPolicyForm.controls.internationalPriceCapCurrency) {
          this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency =
            this.createPolicyForm.controls.internationalPriceCapCurrency.value;
        } else {
          this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency =
            this.policyInfo.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency;
        }
      }
      this.policyObj.companyPolicyHotelRules.avgNightlyPriceLimit =
        this.createPolicyForm.controls.policyLimit.value;
      this.policyObj.companyPolicyHotelRules.avgNightlyPriceLimitCurrency =
        this.createPolicyForm.controls.policyLimitCurrency.value;
      this.policyObj.companyPolicyHotelRules.exceptions = [];
      this.citiesList.forEach((element, index) => {
        const obj = {
          avgNightlyPriceLimit:
            this.createPolicyForm.controls["cityLimit" + index].value,
          avgNightlyPriceLimitCurrency:
            this.createPolicyForm.controls["cityLimitCurrency" + index].value,
          caption: element.caption,
          code: element.code,
          type: element.type,
        };
        this.policyObj.companyPolicyHotelRules.exceptions.push(obj);
      });
      if (removeItem === true) {
        this.policyObj.companyPolicyHotelRules.exceptions.splice(fieldIndex, 1);
      }
      if (this.createPolicyForm.get("maximumNumberOfTrips").value) {
        this.policyObj.companyPolicyFlightRules["maximumNumberOfTrips"] =
          this.createPolicyForm.get("maximumNumberOfTrips").value;
        this.policyObj.companyPolicyFlightRules[
          "maximumNumberOfTripsInterval"
        ] = this.selectedMaxInterval;
      } else {
        delete this.policyObj.companyPolicyFlightRules["maximumNumberOfTrips"];
        delete this.policyObj.companyPolicyFlightRules[
          "maximumNumberOfTripsInterval"
        ];
      }
      Object.assign(this.policyObj, { policyId: this.policyId });
      this.dataService.editPolicy(this.policyObj).subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.submitted = false;
            if (removeItem === true) {
              this.createPolicyForm.removeControl(
                "cityLimit" +
                  (this.policyInfo.companyPolicyHotelRules.exceptions.length -
                    1)
              );
              this.createPolicyForm.removeControl(
                "cityLimitCurrency" +
                  (this.policyInfo.companyPolicyHotelRules.exceptions.length -
                    1)
              );
              this.citiesList.splice(fieldIndex, 1);
              this.cityLoaders.splice(fieldIndex, 1);
              this.editCitiesLimit.splice(fieldIndex, 1);
            }
            this.dataService.getPolicyDetails(this.policyId).subscribe(
              (dataDetails) => {
                this.data = dataDetails;
                if (this.data.errorCode === 0) {
                  this.nameLoader = false;
                  this.limitLoader = false;
                  this.fairClassLoader = false;
                  this.priceCapLoader = false;
                  this.priceCapCurrencyLoader = false;
                  this.internationalPriceCapLoader = false;
                  this.domesticPriceCapLoader = false;
                  this.cityLoaders[fieldIndex] = false;
                  this.checkboxLoader = false;
                  this.tripsMaxNoLoader = false;
                  this.maxIntervalLoader = false;
                  this.flightToggleLoader = false;
                  this.hotelToggleLoader = false;
                  this.policyInfo = this.data.data.policy;
                  if (removeItem === true) {
                    this.citiesList = [];
                    this.data.data.policy.companyPolicyHotelRules.exceptions.forEach(
                      (element, index) => {
                        this.createPolicyForm
                          .get("cityLimit" + index)
                          .setValue(element.avgNightlyPriceLimit);
                        this.createPolicyForm
                          .get("cityLimitCurrency" + index)
                          .setValue(element.avgNightlyPriceLimitCurrency);
                        this.citiesList.push({
                          caption:
                            this.data.data.policy.companyPolicyHotelRules
                              .exceptions[index].caption,
                          code: this.data.data.policy.companyPolicyHotelRules
                            .exceptions[index].code,
                          type: this.data.data.policy.companyPolicyHotelRules
                            .exceptions[index].type,
                        });
                      }
                    );
                  }

                  if (this.dataService.desktopView) {
                    setTimeout(() => {
                      if (fieldName === "name") {
                        const input = document.getElementById(
                          "nameInputPolicy"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "hotelLimit") {
                        const input = document.getElementById(
                          "limitInputPolicy"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "fairClass") {
                        const input = document.getElementById(
                          "fairClass"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "maxInterval") {
                        const input = document.getElementById(
                          "maxInterval"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "maximumNumberOfTrips") {
                        if (
                          this.policyInfo.companyPolicyFlightRules
                            .maximumNumberOfTrips
                        ) {
                          this.showIntervalList = true;
                        } else {
                          this.showIntervalList = false;
                        }
                        const input = document.getElementById(
                          "maximumNumberOfTrips"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "flightLimit") {
                        const input = document.getElementById(
                          "flightPolicyLimit"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "flightPolicyLimitCurrency") {
                        const input = document.getElementById(
                          "flightPolicyLimitCurrency"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "internationalLimit") {
                        const input = document.getElementById(
                          "internationalPriceCap"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "internationalPriceCapCurrency") {
                        const input = document.getElementById(
                          "internationalPriceCapCurrency"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "domesticLimit") {
                        const input = document.getElementById(
                          "domesticPriceCap"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "domesticPriceCapCurrency") {
                        const input = document.getElementById(
                          "domesticPriceCapCurrency"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "cityLimit" && removeItem === false) {
                        const input = document.getElementById(
                          "place-" + fieldIndex
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (
                        fieldName === "cityLimitCurrency" &&
                        removeItem === false
                      ) {
                        const input = document.getElementById(
                          "placeCurrency-" + fieldIndex
                        ) as HTMLInputElement;
                        input.blur();
                      }
                    }, 200);
                  }
                  this.hidePopUp();
                } else {
                  this.nameLoader = false;
                  this.limitLoader = false;
                  this.fairClassLoader = false;
                  this.priceCapLoader = false;
                  this.priceCapCurrencyLoader = false;
                  this.internationalPriceCapLoader = false;
                  this.domesticPriceCapLoader = false;
                  this.cityLoaders[fieldIndex] = false;
                  this.checkboxLoader = false;
                  this.tripsMaxNoLoader = false;
                  this.maxIntervalLoader = false;
                  this.flightToggleLoader = false;
                  this.hotelToggleLoader = false;

                  this.dataService.messages(this.data.message, "error");
                }
              },
              (error) => {
                this.nameLoader = false;
                this.limitLoader = false;
                this.fairClassLoader = false;
                this.priceCapLoader = false;
                this.priceCapCurrencyLoader = false;
                this.internationalPriceCapLoader = false;
                this.domesticPriceCapLoader = false;
                this.cityLoaders[fieldIndex] = false;
                this.checkboxLoader = false;
                this.tripsMaxNoLoader = false;
                this.maxIntervalLoader = false;
                this.flightToggleLoader = false;
                this.hotelToggleLoader = false;

                this.dataService.messages(environment.messages.error, "error");
              }
            );
            this.editMaxInterval = false;
            this.editMaxNoOfTrips = false;
            this.editPolicyName = false;
            this.editFairClass = false;
            this.editDomesticLimit = false;
            this.editInternationalLimit = false;
            this.editFlightLimit = false;
            this.editPolicyLimit = false;
            this.editCitiesLimit[fieldIndex] = false;

            this.showPolicyNamePopUpFlag = false;
            this.showPolicyLimitPopUpFlag = false;
            this.showCityPolicyLimitPopUpFlag[fieldIndex] = false;
            this.showDomesticPriceCapFlag = false;
            this.showInternationalPriceCapFlag = false;
            this.showFlightPriceCapFlag = false;
          } else {
            this.tripsMaxNoLoader = false;
            this.maxIntervalLoader = false;
            this.flightToggleLoader = false;
            this.hotelToggleLoader = false;
            this.nameLoader = false;
            this.limitLoader = false;
            this.fairClassLoader = false;
            this.priceCapLoader = false;
            this.priceCapCurrencyLoader = false;
            this.internationalPriceCapLoader = false;
            this.domesticPriceCapLoader = false;
            this.cityLoaders[fieldIndex] = false;
            this.checkboxLoader = false;

            this.errorMessage = this.data.message;
            this.editMaxInterval = true;
            this.editMaxNoOfTrips = true;
            this.editPolicyName = true;
            this.editFairClass = true;
            this.editDomesticLimit = true;
            this.editInternationalLimit = true;
            this.editFlightLimit = true;
            this.editPolicyLimit = true;
            this.editCitiesLimit[fieldIndex] = true;
          }
        },
        (error) => {
          this.tripsMaxNoLoader = false;
          this.maxIntervalLoader = false;
          this.flightToggleLoader = false;
          this.hotelToggleLoader = false;
          this.nameLoader = false;
          this.limitLoader = false;
          this.fairClassLoader = false;
          this.priceCapLoader = false;
          this.priceCapCurrencyLoader = false;
          this.internationalPriceCapLoader = false;
          this.domesticPriceCapLoader = false;
          this.cityLoaders[fieldIndex] = false;
          this.checkboxLoader = false;
          this.errorMessage = environment.messages.error;
          this.editMaxInterval = true;
          this.editMaxNoOfTrips = true;
          this.editPolicyName = true;
          this.editFairClass = true;
          this.editDomesticLimit = true;
          this.editInternationalLimit = true;
          this.editFlightLimit = true;
          this.editPolicyLimit = true;
          this.editCitiesLimit[fieldIndex] = true;
        }
      );
    }
  }

  trackCity(index, city) {
    return city.id;
  }

  enterActionFunc(fieldName, index, removeItem) {
    if (this.policyId) {
      this.editPolicy(fieldName, index, removeItem);
    } else {
      this.createPolicy();
    }
  }

  showSection(name) {
    if (name === "hotel") {
      if (this.showSelectedSectionHotel === name) {
        this.showSelectedSectionHotel = "";
      } else {
        this.showSelectedSectionHotel = name;
      }
    } else if (name === "flight") {
      if (this.showSelectedSectionFlight === name) {
        this.showSelectedSectionFlight = "";
      } else {
        this.showSelectedSectionFlight = name;
      }
    }
  }

  showCityPolicyLimitPopUp(index) {
    this.selectedCityIndex = index;
    this.showCityPolicyLimitPopUpFlag[index] = true;
    this.dataService.disableBodyScroll();
    if (this.policyInfo.companyPolicyHotelRules.exceptions[index]) {
      this.createPolicyForm.controls["cityLimit" + index].value ===
        this.policyInfo.companyPolicyHotelRules.exceptions[index]
          .avgNightlyPriceLimit;
      this.createPolicyForm.controls["cityLimitCurrency" + index].value ===
        this.policyInfo.companyPolicyHotelRules.exceptions[index]
          .avgNightlyPriceLimitCurrency;
    }
    setTimeout(() => {
      const input = document.getElementById(
        "place-" + index
      ) as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }

  setSearchText(entity, dist) {
    this.citiesList.push(entity);
    this.createPolicyForm.addControl(
      "cityLimit" + (this.citiesList.length - 1),
      new FormControl("", Validators.required)
    );
    this.createPolicyForm.addControl(
      "cityLimitCurrency" + (this.citiesList.length - 1),
      new FormControl("", Validators.required)
    );
    this.editCitiesLimit[this.citiesList.length - 1] = true;
    if (this.dataService.mobileView) {
      this.showSearchPopUpautocompleteFlag = false;
      this.showCityPolicyLimitPopUp(this.citiesList.length - 1);
    }
    this.showDropdown = false;
    setTimeout(() => {
      this.autocompletelist = [];
      this.Query = "";
      this.caption = "";
    }, 500);
  }

  autoCompleteList() {
    if (this.Query && this.Query.length > 2) {
      this.dataService
        .autoComplete(this.Query, sessionStorage.getItem("session-token"))
        .subscribe((success) => {
          this.data = success;
          this.autocompletelist = this.data.data;
        });
    }
  }
  autocompleteOnFocus(event) {
    this.autocompletelist = [];
    event.target.select();
    this.showDropdown = true;
  }

  onChangeSearch() {
    this.showDropdown = true;
  }

  removeCity(index) {
    if (this.policyId) {
      if (
        index >
        this.policyInfo.companyPolicyHotelRules.exceptions.length - 1
      ) {
        this.citiesList.splice(index, 1);
        this.createPolicyForm.removeControl(
          "cityLimit" +
            (this.policyInfo.companyPolicyHotelRules.exceptions.length - 1)
        );
        this.createPolicyForm.removeControl(
          "cityLimitCurrency" +
            (this.policyInfo.companyPolicyHotelRules.exceptions.length - 1)
        );
      }
      this.editPolicy("cityLimit", index, true);
    } else {
      this.citiesList.forEach((element, index) => {
        this.tempArrayForCreate.push({
          cityLimit: this.createPolicyForm.controls["cityLimit" + index].value,
          cityLimitCurrency:
            this.createPolicyForm.controls["cityLimitCurrency" + index].value,
        });
      });
      if (index >= 0) {
        this.citiesList.splice(index, 1);
        this.tempArrayForCreate.splice(index, 1);
        this.citiesList.forEach((index) => {
          this.createPolicyForm
            .get("cityLimit" + index)
            .setValue(this.tempArrayForCreate[index].cityLimit);
          this.createPolicyForm
            .get("cityLimitCurrency" + index)
            .setValue(this.tempArrayForCreate[index].cityLimitCurrency);
        });
        setTimeout(() => {
          const input = document.getElementById(
            "place-" + this.citiesList.length
          ) as HTMLInputElement;

          if (input) input.value = "";
        }, 200);
        this.createPolicyForm.removeControl(
          "cityLimit" + this.citiesList.length
        );
        this.createPolicyForm.removeControl(
          "cityLimitCurrency" + this.citiesList.length
        );
      }
      if (!this.citiesList.length) {
        this.createPolicyForm.get("policyLimit").setValidators(null);
        this.createPolicyForm.get("policyLimitCurrency").setValidators(null);
        this.createPolicyForm.get("policyLimit").updateValueAndValidity();
        this.createPolicyForm
          .get("policyLimitCurrency")
          .updateValueAndValidity();
        this.defaultRequired = false;
      }
    }
  }

  focusField() {
    setTimeout(() => {
      let input;
      input = document.getElementById(
        "place-" + (this.citiesList.length - 1)
      ) as HTMLInputElement;
      if (input) input.focus();
    }, 200);
  }

  isValid(fieldName) {
    return this.createPolicyForm.controls[fieldName].valid;
  }

  notSelected(caption) {
    let notSelected = true;
    if (this.policyInfo && this.policyInfo.companyPolicyHotelRules) {
      this.policyInfo.companyPolicyHotelRules.exceptions.forEach((element) => {
        if (element.caption === caption) {
          notSelected = false;
        }
      });
    }
    return notSelected;
  }

  emptyCityField() {
    const input = document.getElementById("list") as HTMLInputElement;
    setTimeout(() => {
      if (input) {
        input.value = "";
        this.caption = "";
        this.showAutoCompleteField = false;
      }
    }, 200);
    if (
      (this.policyId &&
        (!this.policyInfo.companyPolicyHotelRules.exceptions ||
          !this.policyInfo.companyPolicyHotelRules.exceptions.length)) ||
      (!this.policyId &&
        (!this.policyObj.companyPolicyHotelRules.exceptions ||
          !this.policyObj.companyPolicyHotelRules.exceptions.length))
    ) {
      this.createPolicyForm.get("policyLimit").setValidators(null);
      this.createPolicyForm.get("policyLimit").updateValueAndValidity();
      this.createPolicyForm.get("policyLimitCurrency").setValidators(null);
      this.createPolicyForm.get("policyLimitCurrency").updateValueAndValidity();
      this.defaultRequired = false;
    }
  }

  addRequiredToDefaultValue() {
    if (
      this.createPolicyForm.get("policyLimit").value &&
      this.createPolicyForm.get("policyLimitCurrency").value
    ) {
      if (this.dataService.desktopView) {
        this.showAutoCompleteField = true;
        setTimeout(() => {
          const input = document.getElementById("list") as HTMLInputElement;
          if (input) {
            input.focus();
          }
        }, 200);
      } else {
        this.showSearchPopUpautocompleteFlag = true;
      }
    } else {
      this.createPolicyForm
        .get("policyLimit")
        .setValidators([Validators.required]);
      this.createPolicyForm.get("policyLimit").updateValueAndValidity();
      this.createPolicyForm
        .get("policyLimitCurrency")
        .setValidators([Validators.required]);
      this.createPolicyForm.get("policyLimitCurrency").updateValueAndValidity();
      this.defaultRequired = true;
      this.editPolicyLimit = true;
      this.emptyCityField();
    }
  }

  checkIfHaveValue() {
    if (
      this.createPolicyForm.get("policyLimit").value &&
      !this.policyId &&
      !this.citiesList.length
    ) {
      this.createPolicyForm.get("policyLimit").setValidators(null);
      this.createPolicyForm.get("policyLimit").updateValueAndValidity();
      this.createPolicyForm.get("policyLimitCurrency").setValidators(null);
      this.createPolicyForm.get("policyLimitCurrency").updateValueAndValidity();
      this.defaultRequired = false;
    } else if (
      !this.createPolicyForm.get("policyLimit").value &&
      !this.policyId &&
      this.citiesList.length
    ) {
      this.createPolicyForm
        .get("policyLimit")
        .setValidators([Validators.required]);
      this.createPolicyForm.get("policyLimit").updateValueAndValidity();
      this.createPolicyForm
        .get("policyLimitCurrency")
        .setValidators([Validators.required]);
      this.createPolicyForm.get("policyLimitCurrency").updateValueAndValidity();
      this.defaultRequired = true;
      this.editPolicyLimit = true;
      this.emptyCityField();
    }
  }
}
