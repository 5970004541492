import { Component, OnInit } from '@angular/core';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {onMainContentChange} from '../../../workspace-dashboard/animations/animations';
import {DataService} from '../../../../services/data.service';
import {SidenavService} from '../../../workspace-dashboard/sidenav.service';
import {Router} from '@angular/router';
import {VariablesService} from '../../../../services/vars.service';

@Component({
  selector: 'app-user-cancel-booking',
  templateUrl: './user-cancel-booking.component.html',
  styleUrls: ['./user-cancel-booking.component.scss'],
  animations: [ onMainContentChange ]
})
export class UserCancelBookingComponent implements OnInit {

  constructor(public dataService: DataService, private sidenavService: SidenavService,
              public router: Router, public auth: AuthServiceMain, public vars: VariablesService) {
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }

  ngOnInit(): void {
  }

}
