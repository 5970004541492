<div *ngIf="this.walletsList">
 <div
  class="wallet-container mb-5 pl-10"
  *ngIf="
    (this.walletsList.length > 0) || (activeWalletsList.length > 0 || archivedWalletsList.length > 0) ">
<div *ngIf="walletsList.length || activeWalletsList.length > 0 || archivedWalletsList.length > 0"
  [ngClass]="{'pb-0 mb-0': dataService.mobileView}">
  <div class="mt-4">
    <p class="gray-text-dark font-18">
      <span i18n="@@walletNote">
      SafarHub Wallet simplifies budget management by allocating funds to teams or employees. Easily create, recharge, and assign wallets as needed.
    </span>
    </p>
  </div>
  <div>
    <div class="row mx-0">
      <div class="col ml-0 px-0">
        <div class="main-dashboard-content mt-3 mb-0 pb-0">
          <div class="items-full-list">
            <div class="row mx-0">
              <div class="col-lg-9">
                <div class="row font-16-to-14 font-weight-bold filtering blue-text rounded pb-2">
                  <div class="col-auto py-3 px-1">
                    <span class="pointer px-lg-1 inactive"
                      [ngClass]="{'active' : !isActive, 'border-right': dataService.isSiteLtr(), 'border-left': dataService.isSiteRtl()}"
                      (click)="getIsActiveList()" i18n="@@active">
                      Active
                    </span>
                  </div>
                  <div class="col-auto py-3 px-2">
                    <span class="pointer px-lg-4"
                      [ngClass]="{'active' : isActive, 'mobile-padding-left': dataService.mobileView}"
                      (click)="getIsActiveList(true)" i18n="@@inActive">
                      Archived
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div [ngClass]="archivedWalletsList.length == 0 || activeWalletsList.length == 0 ? 'list-container' : ''">
              <div class="gray-primaray-text font-14" 
                *ngFor="let item of walletsList; let i = index">
                <div class="row hover-row-list px-3 py-4 mx-0 position-relative"
                  [ngClass]="{'pointer': auth.companyInfo && auth.companyInfo.inactive === false, 'prevent-pointer-event': auth.companyInfo && auth.companyInfo.inactive === true, 'border-bottom': dataService.desktopView, 'border rounded shadow-sm mb-4': dataService.mobileView}">
                  <div >
                    <div class="d-flex ml-0 pl-0" >
                      <i *ngIf="!item.isInactive" class="icon-wallet-status font-24 green-text" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"></i>
                      <i *ngIf="item.isInactive" class="icon-wallet-status gray-text font-24" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"></i>
                      <div class="dotted-text ml-0">
                        <p class="mb-0 gray-primaray-text">
                          {{item.name}}
                        </p>
                        <span
                          class="gray-text mb-0 dotted-text dotted-text-direction text-sm">{{item.currencyCode}} {{item.balance | number: '1.0-2'}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="mx-1" >
                    <div class="d-flex"
                      [ngClass]=" dataService.desktopView ? 'mr-23' : ''"
                      *ngIf="auth.companyInfo && auth.companyInfo.inactive === false">
                      <div class="ml-auto actions"
                        [ngClass]="{'mobile-actions': dataService.mobileView, 'margin-top-5': dataService.mobileView && !item.name?.length}">
                        <i class="drop-down{{i}} ellipsis ellipsis-cards font-18 gray-text icon-three-dots p-3 pointer"
                          (click)="$event.stopPropagation(); showActions = !showActions; selectedIndex = i"></i>
                        <div class="border shadow font-14 position-absolute bg-white drop"
                          *ngIf="showActions && i === selectedIndex">
                          <p class="pointer py-2"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            (click)="$event.stopPropagation(); editItem(item)" i18n="@@edit">
                            Edit
                          </p>
                          <p class="pointer py-2"
                            *ngIf="item.isInactive"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            (click)="$event.stopPropagation(); changeActivation(item)"
                            i18n="@@activate">
                            Activate
                          </p>
                          <p class="pointer py-2"
                            *ngIf="!item.isInactive"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            (click)="$event.stopPropagation(); changeActivation(item)"
                            i18n="@@archive">
                            Archive
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-5" >
              <button class="blue_btn px-5 solid-border"
                [ngClass]="{'btn-outline-blue': auth.companyInfo && auth.companyInfo.inactive === false, 'btn-outline-gray': auth.companyInfo && auth.companyInfo.inactive}"
                (click)="addNewWallet()" i18n="@@addNewWallet">
                Add Wallet
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 </div></div>
<div *ngIf="walletsList != undefined && walletsList.length == 0 && activeWalletsList.length == 0 && archivedWalletsList.length == 0" class="main-dashboard-content mt-3 mb-3 pb-3">
  <div class="mt-5 mx-lg-0 px-4 px-lg-5 py-4 company-section-with-thick-border border" [ngClass]="{'minus-margin': dataService.desktopView,
                    'rounded-right': dataService.desktopView && dataService.isSiteLtr(), 'rounded-left': dataService.desktopView && dataService.isSiteRtl(),
                    'thick-top rounded-bottom': dataService.mobileView}">
    <div class="row py-3 h-100 justify-content-center align-items-center">
      <div class="col-md-9">
          <p class="font-18 bold-600 primaray-text" i18n="@@wallets">Wallets</p>
          <p><span class="font-16 gray-text-dark" i18n="@@noWalletMessage">The Safarhub Wallet simplifies budget management by allocating funds to teams or employees without using company cards. Easily create, recharge, and assign wallets as needed.</span></p>
      </div>
      <div class="col-md-3" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">
        <button class="blue_btn solid-border w-75"
        [ngClass]="{'w-100': dataService.mobileView,  'w-75': dataService.desktopView, 'btn-outline-blue': auth.companyInfo && auth.companyInfo.inactive === false, 'btn-outline-gray': auth.companyInfo && auth.companyInfo.inactive}"
          (click)="addNewWallet()" i18n="@@addNewWallet">
          Add Wallet
        </button>
      </div>
    </div>
  </div>
</div>
