import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { WalletManagementService } from 'src/app/services/wallet-services/wallet-management.service';
import { CompanyArchiveStatus } from 'src/app/shared/enums/company-archive-status';
import { Wallet } from 'src/app/shared/model/payment/wallet';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wallet-activation',
  templateUrl: './wallet-activation.component.html',
  styleUrls: ['./wallet-activation.component.scss']
})
export class WalletActivationComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() status: string;
  @Input() name: string;
  companyArchiveStatus = CompanyArchiveStatus;
  loader: boolean = false;
  hasError: boolean = false;
  btnTxt;
  editWalletSubscription: any;
  data: any;

  constructor(public walletManagementService: WalletManagementService,
    public dataService: DataService) { }
 
  ngOnDestroy(): void {
   if(this.editWalletSubscription){
      this.editWalletSubscription.unsubscribe();
     }
   }

  ngOnInit(): void {
    this.btnTxt = this.getBtnTxt();
  }

  getBtnTxt() {
    if(this.status === this.companyArchiveStatus.Active) {
      return $localize`:@@archive:Archive`
    } else {
      return $localize`:@@activate:Activate`
    }
  }

  changeActivation() {
    this.loader = true;
    var wallet: Wallet = new Wallet();

    wallet.id = this.id;
    wallet.isInactive = this.status == this.companyArchiveStatus.Active ? true : false;

    this.editWalletSubscription = this.walletManagementService
      .editWallet(wallet)
      .subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.loader = false;
            this.close();
          }
        },
        (error) => {
          if (error.includes('Http failure response')) {
            error = environment.messages.error;
          }
      
          this.dataService.messages(error ?? environment.messages.error, 'error');
          this.loader = false;
          this.hasError = true;
          this.close();
        }
      );
  }

  close() {
    if (this.dataService.desktopView) {
      this.dataService.modalOf();   
    }
    if (this.dataService.mobileView) {
      this.dataService.BottomSheetOf();
    }
  }
}
