<div class="top_search_fields">
  <div class="search mini_search line_search centered">
    <div class="top_search_fields">
      <div class="search mini_search line_search">
        <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
          <div class="row gutters-5 justify-content-end">
            <div
              [ngClass]="{
                'col-md-30':
                  !this.restApi.isCompany() ||
                  this.router.url.includes('availability'),
                'col-md-100':
                  this.restApi.isCompany() &&
                  !this.router.url.includes('availability')
              }"
            >
              <mat-form-field class="w-100">
                <div class="form-group m-0 with-icon text-section">
                  <div class="form-control">
                    <input
                      (keydown)="onChangeSearch()"
                      [ngClass]="{ 'is-invalid': submitted && caption === '' }"
                      (ngModelChange)="this.SearchTextQuery.next($event)"
                      matInput
                      [matAutocomplete]="auto"
                      required
                      *ngIf="!(availability || hotel)"
                      autocomplete="off"
                      [(ngModel)]="caption"
                      name="search"
                      (focus)="
                        autocompleteOnFocus($event);getSearchHistory();
                      "
                      i18n-placeholder="@@enterCityPlaceholder"
                      placeholder="Enter city or hotel name ..."
                      type="text"
                      class="py-3"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <input
                      (keydown)="onChangeSearch()"
                      [ngClass]="{
                        'is-invalid': submitted && restApi.caption === ''
                      }"
                      (ngModelChange)="this.SearchTextQuery.next($event)"
                      matInput
                      [matAutocomplete]="auto"
                      required
                      *ngIf="availability || hotel"
                      autocomplete="off"
                      [(ngModel)]="this.restApi.caption"
                      [ngModelOptions]="{ standalone: true }"
                      name="search"
                      (focus)="
                        autocompleteOnFocus($event);getSearchHistory();
                      "
                      i18n-placeholder="@@enterCityPlaceholder"
                      placeholder="Enter city or hotel name ..."
                      type="text"
                      class="py-3"
                    />
                    <span
                      *ngIf="
                        restApi.caption !== undefined &&
                        restApi.caption.length === 0 &&
                        (availability || hotel)
                      "
                      class="gray-text placeholder-search"
                      i18n="@@enterCity"
                      >Enter city or hotel name ...</span
                    >

                    <span
                      *ngIf="
                        caption !== undefined &&
                        caption.length === 0 &&
                        !(availability || hotel)
                      "
                      class="gray-text placeholder-search"
                      i18n="@@enterCity"
                      >Enter city or hotel name ...</span
                    >
                  </div>

                  <i class="icon-locator gray-text"></i>
                  <mat-autocomplete class="hotel-list" #auto="matAutocomplete">
                    <div *ngFor="let autocompletetype of autocompletelist">
                      <h2 class="options-headers">
                        <ng-container *ngIf="autocompletetype.name === 'Cities'" i18n="@@citiesSearch">{{ autocompletetype.name }}</ng-container>
                        <ng-container *ngIf="autocompletetype.name === 'Hotels'" i18n="@@hotelsSearch">{{ autocompletetype.name }}</ng-container>
                        <ng-container *ngIf="autocompletetype.name === 'Airports'" i18n="@@airportsSearch">{{ autocompletetype.name }}</ng-container>
                      </h2>
                      <div>
                        <mat-option
                          data-id="{{ entity.code }}"
                          *ngFor="let entity of autocompletetype.entities"
                          tabindex="-1"
                          [value]="entity.caption"
                          (onSelectionChange)="
                            setSearchText(entity, autocompletetype.name)
                          "
                          (click)="setSearchText(entity, autocompletetype.name)"
                        >
                          <span class="d-block">
                            {{ entity.caption }}
                          </span>
                        </mat-option>
                      </div>
                    </div>

                    <div
                      *ngIf="
                        restApi.historyArray && restApi.historyArray.length > 0
                      "
                    >
                      <div class="d-flex">
                        <h2
                          [ngClass]="{
                            'mr-auto': restApi.isSiteLtr(),
                            'ml-auto': restApi.isSiteRtl()
                          }"
                          i18n="@@searchHistory"
                        >
                          Search History
                        </h2>
                        <span class="btn btn-link" (click)="clearHistory()">
                          <i
                            class="icon-close-light font-12"
                            [ngClass]="{
                              'pl-2': restApi.isSiteLtr(),
                              'pr-2': restApi.isSiteRtl()
                            }"
                          ></i>
                          <ng-container i18n="@@clearBtn">Clear</ng-container>
                        </span>
                      </div>

                      <mat-option
                        class="mat-option"
                        *ngFor="let historyitem of restApi.historyArray"
                        tabindex="-1"
                        (onSelectionChange)="
                          setSearchText(historyitem, 'History')
                        "
                        (click)="setSearchText(historyitem, 'History')"
                      >
                        <span class="d-block">
                          {{ historyitem.caption }}
                        </span>
                      </mat-option>
                    </div>
                  </mat-autocomplete>
                </div>
              </mat-form-field>
              <div
                *ngIf="submitted && (caption === '' || restApi.caption === '')"
                class="text-danger mb-2"
              >
                <div i18n="@@chooseHotel">
                  Please choose a hotel or a destination
                </div>
              </div>
            </div>
            <div
              [ngClass]="{
                'col-md-30':
                  !this.restApi.isCompany() ||
                  this.router.url.includes('availability'),
                'col-md-100':
                  this.restApi.isCompany() &&
                  !this.router.url.includes('availability')
              }"
            >

              <div class="datePick">
                <div
                  class="form-group mb-0 with-icon calendar-item text-nowrap position-relative"
                  [ngClass]="{
                    border: restApi.isCompany() && restApi.desktopView
                  }"

                >
                  <div class="overlay-custom" (click)="datepicker.open(); navigateToMonth()"></div>
                  <div class="calendar-value-cont font-14">
                    <span class="d-inline-block">

                      <span id="check-in-date-desktop" class="ng-binding">{{
                        CheckinRangeFormatted
                      }}</span>
                      -
                      <span id="check-out-date-desktop">{{
                        this.CheckoutRangeFormatted
                      }}</span>
                    </span>

                    <span
                      class="small number-of-nights gray-text d-inline-block"
                      [ngClass]="{
                        'ml-1': restApi.isSiteLtr(),
                        'mr-1': restApi.isSiteRtl()
                      }"
                      >(

                      <span
                        class="night-cont"
                        *ngIf="staynights > 1; else Night"
                      >
                        <ng-container
                          *ngIf="staynights === 2"
                          i18n="@@twoNights"
                        >
                          2 nights</ng-container
                        >
                        <ng-container *ngIf="staynights > 2"
                          ><span class="totalstaynights">{{ staynights }}</span>
                          <ng-container i18n="@@nights"
                            > nights</ng-container
                          ></ng-container
                        >
                      </span>

                      <span class="night-cont">
                        <ng-template #Night i18n="@@night">
                          1 night
                        </ng-template>
                      </span>

                      )</span
                    >
                  </div>
                  <input
                    name="datepicker"
                    style="opacity: 0"
                    autocomplete="off"
                    class="form-control"
                    ngbDatepicker
                    #datepicker="ngbDatepicker"
                    [firstDayOfWeek]="7"
                    [autoClose]="'outside'"
                    [minDate]="calendar.getNext(calendar.getToday(), 'd', 0)"
                    [maxDate]="calendar.getNext(calendar.getToday(), 'd', 365)"
                    (dateSelect)="onDateSelection($event)"
                    [displayMonths]="2"
                    [dayTemplate]="t"
                    [navigation]="'arrows'"
                    [footerTemplate]="footerTemplate"

                    outsideDays="hidden"
                  />
                  <ng-template #t let-date let-focused="focused">
                    <span
                      class="custom-day"
                      [class.focused]="focused"
                      [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null"
                    >
                      {{ date.day }}
                    </span>
                  </ng-template>
                  <ng-template #footerTemplate>
                    <hr class="my-0" />
                    <div class="d-flex">
                      <button
                        class="btn btn-primary m-2"
                        (click)="
                          datepicker.toggle();
                          this.getDynamicCheckOutDate();
                          $event.stopPropagation()
                        "
                        [ngClass]="{
                          'ml-auto': restApi.isSiteLtr(),
                          'mr-auto': restApi.isSiteRtl()
                        }"
                        i18n="@@done"
                      >
                        Done
                      </button>
                    </div>
                  </ng-template>
                  <i class="icon-dates gray-text"></i>
                </div>
              </div>
              <ng-container *ngIf="this.router.url.includes('availability')">
              <div *ngIf="submitted && moreThan30Nights" class="text-danger mb-2"
                   [ngClass]="{'text-left': restApi.isSiteLtr(), 'text-right': restApi.isSiteRtl()}">
                {{datePickerErrorMsg}}
              </div>
              </ng-container>
            </div>
            <div
              class="guests-children-menu pb-4"
              [ngClass]="{
                'col-md-20':
                  !this.restApi.isCompany() ||
                  this.router.url.includes('availability'),
                'col-md-50-left':
                  this.restApi.isCompany() &&
                  !this.router.url.includes('availability')
              }"
            >
              <div ngbDropdown class="position-relative">
                <div
                  id="paxes"
                  ngbDropdownToggle
                  class="paxes-cont-input form-group mb-0 with-icon calendar-item"
                >
                  <div class="form-name-paxes-summary form-control">
                    <div class="input_wrapper py-3">
                      <div>
                        <span class="pax_count"> {{ paxCount }} </span>
                        <span *ngIf="paxCount > 0">
                          <ng-container *ngIf="paxCount === 1" i18n="@@guest">
                            Guest</ng-container
                          >
                          <ng-container
                            *ngIf="paxCount === 2"
                            i18n="@@twoGuests"
                            >Guests</ng-container
                          >
                          <ng-container *ngIf="paxCount > 2" i18n="@@guests"
                            >Guests</ng-container
                          >
                        </span>
                      </div>
                    </div>
                  </div>

                  <i class="icon-user gray-text"></i>
                </div>
                <div
                  ngbDropdownMenu
                  aria-labelledby="paxes"
                  (click)="keepOnMenu()"
                  class="p-2"
                  [ngClass]="validChild ? 'd-block top45' : ''"
                >
                  <div class="people_number-menu">
                    <div class="adults row no-gutters">
                      <div class="col">
                        <span
                          class="paxes-icon icon-minus col"
                          [ngClass]="{
                            disabled: decreaseAdultDisabled === true
                          }"
                          (click)="decreaseAdult()"
                        >
                          <i class="icon-min"></i
                        ></span>
                      </div>
                      <div class="col-6 text-center">
                        <span class="count adults_count">
                          <span class="pax_count">{{ adultCount }}</span>
                          <span>
                            <ng-container
                              *ngIf="adultCount === 0 || adultCount === 1"
                              i18n="@@adult"
                            >
                              Adult</ng-container
                            >
                            <ng-container
                              *ngIf="adultCount === 2"
                              i18n="@@twoAdults"
                              >Adults</ng-container
                            >
                            <ng-container *ngIf="adultCount > 2" i18n="@@adults"
                              >Adults</ng-container
                            >
                          </span>
                        </span>
                      </div>
                      <div class="col text-right">
                        <span
                          [ngClass]="{ disabled: increasDisabled === true }"
                          class="paxes-icon icon-add"
                          (click)="increaseAdult()"
                        >
                          <i class="icon-plus"></i>
                        </span>
                      </div>
                    </div>

                    <div class="adults row no-gutters">
                      <div class="col">
                        <span
                          class="paxes-icon icon-minus"
                          [ngClass]="{
                            disabled: decreaseChildrenDisabled === true
                          }"
                          (click)="decreaseChildren()"
                        >
                          <i class="icon-min"></i
                        ></span>
                      </div>

                      <div class="col-6 text-center">
                        <span class="count adults_count">
                          <span class="pax_count"> {{ childrenCount }}</span>
                          <span>
                            <ng-container
                              *ngIf="childrenCount === 0 || childrenCount === 1"
                              i18n="@@child"
                            >
                              Child</ng-container
                            >
                            <ng-container
                              *ngIf="childrenCount === 2"
                              i18n="@@twoChildren"
                              >Children</ng-container
                            >
                            <ng-container
                              *ngIf="childrenCount > 2"
                              i18n="@@children"
                              >Children</ng-container
                            >
                          </span>
                        </span>
                      </div>
                      <div class="col text-right">
                        <span
                          class="paxes-icon icon-add"
                          [ngClass]="{ disabled: increasDisabled === true }"
                          (click)="increaseChildren()"
                        >
                          <i class="icon-plus"></i>
                        </span>
                      </div>
                    </div>

                    <div>
                      <div class="rows clearfix" *ngIf="childrenCount > 0">
                        <small
                          class="px-4 pt-3 pb-3 d-block"
                          [ngClass]="{ 'text-right': restApi.isSiteRtl() }"
                          i18n="@@childAge"
                        >
                          Child age at check-in
                        </small>

                        <div
                          class="item clearfix d-flex"
                          *ngFor="let childrens of childrenList; let i = index"
                        >
                          <label class="name">
                            <ng-container i18n="@@child">Child</ng-container>
                            {{ i + 1 }}
                          </label>

                          <div class="select">
                            {{ childrenList[i] }}
                            <select
                              *ngIf="childrenList"
                              required=""
                              [(ngModel)]="childrens.childList"
                              [ngModelOptions]="{ standalone: true }"
                            >
                              <option
                                *ngFor="let number of ages"
                                [ngValue]="number"
                              >
                                {{ number }}
                              </option>
                            </select>
                            <i class="icon-down"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="validChild"
                    class="text-danger"
                    i18n="@@childAgeRequired"
                  >
                    Children ages are required
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="!this.router.url.includes('availability')"
              [ngClass]="{
                'col-md-20':
                  !this.restApi.isCompany() ||
                  this.router.url.includes('availability'),
                'col-md-50-right':
                  this.restApi.isCompany() &&
                  !this.router.url.includes('availability')
              }"
              class="pb-4"
            >
              <button
                value="submit"
                class="btn-block green_btn btn-block"
                [ngClass]="{ 'm-progress': submitting }"
                i18n="@@searchBtn"
              >
                Search
              </button>
            </div>

            <div
              *ngIf="this.restApi.isCompany()"
              [ngClass]="{
                'col-md-20':
                  !this.restApi.isCompany() ||
                  this.router.url.includes('availability'),
                'col-md-50-left':
                  this.restApi.isCompany() &&
                  !this.router.url.includes('availability')
              }"
              class="pb-1 position-relative"
            >
              <app-user-selection></app-user-selection>
            </div>

            <div
              [ngClass]="{
                'col-md-20':
                  !this.restApi.isCompany() ||
                  this.router.url.includes('availability'),
                'col-md-50-right':
                  this.restApi.isCompany() &&
                  !this.router.url.includes('availability')
              }"
              class="pb-0"
            >
              <button
                *ngIf="this.router.url.includes('availability')"
                value="submit"
                class="btn-block green_btn btn-block"
                [ngClass]="{ 'm-progress': submitting }"
                i18n="@@searchBtn"
              >
                Search
              </button>
            </div>
          </div>
        </form>

      </div>
      <ng-container *ngIf="!this.router.url.includes('availability')">
      <div *ngIf="submitted && moreThan30Nights" class="text-danger px-3 mb-2"
           [ngClass]="{'text-left': restApi.isSiteLtr(), 'text-right': restApi.isSiteRtl()}">
        {{datePickerErrorMsg}}
      </div>
      </ng-container>
    </div>
  </div>
</div>
