<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div
        class="container mt-4 mt-lg-5 mb-5 extra-padding"
        *ngIf="this.approvalsService.approvalData"
      >
        <div class="row mx-lg-4">
          <div class="col">
            <div class="pb-2 pb-lg-5 mb-3">
              <a
                class="font-16 blue-text text-underline"
                (click)="
                  this.router.navigate([
                    '/user/requests'
                  ])
                "
                i18n="@@menuItemMyApprovals"
                >My Trip Approvals</a
              >
              <span class="font-16 gray-text-dark">
                /
                {{
                  this.approvalsService.approvalHotelData.cityName
                    ? this.approvalsService.approvalHotelData.cityName
                    : ""
                }}
              </span>
              <span
                class="font-16 gray-text-dark"
                [innerHTML]="
                  formatDate(
                    this.approvalsService.approvalBookingInfo.searchRequest.checkIn
                  )
                "
              ></span>
            </div>
            <p
              *ngIf="
                this.approvalsService.approvalData.status.toLowerCase() ===
                'approved'
              "
              class="bold-600 font-16 green-text"
              i18n="@@approved"
            >
              Approved
            </p>
            <p
              *ngIf="
                this.approvalsService.approvalData.status.toLowerCase() ===
                'pending_approval'
              "
              class="bold-600 font-16 yellow-text mb-0"
              i18n="@@pendingApproval"
            >
              Pending Approval&nbsp;
            </p>
            <p
              *ngIf="
                dataService.desktopView &&
                this.approvalsService.approvalData.status.toLowerCase() ===
                  'pending_approval'
              "
            >
              <span
                class="font-14 blue-text pointer font-weight-normal mb-0"
                (click)="
                  withdrawApprovalRequestPopup(this.approvalsService.approvalData.id)
                "
                i18n="@@withdraw"
                >Withdraw</span
              >
            </p>
            <p
              *ngIf="
                this.approvalsService.approvalData.status.toLowerCase() ===
                'disapproved'
              "
              class="bold-600 font-16 text-danger mb-0"
              i18n="@@declined"
            >
              Declined
            </p>
            <div
              *ngIf="
                this.approvalsService.approvalData.status.toLowerCase() ===
                'disapproved'
              "
            >
              <ng-container
                *ngFor="let item of this.approvalsService.approvalData.operations"
              >
                <p class="font-12 gray-text">{{ item.reason }}</p>
              </ng-container>
            </div>
            <p
              *ngIf="
                this.approvalsService.approvalData.status.toLowerCase() === 'expired'
              "
              class="bold-600 font-16 gray-text"
              i18n="@@expired"
            >
              Expired
            </p>
            <p
              *ngIf="
                this.approvalsService.approvalData.status.toLowerCase() ===
                'withdrawn'
              "
              class="bold-600 font-16 gray-text"
              i18n="@@withdrawn"
            >
              Withdrawn
            </p>
          </div>
        </div>
        <div class="row mx-lg-4">
          <div class="col-lg-8 pt-3">
            <div class="border rounded px-lg-4 pt-4">
              <div
                class="px-4 px-lg-0"
                [ngClass]="{ 'd-flex': dataService.desktopView }"
              >
                <div
                  class="d-flex"
                  [ngClass]="{
                    'mr-5 pr-4':
                      dataService.desktopView && dataService.isSiteLtr(),
                    'ml-5 pl-4':
                      dataService.desktopView && dataService.isSiteRtl()
                  }"
                >
                  <div>
                    <img
                      width="50"
                      height="50"
                      loading="lazy"
                      *ngIf="approvalsService.approvalData.requesterProfileImageUrl"
                      class="user-img d-inline-block my-3"
                      [ngClass]="{ 'img-border': userService.imageHasError }"
                      [src]="userService.imageHasError ? userService.fallbackSrc : approvalsService.approvalData.requesterProfileImageUrl"
                      (error)="userService.imageHasError = true"
                    />
                    <div
                      *ngIf="!approvalsService.approvalData.requesterProfileImageUrl"
                      class="name-first-letters requester-img my-3"
                      [ngStyle]="{
                        'background-color':
                          approvalsService.approvalData
                            .requesterProfileImageBackgroundHexColor
                      }"
                    >
                      {{
                        approvalsService.approvalData.requesterFirstname
                          .substring(0, 1)
                          .toUpperCase()
                      }}{{
                        approvalsService.approvalData.requesterLastname
                          .substring(0, 1)
                          .toUpperCase()
                      }}
                    </div>
                  </div>

                  <div class="pt-4">
                    <p
                      class="gray-primaray-text bold-600 font-18 mb-0"
                      [ngClass]="{
                        'ml-3': dataService.isSiteLtr(),
                        'mr-3': dataService.isSiteRtl(),
                        'mt-2': !approvalsService.approvalData.requesterTeamName,
                        'dotted-text dotted-text-direction':
                          dataService.mobileView
                      }"
                    >
                      <span class="pr-2">{{
                        this.approvalsService.approvalData.requesterFirstname
                      }}</span
                      >{{ this.approvalsService.approvalData.requesterLastname }}
                    </p>
                    <p
                      *ngIf="this.approvalsService.approvalData.requesterTeamName"
                      class="gray-text font-14"
                      [ngClass]="{
                        'ml-3': dataService.isSiteLtr(),
                        'mr-3': dataService.isSiteRtl()
                      }"
                    >
                      {{ this.approvalsService.approvalData.requesterTeamName }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="gray-text mt-4 px-4 px-lg-0">
                <ng-container i18n="@@bookedFor">Booked for</ng-container>
                {{ approvalsService.approvalData.guestFirstname }}
                {{ approvalsService.approvalData.guestLastname }}
              </div>
              <div class="price-container pb-4 gray-primaray-text font-16 mt-3">
                <div class="px-4 px-lg-0">
                  <p class="bold-600 mb-2" i18n="@@tripSummary">Trip Summary</p>
                  <p
                    class="mb-1"
                    [ngClass]="{
                      'mr-0': dataService.isSiteLtr(),
                      'ml-0': dataService.isSiteRtl()
                    }"
                    i18n="@@approvalAvgNightlyPrice"
                  >
                    Average Nightly Price
                  </p>
                  <p class="gray-text mb-1">
                    {{
                    this.approvalsService.approvalHotelData.rooms[0].rates[0]
                            .averageNightlySellingPriceConvertedCurrency
                    }} {{
                    this.approvalsService.approvalHotelData.rooms[0].rates[0]
                            .averageNightlySellingPriceConverted
                    }}
                    <i
                      *ngIf="this.approvalsService.approvalData.limitExceeded"
                      class="icon-warning-msg yellow-text font-12"
                    ></i>
                  </p>

                  <p
                    class="mb-1 mt-4"
                    [ngClass]="{
                      'mr-0': dataService.isSiteLtr(),
                      'ml-0': dataService.isSiteRtl()
                    }"
                    i18n="@@totalPrice"
                  >
                    Total Price
                  </p>
                  <p class="gray-text mb-1">
                    {{
                    this.approvalsService.approvalHotelData.rooms[0].rates[0]
                            .finalSellingPriceConvertedCurrency
                    }} {{
                    this.approvalsService.approvalHotelData.rooms[0].rates[0]
                            .finalSellingPriceConverted
                    }}
                  </p>
                </div>
                <hr />
                <div class="px-4 px-lg-0">
                  <ng-container *ngIf="this.approvalsService.approvalData.policy">
                    <p class="bold-600 mt-4 mb-2">
                      <ng-container i18n="@@travelPolicy"
                        >Travel Policy</ng-container
                      >&nbsp;
                      <span class="font-weight-normal font-14"
                        >({{ this.approvalsService.approvalData.policy.name }})</span
                      >
                    </p>

                    <ng-container
                      *ngIf="
                        !approvalsService.approvalData.policy.hotelBookingNotAllowed
                      "
                    >
                      <p
                        class="mb-1 font-16 gray-text"
                        [ngClass]="{
                          'mr-0': dataService.isSiteLtr(),
                          'ml-0': dataService.isSiteRtl()
                        }"
                      >
                        <i
                          class="icon-check green-text font-12"
                          *ngIf="!this.approvalsService.approvalData.limitExceeded"
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>
                        <i
                          class="icon-close-light text-danger font-12"
                          *ngIf="this.approvalsService.approvalData.limitExceeded"
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>

                        <span i18n="@@nightlyPriceCap">Nightly price cap</span
                        >:<span
                          class="px-2"
                          *ngIf="
                            this.approvalsService.approvalData.appliedException &&
                            this.approvalsService.approvalData.appliedException
                              .avgNightlyPriceLimit
                          "
                          >{{
                        this.approvalsService.approvalData.appliedException
                                .avgNightlyPriceLimitCurrency
                        }} {{
                            this.approvalsService.approvalData.appliedException
                              .avgNightlyPriceLimit
                          }}</span
                        >
                        <span
                          class="px-2"
                          *ngIf="
                            !this.approvalsService.approvalData.appliedException &&
                            this.approvalsService.approvalData.policy
                              .companyPolicyHotelRules &&
                            this.approvalsService.approvalData.policy
                              .companyPolicyHotelRules.avgNightlyPriceLimit
                          "
                          >{{
                          this.approvalsService.approvalData.policy
                                  .companyPolicyHotelRules.avgNightlyPriceLimitCurrency
                          }} {{
                            this.approvalsService.approvalData.policy
                              .companyPolicyHotelRules.avgNightlyPriceLimit
                          }}</span
                        >
                      </p>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        approvalsService.approvalData.policy.hotelBookingNotAllowed
                      "
                    >
                      <p
                        class="mb-1"
                        [ngClass]="{
                          'mr-0': dataService.isSiteLtr(),
                          'ml-0': dataService.isSiteRtl()
                        }"
                      >
                        <i
                          class="icon-close-light text-danger font-12"
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>
                        <span
                          i18n="@@hotelBookingAllowed"
                          class="font-16 gray-text"
                          >Hotel booking allowed</span
                        >
                      </p>
                    </ng-container>
                  </ng-container>
                </div>

                <hr />
                <div class="px-4 px-lg-0">
                  <p class="bold-600 mb-2">
                    <ng-container i18n="@@approver">Approver</ng-container>
                  </p>
                  <div
                    class="px-lg-0"
                    [ngClass]="{ 'd-flex': dataService.desktopView }"
                  >
                    <p class="font-weight-normal font-16 gray-text">
                      <span class="pr-2">{{
                        this.approvalsService.approvalData.approverFirstname
                      }}</span
                      >{{ this.approvalsService.approvalData.approverLastname }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="border rounded px-4 pt-4 mt-4">
              <p
                class="font-16 gray-primaray-text bold-600"
                i18n="@@cancellationPolicies"
              >
                Cancellation Policies
              </p>

              <p
                class="gray-text-dark font-14 cancel-text"
                *ngFor="
                  let policy of this.approvalsService.approvalHotelData.rooms[0]
                    .rates[0].cancellationPolicies
                "
              >
                {{ policy.description }}
              </p>

              <p
                class="non-refundable gray-text-dark"
                *ngIf="
                  !this.approvalsService.approvalHotelData.rooms[0].rates[0]
                    .cancellationPolicies ||
                  (this.approvalsService.approvalHotelData.rooms[0].rates[0]
                    .cancellationPolicies &&
                    !this.approvalsService.approvalHotelData.rooms[0].rates[0]
                      .cancellationPolicies.length)
                "
                i18n="@@nonrefundable"
              >
                Non Refundable
              </p>
              <ng-container
                *ngIf="
                  this.approvalsService.approvalData.status.toLowerCase() !==
                    'disapproved' &&
                  this.approvalsService.approvalData.status.toLowerCase() !==
                    'expired' &&
                  this.approvalsService.approvalData.status.toLowerCase() !==
                    'withdrawn' &&
                  this.approvalsService.approvalData.status.toLowerCase() !==
                    'withdrawn'
                "
              >
                <p
                  class="font-16 gray-primaray-text bold-600 mt-4"
                  i18n="@@paymentMethod"
                >
                  Payment Method
                </p>
                <p
                  class="font-14 gray-text-dark"
                  *ngIf="
                    this.approvalsService.approvalData.status.toLowerCase() ===
                    'pending_approval'
                  "
                >
                  <span
                    *ngIf="
                      this.approvalsService.approvalData.payment.channelProvider ===
                      'AMAZON_PAYMENT_SERVICE'
                    "
                  >
                    <ng-container i18n="@@cardEndsWith"
                      >Card ending with</ng-container
                    >
                    {{ this.approvalsService.approvalData.payment.lastFour }}
                    <ng-container i18n="@@willCharged"
                      >will be charged with total of</ng-container
                    >
                    {{
                    this.approvalsService.approvalHotelData.rooms[0].rates[0]
                            .chargeAmountCurrency
                    }} {{
                    this.approvalsService.approvalHotelData.rooms[0].rates[0]
                            .chargeAmount | number : "1.0-2"
                    }}
                  </span>
                </p>

                <p
                  class="font-14 gray-text-dark"
                  *ngIf="
                    this.approvalsService.approvalData.status.toLowerCase() ===
                    'approved'
                  "
                >
                  <span
                    *ngIf="
                      this.approvalsService.approvalData.payment.channelProvider ===
                      'AMAZON_PAYMENT_SERVICE'
                    "
                  >
                     {{
                    this.approvalsService.approvalHotelData.rooms[0].rates[0]
                            .chargeAmountCurrency
                    }} {{
                    this.approvalsService.approvalHotelData.rooms[0].rates[0]
                            .chargeAmount | number : "1.0-2"
                    }}
                    <ng-container i18n="@@charged"
                      >charged to card ending with</ng-container
                    >
                    {{ this.approvalsService.approvalData.payment.lastFour }}
                  </span>
                </p>
              </ng-container>
            </div>

            <div class="mt-4" *ngIf="dataService.desktopView">
              <div
                class="mb-2 pt-4"
                *ngFor="let msg of this.approvalsService.approvalData.operations"
              >
                <div
                  [ngClass]="{ 'chat-max-width': dataService.desktopView }"
                  *ngIf="msg.operationType === 'REQUEST_MORE_INFO'"
                >
                  <div
                    class="d-flex"
                    [ngClass]="{
                      'mr-5': dataService.isSiteLtr(),
                      'ml-5': dataService.isSiteRtl()
                    }"
                  >
                    <div>
                      <img
                        width="35"
                        height="35"
                        loading="lazy"
                        *ngIf="approvalsService.approvalData.approverProfileImageUrl"
                        class="user-img d-inline-block my-3"
                        [ngClass]="{ 'img-border': userService.imageHasError }"
                        [src]="userService.imageHasError ? userService.fallbackSrc : approvalsService.approvalData.approverProfileImageUrl"
                        (error)="userService.imageHasError = true"
                      />
                      <div
                        *ngIf="
                          !approvalsService.approvalData.approverProfileImageUrl
                        "
                        class="name-first-letters small my-3"
                        [ngStyle]="{
                          'background-color':
                            approvalsService.approvalData
                              .approverProfileImageBackgroundHexColor
                        }"
                      >
                        {{
                          approvalsService.approvalData.approverFirstname
                            .substring(0, 1)
                            .toUpperCase()
                        }}{{
                          approvalsService.approvalData.approverLastname
                            .substring(0, 1)
                            .toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="pt-4">
                      <p
                        class="primaray-text bold-600 font-18 minus-margin-bottom"
                        [ngClass]="{
                          'ml-2': dataService.isSiteLtr(),
                          'mr-2': dataService.isSiteRtl()
                        }"
                      >
                        <span class="pr-2">{{
                          this.approvalsService.approvalData.approverFirstname
                        }}</span
                        >{{ this.approvalsService.approvalData.approverLastname }}
                      </p>
                    </div>
                  </div>
                  <div class="border rounded p-4 gray-text-dark">
                    {{ msg.comment }}
                  </div>
                </div>

                <div
                  [ngClass]="{ 'chat-max-width': dataService.desktopView }"
                  *ngIf="msg.operationType === 'SUBMIT_MORE_INFO'"
                >
                  <div class="d-flex">
                    <div>
                      <img
                        width="35"
                        height="35"
                        loading="lazy"
                        *ngIf="
                          approvalsService.approvalData.requesterProfileImageUrl
                        "
                        class="user-img d-inline-block my-3"
                        [ngClass]="{ 'img-border': userService.imageHasError }"
                        [src]="userService.imageHasError ? userService.fallbackSrc : approvalsService.approvalData.requesterProfileImageUrl"
                        (error)="userService.imageHasError = true"
                      />
                      <div
                        *ngIf="
                          !approvalsService.approvalData.requesterProfileImageUrl
                        "
                        class="name-first-letters small my-3"
                        [ngStyle]="{
                          'background-color':
                            approvalsService.approvalData
                              .requesterProfileImageBackgroundHexColor
                        }"
                      >
                        {{
                          approvalsService.approvalData.requesterFirstname
                            .substring(0, 1)
                            .toUpperCase()
                        }}{{
                          approvalsService.approvalData.requesterLastname
                            .substring(0, 1)
                            .toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="pt-4">
                      <p
                        class="primaray-text bold-600 font-18 minus-margin-bottom"
                        [ngClass]="{
                          'ml-2': dataService.isSiteLtr(),
                          'mr-2': dataService.isSiteRtl()
                        }"
                        i18n="@@me"
                      >
                        Me
                      </p>
                    </div>
                  </div>
                  <div class="border rounded p-4 gray-text-dark gray-bg">
                    {{ msg.comment }}
                  </div>
                </div>
              </div>

              <form
                class="my-5"
                [formGroup]="requestMoreInfoForm"
                *ngIf="
                  this.approvalsService.approvalData.status.toLowerCase() ===
                  'pending_approval'
                "
              >
                <textarea
                  name="moreComment"
                  id="moreComment"
                  formControlName="moreComment"
                  class="form-control pt-3 mt-5"
                  i18n-placeholder="@@LeaveComments"
                  placeholder="Type your message here"
                  rows="6"
                  [ngClass]="{
                    'is-invalid': submitted && c.moreComment.errors
                  }"
                ></textarea>
                <div
                  *ngIf="submitted && c.moreComment.errors"
                  class="text-danger smaller mt-2"
                >
                  <div
                    *ngIf="c.moreComment.errors.required"
                    i18n="@@commentRequired"
                  >
                    Comment is required
                  </div>
                </div>
                <div class="d-flex" *ngIf="dataService.desktopView">
                  <div
                    class="actions"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                  >
                    <button
                      class="mt-5 btn-block blue_btn sharp-font bold-500 font-14 px-4 text-center"
                      (click)="requestSubmitMoreInfo()"
                      [ngClass]="{ 'm-progress': submitting }"
                      i18n="@@send"
                    >
                      Send
                    </button>
                  </div>
                </div>
                <div
                  *ngIf="dataService.mobileView"
                  [ngClass]="{
                    'gray-text': submitting,
                    'blue-text': !submitting,
                    'text-right': dataService.isSiteLtr(),
                    'text-right': dataService.isSiteRtl()
                  }"
                  class="font-16 mt-3 font-weight-bold"
                  (click)="requestSubmitMoreInfo()"
                  i18n="@@send"
                >
                  Send
                </div>
              </form>
            </div>
          </div>
          <div
            class="col-lg-4 hotel-details-side pt-3"
            *ngIf="this.approvalsService.approvalHotelData"
          >
            <div class="p-0 mb-4 white-box">
              <figure
                class="hotel-img-booking"
                *ngIf="
                  approvalsService.approvalHotelData.images &&
                  approvalsService.approvalHotelData.images[0] &&
                  approvalsService.approvalHotelData.images[0].url.includes(
                    'https://'
                  )
                "
                [ngStyle]="{
                  'background-image':
                    'url(' + approvalsService.approvalHotelData.images[0].url + ')'
                }"
              ></figure>

              <figure
                class="hotel-img-booking"
                *ngIf="
                  approvalsService.approvalHotelData.images &&
                  approvalsService.approvalHotelData.images[0] &&
                  !approvalsService.approvalHotelData.images[0].url.includes(
                    'https://'
                  )
                "
                [ngStyle]="{
                  'background-image':
                    'url(' +
                    approvalsService.approvalHotelData.images[0].url.replace(
                      'http://',
                      'https://'
                    ) +
                    ')'
                }"
              ></figure>

              <ng-container
                *ngIf="
                  !approvalsService.approvalHotelData.images ||
                  !approvalsService.approvalHotelData.images[0]
                "
              >
                <img
                  class="rounded-top booking-form-img-hotel w-100"
                  src="assets/img/no_image.png"
                />
              </ng-container>
              <div class="p-4">
                <div class="border-bottom pb-3">
                  <h4 class="primaray-text bold-600">
                    {{ this.approvalsService.approvalHotelData.name }}
                  </h4>
                  <div class="address-inner gray-text">
                    <i class="icon-locator gray-text"></i>
                    {{
                      approvalsService.approvalHotelData.address
                        ? approvalsService.approvalHotelData.address + ", "
                        : ""
                    }}
                    {{
                      this.approvalsService.approvalHotelData.cityName
                        ? this.approvalsService.approvalHotelData.cityName
                        : ""
                    }}{{
                      this.approvalsService.approvalHotelData.stateCode &&
                      this.approvalsService.approvalHotelData.cityName
                        ? ", "
                        : ""
                    }}{{
                      this.approvalsService.approvalHotelData.stateCode
                        ? this.approvalsService.approvalHotelData.stateCode
                        : ""
                    }}{{
                      (this.approvalsService.approvalHotelData.stateCode ||
                        this.approvalsService.approvalHotelData.cityName) &&
                      this.approvalsService.approvalHotelData.countryName
                        ? ", "
                        : ""
                    }}{{
                      this.approvalsService.approvalHotelData.countryName
                        ? this.approvalsService.approvalHotelData.countryName
                        : ""
                    }}
                  </div>
                </div>

                <div
                  class="pb-2"
                  [ngClass]="{
                    'border-bottom pt-2':
                      this.approvalsService.approvalFacilitiesFeaturedavailabile ===
                      true
                  }"
                >
                  <ul
                    *ngIf="this.approvalsService.approvalHotelData"
                    class="facilities-main mb-0 list-unstyled"
                  >
                    <ng-container
                      *ngFor="
                        let item of this.approvalsService.approvalHotelData
                          .myFacilities
                      "
                    >
                      <ng-container *ngIf="item.featured">
                        <li
                          class="facilities-main-item d-inline-block mb-2 mt-2"
                        >
                          <span
                            class="d-block"
                            [ngClass]="{
                              'pr-5': dataService.isSiteLtr(),
                              'pl-5': dataService.isSiteRtl()
                            }"
                          >
                            <i [class]="'icon-' + item.hrsCode"></i>
                            {{ item.title }}
                          </span>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
                </div>
              </div>
              <div class="px-4">
                <div class="margin_bottom">
                  <p
                    class="mb-0 font-16"
                    *ngIf="
                      this.approvalsService.approvalHotelData.rooms[0].rates[0]
                        .roomName
                    "
                  >
                    {{
                      dataService.camelCaseText(
                        this.approvalsService.approvalHotelData.rooms[0].rates[0]
                          .roomName
                      )
                    }}
                  </p>
                </div>
                <div
                  *ngIf="
                    this.approvalsService.approvalHotelData.lowestSellingPriceRate &&
                    this.approvalsService.approvalHotelData.lowestSellingPriceRate
                      .hrsBoardCode != 'RO'
                  "
                  class="mb-3"
                >
                  <i
                    [ngClass]="
                      'icon-' +
                      this.approvalsService.approvalHotelData.lowestSellingPriceRate
                        .boardCode
                    "
                  >
                  </i
                  >{{
                    this.approvalsService.approvalHotelData.lowestSellingPriceRate
                      .boardName
                  }}
                </div>
                <ng-container>
                  <p class="mb-0">
                    <span class="check-date-margin"
                      ><ng-container i18n="@@checkInNoDash"
                        >Check In</ng-container
                      >:</span
                    >
                    {{
                      this.approvalsService.approvalBookingInfo.searchRequest.checkIn
                        | date : "EE, MMM d, y "
                    }}
                  </p>
                  <p>
                    <span
                      [ngClass]="{
                        'mr-2': dataService.isSiteLtr(),
                        'ml-2': dataService.isSiteRtl()
                      }"
                      ><ng-container i18n="@@checkOutNoDash"
                        >Check Out</ng-container
                      >:</span
                    >
                    {{
                      this.approvalsService.approvalBookingInfo.searchRequest
                        .checkOut | date : "EE, MMM d, y "
                    }}
                  </p>
                </ng-container>
                <p class="margin_top">
                  <span
                    class="badge badge-secondary d-inline-block font-12"
                    [ngClass]="{
                      'mr-1': dataService.isSiteLtr(),
                      'ml-1': dataService.isSiteRtl()
                    }"
                    ><!--{{diffInDays(this.approvalsService.approvalBookingInfo.searchRequest.checkOut, this.approvalsService.approvalBookingInfo.searchRequest.checkIn)}}-->
                    <span
                      class="night-cont"
                      *ngIf="
                        diffInDays(
                          this.approvalsService.approvalBookingInfo.searchRequest
                            .checkOut,
                          this.approvalsService.approvalBookingInfo.searchRequest
                            .checkIn
                        ) > 1;
                        else Night
                      "
                    >
                      <ng-container
                        *ngIf="
                          diffInDays(
                            this.approvalsService.approvalBookingInfo.searchRequest
                              .checkOut,
                            this.approvalsService.approvalBookingInfo.searchRequest
                              .checkIn
                          ) > 2
                        "
                      >
                        {{
                          diffInDays(
                            this.approvalsService.approvalBookingInfo.searchRequest
                              .checkOut,
                            this.approvalsService.approvalBookingInfo.searchRequest
                              .checkIn
                          )
                        }}
                        <ng-container i18n="@@nights"
                          >nights</ng-container
                        ></ng-container
                      >
                      <ng-container
                        i18n="@@twoNights"
                        *ngIf="
                          diffInDays(
                            this.approvalsService.approvalBookingInfo.searchRequest
                              .checkOut,
                            this.approvalsService.approvalBookingInfo.searchRequest
                              .checkIn
                          ) === 2
                        "
                      >
                        2 nights</ng-container
                      >
                    </span>

                    <span class="night-cont">
                      <ng-template #Night>
                        <ng-container i18n="@@night">1 night</ng-container>
                      </ng-template>
                    </span>
                    <ng-container i18n="@@stay">Stay</ng-container>
                  </span>
                  <span
                    class="badge badge-secondary d-inline-block font-12"
                    [ngClass]="{
                      'mr-1': dataService.isSiteLtr(),
                      'ml-1': dataService.isSiteRtl()
                    }"
                    *ngIf="this.approvalsService.approvalPaxes + 1 > 1"
                  >
                    <span
                      class="night-cont font-12"
                      *ngIf="this.approvalsService.approvalPaxes + 1 > 2"
                      >{{ this.approvalsService.approvalPaxes + 1 }}
                      <ng-container i18n="@@guests">Guests</ng-container></span
                    >
                    <span
                      class="night-cont font-12"
                      *ngIf="this.approvalsService.approvalPaxes + 1 === 2"
                      >{{ this.approvalsService.approvalPaxes + 1 }}
                      <ng-container i18n="@@twoGuests"
                        >Guests</ng-container
                      ></span
                    >
                  </span>
                  <span
                    class="badge badge-secondary d-inline-block font-12"
                    [ngClass]="{
                      'mr-1': dataService.isSiteLtr(),
                      'ml-1': dataService.isSiteRtl()
                    }"
                    *ngIf="this.approvalsService.approvalPaxes + 1 === 1"
                  >
                    <span class="night-cont font-12"
                      >1 <ng-container i18n="@@guest">Guest</ng-container></span
                    >
                  </span>
                </p>
              </div>
            </div>
            <div class="p-0 mb-4 white-box">
              <div class="px-4 py-3">
                <h5
                  class="font-18 bold-600 gray-primaray-text mb-3"
                  i18n="@@priceSummary"
                >
                  Price Summary
                </h5>
                <div
                  class="daily"
                  *ngFor="
                    let daily of this.approvalsService.approvalHotelData.rooms[0]
                      .rates[0].dailyRates
                  "
                >
                  <div class="d-flex pb-1 gray-primaray-text">
                    <span class="font-14">
                      {{ dataService.formatDateEEMMMdy(daily.date) }}</span
                    >
                    <span
                      [ngClass]="{
                        'ml-auto': dataService.isSiteLtr(),
                        'mr-auto': dataService.isSiteRtl()
                      }"
                    >
                      <span class="currency font-14">{{
                        daily.sellingPriceConvertedCurrency
                        }} </span
                      ><span class="amount font-14">{{
                        daily.sellingPriceConverted | number
                      }}</span>
                    </span>
                  </div>
                </div>
                <div
                  class="discount"
                  *ngIf="
                    !this.approvalsService.approvalHotelData.rooms[0].rates[0]
                      .taxIncluded &&
                    this.approvalsService.approvalHotelData.rooms[0].rates[0].tax
                  "
                >
                  <div class="d-flex pb-1 gray-primaray-text">
                    <span class="font-14" i18n="@@taxAndFees"
                      >Tax and fees</span
                    >
                    <span
                      [ngClass]="{
                        'ml-auto': dataService.isSiteLtr(),
                        'mr-auto': dataService.isSiteRtl()
                      }"
                      ><span class="currency font-14">$</span
                      ><span class="amount font-14">{{
                        this.approvalsService.approvalHotelData.rooms[0].rates[0].tax
                      }}</span></span
                    >
                  </div>
                </div>
              </div>
              <div class="rounded-bottom py-3 px-4 white-box-footer">
                <div class="d-flex font-21 bold-600">
                  <span class="primaray-text" i18n="@@Total"> Total </span>
                  <span
                    class="primaray-text"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                  >
                    <span class="currency">{{
                      approvalsService.approvalHotelData.rooms[0].rates[0]
                              .finalSellingPriceConvertedCurrency
                      }} </span
                    ><span class="amount">{{
                      approvalsService.approvalHotelData.rooms[0].rates[0]
                        .finalSellingPriceConverted
                    }}</span></span
                  >
                </div>
              </div>
            </div>
            <app-cost-reporting-details
              [costCentersIds]="
                approvalsService.approvalData?.approvalRequest?.costCenterId
                  ? [approvalsService.approvalData?.approvalRequest?.costCenterId]
                  : ''
              "
              [labelsIds]="
                approvalsService.approvalData?.approvalRequest?.labelsIds
                  ? approvalsService.approvalData?.approvalRequest?.labelsIds
                  : []
              "
            >
            </app-cost-reporting-details>
          </div>
          <div class="col-lg-8">
            <ng-container *ngIf="dataService.mobileView">
              <div
                class="mb-2 pt-4"
                *ngFor="let msg of this.approvalsService.approvalData.operations"
              >
                <div
                  [ngClass]="{ 'chat-max-width': dataService.desktopView }"
                  *ngIf="msg.operationType === 'REQUEST_MORE_INFO'"
                >
                  <div
                    class="d-flex"
                    [ngClass]="{
                      'mr-5': dataService.isSiteLtr(),
                      'ml-5': dataService.isSiteRtl()
                    }"
                  >
                    <div>
                      <img
                        width="35"
                        height="35"
                        loading="lazy"
                        *ngIf="approvalsService.approvalData.approverProfileImageUrl"
                        class="user-img d-inline-block my-3"
                        [ngClass]="{ 'img-border': userService.imageHasError }"
                        [src]="userService.imageHasError ? userService.fallbackSrc : approvalsService.approvalData.approverProfileImageUrl"
                        (error)="userService.imageHasError = true"
                      />
                      <div
                        *ngIf="
                          !approvalsService.approvalData.approverProfileImageUrl
                        "
                        class="name-first-letters small my-3"
                      >
                        {{
                          approvalsService.approvalData.approverFirstname
                            .substring(0, 1)
                            .toUpperCase()
                        }}{{
                          approvalsService.approvalData.approverLastname
                            .substring(0, 1)
                            .toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="pt-4">
                      <p
                        class="primaray-text bold-600 font-18 minus-margin-bottom"
                        [ngClass]="{
                          'ml-2': dataService.isSiteLtr(),
                          'mr-2': dataService.isSiteRtl()
                        }"
                      >
                        <span class="pr-2">{{
                          this.approvalsService.approvalData.approverFirstname
                        }}</span
                        >{{ this.approvalsService.approvalData.approverLastname }}
                      </p>
                    </div>
                  </div>
                  <div class="border rounded p-4 gray-text-dark">
                    {{ msg.comment }}
                  </div>
                </div>

                <div
                  [ngClass]="{ 'chat-max-width': dataService.desktopView }"
                  *ngIf="msg.operationType === 'SUBMIT_MORE_INFO'"
                >
                  <div class="d-flex">
                    <div>
                      <img
                        width="35"
                        height="35"
                        loading="lazy"
                        *ngIf="
                          approvalsService.approvalData.requesterProfileImageUrl
                        "
                        class="user-img d-inline-block my-3"
                        [ngClass]="{ 'img-border': userService.imageHasError }"
                        [src]="userService.imageHasError ? userService.fallbackSrc : approvalsService.approvalData.requesterProfileImageUrl"
                        (error)="userService.imageHasError = true"
                      />
                      <div
                        *ngIf="
                          !approvalsService.approvalData.requesterProfileImageUrl
                        "
                        class="name-first-letters small my-3"
                        [ngStyle]="{
                          'background-color':
                            approvalsService.approvalData
                              .requesterProfileImageBackgroundHexColor
                        }"
                      >
                        {{
                          approvalsService.approvalData.requesterFirstname
                            .substring(0, 1)
                            .toUpperCase()
                        }}{{
                          approvalsService.approvalData.requesterLastname
                            .substring(0, 1)
                            .toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="pt-4">
                      <p
                        class="primaray-text bold-600 font-18 minus-margin-bottom"
                        [ngClass]="{
                          'ml-2': dataService.isSiteLtr(),
                          'mr-2': dataService.isSiteRtl()
                        }"
                        i18n="@@me"
                      >
                        Me
                      </p>
                    </div>
                  </div>
                  <div class="border rounded p-4 gray-text-dark gray-bg">
                    {{ msg.comment }}
                  </div>
                </div>
              </div>
              <form
                class="my-5"
                [formGroup]="requestMoreInfoForm"
                *ngIf="
                  this.approvalsService.approvalData.status.toLowerCase() ===
                  'pending_approval'
                "
              >
                <textarea
                  name="moreComment"
                  id="moreComment"
                  formControlName="moreComment"
                  class="form-control pt-3 mt-5"
                  i18n-placeholder="@@LeaveComments"
                  placeholder="Type your message here"
                  rows="6"
                  [ngClass]="{
                    'is-invalid': submitted && c.moreComment.errors
                  }"
                ></textarea>
                <div
                  *ngIf="submitted && c.moreComment.errors"
                  class="text-danger smaller mt-2"
                >
                  <div
                    *ngIf="c.moreComment.errors.required"
                    i18n="@@commentRequired"
                  >
                    Comment is required
                  </div>
                </div>
                <div class="d-flex" *ngIf="dataService.desktopView">
                  <div
                    class="actions"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                  >
                    <button
                      class="mt-5 btn-block blue_btn sharp-font bold-500 font-14 px-4 text-center"
                      (click)="requestSubmitMoreInfo()"
                      [ngClass]="{ 'm-progress': submitting }"
                      i18n="@@send"
                    >
                      Send
                    </button>
                  </div>
                </div>
                <button
                  *ngIf="dataService.mobileView"
                  class="mt-5 btn-block blue_btn sharp-font bold-500 font-14 px-4 text-center"
                  [ngClass]="{ 'm-progress': submitting }"
                  (click)="requestSubmitMoreInfo()"
                  i18n="@@send"
                >
                  Send
                </button>
                <button
                  class="mt-5 btn-block btn btn-outline-blue sharp-font bold-500 font-14 px-4 text-center"
                  (click)="
                    withdrawApprovalRequestPopup(
                      this.approvalsService.approvalData.id
                    )
                  "
                  i18n="@@withdraw"
                >
                  Withdraw
                </button>
              </form>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
