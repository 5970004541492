
<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
                         [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pt-5 pb-0 pb-lg-5">
        <div class="mx-lg-5">
          <div class="font-weight-bold blue-text pb-4 font-16-to-14 mx-lg-4">
            <div class="row">
              <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-12': dataService.mobileView}">
                <div class="d-flex">
                  <p class="text-center pointer" [ngClass]="{'pr-3 border-right': dataService.isSiteLtr(), 'pl-3 border-left': dataService.isSiteRtl()}"
                     (click)="this.router.navigate(['/user/profile']);" i18n="@@profile">Profile</p>
                  <p class="pointer px-3" [ngClass]="{'text-right border-right mr-3': dataService.isSiteLtr(), 'text-left border-left ml-3': dataService.isSiteRtl()}"
                     (click)="this.router.navigate(['/user/payment']);" i18n="@@paymentMethods">Payment Methods</p>
                  <p class="gray-primaray-text"
                     i18n="@@rewards">Rewards</p>
                </div>
              </div>
              <div *ngIf="dataService.desktopView" [ngClass]="{'col-auto': dataService.desktopView, 'col-md-12': dataService.mobileView,
              'ml-auto': dataService.isSiteLtr() && dataService.desktopView, 'mr-auto': dataService.isSiteRtl() && dataService.desktopView}">
                <button (click)="this.auth.logout();"
                        class=" btn-block btn btn-outline-gray px-5 gray-text-dark font-14 sharp-font bold-500" i18n="@@logOutBtn">
                  Logout
                </button>
              </div>
            </div>

          </div>

<div class="mx-lg-4 mb-5 pb-5 mt-lg-5">

  <ng-container *ngIf="!this.auth.userInfo.rewardWallet || !this.auth.userInfo.rewardWallet.balance">
  <div class="row">
    <div class="col-md-5">
      <div class="white-box mb-4 max-width-350">
        <p class="font-16 gray-primaray-text" i18n="@@rewardsBalance">Rewards Balance</p>
        <p class="font-26-to-22 gray-primaray-text bold-600 mb-0">0.00 USD</p>
        <p class="gray-text" i18n="@@available">Available</p>
      </div>
    </div>
  </div>
    <div class="row">
      <div class="col-md-6">
        <div class="mb-5 pb-5 pt-4 rewards-bg">
          <p class="primaray-text font-22-to-18 mb-2 pt-4 bold-600"><ng-container i18n="@@rewardsNoThe">{{this.auth.companyInfo.name}} Rewards</ng-container></p>
          <p class="gray-text-dark font-16 mb-5" i18n="@@enjoyBenefits">Enjoy the benefits of being a member</p>
          <div class="d-flex mb-5">
            <div class="number-circle primaray-text font-16 bold-600 text-center" [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}">1</div>
            <div>
              <p class="gray-primaray-text font-16 mb-0 bold-600" i18n="@@book">Book</p>
              <p class="gray-text-dark mb-0" i18n="@@bookTravel">Book travel below your travel policy</p>
            </div>
          </div>

          <div class="d-flex mb-5">
            <div class="number-circle primaray-text font-16 bold-600 text-center" [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}">2</div>
            <div>
              <p class="gray-primaray-text font-16 mb-0 bold-600" i18n="@@earn">Earn</p>
              <p class="gray-text-dark mb-0" i18n="@@earnDollarRewards">Earn dollar rewards % of savings upon trip completion</p>
            </div>
          </div>

          <div class="d-flex mb-4">
            <div class="number-circle primaray-text font-16 bold-600 text-center" [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}">3</div>
            <div>
              <p class="gray-primaray-text font-16 mb-0 bold-600" i18n="@@redeem">Redeem</p>
              <p class="gray-text-dark mb-0" i18n="@@redeemGift">Redeem your gift cards over 50+ brands</p>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-6">
        <div class="h-100 d-flex pt-5">
          <img width="450" height="273" class="max-100 ml-auto my-auto initial-height" loading="lazy" src="./assets/img/Expenses-Integrations-rewards.png" alt="Expenses Integrations rewards">
        </div>
       </div>
    </div>
  </ng-container>
  <!------>
  <ng-container *ngIf="this.auth.userInfo.rewardWallet && this.auth.userInfo.rewardWallet.balance">
  <div class="row" >
    <div class="col-md-5">
      <div class="white-box mb-4 max-width-350">
        <p class="font-16 gray-primaray-text" i18n="@@rewardsBalance">Rewards Balance</p>
        <p class="font-26-to-22 gray-primaray-text bold-600 mb-0">{{this.auth.userInfo.rewardWallet.balance | number : '1.2-2'}} {{this.auth.userInfo.rewardWallet.currencyCode}}</p>
        <p class="gray-text" i18n="@@available">Available</p>
      </div>
    </div>
    <ng-container *ngIf="historyList && historyList.length">
    <div class="col-md-4" [ngClass]="{'offset-md-3': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
      <p class="font-16 bold-600 gray-primaray-text" i18n="@@recentActivity">Recent Activity</p>
      <ng-container *ngFor="let item of historyList, let i = index">
        <ng-container *ngIf="i < 3">
      <div class="d-flex">
        <p class="gray-primaray-text mb-0">{{item.description}}</p>
        <p class="mb-0" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl(), 'green-text': item.type === 'CREDIT', 'gray-primaray-text': item.type !== 'CREDIT'}"><span *ngIf="item.type === 'CREDIT'">+</span><span *ngIf="item.type !== 'CREDIT'">-</span>{{item.amount}} USD</p>
      </div>
      <div class="d-flex">
        <p class="gray-text mb-0">{{item.details}}</p>
        <p class="gray-text mb-0" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}" [innerHTML]="formatDate(item.creationDate)">Nov 15, 2020</p>
      </div>
      <hr class="mb-4" />
        </ng-container>
      </ng-container>
      <p><a (click)="this.router.navigate(['/user/rewards/history']);" class="blue-text font-16" i18n="@@viewAll">View All</a></p>
    </div>
    </ng-container>


  </div>
<div class="row">
  <div class="col-lg-3 pt-1">
    <p class="font-18 bold-600 mt-5" i18n="@@redeemMyGift">Redeem my gift card</p>
    <div class="form-group">
      <input  class="form-control" id="search"  type="text" name="search" i18n-placeholder="@@searchForBrand" placeholder="Search for brand" (focus)="filterByText($event);" (keyup)="filterByText($event);" />
    </div>
  </div>
</div>

  <div class="position-relative cards-container">
  <div class="row">

    <div class="col-md-6 col-lg-3" *ngFor="let item of brands, let i = index" [ngClass]="{'overlay-card': showBrand === 'Q' + i}">
        <div class="border px-0 pt-3 pb-3 mb-3 rounded white-box shadow-sm white-bg" *ngIf="i === 0">
          <h3 class="text-center my-0 font-16 container px-4 position-relative " (click)="showBrandByID(i); redeemValue = '';">
            <img width="157" height="98" class="max-100" loading="lazy" src="./assets/img/{{item}}.png" alt="Expenses Integrations rewards">
          </h3>
          <div [ngClass]="{'show-brand': showBrand === 'Q' + i, 'hide-brand': showBrand !== 'Q' + i}">
<ng-container *ngIf="!successfulRedeem">
              <div class="row max-row-width mx-auto justify-content-center pt-4">
                <div class="checkbox-radio-item-container col-auto form-group " *ngFor="let price of priceList, let i = index">
                  <input class="d-none" type="radio" id="{{i}}" name="price" (click)="redeemValue = price" />
                  <label class="rounded border w-100  d-flex justify-content-center align-items-center mb-0"
                         [ngClass]="{'unselected green-text': redeemValue === price, 'gray-text-dark': redeemValue !== price}"  for="{{i}}">
                    <div class="text-center">
                      <span class="d-block py-1">${{price}}</span>
                    </div>
                  </label>
                </div>


                <div class="form-group mb-3 amount-field px-2"  [ngClass]="{'mr-auto': dataService.isSiteLtr(), 'ml-auto': dataService.isSiteRtl(), 'show': showBrand === 'Q' + i, 'hide': showBrand !== 'Q' + i}">
                  <span class="sign-dollar font-16 bolder px-2">$</span>
                  <input type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="price" class="form-control custom-input"
                          (focus)="redeemValue = ''"/>
                  <div *ngIf="submitted && !redeemValue" class="text-danger smaller">
                    {{errorMsg}}
                  </div>

                </div>
                <button class="btn btn-outline-blue font-14 sharp-font bold-500 my-3 py-1 px-5 mx-2 w-100" [ngClass]="{'m-progress': submitting}" (click)="redeemReward()" i18n="@@redeem">Redeem</button>


              </div>


</ng-container>
            <div *ngIf="successfulRedeem" class="green-text text-center p-4 m-2" i18n="@@successfulRedeem">
              Successful redeemed ${{redeemValue}} gift card. We’ve sent you the gift card via email.
            </div>
          </div>
        </div>
    </div>

  </div>

<!--  <div class="row" [ngClass]="{'second-row': dataService.desktopView}">

    <div class="col-md-6 col-lg-3" *ngFor="let item of brands, let i = index">
      <div class="border px-0 pt-3 pb-3 mb-3 rounded white-box shadow-sm white-bg">
        <h3 class="text-center my-0 font-16 container px-4 position-relative "  (click)="showBrandByID(i + 4); redeemValue = '';">
          <img width="157" height="98" class="max-100" loading="lazy" src="./assets/img/{{item}}.png" alt="Expenses Integrations rewards">
        </h3>
        <div [ngClass]="{'show-brand': showBrand === 'Q' + (i + 4), 'hide-brand': showBrand !== 'Q' + (i + 4)}">
          <ng-container *ngIf="!successfulRedeem">
          <div class="row max-row-width mx-auto justify-content-center pt-4">
            <div class="checkbox-radio-item-container col-auto form-group " *ngFor="let price of priceList, let y = index">
              <input class="d-none" type="radio" id="{{y}}" name="price" (click)="redeemValue = price" />
              <label class="rounded border w-100  d-flex justify-content-center align-items-center mb-0"
                     [ngClass]="{'unselected green-text': redeemValue === price, 'gray-text-dark': redeemValue !== price}"  for="{{y}}">
                <div class="text-center">
                  <span class="d-block py-1">${{price}}</span>
                </div>
              </label>
            </div>


            <div class="form-group mb-3 amount-field px-2 mr-auto"  [ngClass]="{'show': showBrand === 'Q' + (i + 4), 'd-none': showBrand !== 'Q' + (i + 4)}">
              <span class="sign-dollar font-16 bolder px-2">$</span>
              <input type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="price" class="form-control custom-input"
                     (focus)="redeemValue = ''"/>
              <div *ngIf="submitted && !redeemValue" class="text-danger smaller">
                {{errorMsg}}
              </div>

            </div>
            <button class="btn btn-outline-blue font-14 sharp-font bold-500 my-3 py-1 px-5 mx-2 w-100" [ngClass]="{'m-progress': submitting}" (click)="redeemReward()">Redeem</button>


          </div>

          </ng-container>
          <div *ngIf="successfulRedeem" class="green-text text-center p-4 m-2">
            Successful redeemed ${{redeemValue}} gift card. We’ve sent you the gift card via email.
          </div>




        </div>


      </div>
    </div>

  </div>-->
  </div>





</ng-container>





</div>



        </div>
        <div *ngIf="dataService.mobileView" class="col-md-6 px-0 my-5">
          <button (click)="this.auth.logout();"
                  class=" btn-block btn btn-outline-gray px-5 gray-text-dark font-14 sharp-font bold-500" i18n="@@logOutBtn">
            Logout
          </button>
        </div>
      </div>



    </div>
  </mat-sidenav-content>
</mat-sidenav-container>








