import {Component, OnInit, ElementRef, ViewChild, OnDestroy} from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { DataService } from "../../services/data.service";
import {
  ScrollToService,
  ScrollToConfigOptions,
} from "@nicky-lenaers/ngx-scroll-to";
import * as moment from "moment";
import { GoogleAnalyticsService } from "../../google-analytics.service";
import { AuthServiceMain } from "../../auth/auth.service";
import { environment } from "../../../environments/environment";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import { VariablesService } from "../../services/vars.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { GoogleAnalytics4Service } from "../../google-analytics4.service";
import { CostBookingStateService } from "src/app/services/states/cost-booking-state.service";
import {PayType} from "../../shared/enums/pay-type.enum";
import {Card} from "../../shared/model/payment/card";
import {FlightBookingService} from "../../services/booking/flight.booking.service";
import {ApprovalsService} from "../../services/approvals.service";

declare let window: any;

@Component({
  selector: "app-workspace-flight-booking-form",
  templateUrl: "./workspace-flight-booking-form.component.html",
  styleUrls: ["./workspace-flight-booking-form.component.scss"],
})
export class WorkspaceFlightBookingFormComponent implements OnInit, OnDestroy {
  env = environment;
  getApprovalsList;
  paymentType = "new_card";
  payTypeMethod: PayType = PayType.Amazon;
  previousSavedCardListLength = 0; // Track previous length
  savedCardList: BehaviorSubject<Card[]> = new BehaviorSubject<Card[]>([]);
  selectedCard = "";
  savedCard;
  paymentID;
  submitted = false;
  data;
  travelerInfo;
  cardholder = false;
  SelectedsavedCard = "";
  CardSelected;
  errorMsg = "";
  public showSuccess = false;
  public showCancel = false;
  public showError = false;
  PaxesCount: any;
  rate;
  diffInDays;
  tripAdviserdata;
  previousUrl = sessionStorage.getItem("previousUrl");
  @ViewChild("priceElem", { static: false }) priceElem?: ElementRef;
  selectedDay;
  selectedMonth;
  selectedYear;
  bodErrorMsg;
  minDate = new Date();
  formatExpiryDateText;
  adultFormatExpiryDateText;
  adultsDOBErrorMsg;
  adultsSelectedMonth;
  adultsSelectedDay;
  adultsSelectedYear;
  adultsExpiryDate;
  expiryDate;
  otherAdultsTravelers;
  travelersArray = [];
  userControl = new FormControl();
  users = [];
  showUsersAutoCompletePopUp = false;
  //selectedUser;
  filteredUsers: Observable<any[]>;
  lastFilter: string = "";
  dataUsers;
  requiresApprovalData;
  submittingApproval;
  submittedApproval;
  phonePlaceHolder = "Phone number";
  userInfoObj = JSON.parse(localStorage.getItem("userInfo"));
  programsList;
  @ViewChild("userInput") userInput: ElementRef;
  travelerId;
  traveler;
  inValidCVV = false;
  noSelectedCard: boolean = false;
  private subscriptions = new Subscription();
  bookSubscription: Subscription;
  cardsSubscription: Subscription;
  requireApprovalSubscription: Subscription;
  approvalsListSubscription: Subscription;
  infoSubscription: Subscription;
  personalCardSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  genderRequired;
  dateOfBirthRequired;
  documentRequired;
  constructor(
    public googleAnalytics4Service: GoogleAnalytics4Service,
    public router: Router,
    public vars: VariablesService,
    private formBuilder: FormBuilder,
    public Service: DataService,
    public flightBookingService: FlightBookingService,
    public approvalsService: ApprovalsService,
    public costBookingState: CostBookingStateService,
    public Auth: AuthServiceMain,
    private scrollToService: ScrollToService,
    public googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.Service.getCookie("shareasaleSSCID");
  }

  ngOnInit() {
    //this.selectedUser = this.userInfoObj;
    this.phonePlaceHolder = "";
    this.travelersArray = this.Service.flightParams.paxes.split(",");
    this.otherAdultsTravelers = this.travelersArray[0] - 1;
    this.adultsExpiryDate = new Array(this.otherAdultsTravelers);
    this.adultFormatExpiryDateText = new Array(this.otherAdultsTravelers);
    this.adultsDOBErrorMsg = new Array(this.otherAdultsTravelers);
    this.adultsSelectedMonth = new Array(this.otherAdultsTravelers);
    this.adultsSelectedDay = new Array(this.otherAdultsTravelers);
    this.adultsSelectedYear = new Array(this.otherAdultsTravelers);
    this.Service.loaderText = $localize`:@@bookingYourFlight:Booking your flight ..`;

    this.errorMsg = "";
    this.Service.errorMsg = "";
    this.Service.flightBookingForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      LastName: ["", Validators.required],
      HolderName: ["", Validators.pattern("^[A-Za-z -]+$")],
      phone: ["", Validators.required],
      password: [""],
      FirstName: ["", Validators.required],
      programNumber: [""],
      flyerProgram: [""],
      mealRequest: [""],
      cvv: ["",
        [Validators.maxLength(4),
          Validators.pattern(/^[0-9]*$/)]
      ],
    });

    this.genderRequired = this.Service.flightPricing.bookingRequirements?.travelerRequirements?.[0]?.genderRequired;
    this.dateOfBirthRequired = this.Service.flightPricing.bookingRequirements?.travelerRequirements?.[0]?.dateOfBirthRequired;
    this.documentRequired = this.Service.flightPricing.bookingRequirements?.travelerRequirements?.[0]?.documentRequired;

    if (this.genderRequired !== false){
      this.Service.flightBookingForm.addControl('gender', this.formBuilder.control('', Validators.required));
    }
    if (this.dateOfBirthRequired !== false) {
      this.Service.flightBookingForm.addControl('dayOfBirth', this.formBuilder.control(null, Validators.required));
      this.Service.flightBookingForm.addControl('monthOfBirth', this.formBuilder.control(null, Validators.required));
      this.Service.flightBookingForm.addControl('yearOfBirth', this.formBuilder.control(null, Validators.required));
    }
    if (this.documentRequired) {
      this.Service.flightBookingForm.addControl('passportIssueCountryCode', this.formBuilder.control('', Validators.required));
      this.Service.flightBookingForm.addControl('passportNumber', this.formBuilder.control('', Validators.required));
      this.Service.flightBookingForm.addControl('passportExpiry', this.formBuilder.control('', Validators.required));
    }
    this.Service.flightBookingForm.addControl('nationality', this.formBuilder.control('', Validators.required));

    this.addMoreTravelers();
    this.Service.flightBookingSubmit = false;
    this.submittingApproval = false;
    this.Service.needRequestApproval = false;
    this.Service.alerts = [];
    this.travelerId = sessionStorage.getItem("travelerIdFlight");
    if ((this.Auth.isOwner() || this.Auth.isAdmin()) && this.travelerId !== this.Auth.userInfo.id) {
      if (
        this.travelerId &&
        this.travelerId !== "" &&
        this.travelerId !== "guest"
      ) {

       this.getTravellerDetails();
      }
    } else {
        this.fillUserDataAsTraveler();
    }

    this.rate = JSON.parse(sessionStorage.getItem("flightData"));

    this.checkRequireApproval();

    this.callSavedCardsMethod();

  }

  checkRequireApproval(){
    this.requireApprovalSubscription = this.flightBookingService.requiresAirApprovalCheck(
        this.Service.flightData.details.transactionId
    ).subscribe((data) => {
      this.data = data;
      if (this.data.errorCode === 0) {
        this.requiresApprovalData = this.data.data;
        if (this.requiresApprovalData) {
          this.Service.needRequestApproval = true;
        }
      }
    });
    this.subscriptions.add(this.requireApprovalSubscription);
  }

  addMoreTravelers(){
    for (let i = 0; i < this.otherAdultsTravelers; i++) {
      this.Service.flightBookingForm.addControl(
          "FirstName" + i,
          new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
          "LastName" + i,
          new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
          "gender" + i,
          new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
          "dayOfBirth" + i,
          new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
          "monthOfBirth" + i,
          new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
          "yearOfBirth" + i,
          new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
          "passportIssueCountryCode" + i,
          new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
          "passportNumber" + i,
          new FormControl("", Validators.required)
      );
      const namePassport = "passportExpiry" + i;
      this.Service.flightBookingForm.addControl(
          i.toString(),
          this.formBuilder.group({
            adultsPassportExpiry: new FormControl(),
          })
      );
      this.Service.flightBookingForm.addControl(
          "flyerProgram" + i,
          new FormControl()
      );
      this.Service.flightBookingForm.addControl(
          "programNumber" + i,
          new FormControl()
      );
      this.Service.flightBookingForm.addControl(
          "mealRequest" + i,
          new FormControl()
      );
    }
    const PaxesArray: any = this.Service.flightParams.paxes.split(",");

    this.PaxesCount = PaxesArray.length + Number(PaxesArray[0]) - 1;
  }

  getTravellerDetails(){
    this.infoSubscription = this.Service.getCompanyUserDetails(this.travelerId).subscribe(
        (suc) => {
          this.data = suc;
          if (this.data.errorCode === 0) {
            this.travelerInfo = this.data.data;
            let travelerInfo = this.data.data;
            this.traveler = {
              travelerPersonalPaymentMethodsDisabled:
              this.data.data.personalPaymentMethodsDisabled,
              travelerName:
                  this.data.data.firstName + " " + this.data.data.lastName,
            };

            if (
                this.Service.flightBookingForm.controls.FirstName.value === ""
            ) {
              this.Service.flightBookingForm
                  .get("FirstName")
                  .setValue(travelerInfo.firstName);
            }
            if (
                this.Service.flightBookingForm.controls.LastName.value === ""
            ) {
              this.Service.flightBookingForm
                  .get("LastName")
                  .setValue(travelerInfo.lastName);
            }
            if (this.Service.flightBookingForm.controls.phone.value === "") {
              this.Service.flightBookingForm
                  .get("phone")
                  .setValue(travelerInfo.phoneNumber);
            }
            if (this.Service.flightBookingForm.controls.email.value === "") {
              this.Service.flightBookingForm
                  .get("email")
                  .setValue(travelerInfo.email);
              this.Service.flightBookingForm.get("email").disable();
            }
         /*   if (
                travelerInfo.frequentFlyerPrograms &&
                travelerInfo.frequentFlyerPrograms.length
            ) {
              this.Service.flightBookingForm
                  .get("flyerProgram")
                  .setValue(travelerInfo.frequentFlyerPrograms[0].code);
              this.Service.flightBookingForm
                  .get("programNumber")
                  .setValue(travelerInfo.frequentFlyerPrograms[0].number);
            }*/
            if (
                travelerInfo.travelDocuments &&
                travelerInfo.travelDocuments.length &&
                this.documentRequired
            ) {
              this.Service.flightBookingForm
                  .get("passportIssueCountryCode")
                  .setValue(travelerInfo.travelDocuments[0].issuingCountryCode);
              this.Service.flightBookingForm
                  .get("passportNumber")
                  .setValue(travelerInfo.travelDocuments[0].number);
              this.Service.flightBookingForm
                  .get("passportExpiry")
                  .setValue(
                      new Date(travelerInfo.travelDocuments[0].expiryDate)
                  );
              this.formatExpiryDateText =
                  moment(travelerInfo.travelDocuments[0].expiryDate).format(
                      "MMM"
                  ) +
                  " " +
                  moment(travelerInfo.travelDocuments[0].expiryDate).format(
                      "DD"
                  ) +
                  ", " +
                  moment(travelerInfo.travelDocuments[0].expiryDate).format(
                      "YYYY"
                  );
              this.expiryDate =
                  moment(travelerInfo.travelDocuments[0].expiryDate).format(
                      "YYYY"
                  ) +
                  "-" +
                  moment(travelerInfo.travelDocuments[0].expiryDate).format(
                      "MM"
                  ) +
                  "-" +
                  moment(travelerInfo.travelDocuments[0].expiryDate).format(
                      "DD"
                  );
            }
            if (travelerInfo.gender &&
                (this.genderRequired !== false)) {
              this.Service.flightBookingForm
                  .get("gender")
                  .setValue(travelerInfo.gender);
            }
            if (travelerInfo.birthDate &&
                (this.dateOfBirthRequired !== false)) {
              this.Service.flightBookingForm
                  .get("monthOfBirth")
                  .setValue(moment(travelerInfo.birthDate).format("MM"));
              this.Service.flightBookingForm
                  .get("dayOfBirth")
                  .setValue(moment(travelerInfo.birthDate).format("DD"));
              this.Service.flightBookingForm
                  .get("yearOfBirth")
                  .setValue(
                      Number(moment(travelerInfo.birthDate).format("YYYY"))
                  );
              this.selectedDay = moment(travelerInfo.birthDate).format("DD");
              this.selectedMonth = moment(travelerInfo.birthDate).format(
                  "MM"
              );
              this.selectedYear = moment(travelerInfo.birthDate).format(
                  "YYYY"
              );
            }

            if (this.data.data.phoneNumberCountryCode) {
              let selected = this.vars.countries.filter(
                  (item) =>
                      item.dial_code === "+" + travelerInfo.phoneNumberCountryCode
              );
              if (selected.length > 0) {
                this.Service.defultCountry.dial_code =
                    "+" + travelerInfo.phoneNumberCountryCode;
              } else {
                selected = this.vars.countries.filter(
                    (item) => item.code === travelerInfo.phoneNumberCountryCode
                );
                this.Service.defultCountry.dial_code = selected[0].dial_code;
              }
              this.Service.defultCountry.code = selected[0].code;
            }
          }
        },
        (err) => {}
    );
    this.subscriptions.add(this.infoSubscription);
  }

  fillUserDataAsTraveler(){
    this.traveler = {
      travelerPersonalPaymentMethodsDisabled:
      this.Auth.userInfo.personalPaymentMethodsDisabled,
      travelerName:
          this.Auth.userInfo.name + " " + this.Auth.userInfo.lastName,
    };

    if (this.Service.flightBookingForm.controls.FirstName.value === "") {
      this.Service.flightBookingForm
          .get("FirstName")
          .setValue(this.Auth.userInfo.name);
    }
    if (this.Service.flightBookingForm.controls.LastName.value === "") {
      this.Service.flightBookingForm
          .get("LastName")
          .setValue(this.Auth.userInfo.lastName);
    }
    if (this.Service.flightBookingForm.controls.phone.value === "") {
      this.Service.flightBookingForm
          .get("phone")
          .setValue(this.Auth.userInfo.phoneNumber);
    }
    if (this.Service.flightBookingForm.controls.email.value === "") {
      this.Service.flightBookingForm
          .get("email")
          .setValue(this.Auth.userInfo.email);
      this.Service.flightBookingForm.get("email").disable();
    }
   /* if (
        this.Auth.userInfo.frequentFlyerPrograms &&
        this.Auth.userInfo.frequentFlyerPrograms.length
    ) {
      this.Service.flightBookingForm
          .get("flyerProgram")
          .setValue(this.Auth.userInfo.frequentFlyerPrograms[0].code);
      this.Service.flightBookingForm
          .get("programNumber")
          .setValue(this.Auth.userInfo.frequentFlyerPrograms[0].number);
    }*/
    if (
        this.Auth.userInfo.travelDocuments &&
        this.Auth.userInfo.travelDocuments.length &&
        this.documentRequired
    ) {
      this.Service.flightBookingForm
          .get("passportIssueCountryCode")
          .setValue(this.Auth.userInfo.travelDocuments[0].issuingCountryCode);
      this.Service.flightBookingForm
          .get("passportNumber")
          .setValue(this.Auth.userInfo.travelDocuments[0].number);
      this.Service.flightBookingForm
          .get("passportExpiry")
          .setValue(new Date(this.Auth.userInfo.travelDocuments[0].expiryDate));
      this.formatExpiryDateText =
          moment(this.Auth.userInfo.travelDocuments[0].expiryDate).format(
              "MMM"
          ) +
          " " +
          moment(this.Auth.userInfo.travelDocuments[0].expiryDate).format(
              "DD"
          ) +
          ", " +
          moment(this.Auth.userInfo.travelDocuments[0].expiryDate).format(
              "YYYY"
          );
      this.expiryDate =
          moment(this.Auth.userInfo.travelDocuments[0].expiryDate).format(
              "YYYY"
          ) +
          "-" +
          moment(this.Auth.userInfo.travelDocuments[0].expiryDate).format(
              "MM"
          ) +
          "-" +
          moment(this.Auth.userInfo.travelDocuments[0].expiryDate).format("DD");
    }
    if (this.Auth.userInfo.gender) {
      this.Service.flightBookingForm
          .get("gender")
          .setValue(this.Auth.userInfo.gender);
    }
    if (this.Auth.userInfo.birthDate) {
      this.Service.flightBookingForm
          .get("monthOfBirth")
          .setValue(moment(this.Auth.userInfo.birthDate).format("MM"));
      this.Service.flightBookingForm
          .get("dayOfBirth")
          .setValue(moment(this.Auth.userInfo.birthDate).format("DD"));
      this.Service.flightBookingForm
          .get("yearOfBirth")
          .setValue(
              Number(moment(this.Auth.userInfo.birthDate).format("YYYY"))
          );
      this.selectedDay = moment(this.Auth.userInfo.birthDate).format("DD");
      this.selectedMonth = moment(this.Auth.userInfo.birthDate).format("MM");
      this.selectedYear = moment(this.Auth.userInfo.birthDate).format("YYYY");
    } else {
      this.Service.flightBookingForm.get("monthOfBirth").setValue(null);
      this.Service.flightBookingForm.get("dayOfBirth").setValue(null);
      this.Service.flightBookingForm.get("yearOfBirth").setValue(null);
    }

    if (this.Auth.userInfo.phoneNumberCountryCode) {
      let selected = this.vars.countries.filter(
          (item) =>
              item.dial_code === "+" + this.Auth.userInfo.phoneNumberCountryCode
      );
      if (selected.length > 0) {
        this.Service.defultCountry.dial_code =
            "+" + this.Auth.userInfo.phoneNumberCountryCode;
      } else {
        selected = this.vars.countries.filter(
            (item) => item.code === this.Auth.userInfo.phoneNumberCountryCode
        );
        this.Service.defultCountry.dial_code = selected[0].dial_code;
      }
      this.Service.defultCountry.code = selected[0].code;
    }
  }

  callSavedCardsMethod(){
    this.cardsSubscription = this.Service.savedCard(this.travelerId).subscribe((success) => {
      this.savedCard = success;

      if (this.savedCard.errorCode === 0) {
        this.payTypeMethod = PayType.SavedCards;

        if (
            this.savedCard.data &&
            this.savedCard.data.cards &&
            this.savedCard.data.cards.length > 0
        ) {
          this.payTypeMethod = PayType.SavedCards;

          const amazonCardsOnly = this.savedCard?.data?.cards
              ?.filter((element) => {
                return element.paymentGatewayProvider === 'AMAZON_PAYMENT_SERVICE';
              }) || [];

          // Check if array length has changed
          if (amazonCardsOnly.length !== this.previousSavedCardListLength) {
            this.selectedCard = 'selectPayment';
            // Length has changed, trigger next
            this.savedCardList.next(amazonCardsOnly);
            // Update the previous length
            this.previousSavedCardListLength = amazonCardsOnly.length;
          }
        } else {
          this.selectedCard = 'noPayment';
          this.payTypeMethod = PayType.Amazon;
        }
      } else {
        this.payTypeMethod = PayType.Amazon;

      }

    });

    this.subscriptions.add(this.cardsSubscription);
  }

  formatExpiryDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.expiryDate =
      moment(event.value).format("YYYY") +
      "-" +
      moment(event.value).format("MM") +
      "-" +
      moment(event.value).format("DD");
    this.formatExpiryDateText =
      moment(event.value).format("MMM") +
      " " +
      moment(event.value).format("DD") +
      ", " +
      moment(event.value).format("YYYY");
  }

  adultsFormatExpiryDate(event: MatDatepickerInputEvent<Date>, index) {
    this.adultsExpiryDate[index] =
      moment(event.value).format("YYYY") +
      "-" +
      moment(event.value).format("MM") +
      "-" +
      moment(event.value).format("DD");
    this.adultFormatExpiryDateText[index] =
      moment(event.value).format("MMM") +
      " " +
      moment(event.value).format("DD") +
      ", " +
      moment(event.value).format("YYYY");
  }
  changeDay() {
    this.bodErrorMsg = "";
    this.selectedDay = this.Service.flightBookingForm.controls.dayOfBirth.value;
    this.checkDateOfBirth();
  }
  changeMonth() {
    this.bodErrorMsg = "";
    this.selectedMonth =
      this.Service.flightBookingForm.controls.monthOfBirth.value;
    this.checkDateOfBirth();
  }
  changeYear() {
    this.bodErrorMsg = "";
    this.selectedYear =
      this.Service.flightBookingForm.controls.yearOfBirth.value;
    this.checkDateOfBirth();
  }
  checkDateOfBirth() {
    if (this.selectedDay && this.selectedMonth && this.selectedYear) {
      const month30days = [4, 6, 9, 11];
      const day = Number("" + this.selectedDay);
      const month = Number("" + this.selectedMonth);
      const year = Number("" + this.selectedYear);
      if (month === 2 && day > 29) {
        this.bodErrorMsg = "select a correct date of birth";
      } else if (month30days.includes(month) && day > 30) {
        this.bodErrorMsg = "select a correct date of birth";
      } else if (
        !((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) &&
        month === 2 &&
        day > 28
      ) {
        this.bodErrorMsg = "select a correct date of birth";
      } else {
        const today = new Date();
        let age = today.getFullYear() - year;
        const m = today.getMonth() + 1 - month;
        if (m < 0 || (m === 0 && today.getDate() < day)) {
          age--;
        }
        if (age < 18) {
          this.bodErrorMsg = "Primary traveler must be 18 or older.";
        }
      }
    }
  }

  adultsChangeDay(index) {
    this.adultsDOBErrorMsg[index] = "";
    this.adultsSelectedDay[index] =
      this.Service.flightBookingForm.controls["dayOfBirth" + index].value;
    this.adultsCheckDateOfBirth(index);
  }
  adultsChangeMonth(index) {
    this.adultsDOBErrorMsg[index] = "";
    this.adultsSelectedMonth[index] =
      this.Service.flightBookingForm.controls["monthOfBirth" + index].value;
    this.adultsCheckDateOfBirth(index);
  }
  adultsChangeYear(index) {
    this.adultsDOBErrorMsg[index] = "";
    this.adultsSelectedYear[index] =
      this.Service.flightBookingForm.controls["yearOfBirth" + index].value;
    this.adultsCheckDateOfBirth(index);
  }
  adultsCheckDateOfBirth(index) {
    if (
      this.adultsSelectedDay[index] &&
      this.adultsSelectedMonth[index] &&
      this.adultsSelectedYear[index]
    ) {
      const month30days = [4, 6, 9, 11];
      const day = Number("" + this.adultsSelectedDay[index]);
      const month = Number("" + this.adultsSelectedMonth[index]);
      const year = Number("" + this.adultsSelectedYear[index]);
      if (month === 2 && day > 29) {
        this.adultsDOBErrorMsg[index] = "select a correct date of birth";
      } else if (month30days.includes(month) && day > 30) {
        this.adultsDOBErrorMsg[index] = "select a correct date of birth";
      } else if (
        !((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) &&
        month === 2 &&
        day > 28
      ) {
        this.adultsDOBErrorMsg[index] = "select a correct date of birth";
      } else {
        const today = new Date();
        let age = today.getFullYear() - year;
        const m = today.getMonth() + 1 - month;
        if (m < 0 || (m === 0 && today.getDate() < day)) {
          age--;
        }
        if (age < 18) {
          this.adultsDOBErrorMsg[index] =
            "Primary traveler must be 18 or older.";
        }
      }
    }
  }

  triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: "bookingForm",
      duration: 650,
      easing: "easeOutElastic",
      offset: 20,
    };

    this.scrollToService.scrollTo(config);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.Service.flightBookingForm.controls;
  }

  isValid(fieldName) {
    return this.Service.flightBookingForm.controls[fieldName].valid;
  }

  onSubmit() {
    if (this.Auth.isManager() || this.Auth.isAdmin() || this.Auth.isOwner()) {
      this.getApprovalsList = true;
    }
    if (this.selectedCard === 'selectPayment' || this.selectedCard === 'noPayment' || this.selectedCard === 'new') {
      this.noSelectedCard = true;
    }

    this.inValidCVV = false;
    if (this.Service.flightBookingForm.controls.cvv.value === "" && this.personalCardSelected.value) {
      this.inValidCVV = true;
      this.Service.scrollTop();
    } else {
      this.inValidCVV = false;
    }

    this.errorMsg = "";
    this.submitted = true;
    this.Service.alerts = [];
    const isValidCostReporting = this.costBookingState
      .setIsSubmittedAndCheckValidation(this.submitted)
      .valueOf();
    console.log(isValidCostReporting);

    if (this.Service.flightBookingForm.invalid || this.noSelectedCard || this.inValidCVV || !isValidCostReporting) {
      this.triggerScrollTo();
      this.Service.scrollTop();
      return;
    }

    this.prepareBookingRequestAndBook();
  }
  prepareBookingRequestAndBook(){
    let header = "";
    if (this.Service.sscid) {
      header = this.Service.sscid;
    }
    let finalCompanyObj: FlightBookingRequest = {
      passengers: [{ contact: { phones: [{}] } }],
      loyaltyPrograms: [{}],
      paymentData: {}
    } as FlightBookingRequest;
    finalCompanyObj.airItineraryCode = this.Service.flightData.details.transactionId;
    finalCompanyObj.passengers[0].travelerId = this.travelerId;
    finalCompanyObj.passengers[0].firstName = this.Service.flightBookingForm.controls.FirstName.value;
    finalCompanyObj.passengers[0].lastName = this.Service.flightBookingForm.controls.LastName.value;
    finalCompanyObj.passengers[0].contact.emailAddress = this.Service.flightBookingForm.controls.email.value;
    finalCompanyObj.passengers[0].contact.phones[0].countryCallingCode = this.Service.defultCountry.dial_code.replace("+", "");
    finalCompanyObj.passengers[0].contact.phones[0].number = this.Service.flightBookingForm.controls.phone.value;
    finalCompanyObj.passengers[0].type = 'ADULT';
    if(this.genderRequired !== false){
      finalCompanyObj.passengers[0].gender = this.Service.flightBookingForm.controls.gender.value;
    }
    if(this.dateOfBirthRequired !== false) {
      finalCompanyObj.passengers[0].birthDate =
          this.selectedYear + "-" + this.selectedMonth + "-" + this.selectedDay;
    }
    if(this.documentRequired) {
      finalCompanyObj.passengers[0].passportNumber = this.Service.flightBookingForm.controls.passportNumber.value;
      finalCompanyObj.passengers[0].passportExpiryDate = this.expiryDate;
      finalCompanyObj.passengers[0].passportIssueCountryCode = this.Service.flightBookingForm.controls.passportIssueCountryCode.value;
    }
    finalCompanyObj.passengers[0].nationality = this.Service.flightBookingForm.controls.nationality.value;
    finalCompanyObj.loyaltyPrograms[0].programOwner = this.Service.flightBookingForm.controls.flyerProgram.value;
    finalCompanyObj.loyaltyPrograms[0].id = this.Service.flightBookingForm.controls.programNumber.value;
    if (this.costBookingState?.selectedCostCenters && this.costBookingState?.selectedCostCenters.length) {
      finalCompanyObj.costCenterId = this.costBookingState?.selectedCostCenters[0]?.id;
    }
    if (this.costBookingState?.selectedLabels && this.costBookingState?.selectedLabels.length) {
      finalCompanyObj.labelsIds = this.costBookingState.selectedLabels?.map(
          (att) => att.id
      );
    }
    finalCompanyObj.sellingPrice = this.Service.flightPricing.flightOffers[0].sellingPriceConverted.total;
    finalCompanyObj.sellingPriceCurrency = this.Service.flightPricing.flightOffers[0].sellingPriceConverted.currency;
    finalCompanyObj.searchRequest =  JSON.parse(localStorage.getItem('flightSearchRequest'));

    if (this.payTypeMethod === PayType.SavedCards) {
      this.CardSelected = this.SelectedsavedCard;
      if (!this.requiresApprovalData) {
        this.Service.flightBookingSubmit = true;
      } else {
        this.submittingApproval = true;
      }

      finalCompanyObj.paymentData.paymentGatewayProvider = "AMAZON_PAYMENT_SERVICE";
      finalCompanyObj.paymentData.apsToken = this.selectedCard;
      if(this.personalCardSelected.value){
        finalCompanyObj.paymentData.cvv = this.Service.flightBookingForm.controls.cvv.value;
      }
      console.log(finalCompanyObj);


      /*    for (let i = 0; i < this.otherAdultsTravelers; i++) {
      const adultTraveler = {} as any;
      adultTraveler.firstName =
        this.Service.flightBookingForm.controls["FirstName" + i].value;
      adultTraveler.lastName =
        this.Service.flightBookingForm.controls["LastName" + i].value;
      adultTraveler.gender =
        this.Service.flightBookingForm.controls["gender" + i].value;
      adultTraveler.birthDate =
        this.adultsSelectedYear[i] +
        "-" +
        this.adultsSelectedMonth[i] +
        "-" +
        this.adultsSelectedDay[i];
      adultTraveler.passportNumber =
        this.Service.flightBookingForm.controls["passportNumber" + i].value;
      (adultTraveler.passportExpiryDate = this.adultsExpiryDate[i]),
        (adultTraveler.passportIssueCountryCode =
          this.Service.flightBookingForm.controls[
            "passportIssueCountryCode" + i
          ].value);
      adultTraveler.frequentFlyerProgramCode =
        this.Service.flightBookingForm.controls["flyerProgram" + i].value;
      adultTraveler.frequentFlyerNumber =
        this.Service.flightBookingForm.controls["programNumber" + i].value;
      adultTraveler.mealPlan =
        this.Service.flightBookingForm.controls["mealRequest" + i].value;
      dataObj.adults.push(adultTraveler);
    }*/
    }
    this.bookNow(finalCompanyObj, header);
  }
  bookNow(finalCompanyObj, header){
    this.bookSubscription = this.flightBookingService.bookingFormFlight(finalCompanyObj, header).subscribe(
        (data) => {
          this.data = data;
          this.errorMsg = this.data.message;

          if (this.data.errorCode === 0) {
            this.googleAnalyticsService.eventEmitter(
                "FlightStats",
                "Booking",
                "Successful",
                1
            );

            this.Service.errorMsg = "";

            if (this.requiresApprovalData) {
              this.submittedApproval = true;
              this.router.navigate([
                "/user/flights/requests/" +
                this.data.data.id,
              ]);
              this.submittingApproval = false;
              if (this.getApprovalsList) {
                this.updateApprovalsBadge();
              }
              return;
            }
            //this.addGoogleEvents();

            if (
                this.Service.RemoteConfg &&
                this.Service.RemoteConfg.enable_share_a_sale &&
                this.Service.RemoteConfg.enable_share_a_sale._value === "true" &&
                this.Service.sscid
            ) {
              this.Service.loadImg(
                  this.data.data.hrsReferenceNumber,
                  this.Service.sscid
              );
            }
            sessionStorage.setItem(
                "flightVoucher",
                JSON.stringify({
                  details: this.data,
                })
            );


            this.router.navigate(
                [
                  "/flights/availability/voucher",
                ],
                {
                  queryParams: {
                    ref: this.data.data.hrsReferenceNumber,
                    email: this.data.data.email,
                    lastname: this.data.data.bookingResult.data.travelers[0].name.lastName
                  },
                }
            );


          } else if (this.data.errorCode === 20) {
            this.router.navigateByUrl(this.previousUrl);

            this.Service.messagesOverRoute(this.errorMsg, "warning");
            this.Service.flightBookingSubmit = false;
            this.submittingApproval = false;
          } else if (this.data.errorCode === 54) {
            this.Service.setModal(
                '<i class="icon-warning"></i> warning',
                "completeDuplicateBooking",
                this.data.message
            );
            this.Service.flightBookingSubmit = false;
            this.submittingApproval = false;
          }  else if (this.data.errorCode >= 500 && this.data.errorCode <= 599){
            if (this.data.errorCode === 503){
              window.location.href = this.data.data;
            } else {
              this.Service.errorMsg = $localize`:@@errorPayment:Error while processing the payment`;
              this.Service.flightBookingSubmit = false;
              this.submittingApproval = false;
            }
          } else {
            this.Service.errorMsg = this.errorMsg;
            this.googleAnalyticsService.eventEmitter(
                "FlightStats",
                "Booking",
                "Failed",
                1
            );
            this.Service.flightBookingSubmit = false;
            this.submittingApproval = false;
          }
        },
        (error) => {
          this.Service.errorMsg = this.errorMsg;
          this.Service.flightBookingSubmit = false;
          this.submittingApproval = false;
        }
    );
    this.subscriptions.add(this.bookSubscription);
  }
  addGoogleEvents(){
    // GA4
    let tripType = "One-way";
    if (this.data.data.bookRequest.searchRequest.roundTrip) {
      tripType = "Round-trip";
    }

    let name =
        this.data.data.airItinerary.originDestinationOptionsGroups[0]
            .summary.departureAirport.city +
        " - " +
        this.data.data.airItinerary.originDestinationOptionsGroups[0]
            .summary.arrivalAirport.city;
    let location = "";
    if (
        this.data.data.airItinerary.originDestinationOptionsGroups[0]
            .summary.arrivalAirport.city
    ) {
      location =
          this.data.data.airItinerary.originDestinationOptionsGroups[0]
              .summary.arrivalAirport.city;
    }
    if (
        this.data.data.airItinerary.originDestinationOptionsGroups[0]
            .summary.arrivalAirport.state
    ) {
      location +=
          ", " +
          this.data.data.airItinerary.originDestinationOptionsGroups[0]
              .summary.arrivalAirport.state;
    }
    if (
        this.data.data.airItinerary.originDestinationOptionsGroups[0]
            .summary.arrivalAirport.country
    ) {
      location +=
          ", " +
          this.data.data.airItinerary.originDestinationOptionsGroups[0]
              .summary.arrivalAirport.country;
    }
    this.googleAnalytics4Service.addTransaction(
        this.data.data.airItinerary.fareInfo.sellingTotalFares.total
            .currencyCode,
        this.data.data.hrsReferenceNumber,
        this.data.data.airItinerary.fareInfo.sellingTotalFares.total
            .amount,
        this.data.data.companyId,
        name,
        location,
        "Flight",
        "Self"
    );
    // end of GA4


  }
  updateApprovalsBadge(){
    this.approvalsListSubscription = this.approvalsService.getFlightListApproversApprovals(
        "PENDING_APPROVAL"
    ).subscribe(
        (dataApprove) => {
          this.data = dataApprove;
          if (this.data.errorCode === 0) {
            const pendingArray = this.data.data.filter(
                (item) =>
                    item.status.toLowerCase() === "pending_approval"
            );
            localStorage.setItem(
                "workspaceApprovalsBadgeFlight",
                pendingArray?.length || 0
            );
          } else {
            this.Service.messages(this.data.message, "error");
          }
          // Unsubscribe after data is received
          this.approvalsListSubscription?.unsubscribe();
        },
        (error) => {
          // Unsubscribe on error as well
          this.approvalsListSubscription?.unsubscribe();
          this.Service.messages(environment.messages.error, "error");
        }
    );
  }


  private resetStatus(): void {
    this.showError = false;
    this.showSuccess = false;
    this.showCancel = false;
  }

  onItemChange(value) {
    this.paymentType = value.target.defaultValue;
    console.log(value);
  }

  savedCardChange() {
    if (this.selectedCard !== 'selectPayment' && this.selectedCard !== 'noPayment' && this.selectedCard !== 'new') {
      this.noSelectedCard = false;
    }
    console.log('here chnage');
    if (this.selectedCard !== "new") {
      this.payTypeMethod = PayType.SavedCards;
      this.isSelectedCardPersonal(this.selectedCard);
    } else {
      console.log('here in open url');
      this.personalCardSelected.next(false);
      const url = '/user/payment';
      console.log('here in open url not safari');
      window.open(url, '_blank');

    }
  }

  isSelectedCardPersonal(token: string){
    const selectedCardByUser = this.savedCardList.value.find(card => card.token === token);

    if (selectedCardByUser?.companyCard) {
      this.personalCardSelected.next(false);
    } else {
      this.personalCardSelected.next(true);
    }
  }

  getFrequentFlyerPrograms() {
    if (!this.programsList) {
      this.Service.getAirLoyaltyPrograms().subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.programsList = this.data.data;
          } else {
            this.Service.messages(this.data.message, "error");
          }
        },
        (error) => {
          this.Service.messages(environment.messages.error, "error");
        }
      );
    }
  }




  SendBookingFormFields() {
    if (this.Service.mobileView) {
      sessionStorage.setItem(
        "flightBookingFormFields",
        JSON.stringify(this.Service.flightBookingForm.value)
      );
    }
  }


  passportCountryChange(event) {
    let keepSearching = true;
    let travelerPassportsList;
    if(this.travelerId === 'guest'){
      travelerPassportsList = [];
    } else if(this.travelerId !== this.Auth.userInfo.id){
      // here considering the traveler is NOT the user account
      travelerPassportsList = this.travelerInfo.travelDocuments;
    } else {
       // here considering the traveler is the user account
       travelerPassportsList = this.Auth.userInfo.travelDocuments;
     }

    travelerPassportsList.forEach((element) => {
      if (keepSearching) {
        if (element.issuingCountryCode === event.target.value) {
          keepSearching = false;
          this.Service.flightBookingForm
            .get("passportNumber")
            .setValue(element.number);
          this.Service.flightBookingForm
            .get("passportExpiry")
            .setValue(new Date(element.expiryDate));
          this.formatExpiryDateText =
            moment(element.expiryDate).format("MMM") +
            " " +
            moment(element.expiryDate).format("DD") +
            ", " +
            moment(element.expiryDate).format("YYYY");
          this.expiryDate =
            moment(element.expiryDate).format("YYYY") +
            "-" +
            moment(element.expiryDate).format("MM") +
            "-" +
            moment(element.expiryDate).format("DD");
        } else {
          this.Service.flightBookingForm.get("passportNumber").setValue(null);
          this.Service.flightBookingForm.get("passportExpiry").setValue(null);
          this.formatExpiryDateText = null;
          this.expiryDate = null;
        }
      }
    });
  }

  programChange(event) {
    let keepSearching = true;
    let travelerProgramsList;
    if(this.travelerId === 'guest'){
      travelerProgramsList = [];
    } else if (this.travelerId !== this.Auth.userInfo.id){
      // here considering the traveler is NOT the user account
      travelerProgramsList = this.travelerInfo.frequentFlyerPrograms;
    } else {
      // here considering the traveler is the user account
      travelerProgramsList = this.Auth.userInfo.frequentFlyerPrograms;
    }
    console.log(this.travelerInfo);
    console.log(travelerProgramsList);
    travelerProgramsList.forEach((element) => {
      if (keepSearching) {
        if (element.code === event.target.value) {
          keepSearching = false;
          this.Service.flightBookingForm
            .get("programNumber")
            .setValue(element.number);
        } else {
          this.Service.flightBookingForm.get("programNumber").setValue(null);
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
