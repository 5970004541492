import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { AuthServiceMain } from '../../auth/auth.service';
import * as moment from 'moment';
import { DatePipe, Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrls: ['./cancel-booking.component.scss']
})
export class CancelBookingComponent implements OnInit {
  checkin;
  checkout;
  data;
  booking;
  refFromURL = 'ref';
  refFromURLValue;
  identifierFromURL = 'identifier';
  identifierFromURLValue;
  retrieveObj;
  bookingType;
  cancelled = false;
  nights;
  cancellation;
  allowCancellation = false;
  dataCancel;
  errorMsg;
  showData = false;
  backUrl;
  dataCancelBooking;
  loading = false;
  submitted = false;
  prepareCancelObj;
  cancellationReason = '';
  type = 'LAST_NAME';
  submitting = false;
  upcomingBookingObj = {
    bookingStatus: 'CONFIRMED',
    includeUpcoming: true,
    includePast: false
  };
  dataUpcoming;
  companyInfoObj = JSON.parse(localStorage.getItem('companyInfo'));
  constructor(public datePipe: DatePipe, private http: HttpClient, public router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute, private logedIn: AuthServiceMain, public Service: DataService,
    private location: Location, private titleService: Title) {
    this.titleService.setTitle('Cancel My Booking | SafarHub');



  }

  ngOnInit() {
    this.Service.alerts = [];
    this.route.queryParams.subscribe(params => {
      this.refFromURLValue = params[this.refFromURL];
      this.identifierFromURLValue = params[this.identifierFromURL];
    });
    if (this.refFromURLValue != null && this.identifierFromURLValue != null) {

      if (this.identifierFromURLValue.indexOf('@') > -1) {
        this.retrieveObj = {
          customerIdentifier: {
            type: 'EMAIL',
            value: this.identifierFromURLValue
          },
          referenceNumber: this.refFromURLValue
        };
      } else {
        this.retrieveObj = {
          customerIdentifier: {
            type: 'LAST_NAME',
            value: this.identifierFromURLValue
          },
          referenceNumber: this.refFromURLValue
        };
      }
      this.getBookingDetails(this.retrieveObj);
    }
  }
  back() {
    this.location.back();
  }
  selectChangeHandler(event: any) {
    this.cancellationReason = event.target.value;
  }
  cancel() {
    this.submitting = true;
    this.submitted = true;

    if (this.identifierFromURLValue.indexOf('@') > -1) {
      this.prepareCancelObj = {
        customerIdentifier: {
          type: 'EMAIL',
          value: this.identifierFromURLValue
        },
        referenceNumber: this.refFromURLValue,
        cancellationReason: this.cancellationReason
      };
    } else {
      this.prepareCancelObj = {
        customerIdentifier: {
          type: 'LAST_NAME',
          value: this.identifierFromURLValue
        },
        referenceNumber: this.refFromURLValue,
        cancellationReason: this.cancellationReason
      };
    }
    this.Service.alerts = [];
    this.Service.cancel(this.prepareCancelObj).subscribe(
      dataCancelBooking => {
        this.dataCancelBooking = dataCancelBooking;
        if (this.dataCancelBooking.errorCode === 0) {
          /*  if(this.logedIn.isLogedIn){
              this.Service.listBookings(this.upcomingBookingObj.bookingStatus, this.upcomingBookingObj.includeUpcoming,
                this.upcomingBookingObj.includePast).subscribe(
                data => {
                  this.dataUpcoming = data;
                  if (this.dataUpcoming.errorCode === 0) {
                    this.Service.tripsBadge = this.dataUpcoming.data.length;
                  } else {
                    this.Service.messages(this.dataUpcoming.message, 'error');
                  }
                },
                error => {
                  this.Service.messages(this.dataUpcoming.message, 'error');
                }
              );
    
            }*/
          if (this.Service.isCompany()) {
            this.router.navigate(['/hotel/booking/details'],
              { queryParams: { ref: this.refFromURLValue, identifier: this.identifierFromURLValue } });
          } else {
            this.router.navigate(['/bookings/manage'],
              { queryParams: { ref: this.refFromURLValue, identifier: this.identifierFromURLValue } });
          }
          this.errorMsg = '';
          this.submitting = false;
        } else {
          this.Service.messages(this.dataCancelBooking.message, 'error');
          this.allowCancellation = false;
          this.submitting = false;
          this.Service.scrollTop();
        }

      },
      error => {
        this.submitting = false;
        this.Service.messages(environment.messages.error, 'error');
        this.Service.scrollTop();
      }


    );
  }
  /* formatDailyDate(date) {
     return moment(date).format('ddd') + ' ' + moment(date).format('MMM')
       + ' ' + moment(date).format('DD') + ', ' + moment(date).format('YYYY');
   }*/

  getBookingDetails(retrieveObj) {
    this.Service.retrieve(retrieveObj).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {

          this.booking = this.data.data;
          this.checkin = this.datePipe.transform(this.booking.checkinDate, 'EE MMM dd');
          this.checkout = this.datePipe.transform(this.booking.checkoutDate, 'EE MMM dd');
          let checkin = moment(this.booking.checkinDate).format('ddd') + ' ' + moment(this.booking.checkinDate).format('MMM')
            + ' ' + moment(this.booking.checkinDate).format('DD');
          let checkout = moment(this.booking.checkoutDate).format('ddd') + ' ' + moment(this.booking.checkoutDate).format('MMM')
            + ' ' + moment(this.booking.checkoutDate).format('DD');
          this.nights = Math.round(moment(checkout, 'ddd MMM D').diff(moment(checkin, 'ddd MMM D'), 'days', true));





          if (this.booking.bookingStatus === 'CANCELLED') {
            this.bookingType = 'cancelled';
          } else {
            if (this.booking.past) {
              this.bookingType = 'completed';
            } else {
              this.bookingType = 'confirmed';
            }
          }
          this.Service.checkCancellationFees(retrieveObj).subscribe(

            dataCancel => {
              this.dataCancel = dataCancel;
              if (this.dataCancel.errorCode === 0) {
                this.showData = true;
                this.cancellation = this.dataCancel.data;
                this.errorMsg = '';
                // this.restApi.messages('Cancellaction fee', 'success');
                this.allowCancellation = true;
              } else {
                this.allowCancellation = false;
              }

            },
            error => {

            }


          );

        } else {


        }

      },
      error => {

      }
    );
  }

}
