import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../auth/auth.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-company-login',
  templateUrl: './company-login.component.html',
  styleUrls: ['./company-login.component.scss']
})
export class CompanyLoginComponent implements OnInit {

  constructor(private titleService: Title,  public dataService: DataService, public router: Router, public auth: AuthServiceMain) {
    this.titleService.setTitle('Sign in to your workspace | SafarHub');
    this.dataService.componentMethodCalled$.subscribe(
      () => {
        if(this.auth.isLogedIn && this.auth.companyInfo) {
          this.router.navigate(['/']);
        }

      }
    );
  }


  ngOnInit(): void {
    this.dataService.signInErrorMsg = false;
    if(this.auth.isLogedIn && this.auth.companyInfo) {
      this.router.navigate(['/']);
    }
  }
}
