import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../services/data.service';
import {AuthServiceMain} from '../../../auth/auth.service';
import {GoogleAnalyticsService} from '../../../google-analytics.service';
import {Router} from '@angular/router';
import 'firebase/remote-config';
import { browserRefresh } from '../../../app.component';
@Component({
  selector: 'app-flight-booking',
  templateUrl: './flight-booking.component.html',
  styleUrls: ['./flight-booking.component.scss']
})
export class FlightBookingComponent implements OnInit {
  public browserRefresh: boolean;
  userInfo;
  data;
  params;
  diffInDays;
  constructor(public Service: DataService,
              public Auth: AuthServiceMain,
              public googleAnalyticsService: GoogleAnalyticsService,
              public router: Router) {

   /* this.Service.componentMethodCalled$.subscribe(
      () => {

        if (this.Service.urlWithoutParams === '/flights/availability/booking' ||
          (this.Auth.isLogedIn && this.Auth.companyInfo && this.Service.urlWithoutParams === '/flights/availability/booking')) {
          this.Service.flightRatesDuration('bookingFormExpiry');
        }



      }
    );*/

  }

  ngOnInit() {
    this.Service.flightRatesExpiry = false;
    this.browserRefresh = browserRefresh;
    this.Service.defultCountry = {};
    this.Service.defultCountry.dial_code = '+1';
    this.Service.defultCountry.code = 'US';
    this.Service.dropDowmBookingForm = false;
    this.Service.flightBookingPage = true;
    if (!sessionStorage.getItem('flightData')) {
      this.router.navigate(['/']);
    }

    this.Service.flightData = JSON.parse(sessionStorage.getItem('flightData'));
    this.Service.flightParams = JSON.parse(sessionStorage.getItem('flightParams'));
    this.Service.flightPricing = JSON.parse(sessionStorage.getItem('flightPricingData'));

    this.userInfo = this.Auth.userInfo;

    this.Service.flightRatesDuration('bookingFormExpiry');
    if (this.browserRefresh) {
   //   this.Service.validateFlight(true, this.Service.flightData.details.userData.travelerId);
    } else {
      sessionStorage.removeItem('flightBookingFormFields');
    }

    this.userInfo = this.Auth.userInfo;

  }
}
