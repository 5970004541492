<footer>
  <div class="container primaray-text extra-padding-top border-top" >

    <div class="column-1">
      <ul class="list" [ngClass]="{'pr-0': Service.mobileView}">

        <li class="item mb-4 pb-2 font-weight-bold font-18">
          SafarHub
        </li>
        <li class="item font-16" [ngClass]="{'mb-5': Service.mobileView}">
          <span i18n="@@footerTitleMain">A cloud platform with modern technology that simplifies and automates business travel in minutes.</span>
        </li>
      </ul>
     <!-- <div class="logo">
        <a  routerLink="/" class="logo_link">
          <img width="145" height="35" loading="lazy"  [src]="this.Service.desktopView ? 'assets/img/logo.svg'  : 'assets/img/logo-mobile.svg'" class="logo-image" alt="SafarHub Logo">
        </a>
      </div>-->
  <!--    <div class="social_networks_wrap d-none d-lg-block">
        <div class="title bolder primaray-text">
          Follow Us
        </div>
        <ul class="social_networks">
          <li class="item">
            <a target="_blank" href="https://www.facebook.com/triphopapp">
              <i class="icon-facebook"></i>
            </a>
          </li>
          <li class="item">
            <a target="_blank" href="https://www.instagram.com/triphoptravel/">
              <i class="icon-instagram"></i>
            </a>
          </li>
          <li  class="item">
            <a target="_blank" href="https://www.twitter.com/triphopapp"><i class="icon-twitter"></i></a>
          </li>
        </ul>
      </div>-->
    </div>

    <div class="column-2" [ngClass]="{'pl-lg-5': Service.isSiteLtr(), 'pr-lg-5': Service.isSiteRtl()}">
      <ul class="list" [ngClass]="{'pr-0': Service.mobileView}">

        <li class="item mb-4 font-weight-bold font-18" i18n="@@footerTitle1">
         About
        </li>
        <li class="item pb-lg-3">
          <a class="text-decoration-none bold-500 font-16 text-capitalize" href="https://safarhub.com/about-us/" target="_blank" i18n="@@aboutUs">About us</a>
        </li>
        <li class="item pb-lg-3" [ngClass]="{'mb-5': Service.mobileView}">
          <a class="text-decoration-none bold-500 font-16" href="https://safarhub.com/why-safarhub/" target="_blank" i18n="@@whySafarHub">Why SafarHub</a>
        </li>
        <li class="item mb-4 font-weight-bold font-18" i18n="@@footerTitle2">
         More Info
        </li>
        <li class="item pb-lg-3">
          <a class="text-decoration-none bold-500 font-16" href="https://safarhub.com/pricing/" target="_blank" i18n="@@pricing">Pricing</a>
        </li>
        <li class="item pb-lg-3" [ngClass]="{'mb-5': Service.mobileView}">
          <a class="text-decoration-none bold-500 font-16" href="https://safarhub.com/faq/" target="_blank" i18n="@@footerFAQ">FAQ</a>
        </li>

        <li class="item mb-4 font-weight-bold font-18" i18n="@@footerTitle3">
          Terms
        </li>
        <li class="item pb-lg-3">
          <a class="text-decoration-none bold-500 font-16" [href]="Service.isLocaleArabic ? env.termsUrlAR : env.termsUrl" target="_blank" i18n="@@termsAndConditionsCapital">Terms and Conditions</a>
        </li>
        <li class="item pb-lg-3" [ngClass]="{'mb-5': Service.mobileView}">
          <a class="text-decoration-none bold-500 font-16" [href]="Service.isLocaleArabic ? env.privacyUrlAR : env.privacyUrl" target="_blank" i18n="@@privacy">Privacy policy</a>
        </li>

        <li class="item mb-4 font-weight-bold font-18" i18n="@@footerTitle4">
          Contact
        </li>
        <li class="item pb-lg-3">
          <a class="text-decoration-none bold-500 font-16 text-capitalize" href="https://safarhub.com/contact-us/" target="_blank" i18n="@@contactUs">Contact us</a>
        </li>

        <li class="item pb-lg-3">
          <a class="text-decoration-none bold-500 font-16" href="https://safarhub.com/demo-request/" target="_blank" target="_blank" i18n="@@landingRequestBtn">Request Demo</a>
        </li>



      </ul>
    </div>







  </div>


  <div class="container mb-0">
    <div class="bottom">
      <div class="copyrights py-4">
        <p dir="ltr"><ng-container *ngIf="Service.isSiteLtr()" i18n="@@copyRightCapital">Copyright</ng-container><ng-container *ngIf="Service.isSiteLtr()">&copy; {{cYear}}</ng-container> SafarHub <ng-container *ngIf="Service.isSiteRtl()">{{cYear}}</ng-container> | <ng-container *ngIf="Service.isSiteRtl()">&copy; </ng-container> <ng-container i18n="@@copyRight">All rights reserved</ng-container></p>
      </div>
    </div>

  </div>

</footer>
