import {Component, OnInit} from '@angular/core';

import {DataService} from '../../services/data.service';
import {StripeService, Elements, Element as StripeElement} from 'ngx-stripe';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {AuthServiceMain} from "../../auth/auth.service";


@Component({
  selector: 'app-new-payment-card',
  templateUrl: './new-payment-card.component.html',
  styleUrls: ['./new-payment-card.component.scss']
})
export class NewPaymentCardComponent implements OnInit {
  card: StripeElement;
  elements: Elements;
  bookingForm: FormGroup;
  submitting = false;
  dataCard;
  cardholder = false;
  stripeError = '';
  errorMsg;
  constructor(public  router: Router,
              private formBuilder: FormBuilder, public Service: DataService,
              private stripeService: StripeService, public  Auth: AuthServiceMain) {
  }

  ngOnInit() {
    this.bookingForm = this.formBuilder.group({
      HolderName: ['', Validators.pattern('^[A-Za-z -]+$')],
      cardAlias: [''],
    });
    this.stripeService.elements()
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            style: {
              base: {
                iconColor: '#515e80',
                color: '#515e80',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: 'Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                fontSize: '14px',
                '::placeholder': {
                  color: '#515e80'
                }
              }
            }
          });
          this.card.mount('#card-element');
        }
      });
  }

  get f() {
    return this.bookingForm.controls;
  }

  submitAddCardForm() {
    this.cardholder = this.bookingForm.controls.HolderName.value === '';
    if (this.cardholder) {
      return;
    }
    this.submitting = true;
    this.Service.alerts = [];
    this.stripeError = '';
    const name = this.bookingForm.controls.HolderName.value;
    this.stripeService
      .createToken(this.card, {name})
      .subscribe(res => {
        if (res.token) {
          let dataObjAddPayment = {
            alias: null,
            token: res.token.id,
            paymentGatewayProvider: 'STRIPE',
            channelType: 'CREDIT_CARD',
          };
          if (this.bookingForm.controls.cardAlias.value) {
            dataObjAddPayment = {
              alias: this.bookingForm.controls.cardAlias.value,
              token: res.token.id,
              paymentGatewayProvider: 'STRIPE',
              channelType: 'CREDIT_CARD',
            };
          }
          this.Service.addPayment(dataObjAddPayment).subscribe(res => {
            this.dataCard = res;
            if (this.dataCard.errorCode === 0) {
              this.Service.showAddingCardForm = false;
              this.Service.listPaymentService();
              this.Service.alerts = [];
              this.Service.modalOf();
              this.Service.BottomSheetOf();
              this.Service.messages(environment.messages.PAYMENT_CARD_SAVED, 'success');
            } else {
              this.errorMsg = this.dataCard.message;
            }
            this.submitting = false;
          }, err => {
            this.submitting = false;
            this.errorMsg = environment.messages.error;
          });

        } else {
          this.submitting = false;
          this.Service.alerts = [];
          this.stripeError = res.error.message;
        }
      });
  }

  close(){
    this.Service.modalOf();
    this.Service.BottomSheetOf();
  }

}
