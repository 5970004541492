<div *ngIf="!priceChange" class="mb-5 mb-lg-3">
  <p class="py-5 py-lg-2" i18n="@@approveBookMsg">Are you sure you want to approve this trip?</p>
  <div class="d-flex justify-content-end" *ngIf="dataService.desktopView">
    <button class="btn-outline-gray btn px-5 font-14" (click)="this.dataService.modalOf(); " [disabled]="submitting" i18n="@@cancelBtn"> Cancel </button>
    <button class="blue_btn px-5" [ngClass]="{'m-progress': submitting,
    'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"
            (click)="this.confirmTripApproval(this.approvalId, this.ignoreRatePriceChange, this.isFlight);" i18n="@@approveBook">
      Approve Booking
    </button>
  </div>
  <div *ngIf="dataService.mobileView">
    <button class="blue_btn px-5 w-100 mb-3" [ngClass]="{'m-progress': submitting }"
            (click)="this.confirmTripApproval(this.approvalId, this.ignoreRatePriceChange, this.isFlight);" i18n="@@approveBook">
      Approve Booking
    </button>
    <button class="btn-outline-gray btn px-5 font-14 w-100" (click)=" this.dataService.BottomSheetOf();" [disabled]="submitting" i18n="@@cancelBtn"> Cancel </button>
  </div>
  <div class="text-danger mt-3" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}" *ngIf="this.errorMsg">{{this.errorMsg}}</div>
</div>

<div *ngIf="priceChange" class="mb-5 mb-lg-3">
  <div class="d-flex py-5 py-lg-2">
    <i class="icon-warning-msg pt-1" [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
    <p *ngIf="!isFlight"><ng-container i18n="@@roomPriceChange">Room price changed from</ng-container> {{averageNightlySellingPriceCurrency}} {{this.averageNightlySellingPrice}}&nbsp;<span class="text-lowercase" i18n="@@to">to</span>&nbsp;{{this.newPrice}}</p>
    <p *ngIf="isFlight"><ng-container i18n="@@flightPriceChange">Flight price changed from</ng-container> {{sellingTotalFaresCurrency}} {{this.sellingTotalFares}}&nbsp;<span class="text-lowercase" i18n="@@to">to</span>&nbsp;{{this.newPrice}}</p>
  </div>
  <div class="d-flex justify-content-end" *ngIf="dataService.desktopView">
    <button class="btn-outline-gray btn px-5 font-14" (click)="this.dataService.modalOf()" [disabled]="submitting" i18n="@@cancelBtn"> Cancel </button>
    <button class="blue_btn px-5" [ngClass]="{'m-progress': submitting, 'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"
            (click)="this.confirmTripApprovalAfterPriceChange(this.approvalId, this.ignoreRatePriceChange.replace('false','true'), this.isFlight);"
            i18n="@@approveBook">
      Approve Booking
    </button>
  </div>
  <div *ngIf="dataService.mobileView">
    <button class="blue_btn px-5 w-100 mb-3" [ngClass]="{'m-progress': submitting }"
            (click)="this.confirmTripApprovalAfterPriceChange(this.approvalId, this.ignoreRatePriceChange.replace('false','true'), this.isFlight);"
            i18n="@@approveBook">
      Approve Booking
    </button>
    <button class="btn-outline-gray btn px-5 font-14 w-100" (click)=" this.dataService.BottomSheetOf();" [disabled]="submitting" i18n="@@cancelBtn"> Cancel </button>
  </div>
  <div class="text-danger mt-3" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}" *ngIf="this.errorMsg">{{this.errorMsg}}</div>
</div>
