import { Injectable } from '@angular/core';
import { CompanyArchiveStatus } from 'src/app/shared/enums/company-archive-status';
import { CostCenter } from 'src/app/shared/model/cost-management/cost-center';
import { Label } from 'src/app/shared/model/cost-management/label';

@Injectable({
    providedIn: 'root'
})
export class CostManagementStateService {
    costs: CostCenter[] = [];
    labels: Label[] = [];
    filteredCosts: CostCenter[] = [];
    filteredLabels: Label[] = [];
    hasCostCentersScroll: boolean = false;
    hasLabelsScroll: boolean = false;
    defualtPageSize: number = 3;
    isActiveCosts: boolean = true;
    isActiveLabels: boolean = true;

    constructor() { }

    setCostCenters(costs: CostCenter[]) {
        this.costs = costs;
        this.filterCostsBy(this.isActiveCosts);
    }

    setFilteredCostCenters(costs: CostCenter[]) {
        this.filteredCosts = costs;
    }

    setLabels(labels: Label[]) {
        this.labels = labels;
        this.filterLabelsBy(this.isActiveLabels);
    }

    setFilteredLabels(labels: Label[]) {
        this.filteredLabels = labels;
    }

    setIsActiveCosts(value: boolean = true) {
        this.isActiveCosts = value;
    }

    setIsActiveLabels(value: boolean = true) {
        this.isActiveLabels = value;
    }

    filterCostsBy(isActive: boolean = true) {
        this.isActiveCosts = isActive;
        this.filteredCosts = isActive ? this.costs.filter(item => item.status === CompanyArchiveStatus.Active) :
            this.costs.filter(item => item.status === CompanyArchiveStatus.Archived);
        this.hasCostCentersScroll = this.filteredCosts.length > this.defualtPageSize;
    }

    filterLabelsBy(isActive: boolean = true) {
        this.isActiveLabels = isActive;
        this.filteredLabels = isActive ? this.labels.filter(item => item.status === CompanyArchiveStatus.Active) :
            this.labels.filter(item => item.status === CompanyArchiveStatus.Archived);
        this.hasLabelsScroll = this.filteredLabels.length > this.defualtPageSize;
    }

    modifyCostCenters(item) {
        const modifiedList = this.getModifiedList(item, this.costs);
        this.setCostCenters(modifiedList);
    }

    modifyLabels(item) {
        const modifiedList = this.getModifiedList(item, this.labels);
        this.setLabels(modifiedList);
    }

    getModifiedList(item, currentList) {
        const itemIndex = currentList.findIndex(att => att.id == item.id);
        let modifiedList = [];

        if (itemIndex > -1) {
            modifiedList = currentList.map(att => {
                if (att.id === item.id) {
                    att = {...item};
                }
                return att;
            });
        } else {
            modifiedList = [...currentList, item];
        }

        return modifiedList;
    }
}