import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {VariablesService} from "../vars.service";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AmazonPaymentService {
    constructor( private VarsService: VariablesService,
                 private http: HttpClient) {
    }

    amazonSign(requestBody){
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.amazonSign}`,
            requestBody
        );
    }

    submitTokenizationRequest(paymentData){

        // Create the from with action
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = environment.amazonPayFortTokenizationUrl;

        Object.keys(paymentData).forEach(key => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = paymentData[key];
            form.appendChild(input);
        });

        // Append the form to the document and submit it
        document.body.appendChild(form);
        form.submit();
    }


}
