
<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
                         [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content pb-5" *ngIf="this.auth.companyInfo">
      <app-messages></app-messages>
  <!--    <div class="gray-pass-bg border-0 py-3 pl-3" *ngIf="dataService.mobileView">
        <a class="font-18 blue-text font-18 primaray-text font-weight-bold" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);">Settings</a>  <span class="font-18 gray-text-dark font-weight-bold"> / </span>
        <a class="font-18 blue-text font-18 primaray-text font-weight-bold" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/billing']);">Billing</a>
        <span class="font-18 gray-text-dark font-weight-bold"> / Cancel Subscription </span>
      </div>-->

      <div class="container pt-4 pt-lg-5 pb-0 pb-lg-5">
        <div class="mx-lg-5">
          <div class="row">
            <div class="col">
              <div class="pb-4 pb-lg-5 mb-3">
                <a class="font-16 blue-text text-underline" (click)="this.router.navigate(['/settings']);" i18n="@@menuItemSettings">Settings</a> <span class="font-16 gray-text-dark"> / </span>
                <a class="font-16 blue-text text-underline" (click)="this.router.navigate(['/settings/billing']);" i18n="@@menuItemBilling">Billing</a>
                <span class="font-16 gray-text-dark"> / <ng-container i18n="@@cancelSubscription">Cancel Subscription</ng-container> </span>
              </div>
            </div>
          </div>




          <p class="font-20 bold-600" *ngIf="this.auth.companyInfo" i18n="@@cancelCompanySub">Cancel {{this.auth.companyInfo.name}} Subscription</p>
          <p class="font-18 gray-text-dark mt-4"><ng-container i18n="@@cancelSubMsg1">Canceling your subscription means that
            your account will not be renewed on</ng-container>&nbsp;<span [innerHTML]="formatDate(this.dataService.workspaceSubscriptionDetails.current.nextBillingDate)"></span>. <ng-container i18n="@@cancelSubMsg2">Once canceled, your team will lose access and won’t be able to book trips.</ng-container></p>

           <p class="font-16 gray-text-dark mt-5"><ng-container i18n="@@contactIfNeedHelp">If you need help, please contact us at</ng-container>&nbsp;<a href="mailto:support@safarhub.com?Subject=Question" target="_top">support@safarhub.com</a></p>

            <p class="font-16 gray-primaray-text" i18n="@@sureToCancelSub">Are you sure you want to cancel? </p>
          <div class="row mt-5">
            <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-6': dataService.mobileView}">
              <button (click)="this.router.navigate([this.router.url.replace('/cancel-subscription' , '')]);"
                      class="btn-outline-blue btn px-5 w-100 mb-3 font-14 sharp-font bold-500" > <span class="px-lg-5" i18n="@@no">No</span>  </button>
            </div>
            <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-6': dataService.mobileView,
            'pr-lg-5': dataService.isSiteLtr(), 'pl-lg-5': dataService.isSiteRtl()}">
              <button (click)="confirmDeactivate()"
                      class="btn-outline-red btn px-5 w-100 mb-3 font-14 sharp-font bold-500" i18n="@@yesCancelSub">Yes, Cancel</button>
            </div>
          </div>
        </div>

      </div>




    </div>
  </mat-sidenav-content>
</mat-sidenav-container>





