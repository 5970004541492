
<div class="landingPopup">
  <div class="row no-gutters">
    <a class="close_landing_popup" aria-label="Close" (click)=" this.dataService.modalOf();" *ngIf="dataService.desktopView">
      <i class="icon-close-light gray-text-dark font-18" ></i>
    </a>
    <div class="col-md-5 landing_popup_img"  [style.backgroundImage]="'url(/assets/img/sales-demo.png)'" *ngIf="dataService.desktopView">
    </div>



    <div class="p-3 px-sm-5 py-sm-3 col-md-7">

      <div class="mb-3 promo-title" *ngIf="!sentRequest">
        <p class="gray-primaray-text font-16 py-3" [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}"
           i18n="@@requestDemo1">We're here to help, enter your name and work email</p>
        <form [formGroup]="contactUsForm">
          <div>

            <div class="row gutters-5 ">
              <div class="form-group col-sm-6 col-md-6" [ngClass]="{'pr-lg-2': dataService.isSiteLtr(), 'pl-lg-2': dataService.isSiteRtl()}">
                <input  class="placeholder-text-field form-control" id="firstName" formControlName="firstName"  type="text" name="firstName" i18n-placeholder="@@firstNamePlaceHolder" placeholder="First Name"/>
              </div>

              <div class="form-group col-sm-6 col-md-6" [ngClass]="{'pl-lg-2': dataService.isSiteLtr(), 'pr-lg-2': dataService.isSiteRtl()}">
                <input  class="placeholder-text-field form-control" id="lastName" formControlName="lastName"  type="text" name="lastName" i18n-placeholder="@@lastNamePlaceHolder" placeholder="Last Name"/>
              </div>


            </div>
            <div class="form-group mb-5">
              <input class="placeholder-text-field form-control" id="email" formControlName="email" autocomplete="off"  name="email" type="email" i18n-placeholder="@@workEmailPlaceHolder" placeholder="Work Email"
                     [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required" i18n="@@requiredEmailError">Email is required</div>
                <div *ngIf="f.email.errors.email" i18n="@@invalidEmailError">Email must be a valid email address</div>
              </div>
            </div>
          </div>

<!--<div class="row no-gutters">
  <div class="col-md-6 pr-lg-2">
    <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5 w-100 "  (click)="dataService.modalOf();dataService.BottomSheetOf()">Cancel </button>

      </div>
  <div class="col-md-6 pl-lg-2">
    <button class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5 w-100 " [ngClass]="{'my-3': dataService.mobileView}" (click)="onSubmit()">Submit</button>

  </div>
</div>-->
        <div class="justify-content-end" [ngClass]="{'d-flex': dataService.desktopView}">

            <button class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5" [ngClass]="{'m-progress': submitting, 'w-100': dataService.mobileView, 'ml-2': dataService.desktopView && dataService.isSiteLtr(), 'mr-2': dataService.desktopView && dataService.isSiteRtl()}"
                    [disabled]="submitting" (click)="onSubmit()" i18n="@@submitBtn">Submit</button>
            <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5 " [ngClass]="{'order-first': dataService.desktopView, 'w-100 my-3': dataService.mobileView}"
                    (click)="dataService.modalOf();dataService.BottomSheetOf()" i18n="@@cancelBtn">Cancel </button>
          </div>
          <p *ngIf="errorMsg" class="text-danger">{{errorMsg}}</p>
        </form>
        <ng-container *ngIf="dataService.desktopView">
        <p class="gray-text mt-5" i18n="@@requestDemo2">By giving us your email you agree to receive our emails.</p>
         <button type="button" class="btn-link mt-3 mt-lg-4"
                 [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}"
                 (click)="this.dataService.modalOf()" data-dismiss="modal" aria-label="Close" i18n="@@noThanks">No thanks </button>
        </ng-container>
      </div>
      <div class="mb-3 promo-title" *ngIf="sentRequest">
        <div class="successMsg d-flex align-items-center text-center my-auto mx-0 text-center h-100">
          <p class="gray-primaray-text font-16 px-4" i18n="@@requestDemo3">We’ve received your request, one of our team will contact you to schedule a demo session.  </p>
        </div>
      </div>



    </div>
  </div>

</div>

