

<div class="app-messages-holder container" [ngClass]="{'pad-top': this.restApi.alerts.length || this.restApi.alertsOverRoute.length}">

  <div class="" *ngFor="let alert of this.restApi.alerts"  [ngClass]="['alert-'+alert.type, 'alert alert-dismissible']" role="alert">
    <div *ngIf="alert.message">
      <span [ngClass]="{'pr-3': restApi.isSiteLtr(), 'pl-3': restApi.isSiteRtl()}"><i class="pt-1" [ngClass]="'icon-' + alert.type + '-msg'"></i></span>
      <span [innerHTML]="alert.message.replace('Resend verification email','').replace('Add your preferred Cash Back payment method here','')"></span>
      <span *ngIf="alert.message.indexOf('Resend verification email') > 0 && !this.restApi.resendCodeLoader" (click)="this.restApi.resendCode();"
            class="clickable-cursor blue-text">&nbsp;Resend verification email</span>
      <span class="m-progress"
        *ngIf="alert.message.indexOf('Resend verification email') > 0 && this.restApi.resendCodeLoader">&nbsp;
      </span>

      <span *ngIf="alert.message.indexOf('Add your preferred Cash Back payment method here') > 0" (click)="this.restApi.goToAddWallet()"
            class="clickable-cursor blue-text">&nbsp;Add your preferred Cash Back payment method here.</span>
      <button type="button" class="close" (click)="closess(alert)" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <div class="" *ngFor="let alert of this.restApi.alertsOverRoute"  [ngClass]="['alert-'+alert.type, 'alert alert-dismissible']" role="alert">
    <div  *ngIf="alert.message">
      <span [ngClass]="{'pr-3': restApi.isSiteLtr(), 'pl-3': restApi.isSiteRtl()}"><i class="pt-1" [ngClass]="'icon-' + alert.type + '-msg'"></i></span>
      <span [innerHTML]="alert.message.replace('Resend verification email','').replace('Add your preferred Cash Back payment method here','')"></span>
      <span *ngIf="alert.message.indexOf('Resend verification email') > 0" (click)="this.restApi.resendCode()"
            class="clickable-cursor blue-text"> Resend verification email</span>

      <span *ngIf="alert.message.indexOf('Add your preferred Cash Back payment method here') > 0" (click)="this.restApi.goToAddWallet()"
            class="clickable-cursor blue-text"> Add your preferred Cash Back payment method here.</span>
      <button type="button" class="close" (click)="closesAlertsOverRoute(alert)" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>




