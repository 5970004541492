<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
    [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pt-4 pt-lg-5">
        <div class="payment-list mx-lg-5">
          <div class="row">
            <div class="col-lg-9" [ngClass]="{'px-0': dataService.mobileView}">
              <div class="row font-16-to-14 font-weight-bold filtering blue-text rounded pb-2">
                <div class="col-auto py-1 pr-0">
                  <span class="pointer px-lg-4 font-16 gray-primaray-text bold-600"
                    [ngClass]="{'border-right': dataService.isSiteLtr(), 'border-left': dataService.isSiteRtl(), 'blue-text' : !isCards, 'pl-4 pr-3': dataService.mobileView && dataService.isSiteLtr(), 'pr-4 pl-3': dataService.mobileView && dataService.isSiteRtl()}"
                    (click)="setIsCard()" i18n="@@cards">
                    Cards
                  </span>
                </div>
                <div class="col-auto py-1 pr-0">
                  <span class="pointer font-16 gray-primaray-text bold-600"
                    [ngClass]="{'mr-lg-auto': dataService.isSiteLtr(), 'ml-lg-auto': dataService.isSiteRtl(), 'blue-text' : isCards, 'pr-4 pl-3': dataService.mobileView && dataService.isSiteLtr(), 'pl-3 pr-4': dataService.mobileView && dataService.isSiteRtl()}"
                    (click)="setIsCard(false)" i18n="@@wallets">
                    Wallets
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="isCards && this.dataService.companyPaymentList">
            <div class="row container pl-35 pt-4">
              <div class="d-flex flex-column">
                <div>
                  <p class="gray-text-dark font-18">
                    <span i18n="@@compCardMsg1">Create and manage company cards here. Grant access to your team or
                      specific members. We support Mada, Visa, Mastercard, and Amex. </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="container mb-5" *ngIf="
              this.dataService.companyPaymentList &&
              this.dataService.companyPaymentList.length > 0 &&
              this.dataService.companyPaymentExpiryList.length > 0
            ">
              <div class="row px-lg-3 py-4 hover-row-list font-14 items-full-list" [ngClass]="{
                'border-bottom': dataService.desktopView,
                'rounded border mb-4': dataService.mobileView
              }" *ngFor="
                let item of this.dataService.companyPaymentList;
                let i = index
              ">
                <div class="col-lg-5 d-flex" [ngClass]="{
                  'h-100 justify-content-center align-items-center':
                    dataService.desktopView
                }">
                  <div class="card_type card_type_{{ item.card.brand }}" [ngClass]="{
                    'max-width-card': dataService.mobileView,
                    'text-right mr-4':
                      dataService.desktopView && dataService.isSiteLtr(),
                    'text-left ml-4':
                      dataService.desktopView && dataService.isSiteRtl()
                  }"></div>
                  <div [ngClass]="{
                    'mr-auto': dataService.isSiteLtr(),
                    'ml-auto': dataService.isSiteRtl()
                  }">
                    <div class="mb-1" *ngIf="item.card.alias">
                      <span>{{ item.card.alias }}</span>
                    </div>
                    <div class="mb-1" *ngIf="!item.card.alias">
                      <span i18n="@@noAlias">No alias</span>
                    </div>
                    <p class="gray-text mb-0" dir="ltr">
                      **** **** **** {{ item.card.lastFour }}
                    </p>
                    <div *ngIf="dataService.mobileView" class="min-height-20">
                      <p *ngIf="this.dataService.companyPaymentExpiryList[i]" class="text-danger mb-0">
                        <i class="icon-warning-msg" [ngClass]="{
                          'mr-2': dataService.isSiteLtr(),
                          'ml-2': dataService.isSiteRtl()
                        }"></i>
                        <ng-container i18n="@@expired">Expired</ng-container>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5">
                  <p class="pt-4 pt-lg-0 mb-1 gray-text">
                    <span class="gray-text pr-2" *ngIf="dataService.mobileView">
                      <ng-container i18n="@@team">Team</ng-container>:
                    </span><span [ngClass]="{
                      'text-bg font-12 px-3 py-2': dataService.mobileView
                    }">{{ item.description }}</span>
                  </p>
                  <p *ngIf="
                    this.dataService.companyPaymentExpiryList[i] &&
                    dataService.desktopView
                  " class="text-danger mb-0">
                    <i class="icon-warning-msg" [ngClass]="{
                      'mr-2': dataService.isSiteLtr(),
                      'ml-2': dataService.isSiteRtl()
                    }"></i>
                    <ng-container i18n="@@expired">Expired</ng-container>
                  </p>
                </div>
                <div class="col-lg-2 pt-2 card-actions">
                  <div class="d-flex">
                    <div class="actions" [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }">
                      <i class="drop-down{{
                        i
                      }} icon-three-dots font-18 ellipsis  ellipsis-cards gray-text pointer p-3"
                        (click)="showActions = !showActions; selectedIndex = i"></i>
                      <div class="border shadow font-14 position-absolute bg-white drop"
                        *ngIf="showActions && i === selectedIndex">
                        <p class="pointer py-2" [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }" (click)="
                          editAlias(
                            item.card.id,
                            item.card.paymentGatewayProvider,
                            item.card.alias
                          )
                        " i18n="@@edit">
                          Edit
                        </p>
                        <p class="pointer py-2" [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }" (click)="removePayment(item.card.id)" i18n="@@deleteBtn">
                          Delete
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row d-flex justify-content-end pb-5 pt-4 pt-lg-5 ">
                <div class="d-flex flex-column">
                  <div class="mt-auto" [ngClass]="{
              'mr-auto': dataService.desktopView && dataService.isSiteLtr(),
              'ml-auto': dataService.desktopView && dataService.isSiteRtl()}">
                    <button (click)="addNewPayment()" class="btn btn-outline-blue font-14 px-5 sharp-font bold-500"
                      [ngClass]="{ 'btn-block': dataService.desktopView }" i18n="@@newCard">
                      Add Card
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="isCards">
            <div *ngIf="dataService.companyPaymentList == undefined || (dataService.companyPaymentList != undefined && dataService.companyPaymentList.length == 0)"
              class="main-dashboard-content mt-3 mb-3 pb-3">
              <div class="mt-5 mx-lg-0 px-4 px-lg-5 py-4 company-section-with-thick-border border" [ngClass]="{'minus-margin': dataService.desktopView,
                            'rounded-right': dataService.desktopView && dataService.isSiteLtr(), 'rounded-left': dataService.desktopView && dataService.isSiteRtl(),
                            'thick-top rounded-bottom': dataService.mobileView}">
                <div class="row py-3 h-100 justify-content-center align-items-center">
                  <div class="col-md-9">
                    <p class="font-18 bold-600 primaray-text" i18n="@@cards">Cards</p>
                    <p><span class="font-16 gray-text-dark" i18n="@@compCardMsg1">Create and manage company cards here.
                        Grant access to your team or specific members. We support Mada, Visa, Mastercard, and
                        Amex.</span></p>
                  </div>
                  <div class="col-md-3"
                    [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">
                    <button class="btn btn-outline-blue font-14 px-5 sharp-font bold-500"
                      [ngClass]="{'w-100':
                    dataService.mobileView, 'btn-outline-blue': auth.companyInfo && auth.companyInfo.inactive === false, 'btn-outline-gray': auth.companyInfo && auth.companyInfo.inactive}"
                      (click)="addNewPayment()" i18n="@@newCard">
                      Add Card
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!isCards">
            <div class="container wallet ml-0">
              <div class="payment-list main-dashboard-content">
                <div class="ml-0 pb-0 pb-lg-5">
                  <app-wallet-list></app-wallet-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>