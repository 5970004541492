<div
  class="modal notify-modal"
  *ngIf="this.Service.NotifymodalOn"
  [ngClass]="{
    'd-block': this.Service.NotifymodalShow === true,
    calculator: this.Service.NotifymodalName === 'corporateCalculateSavings'
  }"
>
  <div
    class="modal-dialog modal-lg d-flex"
    [ngClass]="{
      'refresh-flight-booking':
        this.Service.NotifymodalName === 'refreshFlightBooking' ||
        this.Service.NotifymodalName === 'bookingFormExpiry' ||
        this.Service.NotifymodalName === 'flightListExpiry'
    }"
  >
    <div
      class="modal-content justify-content-center vertical-align-center align-self-center"
      [ngClass]="{ Visible: this.Service.NotifymodalVisible === true }"
    >
      <div
        class="modal-body"
        [ngClass]="{
          'align-self-center':
            this.Service.NotifymodalName !== 'profileImageMobileMenu'
        }"
      >
        <div
          *ngIf="this.Service.NotifymodalName === 'CompleteYourBooking'"
          [ngClass]="{ 'text-right': Service.isSiteRtl() }"
        >
          <div class="">
            <div>
              <a
                (click)="this.Service.NotifymodalOf()"
                class="dropdown-item gray-text-dark font-16"
              >
                <span>
                  <i
                    class="icon-bed"
                    [ngClass]="{
                      'mr-2': Service.isSiteLtr(),
                      'ml-2': Service.isSiteRtl()
                    }"
                    *ngIf="!router.url.includes('flights')"
                  ></i>
                  <i
                    class="icon-flight-item"
                    [ngClass]="{
                      'mr-2': Service.isSiteLtr(),
                      'ml-2': Service.isSiteRtl()
                    }"
                    *ngIf="router.url.includes('flights')"
                  ></i
                  ><ng-container i18n="@@completeBooking"
                    >Complete your booking</ng-container
                  ></span
                >
              </a>
            </div>
            <div>
              <a
                (click)="this.Service.goBackBookingForm()"
                class="dropdown-item gray-text-dark font-16"
                ><span
                  ><i
                    [ngClass]="{
                      'mr-2 icon-arrow-left2': Service.isSiteLtr(),
                      'ml-2 icon-arrow-right2': Service.isSiteRtl()
                    }"
                  ></i
                  ><ng-container i18n="@@selectOther">Select other</ng-container
                  >&nbsp;<span
                    *ngIf="!router.url.includes('flights')"
                    i18n="@@anotherRoom"
                  >
                    room </span
                  ><span
                    *ngIf="router.url.includes('flights')"
                    i18n="@@anotherFlight"
                  >
                    flight
                  </span></span
                >
              </a>
            </div>
            <!---->
            <div>
              <a
                href="tel:8887070618"
                class="dropdown-item gray-text-dark font-16"
                ><span class="small"
                  ><i
                    class="icon-phone"
                    [ngClass]="{
                      'mr-2': Service.isSiteLtr(),
                      'ml-2': Service.isSiteRtl()
                    }"
                  ></i
                  ><ng-container i18n="@@needHelpCall"
                    >Need help? Call us at</ng-container
                  >&nbsp;<span dir="ltr">(888) 707-0618</span></span
                >
              </a>
            </div>
          </div>
        </div>

        <div
          *ngIf="
            (this.Service.NotifymodalName === 'refreshFlightBooking' ||
              this.Service.NotifymodalName === 'bookingFormExpiry' ||
              this.Service.NotifymodalName === 'flightListExpiry') &&
            Service.flightRatesExpiry
          "
        >
          <div class="justify-content-center">
            <div class="d-flex mb-4 border-bottom">
              <i
                class="icon-warning-msg pt-1 mt-1"
                [ngClass]="{
                  'mr-3': Service.isSiteLtr(),
                  'ml-3': Service.isSiteRtl()
                }"
              ></i>
              <p
                class="gray-text-dark font-16 bold-600"
                i18n="@@searchResultExpired"
              >
                Search result expired
              </p>
            </div>
            <div class="d-flex justify-content-center">
              <p
                class="font-16 gray-text-dark px-5 text-center"
                i18n="@@flightsPricesNotification"
              >
                Flight prices change frequently. To get the best prices, refresh
                your search or start over.
              </p>
            </div>

            <div class="d-flex justify-content-center">
              <button
                class="btn btn-outline-blue font-14 sharp-font bold-500 px-4 py-0 mt-4 mx-2"
                [ngClass]="{ 'm-progress': Service.submitting }"
                (click)="
                  this.router.navigate([
                    '/'
                  ]);
                  this.Service.flightTabActive = true;
                  this.Service.NotifymodalOf()
                "
              >
                <span class="px-4" i18n="@@startOverBtn">Start Over</span>
              </button>
              <button
                class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5 mt-4"
                *ngIf="this.Service.NotifymodalName === 'refreshFlightBooking'"
                [ngClass]="{ 'm-progress': Service.submitting }"
                (click)="
                  this.router.navigateByUrl(this.Service.NotifymodalData);
                  this.Service.NotifymodalOf()
                "
              >
                <span class="px-5" i18n="@@refresh">Refresh</span>
              </button>
              <button
                class="btn blue_btn font-14 sharp-font bold-500 py-0 px-3 mt-4 mx-2"
                *ngIf="
                  this.Service.NotifymodalName === 'bookingFormExpiry' ||
                  this.Service.NotifymodalName === 'flightListExpiry'
                "
                [ngClass]="{ 'm-progress': Service.submitting }"
                (click)="refreshPage()"
              >
                <span class="px-5" i18n="@@refresh">Refresh</span>
              </button>
            </div>
          </div>
        </div>

        <div
          *ngIf="this.Service.NotifymodalName === 'updateCompanyUserPolicyNote'"
        >
          <div class="p-4" i18n="@@applyPolicyToMember">
            When applying a policy to a member, it will overwrite the member
            team's travel policy
          </div>
        </div>

        <app-savings-calculator
          *ngIf="this.Service.NotifymodalName === 'corporateCalculateSavings'"
        ></app-savings-calculator>
        <app-contact-owner-msg
          *ngIf="this.Service.NotifymodalName === 'contactOwner'"
        ></app-contact-owner-msg>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      this.Service.NotifymodalOn &&
      (this.Service.NotifymodalName === 'refreshFlightBooking' ||
        this.Service.NotifymodalName === 'bookingFormExpiry' ||
        this.Service.NotifymodalName === 'flightListExpiry') &&
        Service.flightRatesExpiry
    "
    class="modal-backdrop show"
  ></div>
  <div
    *ngIf="
      this.Service.NotifymodalOn &&
      this.Service.NotifymodalName !== 'refreshFlightBooking' &&
      this.Service.NotifymodalName !== 'bookingFormExpiry' &&
      this.Service.NotifymodalName !== 'flightListExpiry'
    "
    (click)="this.Service.NotifymodalOf()"
    class="modal-backdrop show"
  ></div>
</div>
