import {Component, ElementRef, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import { SidenavService} from '../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../workspace-dashboard/animations/animations';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthServiceMain} from '../../../auth/auth.service';
import * as moment from 'moment';
import {map, startWith} from "rxjs/operators";
import {Payment} from "../create-team/create-team.component";
import {Title} from "@angular/platform-browser";
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.scss'],
  animations: [ onMainContentChange ],
  host: {
    '(body:click)': 'onClickBody($event)',
  },
})
export class TeamDetailsComponent implements OnInit {
  showHeaderTable = false;
  teamPendingCounter = 0;
  constructor(private route: ActivatedRoute,
              private titleService: Title,
              private eref: ElementRef,
              public dataService: DataService,
              private sidenavService: SidenavService,
              public router: Router,
              public auth: AuthServiceMain,
              public userService: UserService) {
    this.titleService.setTitle('Team details | SafarHub');
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });

 /*   this.dataService.componentMethodCalled$.subscribe(
      () => {
        if(this.dataService.teamInfo && this.dataService.pendingArrayUsers  && this.dataService.pendingArrayUsers.length) {
          this.dataService.pendingArrayUsers.forEach((element) => {
            if (element.teamName === this.dataService.teamInfo.name) {
              debugger
              this.showHeaderTable = true;
              this.teamPendingCounter++;
            }

          });
        }
      });*/
  }
  data;
  // teamInfo;
  // teamMembers;
  urlArray;
  teamId;
  previousURL;
  removeMemberData;
  showActions = false;
  selectedIndex;
  actionsPending = false;
  selectedIndexPending;
  search;

  teamPayments = [];
  ngOnInit(): void {
    this.urlArray = (this.router.url).split('/');
    this.teamId =  this.urlArray[this.urlArray.length - 1];
    const currentUrl = this.route.snapshot.url;  // Get the current URL segments
    this.previousURL = currentUrl.slice(0, currentUrl.length - 1).join('/');  // Remove the last segment

    this.dataService.teamInfo = null;
    this.dataService.getCompanyTeamMembers(this.teamId);
    this.dataService.getTeamDetails(this.teamId).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.teamInfo = this.data.data;
          this.dataService.getCompanyPendingUsers().subscribe(
            dataPending => {
              this.data = dataPending;
              if (this.data.errorCode === 0) {
                this.dataService.pendingArrayUsers = this.data.data;
                this.dataService.filteredPending = this.data.data;
                if(this.dataService.pendingArrayUsers){
                  this.dataService.pendingArrayUsers.forEach((element) => {
                    if (element.teamName === this.dataService.teamInfo.name) {
                      this.showHeaderTable = true;
                      this.teamPendingCounter++;
                    }

                  });
                }
              } else {
                this.dataService.messages(this.data.message, 'error');
              }
            }, error => {
              this.dataService.messages(environment.messages.error, 'error');
            }
          );





        } else {
          this.dataService.messages(this.data.message, 'error');
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
      }
    );















    this.dataService.getTeamPaymentMethods(this.teamId).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.teamPaymentMethods = this.data.data;
          if(this.dataService.teamPaymentMethods) {
            this.dataService.teamPaymentMethods.forEach((element) => {
              if (element.alias) {
                this.teamPayments.push(element.alias);
              } else {
                this.teamPayments.push(element.brand + ' ' + element.lastFour);
              }
            });
          }
        } else {
           this.dataService.messages(this.data.message, 'error');

        }
      }, error => {
         this.dataService.messages(environment.messages.error, 'error');

      }
    );





  }
  onClickBody(event) {
    if (this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex) && !this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex).contains(event.target) ){
      this.showActions = false;
    }
    if (this.eref.nativeElement.querySelector('.drop-down-pending' + this.selectedIndexPending) && !this.eref.nativeElement.querySelector('.drop-down-pending' + this.selectedIndexPending).contains(event.target) ){
      this.actionsPending = false;
    }
  }
  filterBy(filter) {
    this.dataService.teamMembersSortType = filter;
    if (filter === 'active' && this.dataService.teamMembersActiveArray) {
      this.dataService.filteredTeamMembersList =  this.dataService.teamMembersActiveArray.slice();
    } else if (filter === 'inactive' && this.dataService.teamMembersInactiveArray) {
      this.dataService.filteredTeamMembersList =  this.dataService.teamMembersInactiveArray.slice();
    } else if (filter === 'pending' && this.dataService.pendingArrayUsers) {
      this.dataService.filteredPending =  this.dataService.pendingArrayUsers.slice();
    } else {
      if(this.dataService.filteredPending) {
        this.dataService.filteredPending = this.dataService.pendingArrayUsers.slice();
      }
      if(this.dataService.teamMembers){
        this.dataService.filteredTeamMembersList =  this.dataService.teamMembers.slice();
      }

    }
    if (this.search) {
      if (this.dataService.teamMembersSortType === 'all' || this.dataService.teamMembersSortType === 'active' || this.dataService.teamMembersSortType === 'inactive') {
        if(this.dataService.filteredTeamMembersList){
          this.dataService.filteredTeamMembersList = this.dataService.filteredTeamMembersList.filter(item => {
            return item.firstName?.toLowerCase().indexOf(this.search) === 0 || item.lastName?.toLowerCase().indexOf(this.search) === 0
              || item.email.toLowerCase().indexOf(this.search) === 0;
          });
        }
      }
      if (this.dataService.teamMembersSortType === 'all' || this.dataService.teamMembersSortType === 'pending') {
        if(this.dataService.filteredPending) {
          this.dataService.filteredPending = this.dataService.filteredPending.filter(item => {
            return item.email.toLowerCase().indexOf(this.search) === 0;
          });
        }
      }
    }
  }
  filterByText(event) {
    this.search = event.target.value.toLowerCase();
    if (this.dataService.teamMembersSortType === 'active' && this.dataService.teamMembersActiveArray) {
      this.dataService.filteredTeamMembersList =  this.dataService.teamMembersActiveArray.slice();
    } else if (this.dataService.teamMembersSortType === 'inactive' && this.dataService.teamMembersInactiveArray) {
      this.dataService.filteredTeamMembersList =  this.dataService.teamMembersInactiveArray.slice();
    } else if (this.dataService.teamMembersSortType === 'pending' && this.dataService.pendingArrayUsers) {
      this.dataService.filteredPending =  this.dataService.pendingArrayUsers.slice();
    } else {
      if(this.dataService.filteredPending) {
        this.dataService.filteredPending =  this.dataService.pendingArrayUsers.slice();
      }
      if(this.dataService.teamMembers){
        this.dataService.filteredTeamMembersList =  this.dataService.teamMembers.slice();
      }
    }
    if (this.dataService.teamMembersSortType === 'all' || this.dataService.teamMembersSortType === 'active' || this.dataService.teamMembersSortType === 'inactive') {
      if(this.dataService.filteredTeamMembersList) {
        this.dataService.filteredTeamMembersList = this.dataService.filteredTeamMembersList.filter(item => {
          return item.firstName?.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0 || item.lastName?.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0
            || item.email.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0;
        });
      }
    }
    if (this.dataService.teamMembersSortType === 'all' || this.dataService.teamMembersSortType === 'pending') {
      if (this.dataService.filteredPending) {
        this.dataService.filteredPending = this.dataService.filteredPending.filter(item => {
          return item.email.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0;
        });
      }
    }
  }
  formatDate(date) {
    return moment(date).format('MMMM') + ' ' +  moment(date).format('YYYY');
  }
  addMembers() {
      if (this.dataService.desktopView) {
        this.dataService.setModal($localize`:@@addMembers:Add Members`, 'addTeamMembers', '');
      } else {
        this.router.navigate([this.router.url + '/add-members']);
      }
  }
  removeTeam(teamId, teamName) {
    const teamData = {
      teamIdValue: teamId,
      teamNameValue: teamName  };
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@removeTeam:Delete Team`, 'removeTeam', teamData);
    } else {
      this.dataService.setBottomSheet('removeTeam', teamData);
    }
  }
  editTeam(teamId) {
 /*   if (this.dataService.desktopView) {
      this.dataService.setModal('Edit Team', 'editTeam', teamId);
    } else {*/
      this.router.navigate([this.router.url + '/edit']);
   // }
  }

  removeMember(userId, userDescription) {
    this.removeMemberData = {
      memberId: userId,
      memberDescription: userDescription,
      teamName: this.dataService.teamInfo.name,
      teamId: this.teamId};

    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@removeMember:Remove Member`, 'removeMember', this.removeMemberData);
    } else {
      this.dataService.setBottomSheet('removeMember', this.removeMemberData);
    }
  }
  showTeamMemberDetails(teamMemberId) {

  }
  viewUser(id) {
    if(this.auth.isAdmin() || this.auth.isOwner()){
      this.router.navigate(['/members/' + id]);
    }
  }
  showCancelInvitation(email) {
    this.dataService.submitting = false;
    if (this.dataService.desktopView) {
      this.dataService.setModal( $localize`:@@withdrawInviteTitle:Withdraw Invitation`, 'cancelInvitation', email);
    } else {
      this.dataService.setBottomSheet('cancelInvitation', email);
    }
  }
}
