<div class="section-body gray-text-dark p-4 mt-1">
    <div class="row">
        <div class="col-md-7">

            <div class="checkout-container">
                <!-- Checkout Form -->
                <form [formGroup]="walletInfoForm">

                    <!-- Name Input -->
                    <div class="form-group">
                        <label for="name" class="gray-text-dark" i18n="@@walletName"> Name </label>
                        <input
                                id="name"
                                formControlName="name"
                                type="text"
                                class="form-control"/>
                    </div>

                    <!-- Currency Code Input -->
                    <div class="form-group">
                        <label for="currencyCode" class="gray-text-dark" i18n="@@walletCurrancyCode">Currency</label>
                            <input
                                    id="currencyCode"
                                    formControlName="currencyCode"
                                    type="text"
                                    class="form-control"
                                    (input)="lettersOnly($event)"/>                    

                    </div>

                    <!-- Description Input -->
                    <div class="form-group">
                        <label for="description" class="gray-text-dark" i18n="@@walletDescription">Description</label>
                            <textarea class="text-area-width"
                                    id="description"
                                    formControlName="description"
                                    type="text"
                                    class="form-control p-3"
                                    rows="4"></textarea>             
                    </div>

                    <p class="mt-3 mb-4 text-danger font-12" *ngIf=" this.validationMessage">{{this.validationMessage}}</p>

                    <!-- Submit Button -->
                    <div class="form-actions d-flex justify-content-md-start justify-content-center mt-5 mt-md-0" *ngIf="!dataService.mobileView">
                        <button type="button" class="btn-outline-blue btn cancel font-14"
                                [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"
                                (click)="close()" i18n="@@cancelBtn"> Cancel</button>
                        <button type="button" [ngClass]="{'m-progress': submitting }" class="loader_button btn blue_btn blue_btn_form form-submit" (click)="onSubmit()" i18n="@@save">Save</button>
                    </div>
                    <div class="form-actions justify-content-md-start justify-content-center mt-5 mt-md-0" *ngIf="dataService.mobileView">
                        <button type="button" [ngClass]="{'m-progress': submitting }" class="loader_button btn blue_btn blue_btn_form form-submit w-100 mb-3" (click)="onSubmit()" i18n="@@save">Save</button>
                        <button *ngIf="!this.router.url.includes('add-card')" type="button" class="btn-outline-blue btn cancel w-100" (click)="close()" i18n="@@cancelBtn"> Cancel</button>
                    </div>
                </form>
            </div>

        </div>

        <!-- right side -->
        <div class="col-md-5 border-sm-0" *ngIf="dataService.desktopView"
             [ngClass]="{'border-left': dataService.isSiteLtr(), 'border-right': dataService.isSiteRtl()}">
        <app-accepted-wallet-section></app-accepted-wallet-section>
        </div>
    </div>
</div>


