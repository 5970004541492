import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataService } from "../../services/data.service";
import { environment } from "../../../environments/environment";
import { AuthServiceMain } from "../../auth/auth.service";
import { VariablesService } from "../../services/vars.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import * as moment from "moment";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-add-edit-travel-document",
  templateUrl: "./add-edit-travel-document.component.html",
  styleUrls: ["./add-edit-travel-document.component.scss"],
})
export class AddEditTravelDocumentComponent implements OnInit {
  constructor(
    public datePipe: DatePipe,
    private formBuilder: FormBuilder,
    public dataService: DataService,
    public vars: VariablesService,
    public auth: AuthServiceMain
  ) {}
  selectedValue = {
    name: "",
    code: "",
  };
  docForm: FormGroup;
  data;
  dataUser;
  submitting;
  submitted;
  errorMessage;
  expiryDateVal;
  minDate = new Date();
  formatExpiry;
  travelDocData;
  ngOnInit(): void {
    this.docForm = this.formBuilder.group({
      selectedCountry: ["", Validators.required],
      passportNumber: ["", Validators.required],
      expiryDate: ["", Validators.required],
    });
    if (this.dataService.desktopView) {
      this.travelDocData = this.dataService.rightSideDrawerData;
    } else {
      this.travelDocData = this.dataService.BottomSheetData;
    }
    if (this.travelDocData !== null) {
      this.formatExpiry = this.datePipe.transform(
        this.travelDocData.expiryDate,
        "MMM dd, y"
      );
      this.docForm.controls.expiryDate.setValue(
        new Date(this.travelDocData.expiryDate)
      );
      this.docForm.controls.passportNumber.setValue(this.travelDocData.number);
      this.docForm.controls.selectedCountry.setValue(
        this.travelDocData.issuingCountryCode
      );
      this.selectedValue.code = this.travelDocData.issuingCountryCode;
      const selected = this.vars.countries.filter(
        (item) => item.code === this.travelDocData.issuingCountryCode
      );
      this.selectedValue.name = selected[0].name;
    }
  }
  expiryDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.expiryDateVal =
      moment(event.value).format("YYYY") +
      "-" +
      moment(event.value).format("MM") +
      "-" +
      moment(event.value).format("DD");
    this.formatExpiry = this.datePipe.transform(event.value, "MMM dd, y");
  }
  selectChangeHandler(event: any) {
    this.selectedValue.code = event;
    const selected = this.vars.countries.filter((item) => item.code === event);
    this.selectedValue.name = selected[0].name;
  }
  get f() {
    return this.docForm.controls;
  }
  editDoc() {
    this.dataService.alerts = [];
    this.submitting = true;
    this.submitted = true;
    if (this.docForm.invalid) {
      this.submitting = false;
      return;
    }
    const travelDocument = {
      id: this.travelDocData.id,
      expiryDate: this.expiryDateVal
        ? this.expiryDateVal
        : this.travelDocData.expiryDate,
      issuingCountry: this.selectedValue.name,
      issuingCountryCode: this.selectedValue.code,
      issuingDate: "",
      number: this.docForm.controls.passportNumber.value,
      type: "PASSPORT",
    };
    this.dataService.updateTravelDocument(travelDocument).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          //  this.auth.fetchUserData();
          this.dataService.getUserInfo().subscribe(
            (dataUser) => {
              this.dataUser = dataUser;
              if (this.dataUser.errorCode === 0) {
                this.auth.userInfo.travelDocuments =
                  this.dataUser.data.travelDocuments;
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(this.auth.userInfo)
                );
                this.dataService.fetchUserData.next();
              } else {
                this.dataService.messages(this.dataUser.message, "error");
              }
            },
            (error) => {
              this.dataService.messages(environment.messages.error, "error");
            }
          );

          this.submitting = false;
          this.dataService.messages(
            $localize`:@@travelDocUpdated:Travel document was updated successfully`,
            "success"
          );
          this.dataService.BottomSheetOf();
          this.dataService.rightSideDrawerOff();
        } else {
          this.errorMessage = this.data.message;
          this.submitting = false;
        }
      },
      (error) => {
        this.errorMessage = environment.messages.error;
        this.submitting = false;
      }
    );
  }
  addDoc() {
    this.dataService.alerts = [];
    this.submitting = true;
    this.submitted = true;
    if (this.docForm.invalid) {
      this.submitting = false;
      return;
    }
    const travelDocument = {
      expiryDate: this.expiryDateVal,
      issuingCountry: this.selectedValue.name,
      issuingCountryCode: this.selectedValue.code,
      issuingDate: "",
      number: this.docForm.controls.passportNumber.value,
      type: "PASSPORT",
    };
    this.dataService.addTravelDocument(travelDocument).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          //this.auth.fetchUserData();
          this.dataService.getUserInfo().subscribe(
            (dataUser) => {
              this.dataUser = dataUser;
              if (this.dataUser.errorCode === 0) {
                this.auth.userInfo.travelDocuments =
                  this.dataUser.data.travelDocuments;
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(this.auth.userInfo)
                );
                this.dataService.fetchUserData.next();
              } else {
                this.dataService.messages(this.dataUser.message, "error");
              }
            },
            (error) => {
              this.dataService.messages(environment.messages.error, "error");
            }
          );
          this.submitting = false;
          this.dataService.messages(
            $localize`:@@travelDocAdded:Travel document was added successfully`,
            "success"
          );
          this.dataService.BottomSheetOf();
          this.dataService.rightSideDrawerOff();
        } else {
          this.errorMessage = this.data.message;
          this.submitting = false;
        }
      },
      (error) => {
        this.errorMessage = environment.messages.error;
        this.submitting = false;
      }
    );
  }
}
