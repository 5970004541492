
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {DataService} from "../data.service";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {VariablesService} from "../vars.service";

@Injectable({
    providedIn: 'root',
})
export class MobileAppPaymentService
{
    constructor(public  dataService: DataService,
                private http: HttpClient,
                public VarsService: VariablesService,
                public router: Router) {}
    private tokenSubject = new BehaviorSubject<string | null>(null);


    // Set the token from the interceptor
    setTokenString(token: string) {
        this.tokenSubject.next(token);
    }

    // Get the token as an observable
    getTokenString() {
        return this.tokenSubject.asObservable();
    }

    listPayments(token: string): Observable<any> {
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        });

        return this.http.post(`${environment.apiBase}${this.VarsService.apiUrls.getPaymentsMe}`, { headers });
    }
    companyListPaymentMethods(token: string): Observable<any> {
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        });

        return this.http.post(`${environment.apiBase}${this.VarsService.apiUrls.companyListPaymentMethods}`, { headers });
    }


    paymentList(token: string){
        if (this.router.url.includes("settings/payment")) {
            this.companyListPaymentService(token);
        } else {
            this.listPayments(token).subscribe(
                (data) => {
                    this.dataService.dataPaymentMethods = data;
                    if (this.dataService.dataPaymentMethods.errorCode === 0) {
                        this.dataService.paymentMethods = this.dataService.dataPaymentMethods.data;
                        this.dataService.userPaymentExpiryList = [];
                        this.dataService.paymentMethods.cards.forEach((element) => {
                            const splitDate = element.expiry.split("-");
                            if (splitDate[0] > this.dataService.currentYear) {
                                this.dataService.userPaymentExpiryList.push(false);
                            } else {
                                if (splitDate[1] >= this.dataService.currentMonth) {
                                    this.dataService.userPaymentExpiryList.push(false);
                                } else {
                                    this.dataService.userPaymentExpiryList.push(true);
                                }
                            }
                        });
                    } else {
                    }
                },
                (error) => {}
            );
        }
    }

    companyListPaymentService(token: string) {
        this.companyListPaymentMethods(token).subscribe(
            (data) => {
                this.dataService.data = data;
                if (this.dataService.data.errorCode === 0) {
                    this.dataService.companyPaymentList = this.dataService.data.data;
                    if (this.dataService.companyPaymentList && this.dataService.companyPaymentList.length) {
                        this.dataService.companyPaymentList.forEach((element, index) => {
                            const splitDate = element.card.expiry.split("-");
                            if (splitDate[0] > this.dataService.currentYear) {
                                this.dataService.companyPaymentExpiryList.push(false);
                            } else {
                                if (splitDate[1] >= this.dataService.currentMonth) {
                                    this.dataService.companyPaymentExpiryList.push(false);
                                } else {
                                    this.dataService.companyPaymentExpiryList.push(true);
                                }
                            }
                        });
                    }
                }
            },
            (error) => {}
        );
    }


}
