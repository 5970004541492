import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../../../services/data.service";

@Component({
  selector: 'app-flight-summary',
  templateUrl: './flight-summary.component.html',
  styleUrls: ['./flight-summary.component.scss']
})
export class FlightSummaryComponent implements OnInit {
@Input() flightSummary;
  constructor(public Service: DataService) { }

  ngOnInit(): void {
  }
  showFlightDetails(){
    let flightDataRequestObj = {
      flightDetails: this.flightSummary,
      isDataAlreadyExist: true
    };
    this.Service.setRightSideDrawer("flightDetails", flightDataRequestObj);
  }
}
