import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-fares-details',
  templateUrl: './fares-details.component.html',
  styleUrls: ['./fares-details.component.scss']
})
export class FaresDetailsComponent implements OnInit {
  @Input() fareDetailsBySegment;
  constructor() { }

  ngOnInit(): void {
  }

}
