<div class="container create-team pb-5">
  <div
    [ngClass]="{
      'w-75': this.dataService.desktopView && !dataService.createEditTeamPage
    }"
  >
    <form
      name="createTeamForm"
      [formGroup]="createTeamForm"
      *ngIf="dataService.desktopView || teamId === undefined"
    >
      <p
        class="mb-2"
        [ngClass]="{
          'gray-text': teamId,
          'gray-primaray-text': !teamId,
          'font-16 custom-line-height': dataService.createEditTeamPage
        }"
        i18n="@@teamName"
      >
        Team Name
      </p>
      <div
        class="form-group with-icon position-relative form-type-textfield form-name-email"
        [ngClass]="{ 'realtime-loader': nameLoader }"
      >
        <input
          #nameInput
          type="text"
          name="teamName"
          class="form-control"
          formControlName="teamName"
          autocomplete="off"
          id="nameInputTeam"
          [ngClass]="{
            'is-invalid': submitted && c.teamName.errors,
            'edit-field': teamId && !editTeamName
          }"
          (click)="editTeamName = true"
          (blur)="editTeamNameDone()"
          (focus)="nameInput.select()"
          (keyup.enter)="enterAction()"
          (keyup.escape)="restoreTeamName(); nameInput.blur()"
        />
      </div>
      <div *ngIf="submitted && c.teamName.errors" class="text-danger font-12">
        <div *ngIf="c.teamName.errors.required" i18n="@@teamNameRequired">
          Team Name is required
        </div>
      </div>
    </form>
    <div
      class="form-control rounded edit-field"
      *ngIf="dataService.mobileView && teamId"
      (click)="showTeamNamePopUp()"
    >
      <p
        *ngIf="this.dataService.teamInfo && this.dataService.teamInfo.name"
        class="mt-2 pt-3"
      >
        {{ this.dataService.teamInfo.name }}
      </p>
    </div>
    <p
      class="mb-2"
      [ngClass]="{
        'gray-text': teamId,
        'gray-primaray-text': !teamId,
        'font-16 custom-line-height mt-5': dataService.createEditTeamPage,
        'mt-4': !dataService.createEditTeamPage
      }"
    >
      <ng-container i18n="@@members">Members</ng-container> &nbsp;<span
        class="font-12 gray-text"
        ><br *ngIf="dataService.createEditTeamPage" /><span
          *ngIf="!dataService.createEditTeamPage"
          >(</span
        ><ng-container i18n="@@oneTeamOnly"
          >Member can be part of one team at a time</ng-container
        ><span *ngIf="!dataService.createEditTeamPage">)</span></span
      >
    </p>
    <mat-form-field
      class="w-100 chips-container rounded"
      [ngClass]="{
        'edit-field':
          !this.dataService.teamMembers && teamId && !editTeamMembers,
        border: teamId === undefined || (teamId && editTeamMembers)
      }"
      (click)="showMembersPopUp(); editTeamMembers = true; desktopMemberFocus()"
    >
      <mat-chip-list #chipList>
        <mat-chip
          class="pointer"
          (click)="
            showMembersPopUp(); editTeamMembers = true; desktopMemberFocus()
          "
          *ngFor="let user of selectedUsers"
        >
          {{ user.description }}
          <i
            class="icon-danger placeholder-text"
            [ngClass]="{
              'ml-2': dataService.isSiteLtr(),
              'mr-2': dataService.isSiteRtl()
            }"
            *ngIf="
              dataService.desktopView &&
              (teamId === undefined || editTeamMembers)
            "
            (click)="toggleSelection(user)"
          ></i>
        </mat-chip>
        <mat-hint
          class="pt-3 pt-lg-3"
          [ngClass]="{
            'pl-4': dataService.isSiteLtr(),
            'pr-4': dataService.isSiteRtl()
          }"
          style="color: black; pointer-events: none"
          *ngIf="!this.dataService.teamMembers && teamId && !editTeamMembers"
          i18n="@@noMembers"
          >No Members</mat-hint
        >
        <input
          id="desktopUserInput"
          *ngIf="
            this.dataService.desktopView &&
            (teamId === undefined || (teamId && editTeamMembers))
          "
          #userInput
          class="min-height mx-0 my-0"
          [ngClass]="{
            'pl-4': dataService.isSiteLtr(),
            'pr-4': dataService.isSiteRtl()
          }"
          type="text"
          placeholder="Select Users"
          aria-label="Select Users"
          matInput
          [matAutocomplete]="auto"
          [formControl]="userControl"
          (focus)="reset()"
          (blur)="resetBlur()"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
        />
      </mat-chip-list>
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option
        style="padding: 0"
        *ngFor="let user of filteredUsers | async; let i = index"
        [value]="selectedUsers"
      >
        <div
          *ngIf="
            user.description !== 'Unassigned' &&
            user.description !== NoManagerLocalized
          "
          (click)="optionClicked($event, user)"
          class="mat-checkbox-container"
        >
          <mat-checkbox
            color="primary"
            class="position-absolute"
            [checked]="user.selected"
            (change)="toggleSelection(user)"
            (click)="$event.stopPropagation()"
          >
          </mat-checkbox>
          <p
            [ngClass]="{
              'mb-0': dataService.desktopView,
              'ml-5': dataService.desktopView && dataService.isSiteLtr(),
              'mr-5': dataService.desktopView && dataService.isSiteRtl()
            }"
          >
            {{ user.description }}<br />
            <span class="font-12 gray-text" *ngIf="user.teamName">{{
              user.teamName
            }}</span>
          </p>
        </div>
      </mat-option>
    </mat-autocomplete>
    <div
      class="d-flex justify-content-end mt-3"
      *ngIf="teamId && editTeamMembers"
    >
      <button
        class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
        (click)="cancelEditTeamMembers()"
        i18n="@@cancelBtn"
      >
        Cancel
      </button>
      <button
        class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5"
        [ngClass]="{
          'ml-3': dataService.isSiteLtr(),
          'mr-3': dataService.isSiteRtl(),
          'm-progress': submitting
        }"
        (click)="saveEditTeamMembers()"
        i18n="@@save"
      >
        Save
      </button>
    </div>
    <div
      *ngIf="errorMessageUser"
      class="text-danger smaller pt-3"
      [ngClass]="{ 'text-right': dataService.desktopView }"
    >
      {{ errorMessageUser }}
    </div>
    <p
      class="mb-2"
      [ngClass]="{
        'gray-text': teamId,
        'gray-primaray-text': !teamId,
        'font-16 custom-line-height mt-5': dataService.createEditTeamPage,
        'mt-4': !dataService.createEditTeamPage
      }"
    >
      <ng-container i18n="@@manager">Manager</ng-container> &nbsp;<span
        class="font-12 gray-text"
        ><br *ngIf="dataService.createEditTeamPage" /><span
          *ngIf="!dataService.createEditTeamPage"
          >(</span
        ><ng-container i18n="@@setToTeamManager"
          >All team’s trip approvals will be sent to the team
          manager</ng-container
        ><span *ngIf="!dataService.createEditTeamPage">)</span></span
      >
    </p>
    <mat-form-field
      [ngClass]="{
        'border-0': teamId && !editTeamManager,
        border: teamId === undefined || editTeamManager,
        'realtime-loader': managerLoader
      }"
      class="w-100 chips-container rounded"
      *ngIf="dataService.desktopView"
      (click)="editTeamManager = true; managerInput.select()"
    >
      <i
        *ngIf="!managerLoader"
        [ngClass]="{
          'gray-text': editTeamManager,
          'prevent-pointer-event inactive-clear': !editTeamManager && teamId,
          'prevent-pointer-event text-white': !editTeamManager && !teamId
        }"
        class="icon-cross small pointer clear-text p-1"
        (click)="applyBlur = false; desktopManagerFocus()"
      ></i>
      <input
        class="min-height mx-0 my-0"
        id="managerInputHtml"
        #managerInput
        type="text"
        matInput
        [formControl]="managerControl"
        [matAutocomplete]="autoManager"
        (blur)="resetManager()"
        [ngClass]="{
          'pl-4': dataService.isSiteLtr(),
          'pr-4': dataService.isSiteRtl(),
          'edit-field': teamId && !editTeamManager
        }"
        #trigger="matAutocompleteTrigger"
        (keyup.enter)="onEnterManager(); trigger.closePanel()"
        (keyup.escape)="restoreSelectedManager()"
      />
      <mat-autocomplete
        #autoManager="matAutocomplete"
        [displayWith]="displayFn"
      >
        <mat-option
          style="padding: 0"
          *ngFor="let user of filteredManager | async"
          [value]="user"
        >
          <div
            (click)="optionClickedManager($event, user)"
            class="mat-checkbox-container"
          >
            {{ user.description }}<br /><span
              class="font-12 gray-text"
              *ngIf="user.teamName"
              >{{ user.teamName }}</span
            >
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div
      class="form-control rounded pt-1"
      *ngIf="dataService.mobileView"
      [ngClass]="{
        'edit-field': teamId && !editTeamManager,
        'border-0': teamId && !editTeamManager,
        border: teamId === undefined || editTeamManager
      }"
      (click)="showManagerPopUp()"
    >
      <p *ngIf="selectedManager" class="mt-2 pt-1">
        {{ selectedManager.description }}
      </p>
    </div>
    <p
      class="font-12 text-danger mt-2"
      *ngIf="teamId && noManagerWarning"
      i18n="@@removeManagerMsg"
    >
      By removing the manager, all booking approval requests will be sent to the
      owner.
    </p>
    <p
      class="mb-2"
      [ngClass]="{
        'gray-text': teamId,
        'gray-primaray-text': !teamId,
        'font-16 custom-line-height mt-5': dataService.createEditTeamPage,
        'mt-4': !dataService.createEditTeamPage
      }"
    >
      <ng-container i18n="@@policy">Policy</ng-container> &nbsp;<span
        class="font-12 gray-text"
        ><br *ngIf="dataService.createEditTeamPage" /><span
          *ngIf="!dataService.createEditTeamPage"
          >(</span
        ><ng-container i18n="@@selectPolicy"
          >select the team travel policy</ng-container
        ><span *ngIf="!dataService.createEditTeamPage">)</span></span
      >
    </p>
    <mat-form-field
      class="w-100 chips-container rounded"
      [ngClass]="{
        'border-0': teamId && !editTeamPolicy,
        border: teamId === undefined || editTeamPolicy,
        'realtime-loader': policyLoader
      }"
      *ngIf="dataService.desktopView"
      (click)="editTeamPolicy = true; policyInput.select()"
    >
      <i
        *ngIf="!policyLoader"
        [ngClass]="{
          'gray-text': editTeamPolicy,
          'prevent-pointer-event inactive-clear': !editTeamPolicy && teamId,
          'prevent-pointer-event text-white': !editTeamPolicy && !teamId
        }"
        class="icon-cross small pointer clear-text p-1"
        (click)="applyBlur = false; desktopPolicyFocus()"
      ></i>
      <input
        [ngClass]="{
          'pl-4': dataService.isSiteLtr(),
          'pr-4': dataService.isSiteRtl(),
          'edit-field': teamId && !editTeamPolicy
        }"
        class="min-height mx-0 my-0"
        id="policyInputHtml"
        #policyInput
        type="text"
        matInput
        [formControl]="policyControl"
        [matAutocomplete]="autoPolicy"
        #trigger="matAutocompleteTrigger"
        (keyup.enter)="onEnterPolicy(); trigger.closePanel()"
        (blur)="resetPolicy()"
        (keyup.escape)="restoreSelectedPolicy()"
        (focus)="policyAutoComplete()"
      />
      <mat-autocomplete
        #autoPolicy="matAutocomplete"
        [displayWith]="displayFnPolicy"
      >
        <mat-option
          style="padding: 0"
          *ngFor="let policy of filteredPolicies | async"
          [value]="policy"
        >
          <div
            (click)="optionClickedPolicy($event, policy)"
            class="mat-checkbox-container"
          >
            {{ policy.name }}
          </div>
        </mat-option>
        <mat-option
          style="padding: 0"
          *ngIf="
            dataService.createEditTeamPage &&
            dataService.desktopView &&
            (this.auth.isAdmin() || this.auth.isOwner())
          "
        >
          <div
            (click)="
              this.dataService.setModal(
                dataService.newPolicyLocalized,
                'createPolicy',
                ''
              )
            "
            class="mat-checkbox-container"
          >
            <p
              class="blue-text"
              [ngClass]="{
                'mb-0': dataService.desktopView,
                'ml-1': dataService.desktopView && dataService.isSiteLtr(),
                'mr-1': dataService.desktopView && dataService.isSiteRtl()
              }"
              i18n="@@createNewPolicy"
            >
              Create new policy
            </p>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div
      class="form-control rounded pt-1"
      *ngIf="dataService.mobileView"
      [ngClass]="{
        'edit-field': teamId && !editTeamPolicy,
        'border-0': teamId && !editTeamPolicy,
        border: teamId === undefined || editTeamPolicy
      }"
      (click)="showPolicyPopUp()"
    >
      <p *ngIf="selectedPolicy" class="mt-2 pt-1">{{ selectedPolicy.name }}</p>
    </div>
    <p
      class="mb-2"
      [ngClass]="{
        'gray-text': teamId,
        'gray-primaray-text': !teamId,
        'font-16 custom-line-height mt-5': dataService.createEditTeamPage,
        'mt-4': !dataService.createEditTeamPage
      }"
      i18n="@@paymentMethods"
    >
      Payment Methods
    </p>
    <mat-form-field
      class="w-100 chips-container rounded"
      [ngClass]="{
        'edit-field':
          !this.dataService.teamPaymentMethods && teamId && !editTeamPayments,
        border: teamId === undefined || (teamId && editTeamPayments)
      }"
      (click)="
        showPaymentsPopUp(); editTeamPayments = true; desktopPaymentFocus()
      "
      *ngIf="
        (dataService.desktopView && dataService.createEditTeamPage) ||
        (getAllPaymentFlag && payments.length && dataService.desktopView) ||
        (getAllPaymentFlag && payments.length && dataService.mobileView)
      "
    >
      <mat-chip-list #chipListPayment>
        <mat-chip
          class="pointer"
          (click)="
            showPaymentsPopUp(); editTeamPayments = true; desktopPaymentFocus()
          "
          *ngFor="let payment of selectedPayments"
        >
          {{ payment.description }}
          <i
            class="icon-danger placeholder-text"
            [ngClass]="{
              'ml-2': dataService.isSiteLtr(),
              'mr-2': dataService.isSiteRtl()
            }"
            *ngIf="
              dataService.desktopView &&
              (teamId === undefined || editTeamPayments)
            "
            (click)="toggleSelectionPayment(payment)"
          ></i>
        </mat-chip>
        <mat-hint
          class="pt-3"
          [ngClass]="{
            'pl-4': dataService.isSiteLtr(),
            'pr-4': dataService.isSiteRtl()
          }"
          style="color: black; pointer-events: none"
          *ngIf="
            !this.dataService.teamPaymentMethods && teamId && !editTeamPayments
          "
          i18n="@@noPayment"
          >No Payment Methods</mat-hint
        >
        <input
          id="desktopPaymentInput"
          *ngIf="
            this.dataService.desktopView &&
            (teamId === undefined || (teamId && editTeamPayments))
          "
          #paymentInput
          class="min-height mx-0 my-0"
          [ngClass]="{
            'pl-4': dataService.isSiteLtr(),
            'pr-4': dataService.isSiteRtl()
          }"
          type="text"
          placeholder="Select Payment Methods"
          aria-label="Select Payment Methods"
          matInput
          [matAutocomplete]="autoB"
          [formControl]="paymentControl"
          (focus)="resetPayment()"
          (blur)="resetPaymentBlur()"
          [matChipInputFor]="chipListPayment"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
        />
      </mat-chip-list>
    </mat-form-field>

    <mat-autocomplete #autoB="matAutocomplete" [displayWith]="displayFnPayment">
      <mat-option
        style="padding: 0"
        *ngFor="let payment of filteredPayments | async; let i = index"
        [value]="selectedPayments"
      >
        <div
          (click)="optionClickedPayment($event, payment)"
          class="mat-checkbox-container"
        >
          <mat-checkbox
            color="primary"
            class="position-absolute"
            [checked]="payment.selected"
            (change)="toggleSelectionPayment(payment)"
            (click)="$event.stopPropagation()"
          >
          </mat-checkbox>
          <p
            [ngClass]="{
              'mb-0': dataService.desktopView,
              'ml-5': dataService.desktopView && dataService.isSiteLtr(),
              'mr-5': dataService.desktopView && dataService.isSiteRtl()
            }"
          >
            {{ payment.description }}
          </p>
        </div>
      </mat-option>

      <mat-option
        style="padding: 0"
        *ngIf="
          dataService.createEditTeamPage &&
          dataService.desktopView &&
          (this.auth.isAdmin() || this.auth.isOwner())
        "
      >
        <div
          (click)="
            this.dataService.setModal(
              this.dataService.addNewCard,
              'NewPaymentCard',
              ''
            )
          "
          class="mat-checkbox-container"
        >
          <p
            class="blue-text"
            [ngClass]="{
              'mb-0': dataService.desktopView,
              'ml-1': dataService.desktopView && dataService.isSiteLtr(),
              'mr-1': dataService.desktopView && dataService.isSiteRtl()
            }"
            i18n="@@createNewPayment"
          >
            Create new payment
          </p>
        </div>
      </mat-option>
    </mat-autocomplete>
    <div
      class="d-flex justify-content-end mt-3"
      *ngIf="teamId && editTeamPayments"
    >
      <button
        class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
        (click)="cancelEditTeamPayments()"
      >
        Cancel
      </button>
      <button
        class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5"
        [ngClass]="{
          'ml-3': dataService.isSiteLtr(),
          'mr-3': dataService.isSiteRtl(),
          'm-progress': submittingPayment
        }"
        (click)="saveEditTeamPayments()"
        i18n="@@save"
      >
        Save
      </button>
    </div>

    <div
      *ngIf="errorMessagePay"
      class="text-danger smaller pt-3"
      [ngClass]="{ 'text-right': dataService.desktopView }"
    >
      {{ errorMessagePay }}
    </div>
    <input
      type="text"
      i18n-placeholder="@@noAvailablePay"
      placeholder="No available payment methods"
      class="form-control"
      disabled
      *ngIf="
        getAllPaymentFlag &&
        !payments.length &&
        (dataService.mobileView || !dataService.createEditTeamPage)
      "
    />
    <div
      class="form-item form-type-checkbox mt-3 pb-1"
      *ngIf="getAllPaymentFlag && payments.length && selectedPayments.length"
    >
      <ng-container *ngIf="teamId">
        <input
          [ngClass]="{
            'mt-2 input-mobile':
              dataService.mobileView || dataService.createEditTeamPage,
            'ml-4':
              dataService.desktopView &&
              !dataService.createEditTeamPage &&
              dataService.isSiteLtr(),
            'mr-4':
              dataService.desktopView &&
              !dataService.createEditTeamPage &&
              dataService.isSiteRtl(),
            'mr-2': dataService.isSiteLtr(),
            'ml-2': dataService.isSiteRtl()
          }"
          id="restrict-user-edit"
          type="checkbox"
          [checked]="restrictTeam"
          (change)="
            this.restrictTeam = !this.restrictTeam;
            disablePersonalPayments(teamId)
          "
        />
        <label
          [ngClass]="{ 'text-nowrap': dataService.desktopView }"
          for="restrict-user-edit"
          class="gray-text-dark font-16 checkbox-mobile"
          i18n="@@onlyBookAssignedCard"
          >Team members can book using assigned cards only</label
        >
      </ng-container>
      <ng-container *ngIf="!teamId">
        <input
          [ngClass]="{
            'mt-2 input-mobile':
              dataService.mobileView || dataService.createEditTeamPage,
            'ml-4':
              dataService.desktopView &&
              !dataService.createEditTeamPage &&
              dataService.isSiteLtr(),
            'mr-4':
              dataService.desktopView &&
              !dataService.createEditTeamPage &&
              dataService.isSiteRtl(),
            'mr-2': dataService.isSiteLtr(),
            'ml-2': dataService.isSiteRtl()
          }"
          id="restrict-user-team"
          type="checkbox"
          [checked]="restrictTeam"
          (change)="this.restrictTeam = !this.restrictTeam"
        />
        <label
          for="restrict-user-team"
          class="gray-text-dark font-16 checkbox-mobile"
          [ngClass]="{
            'text-nowrap': dataService.desktopView,
            'popup-label': !dataService.createEditTeamPage
          }"
          i18n="@@onlyBookAssignedCard"
          >Team members can book using assigned cards only</label
        >
      </ng-container>
    </div>

    <!---------------- office address in edit team-------------------->
    <app-office-address *ngIf="teamId && this.dataService.teamInfo" [teamId]="teamId"></app-office-address>
    <!---------------- office address in edit team-------------------->


  </div>
  <br /><br />
  <div
    class="d-flex"
    [ngClass]="{
      'justify-content-end': !dataService.createEditTeamPage,
      row: dataService.createEditTeamPage,
      'ml-0': dataService.createEditTeamPage && dataService.isSiteLtr(),
      'mr-0': dataService.createEditTeamPage && dataService.isSiteRtl()
    }"
    *ngIf="dataService.desktopView && teamId === undefined"
  >
    <button
      type="button"
      class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
      [ngClass]="{ 'col-5': dataService.createEditTeamPage }"
      (click)="cancelCreate()"
      i18n="@@cancelBtn"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn blue_btn font-14 sharp-font bold-500 py-0 px-4"
      (click)="onSubmit()"
      [ngClass]="{
        'ml-3': dataService.isSiteLtr(),
        'mr-3': dataService.isSiteRtl(),
        'col-6': dataService.createEditTeamPage,
        'm-progress': submitting
      }"
      i18n="@@createTeam"
    >
      Create Team
    </button>
  </div>
  <div *ngIf="dataService.mobileView && teamId === undefined">
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn blue_btn font-14 sharp-font bold-500 py-1 mb-4 px-5 w-100"
        (click)="onSubmit()"
        [ngClass]="{ 'm-progress': submitting }"
        i18n="@@createTeam"
      >
        Create Team
      </button>
    </div>
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5 w-100"
        (click)="cancelCreate()"
        i18n="@@cancelBtn"
      >
        Cancel
      </button>
    </div>
  </div>
  <div
    *ngIf="errorMessage"
    class="text-danger pt-3"
    [ngClass]="{
      'text-right': dataService.desktopView && !dataService.createEditTeamPage
    }"
  >
    {{ errorMessage }}
  </div>
</div>

<div class="justify-content-end create-team">
  <div
    (click)="mobileFocus()"
    [ngClass]="{
      'in action-popup':
        showPolicyAutoCompletePopUp ||
        showMembersAutoCompletePopUp ||
        showManagerAutoCompletePopUp ||
        showPaymentsAutoCompletePopUp
    }"
  >
    <div
      [ngClass]="{
        header:
          showPolicyAutoCompletePopUp ||
          showMembersAutoCompletePopUp ||
          showManagerAutoCompletePopUp ||
          showPaymentsAutoCompletePopUp
      }"
    >
      <div
        *ngIf="
          showPolicyAutoCompletePopUp ||
          showMembersAutoCompletePopUp ||
          showManagerAutoCompletePopUp ||
          showPaymentsAutoCompletePopUp
        "
      >
        <div
          *ngIf="
            showPolicyAutoCompletePopUp ||
            showMembersAutoCompletePopUp ||
            showManagerAutoCompletePopUp ||
            showPaymentsAutoCompletePopUp
          "
        >
          <div
            class="info"
            [ngClass]="{
              'pay-and-member':
                showMembersAutoCompletePopUp || showPaymentsAutoCompletePopUp
            }"
          >
            <span *ngIf="showPolicyAutoCompletePopUp" i18n="@@policy"
              >Policy</span
            >
            <span *ngIf="showMembersAutoCompletePopUp" i18n="@@members"
              >Members</span
            >
            <span *ngIf="showManagerAutoCompletePopUp" i18n="@@manager"
              >Manager</span
            >
            <span *ngIf="showPaymentsAutoCompletePopUp" i18n="@@paymentMethods"
              >Payment Methods</span
            >
          </div>
          <div
            class="done"
            *ngIf="
              showMembersAutoCompletePopUp || showPaymentsAutoCompletePopUp
            "
          >
            <span
              *ngIf="showMembersAutoCompletePopUp"
              (click)="saveSelectedUsers(); hidePopUp()"
              i18n="@@done"
              >Done</span
            >
            <span
              *ngIf="showPaymentsAutoCompletePopUp"
              (click)="saveSelectedPayments(); hidePopUp()"
              i18n="@@done"
              >Done</span
            >
          </div>
          <div
            *ngIf="showPaymentsAutoCompletePopUp"
            class="close"
            (click)="cancelEditTempPayments(); hidePopUp()"
            i18n="@@cancelBtn"
          >
            Cancel
          </div>
          <div
            *ngIf="showMembersAutoCompletePopUp"
            class="close"
            (click)="cancelEditTempUsers(); hidePopUp()"
            i18n="@@cancelBtn"
          >
            Cancel
          </div>
        </div>
      </div>

      <div
        class="position-relative mobile-search-field"
        *ngIf="showMembersAutoCompletePopUp"
      >
        <div class="form-group mb-3">
          <mat-form-field
            class="w-100 chips-container mobile-header-chips border rounded"
          >
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let user of mobileTempSelectedUsers">
                {{ user.description }}
                <i
                  class="icon-danger placeholder-text"
                  [ngClass]="{
                    'ml-2': dataService.isSiteLtr(),
                    'mr-3': dataService.isSiteRtl()
                  }"
                  (click)="toggleSelectionTemp(user)"
                ></i>
              </mat-chip>
              <input
                #userInput
                id="userInput"
                class="mobile-input mx-0 my-0"
                [ngClass]="{
                  'pl-4': dataService.isSiteLtr(),
                  'pr-4': dataService.isSiteRtl()
                }"
                type="text"
                placeholder="Select Users"
                aria-label="Select Users"
                matInput
                [matAutocomplete]="auto"
                [formControl]="userControl"
                (focus)="reset()"
                (blur)="resetBlur()"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
              />
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option
                style="padding: 0"
                *ngFor="let user of filteredUsers | async; let i = index"
                [value]="mobileTempSelectedUsers"
              >
                <div
                  *ngIf="
                    user.description !== 'Unassigned' &&
                    user.description !== this.NoManagerLocalized
                  "
                  (click)="optionClickedMobile($event, user)"
                  class="mat-checkbox-container"
                >
                  <mat-checkbox
                    color="primary"
                    class="position-absolute"
                    [checked]="user.selected"
                    (change)="toggleSelectionTemp(user)"
                    (click)="$event.stopPropagation()"
                  >
                  </mat-checkbox>
                  {{ user.description }}<br /><span
                    class="font-12 gray-text"
                    *ngIf="user.teamName"
                    >{{ user.teamName }}</span
                  >
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div
        class="position-relative mobile-search-field"
        *ngIf="showManagerAutoCompletePopUp"
      >
        <div class="form-group mb-3">
          <mat-form-field class="w-100 chips-container border rounded">
            <input
              class="bg-white min-height mx-0 my-0"
              [ngClass]="{
                'pl-4': dataService.isSiteLtr(),
                'pr-4': dataService.isSiteRtl()
              }"
              #managerInput
              id="managerInput"
              type="text"
              matInput
              [formControl]="managerControl"
              [matAutocomplete]="autoManager"
              (blur)="resetManager()"
            />
            <mat-autocomplete
              #autoManager="matAutocomplete"
              [displayWith]="displayFn"
            >
              <mat-option
                style="padding: 0"
                *ngFor="let user of filteredManager | async"
                [value]="user"
              >
                <div
                  (click)="optionClickedManager($event, user)"
                  class="mat-checkbox-container"
                >
                  {{ user.description }}<br /><span
                    class="font-12 gray-text"
                    *ngIf="user.teamName"
                    >{{ user.teamName }}</span
                  >
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div
        class="position-relative mobile-search-field"
        *ngIf="showPolicyAutoCompletePopUp"
      >
        <div class="form-group mb-3">
          <mat-form-field class="w-100 chips-container border rounded">
            <input
              class="bg-white min-height mx-0 my-0"
              [ngClass]="{
                'pl-4': dataService.isSiteLtr(),
                'pr-4': dataService.isSiteRtl()
              }"
              type="text"
              matInput
              #policyInput
              id="policyInput"
              [formControl]="policyControl"
              [matAutocomplete]="autoPolicy"
              (blur)="resetPolicy()"
            />
            <mat-autocomplete
              #autoPolicy="matAutocomplete"
              [displayWith]="displayFnPolicy"
            >
              <mat-option
                style="padding: 0"
                *ngFor="let policy of filteredPolicies | async"
                [value]="policy"
              >
                <div
                  (click)="optionClickedPolicy($event, policy)"
                  class="mat-checkbox-container"
                >
                  {{ policy.name }}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div
        class="position-relative mobile-search-field"
        *ngIf="showPaymentsAutoCompletePopUp"
      >
        <div class="form-group mb-3">
          <mat-form-field
            class="w-100 chips-container mobile-header-chips border rounded"
          >
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let payment of mobileTempSelectedPayments">
                {{ payment.description }}
                <i
                  class="icon-danger placeholder-text"
                  [ngClass]="{
                    'ml-2': dataService.isSiteLtr(),
                    'mr-2': dataService.isSiteRtl()
                  }"
                  (click)="toggleSelectionTempPayment(payment)"
                ></i>
              </mat-chip>
              <input
                #paymentInput
                id="paymentInput"
                class="mobile-input mx-0 my-0"
                [ngClass]="{
                  'pl-4': dataService.isSiteLtr(),
                  'pr-4': dataService.isSiteRtl()
                }"
                type="text"
                placeholder="Select Users"
                aria-label="Select Payment Methods"
                matInput
                [matAutocomplete]="autoPayment"
                [formControl]="paymentControl"
                (focus)="resetPayment()"
                (blur)="resetPaymentBlur()"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
              />
            </mat-chip-list>
            <mat-autocomplete
              #autoPayment="matAutocomplete"
              [displayWith]="displayFnPayment"
            >
              <mat-option
                style="padding: 0"
                *ngFor="let payment of filteredPayments | async; let i = index"
                [value]="mobileTempSelectedPayments"
              >
                <div
                  (click)="optionClickedMobilePayment($event, payment)"
                  class="mat-checkbox-container"
                >
                  <mat-checkbox
                    color="primary"
                    class="position-absolute"
                    [checked]="payment.selected"
                    (change)="toggleSelectionTempPayment(payment)"
                    (click)="$event.stopPropagation()"
                  >
                  </mat-checkbox>
                  {{ payment.description }}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="team-sheet main-bottom-sheet bg-white animate-C px-3 py-4"
  [ngClass]="{ active: showTeamNameAutoCompletePopUp }"
>
  <p class="gray-primaray-text font-16" i18n="@@teamName">Team name</p>
  <form name="createTeamForm mb-5" [formGroup]="createTeamForm">
    <div
      class="form-group with-icon position-relative form-type-textfield form-name-email"
    >
      <input
        id="nameInput"
        #nameInput
        type="text"
        name="teamName"
        class="form-control"
        formControlName="teamName"
        autocomplete="off"
        [ngClass]="{ 'is-invalid': submitted && c.teamName.errors }"
      />
    </div>
    <div
      *ngIf="submitted && c.teamName.errors"
      class="text-danger font-12 pb-3"
    >
      <div *ngIf="c.teamName.errors.required" i18n="@@teamNameRequired">
        Team name is required
      </div>
    </div>
  </form>
  <button
    class="btn-outline-blue btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14 mt-5"
    (click)="editTeamNameDone()"
    i18n="@@edit"
  >
    Update
  </button>
  <button
    class="btn-outline-gray btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14"
    (click)="hidePopUp()"
    i18n="@@cancelBtn"
  >
    Cancel
  </button>
</div>
<div
  *ngIf="showTeamNameAutoCompletePopUp"
  (click)="hidePopUp()"
  class="modal-backdrop show"
></div>
