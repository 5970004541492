import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-update-billing-card',
  templateUrl: './update-billing-card.component.html',
  styleUrls: ['./update-billing-card.component.scss']
})
export class UpdateBillingCardComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, public dataService: DataService, public router: Router, public auth: AuthServiceMain) { }
  editAliasForm: FormGroup;
  submitted = false;
  submitting = false;
  primary;
  errorMessage;
  data;
  paymentObject: {};
  isPrimary;
  ngOnInit(): void {

    this.editAliasForm = this.formBuilder.group({
      alias: [''],
      primary: [''],
    }, {});
    if (this.dataService.companyBillingObjForEdit.oldAlias) {
      this.editAliasForm.get('alias').setValue(this.dataService.companyBillingObjForEdit.oldAlias);
    }
  }
  get f() {
    return this.editAliasForm.controls;
  }
  submitForm() {
    this.submitting = true;
    this.paymentObject = {
      paymentGatewayProvider: this.dataService.companyBillingObjForEdit.paymentGatewayProvider,
      id: this.dataService.companyBillingObjForEdit.id,
      alias: this.editAliasForm.controls.alias.value,
      primary: this.isPrimary
    };
    this.dataService.updateCompanyBilling(this.paymentObject).subscribe(

      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.listPaymentService();
          this.dataService.modalOf();
          this.dataService.BottomSheetOf();
          this.submitting = false;
        } else {
          this.errorMessage = this.data.message;
          this.submitting = false;
        }
      },
      error => {
        this.errorMessage = environment.messages.error;
        this.submitting = false;
      }


    );
  }
}
