<div [ngClass]="{'container': dataService.mobileView}">
<div class="share-box-center mx-auto d-block py-4 px-2">
  <form name="userAddressForm">
    <p class="mb-1" i18n="@@shareViaEmail"
       *ngIf="(dataService.mobileView && dataService.BottomSheetName !== 'companySendBookingVoucher') ||
dataService.desktopView && dataService.modalName !== 'companySendBookingVoucher'">We will share the hotel details via email</p>
    <p class="mb-1" i18n="@@shareViaEmailHotelVoucher"
       *ngIf="(dataService.mobileView && dataService.BottomSheetName === 'companySendBookingVoucher') ||
dataService.desktopView && dataService.modalName === 'companySendBookingVoucher'">Enter email addresses or select from the list</p>
      <div [ngClass]="{ 'border-red': submitted && this.selectedUsers.length === 0 }" (click)="placeholder = false">

        <div class="create-team">
          <mat-form-field class="w-100 chips-container border rounded" (click)="showMembersPopUp();" *ngIf="users.length !==0">
            <mat-chip-list #chipList >
              <mat-chip (click)="showMembersPopUp()"
                        *ngFor="let user of selectedUsers">
                {{ user.description }}
                <!-- <mat-icon matChipRemove >cancel</mat-icon>-->
                <i class="icon-danger placeholder-text" [ngClass]="{'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}" *ngIf="dataService.desktopView" (click)="toggleSelection(user)"></i>
              </mat-chip>
              <input *ngIf="this.dataService.desktopView" #userInput class="min-height mx-0 my-0" id="userInput"
                     [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                     type="text" placeholder="Select Users" aria-label="Select Users"
                     matInput [matAutocomplete]="auto" [formControl]="userControl"  (focus)="reset()" (blur)="resetBlur()"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="addOnBlur"
                     (matChipInputTokenEnd)="add($event)"
              />
            </mat-chip-list>
            <!-- <input class="border" type="text" placeholder="Select Users" aria-label="Select Users" matInput [matAutocomplete]="auto" [formControl]="userControl">-->
            <!--  <mat-hint>Enter text to find users by name</mat-hint>-->
          </mat-form-field>



          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option style="padding: 0" *ngFor="let user of filteredUsers | async" [value]="selectedUsers">
              <div (click)="optionClicked($event, user)" class="mat-checkbox-container">
                <mat-checkbox color="primary" class="position-absolute" [checked]="user.selected" (change)="toggleSelection(user)" (click)="$event.stopPropagation()">
                </mat-checkbox>
                <p [ngClass]="{'mb-0': dataService.desktopView, 'ml-5': dataService.isSiteLtr() && dataService.desktopView, 'mr-5': dataService.isSiteRtl() && dataService.desktopView}">
                  {{ user.description }}<br/><span class="font-12 gray-text" *ngIf="user.email">{{user.email}}</span>
                </p>
              </div>
            </mat-option>
          </mat-autocomplete>
        </div>
     <!--    <span class="placeholder-text position-absolute gray-text" *ngIf="placeholder" [ngClass]="{'text-right': dataService.isSiteRtl()}">Enter email or name</span>-->
      </div>
      <div class="mt-5 pt-lg-3 d-flex pb-5 pb-lg-0 mb-lg-0">
        <button (click)="this.dataService.modalOf(); this.dataService.BottomSheetOf()" class="rounded btn-outline-gray text-center col-4 mt-3 mt-lg-5 font-14 sharp-font gray-text-dark bold-500"
                [ngClass]="{'mr-3 ml-auto': dataService.isSiteLtr(), 'ml-3 mr-auto': dataService.isSiteRtl()}" i18n="@@cancelBtn">Cancel</button>
        <button (click)="sendEmail()" class="blue_btn text-center col-4 mt-3 mt-lg-5 sharp-font font-14 bold-500"
                [ngClass]="{'m-progress': submitting }" [disabled]="selectedUsers.length === 0" i18n="@@share"
                *ngIf="(dataService.mobileView && dataService.BottomSheetName !== 'companySendBookingVoucher') ||
dataService.desktopView && dataService.modalName !== 'companySendBookingVoucher'">Share</button>
        <button (click)="sendEmailVocher()" class="blue_btn text-center col-4 mt-3 mt-lg-5 sharp-font font-14 bold-500"
                [ngClass]="{'m-progress': submitting }" [disabled]="selectedUsers.length === 0" i18n="@@share"
                *ngIf="(dataService.mobileView && dataService.BottomSheetName === 'companySendBookingVoucher') ||
dataService.desktopView && dataService.modalName === 'companySendBookingVoucher'">Share</button>

      </div>

  </form>
  <div *ngIf="submitted" >
    <div *ngIf="this.selectedUsers.length === 0" class="empty-emails">Emails are required</div>
  </div>

 <!-- <div class="container  share-link pt-5">
    <div class="row"><span class="share-a-link-span sharp-font bolder">Share a link</span></div>
    <div class="row">

      <div class="col-md-8 rounded copy border d-flex justify-content-between">

        <input class="form-control pl-0" id="code-to-copy" type="text" value="hotelLink" [ngModel]="hotelLink" #url readonly >

        <p class="d-none d-lg-block pt-3"><span class="mr-3">|</span><a (click)="copy(url)">{{copyText}}</a></p>

      </div>

      <div class="col-md-4 social-buttons text-center text-lg-left pt-3">
        <p class="text-center mb-2 mt-3 d-block d-lg-none"><a class="px-3 pb-3 border-bottom" (click)="copy(url)">{{copyText}}</a></p>
        <br class="d-block d-lg-none"/>
        <a class="font-24" (click)="openPopUp()"><i class="icon-twitter mt-3 mr-4 blue-text"></i></a>

        <a class="font-24" target="_blank" id="fb-btn-hotel" ><i class="icon-facebook2 mt-3 primaray-text" (click)="facebookShare()"></i></a>
      </div>
    </div>
  </div>-->

</div>

</div>

<div class="justify-content-end create-team" >

  <div class="" [ngClass]="{'in action-popup':showMembersAutoCompletePopUp}">
    <div [ngClass]="{'header':showMembersAutoCompletePopUp}" >
      <div *ngIf="showMembersAutoCompletePopUp">
        <div *ngIf="showMembersAutoCompletePopUp">
          <div class="info" [ngClass]="{'pay-and-member' : showMembersAutoCompletePopUp}">
            <span *ngIf="showMembersAutoCompletePopUp">Members</span>
          </div>
          <div class="done" *ngIf="showMembersAutoCompletePopUp">
            <span *ngIf="showMembersAutoCompletePopUp" (click)="saveSelectedUsers(); hidePopUp();">Done</span>
          </div>
          <div class="close" (click)="hidePopUp(); mobileTempSelectedUsers = []">
            Cancel
          </div>
        </div>
      </div>


      <div class="position-relative mobile-search-field" *ngIf="showMembersAutoCompletePopUp">
        <div class="form-group mb-3">
          <mat-form-field class="w-100 chips-container mobile-header-chips border rounded">
            <mat-chip-list #chipList>
              <mat-chip
                *ngFor="let user of mobileTempSelectedUsers">
                {{ user.description }}
                <i class="icon-danger placeholder-text" [ngClass]="{'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}" (click)="toggleSelectionTemp(user)"></i>
              </mat-chip>
              <input #userInput id="userInput" class=" mobile-input mx-0 my-0"
                     [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                     type="text" placeholder="Select Users" aria-label="Select Users"
                     matInput [matAutocomplete]="auto" [formControl]="userControl"
                     (focus)="reset()" (blur)="resetBlur()"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="addOnBlur"
                     (matChipInputTokenEnd)="add($event)"
              />
            </mat-chip-list>

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option style="padding: 0" *ngFor="let user of filteredUsers | async" [value]="mobileTempSelectedUsers">
                <div (click)="optionClickedMobile($event, user)" class="mat-checkbox-container">
                  <mat-checkbox color="primary" class="position-absolute" [checked]="user.selected" (change)="toggleSelectionTemp(user)" (click)="$event.stopPropagation()">
                  </mat-checkbox>
                  {{ user.description }}<br/><span class="font-12 gray-text" *ngIf="user.email">{{user.email}}</span>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </div>
      </div>



    </div>

    <!--  <div class="body" *ngIf="showMembersAutoCompletePopUp === true ">
        <div class="position-absolute done-btn">
          <button class="blue_btn btn py-1 px-5 mb-3 w-100 font-14 sharp-font bold-500" (click)="saveSelectedUsers(); hidePopUp();">Done </button>
        </div>
      </div>-->


  </div>
</div>
