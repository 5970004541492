<div *ngIf="this.dataService.mobileView">

  <div class="gray-pass-bg border-0 py-3 mb-5" [ngClass]="{'pl-3': dataService.isSiteLtr(), 'pr-3': dataService.isSiteRtl()}" *ngIf="this.auth.companyInfo && dataService.companyUserInfo">
  <!--  <a class="font-18 blue-text font-18 primaray-text font-weight-bold" *ngIf="this.auth.companyInfo"
       (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);">Settings</a> <span class="font-18 gray-text-dark"> / </span>-->
    <a class="font-18 blue-text font-18 primaray-text font-weight-bold"
       (click)="this.router.navigate(['/members']);" i18n="@@members">Members</a> <span class="font-18 gray-text-dark"> / </span>
    <a class="font-18 blue-text font-18 primaray-text font-weight-bold"
       (click)="this.router.navigate(['/members/' + this.userId]);">{{dataService.companyUserInfo.fullName}}</a>  <span class="font-18 gray-text-dark"> / </span>
    <span class="font-18 gray-text-dark font-weight-bold" i18n="@@deActivate">Deactivate</span>
  </div>

</div>
<div class="container pb-3 mb-5 mb-lg-0">
<div class="justify-content-center">
  <div class="d-flex mb-4">
    <i class="icon-warning-msg pt-1" [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
    <p class="gray-text-dark font-16 line-height-text">
      <span><ng-container i18n="@@deactivateMsg1">Are you sure you want to deactivate</ng-container>
        <span class="font-weight-bold" *ngIf="this.dataService.companyUserInfo && this.dataService.companyUserInfo.fullName"> {{this.dataService.companyUserInfo.fullName}}</span>
        <span class="font-weight-bold" *ngIf="this.dataService.companyUserInfo && !this.dataService.companyUserInfo.fullName"> {{this.dataService.companyUserInfo.email}}</span>
        <ng-container *ngIf="dataService.isSiteLtr()">?</ng-container><ng-container *ngIf="dataService.isSiteRtl()">؟</ng-container></span>
      <br/>
    <span class="gray-text"><ng-container i18n="@@deactivateMsg2"> By deactivating a member he will not be able to use your company’s account to book and save.</ng-container></span></p>

  </div>

  <div class="container" [ngClass]="{'w-75 ml-0' : this.dataService.desktopView && dataService.isSiteLtr(), 'w-75 mr-0' : this.dataService.desktopView && dataService.isSiteRtl()}">
  <form [formGroup]="userDeactivateForm" name="userDeactivateForm">

           <p class="font-weight-bold font-14 sharp-font" i18n="@@deactivateMsg3">Help us to understand and improve our services</p>

      <div class="form-group font-16 mb-0 gray-text-dark" *ngFor="let reason of reasonsArray">
        <input [ngClass]="{'mt-2 input-mobile': dataService.mobileView, 'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}" type="radio" value="{{reason.id}}" id="{{reason.id}}" name="reasons"  formControlName="reasons"/>
        <label  [ngClass]="{'radio-mobile': dataService.mobileView}" for="{{reason.id}}">{{reason.text}}</label>
      </div>
    <div *ngIf="submitted && ctrl.reasons.errors" class="text-danger">
      <div *ngIf="ctrl.reasons.errors.required" i18n="@@reasonRequired">Reason is required</div>
    </div>

    <div class="form-group mt-4 mb-5">
          <textarea name="improveService" id="improveService" formControlName="improveService" class="pt-3 form-control"
                   i18n-placeholder="@@deactivatePlaceholder" placeholder="Give us more details to help improve our service." rows="4"></textarea>
    </div>
  </form>

  </div>
  <div class="justify-content-end" [ngClass]="{'d-flex': dataService.desktopView}">
    <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" [ngClass]="{'w-100 mb-3': dataService.mobileView}" (click)="cancelDeactivate()" i18n="@@cancelBtn">Cancel </button>
    <button class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5" [ngClass]="{'m-progress': submitting, 'w-100': dataService.mobileView, 'ml-2': dataService.desktopView && dataService.isSiteLtr(), 'mr-2': dataService.desktopView && dataService.isSiteRtl()}"
            (click)="deactivateUser()" i18n="@@deActivate">Deactivate</button>
  </div>
  <div class="text-danger mt-3" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">{{this.errorMsg }}</div>
</div>
</div>
