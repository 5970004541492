
<div *ngIf="!this.dataService.bookingSubmit && !this.dataService.flightBookingSubmit" (click)="onSideNavToggle(); this.dataService.initialMarginValue = false;"
     class="toggle-btn mx-auto mb-3 dashboard-icon-container-btn-in-middle text-center arrow-left-right d-flex justify-content-center align-items-center"
      [ngClass]="{'prevent-pointer-event' :!this.dataService.sideNavState}"
     [@animateText]="!this.dataService.linkText ? 'hide' : 'show'">
  <i [ngClass]="{'icon-double-arrows-left': dataService.isSiteLtr(), 'icon-double-arrows-right': dataService.isSiteRtl()}" class="font-12" ></i>
</div>

<div  class="dropdown-menu header-menu shadow-sm show" [ngClass]="{'text-right': dataService.isSiteRtl()}"  *ngIf="dataService.dropDowmBookingForm">
  <div >
    <a  (click)="dataService.dropDowmBookingForm = false" class="dropdown-item gray-text-dark font-16"> <span>  <i class="icon-bed" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="!router.url.includes('flights')"></i><i class="icon-flight-item" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="router.url.includes('flights')"></i><ng-container i18n="@@completeBooking">Complete your booking</ng-container></span> </a>
  </div>
  <div >
    <a (click)="this.dataService.goBackBookingForm();" class="dropdown-item gray-text-dark font-16"><span><i [ngClass]="{'mr-2 icon-arrow-left2': dataService.isSiteLtr(), 'ml-2 icon-arrow-right2': dataService.isSiteRtl()}"></i><ng-container i18n="@@selectOther">Select other</ng-container>&nbsp;<span *ngIf="!router.url.includes('flights')" i18n="@@anotherRoom"> room </span><span *ngIf="router.url.includes('flights')" i18n="@@anotherFlight"> flight </span> </span> </a>
  </div>

  <div>
    <a href="tel:8887070618" class="dropdown-item gray-text-dark font-16"><span class="small"><i class="icon-phone" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"></i><ng-container i18n="@@needHelpCall">Need help? Call us at</ng-container>&nbsp;<span dir="ltr">(888) 707-0618</span></span> </a>
  </div>
</div>
