import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {DataService} from "../../services/data.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-slack-footer',
  templateUrl: './slack-footer.component.html',
  styleUrls: ['./slack-footer.component.scss']
})
export class SlackFooterComponent implements OnInit {

  constructor(public router: Router,  public  Service: DataService) { }
  cYear;
  env = environment;
  ngOnInit(): void {
    this.cYear =   new Date().getFullYear()
  }

}
