import {Component, ElementRef, OnInit} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import { SidenavService} from '../../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../../workspace-dashboard/animations/animations';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {VariablesService} from '../../../../services/vars.service';
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
  animations: [ onMainContentChange ],
  host: {
    '(body:click)': 'onClickBody($event)',
  },
})
export class AccountInfoComponent implements OnInit {

  constructor(private eref: ElementRef, private titleService: Title, public dataService: DataService, private sidenavService: SidenavService,
               public router: Router, public auth: AuthServiceMain, public vars: VariablesService) {
    this.titleService.setTitle('Account info | SafarHub');
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
  notSet = $localize`:@@notSet:Not set`;
  selectedIndustry;
  showActionsAddMain = false;
  data;
  //getAllAddresses;
  hasOtherAddressesFlag = false;
  showActions = false;
  selectedIndex;
  ngOnInit(): void {
    this.dataService.getAllAddressesFun();
    if (this.auth.companyInfo.industry) {
      const selected = this.vars.industries.filter(item => item.id === this.auth.companyInfo.industry);
      this.selectedIndustry = selected[0]?.name;
    }
  }
  hasOtherAddresses(){
    this.hasOtherAddressesFlag = false;
    if(this.dataService.getAllAddresses) {
      this.dataService.getAllAddresses.forEach((element) => {
        if (element.id >= 2) {
          this.hasOtherAddressesFlag = true;
        }
      });
    }
    return this.hasOtherAddressesFlag;
  }
  getSelectedIndustry(){
    const selected = this.vars.industries.filter(item => item.id === this.auth.companyInfo.industry);
    this.selectedIndustry = selected[0]?.name;
    return this.selectedIndustry;
  }
  editCompanyInfo(){
    if (this.dataService.desktopView) {
      this.dataService.setRightSideDrawer( 'editCompanyInfo', '');
    } else {
      this.dataService.setBottomSheet('editCompanyInfo', '');
    }
  }
  addEditMainAddress(){
    this.dataService.rightSideDrawerData = null;
    this.dataService.BottomSheetData = null;
    if (this.dataService.desktopView) {
      this.dataService.setRightSideDrawer( 'editCompanyMainAddress', '');
    } else {
      this.dataService.setBottomSheet('editCompanyMainAddress', '');
    }
  }
  addOfficeAnotherAddress(){
    if (this.dataService.desktopView) {
      this.dataService.setRightSideDrawer( 'addCompanyOtherAddress', '');
    } else {
      this.dataService.setBottomSheet('addCompanyOtherAddress', '');
    }
  }
  editAnotherAddress(address){
    this.dataService.rightSideDrawerData = null;
    this.dataService.BottomSheetData = null;
    if (this.dataService.desktopView) {
      this.dataService.setRightSideDrawer( 'editCompanyOtherAddress', address);
    } else {
      this.dataService.setBottomSheet('editCompanyOtherAddress',  address);
    }
  }
  deleteAddress(address){
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@deleteAddress:Delete Address`, 'deleteAddress', address);
    } else {
      this.dataService.setBottomSheet('deleteAddress', address);
    }
  }
  onClickBody(event) {
    if (this.eref.nativeElement.querySelector('.add-other-drop-down' + this.selectedIndex) && !this.eref.nativeElement.querySelector('.add-other-drop-down' + this.selectedIndex).contains(event.target) ){
      this.showActions = false;
    }
    if (this.eref.nativeElement.querySelector('.add-drop-down') && !this.eref.nativeElement.querySelector('.add-drop-down').contains(event.target) ){
      this.showActionsAddMain = false;
    }
  }
  editPreferences(data) {
    if (this.dataService.desktopView) {
      this.dataService.setRightSideDrawer("companyPreferences", data ? data : '');
    } else {
      this.dataService.setBottomSheet("companyPreferences", data ? data : '');
    }
  }
}
