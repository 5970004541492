import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {AuthServiceMain} from '../../auth/auth.service';
import {Router} from '@angular/router';
import {Title, Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(public logedIn: AuthServiceMain, public Service: DataService, private titleService:Title
    , public router: Router, private meta: Meta) {
    this.titleService.setTitle('About SafarHub.com | SafarHub');
    this.meta.updateTag({ name: 'description', content: 'SafarHub saves travelers time and money by providing easy access to guaranteed unpublished hotel rates for millions of rooms and suites worldwide.' });

  }
  ngOnInit() {
  }

}
