import { Component, OnDestroy, OnInit } from '@angular/core';
import { CostManagementService } from 'src/app/services/cost-management.service';
import { DataService } from 'src/app/services/data.service';
import { CostCenter } from 'src/app/shared/model/cost-management/cost-center';
import { Label } from 'src/app/shared/model/cost-management/label';
import { environment } from 'src/environments/environment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CostBookingStateService } from 'src/app/services/states/cost-booking-state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cost-reporting-manage',
  templateUrl: './cost-reporting-manage.component.html',
  styleUrls: ['./cost-reporting-manage.component.scss']
})
export class CostReportingManageComponent implements OnInit, OnDestroy {
  loader: boolean = false;
  costCenters: CostCenter[] = [];
  labels: Label[] = [];
  selectedCostCenters: CostCenter[] = [];
  selectedLabels: Label[] = [];
  errorMsg: string;
  costCentersDropdownSettings: IDropdownSettings = {};
  labelsDropdownSettings: IDropdownSettings = {};
  isValidCenter: boolean = true;
  isValidLabels: boolean = true;
  isSubmitted: boolean = false;
  isCostCenterMandatory: boolean = false;
  isLabelMandatory: boolean = false;
  bookingState: Subscription;

  constructor(public Service: DataService, public costManagementService: CostManagementService,
    public costBookingState: CostBookingStateService) {
  }

  ngOnInit() {
    this.getAsyncData();
    this.prepareDropDownSettings();
    this.initBookingState();
  }

  initBookingState() {
    this.bookingState = this.costBookingState.isSubmitted$.subscribe((value) => {
      this.isSubmitted = value;
      this.isValidCenter = !this.isSubmitted || !this.isCostCenterMandatory || (this.isSubmitted && this.isCostCenterMandatory && this.selectedCostCenters?.length > 0);
      this.isValidLabels = !this.isSubmitted || !this.isLabelMandatory || (this.isSubmitted && this.isLabelMandatory && this.selectedLabels?.length > 0);  
    });
  }

  ngOnDestroy() {
    this.bookingState.unsubscribe()
  }

  getAsyncData() {
    this.loader = true;
    Promise.all([this.getMandatory(), this.getActiveCostCenters(), this.getActiveLabels()])
      .then((result: any) => {
        this.isCostCenterMandatory = result[0]?.data ? result[0]?.data?.costCenterMandatoryOnBookingsAndApprovals : false;
        this.isLabelMandatory = result[0]?.data ? result[0]?.data?.labelMandatoryOnBookingsAndApprovals : false;
        this.costCenters = result[0]?.data ? result[1].data : [];
        this.labels = result[0]?.data ? result[2].data : [];
        this.costBookingState.setIsCostCenterMandatory(this.isCostCenterMandatory);
        this.costBookingState.setIsLabelMandatory(this.isLabelMandatory);
        this.costBookingState.setCostCenters(this.selectedCostCenters);
        this.costBookingState.setLabels(this.selectedLabels);
        if (result[0].errorCode) {
          this.handleFailed(result[0].message);
        }
        if (result[1].errorCode) {
          this.handleFailed(result[1].message);
        }
        if (result[2].errorCode) {
          this.handleFailed(result[2].message);
        }
      })
      .catch((error) => {
        this.handleFailed(error.message);
      })
      .finally(() => {
        this.loader = false;
      });
  }

  getMandatory() {
    return this.costManagementService.getMandatory().toPromise();
  }

  getActiveCostCenters() {
    return this.costManagementService.getActiveCostCenters().toPromise();
  }

  getActiveLabels() {
    return this.costManagementService.getActiveLabels().toPromise();
  }

  handleFailed(error) {
    this.errorMsg = error ?? environment.messages.error;
  }

  costCenterChange() {
    this.isValidCenter = !this.isSubmitted || !this.isCostCenterMandatory || (this.isSubmitted && this.isCostCenterMandatory && this.selectedCostCenters?.length > 0);
    this.costBookingState.setCostCenters(this.selectedCostCenters);
  }

  labelsChange() {
    this.isValidLabels = !this.isSubmitted || !this.isLabelMandatory || (this.isSubmitted && this.isLabelMandatory && this.selectedLabels?.length > 0);
    this.costBookingState.setLabels(this.selectedLabels);
  }

  prepareDropDownSettings() {
    this.costCentersDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      limitSelection: 5,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      searchPlaceholderText: $localize`:@@searchBtn:Search`,
      noFilteredDataAvailablePlaceholderText: $localize`:@@noFilteredData:No Filtered data available`,
    };

    this.labelsDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 5,
      limitSelection: 5,
      allowSearchFilter: true,
      searchPlaceholderText: $localize`:@@searchBtn:Search`,
      noFilteredDataAvailablePlaceholderText: $localize`:@@noFilteredData:No Filtered data available`,
    };
  }
}