<div class="manage-booking container mt-4">
  <div class="d-flex">
    <h2 class="green-text bolder py-2"
      [ngClass]="{'mr-auto pr-2': Service.isSiteLtr(), 'ml-auto pl-2': Service.isSiteRtl(), 'font-24': Service.desktopView, 'font-14': Service.mobileView}">
      <i class="icon-success font-24-to-18"
        [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}"></i>&nbsp;<ng-container
        i18n="@@successfullyBookedFlight">You have successfully booked your flight!</ng-container>
    </h2>
    <div>
      <button class="btn btn-link karla-font font-16"
        [ngClass]="{'pr-2': Service.isSiteLtr(), 'pl-2': Service.isSiteRtl()}"
        (click)="Service.showPrint = true; print()" *ngIf="this.Service.desktopView" i18n="@@print">Print</button>
      <button (click)="openSendBookingVoucherPopUp()" class="btn btn-link karla-font font-16"
        *ngIf="this.Service.desktopView" i18n="@@email"> Email</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 custom-col8" [ngClass]="{'px-0': Service.mobileView}" *ngIf="VoucherData">
      <div [ngClass]="{'container': Service.mobileView}">
        <ng-container *ngIf="noTicketNo">
          <p class="font-16 mb-4" i18n="@@voucherMsg1">
            Your flight has been booked successfully and the itinerary is ticketing in progress by the airline.
          </p>
          <p class="font-16" i18n="@@voucherMsg2">
            No need to contact us at the moment, you will receive an email with all details within few hours with the
            ticket number.
          </p>
        </ng-container>
        <p *ngIf="Service.mobileView" class="blue-text font-weight-bold font-16 karla-font pt-3 pb-3"
          (click)="shareByEmailMobile()">
          <i class="icon-share-email"></i>&nbsp;<ng-container i18n="@@shareByEmail">Share by email</ng-container>
        </p>
      </div>
      <div class="table-header pt-4 pb-2 mb-5"
        [ngClass]="{'rounded border': Service.desktopView, 'border-top border-bottom': Service.mobileView}">

        <p class="gray-primaray-text font-16 mb-0" i18n="@@confirmationNumber">Confirmation number</p>
        <p class="gray-text-dark font-14 mb-5">{{this.VoucherData.details.data.hrsReferenceNumber}}</p>
        <div class="mb-5">
          <ng-container *ngIf="VoucherData.details.data.bookingResult.data.associatedRecords">
            <p class="gray-primaray-text font-16 mb-0" i18n="@@airlinesConfirmationNumbers">Airlines confirmation numbers</p>
            <ng-container *ngFor="let airline of VoucherData.details.data.bookingResult.data.associatedRecords">
              <p class="gray-text-dark font-14 mb-0">{{airline.reference}}</p>
            </ng-container>
          </ng-container>
        </div>
        <div>
          <p class="gray-primaray-text font-16 mb-0" i18n="@@theTraverlers">Travelers</p>

            <ng-container *ngFor="let traveler of VoucherData.details.data.bookingResult.data.travelers">
              <p class="gray-text-dark font-14 mb-0">{{traveler.name.firstName}} {{traveler.name.lastName}}
                <span *ngIf="traveler.type"
                  class="font-14 text-capitalize gray-text">- {{traveler.type.toLowerCase()}}</span>
              </p>
              <p class="gray-text font-14 mb-0" *ngIf="traveler.eTicketNumber"><ng-container
                  i18n="@@ticket">Ticket</ng-container>&nbsp;# {{traveler.eTicketNumber}}</p>
            </ng-container>


        </div>
      </div>

      <div [ngClass]="{'px-4': Service.mobileView}">
      <app-flight [details]="VoucherData.details.data.bookingResult.data.flightOffers[0]" [isVoucher]="true"></app-flight>

      <div *ngIf="VoucherData && Service.mobileView">
        <app-flight-pricing [pricing]="VoucherData.details.data.bookingResult.data"></app-flight-pricing>

        <app-flight-rewards [rewardAmount]="VoucherData.details.data.bookingResult.data.flightOffers[0].travelerPricings[0].userData.convertedRewardAmount"
                            [rewardCurrency]="VoucherData.details.data.bookingResult.data.flightOffers[0].travelerPricings[0].userData.convertedRewardCurrencyCode"></app-flight-rewards>



        <app-cost-reporting-details
                [costCenters]="VoucherData?.details?.data?.costCenter ? [VoucherData?.details?.data?.costCenter.name] : []"
                [labels]="labels">
        </app-cost-reporting-details>
      </div>
      </div>


      <div class="table-header pt-4 mt-5"
        [ngClass]="{'rounded border': Service.desktopView, 'border-top border-bottom': Service.mobileView}">
        <p class="gray-primaray-text" i18n="@@flightBookedSuccessfully">Your flight has been booked successfully and the itinerary is ticketing in progress by the he airline.</p>

          <p class="my-4 py-2 gray-primaray-text" i18n="@@noNeedContact">No need to contact us at the moment, you will receive an email with all details within few hours with the ticket number or you can check your trips list. </p>
        <p class="font-18 gray-primaray-text" i18n="@@beforeYouTravel1">Before you travel</p>
        <ul class="gray-text-dark" [ngClass]="{'pl-4': Service.isSiteLtr(), 'pr-4': Service.isSiteRtl()}">
          <li i18n="@@beforeYouTravel2">Make sure to have your E-ticket with you at the airport. </li>
          <li i18n="@@beforeYouTravel3">Bring your government-issued photo ID at the airport check-in and security.</li>
          <li i18n="@@beforeYouTravel4">For international travel, make sure to bring all necessary travel documentation
            (passport, visa, transit permit or any other documents required at the destination).</li>
        </ul>
        <p class="gray-text-dark mb-0 mt-5" i18n="@@bookingDetails4">You can contact the airline for the following</p>
        <ul class="gray-text-dark" [ngClass]="{'pl-4': Service.isSiteLtr(), 'pr-4': Service.isSiteRtl()}">
          <li i18n="@@beforeYouTravel5">Special meals</li>
          <li i18n="@@beforeYouTravel6">Seat assignment</li>
          <li i18n="@@beforeYouTravel7">Any required special assistance</li>
        </ul>

        <p class="font-16 gray-primaray-text mt-5" i18n="@@needOurHelp">Need our help?</p>
        <p class="gray-text-dark" i18n="@@contactSupport"> You can contact us by email on support@safarhub.com</p>
      </div>
    </div>
    <div class="col-md-4 custom-col4 custom-mt" *ngIf="VoucherData && Service.desktopView">
      <app-flight-pricing [pricing]="VoucherData.details.data.bookingResult.data"></app-flight-pricing>
      <app-flight-rewards [rewardAmount]="VoucherData.details.data.bookingResult.data.flightOffers[0].travelerPricings[0].userData.convertedRewardAmount"
                          [rewardCurrency]="VoucherData.details.data.bookingResult.data.flightOffers[0].travelerPricings[0].userData.convertedRewardCurrencyCode"></app-flight-rewards>


      <app-cost-reporting-details
        [costCenters]="VoucherData?.details?.data?.costCenter ? [VoucherData?.details?.data?.costCenter.name] : []"
        [labels]="labels">
      </app-cost-reporting-details>
    </div>
  </div>
</div>


<!--<app-notifications *ngIf="Service.ShowPushNotificationsPopUp"></app-notifications>-->
