import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import { SidenavService} from '../sidenav.service';
import { onMainContentChange } from '../animations/animations';
import {environment} from '../../../../environments/environment';
import {AuthServiceMain} from '../../../auth/auth.service';
import {Router} from '@angular/router';
import * as moment from "moment";

@Component({
  selector: 'app-workspace-dashboard-home',
  templateUrl: './workspace-dashboard-home.component.html',
  styleUrls: ['./workspace-dashboard-home.component.scss'],
  animations: [ onMainContentChange ]
})
export class WorkspaceDashboardHomeComponent implements OnInit  {
  data;

  constructor(public router: Router, public dataService: DataService, private sidenavService: SidenavService, public auth: AuthServiceMain) {
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
 /*   this.dataService.componentMethodCalled$.subscribe(
    () => {
    if (this.auth.isLogedIn && !this.auth.companyInfo) {
      this.auth.fetchCompanyData();

}
    });*/
  /*  this.dataService.componentMethodCalled$.subscribe(
      () => {
        debugger
if(this.auth.isLogedIn && this.auth.userInfo) {
  this.dataService.getCompanyInfo().subscribe(
    suc => {

      this.data = suc;
      if (this.data.errorCode === 0) {
        this.dataService.workspaceLoader = true;
      } else {
        //    this.logout();
      }

    },
    err => {
      //  this.logout();

    }
  );

}
      });*/
  }

 // public onSideNavChange: boolean;
  // fillerNav = Array(50).fill(0).map((_, i) => `Nav Item ${i + 1}`);



  ngOnInit(): void {

    this.dataService.removeBodyClass();
    setTimeout( () => {

      if (this.auth.isLogedIn && !this.auth.companyInfo) {
        this.auth.fetchCompanyData();
      }

    }, 1000);
  /*  if(this.auth.isLogedIn && this.auth.userInfo) {

    this.dataService.getCompanyInfo().subscribe(
      suc => {

        this.data = suc;
        if (this.data.errorCode === 0) {


          this.dataService.workspaceLoader = true;
        } else {
          //    this.logout();
        }

      },
      err => {
        //  this.logout();

      }
    );
  }*/
  }

}
