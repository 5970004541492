<div class="mb-5 mb-lg-3">
    <p *ngIf="status === companyArchiveStatus.Archived" class="py-5 py-lg-2" i18n="@@walletActivateMsg">
      Are you sure you want to activate {{name}} ?</p>
    <p *ngIf="status === companyArchiveStatus.Active" class="py-5 py-lg-2" i18n="@@walletArchiveMsg">
      Are you sure you want to archive {{name}} ?</p>
  
    <div class="d-flex justify-content-end" *ngIf="dataService.desktopView">
      <button class="btn-outline-gray btn px-5 font-14" (click)="close()" [disabled]="loader" i18n="@@cancelBtn">
        Cancel </button>
      <button class="blue_btn px-5"
        [ngClass]="{'m-progress': loader,  'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"
        (click)="changeActivation();">
        {{btnTxt}}
      </button>
    </div>
  
    <div *ngIf="dataService.mobileView">
      <button class="blue_btn px-5 w-100 mb-3" [ngClass]="{'m-progress': loader }" (click)="changeActivation();">
        {{btnTxt}}
      </button>
      <button class="btn-outline-gray btn px-5 font-14 w-100" (click)="close()" [disabled]="loader" i18n="@@cancelBtn">
        Cancel </button>
    </div>
  </div>