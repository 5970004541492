import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import { SidenavService} from '../../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../../workspace-dashboard/animations/animations';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {VariablesService} from '../../../../services/vars.service';
import {map, startWith} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {User} from "../../../../shared/model/user";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-transfer-ownership',
  templateUrl: './transfer-ownership.component.html',
  styleUrls: ['./transfer-ownership.component.scss'],
  animations: [ onMainContentChange ]
})
export class TransferOwnershipComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, public dataService: DataService, private sidenavService: SidenavService,
               public router: Router, public auth: AuthServiceMain, public vars: VariablesService) {
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
  transferFrom: FormGroup;
  showConfirmation = false;
  dataUsers;
  data;
  submitted = false;
  submitting = false;
  userControl = new FormControl();
  users = [];
  showUsersAutoCompletePopUp = false;
  selectedUser;
  filteredUsers: Observable<any[]>;
  lastFilter: string = '';
  @ViewChild('userInput') userInput: ElementRef;
  userInfoObj = JSON.parse(localStorage.getItem('userInfo'));
  ngOnInit(): void {
    this.transferFrom = this.formBuilder.group({
      password: ['', [Validators.required]]
    }, {});
    if (this.dataService.desktopView) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('workspace-modal');
    }
    this.usersAutoComplete();
  }
  get c() {return this.transferFrom.controls;}
  transferOwnership() {
    this.submitting = true;
    this.submitted = true;
    if (this.transferFrom.invalid) {
      this.submitting = false;
      return;
    }
    this.dataService.transferOwnership(this.transferFrom.controls.password.value, true, this.selectedUser.id).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.submitting = false;
          this.auth.logout();
        } else {
          this.dataService.messages(this.data.message, 'error');
          this.submitting = false;
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
        this.submitting = false;
      }
    );
  }
  showConfirm() {
    this.submitted = true;
    if (!this.selectedUser) {
      return;
    } else {
      this.submitted = false;
      this.showConfirmation = true;
    }
  }
  // for Company Booking
  usersAutoComplete() {
    this.dataService.getCompanyUsersList().subscribe(
      dataList => {
        this.dataUsers = dataList;
        if (this.dataUsers.errorCode === 0) {
          this.dataUsers = this.dataUsers.data;

          this.dataUsers.forEach((element) => {


            if (element.role !== 'OWNER') {
              this.users.push(new User(element.description, element.id, element.email, element.teamName));
            }
          });
          this.filteredUsers = this.userControl.valueChanges.pipe(
            startWith<string | User[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter))
          );

        } else {
          this.dataService.messages(this.dataUsers.message, 'error');
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
      }
    );
  }
  filter(filter: string): User[] {
    this.lastFilter = filter;
    if (filter) {
      return this.users.filter(option => {
        return option.description.toLowerCase().includes(filter.toLowerCase()) || option.email.includes(filter);
      });
    } else {
      return this.users.slice();
    }
  }
  displayFn(user: User): string {
    return user && user.description ? user.description : '';
  }
  reset() {
    setTimeout(() => {
      if (typeof this.userControl.value !== 'object') {

          this.userInput.nativeElement.value = '';
        this.userControl.setValue(null);
        this.lastFilter = '';
        this.selectedUser = null;
        this.filteredUsers = this.userControl.valueChanges.pipe(
          startWith<string | User[]>(''),
          map(value => typeof value === 'string' ? value : this.lastFilter),
          map(filter => this.filter(filter))
        );
      }
    }, 400);
  }

  optionClicked(event: Event, user: User) {
    this.selectedUser = user;
    this.showUsersAutoCompletePopUp = false;
    this.dataService.enableBodyScroll();
  }
  showUsersPopUp() {
    this.showUsersAutoCompletePopUp = true;
    this.dataService.disableBodyScroll();
    if (this.selectedUser) {
      this.userControl.setValue(this.selectedUser);
    }
    setTimeout(() => {
      const input = document.getElementById('userInput') as HTMLInputElement;
      input.select();
      input.focus();
    }, 200);
    return;
  }
}
