
<div class="workspace-login main-container pb-5" [ngClass]="{'gray-pass-bg' : dataService.desktopView}">
 <!-- <app-messages></app-messages>-->
  <div class="container pb-5" [ngClass]="{'desktop-design text-center': dataService.desktopView, 'mb-5 pt-5 pt-lg-2': dataService.mobileView}" *ngIf="!dataService.signInErrorMsg">
    <img width="471" height="422" loading="lazy" class="fixed-img rounded" src="assets/img/triphop-Signup-photo.jpg" *ngIf="dataService.desktopView">
  <!--  <div class="custom-container mx-auto" >-->
     <div class="login-form px-lg-5 pt-5 corporate-box workspace-registration-container"
          [ngClass]="{'white-box position-relative' : dataService.desktopView, 'mx-auto': dataService.mobileView,
          'mr-auto':  dataService.desktopView && dataService.isSiteLtr(), 'ml-auto':  dataService.desktopView && dataService.isSiteRtl(),
          'mr-auto': dataService.isSiteLtr(), 'ml-auto': dataService.isSiteRtl()}">
       <div class="text-center">
         <h4 class="font-24 pt-md-4 mb-3 font-weight-bold text-center" i18n="@@workspaceLogin1">
           Sign in to {{dataService.companyName}}
         </h4>
       </div>
       <p class="gray-text font-16 pb-4 mb-3 text-center px-4 workspace-registration-container mx-auto" i18n="@@workspaceLogin2">Enter your password to login</p>

      <app-workspace-login-form></app-workspace-login-form>


      </div>

  <!--  </div>-->
  </div>


  <app-login-error-page *ngIf="dataService.signInErrorMsg"></app-login-error-page>
</div>
