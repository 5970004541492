import { Component, OnInit } from '@angular/core';
import {DataService} from "../../services/data.service";
import {environment} from "../../../environments/environment";
import {AuthServiceMain} from "../../auth/auth.service";

@Component({
  selector: 'app-remove-program-or-passport',
  templateUrl: './remove-program-or-passport.component.html',
  styleUrls: ['./remove-program-or-passport.component.scss']
})
export class RemoveProgramOrPassportComponent implements OnInit {

  constructor(public dataService: DataService, public auth: AuthServiceMain) { }
  submitting;
  data;
  errorMessage;
  id;
  dataUser;
  ngOnInit(): void {
    if(this.dataService.desktopView){
      this.id = this.dataService.modalData;
    } else {
      this.id = this.dataService.BottomSheetData;
    }
  }
  deleteDoc() {
    this.dataService.alerts = [];
    this.submitting = true;
    this.dataService.removeTravelDocument(this.id).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
         // this.auth.fetchUserData();
          this.dataService.getUserInfo().subscribe(
              dataUser => {
                this.dataUser = dataUser;
                if (this.dataUser.errorCode === 0) {
                  this.auth.userInfo.travelDocuments = this.dataUser.data.travelDocuments;
                  localStorage.setItem('userInfo', JSON.stringify(this.auth.userInfo));
                    this.dataService.fetchUserData.next();
                } else {
                  this.dataService.messages(this.dataUser.message, 'error');
                }
              }, error => {
                this.dataService.messages(environment.messages.error, 'error');
              }
          );
          this.submitting = false;
          this.dataService.messages($localize`:@@travelDocDeleted:Travel document was deleted successfully`, 'success');
          this.dataService.BottomSheetOf();
          this.dataService.modalOf();
        } else {
          this.errorMessage = this.data.message;
          this.submitting = false;
        }
      },
      error => {
        this.errorMessage = environment.messages.error;
        this.submitting = false;
      }
    );
  }
  deleteProgram() {
    this.dataService.alerts = [];
    this.submitting = true;
    this.dataService.removeFrequentFlyerProgram(this.id).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          //this.auth.fetchUserData();
          this.dataService.getUserInfo().subscribe(
              dataUser => {
                this.dataUser = dataUser;
                if (this.dataUser.errorCode === 0) {
                  this.auth.userInfo.frequentFlyerPrograms = this.dataUser.data.frequentFlyerPrograms;
                  localStorage.setItem('userInfo', JSON.stringify(this.auth.userInfo));
                    this.dataService.fetchUserData.next();
                } else {
                  this.dataService.messages(this.dataUser.message, 'error');
                }
              }, error => {
                this.dataService.messages(environment.messages.error, 'error');
              }
          );
          this.submitting = false;
          this.dataService.messages($localize`:@@flyerProgDeleted:Flyer program was deleted successfully`, 'success');
          this.dataService.BottomSheetOf();
          this.dataService.modalOf();
        } else {
          this.errorMessage = this.data.message;
          this.submitting = false;
        }
      },
      error => {
        this.errorMessage = environment.messages.error;
        this.submitting = false;
      }
    );
  }

}
