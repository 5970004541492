<ng-container *ngIf="Service.desktopView">
    <div class="policy-details position-relative mb-4 py-3 px-4 company-section-with-thick-border border"
         [ngClass]="{'rounded-right': Service.isSiteLtr(), 'rounded-left': Service.isSiteRtl(), 'thick-top rounded-bottom': Service.mobileView}"
         *ngIf="policyDetails && showPolicyDetails">

        <i class="icon-close-light gray-text font-12 pointer position-absolute"
           [ngClass]="{'mr-4 ml-auto': Service.isSiteLtr(), 'ml-4 mr-auto': Service.isSiteRtl()}"
           (click)="showPolicyDetails = false"></i>
        <p class="font-18 bold-600" i18n="@@travelPolicy">Travel Policy</p>
        <p *ngIf="policyDetails.flightBookingNotAllowed === true" i18n="@@approvalAlwaysRequiredFlight">
            Approval is required for any flight booking.
        </p>
        <ng-container *ngIf="!policyDetails.flightBookingNotAllowed || policyDetails.flightBookingNotAllowed === false">
            <div  *ngIf="policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger === policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassenger">
                <div class="d-flex mb-3">
                    <span class="mb-0" [ngClass]="{'mr-4': Service.isSiteLtr(), 'ml-4': Service.isSiteRtl()}"><span i18n="@@fareClass">Fare class</span>: <span class="gray-text">{{applicablePolicySelectedClass}}</span></span>


                    <span [ngClass]="{'mr-4': Service.isSiteLtr(), 'ml-4': Service.isSiteRtl()}">
                      <span class="gray-primaray-text" i18n="@@priceCap">Price cap</span>:

                      <ng-container *ngIf="policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger">
                          <span class="gray-text">{{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency}}
                              {{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger | number : '1.0-0'}}
                          </span>

                        <ng-container *ngIf="policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerConverted">
                              (<span class="gray-text">{{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerConvertedCurrency}}
                            {{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerConverted | number : '1.0-0'}}</span>)
                        </ng-container>

                        </ng-container>
                              <span class="gray-text" *ngIf="!policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger">
                    -
                  </span>

                    </span>

                    <p class="mb-0">
                        <span class="gray-primaray-text" i18n="@@ApprovalRequiredOn">Approval required on: </span>
                        <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'NO_APPROVAL_REQUIRED'" i18n="@@noApproval">No approval</span>
                        <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'RULES_VIOLATION_APPROVAL_REQUIRED'" i18n="@@outOfPolicy">Out of policy</span>
                        <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'ALWAYS_APPROVAL_REQUIRED'" i18n="@@allTrips">All trips</span>
                    </p>

                </div>
                <span *ngIf="maxNoOfTrips">
                      <span i18n="@@maxNoOfTrips">Max number of trips</span>: <span class="gray-text">{{maxNoOfTrips}}/{{maxNoInterval}}</span>
                    </span>
            </div>



            <div *ngIf="policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger !== policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassenger">
                <div class="d-flex">
                    <span [ngClass]="{'mr-4': Service.isSiteLtr(), 'ml-4': Service.isSiteRtl()}"><span i18n="@@fareClass">Fare class</span>: <span class="gray-text">{{applicablePolicySelectedClass}}</span></span>


                    <p class="mb-2" [ngClass]="{'mr-4': Service.isSiteLtr(), 'ml-4': Service.isSiteRtl()}">
                        <span class="gray-primaray-text" i18n="@@ApprovalRequiredOn">Approval required on: </span>
                        <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'NO_APPROVAL_REQUIRED'" i18n="@@noApproval">No approval</span>
                        <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'RULES_VIOLATION_APPROVAL_REQUIRED'" i18n="@@outOfPolicy">Out of policy</span>
                        <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'ALWAYS_APPROVAL_REQUIRED'" i18n="@@allTrips">All trips</span>
                    </p>
                    <span *ngIf="maxNoOfTrips">
                      <span i18n="@@maxNoOfTrips">Max number of trips</span>: <span class="gray-text">{{maxNoOfTrips}}/{{maxNoInterval}}</span>
                    </span>

                </div>
                <div class="d-flex">
                      <span class="mb-0" [ngClass]="{'mr-4': Service.isSiteLtr(), 'ml-4': Service.isSiteRtl()}"><span class="gray-primaray-text" i18n="@@interPriceCap">International price cap</span>: <span class="gray-text">{{policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency}} {{policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassenger | number : '1.0-0'}}</span>

                          <ng-container *ngIf="policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassengerConverted">
                        (<span class="gray-text">{{policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassengerConvertedCurrency}} {{policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassengerConverted | number : '1.0-0'}}</span>)
                      </ng-container>

                      </span>



                    <span *ngIf="policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger" class="mb-0" [ngClass]="{'mr-4': Service.isSiteLtr(), 'ml-4': Service.isSiteRtl()}"><span class="gray-primaray-text" i18n="@@domPriceCap">Domestic price cap</span>: <span class="gray-text">{{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency}} {{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger | number : '1.0-0'}}</span>
                                  <ng-container *ngIf="policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerConverted">
                        (<span class="gray-text">{{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerConvertedCurrency}} {{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerConverted | number : '1.0-0'}}</span>)
                      </ng-container>

                      </span>
                </div>
            </div>
        </ng-container>


    </div>
</ng-container>

<ng-container *ngIf="Service.mobileView">
<div class="policy-details position-relative mb-4 py-3 px-4 company-section-with-thick-border border"
     *ngIf="policyDetails && showPolicyDetails" [ngClass]="{'rounded-right': Service.isSiteLtr(), 'rounded-left': Service.isSiteRtl()}">
    <i class="icon-close-light gray-text font-12 pointer position-absolute"
       [ngClass]="{'mr-4 ml-auto': Service.isSiteLtr(), 'ml-4 mr-auto': Service.isSiteRtl()}"
       (click)="showPolicyDetails = false"></i>
    <p class="font-18 bold-600" i18n="@@travelPolicy">Travel Policy</p>
    <p *ngIf="policyDetails.flightBookingNotAllowed === true" i18n="@@approvalAlwaysRequiredFlight">
        Approval is required for any flight booking.
    </p>
    <ng-container *ngIf="!policyDetails.flightBookingNotAllowed || policyDetails.flightBookingNotAllowed === false">
        <p><span i18n="@@fareClass">Fare class</span>: <span class="gray-text">{{applicablePolicySelectedClass}}</span></p>

        <p *ngIf="policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger === policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassenger">
            <span class="gray-primaray-text" i18n="@@priceCap">Price cap</span>:

            <ng-container *ngIf="policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger">
                          <span class="gray-text">{{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency}}
                              {{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger | number : '1.0-0'}}
                          </span>

                <ng-container *ngIf="policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerConverted">
                    (<span class="gray-text">{{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerConvertedCurrency}}
                    {{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerConverted | number : '1.0-0'}}</span>)
                </ng-container>

            </ng-container>


            <span class="gray-text" *ngIf="!policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger">
                    -
                  </span>
        </p>
        <ng-container *ngIf="policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger !== policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassenger">
            <p>
                <span class="gray-primaray-text" i18n="@@interPriceCap">International price cap</span>:
                <span class="gray-text">{{policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency}} {{policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassenger | number : '1.0-0'}}</span>
                <span class="gray-text">
                         <ng-container *ngIf="policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassengerConverted">
                        (<span class="gray-text">{{policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassengerConvertedCurrency}} {{policyDetails.companyPolicyFlightRules.internationalPriceLimitPerPassengerConverted | number : '1.0-0'}}</span>)
                      </ng-container>
                  </span>

            </p>
            <p *ngIf="policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger"><span class="gray-primaray-text" i18n="@@domPriceCap">Domestic price cap</span>:
                <span class="gray-text">{{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency}} {{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassenger | number : '1.0-0'}}</span>
                <span class="gray-text">
                   <ng-container *ngIf="policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerConverted">
                        (<span class="gray-text">{{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerConvertedCurrency}} {{policyDetails.companyPolicyFlightRules.domesticPriceLimitPerPassengerConverted | number : '1.0-0'}}</span>)
                      </ng-container>
                  </span>
            </p>
        </ng-container>
        <p *ngIf="maxNoOfTrips">
            <span i18n="@@maxNoOfTrips">Max number of trips</span>: <span class="gray-text">{{maxNoOfTrips}}/{{maxNoInterval}}</span>
        </p>
        <p>
            <span class="gray-primaray-text" i18n="@@ApprovalRequiredOn">Approval required on: </span>
            <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'NO_APPROVAL_REQUIRED'" i18n="@@noApproval">No approval</span>
            <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'RULES_VIOLATION_APPROVAL_REQUIRED'" i18n="@@outOfPolicy">Out of policy</span>
            <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'ALWAYS_APPROVAL_REQUIRED'" i18n="@@allTrips">All trips</span>
        </p>

    </ng-container>
</div>
</ng-container>
