import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { NewPaymentCardComponent } from './new-payment-card/new-payment-card.component';
import { NgxStripeModule } from 'ngx-stripe';
import {environment} from '../../environments/environment';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    // NewPaymentCardComponent
  ],
  exports: [
   // NewPaymentCardComponent
  ],
  imports: [
    CommonModule,
    NgxStripeModule.forChild(environment.stripeKey),
    ReactiveFormsModule
  ]
})
export class PaymentModule { }
