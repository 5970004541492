<div class="manage-booking container mt-4 mb-5">
  <div class="d-flex">
    <h2
      class="green-text bolder p-2"
      [ngClass]="{
        'mr-auto': Service.isSiteLtr(),
        'ml-auto': Service.isSiteRtl(),
        'font-24': Service.desktopView,
        'font-14': Service.mobileView
      }"
    >
      <i
        class="icon-success font-24-to-18"
        [ngClass]="{ 'mr-3': Service.isSiteLtr(), 'ml-3': Service.isSiteRtl() }"
      >
      </i>
      <span
        *ngIf="!Service.inactiveWorkspace && bookingType === 'cancelled'"
        i18n="@@activeBookingCanceled"
        >Your booking is canceled</span
      >
      <span
        *ngIf="!Service.inactiveWorkspace && bookingType === 'completed'"
        i18n="@@activeBookingCompleted"
        >Your booking is completed</span
      >
      <span
        *ngIf="!Service.inactiveWorkspace && bookingType === 'confirmed'"
        i18n="@@activeBookingConfirmed"
        >Your booking is confirmed</span
      >
      <span
        *ngIf="Service.inactiveWorkspace && bookingType === 'cancelled'"
        i18n="@@inActiveBookingCanceled"
        >Booking is canceled</span
      >
      <span
        *ngIf="Service.inactiveWorkspace && bookingType === 'completed'"
        i18n="@@inActiveBookingCompleted"
        >Booking is completed</span
      >
      <span
        *ngIf="Service.inactiveWorkspace && bookingType === 'confirmed'"
        i18n="@@inActiveBookingConfirmed"
        >Booking is confirmed</span
      >
    </h2>
    <button
      class="btn btn-link karla-font font-16"
      [ngClass]="{ 'pr-3': Service.isSiteLtr(), 'p-3': Service.isSiteRtl() }"
      (click)="Service.showPrint = true; print()"
      *ngIf="Service.desktopView"
      i18n="@@print"
    >
      Print
    </button>
    <button
      (click)="openSendBookingVoucherPopUp()"
      class="btn btn-link karla-font font-16"
      *ngIf="Service.desktopView && !Service.inactiveWorkspace"
      i18n="@@email"
    >
      Email
    </button>
  </div>
  <div class="row">
    <div class="col-md-8 custom-col8"
      [ngClass]="{ 'px-0': Service.mobileView }"
      *ngIf="bookingDetails">

      <div class="table-header pt-4 pb-2 mb-5"
           [ngClass]="{'rounded border': Service.desktopView, 'border-top border-bottom': Service.mobileView}">

        <p class="gray-primaray-text font-16 mb-0" i18n="@@confirmationNumber">Confirmation number</p>
        <p class="gray-text-dark font-14 mb-5">{{bookingDetails.hrsReferenceNumber}}</p>
        <div class="mb-5">
          <ng-container *ngIf="bookingDetails.bookingResult.data.associatedRecords">
            <p class="gray-primaray-text font-16 mb-0" i18n="@@airlinesConfirmationNumbers">Airlines confirmation numbers</p>
            <ng-container *ngFor="let airline of bookingDetails.bookingResult.data.associatedRecords">
              <p class="gray-text-dark font-14 mb-0">{{airline.reference}}</p>
            </ng-container>
          </ng-container>
        </div>
        <div>
          <p class="gray-primaray-text font-16 mb-0" i18n="@@theTraverlers">Travelers</p>

          <ng-container *ngFor="let traveler of bookingDetails.bookingResult.data.travelers">
            <p class="gray-text-dark font-14 mb-0">{{traveler.name.firstName}} {{traveler.name.lastName}}
              <span *ngIf="traveler.type"
                    class="font-14 text-capitalize gray-text">- {{traveler.type.toLowerCase()}}</span>
            </p>
            <p class="gray-text font-14 mb-0" *ngIf="traveler.eTicketNumber"><ng-container
                    i18n="@@ticket">Ticket</ng-container>&nbsp;# {{traveler.eTicketNumber}}</p>
          </ng-container>


        </div>
      </div>



      <div [ngClass]="{'px-4': Service.mobileView}">
        <app-flight [details]="bookingDetails.bookingResult.data.flightOffers[0]" [isVoucher]="true"></app-flight>

        <div *ngIf="bookingDetails && Service.mobileView">
          <app-flight-pricing [pricing]="bookingDetails.bookingResult.data"></app-flight-pricing>

          <app-flight-rewards [rewardAmount]="bookingDetails.bookingResult.data.flightOffers[0].travelerPricings[0].userData.convertedRewardAmount"
                              [rewardCurrency]="bookingDetails.bookingResult.data.flightOffers[0].travelerPricings[0].userData.convertedRewardCurrencyCode">
          </app-flight-rewards>

          <app-cost-reporting-details
                  [costCentersIds]=" bookingDetails?.costCenter ? [bookingDetails?.costCenter] : ''"
                  [labelsIds]="bookingDetails?.labels ? bookingDetails?.labels : []">
          </app-cost-reporting-details>
        </div>
      </div>

    </div>
    <div class="col-md-4 custom-col4"
      *ngIf="bookingDetails && Service.desktopView">
      <app-flight-pricing [pricing]="bookingDetails.bookingResult.data"></app-flight-pricing>

      <app-flight-rewards [rewardAmount]="bookingDetails.bookingResult.data.flightOffers[0].travelerPricings[0].userData.convertedRewardAmount"
                          [rewardCurrency]="bookingDetails.bookingResult.data.flightOffers[0].travelerPricings[0].userData.convertedRewardCurrencyCode">
      </app-flight-rewards>

      <app-cost-reporting-details
        [costCentersIds]="
          bookingDetails?.costCenter ? [bookingDetails?.costCenter] : ''"
        [labelsIds]="bookingDetails?.labels ? bookingDetails?.labels : []">
      </app-cost-reporting-details>
    </div>
  </div>
</div>
<app-notifications
  *ngIf="Service.ShowPushNotificationsPopUp"
></app-notifications>
