import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {VariablesService} from "./vars.service";
import {Router} from "@angular/router";
import {DataService} from "./data.service";
@Injectable({
    providedIn: 'root'
})
export class TripsService {
   /* flightPricingDetails = new BehaviorSubject<any>(null);
    pricingLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);*/

    constructor(private http: HttpClient,
                public  router: Router,
                private dataService: DataService,
                public VarsService: VariablesService) {
    }

    flightDetails(body){
        return this.http.post(
            `${environment.apiBase}${this.VarsService.flightApis.flightDetails}`,
            body
        );
    }


    viewTripsDetails(hrsReferenceNumber: string, email: string, bookingType: string) {
        let queryParams = {
            ref: hrsReferenceNumber,
            identifier : email
        };
        this.router.navigate(['/' + bookingType.toLowerCase() + '/booking/details'], {queryParams: queryParams});
    }

    flightHotelListBookings(bookingStatus, includeUpcoming, includePast) {
        if (this.dataService.inactiveWorkspace) {
            return this.http.post(
                `${environment.apiBase}${this.VarsService.apiUrls.retrieveCompanyBookings}?bookingStatus=` +
                bookingStatus +
                `&includeUpcoming=` +
                includeUpcoming +
                `&includePast=` +
                includePast,
                ""
            );
        } else {
            return this.http.post(
                `${environment.apiBase}${this.VarsService.apiUrls.retrieveAllBookingsMe}?bookingStatus=` +
                bookingStatus +
                `&includeUpcoming=` +
                includeUpcoming +
                `&includePast=` +
                includePast,
                ""
            );
        }
    }

}











