<div *ngIf="!loader">
  <div *ngIf="costCenters.length || labels.length" class="white-box mt-4 mb-4 pb-4 mx-0">
    <div class="d-flex justify-content-start">
      <h5 class="font-18 bold-600 gray-primaray-text mb-3" i18n="@@costReporting"> Cost Reporting </h5>
    </div>

    <div class="form-group form-type-select mt-2 mb-3">
      <p class="gray-text mb-0 pb-1" i18n="@@costCenter">Cost Center</p>
      <ng-multiselect-dropdown *ngIf="costCenters.length" [settings]="costCentersDropdownSettings" [data]="costCenters"
        name="cost" [(ngModel)]="costCenters" placeholder="-" class="w-100" [disabled]="true">
      </ng-multiselect-dropdown>
      <input *ngIf="!costCenters.length" type="text" placeholder="-" class="w-100 empty" disabled="true" />
    </div>

    <div class="form-group form-type-select">
      <p class="gray-text mb-0 pb-1" i18n="@@labels">Label(s)</p>
      <ng-multiselect-dropdown *ngIf="labels.length" [settings]="labelsDropdownSettings" [data]="labels" name="label"
        [(ngModel)]="labels" placeholder="-" class="w-100" [disabled]="true">
      </ng-multiselect-dropdown>
      <input *ngIf="!labels.length" type="text" placeholder="-" class="w-100 empty" disabled="true" />
    </div>
  </div>
</div>