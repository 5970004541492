import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DataService} from "../../../services/data.service";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {DomSanitizer, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-slack-installation',
  templateUrl: './slack-installation.component.html',
  styleUrls: ['./slack-installation.component.scss'],
})
export class SlackInstallationComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer, private titleService: Title, public dataService: DataService,
               public router: Router) {
    this.titleService.setTitle('Integrate your slack workspace | SafarHub');
  }
  env = environment;
  data;
    aTag;
    aHref = 'https://slack.com/oauth/v2/authorize?client_id=166283204837.1573177487333&scope=channels:join,chat:write,chat:write.customize,chat:write.public,commands,im:write,users:read,users:read.email,users:write&user_scope=';

  ngOnInit(): void {
if(this.env.production){
    this.aHref = 'https://slack.com/oauth/v2/authorize?client_id=166283204837.1816344364675&scope=chat:write,commands,im:write,users:read,users:read.email&user_scope=';
}

this.aHref = '<a href="' + this.aHref + '" target="_blank">\n' +
    '              <img width="139" height="40" loading="lazy" src="https://platform.slack-edge.com/img/add_to_slack.png"\n' +
    '                   class="logo-image initial-height max-width pointer" alt="slack button" >\n' +
    '            </a>';
    this.dataService.slack().subscribe(
        data => {
            this.data = data;
            this.aHref = this.data.substring((this.data).indexOf('<a href') , (this.data).indexOf('</p'));
            this.aHref = this.aHref.replace('<a href', '<a target="_blank" href');
            this.aTag = this.data.substring((this.data).indexOf('<a href') , (this.data).indexOf('<img'));
            this.aTag = this.aTag.replace('<a href="', '');
            this.aTag = this.aTag.replace('">', '');

        }, error => {

        }
    );
  }

}
