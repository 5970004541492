<div class="mini-container mx-auto gray-pass-bg position-relative" [ngClass]="{'rounded': dataService.desktopView, 'text-right': dataService.isSiteRtl()}">
  <div class="progress-bar mb-3" [style]="{'width': width + '%'}"> </div>
  <div class="d-flex">

    <i *ngIf="counter > 0" (click)="cal(backIndex, true);"
       class="primaray-text icon-left-arrow font-22 mb-4 align-middle" [ngClass]="{'ml-4': dataService.isSiteLtr(), 'mr-4': dataService.isSiteRtl()}"></i>
    <i class="icon-close-light gray-text font-12 pointer"
       [ngClass]="{'mr-4 ml-auto': dataService.isSiteLtr(), 'ml-4 mr-auto': dataService.isSiteRtl()}"
       (click)="dataService.NotifymodalOf(); this.dataService.BottomSheetOf();"></i>
  </div>
  <div class="p-3 inner-container mx-auto">
    <ng-container *ngIf="counter === 0">
      <p class="dark-gray font-28 font-weight-bold mb-5 mt-4" i18n="@@saveCal1">Savings Calculator</p>
     <!-- <p class="dark-gray font-18 mb-5 px-2" i18n="@@saveCal2">Calculate your average annual savings on travel expenses by answering {{this.dataService.corporateLocale !== 'ar' ? listOfQuestionsText.length : listOfQuestionsText.length.toLocaleString('ar-SA')}} questions only.</p>-->
      <p class="dark-gray font-18 mb-5 px-2" i18n="@@saveCal2">Calculate your average annual savings on travel expenses by answering {{listOfQuestionsText.length}} questions only.</p>
      <button  (click)="cal(1, false); counter = 1;" class="btn btn-outline-blue rounded-pill pass-btn font-18 position-absolute btn-position" [ngClass]="{'mr-3': dataService.desktopView && dataService.isSiteLtr(), 'ml-3': dataService.desktopView && dataService.isSiteRtl()}" i18n="@@calStartBtn">Get Started</button>
    </ng-container>
  <div *ngIf="counter > 0">
      <ng-container *ngFor="let item of listOfQuestionsText, let i = index">
        <div *ngIf="counter === (i + 1)">
          <p class="dark-gray font-18 mb-5 px-2">{{item}}</p>

          <select class="form-control mt-2 select-custom-arrow" name="suggestions" [formControl]="answerControl" >
            <option class="placeholder-text" value="null" disabled i18n="@@chooseAnswer">Choose answer</option>
           <!-- <option value="{{listOfEachQuestionSuggestions[i+1][0].value}}" selected>{{listOfEachQuestionSuggestions[i+1][0].text}}</option>-->
            <ng-container *ngFor="let suggestion of this.listOfEachQuestionSuggestions[i+1], let y = index" >
              <option value="{{y + 1}}">{{suggestion.text}}</option>
            </ng-container>
          </select>
          <button [disabled]="!answerControl.value" (click)="cal(i + 2, false);" class="btn btn-outline-blue rounded-pill pass-btn font-18  position-absolute btn-position" [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}" i18n="@@nextBtn">Next</button>
        </div>
      </ng-container>
    <ng-container *ngIf="counter > listOfQuestionsText.length">
      <div>
        <p class="dark-gray font-18 mb-5 px-2">
          <ng-container i18n="@@calFinal">Based on your input, your savings upon joining SafarHub will be between</ng-container> <br/><br/><span class="font-24 font-weight-bold">${{fromValue | number : '1.0-0'}} - ${{toValue | number : '1.0-0'}}</span>
        </p>
        <button  (click)="reset()" class="btn btn-outline-blue rounded-pill pass-btn font-18  position-absolute btn-position" [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}" i18n="@@resetBtn">Reset</button>
      </div>
    </ng-container>
    <p class="text-danger position-absolute btn-position msg">{{errorMsg}}</p>
  </div>
  </div>
</div>

