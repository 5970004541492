import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import { SidenavService} from '../../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../../workspace-dashboard/animations/animations';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {VariablesService} from '../../../../services/vars.service';
import {environment} from '../../../../../environments/environment';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-integration-settings',
  templateUrl: './integration-settings.component.html',
  styleUrls: ['./integration-settings.component.scss'],
  animations: [ onMainContentChange ]
})
export class IntegrationSettingsComponent implements OnInit {

  constructor(private titleService: Title, private formBuilder: FormBuilder, public dataService: DataService, private sidenavService: SidenavService,
              public router: Router, public auth: AuthServiceMain, public vars: VariablesService) {
    this.titleService.setTitle('Add/Edit Integration | SafarHub');
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
  integrationSettingForm: FormGroup;
  submitting;
  submitted;
  data;
  // connectOrUpdateCTA = 'Connect';
  disconnectOrRemoveCTA = $localize`:@@disconnect:Disconnect`;
  connected;
  savedIntegrationData;
  timer = null;
  keys = {
    ctrl: false,
    v: false
  };
  errorMsg;
  loaded;
  isSecretEmpty;
  expensifyId;
  connectedLocalizedText = $localize`:@@connected:Connected`;
  disconnectedLocalizedText = $localize`:@@disconnected:Disconnected`;
  disconnectLocalizedText = $localize`:@@disconnect:Disconnect`;
  removeAccountLocalizedText = $localize`:@@removeAccount:Remove Account`;
  ngOnInit(): void {
    this.dataService.isTextFieldType = false;
    this.integrationSettingForm = this.formBuilder.group({
      partnerUserID: ['', [Validators.required]],
      partnerUserSecret: ['']
    }, {});
    this.dataService.getExpensifyCreds().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.loaded = true;
          if(this.data.data) {
            this.expensifyId = this.data.data.partnerUserID;
            this.connected = this.data.data.enabled;
            if (!this.connected) {
              this.disconnectOrRemoveCTA = this.removeAccountLocalizedText;
            }
            this.integrationSettingForm.controls.partnerUserID.setValue(this.data.data.partnerUserID);
            this.savedIntegrationData = true;
          }
          this.errorMsg = '';
        } else {
          this.errorMsg = this.data.message;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
      }
    );
  }
  get ctrl() {return this.integrationSettingForm.controls; }
  connect() {
    this.submitted = true;
    this.submitting = true;
    if (this.integrationSettingForm.invalid) {
      this.submitting = false;
      if (!this.integrationSettingForm.controls.partnerUserSecret.value || this.integrationSettingForm.controls.partnerUserSecret.value === '') {
        this.submitting = false;
        this.isSecretEmpty = true;
        return;
      } else {
        this.isSecretEmpty = false;
      }
      return;
    }
    if (!this.integrationSettingForm.controls.partnerUserSecret.value || this.integrationSettingForm.controls.partnerUserSecret.value === '') {
      this.submitting = false;
      this.isSecretEmpty = true;
      return;
    } else {
      this.isSecretEmpty = false;
    }
    this.dataService.setExpensifyCreds(this.integrationSettingForm.controls.partnerUserID.value,
      this.integrationSettingForm.controls.partnerUserSecret.value).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.connected = this.data.data.enabled;
          this.expensifyId = this.data.data.partnerUserID;
          this.submitting = false;
          this.savedIntegrationData = true;
          this.disconnectOrRemoveCTA = this.disconnectLocalizedText;
          this.errorMsg = '';
        } else {
          this.errorMsg = this.data.message;
          this.submitting = false;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
        this.submitting = false;
      }
    );
  }
  enableAccount(enabled) {
  //  this.isSecretEmpty = false;
  //  this.submitted = false;
  //    this.submitted = true;
      this.submitting = true;
      if (this.integrationSettingForm.invalid) {
        this.submitting = false;
        return;
      }
    /*  if (!this.integrationSettingForm.controls.partnerUserSecret.value || this.integrationSettingForm.controls.partnerUserSecret.value === '') {
        this.submitting = false;
        this.isSecretEmpty = true;
        return;
      } else {
        this.isSecretEmpty = false;
      }*/

      this.dataService.enableExpensifyCreds(enabled).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.connected = this.data.data.enabled;
            this.submitting = false;
            if (this.connected) {
              this.disconnectOrRemoveCTA = this.disconnectLocalizedText;
            } else {
              this.disconnectOrRemoveCTA = this.removeAccountLocalizedText;;
            }
         //   return;
            this.errorMsg = '';
          } else {
            this.errorMsg = this.data.message;
            this.submitting = false;
            return;
          }
        }, error => {
          this.errorMsg = environment.messages.error;
          this.submitting = false;
       //   return;
        }
      );

  }

  removeAccount() {
  //  this.isSecretEmpty = false;
    this.submitted = false;
    this.submitting = true;
    this.dataService.removeExpensifyCreds().subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.connected = false;
            this.submitting = false;
            this.expensifyId = '';
          //  this.isSecretEmpty = false;
          //  this.submitted = false;
            this.disconnectOrRemoveCTA = this.disconnectLocalizedText;
            this.savedIntegrationData = false;
            this.integrationSettingForm.controls.partnerUserID.setValue(null);
            this.integrationSettingForm.controls.partnerUserSecret.setValue(null);
           // return;
            this.errorMsg = '';
          } else {
            this.errorMsg = this.data.message;
            this.submitting = false;
          //  return;
          }
        }, error => {
          this.errorMsg = environment.messages.error;
          this.submitting = false;
        //  return;
        }
      );
  }
  checkCarPressed(event) {
    if (event.keyCode === 17) {
       this.keys.ctrl = true;
    }
    if (event.keyCode === 86) {
      this.keys.v = true;
    }
    if (this.keys.ctrl && this.keys.v) {
      this.dataService.isTextFieldType = false;
    } else {
      this.dataService.isTextFieldType = true;
    }
  }
  checkCarReleased(event) {
    if (event.keyCode === 17) {
      this.keys.ctrl = false;
    }
    if (event.keyCode === 86) {
      this.keys.v = false;
    }
    if (!this.keys.ctrl || !this.keys.v) {
      setTimeout(() => {
        this.dataService.isTextFieldType = true;
      }, 1000);
    }
  }
}
