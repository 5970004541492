import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import { SidenavService} from '../../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../../workspace-dashboard/animations/animations';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {VariablesService} from '../../../../services/vars.service';
import {environment} from "../../../../../environments/environment";
import {Title} from "@angular/platform-browser";



@Component({
  selector: 'app-integrations-list',
  templateUrl: './integrations-list.component.html',
  styleUrls: ['./integrations-list.component.scss'],
  animations: [ onMainContentChange ]
})
export class IntegrationsListComponent implements OnInit {

  constructor(private titleService: Title, public dataService: DataService, private sidenavService: SidenavService,
              public router: Router, public auth: AuthServiceMain, public vars: VariablesService) {
    this.titleService.setTitle('Workspace integration list | SafarHub');
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
  connected;
  data;
  loaded;
  ngOnInit(): void {
    this.dataService.getExpensifyStatus().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.connected = this.data.data.enabled;
          this.loaded = true;
        } else {
          this.dataService.messages(this.data.message, 'error');
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
      }
    );
  }

}
