<select
        (focus)="bookingWalletsService.getBookingWalletsList(travelerId)"
        (change)="walletChange()"
        [(ngModel)]="bookingWalletsService.selectedWallet"
        [ngClass]="{'is-invalid': inValidWallet()}"
        [ngModelOptions]="{ standalone: true }"
        class="form-control mb-3">
    <option
            i18n="@@selectWallet"
            value="selectWallet"
            disabled>
        Select wallet
    </option>
    <ng-container *ngFor="let wallet of bookingWalletsService.walletsList | async; let i = index">
        <option *ngIf="!wallet.isInactive" [value]="wallet.id">
            {{ wallet.name }}
        </option>
    </ng-container>
    <option *ngIf="!(bookingWalletsService.walletsList | async)?.length"
            i18n="@@noWallet"
            value="noWallet"
            disabled>
        You don’t have any company wallets assigned to you
    </option>
</select>
