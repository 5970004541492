import {Component, OnInit, HostListener, Inject, PLATFORM_ID, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {AuthServiceMain} from '../../auth/auth.service';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { isPlatformBrowser } from '@angular/common';
import {environment} from '../../../environments/environment';
import {Title} from "@angular/platform-browser";
import {TripsService} from "../../services/trips.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-hotel-flight-trips-control',
  templateUrl: './hotel-flight-trips.component.html',
  styleUrls: ['./hotel-flight-trips.component.scss']
})
export class HotelFlightTripsComponent implements OnInit, OnDestroy{
  constructor(private titleService: Title,
              @Inject(PLATFORM_ID) private platformId,
              private scrollToService: ScrollToService,
              public router: Router,
              public logedIn: AuthServiceMain,
              public tripsService: TripsService,
              public dataService: DataService) {
    this.titleService.setTitle('My trips | SafarHub');
    if(this.logedIn.isLogedIn && this.dataService.isCompany() && this.logedIn.companyInfo && this.logedIn.companyInfo.inactive) {
      this.dataService.inactiveWorkspace = true;
    }

  }
  upcomingBookingObj = {
    bookingStatus : 'CONFIRMED',
    includeUpcoming : true,
    includePast : false
  };
  canceledBookingObj = {
    bookingStatus : 'CANCELLED',
    includeUpcoming : true,
    includePast : true
  };
  completedBookingObj = {
    bookingStatus : 'CONFIRMED',
    includeUpcoming : false,
    includePast : true
  };
  upcomingBooking;
  canceledBooking;
  completedBooking;
  upcomingBookingMain;
  canceledBookingMain;
  completedBookingMain;
  dataUpcoming;
  dataCanceled;
  dataCompleted;
  isUpcoming = true;
  isCompleted = false;
  isCanceled = false;
  isSticky = false;
  getCompleted;
  selectCompleted;
  getUpComing;
  selectUpcoming;
  getCanceled;
  selectCanceled;
  showMoreCompleted = 3;
  showMoreCanceled = 3;
  bookingsUrl;
  availabilityUrl;

  private subscriptions = new Subscription();
  ngOnInit() {
    this.initializeUrls();
    this.initializeData();
    this.subscribeToBookings();
  }

  initializeUrls() {
    this.bookingsUrl = '/bookings/manage';
    this.availabilityUrl = '/availability/hotel';

    if (this.logedIn.isLogedIn && this.logedIn.companyInfo?.inactive) {
      this.dataService.inactiveWorkspace = true;
        this.bookingsUrl = '/hotel/booking/details';
    }
  }

  initializeData() {
    this.dataService.alerts = [];
    this.dataService.alertsOverRoute = [];
    this.dataService.hotellistLoader = true;
  }

  subscribeToBookings() {
    const bookingTypes = [
      { obj: this.upcomingBookingObj, bookingVar: 'upcomingBooking', mainVar: 'upcomingBookingMain' },
      { obj: this.completedBookingObj, bookingVar: 'completedBooking', mainVar: 'completedBookingMain' },
      { obj: this.canceledBookingObj, bookingVar: 'canceledBooking', mainVar: 'canceledBookingMain' },
    ];

    let completedSubscriptions = 0;

    bookingTypes.forEach(type => {
      const subscription = this.tripsService
          .flightHotelListBookings(type.obj.bookingStatus, type.obj.includeUpcoming, type.obj.includePast)
          .subscribe(
              data => {
                this.handleBookingData(data, type.bookingVar, type.mainVar);

                // Increment the counter after each successful response
                completedSubscriptions++;
                if (completedSubscriptions === bookingTypes.length) {
                  this.dataService.hotellistLoader = false;
                }
              },
              error => this.handleBookingError(error)
          );
      this.subscriptions.add(subscription);
    });
  }

  handleBookingData(data, bookingVar, mainVar) {
    if (data.errorCode === 0) {
      this[bookingVar] = data.data;
      this[bookingVar].sort(this.compareBookingDates);
      this[mainVar] = data.data;
    } else {
      this.dataService.messages(data.message, 'error');
    }
  }

  // compareBookingDates = (a, b) => {
  //   const aDate = this.getBookingDate(a).getTime();
  //   const bDate = this.getBookingDate(b).getTime();
  //   return bDate - aDate; // Descending order
  // };

  compareBookingDates = (a, b) => {
    const aDate = this.getBookingDate(a).getTime();
    const bDate = this.getBookingDate(b).getTime();
    return aDate - bDate; // Ascending order
  };

  getBookingDate(booking) {
    if (booking.bookingType === 'FLIGHT' && booking.bookingResult.data.flightOffers[0].itineraries[0].searchSegmentSummary) {
      return new Date(booking.bookingResult.data.flightOffers[0].itineraries[0].searchSegmentSummary.departureDate);
    }
    return new Date(booking.checkinDate);
  }

  handleBookingError(error) {
    this.dataService.messages(environment.messages.error, 'error');
    this.dataService.hotellistLoader = false;
  }

  filterByText(event) {
    const searchTerm = event.target.value?.toLowerCase();

    const filterBookings = (bookings, mainBookings) => {
      return searchTerm
          ? bookings.filter(item =>
              item.guestFirstName?.toLowerCase().startsWith(searchTerm) ||
              item.guestLasttName?.toLowerCase().startsWith(searchTerm) ||
              item.emailAddress?.toLowerCase().startsWith(searchTerm)
          )
          : mainBookings.slice();
    };

    this.upcomingBooking = filterBookings(this.upcomingBooking, this.upcomingBookingMain);
    this.completedBooking = filterBookings(this.completedBooking, this.completedBookingMain);
    this.canceledBooking = filterBookings(this.canceledBooking, this.canceledBookingMain);
  }

  public triggerScrollToOffsetOnly(destination) {
    if (this.isSticky) {
      const config: ScrollToConfigOptions = {
        offset: -49,
        target : destination,
        duration : 100
      };
      this.scrollToService.scrollTo(config);
    } else{
      const config: ScrollToConfigOptions = {
        offset: -75,
        target : destination,
        duration : 100
      };
      this.scrollToService.scrollTo(config);
    }
  }
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    if (isPlatformBrowser(this.platformId)) {

      this.getUpComing = document.getElementById('upcoming');
      if (this.getUpComing) {
        this.selectUpcoming = this.getUpComing.offsetTop;
        if (window.pageYOffset > this.selectUpcoming) {
          this.isUpcoming = true;
          this.isCompleted = false;
          this.isCanceled = false;
        }
      }
      this.getCompleted = document.getElementById('completed');
      if (this.getCompleted) {
        this.selectCompleted = this.getCompleted.offsetTop;
        if (window.pageYOffset > this.selectCompleted - 100) {
          this.isUpcoming = false;
          this.isCompleted = true;
          this.isCanceled = false;
        }
      }
      this.getCanceled = document.getElementById('canceled');
      if (this.getCanceled) {
        this.selectCanceled = this.getCanceled.offsetTop;
        if (window.pageYOffset > this.selectCanceled - 100) {
          this.isUpcoming = false;
          this.isCompleted = false;
          this.isCanceled = true;
        }
      }
      this.isSticky = window.pageYOffset >= 250;
    }}
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
