
<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
                         [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
    <!--  <div class="gray-pass-bg border-0 py-3 pl-3" *ngIf="dataService.mobileView">
        <a class="font-18 blue-text font-18 primaray-text font-weight-bold" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);">Settings</a>
        <span class="font-18 gray-text-dark font-weight-bold"> / Employee Rewards </span>
      </div>-->

      <div class="container pb-5 pt-4 pt-lg-5" >
        <div class="row mx-lg-4" >

          <div class="col">
            <div class="pb-4 pb-lg-5 mb-3">
              <a class="font-16 blue-text text-underline" (click)="this.router.navigate(['/settings']);" i18n="@@menuItemSettings">Settings</a>
              <span class="font-16 gray-text-dark "> / <ng-container i18n="@@employeeRewards">Employee Rewards</ng-container> </span>
            </div>
          </div>

        </div>


        <div class="row mx-lg-4 pb-5" >
          <div class="col-lg-6" >
            <div class="row">
              <div class="col" [ngClass]="{'min-left-side-col-width': dataService.desktopView}" *ngIf="auth.companyInfo">
                <p class="font-18 gray-primaray-text mb-5" i18n="@@rewardsProgram">{{auth.companyInfo.name}} Rewards Program</p>

                <ng-container *ngIf="!auth.companyInfo.rewardProgram && !dataService.showSliders">
                <p class="gray-text-dark font-16 mb-5" i18n="@@rewardsProgMsg1">You do not have an active rewards program.</p>
                <p class="gray-text-dark font-16" i18n="@@rewardsProgMsg2">Rewards program incentivize your employees to book lower prices than the travel policy cap which result in more travel spend savings. </p>
                <div class="row d-flex justify-content-center mb-3 mt-5 buttons-width"  *ngIf="!auth.companyInfo.rewardProgram">
                  <div class="col-lg-7">
                    <button class="mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0" [ngClass]="{'m-progress': submitting}">
                      <span class="mx-auto font-14" (click)="createMyProgramAction()" i18n="@@createProg">Create My Program</span>
                    </button>
                  </div>
                  <div class="col-lg-5"></div>
                </div>
                </ng-container>

                <div *ngIf="(!auth.companyInfo.rewardProgram && dataService.showSliders) || auth.companyInfo.rewardProgram">
                <div class="form-item form-type-checkbox mt-3 pb-1 d-flex" >
                  <input [ngClass]="{'input-mobile': dataService.mobileView, 'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" class="mt-2" id="hotel-rewards" type="checkbox" [(ngModel)]="isHotelRewardsChecked" (change)="enableRewards('hotels');" />
                  <label [ngClass]="{'checkbox-mobile': dataService.mobileView}" for="hotel-rewards" class="gray-text-dark font-16" i18n="@@hotelRewards">Rewards employees for savings on hotel booking</label>
                </div>
                  <div class="d-flex gray-text">
                    <div class="color-border box bold-600 rounded font-12 ng-tns-c551-1 py-1 text-center"
                         [ngClass]="{'disabled-slider placeholder-text': !isHotelRewardsChecked, 'green-text': isHotelRewardsChecked,
                         'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}">{{hotelRewardsValue}}%</div>
                    <ng-container i18n="@@fromTotalBelowCap">From the total amount below policy cap</ng-container>
                  </div>
                  <div class="custom-slider" [ngClass]="{'disabled-slider': !isHotelRewardsChecked}">
                     <ng5-slider [(value)]="hotelRewardsValue" [options]="optionsHotels"   (userChangeEnd)="startEdit = true"  ></ng5-slider>
                  </div>
                  <p class="text-danger smaller">{{hotelErrorMsg}}</p>
                  <div class="form-item form-type-checkbox mt-3 pb-1 d-flex" >
                    <input [ngClass]="{'input-mobile': dataService.mobileView, 'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" class="mt-2" id="flight-rewards" type="checkbox" [(ngModel)]="isFlightRewardsChecked" (change)="enableRewards('flights');" />
                    <label [ngClass]="{'checkbox-mobile': dataService.mobileView}" for="flight-rewards" class="gray-text-dark font-16" i18n="@@flightRewards">Rewards employees for savings on flight booking</label>
                  </div>
                  <div class="d-flex gray-text">
                    <div class="color-border box bold-600 rounded font-12 ng-tns-c551-1 py-1 text-center"
                         [ngClass]="{'disabled-slider placeholder-text': !isFlightRewardsChecked, 'green-text': isFlightRewardsChecked,
                         'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}">{{flightRewardsValue}}%</div>
                    <ng-container i18n="@@fromTotalBelowCap">From the total amount below policy cap</ng-container>
                  </div>
                  <div class="custom-slider" [ngClass]="{'disabled-slider': !isFlightRewardsChecked}">
                    <ng5-slider [(value)]="flightRewardsValue" [options]="optionsFlights" (userChangeEnd)="startEdit = true"></ng5-slider>
                  </div>
                  <p class="text-danger smaller">{{flightErrorMsg}}</p>
                </div>

                <div class="row d-flex justify-content-center mb-3 mt-5 buttons-width"  *ngIf="!auth.companyInfo.rewardProgram && dataService.showSliders">
                  <div class="col-lg-7 order-lg-1" [ngClass]="{'pl-lg-2': dataService.isSiteLtr(), 'pr-lg-2': dataService.isSiteRtl()}">
                    <button class="mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0" [disabled]="!isHotelRewardsChecked && !isFlightRewardsChecked"
                            [ngClass]="{'m-progress': submitting}" (click)="createOrEditRewards('create')">
                      <span class="mx-auto font-14" i18n="@@createProgram">Create Program</span>
                    </button>
                  </div>
                  <div class="col-lg-5 pr-lg-2 order-lg-0">
                    <button (click)="dataService.showSliders = false"
                            class="mt-3 btn-block btn btn-outline-gray px-5 d-flex" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      <span class="mx-auto gray-text-dark font-14 sharp-font bold-500" i18n="@@cancelBtn">Cancel</span>
                    </button>
                  </div>
                </div>


                <div class="row d-flex justify-content-center mb-3 mt-5 buttons-width"  *ngIf="auth.companyInfo.rewardProgram">
                  <div class="col-lg-7 order-lg-1" [ngClass]="{'pl-lg-2': dataService.isSiteLtr(), 'pr-lg-2': dataService.isSiteRtl()}">
                    <button class="mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0" [disabled]="!startEdit"
                            [ngClass]="{'m-progress': submitting}" (click)="createOrEditRewards('edit')">
                      <span class="mx-auto font-14" i18n="@@save">Save</span>
                    </button>
                  </div>
                  <div class="col-lg-5 order-lg-0" [ngClass]="{'pr-lg-2': dataService.isSiteLtr(), 'pl-lg-2': dataService.isSiteRtl()}">
                    <button (click)="deleteRewardsProgram()"
                            class="mt-3 btn-block btn btn-outline-red px-5 d-flex" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      <span class="mx-auto font-14 sharp-font bold-500" i18n="@@deleteBtn">Delete</span>
                    </button>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div class="col-md-6"  *ngIf="dataService.desktopView">
            <div class="rounded-bottom p-4 font-14 right-box-style company-section-with-thick-border thick-top"
                 [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
              <p class="gray-primaray-text bold-600 mb-1 font-16 mb-5" i18n="@@howRewardsWorks">How Rewards Works</p>
              <p class="gray-text-dark" i18n="@@howRewardsWorks1">1- Create your travel policy and set cap for hotel/flight</p>
              <p class="gray-text-dark" i18n="@@howRewardsWorks2">2- For any booking lower than the policy cap, employee get percentage of the savings in form of dollar rewards</p>
              <p class="gray-text-dark" i18n="@@howRewardsWorks3">3- Rewards get accredited to employee wallet once the trip is completed</p>
              <p class="gray-text-dark" i18n="@@howRewardsWorks4">4- Employees can redeem their rewards for different brand vouchers. </p>
            </div>

            <div class="rounded-bottom p-4 font-14 right-box-style company-section-with-thick-border thick-top mt-5"
                 [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}"
                 *ngIf="dataService.showSliders">
              <p class="gray-primaray-text bold-600 mb-1 font-16 mb-5" i18n="@@hints">Hints</p>
              <p class="gray-text-dark" i18n="@@hints1">1- Recommended value for hotel percentage is 30%</p>
              <p class="gray-text-dark" i18n="@@hints2">2-  Recommended value for flight percentage is 20%</p>
              <p class="gray-text-dark" i18n="@@hints3">3- Employee will see the rewards value before they book</p>
            </div>
          </div>
        </div>
      </div>




    </div>
  </mat-sidenav-content>
</mat-sidenav-container>





