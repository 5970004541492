<div class="container mt-0 mt-lg-5 mb-5" [ngClass]="{'px-0': Service.mobileView}">
  <div class="row"
    [ngClass]="{'no-gutters': Service.mobileView}">

    <div class="col-md-8 custom-col8">
     <app-workspace-flight-booking-form *ngIf="Service.flightPricing"></app-workspace-flight-booking-form>
    </div>

    <div class="col-md-4 custom-col4 mt-extra" *ngIf="Service.flightData && Service.desktopView">

    <app-flight-summary [flightSummary]="Service.flightData.details"></app-flight-summary>

      <app-flight-pricing [pricing]="Service.flightPricing"></app-flight-pricing>

      <app-flight-rewards [rewardAmount]="Service.flightPricing.flightOffers[0].travelerPricings[0].userData.convertedRewardAmount"
      [rewardCurrency]="Service.flightPricing.flightOffers[0].travelerPricings[0].userData.convertedRewardCurrencyCode"></app-flight-rewards>


      <app-cost-reporting-manage></app-cost-reporting-manage>
    </div>
  </div>
</div>


<app-loaders></app-loaders>
