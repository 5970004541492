<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div
        class="container mt-4 mt-lg-5 mb-5 extra-padding"
        *ngIf="this.approvalsService.approvalData"
      >
        <div class="row mx-lg-4">
          <div class="col">
            <div class="pb-2 pb-lg-5 mb-3">
              <a
                class="font-16 blue-text text-underline"
                (click)="
                  this.router.navigate([
                    '/approvals'
                  ])
                "
                i18n="@@menuItemApprovals"
                >Approvals</a
              >
              <span class="font-16 gray-text-dark">
                / {{ this.approvalsService.approvalData.requester.firstName }}
              </span>
              <span class="font-16 gray-text-dark">
                ({{this.approvalsService.approvalData.pricingDetails.itineraries[0].searchSegmentSummary.arrivalAirport.city}})
              </span>
            </div>

            <ng-container [ngSwitch]="approvalsService.approvalData.status.toLowerCase()">
              <p *ngSwitchCase="'approved'" class="bold-600 font-16 green-text" i18n="@@approved">Approved</p>
              <p *ngSwitchCase="'pending_approval'" class="bold-600 font-16 yellow-text" i18n="@@pendingApproval">Pending Approval</p>
              <p *ngSwitchCase="'disapproved'" class="bold-600 font-16 text-danger mb-0" i18n="@@declined">Declined</p>
              <div *ngSwitchCase="'disapproved'">
                <ng-container *ngFor="let item of approvalsService.approvalData.operations">
                  <p class="font-12 gray-text">{{ item.reason }}</p>
                </ng-container>
              </div>
              <p *ngSwitchCase="'expired'" class="bold-600 font-16 gray-text" i18n="@@expired">Expired</p>
            </ng-container>


          </div>
        </div>
        <div class="row mx-lg-4">
          <div class="col-lg-8 custom-col8 pt-3">

         <app-approval-info></app-approval-info>


           <app-approval-operations *ngIf="dataService.desktopView"></app-approval-operations>

            <form
              class="mb-5"
              [formGroup]="approvalForm"
              *ngIf="
                dataService.desktopView &&
                this.approvalsService.approvalData.status.toLowerCase() ===
                  'pending_approval'
              "
            >
              <textarea
                name="comment"
                formControlName="comment"
                class="form-control pt-3 mt-5"
                i18n-placeholder="@@LeaveComments"
                placeholder="Leave your comments here"
                rows="6"
              ></textarea>
              <p class="text-danger font-12">{{ errorMsg }}</p>

              <div class="d-flex">
                <div
                  class="actions"
                  [ngClass]="{
                    'ml-auto': dataService.isSiteLtr(),
                    'mr-auto': dataService.isSiteRtl()
                  }"
                >
                  <button
                    class="approval-drop-down mt-5 btn-block blue_btn font-14 bold-500 px-4 d-flex"
                    (click)="approvalActions = !approvalActions"
                  >
                    <span
                      class="mx-auto sharp-font"
                      *ngIf="
                        this.approvalsService.approvalData.status.toLowerCase() ===
                        'pending_approval'
                      "
                      i18n="@@pending"
                      >Pending</span
                    >
                    <i
                      class="icon-double-arrows-down white padding-arrow font-12"
                    ></i>
                  </button>
                  <div
                    class="border rounded shadow font-14 pt-3 position-absolute bg-white drop"
                    *ngIf="approvalActions"
                  >
                    <p
                      class="pointer"
                      [ngClass]="{
                        'pl-4': dataService.isSiteLtr(),
                        'pr-4': dataService.isSiteRtl()
                      }"
                      (click)="
                        approveFlight(
                          this.approvalsService.approvalData.id,
                          approvalsService.approvalData.pricingDetails.sellingPriceConverted.grandTotal,
                          approvalsService.approvalData.pricingDetails.sellingPriceConverted.currency)"
                      i18n="@@approve">
                      Approve
                    </p>
                    <p
                      class="pointer"
                      [ngClass]="{
                        'pl-4': dataService.isSiteLtr(),
                        'pr-4': dataService.isSiteRtl()
                      }"
                      (click)="
                        disapproveFlight(
                          this.approvalsService.approvalData.id,
                          this.approvalsService.approvalData.requester.firstName +
                            ' ' +
                            this.approvalsService.approvalData.requester.lastName
                        )
                      "
                      i18n="@@decline"
                    >
                      Decline
                    </p>
                    <p
                      class="pointer"
                      [ngClass]="{
                        'pl-4': dataService.isSiteLtr(),
                        'pr-4': dataService.isSiteRtl()
                      }"
                      (click)="approvalSubmitMoreInfo()"
                      i18n="@@clarify"
                    >
                      Request Clarification
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!---------------->
          <div
            class="col-md-4 custom-col4 hotel-details-side pt-3"
            [ngClass]="{ 'px-0': dataService.mobileView }"
            *ngIf="this.approvalsService.approvalData">
            <app-flight-summary [flightSummary]="this.approvalsService.approvalData.pricingDetails"></app-flight-summary>
            <div  [ngClass]="{ 'px-3': dataService.mobileView }">
              <app-flight-pricing [pricing]="this.approvalsService.approvalData.pricingDetails" [isApprovals]="true"></app-flight-pricing>

              <app-flight-rewards [rewardAmount]="approvalsService.approvalData.pricingDetails.travelerPricings[0].userData.convertedRewardAmount"
                                  [rewardCurrency]="approvalsService.approvalData.pricingDetails.travelerPricings[0].userData.convertedRewardCurrencyCode">
              </app-flight-rewards>
            </div>


            <app-cost-reporting-details
                    [costCentersIds]="
                approvalsService.approvalData?.costCenter
                  ? [approvalsService.approvalData?.costCenter]
                  : ''
              "
                    [labelsIds]="
                approvalsService.approvalData?.labels
                  ? approvalsService.approvalData?.labels
                  : []">
            </app-cost-reporting-details>
          </div>
          <!---------------->
          <div class="col-lg-8">
            <ng-container *ngIf="dataService.mobileView">
              <app-approval-operations></app-approval-operations>
            </ng-container>
            <form
              class="mb-5"
              [formGroup]="approvalForm"
              *ngIf="
                dataService.mobileView &&
                this.approvalsService.approvalData.status.toLowerCase() ===
                  'pending_approval'
              "
            >
              <textarea
                name="comment"
                formControlName="comment"
                class="form-control pt-3 mt-5"
                i18n-placeholder="@@LeaveComments"
                placeholder="Leave your comments here"
                rows="6"
              ></textarea>
              <p class="text-danger font-12">{{ errorMsg }}</p>
              <div class="row">
                <div class="col-md-4">
                  <button
                    class="mt-5 btn-block blue_btn sharp-font bold-500 font-14 px-4 text-center"
                    (click)="
                      showApprovalsMobileActions(
                        this.approvalsService.approvalData.id,
                        approvalsService.approvalData.pricingDetails.sellingPriceConverted.currency + ' ' + approvalsService.approvalData.pricingDetails.sellingPriceConverted.grandTotal,
                        this.approvalsService.approvalData.requester.firstName +
                          ' ' + this.approvalsService.approvalData.requester.lastName)"
                    i18n="@@pending">
                    Pending
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
