import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor( public restApi: DataService, private titleService:Title) {
  this.titleService.setTitle('SafarHub Terms of Use | SafarHub'); }

  ngOnInit() {
    this.restApi.alerts = [];
  }

}
