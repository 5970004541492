import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DataService} from "../../services/data.service";
import {environment} from "../../../environments/environment";
import {VariablesService} from "../../services/vars.service";
import {AuthServiceMain} from "../../auth/auth.service";
import {MatDatepicker} from "@angular/material/datepicker";

@Component({
  selector: 'app-add-edit-flyer-program',
  templateUrl: './add-edit-flyer-program.component.html',
  styleUrls: ['./add-edit-flyer-program.component.scss']
})
export class AddEditFlyerProgramComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, public dataService: DataService, public auth: AuthServiceMain) { }
  selectedValue ={
  code: '',
  name: '',
  number: '',
  type: '' };
  programsList;
  programForm: FormGroup;
  data;
  submitting;
  submitted;
  errorMessage;
  programData;
  dataUser;
  selectedProgramCode;
  selectedProgramName;
  ngOnInit(): void {
    this.programForm = this.formBuilder.group({
      selectedProgram: ['', Validators.required],
      programNumber: ['', Validators.required]
    });
    if(this.dataService.desktopView){
      this.programData = this.dataService.rightSideDrawerData;
    } else {
      this.programData = this.dataService.BottomSheetData;
    }
    this.dataService.getAirLoyaltyPrograms().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.programsList = this.data.data;
          if(this.programData) {
            this.programsList.forEach( (element) => {
              if(element.code === this.programData.code){
                this.programForm.controls.selectedProgram.setValue(element);
                this.selectedValue.name = this.programData.name;
                this.selectedValue.code = this.programData.code;
                this.selectedValue.type = this.programData.type;
              }
            });
          }
        } else {
          this.errorMessage = this.data.message;
        }
      },
      error => {
        this.errorMessage = environment.messages.error;

      }
    );
    if(this.programData) {
      this.programForm.controls.programNumber.setValue(this.programData.number);
    }

  }
  selectChangeHandler(event: any) {

    this.selectedValue.code =  event.code;
    this.selectedValue.name = event.name;
    this.selectedValue.type = event.type;
   // this.selectedValue = event.target.value;

  }
  get f() {return this.programForm.controls; }
  editProgram() {
    this.dataService.alerts = [];
    this.submitting = true;
    this.submitted = true;
    if (this.programForm.invalid) {
      this.submitting = false;
      return;
    }
    this.selectedValue.number = this.programForm.controls.programNumber.value;
    const id = 'id';
    this.selectedValue[id] = this.programData.id;

    if(!this.selectedValue.name){
      this.selectedValue.name = this.programData.name;
    }
    if(!this.selectedValue.code){
      this.selectedValue.code = this.programData.code;
    }
    if(!this.selectedValue.type){
      this.selectedValue.type = this.programData.type;
    }
    this.dataService.updateFrequentFlyerProgram(this.selectedValue).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
        //  this.auth.fetchUserData();
          this.dataService.getUserInfo().subscribe(
              dataUser => {
                this.dataUser = dataUser;
                if (this.dataUser.errorCode === 0) {
                  this.auth.userInfo.frequentFlyerPrograms = this.dataUser.data.frequentFlyerPrograms;
                  localStorage.setItem('userInfo', JSON.stringify(this.auth.userInfo));
                  this.dataService.fetchUserData.next();
                } else {
                  this.dataService.messages(this.dataUser.message, 'error');
                }
              }, error => {
                this.dataService.messages(environment.messages.error, 'error');
              }
          );

          this.submitting = false;
          this.dataService.messages($localize`:@@flyerProgUpdated:Program was updated successfully`, 'success');
          this.dataService.BottomSheetOf();
          this.dataService.rightSideDrawerOff();
        } else {
          this.errorMessage = this.data.message;
          this.submitting = false;
        }
      },
      error => {
        this.errorMessage = environment.messages.error;
        this.submitting = false;
      }
    );
  }
  addProgram() {
    this.dataService.alerts = [];
    this.submitting = true;
    this.submitted = true;
    if (this.programForm.invalid) {
      this.submitting = false;
      return;
    }
    this.selectedValue.number = this.programForm.controls.programNumber.value;
    this.dataService.addFlyerProgram(this.selectedValue).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.getUserInfo().subscribe(
              dataUser => {
                this.dataUser = dataUser;
                if (this.dataUser.errorCode === 0) {
                  this.auth.userInfo.frequentFlyerPrograms = this.dataUser.data.frequentFlyerPrograms;
                  localStorage.setItem('userInfo', JSON.stringify(this.auth.userInfo));
                  this.dataService.fetchUserData.next();
                } else {
                  this.dataService.messages(this.dataUser.message, 'error');
                }
              }, error => {
                this.dataService.messages(environment.messages.error, 'error');
              }
          );
          this.submitting = false;
          this.dataService.messages($localize`:@@flyerProgAdded:Program was added successfully`, 'success');
          this.dataService.BottomSheetOf();
          this.dataService.rightSideDrawerOff();
        } else {
          this.errorMessage = this.data.message;
          this.submitting = false;
        }
      },
      error => {
        this.errorMessage = environment.messages.error;
        this.submitting = false;
      }
    );
  }
}
