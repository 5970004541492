import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";

import { environment } from "../../environments/environment";
import { DataService } from "./data.service";
import { AuthServiceMain } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class FireService {
  messaging;
  fireBase;
  constructor(public Service: DataService, public Auth: AuthServiceMain) {
    //  FireService
  }

  initializeAppFirebase() {
    firebase.initializeApp(environment.firebaseConfig);
    this.fireBase = firebase;
  }

  remoteConfig() {
    const remoteConfig = this.fireBase.remoteConfig();
    remoteConfig.settings = {
      minimumFetchIntervalMillis: environment.firebaseConfigTimeOut,
      fetchTimeoutMillis: 300000,
    };

    remoteConfig
      .fetchAndActivate()
      .then(() => {
        this.Service.RemoteConfg = remoteConfig.getAll();
      })
      .catch((err) => {});
  }

  getPermissionMessaging() {
    this.messaging = firebase.messaging();
    this.Service.PushNotificationsPopUpOf();

    this.messaging.onTokenRefresh(() => {
      this.messaging
        .getToken()
        .then((refreshedToken) => {
          // Indicate that the new Instance ID token has not yet been sent to the
          // app server.
          // Send Instance ID token to app server.
          this.Service.savePushToken(
            refreshedToken,
            this.Auth.LeadEmail
          ).subscribe();
        })
        .catch((err) => {});
    });

    this.messaging
      .requestPermission()
      .then(() => {
        return this.messaging.getToken();
      })
      .then((token) => {
        this.Service.savePushToken(token, this.Auth.LeadEmail).subscribe();
      })
      .catch((err) => {});
  }
  //
}
