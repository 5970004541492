import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {AuthServiceMain} from '../../auth/auth.service';


declare let gtag: any;
@Component({
  selector: 'app-company-invitation-signup',
  templateUrl: './company-invitation-signup.component.html',
  styleUrls: ['./company-invitation-signup.component.scss']
})
export class CompanyInvitationSignupComponent implements OnInit {
  env = environment;
  signUpForm: FormGroup;
  submitted = false;
  submitting = false;
  companyID;
  companyDomain;
  companyName;
  data;

  // showPassword = false;
  passwordValue = null;
  signUpErrorMsg = false;
  errorMsg = '';
  logoUrl;
  code = 'code';
  codeValue;
  email;
  wrongPass = '';
  wrongPassFlag = false;
  invalidInvitation;
  letterRegExpression = /^[a-zA-Z\s]*$/;  

  constructor(private route: ActivatedRoute, public router: Router, private formBuilder: FormBuilder, public dataService: DataService,
              public auth: AuthServiceMain) {
    if(this.auth.isLogedIn && this.auth.companyInfo) {
      this.router.navigate(['/user/profile']);
    }
  }


  ngOnInit(): void {
    if(this.auth.isLogedIn && this.auth.companyInfo) {
      this.router.navigate(['/user/profile']);
    }
    this.dataService.alerts = [];
    this.route.queryParams.subscribe(params => {
      this.codeValue =  params[this.code];
    });


    this.dataService.getEmailByInvitationCode(this.codeValue).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          if (this.data.data) {
          //  this.companyID = this.data.data.id;
           // this.companyDomain = '@' + this.data.data.companyDomain;
          //  this.companyName = this.data.data.name;
           // this.logoUrl = this.data.data.logoUrl;
            // this.showPassword = !this.data.data.exists;
           // this.email = this.data.data.email.substring(0, this.data.data.email.indexOf('@'));
            this.companyID = this.data.data.companyId;
            this.email = this.data.data.email;
            this.getCompanyInfoById(this.companyID);
         //  this.companyDomain = '@' + this.data.data.email.substring(this.data.data.email.indexOf('@') + 1, this.data.data.email.length);
          } else {

          }
        } else {
         // this.dataService.messages(this.data.message, 'error');
          // this.errorMsg = this.data.message;
          this.invalidInvitation = $localize`:@@invalidInvitation:This invitation is no longer valid`;

        }
      }, error => {
      //  this.dataService.messages(environment.messages.error, 'error');
        this.errorMsg = environment.messages.error;
      }
    );

    this.signUpForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(100)]],
      firstName: ['', [Validators.required, Validators.pattern(this.letterRegExpression), Validators.minLength(1)]],
      lastName: ['', [Validators.required, Validators.pattern(this.letterRegExpression), Validators.minLength(1)]],
    }, {});
  }
  get f() { return this.signUpForm.controls; }


  getCompanyInfoById(companyID){
    this.dataService.companyInfoById(companyID).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            if (this.data.data) {
              // this.companyID = this.data.data.id;
              this.companyDomain = '@' + this.data.data.domain;
              this.companyName = this.data.data.name;
              this.logoUrl = this.data.data.logoUrl;
            }
          } else {
            this.errorMsg = this.data.message;
          }
        }, error => {
          this.errorMsg = environment.messages.error;
        }
    );
  }

  onSubmitSignUp() {
    this.wrongPassFlag = false;
    this.errorMsg = '';
    this.submitted = true;
    this.submitting = true;
/*    if (this.showPassword) {
      this.passwordValue = this.signUpForm.controls.password.value;
      if (this.passwordValue === null || this.passwordValue === '') {
        this.wrongPass = 'Password id required';
        this.wrongPassFlag = true;
        this.submitting = false;
        return;
      }
    }*/
    if (this.signUpForm.invalid ) {
      this.submitting = false;
      return;
    }
    this.dataService.registerToCompany(this.codeValue, this.signUpForm.controls.password.value,
      this.signUpForm.controls.firstName.value, this.signUpForm.controls.lastName.value).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          gtag('event', "sign_up", {
            method: "User"
          });
          this.auth.handleAuthentication(
            this.auth.token = this.data.data.token,
          );
          this.auth.fetchUserData();
         // this.auth.fetchCompanyData();
          this.router.navigate(['/' + this.companyID]);
          this.submitting = false;
        } else {
          this.submitting = false;
          this.errorMsg = this.data.message;
        }
      }, error => {
        this.submitting = false;
        this.errorMsg = environment.messages.error;
      }
    );

  }
}
