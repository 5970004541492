import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-bulk-invite-users',
  templateUrl: './bulk-invite-users.component.html',
  styleUrls: ['./bulk-invite-users.component.scss']
})
export class BulkInviteUsersComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, public dataService: DataService) {
  }
  showProcessing = false;
  progress = 0;
  env = environment;
  bulkInvitationForm: FormGroup;
  submitted = false;
  data;
  errorMsg = '';
  fileUpload = false;
 // fileName = $localize`:@@chooseFile:Choose file`;
  //showFileName = false;
  fileObj;
  submitting = false;
  // formData = new FormData();
  csvCounter = 0;
  csvData = [];
  totalProcessed = 0;
  successfulInvitations = 0;
  failedInvitations = 0;
  showUploadForm = true;
  showProcessingCSV = false;
  showCounters = false;
  showFailedDetails = false;
  fileToProcess;
//  headerLine = true;
  csvArray = [];
  invitationsList;
  errorDetailsArray = [];
  message = '';
  isDrag = false;
  ngOnInit(): void {
    this.bulkInvitationForm = this.formBuilder.group({
      file: ['', Validators.required],
    }, {});
  }

  get f() {
    return this.bulkInvitationForm.controls;
  }
  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }
  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    this.showProcessing = false;
    this.message = '';
    var mimeType = files[0].type;
    if (mimeType.match(/vnd.ms-excel\/*/) == null && !mimeType.match(/csv*/)) {
      this.message = $localize`:@@supportCSV:Only CSV files are supported`;
      return;
    } else {
     // this.fileName = files[0].name;
      this.fileToProcess = files[0];
      this.bulkInvitationForm.get('file').setValidators(null);
      this.isDrag = true;
      //this.showFileName = true;
    /*  const reader = new FileReader();
      reader.onload = () => {
        const text = reader.result;
        // convert text to json here
        this.invitationsList = this.csvToJSON(text);
      };
      reader.readAsText(this.fileToProcess);*/

      this.uploadFilesSimulator();
    }

  }
  deleteFile(){
    this.progress = 0;
    this.submitted = false;
    this.fileToProcess = null;
    this.bulkInvitationForm.get('file').setValue(null);
    this.bulkInvitationForm.get('file').setValidators([Validators.required]);
    this.bulkInvitationForm.get('file').updateValueAndValidity();
    this.isDrag = false;
  }
  startOverReset() {
    this.deleteFile();
    this.showCounters = false;
    this.showUploadForm = true;
    this.showFailedDetails = false;
  }
  uploadFilesSimulator() {
    this.showProcessing = true;
    this.progress = 0;
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      // convert text to json here
      this.invitationsList = this.csvToJSON(text);
    };
    reader.readAsText(this.fileToProcess);

    setTimeout(() => {
        const progressInterval = setInterval(() => {
          if (this.progress === 100) {
            clearInterval(progressInterval);
          } else {
            this.progress += 5;
          }
        }, 100);

    }, 400);
  }
  onFileSelect(event) {
    this.message = '';
    this.showProcessing = false;
     this.fileUpload = this.bulkInvitationForm.value.file;
     this.fileToProcess = event.target.files[0];

    var mimeType = this.fileToProcess.type;
    if (mimeType.match(/vnd.ms-excel\/*/) == null && !mimeType.match(/csv*/)) {
      this.message = $localize`:@@supportCSV:Only CSV files are supported`;
      return;
    } else {
      this.uploadFilesSimulator();
    }



}


  /*onFileSelect(event) {
      this.fileUpload = this.bulkInvitationForm.value.file;
      this.fileName = event.target.value.split('\\').pop();
      this.fileToProcess = event.target.files[0];
      this.showFileName = true;
      const reader = new FileReader();
      reader.onload = () => {
      const text = reader.result;
      // convert text to json here
      this.invitationsList = this.csvToJSON(text);
    };
      reader.readAsText(this.fileToProcess);

}*/
  csvToJSON(csv) {
    const lines = csv.split(/\r?\n|\r/);
    const result = [];
    const headers = ['email' , 'teamName'];
    const firstLine = lines[0].split(',');
    let i = 1;
    if (!firstLine.toString().trim().includes('User Email,Team Name(Optional)')) {
      i = 0;
    }
    for (i; i < lines.length; i++) {
      const obj = {};
      const currentLine = lines[i].split(',');
      if(currentLine !== '') {
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentLine[j];
        }
      }
      if (obj[headers[0]] !== '' && obj[headers[0]] !== undefined) {
        result.push(obj);
      }
    }
    this.totalProcessed = result.length;
   /* if (firstLine.toString().trim() !== 'User Email,Team Name(Optional)') {
      // no header line
      this.headerLine = false;
      this.totalProcessed = result.length;
    }*/
   // return JSON.stringify(result); // JSON
    return result;
  }

  processData() {
    debugger
    this.submitted = true;
    if (this.bulkInvitationForm.invalid && !this.isDrag) {
      return;
    }
    this.showUploadForm = false;
    this.showProcessingCSV = true;
    for (let i = 0; i < this.invitationsList.length; i++) {
      const email =  this.invitationsList[i].email;
      const teamNameValue = this.invitationsList[i].teamName;
      const last = ((i === this.invitationsList.length - 1) ? true : false);
      let sendData;
      if (teamNameValue && teamNameValue.trim() !== '') {
        sendData = {
          emails: email.trim(),
          teamName: teamNameValue.trim()
        };
      } else {
        sendData = {
          emails: email.trim()
        };
      }
      this.sendInvitation(sendData, last);
    }

  }
  sendInvitation(sendData, last) {
    this.csvCounter = 0;
    this.failedInvitations = 0;
    this.successfulInvitations = 0;
    this.errorDetailsArray = [];
    this.dataService.inviteToCompany(sendData).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          if (last) {
            this.showCounters = true;
            this.showProcessingCSV = false;
            this.dataService.getPendingUsers();
          }
          this.successfulInvitations++;
          this.csvCounter++;
        } else {
          const obj = {
            email: sendData.emails,
            errorMsg: this.data.message
          };
          this.errorDetailsArray.push(obj);
          this.showCounters = true;
          this.showProcessingCSV = false;
          this.failedInvitations++;
          this.csvCounter++;
          this.dataService.getPendingUsers();

        }

      },
      error => {
        this.showCounters = true;
        this.showProcessingCSV = false;
        this.failedInvitations++;
        this.csvCounter++;
        this.dataService.getPendingUsers();
      }
    );
  }

}
