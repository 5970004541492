<form class="container invite-page form" #manageEntityForm="ngForm" (ngSubmit)="save(manageEntityForm)">
  <div class="share-box-center mx-auto d-block px-0">
    <div class="col d-flex justify-content-center">
      <div class="form-group col-md-9 mt-3">
        <p class="gray-text mb-0 pb-1" i18n="@@centerName" *ngIf="isCenter">Cost Center Name</p>
        <p class="gray-text mb-0 pb-1" i18n="@@labelName" *ngIf="!isCenter">Label Name</p>
        <input type="text" class="form-control rounded" [ngClass]="{ 'border-red': !validForm }" name="nameControl"
          #nameControl="ngModel" [placeholder]="placeholderText" [(ngModel)]="name" (ngModelChange)="changeName()"
          required />
      </div>
    </div>

    <div *ngIf="!validForm" class="col d-flex justify-content-center">
      <div class="form-group col-md-9 mx-0">
        <div class="empty-name" i18n="@@fieldRequired">This field is required</div>
      </div>
    </div>
  </div>

  <div class="share-box-center mx-auto d-block px-0">
    <div class="col d-flex justify-content-center">
      <div class="form-group col-md-9 mt-3">
        <p class="gray-text mb-0 pb-1" i18n="@@centerDescription" *ngIf="isCenter">Cost Center Description</p>
        <p class="gray-text mb-0 pb-1" i18n="@@labelDescription" *ngIf="!isCenter">Label Description</p>
        <input type="text" class="form-control rounded" name="descriptionControl" #descriptionControl="ngModel"
          placeholder="Enter description" i18n-placeholder="@@enterDescription" [(ngModel)]="description" />
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-3 mx-5"
    [ngClass]="{'ml-4': dataService.isSiteLtr(), 'mr-4': dataService.isSiteRtl()}" *ngIf="dataService.desktopView">
    <button class="btn-outline-gray btn font-14 py-1 px-5 sharp-font cancel-btn" (click)="cancel()"
      i18n="@@cancelBtn">Cancel </button>
    <button class="btn blue_btn py-0 px-5 font-14 sharp-font" type="submit"
      [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl(), 'm-progress': submitting}"
      >{{saveBtnText}}</button>
  </div>

  <div class="mt-5" *ngIf="dataService.mobileView">
    <div class="d-flex justify-content-end">
      <button class="btn blue_btn font-12 py-1 mb-4 px-5 w-100 sharp-font" type="submit"
        [ngClass]="{'m-progress': submitting}">{{saveBtnText}}</button>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn-outline-gray btn font-12 py-1 px-5 w-100 sharp-font" (click)="cancel()"
        i18n="@@cancelBtn">Cancel </button>
    </div>
  </div>

  <div *ngIf="errorMsg">
    <div class="empty-name pt-3" [ngClass]="{ 'text-right': dataService.desktopView}">{{errorMsg}}</div>
  </div>
</form>