<div class="mt-4" [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}" *ngIf="dataService.mobileView">
  <div class="d-flex mb-4">
    <i class="icon-warning-msg pt-1" [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
    <p class="gray-text-dark font-16" i18n="@@removeImageMsg">Are you sure you want to remove your profile image? This action cannot be undone!</p>
  </div>
  <div class="d-flex justify-content-center">
    <button class="w-100 btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5 mb-3" (click)="this.removeUserProfileImage(); " i18n="@@removeBtn">Remove</button>
  </div>
  <div class="d-flex justify-content-center">
    <button class="w-100 btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" (click)="this.dataService.BottomSheetOf()" i18n="@@cancelBtn">Cancel </button>
  </div>
</div>

<div *ngIf="dataService.desktopView">
  <div class="justify-content-center">
    <div class="d-flex mb-4">
      <i class="icon-warning-msg pt-1" [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
      <p class="gray-text-dark font-16" i18n="@@removeImageMsg">Are you sure you want to remove your profile image? This action cannot be undone!</p>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" (click)="this.dataService.modalOf()" i18n="@@cancelBtn">Cancel </button>
      <button class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5"
              [ngClass]="{'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"
              (click)="this.removeUserProfileImage();" i18n="@@removeBtn">Remove</button>
    </div>
  </div>
</div>
