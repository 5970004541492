import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {SearchUser} from "../../../shared/model/search-user";
import {UserService} from "../../../services/user.service";
import {environment} from "../../../../environments/environment";
import {AuthServiceMain} from "../../../auth/auth.service";
import {DataService} from "../../../services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SearchUserSelectionService} from "../../../services/search-user-selection/search-user-selection.service";
@Component({
  selector: 'app-user-selection',
  templateUrl: './user-selection.component.html',
  styleUrls: ['./user-selection.component.scss']
})
export class UserSelectionComponent implements OnInit {

  filteredUsers: SearchUser[] = [];
  query: string = '';
  dataUsers;
  @ViewChild("userInput") userInput: ElementRef;
  maxNoUserToShow: number = 3;
  data;
  defaultUsers: SearchUser[] = [];

  constructor(public userService: UserService,
              public Auth: AuthServiceMain,
              public restApi: DataService,
              public router: Router,
              private cdr: ChangeDetectorRef,
              public searchUserSelectionService: SearchUserSelectionService,
              public route: ActivatedRoute) { }
  ngOnInit(): void {
    this.searchUserSelectionService.currentLoggedInUser = new SearchUser({
      description: `${this.Auth.userInfo.name} ${this.Auth.userInfo.lastName}`,
      id: this.Auth.userInfo.id,
      email: this.Auth.userInfo.email,
      teamName: this.Auth.userInfo.teamName,
      selected: false,
      firstName: this.Auth.userInfo.name,
      lastName: this.Auth.userInfo.lastName,
      inactive: this.Auth.userInfo.inactive,
      profileImageBackgroundHexColor: this.Auth.userInfo.profileImageBackgroundHexColor,
      profileImage: this.Auth.userInfo.imageUrl,
    });
    if (this.Auth.isOwner() || this.Auth.isAdmin()) {
      this.defaultUsers.push(this.searchUserSelectionService.guest);
      this.defaultUsers.push(this.searchUserSelectionService.currentLoggedInUser);
    } else{
      this.defaultUsers.push(this.searchUserSelectionService.currentLoggedInUser);
      this.searchUserSelectionService.selectedUser = this.searchUserSelectionService.currentLoggedInUser;
      this.searchUserSelectionService.userControl.setValue(this.searchUserSelectionService.selectedUser.description);
      this.searchUserSelectionService.disableAction.next(true);
      this.searchUserSelectionService.userControl.disable();
    }
  }



  showDefaultUsers(): void {
    if (!this.query || this.query === "null") {
      this.filteredUsers = [...this.defaultUsers];
    }
  }

  onInputChange(): void {
    this.query = this.searchUserSelectionService.userControl.value;
    // Decode the URL-encoded string
    const decodedString = decodeURIComponent(this.query);

  // Split the string by space and take the first part (first name)
    this.query = decodedString.split(' ')[0];
    if (this.query !== "null" && this.query.length >= 3 && (this.Auth.isOwner() || this.Auth.isAdmin())) {
      this.fetchUsers(this.query);
    } else {
      this.showDefaultUsers();
    }
  }

  fetchUsers(query: string) {
    this.restApi.getCompanyUsersList(query).subscribe(
        (dataList) => {
          this.dataUsers = dataList;
          if (this.dataUsers.errorCode === 0) {
            this.dataUsers = this.dataUsers.data;
            if(this.dataUsers){
              this.filteredUsers =  this.dataUsers.filter((user: any) => !user.inactive);
            }
          } else {
            this.restApi.messages(this.data.message, "error");
          }
        },
        (error) => {
          this.restApi.messages(environment.messages.error, "error");
        }
    );
  }

  selectUser(user: any): void {
    this.searchUserSelectionService.submitted = false;
    this.searchUserSelectionService.selectedUser = user;
    this.searchUserSelectionService.userControl.setValue(this.searchUserSelectionService.selectedUser.description);
    console.log(this.searchUserSelectionService.userControl.value);
    this.query = user.description;
    this.filteredUsers = []; // Clear the suggestions
    if(this.restApi.mobileView){
      this.searchUserSelectionService.showUsersAutoCompletePopUp = false;
      this.restApi.enableBodyScroll();
    }
  }
  /********* guest changes *********/
  reset() {
    if(this.restApi.desktopView){
      setTimeout(() => {
        this.query = '';
        this.filteredUsers = []; // Clear the suggestions
        if (this.searchUserSelectionService.userControl.value !== this.searchUserSelectionService?.selectedUser?.description || !this.searchUserSelectionService.userControl.value) {
          this.userInput.nativeElement.value = "";
          this.searchUserSelectionService.userControl.setValue(null);
          this.searchUserSelectionService.selectedUser = null;
        }
      }, 400);
    }
  }
  autocompleteOnFocus(event) {
    event.target.select();
  }


  showUsersPopUp() {
    setTimeout(() => {
      if (this.searchUserSelectionService.selectedUser) {
        this.searchUserSelectionService.userControl.setValue(this.searchUserSelectionService.selectedUser?.description);
      }
      this.cdr.markForCheck(); // Trigger Angular to detect changes
      const input = document.getElementById('userInput') as HTMLInputElement;
      input.select();
      input.focus();
    }, 200);
    return;
  }



  // optionClicked(event: Event, user: SearchUser) {
  //   this.searchUserSelection.selectedUser = user;
  //   this.showUsersAutoCompletePopUp = false;
  //   this.restApi.enableBodyScroll();
  // }
}
