import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-accepted-wallet-section',
  templateUrl: './accepted-wallet-section.component.html',
  styleUrls: ['./accepted-wallet-section.component.scss']
})
export class AcceptedWalletSectionComponent implements OnInit {

  constructor(public dataService: DataService) { }
  ngOnInit(): void {
  }

}
