import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
// import {DomSanitizer} from '@angular/platform-browser';



interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {


  message: any;
  constructor(public restApi: DataService) { }

  ngOnInit() {


    // this.message = this.sanitizer.bypassSecurityTrustHtml(this.message);

  }


  closess(alert: Alert) {
    this.restApi.alerts.splice(this.restApi.alerts.indexOf(alert), 1);

  }

  closesAlertsOverRoute(alert: Alert) {
    this.restApi.alertsOverRoute.splice(this.restApi.alertsOverRoute.indexOf(alert), 1);

  }

}

