<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pt-4 pt-lg-5 pb-0 pb-5">
        <div class="mx-lg-5">
          <div class="row">
            <div class="col">
              <div class="pb-4 pb-lg-5 mb-3">
                <a
                  class="font-16 blue-text text-underline"
                  (click)="this.router.navigate(['/teams'])"
                  i18n="@@teams"
                  >Teams</a
                >
                <span
                  class="font-16 gray-text-dark"
                  *ngIf="this.dataService.teamInfo"
                >
                  / {{ this.dataService.teamInfo.name }}
                </span>
              </div>
            </div>
          </div>

          <div
            [ngClass]="{
              'mb-5 rounded border pt-4 workspace-rounded-container':
                dataService.desktopView,
              'pl-4': dataService.desktopView && dataService.isSiteLtr(),
              'pr-4': dataService.desktopView && dataService.isSiteRtl()
            }"
          >
            <div *ngIf="this.dataService.teamInfo">
              <div class="d-flex">
                <div>
                  <p
                    class="team-first-letter font-16 mx-auto border text-center font-weight-bold primaray-text"
                  >
                    {{
                      dataService.teamInfo.name.substring(0, 1).toUpperCase()
                    }}
                  </p>
                </div>
                <div
                  class="details pt-1"
                  [ngClass]="{
                    'pl-3': dataService.isSiteLtr(),
                    'pr-3': dataService.isSiteRtl()
                  }"
                >
                  <p class="mb-0">
                    <span class="font-16 gray-primaray-text">
                      {{ dataService.teamInfo.name }}
                    </span>
                    <span
                      class="blue-text font-14-to-16 pointer px-2 px-lg-3 pt-3"
                      *ngIf="
                        dataService.teamInfo && dataService.teamInfo.id !== '0'
                      "
                      (click)="editTeam(dataService.teamInfo.id)"
                      i18n="@@edit"
                      >Edit</span
                    >
                  </p>
                  <p
                    class="mb-0 gray-text"
                    [innerHTML]="formatDate(dataService.teamInfo.creationDate)"
                  ></p>
                </div>
              </div>

              <p class="gray-text mb-0 pt-5" i18n="@@teamManager">
                Team Manager
              </p>
              <p class="font-16 gray-primaray-text mb-5">
                <span class="mb-0" *ngIf="dataService.teamInfo.teamManager">{{
                  dataService.teamInfo.teamManager
                }}</span>
                <span
                  class="mb-0"
                  *ngIf="!dataService.teamInfo.teamManager"
                  i18n="@@noManager"
                  >No Manager</span
                >
              </p>

              <p class="gray-text mb-0" i18n="@@travelPolicy">Travel Policy</p>
              <p class="font-16 gray-primaray-text mb-5">
                <span *ngIf="dataService.teamInfo.policyName">{{
                  dataService.teamInfo.policyName
                }}</span>
                <span
                  *ngIf="!dataService.teamInfo.policyName"
                  i18n="@@unassigned"
                  >Unassigned</span
                >
              </p>

              <p class="gray-text mb-2 mb-lg-1" i18n="@@paymentMethods">
                Payment Methods
              </p>
              <div class="font-16 gray-primaray-text mb-5">
                <div *ngFor="let item of teamPayments">
                  <p class="mb-lg-0">
                    <span
                      [ngClass]="{
                        'payment-item table-header font-12 rounded':
                          dataService.mobileView
                      }"
                      >{{ item }}</span
                    >
                  </p>
                </div>

                <span *ngIf="!teamPayments.length" i18n="@@noPayment"
                  >No Payment Methods</span
                >
              </div>

              <p class="gray-text mb-2 mb-lg-1" i18n="@@wallets">
                Wallets
              </p>
              <div class="font-16 gray-primaray-text mb-5">
                <div *ngFor="let item of teamWallets">
                  <p class="mb-lg-0">
                    <span
                      [ngClass]="{
                        'payment-item table-header font-12 rounded':
                          dataService.mobileView
                      }"
                      >{{ item }}</span
                    >
                  </p>
                </div>

                <span *ngIf="!teamWallets.length" i18n="@@noWallet"
                  >No Wallets</span
                >
              </div>
            </div>

            <div
              class="mb-3 mt-5"
              [ngClass]="{ 'd-flex': dataService.desktopView }"
              *ngIf="dataService.teamInfo && dataService.teamInfo.id !== '0'"
            >
              <div
                class="min-width-workspace-btn"
                *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
              >
                <button
                  class="btn-outline-red btn py-1 px-5 mb-3 font-12 btn-min-width sharp-font font-14"
                  (click)="
                    removeTeam(
                      dataService.teamInfo.id,
                      dataService.teamInfo.name
                    )
                  "
                  i18n="@@deleteBtn"
                >
                  Delete
                </button>
              </div>
              <div class="min-width-workspace-btn">
                <button
                  (click)="addMembers()"
                  class="btn-block btn btn-outline-blue font-14 px-5 sharp-font bold-500"
                  i18n="@@addMembers"
                >
                  Add Members
                </button>
              </div>
            </div>
          </div>
          <div
            class="mt-5 items-full-list team-details"
            *ngIf="
              dataService.teamInfo &&
              (dataService.teamInfo.membersCount > 0 || this.showHeaderTable)
            "
          >
            <div class="row mx-0">
              <div class="col-lg-3 px-0 pt-1">
                <div class="form-group">
                  <input
                    class="form-control"
                    id="search"
                    type="text"
                    name="search"
                    i18n-placeholder="@@searchForUsers"
                    placeholder="Search for users"
                    (focus)="filterByText($event)"
                    (keyup)="filterByText($event)"
                  />
                </div>
              </div>
              <div
                class="col-lg-9"
                [ngClass]="{ 'px-0': dataService.mobileView }"
              >
                <div
                  class="row font-16-to-14 font-weight-bold filtering blue-text rounded pb-2"
                >
                  <div class="col-auto py-3 px-0">
                    <span
                      class="pointer px-lg-4"
                      [ngClass]="{
                        'border-right': dataService.isSiteLtr(),
                        'border-left': dataService.isSiteRtl(),
                        active: dataService.teamMembersSortType === 'all',
                        'pl-4 pr-3': dataService.mobileView
                      }"
                      (click)="filterBy('all')"
                      i18n="@@all"
                      >All
                      <span
                        *ngIf="
                          this.dataService.teamMembers &&
                          this.dataService.teamMembers.length
                        "
                        >({{
                          this.dataService.teamMembers.length +
                            this.teamPendingCounter
                        }})</span
                      >
                      <span
                        *ngIf="
                          !this.dataService.teamMembers ||
                          !this.dataService.teamMembers.length
                        "
                        >({{ this.teamPendingCounter }})</span
                      >
                    </span>
                  </div>
                  <div class="col-auto py-3 px-0">
                    <span
                      class="pointer px-3 px-lg-4"
                      [ngClass]="{
                        'border-right': dataService.isSiteLtr(),
                        'border-left': dataService.isSiteRtl(),
                        active: dataService.teamMembersSortType === 'active'
                      }"
                      (click)="filterBy('active')"
                      i18n="@@active"
                      >Active</span
                    >
                  </div>
                  <div class="col-auto py-3 px-0">
                    <span
                      class="pointer px-3 px-lg-4"
                      [ngClass]="{
                        'border-right': dataService.isSiteLtr(),
                        'border-left': dataService.isSiteRtl(),
                        active: dataService.teamMembersSortType === 'inactive'
                      }"
                      (click)="filterBy('inactive')"
                      i18n="@@inActive"
                      >Inactive</span
                    >
                  </div>
                  <div class="col-auto py-3 px-0">
                    <span
                      class="pointer px-lg-4"
                      [ngClass]="{
                        'mr-lg-auto': dataService.isSiteLtr(),
                        'ml-lg-auto': dataService.isSiteRtl(),
                        active: dataService.teamMembersSortType === 'pending',
                        'pr-4 pl-3': dataService.mobileView
                      }"
                      (click)="filterBy('pending')"
                      i18n="@@pending"
                      >Pending</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="
                dataService.filteredTeamMembersList &&
                dataService.filteredTeamMembersList.length > 0 &&
                (dataService.teamMembersSortType === 'all' ||
                  dataService.teamMembersSortType === 'active' ||
                  dataService.teamMembersSortType === 'inactive')
              "
            >
              <div
                class="row px-3 py-3 mx-0 mb-3 gray-primaray-text bold-600 table-header rounded"
                *ngIf="dataService.desktopView"
              >
                <div class="col-4" i18n="@@name">Name</div>
                <div class="col-2" i18n="@@role">Role</div>
                <div class="col-5"></div>
                <div class="col-1"></div>
              </div>
              <div
                class="gray-primaray-text font-14"
                *ngFor="
                  let item of dataService.filteredTeamMembersList;
                  let i = index
                "
              >
                <div
                  class="row px-lg-3 hover-row-list py-4 mx-0"
                  [ngClass]="{
                    pointer: this.auth.isAdmin() || this.auth.isOwner(),
                    'border-bottom': dataService.desktopView,
                    'border rounded shadow-sm mb-4': dataService.mobileView
                  }"
                  (click)="viewUser(item.id)"
                >
                  <div class="col-lg-4">
                    <div class="d-flex">
                      <img
                        width="35"
                        height="35"
                        loading="lazy"
                        *ngIf="item.profileImage"
                        class="user-img d-inline-block"
                        [ngClass]="{
                          'mr-3': dataService.isSiteLtr(),
                          'ml-3': dataService.isSiteRtl(),
                           'img-border': userService.imageHasError
                      }"
                        [src]="userService.imageHasError ? userService.fallbackSrc : item.profileImage"
                        (error)="userService.imageHasError = true"
                      />
                      <div
                        class="name-first-letters small"
                        [ngClass]="{
                          'mr-3': dataService.isSiteLtr(),
                          'ml-3': dataService.isSiteRtl()
                        }"
                        *ngIf="!item.profileImage"
                        [ngStyle]="{
                          'background-color':
                            item.profileImageBackgroundHexColor
                        }"
                      >
                        {{ item.firstName.substring(0, 1).toUpperCase()
                        }}{{ item.lastName.substring(0, 1).toUpperCase() }}
                      </div>
                      <div class="dotted-text">
                        <p class="mb-0 gray-primaray-text">
                          <span
                            *ngIf="item.firstName"
                            class="pr-2"
                            [ngClass]="{ 'pl-4': dataService.isSiteRtl() }"
                            >{{ item.firstName }}</span
                          ><span
                            [ngClass]="{ 'pr-4': dataService.isSiteLtr() }"
                            *ngIf="item.lastName"
                            >{{ item.lastName }}</span
                          ><span
                            class="text-danger"
                            *ngIf="item.inactive && dataService.mobileView"
                            i18n="@@inActive"
                            >Inactive</span
                          >
                        </p>
                        <p
                          class="gray-text mb-0 dotted-text dotted-text-direction"
                        >
                          {{ item.email }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 gray-text pt-5 pt-lg-3">
                    <span
                      *ngIf="dataService.mobileView"
                      class="gray-primaray-text"
                      ><ng-container i18n="@@role">Role</ng-container><br
                    /></span>
                    <span *ngIf="item.role === 'OWNER'" i18n="@@owner">
                      {{ item.role.toLowerCase().replace("_", " ") }}
                    </span>
                    <span *ngIf="item.role === 'ADMIN'" i18n="@@admin">
                      {{ item.role.toLowerCase().replace("_", " ") }}
                    </span>
                    <span *ngIf="item.role === 'TEAM_MANAGER'" i18n="@@teamManager">
                      {{ item.role.toLowerCase().replace("_", " ") }}
                    </span>
                    <span *ngIf="item.role === 'MEMBER'" i18n="@@member">
                      {{ item.role.toLowerCase().replace("_", " ") }}
                    </span>
                  </div>

                  <div class="col-lg-5 pt-3">
                    <p
                      class="mb-0 text-danger"
                      *ngIf="item.inactive && dataService.desktopView"
                      i18n="@@inActive"
                    >
                      Inactive
                    </p>
                  </div>
                  <div class="col-lg-1 max-col-with">
                    <div class="d-flex">
                      <div
                        class="actions"
                        [ngClass]="{
                          'ml-auto': dataService.isSiteLtr(),
                          'mr-auto': dataService.isSiteRtl()
                        }"
                      >
                        <i
                          class="drop-down{{
                            i
                          }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                          (click)="
                            $event.stopPropagation();
                            showActions = !showActions;
                            selectedIndex = i
                          "
                        ></i>
                        <div
                          class="border shadow font-14 position-absolute bg-white drop"
                          *ngIf="showActions && i === selectedIndex"
                        >
                          <p
                            class="pointer py-2"
                            [ngClass]="{
                              'pl-4': dataService.isSiteLtr(),
                              'pr-4': dataService.isSiteRtl()
                            }"
                            (click)="
                              $event.stopPropagation();
                              removeMember(item.id, item.description)
                            "
                            i18n="@@removeBtn"
                          >
                            Remove
                          </p>
                          <p
                            class="pointer py-2"
                            [ngClass]="{
                              'pl-4': dataService.isSiteLtr(),
                              'pr-4': dataService.isSiteRtl()
                            }"
                            *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
                            (click)="
                              this.router.navigate([
                                  '/members/' +
                                  item.id
                              ])
                            "
                            i18n="@@view"
                          >
                            View
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row px-3 py-3 mx-0 mb-3 gray-primaray-text bold-600 table-header rounded"
              *ngIf="dataService.teamMembersSortType === 'pending'"
            >
              <div class="col-4" i18n="@@name">Name</div>
              <div class="col-2" i18n="@@role">Role</div>
              <div class="col-5"></div>
              <div class="col-1"></div>
            </div>
            <ng-container
              *ngIf="
                dataService.teamMembersSortType === 'all' ||
                dataService.teamMembersSortType === 'pending'
              "
            >
              <div
                class="gray-primaray-text font-14"
                *ngFor="let item of dataService.filteredPending; let i = index"
              >
                <div
                  class="row px-lg-3 hover-row-list py-4 mx-0"
                  *ngIf="item.teamName === dataService.teamInfo.name"
                  [ngClass]="{
                    'border-bottom': dataService.desktopView,
                    'border rounded shadow-sm mb-4': dataService.mobileView
                  }"
                >
                  <div class="col-lg-4">
                    <div class="d-flex">
                      <div
                        class="name-first-letters pending-circle small"
                        *ngIf="dataService.desktopView"
                        [ngClass]="{
                          'mr-3': dataService.isSiteLtr(),
                          'ml-3': dataService.isSiteRtl(),
                          'd-none':
                            dataService.teamMembersSortType === 'pending' ||
                            !dataService.filteredTeamMembersList
                        }"
                      ></div>
                      <div class="dotted-text">
                        <p class="mt-0 mt-lg-3 gray-text mb-0 dotted-text">
                          {{ item.email }}
                        </p>
                        <p
                          class="mt-1 yellow-text"
                          *ngIf="dataService.mobileView"
                          i18n="@@pending"
                        >
                          Pending
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <span
                      *ngIf="dataService.mobileView"
                      class="gray-primaray-text"
                      ><ng-container i18n="@@role">Role</ng-container><br
                    /></span>
                    <p
                      *ngIf="!item.admin"
                      class="font-14 gray-text text-capitalize pt-lg-3"
                      i18n="@@member"
                    >
                      Member
                    </p>
                    <p
                      *ngIf="item.admin"
                      class="font-14 gray-text text-capitalize pt-lg-3"
                      i18n="@@admin"
                    >
                      Admin
                    </p>
                  </div>
                  <div class="col-lg-5">
                    <p
                      class="mb-0 yellow-text mt-3"
                      *ngIf="dataService.desktopView"
                      i18n="@@pending"
                    >
                      Pending
                    </p>
                  </div>
                  <div class="col-lg-1 max-col-with">
                    <div
                      class="d-flex"
                      *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
                    >
                      <div
                        class="actions"
                        [ngClass]="{
                          'ml-auto': dataService.isSiteLtr(),
                          'mr-auto': dataService.isSiteRtl()
                        }"
                      >
                        <i
                          class="drop-down-pending{{
                            i
                          }} icon-three-dots font-18 ellipsis pending gray-text pointer p-3"
                          (click)="
                            actionsPending = !actionsPending;
                            selectedIndexPending = i
                          "
                        ></i>
                        <div
                          class="border shadow font-14 position-absolute bg-white drop pending-mobile resend-invite"
                          *ngIf="actionsPending && i === selectedIndexPending"
                        >
                          <p
                            class="pointer py-2"
                            [ngClass]="{
                              'pl-4': dataService.isSiteLtr(),
                              'pr-4': dataService.isSiteRtl()
                            }"
                            (click)="dataService.resendInvitation(item.email)"
                            i18n="@@resetInvite"
                          >
                            Resend invitation
                          </p>
                          <p
                            class="pointer py-2"
                            [ngClass]="{
                              'pl-4': dataService.isSiteLtr(),
                              'pr-4': dataService.isSiteRtl()
                            }"
                            (click)="showCancelInvitation(item.email)"
                            i18n="@@withdrawInvite"
                          >
                            Withdraw invitation
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
