<div class="contact_us my-5">
  <div class="container custom-container">
    <h1
      class="primaray-text border-bottom pb-5 mb-5 sharp-font bolder font-30-to-24 text-center"
      i18n="@@contactMsg1"
    >
      SafarHub Help and Customer Support
    </h1>
    <div class="px-0 mx-0 px-lg-5 mx-lg-5">
      <h4 class="gray-text-dark pb-5 font-18-to-16" i18n="@@contactMsg2">
        We're here to help with your SafarHub rewards 24/7. To get started,
        please send us an email and provide us with a few details.
      </h4>
      <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
        <div class="row gutters-5">
          <div class="form-group col-sm-6 col-md-6 pr-lg-2">
            <input
              class="form-control"
              id="name"
              formControlName="name"
              type="text"
              name="name"
              i18n-placeholder="@@yourName"
              placeholder="Your name"
              [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
            />
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required" i18n="@@nameIsRequired">
                Name is required
              </div>
            </div>
          </div>
          <div
            class="form-group col-sm-6 col-md-6"
            [ngClass]="{
              'pl-lg-2': restApi.isSiteLtr(),
              'pr-lg-2': restApi.isSiteRtl()
            }"
          >
            <input
              class="form-control"
              id="email"
              formControlName="email"
              name="email"
              type="email"
              i18n-placeholder="@@yourEmail"
              placeholder="Your email"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required" i18n="@@requiredEmailError">
                Email is required
              </div>
              <div *ngIf="f.email.errors.email" i18n="@@invalidEmailError">
                Email must be a valid email address
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <input
            class="form-control"
            formControlName="subject"
            id="subject"
            type="text"
            name="subject"
            title="Subject"
            i18n-placeholder="@@subject"
            placeholder="Subject"
            [ngClass]="{ 'is-invalid': submitted && f.subject.errors }"
          />
          <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
            <div *ngIf="f.subject.errors.required" i18n="@@subjectRequired">
              Subject is required
            </div>
          </div>
        </div>
        <div class="form-group">
          <textarea
            name="message"
            id="message"
            formControlName="message"
            class="form-control"
            i18n-placeholder="@@contactMsgPlaceHolder"
            placeholder="Provide us with a few details and tell how we can help you..."
            rows="4"
            [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
          ></textarea>
          <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
            <div *ngIf="f.message.errors.required" i18n="@@messageRequired">
              Message is required
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="blue_btn btn-block mb-4 font-16"
          [disabled]="submitting"
          [ngClass]="{ 'm-progress': submitting }"
          i18n="@@submitBtn"
        >
          Submit
        </button>
      </form>
      <br /><br />
    </div>
  </div>
</div>
