import { Component, OnInit, isDevMode } from '@angular/core';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-release-number',
  templateUrl: './release-number.component.html',
  styleUrls: ['./release-number.component.scss'],

})
export class ReleaseNumberComponent implements OnInit {
   releaseNumber: string;
   isDevMode: boolean;

  constructor() { }

  ngOnInit() {
    this.isDevMode = isDevMode();
    this.releaseNumber = `${environment.releaseNumber}`;
  }

}
