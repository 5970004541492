import { Injectable } from '@angular/core';
declare let gtag: any;
@Injectable()

export class GoogleAnalytics4Service {

    constructor() { }
    items = [];
    public addTransaction(currency, id, price, companyId, name, location, category, variant) {
        this.items = [];
        const item  = {
            item_id: id,
            item_name: name,
            affiliation: companyId,
            currency: currency,
            item_category: category,
            price: price,
            location_id: location,
            item_variant: variant,
            quantity: 1
        };
        this.items.push(item);
        gtag('event', 'purchase', {
            currency: currency,
            transaction_id: id,
            value: price,
            affiliation: companyId,
            items: this.items
        });
    }

  /*  public addItem(id, name, companyId, currency, category, price, location, variant) {
        const item  = {
            item_id: id,
            item_name: name,
            affiliation: companyId,
            currency: currency,
            item_category: category,
            price: price,
            location_id: location,
            item_variant: variant
        };
        this.items.push(item);
    }*/
}
