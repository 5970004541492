import {ElementRef, Injectable, ViewChild} from '@angular/core';

import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {VariablesService} from "../vars.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FlightListService {
    showEditActionInFlightsList: boolean = false;
    isCardDetailsVisible: BehaviorSubject<boolean>[] = [];
    data: any;
    constructor(private http: HttpClient,
                public VarsService: VariablesService) {
    }

    convertDurationToMinutes(duration: string): number {
        // Use a regular expression to extract hours and minutes
        const match = duration.match(/PT(\d+H)?(\d+M)?/);

        if (!match) {
            return 0; // Return 0 if the format is invalid
        }

        // Extract hours and minutes from the match array, defaulting to 0 if not present
        const hours = match[1] ? parseInt(match[1].replace('H', ''), 10) : 0;
        const minutes = match[2] ? parseInt(match[2].replace('M', ''), 10) : 0;

        // Convert to total minutes
        return (hours * 60) + minutes;
    }

    convertISOToMinutes(duration: string): string {
        // Regular expression to match the hours and minutes in the ISO 8601 duration
        const match = duration.match(/PT(\d+H)?(\d+M)?/);

        if (!match) {
            return ''; // Return empty string if the format is invalid
        }

        // Extract hours and minutes from the match array, defaulting to 0 if not present
        const hours = match[1] ? parseInt(match[1].replace('H', ''), 10) : 0;
        const minutes = match[2] ? parseInt(match[2].replace('M', ''), 10) : 0;

        // Return the time in the format of 'Xh Ym'
        return `${hours}h ${minutes}m`;
    }

    getPolicyToolTip(userDate: any): string {
        let policyViolation = '';

        if (userDate.priceLimitPerPassengerViolated) {
            policyViolation += $localize`:@@priceLimitPerPassengerViolated:Price Limit Per Passenger Violated\n`;
        }
        if (userDate.maximumCabinClassViolated) {
            policyViolation += $localize`:@@maximumCabinClassViolated:Maximum Cabin Class Violated\n`;
        }
        if (userDate.maximumNumberOfTripsViolated) {
            policyViolation += $localize`:@@maximumNumberOfTripsViolated:Maximum Number Of Trips Violated\n`;
        }
        if (userDate.flightBookingNotAllowed) {
            policyViolation += $localize`:@@flightBookingNotAllowed:Flight Booking Not Allowed\n`;
        }
        if (userDate.bookingWindowViolated) {
            policyViolation += $localize`:@@bookingWindowViolated:The flight date exceeds the allowed advance booking window\n`;
        }
        if (userDate.medianPriceViolated) {
            policyViolation += $localize`:@@medianPriceLimitViolated:The flight price is higher than the allowed price for this route\n`;
        }

        // Trim the trailing newline character, if present
        return policyViolation.trim();
    }

    viewFlightUpSellingSection(index: number) {
        // Close all card details
        this.isCardDetailsVisible.forEach((subject, i) => {
            if (i !== index) {
                subject.next(false);
            }
        });

        // Open the selected card details
        this.isCardDetailsVisible[index].next(true);

    }
    hideFlightUpSellingSection(index: number){
        this.isCardDetailsVisible[index].next(false);
    }

    upSelling(body){
        return this.http.post(
            `${environment.apiBase}${this.VarsService.flightApis.upSelling}`,
            body
        );
    }


}











