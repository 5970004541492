<div class="p-4" *ngIf="booking">
  <div class="row grey-bg font-16">
    <div class="col-6">
      <img  width="145" height="35"  src="assets/img/logo.svg">
    </div>
    <div class="col-6 gray-text" [ngClass]="{'text-right': Service.isSiteLtr(), 'text-left': Service.isSiteRtl()}">
      <p class="mb-0">https://www.safarhub.com</p>
      <p>41829 Albrae Street, Fremont, CA 94538</p>
    </div>
  </div>
  <p class="booking-type border-bottom gray-primaray-text bolder pb-4">
    <span *ngIf="!Service.inactiveWorkspace && bookingType === 'cancelled'" i18n="@@activeBookingCanceled">Your booking is canceled</span>
    <span *ngIf="!Service.inactiveWorkspace && bookingType === 'completed'" i18n="@@activeBookingCompleted">Your booking is completed</span>
    <span *ngIf="!Service.inactiveWorkspace && bookingType === 'confirmed'" i18n="@@activeBookingConfirmed">Your booking is confirmed</span>

    <span *ngIf="Service.inactiveWorkspace && bookingType === 'cancelled'" i18n="@@inActiveBookingCanceled">Booking is canceled</span>
    <span *ngIf="Service.inactiveWorkspace && bookingType === 'completed'" i18n="@@inActiveBookingCompleted">Booking is completed</span>
    <span *ngIf="Service.inactiveWorkspace && bookingType === 'confirmed'" i18n="@@inActiveBookingConfirmed">Booking is confirmed</span>
  </p>
  <p class="font-16 gray-text-dark py-4 mt-3" *ngIf="bookingType !== 'canceled'"><ng-container i18n="@@printConfirmFlight1">We're confirming your flight. Your SafarHub confirmation number is {{booking.hrsReferenceNumber}}</ng-container></p>
  <p class="font-16 gray-text-dark py-4 mt-3" *ngIf="bookingType === 'canceled'" i18n="@@printCancelFlight">Your flight has been cancelled, all the details of your flight cancellation listed below.</p>







  <div class="table-header pt-4 pb-2 mb-5"
       [ngClass]="{'rounded border': Service.desktopView, 'border-top border-bottom': Service.mobileView}">

    <p class="gray-primaray-text font-16 mb-0" i18n="@@confirmationNumber">Confirmation number</p>
    <p class="gray-text-dark font-14 mb-5">{{booking.hrsReferenceNumber}}</p>
    <div class="mb-5">
      <ng-container *ngIf="booking.bookingResult.data.associatedRecords">
        <p class="gray-primaray-text font-16 mb-0" i18n="@@airlinesConfirmationNumbers">Airlines confirmation numbers</p>
        <ng-container *ngFor="let airline of booking.bookingResult.data.associatedRecords">
          <p class="gray-text-dark font-14 mb-0">{{airline.reference}}</p>
        </ng-container>
      </ng-container>
    </div>
    <div>
      <p class="gray-primaray-text font-16 mb-0" i18n="@@theTraverlers">Travelers</p>

      <ng-container *ngFor="let traveler of booking.bookingResult.data.travelers">
        <p class="gray-text-dark font-14 mb-0">{{traveler.name.firstName}} {{traveler.name.lastName}}
          <span *ngIf="traveler.type"
                class="font-14 text-capitalize gray-text">- {{traveler.type.toLowerCase()}}</span>
        </p>
        <p class="gray-text font-14 mb-0" *ngIf="traveler.eTicketNumber"><ng-container
                i18n="@@ticket">Ticket</ng-container>&nbsp;# {{traveler.eTicketNumber}}</p>
      </ng-container>


    </div>
  </div>


  <div *ngIf="booking">
    <app-flight [details]="booking.bookingResult.data.flightOffers[0]" [isVoucher]="true"></app-flight>
  </div>

  <div class="w-50 mt-5">

    <div class="p-0 mb-4" *ngIf="bookingType !== 'canceled'">
      <div class="p-3">
        <div *ngIf="booking">
          <app-flight-pricing [pricing]="booking.bookingResult.data"></app-flight-pricing>
        </div>
      </div>

    </div>


    <div class="p-0 mb-4 font-16 gray-text-dark" *ngIf="bookingType === 'canceled'">
      <p>Total paid: {{booking.data.bookingResult.data.flightOffers[0].sellingPriceConverted.currency}} {{booking.bookingResult.data.flightOffers[0].sellingPriceConverted.total | number: '1.0-2'}}</p>
    </div>



  </div>


  <div class="pt-4 mt-5">
    <p class="font-18 gray-primaray-text bold-600" i18n="@@additionalInformation">Additional Information</p>
    <p>
    <p class="gray-primaray-text font-18" i18n="@@additionalFees">Additional fees</p>
    <p class="gray-text-dark" i18n="@@flightCancellation">We do not charge any change or cancellation fee. When the airline charge for such fee based on their rules and polices, the cost will be passed on you.</p>

    <p class="gray-text-dark mt-4" i18n="@@contactAirline">Please contact the airline for any additional baggage or extra services.</p>

    <p class="font-18 gray-primaray-text mt-5" i18n="@@beforeYouTravel1">Before you travel</p>
    <p class="gray-text-dark mb-4" i18n="@@photoId">Government-issued photo ID is required for airport check-in and security. </p>

    <p class="gray-text-dark mb-4" i18n="@@internationalTravel"> International Travel: Proof of citizenship is required along with all required documentation. e.g. passport, visa, transit permit...etc. </p>

    <p class="gray-text-dark" i18n="@@allNeededDocs"> Make sure you check all necessary documents before you travel. </p>

    <p class="font-18 gray-primaray-text mt-5" i18n="@@contactFor">Contact the airline for</p>
    <p class="gray-text-dark mb-0" i18n="@@beforeYouTravel5">Special meals</p>
    <p class="gray-text-dark mb-0" i18n="@@beforeYouTravel6"> Seat assignments</p>
    <p class="gray-text-dark" i18n="@@specialHelp"> Special assistance</p>

  </div>

</div>
