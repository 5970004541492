<div
  class="main-container pb-5"
  [ngClass]="{ 'gray-pass-bg': dataService.desktopView }"
>
  <div
    class="container mb-5 pt-5 pt-lg-2 pb-5 create-account-form"
    *ngIf="showFrom"
  >
    <div class="custom-container workspace-registration-container mx-auto">
      <div class="text-center">
        <h4
          class="font-24 pt-md-4 mb-0 font-weight-bold text-center mb-lg-5"
          i18n="@@create0"
        >
          Create your company account
        </h4>
      </div>

      <div
        class="login-form mx-auto px-lg-5 py-5 corporate-box"
        [ngClass]="{ 'white-box': dataService.desktopView }"
      >
        <form
          name="companyAccountForm"
          *ngIf="companyAccountForm"
          [formGroup]="companyAccountForm"
          (ngSubmit)="onSubmit()"
          class="pt-3 px-lg-3"
        >
          <p class="font-18 font-weight-bold gray-text-dark" i18n="@@create1">
            Contact Details
          </p>
          <div
            class="form-group with-icon position-relative form-type-textfield"
          >
            <input class="form-control" placeholder="{{ email }}" disabled />
          </div>
          <div class="row gutters-5">
            <div class="col-md-6">
              <div class="form-group mb-2">
                <input
                  type="text"
                  name="firstName"
                  class="form-control placeholder-text-field"
                  formControlName="firstName"
                  placeholder="First Name"
                  autocomplete="off"
                  i18n-placeholder="@@firstNamePlaceHolder"
                  [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                />
                <div
                  *ngIf="submitted && f.firstName.errors"
                  class="text-danger smaller mb-2"
                >
                  <div
                    *ngIf="f.firstName.errors.required"
                    i18n="@@requiredFirstNameError"
                  >
                    First name is required
                  </div>
                  <div
                    *ngIf="f.firstName.errors.pattern"
                    i18n="@@englishCharsAreAllowedError"
                  >
                    Only English characters are allowed
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-2">
                <input
                  type="text"
                  name="lastName"
                  class="form-control placeholder-text-field"
                  formControlName="lastName"
                  placeholder="Last Name"
                  autocomplete="off"
                  i18n-placeholder="@@lastNamePlaceHolder"
                  [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
                />
                <div
                  *ngIf="submitted && f.lastName.errors"
                  class="text-danger smaller mb-2"
                >
                  <div
                    *ngIf="f.lastName.errors.required"
                    i18n="@@requiredLastNameError"
                  >
                    Last name is required
                  </div>
                  <div
                    *ngIf="f.lastName.errors.pattern"
                    i18n="@@englishCharsAreAllowedError"
                  >
                    Only English characters are allowed
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="gray-text font-12 mb-4 pb-2" i18n="@@create2">
            Enter your correct name, it cannot be changed later
          </p>
          <div
            class="form-group with-icon position-relative form-type-password form-name-password"
          >
            <input
              (keyup)="wrongPassFlag = false"
              class="form-control placeholder-text-field"
              formControlName="password"
              placeholder="Enter Your Password"
              autocomplete="new-password"
              i18n-placeholder="@@enterPasswordPlaceHolder"
              [type]="this.dataService.isTextFieldType ? 'password' : 'text'"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            />
            <i
              class="pointer"
              (click)="this.dataService.togglePasswordFieldType()"
              [ngClass]="
                this.dataService.isTextFieldType
                  ? 'icon-eye'
                  : 'icon-eye-blocked'
              "
            ></i>
          </div>
          <div
            *ngIf="submitted && f.password.errors"
            class="text-danger smaller mb-2"
          >
            <div
              *ngIf="f.password.errors.required"
              i18n="@@requiredPasswordError"
            >
              Password is required
            </div>
          </div>
      <ng-container *ngIf="!data.data.companyName">
        <p
                class="font-18 font-weight-bold gray-text-dark pt-5 mt-3"
                i18n="@@create3"
        >
          Company Workspace
        </p>
        <div
                class="form-group with-icon position-relative form-type-textfield"
        >
          <input
                  type="text"
                  name="companyName"
                  class="form-control placeholder-text-field"
                  formControlName="companyName"
                  placeholder="Company Name"
                  autocomplete="off"
                  i18n-placeholder="@@companyPlaceHolder"
                  (keyup)="checkName()"
                  [ngClass]="{
                'is-invalid': submitted && f.companyName.errors,
                'disabled-color': data.data.companyName
                }"
          />
        </div>
        <div
                *ngIf="submitted && f.companyName.errors"
                class="text-danger smaller mb-2"
        >
          <div
                  *ngIf="f.companyName.errors.required"
                  i18n="@@requiredCompanyError"
          >
            Company Name is required
          </div>
        </div>
        <div *ngIf="wrongName && startWithName" class="text-danger">
          <div i18n="@@create4">
            only letters, numbers, dash and underscore are allowed
          </div>
        </div>
        <div
                class="form-group with-icon position-relative form-type-textfield"
        >
          <input
                  type="text"
                  name="companyId"
                  class="form-control lower-case placeholder-text-field"
                  formControlName="companyId"
                  placeholder="Company ID"
                  autocomplete="off"
                  i18n-placeholder="@@companyIdPlaceHolder"
                  (keyup)="checkId()"
                  [ngClass]="{
                'is-invalid': submitted && f.companyId.errors,
                'disabled-color': data.data.companyId
                }"
          />
          <i *ngIf="availableDomain" class="icon-check green-text"></i>
        </div>
        <div
                *ngIf="submitted && f.companyId.errors"
                class="text-danger smaller"
        >
          <div
                  *ngIf="f.companyId.errors.required"
                  i18n="@@requiredCompanyIdError"
          >
            Company ID is required
          </div>
        </div>

        <div
                *ngIf="!availableDomain && startWithCompanyID"
                class="text-danger"
                [ngClass]="{
              'text-left': dataService.isSiteLtr(),
              'text-right': dataService.isSiteRtl()
            }"
        >
          <div i18n="@@notAvailableCompanyIdError">
            Company ID is already in use.
          </div>
        </div>
        <p class="gray-text" i18n="@@create5">
          only letters and numbers are allowed
        </p>
      </ng-container>

          <p
            class="font-18 font-weight-bold gray-text-dark pt-3"
            i18n="@@create7"
          >
            Your company domain
          </p>
          <div
            class="form-group with-icon position-relative form-type-textfield"
          >
            <input class="form-control" placeholder="{{ domain }}" disabled />
          </div>
          <div class="row">
            <div class="col">
              <button
                [ngClass]="{ 'm-progress': submitting }"
                class="btn green_btn btn-block font-weight-normal font-14 px-5 mt-auto workspace-btn-padding"
              >
                <span class="mx-auto" i18n="@@contBtn">Continue</span>
              </button>
            </div>
          </div>

          <div
            class="text-danger mt-2"
            [ngClass]="{
              'text-left': dataService.isSiteLtr(),
              'text-right': dataService.isSiteRtl()
            }"
          >
            {{ this.errorMsg }}
          </div>

          <p class="gray-text mt-3">
            <ng-container i18n="@@create8"
              >By creating an account, you agree to SafarHub's
            </ng-container>
            <a
              i18n="@@terms"
              class="btn-link px-2"
              [href]="dataService.isLocaleArabic ? env.termsUrlAR : env.termsUrl"
              target="_blank"
            >
              Terms
            </a>
            <ng-container i18n="@@and"> and </ng-container>
            <a
              i18n="@@privacy"
              class="btn-link"
              [ngClass]="{
                'px-2': dataService.isSiteLtr(),
                'pl-2': dataService.isSiteRtl()
              }"
              [href]="dataService.isLocaleArabic ? env.privacyUrlAR : env.privacyUrl"
              target="_blank"
            >
              Privacy Policy
            </a>
            <ng-container
              i18n="@@create9"
              *ngIf="dataService.corporateLocale.includes('ar')"
            >
              SafarHub
            </ng-container>
          </p>
        </form>
      </div>
    </div>
  </div>

  <div class="container mb-5 pt-5 pb-5" *ngIf="!showFrom">
    <div
      class="custom-container workspace-registration-container mx-auto pt-lg-5 mt-lg-5"
    >
      <div
        class="login-form mx-auto px-lg-5 pt-5 corporate-box d-flex align-items-center"
        [ngClass]="{ 'white-box': dataService.desktopView }"
      >
        <div class="w-100">
          <h4
            class="font-24 mb-0 font-weight-bold pb-5 text-center green-text"
            i18n="@@create10"
          >
            Company account is created successfully
          </h4>
          <p
            class="gray-text font-16 pb-0 pb-lg-4 mb-0 mb-lg-3 text-center px-0 px-lg-4"
            i18n="@@create11"
          >
            We need more information to personalize your workspace
          </p>

          <div class="row mt-4 d-flex justify-content-center">
            <div class="col mx-0 mx-lg-5">
              <button
                (click)="
                  this.router.navigate([  '/onboarding'
                  ])
                "
                class="btn green_btn btn-block font-weight-normal font-14 px-5 mt-auto workspace-btn-padding"
              >
                <span class="mx-auto" i18n="@@contBtn">Continue</span>
              </button>
            </div>
          </div>
          <p
            class="mt-3 text-center font-16 pb-0 pb-lg-4 mb-0 mb-lg-3 px-0 px-lg-4"
            (click)="
              this.router.navigate([
                '/' + this.companyAccountForm.controls.companyId.value
              ])
            "
          >
            <a class="blue-text" i18n="@@create12"
              >Just take me to my workspace</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
