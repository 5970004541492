import {Component, OnDestroy, OnInit} from "@angular/core";
import { DataService } from "../../../services/data.service";
import { AuthServiceMain } from "../../../auth/auth.service";
import * as moment from "moment";

import {ActivatedRoute, Router} from "@angular/router";
import { Title } from "@angular/platform-browser";
import "firebase/remote-config";

@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
  styleUrls: ["./booking.component.scss"],
})
export class BookingComponent implements OnInit, OnDestroy {
  rateKeyFromUrl: string;
  userInfo;
  data;
  hotelData;
  params;


  login;


  constructor(
      private route: ActivatedRoute,
    public Service: DataService,
    public Auth: AuthServiceMain,
    public router: Router,
    private titleService: Title,
  ) {
    this.titleService.setTitle("Book | SafarHub");
  }

  ngOnInit() {
    this.readUrlParameters();
    this.Service.FacilitiesFeaturedavailabile = false;
    this.Service.dropDowmBookingForm = false;
    this.Service.errorMsgCoupon = "";
    this.Service.couponValue = "";
    this.Service.bookingPage = true;
    this.Service.hotelPage = false;
    this.Service.validcoupon = false;
    if (!sessionStorage.getItem("hotelData")) {
      this.router.navigate(["/"]);
    }
    this.Service.clearAllMsgs();
    this.Service.ratesExpiryDuration();
    if(this.rateKeyFromUrl){
      this.Service.recheckAll(true, this.rateKeyFromUrl);
    } else {
      this.Service.recheckAll(true);
    }



    this.Service.hotelData = JSON.parse(sessionStorage.getItem("hotelData"));
    this.Service.roomName = sessionStorage.getItem("room");
    this.Service.params = JSON.parse(sessionStorage.getItem("params"));
    this.userInfo = this.Auth.userInfo;
    if (this.Service.hotelData) {
      this.Service.hotelData.myFacilities.map((item) => {
        if (item.featured) {
          this.Service.FacilitiesFeaturedavailabile = true;
        }
      });
    }
  }



  refreshPage() {
    location.reload();
  }

  readUrlParameters() {
    this.route.queryParams.subscribe(params => {
      this.rateKeyFromUrl = params['ratekey'];
      console.log('Rate Key:', this.rateKeyFromUrl);
    });
  }

  ngOnDestroy(): void {
    this.Service.unsubscribeRecheck();
  }
}
