import {AfterViewChecked, Component, OnDestroy, OnInit} from "@angular/core";
import { DataService } from "../../../services/data.service";
import { environment } from "../../../../environments/environment";
import { AuthServiceMain } from "../../../auth/auth.service";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import {
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import {TripsService} from "../../../services/trips.service";
import {Subscription} from "rxjs";
import {ApprovalsService} from "../../../services/approvals.service";

@Component({
  selector: "app-workspace-dashboard-control",
  templateUrl: "./workspace-dashboard-control.component.html",
  styleUrls: ["./workspace-dashboard-control.component.scss"],
})
export class WorkspaceDashboardControlComponent implements OnInit, AfterViewChecked, OnDestroy{
  
  constructor(
    public formatter: NgbDateParserFormatter,
    private titleService: Title,
    public router: Router,
    public dataService: DataService,
    public tripsService: TripsService,
    public auth: AuthServiceMain,
    public approvalsService: ApprovalsService
  ) {this.titleService.setTitle("Book a trip | SafarHub");}
  flightHistoryArrayEmpty: boolean = true;
  env = environment;
  flight = $localize`:@@flights:Flights`;
  hotels = $localize`:@@hotels:Hotels`;
  upcomingBookingObj = {
    bookingStatus: "CONFIRMED",
    includeUpcoming: true,
    includePast: false,
  };
  image = new FormData();
  data;
  submitting = false;
  usersList;
  searchUrl;
  dataUpcoming;
  upcomingBooking;
  private subscriptions = new Subscription();
  upcomingBookingSubscription: Subscription;
  flightHistorySubscription: Subscription;
  companyUsersSubscription: Subscription;
  clearHistorySubscription: Subscription;
  clearFlightHistorySubscription: Subscription;

  ngOnInit(): void {
    setTimeout(() => {
      let flightsTab = document.getElementById("flights") as HTMLElement;
      if (flightsTab && this.dataService.flightTabActive) {
        flightsTab.click();
      }
    }, 200);

    setTimeout(() => {
      this.dataService.flightTabActive = false;
    }, 700);

    this.flightHistorySubscription = this.dataService.fetchFlightSearchHistory("false").subscribe((success) => {
      this.data = success;
      if (this.data.errorCode === 0 && this.data.data) {
        this.dataService.flightHistoryArray = this.data.data.searches.slice(0, 5);
      }
    });
    this.subscriptions.add(this.flightHistorySubscription);

    if (this.auth.isManager() || this.auth.isAdmin() || this.auth.isOwner()) {
      this.getCompanyUsersList();
    }

    if (this.auth.isManager() || this.auth.isAdmin() || this.auth.isOwner()) {
      this.approvalsService.globalListApproversApprovals("PENDING_APPROVAL");
    }

    this.getUpCompingBookings();
  }

  getCompanyUsersList(){

      this.companyUsersSubscription = this.dataService.getCompanyUsersList().subscribe(
          (data) => {
            this.data = data;
            if (this.data.errorCode === 0) {
              this.usersList = this.data.data;
            } else {
              this.dataService.messages(this.data.message, "error");
            }
          },
          (error) => {
            this.dataService.messages(environment.messages.error, "error");
          }
      );
      if (this.auth.isAdmin() || this.auth.isOwner()) {
        this.dataService.getCompanyPolicies();
      }
    this.subscriptions.add(this.companyUsersSubscription);

  }

  getUpCompingBookings(){
  this.upcomingBookingSubscription = this.tripsService
        .flightHotelListBookings(
            this.upcomingBookingObj.bookingStatus,
            this.upcomingBookingObj.includeUpcoming,
            this.upcomingBookingObj.includePast
        )
        .subscribe(
            (data) => {
              this.dataUpcoming = data;
              if (this.dataUpcoming.errorCode === 0) {
                this.upcomingBooking = this.dataUpcoming.data;

                this.dataService.hotellistLoader = false;
              } else {
                this.dataService.messages(this.dataUpcoming.message, "error");
                this.dataService.hotellistLoader = false;
              }
            },
            (error) => {
              this.dataService.messages(environment.messages.error, "error");
              this.dataService.hotellistLoader = false;
            }
        );
    this.subscriptions.add(this.upcomingBookingSubscription);
  }

  clearHistory() {
    if (this.auth.isLogedIn) {
      this.clearHistorySubscription = this.dataService.fetchSearchHistory("true").subscribe();
      this.subscriptions.add(this.clearHistorySubscription);
    }
    if (localStorage.getItem("history_storage")) {
      localStorage.removeItem("history_storage");
    }
    this.dataService.historyArray = [];
  }
  openHistoryUrl(item) {
    if (item.viewMode === "LIST" || item.viewMode === "GEO") {
      this.searchUrl = "/availability";
    } else {
      this.searchUrl = "/availability/hotel";
    }
    this.router.navigate([this.searchUrl], {
      queryParams: {
        searchQuery: item.searchQuery,
        searchType: "GOOGLE_PLACE",
        checkIn: item.checkIn,
        checkOut: item.checkOut,
        travelerId: item.travelerId || this.auth.userInfo.id,
      },
      queryParamsHandling: "merge",
    });
  }

  clearFlightHistory() {
    if (this.auth.isLogedIn) {
      this.clearFlightHistorySubscription = this.dataService.fetchFlightSearchHistory("true").subscribe();
      this.subscriptions.add(this.clearFlightHistorySubscription);
    }
    if (localStorage.getItem("flight_history_storage")) {
      localStorage.removeItem("flight_history_storage");
    }
    this.dataService.flightHistoryArray = [];
  }
  openFlightHistoryUrl(item) {
    localStorage.setItem(
      "lastSearchDepart",
      JSON.stringify({
        caption: item.lookupSearchCaptions.departureCaptions[0],
        code: item.airQueries[0].departureSearchCode,
        //          name: "Ataturk Airport",
        searchQuery: item.airQueries[0].departureSearchCode + ";AIRPORT",
        type: "AIRPORT",
        viewMode: "LIST",
        travelerId: item.travelerId || this.auth.userInfo.id,
      })
    );
    localStorage.setItem(
      "lastSearchReturn",
      JSON.stringify({
        caption: item.lookupSearchCaptions.arrivalCaptions[0],
        code: item.airQueries[0].arrivalSearchCode,
        //  name: "Ataturk Airport",
        searchQuery: item.airQueries[0].arrivalSearchCode + ";AIRPORT",
        type: "AIRPORT",
        viewMode: "LIST",
      })
    );

    let localStorageFlightItem = {
      roundTrip: false,
      cabinClass: item.cabinClass,
      airQueries: [
        {
          departureDate: item.airQueries[0].departureDate,
          departureSearchCode: item.airQueries[0].departureSearchCode,
          departureSearchType: "AIRPORT",
          arrivalSearchCode: item.airQueries[0].arrivalSearchCode,
          arrivalSearchType: "AIRPORT",
          lookupSearchCaptions: {
            departureCaptions: [item.lookupSearchCaptions.departureCaptions[0]],
            arrivalCaptions: [item.lookupSearchCaptions.arrivalCaptions[0]],
          },
        },
      ],
      adultCount: item.adultCount,
      childCount: 0,
      infantCount: 0,
      travelerId: item.travelerId || this.auth.userInfo.id,
    };

    let searchType = "/flights/availability";
    debugger;
    this.dataService.captionReturn =
      item.lookupSearchCaptions.arrivalCaptions[0];
    this.dataService.captionDepart =
      item.lookupSearchCaptions.departureCaptions[0];
    if (item.roundTrip) {
      localStorageFlightItem.roundTrip = true;
      localStorageFlightItem.airQueries[0]["returnDate"] =
        item.airQueries[0].returnDate;
      this.router.navigate([searchType], {
        queryParams: {
          trip: "ROUNDTRIP",
          cabinClass: item.cabinClass,
          departureDate: item.airQueries[0].departureDate,
          departureCode: item.airQueries[0].departureSearchCode,
          arrivalCode: item.airQueries[0].arrivalSearchCode,
          paxes: item.adultCount,
          returnDate: item.airQueries[0].returnDate,
          travelerId: item.travelerId || this.auth.userInfo.id,
        },
      });
    } else {
      this.router.navigate([searchType], {
        queryParams: {
          trip: "ONEWAY",
          cabinClass: item.cabinClass,
          departureDate: item.airQueries[0].departureDate,
          departureCode: item.airQueries[0].departureSearchCode,
          arrivalCode: item.airQueries[0].arrivalSearchCode,
          paxes: item.adultCount,
          returnDate: item.airQueries[0].returnDate,
          travelerId: item.travelerId || this.auth.userInfo.id,
        },
      });
    }

    if (!this.dataService.flightHistoryArray) {
      const historyArr = [];
      historyArr.unshift(localStorageFlightItem);
      localStorage.setItem(
        "flight_history_storage",
        JSON.stringify(historyArr)
      );
    } else {
      let historyArr = this.dataService.flightHistoryArray;
      debugger;
      if (historyArr.length === 5) {
        historyArr.splice(-1, 1);
        historyArr.unshift(localStorageFlightItem);
        localStorage.setItem(
          "flight_history_storage",
          JSON.stringify(historyArr)
        );
      } else {
        historyArr.unshift(localStorageFlightItem);
        localStorage.setItem(
          "flight_history_storage",
          JSON.stringify(historyArr)
        );
      }
    }
  }

  viewDetails(obj, bookingType) {
      this.router.navigate(
        [
            "/" +
            bookingType.toLowerCase() +
            "/booking/details",
        ],
        { queryParams: obj }
      );
  }

  ngAfterViewChecked() {
    if (this.dataService.flightHistoryArray && this.dataService.flightHistoryArray.length) {
      this.flightHistoryArrayEmpty = true;
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
