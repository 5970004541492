import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'featured',
})
@Injectable()
export class Featured implements PipeTransform {
  transform(items: any[], param: any): any {


    if (items) {
      return items.filter((item, index) => {
        const find = item.featured === true;
        return find === false ? false : true;
      });
    }}
}
