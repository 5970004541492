import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MobileAppPaymentService} from "../../services/amazon-payment/mobile-app-payment.service";
import {DataService} from "../../services/data.service";
import {ActivatedRoute} from "@angular/router";
import {AuthServiceMain} from "../../auth/auth.service";
import {User} from "../../auth/user.model";

@Component({
  selector: 'app-mobile-app-payment-page',
  templateUrl: './mobile-app-payment-page.component.html',
  styleUrls: ['./mobile-app-payment-page.component.scss']
})
export class MobileAppPaymentPageComponent implements OnInit {

  constructor(public mobAppService: MobileAppPaymentService,
              public auth: AuthServiceMain,
              public dataService: DataService,
              private cdr: ChangeDetectorRef,
              private route: ActivatedRoute) {

  }
  encodedToken: string | null = '';
  token: string | null = '';
  //tokenString: string | null = null;
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
     // this.encodedToken = "aIB3sF5gRcIfd%2BqVT7YGaUUQm8%2BZmDYzNqZ0anrIE0w%3D";//params['user'] || null;
      this.encodedToken = params['user'] || null;
      this.token = decodeURIComponent(this.encodedToken);


      if (this.token) {
        console.log('user:', decodeURIComponent(this.token));
        const user = new User(this.token);
        this.auth.user.next(user);
        this.auth.userToken = user;
        console.log('this.auth.userToken', this.auth.userToken);
        localStorage.setItem("user", this.token);
        localStorage.setItem("isLoggedin", "true");
        this.auth.fetchUserData();
      } else {
        console.warn('User token is missing in query params');
      }
    });
    //this.mobAppService.listPayments(this.token );
    /*this.mobAppService.getTokenString().subscribe((token) => {
      this.tokenString = token;
      console.log(this.tokenString);
      this.cdr.detectChanges();
    });*/



  }


}
