import { trigger, state, style, transition, animate, animateChild, query } from '@angular/animations';


export const onSideNavChange = trigger('onSideNavChange', [
  state('close',
    style({
      'min-width': '100px'
    })
  ),
  state('open',
    style({
      'min-width': '250px'
    })
  ),
  transition('close => open', animate('250ms ease-in')),
  transition('open => close', animate('250ms ease-in')),
]);


export const onMainContentChange = trigger('onMainContentChange', [
  state('closeLtr',
    style({
      'margin-left': '100px'
    })
  ),
  state('openLtr',
    style({
      'margin-left': '250px'
    })
  ),
  state('closeRtl',
    style({
      'margin-right': '100px'
    })
  ),
  state('openRtl',
    style({
      'margin-right': '250px'
    })
  ),
  transition('closeLtr => openLtr', animate('250ms ease-in')),
  transition('openLtr => closeLtr', animate('250ms ease-in')),
  transition('closeRtl => openRtl', animate('250ms ease-in')),
  transition('openRtl => closeRtl', animate('250ms ease-in')),
]);


export const animateText = trigger('animateText', [
  state('hide',
    style({
      'display': 'none',
      opacity: 0,
    })
  ),
  state('show',
    style({
      'display': 'block',
      opacity: 1,
    })
  ),
  transition('close => open', animate('350ms ease-in')),
  transition('open => close', animate('200ms ease-out')),
]);
