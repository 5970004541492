import {Component, Input, OnInit} from '@angular/core';
import * as moment from "moment";
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-print-flight-booking',
  templateUrl: './print-flight-booking.component.html',
  styleUrls: ['./print-flight-booking.component.scss']
})
export class PrintFlightBookingComponent implements OnInit {

  constructor(public Service: DataService) { }
  @Input() booking: any = {};
  bookingType;
  summaryDeparture;
  departureDetails;
  summaryReturn;
  returnDetails;
  ngOnInit(): void {
    this.booking = JSON.parse(this.booking);
    if (this.booking.bookingStatus === 'CANCELLED') {
      this.bookingType = 'cancelled';
    } else {
      if (this.booking.past) {
        this.bookingType = 'completed';
      } else {
        this.bookingType = 'confirmed';
      }
    }
  }

}
