<div *ngIf="this.dataService.mobileView">

  <div class="container pt-4 pt-lg-5 pb-0 pb-lg-5" *ngIf="this.auth.companyInfo">
    <div class="mx-lg-5">
      <div class="row">
        <div class="col">
          <div class="pb-4 pb-lg-5 mb-3">
    <a class="font-16 blue-text text-underline" *ngIf="this.auth.companyInfo"
       (click)="this.router.navigate(['/settings']);" i18n="@@menuItemSettings">Settings</a> <span class="font-18 gray-text-dark"> / </span>
    <a class="font-16 blue-text text-underline"
       (click)="this.router.navigate(['/settings/billing']);" i18n="@@menuItemBilling">Billing</a> <span class="font-18 gray-text-dark"> / </span>
    <a class="font-16 blue-text text-underline"
       (click)="this.router.navigate(['/settings/billing/cancel-subscription']);" i18n="@@cancelSubscription">Cancel Subscription</a>  <span class="font-18 gray-text-dark"> / </span>
    <span class="font-16 gray-text-dark" i18n="@@reasons">Reasons</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="container pb-3 mb-5 mb-lg-0">
  <div class="justify-content-center">
    <p class="font-16 px-4 primaray-text pb-3" i18n="@@deactivateMsg3">Help us to understand and improve our services</p>
    <p class="font-16 gray-text-dark px-4" i18n="@@whyDeactivateSub">Why are you cancelling your account subscription?</p>


    <div class="container" [ngClass]="{'ml-0' : this.dataService.desktopView}">
      <form [formGroup]="companyDeactivateForm" name="userDeactivateForm">
        <div class="form-group font-16 mb-0 gray-text-dark" *ngFor="let reason of reasonsArray">
          <input [ngClass]="{'mt-2 input-mobile': dataService.mobileView, 'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}" type="radio" value="{{reason.text}}" id="{{reason.id}}" name="reasons"  formControlName="reasons"/>
          <label  [ngClass]="{'radio-mobile': dataService.mobileView}" for="{{reason.id}}">{{reason.text}}</label>
        </div>
        <div *ngIf="submitted && ctrl.reasons.errors" class="text-danger">
          <div *ngIf="ctrl.reasons.errors.required" i18n="@@reasonRequired">Reason is required</div>
        </div>

        <div class="form-group mt-4 mb-5">
          <textarea name="improveService" id="improveService" formControlName="improveService" class="pt-3 form-control"
                  i18n-placeholder="@@deactivatePlaceholder"  placeholder="Give us more details to help improve our service." rows="4"></textarea>
        </div>
      </form>

    </div>
    <div class="justify-content-end" [ngClass]="{'d-flex': dataService.desktopView, 'mr-4': dataService.desktopView && dataService.isSiteLtr(), 'ml-4': dataService.desktopView && dataService.isSiteRtl(), 'mx-4':dataService.mobileView}">
    <!--  <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" [ngClass]="{'w-100 mb-3': dataService.mobileView}" (click)="cancelDeactivate()">Cancel </button>-->
      <button class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5" [ngClass]="{'m-progress': submitting, 'w-100': dataService.mobileView, 'ml-2': dataService.desktopView}" (click)="deactivateCompany()" i18n="@@cancelBtn">Cancel</button>
    </div>
    <div class="text-danger mt-3">{{this.errorMsg }}</div>
  </div>
</div>
