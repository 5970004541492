<div class="w-100 chips-container position-relative">
    <input  class="form-control traveller-field"
            type="text"
            #userInput
            autocomplete="off"
            aria-autocomplete="none"
            id="userInput"
            [formControl]="flightSearchUserSelectionService.userControl"
            (input)="onInputChange()"
            (focus)="onInputChange(); autocompleteOnFocus($event)"
            (blur)="reset()"
            [ngClass]="{ 'red-border': flightSearchUserSelectionService.submitted && !flightSearchUserSelectionService.selectedUser }"
            i18n-placeholder="@@whoTravelling"
            placeholder="Who's travelling?" />

<div class="user-list-options bg-white"
     [ngClass]="{'border': restApi.desktopView}"
     *ngIf="filteredUsers.length > 0">

   <ng-container *ngFor="let user of filteredUsers | slice : 0 : maxNoUserToShow">
       <div  class="d-flex user-item px-2 py-3 py-md-2"
               (click)="selectUser(user)"
               *ngIf="user.id === 'guest'">
           <div class="name-first-letters small"
                   [ngClass]="{'mr-3': restApi.isSiteLtr(), 'ml-3': restApi.isSiteRtl()}"
                   [ngStyle]="{'background-color': user.profileImageBackgroundHexColor}">
               <img loading="lazy" class="mb-1 user-img d-inline-block"
                       onError="this.src='assets/img/default-user.png'" [src]="user.profileImage" />
           </div>
           <div class="dotted-text d-flex align-items-center">
               <p class="mb-0 gray-primaray-text">
                   <span>{{ user.description }}</span>
               </p>
           </div>
       </div>

       <div class="d-flex user-item p-2"
               (click)="selectUser(user)"
               *ngIf="user.id !== 'guest'">
              <img width="35"
                   height="35"
                   loading="lazy"
                   *ngIf="user.profileImage"
                   class="user-img d-inline-block"
                   [ngClass]="{'mr-3': restApi.isSiteLtr(), 'ml-3': restApi.isSiteRtl(), 'img-border': userService.imageHasError}"
                   [src]="userService.imageHasError ? userService.fallbackSrc : user.profileImage"
                   (error)="userService.imageHasError = true" />

           <div class="name-first-letters small"
                   [ngClass]="{'mr-3': restApi.isSiteLtr(),'ml-3': restApi.isSiteRtl()}"
                   *ngIf="!user.profileImage"
                   [ngStyle]="{'background-color': user.profileImageBackgroundHexColor}">
               {{ user.firstName?.substring(0, 1).toUpperCase()}}{{ user.lastName?.substring(0, 1).toUpperCase() }}
           </div>
           <div class="dotted-text">
               <p class="mb-0 gray-primaray-text">
                   {{ user.description }}
               </p>
               <p class="gray-text mb-0 dotted-text dotted-text-direction" >
                   {{ user.email }}
               </p>
           </div>
       </div>
   </ng-container>
</div>

</div>

<div *ngIf="flightSearchUserSelectionService.submitted && !flightSearchUserSelectionService.selectedUser"
        class="small text-danger"
        i18n="@@travellerRequired">
    Traveller is required
</div>
