import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../services/data.service';
import {SidenavService} from '../sidenav.service';
import {animateText, onSideNavChange} from "../animations/animations";
import {Router} from "@angular/router";

@Component({
  selector: 'app-middle-button',
  templateUrl: './middle-button.component.html',
  styleUrls: ['./middle-button.component.scss'],
  animations: [onSideNavChange, animateText]
})
export class MiddleButtonComponent implements OnInit {
  constructor(public dataService: DataService, private sidenavService: SidenavService, public router: Router) {
  }

  ngOnInit(): void {
  }
  onSideNavToggle() {
    localStorage.setItem('isLeftMenuOpen', 'false');
    this.dataService.sideNavState = !this.dataService.sideNavState

    setTimeout(() => {
      this.dataService.linkText = this.dataService.sideNavState;
    }, 100)
    this.sidenavService.sideNavState$.next(this.dataService.sideNavState);

  }
}
