import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormBuilder} from "@angular/forms";
import {Subject, Subscription} from "rxjs";
import { GoogleAnalyticsService } from "../../../google-analytics.service";
import { AuthServiceMain } from "../../../auth/auth.service";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { v4 as uuid } from "uuid";
import {
  debounceTime,
  distinctUntilChanged
} from "rxjs/operators";
import { VariablesService } from "../../../services/vars.service";
import { DatePipe } from "@angular/common";
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbDateParserFormatter, NgbDatepicker,
} from "@ng-bootstrap/ng-bootstrap";
import {FlightListService} from "../../../services/flight-services/flight-list.service";
import {TripType} from "../../../shared/enums/trip-type.enum";
import {SearchUserSelectionService} from "../../../services/search-user-selection/search-user-selection.service";
import {FlightSearchUserSelectionService} from "../../../services/search-user-selection/flight-search-user-selection.service";
@Component({
  selector: "app-flights-search-controller",
  templateUrl: "./flights-search-controller.component.html",
  styleUrls: ["./flights-search-controller.component.scss"],
  host: {
    "(document:click)": "onClickBody($event)",
  },
})
export class FlightsSearchControllerComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  autoCompleteDepartSubscription: Subscription;
  autoCompleteListDepartArraySubscription: Subscription;
  directFlightsOnly: boolean = false;
  @Input() fromHomePage: boolean = false;
  data;
  hoveredDate: NgbDate | null = null;
  returnDate: NgbDate | null;
  departureDate: NgbDate | null;
  home = false;
  oldsearchQuery;
  oldcaption;
  destination = "";
  queryParams;
  autocompletelistDepart;
  autocompletelistReturn;
  returningRangeFormatted;
  pickerOneWay;
  picker;
  onclickActive = true;
  departingRangeFormatted;
  decreaseChildrenDisabled = true;
  decreaseAdultDisabled = false;
  returningRange;
  departingRange;
  searchQuery;
  searchQueryReturn;
  searchQueryDepart;
  adultCount = 1;
  paxCount = 1;
  childrenCount = 0;
  decreaseDisabled = false;
  increasDisabled = false;
  Query = "";
  entity;
  // caption = "";
  // captionReturn = "";
  // captionDepart = "";
  children = [];
  ages = [];
  // searchForm: FormGroup;
  submitted = false;
  validChild = false;
  Childrenpax = "";
  checki;
  childrenList = [];
  availability = false;
  SearchTextQuery = new Subject<string>();
  SearchTextQueryReturn = new Subject<string>();
  readyToSearch = true;
  submitting = false;
  tripType = [
    $localize`:@@roundTrip:Round-trip`,
    $localize`:@@oneWay:One-way`,
    $localize`:@@multiCity:Multi-city`,
  ];
  roundTripLocalized = $localize`:@@roundTrip:Round-trip`;
  oneWayLocalized = $localize`:@@oneWay:One-way`;
  multiCityLocalized = $localize`:@@multiCity:Multi-city`;
  selectedClass = { value: "ECONOMY", name: $localize`:@@economy:Economy` };
  selectedTrip = $localize`:@@roundTrip:Round-trip`;
  selectedTripParam;
  arrivalCode;
  departureCode;
  returningRangeFormattedCity = [];
  arrivalCodeArray = [];

  allFlights = Array(2).fill(null).map(() => {
    const departure = this.getFormattedDate(14);
    return {
      SearchTextQuery: new Subject<string>(),
      SearchTextQueryArrive: new Subject<string>(),
      autocompletelistDepart: [],
      autocompletelistArrive: [],
      departureDateISO: departure.dateISO, // ISO format
      departureDate: departure.date,  // { year, month, day }
      departureDateFormatted: departure.formatted,  // Formatted date
      captionArrive: "",
      captionDepart: "",
      arrivalCode: null,
      departureCode: null,
    };
  });
  // allFlightsLocalStorageArriveArray = [];
  // allFlightsLocalStorageDepartArray = [];

  @ViewChild('datepicker', { static: false }) datepicker!: NgbDatepicker;
  flightSearchDates = undefined;
  constructor(
      public flightSearchUserSelectionService: FlightSearchUserSelectionService,
      public flightListService: FlightListService,
    public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public datePipe: DatePipe,
    public googleAnalyticsService: GoogleAnalyticsService,
    public vars: VariablesService,
    private formBuilder: FormBuilder,
    public Auth: AuthServiceMain,
    public restApi: DataService,
    public router: Router,
    public route: ActivatedRoute,
    private eref: ElementRef
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.flightSearchUserSelectionService.disableAction.next(false);
    this.flightSearchUserSelectionService.userControl.enable();
    if(!this.router.url.includes('availability')){
      this.flightSearchUserSelectionService.selectedUser = null;
      this.flightSearchUserSelectionService.userControl.setValue(null);
    } else {
      this.route.queryParams.subscribe((params) => {
        this.flightSearchUserSelectionService.travelerId =  params['travelerId'];
        this.flightSearchUserSelectionService.loadTraveler();
        this.directFlightsOnly = (params['directOnly'] === 'true');
      });
    }
    this.flightSearchUserSelectionService.submitted = false;


    const body = document.getElementsByTagName("body")[0];
    body.classList.add("search-page");

    // this.searchForm = this.formBuilder.group({}, {});
    // for (let i = 0; i < this.allFlights.length; i++) {
    //   this.searchForm.addControl(
    //     i.toString(),
    //     this.formBuilder.group({
    //       departAutoComplete: new FormControl(),
    //     })
    //   );
    //   this.searchForm.addControl(
    //     "arrive" + i,
    //     this.formBuilder.group({
    //       departAutoComplete: new FormControl(),
    //     })
    //   );
    // }


      this.readCaptionsFromLocalStorage();


      this.readFlightSearchDatesFromLocalStorage();
      this.readURLParameters();
      this.flightSearchAutoComplete();
      this.multiCityFlightSearchAutoComplete();
    for (let i = 2; i < 18; i++) {
      this.ages[i] = i.toString();
    }
    this.increaseCheck();
    // this.restApi.flightHistoryArray = JSON.parse(
    //   localStorage.getItem("flight_history_storage")
    // );
//     if (this.restApi.flightHistoryArray && this.restApi.flightHistoryArray[0]) {
// //      console.log(this.restApi.flightHistoryArray[0]);
//       setTimeout(() => {
//         this.restApi.captionReturn =
//           this.restApi.flightHistoryArray[0].lookupSearchCaptions?.arrivalCaptions[0];
//         this.searchQueryReturn =
//           this.restApi.flightHistoryArray[0].lookupSearchCaptions?.arrivalCaptions[0];
//         this.captionReturn =
//           this.restApi.flightHistoryArray[0].lookupSearchCaptions?.arrivalCaptions[0];
//         this.arrivalCode =
//           this.restApi.flightHistoryArray[0].airQueries[0]?.arrivalSearchCode;
//         this.restApi.captionDepart =
//           this.restApi.flightHistoryArray[0].lookupSearchCaptions?.departureCaptions[0];
//         this.searchQueryDepart =
//           this.restApi.flightHistoryArray[0].lookupSearchCaptions?.departureCaptions[0];
//         this.captionDepart =
//           this.restApi.flightHistoryArray[0].lookupSearchCaptions?.departureCaptions[0];
//         this.departureCode =
//           this.restApi.flightHistoryArray[0].airQueries[0]?.departureSearchCode;
//       }, 500);
//     }


    console.log(this.restApi.captionDepartArray);
    console.log(this.restApi.captionArriveArray);
    console.log(this.allFlights);
  }

  selectTripType(trip: string): void{
    if(trip === TripType.roundTrip || this.selectedTrip === "Round-trip" || this.selectedTrip === this.roundTripLocalized){
      this.flightListService.showEditActionInFlightsList = false;
      this.selectedTrip = this.roundTripLocalized;
      this.restApi.selectedTrip = this.roundTripLocalized;
      // if(this.restApi.captionDepartArray?.[0] !== ''){
      //   this.restApi.captionDepart = this.restApi.captionDepartArray?.[0];
      // }
      // if(this.restApi.captionArriveArray?.[0] !== ''){
      //   this.restApi.captionReturn = this.restApi.captionArriveArray?.[0];
      //   this.restApi.captionReturn = this.restApi.captionArriveArray?.[0];s
      // }
      if(!this.router.url.includes('availability')){
        this.readCaptionsFromLocalStorage();
      }
    }

    if(trip === TripType.oneWay || this.selectedTrip === "One-way" || this.selectedTrip === this.oneWayLocalized){
      this.flightListService.showEditActionInFlightsList = false;
      this.selectedTrip = this.oneWayLocalized;
      this.restApi.selectedTrip = this.oneWayLocalized;
      // if(this.restApi.captionDepartArray[0] !== ''){
      //   this.restApi.captionDepart = this.restApi.captionDepartArray[0];
      // }
      // if(this.restApi.captionArriveArray[0] !== ''){
      //   this.restApi.captionReturn = this.restApi.captionArriveArray[0];
      // }
      if(!this.router.url.includes('availability')){
        this.readCaptionsFromLocalStorage();
      }
    }

    if(trip === TripType.multiCity || this.selectedTrip === "Multi-city" || this.selectedTrip === this.multiCityLocalized){
      this.flightListService.showEditActionInFlightsList = true;
      this.restApi.isCollapsedFlight = false;
      this.selectedTrip = this.multiCityLocalized;
      this.restApi.selectedTrip = this.multiCityLocalized;
      // if(this.restApi.captionDepart !== ''){
      //   this.restApi.captionDepartArray[0] = this.restApi.captionDepart;
      // }
      // if(this.restApi.captionDepart !== ''){
      //   this.restApi.captionArriveArray[0] = this.restApi.captionReturn;
      // }
      if(!this.router.url.includes('availability')){
        this.readCaptionsFromLocalStorage();
      }
      console.log(this.restApi.captionDepartArray);
      console.log(this.restApi.captionArriveArray);
      console.log(this.restApi.captionArriveArray);
    }
  }

  flightSearchAutoComplete(){
    this.SearchTextQueryReturn.pipe(
        // get value
        debounceTime(300),
        distinctUntilChanged()
    ).subscribe((value) => {
      this.Query = value;
      this.readyToSearch = false;
      this.searchQuery = undefined;
      this.searchQueryReturn = undefined;
      this.autoCompleteListReturn();
    });
    this.SearchTextQuery.pipe(
        // get value
        debounceTime(300),
        distinctUntilChanged()
    ).subscribe((value) => {
      this.Query = value;
      this.readyToSearch = false;
      this.searchQuery = undefined;
      this.searchQueryDepart = undefined;
      this.autoCompleteListDepart();
    });
  }
  multiCityFlightSearchAutoComplete(){
    this.allFlights.forEach((element, index) => {
      // Check if the SearchTextQuery and SearchTextQueryArrive are defined
      if (element.SearchTextQuery && element.SearchTextQuery instanceof Subject) {
        element.SearchTextQuery
            .pipe(
                debounceTime(300), // Wait for 300 ms after the user stops typing
                distinctUntilChanged() // Only emit when the current value is different from the last
            )
            .subscribe((value: string) => {
              this.Query = value;
              this.readyToSearch = false;
              this.searchQuery = undefined;
              // Trigger the departure autocomplete list update
              this.autoCompleteListDepartArray(index);
            });
      }

      if (element.SearchTextQueryArrive && element.SearchTextQueryArrive instanceof Subject) {
        element.SearchTextQueryArrive
            .pipe(
                debounceTime(300), // Wait for 300 ms after the user stops typing
                distinctUntilChanged() // Only emit when the current value is different from the last
            )
            .subscribe((value: string) => {
              this.Query = value;
              this.readyToSearch = false;
              this.searchQuery = undefined;
              // Trigger the arrival autocomplete list update
              this.autoCompleteListReturnArray(index);
            });
      }
    });
  }
  getFormattedDate(offsetDays: number) {
    const date = this.calendar.getNext(this.calendar.getToday(), 'd', offsetDays);
    const formattedDate = this.datePipe.transform(
        new Date(date.year, date.month - 1, date.day),
        "EE, MMM dd"
    );
    return {
      dateISO: moment().add(offsetDays, "days").format("YYYY-MM-DD"), // ISO format
      date: date, // { year, month, day }
      formatted: formattedDate, // Formatted date
    };
  }
  showReturnDate() {
    this.checkDepartureDate();
    if (!this.returnDate) {
      this.returnDate = this.calendar.getNext(this.departureDate, "d", 0);
      this.returningRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.returnDate),
          "EE, MMM dd"
      );
    }
  }

  navigateToMonth(index?: number) {
    if(index){
      if (this.allFlights?.[index]?.departureDate) {
        this.datepicker.navigateTo(this.allFlights?.[index]?.departureDate); // Navigate to the desired date
      }
    } else {
      if (this.departureDate) {
        this.datepicker.navigateTo(this.departureDate); // Navigate to the desired date
      }
    }
  }

  onDateSelection(date: NgbDate, index?: number) {
    if (
        this.selectedTrip === "Round-trip" ||
        this.selectedTrip === this.roundTripLocalized
    ) {
      if (!this.departureDate && !this.returnDate) {
        this.departureDate = date;

        this.departingRangeFormatted = this.datePipe.transform(
            this.formatter.format(this.departureDate),
            "EE, MMM dd"
        );
        this.returningRangeFormatted = "";
      } else if (
          this.departureDate &&
          !this.returnDate &&
          date &&
          (date.after(this.departureDate) || date.equals(this.departureDate))
      ) {
        this.returnDate = date;
        this.departingRangeFormatted = this.datePipe.transform(
            this.formatter.format(this.departureDate),
            "EE, MMM dd"
        );
        this.returningRangeFormatted = this.datePipe.transform(
            this.formatter.format(this.returnDate),
            "EE, MMM dd"
        );
      } else {
        this.returnDate = null;
        this.departureDate = date;
        this.returningRangeFormatted = "";
        this.departingRangeFormatted = this.datePipe.transform(
            this.formatter.format(this.departureDate),
            "EE, MMM dd"
        );
      }
      if (this.departureDate && this.returnDate) {
        this.departingRangeFormatted = this.datePipe.transform(
            this.formatter.format(this.departureDate),
            "EE, MMM dd"
        );
        this.returningRangeFormatted = this.datePipe.transform(
            this.formatter.format(this.returnDate),
            "EE, MMM dd"
        );
      }
    } else if (
        this.selectedTrip === "One-way" ||
        this.selectedTrip === this.oneWayLocalized
    ) {
      this.departureDate = date;
      this.departingRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.departureDate),
          "EE, MMM dd"
      );
    } else {
      this.allFlights[index].departureDate = date;
      this.allFlights[index].departureDateFormatted = this.datePipe.transform(
          this.formatter.format(date),
          "EE, MMM dd"
      );
      // Convert NgbDate to JavaScript Date object
      const jsDate = new Date(date.year, date.month - 1, date.day);
      this.allFlights[index].departureDateISO =  moment(jsDate).format("YYYY-MM-DD");
      this.compareSelectedDateWithOtherElements(date,index);
    }
  }
  compareSelectedDateWithOtherElements(date, index) {
    // Convert the selected date to a Date object for comparison
    const selectedDate = new Date(date.year, date.month - 1, date.day);

    // Iterate through the subsequent flights in the array
    for (let i = index + 1; i < this.allFlights.length; i++) {
      const nextFlight = this.allFlights[i];
      const nextDate = nextFlight.departureDate;

      // If `nextDate` is not null, convert it to a Date object
      const nextDateObj = nextDate
          ? new Date(nextDate.year, nextDate.month - 1, nextDate.day)
          : null;

      // Check if `nextDate` is null or is before the `selectedDate`
      if (!nextDateObj || nextDateObj < selectedDate) {
        // Update the next flight's date to match the selected date
        this.allFlights[i].departureDate = { ...date };
        this.allFlights[i].departureDateFormatted = this.datePipe.transform(
            this.formatter.format(date),
            "EE, MMM dd"
        );
      }
    }
  }

  doneDate(index?: number) {
    if(!index){ // means either oneway or roundtrip
      if (!this.returnDate) {
        this.returnDate = this.calendar.getNext(this.departureDate, "d", 1);
        this.returningRangeFormatted = this.datePipe.transform(
            this.formatter.format(this.returnDate),
            "EE, MMM dd"
        );
      }
    }
  }

  isHovered(date: NgbDate, index?: number) {
    if (
        this.selectedTrip === "Round-trip" ||
        this.selectedTrip === this.roundTripLocalized
    ) {
      return (
          this.departureDate &&
          !this.returnDate &&
          this.hoveredDate &&
          date.after(this.departureDate) &&
          date.before(this.hoveredDate)
      );
    } else if (
        this.selectedTrip === "One-way" ||
        this.selectedTrip === this.oneWayLocalized
    ) {
      return date.equals(this.departureDate);
    } else {
      return date.equals(this.allFlights[index].departureDate);
    }
  }

  isInside(date: NgbDate) {
    return (
        this.returnDate &&
        date.after(this.departureDate) &&
        date.before(this.returnDate)
    );
  }

  isRange(date: NgbDate) {
    return (
        date.equals(this.departureDate) ||
        (this.returnDate && date.equals(this.returnDate)) ||
        this.isInside(date) ||
        this.isHovered(date)
    );
  }


  readCaptionsFromLocalStorage(){
    if (localStorage.getItem("lastSearchReturn")) {
      const lastSearchReturn = JSON.parse(
          localStorage.getItem("lastSearchReturn")
      );
      this.restApi.captionReturn = lastSearchReturn.caption;
      this.searchQueryReturn = lastSearchReturn.caption;
      //this.captionReturn = lastSearchReturn.caption;
      this.arrivalCode = lastSearchReturn.code;
    }
    if (localStorage.getItem("lastSearchDepart")) {
      const lastSearchDepart = JSON.parse(
          localStorage.getItem("lastSearchDepart")
      );
      this.restApi.captionDepart = lastSearchDepart.caption;
      this.searchQueryDepart = lastSearchDepart.caption;
      //this.captionDepart = lastSearchDepart.caption;
      this.departureCode = lastSearchDepart.code;
    }

    if (!localStorage.getItem("lastSearchDepartAndReturnArray")) {
      this.restApi.captionDepartArray[0] = this.restApi.captionDepart;
      this.allFlights[0].captionDepart = this.restApi.captionDepart;
      this.allFlights[0].departureCode = this.departureCode;

      this.restApi.captionArriveArray[0] = this.restApi.captionReturn;
      this.allFlights[0].captionArrive = this.restApi.captionReturn;
      this.allFlights[0].arrivalCode = this.arrivalCode;
    }

    if (localStorage.getItem("lastSearchDepartAndReturnArray")) {
      const lastSearchDepartAndReturnArray = JSON.parse(
          localStorage.getItem("lastSearchDepartAndReturnArray")
      );

      this.restApi.captionDepartArray = JSON.parse(localStorage.getItem('captionDepartArray')) || ['',''];
      this.restApi.captionArriveArray = JSON.parse(localStorage.getItem('captionArriveArray')) || ['',''];


        // Loop through the allFlights array and fill in captionDepart and captionArrive
        this.allFlights.forEach((flight, index) => {
          // Check if the corresponding index exists in the arrays
          if (localStorage.getItem("lastSearchDepart" + index)) {
            const lastSearchDepart = JSON.parse(localStorage.getItem("lastSearchDepart" + index));
            this.restApi.captionDepartArray[index] = lastSearchDepart.caption;
            flight.captionDepart = lastSearchDepart.caption;
            flight.departureCode = lastSearchDepart.code;
          }

          if (localStorage.getItem("lastSearchArrive" + index)) {
            const lastSearchArrive = JSON.parse(localStorage.getItem("lastSearchArrive"  + index));
            this.restApi.captionArriveArray[index] = lastSearchArrive.caption;
            flight.captionArrive = lastSearchArrive.caption;
            flight.arrivalCode = lastSearchArrive.code;
          }
        });



      //console.log(this.restApi.captionDepartArray);

      if (!localStorage.getItem("lastSearchReturn")) {
        this.restApi.captionReturn = this.allFlights[0].captionArrive;
        //this.captionReturn = this.allFlights[0].captionArrive;
        this.arrivalCode = this.allFlights[0].arrivalCode;
      }
      if (!localStorage.getItem("lastSearchDepart")) {
        this.restApi.captionDepart = this.allFlights[0].captionDepart;
        //this.captionDepart = this.allFlights[0].captionDepart;
        this.departureCode = this.allFlights[0].departureCode;
      }
    }
    //console.log(this.allFlights);
  }
  readFlightSearchDatesFromLocalStorage(){
    if (JSON.parse(localStorage.getItem("flightSearchDates"))) {
      this.flightSearchDates = JSON.parse(
          localStorage.getItem("flightSearchDates")
      );
      if (
          moment(this.flightSearchDates.departureDate).isBefore(moment(), "day") &&
          this.flightSearchDates.returnDate
      ) {
        localStorage.setItem(
            "flightSearchDates",
            JSON.stringify({
              departureDate: moment().add(14, "days").format("YYYY-MM-DD"),
              returnDate: moment().add(17, "days").format("YYYY-MM-DD"),
            })
        );
        this.flightSearchDates = {
          departureDate: moment().add(14, "days").format("YYYY-MM-DD"),
          returnDate: moment().add(17, "days").format("YYYY-MM-DD"),
        };
      } else if (
          moment(this.flightSearchDates.departureDate).isBefore(moment(), "day")
      ) {
        localStorage.setItem(
            "flightSearchDates",
            JSON.stringify({
              departureDate: moment().add(14, "days").format("YYYY-MM-DD"),
            })
        );
        this.flightSearchDates = {
          departureDate: moment().add(14, "days").format("YYYY-MM-DD"),
        };
      }
    }


    if (!this.departureDate) {
      if (this.flightSearchDates) {
        this.departingRange = this.flightSearchDates.departureDate;
      } else {
        this.departingRange = moment()
            .add(14, "days")
            .format("YYYY-MM-DD");
      }
      this.departingRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.departureDate),
          "EE, MMM dd"
      );
    }

    if (!this.returnDate) {
      if (this.flightSearchDates) {
        this.returningRange = this.flightSearchDates.returnDate;
      } else {
        this.returningRange = moment()
            .add(17, "days")
            .format("YYYY-MM-DD");
      }
      this.returningRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.returnDate),
          "EE, MMM dd"
      );
    }
    this.setDepartAndReturnDates();
  }
  readURLParameters(){
    this.route.queryParams.subscribe((params) => {


    this.queryParams = params;
    this.vars.classType.forEach((element) => {
      if (this.queryParams.cabinClass === element.value) {
        this.selectedClass = element;
      }
    });
    if (params.trip === "ONEWAY") {
      this.selectedTrip = this.oneWayLocalized;
      if (
          params.departureDate === "null" ||
          moment(params.departureDate).isBefore(moment(), "day") ||
          !moment(params.departureDate).isValid()
      ) {
        if (
            !this.departingRange ||
            moment(this.departingRange).isBefore(moment(), "day")
        ) {
          this.departingRange = moment().add(14, "days").format("YYYY-MM-DD");
          this.returningRange = moment().add(17, "days").format("YYYY-MM-DD");
        }
      } else if (
          params.departureDate !== undefined &&
          params.paxes !== undefined
      ) {
        this.departingRange = moment(params.departureDate).format(
            "YYYY-MM-DD"
        );
        this.returningRange = moment(params.departureDate)
            .add(3, "days")
            .format("YYYY-MM-DD");
      } else {
        if (
            params.departureDate === undefined &&
            this.departingRange === ""
        ) {
          if (this.flightSearchDates) {
            this.departingRange = this.flightSearchDates.departureDate;
          } else {
            this.departingRange = moment()
                .add(14, "days")
                .format("YYYY-MM-DD");
            this.returningRange = moment()
                .add(17, "days")
                .format("YYYY-MM-DD");
          }
        } else if (!this.departingRange) {
          this.departingRange = moment(params.departureDate).format(
              "YYYY-MM-DD"
          );
          this.returningRange = moment(params.departureDate)
              .add(3, "days")
              .format("YYYY-MM-DD");
        }
      }
    } else if (params.trip === "MULTICITY") {
      this.selectedTrip = this.multiCityLocalized;
      // Get the segments from the URL
      const segments = params['segments'];

      if (segments) {
        // Split the segments into an array based on the semicolon
        const segmentArray = segments.split(';');

        // add controls for the flight with index > 1 (initial length is 2)
        // for (let i = 2; i < segmentArray.length; i++) {
        //   this.searchForm.addControl(
        //       (i).toString(),
        //       this.formBuilder.group({
        //         departAutoComplete: new FormControl(),
        //       })
        //   );
        //   this.searchForm.addControl(
        //       "arrive" + i,
        //       this.formBuilder.group({
        //         departAutoComplete: new FormControl(),
        //       })
        //   );
        // }

        // Map the segment data to the allFlights array

        segmentArray.forEach((segment, index) => {
          const [departureCode, arrivalCode, departureDateISO] = segment.split(',');
          const departureDateFormatted = this.datePipe.transform(departureDateISO, "EE, MMM dd");

          // If the element already exists in allFlights, update it
          if (this.allFlights[index]) {
            this.allFlights[index].departureCode = departureCode;
            this.allFlights[index].arrivalCode = arrivalCode;
            this.allFlights[index].departureDateISO = departureDateISO;
            this.allFlights[index].departureDate = this.getNgDateFromISO(departureDateISO);
            this.allFlights[index].departureDateFormatted = departureDateFormatted;
          } else {
            // If the element does not exist, push a new one
            this.allFlights.push({
              SearchTextQuery: new Subject<string>(),
              SearchTextQueryArrive: new Subject<string>(),
              autocompletelistDepart: [],
              autocompletelistArrive: [],
              departureDateISO: departureDateISO,
              departureDate: this.getNgDateFromISO(departureDateISO),
              departureDateFormatted: departureDateFormatted,
              captionArrive: this.restApi.captionArriveArray?.[index] || "",
              captionDepart: this.restApi.captionDepartArray?.[index] || "",
              arrivalCode: arrivalCode,
              departureCode: departureCode,
            });
          }
        });


      }
      //console.log(this.allFlights);

    } else {
      if (
          params.returnDate === "null" ||
          params.departureDate === "null" ||
          moment(params.departureDate).isBefore(moment(), "day") ||
          !moment(params.departureDate).isValid() ||
          !moment(params.returnDate).isValid() ||
          moment
              .utc(params.returnDate)
              .diff(moment.utc(params.departureDate), "days") < 0
      ) {
        if (
            !this.departingRange ||
            moment(this.departingRange).isBefore(moment(), "day")
        ) {
          this.departingRange = moment().add(14, "days").format("YYYY-MM-DD");
        }
        if (
            !this.returningRange ||
            moment(this.returningRange).isBefore(moment(), "day")
        ) {
          this.returningRange = moment().add(17, "days").format("YYYY-MM-DD");
        }
      } else if (
          params.departureDate !== undefined &&
          params.returnDate !== undefined &&
          params.paxes !== undefined
      ) {
        this.returningRange = moment(params.returnDate).format("YYYY-MM-DD");

        this.departingRange = moment(params.departureDate).format(
            "YYYY-MM-DD"
        );
      } else {
        if (params.departureDate === undefined && !this.departingRange) {
          if (this.flightSearchDates) {
            this.departingRange = this.flightSearchDates.departureDate;
          } else {
            this.departingRange = moment()
                .add(14, "days")
                .format("YYYY-MM-DD");
          }
        } else if (!this.departingRange) {
          this.departingRange = moment(params.departureDate).format(
              "YYYY-MM-DD"
          );
        }

        if (params.returnDate === undefined && !this.returningRange) {
          if (this.flightSearchDates) {
            this.returningRange = this.flightSearchDates.returnDate;
          } else {
            this.returningRange = moment()
                .add(17, "days")
                .format("YYYY-MM-DD");
          }
        } else if (!this.returningRange) {
          this.returningRange = moment(params.returnDate).format(
              "YYYY-MM-DD"
          );
        }
      }

      const days = moment
          .utc(this.returningRange)
          .diff(moment.utc(this.departingRange), "days");
      if (days < 0) {
        this.departingRange = moment().add(14, "days").format("YYYY-MM-DD");
        this.returningRange = moment().add(17, "days").format("YYYY-MM-DD");
      }
    }

    if(params.trip !== "MULTICITY"){
    this.setDepartAndReturnDates();
    }



    if (this.queryParams.paxes) {
      const childrenArr = this.queryParams.paxes.split(",");
      for (let i = 1; i < childrenArr.length; i++) {
        this.childrenList.push({ childList: childrenArr[i] });
        this.childrenCount++;
        this.decreaseChildrenDisabled = false;
        this.increaseCheck();
      }

      this.adultCount = Number(childrenArr[0]);
      if (childrenArr[1]) {
        this.childrenCount = Number(childrenArr[1]);
        this.paxCount = Number(childrenArr[1]) + Number(childrenArr[0]);
      } else {
        this.paxCount = Number(childrenArr[0]);
      }
    }
    //------------------ added to get paxes in dashboard from localStorage
    // if (!this.queryParams.paxes && localStorage.getItem("flightPaxes")) {
    //   const childrenArr = localStorage.getItem("flightPaxes").split(",");
    //   for (let i = 1; i < childrenArr.length; i++) {
    //     this.childrenList.push({ childList: childrenArr[i] });
    //     this.childrenCount++;
    //     this.decreaseChildrenDisabled = false;
    //     this.increaseCheck();
    //   }
    //
    //   this.adultCount = Number(childrenArr[0]);
    //   if (childrenArr[1]) {
    //     this.childrenCount = Number(childrenArr[1]);
    //     this.paxCount = Number(childrenArr[1]) + Number(childrenArr[0]);
    //   } else {
    //     this.paxCount = Number(childrenArr[0]);
    //   }
    // }
    if (!this.queryParams.cabinClass) {
      let selectedClass = localStorage.getItem("selectedClass");
      this.vars.classType.forEach((element) => {
        if (selectedClass === element.value) {
          this.selectedClass = element;
        }
      });
    }
    if (!this.queryParams.trip) {
      let trip = localStorage.getItem("selectedTripParam");
      if (trip === "ONEWAY") {
        this.selectedTrip = this.oneWayLocalized;
        this.restApi.selectedTrip = this.oneWayLocalized;
      } else if (trip === "ROUNDTRIP") {
        this.selectedTrip = this.roundTripLocalized;
        this.restApi.selectedTrip = this.roundTripLocalized;
      } else if (trip === "MULTICITY") {
        this.selectedTrip = this.multiCityLocalized;
        setTimeout(() => { // this will handle ExpressionChangedAfterItHasBeenCheckedError in dashboard
          this.restApi.selectedTrip = this.multiCityLocalized;
        }, 0);
      }
    }

    //-------------------------------------------
    if (this.adultCount === 1) {
      this.decreaseAdultDisabled = true;
    }
  });

  }
  setDepartAndReturnDates(){
    let splitDateDeparture = this.departingRange.split("-");
    let checkDepartureDateObj: any;
    checkDepartureDateObj = {
      year: Number(splitDateDeparture[0]),
      month: Number(splitDateDeparture[1]),
      day: Number(splitDateDeparture[2]),
    };
    this.departureDate = this.calendar.getNext(checkDepartureDateObj, "d", 0);
    let splitDateReturn = this.returningRange?.split("-");
    let checkReturnDateObj: any;
    checkReturnDateObj = {
      year: Number(splitDateReturn[0]),
      month: Number(splitDateReturn[1]),
      day: Number(splitDateReturn[2]),
    };
    this.returnDate = this.calendar.getNext(checkReturnDateObj, "d", 0);
    this.departingRangeFormatted = this.datePipe.transform(
        this.formatter.format(this.departureDate),
        "EE, MMM dd"
    );
    this.returningRangeFormatted = this.datePipe.transform(
        this.formatter.format(this.returnDate),
        "EE, MMM dd"
    );
  }
  checkDepartureDate(){
    if (!this.departureDate) {
      this.departingRange = moment().add(14, "days").format("YYYY-MM-DD");
      let splitDateDeparture = this.departingRange.split("-");
      let checkDepartureDateObj: any;
      checkDepartureDateObj = {
        year: Number(splitDateDeparture[0]),
        month: Number(splitDateDeparture[1]),
        day: Number(splitDateDeparture[2]),
      };
      this.departureDate = this.calendar.getNext(checkDepartureDateObj, "d", 0);
      this.departingRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.departureDate),
          "EE, MMM dd"
      );
    }
  }

  getNgDateFromISO(departureDateISO: string): NgbDate {
    const [year, month, day] = departureDateISO.split('-').map(Number);
    return new NgbDate(year, month, day);
  }

  // Helper method to convert ISO date string into a Date object
  getFormattedDateFromISO(isoDate: string) {
    const [year, month, day] = isoDate.split('-');
    return { year: +year, month: +month, day: +day };  // Returns an object in { year, month, day } format
  }
  // get f() {
  //   return this.searchForm.controls;
  // }
  // Function to open specific datepicker based on index
  // openDatepicker(index: number) {
  //   console.log('dddddddddddddddddd');
  //   const datepicker = this.datepickerArray.toArray()[index];
  //   if (datepicker) {
  //     datepicker.open();
  //   }
  // }

  setSearchTextReturn(entity, dist) {
    this.Query = entity.caption;
    this.restApi.captionReturn = entity.caption;
    //this.captionReturn = entity.caption;
    this.arrivalCode = entity.code;
    this.destination = dist;
    setTimeout(() => {
      this.autocompletelistReturn = [];
      this.searchQuery = entity.searchQuery;
      this.searchQueryReturn = entity.searchQuery;
      this.readyToSearch = true;
      localStorage.setItem("lastSearchReturn", JSON.stringify(entity));
      localStorage.setItem("lastSearchArrive0", JSON.stringify(entity));
    }, 500);
  }

  departReturnSwitch() {
    [this.restApi.captionDepart, this.restApi.captionReturn] = [
      this.restApi.captionReturn,
      this.restApi.captionDepart,
    ];
    // [this.captionDepart, this.captionReturn] = [
    //   this.captionReturn,
    //   this.captionDepart,
    // ];
    [this.departureCode, this.arrivalCode] = [
      this.arrivalCode,
      this.departureCode,
    ];
    [this.autocompletelistDepart, this.autocompletelistReturn] = [
      this.autocompletelistReturn,
      this.autocompletelistDepart,
    ];
    const tempEntityReturn = JSON.parse(
      localStorage.getItem("lastSearchReturn")
    );
    const tempEntityDepart = JSON.parse(
      localStorage.getItem("lastSearchDepart")
    );
    localStorage.setItem("lastSearchDepart", JSON.stringify(tempEntityReturn));
    localStorage.setItem("lastSearchReturn", JSON.stringify(tempEntityDepart));
  }

  departArriveSwitchArray(i) {
    [this.restApi.captionDepartArray[i], this.restApi.captionArriveArray[i]] = [
      this.restApi.captionArriveArray[i],
      this.restApi.captionDepartArray[i],
    ];
    [this.allFlights[i].captionDepart, this.allFlights[i].captionArrive] = [
      this.allFlights[i].captionArrive,
      this.allFlights[i].captionDepart,
    ];
    [this.allFlights[i].departureCode, this.allFlights[i].arrivalCode] = [
      this.allFlights[i].arrivalCode,
      this.allFlights[i].departureCode,
    ];
    [
      this.allFlights[i].autocompletelistDepart,
      this.allFlights[i].autocompletelistArrive,
    ] = [
      this.allFlights[i].autocompletelistArrive,
      this.allFlights[i].autocompletelistDepart,
    ];
    const tempEntityReturn = JSON.parse(
      localStorage.getItem("lastSearchArrive" + i)
    );
    const tempEntityDepart = JSON.parse(
      localStorage.getItem("lastSearchDepart" + i)
    );
    localStorage.setItem(
      "lastSearchDepart" + i,
      JSON.stringify(tempEntityReturn)
    );
    localStorage.setItem(
      "lastSearchArrive" + i,
      JSON.stringify(tempEntityDepart)
    );
  }

  setSearchTextDepart(entity, dist) {
    this.Query = entity.caption;
    this.restApi.captionDepart = entity.caption;
    //this.captionDepart = entity.caption;
    this.destination = dist;
    this.departureCode = entity.code;
    setTimeout(() => {
      this.autocompletelistDepart = [];
      this.searchQuery = entity.searchQuery;
      this.searchQueryDepart = entity.searchQuery;
      this.readyToSearch = true;
      localStorage.setItem("lastSearchDepart", JSON.stringify(entity));
      localStorage.setItem("lastSearchDepart0", JSON.stringify(entity));
    }, 500);
  }

  setSearchTextDepartArray(entity, dist, index) {
    this.Query = entity.caption;
    if (this.restApi.captionDepartArray[index] !== undefined) {
      this.restApi.captionDepartArray[index] = entity.caption;
    } else {
      this.restApi.captionDepartArray.push(entity.caption);
    }
    this.allFlights[index].captionDepart = entity.caption;
    this.destination = dist;
    this.allFlights[index].departureCode = entity.code;
    setTimeout(() => {
      this.allFlights[index].autocompletelistDepart = [];
      this.searchQuery = entity.searchQuery;
      this.readyToSearch = true;
      // if (this.allFlightsLocalStorageDepartArray[index]) {
      //   this.allFlightsLocalStorageDepartArray[index] = entity;
      // } else {
      //   this.allFlightsLocalStorageDepartArray.push(entity);
      // }
      if(index === 0){
        localStorage.setItem("lastSearchDepart" + index, JSON.stringify(entity));
      }

    }, 500);
    //console.log(this.restApi.captionDepartArray);
  }
  setSearchTextReturnArray(entity, dist, index) {
    this.Query = entity.caption;
    if (this.restApi.captionArriveArray[index]) {
      this.restApi.captionArriveArray[index] = entity.caption;
    } else {
      this.restApi.captionArriveArray.push(entity.caption);
    }
    this.allFlights[index].captionArrive = entity.caption;
    this.destination = dist;
    this.allFlights[index].arrivalCode = entity.code;

    //set the departure for the next flight if exist
    if(this.allFlights[index + 1]){
      this.allFlights[index + 1].captionDepart = entity.caption;
      this.allFlights[index + 1].departureCode = entity.code;
      //localStorage.setItem("lastSearchDepart" + (index + 1), JSON.stringify(entity));
      if (this.restApi.captionDepartArray[index + 1] !== undefined) {
        this.restApi.captionDepartArray[index + 1] = entity.caption;
      } else {
        this.restApi.captionDepartArray.push(entity.caption);
      }
    }

    ///////////////

    setTimeout(() => {
      this.allFlights[index].autocompletelistArrive = [];
      this.searchQuery = entity.searchQuery;
      this.readyToSearch = true;
      // if (this.allFlightsLocalStorageArriveArray[index]) {
      //   this.allFlightsLocalStorageArriveArray[index] = entity;
      // } else {
      //   this.allFlightsLocalStorageArriveArray.push(entity);
      // }
       //localStorage.setItem("lastSearchArrive" + index, JSON.stringify(entity));
      if(index === 0){
        localStorage.setItem("lastSearchArrive" + index, JSON.stringify(entity));
      }
    }, 500);
    // console.log(this.restApi.captionDepartArray);
    // console.log(this.restApi.captionArriveArray);
    // console.log(this.allFlights);
  }



  removeFlight(index: number) {
    // Remove the flight segment from the array
    this.allFlights.splice(index, 1);

    // Update captions
    this.restApi.captionArriveArray.splice(index, 1);
    this.restApi.captionDepartArray.splice(index, 1);
    console.log(this.allFlights);
    this.multiCityFlightSearchAutoComplete();
    // Shift localStorage keys
    //this.shiftLocalStorageKeys(index, this.allFlights.length);
  }

  shiftLocalStorageKeys(removeIndex: number, totalLength: number) {
    for (let i = removeIndex; i < totalLength; i++) {
      const nextArriveKey = `lastSearchArrive${i + 1}`;
      const nextDepartKey = `lastSearchDepart${i + 1}`;
      const currentArriveKey = `lastSearchArrive${i}`;
      const currentDepartKey = `lastSearchDepart${i}`;

      // Fetch values of the next keys
      const nextArriveValue = localStorage.getItem(nextArriveKey);
      const nextDepartValue = localStorage.getItem(nextDepartKey);

      // Move the next keys to the current position
      if (nextArriveValue !== null) {
        localStorage.setItem(currentArriveKey, nextArriveValue);
      } else {
        localStorage.removeItem(currentArriveKey);
      }

      if (nextDepartValue !== null) {
        localStorage.setItem(currentDepartKey, nextDepartValue);
      } else {
        localStorage.removeItem(currentDepartKey);
      }
    }

    // Remove the last pair of keys after the shift
    const lastArriveKey = `lastSearchArrive${totalLength}`;
    const lastDepartKey = `lastSearchDepart${totalLength}`;
    localStorage.removeItem(lastArriveKey);
    localStorage.removeItem(lastDepartKey);
  }


  addFlight() {
    this.allFlights.push({
      SearchTextQuery: new Subject<string>(),
      SearchTextQueryArrive: new Subject<string>(),
      autocompletelistDepart: [],
      autocompletelistArrive: [],
      departureDateISO: null,
      departureDate: null,
      departureDateFormatted: null,
      captionArrive: "",
      captionDepart: "",
      arrivalCode: null,
      departureCode: null,
    });
    // Ensure captionDepartArray has the same length as allFlights
    while (this.restApi.captionDepartArray.length < this.allFlights.length) {
      this.restApi.captionDepartArray.push("");
    }

    // Ensure captionArriveArray has the same length as allFlights
    while (this.restApi.captionArriveArray.length < this.allFlights.length) {
      this.restApi.captionArriveArray.push("");
    }

    this.multiCityFlightSearchAutoComplete();
    // this.searchForm.addControl(
    //   (this.allFlights.length - 1).toString(),
    //   this.formBuilder.group({
    //     departAutoComplete: new FormControl(),
    //   })
    // );
    // this.searchForm.addControl(
    //   "arrive" + (this.allFlights.length - 1),
    //   this.formBuilder.group({
    //     departAutoComplete: new FormControl(),
    //   })
    // );
    this.allFlights.forEach((element, index) => {
      if (!element.departureDateISO) {
        //element.departureDateISO = moment().add(14, "days").format("YYYY-MM-DD");
        element.departureDate = this.allFlights[index - 1].departureDate;
        element.departureDateISO = this.allFlights[index - 1].departureDateISO;
        element.departureDateFormatted = this.allFlights[index - 1].departureDateFormatted
      }
    });
  }
  getMinDate(index: number): NgbDateStruct {
    // If it's the first datepicker, return today's date as minDate
    if (index === 0) {
      return this.calendar.getNext(this.calendar.getToday(), 'd', 0);
    }

    // For subsequent datepickers, return the date selected in the previous input
    const previousDate = this.allFlights[index - 1].departureDate;
    if (previousDate) {
      return this.calendar.getNext(previousDate, 'd', 0); // Use the previous selected date as minDate
    }

    // Fallback to today's date if no previous date is found (just in case)
    return this.calendar.getNext(this.calendar.getToday(), 'd', 0);
  }
  onSubmit() {
    this.flightSearchUserSelectionService.submitted = true;
    //this.selectTripType(this.selectedTrip);
    console.log(this.allFlights);
    this.restApi.alertsOverRoute = [];
    this.submitted = true;
    if(!this.flightSearchUserSelectionService.selectedUser){
      return;;
    }
    if(this.selectedTrip === "Round-trip" ||
        this.selectedTrip === "One-way" ||
        this.selectedTrip === this.roundTripLocalized ||
        this.selectedTrip === this.oneWayLocalized){
      // if (
      //     this.searchQueryReturn === undefined ||
      //     this.searchQueryDepart === undefined
      // ) {
      //   return;
      // }
      if (
          !this.departureCode ||
          !this.arrivalCode ||
          (this.restApi.captionDepart === "" || this.restApi.captionReturn === "")
      ) {
        return;
      }
    }

    let inValidMultiCity = false;
    if (
      this.selectedTrip === "Multi-city" ||
      this.selectedTrip === this.multiCityLocalized
    ) {
      this.allFlights.forEach((element, index) => {
        if ( !element.departureCode ||
            !element.arrivalCode) {
          inValidMultiCity = true;
        }
      });
      if (inValidMultiCity) {
        return;
      }
    }

    this.Childrenpax = "";
    if (this.childrenCount) {
      this.Childrenpax = "," + this.childrenCount;
    }
    //localStorage.setItem("flightPaxes", this.adultCount + this.Childrenpax);
    localStorage.setItem("selectedClass", this.selectedClass.value);
    let searchType = "";
    let finalCheckDepart;
    let finalCheckReturn;
    let departMonth =
      (this.departureDate.month < 10 ? "0" : "") + this.departureDate.month;
    let departDay =
      (this.departureDate.day < 10 ? "0" : "") + this.departureDate.day;
    let returnMonth =
      (this.returnDate.month < 10 ? "0" : "") + this.returnDate.month;
    let returnDay = (this.returnDate.day < 10 ? "0" : "") + this.returnDate.day;
    finalCheckDepart =
      this.departureDate.year + "-" + departMonth + "-" + departDay;


      searchType = "flights/availability";
 /*   let localStorageFlightItem =   {
        roundTrip: false,
        cabinClass: this.selectedClass.value,
        airQueries: [
            {
              departureDate: finalCheckDepart,
              departureSearchCode: this.departureCode,
              arrivalSearchCode: this.arrivalCode,
            }
        ],
        adultCount: this.adultCount,
        childCount: 0,
        infantSeatedCount: 0,
        infantLapCount: 0,
      lookupSearchCaptions: {
        departureCaptions: [this.captionDepart],
        arrivalCaptions: [this.captionReturn],
      },
        directFlightsOnly: this.directFlightsOnly,
        travelerId: [this.selectedUser.id]
    };*/


    let localStorageFlightItem = {
      roundTrip: false,
      cabinClass: this.selectedClass.value,
      airQueries: [
        {
          departureDate: finalCheckDepart,
          departureSearchCode: this.departureCode,
          departureSearchType: "AIRPORT",
          arrivalSearchCode: this.arrivalCode,
          arrivalSearchType: "AIRPORT",
        },
      ],
      lookupSearchCaptions: {
        departureCaptions: [this.restApi.captionDepart],
        arrivalCaptions: [this.restApi.captionReturn],
      },
      directFlightsOnly: this.directFlightsOnly,
      adultCount: this.adultCount,
      childCount: 0,
      infantCount: 0,
    };

    if (
      this.selectedTrip === "Round-trip" ||
      this.selectedTrip === this.roundTripLocalized
    ) {
      this.selectedTripParam = "ROUNDTRIP";
      localStorage.setItem("selectedTripParam", this.selectedTripParam);
      finalCheckReturn =
        this.returnDate.year + "-" + returnMonth + "-" + returnDay;
      localStorageFlightItem.roundTrip = true;
      localStorageFlightItem.airQueries[0]["returnDate"] = finalCheckReturn;
      localStorage.setItem(
        "flightSearchDates",
        JSON.stringify({
          departureDate: finalCheckDepart,
          returnDate: finalCheckReturn,
        })
      );
      // let arr = JSON.parse(localStorage.getItem("flight_history_storage"));
      // if (
      //   arr &&
      //   JSON.stringify(localStorageFlightItem) === JSON.stringify(arr[0]) &&
      //   this.router.url.includes("availability")
      // ) {
      //   if (this.travelerId === this.selectedUser.id) {
      //     return false;
      //   }
      // }
      this.router.navigate([searchType], {
        queryParams: {
          trip: this.selectedTripParam,
          directOnly: this.directFlightsOnly,
          cabinClass: this.selectedClass.value,
          departureDate: finalCheckDepart,
          departureCode: this.departureCode,
          arrivalCode: this.arrivalCode,
          paxes: this.adultCount + this.Childrenpax,
          returnDate: finalCheckReturn,
          travelerId: this.flightSearchUserSelectionService.selectedUser?.id,
        },
      });
    } else if (
      this.selectedTrip === "One-way" ||
      this.selectedTrip === this.oneWayLocalized
    ) {
      this.selectedTripParam = "ONEWAY";
      localStorage.setItem("selectedTripParam", this.selectedTripParam);
      localStorage.setItem(
        "flightSearchDates",
        JSON.stringify({
          departureDate: finalCheckDepart,
          returnDate: moment(finalCheckDepart)
            .add(3, "days")
            .format("YYYY-MM-DD"),
        })
      );

      // let arr = JSON.parse(localStorage.getItem("flight_history_storage"));
      // if (
      //   arr &&
      //   JSON.stringify(localStorageFlightItem) === JSON.stringify(arr[0]) &&
      //   this.router.url.includes("availability")
      // ) {
      //   if (this.travelerId === this.selectedUser.id) {
      //     return false;
      //   }
      // }
      this.router.navigate([searchType], {
        queryParams: {
          trip: this.selectedTripParam,
          directOnly: this.directFlightsOnly,
          cabinClass: this.selectedClass.value,
          departureDate: finalCheckDepart,
          departureCode: this.departureCode,
          arrivalCode: this.arrivalCode,
          paxes: this.adultCount + this.Childrenpax,
          travelerId: this.flightSearchUserSelectionService.selectedUser?.id,
        },
      });
    } else if (
      this.selectedTrip === "Multi-city" ||
      this.selectedTrip === this.multiCityLocalized
    ) {
      this.selectedTripParam = "MULTICITY";
      localStorage.setItem("selectedTripParam", this.selectedTripParam);

      console.log(this.allFlights);
      const segments = this.allFlights
          .filter((flight) => flight.departureCode && flight.arrivalCode && flight.departureDateISO) // Filter flights with complete data
          .map((flight) => `${flight.departureCode},${flight.arrivalCode},${flight.departureDateISO}`)
          .join(';');

      console.log(segments);
      localStorage.setItem(
        "lastSearchDepartAndReturnArray",
        this.allFlights.length.toString()
      );

      // Assuming your array of flights is called `allFlights`
      this.restApi.captionDepartArray = this.allFlights.map(flight => flight.captionDepart);
      this.restApi.captionArriveArray = this.allFlights.map(flight => flight.captionArrive);

      // localStorage.setItem("captionDepartArray", JSON.stringify(this.restApi.captionDepartArray));
      // localStorage.setItem("captionArriveArray", JSON.stringify(this.restApi.captionArriveArray));
       localStorage.setItem("captionDepartArray", JSON.stringify(this.restApi.captionDepartArray.slice(0, 1)));
       localStorage.setItem("captionArriveArray", JSON.stringify(this.restApi.captionArriveArray.slice(0, 1)));

      this.router.navigate([searchType], {
        queryParams: {
          trip: this.selectedTripParam,
          directOnly: this.directFlightsOnly,
          cabinClass: this.selectedClass.value,
          paxes: this.adultCount + this.Childrenpax,
          travelerId: this.flightSearchUserSelectionService.selectedUser?.id,
          segments: segments,
        },
      });

    }

    // if (!this.restApi.flightHistoryArray) {
    //   const historyArr = [];
    //   historyArr.unshift(localStorageFlightItem);
    //   localStorage.setItem(
    //     "flight_history_storage",
    //     JSON.stringify(historyArr)
    //   );
    // } else {
    //   let historyArr = this.restApi.flightHistoryArray;
    //   if (historyArr.length === 5) {
    //     historyArr.splice(-1, 1);
    //     historyArr.unshift(localStorageFlightItem);
    //     localStorage.setItem(
    //       "flight_history_storage",
    //       JSON.stringify(historyArr)
    //     );
    //   } else {
    //     historyArr.unshift(localStorageFlightItem);
    //     localStorage.setItem(
    //       "flight_history_storage",
    //       JSON.stringify(historyArr)
    //     );
    //   }
    // }

    this.router.onSameUrlNavigation = "reload";
  }

  decreaseCheck() {
    this.paxCount = this.childrenCount + this.adultCount;
  }
  increaseCheck() {
    this.paxCount = this.childrenCount + this.adultCount;

    if (this.childrenCount + this.adultCount === 9) {
      this.increasDisabled = true;
      return true;
    } else {
      this.increasDisabled = false;
    }
  }

  decreaseAdult() {
    this.adultCount--;
    this.paxCount--;
    this.increasDisabled = false;
    this.decreaseCheck();
    if (this.adultCount === 1) {
      this.decreaseAdultDisabled = true;
      return true;
    } else {
      this.decreaseDisabled = false;
    }
  }

  increaseAdult() {
    this.adultCount++;
    this.paxCount++;
    this.decreaseAdultDisabled = false;
    this.increaseCheck();
  }

  decreaseChildren() {
    this.childrenCount--;
    this.paxCount--;
    this.increasDisabled = false;
    this.childrenList.pop();
    this.decreaseCheck();
    if (this.childrenCount === 0) {
      this.decreaseChildrenDisabled = true;
      return true;
    } else {
      this.decreaseDisabled = false;
    }
  }

  increaseChildren() {
    this.childrenCount++;
    this.paxCount++;
    this.decreaseChildrenDisabled = false;
    this.increaseCheck();
    this.childrenList.push({ childList: "" });
  }

  autoCompleteListDepart() {
    if (this.Query && this.Query.length > 2) {
     this.autoCompleteDepartSubscription =  this.restApi
        .autoCompleteAirports(
          this.Query,
          sessionStorage.getItem("session-token")
        )
        .subscribe((success) => {
          this.data = success;
          this.autocompletelistDepart = this.data.data;
        });
     this.subscriptions.add(this.autoCompleteDepartSubscription);
    }
  }

  autoCompleteListDepartArray(index) {
    if (this.Query && this.Query.length > 2) {
      this.autoCompleteListDepartArraySubscription =  this.restApi
        .autoCompleteAirports(
          this.Query,
          sessionStorage.getItem("session-token")
        )
        .subscribe((success) => {
          this.data = success;
          this.allFlights[index].autocompletelistDepart = this.data.data;
        });
      this.subscriptions.add(this.autoCompleteListDepartArraySubscription);
    }
  }

  autoCompleteListReturnArray(index) {
    if (this.Query && this.Query.length > 2) {
      this.restApi
        .autoCompleteAirports(
          this.Query,
          sessionStorage.getItem("session-token")
        )
        .subscribe((success) => {
          this.data = success;
          this.allFlights[index].autocompletelistArrive = this.data.data;
        });
    }
  }

  autoCompleteListReturn() {
    if (this.Query && this.Query.length > 2) {
      this.restApi
        .autoCompleteAirports(
          this.Query,
          sessionStorage.getItem("session-token")
        )
        .subscribe((success) => {
          this.data = success;
          this.autocompletelistReturn = this.data.data;
        });
    }
  }

  autocompleteOnFocusDepart(event) {
    event.target.select();
    if (!sessionStorage.getItem("session-token")) {
      this.restApi.sessionToken = uuid();
      sessionStorage.setItem("session-token", this.restApi.sessionToken);
    }
  }

  autocompleteOnFocusReturn(event) {
    event.target.select();
    if (!sessionStorage.getItem("session-token")) {
      this.restApi.sessionToken = uuid();
      sessionStorage.setItem("session-token", this.restApi.sessionToken);
    }
  }

  onClickBody(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.checkDepartureDate();
      if (!this.returnDate) {
        this.returnDate = this.calendar.getNext(this.departureDate, "d", 1);
        this.returningRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.returnDate),
          "EE, MMM dd"
        );
      }
      this.validChild = false;
      this.onclickActive = true;
    }
  }

  keepOnMenu() {
    setTimeout(() => {
      if (this.onclickActive === true) {
        this.onclickActive = false;
      }
    }, 10);
  }

  consumerSearchTabs() {
    return (
      (!this.restApi.isCompany() &&
        this.router.url === "/pass/save-now-travel-later/welcome") ||
      (!this.restApi.isCompany() && this.restApi.urlWithoutParams === "/") ||
      (!this.restApi.isCompany() &&
        this.router.url === "/pass/free-trial-subscription/welcome")
    );
  }


  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
    if(this.flightSearchUserSelectionService.userInfoSubscription){
      this.flightSearchUserSelectionService.userInfoSubscription.unsubscribe();
    }
  }
}
