<div class="bulk-invite container py-4 position-relative" *ngIf="showUploadForm">
  <p class="gray-text-dark">
    <ng-container i18n="@@useCSV1">Use csv file upload to send invitations. Email address is mandatory. Sample file can</ng-container><br/><ng-container i18n="@@useCSV2"> be found</ng-container>
  <a class="blue-text" target="_blank" [href]="env.csvFileUrl" i18n="@@here"> here</a>
  </p>

  <form [formGroup]="bulkInvitationForm" name="priceGuaranteeForm">
  <!--<div class="form-group">
    <input type="file" name="file" id="file"  class="form-control" formControlName="file"
           accept=".csv"
           (change)="onFileSelect($event)"
           [ngClass]="{ 'is-invalid': submitted && f.file.errors }" />
    <label for="file" class="sharp-font blue-text bold-500 pointer mt-5 font-14"
           [ngClass]="{ 'border-red': submitted && f.file.errors, 'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}" i18n="@@chooseFile">
      Choose file
    </label>
      <span *ngIf="showFileName" class="gray-text font-16">{{fileName}}</span>
  </div>-->

    <div class="mx-auto corporate-box position-relative p-0">

      <div class="drag-drop-container d-flex align-items-center" appDnd (fileDropped)="onFileDropped($event)">
        <div class="w-100 pt-4 mt-3">

          <input id="fileDropRef" (change)="onFileSelect($event)"
                 type="file" name="file" class="form-control" formControlName="file"
                 accept=".csv" />
          <p class="text-danger" *ngIf="message !== ''">{{message}}</p>
          <p class="placeholder-text pb-0 pb-lg-4 mb-0 mb-lg-3 text-center px-4" *ngIf="message === '' && dataService.corporateLocale.includes('en')">
            Drag and drop, or <span class="blue-text pointer">browse</span> your file</p>
          <p class="placeholder-text pb-0 pb-lg-4 mb-0 mb-lg-3 text-center px-4" *ngIf="message === '' && !dataService.corporateLocale.includes('en')">
            <ng-container i18n="@@dragDropFile">Drag and drop, or</ng-container> <span class="blue-text pointer" i18n="@@clickHere">click</span>
            <ng-container i18n="@@chooseHere">to choose your file</ng-container> </p>
        </div>
      </div>
    </div>

    <div class="files-list d-flex mt-4" [ngClass]="{'mr-auton': dataService.isSiteLtr(), 'ml-auto': dataService.isSiteRtl()}"
         *ngIf="fileToProcess && showProcessing">
      <div class="single-file w-100">
        <div class="file-icon" style="width: 50px">

        </div>
        <div class="info pb-1">
          <h4 class="name" [ngClass]="{'text-left': dataService.isSiteLtr(), 'test-right': dataService.isSiteRtl()}">
            {{ fileToProcess.name }}
          </h4>
          <div class="progress-cont">
            <div class="progress" [style.width]="progress + '%'">
            </div>
          </div>
        </div>

      </div>
      <i *ngIf="this.progress === 100" class="icon-close-light gray-text-dark smaller pointer" [ngClass]="{'pl-3': dataService.isSiteLtr(), 'pr-3': dataService.isSiteRtl()}" (click)="deleteFile()"></i>
    </div>


<div class="d-flex justify-content-end mt-3 actions position-absolute">
  <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" (click)="dataService.modalOf()" i18n="@@cancelBtn">Cancel </button>
  <button class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5"
          [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}"
          (click)="processData()" i18n="@@sendInvites" [disabled]="this.progress !== 100">Send Invitations</button>
</div>

    <div *ngIf="submitted && f.file.errors && !this.isDrag" class="text-danger font-14 position-absolute error-msg" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">
      <p class="mb-0 mt-2" *ngIf="f.file.errors.required" i18n="@@csvRequired">CSV file is required</p>
    </div>
  </form>
</div>

<div class="bulk-invite container py-4" *ngIf="showProcessingCSV">
  <div class="d-flex align-items-center text-center position-relative my-auto mx-0 text-center h-100">
    <div class="gray-text w-100 font-16">
      <div class="m-progress mb-5"></div>
      <p><ng-container i18n="@@processInvites">Processing</ng-container>{{csvCounter}}<ng-container i18n="@@outOf">out of</ng-container>{{totalProcessed}}<ng-container i18n="@@invitations">invitations</ng-container></p>
    </div>
  </div>
</div>

<div class="bulk-invite container py-4 position-relative" *ngIf="showCounters">
<div class="d-flex align-items-center text-center position-relative my-auto mx-0 text-center h-100">
  <div class="gray-text w-100 font-16">
    <p i18n="@@successfulOne" *ngIf="successfulInvitations <= 1">{{successfulInvitations}} successful invitation</p>
    <p i18n="@@successfulMany" *ngIf="successfulInvitations > 1">{{successfulInvitations}} successful invitations</p>
    <p *ngIf="failedInvitations">
      <ng-container *ngIf="failedInvitations <= 1" i18n="@@failedOne">{{failedInvitations}} failed invitation </ng-container>
      <ng-container *ngIf="failedInvitations > 1" i18n="@@failedMany">{{failedInvitations}} failed invitations </ng-container>
      <span class="blue-text text-underline pointer" (click)="showFailedDetails = true; showCounters = false;" i18n="@@clickForDetails">click for details</span>
    </p>
  </div>
</div>

    <div class="d-flex justify-content-end mt-3 actions position-absolute">
      <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" (click)="dataService.modalOf()" i18n="@@cancelBtn">Cancel </button>
      <button class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5"
              [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}"
              (click)="startOverReset()" i18n="@@startOver">Startover</button>
    </div>
</div>

<div class="bulk-invite container py-4 position-relative" *ngIf="showFailedDetails">
  <div class="d-flex align-items-center text-center position-relative my-auto mx-0 text-center h-100">
    <div class="gray-text w-100 font-16">
      <p *ngFor="let item of errorDetailsArray">{{item.email}}: {{item.errorMsg}}</p>
    </div>
  </div>

  <div class="d-flex justify-content-end mt-3 actions position-absolute">
    <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" (click)="dataService.modalOf()" i18n="@@cancelBtn">Cancel </button>
    <button class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5"
            [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}"
            (click)="startOverReset()" i18n="@@startOver">Startover</button>
  </div>
</div>
