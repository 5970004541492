import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";

import { DataService } from "../../services/data.service";
import { AuthServiceMain } from "../../auth/auth.service";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../../google-analytics.service";
import { DOCUMENT } from "@angular/common";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-workspace-header",
  templateUrl: "./workspace-header.component.html",
  styleUrls: ["./workspace-header.component.scss"],
  host: {
    "(body:click)": "onClickBody($event)",
  },
})
export class WorkspaceHeaderComponent implements OnInit {
  logedin = false;
  logdinlocal = false;
  env = environment;
  constructor(
    @Inject(DOCUMENT) private doc: any,
    public googleAnalyticsService: GoogleAnalyticsService,
    public Modal: DataService,
    public logedIn: AuthServiceMain,
    public screenSize: DataService,
    public router: Router,
    private eref: ElementRef,
    @Inject(PLATFORM_ID) public platformId
  ) {
    this.screenSize.componentMethodCalled$.subscribe(() => {
      this.logedin = this.logedIn.isLogedIn;
      this.logdinlocal = true;
    });
  }

  ngOnInit() {
    this.Modal.dropDowmBookingForm = false;
    this.logedin = this.logedIn.isLogedIn;
    const htmlTag = this.doc.getElementsByTagName("html")[0];
    this.screenSize.corporateLocale = htmlTag.getAttribute("lang");
  }

  openManageWorkspaceMenu() {
    this.screenSize.manageWorkspaceMenuActive = true;
  }
  closeMenu() {
    this.screenSize.menuActive = false;
    this.screenSize.enableBodyScroll();
  }
  CompleteYourBooking() {
    if (this.Modal.mobileView) {
      this.Modal.NotifymodalOf();
    } else {
      this.Modal.dropDowmBookingForm = false;
    }
  }

  opendropDowmBookingForm() {
    if (this.Modal.mobileView) {
      this.Modal.setNotifyModal("CompleteYourBooking", "");
    } else {
      this.Modal.dropDowmBookingForm = true;
    }
  }
  onClickBody(event) {
    if (
      this.eref.nativeElement.querySelector(".bookingDropLogo") &&
      !this.eref.nativeElement
        .querySelector(".bookingDropLogo")
        .contains(event.target) &&
      this.Modal.desktopView
    ) {
      this.Modal.dropDowmBookingForm = false;
    }
  }
}
