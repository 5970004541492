import { Component, OnInit } from '@angular/core';
import {DataService} from "../data.service";

@Component({
  selector: 'app-snak-bar',
  templateUrl: './snak-bar.component.html',
  styleUrls: ['./snak-bar.component.scss']
})
export class SnakBarComponent implements OnInit {

  constructor(public Service: DataService) { }

  ngOnInit() {
  }

}
