import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, OnInit, ViewChild, ElementRef, Input} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {DataService} from '../../../../services/data.service';
import {environment} from '../../../../../environments/environment';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
export class Policy {
  constructor(public id: string, public name: string, public selected?: boolean) {
    if (selected === undefined) { selected = false;}
  }
}

export class Report {
  constructor(public reportID: string, public reportName: string, public selected?: boolean) {
    if (selected === undefined) { selected = false;}
  }
}
@Component({
  selector: 'app-upload-expenses',
  templateUrl: './upload-expenses.component.html',
  styleUrls: ['./upload-expenses.component.scss']
})
export class UploadExpensesComponent implements OnInit {
  data;
  dataPolicy;
  inputControl = new FormControl();
  policyControl = new FormControl();
  submitting = false;
  separatorKeysCodes = [ENTER, COMMA];
  addOnBlur: false;
  showAutoCompletePopUp = false;
  showPolicyAutoCompletePopUp = false;
  reports = [];
  policies = [];
  selectedItem;
  selectedPolicy;
  filteredItems: Observable<Report[]>;
  filteredPolicies: Observable<any[]>;
  lastFilter: string = '';
  lastFilterPolicy: string = '';
  expensesData;
  showCreateNew;
  errorMsg;
  NoPolicy: Policy = {name: '', id: null};
  applyBlur = true;
  @ViewChild('itemInput') itemInput: ElementRef;
  @ViewChild('policyInput') policyInput: ElementRef;
  constructor(public dataService: DataService, public router: Router, public auth: AuthServiceMain) { }

  ngOnInit(): void {
    if (this.dataService.desktopView) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('workspace-modal');
    }
    if (this.dataService.desktopView) {
      this.expensesData = this.dataService.modalData;
    } else {
      this.expensesData = this.dataService.BottomSheetData;
    }
    this.policiesAutoComplete();
    this.reportsAutoComplete();
  }
  policiesAutoComplete() {
    this.dataService.getExpensifyPolicies().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataPolicy = this.data.data;
          this.dataPolicy.forEach((element) => {
            this.policies.push(new Policy(element.id, element.name));
          });
          this.filteredPolicies = this.policyControl.valueChanges.pipe(
            startWith<string | Policy[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilterPolicy),
            map(filter => this.filterPolicy(filter))
          );
        } else {
          this.errorMsg = this.data.message;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
      }
    );
  }
  reportsAutoComplete() {
    this.dataService.expensifyReports().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.data = this.data.data;
         // this.data = this.dummyReports;
          this.data.forEach((element) => {
              this.reports.push(new Report(element.reportID, element.reportName));
          });
          this.filteredItems = this.inputControl.valueChanges.pipe(
            startWith<string | Report[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter))
          );
        } else {
          this.errorMsg = this.data.message;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
      }
    );
  }
  filterPolicy(filter: string): Policy[] {
    this.lastFilterPolicy = filter;
    if (filter) {
      return this.policies.filter(option => {
        return option.name.toLowerCase().includes(filter.toLowerCase());
      });
    } else {
      return this.policies.slice();
    }
  }
  filter(filter: string): Report[] {
    this.lastFilter = filter;
    if (filter) {
      return this.reports.filter(option => {
        const result =  option.reportName.toLowerCase().includes(filter.toLowerCase());
        if (!result.length) {
          this.showCreateNew = true;
        }
        return result;
      });
    } else {
      this.showCreateNew = false;
      return this.reports.slice();
    }
  }
  displayFn(report: Report): string {
    return report && report.reportName ? report.reportName : '';
  }
  displayFnPolicy(policy: Policy): string {
    return policy && policy.name ? policy.name : '';
  }
 /* optionClickedMobile() {
    this.selectedItem = new Report(null, this.inputControl.value);
    this.showAutoCompletePopUp = false;
    this.dataService.enableBodyScroll();
  }*/
  resetPolicy() {
    setTimeout(() => {
      if (typeof this.policyControl.value !== 'object') {
          if (this.dataService.desktopView) {
            const input = document.getElementById('policyInputHtml') as HTMLInputElement;
            if(input) {
              input.blur();
            }
          }
        this.policyInput.nativeElement.value = '';
        this.lastFilterPolicy = '';
        this.selectedPolicy = this.NoPolicy;
        this.policyControl.setValue(this.selectedPolicy);
      }
        this.filteredPolicies = this.policyControl.valueChanges
            .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : this.lastFilterPolicy),
              map(name => name ? this.filterPolicy(name) : this.policies.slice())
            );
    }, 200);
  }

  optionClickedPolicy(event: Event, policy: Policy) {
    this.showPolicyAutoCompletePopUp = false;
    this.dataService.enableBodyScroll();
    this.selectedPolicy = policy;
    this.policyControl.setValue(this.selectedPolicy);
  }
  optionClicked(event: Event, report: Report) {
    if (report === null) {
      this.selectedItem = new Report(null, this.inputControl.value);
    } else {
      this.selectedItem = report;
    }
      this.showAutoCompletePopUp = false;
      this.dataService.enableBodyScroll();
      this.inputControl.setValue(this.selectedItem);
  }
  reportNameText() {
    if (typeof this.inputControl.value !== 'object') {
      this.selectedItem = new Report(null, this.inputControl.value);
    }
  }
  showPolicyPopUp() {
    this.showPolicyAutoCompletePopUp = true;
    this.dataService.disableBodyScroll();
    if (this.selectedItem) {
      this.policyControl.setValue(this.selectedPolicy);
    }
    setTimeout(() => {
      const input = document.getElementById('policyInput') as HTMLInputElement;
      input.select();
      input.focus();
    }, 200);
    return;
  }
  showPopUp() {
    this.showAutoCompletePopUp = true;
    this.dataService.disableBodyScroll();
    if (this.selectedItem) {
      this.inputControl.setValue(this.selectedItem);
    }
    setTimeout(() => {
      const input = document.getElementById('itemInput') as HTMLInputElement;
      input.select();
      input.focus();
    }, 200);
    return;
  }
  upload() {
    let dataToSend = '?referenceNumber=' + this.expensesData;
    if (this.selectedPolicy && this.selectedPolicy.id) {
      dataToSend += '&policyId=' + this.selectedPolicy.id;
    }
    if (this.selectedItem && this.selectedItem.reportID) {
      dataToSend += '&reportId=' + this.selectedItem.reportID;
    } else if (this.selectedItem && this.selectedItem.reportName) {
      dataToSend += '&reportName=' + this.selectedItem.reportName;
    }
    this.dataService.expensifyAddHotelBookingExpense(dataToSend).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.data = this.data.data;
          this.dataService.expensifyLastModifiedDate  = this.data.expensifyLastModifiedDate;
          this.dataService.expensifyLastReportName  = this.data.expensifyLastReportName;
          this.dataService.modalOf();
          this.dataService.BottomSheetOf();
        } else {
          this.errorMsg = this.data.message;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
      }
    );
  }

  hidePopUp() {
    this.showAutoCompletePopUp = false;
    this.dataService.enableBodyScroll();
  }
}
