import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CostCenter } from 'src/app/shared/model/cost-management/cost-center';
import { Label } from 'src/app/shared/model/cost-management/label';

@Injectable({
    providedIn: 'root'
})
export class CostBookingStateService {
    selectedCostCenters: CostCenter[] = [];
    selectedLabels: Label[] = [];
    isCostCenterMandatory: boolean = false;
    isLabelMandatory: boolean = false;

    private isSubmittedSubject = new BehaviorSubject<boolean>(false);
    isSubmitted$ = this.isSubmittedSubject.asObservable();

    constructor() { }

    setCostCenters(value: CostCenter[]) {
        this.selectedCostCenters = value;
    }

    setLabels(value: Label[]) {
        this.selectedLabels = value;
    }

    setIsCostCenterMandatory(value: boolean) {
        this.isCostCenterMandatory = value;
    }

    setIsLabelMandatory(value: boolean) {
        this.isLabelMandatory = value;
    }

    setIsSubmittedAndCheckValidation(value: boolean) {
        this.isSubmittedSubject.next(value);
        const isValidCenter = !value || !this.isCostCenterMandatory || (value && this.isCostCenterMandatory && this.selectedCostCenters?.length > 0);
        const isValidLabels = !value || !this.isLabelMandatory || (value && this.isLabelMandatory && this.selectedLabels?.length > 0);
        return isValidCenter && isValidLabels;
    }

    refrehData() {
        this.selectedCostCenters = [];
        this.selectedLabels = [];
        this.isCostCenterMandatory = false;
        this.isLabelMandatory = false;
        this.isSubmittedSubject.next(false);
    }
}