<div class="holder">

  <div *ngIf="Service.desktopView" class="prime-banner mb-5 color-primary d-flex align-items-md-center">

    <div class="container pass-banner position-relative">
      <div class="row hero-container">
        <div class="col-md-7 my-auto" [ngClass]="{'pr-lg-0': Service.isSiteLtr(), 'pl-lg-0': Service.isSiteRtl()}">
          <h1 i18n="@@landingPageH1" class="font-weight-bold font-32-to-26 mb-5 text-white">Full Corporate Travel Management Platform. Instant Savings.</h1>
          <p i18n="@@landingPageUnderH1" class="font-24-to-20 pb-2 list-unstyled line-extra-height text-white">
            A cloud platform with modern technology that simplifies and automates business travel in minutes.
          </p>
          <div class="d-flex">
            <div [ngClass]="{'mr-5': Service.isSiteLtr(), 'ml-5': Service.isSiteRtl()}">
              <button i18n="@@getStartBtn" (click)="SendGAevent('CTA-top')" routerLink="/join" class="mt-5 btn-block btn pass-btn green_btn bold-500 font-18 px-5">
                Get Started
              </button>

            </div>
            <div class="">
              <button i18n="@@landingRequestBtn" (click)="requestDemo()" class="mt-5 btn-block btn pass-btn btn-outline-blue bold-500 font-18 px-4">
                Request Demo
              </button>

            </div>
          </div>

        </div>
<div class="col-md-5">
  <img width="499" height="475" loading="lazy" class="hero-img rounded position-absolute" alt="two travelers" src="assets/img/woman-in-airport-lounge-for-landing.png">
</div>
      </div>

    </div>

  </div>
  <section *ngIf="Service.mobileView">
    <div class="bold-600 font-34 primary-bg text-white px-3 py-5 text-center">
      <p i18n="@@landingPageH1" class="mb-5 sharp-font">Full Corporate Travel Management Platform. Instant Savings.</p>
    </div>
    <div class="container">
      <p i18n="@@landingPageUnderH1" class="font-24-to-20 font-weight-normal mb-0 mt-5 list-unstyled line-extra-height dark-gray text-center">
        A cloud platform with modern technology that simplifies and automates business travel in minutes.
      </p>
      <div class="row pb-5">
        <div class="col-9 col-md-4 mx-auto">
          <button i18n="@@getStartBtn" (click)="SendGAevent('CTA-top')" routerLink="/join" class="mt-5 btn-block btn pass-btn green_btn font-weight-normal font-16 px-5">
            Get Started
          </button>

        </div>

      </div>
      <p class="text-center"><a class="font-18 blue-text bold-500 sharp-font" i18n="@@landingRequestBtn" (click)="requestDemo()">Request Demo</a></p>
    </div>

  </section>

  <section>
    <div class="pb-7">

      <div class="container">


        <div class="row px-md-5 pt-7">


          <div class="col-md-6 d-flex align-items-center gray-primaray-text">
            <div  class=" px-sm-0 px-4 text-center" [ngClass]="{'text-sm-left': Service.isSiteLtr(), 'text-sm-right': Service.isSiteRtl()}">
              <h4 i18n="@@landingSec1Title" class="font-26-to-22 bold-600 mb-4">Instant Savings</h4>
              <p class="checks p-0 font-18 bold-600">
                <i class="icon-bold-check green-text" [ngClass]="{'pr-3': Service.isSiteLtr(), 'pl-3': Service.isSiteRtl()}"></i>
                <ng-container i18n="@@landingSec1ItemA">Zero commissions</ng-container>
              </p>
              <p class="checks p-0 font-18 bold-600">
                <i class="icon-bold-check green-text" [ngClass]="{'pr-3': Service.isSiteLtr(), 'pl-3': Service.isSiteRtl()}"></i>
                <ng-container i18n="@@landingSec1ItemB">Zero booking fees</ng-container>
              </p>
              <p i18n="@@landingSec1Desc" class="font-16" [ngClass]="{'pr-lg-4': Service.isSiteLtr(), 'pl-lg-4': Service.isSiteRtl()}">
                SafarHub offers the industry’s most cost-effective corporate travel management solution. Unlike other travel management systems, your company will save money from day one.
              </p>
              <a i18n="@@landingCalLink" class="blue-text font-18 bold-600 mb-3" *ngIf="Service.desktopView" (click)=" this.Service.setNotifyModal('corporateCalculateSavings', '')">Calculate Your Savings</a>
              <p class="mb-5 mb-lg-3"><a i18n="@@landingCalLink" class="blue-text font-18 bold-600" *ngIf="Service.mobileView" (click)=" this.Service.setBottomSheet('corporateCalculateSavings', ''); ">Calculate Your Savings</a></p>
            </div>


          </div>
          <div class="col-md-6" [ngClass]="{'text-right':Service.desktopView, 'd-flex': Service.mobileView}" >
            <img width="616" height="401" loading="lazy" class="max-100 initial-height" [ngClass]="{'mx-auto':Service.mobileView}" src="assets/img/pass-freetraial-work-1-b.png" alt="Guaranteed Savings" />

          </div>
        </div>


      </div>


    </div>

    <div class="pt-7 pb-7 border-0">

      <div class="container">

        <div class="row px-md-5">
          <div class="col-md-6 d-flex align-items-center order-md-last order-first">
            <div class="px-4 text-center" [ngClass]="{'text-sm-left': Service.isSiteLtr(), 'text-sm-right': Service.isSiteRtl()}">
              <h4 i18n="@@landingSec2Title" class="font-26-to-22 bold-600 mb-4">All-In-One Platform</h4>
              <p i18n="@@landingSec2Desc" class="font-16 gray-primaray-text">
                One tool to streamline and simplify all aspects of corporate travel. We help corporates to book, manage travel policies, automate approvals, minimize expense filing, view reports, and more.
              </p>


            </div>


          </div>
          <div class="col-md-6 d-flex">
            <img width="367" height="355" loading="lazy"  class="max-100 initial-height" [ngClass]="{'mx-auto':Service.mobileView}" alt="all in one" src="assets/img/all-in-one.png" />

          </div>

        </div>


      </div></div>

    <div class="pt-7 pb-7">

      <div class="container">

        <div class="row px-md-5">
          <div class="col-md-6 d-flex align-items-center">
            <div class=" px-sm-0 px-4 text-center" [ngClass]="{'text-sm-left': Service.isSiteLtr(), 'text-sm-right': Service.isSiteRtl()}">
              <h4 i18n="@@landingSec3Title" class="font-26-to-22 bold-600 mb-4">Unmatched Adoption Rates</h4>
              <p i18n="@@landingSec3Desc" class="font-16 gray-primaray-text">
                Our fast and easy booking engine allows travelers to book hotels and flights in minutes! With SafarHub Rewards, travelers have an incentive to make money-saving choices.
              </p>
              <p class="mb-5 mb-lg-3"> <a i18n="@@landingFreeJoin" class="blue-text font-18 bold-600" routerLink="/join">Join For Free</a></p>
            </div>


          </div>
          <div class="col-md-6 " [ngClass]="{'text-right':Service.desktopView, 'd-flex': Service.mobileView}" >
            <img width="477" height="373" loading="lazy" class="max-100 initial-height rounded-corner" [ngClass]="{'mx-auto':Service.mobileView}" alt="unmatched-rate" src="assets/img/unmatched-rate.png" />

          </div>

        </div>


      </div>
    </div>
    <div class="pt-7 pb-7 border-0">

      <div class="container">

        <div class="row px-md-5">
          <div class="col-md-6 d-flex align-items-center order-md-last order-first">
            <div class="text-center" [ngClass]="{'pr-4 pl-4 pr-lg-0 text-sm-left': Service.isSiteLtr(), 'pl-4 pr-4 pl-lg-0 text-sm-right': Service.isSiteRtl()}">
              <h4 i18n="@@landingSec4Title" class="font-26-to-22 bold-600 mb-4">Approval & Expenses Automation</h4>
              <p i18n="@@landingSec4Desc" class="font-16 gray-primaray-text mb-5 mb-lg-3">
                SafarHub integrates with the leading Expenses and communication tools such as Expensify & Slack. With one click, travelers can upload the travel expenses into the company expenses management tool or manage approval directly from slack.</p>

            </div>


          </div>
          <div class="col-md-6 d-flex">
            <img width="444" height="273" loading="lazy"  class="max-100 initial-height" [ngClass]="{'mx-auto':Service.mobileView}" alt="slack integrations" src="assets/img/slack-integrations.png" />

          </div>

        </div>


      </div></div>

  </section>
<section>
  <div class="gray-pass-bg text-center py-5 border-0">
    <div class="container py-4">
    <p class="font-26 bold-600" i18n="@@moreInfo">Need more info? talk to our expert</p>
    <div class="d-flex">
      <div class=" mx-auto">
        <button i18n="@@landingRequestBtn" (click)="requestDemo()" class="mt-5 btn-block btn pass-btn btn-outline-blue bold-500 font-18 px-4">
          Request Demo
        </button>
      </div>
    </div>
    </div>
  </div>
</section>
  <section>
    <div class="pb-5">
    <div class="container">
      <p class="font-32 primaray-text bold-600 mt-5 pt-lg-5" [ngClass]="{'text-center': Service.mobileView}" i18n="@@pricing">Pricing</p>
    </div>
      <div class="row primary-bg mb-5">

        <div class="col-12 ">
          <div class="container price-min-height">

          <div class="row" >
            <div class="col-lg-6"  >
              <div class="py-3" [ngClass]="{'pr-lg-5': Service.isSiteLtr(), 'pl-lg-5': Service.isSiteRtl()}">
              <p class="font-18 text-white mb-0 py-5" i18n="@@pricingDesc" [ngClass]="{'text-center': Service.mobileView, 'pr-lg-5 mr-lg-5': Service.isSiteLtr(), 'pl-lg-5 ml-lg-5': Service.isSiteRtl()}">
                We believe access to all features gives the most value to your business. That’s why we structure a very simple pricing model that fits all businesses no matter the number of employees or travel volume.
              </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div [ngClass]="{'position-absolute w-100 price-box': Service.desktopView}">
                <div class="row" [ngClass]="{'mb-5 px-5': Service.mobileView}">
                  <div class="col-md-6" [ngClass]="{'mb-5': Service.mobileView}">
                    <div class="white-box price-max-height">
                      <div class="p-3 text-center">
                        <p class="dark-gray font-18 bold-600 mb-4 pb-2" i18n="@@priceStarter">STARTER</p>
                        <p class="dark-gray font-18 mb-4 pb-2" i18n="@@priceStarter1">1-5 Members</p>
                        <p class="gray-text-dark font-16" i18n="@@priceStarter2">$29.99 Flat fee per month</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">

                    <div class="white-box price-max-height">
                    <div class="p-3 text-center">
                      <p class="dark-gray font-18 bold-600 mb-4 pb-2" i18n="@@priceProf">PROFESSIONAL</p>
                      <p class="dark-gray font-18 mb-4 pb-2" i18n="@@priceProf1">5+ Members</p>
                      <p class="gray-text-dark font-16" i18n="@@priceProf2">$8 per member per month</p>
                    </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>


        </div>
      </div>
    </div>
  </section>

    <section>
      <div class="pb-5 mb-5" [ngClass]="{'pt-7': Service.desktopView}">
    <div class="mb-5 pass-joining-cont d-flex align-items-center mx-auto" id="PassSubscription" [ngClass]="{'mt-5 py-5': Service.mobileView}">

      <div class="container">
        <div class="px-5 custom-white-box" [ngClass]="{'ml-lg-auto': Service.isSiteLtr(), 'mr-lg-auto': Service.isSiteRtl()}">
          <div class="px-md-5 py-md-4">
            <h5 class="font-28-to-22 mb-4 font-weight-bold primaray-text" i18n="@@freeTrialTitle"> Start your free trial</h5>
            <ul class="checks p-0 font-18-to-16">
              <li class="gray-primaray-text" i18n="@@freeTrial1">30 days free trial</li>
              <li class="gray-primaray-text" i18n="@@freeTrial2">Add unlimited number of members</li>
              <li class="gray-primaray-text" i18n="@@freeTrial3">All features included</li>
              <li class="gray-primaray-text" i18n="@@freeTrial4">No payment method required</li>
            </ul>
            <button i18n="@@getStartBtn" (click)="SendGAevent('CTA-buttom')"  routerLink="/join"
                    class="mt-3 btn pass-btn  green_btn font-weight-normal rounded-pill font-16 px-5">
              Get Started
            </button>

          </div>

        </div>

      </div>
      </div>

    </div>

  </section>


  <section>
    <div class="gray-pass-bg">
    <div class="container pt-7 pb-7 faq">
    <div class="custom-container mx-auto">
        <p class="font-weight-bold gray-primaray-text font-30-to-26 text-center pb-5" i18n="@@faq">Frequently Asked Questions</p>

        <div class="border px-0 pt-3 pb-3 mb-3 rounded white-box shadow-sm white-bg" *ngFor="let item of FAQPrime, let i = index">
          <h3 class="my-0 font-16 container pr-5 py-4 position-relative " id="hotelQ2" (click)="showFaqByID(i);">
            <a class="bolder">{{item.title}}</a>
            <i class="mlight-text font-20 faq-arrow icon-right-arrow-slider" [ngClass]="{'rotate-top' :showFaq==='Q'+i , 'rotate-bottom' : showFaq !=='Q'+i }"></i>
          </h3>
          <div [ngClass]="{'show-faq': showFaq==='Q'+i, 'hide-faq': showFaq!=='Q'+i}">
            <p class="mx-4 mt-3 pt-3 border-top gray-text" [innerHTML]="item.desc"></p>
          </div>
        </div>
      </div>
    </div>
    </div>

  </section>
</div>

