<div class="card-details-box sky-blue-bg px-3 py-4"
     *ngIf="flightListService.isCardDetailsVisible[index] | async">
    <ng-container *ngIf="fares">
        <div class="child d-flex" *ngFor="let fare of fares; let i = index">
            <div class="mb-auto">
                <div class="d-flex w-100 justify-content-between">
                    <div>
                        <p class="primaray-text mb-0 bold-600">{{fare.travelerPricings[0].fareDetailsBySegment[0].brandedFare}}</p>
                        <p class="font-13 gray-text-dark">{{fare.travelerPricings[0].fareDetailsBySegment[0].cabin}}</p>
                    </div>
                    <i class="icon-info-msg font-20 pointer"
                       (click)="showMoreInfo(fare.travelerPricings[0].fareDetailsBySegment[0].amenities)"
                       *ngIf="isDevMode"></i>
                </div>
                <app-fares-details [fareDetailsBySegment]="fare.travelerPricings[0].fareDetailsBySegment[0]"></app-fares-details>
         </div>
            <div class="bottom-section">
                <div class="separator mt-3 mb-4"></div>
                <div class="d-flex align-items-center">
                            <span  class="primaray-text font-22 bold-600">
                                {{fare.travelerPricings[0].sellingPriceConverted.currency}} {{fare.travelerPricings[0].sellingPriceConverted.total | number : '1.0-0'}}
                            </span>
                    <a  class="green_btn in-mobile text-white px-4 custom-green-btn"
                        [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl(),
                         'm-progress': selectedFareIndex === i && (this.flightService.pricingLoading | async)}"
                         i18n="@@select" (click)="navigateToFlightInfo(i, fare.transactionId)">Select</a>
                </div>
                <p class="smaller text-danger" *ngIf="selectedFareIndex === i && errorMsg !== ''">{{errorMsg}}</p>

                <div class="text-danger mt-3" *ngIf="fare.travelerPricings[0].userData.policyViolated"
                     [ngClass]="{'text-right': Service.isSiteRtl(), 'text-left': Service.isSiteLtr()}"
                     i18n="@@outOfPolicy">
                    Out of policy
                </div>
            </div>

        </div>
    </ng-container>
    <ng-container *ngIf="!fares">
        <div class="child d-flex">
            <div class="mb-auto">
                <div class="d-flex w-100 justify-content-between">
                    <div>
                        <p class="primaray-text mb-0 bold-600">{{item.travelerPricings[0].fareDetailsBySegment[0].cabin}} ({{item.travelerPricings[0].fareDetailsBySegment[0].class}})</p>
                        <p class="font-13 gray-text-dark">{{item.travelerPricings[0].fareDetailsBySegment[0].cabin}}</p>
                    </div>
                    <i class="icon-info-msg font-20 pointer"
                       (click)="showMoreInfo(item.travelerPricings[0].fareDetailsBySegment[0].amenities)"
                       *ngIf="this.isDevMode"></i>
                </div>
                <app-fares-details [fareDetailsBySegment]="item.travelerPricings[0].fareDetailsBySegment[0]"></app-fares-details>
            </div>

            <div class="bottom-section">
                <div class="separator mt-3 mb-4"></div>
                <div class="d-flex align-items-center">
                                <span  class="primaray-text font-22 bold-600">
                                    {{item.sellingPriceConverted.currency}} {{item.sellingPriceConverted.total |  number : '1.0-0'}}
                                </span>
                    <a  class="green_btn in-mobile text-white px-4 custom-green-btn"
                        [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl(),
                        'm-progress': (this.flightService.pricingLoading | async)}"
                         i18n="@@select" (click)="navigateToFlightInfo(0, item.transactionId)">Select</a>
                </div>
                <p class="smaller text-danger" *ngIf="errorMsg !== ''">{{errorMsg}}</p>
                <div class="text-danger mt-3" *ngIf="item.travelerPricings[0].userData.policyViolated"
                     [ngClass]="{'text-right': Service.isSiteRtl(), 'text-left': Service.isSiteLtr()}"
                     i18n="@@outOfPolicy">
                    Out of policy
                </div>
            </div>

        </div>
    </ng-container>


</div>
