
<div class="main-container border-0 pb-5" [ngClass]="{'gray-pass-bg' : dataService.desktopView}" *ngIf="!this.dataService.companyIdForSlackLogin">

  <div class="container pb-5" [ngClass]="{'desktop-design text-center': dataService.desktopView, 'mb-5 pt-5 pt-lg-2': dataService.mobileView}" *ngIf="!this.dataService.signInErrorMsg">


    <div class="row">
      <div class="col-lg-6 pb-5 pb-lg-0 order-lg-1 extra-padding-top">
        <div class="login-form workspace-registration-container px-lg-5 pt-5 corporate-box" [ngClass]="{'white-box position-relative' : dataService.desktopView,
        'mr-auto': dataService.desktopView && dataService.isSiteLtr(), 'ml-auto': dataService.desktopView && dataService.isSiteRtl(), 'mx-auto': dataService.mobileView}">
          <div class="text-center">
            <h4 class="font-24 pt-md-4 mb-3 bold-600 text-center" i18n="@@connectSafarHubApp">
              Connect SafarHub App
            </h4>
          </div>
          <p class="gray-text font-16 pb-4 mb-3 text-center px-4 workspace-registration-container mx-auto" i18n="@@enterEmailInSlack">Enter your work email address to login to your workspace.</p>
          <app-company-login-form></app-company-login-form>

        </div>
      </div>

      <div class="col-lg-6 d-lg-flex z-index-10">
        <div class="text-center" [ngClass]="{'text-lg-left': dataService.isSiteLtr(), 'text-lg-right': dataService.isSiteRtl()}">
          <div class="w-100 mb-2">
            <img width="120" height="38"  loading="lazy" src="assets/img/slack.png" />
          </div>
          <div class="w-100 mb-4">
            <p class="gray-text font-16" i18n="@@manageYourBusinessInSlack">Manage your business travel in slack</p>
          </div>
          <div class="w-100">
            <img width="350" height="430"  loading="lazy" class="initial-height img-mobile" src="assets/img/slack-business.png" />
          </div>
        </div>
      </div>


    </div>




  </div>

  <app-login-error-page *ngIf="this.dataService.signInErrorMsg"></app-login-error-page>




</div>








<div class="main-container border-0 pb-5" [ngClass]="{'gray-pass-bg' : dataService.desktopView}" *ngIf="this.dataService.companyIdForSlackLogin">

  <div class="container pb-5" [ngClass]="{'desktop-design text-center': dataService.desktopView, 'mb-5 pt-5 pt-lg-2': dataService.mobileView}" >


    <div class="row">
      <div class="col-lg-6 order-lg-1 extra-padding-top">
        <div class="login-form px-lg-5 pt-5 corporate-box workspace-registration-container" [ngClass]="{'white-box position-relative' : dataService.desktopView,
        'mr-auto': dataService.desktopView && dataService.isSiteLtr(), 'ml-auto': dataService.desktopView && dataService.isSiteRtl(), 'mx-auto': dataService.mobileView}">
          <div class="text-center">
            <h4 class="font-24 pt-md-4 mb-3 bold-600 text-center" i18n="@@connectSafarHubApp">
              Connect SafarHub App
            </h4>
          </div>
          <app-workspace-login-form></app-workspace-login-form>


        </div>
      </div>
      <div class="col-lg-6 d-lg-flex">
        <div class="text-center" [ngClass]="{'text-lg-left': dataService.isSiteLtr(), 'text-lg-right': dataService.isSiteRtl()}">
          <div class="w-100 mb-4">
            <img width="120" height="38"  loading="lazy" src="assets/img/slack.png" />
          </div>
          <div class="w-100 mb-4">
            <p class="gray-text font-16" i18n="@@manageYourBusinessInSlack">Manage your business travel in slack</p>
          </div>
          <div class="w-100">
            <img width="350" height="430"  loading="lazy" class="initial-height img-mobile" src="assets/img/slack-business.png" />
          </div>
        </div>
      </div>

    </div>




  </div>






</div>



