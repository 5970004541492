import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {VariablesService} from "../vars.service";
import {DataService} from "../data.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {PayType} from "../../shared/enums/pay-type.enum";
import {Wallet} from "../../shared/model/payment/wallet";
import {ChannelProvider} from "../../shared/enums/channel-provider.enum";
@Injectable({
    providedIn: 'root'
})
export class BookingWalletsService {
    booking: any = { payment: { amazon: ChannelProvider.Amazon, wallet: ChannelProvider.BeWallet} };
    bookingWalletsSubscription: Subscription;
    selectedWallet = "selectWallet";
    noSelectedWallet: boolean = false;
    payTypeMethod: PayType = PayType.Wallet;
    walletsData;
    walletsList: BehaviorSubject<Wallet[]> = new BehaviorSubject<Wallet[]>([]);
    previousWalletsListLength = 0; // Track previous length
    constructor(private http: HttpClient,
                private dataService: DataService,
                public VarsService: VariablesService) {
    }


    getBookingWallets(travelerId) {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.booking.getBookingWallets}?travelerId=` +
            travelerId,
            ""
        );
    }

    getBookingWalletsList(travelerId){
        this.bookingWalletsSubscription =
            this.getBookingWallets(travelerId).subscribe((success) => {
                this.walletsData = success;

                if (this.walletsData.errorCode === 0) {
                    if (this.walletsData?.data?.length > 0) {
                        console.log(this.walletsData?.data);

                        // Check if array length has changed
                        if (this.walletsData?.data?.length !== this.previousWalletsListLength) {
                            this.selectedWallet = 'selectWallet';
                            // Length has changed, trigger next
                            this.walletsList.next(this.walletsData?.data);
                            // Update the previous length
                            this.previousWalletsListLength = this.walletsData?.data?.length;
                        }

                    } else {
                        this.selectedWallet = 'noWallet';
                    }
                }

            });
    }
}











