import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import { SidenavService} from '../../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../../workspace-dashboard/animations/animations';
import {environment} from '../../../../../environments/environment';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-user-update-page',
  templateUrl: './user-update-page.component.html',
  styleUrls: ['./user-update-page.component.scss'],
  animations: [ onMainContentChange ]
})
export class UserUpdatePageComponent implements OnInit {
  constructor( public dataService: DataService, private sidenavService: SidenavService, public router: Router, public auth: AuthServiceMain, private titleService: Title) {
    this.titleService.setTitle('Edit member | SafarHub');
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }

  ngOnInit(): void {
    this.dataService.userUpdatePage = true;
  }

}
