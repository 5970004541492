
<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
                         [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="container py-5 pb-5">
        <div class="mx-lg-5 mb-lg-5">

          <div class="row" >

            <div class="col">
              <div class="pb-4 pb-lg-5 mb-3">
                <a class="font-16 blue-text text-underline" (click)="this.router.navigate(['/user/rewards']);"
                   i18n="@@rewards">Rewards</a>
                <span class="font-16 gray-text-dark "> / <ng-container i18n="@@history">History</ng-container></span>
              </div>
            </div>

          </div>



<ng-container *ngIf="this.auth.userInfo.rewardWallet && this.auth.userInfo.rewardWallet.balance">
  <div class="row mb-5" >
    <div class="col-md-5">
      <div class="white-box mb-4 max-width-350">
        <p class="font-16 gray-primaray-text" i18n="@@rewardsBalance">Rewards Balance</p>
        <p class="font-26-to-22 gray-primaray-text bold-600 mb-0">{{this.auth.userInfo.rewardWallet.balance | number : '1.2-2'}} {{this.auth.userInfo.rewardWallet.currencyCode}}</p>
        <p class="gray-text" i18n="@@available">Available</p>
      </div>
    </div>
  </div>
</ng-container>

          <div *ngIf="historyList" class="max-width-840">
            <div class="row px-0 px-lg-3 mx-lg-0 mt-4 pt-3 pb-3 gray-primaray-text table-header rounded bold-600" *ngIf="dataService.desktopView">
              <div class="col-md-3" i18n="@@date">Date</div>
              <div class="col-md-3 pl-0" i18n="@@activity">Activity</div>
              <div class="col-md-3"></div>
              <div class="col-md-3" i18n="@@amount">Amount</div>

            </div>
            <div class="row px-0 px-lg-3 gray-text-dark hover-row-list mx-0 py-4"
                 [ngClass]="{'border-bottom': dataService.desktopView, 'rounded border mb-4': dataService.mobileView}"
                 *ngFor="let item of historyList, let i = index">
              <div class="gray-primaray-text col-lg-3 py-1" *ngIf="dataService.desktopView" [innerHTML]="dataService.formatDateEEMMMdd(item.creationDate)"></div>
              <div class="col-lg-3 d-flex pb-4" *ngIf="dataService.mobileView">
                <div><span class="gray-primaray-text bold-600" [innerHTML]="dataService.formatDateEEMMMdd(item.creationDate)"></span></div>
                <div [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                  <p class="mb-0" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl(), 'green-text': item.type === 'CREDIT', 'gray-primaray-text': item.type !== 'CREDIT'}"><span *ngIf="item.type === 'CREDIT'">+</span><span *ngIf="item.type !== 'CREDIT'">-</span>{{item.amount}} USD</p>
              </div>
              </div>
              <div class="col-lg-3 py-lg-1 pl-lg-0">
                <p class="gray-primaray-text mb-0">{{item.description}}</p>
              </div>
              <div class="col-lg-3 py-1 px-lg-0">
                <p class="gray-text mb-0">{{item.details}}</p>
              </div>
              <div class="col-lg-3">
                <p *ngIf="dataService.desktopView" class="mb-0" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl(), 'green-text': item.type === 'CREDIT', 'gray-primaray-text': item.type !== 'CREDIT'}"><span *ngIf="item.type === 'CREDIT'">+</span><span *ngIf="item.type !== 'CREDIT'">-</span>{{item.amount}} USD</p>
              </div>
            </div>
          </div>





</div>
</div>



</div>
</mat-sidenav-content>
</mat-sidenav-container>
