import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../../../services/data.service";
import {FlightService} from "../../../services/flight-services/flight.service";
import {Router} from "@angular/router";



@Component({
  selector: 'app-flight-pricing',
  templateUrl: './flight-pricing.component.html',
  styleUrls: ['./flight-pricing.component.scss']
})
export class FlightPricingComponent implements OnInit {
  @Input() pricing;
  @Input() isFLightInfo: boolean = false;
  @Input() isApprovals: boolean = false;
  pricingTravelers;
  offerSellingPriceConverted;
  constructor(public router: Router,
              private dataService:DataService) { }

  ngOnInit(): void {
    if(this.isApprovals){
      this.pricingTravelers = this.pricing.travelerPricings;
      this.offerSellingPriceConverted =  this.pricing.sellingPriceConverted;
    } else {
      this.pricingTravelers = this.pricing.flightOffers[0].travelerPricings;
      this.offerSellingPriceConverted = this.pricing.flightOffers[0].sellingPriceConverted;
    }
  }

  addCustomPadding(): boolean{
    if(this.router.url.includes('booking')){
      return false;
    } else {
      return true;
    }
  }
}
