import { Component, OnInit } from '@angular/core';
import {AuthServiceMain} from '../../../auth/auth.service';
import {onMainContentChange} from '../../workspace-dashboard/animations/animations';
import {DataService} from '../../../services/data.service';
import {SidenavService} from '../../workspace-dashboard/sidenav.service';
import {Router} from '@angular/router';
import {VariablesService} from '../../../services/vars.service';
import {environment} from "../../../../environments/environment";
import * as moment from "moment";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-user-rewards',
  templateUrl: './user-rewards.component.html',
  styleUrls: ['./user-rewards.component.scss'],
  animations: [ onMainContentChange ]
})
export class UserRewardsComponent implements OnInit {


  constructor(private titleService: Title, public dataService: DataService, private sidenavService: SidenavService,
              public router: Router, public auth: AuthServiceMain, public vars: VariablesService) {
    this.titleService.setTitle('My rewards | SafarHub');
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
  brands = ['amazon', 'app-store', 'uber', 'itune'];
  showBrand = '';
  priceList = ['10', '20', '30', '50', '75', '100'];
  redeemValue;
  submitted;
  submitting;
  errorMsg;
  successfulRedeem;
  data;
  historyList;
  ngOnInit(): void {
    if(this.auth.userInfo.rewardWallet) {
      this.dataService.getRewardWalletOperations().subscribe(
          data => {
            this.data = data;
            if (this.data.errorCode === 0) {
              this.historyList = this.data.data;
            } else {
              this.dataService.messages(this.data.message, 'error');
            }
          }, error => {
            this.dataService.messages(environment.messages.error, 'error');
          }
      );
    }
 /*   this.dataService.getUserInfo().subscribe(
      suc => {

        this.data = suc;
        if (this.data.errorCode === 0) {
          if (this.data.data.address) {
            this.auth.address = {
              address1: this.data.data.address.address1,
              address2: this.data.data.address.address2,
              cityName: this.data.data.address.city,
              zipCode: this.data.data.address.zipCode,
              countryCode: this.data.data.address.countryCode,
              stateCode: this.data.data.address.stateCode,
              countryName: this.data.data.address.countryName
            };
          }
            this.auth.userInfo = {
              name: this.data.data.firstName,
              imageUrl: this.data.data.profileImage,
              id: this.data.data.id,
              lastName: this.data.data.lastName,
              email: this.data.data.email,
              phoneNumber: this.data.data.phoneNumber,
              creationDate: this.data.data.creationDate,
              address: this.auth.address,
              inactive: this.data.data.inactive,
              role: this.data.data.role,
              policyId:  this.data.data.policyId,
              teamId:  this.data.data.teamId,
              teamName: this.data.data.teamName,
              effectivePolicyId: this.data.data.effectivePolicyId,
              effectivePolicyName: this.data.data.effectivePolicyName,
              companyCards: this.data.data.companyCards,
              profileImageBackgroundHexColor: this.data.data.profileImageBackgroundHexColor,
              personalPaymentMethodsDisabled: this.data.data.personalPaymentMethodsDisabled,
              rewardWallet: this.data.data.rewardWallet
            };
          this.dataService.userProfileImageWithRandomText = this.auth.userInfo.imageUrl + '?' + (new Date()).getTime();

          localStorage.setItem('leads_email', this.data.data.email);


          localStorage.removeItem('userInfo');

          localStorage.setItem('userInfo', JSON.stringify(this.auth.userInfo));
          if(this.auth.userInfo.rewardWallet) {
            this.dataService.getRewardWalletOperations().subscribe(
              data => {
                this.data = data;
                if (this.data.errorCode === 0) {
                  this.historyList = this.data.data;
                } else {
                  this.dataService.messages(this.data.message, 'error');
                }
              }, error => {
                this.dataService.messages(environment.messages.error, 'error');
              }
            );
          }


        } else {
          this.auth.logout();
        }

      },
      err => {
        this.auth.logout();

      }
    );*/
  }
  showBrandByID(index) {
    if (this.showBrand === 'Q' + index) {
      this.showBrand = '';
    } else {
      this.showBrand = 'Q' + index;
    }
  }
  filterByText(event) {

  }
  redeemReward() {
    this.errorMsg = '';
    this.submitted = true;
    this.submitting = true;
    if (!this.redeemValue) {
      this.submitting = false;
      this.errorMsg = 'Please enter or select a number to redeem';
      return;
    }
    this.successfulRedeem = true;

  }
  formatDate(date) {
    return  moment(date).format('ddd') + ', ' + moment(date).format('MMM') + ' ' + moment(date).format('DD');
  }
}
