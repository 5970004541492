import { Component, ElementRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { SidenavService } from '../../../workspace-dashboard/sidenav.service';
import { Router } from '@angular/router';
import { AuthServiceMain } from '../../../../auth/auth.service';
import { Title } from "@angular/platform-browser";
import { CostManagementService } from 'src/app/services/cost-management.service';
import { environment } from 'src/environments/environment';
import { onMainContentChange } from 'src/app/corporate-travel/workspace-dashboard/animations/animations';

@Component({
  selector: 'app-workspace-costs-list',
  templateUrl: './workspace-costs-list.component.html',
  styleUrls: ['./workspace-costs-list.component.scss'],
  animations: [onMainContentChange]
})
export class WorkspaceCostsListSettingsComponent implements OnInit, OnChanges {
  loader: boolean = false;
  errorMsg: string;
  isCostCenterMandatory: boolean = false;
  isLabelMandatory: boolean = false;

  constructor(private titleService: Title, public eref: ElementRef, public dataService: DataService,
    private sidenavService: SidenavService, public router: Router, public auth: AuthServiceMain,
    public costManagementService: CostManagementService) {

    this.titleService.setTitle('Cost Management Setting | SafarHub');
    this.sidenavService.sideNavState$.subscribe(res => {
      this.dataService.onSideNavChange = res;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loader) {
      this.loader = changes.loader.currentValue;
    }
  }

  ngOnInit(): void {
    //this.getMandatory();

    if (this.auth.companyInfo.rewardProgram) {
      this.dataService.showSliders = true;
      this.dataService.showSliders = true;
    }
    else {
      this.dataService.showSliders = false;
    }
  }

  getMandatory() {
    this.costManagementService.getMandatory().subscribe(
      result => this.handleSuccess(result),
      error => this.handleFailed(error.message)
    ).add(() => {
      this.loader = false;
    });
  }

  handleSuccess(result) {
    if (result.errorCode) {
      this.handleFailed(result.message);
      return;
    }

    this.isCostCenterMandatory = result.data.costCenterMandatoryOnBookingsAndApprovals;
    this.isLabelMandatory = result.data.labelMandatoryOnBookingsAndApprovals;
  }

  handleFailed(error) {
    if (error.includes('Http failure response')) {
      error = environment.messages.error;
    }
    this.dataService.messages(error ?? environment.messages.error, 'error');
  }
}
