<div class="mb-5" *ngIf="this.dataService.mobileView">

   <!-- <div class="gray-pass-bg border-0 py-3 pl-3" *ngIf="this.auth.companyInfo">
      <a class="font-18 blue-text font-18 primaray-text font-weight-bold" *ngIf="this.auth.companyInfo"
         (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/teams']);">Teams</a> <span class="font-18 gray-text-dark"> / </span>
      <a class="font-18 blue-text font-18 primaray-text font-weight-bold"
         (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/teams/' + teamId]);">{{teamName}}</a>
      <span class="font-18 gray-text-dark font-weight-bold"> / Add Members </span>
    </div>-->
  <div class="container pt-4">
  <div class="row ">
    <div class="col">
      <div class="pb-5 mb-3">
        <a class="font-16 blue-text text-underline" (click)="this.router.navigate(['/teams']);" i18n="@@teams">Teams</a>
        <span class="font-16 gray-text-dark "> / </span>
        <a class="font-16 blue-text text-underline" (click)="this.router.navigate(['/teams/' + teamId]);">{{teamName}}</a>
        <span class="font-16 gray-text-dark "> / <ng-container i18n="@@addMembers">Add Members</ng-container> </span>
      </div>
    </div>

  </div>
  </div>

</div>

<div class="container create-team pb-5">
  <div [ngClass]="{'w-75' : this.dataService.desktopView}">
<p *ngIf="users.length ===0" i18n="@@addMemberdMsg">All company users are already added to this team</p>
    <mat-form-field class="w-100 chips-container border rounded" (click)="showMembersPopUp();" *ngIf="users.length !==0">
      <mat-chip-list #chipList >
        <mat-chip (click)="showMembersPopUp()"
                  *ngFor="let user of selectedUsers">
          {{ user.description }}
          <!-- <mat-icon matChipRemove >cancel</mat-icon>-->
          <i class="icon-danger placeholder-text ml-2" *ngIf="dataService.desktopView" (click)="toggleSelection(user)"></i>
        </mat-chip>
        <input *ngIf="this.dataService.desktopView" #userInput class="min-height mx-0 my-0" type="text" placeholder="Select Users" aria-label="Select Users"
               [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
               matInput [matAutocomplete]="auto" [formControl]="userControl"  (focus)="reset()" (blur)="resetBlur()"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"

        />
      </mat-chip-list>
      <!-- <input class="border" type="text" placeholder="Select Users" aria-label="Select Users" matInput [matAutocomplete]="auto" [formControl]="userControl">-->
      <!--  <mat-hint>Enter text to find users by name</mat-hint>-->
    </mat-form-field>



    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option style="padding: 0" *ngFor="let user of filteredUsers | async" [value]="selectedUsers">
        <div (click)="optionClicked($event, user)" class="mat-checkbox-container">
          <mat-checkbox color="primary" class="position-absolute" [checked]="user.selected" (change)="toggleSelection(user)" (click)="$event.stopPropagation()">
          </mat-checkbox>
          <p [ngClass]="{'mb-0': dataService.desktopView, 'ml-5': dataService.desktopView && dataService.isSiteLtr(), 'mr-5': dataService.desktopView && dataService.isSiteRtl()}">
            {{ user.description }}<br/><span class="font-12 gray-text" *ngIf="user.teamName">{{user.teamName}}</span>
          </p>
        </div>
      </mat-option>
    </mat-autocomplete>

  </div>

  <br><br>
  <div class="d-flex justify-content-end" *ngIf="dataService.desktopView">
    <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" (click)="cancelAddMembers()" i18n="@@cancelBtn">Cancel </button>
    <button class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5" (click)="submitAddMembers()"
            [ngClass]="{'m-progress': submitting, 'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" [disabled]="selectedUsers.length === 0" i18n="@@addMembersWithThe">Add Members</button>
  </div>

  <div *ngIf="dataService.mobileView">
    <div class="d-flex justify-content-end">
      <button class="btn blue_btn font-14 sharp-font bold-500 py-1 mb-4 px-5 w-100" (click)="submitAddMembers()" [ngClass]="{'m-progress': submitting}"  [disabled]="selectedUsers.length === 0" i18n="@@addMembersWithThe">Add Members</button>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5 w-100" (click)="cancelAddMembers()" i18n="@@cancelBtn">Cancel </button>
    </div>
  </div>

</div>






<div class="justify-content-end create-team" >

  <div class="" [ngClass]="{'in action-popup':showMembersAutoCompletePopUp}">
    <div [ngClass]="{'header':showMembersAutoCompletePopUp}" >
      <div *ngIf="showMembersAutoCompletePopUp">
        <div *ngIf="showMembersAutoCompletePopUp">
          <div class="info" [ngClass]="{'pay-and-member' : showMembersAutoCompletePopUp}">
            <span *ngIf="showMembersAutoCompletePopUp" i18n="@@members">Members</span>
          </div>
          <div class="done" *ngIf="showMembersAutoCompletePopUp">
            <span *ngIf="showMembersAutoCompletePopUp" (click)="saveSelectedUsers(); hidePopUp();" i18n="@@done">Done</span>
          </div>
          <div class="close" (click)="hidePopUp(); mobileTempSelectedUsers = []">
            Cancel
          </div>
        </div>
      </div>


      <div class="position-relative mobile-search-field" *ngIf="showMembersAutoCompletePopUp">
        <div class="form-group mb-3">
          <mat-form-field class="w-100 chips-container mobile-header-chips border rounded">
            <mat-chip-list #chipList>
              <mat-chip
                *ngFor="let user of mobileTempSelectedUsers">
                {{ user.description }}
                <i class="icon-danger placeholder-text ml-2" (click)="toggleSelectionTemp(user)"></i>
              </mat-chip>
              <input #userInput id="userInput" class=" mobile-input mx-0 my-0" type="text" placeholder="Select Users" aria-label="Select Users"
                     [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                     matInput [matAutocomplete]="auto" [formControl]="userControl"
                     (focus)="reset()" (blur)="resetBlur()"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="addOnBlur"

              />
            </mat-chip-list>

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option style="padding: 0" *ngFor="let user of filteredUsers | async" [value]="mobileTempSelectedUsers">
                <div (click)="optionClickedMobile($event, user)" class="mat-checkbox-container">
                  <mat-checkbox color="primary" class="position-absolute" [checked]="user.selected" (change)="toggleSelectionTemp(user)" (click)="$event.stopPropagation()">
                  </mat-checkbox>
                  {{ user.description }}<br/><span class="font-12 gray-text" *ngIf="user.teamName">{{user.teamName}}</span>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </div>
      </div>



    </div>

  <!--  <div class="body" *ngIf="showMembersAutoCompletePopUp === true ">
      <div class="position-absolute done-btn">
        <button class="blue_btn btn py-1 px-5 mb-3 w-100 font-14 sharp-font bold-500" (click)="saveSelectedUsers(); hidePopUp();">Done </button>
      </div>
    </div>-->


  </div>
</div>
