<div class="container pt-4 pb-lg-5">
    <form class="mb-5 pb-5" [formGroup]="updateUserPassword" name="userAddressForm" (ngSubmit)="submitUserPasswordUpdate()">
    <div class="row">
            <div class="col-md-9">

                    <label for="currentPassword" class="gray-text" i18n="@@currentPassword">Current Password</label>
                    <div class="form-group with-icon mb-0 position-relative form-type-password form-name-password">
                        <input id='currentPassword' autocapitalize="off"  autocomplete="off"  type="password"  name="currentPassword"  class="form-control" formControlName="currentPassword" [type]="this.dataService.isTextFieldType ? 'password' : 'text'"
                               minlength="6" [ngClass]="{ 'is-invalid': submitted && f.currentPassword.errors }" />
                        <i class="pointer" (click)="this.dataService.togglePasswordFieldType()"  [ngClass]="this.dataService.isTextFieldType ? 'icon-eye' : 'icon-eye-blocked'"></i>

                    </div>
                    <div *ngIf="submitted && f.currentPassword.errors" class="text-danger mb-2 pt-2">
                        <div *ngIf="f.currentPassword.errors.required">Current password is required</div>
                        <div class="password-error-msg" *ngIf="f.currentPassword.errors?.minlength" i18n="@@passwordShort">Password is too short</div>
                    </div>
                    <p class="blue-text pointer mt-2 mb-0"
                       (click)="dataService.rightSideDrawerOff(); this.auth.logout(); this.dataService.forgotPasswordDialogue()"
                       i18n="@@forgotPassword">
                        Forget your password?</p>
            </div>
    </div>
        <div class="row">
<div class="col">
    <hr class="mb-4"/>
</div>
        </div>
        <div class="row">

        <div class="col-md-9">
                    <label for="newPassword" class="gray-text mt-4 pt-1" i18n="@@newPassword">New Password</label>
                    <div class="form-group with-icon mb-0 position-relative form-type-password form-name-password">
                        <input id='newPassword' autocapitalize="off"  autocomplete="off"  type="password"  name="newPassword"  class="form-control" formControlName="newPassword" [type]="this.dataService.isTextFieldType2 ? 'password' : 'text'"
                               minlength="6" [ngClass]="{ 'is-invalid': (submitted && f.newPassword.errors) || ( submitted && f.newPassword.value !== f.confirmPassword.value)}" />
                        <i class="pointer" (click)="this.dataService.togglePasswordFieldType2()"  [ngClass]="this.dataService.isTextFieldType2 ? 'icon-eye' : 'icon-eye-blocked'"></i>

                    </div>
                    <div *ngIf="submitted && f.newPassword.errors" class="text-danger mb-2 mt-2">
                        <div *ngIf="f.newPassword.errors.required" i18n="@@newPasswordRequired">New password is required</div>
                        <div class="password-error-msg" *ngIf="f.newPassword.errors?.minlength" i18n="@@passwordShort">Password is too short</div>
                    </div>

                    <label for="confirmPassword" class="gray-text mt-4 pt-1" i18n="@@confirmNewPassword">Confirm New Password</label>
                    <div class="form-group with-icon mb-0 position-relative form-type-password form-name-password">
                        <input id='confirmPassword' autocapitalize="off"  autocomplete="off"  type="password"  name="confirmPassword"  class="form-control" formControlName="confirmPassword" [type]="this.dataService.isTextFieldType3 ? 'password' : 'text'"
                               minlength="6" [ngClass]="{ 'is-invalid': (submitted && f.confirmPassword.errors) || ( submitted && f.newPassword.value !== f.confirmPassword.value) }" />
                        <i class="pointer" (click)="this.dataService.togglePasswordFieldType3()"  [ngClass]="this.dataService.isTextFieldType3 ? 'icon-eye' : 'icon-eye-blocked'"></i>

                    </div>
                    <div *ngIf="submitted && f.confirmPassword.errors" class="text-danger smaller mb-2">
                        <div *ngIf="f.confirmPassword.errors.required" i18n="@@confirmPasswordRequired">confirmation password is required</div>
                        <div class="password-error-msg" *ngIf="f.newPassword.errors?.minlength" i18n="@@passwordShort">Password is too short</div>
                    </div>

            <div *ngIf="errorMsg" class="text-danger mb-2 pt-2" [ngClass]="{'text-left' : dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">
                {{this.errorMsg}}
            </div>

            </div>
        </div>






        <div class="row pt-3 pt-lg-5 mt-lg-5">
            <div class="col-lg-5 order-lg-1" [ngClass]="{'pl-lg-2': dataService.isSiteLtr(), 'pr-lg-2': dataService.isSiteRtl()}">
                <button class="mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0">
                    <span class="mx-auto font-14" [ngClass]="{'m-progress': submittingPassword}" i18n="@@save">Save</span>
                </button>
            </div>
            <div class="col-lg-5 order-lg-0" [ngClass]="{'pr-lg-2': dataService.isSiteLtr(), 'pl-lg-2': dataService.isSiteRtl()}">
                <button (click)="dataService.rightSideDrawerOff(); dataService.BottomSheetOf()"
                        class="mt-3 btn-block btn btn-outline-gray  px-5 d-flex ml-auto">
                    <span class="mx-auto gray-text-dark font-14 sharp-font bold-500" i18n="@@cancelBtn">Cancel</span>
                </button>
            </div>
        </div>
        <div *ngIf="errorMsgAfterSubmit" class="text-danger mb-2 pt-2" [ngClass]="{'text-left' : dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">
            {{this.errorMsgAfterSubmit}}
        </div>


    </form>
</div>
