import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceMain} from "../../../auth/auth.service";
import {environment} from "../../../../environments/environment";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-slack-connect',
  templateUrl: './slack-connect.component.html',
  styleUrls: ['./slack-connect.component.scss']
})
export class SlackConnectComponent implements OnInit {

  constructor(private titleService: Title, public dataService: DataService, public route: ActivatedRoute,
               public router: Router, public auth: AuthServiceMain) {
    this.titleService.setTitle('Integrate your slack workspace | SafarHub');
  }
  submitting;
  errorMsg;
  slackConnected;
  state = 'state';
  stateValue;
  data;
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.stateValue = params[this.state];
    });
    if(!this.auth.isLogedIn) {
      sessionStorage.setItem('state', this.stateValue);
      this.router.navigate(['/slack/login']);
    } else {
      if (this.stateValue === '' || this.stateValue === null || this.stateValue === undefined) {
        this.errorMsg = $localize`:@@plsInstallSlackAppFirst:Please, install Slack App first`;
      }
    }
  }
  submit() {
    this.dataService.connectSlack(this.stateValue).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.slackConnected = true;
        } else {
          this.errorMsg = this.data.message;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
      }
    );
  }

}
