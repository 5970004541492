import {Component, Input, OnInit} from '@angular/core';
import {BookingWalletsService} from "../../../services/booking/booking-wallets.service";
import {PayType} from "../../../shared/enums/pay-type.enum";
import {DataService} from "../../../services/data.service";
import {BehaviorSubject} from "rxjs";
import {Card} from "../../../shared/model/payment/card";
import {Wallet} from "../../../shared/model/payment/wallet";

@Component({
  selector: 'app-booking-wallets-select',
  templateUrl: './booking-wallets-select.component.html',
  styleUrls: ['./booking-wallets-select.component.scss']
})
export class BookingWalletsSelectComponent implements OnInit {
@Input() travelerId;
  constructor(public bookingWalletsService: BookingWalletsService,
              public dataService: DataService) { }

  ngOnInit(): void {

  }


    inValidWallet(): boolean{
        return this.bookingWalletsService.noSelectedWallet && this.bookingWalletsService.payTypeMethod === PayType.Wallet;
    }

    walletChange() {
        if (this.bookingWalletsService.selectedWallet !== 'selectWallet' && this.bookingWalletsService.selectedWallet !== 'noWallet') {
            this.bookingWalletsService.noSelectedWallet = false;
        }
     }
}
