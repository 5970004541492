import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {Router} from "@angular/router";
@Component({
  selector: 'app-cookies-bar',
  templateUrl: './cookies-bar.component.html',
  styleUrls: ['./cookies-bar.component.scss']
})
export class CookiesBarComponent implements OnInit {

  constructor( public router: Router, public  dataService: DataService) { }

  ngOnInit() {

    if (localStorage.getItem('showCookiesMsg') === null ||
      localStorage.getItem('showCookiesMsg') === undefined || localStorage.getItem('showCookiesMsg') === '') {
      setTimeout(() => {
        this.dataService.showCookiesMsg = true;
      }, 50);
    }
  }
  hideCookiesMsg() {
    localStorage.setItem('showCookiesMsg', 'false');
    this.dataService.showCookiesMsg = false;
  }

}
