import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {DataService} from '../../../../services/data.service';
import * as moment from "moment";
import {Router} from "@angular/router";

@Component({
  selector: 'app-user-reactivation-fees',
  templateUrl: './user-reactivation-fees.component.html',
  styleUrls: ['./user-reactivation-fees.component.scss']
})
export class UserReactivationFeesComponent implements OnInit {

  constructor(public dataService: DataService, public router: Router) { }
  data;
  submitting;
  reactivateDate;
  errorMsg;
  ngOnInit(): void {
    this.dataService.getSubscriptionDetails();
    this.dataService.userReactivationFees().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.reactivateDate = this.data.data;
        } else {
          this.errorMsg = this.data.message;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
      }
    );
  }
  /*formatDate(date) {
    return moment(date).format('MMM') + ' ' + moment(date).format('DD') + ', ' +  moment(date).format('YYYY');
  }*/
  confirm() {
    this.submitting = true;
      this.dataService.userReactivation(this.dataService.userReactivateId).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.submitting = false;
            const url = (this.router.url).split('/');
            if (url[url.length - 1] === 'members') {
              this.dataService.getAllCompanyUsers();
            } else{
              this.dataService.getCompanyUserInfo(this.dataService.userReactivateId);
            }

            this.dataService.modalOf();
            this.dataService.BottomSheetOf();
          } else {
            this.errorMsg = this.data.message;
            this.submitting = false;
          }
        }, error => {
          this.errorMsg = environment.messages.error;
          this.submitting = false;
        }
      );
  }
}
