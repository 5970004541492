import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../../environments/environment';
import {AuthServiceMain} from '../../../../auth/auth.service';

@Component({
  selector: 'app-workspace-remove-rewards',
  templateUrl: './workspace-remove-rewards.component.html',
  styleUrls: ['./workspace-remove-rewards.component.scss']
})
export class WorkspaceRemoveRewardsComponent implements OnInit {

  constructor(public auth: AuthServiceMain, private formBuilder: FormBuilder, public dataService: DataService, public router: Router) { }

  data;
  deleteRewardsForm: FormGroup;
  submitted = false;
  submitting = false;
  reasonsArray: any = [
    {id: 0, text: $localize`:@@notWantShareSaving:Do not want to share saving with my employees`},
    {id: 1, text: $localize`:@@notEffective:Was not effective in saving`},
    {id: 2, text: $localize`:@@redemptionLimited:The redemption network is limited`},
    {id: 3, text: $localize`:@@declineArray4:Other`},
  ];
  urlArray;
  userId;
  previousURL;
  errorMsg;
  deleteReason;
  rewardProgram;
  ngOnInit(): void {
    if (this.dataService.desktopView) {
      this.rewardProgram = this.dataService.modalData;
    } else {
      this.rewardProgram = this.dataService.BottomSheetData;
    }
    this.deleteRewardsForm = this.formBuilder.group({
      reasons: ['', Validators.required],
      improveService: [''],
    }, {
    });
  }
  get ctrl() { return this.deleteRewardsForm.controls; }
  deleteRewardsProgram() {
    this.dataService.alerts = [];
    this.submitted = true;
    this.submitting = true;
    if (this.deleteRewardsForm.invalid) {
      this.submitting = false;
      return;
    }
    if (this.deleteRewardsForm.controls.improveService.value) {
      this.deleteReason = this.deleteRewardsForm.controls.reasons.value + '. Comment: ' + this.deleteRewardsForm.controls.improveService.value;
    } else  {
      this.deleteReason = this.deleteRewardsForm.controls.reasons.value;
    }
    this.dataService.deleteRewardProgram(this.rewardProgram).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.showSliders = false;
          this.submitting = false;

          delete  this.auth.companyInfo.rewardProgram;
          localStorage.setItem('companyInfo', JSON.stringify(this.auth.companyInfo));
          this.dataService.fetchCompanyData.next();
        //  this.auth.fetchCompanyData();


          if (this.dataService.desktopView) {
            this.dataService.modalOf();
          } else {
            this.dataService.BottomSheetOf();
          }
          this.dataService.messages($localize`:@@rewardProgramRemoved:Reward program was removed successfully`, 'success');
        } else {
          this.errorMsg = this.data.message;
          this.submitting = false;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
        this.submitting = false;
      }
    );
  }
}
