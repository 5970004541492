
<div class="workspace-home deactivate-dashboard-page" *ngIf="this.auth.companyInfo">
  <div class="container">
    <div class="mx-lg-5">
<!--<p class="my-5 font-16 text-danger sharp-font bold-500">Your account is deactivated</p>-->

    </div>
  </div>

  <div [ngClass]="{'container': dataService.desktopView}" >
    <div  [ngClass]="{'mx-lg-5 pt-5': dataService.desktopView}">


      <div class="mx-3 mx-lg-0 px-4 px-lg-5 py-3 minus-margin company-section-with-thick-border mt-5 border"
           [ngClass]="{'rounded-right': dataService.desktopView && dataService.isSiteLtr(), 'rounded-left': dataService.desktopView && dataService.isSiteRtl(), 'thick-top rounded-bottom': dataService.mobileView}">
        <div class="row py-3 h-100 justify-content-center align-items-center">
          <div class="col-md-7 d-flex">
            <div>
              <p class="bold-500 gray-text-dark"><ng-container i18n="@@subExpiredOn">Your subscription expired on</ng-container>&nbsp;<span *ngIf="dataService.workspaceLastSubscription" [innerHTML]="dataService.formatDateMMMdy(dataService.workspaceLastSubscription.periodEnd)"></span>.  </p>
              <span class="gray-text-dark" i18n="@@limitedAccount">You have limited access to your account. Subscribe again and get access to booking and other features. </span>
            </div>

          </div>
          <div class="col-md-5" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">
            <button (click)="dataService.reactivateAccount()"
                    class="btn py-2 mt-4 mb-3 mt-lg-0 mb-lg-0 rounded text-center bg-white sharp-font bold-500 font-14 btn-block white px-3 btn-max-width primaray-text desktop-btn"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}" i18n="@@activateSubscription">Activate Subscription</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="mx-lg-5">
      <div class="custom-container" *ngIf="upcomingBooking && upcomingBooking.length">
        <div class="max-width-600">
          <p class="primaray-text font-26 pt-4 bold-600" i18n="@@dashboardMsg3">Your next trips</p>

          <div *ngFor="let item of upcomingBooking; let i = index">
            <div *ngIf="item.bookingType === 'HOTEL'">
              <div class="d-flex">
                <i class="icon-hotel-item font-25 gray-text" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
                <p class="gray-primaray-text mt-1 mb-2">{{dataService.formatDateEEMMMdy(item.checkinDate)}}</p>
              </div>
              <div class="mb-5 p-4 hotel-item rounded pointer position-relative" (click)="viewDetails({ref: item.referenceNumber, identifier : item.emailAddress}, item.bookingType)">
                <div class="details">
                  <h2 class="gray-primaray-text bold-600 font-16 mb-2">{{item.hotelName}}</h2>
                  <div class="gray-text mb-3 pb-3 pr-3">{{item.hotelAddress? item.hotelAddress + ', ' :
                    ''}}
                    <br *ngIf="item.cityName">
                    {{item.cityName? item.cityName : ''}}{{(item.stateCode && item.cityName)? ', ' :
                      ''}}{{item.stateCode? item.stateCode : ''}}{{((item.stateCode || item.cityName ) &&
                      (item.countryName))? ', ' : ''}}{{item.countryName? item.countryName : ''}}
                  </div>
                  <div class="gray-text mb-1">{{dataService.formatDateMMMdd(item.checkinDate)}} - {{dataService.formatDateMMMdd(item.checkoutDate)}}
                  </div>
                  <p *ngIf="dataService.inactiveWorkspace" class="view-guest font-16"> {{item.guestFirstName}} {{item.guestLastName}}</p>
                </div>
                <div class="reward-promo inactive-rewards d-flex position-absolute" *ngIf="item.rewardAmount">
                  <div class="promo-triangle"></div>
                  <div class="promo-text text-white font-12 px-3 pt-2" i18n="@@earnInRewardsDeactivated">${{item.rewardAmount  |  number : '1.0-0'}} in Rewards</div>
                </div>
              </div>
            </div>
            <div *ngIf="item.bookingType === 'FLIGHT'">
              <div class="d-flex">
                <i class="icon-flight-item font-18 gray-text" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
                <p class="gray-primaray-text mb-2">{{dataService.formatDateEEMMMdy(item.airItinerary.originDestinationOptionsGroups[0].summary.departureDate)}}
                  <span *ngIf="item.airItinerary.originDestinationOptionsGroups[1]"> - </span>
                  <span *ngIf="item.airItinerary.originDestinationOptionsGroups[1]" class="gray-text-dark">{{dataService.formatDateEEMMMdy(item.airItinerary.originDestinationOptionsGroups[1].summary.departureDate)}}</span>
                </p>
              </div>
              <div class="position-relative mb-5" (click)="viewDetails({ref: item.hrsReferenceNumber, identifier : item.bookRequest.email}, item.bookingType)">

                <div class="p-4 hotel-item rounded pointer" *ngFor="let destination of item.airItinerary.originDestinationOptionsGroups">

                  <div class="details">
                    <div class="row">
                      <div class="col-8">
                        <h2 class="gray-primaray-text bold-600 font-16 mb-2">{{destination.summary.departureAirport.city}} - {{destination.summary.arrivalAirport.city}}</h2>
                        <p class="primaray-text font-weight-bold mt-3">
                          <span class="mb-0 primaray-text" [innerHTML]="this.dataService.formatDateTime12Hours(destination.summary.departureDate)">10:15pm</span> -
                          <span class="mb-0 primaray-text" [innerHTML]="this.dataService.formatDateTime12Hours(destination.summary.arrivalDate)">10:15pm</span>

                          <span class="font-weight-normal" *ngIf="dataService.checkNextDay(destination.summary.departureDate, destination.summary.arrivalDate) === 1" i18n="@@nextDay"> (Next day)</span>
                          <span class="font-weight-normal" *ngIf="dataService.checkNextDay(destination.summary.departureDate, destination.summary.arrivalDate) > 1"> +{{dataService.checkNextDay(destination.summary.departureDate, destination.summary.arrivalDate)}}</span>
                          <span class="font-weight-normal" *ngIf="dataService.checkPreviousDay(destination.summary.departureDate, destination.summary.arrivalDate)" i18n="@@prevDay"> (Previous day)</span>
                        </p>
                        <p class="gray-text-dark font-14"><span>{{destination.summary.departureAirport.iata}}</span><span class="super-vertical-align"> ____ </span><span>{{destination.summary.arrivalAirport.iata}}</span></p>

                      </div>
                      <div class="col-4" [ngClass]="{'text-right': dataService.mobileView}">
                        <div [ngClass]="{'h-100 d-flex pt-4': dataService.mobileView}">
                          <p class="mb-0 primaray-text" [ngClass]="{'my-auto ml-auto': dataService.mobileView}"><span [innerHTML]="this.dataService.calDuration(destination.summary.journeyDurationInMins)">6h 45m</span>
                            <br/>
                            <span *ngIf="destination.summary.totalStops === 1" i18n="@@oneStop"> 1 stop</span>
                            <span *ngIf="destination.summary.totalStops === 2" i18n="@@twoStops"> 2 stops</span>
                            <span *ngIf="destination.summary.totalStops > 2"> {{destination.summary.totalStops}} <ng-container i18n="@@stops">stops</ng-container></span>
                            <span class="green-text" *ngIf="destination.summary.totalStops === 0" i18n="@@nonStop">Nonstop</span>
                          </p>
                        </div>
                        <!-- <div  *ngFor="let stop of destination.summary.stopSummaries ,  let i = index" >
                           <p class="gray-text-dark mb-0">
                             <span [innerHTML]="this.dataService.calDuration(stop.stopDurationInMinutes)"></span> in <span>{{stop.airport.iata}}</span>
                           </p>
                         </div>-->
                        <!-- <p class="green-text" *ngIf="destination.summary.totalStops === 0">Nonstop</p>-->
                      </div>
                    </div>
                    <div class="airlines">
                      <p class="mb-0 gray-text-dark" *ngIf="destination.summary.airlineInfoSummary.marketingAirlines.length === 1">
                        {{destination.summary.airlineInfoSummary.marketingAirlines[0]}}. {{destination.originDestinationOptions[0].flightSegment.cabinClassName}}
                      </p>
                      <p class="mb-0 gray-text-dark" *ngIf="destination.summary.airlineInfoSummary.marketingAirlines.length > 1">
                        <span i18n="@@multipleAirlines">Multiple Airlines: </span>
                        <span *ngFor="let airlineItem of destination.summary.airlineInfoSummary.marketingAirlines">
                                    {{airlineItem}}.
                                  </span>
                        {{destination.originDestinationOptions[0].flightSegment.cabinClassName}}
                      </p>
                    </div>
                  </div>
                  <div *ngIf="item.rewardAmount && dataService.mobileView" style="height: 34px"></div>
                  <div class="reward-promo inactive-rewards d-flex position-absolute" *ngIf="item.rewardAmount">
                    <div class="promo-triangle"></div>
                    <div class="promo-text text-white font-12 px-3 pt-2" i18n="@@earnInRewardsDeactivated">${{item.rewardAmount  |  number : '1.0-0'}} in Rewards</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div *ngIf="dataService.desktopView">
          <p class="primaray-text font-26 bold-600 pt-5 pt-4">Your next trip</p>

          <div *ngFor="let item of upcomingBooking; let i = index">
            <div class=" mb-4 hotel-item rounded" >

              <div>
                <div class="row">
                  <div class="col-5 col-lg-4">
                    <div class="hotel_image position-relative">
                      <div *ngIf="item.hotelImages[0] && item.hotelImages[0].url && item.hotelImages[0].url.includes('https://')" >
                        <img width="1000" height="667" class="rounded-left" [src]="item.hotelImages[0].url " onError="this.src='assets/img/no_image.png'"
                             loading="lazy" alt="hotel image">
                      </div>

                      <div *ngIf="item.hotelImages[0] && item.hotelImages[0].url && !item.hotelImages[0].url.includes('https://') " >
                        <img width="1000" height="667" class="rounded-left" [src]="item.hotelImages[0].url.replace('http://','https://')"
                             loading="lazy" alt="hotel image" onError="this.src='assets/img/no_image.png'">
                      </div>
                      <ng-container *ngIf="!item.hotelImages[0]">
                        <img width="279" height="239" class="rounded-left" loading="lazy" src="assets/img/no_image.png" />

                      </ng-container>

                    </div>

                  </div>
                  <div class="col-7 col-lg-8 pl-0">
                    <div class="pt-2 pt-lg-4 pb-4 pr-4">
                      <div class="row details">
                        <div class="col-12 hotel-name">
                          <h2 class="primaray-text bolder font-16-to-14 mb-0 mb-lg-2 ">{{item.hotelName}}</h2>
                        </div>
                        <div class="col-lg-12 col-md-6 pl-3 pl-lg-4">

                          <div class="address mb-3 pb-3 bookings-item-address pr-3">{{item.hotelAddress? item.hotelAddress + ', ' :
                            ''}}
                            {{item.cityName? item.cityName : ''}}{{(item.stateCode && item.cityName)? ', ' :
                              ''}}{{item.stateCode? item.stateCode : ''}}{{((item.stateCode || item.cityName ) &&
                              (item.countryName))? ', ' : ''}}{{item.countryName? item.countryName : ''}}
                          </div>
                          <div class="value mb-1">{{formatDailyDate(item.checkinDate)}} To {{formatDailyDate(item.checkoutDate)}}
                          </div>
                          <a [routerLink]="['/' + this.companyID + '/hotel/booking/details']" [queryParams]="{ref: item.referenceNumber, identifier : item.emailAddress}"
                             class="btn-link text-left view-details"> View details </a>

                        </div>

                      </div></div>


                  </div>


                </div>

              </div>

            </div>
          </div>
        </div>
-->




  <!--      <div *ngIf="dataService.mobileView">
          <p class="primaray-text font-18 pt-4">Your next trip</p>
          <div *ngFor="let item of upcomingBooking; let i = index">
            <div class=" mb-4 hotel-item rounded" >

              <div >

                <div class="row">
                  <div class="col-5 col-lg-4">
                    <div class="hotel_image">
                      <a class="clickable-cell" [routerLink]="['/' + this.companyID + '/hotel/booking/details']" [queryParams]="{ref: item.referenceNumber, identifier : item.emailAddress}">
                        <div *ngIf="item.hotelImages[0] && item.hotelImages[0].url && item.hotelImages[0].url.includes('https://')" >
                          <img width="1000" height="667" class="rounded-left" [src]="item.hotelImages[0].url "  onError="this.src='assets/img/no_image.png'"
                               loading="lazy" alt="hotel image">
                        </div>

                        <div width="1000" height="667" *ngIf="item.hotelImages[0] && item.hotelImages[0].url && !item.hotelImages[0].url.includes('https://') " >
                          <img class="rounded-left" [src]="item.hotelImages[0].url.replace('http://','https://')"  onError="this.src='assets/img/no_image.png'"
                               loading="lazy" alt="hotel image">
                        </div>
                        <ng-container *ngIf="!item.hotelImages || !item.hotelImages[0] || !item.hotelImages[0].url">
                          <img width="279" height="239" class="rounded-left" loading="lazy" src="assets/img/no_image.png" />

                        </ng-container>

                      </a>

                    </div>

                  </div>
                  <div class="col-7 col-lg-8 pl-0">
                    <a class="clickable-cell" [routerLink]="['/' + this.companyID + '/hotel/booking/details']" [queryParams]="{ref: item.referenceNumber, identifier : item.emailAddress}">
                      <div class="pt-2 pt-lg-4 pb-4 pr-4">
                        <div class="row details">

                          <div class="col-lg-6 pl-3 pl-lg-4">
                            <h2 class="primaray-text bolder font-18-to-14 mb-0 mb-lg-2 hotel-name">{{item.hotelName}}</h2>
                          </div>

                          <div class="col-lg-5 col-md-6 pl-3 pl-lg-0">

                            <div class="price mt-0 mt-lg-5 cash-back-cont bookings-item">
                              <div class="mt-1 mt-lg-2 cashback text-left">
                                <div class="value font-12 gray-primaray-text">{{formatDailyDate(item.checkinDate)}} To {{formatDailyDate(item.checkoutDate)}}
                                </div>
                              </div>
                            </div>
                          </div>


                        </div></div>

                    </a>
                  </div>


                </div>


              </div>

            </div>
          </div>
        </div>-->

      </div>
      <p class="font-26 mb-4 mb-lg-2 mb-lg-1 mt-5 primaray-text bold-600 pt-4" i18n="@@subscriptionSummary">Subscription Summary</p>
     <!-- <hr class="mt-1 d-none d-lg-block"/>-->
      <div class="pb-5">
      <div class="border-top border-bottom pt-2 pb-3 mt-4 mb-5" *ngIf="dataService.desktopView">
        <div class="row pt-3 pb-3 font-16 gray-primaray-text">
          <div class="col" i18n="@@billingCycle">Billing Cycle</div>
          <div class="col" i18n="@@lastBillingDate">Last Billing Date</div>
          <div class="col" i18n="@@lastInvoice">Last Invoice</div>
          <div class="col" i18n="@@activeMembers">Active Members</div>
          <div class="col"></div>
        </div>
        <div *ngIf="this.dataService.workspaceLastSubscription">
          <div class="row py-3">
            <div class="col">
              <div class="mb-1">
                <span class="border rounded p-2 placeholder-text">{{this.dataService.workspaceLastSubscription.cycle}}</span>
              </div>
            </div>
            <div class="col">
              <span class="border rounded p-2 placeholder-text" [innerHTML]="dataService.formatDateMMMdy(dataService.workspaceLastSubscription.periodEnd)"></span>
            </div>
            <div class="col">
              <span *ngIf="dataService.workspaceLastSubscription.value" class="border rounded p-2 font-weight-bold placeholder-text">${{dataService.workspaceLastSubscription.value}}</span>
              <span *ngIf="!dataService.workspaceLastSubscription.value" class="border rounded p-2 font-weight-bold placeholder-text">&mdash;</span>
            </div>
            <div class="col">
              <span class="border rounded p-2 placeholder-text">{{dataService.workspaceLastSubscription.quantity}}</span>
            </div>
            <div class="col"></div>
          </div>

        </div>
      </div>
      <div class="white-box p-3 mb-5" *ngIf="dataService.workspaceLastSubscription && dataService.mobileView">
        <div class="mb-5">
          <p class="font-16 gray-primaray-text" i18n="@@billingCycle">Billing Cycle</p>
          <div  class="mb-1 pointer">
            <span class="border rounded p-2 placeholder-text">{{dataService.workspaceLastSubscription.cycle}}</span>
          </div>
        </div>

        <div class="mb-5">
          <p class="font-16 gray-primaray-text" i18n="@@lastBillingDate">Last Billing Date</p>
          <p>
                <span class="border rounded p-2 placeholder-text"
                      [innerHTML]="dataService.formatDateMMMdy(dataService.workspaceLastSubscription.periodEnd)"></span>
          </p>
        </div>

        <div class="mb-5">
          <p class="font-16 gray-primaray-text" i18n="@@lastInvoice">Last Invoice</p>
          <p>
                <span *ngIf="dataService.workspaceLastSubscription.value" class="border rounded p-2 placeholder-text">
                  ${{dataService.workspaceLastSubscription.value}}</span>
            <span *ngIf="!dataService.workspaceLastSubscription.value" class="border rounded p-2 placeholder-text">
                  &mdash;</span>
          </p>
        </div>


        <p class="font-16 gray-primaray-text" i18n="@@activeMembers">Active Members</p>
        <p>
                <span class="border rounded p-2 placeholder-text">
                {{dataService.workspaceLastSubscription.quantity}}</span>
        </p>

      </div>
    </div>
    <!--  <div [ngClass]="{'custom-container': dataService.desktopView}">
        <p class="font-18 pb-2 mb-lg-1 mt-5">Company Bookings Summary</p>
        <app-dashboard></app-dashboard>
      </div>-->



    </div>

  </div>
</div>
