import { Component, ElementRef, OnInit } from "@angular/core";
import { DataService } from "../../../../services/data.service";
import { SidenavService } from "../../../workspace-dashboard/sidenav.service";
import { onMainContentChange } from "../../../workspace-dashboard/animations/animations";
import { Router } from "@angular/router";
import { AuthServiceMain } from "../../../../auth/auth.service";
import { Title } from "@angular/platform-browser";
import {UserService} from "../../../../services/user.service";

@Component({
  selector: "app-users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.scss"],
  animations: [onMainContentChange],
  host: {
    "(body:click)": "onClickBody($event)",
  },
})
export class UsersListComponent implements OnInit {
  constructor(
    private eref: ElementRef,
    public dataService: DataService,
    private sidenavService: SidenavService,
    public router: Router,
    public auth: AuthServiceMain,
    private titleService: Title,
    public userService: UserService
  ) {
    this.titleService.setTitle("Members list | SafarHub");
    this.sidenavService.sideNavState$.subscribe((res) => {
      this.dataService.onSideNavChange = res;
    });
    this.dataService.componentMethodCalled$.subscribe(() => {
      if (
        this.auth.isLogedIn &&
        this.auth.companyInfo &&
        this.auth.companyInfo.inactive === false
      ) {
        if (this.auth.isAdmin() || this.auth.isOwner()) {
          this.dataService.getPendingUsers();
        }
      }
    });
  }
  data;
  popUpTitle = $localize`:@@popUpTitle:CSV file upload`;
  showActions = false;
  selectedIndex;
  actionsPending = false;
  selectedIndexPending;
  search;

  ngOnInit(): void {
    this.dataService.getAllCompanyUsers();
    if (this.auth.companyInfo && this.auth.companyInfo.inactive === false) {
      this.dataService.getPendingUsers();
    }
    this.filterBy('all');
  }

  onClickBody(event) {
    if (
      this.eref.nativeElement.querySelector(
        ".drop-down" + this.selectedIndex
      ) &&
      !this.eref.nativeElement
        .querySelector(".drop-down" + this.selectedIndex)
        .contains(event.target)
    ) {
      this.showActions = false;
    }
    if (
      this.eref.nativeElement.querySelector(
        ".drop-down-pending" + this.selectedIndexPending
      ) &&
      !this.eref.nativeElement
        .querySelector(".drop-down-pending" + this.selectedIndexPending)
        .contains(event.target)
    ) {
      this.actionsPending = false;
    }
  }

  filterBy(filter) {
    this.dataService.usersSortType = filter;
    if (filter === "active") {
      this.dataService.filteredUsersList = this.dataService.activeArray ? this.dataService.activeArray.slice() : [];
    } else if (filter === "inactive") {
      this.dataService.filteredUsersList =
        this.dataService.inactiveArray ? this.dataService.inactiveArray.slice() : [];
    } else if (filter === "pending") {
      this.dataService.filteredUsersList = [];
      this.dataService.filteredPending =
        this.dataService.pendingArrayUsers ? this.dataService.pendingArrayUsers.slice() : [];
    } else {
      if (this.auth.companyInfo && this.auth.companyInfo.inactive === false) {
        this.dataService.filteredPending =
          this.dataService.pendingArrayUsers ? this.dataService.pendingArrayUsers.slice() : [];
      }
      this.dataService.filteredUsersList =
        this.dataService.companyUsersList ? this.dataService.companyUsersList.slice() : [];
    }
    if (this.search) {
      if (
        this.dataService.usersSortType === "all" ||
        this.dataService.usersSortType === "active" ||
        this.dataService.usersSortType === "inactive"
      ) {
        this.dataService.filteredUsersList =
          this.dataService.filteredUsersList.filter((item) => {
            return (
              item.firstName?.toLowerCase().indexOf(this.search) === 0 ||
              item.lastName?.toLowerCase().indexOf(this.search) === 0 ||
              item.email.toLowerCase().indexOf(this.search) === 0
            );
          });
      }
      if (
        this.dataService.usersSortType === "all" ||
        this.dataService.usersSortType === "pending"
      ) {
        if (this.auth.companyInfo && this.auth.companyInfo.inactive === false) {
          this.dataService.filteredPending =
            this.dataService.filteredPending.filter((item) => {
              return item.email.toLowerCase().indexOf(this.search) === 0;
            });
        }
      }
    }
  }

  filterByText(event) {
    this.search = event.target.value.toLowerCase();
    if (this.dataService.usersSortType === "active") {
      this.dataService.filteredUsersList = this.dataService.activeArray ? this.dataService.activeArray.slice() : [];
    } else if (this.dataService.usersSortType === "inactive") {
      this.dataService.filteredUsersList =
        this.dataService.inactiveArray ? this.dataService.inactiveArray.slice() : [];
    } else if (this.dataService.usersSortType === "pending") {
      this.dataService.filteredPending =
        this.dataService.pendingArrayUsers ? this.dataService.pendingArrayUsers.slice() : [];
    } else {
      if (this.auth.companyInfo && this.auth.companyInfo.inactive === false) {
        this.dataService.filteredPending =
          this.dataService.pendingArrayUsers ? this.dataService.pendingArrayUsers.slice() : [];
      }
      this.dataService.filteredUsersList =
        this.dataService.companyUsersList ? this.dataService.companyUsersList.slice() : [];
    }
    if (
      this.dataService.usersSortType === "all" ||
      this.dataService.usersSortType === "active" ||
      this.dataService.usersSortType === "inactive"
    ) {
      this.dataService.filteredUsersList =
        this.dataService.filteredUsersList.filter((item) => {
          return (
            item.firstName
              ?.toLowerCase()
              .indexOf(event.target.value.toLowerCase()) === 0 ||
            item.lastName
              ?.toLowerCase()
              .indexOf(event.target.value.toLowerCase()) === 0 ||
            item.email
              .toLowerCase()
              .indexOf(event.target.value.toLowerCase()) === 0
          );
        });
    }
    if (
      this.dataService.usersSortType === "all" ||
      this.dataService.usersSortType === "pending"
    ) {
      if (this.auth.companyInfo && this.auth.companyInfo.inactive === false) {
        this.dataService.filteredPending =
          this.dataService.filteredPending.filter((item) => {
            return (
              item.email
                .toLowerCase()
                .indexOf(event.target.value.toLowerCase()) === 0
            );
          });
      }
    }
  }

  inviteUsers() {
    this.router.navigate([this.router.url + "/invite"]);
  }

  viewUserDetails(userId) {
    if (this.auth.companyInfo && this.auth.companyInfo.inactive === false) {
      this.router.navigate([this.router.url + "/" + userId]);
    }
  }

  deactivateUser(userId) {
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@deactivateUser:Deactivate User`,
        "deactivateUser",
        userId
      );
    } else {
      this.router.navigate([this.router.url + "/" + userId + "/deactivate"]);
    }
  }

  reactivateUser(userId) {
    this.dataService.userReactivateId = userId;
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@reactivateUser:User Reactivation Fees`,
        "userReactivationFees",
        ""
      );
    } else {
      this.dataService.setBottomSheet("userReactivationFees", "");
    }
  }

  showCancelInvitation(email) {
    this.dataService.submitting = false;
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@withdrawInviteTitle:Withdraw Invitation`,
        "cancelInvitation",
        email
      );
    } else {
      this.dataService.setBottomSheet("cancelInvitation", email);
    }
  }
}
