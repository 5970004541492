import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../../services/data.service";
import * as moment from "moment";
import { GoogleAnalyticsService } from "../../../../google-analytics.service";
@Component({
  selector: 'app-hotel-details',
  templateUrl: './hotel-details.component.html',
  styleUrls: ['./hotel-details.component.scss']
})
export class HotelDetailsComponent implements OnInit {
  diffInDays;
  PaxesCount;
  PaxesArray;
  reviewTitle =
      $localize`:@@reviewSummary:Review summary` +
      "<span>(" +
      $localize`:@@poweredByGoogle:Powered by Google` +
      ") </span>";
  TripAdviserRating;
  tripAdviserdata;
  constructor(public Service: DataService,
              public googleAnalyticsService: GoogleAnalyticsService) { }
  ngOnInit(): void {
    if (sessionStorage.getItem("tripAdviserdata")) {
      this.tripAdviserdata = JSON.parse(
          sessionStorage.getItem("tripAdviserdata")
      );
      this.TripAdviserRating = (this.tripAdviserdata.data.rating * 100) / 5;
    }
    this.PaxesArray = this.Service.params.paxes.split(",");
    this.PaxesCount = this.PaxesArray.length + Number(this.PaxesArray[0]) - 1;
    this.diffInDays = moment
        .utc(this.Service.params.checkOut)
        .diff(moment.utc(this.Service.params.checkIn), "days");
  }
  tripAdvisorTrigger() {
    this.googleAnalyticsService.eventEmitter(
        "Stats",
        "TripAdvisor",
        "click",
        1
    );
  }
}
