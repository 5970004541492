
<div class="container pb-3 mb-5 mb-lg-0">
  <div class="justify-content-center" [ngClass]="{'text-right': dataService.isSiteRtl()}">
    <div class="d-flex mb-4">
      <p class="gray-text-dark font-16 line-height-text mt-4 mt-lg-0" i18n="@@deleteRewardProgMsg">Why are you deleting your rewards program?</p>

    </div>


      <form [formGroup]="deleteRewardsForm" name="deleteRewardsForm">
        <div class="form-group font-16 mb-0 gray-text-dark" *ngFor="let reason of reasonsArray">
          <input [ngClass]="{'mt-2 input-mobile': dataService.mobileView, 'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}" type="radio" value="{{reason.text}}" id="{{reason.id}}" name="reasons"  formControlName="reasons"/>
          <label  [ngClass]="{'radio-mobile': dataService.mobileView}" for="{{reason.id}}">{{reason.text}}</label>
        </div>
        <div *ngIf="submitted && ctrl.reasons.errors" class="text-danger">
          <div *ngIf="ctrl.reasons.errors.required" i18n="@@reasonRequired">Reason is required</div>
        </div>

        <div class="form-group mt-4 mb-5">
          <textarea name="improveService" id="improveService" formControlName="improveService" class="pt-3 form-control"
              i18n-placeholder="@@deactivatePlaceholder"      placeholder="Give us more details to help improve our service." rows="4"></textarea>
        </div>
      </form>


    <div class="justify-content-end" [ngClass]="{'d-flex': dataService.desktopView}">
      <button class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5" [ngClass]="{'m-progress': submitting, 'w-100': dataService.mobileView, 'ml-2': dataService.desktopView}" (click)="deleteRewardsProgram()" i18n="@@deleteBtn">Delete</button>
    </div>
    <div class="text-danger mt-3" [ngClass]="{'text-right': dataService.desktopView}">{{this.errorMsg }}</div>
  </div>
</div>
