<div class="section-body gray-text-dark p-4 mt-1">
    <div class="row">
        <div class="col-md-7">

            <div class="checkout-container">

                <!-- Checkout Form -->
                <form [formGroup]="cardInfoForm">

                    <!-- Card Holder Name Input -->
                    <div class="form-group">
                        <label for="cardHolderName" class="gray-text-dark" i18n="@@cardPlaceholder"> Cardholder Name </label>
                        <input
                                id="cardHolderName"
                                formControlName="cardHolderName"
                                type="text"
                                class="form-control"
                                (input)="lettersOnly($event)"
                                [ngClass]="{ 'is-invalid': formControls.cardHolderName.errors && submitted}"
                        />
                        <!-- Error Message for Card Holder Name -->
                        <div i18n="@@cardNameRequired"
                             class="text-danger mt-1 font-12"
                             *ngIf="cardInfoForm.get('cardHolderName')?.invalid && submitted">
                            Card holder name is required.
                        </div>
                    </div>

                    <!-- Card Number Input -->
                    <div class="form-group">
                        <label for="cardNumber" class="gray-text-dark" i18n="@@cardNumber">Card Number</label>

                        <div class="input-group">
                            <input
                                    id="cardNumber"
                                    formControlName="cardNumber"
                                    type="text"
                                    (input)="numbersOnly($event)"
                                    class="form-control card-number"
                                    [ngClass]="{ 'is-invalid': formControls.cardNumber.errors && submitted}"
                            />

                            <!-- Card Icon Container (placed inside input group) -->
                            <div class="input-group-append">
                                <ng-container *ngIf="isCardNumberLongEnough">
                                    <div class="card-icon" *ngIf="cardDetails?.cardType === 'visa'">
                                        <img src="/assets/img/payment_methods/visa.png" alt="Visa" class="card-icon-img">
                                    </div>
                                    <div class="card-icon" *ngIf="cardDetails?.cardType === 'mastercard'">
                                        <img src="/assets/img/payment_methods/mastercard.png" alt="Mastercard" class="card-icon-img">
                                    </div>
                                    <div class="card-icon" *ngIf="cardDetails?.cardType === 'American' || cardDetails?.cardType === 'amex'">
                                        <img src="/assets/img/payment_methods/amex.png" alt="American" class="card-icon-img">
                                    </div>
                                    <div class="card-icon" *ngIf="cardDetails?.cardType === 'mada'">
                                        <img src="/assets/img/payment_methods/mada.png" alt="mada" class="card-icon-img">
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <!-- Error Message for Card Number -->
                        <div i18n="@@cardNumberRequired" class="text-danger mt-1 font-12"
                             *ngIf="cardInfoForm.get('cardNumber')?.invalid && submitted">
                            Card number is required.
                        </div>
                    </div>

                    <!-- Expiry Date Input -->
                    <div class="form-group expiry-group">
                        <div class="expiry-item">
                            <label for="expiryMonth" class="gray-text-dark" i18n="@@expiryMonth">Expiry Month (MM)</label>
                            <input
                                    id="expiryMonth"
                                    formControlName="expiryMonth"
                                    type="text"
                                    maxlength="2"
                                    class="form-control"
                                    (input)="numbersOnly($event); onMonthInput()"
                                    [ngClass]="{ 'is-invalid': formControls.expiryMonth.errors && submitted}"
                            />
                            <!-- Error Message for Expiry Month -->
                            <div i18n="@@expiryMonthRequired"
                                 class="text-danger mt-1 font-12"
                                 *ngIf="cardInfoForm.get('expiryMonth')?.invalid && submitted">
                                Expiry month is required.
                            </div>
                        </div>


                        <div class="expiry-item">
                            <label for="expiryYear" class="gray-text-dark" i18n="@@expiryYear">Expiry Year (YY)</label>
                            <input
                                    #expiryYear
                                    id="expiryYear"
                                    formControlName="expiryYear"
                                    type="text"
                                    maxlength="2"
                                    class="form-control"
                                    (input)="numbersOnly($event)"
                                    [ngClass]="{ 'is-invalid': formControls.expiryYear.errors && submitted}"
                            />
                            <!-- Error Message for Expiry Year -->
                            <div i18n="@@expiryYearRequired"
                                 class="text-danger mt-1 font-12"
                                 *ngIf="cardInfoForm.get('expiryYear')?.invalid && submitted">
                                Expiry year is required.
                            </div>
                        </div>

                    </div>

                    <!-- Card Security Code Input -->
                    <div class="form-group">
                        <label for="cardSecurityCode" class="gray-text-dark" i18n="@@securityCode">Security Code (CVV)</label>
                        <input
                                id="cardSecurityCode"
                                formControlName="cardSecurityCode"
                                type="text"
                                class="form-control"
                                (input)="numbersOnly($event)"
                                (keyup.enter)="onSubmit()"
                                [ngClass]="{ 'is-invalid': formControls.cardSecurityCode.errors && submitted}"
                        />
                        <!-- Error Message for CVV -->
                        <div i18n="@@securityCodeRequired"
                             class="text-danger mt-1 font-12"
                             *ngIf="cardInfoForm.get('cardSecurityCode')?.invalid && submitted">
                            Security code is required.
                        </div>
                    </div>



                    <div class="form-group mt-3">
                        <label *ngIf="this.dataService.desktopView" i18n="@@cardAlias"> Card Alias (optional) </label>
                        <input (keydown)="preventSpace($event)" *ngIf="this.dataService.desktopView" (keyup.enter)="onSubmit()" type="text" name="cardAlias"  formControlName="cardAlias" class="form-control" placeholder=""  autocomplete="off">
                        <input (keydown)="preventSpace($event)" *ngIf="this.dataService.mobileView" (keyup.enter)="onSubmit()" type="text" name="cardAlias"  formControlName="cardAlias" class="form-control" i18n-placeholder="@@cardAlias" placeholder="Card Alias (optional)"  autocomplete="off">

                        <!-- Error Message for CVV -->
                        <div class="text-danger mt-1 font-12" *ngIf=" this.spaceMsg">{{this.spaceMsg}}</div>
                    </div>



                    <!-- Submit Button -->

                    <div class="form-actions d-flex justify-content-md-start justify-content-center mt-5 mt-md-0" *ngIf="!dataService.mobileView">
                        <button type="button" class="btn-outline-blue btn cancel font-14"
                                [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"
                                (click)="close()" i18n="@@cancelBtn"> Cancel</button>
                        <button type="button" [ngClass]="{'m-progress': submitting }" class="loader_button btn blue_btn blue_btn_form form-submit" (click)="onSubmit()" i18n="@@save">Save</button>
                    </div>
                    <div class="form-actions justify-content-md-start justify-content-center mt-5 mt-md-0" *ngIf="dataService.mobileView">
                        <button type="button" [ngClass]="{'m-progress': submitting }" class="loader_button btn blue_btn blue_btn_form form-submit w-100 mb-3" (click)="onSubmit()" i18n="@@save">Save</button>
                        <button *ngIf="!this.router.url.includes('add-card')" type="button" class="btn-outline-blue btn cancel w-100" (click)="close()" i18n="@@cancelBtn"> Cancel</button>
                    </div>
                    <p class="mb-0 mt-3 text-danger font-12" *ngIf=" this.validationMessage">{{this.validationMessage}}</p>

                </form>
            </div>

        </div>

        <!-- right side -->
        <div class="col-md-5 border-sm-0" *ngIf="dataService.desktopView"
             [ngClass]="{'border-left': dataService.isSiteLtr(), 'border-right': dataService.isSiteRtl()}">
        <app-accepted-cards-section></app-accepted-cards-section>
        </div>
    </div>



</div>


