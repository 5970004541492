<div id="appComp" (window:scroll)="Service.onScroll($event)" (window:resize)="Service.onResize($event)" [dir]="this.Service.siteDirection.toLowerCase()">
  <div i18n-align="@@siteAlign" align="left">
  <app-workspace-header *ngIf="(Service.routeToWorkSpaceHomePage && router.url ==='/' && !authService.isLogedIn)
  || router.url === '/info/about' || router.url === '/info/contact-us' ||
  (Service.isCompany() && ((Service.desktopView && !authService.isLogedIn && !router.url.includes('add-card')) ||
   (Service.desktopView && router.url.includes('/slack')) ||
   (router.url ==='/' && !authService.isLogedIn) || router.url.includes('code') || router.url.includes('upload') ||
   router.url.includes('onboarding') || (Service.mobileView && !router.url.includes('add-card'))))"></app-workspace-header>
  <div  [@routerTransition]="prepareRoute(outlet)" class="main-container">
    <router-outlet  #outlet="outlet" > </router-outlet>
  </div>

  <app-footer *ngIf="! (Service.routeToWorkSpaceHomePage || this.Service.isCompany()  || router.url.includes('best-hotel-rates-newsletter') || router.url.includes('offer/coupon')  ||  router.url.includes('save-now-travel-later') ||  router.url.includes('free-lifebox-promotion')  || router.url.includes('pass/promo/lifebox')  || router.url.includes('free-trial-subscription') || (router.url.includes('pass/preorder') && !router.url.includes('pass/preorder/welcome'))) || (router.url.includes('best-hotel-rates-newsletter/welcome') || router.url.includes('free-trial-subscription/welcome') || router.url.includes('save-now-travel-later/welcome') || router.url.includes('free-lifebox-promotion/welcome')    )"></app-footer>
  <app-slack-footer *ngIf="router.url.includes('/slack') && !router.url.includes('/')"></app-slack-footer>
  <app-modal></app-modal>
  <app-modal-notification></app-modal-notification>
  <app-snak-bar></app-snak-bar>
  </div>
</div>
<app-cookies-bar *ngIf="router.url === '/' && !Service.routeToWorkSpaceHomePage"></app-cookies-bar>
<app-release-number></app-release-number>
<app-bottom-sheet></app-bottom-sheet>
<app-right-side-drawer></app-right-side-drawer>


<div *ngIf="showOverlay" class="overlay-white"></div>


