<div *ngIf="logedIn.isLogedIn" class="trips-page">
  <ng-container *ngIf="!(canceledBooking && completedBooking && canceledBooking) || this.dataService.hotellistLoader" >
      <div class="loading_icon_caption text-center mt-5"></div>
      <div class="loading-message text-center" i18n="@@loading"> Loading </div>
  </ng-container>
  <div *ngIf="canceledBooking && completedBooking && canceledBooking" >

    <div class="company-section-with-thick-border border" [ngClass]="{'custom-container': dataService.desktopView,
    'rounded-right': dataService.desktopView && dataService.isSiteLtr(), 'rounded-left': dataService.desktopView && dataService.isSiteRtl(),
    'thick-top rounded-bottom': dataService.mobileView}"
         *ngIf="upcomingBookingMain?.length === 0 && completedBookingMain?.length === 0 && canceledBookingMain?.length === 0 && !this.dataService.hotellistLoader">
      <div class="row mx-0 p-4 h-100 justify-content-center align-items-center">
        <div class="col-md-8 font-16 ">
          <p class="mb-0 gray-primaray-text" i18n="@@myTripsMsg">Your trips will appear here once you book a trip.</p>
        </div>
        <div class="col-md-4" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">
          <button   (click)="this.router.navigate(['/']);"
                    class="w-100 btn px-5 py-2 mt-4 mb-3 my-lg-2 rounded text-center sharp-font bold-500 ml-auto font-14 find-btn btn-primary" i18n="@@menuItemBookTrip">Book a trip</button>
        </div>
      </div>
    </div>

    <div *ngIf="upcomingBookingMain || completedBookingMain || canceledBookingMain" class="fav-hotels-in-trips max-width-600">

      <div *ngIf="(upcomingBookingMain?.length || completedBookingMain?.length || canceledBookingMain?.length)  && !this.dataService.hotellistLoader">
        <div id="sticky-booking-menu" class="bookings-menu"
             [ngClass]="{'sticky' : isSticky}" *ngIf="!this.dataService.hotellistLoader">
          <a [ngClass]="{ 'selected': isUpcoming, 'disabled': upcomingBookingMain?.length === 0 }" (click)="isUpcoming = true; isCompleted = false; isCanceled = false;  triggerScrollToOffsetOnly('upcoming');" class="bolder" i18n="@@upcoming">Upcoming</a> <span class="px-3">|</span>
          <a [ngClass]="{ 'selected': isCompleted, 'disabled': completedBookingMain?.length === 0 }" (click)="isUpcoming = false; isCompleted = true; isCanceled = false; triggerScrollToOffsetOnly('completed');" class="bolder" i18n="@@completed">Completed</a> <span class="px-3">|</span>
          <a [ngClass]="{ 'selected': isCanceled, 'disabled': canceledBookingMain?.length === 0 }" (click)="isUpcoming = false; isCompleted = false; isCanceled = true; triggerScrollToOffsetOnly('canceled');" class="bolder" i18n="@@canceled">Canceled</a>
        </div>
        <div class="col-md-4 px-0 form-group mt-4" *ngIf="dataService.inactiveWorkspace">
          <input  class="form-control" id="search"  type="text" name="search" i18n-placeholder="@@searchForUsers" placeholder="Search for users"  (focus)="filterByText($event);" (keyup)="filterByText($event);"/>
        </div>
        <div>

          <p id="upcoming" class="gray-primaray-text font-18-to-16 bold-600 my-4" *ngIf="upcomingBooking?.length !==0 " i18n="@@upcomingStays">Upcoming Stays</p>
          <div *ngFor="let item of upcomingBooking; let i = index">
            <div *ngIf="item.bookingType === 'HOTEL'">
              <app-hotel-trip-item [item]="item"></app-hotel-trip-item>
            </div>

            <ng-container *ngIf="item.bookingType === 'FLIGHT'">
              <app-flight-trip-item [flightItem]="item"
                                    *ngIf="item.bookingResult.data.flightOffers[0].itineraries[0].searchSegmentSummary">
              </app-flight-trip-item>
            </ng-container>
          </div>


          <p id="completed" class="gray-primaray-text font-18-to-16 bold-600 my-4" *ngIf="completedBooking?.length !==0 " i18n="@@completedStays">Completed Stays</p>
          <div class="scroll-animation" *ngFor="let item of completedBooking | slice : 0 : showMoreCompleted; let i = index">
            <div *ngIf="item.bookingType === 'HOTEL'">
              <app-hotel-trip-item [item]="item"></app-hotel-trip-item>
            </div>

            <ng-container *ngIf="item.bookingType === 'FLIGHT'">
              <app-flight-trip-item [flightItem]="item"
                                    *ngIf="item.bookingResult.data.flightOffers[0].itineraries[0].searchSegmentSummary">
              </app-flight-trip-item>
            </ng-container>

          </div>

          <div id="show-more-completed" class="text-center no-data-cont mt-5 mb-5 position-relative" *ngIf="showMoreCompleted < completedBooking?.length">
            <a (click)="showMoreCompleted = showMoreCompleted + 3" class="no-data-box center_text sharp-font font-12 bold" i18n="@@showMore">
              Show more </a>
          </div>



          <p id="canceled" class="gray-primaray-text font-18-to-16 bold-600 my-4" *ngIf="canceledBooking?.length !==0 " i18n="@@canceledBookings">Canceled Bookings</p>
          <div class="scroll-animation" *ngFor="let item of canceledBooking| slice : 0 : showMoreCanceled; let i = index">
            <div *ngIf="item.bookingType === 'HOTEL'">
              <app-hotel-trip-item [item]="item"></app-hotel-trip-item>
            </div>

            <ng-container *ngIf="item.bookingType === 'FLIGHT'">
              <app-flight-trip-item [flightItem]="item"
                                    *ngIf="item.bookingResult.data.flightOffers[0].itineraries[0].searchSegmentSummary">
              </app-flight-trip-item>
            </ng-container>
          </div>

          <div id="show-more-canceled" class="text-center no-data-cont mt-5 mb-5 position-relative" *ngIf="showMoreCanceled < canceledBooking?.length">
            <a (click)="showMoreCanceled = showMoreCanceled + 10" class="no-data-box center_text sharp-font font-12 bold" i18n="@@showMore">
              Show more </a>
          </div>

        </div>





      </div>
    </div>
  </div>
</div>
