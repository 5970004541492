<div class="container small-container mt-5 pb-4" >
<h5 class="text-center text-medium gray-text-dark mb-4" i18n="@@ForgotPassword1">We'll email you instructions on how to reset it.</h5>


<form name="forgotPassword" [formGroup]="forgotPassword" (ngSubmit)="onSubmit()">
    <div class="form-group ">
      <input  type="email" name="email" class="form-control"  formControlName="email" i18n-placeholder="@@enterEmailPlaceHolder" placeholder="Enter your email address" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" >
      <div *ngIf="submitted && f.email.errors" class="text-danger smaller" [ngClass]="{'text-left': restApi.isSiteLtr(), 'text-right': restApi.isSiteRtl()}">
        <div *ngIf="f.email.errors.required" i18n="@@requiredEmailError">Email is required</div>
        <div *ngIf="f.email.errors.email" i18n="@@invalidEmailError">Email must be a valid email address</div>
      </div>
    </div>
    <div class="form-actions">
      <button value="submit" class="loader_button btn btn-block" [ngClass]="{'m-progress': submitting, 'btn-primary': !restApi.isCompany(), 'blue_btn font-14 sharp-font bold-500': restApi.isCompany()}" i18n="@@submitBtn">Submit</button>
    </div>
  <div class="text-danger mt-2">{{ereeorMSG}}</div>

<!--  <div class="other_actions">-->
<!--      <ul class="list-unstyled mt-3">-->
<!--        <li>-->
<!--          <button class="btn-link  py-2 font-weight-normal text-sm" (click)="this.restApi.openloginDialogue()" ng-show="!user.in" > Already a member? Sign in</button>-->
<!--        </li>-->
<!--        <li>-->
<!--          <button class="btn-link  py-2 font-weight-normal text-sm" (click)="this.restApi.openRegistrationDialogue()" ng-show="!user.in"> Not a member? Sign up</button>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->


</form>
</div>
