import {Component, Inject, OnInit} from '@angular/core';
import { onSideNavChange, animateText } from '../animations/animations';
import { SidenavService} from '../sidenav.service';
import {DataService} from '../../../services/data.service';
import {AuthServiceMain} from '../../../auth/auth.service';
import {Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {environment} from "../../../../environments/environment";
import {NoopImportRewriter} from "@angular/compiler-cli/src/ngtsc/imports";
import {ApprovalsService} from "../../../services/approvals.service";
import {UserService} from "../../../services/user.service";
@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [onSideNavChange, animateText]
})
export class LeftMenuComponent implements OnInit {
langSwitchTo = 'ar';
/*  public sideNavState: boolean = false;
  public linkText: boolean = false;*/
  env = environment;
  constructor(@Inject(DOCUMENT) private doc: any,
              public auth: AuthServiceMain,
              public dataService: DataService,
              private sidenavService: SidenavService,
              public approvalsService: ApprovalsService,
              public router: Router,
              public userService: UserService) {
    this.dataService.sideNavState = this.dataService.linkText = this.dataService.onSideNavChange = this.dataService.initialMarginValue = localStorage.getItem('isLeftMenuOpen') === 'true';
  }

  ngOnInit() {
    if(localStorage.getItem('locale') && localStorage.getItem('locale').includes('ar')){
      this.langSwitchTo = 'en-US';
    }
    const htmlTag = this.doc.getElementsByTagName('html')[0];
    this.dataService.corporateLocale = htmlTag.getAttribute('lang');
    this.dataService.sideNavState = this.dataService.linkText = this.dataService.onSideNavChange = this.dataService.initialMarginValue = localStorage.getItem('isLeftMenuOpen') === 'true';
    setTimeout( () => {

      if (this.auth.isLogedIn && !this.auth.companyInfo) {
        this.auth.fetchCompanyData();
      }

    }, 1000);
  }

  onSideNavToggle() {
    localStorage.setItem('isLeftMenuOpen', 'true');
    this.dataService.sideNavState = !this.dataService.sideNavState

    setTimeout(() => {
      this.dataService.linkText = this.dataService.sideNavState;
    }, 200)
    this.sidenavService.sideNavState$.next(this.dataService.sideNavState);

  }
}
