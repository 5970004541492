import {
    transition,
    trigger,
    query,
    style,
    animate,
    group,
} from '@angular/animations';

export const slideInAnimation =
  trigger('routerTransition', [
    transition('* => *', [
      query(':enter, :leave', style({ opacity: "1", display: 'block', width: '100%' }), { optional: true }),
      group([
        query(':enter', [
          style({opacity: "0",  transform: 'translateX(30%)' }),
          animate('0.7s ease-out', style({ opacity: "1", transform: 'translateX(0%)' }))
        ], { optional: true }),
        query(':leave', [
          style({ transform: 'translateX(0%)' ,  height: '0%', opacity: '0',}),
          animate('0.7s ease-out', style({opacity: "0",  position: 'absolute',transform: 'translateX(-30%)' }))
        ], { optional: true }),
      ])
    ]),
  ])
