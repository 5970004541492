
<div class="form-gruop">

  <input #SearchCode type="text" [(ngModel)]="Search"  (ngModelChange)="CountrySearch()" class="form-control" placeholder="Search..."  />
</div>


<div class="country-code-list-container">
<ng-container *ngFor="let item of countryCodeList">
<div class="py-3 px-2 border-bottom d-flex gray-primaray-text font-16 clickable-cursor country-item animate-C " (click)="this.Service.SetCountryCode(item)">
  <span [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}">{{item.code}}</span>
  <span>{{item.dial_code}}</span>

</div>
</ng-container>
  </div>
