<div class="d-flex">
    <i class="icon-hotel-item font-25 gray-text" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
    <p class="gray-primaray-text mt-1 mb-2">{{item.cityName}} - {{dataService.formatDateEEMMMdy(item.checkinDate)}}</p>
</div>
<div class="mb-5 hotel-item rounded pointer position-relative p-4"
     (click)="tripsService.viewTripsDetails(item.referenceNumber, item.emailAddress, item.bookingType)">


    <div class="details">
        <h2 class="gray-primaray-text bold-600 font-16 mb-2">{{item.hotelName}}</h2>
        <div class="gray-text mb-3 pb-3 pr-3">{{item.hotelAddress? item.hotelAddress + ', ' :
            ''}}
            <br *ngIf="item.cityName">
            {{item.cityName? item.cityName : ''}}{{(item.stateCode && item.cityName)? ', ' :
                ''}}{{item.stateCode? item.stateCode : ''}}{{((item.stateCode || item.cityName ) &&
                (item.countryName))? ', ' : ''}}{{item.countryName? item.countryName : ''}}
        </div>
        <div class="gray-text mb-1">{{dataService.formatDateMMMdd(item.checkinDate)}} - {{dataService.formatDateMMMdd(item.checkoutDate)}}
        </div>
        <p *ngIf="dataService.inactiveWorkspace" class="view-guest font-16"> {{item.guestFirstName}} {{item.guestLastName}}</p>
    </div>




<!--    <div *ngIf="item.rewardAmount && dataService.mobileView" style="height: 34px"></div>-->
<!--    <div class="reward-promo d-flex position-absolute" [ngClass]="{'inactive-rewards': dataService.inactiveWorkspace}" *ngIf="item.rewardAmount">-->
<!--        <div class="promo-triangle"></div>-->
<!--        <div class="promo-text text-white font-12 px-3 pt-2">{{item.convertedRewardCurrencyCode}} {{item.rewardAmount  |  number : '1.0-0'}} <ng-container i18n="@@inRewards">in Rewards</ng-container></div>-->
<!--    </div>-->

</div>
