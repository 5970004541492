import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PaymentValidationService} from "../../../services/amazon-payment/payment-validation.service";
import {environment} from "../../../../environments/environment";
import {AmazonPaymentService} from "../../../services/amazon-payment/amazon-payment.service";
import {DataService} from "../../../services/data.service";
import {AmazonRequest} from "../../../shared/model/payment/amazon-request";
import {DomSanitizer} from "@angular/platform-browser";
import {PaymentCard} from "../../../shared/model/payment/payment-card";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {VariablesService} from "../../../services/vars.service";

@Component({
  selector: 'amazon-payment-form',
  templateUrl: './amazon-payment-form.component.html',
  styleUrls: ['./amazon-payment-form.component.scss']
})
export class AmazonPaymentFormComponent implements OnDestroy {
  @ViewChild('expiryYear', { static: true }) expiryYearInput!: ElementRef;
  cardDetails: PaymentCard;
  submitting = false;
  submitted: boolean = false;
  spaceMsg = '';
  data: any;
  companyInfoObj = JSON.parse(localStorage.getItem('companyInfo'));
  userInfoObj = JSON.parse(localStorage.getItem('userInfo'));
  cardInfoForm: FormGroup;
  validationMessage: string = '';
  signRequestObj: AmazonRequest;
  amazonSignResponse: any;
  amazonTokenizationRequest: AmazonRequest;
  cardNumber: string;
  cardHolderName: string;
  expiryMonth: string;
  expiryYear: string;
  cardSecurityCode: string;
  cardAlias: string;
  cardNumberSubscription: Subscription;
  signAmazonSubscription: Subscription;

  constructor(
      private sanitizer: DomSanitizer,
      private fb: FormBuilder,
      private paymentValidationService: PaymentValidationService,
      private amazonService: AmazonPaymentService,
      public dataService: DataService,
      public router: Router,
      public vars: VariablesService
  ) {
    this.signRequestObj = JSON.parse(JSON.stringify(environment.amazonPaymentServiceRequest));
    this.cardInfoForm = this.fb.group({
      cardNumber: ['', [Validators.required]],
      cardHolderName: ['', [Validators.required]],
      expiryMonth: ['', [Validators.required]],
      expiryYear: ['', [Validators.required]],
      cardSecurityCode: ['', [Validators.required]],
      cardAlias: [''],
    });

    // Listen for changes in the card number input field
    this.cardNumberSubscription = this.cardInfoForm.get('cardNumber')?.valueChanges.subscribe((cardNumber: string) => {
      if (cardNumber.length >= 4) {
        this.onCardInput(cardNumber);
      }
    });
  }

  get formControls() {
    return this.cardInfoForm.controls;
  }

  amazonSignCall(requestBody: any): void {
    this.signAmazonSubscription = this.amazonService.amazonSign(requestBody).subscribe(
        response => {
          this.data = response;
          this.amazonSignResponse = this.data.data;
          if (this.data.errorCode === 0) {
            // You can now send the payment data to the payment gateway
            this.amazonTokenizationRequest = {...this.amazonSignResponse};
            this.amazonTokenizationRequest.card_holder_name = this.cardHolderName;
            this.amazonTokenizationRequest.card_number = this.cardNumber;
            this.amazonTokenizationRequest.expiry_date = this.expiryYear + this.expiryMonth;
            this.amazonTokenizationRequest.card_security_code = this.cardSecurityCode;
            this.amazonService.submitTokenizationRequest(this.amazonTokenizationRequest);
          } else {
            this.dataService.messages(this.amazonSignResponse.message, 'error');
          }
        }, error => {
          this.dataService.messages(environment.messages.error, 'error');
        }
    );
  }

  onSubmit() {
    this.submitted = true;
    this.submitting = true;
    this.validationMessage = '';
    if (this.cardInfoForm.valid) {
      this.cardNumber = this.cardInfoForm.get('cardNumber')?.value;
      this.cardHolderName = this.cardInfoForm.get('cardHolderName')?.value;
      this.expiryMonth = this.cardInfoForm.get('expiryMonth')?.value;
      this.expiryYear = this.cardInfoForm.get('expiryYear')?.value;
      this.cardSecurityCode = this.cardInfoForm.get('cardSecurityCode')?.value;
      this.cardAlias = this.cardInfoForm.get('cardAlias')?.value !== ''
          ? this.cardInfoForm.get('cardAlias')?.value
          : null;

      // Validate the inputs
      const validateCard = this.paymentValidationService.validateCard(this.cardNumber);
      const validateHolderName = this.paymentValidationService.validateHolderName(this.cardHolderName);
      const validateExpiry = this.paymentValidationService.validateCardExpiry(this.cardInfoForm.get('expiryMonth')?.value, this.cardInfoForm.get('expiryYear')?.value);
      const validateCardCVV = this.paymentValidationService.validateCVV(this.cardSecurityCode, validateCard.cardType);

      // Check for validation errors
      if (!validateHolderName.validity) {
        this.submitted = false;
        this.submitting = false;
        this.validationMessage = validateHolderName.msg;
      } else if (!validateCard.validity) {
        this.submitted = false;
        this.submitting = false;
        this.validationMessage = validateCard.msg;
      } else if (!validateExpiry.validity) {
        this.submitted = false;
        this.submitting = false;
        this.validationMessage = validateExpiry.msg;
      } else if (!validateCardCVV.validity) {
        this.submitted = false;
        this.submitting = false;
        this.validationMessage = validateCardCVV.msg;
      } else {
        this.signRequestObj.merchant_extra = this.userInfoObj.id;
        this.signRequestObj.merchant_extra1 = this.cardAlias;
        this.signRequestObj.merchant_extra2 = this.companyInfoObj.id;
        if(this.router.url.includes('user/payment')){
          console.log('user return');

          this.signRequestObj.return_url = environment.apiBase + this.vars.amazonUserCardsReturnUrl;
          console.log('this.signRequestObj.return_url' + this.signRequestObj.return_url);
        }
        // Check to see if return_url is being set correctly
        console.log('Return URL:', this.signRequestObj.return_url);
        this.amazonSignCall(this.signRequestObj);
      }
    } else {
      this.submitting = false;
      return;
    }
  }

  close(){
    this.cardInfoForm.reset();
    this.dataService.modalOf();
    this.dataService.BottomSheetOf();
  }

  onCardInput(cardNumber: string) {
    this.cardDetails = this.paymentValidationService.validateCard(cardNumber);
  }

  get isCardNumberLongEnough(): boolean {
    const cardNumber = this.cardInfoForm.get('cardNumber')?.value;
    return cardNumber && cardNumber.length >= 4;
  }

  preventSpace(event: KeyboardEvent) {
    if (event.code === 'Space' || event.keyCode === 32) {
      this.spaceMsg = $localize`:@@spaceNotPermitted:Space character is not permitted.`;
      event.preventDefault();
    } else {
      this.spaceMsg = '';
    }
  }

  // Triggered when the user inputs a value into the expiry month field
  onMonthInput(): void {
    const expiryMonthControl = this.cardInfoForm.get('expiryMonth');

    // When the expiry month is filled (2 digits), move focus to the expiry year field
    if (expiryMonthControl?.value.length === 2) {
      this.expiryYearInput.nativeElement.focus();
    }
  }
  numbersOnly(event: any) {
    const inputValue = event.target.value;
    // Replace any non-numeric characters
    event.target.value = inputValue.replace(/[^0-9]/g, '');
  }

  lettersOnly(event: any) {
    const inputValue = event.target.value;
    // Replace anything that is not a letter or space
    event.target.value = inputValue.replace(/[^A-Za-z\s]/g, '');
  }
  ngOnDestroy() {
    if (this.cardNumberSubscription) {
      this.cardNumberSubscription.unsubscribe();
    }

    if (this.signAmazonSubscription) {
      this.signAmazonSubscription.unsubscribe();
    }
  }
}
