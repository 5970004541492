import {Component, EventEmitter, HostBinding, HostListener, OnInit, Output} from '@angular/core';
import {DataService} from '../../services/data.service';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../auth/auth.service';

@Component({
  selector: 'app-upload-company-logo',
  templateUrl: './upload-company-logo.component.html',
  styleUrls: ['./upload-company-logo.component.scss']
})
export class UploadCompanyLogoComponent {
  files: any[] = [];
  imageURL;
  message = '';
  submitting = false;
  image = new FormData();
  data;
  errorMsg;
  constructor( public dataService: DataService, public router: Router, public auth: AuthServiceMain) { }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }
  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported';
      return;
    } else {
      if (mimeType === 'image/webp') {
        this.message = 'Image format are not supported';
        return;
      }
      if(this.image.get('logo')) {
        this.image.delete('logo');
      }
      this.image.append('logo', files[0]) ;
      this.message = '';
    }
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = ($event) => {
      this.imageURL = reader.result;
    };
  }
  uploadLogo() {
    this.errorMsg = '';
    this.submitting = true;
    this.dataService.updateCompanyLogo(this.image).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.router.navigate(['/']);
          this.submitting = false;
          this.auth.fetchCompanyData();
        } else {
          this.submitting = false;
          this.errorMsg = this.data.message;
         // this.dataService.messages(this.data.message, 'error');
        }
      }, error => {
        this.submitting = false;
        this.errorMsg = environment.messages.error;
       // this.dataService.messages(environment.messages.error, 'error');
      }
    );
  }
}
