<div class="container pt-4 pb-lg-5">
  <form [formGroup]="memberInfoForm" id="memberInfo">

    <div class="row gutters-10">
      <div class="col-12">
        <p class="gray-text mb-1" i18n="@@email">Email</p>
        <div class="form-group mt-2 with-icon position-relative form-type-textfield mb-4">
          <input class="form-control" placeholder="{{this.auth.userInfo.email}}" disabled />
        </div>
      </div>
      <div class="col-12">
        <p class="gray-text pb-1 mb-2" i18n="@@name">Name</p>
      </div>
      <div class="col-6">
        <input class="form-control" placeholder="{{this.auth.userInfo.name}}" disabled />
      </div>
      <div class="col-6">
        <input class="form-control" placeholder="{{this.auth.userInfo.lastName}}" disabled />
      </div>
      <div class="col-12">
        <p class="gray-text pb-1 mt-2" i18n="@@nameNote">To change your name, please contact the system administrator.
        </p>
      </div>


      <div class="col-12 mt-4" *ngIf="dataService.desktopView">
        <p class="gray-text mb-1" i18n="@@contactNumber">Contact number</p>
      </div>
      <div class="col-md-6">
        <p class="gray-primaray-text mb-1 mt-3" *ngIf="dataService.mobileView" i18n="@@countryCode">Country code</p>
        <div ngbDropdown class="form-group">
          <select class="form-control mt-2 select-custom-arrow" formControlName="phoneNumberCountryCode"
            name="phoneNumberCountryCode" (change)="selectChangeHandler($event)"
            [ngClass]="{ 'is-invalid': (submitted && !selectedValue && con.value !== '' &&  con.value !== null)}">

            <ng-container *ngFor="let country of vars.countries; let i = index">
              <option
                *ngIf="this.auth.userInfo.phoneNumberCountryCode && (country.dial_code === '+' + this.auth.userInfo.phoneNumberCountryCode || country.code === this.auth.userInfo.phoneNumberCountryCode)"
                [value]="returnPhoneCountryCode()" [selected]="true"><span
                  [ngClass]="{'pr-4': dataService.isSiteLtr(), 'pl-4': dataService.isSiteRtl()}">{{country.name}}</span>
                {{country.dial_code}} </option>
              <option
                *ngIf="!this.auth.userInfo.phoneNumberCountryCode || (country.dial_code !==  '+' + this.auth.userInfo.phoneNumberCountryCode && country.code !==  this.auth.userInfo.phoneNumberCountryCode)"
                [value]="country.code"><span
                  [ngClass]="{'pr-4': dataService.isSiteLtr(), 'pl-4': dataService.isSiteRtl()}">{{country.name}}</span>
                {{country.dial_code}}</option>
            </ng-container>
          </select>

        </div>
        <p class="text-danger font-12" *ngIf="submitted && !selectedValue && con.value !== '' &&  con.value !== null"
          i18n="@@selectCountryCode">Please select your country code </p>
      </div>

      <div class="col-md-6">
        <p class="gray-primaray-text mb-1 mt-3" *ngIf="dataService.mobileView" i18n="@@phoneNumber">Phone number</p>
        <div class="form-group">
          <input #con class="form-control mt-2" formControlName="contNum"
            pattern="[[\+]{0,1}(\d{0,20}|[\(][\+]{0,1}\d{2,}[\20)]*\d{5,20}|\d{2,6}[\-]{1}\d{2,20}[\-]*\d{3,20})]*"
            name="contNum"
            [ngClass]="{ 'is-invalid': (submitted && selectedValue && (con.value === null || con.value === '') || (submitted && InvalidPhoneNumber))}" />
        </div>
        <p class="text-danger smaller" *ngIf="submitted && selectedValue && (con.value === null || con.value === '')"
          i18n="@@enterPhoneNumber">Please enter your phone number</p>
        <p class="text-danger smaller" *ngIf="submitted && InvalidPhoneNumber" i18n="@@enterPhoneNumber">Invalid phone
          number</p>
      </div>

    </div>

    <div class="row">
      <div class="col-md-6">
        <p class="mb-1 mt-3"
          [ngClass]="{'gray-text': dataService.desktopView, 'gray-primaray-text': dataService.mobileView}"
          i18n="@@gender">Gender</p>
        <div class="form-group">
          <select class="form-control mt-2 select-custom-arrow" formControlName="gender" name="gender"
            (change)="selectChangeHandlerGender($event)">
            <ng-container *ngFor="let item of genderArray">
              <option *ngIf="item.id === this.auth.userInfo.gender" [value]="this.auth.userInfo.gender"
                [selected]="true">{{item.name}}</option>
              <option *ngIf="item.id !== this.auth.userInfo.gender" [value]="item.id">{{item.name}}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <p class="mb-1 mt-3"
          [ngClass]="{'gray-text': dataService.desktopView, 'gray-primaray-text': dataService.mobileView}" i18n="@@dob">
          Date of birth</p>
        <div class="form-group">
          <div class="form-control position-relative">
            <input formControlName="dob" matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate"
              autocomplete="off" class="form-control pointer" (click)="picker.open();"
              (dateChange)="dobDate('change', $event)" style="opacity: 0">
            <span class="position-absolute format-date" (click)="picker.open();">{{formatDOB}}</span>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col-md-6 order-lg-1"
        [ngClass]="{'pl-lg-2': dataService.isSiteLtr(), 'pr-lg-2': dataService.isSiteRtl()}">
        <button class="mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0" (click)="updateInfo()">
          <span class="mx-auto font-14" [ngClass]="{'m-progress': submitting}" i18n="@@save">Save</span>
        </button>
      </div>
      <div class="col-md-6 order-lg-0"
        [ngClass]="{'pr-lg-2': dataService.isSiteLtr(), 'pl-lg-2': dataService.isSiteRtl()}">
        <button (click)="dataService.rightSideDrawerOff(); dataService.BottomSheetOf()"
          class="mt-3 btn-block btn btn-outline-gray  px-5 d-flex ml-auto">
          <span class="mx-auto gray-text-dark font-14 sharp-font bold-500" i18n="@@cancelBtn">Cancel</span>
        </button>
      </div>
    </div>
    <p class="text-danger">{{this.errorMessage}}</p>
  </form>
</div>