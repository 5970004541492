import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {Title} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor(public router: Router, public restApi: DataService, private titleService:Title) {
    this.titleService.setTitle('SafarHub Privacy Policy | SafarHub');}
  env = environment;
  ngOnInit() {
    this.restApi.alerts = [];
  }

}
