import {ElementRef, Injectable, ViewChild} from '@angular/core';

import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {VariablesService} from "../vars.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class HotelsListService {
    constructor() {
    }


    getHotelPolicyToolTip(userDate: any): string {
        let policyViolation = '';

        if (userDate.outOfPolicyDescriptions) {
            policyViolation += userDate.outOfPolicyDescriptions[0] + "\n";
        }
        if (userDate.bookingWindowViolated) {
            policyViolation += $localize`:@@hotelBookingWindowViolated:The travel date exceeds the allowed advance booking window\n`;
        }
        if (userDate.starRatingViolated) {
            policyViolation += $localize`:@@maxStarViolated:The hotel stars is above the allowed hotel policy stars\n`;
        }
        if (userDate.medianPriceViolated) {
            policyViolation += $localize`:@@hotelMedianPriceViolated:The hotel price is higher than the allowed price for this destination\n`;
        }

        // Trim the trailing newline character, if present
        return policyViolation.trim();
    }
}
