import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {DataService} from "../../../../services/data.service";
import {Router} from "@angular/router";
import {AuthServiceMain} from "../../../../auth/auth.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-edit-user-payment',
  templateUrl: './edit-user-payment.component.html',
  styleUrls: ['./edit-user-payment.component.scss']
})
export class EditUserPaymentComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, public dataService: DataService, public router: Router, public auth: AuthServiceMain) { }
  editAliasForm: FormGroup;
  submitted = false;
  submitting = false;
  primary;
  errorMessage;
  data;
  paymentObject: {};
  isPrimary;
  ngOnInit(): void {

    this.editAliasForm = this.formBuilder.group({
      alias: [''],
      primary: [''],
    }, {});
    if (this.dataService.userPaymentObjForEdit.oldAlias) {
      this.editAliasForm.get('alias').setValue(this.dataService.userPaymentObjForEdit.oldAlias);
    }
  }
  get f() {
    return this.editAliasForm.controls;
  }
  submitForm() {
    this.submitting = true;
    this.paymentObject = {
      paymentGatewayProvider: this.dataService.userPaymentObjForEdit.paymentGatewayProvider,
      id: this.dataService.userPaymentObjForEdit.id,
      alias: this.editAliasForm.controls.alias.value,
      primary: this.isPrimary
    };
    this.dataService.updatePayment(this.paymentObject).subscribe(

      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.listPaymentService();
          this.dataService.modalOf();
          this.dataService.BottomSheetOf();
          this.submitting = false;
        } else {
          this.errorMessage = this.data.message;
          this.submitting = false;
        }
      },
      error => {
        this.errorMessage = environment.messages.error;
        this.submitting = false;
      }


    );
  }
}
