import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DataService} from "../../../services/data.service";
import {VariablesService} from "../../../services/vars.service";
import {AuthServiceMain} from "../../../auth/auth.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.scss']
})
export class UserPasswordComponent implements OnInit {
  updateUserPassword: FormGroup;
  editPassword = false;
  submittingPassword = false;
  shortPassword = false;
  errorMsg = '';
  errorMsgAfterSubmit = '';
  dataPassword;
  submitted;
  constructor(private formBuilder: FormBuilder, public dataService: DataService,  public vars: VariablesService, public auth: AuthServiceMain) { }

  ngOnInit(): void {
    this.updateUserPassword = this.formBuilder.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, {});
  }
  get f() {return this.updateUserPassword.controls; }
  submitUserPasswordUpdate() {
    this.submittingPassword = true;
    this.submitted = true;

 /*   if (this.updateUserPassword.controls.currentPassword.value.length < 6 ||
        this.updateUserPassword.controls.newPassword.value.length < 6 ||
        this.updateUserPassword.controls.confirmPassword.value.length < 6) {
      this.errorMsg = $localize`:@@passwordChars:Password must have at least 6 characters`;
      return ;
    }*/
    if (this.updateUserPassword.controls.newPassword.value !==  this.updateUserPassword.controls.confirmPassword.value) {
      this.errorMsg = $localize`:@@noMatchPassword:New password and confirmation password do not match`;

      this.submittingPassword = false;
      return ;
    }
    if (this.updateUserPassword.invalid) {
      this.submittingPassword = false;
      return false;
    }
    this.errorMsg = '';
    this.errorMsgAfterSubmit = '';
    this.dataService.changePassword(this.updateUserPassword.controls.currentPassword.value,
        this.updateUserPassword.controls.newPassword.value).subscribe(
        data => {
          this.dataPassword = data;
          if (this.dataPassword.errorCode === 0) {
            this.dataService.rightSideDrawerOff();
            this.auth.logout();
            this.submittingPassword = false;
          } else {
            debugger
            this.submittingPassword = false;
            this.errorMsgAfterSubmit = this.dataPassword.message;
          //  this.dataService.alerts = [];
           // this.dataService.messages(this.dataPassword.message, 'error');
          //  this.dataService.scrollTop();
          }
        },
        error => {
          this.submittingPassword = false;
          this.dataService.alerts = [];
          this.dataService.messages(environment.messages.error, 'error');
          this.dataService.scrollTop();

        }
    );
  }
}
