import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import {DataService} from '../../services/data.service';
import {AuthServiceMain} from '../../auth/auth.service';
import * as moment from 'moment';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import {environment} from '../../../environments/environment';
declare let window: any;
@Component({
  selector: 'app-flight-voucher',
  templateUrl: './flight-voucher.component.html',
  styleUrls: ['./flight-voucher.component.scss']
})
export class FlightVoucherComponent implements OnInit {
  VoucherData;
  flightData;
  identifier;
  referenceNumber;
  summaryDeparture;
  summaryReturn;
  rules;
  data;
  noTicketNo;
  labels = [];
  constructor(@Inject(PLATFORM_ID) private platformId, public Service: DataService, public logedIn: AuthServiceMain, private titleService:Title, private route: ActivatedRoute, private router: Router) {
    this.titleService.setTitle('Booking Voucher | SafarHub');
  }

  ngOnInit() {
    this.Service.showPrint = false;
    this.Service.flightBookingSubmit = false;
    if (isPlatformBrowser(this.platformId)) {

      this.Service.bookingSubmit = false;


      this.Service.alerts = [];
      this.Service.alertsOverRoute = [];


      if (JSON.parse(sessionStorage.getItem('flightData'))) {
        this.flightData = JSON.parse(sessionStorage.getItem('flightData'));
      }

      if (sessionStorage.getItem('flightVoucher')) {
        this.VoucherData = JSON.parse(sessionStorage.getItem('flightVoucher'));
        if (this.VoucherData?.details?.data?.labels && Object.keys(this.VoucherData?.details?.data?.labels).length > 0) {
          this.labels = Object.values(this.VoucherData.details.data.labels).map((item) => (item as { name: string }).name);
          console.log(this.labels); // Output
        }

        if(this.VoucherData.details.data.tripDetails) {
          this.VoucherData.details.data.tripDetails.itineraryInfo.passengers.forEach((traveler) => {
            if(!traveler.eTicketNumber){
              this.noTicketNo = true;
            }
          });
        } else {
          this.noTicketNo = true;
        }


        this.Service.printData = JSON.stringify(this.VoucherData.details.data);


      } else {

        this.route.queryParams.subscribe(params => {
          let identifierVal = '';
          if (params.email) {
            identifierVal = params.email;
          } else {
            identifierVal = params.lastname;
          }

            this.router.navigate(['/flight/booking/details'], {queryParams: {ref: params.ref, identifier: identifierVal}});


        });

      }


      if (!sessionStorage.getItem('voucurevist')) {


       /* window.dataLayer.push({
          event: 'fireRemarketing',

          ecomm_prodid: this.hotelData.giataId,
          ecomm_pagetype: 'purchase',
          ecomm_totalvalue: this.VoucherData.data.sellingPrice,
          hrental_id: this.hotelData.giataId,
          hrental_pagetype: 'conversionintent'


        });
        sessionStorage.setItem('voucurevist', 'true')*/
      }

      sessionStorage.removeItem('flightData');
    }
  }

  print() {
    setTimeout(() => {
      window.print();
    }, 1000);


  }
  openSendBookingVoucherPopUp() {
      if (this.Service.desktopView) {
        this.Service.setModal( $localize`:@@shareVoucher:Email to another address`, 'companySendBookingVoucher', '');
      } else {
        this.Service.setBottomSheet('companySendBookingVoucher', '');
      }
  }
  shareByEmailMobile() {
      this.Service.setBottomSheet('companySendBookingVoucher', '');
  }
}
