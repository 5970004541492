
<div class="slack-install main-container border-0 pb-5" [ngClass]="{'gray-pass-bg' : dataService.desktopView}">
  <div class="container pb-5" [ngClass]="{'desktop-design text-center': dataService.desktopView, 'mb-5 pt-5 pt-lg-2': dataService.mobileView}">
    <div class="row">
      <div class="col-lg-6 pb-5 pb-lg-0 order-lg-1 extra-padding-top" >
        <div class="login-form workspace-registration-container px-lg-5 pt-5 corporate-box" [ngClass]="{'white-box position-relative' : dataService.desktopView,
        'mr-auto': dataService.desktopView && dataService.isSiteLtr(), 'ml-auto': dataService.desktopView && dataService.isSiteRtl(), 'mx-auto': dataService.mobileView}">
          <div class="text-center">
            <h4 class="font-24 pt-md-5 mb-3 bold-600 text-center" i18n="@@safarHubApp">
              SafarHub App
            </h4>
          </div>
             <p class="gray-text font-16 mb-5 text-center workspace-registration-container mx-auto pt-4" i18n="@@firstInstallSafarHub">First, install SafarHub app on your slack workspace </p>
          <div class="row" >
            <div class="col pb-5 text-center">
              <a *ngIf="(!aHref || aHref === '' || aHref === undefined || aHref === null) && !env.production" target="_blank" href="https://slack.com/oauth/v2/authorize?client_id=166283204837.1573177487333&scope=channels:join,chat:write,chat:write.customize,chat:write.public,commands,im:write,users:read,users:read.email,users:write&user_scope="><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
              <a *ngIf="(!aHref || aHref === '' || aHref === undefined || aHref === null) && env.production" target="_blank" href="https://slack.com/oauth/v2/authorize?client_id=166283204837.1816344364675&scope=chat:write,commands,im:write,users:read,users:read.email&user_scope="><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
              <div *ngIf="aHref && aHref !== ''" [innerHTML]="aHref | sanitizeHtml"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 d-lg-flex z-index-10">
        <div class="text-center" [ngClass]="{'text-lg-left': dataService.isSiteLtr(), 'text-lg-right': dataService.isSiteRtl()}">
          <div class="w-100 mb-2">
            <img width="120" height="38"  loading="lazy" src="assets/img/slack.png" />
          </div>
          <div class="w-100 mb-4">
            <p class="gray-text font-16" i18n="@@manageYourBusinessInSlack">Manage your business travel in slack</p>
          </div>
          <div class="w-100">
            <img width="350" height="430"  loading="lazy" class="initial-height img-mobile" src="assets/img/slack-business.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


