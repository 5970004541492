import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../services/data.service';
import { SidenavService} from '../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../workspace-dashboard/animations/animations';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../auth/auth.service';
@Component({
  selector: 'app-hotel-details',
  templateUrl: './hotel-details.component.html',
  styleUrls: ['./hotel-details.component.scss'],
  animations: [ onMainContentChange ]
})
export class HotelDetailsComponent implements OnInit {

  constructor(public auth: AuthServiceMain, public dataService: DataService, private sidenavService: SidenavService, public router: Router) {
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }

  ngOnInit(): void {
   this.dataService.removeBodyClass();
  }

}
