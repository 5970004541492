import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {VariablesService} from "../vars.service";
import {DataService} from "../data.service";
@Injectable({
    providedIn: 'root'
})
export class FlightBookingService {

    constructor(private http: HttpClient,
                private dataService: DataService,
                public VarsService: VariablesService) {
    }

    bookingFormFlight(dataObj, header) {
        let headers = new HttpHeaders();
        if (header !== "") {
            headers = headers.append("shareasale_sscid", header);
        }
        this.dataService.guestInfo = dataObj;
        if (this.dataService.needRequestApproval) {
            return this.http.post(
                `${environment.apiBase}${this.VarsService.apiUrls.requestFlightApproval}`,
                dataObj,
                { headers }
            );
        } else {
            return this.http.post(
                `${environment.apiBase}${this.VarsService.flightApis.companyAirBook}`,
                dataObj,
                { headers }
            );
        }
    }

    requiresAirApprovalCheck(transactionId) {
        return this.http.post(
            `${environment.apiBase}${this.VarsService.apiUrls.requiresAirApprovalCheck}?transactionId=` +
            encodeURIComponent(transactionId),
            ""
        );
    }
}











