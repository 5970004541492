<div
     [ngStyle]="{'direction' : dataService.siteDirection.toLowerCase()}"
     [ngClass]="{'flight-details': !this.isFLightInfo && !isVoucher}"
     *ngIf="details">
<div class="fixed-bar-header bg-white px-3" *ngIf="dataService.mobileView && !this.isFLightInfo && !isVoucher"
     [ngClass]="{'flight-list-page': this.router.url.includes('flights/availability') && !this.router.url.includes('flights/availability/booking')}">
  <div class="d-flex pt-3">
    <p class="font-18 bold-600 gray-primaray-text" i18n="@@flightDetails">Flight details</p>
    <i class="icon-close-light gray-text pointer pt-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}" (click)="this.dataService.rightSideDrawerOff()"></i>
  </div>
</div>

<div
     [ngClass]="{'table-header': dataService.desktopView && !this.isFLightInfo && !isVoucher,
     'flight-list-page': this.router.url.includes('flights/availability') && !this.router.url.includes('flights/availability/booking'),
     'flight-main-container': !this.isFLightInfo && !isVoucher
     }">
  <div class="mb-5 pb-4" *ngFor="let flight of itineraries ,  let x = index">
    <div class="d-flex" *ngIf="flight.searchSegmentSummary">
      <p class="font-20 primaray-text font-weight-bold" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" [innerHTML]="formatDate(flight.searchSegmentSummary.departureDate)"></p>
      <p class="pt-1"><span class="gray-text-dark font-16" *ngIf="dataService.desktopView"> {{flight.searchSegmentSummary.departureAirport.city}} <span class="text-lowercase" i18n="@@to">to</span> {{flight.searchSegmentSummary.arrivalAirport.city}}</span></p>
    </div>
     <div [ngClass]="{'bg-white rounded border shadow-sm p-4': dataService.desktopView}" >
       <ng-container *ngFor="let segment of flight.segments ,  let i = index">
         <div class="itinerary-fare-container" >
           <div class="itinerary-section">
             <div class="d-flex">
               <img width="26.5" height="27" loading="lazy" class="max-100 img-min-width" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"
                    [src]="segment.carrierLogo"  onError="this.src='/assets/img/airline.png'" />
               <div>
                 <div [ngClass]="{'d-flex' : dataService.desktopView}">
                   <p class="mb-0 font-16 gray-text-dark" [ngClass]="{'pr-lg-4': dataService.isSiteLtr(), 'pl-lg-4': dataService.isSiteRtl()}">{{segment.carrierName}} {{segment.carrierCode}} {{segment.number}}</p>
                   <p class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded flex-2" [ngClass]="{'my-1' : dataService.mobileView}" *ngIf="segment.departure.bus || segment.arrival.bus" i18n="@@noteBus">Note: This is bus service</p>
                   <p class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded flex-2" [ngClass]="{'my-1' : dataService.mobileView}" *ngIf="segment.departure.train || segment.arrival.train" i18n="@@noteTrain">Note: This is train service</p>
                 </div>

                 <p class="gray-text mb-0" *ngIf="segment.operating && (segment.carrierCode !== segment.operating.carrierCode)"><ng-container i18n="@@operatedBy">Operated by</ng-container> {{segment.carrierName}}</p>
                 <p  class="gray-text mb-0" [ngClass]="{'text-right': dataService.isSiteRtl()}">{{segment.cabinClassName}}</p>

               </div>
             </div>

             <div class="d-flex mt-3">
               <i class="icon-fly-from placeholder-text font-20" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="!segment.departure.bus && !segment.arrival.bus && !segment.departure.train && !segment.arrival.train"></i>
               <i class="icon-bus-station placeholder-text font-18" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="segment.departure.bus || segment.arrival.bus"></i>
               <i class="icon-train-station placeholder-text font-18" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="segment.departure.train || segment.arrival.train"></i>
               <p class="mb-0 gray-text-dark"><span [innerHTML]="this.dataService.formatDateTime12Hours(segment.departure.at)">10:15pm</span><ng-container *ngIf="dataService.desktopView"> - {{segment.departure.name}} ({{segment.departure.iataCode}})</ng-container><span *ngIf="segment.departure.terminal"> ,T {{segment.departure.terminal}}</span></p>
             </div>

             <div class="d-flex pb-2">
               <span class="border-right-square" [ngClass]="{'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"></span>
               <div>
                 <p class="extra-left-margin gray-text" [ngClass]="{'bus-margin': segment.departure.bus || segment.arrival.bus}" *ngIf="dataService.desktopView">{{segment.departure.city}}</p>
                 <p class="mb-4 gray-text-dark" [ngClass]="{'ml-4 pl-1': dataService.isSiteLtr(), 'mr-4 pr-1': dataService.isSiteRtl()}" *ngIf="dataService.mobileView">{{segment.departure.name}} ({{segment.departure.iataCode}})</p>
                 <p class="mb-4 font-12 gray-text" [ngClass]="{'ml-4 pl-1 text-left': dataService.isSiteLtr(), 'mr-4 pr-1 text-right': dataService.isSiteRtl()}"><ng-container i18n="@@duration">Duration:</ng-container>&nbsp; <span [innerHTML]="segment.duration | isoToMinutes : flightListService">2h 33m</span></p>
               </div>
             </div>

             <div class="d-flex">
               <i class="icon-fly-to placeholder-text font-18" [ngClass]="{'mr-2 pr-1': dataService.isSiteLtr(), 'ml-2 pl-1': dataService.isSiteRtl()}" *ngIf="!segment.departure.bus && !segment.arrival.bus && !segment.departure.train && !segment.arrival.train"></i>
               <i class="icon-bus-station placeholder-text font-18" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="segment.departure.bus || segment.arrival.bus"></i>
               <i class="icon-train-station placeholder-text font-18" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="segment.departure.train || segment.arrival.train"></i>
               <p class="mb-0 gray-text-dark"><span [innerHTML]="this.dataService.formatDateTime12Hours(segment.arrival.at)">10:15pm</span>
                 <span class="font-14 font-weight-normal" *ngIf="dataService.checkNextDay(segment.departure.at, segment.arrival.at) === 1" i18n="@@nextDay"> (Next day)</span>
                 <span class="font-14 font-weight-normal" *ngIf="dataService.checkNextDay(segment.departure.at, segment.arrival.at) > 1"> +{{dataService.checkNextDay(segment.departure.at, segment.arrival.at)}}</span>
                 <span class="font-14 font-weight-normal" *ngIf="dataService.checkPreviousDay(segment.departure.at, segment.arrival.at)" i18n="@@prevDay"> (Previous day)</span>
                 <ng-container *ngIf="dataService.desktopView"> - </ng-container><br *ngIf="dataService.mobileView"/>{{segment.arrival.name}} ({{segment.arrival.iataCode}}) <span *ngIf="segment.arrival.terminal"> ,T {{segment.arrival.terminal}}</span></p>
             </div>

             <div class="d-flex pb-2">
               <span [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}"></span>
               <div>
                 <p class="extra-left-margin gray-text" [ngClass]="{'bus-margin': segment.departure.bus || segment.arrival.bus}" *ngIf="dataService.desktopView">{{segment.arrival.city}}</p>
               </div>
             </div>


           </div>

           <div class="fare-section" *ngIf="this.isFLightInfo || isVoucher">
             <ng-container *ngIf="fares">
               <ng-container *ngFor="let segmentFare of fares">
                 <ng-container *ngIf="segmentFare.segmentId === segment.id">
                   <div class="d-flex w-100 justify-content-between">
                     <div>
                       <p *ngIf="segmentFare.brandedFare" class="primaray-text mb-0 bold-600">{{segmentFare.brandedFare}}</p>
                       <p *ngIf="!segmentFare.brandedFare" class="primaray-text mb-0 bold-600">{{segmentFare.cabin}} ({{segmentFare.class}})</p>
                       <p class="font-13 gray-text-dark">{{segmentFare.cabin}}</p>
                     </div>
                     <i class="icon-info-msg font-20 pointer"
                        (click)="showMoreInfo(segmentFare.amenities)"
                        *ngIf="isDevMode"></i>
                   </div>


                      <app-fares-details [fareDetailsBySegment]="segmentFare"></app-fares-details>
                 </ng-container>
               </ng-container>
             </ng-container>
           </div>


         </div>
         <div *ngIf="segment.stopDurationInMinutes" class="primaray-text font-16 mb-5 mt-4 "><i class="icon-wall-clock align-middle mr-2"></i> <span [innerHTML]="this.dataService.calDuration(segment.stopDurationInMinutes)">2h 33m</span>&nbsp; <ng-container i18n="@@layoverIn">layover in</ng-container> {{segment.arrival.city}}<span *ngIf="!segment.airportChangeDescription"> ({{segment.arrival.iataCode}})</span></div>
         <div class="position-relative py-4" *ngIf="segment.airportChangeDescription">
           <div class="position-absolute font-12 text-white orange-bg change-stop rounded px-2 px-lg-3">{{segment.airportChangeDescription}}</div>
         </div>
       </ng-container>


   </div>
  </div>


</div>

  <div class="d-flex justify-content-end py-5" [ngClass]="{'mobile-view-sticky-action': dataService.mobileView}" *ngIf="this.isFLightInfo">
    <button  class="green_btn btn-block max-width-225 mb-lg-5"
             (click)="bookingFlight()"
             i18n="@@ContinueToPayment">
      Continue to payment
    </button>
  </div>

</div>


<div *ngIf="detailsLoading | async" class="p-4 shimmer-container">
  <div class="shimmer-line"></div>
  <div class="shimmer-line"></div>
  <div class="shimmer-line"></div>
  <div class="shimmer-line"></div>
  <div class="shimmer-line"></div>
  <div class="shimmer-line"></div>
  <div class="shimmer-line"></div>
  <div class="shimmer-line"></div>
  <div class="shimmer-line short"></div>
</div>
