import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../services/data.service";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {AuthServiceMain} from "../../../auth/auth.service";
@Component({
  selector: 'app-remove-profile-image',
  templateUrl: './remove-profile-image.component.html',
  styleUrls: ['./remove-profile-image.component.scss']
})
export class RemoveProfileImageComponent implements OnInit {

  constructor(public dataService: DataService, public router: Router, public auth: AuthServiceMain) { }
  data;
  dataUser
  ngOnInit(): void {
  }
  removeUserProfileImage() {
    this.dataService.removeProfileImage().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.showDefaultUserImage = true;
          this.dataService.getUserInfo().subscribe(
            dataUser => {
              this.dataUser = dataUser;
              if (this.dataUser.errorCode === 0) {
                this.auth.userInfo.imageUrl = null;
                localStorage.setItem('userInfo', JSON.stringify(this.auth.userInfo));
                this.dataService.BottomSheetOf();
                this.dataService.modalOf();
                  this.dataService.fetchUserData.next();
              } else {
                this.dataService.messages(this.dataUser.message, 'error');
              }
            }, error => {
              this.dataService.messages(environment.messages.error, 'error');
            }
          );
          this.dataService.BottomSheetOf();
          this.dataService.modalOf();
        } else {
          this.dataService.messages(this.data.message, 'error');
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
      }
    );
  }
}
