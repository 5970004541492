import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../../../services/data.service";

@Component({
  selector: 'app-policy-details-section',
  templateUrl: './policy-details-section.component.html',
  styleUrls: ['./policy-details-section.component.scss']
})
export class PolicyDetailsSectionComponent implements OnInit {
  @Input() showPolicyDetails;
  @Input() policyDetails;
  @Input() maxNoOfTrips;
  @Input() maxNoInterval;
  @Input() applicablePolicySelectedClass;
  constructor(public Service: DataService) { }

  ngOnInit(): void {


  }

}
