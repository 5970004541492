
    <div class="policy-details position-relative mb-4 py-3 px-4 company-section-with-thick-border border"
         [ngClass]="{'rounded-right': Service.isSiteLtr(),
                     'rounded-left': Service.isSiteRtl() }"  *ngIf="policyDetails && showPolicyDetails">

        <i class="icon-close-light gray-text font-12 pointer position-absolute"
           [ngClass]="{'mr-4 ml-auto': Service.isSiteLtr(), 'ml-4 mr-auto': Service.isSiteRtl()}"
           (click)="showPolicyDetails = false"></i>
        <p class="font-18 bold-600" i18n="@@travelPolicy">Travel Policy</p>
        <p *ngIf="policyDetails.flightBookingNotAllowed === true" i18n="@@approvalAlwaysRequiredFlight">
            Approval is required for any flight booking.
        </p>
        <ng-container *ngIf="policyDetails.companyPolicyFlightRules as flightRules">
            <ng-container *ngIf="!policyDetails.flightBookingNotAllowed || policyDetails.flightBookingNotAllowed === false">
                <div class="d-block d-md-flex custom-gap">
                      <p class="mb-2">
                         <span i18n="@@fareClass">Fare class</span>: <span class="gray-text">{{applicablePolicySelectedClass}}</span>
                      </p>
                    <p class="mb-2">
                        <span i18n="@@bookingWindow">Booking Window</span>: <span *ngIf="flightRules.bookingWindowInDays; else noBookingWindowInDays" class="gray-text">{{flightRules.bookingWindowInDays}}
                        <span i18n="@@oneDayInAdvance" *ngIf="flightRules.bookingWindowInDays === 1">day in advance</span>
                        <span i18n="@@toDaysInAdvance" *ngIf="flightRules.bookingWindowInDays === 2">days in advance</span>
                        <span i18n="@@daysInAdvance" *ngIf="flightRules.bookingWindowInDays > 2">days in advance</span>
                    </span>
                        <ng-template #noBookingWindowInDays><span class="gray-text">-</span></ng-template>
                    </p>
                </div>


                  <div class="d-block d-md-flex custom-gap">
                      <p class="mb-2">
                          <span i18n="@@price">Price</span>: <span *ngIf="flightRules.medianPriceLimitPercentage; else noMedianPriceLimitPercentage" class="gray-text">
                          {{ Math.abs(flightRules.medianPriceLimitPercentage * 100) }}%
                            <span i18n="@@underMedian" *ngIf="flightRules.medianPriceLimitPercentage < 0">under median price</span>
                          <span i18n="@@aboveMedian" *ngIf="flightRules.medianPriceLimitPercentage > 0">above median price</span> <ng-container *ngIf="availabilityMedianPrice"> ({{availabilityMedianCurrency}} {{availabilityMedianPrice | number : '1.0-0'}})</ng-container>
                          </span>
                          <ng-template #noMedianPriceLimitPercentage><span class="gray-text">-</span></ng-template>
                      </p>


                      <ng-container  *ngIf="flightRules.domesticPriceLimitPerPassenger === flightRules.internationalPriceLimitPerPassenger; else domesticInternationalNotSame">

                            <p class="mb-2">
                                        <span class="gray-primaray-text" i18n="@@priceCap">Max price</span>:

                                        <ng-container *ngIf="flightRules.domesticPriceLimitPerPassenger; else noDomesticPrice">

                                          <span class="gray-text">{{flightRules.domesticPriceLimitPerPassengerCurrency}}
                                              {{flightRules.domesticPriceLimitPerPassenger | number : '1.0-0'}}
                                          </span>

                                        <ng-container *ngIf="flightRules.domesticPriceLimitPerPassengerConverted">
                                              <span class="gray-text">({{flightRules.domesticPriceLimitPerPassengerConvertedCurrency}}
                                            {{flightRules.domesticPriceLimitPerPassengerConverted | number : '1.0-0'}})</span>
                                        </ng-container>

                                        </ng-container>

                                        <ng-template #noDomesticPrice><span class="gray-text">-</span></ng-template>

                            </p>

                      </ng-container>

                      <ng-template #domesticInternationalNotSame>

                             <p class="mb-2"
                                [ngClass]="{'mr-4': Service.isSiteLtr(), 'ml-4': Service.isSiteRtl()}">
                                    <span class="gray-primaray-text" i18n="@@domPriceCap">Max price (Demotic)</span>:
                                    <span class="gray-text" *ngIf="flightRules.domesticPriceLimitPerPassenger; else noDomesticPrice">{{flightRules.domesticPriceLimitPerPassengerCurrency}} {{flightRules.domesticPriceLimitPerPassenger | number : '1.0-0'}}</span>
                                    <ng-template #noDomesticPrice><span class="gray-text">-</span></ng-template>

                                    <ng-container *ngIf="flightRules.domesticPriceLimitPerPassengerConverted">
                                    <span class="gray-text"> ({{flightRules.domesticPriceLimitPerPassengerConvertedCurrency}} {{flightRules.domesticPriceLimitPerPassengerConverted | number : '1.0-0'}})</span>
                                    </ng-container>

                              </p>

                          <p class="mb-2">
                              <span class="gray-primaray-text" i18n="@@interPriceCap">Max price (Int)</span>:
                              <span class="gray-text" *ngIf="flightRules.internationalPriceLimitPerPassengerCurrency; else noInternationalPrice">{{flightRules.internationalPriceLimitPerPassengerCurrency}} {{flightRules.internationalPriceLimitPerPassenger | number : '1.0-0'}}</span>
                              <ng-template #noInternationalPrice><span class="gray-text">-</span></ng-template>

                              <ng-container *ngIf="flightRules.internationalPriceLimitPerPassengerConverted">
                                  <span class="gray-text"> ({{flightRules.internationalPriceLimitPerPassengerConvertedCurrency}} {{flightRules.internationalPriceLimitPerPassengerConverted | number : '1.0-0'}})</span>
                              </ng-container>

                          </p>
                      </ng-template>
                  </div>

                <div class="d-block d-md-flex custom-gap">
                    <p class="mb-2">
                        <span i18n="@@maxNoOfTrips">Max number of trips</span>:
                        <span class="gray-text" *ngIf="maxNoOfTrips; else noMaxNoOfTrips">{{maxNoOfTrips}}/{{maxNoInterval}}</span>
                        <ng-template #noMaxNoOfTrips><span class="gray-text">-</span></ng-template>
                    </p>

                    <p class="mb-2">
                        <span class="gray-primaray-text" i18n="@@ApprovalRequiredOn">Approval required on: </span>
                        <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'NO_APPROVAL_REQUIRED'" i18n="@@noApproval">No approval</span>
                        <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'RULES_VIOLATION_APPROVAL_REQUIRED'" i18n="@@outOfPolicy">Out of policy</span>
                        <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'ALWAYS_APPROVAL_REQUIRED'" i18n="@@allTrips">All trips</span>
                    </p>
                </div>


            </ng-container>
        </ng-container>
        


    </div>


