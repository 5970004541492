import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../../services/data.service";
import {TripsService} from "../../services/trips.service";

@Component({
  selector: 'app-hotel-trip-item',
  templateUrl: './hotel-trip-item.component.html',
  styleUrls: ['./hotel-trip-item.component.scss']
})
export class HotelTripItemComponent implements OnInit {

  constructor(public dataService: DataService,
              public tripsService: TripsService) { }
  @Input() item;
  ngOnInit(): void {
  }

}
