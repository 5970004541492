import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {AuthServiceMain} from '../../auth/auth.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Meta, Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from '../../google-analytics.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss']
})
export class WorkspaceComponent implements OnInit {
  FeefoReviews;
  constructor(public router: Router, public Service: DataService, public Auth: AuthServiceMain, public googleAnalyticsService: GoogleAnalyticsService, private titleService: Title, private meta: Meta) {
    this.titleService.setTitle('SafarHub for Corporate Travel | SafarHub');
    this.meta.updateTag({ name: 'description', content: 'SafarHub is the best and the easiest way to save money and time for corporate travel. Manage and Book hotels, flights car rentals and more.' });

  }
  configHotelList = {
    direction: 'horizontal',
    loop: true,
    slidesPerView: 6,
    spaceBetween: 0,
    navigation: {
      nextEl: '.sldier-button-next-hotels',
      prevEl: '.sldier-button-prev-hotels',
    },
  };

  configClientsSays: SwiperConfigInterface = {
    direction: 'horizontal',
    loop: true,
    slidesPerView: 'auto',
    centeredSlides : true,
    spaceBetween: 120,
    observer: true,
    navigation: {
      nextEl: '.clients-sldier-button-next',
      prevEl: '.clients-sldier-button-prev',
    },
    pagination: {
      el: '.swiper-paginationx',
      dynamicBullets: true,

    },




  };
  configHotelListMobile = {
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,

    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
    },
  };


  hotelList = [
    {
      url: 'assets/img/hotelsList/logo-00.png',
      link: '#'
    },

    {
      url: 'assets/img/hotelsList/logo-01.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-02.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-03.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-04.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-05.png',
      link: '#'
    },

    {
      url: 'assets/img/hotelsList/logo-06.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-07.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-08.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-09.png',
      link: '#'
    },

    {
      url: 'assets/img/hotelsList/logo-10.png',
      link: '#'
    },

    {
      url: 'assets/img/hotelsList/logo-11.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-12.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-13.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-14.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-15.png',
      link: '#'
    },

    {
      url: 'assets/img/hotelsList/logo-16.png',
      link: '#'
    },

    {
      url: 'assets/img/hotelsList/logo-17.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-18.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-19.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-20.png',
      link: '#'
    },

    {
      url: 'assets/img/hotelsList/logo-21.png',
      link: '#'
    },

    {
      url: 'assets/img/hotelsList/logo-22.png',
      link: '#'
    },

    {
      url: 'assets/img/hotelsList/logo-23.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-24.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-25.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-26.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-27.png',
      link: '#'
    },
    {
      url: 'assets/img/hotelsList/logo-28.png',
      link: '#'
    },
  ];
  hotelListMobile = [
    {
      url: 'assets/img/hotelsList/mobile/1.png',
    },

    {
      url: 'assets/img/hotelsList/mobile/2.png',
    },
    {
      url: 'assets/img/hotelsList/mobile/3.png',
    },
    {
      url: 'assets/img/hotelsList/mobile/4.png',
    },
    {
      url: 'assets/img/hotelsList/mobile/5.png',
    }
  ];
  showFaq = '';

  FAQPrime = [
    {
      title: $localize`:@@Q1:What is SafarHub?`,
      desc: $localize`:@@A1:SafarHub provides an end-to-end Corporate Travel platform that helps companies reduce corporate travel costs by 25-35% in addition to improving employee productivity. `,
    },
    {
      title: $localize`:@@Q2:What features does SafarHub offer?`,
      desc: $localize`:@@A2:Apart from the booking engine, the solution provides a travel policy and approval engine, team and role management, automated expenses filing, travel spend reports & insight, employee rewards module, and more.`,
    }, {
      title: $localize`:@@Q3:What is the average saving companies will get by joining SafarHub?`,
      desc: $localize`:@@A3:By combining travel policy enforcement and our best travel rates, companies will be able to save 25-35% on corporate travel.`,
    }, {
      title: $localize`:@@Q4:Is there any limit on the company employees?`,
      desc: $localize`:@@A4:There are no minimum or maximum limits. Any small, midsize, or enterprise company can join the SafarHub platform in few minutes.`,
    }, {
      title: $localize`:@@Q5:What travel products and services offered by SafarHub?`,
      desc: $localize`:@@A5:Hotels and flights. SafarHub offers access to over 200,000 hotels worldwide and all major airlines including low cost carriers. SafarHub will be offering car rental and rails in the coming few months.`,
    }, {
      title: $localize`:@@Q6:Does SafarHub have any external integrations?`,
      desc: $localize`:@@A6:Absolutely. SafarHub seamlessly integrates with expenses management system Expensify for expense automation. At the same time, users will be able to approve and manage business travel directly from slack.`,
    }, {
      title: $localize`:@@Q7:Can I offer rewards for my employee who saves on business travel?`,
      desc: $localize`:@@A7:Yes. Companies can optimally set a rewards program where employees get part of the savings when they book travel lower than the company travel policy. Employees will be able to redeem their rewards with digital gift cards such as Amazon.`,
    }, {
      title: $localize`:@@Q8:What is the pricing model for joining SafarHub?`,
      desc: $localize`:@@A8:Our model is simple, customers will get access to all features, no hidden or booking fees. Our pricing model is based on the number of members joining. Please check the pricing section above.`,
    }, {
      title: $localize`:@@Q9:Do I receive an immediate confirmation once I make a booking?`,
      desc: $localize`:@@A9:Yes, our booking engine confirms your reservation in a few seconds and sends you an email confirmation immediately.`,
    }

  ];
  ngOnInit() {
  }
  showFaqByID(index) {

    if(this.showFaq === 'Q'+index){
      this.showFaq = ''

    } else{
      this.showFaq = 'Q'+index;


    }



  }
  SendGAevent(name) {
   /* if(this.Auth.isLogedIn && !this.Auth.isNewsLetter){

      this.Service.emailNewsLetter(this.Auth.LeadEmail, '').subscribe();

    }


    this.googleAnalyticsService.eventEmitter('Free-Trial-Subscription', 'CTA', name, 1);
*/

  }
requestDemo() {
  if (this.Service.desktopView) {
    this.Service.setModal('Request Demo', 'requestDemo', '');
    if (this.Service.modalName === 'requestDemo') {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('modal-promo');
    }
  } else {
    this.Service.setBottomSheet('requestDemo', '');
  }
}
}
