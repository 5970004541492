<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div
        class="container mt-4 mt-lg-5 mb-5 extra-padding"
        *ngIf="this.approvalsService.approvalData"
      >
        <div class="row mx-lg-4">
          <div class="col">
            <div class="pb-2 pb-lg-5 mb-3">
              <a
                class="font-16 blue-text text-underline"
                (click)="
                  this.router.navigate([
                   '/user/requests'
                  ])
                "
                i18n="@@menuItemMyApprovals"
                >My Trip Approvals</a
              >
              <span class="font-16 gray-text-dark">
                /
                {{this.approvalsService.approvalData.pricingDetails.itineraries[0].searchSegmentSummary.arrivalAirport.city}}

              </span>
            </div>
            <p *ngIf="this.approvalsService.approvalData.status.toLowerCase() === 'approved'" class="bold-600 font-16 green-text" i18n="@@approved">Approved</p>
            <p *ngIf="this.approvalsService.approvalData.status.toLowerCase() === 'pending_approval'" class="bold-600 font-16 yellow-text mb-0" i18n="@@pendingApproval">Pending Approval&nbsp;</p>
            <p *ngIf="dataService.desktopView && this.approvalsService.approvalData.status.toLowerCase() === 'pending_approval'">
              <span class="font-14 blue-text pointer font-weight-normal mb-0" (click)="withdrawApprovalRequestPopup(this.approvalsService.approvalData.id)" i18n="@@withdraw">Withdraw</span>
            </p>
            <p *ngIf="this.approvalsService.approvalData.status.toLowerCase() === 'disapproved'" class="bold-600 font-16 text-danger mb-0" i18n="@@declined">Declined</p>
            <div *ngIf="this.approvalsService.approvalData.status.toLowerCase() === 'disapproved'">
              <ng-container *ngFor="let item of this.approvalsService.approvalData.operations">
                <p class="font-12 gray-text">{{ item.reason }}</p>
              </ng-container>
            </div>
            <p *ngIf="this.approvalsService.approvalData.status.toLowerCase() === 'expired'" class="bold-600 font-16 gray-text" i18n="@@expired">Expired</p>
            <p *ngIf="this.approvalsService.approvalData.status.toLowerCase() === 'withdrawn'" class="bold-600 font-16 gray-text" i18n="@@withdrawn">Withdrawn</p>

          </div>
        </div>
        <div class="row mx-lg-4">
          <div class="col-lg-8 custom-col8 pt-3">
            <app-approval-info [isRequester]="true"></app-approval-info>



            <div class="mt-4" *ngIf="dataService.desktopView">
              <app-request-operations></app-request-operations>
              <form
                class="my-5"
                [formGroup]="requestMoreInfoForm"
                *ngIf="
                  this.approvalsService.approvalData.status.toLowerCase() ===
                  'pending_approval'
                "
              >
                <textarea
                  name="moreComment"
                  id="moreComment"
                  formControlName="moreComment"
                  class="form-control pt-3 mt-5"
                  i18n-placeholder="@@typeMsg"
                  placeholder="Type your message here"
                  rows="6"
                  [ngClass]="{
                    'is-invalid': submitted && c.moreComment.errors
                  }"
                ></textarea>
                <div
                  *ngIf="submitted && c.moreComment.errors"
                  class="text-danger smaller mt-2"
                >
                  <div
                    *ngIf="c.moreComment.errors.required"
                    i18n="@@commentRequired"
                  >
                    Comment is required
                  </div>
                </div>
                <div class="d-flex" *ngIf="dataService.desktopView">
                  <div
                    class="actions"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                  >
                    <button
                      class="mt-5 btn-block blue_btn sharp-font bold-500 font-14 px-4 text-center"
                      (click)="requestSubmitMoreInfo()"
                      [ngClass]="{ 'm-progress': submitting }"
                      i18n="@@send"
                    >
                      Send
                    </button>
                  </div>
                </div>
                <div
                  *ngIf="dataService.mobileView"
                  [ngClass]="{
                    'gray-text': submitting,
                    'blue-text': !submitting,
                    'text-right': dataService.isSiteLtr(),
                    'text-right': dataService.isSiteRtl()
                  }"
                  class="font-16 mt-3 font-weight-bold"
                  (click)="requestSubmitMoreInfo()"
                  i18n="@@send"
                >
                  Send
                </div>
              </form>
            </div>
          </div>
          <!---------------->
          <div
            class="col-md-4 custom-col4 hotel-details-side pt-3"
            [ngClass]="{ 'px-0': dataService.mobileView }"
            *ngIf="this.approvalsService.approvalData"
          >
              <app-flight-summary [flightSummary]="this.approvalsService.approvalData.pricingDetails"></app-flight-summary>
              <app-flight-pricing [pricing]="this.approvalsService.approvalData.pricingDetails" [isApprovals]="true"></app-flight-pricing>
              <app-flight-rewards [rewardAmount]="approvalsService.approvalData.pricingDetails.travelerPricings[0].userData.convertedRewardAmount"
                                  [rewardCurrency]="approvalsService.approvalData.pricingDetails.travelerPricings[0].userData.convertedRewardCurrencyCode">
              </app-flight-rewards>
            <app-cost-reporting-details
              [costCentersIds]="
                approvalsService.approvalData?.costCenter
                  ? [approvalsService.approvalData?.costCenter]
                  : ''
              "
              [labelsIds]="
                approvalsService.approvalData?.labels
                  ? approvalsService.approvalData?.labels
                  : []">
            </app-cost-reporting-details>
          </div>
          <!---------------->
          <div class="col-lg-8">
            <ng-container *ngIf="dataService.mobileView">
              <app-request-operations></app-request-operations>
              <form
                class="my-5"
                [formGroup]="requestMoreInfoForm"
                *ngIf="
                  this.approvalsService.approvalData.status.toLowerCase() ===
                  'pending_approval'
                "
              >
                <textarea
                  name="moreComment"
                  id="moreComment"
                  formControlName="moreComment"
                  class="form-control pt-3 mt-5"
                  i18n-placeholder="@@typeMsg"
                  placeholder="Type your message here"
                  rows="6"
                  [ngClass]="{
                    'is-invalid': submitted && c.moreComment.errors
                  }"
                ></textarea>
                <div
                  *ngIf="submitted && c.moreComment.errors"
                  class="text-danger smaller mt-2"
                >
                  <div
                    *ngIf="c.moreComment.errors.required"
                    i18n="@@commentRequired"
                  >
                    Comment is required
                  </div>
                </div>
                <div class="d-flex" *ngIf="dataService.desktopView">
                  <div
                    class="actions"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                  >
                    <button
                      class="mt-5 btn-block blue_btn sharp-font bold-500 font-14 px-4 text-center"
                      (click)="requestSubmitMoreInfo()"
                      [ngClass]="{ 'm-progress': submitting }"
                      i18n="@@send"
                    >
                      Send
                    </button>
                  </div>
                </div>
                <button
                  *ngIf="dataService.mobileView"
                  class="mt-5 btn-block blue_btn sharp-font bold-500 font-14 px-4 text-center"
                  [ngClass]="{ 'm-progress': submitting }"
                  (click)="requestSubmitMoreInfo()"
                >
                  Send
                </button>
                <button
                  class="mt-5 btn-block btn btn-outline-blue sharp-font bold-500 font-14 px-4 text-center"
                  (click)="
                    withdrawApprovalRequestPopup(
                      this.approvalsService.approvalData.id
                    )
                  "
                  i18n="@@withdraw"
                >
                  Withdraw
                </button>
              </form>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
