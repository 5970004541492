import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-loaders',
  templateUrl: './loaders.component.html',
  styleUrls: ['./loaders.component.scss']
})


export class LoadersComponent implements OnInit {
  constructor(public loaders: DataService, public router: Router) { }
  @Input() loaderName: string;
  ngOnInit() {

  }

}
