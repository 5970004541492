<div [ngClass]="{'container pb-5 pt-4': dataService.mobileView}">
  <ng-container *ngIf="!dataService.showNoPaymentMsg">
    <p class="gray-text-dark font-16 mb-0"><ng-container i18n="@@membersToActivate">Members to be activated:</ng-container> <span *ngIf="this.activeArray">{{this.activeArray.length}}</span>
    </p>
<p class="gray-text-dark font-16" i18n="@@billingCycle">Billing cycle:</p>

<div class="row justify-content-center font-16" *ngIf="plans">
  <div class="checkbox-radio-item-container col-6 col-md form-group px-4 px-lg-2" *ngFor="let plan of plans, let i = index">
    <input class="d-none" type="radio" id="{{i}}" name="plan"
           (click)="reactivateFees(plan.planId); selected = true"/>
    <label class="white-box border w-100 d-flex justify-content-center align-items-center mb-0" for="{{i}}">
      <div class="text-center">
        <span class="d-block font-weight-bold font-16 mb-2">{{plan.name}}</span>
        <span class="d-block font-12" *ngIf="plan.tier.flatAmount && plan.tier.upTo">${{plan.tier.flatAmount}} <ng-container i18n="@@upToMembers">(up to {{plan.tier.upTo}} members)</ng-container> </span>
        <span class="d-block font-12" *ngIf="plan.tier.unitAmount">${{plan.tier.unitAmount}}/<ng-container i18n="@@member">member</ng-container></span>
      </div>
    </label>
  </div>
</div>





<p class="gray-text-dark font-16 mt-5"><ng-container i18n="@@activateSubCharge">By activating your subscription, you will be charged</ng-container> <ng-container *ngIf="dataService.isSiteLtr()">$</ng-container>{{this.fees | number: '1.0-2'}}<ng-container *ngIf="dataService.isSiteRtl()">$</ng-container>.</p>

<div class="d-flex justify-content-end" *ngIf="dataService.desktopView">
  <button class="btn-outline-gray btn px-5 font-14" (click)="this.dataService.modalOf(); " i18n="@@cancelBtn"> Cancel </button>
  <button class="blue_btn px-5" [ngClass]="{'m-progress': submitting, 'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"
          (click)="confirm()" [disabled]="selected === false" i18n="@@confirmBtn">
    Confirm
  </button>
</div>
<div *ngIf="dataService.mobileView">
  <button class="blue_btn px-5 w-100 mb-3" [ngClass]="{'m-progress': submitting }"
          (click)="confirm()" [disabled]="selected === false" i18n="@@confirmBtn">
    Confirm
  </button>
  <button class="btn-outline-gray btn px-5 font-14 w-100" (click)=" this.dataService.BottomSheetOf();" i18n="@@cancelBtn"> Cancel </button>
</div>
  </ng-container>
  <ng-container *ngIf="dataService.showNoPaymentMsg">
    <p class="gray-text-dark font-16 mb-5" i18n="@@dontHavePayment">You do not have a any payment method to activate your account</p>
    <div class="d-flex justify-content-end" *ngIf="dataService.desktopView">
      <button class="btn-outline-gray btn px-5 font-14" (click)="this.dataService.modalOf(); " i18n="@@cancelBtn"> Cancel </button>
      <button class="blue_btn px-5" [ngClass]="{'m-progress': submitting, 'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"
              (click)="addNewPayment()" i18n="@@addPaymentMethod">
        Add Payment Method
      </button>
    </div>
    <div *ngIf="dataService.mobileView">
      <button class="blue_btn px-5 w-100 mb-3" [ngClass]="{'m-progress': submitting }"
              (click)="addNewPayment()" i18n="@@addPaymentMethod">
        Add Payment Method
      </button>
      <button class="btn-outline-gray btn px-5 font-14 w-100" (click)=" this.dataService.BottomSheetOf();" i18n="@@cancelBtn"> Cancel </button>
    </div>
  </ng-container>
  <div class="text-danger pt-3"  [ngClass]="{ 'text-right': dataService.desktopView}">{{this.errorMsg}}</div>
</div>
