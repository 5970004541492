<div class="border rounded px-lg-4 pt-4">
    <div
            class="px-4 px-lg-0"
            [ngClass]="{ 'd-flex': dataService.desktopView }"
    >
        <div
                class="d-flex"
                [ngClass]="{
                    'mr-5 pr-4':
                      dataService.desktopView && dataService.isSiteLtr(),
                    'ml-5 pl-4':
                      dataService.desktopView && dataService.isSiteRtl()
                  }"
        >
            <div>
                <img
                        width="50"
                        height="50"
                        loading="lazy"
                        *ngIf="approvalsService.approvalData.requester.profileImageUrl"
                        class="user-img d-inline-block my-3"
                        [ngClass]="{ 'img-border': userService.imageHasError }"
                        [src]="userService.imageHasError ? userService.fallbackSrc : approvalsService.approvalData.requester.profileImageUrl"
                        (error)="userService.imageHasError = true"
                />
                <div
                        *ngIf="!approvalsService.approvalData.requester.profileImageUrl"
                        class="name-first-letters requester-img my-3"
                        [ngStyle]="{
                        'background-color':
                          approvalsService.approvalData
                            .requester.profileImageBackgroundHexColor
                      }"
                >
                    {{
                    approvalsService.approvalData.requester.firstName
                        .substring(0, 1)
                        .toUpperCase()
                    }}{{
                    approvalsService.approvalData.requester.lastName
                        .substring(0, 1)
                        .toUpperCase()
                    }}
                </div>
            </div>
            <div class="pt-4">
                <p
                        class="gray-primaray-text bold-600 font-16 mb-0"
                        [ngClass]="{
                        'ml-3': dataService.isSiteLtr(),
                        'mr-3': dataService.isSiteRtl(),
                        'mt-2': !approvalsService.approvalData.requester.teamName,
                        'dotted-text dotted-text-direction':
                          dataService.mobileView
                      }"
                >
                      <span class="pr-2">{{
                          this.approvalsService.approvalData.requester.firstName
                          }}</span
                      >{{ this.approvalsService.approvalData.requester.lastName }}
                </p>
                <p
                        *ngIf="this.approvalsService.approvalData.requester.teamName"
                        class="gray-text font-14"
                        [ngClass]="{
                        'ml-3': dataService.isSiteLtr(),
                        'mr-3': dataService.isSiteRtl()
                      }"
                >
                    {{ this.approvalsService.approvalData.requester.teamName }}
                </p>
            </div>
        </div>
    </div>
    <div class="gray-text mt-4 px-4 px-lg-0">
        <ng-container i18n="@@bookedFor">Booked for</ng-container>
        {{ approvalsService.approvalData.travelers[0].firstName }}
        {{ approvalsService.approvalData.travelers[0].lastName }}
    </div>

    <div class="price-container pb-4 gray-primaray-text font-16 mt-3">
        <div class="px-4 px-lg-0">
            <p class="bold-600 mb-2" i18n="@@tripSummary">Trip Summary</p>
            <p class="mb-1" [ngClass]="{ 'mr-0': dataService.isSiteLtr(), 'ml-0': dataService.isSiteRtl() }">
                <ng-container i18n="@@flight">Flight</ng-container>
                ({{this.approvalsService.approvalData.pricingDetails.itineraries[0].searchSegmentSummary.arrivalAirport.city}})
            </p>
            <p class="gray-text mb-1">{{ approvalsService.getTravelTypeLabel(approvalsService.approvalData.pricingDetails.itineraries) }}</p>
            <p class="mb-1 mt-4" [ngClass]="{ 'mr-0': dataService.isSiteLtr(), 'ml-0': dataService.isSiteRtl() }" i18n="@@totalPrice">
                Total Price
            </p>
            <p class="gray-text mb-1">
                {{approvalsService.approvalData.pricingDetails.sellingPriceConverted.currency}} {{ approvalsService.approvalData.pricingDetails.sellingPriceConverted.grandTotal | number : '1.0-2' }}
            </p>
        </div>
        <hr />
        <div class="px-4 px-lg-0">

            <ng-container *ngFor="let traveler of approvalsService.approvalData.travelers">
                <ng-container *ngIf="traveler.policy as policy">
                    <p class="bold-600 mt-4 mb-2">
                        <ng-container i18n="@@travelPolicy">Travel Policy</ng-container>&nbsp;
                        <span class="font-weight-normal font-14">({{ policy.name }})</span>
                    </p>
                    <ng-container *ngIf="!policy.flightBookingNotAllowed">
                        <p class="mb-1" [ngClass]="{ 'mr-0': dataService.isSiteLtr(), 'ml-0': dataService.isSiteRtl() }">
                            <i class="icon-check green-text font-12" *ngIf="!approvalsService.approvalData.pricingDetails.travelerPricings[0].userData.priceLimitPerPassengerViolated" [ngClass]="{ 'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl() }"></i>
                            <i class="icon-close-light text-danger font-12" *ngIf="approvalsService.approvalData.pricingDetails.travelerPricings[0].userData.priceLimitPerPassengerViolated" [ngClass]="{ 'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl() }"></i>
                            <span i18n="@@maxPrice" class="font-16 gray-text">Max price:</span>
                            <span class="gray-text mb-1 px-2" *ngIf="!approvalsService.approvalData.pricingDetails.domestic">
            {{policy.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency}} {{ policy.companyPolicyFlightRules.internationalPriceLimitPerPassenger }}
          </span>
                            <span class="gray-text mb-1 px-2" *ngIf="approvalsService.approvalData.pricingDetails.domestic">
            {{policy.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency}} {{ policy.companyPolicyFlightRules.domesticPriceLimitPerPassenger }}
          </span>
                        </p>
                        <p class="mb-1" [ngClass]="{ 'mr-0': dataService.isSiteLtr(), 'ml-0': dataService.isSiteRtl() }">
                            <i class="icon-check green-text font-12" *ngIf="!approvalsService.approvalData.pricingDetails.travelerPricings[0].userData.maximumCabinClassViolated" [ngClass]="{ 'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl() }"></i>
                            <i class="icon-close-light text-danger font-12" *ngIf="approvalsService.approvalData.pricingDetails.travelerPricings[0].userData.maximumCabinClassViolated" [ngClass]="{ 'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl() }"></i>
                            <span i18n="@@fareClass" class="font-16 gray-text">Fare class:</span>
                            <ng-container *ngFor="let item of vars.classType">
                                <span class="gray-text mb-1 px-2" *ngIf="item.value === policy.companyPolicyFlightRules.maximumCabinClass">{{ item.name }}</span>
                            </ng-container>
                        </p>

                        <p class="mb-1 gray-text" [ngClass]="{ 'mr-0': dataService.isSiteLtr(), 'ml-0': dataService.isSiteRtl() }"
                           *ngIf="approvalsService.approvalData.pricingDetails.travelerPricings[0].userData.maximumNumberOfTripsViolated">
                            <i class="icon-check green-text font-12" *ngIf="!approvalsService.approvalData.pricingDetails.travelerPricings[0].userData.maximumNumberOfTripsViolated" [ngClass]="{ 'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl() }"></i>
                            <i class="icon-close-light text-danger font-12" *ngIf="approvalsService.approvalData.pricingDetails.travelerPricings[0].userData.maximumNumberOfTripsViolated" [ngClass]="{ 'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl() }"></i>
                            <span i18n="@@maxNoOfTrips" class="font-16 gray-text">Max trip/</span>{{ dataService.getInterval(policy.companyPolicyFlightRules.maximumNumberOfTripsInterval).toLowerCase() }}:<span class="gray-text mb-1 px-2">{{ policy.companyPolicyFlightRules.maximumNumberOfTrips }}</span>
                        </p>
                    </ng-container>

                    <ng-container *ngIf="approvalsService.approvalData.pricingDetails.travelerPricings[0].userData.flightBookingNotAllowed">
                        <p class="mb-1" [ngClass]="{ 'mr-0': dataService.isSiteLtr(), 'ml-0': dataService.isSiteRtl() }">
                            <i class="icon-close-light text-danger font-12" *ngIf="approvalsService.approvalData.pricingDetails.travelerPricings[0].userData.flightBookingNotAllowed" [ngClass]="{ 'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl() }"></i>
                            <span i18n="@@hotelBookingAllowed" class="font-16 gray-text">Flight booking allowed</span>
                        </p>
                    </ng-container>

                </ng-container>
            </ng-container>
        </div>

        <ng-container *ngIf="isRequester">
            <hr />
            <div class="px-4 px-lg-0">
                <p class="bold-600 mb-2">
                    <ng-container i18n="@@approver">Approver</ng-container>
                </p>
                <div
                        class="px-lg-0"
                        [ngClass]="{ 'd-flex': dataService.desktopView }">
                    <p class="font-weight-normal font-16 gray-text">
                                  {{this.approvalsService.approvalData.approvers[0].firstName }}
                        {{ this.approvalsService.approvalData.approvers[0].lastName }}
                    </p>
                </div>
            </div>
        </ng-container>

    </div>

</div>

<div class="border rounded px-4 pt-4 mt-4">
    <p class="font-16 gray-primaray-text bold-600 mt-3" i18n="@@cancellationPolicies">Cancellation Policies</p>
    <div class="gray-text-dark font-14 mb-0" *ngIf="this.approvalsService.approvalData.pricingDetails.pricingOptions.refundableFare === true" i18n="@@refundTicket">Ticket is Refundable</div>
    <div class="non-refundable gray-text-dark mb-0" *ngIf="this.approvalsService.approvalData.pricingDetails.pricingOptions.refundableFare === false" i18n="@@nonRefundTicket">Ticket is Nonrefundable</div>
    <p class="gray-text-dark" i18n="@@flightCancellation">We do not charge any change or cancellation fee. When the airline charge for such fee based on their rules and polices, the cost will be passed on you.</p>
    <ng-container *ngIf="this.approvalsService.approvalData.status.toLowerCase() !== 'disapproved' && this.approvalsService.approvalData.status.toLowerCase() !== 'expired' && this.approvalsService.approvalData.status.toLowerCase() !== 'withdrawn'">
        <p class="font-16 gray-primaray-text bold-600 mt-5" i18n="@@paymentMethod">Payment Method</p>
        <p class="font-14 gray-text-dark" *ngIf="this.approvalsService.approvalData.status.toLowerCase() === 'pending_approval'">
            <span *ngIf="this.approvalsService.approvalData.payment.channelProvider === 'AMAZON_PAYMENT_SERVICE'">
                <ng-container i18n="@@cardEndsWith">Card ending with</ng-container>
                {{ this.approvalsService.approvalData.payment.lastFour }}
                <ng-container i18n="@@willCharged">will be charged with total of</ng-container>
                {{approvalsService.approvalData.pricingDetails.sellingPriceConverted.currency}} {{ approvalsService.approvalData.pricingDetails.sellingPriceConverted.grandTotal | number : '1.0-2' }}
            </span>
        </p>
        <p class="font-14 gray-text-dark" *ngIf="this.approvalsService.approvalData.status.toLowerCase() === 'approved'">
            <span *ngIf="this.approvalsService.approvalData.payment.channelProvider === 'AMAZON_PAYMENT_SERVICE'">
                {{approvalsService.approvalData.pricingDetails.sellingPriceConverted.currency}} {{ approvalsService.approvalData.pricingDetails.sellingPriceConverted.grandTotal | number : '1.0-2' }}
                <ng-container i18n="@@charged">charged to card ending with</ng-container>
                {{ this.approvalsService.approvalData.payment.lastFour }}
            </span>
        </p>
    </ng-container>
</div>
