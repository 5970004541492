import { Pipe, PipeTransform } from '@angular/core';
import {FlightListService} from "./flight-services/flight-list.service";

@Pipe({
    name: 'isoToMinutes',
})
export class IsoToMinutesPipe implements PipeTransform {
    transform(duration: string | null | undefined, flightListService: FlightListService): string {
        return duration ? flightListService.convertISOToMinutes(duration) : '';
    }
}
