<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content pb-5">
      <app-messages></app-messages>
      <div class="items-full-list policy-list container pt-4 pt-lg-5">
        <div class="mx-lg-5">
          <p
            class="mb-4 font-24 gray-primaray-text bold-600"
            i18n="@@menuItemPolicies"
          >
            Travel Policies
          </p>
          <ng-container
            *ngIf="
              dataService.companyPoliciesList &&
              dataService.companyPoliciesList.length > 0
            "
          >
            <div class="row">
              <div class="col-12 teams-link">
                <p class="gray-text-dark font-18" i18n="@@dashboardMsg8">
                  Create your corporate travel policies, set booking limits and
                  approvals.
                </p>
              </div>
            </div>
            <div
              class="my-5 pt-lg-5"
              [ngClass]="{ 'd-flex': dataService.desktopView }"
            >
              <div
                class="min-width-workspace-btn"
                [ngClass]="{
                  'mb-3': dataService.mobileView,
                  'mr-lg-3': dataService.isSiteLtr(),
                  'ml-lg-3': dataService.isSiteRtl()
                }"
              >
                <button
                  (click)="newPolicy()"
                  class="btn btn-outline-blue font-14 px-5 sharp-font bold-500"
                  [ngClass]="{ 'btn-block': dataService.desktopView }"
                  i18n="@@newPolicy"
                >
                  New Policy
                </button>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              (!dataService.companyPoliciesList ||
                !dataService.companyPoliciesList.length) &&
              !this.dataService.loadingFlag
            "
          >
            <div
              class="mt-5 company-section-with-thick-border border"
              [ngClass]="{
                'custom-container': dataService.desktopView,
                'rounded-right':
                  dataService.desktopView && dataService.isSiteLtr(),
                'rounded-left':
                  dataService.desktopView && dataService.isSiteRtl(),
                'thick-top rounded-bottom': dataService.mobileView
              }"
            >
              <div
                class="row mx-0 p-4 h-100 justify-content-center align-items-center"
              >
                <div class="col-md-8 font-16">
                  <p class="mb-0 gray-primaray-text" i18n="@@dashboardMsg8">
                    Create your corporate travel policies, set booking limits
                    and approvals.
                  </p>
                </div>
                <div
                  class="col-md-4"
                  [ngClass]="{
                    'text-right': dataService.isSiteLtr(),
                    'text-left': dataService.isSiteRtl()
                  }"
                >
                  <button
                    (click)="newPolicy()"
                    class="w-100 btn px-5 py-2 mt-4 mb-3 my-lg-2 rounded text-center sharp-font bold-500 ml-auto font-14 find-btn btn-primary"
                    i18n="@@newPolicy"
                  >
                    New Policy
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
          <div
            *ngIf="this.dataService.loadingFlag"
            class="loading_icon_caption text-center mt-5"
          ></div>
          <div
            *ngIf="this.dataService.loadingFlag"
            class="loading-message text-center"
            i18n="@@loading"
          >
            Loading
          </div>
          <div
            class="container"
            *ngIf="
              !this.dataService.loadingFlag &&
              dataService.companyPoliciesList &&
              dataService.companyPoliciesList.length > 0
            "
          >
            <div
              class="row px-3 py-3 gray-primaray-text bold-600 table-header rounded mb-3 row-max-width"
              *ngIf="dataService.desktopView"
            >
              <div class="col-5" i18n="@@policy">Policy</div>
              <div class="col-6" i18n="@@appliedTo">Applied to</div>
              <div class="col-1"></div>
            </div>
            <div
              class="row px-lg-3 gray-text-dark hover-row-list font-14 pointer row-max-width"
              [ngClass]="{
                'border-bottom': dataService.desktopView,
                'rounded border mb-4': dataService.mobileView
              }"
              *ngFor="
                let item of dataService.companyPoliciesList;
                let i = index
              "
              (click)="editPolicy(item.policy.policyId)"
            >
              <div
                class="col-lg-5"
                [ngClass]="{
                  'gray-primaray-text mb-2': dataService.mobileView
                }"
              >
                {{ item.policy.name }}
              </div>
              <div class="col-lg-6">
                <span
                  class="gray-text"
                  [ngClass]="{
                    'pr-2': dataService.isSiteLtr(),
                    'pl-2': dataService.isSiteRtl()
                  }"
                  *ngIf="dataService.mobileView"
                  ><ng-container i18n="@@team">Team</ng-container>:</span
                ><span
                  [ngClass]="{
                    'text-bg font-12 px-3 py-2': dataService.mobileView
                  }"
                  >{{ item.description }}</span
                >
              </div>
              <div class="col-lg-1">
                <div class="d-flex">
                  <div class="ml-auto actions">
                    <i
                      class="drop-down{{
                        i
                      }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                      (click)="
                        $event.stopPropagation();
                        showActions = !showActions;
                        selectedIndex = i
                      "
                    ></i>
                    <div
                      class="border shadow font-14 position-absolute bg-white drop"
                      *ngIf="showActions && i === selectedIndex"
                    >
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        (click)="editPolicy(item.policy.policyId)"
                        i18n="@@view"
                      >
                        View
                      </p>
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                        (click)="
                          $event.stopPropagation();
                          removePolicy(item.policy.policyId, item.policy.name)
                        "
                        i18n="@@deleteBtn"
                      >
                        Delete
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
