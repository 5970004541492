import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataService } from "src/app/services/data.service";
import { AuthServiceMain } from "src/app/auth/auth.service";
import { VariablesService } from "src/app/services/vars.service";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-update-user-preferences",
  templateUrl: "./update-user-preferences.component.html",
  styleUrls: ["./update-user-preferences.component.scss"],
})
export class UpdateUserPreferencesComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    public dataService: DataService,
    public vars: VariablesService,
    public auth: AuthServiceMain
  ) {}

  preferencesForm: FormGroup;
  data;
  userFormData = [];
  sendUserData;
  languagesList;
  currenciesList;
  currentPreferences;
  submitting;
  submitted;
  errorMessage;

  ngOnInit(): void {
    this.initCurrentPreferences();
    this.getPreferences();
    this.initForm();
  }

  getPreferences() {
    this.dataService.getPreferencesList().subscribe(
      (result) => {
        this.data = result;
        if (this.data.errorCode === 0) {
          this.initData();
        } else {
          this.handleError();
        }
      },
      () => {}
    );
  }

  initCurrentPreferences() {
    if (this.dataService.desktopView) {
      this.currentPreferences = this.dataService.rightSideDrawerData;
    } else {
      this.currentPreferences = this.dataService.BottomSheetData;
    }
  }

  initForm() {
    this.preferencesForm = this.formBuilder.group({
      selectedLanguage: [
        this.currentPreferences.preferredLanguage.code,
        Validators.required,
      ],
      selectedCurrency: [
        this.currentPreferences.preferredCurrency.code,
        Validators.required,
      ],
    });
  }

  initData() {
    this.currenciesList = this.data.data.currencies;
    this.languagesList = this.data.data.languages;
  }

  handleError() {
    this.errorMessage = this.data.message;
  }

  get f() {
    return this.preferencesForm.controls;
  }

  onSaveClicked() {
    if (this.preferencesForm.valid) {
      this.updatePreferences();
    }
  }

  updatePreferences() {
    this.submitted = true;
    if (this.f.selectedLanguage.value) {
      this.userFormData.push({ param: 'preferredLanguageCode', value: this.f.selectedLanguage.value });
    }
    if (this.f.selectedCurrency.value) {
      this.userFormData.push({ param: 'preferredCurrencyCode', value: this.f.selectedCurrency.value });
    }
    if (this.userFormData.length) {
      this.userFormData.forEach((element, index) => {
        if (index === 0) {
          this.sendUserData = element.param + '=' + element.value;
        } else {
          this.sendUserData += '&' + element.param + '=' + element.value;
        }
      });
      this.dataService.userUpdate(this.sendUserData).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.auth.userInfo.systemPreferences = this.data.data.systemPreferences;
            localStorage.setItem('userInfo', JSON.stringify(this.auth.userInfo));
            this.submitting = false;
            this.checkLanguage();
            this.dataService.BottomSheetOf();
            this.dataService.rightSideDrawerOff();
            this.dataService.fetchUserData.next();
          } else {
            this.errorMessage = this.data.message;
            this.submitting = false;
          }
        }, error => {
          this.errorMessage = environment.messages.error;
          this.submitting = false;
        }
      );
    } else {
      this.submitting = false;
    }
  }

  checkLanguage() {
    if((this.auth.userInfo.systemPreferences.preferredLanguage.code === 'en') && this.dataService.corporateLocale.includes('ar')) {
      this.dataService.changeLocale('en-US')
    } else if((this.auth.userInfo.systemPreferences.preferredLanguage.code === 'ar') && this.dataService.corporateLocale.includes('en')) {
      this.dataService.changeLocale('ar')
    } else {
      this.dataService.messages($localize`:@@memberInfoUpdated:Member info updated successfully`, 'success');
    }
  }
}
