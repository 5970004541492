<div class="container pt-4 pb-lg-5">
  <form [formGroup]="programForm">
<div class="row">
  <div class="col-md-9">
    <p class="mb-1 mt-3" [ngClass]="{'gray-text': dataService.desktopView, 'gray-primaray-text': dataService.mobileView}" i18n="@@AirlineProgName">Airline & program name</p>
    <div class="form-group" *ngIf="programsList && programsList.length">
      <select class="form-control mt-2 select-custom-arrow" formControlName="selectedProgram" name="selectedProgram" (ngModelChange)="selectChangeHandler($event)"
              [ngClass]="{ 'is-invalid': submitted && f.selectedProgram.errors }" >
        <ng-container *ngFor="let item of programsList">
          <option [ngValue]="item">{{item.name}}</option>
          <option [ngValue]="item" *ngIf="programData && item.code === this.programData.code" [selected]="true">{{item.name}}</option>

        </ng-container>
      </select>
      <div *ngIf="submitted && f.selectedProgram.errors" class="invalid-feedback">
        <div *ngIf="f.selectedProgram.errors.required" i18n="@@AirlineProgNameRequired">Airline & program name is required</div>
      </div>
    </div>
  </div>
</div>

    <div class="row">
      <div class="col-md-9">
        <p class="mb-1 mt-3" [ngClass]="{'gray-text': dataService.desktopView, 'gray-primaray-text': dataService.mobileView}" i18n="@@programNumber">Program number</p>
        <div class="form-group">
          <input class="form-control" id="programNumber" name="programNumber" type="text" formControlName="programNumber"
                 [ngClass]="{ 'is-invalid': submitted && f.programNumber.errors }"/>
          <div *ngIf="submitted && f.programNumber.errors" class="invalid-feedback">
            <div *ngIf="f.programNumber.errors.required" i18n="@@programNumberRequired">Program number is required</div>
          </div>
        </div>
      </div>
    </div>






    <div class="row pt-3 pt-lg-5 mt-lg-5">
      <div class="col-md-6 order-lg-1" [ngClass]="{'pl-lg-2': dataService.isSiteLtr(), 'pr-lg-2': dataService.isSiteRtl()}">
        <button *ngIf="!this.programData"
          class="mt-3 px-0 btn-block sharp-font bold-500 d-flex px-0" (click)="addProgram()" [ngClass]="{'blue_btn':dataService.desktopView, 'btn btn-outline-blue w-100':dataService.mobileView}">
          <span class="mx-auto font-14" [ngClass]="{'m-progress': submitting}" i18n="@@addFlyerProg">Add Flyer Program</span>
        </button>
        <button *ngIf="this.programData"
                class="mt-3 px-0 btn-block sharp-font bold-500 d-flex px-0" (click)="editProgram()" [ngClass]="{'blue_btn':dataService.desktopView, 'btn btn-outline-blue w-100':dataService.mobileView}">
          <span class="mx-auto font-14" [ngClass]="{'m-progress': submitting}" i18n="@@save">Save</span>
        </button>
      </div>
      <div class="col-md-6 order-lg-0" [ngClass]="{'pr-lg-2': dataService.isSiteLtr(), 'pl-lg-2': dataService.isSiteRtl()}">
        <button (click)="dataService.rightSideDrawerOff(); dataService.BottomSheetOf()"
                class="mt-3 btn-block btn btn-outline-gray  px-5 d-flex ml-auto">
          <span class="mx-auto gray-text-dark font-14 sharp-font bold-500" i18n="@@cancelBtn">Cancel</span>
        </button>
      </div>
    </div>
    <p class="text-danger">{{this.errorMessage}}</p>
  </form>
</div>
