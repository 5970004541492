
<div class="sign-up main-container border-0 pb-5" [ngClass]="{'gray-pass-bg' : dataService.desktopView}"
     *ngIf="!showCompanyInvitationSignUp">

    <div *ngIf="!verifyEmailMsg">
      <div class="container pb-5" *ngIf="showSignUpForm" [ngClass]="{'desktop-design' : dataService.desktopView, 'mb-5 pt-5 pt-lg-2': dataService.mobileView}">
        <img width="481" height="406" loading="lazy" class="fixed-img" src="assets/img/Safarhub-signup-img.jpg" *ngIf="dataService.desktopView">
          <div class="login-form workspace-registration-container px-lg-5 pt-5 corporate-box signup-box"
               [ngClass]="{'white-box position-relative' : dataService.desktopView, 'mx-auto':dataService.mobileView,
               'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
            <div class="text-center">
              <h4 class="font-24 pt-md-4 mb-0 font-weight-bold text-center pb-4" i18n="@@signUp1"> Enter your work Email</h4>
              <p class="workspace-registration-container gray-text font-16 text-center mb-4 pb-2 mx-auto" i18n="@@signUp2">Sign up for free using your work email. Personal or testing email addresses are not allowed.</p>
            </div>
            <form name="signUpForm" [formGroup]="signUpForm" (ngSubmit)="onSubmitSignUp()" class="pt-lg-3 px-lg-3">
              <div class="form-group with-icon position-relative form-type-textfield form-name-email">
                <input type="email" name="email"  class="form-control placeholder-text-field" formControlName="email" placeholder="Work Email" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                       i18n-placeholder="@@workEmailPlaceHolder" />
                <i  class="icon-email"></i>
              </div>
              <div *ngIf="submitted && f.email.errors" class="text-danger smaller" >
                <div *ngIf="f.email.errors.required" i18n="@@requiredEmailError">Email is required</div>
                <div *ngIf="f.email.errors.email" i18n="@@invalidEmailError">Email must be a valid email address</div>
              </div>


              <div class="row mt-4" >
                <div class="col align-items-end">
                  <button  [ngClass]="{'m-progress': submitting }" class="btn green_btn btn-block font-weight-normal font-14 px-5 mt-auto workspace-btn-padding">
                    <span class="mx-auto" i18n="@@contBtn">Continue</span>
                  </button>
                </div>

              </div>
              <div class="text-danger mt-2" [ngClass]="{ 'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">{{this.errorMsg}}</div>
              <p class="blue-text mt-3"
                 [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}"><a (click)="this.router.navigate(['/login']);" i18n="@@signInBtn">Sign in</a></p>

            </form>

          </div>

      </div>

    </div>

  <app-send-verification-email *ngIf="verifyEmailMsg"></app-send-verification-email>


</div>


<app-company-invitation-signup *ngIf="showCompanyInvitationSignUp"></app-company-invitation-signup>
