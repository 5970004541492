<div class="border rounded pt-4 px-lg-4">
    <div
            class="px-4 px-lg-0"
            [ngClass]="{ 'd-flex': dataService.desktopView }"
    >
        <div
                class="d-flex"
                [ngClass]="{
                    'mr-5 pr-4':
                      dataService.desktopView && dataService.isSiteLtr(),
                    'ml-5 pl-4':
                      dataService.desktopView && dataService.isSiteRtl()
                  }"
        >
            <div>
                <img
                        width="50"
                        height="50"
                        loading="lazy"
                        *ngIf="approvalsService.approvalData.requesterProfileImageUrl"
                        class="user-img d-inline-block my-3"
                        [ngClass]="{ 'img-border': userService.imageHasError }"
                        [src]="userService.imageHasError ? userService.fallbackSrc : approvalsService.approvalData.requesterProfileImageUrl"
                        (error)="userService.imageHasError = true"
                />
                <div
                        *ngIf="!approvalsService.approvalData.requesterProfileImageUrl"
                        class="name-first-letters requester-img my-3"
                        [ngStyle]="{
                        'background-color':
                          approvalsService.approvalData
                            .requesterProfileImageBackgroundHexColor
                      }"
                >
                    {{
                    approvalsService.approvalData.requesterFirstname
                        .substring(0, 1)
                        .toUpperCase()
                    }}{{
                    approvalsService.approvalData.requesterLastname
                        .substring(0, 1)
                        .toUpperCase()
                    }}
                </div>
            </div>
            <div class="pt-4">
                <p
                        class="gray-primaray-text bold-600 font-16 mb-0"
                        [ngClass]="{
                        'ml-3': dataService.isSiteLtr(),
                        'mr-3': dataService.isSiteRtl(),
                        'mt-2': !approvalsService.approvalData.requesterTeamName,
                        'dotted-text dotted-text-direction':
                          dataService.mobileView
                      }"
                >
                      <span class="pr-2">{{
                          this.approvalsService.approvalData.requesterFirstname
                          }}</span
                      >{{ this.approvalsService.approvalData.requesterLastname }}
                </p>
                <p
                        *ngIf="this.approvalsService.approvalData.requesterTeamName"
                        class="gray-text font-14"
                        [ngClass]="{
                        'ml-3': dataService.isSiteLtr(),
                        'mr-3': dataService.isSiteRtl()
                      }"
                >
                    {{ this.approvalsService.approvalData.requesterTeamName }}
                </p>
            </div>
        </div>
    </div>
    <div class="gray-text mt-4 px-4 px-lg-0">
        <ng-container i18n="@@bookedFor">Booked for</ng-container>
        {{ approvalsService.approvalData.guestFirstname }}
        {{ approvalsService.approvalData.guestLastname }}
    </div>
    <div class="price-container pb-4 gray-primaray-text font-16 mt-3">
        <div class="px-4 px-lg-0">
            <p class="bold-600 mb-2" i18n="@@tripSummary">Trip Summary</p>

            <p
                    class="mb-1"
                    [ngClass]="{
                      'mr-0': dataService.isSiteLtr(),
                      'ml-0': dataService.isSiteRtl()
                    }"
                    i18n="@@approvalAvgNightlyPrice"
            >
                Average Nightly Price
            </p>
            <p class="gray-text mb-1">
                {{
                this.approvalsService.approvalHotelData.rooms[0].rates[0]
                    .averageNightlySellingPriceConvertedCurrency
                }} {{
                this.approvalsService.approvalHotelData.rooms[0].rates[0]
                    .averageNightlySellingPriceConverted
                }}
                <i
                        *ngIf="this.approvalsService.approvalData.limitExceeded"
                        class="icon-warning-msg yellow-text font-12"
                ></i>
            </p>

            <p
                    class="mb-1 mt-4"
                    [ngClass]="{
                      'mr-0': dataService.isSiteLtr(),
                      'ml-0': dataService.isSiteRtl()
                    }"
                    i18n="@@totalPrice"
            >
                Total Price
            </p>
            <p class="gray-text mb-1">
                {{
                this.approvalsService.approvalHotelData.rooms[0].rates[0]
                    .finalSellingPriceConvertedCurrency
                }} {{
                this.approvalsService.approvalHotelData.rooms[0].rates[0]
                    .finalSellingPriceConverted
                }}
            </p>
        </div>
        <hr />
        <div class="px-4 px-lg-0">
            <ng-container *ngIf="this.approvalsService.approvalData.policy">
                <p class="bold-600 mt-4 mb-2">
                    <ng-container i18n="@@travelPolicy"
                    >Travel Policy</ng-container
                    >&nbsp;
                    <span class="font-weight-normal font-14"
                    >({{ this.approvalsService.approvalData.policy.name }})</span
                    >
                </p>

                <ng-container
                        *ngIf="
                        !approvalsService.approvalData.policy.hotelBookingNotAllowed
                      "
                >
                    <p
                            class="mb-1 font-16 gray-text"
                            [ngClass]="{
                          'mr-0': dataService.isSiteLtr(),
                          'ml-0': dataService.isSiteRtl()
                        }"
                    >
                        <i
                                class="icon-check green-text font-12"
                                *ngIf="!this.approvalsService.approvalData.limitExceeded"
                                [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>
                        <i
                                class="icon-close-light text-danger font-12"
                                *ngIf="this.approvalsService.approvalData.limitExceeded"
                                [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>

                        <span i18n="@@nightlyPriceCap">Nightly price cap</span
                        >:<span
                            class="px-2"
                            *ngIf="
                            this.approvalsService.approvalData.appliedException &&
                            this.approvalsService.approvalData.appliedException
                              .avgNightlyPriceLimit
                          "
                    >{{
                        this.approvalsService.approvalData.appliedException
                            .avgNightlyPriceLimitCurrency
                        }} {{
                        this.approvalsService.approvalData.appliedException
                            .avgNightlyPriceLimit
                        }}</span
                    >
                        <span
                                class="px-2"
                                *ngIf="
                            !this.approvalsService.approvalData.appliedException &&
                            this.approvalsService.approvalData.policy
                              .companyPolicyHotelRules &&
                            this.approvalsService.approvalData.policy
                              .companyPolicyHotelRules.avgNightlyPriceLimit
                          "
                        >{{
                            this.approvalsService.approvalData.policy
                                .companyPolicyHotelRules.avgNightlyPriceLimitCurrency
                            }} {{
                            this.approvalsService.approvalData.policy
                                .companyPolicyHotelRules.avgNightlyPriceLimit
                            }}</span
                        >
                    </p>
                    <ng-container *ngIf="approvalsService.approvalData.policy.companyPolicyHotelRules as hotelRules">
                        <p [ngClass]="{'mr-0': dataService.isSiteLtr(), 'ml-0': dataService.isSiteRtl()}" class="mb-1 font-16 gray-text">
                            <i *ngIf="!approvalsService.approvalData.medianPriceViolated" [ngClass]="{'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl()}" class="icon-check green-text font-12"></i>
                            <i *ngIf="approvalsService.approvalData.medianPriceViolated" [ngClass]="{'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl()}" class="icon-close-light text-danger font-12"></i>
                            <span i18n="@@price" class="font-16 gray-text">Price</span>:
                            <ng-container *ngIf="hotelRules.medianPriceLimitPercentage; else noMedian">
                                {{ Math.abs(hotelRules.medianPriceLimitPercentage * 100) }}%
                                <span i18n="@@underMedian" *ngIf="hotelRules.medianPriceLimitPercentage < 0">under median price</span>
                                <span i18n="@@aboveMedian" *ngIf="hotelRules.medianPriceLimitPercentage > 0">above median price</span>
                            </ng-container>
                            <ng-template #noMedian> - </ng-template>
                        </p>

                        <p [ngClass]="{'mr-0': dataService.isSiteLtr(), 'ml-0': dataService.isSiteRtl()}" class="mb-1 font-16 gray-text">
                            <i *ngIf="!approvalsService.approvalData.bookingWindowViolated" [ngClass]="{'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl()}" class="icon-check green-text font-12"></i>
                            <i *ngIf="approvalsService.approvalData.bookingWindowViolated" [ngClass]="{'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl()}" class="icon-close-light text-danger font-12"></i>
                            <span i18n="@@bookingWindow" class="font-16 gray-text">Booking window</span>:
                            {{ hotelRules.bookingWindowInDays || '-'}}
                            <span i18n="@@oneDayInAdvance" *ngIf="hotelRules.bookingWindowInDays === 1">day in advance</span>
                            <span i18n="@@toDaysInAdvance" *ngIf="hotelRules.bookingWindowInDays === 2">days in advance</span>
                            <span i18n="@@daysInAdvance" *ngIf="hotelRules.bookingWindowInDays > 2">days in advance</span>
                        </p>

                        <p [ngClass]="{'mr-0': dataService.isSiteLtr(), 'ml-0': dataService.isSiteRtl()}" class="mb-1 font-16 gray-text">
                            <i *ngIf="!approvalsService.approvalData.starRatingViolated" [ngClass]="{'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl()}" class="icon-check green-text font-12"></i>
                            <i *ngIf="approvalsService.approvalData.starRatingViolated" [ngClass]="{'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl()}" class="icon-close-light text-danger font-12"></i>
                            <span i18n="@@hotelStarRating" class="font-16 gray-text">Star rating</span>:
                            {{ hotelRules.maxStars || '-'}}
                            <span i18n="@@star" *ngIf="hotelRules.maxStars === 1">star</span>
                            <span i18n="@@stars" *ngIf="hotelRules.maxStars === 2">stars</span>
                            <span i18n="@@stars" *ngIf="hotelRules.maxStars > 2">stars</span>
                        </p>
                    </ng-container>
                </ng-container>

                <ng-container
                        *ngIf="
                        approvalsService.approvalData.policy.hotelBookingNotAllowed
                      "
                >
                    <p
                            class="mb-1"
                            [ngClass]="{
                          'mr-0': dataService.isSiteLtr(),
                          'ml-0': dataService.isSiteRtl()
                        }"
                    >
                        <i
                                class="icon-close-light text-danger font-12"
                                [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>
                        <span
                                i18n="@@hotelBookingAllowed"
                                class="font-16 gray-text"
                        >Hotel booking allowed</span
                        >
                    </p>
                </ng-container>
            </ng-container>
        </div>

        <ng-container *ngIf="isRequester">
            <hr />
            <div class="px-4 px-lg-0">
                <p class="bold-600 mb-2">
                    <ng-container i18n="@@approver">Approver</ng-container>
                </p>
                <div
                        class="px-lg-0"
                        [ngClass]="{ 'd-flex': dataService.desktopView }"
                >
                    <p class="font-weight-normal font-16 gray-text">
                      <span class="pr-2">{{
                          this.approvalsService.approvalData.approverFirstname
                          }}</span
                      >{{ this.approvalsService.approvalData.approverLastname }}
                    </p>
                </div>
            </div>
        </ng-container>

    </div>
</div>

<div class="border rounded px-4 pt-4 mt-4">
    <p
            class="font-16 gray-primaray-text bold-600"
            i18n="@@cancellationPolicies"
    >
        Cancellation Policies
    </p>

    <p
            class="gray-text-dark font-14 cancel-text"
            *ngFor="
                  let policy of this.approvalsService.approvalHotelData.rooms[0]
                    .rates[0].cancellationPolicies
                "
    >
        {{ policy.description }}
    </p>

    <p
            class="non-refundable gray-text-dark"
            *ngIf="
                  !this.approvalsService.approvalHotelData.rooms[0].rates[0]
                    .cancellationPolicies ||
                  (this.approvalsService.approvalHotelData.rooms[0].rates[0]
                    .cancellationPolicies &&
                    !this.approvalsService.approvalHotelData.rooms[0].rates[0]
                      .cancellationPolicies.length)
                "
            i18n="@@nonrefundable"
    >
        Non Refundable
    </p>
    <ng-container
            *ngIf="
                  this.approvalsService.approvalData.status.toLowerCase() !==
                    'disapproved' &&
                  this.approvalsService.approvalData.status.toLowerCase() !==
                    'expired'
                "
    >
        <p
                class="font-16 gray-primaray-text bold-600 mt-4"
                i18n="@@paymentMethod"
        >
            Payment Method
        </p>
        <p class="font-14 gray-text-dark"
           *ngIf="this.approvalsService.approvalData.status.toLowerCase() === 'pending_approval'">
                    <span *ngIf="this.approvalsService.approvalData.payment.channelProvider === bookingWalletsService.booking.payment.amazon">
                      <ng-container i18n="@@cardEndsWith">Card ending with</ng-container> {{ this.approvalsService.approvalData.payment.lastFour }}
                        <ng-container i18n="@@willCharged">will be charged with total of</ng-container>
                        {{ this.approvalsService.approvalHotelData.rooms[0].rates[0].chargeAmountCurrency }}
                        {{ this.approvalsService.approvalHotelData.rooms[0].rates[0].chargeAmount | number: '1.0-2' }}
                    </span>

            <span *ngIf="this.approvalsService.approvalData.payment.channelProvider === bookingWalletsService.booking.payment.wallet">
                      <ng-container i18n="@@companyWallet">Company wallet </ng-container>
                    <ng-container i18n="@@willCharged">will be charged with total of</ng-container>
                {{ this.approvalsService.approvalHotelData.rooms[0].rates[0].chargeAmountCurrency }}
                {{ this.approvalsService.approvalHotelData.rooms[0].rates[0].chargeAmount | number: '1.0-2' }}
                    </span>
        </p>
        <p class="font-14 gray-text-dark"
           *ngIf="this.approvalsService.approvalData.status.toLowerCase() === 'approved'">
                  <span *ngIf="this.approvalsService.approvalData.payment.channelProvider === bookingWalletsService.booking.payment.amazon">
                    {{ this.approvalsService.approvalHotelData.rooms[0].rates[0].chargeAmountCurrency }}
                      {{ this.approvalsService.approvalHotelData.rooms[0].rates[0].chargeAmount | number: '1.0-2' }}
                      <ng-container i18n="@@charged">charged to card ending with</ng-container> {{ this.approvalsService.approvalData.payment.lastFour }}
                  </span>

            <span *ngIf="this.approvalsService.approvalData.payment.channelProvider === bookingWalletsService.booking.payment.wallet">
                    {{ this.approvalsService.approvalHotelData.rooms[0].rates[0].chargeAmountCurrency }}
                {{ this.approvalsService.approvalHotelData.rooms[0].rates[0].chargeAmount | number: '1.0-2' }}
                <ng-container i18n="@@chargedWallet">charged to company wallet</ng-container>
                  </span>
        </p>

    </ng-container>
</div>
