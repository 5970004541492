<form [formGroup]="searchForm" (ngSubmit)="onSubmit()">

<div class="justify-content-end">

    <div class="" [ngClass]="{'in action-popup':showSearchPopUpautocomplete}">
<div [ngClass]="{'header':showSearchPopUpautocomplete}" >
  <div *ngIf="showSearchPopUpautocomplete === true && !validChild">
    <div *ngIf="searchValid" class="text-white mb-3" [ngClass]="{'text-left': restApi.isSiteLtr(), 'text-right': restApi.isSiteRtl()}" i18n="@@chooseHotel">
      Please choose a hotel or a destination

    </div>


    <div *ngIf="showSearchPopUpautocomplete === true && !validChild">

      <div class="info" i18n="@@domesticHotels">
        Domestic &amp; International Hotels


      </div>
      <div class="close" (click)="hideSearchPopUpSearch()" i18n="@@cancelBtn">
        Cancel
      </div>
    </div>

  </div>


  <div class="position-relative mobile-search-field" >
    <div class="form-group mb-3">

    <input  (focus)="autocompleteOnClick($event)" *ngIf="!(availability || hotel) "  autocomplete="off" [value]="caption" [(ngModel)]="Query"  [ngModelOptions]="{standalone: true}"  name="search"
            (ngModelChange)="this.SearchTextQuery.next($event)"  i18n-placeholder="@@enterCityPlaceholder" placeholder="Enter city or hotel name ..." type="text"
            class="form-control mobileSearch" />

    <input  (focus)="autocompleteOnClick($event)" *ngIf="(availability || hotel) && captionMobile === ''" autocomplete="off" [value]="this.restApi.caption" [(ngModel)]="Query"  [ngModelOptions]="{standalone: true}"  name="search"
            (ngModelChange)="this.SearchTextQuery.next($event)"   i18n-placeholder="@@enterCityPlaceholder" placeholder="Enter city or hotel name ..." type="text"
            class="form-control mobileSearch" />

    <input (focus)="autocompleteOnClick($event)"  *ngIf="(availability || hotel) && captionMobile !== ''" autocomplete="off" [value]="captionMobile" [(ngModel)]="Query"  [ngModelOptions]="{standalone: true}"  name="search"
           (ngModelChange)="this.SearchTextQuery.next($event)"  i18n-placeholder="@@enterCityPlaceholder"  placeholder="Enter city or hotel name ..." type="text"
           class="form-control mobileSearch" />

    <!--        <div class="clear_search_text" *ngIf="(Query && Query.length > 0) || textfill"  (click)="clearSearchText()">-->
    <!--          <i class="icon-cross"></i>-->
    <!--        </div>-->
<!--      <button class="blue-text btn font-weight-norma clearSearchTextl" (click)="clearSearchText()" *ngIf="showSearchPopUpautocomplete && Query.length > 0 ">CLEAR</button>-->
  </div></div>
</div>





<!--      <div class="form-group m-0  mb-3 with-icon"  (click)="showSearchPopUp('autocomplete')">-->
<!--        <div   *ngIf="!(availability || hotel)"  class="form-control pl-3 py-3 caption-mobile"><span>{{captionbyClick}} </span></div>-->
<!--        <div  *ngIf="(availability || hotel) && captionMobile === ''"  class="form-control caption-mobile  pl-3 py-3"><span>{{this.restApi.caption}}</span> </div>-->
<!--        <div  *ngIf="(availability || hotel) && captionMobile !== ''"  class="form-control  caption-mobile pl-3 py-3"><span> {{captionMobile}} </span></div>-->


<!--        <i class="icon-locator gray-text"></i>-->
<!--      </div>-->






      <div class="body" *ngIf="showSearchPopUpautocomplete === true && !validChild">

        <div class="autocomplete_wrapper">
          <div class="history_list">
              <h2 class="main_title"><span class="btn-link btn" (click)="getGeolocation()" ><i class="icon-location"></i><span class="geolocation_icon"></span> <ng-container i18n="@@yourLocation">Your Location</ng-container>  </span></h2>
          </div>

          <div *ngFor="let autocompletetype   of autocompletelist">
            <h2>
              {{autocompletetype.name}}</h2>
            <ul>
              <li data-id="{{entity.code}}" *ngFor="let entity of autocompletetype.entities" tabindex="-1" >
															<span (click)="setSearchText(entity,  autocompletetype.name)">
																{{entity.caption}}
															</span>
              </li>
            </ul>
          </div>

          <div *ngIf="restApi.historyArray && restApi.historyArray.length > 0">
            <div class="d-flex">

              <h2 [ngClass]="{'mr-auto': restApi.isSiteLtr(), 'ml-auto': restApi.isSiteRtl()}" i18n="@@searchHistory">
                Search History</h2>  <span class="btn btn-link" (click)="clearHistory()">
 <i class="icon-close-light font-12" [ngClass]="{'pl-2': restApi.isSiteLtr(), 'pr-2': restApi.isSiteRtl()}"></i> <ng-container i18n="@@clearBtn">Clear</ng-container>                                                    </span>
            </div>
            <ul>
              <li *ngFor="let historyitem   of restApi.historyArray" tabindex="-1"
              >
															<span (click)="setSearchText(historyitem, 'History');">
																{{historyitem.caption}}
															</span>
              </li>
            </ul>
          </div>
        </div>
      </div>









    </div>

    <div>
      <div class="form-group mb-3  with-icon calendar-item"  (click)="showSearchPopUp('dates')">
        <div class="calendar-value-cont font-14">

                                            <span class=" d-inline-block">
                                         <span id="check-in-date-desktop"
                                               >{{CheckinRangeFormated}}</span>    -   <span
                                              id="check-out-date-desktop">{{CheckoutRangeFormated}}</span>

                                            </span>

            <span class="small number-of-nights gray-text d-inline-block" [ngClass]="{'ml-1': restApi.isSiteLtr(), 'mr-1': restApi.isSiteRtl()}">(


                  <span class="night-cont" *ngIf="staynights > 1; else Night">
                    <ng-container *ngIf="staynights === 2" i18n="@@twoNights"> 2 nights</ng-container>
                    <ng-container *ngIf="staynights > 2" ><span class="totalstaynights">{{staynights}}</span> <ng-container i18n="@@nights">nights</ng-container></ng-container>
                    </span>

                      <span class="night-cont">  <ng-template #Night i18n="@@night">
                      1 night
                        </ng-template>
                    </span>

                      )</span>

        </div>
        <div class="form-control form-control-sm" ></div>
        <i class="icon-dates gray-text"></i>
      </div>


    </div>

    <div>
      <div class="position-relative" (click)="showSearchPopUp('paxes')">

        <div id="paxes" class="paxes-cont-input form-group  mb-3  with-icon calendar-item"  >


          <div class="form-name-paxes-summary form-control">

            <div class="input_wrapper py-3">


              <div>

                <span class="pax_count"> {{paxCount}} </span>
                <span>
                       <ng-container *ngIf="paxCount === 1" i18n="@@guest"> Guest</ng-container>
                          <ng-container *ngIf="paxCount === 2" i18n="@@twoGuests">Guests</ng-container>
                          <ng-container *ngIf="paxCount > 2" i18n="@@guests">Guests</ng-container>
                </span>
              </div>
            </div>


          </div>

          <i class="icon-user gray-text"></i>

        </div>


      </div>



        <div class="position-relative" (click)="showUsersPopUp()"
             [ngClass]="{'prevent-pointer-event': (searchUserSelectionService.disableAction | async)}">
            <div class="paxes-cont-input form-group  mb-3  with-icon calendar-item"  >
                <div class="form-name-paxes-summary form-control"
                     [ngClass]="{'bg-color': (searchUserSelectionService.disableAction | async)}">

                    <div class="input_wrapper">

                        <p *ngIf="searchUserSelectionService.selectedUser" class="mt-2 pt-1">{{searchUserSelectionService.selectedUser.description}}</p>
                        <span *ngIf="!searchUserSelectionService.selectedUser" class="gray-text traveller placeholder-search px-4" i18n="@@whoTravelling">Who's travelling?</span>
                    </div>
                </div>
             <!--   <i class="icon-user gray-text"></i>-->
            </div>
        </div>
    </div>

    <div >
      <button value="submit"  class="btn-block green_btn btn-block" [ngClass]="{'m-progress': submitting }" i18n="@@searchBtn">Search</button>


    </div>

  </div>





<div class="action-popup"   [ngClass]="{in : activePopUp || validChild === true}">

  <div class="header">
    <div class="form-name-dates-summary picker-new-header" *ngIf="showSearchPopDates && !validChild">
      <div class="info" i18n="@@howLongStay">
        How long will you stay?
      </div>


      <div class="dates row no-gutters px-2">
        <div class="date col-5 text-center">
          <div class="d-inline-block" [ngClass]="{'text-left': restApi.isSiteLtr(), 'text-right': restApi.isSiteRtl()}">

            <div class="title" i18n="@@checkIn">
              Check-in
            </div>
            <div class="value">{{CheckinRangeFormated}}</div>
          </div>
          </div>

        <div class="between col-2 text-center">
            <i class="icon-arrow-right2" *ngIf="restApi.isSiteLtr()"></i>
            <i class="icon-arrow-left2" *ngIf="restApi.isSiteRtl()"></i>
        </div>
        <div class="date col-5 text-center">

          <div class="d-inline-block" [ngClass]="{'text-left': restApi.isSiteLtr(), 'text-right': restApi.isSiteRtl()}">

          <div class="title" i18n="@@checkOut">
            Check-out
          </div>
          <div class="value">{{CheckoutRangeFormated}}</div>
        </div>
      </div>
      </div>

    </div>

    <div *ngIf="showSearchPopPaxes || validChild">
      <div class="info" i18n="@@whoIsTravel">

        Who's traveling?

      </div>









    </div>

    <div *ngIf="showSearchPopPaxes === true || validChild" class="summary_item">
      <span class="pax_count ng-binding">{{adultCount}}</span>


      <span class="night-cont">
                    <ng-container *ngIf="adultCount === 0 || adultCount === 1" i18n="@@adult"> Adult</ng-container>
                          <ng-container *ngIf="adultCount === 2" i18n="@@twoAdults">Adults</ng-container>
                          <ng-container *ngIf="adultCount > 2" i18n="@@adults">Adults</ng-container>
                    </span>





      <span class="pax_count">{{childrenCount}}  </span>

     <!-- <span class="night-cont" *ngIf="childrenCount > 1; else Child">
                   children
                    </span>

      <span class="night-cont">  <ng-template #Child>
                        Child
                        </ng-template>
                    </span>-->

        <span class="night-cont">
             <ng-container *ngIf="childrenCount === 0 || childrenCount === 1" i18n="@@child"> Child</ng-container>
                          <ng-container *ngIf="childrenCount === 2" i18n="@@twoChildren">Children</ng-container>
                          <ng-container *ngIf="childrenCount > 2" i18n="@@children">Children</ng-container>
        </span>


      <span i18n="@@max6">(max 6)</span>

    </div>






  </div>
  <div class="body">

  <div class="mobile_dates"  [ngClass]="showSearchPopDates && !validChild ? 'd-block' : 'd-none'" >



  <div class="dates-search-popup">

    <input type="text" id="calendarx" class="form-control form-control-sm" style="display: none;"/>


  </div>



  </div>



    <div class="p-2" *ngIf="showSearchPopPaxes === true || validChild">
      <div class="people_number-menu">
        <div class="adults row no-gutters">

          <div class="col">
            <span class="paxes-icon icon-minus col" [ngClass]="{disabled : decreaseAdultDisabled === true}"  (click)="decreaseAdult()"> <i class="icon-min"></i></span>


          </div>
          <div class="col text-center">

                        <span class="count adults_count ">
                            <span class="pax_count ">{{adultCount}} </span>
                         <ng-container *ngIf="adultCount === 0 || adultCount === 1" i18n="@@adult"> Adult</ng-container>
                          <ng-container *ngIf="adultCount === 2" i18n="@@twoAdults">Adults</ng-container>
                          <ng-container *ngIf="adultCount > 2" i18n="@@adults">Adults</ng-container>
                        </span>
          </div>
          <div class="col text-right">

                        <span  [ngClass]="{disabled : increasDisabled === true}"  class="paxes-icon icon-add " (click)="increaseAdult()">  <i class="icon-plus"></i>
                        </span>

          </div>

        </div>

        <div class="adults row no-gutters">
          <div class="col">

            <span class="paxes-icon icon-minus" [ngClass]="{disabled : decreaseChildrenDisabled === true}" (click)="decreaseChildren()"> <i class=" icon-min"></i></span>
          </div>

          <div class="col text-center">

                        <span class="count adults_count">
                            <span class="pax_count"> {{childrenCount}} </span>
                                    <ng-container *ngIf="childrenCount === 0 || childrenCount === 1" i18n="@@child"> Child</ng-container>
                          <ng-container *ngIf="childrenCount === 2" i18n="@@twoChildren">Children</ng-container>
                          <ng-container *ngIf="childrenCount > 2" i18n="@@children">Children</ng-container>
                        </span>
          </div>
          <div class="col text-right">

                        <span class="paxes-icon icon-add" [ngClass]="{disabled : increasDisabled === true}" (click)="increaseChildren()">  <i class="icon-plus"></i>
                        </span>
          </div>

        </div>

        <div >

          <div class="rows clearfix" *ngIf="childrenCount > 0" >
            <div class="px-4 pt-3 pb-3 text-center d-block" i18n="@@childAge">

              Child age at check-in
            </div>

            <div  class="item clearfix d-flex" *ngFor="let childrens of childrenList; let i = index" >
              <label class="name">

                  <ng-container i18n="@@child">Child</ng-container>  {{i+1}}


              </label>


              <div class="select" >
                {{childrenList[i]}}
                <select *ngIf="childrenList"  [(ngModel)]="childrens.childList" [ngModelOptions]="{standalone: true}">



                  <option *ngFor="let number of ages " [ngValue]="number"  >
                    {{number}}
                  </option>






                </select>
                <i class="icon-down"></i>

              </div>



            </div>

          </div>


        </div>
      </div>
      <div *ngIf="validChild" class="text-danger" i18n="@@childAgeRequired">
        Children ages are required


      </div>

  </div>





    <div     [ngClass]="{'d-block' : validChild}"  class="green_btn btn btn-block text-medium d-none" (click)="hideSearchPopUp()">
     <ng-container i18n="@@done">Done</ng-container>
      <span class="nights_in_button"   [ngClass]="showSearchPopDates ? 'd-inline' : 'd-none'" >
          (<span class="staynights">{{staynights}}</span>)    <span class="night-cont" >
                               <ng-container *ngIf="staynights === 1" i18n="@@nightNoNumber">night</ng-container>
                    <ng-container *ngIf="staynights === 2" i18n="@@twoNightsNoNumber">nights</ng-container>
                    <ng-container *ngIf="staynights > 2" i18n="@@nights">nights</ng-container>

                    </span>

                      </span>
    </div>
    <div     [ngClass]="{'d-none' : showSearchPopUpautocomplete === true || validChild}"  class="green_btn btn btn-block text-medium" (click)="hideSearchPopUp()">
        <ng-container i18n="@@done">Done</ng-container>
      <span class="nights_in_button"   [ngClass]="showSearchPopDates ? 'd-inline' : 'd-none'" >
          (<span class="staynights">{{staynights}}</span>)    <span class="night-cont">
                     <ng-container *ngIf="staynights === 1" i18n="@@nightNoNumber">night</ng-container>
                    <ng-container *ngIf="staynights === 2" i18n="@@twoNightsNoNumber">nights</ng-container>
                    <ng-container *ngIf="staynights > 2" i18n="@@nights">nights</ng-container>

                    </span>

                    </span>
    </div>
</div>


</div>

</form>







<div class="justify-content-end create-team">

    <div [ngClass]="{'in action-popup':searchUserSelectionService.showUsersAutoCompletePopUp}" (click)="$event.stopPropagation()">
        <div [ngClass]="{'header':searchUserSelectionService.showUsersAutoCompletePopUp}" >
            <div *ngIf="searchUserSelectionService.showUsersAutoCompletePopUp">
                <div *ngIf="searchUserSelectionService.showUsersAutoCompletePopUp">

                    <div class="info">
                       <span i18n="@@whoTravelling">Who's travelling?</span>
                    </div>
                    <div
                            class="close"
                            (click)="hideUserSearchPopUp()"
                            i18n="@@cancelBtn"
                    >
                        Cancel
                    </div>
                </div>
            </div>




            <div class="position-relative mobile-search-field" *ngIf="searchUserSelectionService.showUsersAutoCompletePopUp">
                <div class="form-group mb-3">
                    <app-user-selection #child></app-user-selection>
<!--                    <mat-form-field class="w-100 chips-container border rounded">-->
<!--                        <input-->
<!--                                class="bg-white min-height mx-0 my-0 text-black"-->
<!--                                [ngClass]="{-->
<!--                'pl-4': restApi.isSiteLtr(),-->
<!--                'pr-4': restApi.isSiteRtl()-->
<!--              }"-->
<!--                                #userInput-->
<!--                                id="userInput"-->
<!--                                type="text"-->
<!--                                matInput-->
<!--                                [formControl]="userControl"-->
<!--                                [matAutocomplete]="autoUser"-->
<!--                        />-->
<!--                        <mat-autocomplete-->
<!--                            #autoUser="matAutocomplete"-->
<!--                            [displayWith]="displayFn"-->
<!--                        >-->
<!--                            <mat-option-->
<!--                                    class="px-2"-->
<!--                                    *ngFor="-->
<!--                  let user of filteredUsers-->
<!--                    | async-->
<!--                    | slice : 0 : maxNoUserToShow-->
<!--                "-->
<!--                                    [value]="user"-->
<!--                            >-->
<!--                                <div-->
<!--                                        class="d-flex mat-checkbox-container"-->
<!--                                        (click)="optionClicked($event, user)"-->
<!--                                        *ngIf="user.id !== 'guest'"-->
<!--                                >-->
<!--                                    <img-->
<!--                                            width="35"-->
<!--                                            height="35"-->
<!--                                            loading="lazy"-->
<!--                                            *ngIf="user.profileImage"-->
<!--                                            class="user-img d-inline-block"-->
<!--                                            [ngClass]="{-->
<!--                            'mr-3': restApi.isSiteLtr(),-->
<!--                            'ml-3': restApi.isSiteRtl(),-->
<!--                            'img-border': userService.imageHasError-->
<!--                          }"-->
<!--                                            [src]="userService.imageHasError ? userService.fallbackSrc : user.profileImage"-->
<!--                                            (error)="userService.imageHasError = true"-->
<!--                                    />-->
<!--                                    <div-->
<!--                                            class="name-first-letters small"-->
<!--                                            [ngClass]="{-->
<!--                            'mr-3': restApi.isSiteLtr(),-->
<!--                            'ml-3': restApi.isSiteRtl()-->
<!--                          }"-->
<!--                                            *ngIf="!user.profileImage"-->
<!--                                            [ngStyle]="{-->
<!--                            'background-color':-->
<!--                              user.profileImageBackgroundHexColor-->
<!--                          }"-->
<!--                                    >-->
<!--                                        {{ user.firstName.substring(0, 1).toUpperCase()-->
<!--                                        }}{{ user.lastName.substring(0, 1).toUpperCase() }}-->
<!--                                    </div>-->
<!--                                    <div class="dotted-text">-->
<!--                                        <p class="mb-0 gray-primaray-text">-->
<!--                                            <span>{{ user.description }}</span>-->
<!--                                        </p>-->
<!--                                        <p-->
<!--                                                class="gray-text mb-0 dotted-text dotted-text-direction"-->
<!--                                        >-->
<!--                                            {{ user.email }}-->
<!--                                        </p>-->
<!--                                    </div>-->
<!--                                </div>-->

<!--                                <div-->
<!--                                        class="d-flex mat-checkbox-container"-->
<!--                                        (click)="optionClicked($event, user)"-->
<!--                                        *ngIf="user.id === 'guest'"-->
<!--                                >-->
<!--                                    <div-->
<!--                                            class="name-first-letters small"-->
<!--                                            [ngClass]="{-->
<!--                            'mr-3': restApi.isSiteLtr(),-->
<!--                            'ml-3': restApi.isSiteRtl()-->
<!--                          }"-->
<!--                                            [ngStyle]="{-->
<!--                            'background-color':-->
<!--                              user.profileImageBackgroundHexColor-->
<!--                          }"-->
<!--                                    >-->
<!--                                        <img-->
<!--                                                loading="lazy"-->
<!--                                                class="mb-1 user-img d-inline-block"-->
<!--                                                onError="this.src='assets/img/default-user.png'"-->
<!--                                                [src]="user.profileImage"-->
<!--                                        />-->
<!--                                    </div>-->
<!--                                    <div class="dotted-text d-flex align-items-center">-->
<!--                                        <p class="mb-0 gray-primaray-text">-->
<!--                                            <span>{{ user.description }}</span>-->
<!--                                        </p>-->
<!--                                    </div>-->
<!--                                </div>-->

<!--                            </mat-option>-->
<!--                        </mat-autocomplete>-->
<!--                    </mat-form-field>-->

                </div>
            </div>





        </div>



    </div>
</div>




