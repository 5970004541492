
<div class="main-container border-0 pb-5" [ngClass]="{'gray-pass-bg' : dataService.desktopView}">

  <div class="container pb-5" [ngClass]="{'desktop-design text-center': dataService.desktopView, 'mb-5 pt-5 pt-lg-2': dataService.mobileView}">


    <div class="row">
      <div class="col-lg-6 pb-5 pb-lg-0 order-lg-1 extra-padding-top">
        <div class="login-form workspace-registration-container px-lg-5 pt-5 corporate-box" [ngClass]="{'white-box position-relative' : dataService.desktopView, 'mx-auto': dataService.mobileView,
        'mr-auto': dataService.desktopView && dataService.isSiteLtr(), 'ml-auto': dataService.desktopView && dataService.isSiteRtl()}">
          <div class="px-3 pt-4">


            <div class="text-center">
              <p class="text-danger font-16" i18n="@@somethingWentWrongSafarHubApp">Something went wrong SafarHub App failed to install</p>
            </div>
            <p class="gray-text pt-4 font-16 mb-0 text-center workspace-registration-container mx-auto px-3" i18n="@@contactOurSupportAt">contact our support at</p>
            <p class="mb-5 text-center"><a href="mailto:support@safarhub.com?Subject=Question" target="_top">support@safarhub.com</a></p>
            <div class="row" >
              <div class="col">
                <button (click)="this.router.navigate(['/slack'])"  class="btn btn-outline-blue btn-block font-weight-normal font-14 px-5 mt-auto workspace-btn-padding">
                  <span class="mx-auto" i18n="@@tryAgain">Try Again</span>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="col-lg-6 d-lg-flex z-index-10">
        <div class="text-center" [ngClass]="{'text-lg-left': dataService.isSiteLtr(), 'text-lg-right': dataService.isSiteRtl()}">
          <div class="w-100 mb-2">
            <img width="120" height="38"  loading="lazy" src="assets/img/slack.png" />
          </div>
          <div class="w-100 mb-4">
            <p class="gray-text font-16" i18n="@@manageYourBusinessInSlack">Manage your business travel in slack</p>
          </div>
          <div class="w-100">
            <img width="350" height="430"  loading="lazy" class="initial-height img-mobile" src="assets/img/slack-business.png" />
          </div>
        </div>
      </div>


    </div>




  </div>






</div>


