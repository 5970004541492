import {
  Component,
  ElementRef, HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { VariablesService } from "../../../services/vars.service";
import { OrderPipe } from "ngx-order-pipe";
import { AuthServiceMain } from "../../../auth/auth.service";
import { Title } from "@angular/platform-browser";
import {
  ScrollToConfigOptions,
  ScrollToService,
} from "@nicky-lenaers/ngx-scroll-to";
import { GoogleAnalyticsService } from "../../../google-analytics.service";
import { environment } from "../../../../environments/environment";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { ChangeContext, Options } from "ng5-slider";
import {FlightListService} from "../../../services/flight-services/flight-list.service";
import {BehaviorSubject, Subscription} from "rxjs";

@Component({
  selector: "app-flights-list",
  templateUrl: "./flights-list.component.html",
  styleUrls: ["./flights-list.component.scss"],
  host: {
    "(document:click)": "onClickBody($event)",
  },
})
export class FlightsListComponent implements OnInit, OnDestroy {
  private previousWidth: number = window.innerWidth; // Track the previous screen width
  upSellingErrorMsg;
  selectedFlightIndex;
  upSellingSubscription: Subscription;
  upSellingData: any;
  fares: any;
  upSellingLoading: BehaviorSubject<boolean>[] = [];
  @ViewChild("filterMenu", { static: false })
  private scrollContainer: ElementRef;
  currentFlightList;
  timeFilter;
  timeFilterArrival;
  numberOfFlights = 10;
  flightList;
  filterreditems = [];
  autoCompleteMenuFilter = false;
  activeScroll = false;
  oldScrollFromTop = 0;
  data;
  PaxesArray = [];
  queryParams: any = {
    roundTrip: true,
    cabinClass: "ECONOMY",
    airQueries: [
      {
        departureDate: "",
        departureSearchCode: "",
        arrivalSearchCode: "",
      },
    ],
    adultCount: 1,
    childCount: 0,
    infantSeatedCount: 0,
    infantLapCount: 0,
    directFlightsOnly: false,
    travelerId: []
  };
  errorData = false;
  sortHeader = false;
  sortingEnabled = false;
  activeSort = false;
  ParentSort = "default";
  SortType = "default";
  stops = [
    {
      id: "0",
      isChecked: false,
      title: $localize`:@@directOnly:Direct Only`,
      counter: 0,
      price: 0,
      currency: '',
      faded: false,
    },
    {
      id: "1",
      isChecked: false,
      title: $localize`:@@oneStop:1 stop`,
      counter: 0,
      price: 0,
      currency: '',
      faded: false,
    },
    {
      id: "2",
      isChecked: false,
      title: $localize`:@@moreThanTwoStops:2+ Stops`,
      counter: 0,
      price: 0,
      currency: '',
      faded: false,
    },
  ];

  departureSeg1 = [
    {
      id: "earlyMorning",
      isChecked: false,
      title: $localize`:@@earlyMorning:Early Morning`,
      time: $localize`:@@am1:12:00am - 4:59am`,
      counter: 0,
      faded: false,
    },
    {
      id: "morning",
      isChecked: false,
      title: $localize`:@@morning:Morning`,
      time: $localize`:@@am2:5:00am - 11:59am`,
      counter: 0,
      faded: false,
    },
    {
      id: "afternoon",
      isChecked: false,
      title: $localize`:@@afternoon:Afternoon`,
      time: $localize`:@@pm1:12:00pm - 5:59pm`,
      counter: 0,
      faded: false,
    },
    {
      id: "evening",
      isChecked: false,
      title: $localize`:@@evening:Evening`,
      time: $localize`:@@pm2:6:00pm - 11:59pm`,
      counter: 0,
      faded: false,
    },
  ];
  departureSeg2 = [
    {
      id: "earlyMorningArrival",
      isChecked: false,
      title: $localize`:@@earlyMorning:Early Morning`,
      time: $localize`:@@am1:12:00am - 4:59am`,
      counter: 0,
      faded: false,
    },
    {
      id: "morningArrival",
      isChecked: false,
      title: $localize`:@@morning:Morning`,
      time: $localize`:@@am2:5:00am - 11:59am`,
      counter: 0,
      faded: false,
    },
    {
      id: "afternoonArrival",
      isChecked: false,
      title: $localize`:@@afternoon:Afternoon`,
      time: $localize`:@@pm1:12:00pm - 5:59pm`,
      counter: 0,
      faded: false,
    },
    {
      id: "eveningArrival",
      isChecked: false,
      title: $localize`:@@evening:Evening`,
      time: $localize`:@@pm2:6:00pm - 11:59pm`,
      counter: 0,
      faded: false,
    },
  ];
  tempAirline = [];
  airlines = [];
  airports = [];
  tempAirports = [];
  filteringActive;
  defaultNavigateUrl;
  Params;
  minimumMins = 0;
  maxMins = 0;
  showMore = 4;
  filterapplied = false;
  activecheckbox;
  filterChangePolicyCheck = false;
  filterWithinPolicyCheck = false;
  showWithinPolicyFilter;
  filterWithinPolicy = false;
  departSeg1Array = [];
  departSeg2Array = [];
  stopSelectedArray = [];
  airlinesSelectedArray = [];
  airportsSelectedArray = [];
  airportsSelectedArrayWithCity = [];
  cityArrayForAirports = [];
  // filterByDepartDate0;
  // filterByArrivalDate0;
  // filterByDepartDate1;
  // filterByArrivalDate1;
  //filterByReturnDateCheck;
  filterByDepartureDateCheck;
  activePopUp = false;
  sortPopUp = false;
  filterPopUp = false;
  config = {
    direction: "horizontal",
    loop: false,
    slidesPerView: "auto",
    spaceBetween: 5,
    observer: true,
    freeMode: true,
  };
  bestFlightItem;
  cheapestFlightItem;
  shortestFlightItem;
  travelerId;
  travelersArray: string[] = [];
  hasMaxNoOfTrips = false;
  policyDetails;
  showRewardsBox = true;
  applicablePolicySelectedClass;
  maxNoInterval;
  maxNoOfTrips;
  showPolicyDetails = true;
  sub: any;
  tripType: string = '';
  order = "index";
  isInteger = true;
  optionsHours: Options;
  hoursValue = 0;
  oneHourValueText = $localize`:@@oneHour:1 h`;
  twoHourValueText = $localize`:@@twoHours:2 h`;
  moreHourValueText = $localize`:@@moreHours:h`;
  moreThan11Hours = $localize`:@@moreThan11Hours:h`;
  filterByDateCheck: boolean[] = []; // Array to track checked state by index
  selectedDatesByItinerary: { [index: number]: { departureDate: string | null; arrivalDate: string | null } } = {}; // Track selected dates by itinerary index
  availabilityMedianCurrency;
  availabilityMedianPrice;
  constructor(
      public flightListService: FlightListService,
      public datePipe: DatePipe,
      private route: ActivatedRoute,
      public VarsService: VariablesService,
      public Service: DataService,
      public Vars: VariablesService,
      public logedIn: AuthServiceMain,
      public router: Router,
      private titleService: Title,
      private scrollToService: ScrollToService,
      private eref: ElementRef,
      public googleAnalyticsService: GoogleAnalyticsService
  ) {   //  flights can have up to 6 itineraries:
    this.filterByDateCheck = new Array(6).fill(false);
    // Initialize selected dates tracking object based on max itineraries
    // Set to `null` initially for each itinerary index
    this.selectedDatesByItinerary = {
      0: { departureDate: null, arrivalDate: null },
      1: { departureDate: null, arrivalDate: null },
      2: { departureDate: null, arrivalDate: null },
      3: { departureDate: null, arrivalDate: null },
      4: { departureDate: null, arrivalDate: null },
      5: { departureDate: null, arrivalDate: null }
    };
  }
  /*toggleCheckboxFilterByDate(itineraryIndex: number) {
    this.filterByDateCheck[itineraryIndex] = !this.filterByDateCheck[itineraryIndex];
  }*/
  // Toggle date selection for a given itinerary index and both dates
  toggleDateSelection(itineraryIndex: number, departureDate: string, arrivalDate: string) {
    this.filterByDateCheck[itineraryIndex] = !this.filterByDateCheck[itineraryIndex];
    const selectedDates = this.selectedDatesByItinerary[itineraryIndex];
    // Check if both dates match the current selection, then deselect
    if (selectedDates.departureDate === departureDate && selectedDates.arrivalDate === arrivalDate) {
      this.selectedDatesByItinerary[itineraryIndex] = { departureDate: null, arrivalDate: null };
    } else {
      // Otherwise, update with the new selection
      this.selectedDatesByItinerary[itineraryIndex] = { departureDate, arrivalDate };
    }
    this.applyfilters();
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    if(this.upSellingSubscription){
      this.upSellingSubscription.unsubscribe();
    }
  }

  hoursChangeEnd(changeContext: ChangeContext): void {
    this.filteringActive = true;
    this.updateFlightList();
    this.Service.scrollTop();
    this.applyfilters();
    this.filterSideFadedItems("duration");
  }
  ngOnInit(): void {
    this.Service.flightFilter = false;
    //this.Service.alertsOverRoute = [];
    this.Service.flightRatesExpiry = false;
    this.defaultNavigateUrl = "/flights/availability";


    this.Service.bookingPage = false;
    this.Service.flightBookingPage = false;
    this.Service.flightBookingSubmit = false;
    this.Service.bookingSubmit = false;
    this.Service.flightListLoader = true;
    this.route.queryParams.subscribe((params) => {
      this.tripType = params['trip'] || '';
      if(params['travelerId']){
        this.travelersArray.push(params['travelerId']);
      }

      this.queryParams.travelerId = this.travelersArray;

      this.queryParams['directFlightsOnly'] = (params['directOnly'] === 'true');
      this.travelerId = params['travelerId'] || null;

      this.Params = params;
      this.queryParams.cabinClass = params.cabinClass;

       if (params.trip === "ONEWAY" || params.trip === "ROUNDTRIP") {
         if(this.Service.desktopView){
           this.Service.isCollapsedFlight = false;
           this.flightListService.showEditActionInFlightsList = false;
         }

        this.queryParams.airQueries[0].departureSearchCode = params.departureCode;
        this.queryParams.airQueries[0].arrivalSearchCode = params.arrivalCode;


       // this.queryParams.roundTrip = false;
        //delete this.queryParams.airQueries[0].returnDate;
        if (
          params.departureDate === "null" ||
          moment(params.departureDate).isBefore(moment(), "day") ||
          !moment(params.departureDate).isValid()
        ) {
          this.queryParams.airQueries[0].departureDate = moment()
            .add(14, "days")
            .format("YYYY-MM-DD");
        } else {
          if (params.departureDate === undefined) {
            this.queryParams.airQueries[0].departureDate = moment()
              .add(14, "days")
              .format("YYYY-MM-DD");
          } else {
            this.queryParams.airQueries[0].departureDate = params.departureDate;
          }
        }
      }

      if (params.trip === "ROUNDTRIP") {
        let airQueries1 = {
          departureDate: params.returnDate,
          departureSearchCode: params.arrivalCode,
          arrivalSearchCode: params.departureCode

        };
        this.queryParams.airQueries.push(airQueries1);
       /* this.queryParams.airQueries[1].departureSearchCode = params.arrivalCode;
        this.queryParams.airQueries[1].arrivalSearchCode = params.departureCode;*/

       // this.queryParams.roundTrip = true;
       // this.queryParams.airQueries[0].returnDate = params.returnDate;

        if (
          params.returnDate === "null" ||
          params.departureDate === "null" ||
          moment(params.departureDate).isBefore(moment(), "day") ||
          !moment(params.departureDate).isValid() ||
          !moment(params.returnDate).isValid() ||
          moment
            .utc(params.returnDate)
            .diff(moment.utc(params.departureDate), "days") < 0
        ) {
         /* if (
            !params.departureDate ||
            moment(params.departureDate).isBefore(moment(), "day")
          ) {
            this.queryParams.airQueries[0].departureDate = moment()
              .add(14, "days")
              .format("YYYY-MM-DD");
          }*/
          if (
            !params.returnDate ||
            moment(params.returnDate).isBefore(moment(), "day")
          ) {
            this.queryParams.airQueries[1].departureDate = moment()
              .add(17, "days")
              .format("YYYY-MM-DD");
          }
        } else if (
          params.departureDate !== undefined &&
          params.returnDate !== undefined &&
          params.paxes !== undefined
        ) {
          this.queryParams.airQueries[1].departureDate = moment(
            params.returnDate
          ).format("YYYY-MM-DD");
        } else {
          if (params.departureDate === undefined) {
            this.queryParams.airQueries[1].departureDate = moment()
              .add(14, "days")
              .format("YYYY-MM-DD");
          }
          if (params.returnDate === undefined) {
            this.queryParams.airQueries[1].departureDate = moment()
              .add(17, "days")
              .format("YYYY-MM-DD");
          }
        }


      } else if (params.trip === "MULTICITY") {
        if(this.Service.desktopView){
          this.Service.isCollapsedFlight = true;
          this.flightListService.showEditActionInFlightsList = true;
        }
        const segmentsString = params['segments'];

        // Check if segments exist in the URL
        if (segmentsString) {
          // Split the segments by semicolon, then split each segment by comma
          const segments = segmentsString.split(';').map((segment: string) => {
            const [departureSearchCode, arrivalSearchCode, departureDate] = segment.split(',');
            return {
              departureSearchCode,
              arrivalSearchCode,
              departureDate,
            };
          });
          this.queryParams.airQueries = segments;
        }
      }

      if (params.paxes !== undefined) {
        this.PaxesArray = params.paxes.split(",");
        this.queryParams.adultCount = Number(this.PaxesArray[0]);
        if (this.PaxesArray[1]) {
          this.queryParams.childCount = this.PaxesArray[1];
        }
      }
      sessionStorage.setItem("flightParams", JSON.stringify(this.Params));
      let tripInTitle = "Oneway trip";
      if (params.trip === "ROUNDTRIP") {
        tripInTitle = "Round trip";
      }
      if (params.trip === "MULTICITY") {
        tripInTitle = "Multi city trip";
      }
      this.titleService.setTitle(
        tripInTitle +
          " From " +
          params.departureCode +
          " to " +
          params.arrivalCode +
          " | SafarHub"
      );
    });
    let header = "";
    if (sessionStorage.getItem("session-token")) {
      header = sessionStorage.getItem("session-token");
    }
    let finalParameters = JSON.parse(JSON.stringify(this.queryParams)); // Deep copy
    delete finalParameters.roundTrip;
    delete finalParameters.adultCount;
    delete finalParameters.childCount;
    delete finalParameters.infantSeatedCount;
    delete finalParameters.infantLapCount;
    delete finalParameters.travelerId;
    finalParameters['travelers'] = [
      {
        travelerId: this.travelerId,
        travelerType: "ADULT"
      }
    ];
    console.log(this.queryParams);
    console.log(finalParameters);
    localStorage.setItem('flightSearchRequest', JSON.stringify(finalParameters));

    this.sub = this.Service.flightAvailabilityList(
        finalParameters,
      header
    ).subscribe(
      (success) => {
        if (sessionStorage.getItem("session-token")) {
          sessionStorage.removeItem("session-token");
        }
        this.data = success;
        if (this.data.errorCode === 0) {
          this.fillFlightSearchCations();
          if (
            !this.data.data ||
            !this.data.data.flightOffers ||
            this.data.data.flightOffers.length <= 0
          ) {
            this.errorData = true;
            this.Service.flightListLoader = false;
            return;
          }

          this.availabilityMedianPrice = this.data.data.flightOffers[0].sellingPriceConverted.availabilityMedianPrice;
          this.availabilityMedianCurrency = this.data.data.flightOffers[0].sellingPriceConverted.currency;
          this.prepareLeftSideFilters();
          this.getPolicyBoxDetails();

          // if (this.data.data.captions) {
          //   let captions = "";
          //   this.data.data.captions.forEach((element, index) => {
          //     if (index === 0) {
          //       captions = element;
          //     } else {
          //       captions = captions + "-" + element;
          //     }
          //   });
          //   this.googleAnalyticsService.eventEmitter(
          //     "FlightSearch",
          //     this.data.data.tripType.replace("_", ""),
          //     captions,
          //     1
          //   );
          // }



          this.flightList = this.data.data.flightOffers;    ///this.flightList = this.data.data.itineraries;
          //console.log('this.flightList' , this.flightList);
          this.flightList.forEach((item, index) => {
            item.index = index;

            // Duration filter

          /*  let timeInMinutes =
              item.originDestinationOptionsGroups[0].summary
                .journeyDurationInMins;
            if (this.Params.trip === "ROUNDTRIP") {
              timeInMinutes = Math.max(
                item.originDestinationOptionsGroups[0].summary
                  .journeyDurationInMins,
                item.originDestinationOptionsGroups[1].summary
                  .journeyDurationInMins
              );
            }*/

            let timeInMinutes = this.flightListService.convertDurationToMinutes(item.itineraries[0].duration);

            if (this.Params.trip === "ROUNDTRIP") {
              timeInMinutes = Math.max(
                  this.flightListService.convertDurationToMinutes(item.itineraries[0].duration),
                  this.flightListService.convertDurationToMinutes(item.itineraries[1].duration)
              );
            }



            if (index === 0) {
              this.minimumMins = timeInMinutes;
              this.maxMins = timeInMinutes;
            } else {
              if (timeInMinutes < this.minimumMins) {
                this.minimumMins = timeInMinutes;
              }
              if (timeInMinutes > this.maxMins) {
                this.maxMins = timeInMinutes;
              }
            }

            // Departure Time filter
            let time = this.datePipe.transform(
              //item.originDestinationOptionsGroups[0].summary.departureDate,
                item.itineraries[0].searchSegmentSummary.departureDate,
              "hh:mma"
            );
            let timeArray = time.split(":");
            let hours = timeArray[0];

            if (time.includes("AM") || time.includes("ص")) {
              if (Number(hours) >= 12 || Number(hours) <= 4) {
                this.departureSeg1[0].counter++;
              }
            }

            if (time.includes("AM") || time.includes("ص")) {
              if (Number(hours) >= 5 && Number(hours) <= 11) {
                this.departureSeg1[1].counter++;
              }
            }

            if (time.includes("PM") || time.includes("م")) {
              if (Number(hours) >= 12 || Number(hours) <= 5) {
                this.departureSeg1[2].counter++;
              }
            }

            if (time.includes("PM") || time.includes("م")) {
              if (Number(hours) >= 6 && Number(hours) <= 12) {
                this.departureSeg1[3].counter++;
              }
            }

            if (this.Params.trip === "ROUNDTRIP") {
              let timeArrival = this.datePipe.transform(
               // item.originDestinationOptionsGroups[1].summary.departureDate,
                  item.itineraries[1].searchSegmentSummary.departureDate,
                "hh:mma"
              );
              let timeArrayArrival = timeArrival.split(":");
              let hoursArrival = timeArrayArrival[0];

              if (timeArrival.includes("AM") || timeArrival.includes("ص")) {
                if (Number(hoursArrival) >= 12 || Number(hoursArrival) <= 4) {
                  this.departureSeg2[0].counter++;
                }
              }

              if (timeArrival.includes("AM") || timeArrival.includes("ص")) {
                if (Number(hoursArrival) >= 5 && Number(hoursArrival) <= 11) {
                  this.departureSeg2[1].counter++;
                }
              }

              if (timeArrival.includes("PM") || timeArrival.includes("م")) {
                if (Number(hoursArrival) >= 12 || Number(hoursArrival) <= 5) {
                  this.departureSeg2[2].counter++;
                }
              }

              if (timeArrival.includes("PM") || timeArrival.includes("م")) {
                if (Number(hoursArrival) >= 6 && Number(hoursArrival) <= 12) {
                  this.departureSeg2[3].counter++;
                }
              }
            }

            // stops filters
            // if (
            //   (this.Params.trip === "ONEWAY" &&
            //   item.itineraries[0].searchSegmentSummary.totalStops ===
            //    // item.originDestinationOptionsGroups[0].summary.totalStops ===
            //       0) ||
            //   (this.Params.trip === "ROUNDTRIP" &&
            //     Math.max(
            //     /*  item.originDestinationOptionsGroups[0].summary.totalStops,
            //       item.originDestinationOptionsGroups[1].summary.totalStops*/
            //         item.itineraries[0].searchSegmentSummary.totalStops,
            //         item.itineraries[1].searchSegmentSummary.totalStops
            //     ) === 0)
            // ) {
            //   this.stops[0].counter++;
            //   /*if (this.stops[0].counter === 1) {
            //     this.stops[0].price =
            //       item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
            //   }
            //   if (
            //     item.fareInfo.sellingTotalFares.totalPerPassenger.amount <
            //     this.stops[0].price
            //   ) {
            //     this.stops[0].price =
            //       item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
            //   }*/
            // } else if (
            //   (this.Params.trip === "ONEWAY" &&
            //    // item.originDestinationOptionsGroups[0].summary.totalStops ===
            //       item.itineraries[0].searchSegmentSummary.totalStops ===
            //       1) ||
            //   (this.Params.trip === "ROUNDTRIP" &&
            //     Math.max(
            //      /* item.originDestinationOptionsGroups[0].summary.totalStops,
            //       item.originDestinationOptionsGroups[1].summary.totalStops*/
            //         item.itineraries[0].searchSegmentSummary.totalStops,
            //         item.itineraries[1].searchSegmentSummary.totalStops
            //     ) === 1)
            // ) {
            //   this.stops[1].counter++;
            //  /* if (this.stops[1].counter === 1) {
            //     this.stops[1].price =
            //       item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
            //   }
            //   if (
            //     item.fareInfo.sellingTotalFares.totalPerPassenger.amount <
            //     this.stops[1].price
            //   ) {
            //     this.stops[1].price =
            //       item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
            //   }*/
            // } else if (
            //   (this.Params.trip === "ONEWAY" &&
            //     //item.originDestinationOptionsGroups[0].summary.totalStops >
            //       item.itineraries[0].searchSegmentSummary.totalStops >
            //       1) ||
            //   (this.Params.trip === "ROUNDTRIP" &&
            //     Math.max(
            //      /* item.originDestinationOptionsGroups[0].summary.totalStops,
            //       item.originDestinationOptionsGroups[1].summary.totalStops*/
            //         item.itineraries[0].searchSegmentSummary.totalStops,
            //         item.itineraries[1].searchSegmentSummary.totalStops
            //     ) > 1)
            // ) {
            //   this.stops[2].counter++;
            //  /* if (this.stops[2].counter === 1) {
            //     this.stops[2].price =
            //       item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
            //   }
            //   if (
            //     item.fareInfo.sellingTotalFares.totalPerPassenger.amount <
            //     this.stops[2].price
            //   ) {
            //     this.stops[2].price =
            //       item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
            //   }*/
            // }
            // stops filters
          /*  if (this.Params.trip === "ONEWAY") {
              if (
                item.originDestinationOptionsGroups[0].summary
                  .airlineInfoSummary.marketingAirlines.length === 1
              ) {
                this.tempAirline.push(
                  item.originDestinationOptionsGroups[0].summary
                    .airlineInfoSummary.marketingAirlines[0]
                );
              }
            }

            if (
              item.originDestinationOptionsGroups[0].summary.departureAirport &&
              item.originDestinationOptionsGroups[0].summary.departureAirport
                .iata
            ) {
              this.tempAirports.push({
                city: item.originDestinationOptionsGroups[0].summary
                  .departureAirport.city,
                airport:
                  item.originDestinationOptionsGroups[0].summary
                    .departureAirport.iata,
                passengerPrice:
                  item.fareInfo.sellingTotalFares.totalPerPassenger.amount,
              });
            }

            if (
              item.originDestinationOptionsGroups[0].summary.arrivalAirport &&
              item.originDestinationOptionsGroups[0].summary.arrivalAirport.iata
            ) {
              this.tempAirports.push({
                city: item.originDestinationOptionsGroups[0].summary
                  .arrivalAirport.city,
                airport:
                  item.originDestinationOptionsGroups[0].summary.arrivalAirport
                    .iata,
                passengerPrice:
                  item.fareInfo.sellingTotalFares.totalPerPassenger.amount,
              });
            }
            if (this.Params.trip === "ROUNDTRIP") {
              if (
                item.originDestinationOptionsGroups[1].summary
                  .departureAirport &&
                item.originDestinationOptionsGroups[1].summary.departureAirport
                  .iata
              ) {
                this.tempAirports.push({
                  city: item.originDestinationOptionsGroups[1].summary
                    .departureAirport.city,
                  airport:
                    item.originDestinationOptionsGroups[1].summary
                      .departureAirport.iata,
                  passengerPrice:
                    item.fareInfo.sellingTotalFares.totalPerPassenger.amount,
                });
              }

              if (
                item.originDestinationOptionsGroups[1].summary.arrivalAirport &&
                item.originDestinationOptionsGroups[0].summary.arrivalAirport
                  .iata
              ) {
                this.tempAirports.push({
                  city: item.originDestinationOptionsGroups[1].summary
                    .arrivalAirport.city,
                  airport:
                    item.originDestinationOptionsGroups[1].summary
                      .arrivalAirport.iata,
                  passengerPrice:
                    item.fareInfo.sellingTotalFares.totalPerPassenger.amount,
                });
              }
              if (
                item.originDestinationOptionsGroups[0].summary
                  .airlineInfoSummary.marketingAirlines.length === 1 &&
                item.originDestinationOptionsGroups[1].summary
                  .airlineInfoSummary.marketingAirlines.length === 1 &&
                item.originDestinationOptionsGroups[0].summary
                  .airlineInfoSummary.marketingAirlines[0] ===
                  item.originDestinationOptionsGroups[1].summary
                    .airlineInfoSummary.marketingAirlines[0]
              ) {
                this.tempAirline.push(
                  item.originDestinationOptionsGroups[0].summary
                    .airlineInfoSummary.marketingAirlines[0]
                );
              }
            }*/
          });
          const hoursMinimumMins = this.minimumMins / 60;
          let roundHoursMinimumMins = Math.floor(hoursMinimumMins);
          const minutesFloor = (hoursMinimumMins - roundHoursMinimumMins) * 60;
          const roundMinutesFloor = Math.round(minutesFloor);
          if (roundMinutesFloor !== 0) {
            roundHoursMinimumMins = roundHoursMinimumMins + 1;
          }
          const hoursMaxMins = this.maxMins / 60;
          let roundHoursMaxMins = Math.floor(hoursMaxMins);
          const minutesCeil = (hoursMaxMins - roundHoursMaxMins) * 60;
          const roundMinutesCeil = Math.round(minutesCeil);
          if (roundMinutesCeil !== 0) {
            roundHoursMaxMins = roundHoursMaxMins + 1;
          }
          this.optionsHours = {
            floor: roundHoursMinimumMins,
            ceil: roundHoursMaxMins,
            step: 1,
            animate: false,
            showOuterSelectionBars: true,
          };
          if (this.Service.isSiteRtl()) {
            this.optionsHours.rightToLeft = true;
          }
          this.hoursValue = this.optionsHours.ceil;


         /* const airlinesTemp = {};
          this.tempAirline.forEach((item, index) => {
            {
              airlinesTemp[item] = (airlinesTemp[item] || 0) + 1;
            }
          });
          let i = 3;
          for (const [key, value] of Object.entries(airlinesTemp)) {
              this.airlines.push({
                id: i,
                isChecked: false,
                title: `${key}`,
                counter: `${value}`,
                price: 0,
                faded: false,
              });
            i++;
          }*/

       /*   if (this.Params.trip === "ONEWAY") {
            this.airlines.forEach((airlineItem, indexAirline) => {
              this.flightList.forEach((flightItem, indexFlight) => {
                if (
                  flightItem.originDestinationOptionsGroups[0].summary
                    .airlineInfoSummary.marketingAirlines.length === 1 &&
                  flightItem.originDestinationOptionsGroups[0].summary
                    .airlineInfoSummary.marketingAirlines[0] ===
                    airlineItem.title &&
                  airlineItem.price === 0
                ) {
                  airlineItem.price =
                    flightItem.fareInfo.sellingTotalFares.totalPerPassenger.amount;
                } else if (
                  flightItem.originDestinationOptionsGroups[0].summary
                    .airlineInfoSummary.marketingAirlines.length === 1 &&
                  flightItem.originDestinationOptionsGroups[0].summary
                    .airlineInfoSummary.marketingAirlines[0] ===
                    airlineItem.title &&
                  airlineItem.price > 0
                ) {
                  if (
                    flightItem.fareInfo.sellingTotalFares.totalPerPassenger
                      .amount < airlineItem.price
                  ) {
                    airlineItem.price =
                      flightItem.fareInfo.sellingTotalFares.totalPerPassenger.amount;
                  }
                }
              });
            });
          }

          if (this.Params.trip === "ROUNDTRIP") {
            this.airlines.forEach((airlineItem, indexAirline) => {
              this.flightList.forEach((flightItem, indexFlight) => {
                if (
                  flightItem.originDestinationOptionsGroups[0].summary
                    .airlineInfoSummary.marketingAirlines.length === 1 &&
                  flightItem.originDestinationOptionsGroups[1].summary
                    .airlineInfoSummary.marketingAirlines.length === 1 &&
                  flightItem.originDestinationOptionsGroups[0].summary
                    .airlineInfoSummary.marketingAirlines[0] ===
                    flightItem.originDestinationOptionsGroups[1].summary
                      .airlineInfoSummary.marketingAirlines[0] &&
                  flightItem.originDestinationOptionsGroups[0].summary
                    .airlineInfoSummary.marketingAirlines[0] ===
                    airlineItem.title &&
                  airlineItem.price === 0
                ) {
                  airlineItem.price =
                    flightItem.fareInfo.sellingTotalFares.totalPerPassenger.amount;
                } else if (
                  flightItem.originDestinationOptionsGroups[0].summary
                    .airlineInfoSummary.marketingAirlines.length === 1 &&
                  flightItem.originDestinationOptionsGroups[1].summary
                    .airlineInfoSummary.marketingAirlines.length === 1 &&
                  flightItem.originDestinationOptionsGroups[0].summary
                    .airlineInfoSummary.marketingAirlines[0] ===
                    flightItem.originDestinationOptionsGroups[1].summary
                      .airlineInfoSummary.marketingAirlines[0] &&
                  flightItem.originDestinationOptionsGroups[0].summary
                    .airlineInfoSummary.marketingAirlines[0] ===
                    airlineItem.title &&
                  airlineItem.price > 0
                ) {
                  if (
                    flightItem.fareInfo.sellingTotalFares.totalPerPassenger
                      .amount < airlineItem.price
                  ) {
                    airlineItem.price =
                      flightItem.fareInfo.sellingTotalFares.totalPerPassenger.amount;
                  }
                }
              });
            });
          }*/

          // end of airlines

          const groupByCity = this.tempAirports.reduce(function (r, a) {
            r[a.city] = r[a.city] || [];
            r[a.city].push(a);
            return r;
          }, Object.create(null));
          const cities = Object.keys(groupByCity);
          let inc = 0;
          cities.forEach((elementCity, index) => {
            const groupByAirport = groupByCity[cities[index]].reduce(function (
              r,
              a
            ) {
              r[a.airport] = r[a.airport] || [];
              r[a.airport].push(a);
              return r;
            },
            Object.create(null));
            const airports = Object.keys(groupByAirport);
            this.airports.push({
              city: elementCity,
              airports: [],
            });
           /* airports.forEach((elementAirport, indexAir) => {
              const price = groupByAirport[airports[indexAir]].reduce(
                (min, p) => (p.passengerPrice < min ? p.passengerPrice : min),
                groupByAirport[airports[indexAir]][0].passengerPrice
              );
              inc++;
              const itemId =
                3 +
                this.airlines.length +
                index +
                indexAir +
                inc +
                Math.floor(Math.random() * 1000);
              this.airports[index].airports.push({
                name: elementAirport,
                minPrice: price,
                id: itemId,
                isChecked: false,
              });
            });*/
          });

          // departure
          const tempArray = this.flightList;
          //this.checkBestCheapestShortest(tempArray);

          this.currentFlightList = this.flightList.slice(
            0,
            this.numberOfFlights
          );
          this.upSellingLoading = this.flightList.map(() => new BehaviorSubject<boolean>(false));
          this.flightListService.isCardDetailsVisible = this.flightList.map(() => new BehaviorSubject<boolean>(false));

          this.Service.flightListLoader = false;
          this.sortingEnabled = true;
          this.Service.flightRatesDuration("flightListExpiry");
        } else {
          this.Service.flightListLoader = false;
          this.errorData = true;
          this.Service.messages(this.data.message, "warning");
        }
      },
      (error) => {
        this.errorData = true;
        this.Service.messages(environment.messages.error, "error");
        this.Service.flightListLoader = false;
      }
    );
  }

  fillFlightSearchCations() {
    if (this.data?.data?.searchRequest?.airQueries) {
      let airQueries = this.data.data.searchRequest.airQueries;
      this.Service.captionDepart = airQueries[0].departureCity + " (" + airQueries[0].departureSearchCode + " - " + airQueries[0].departureAirport + ")";
      this.Service.captionReturn = airQueries[0].arrivalCity + " (" + airQueries[0].arrivalSearchCode + " - " + airQueries[0].arrivalAirport + ")";

      if(this.data.data.tripType === 'MULTI_CITY' || this.data.data.tripType === 'ROUND_TRIP'){
        // Clear the arrays before populating them to ensure no leftover data
        this.Service.captionDepartArray = [];
        this.Service.captionArriveArray = [];

        // Loop through the airQueries array and populate captions
        for (let i = 0; i < airQueries.length; i++) {
          const query = airQueries[i];

          // Populate departure array
          this.Service.captionDepartArray.push(
              `${query.departureCity} (${query.departureSearchCode} - ${query.departureAirport})`
          );

          // Populate arrival array
          this.Service.captionArriveArray.push(
              `${query.arrivalCity} (${query.arrivalSearchCode} - ${query.arrivalAirport})`
          );
        }
      } else {
        this.Service.captionDepartArray[0] = airQueries[0].departureCity + " (" + airQueries[0].departureSearchCode + " - " + airQueries[0].departureAirport + ")";
        this.Service.captionArriveArray[0] = airQueries[0].arrivalCity + " (" + airQueries[0].arrivalSearchCode + " - " + airQueries[0].arrivalAirport + ")";
        // Trim extra elements from captionDepartArray if it is longer than airQueries
        if (this.Service.captionDepartArray.length > airQueries.length) {
          this.Service.captionDepartArray = this.Service.captionDepartArray.slice(0, airQueries.length);
        }

        // Trim extra elements from captionArriveArray if it is longer than airQueries
        if (this.Service.captionArriveArray.length > airQueries.length) {
          this.Service.captionArriveArray = this.Service.captionArriveArray.slice(0, airQueries.length);
        }

        for (let i = 1; i < 6 - 1; i++) {
          const currentArriveKey = `lastSearchArrive${i}`;
          const currentDepartKey = `lastSearchDepart${i}`;



          if (currentArriveKey !== null) {
            // remove the current key
            localStorage.removeItem(currentArriveKey);
          }

          if (currentDepartKey !== null) {
            //  remove the current key
            localStorage.removeItem(currentDepartKey);
          }
          this.removeItemFromLocalStorageByIndex(i, 'captionArriveArray');
          this.removeItemFromLocalStorageByIndex(i, 'captionDepartArray')
        }

      }



    }
  }

  removeItemFromLocalStorageByIndex(index: number, arrayKey: string) {
    // Retrieve the array from localStorage
    const arrayString = localStorage.getItem(arrayKey); // Replace with your actual localStorage key

    if (arrayString) {
      // Parse the string into an array
      let array = JSON.parse(arrayString);

      // Check if the index is within bounds
      if (index >= 0 && index < array.length) {
        // Remove the item at the specified index
        array.splice(index, 1);

        // Save the updated array back to localStorage
        localStorage.setItem(arrayKey, JSON.stringify(array)); // Replace with your actual localStorage key
      }
    }
  }

  getPolicyBoxDetails(){
    if (this.data.data.extraData && this.data.data.extraData.policies) {
      this.showWithinPolicyFilter =
          this.data.data.extraData.displayCompanyPoliciesFilter;
      if (this.data.data.extraData.policies && this.data.data.extraData.policies.length) {
        this.policyDetails = this.data.data.extraData.policies[0];
        console.log(this.policyDetails);
        //this.Service.setApplicablePolicyDetails(this.policyDetails);
        this.VarsService.classType.forEach((element) => {
          if (
              this.policyDetails.companyPolicyFlightRules &&
              this.policyDetails.companyPolicyFlightRules
                  .maximumCabinClass === element.value
          ) {
            this.applicablePolicySelectedClass = element.name;
          }
        });
        if (
            this.policyDetails.companyPolicyFlightRules &&
            this.policyDetails.companyPolicyFlightRules.maximumNumberOfTrips
        ) {
          this.maxNoOfTrips =
              this.policyDetails.companyPolicyFlightRules.maximumNumberOfTrips;
          if (
              this.policyDetails.companyPolicyFlightRules.maximumNumberOfTripsInterval === "MONTHLY"
          ) {
            this.maxNoInterval = $localize`:@@forEachMonth:Month`;
          } else {
            this.maxNoInterval = $localize`:@@forEachYear:Year`;
          }
        }
      }
    }
  }

  prepareLeftSideFilters(){
    const stopKeys = ['non_stop', 'one_stop', 'two_stops'];
    stopKeys.forEach((key, index) => {
      const stopData = this.data.data.filters.stops[key] || {};
      this.stops[index].counter = stopData.count || 0;
      this.stops[index].price = stopData.starting || null;
      this.stops[index].currency = stopData.currency || null;
    });

    this.airlines = this.convertAirlinesObjectToArray(this.data.data.filters.airlines);
  }

  convertAirlinesObjectToArray(airlines: Record<string, Airline>): any[] {
    const result: any[] = [];
    let id = 3; // that because the stop array length is either 2 or 3 so we want to start the index from 3 to have unique id

    for (const [key, value] of Object.entries(airlines)) {
      // Explicitly cast value as AirlineData to access its properties
      const airlineDetails = value as Airline;

      result.push({
        id: id++,
        isChecked: false,
        title: key,
        counter: airlineDetails.count,
        price: airlineDetails.starting,
        currency: airlineDetails.currency,
        faded: false,
      });
    }
    console.log(result);
    return result;
  }

  airportsFunc(airport, price, index) {
    if (index === 0) {
      this.tempAirports.push({
        city: airport.city,
        airports: [
          {
            name: airport.iata,
            minPrice: price,
          },
        ],
      });
    } else {
      this.tempAirports.forEach((mainAirportItem) => {
        if (mainAirportItem.city === airport.city) {
          mainAirportItem.airports.forEach((airportItem) => {
            if (airportItem.name === airport.iata) {
              if (airport.iata < airportItem.minPrice) {
                airportItem.minPrice = price;
              }
            } else {
              this.tempAirports.push({
                city: airport.city,
                airports: [
                  {
                    name: airport.iata,
                    minPrice: price,
                  },
                ],
              });
            }
          });
        } else {
          this.tempAirports.push({
            city: airport.city,
            airports: [
              {
                name: airport.iata,
                minPrice: price,
              },
            ],
          });
        }
      });
    }
  }


  showDetails(itemTransactionId) {
    this.Service.flightDetailsInListOn = true;
    const flightData = {
      requestObj: {
        transactionId: itemTransactionId,
        travelers: [
          {
            travelerId: this.travelerId,
            travelerType: "ADULT"
          }
        ]
      },
    };
    this.Service.setRightSideDrawer("flightDetails", flightData);
  }

  smallestNumberOfSeatsFunc(
    originDestinationOptions0,
    originDestinationOptions1
  ) {
    const arrayOfSeats = [];
    let result;
    originDestinationOptions0.forEach((element) => {
      if (element.seatsReamining <= 5) {
        arrayOfSeats.push(element.seatsReamining);
      }
    });
    if (originDestinationOptions1) {
      originDestinationOptions1.forEach((element) => {
        if (element.seatsReamining <= 5) {
          arrayOfSeats.push(element.seatsReamining);
        }
      });
    }
    result = Math.min.apply(null, arrayOfSeats);
    if (result === 1) {
      return result + " " + $localize`:@@oneSeatLeft:seat left`;
    } else if (result <= 5) {
      return result + " " + $localize`:@@seatsLeft:seats left`;
    } else {
      return "";
    }
  }

  smallestNumberOfSeats(originDestinationOptions) {
    const arrayOfSeats = [];
    let result;
    originDestinationOptions.forEach((element) => {
      arrayOfSeats.push(element.seatsReamining);
    });
    result = Math.min.apply(null, arrayOfSeats);
    if (result <= 1) {
      return result + " seat left";
    } else {
      return result + " seats left";
    }
  }

  clearDepartureFilters() {
    this.departureSeg1.forEach((item) => {
      item.isChecked = false;
      item.faded = false;
    });
    this.departureSeg2.forEach((item) => {
      item.isChecked = false;
      item.faded = false;
    });
    this.stops.forEach((item) => {
      item.isChecked = false;
      item.faded = false;
    });
    this.airlines.forEach((item) => {
      item.isChecked = false;
      item.faded = false;
    });
    this.airports.forEach((city) => {
      city.airports.forEach((airport) => {
        airport.isChecked = false;
        airport.faded = false;
      });
    });
    this.filterInActive();
    this.departSeg1Array = [];
    this.departSeg2Array = [];
    this.applyfilters();
  }
  clearFilters() {
    this.filterByDateCheck = new Array(6).fill(false);
  this.selectedDatesByItinerary = {
      0: { departureDate: null, arrivalDate: null },
      1: { departureDate: null, arrivalDate: null },
      2: { departureDate: null, arrivalDate: null },
      3: { departureDate: null, arrivalDate: null },
      4: { departureDate: null, arrivalDate: null },
      5: { departureDate: null, arrivalDate: null }
    };


    this.stops.forEach((item) => {
      item.isChecked = false;
      item.faded = false;
    });
    this.airlines.forEach((item) => {
      item.isChecked = false;
      item.faded = false;
    });
    this.airports.forEach((city) => {
      city.airports.forEach((airport) => {
        airport.isChecked = false;
        airport.faded = false;
      });
    });
    this.departureSeg1.forEach((item) => {
      item.isChecked = false;
      item.faded = false;
    });
    this.departureSeg2.forEach((item) => {
      item.isChecked = false;
      item.faded = false;
    });
    this.hoursValue = this.optionsHours.ceil;
    this.filterInActive();
    this.ParentSort = "default";
    this.SortType = "default";
    this.filterapplied = false;
    this.filterWithinPolicy = false;
    this.stopSelectedArray = [];
    this.departSeg1Array = [];
    this.departSeg2Array = [];
    this.airlinesSelectedArray = [];
    this.airportsSelectedArray = [];
    this.airportsSelectedArrayWithCity = [];
    this.cityArrayForAirports = [];
    // this.filterByDepartDate0 = null;
    // this.filterByArrivalDate0 = null;
    // this.filterByDepartDate1 = null;
    // this.filterByArrivalDate1 = null;
    this.currentFlightList = this.flightList.slice(0, this.numberOfFlights);
    this.filterreditems = [];
    this.applyfilters();
    this.filteringActive = false;
  }
  sortFunc(type, parent) {
    this.Service.flightFilter = true;
    this.activeSort = true;
    this.Service.flightListLoader = true;
    this.SortType = type;
    this.ParentSort = parent;
    if (this.filterreditems.length === 0 && !this.filterapplied) {
      this.filterreditems = this.flightList;
    }
    if (type === "default") {
      this.activeSort = false;
      this.filterreditems.sort((a, b) => {
        if (a.index > b.index) {
          return 1;
        } else if (b.index > a.index) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    if (type === "cheapest") {
      this.filterreditems = this.checkCheapest(this.filterreditems);
    }
    if (type === "shortest") {
      this.filterreditems = this.checkShortest(this.filterreditems);
    }
    if (type === "best") {
      this.filterreditems = this.checkBest(this.filterreditems);
    }
    //this.checkBestCheapestShortest(this.filterreditems);
    this.numberOfFlights = 10;
    this.currentFlightList = this.filterreditems.slice(0, this.numberOfFlights);
    this.filterapplied = true;

    setTimeout(() => {
      this.Service.flightListLoader = false;
    }, 1000);
  }
  checkShortest(array) {
    if (this.Params.trip === "ONEWAY") {
      array.sort((a, b) => {
        if (
          a.originDestinationOptionsGroups[0].summary.journeyDurationInMins >
          b.originDestinationOptionsGroups[0].summary.journeyDurationInMins
        ) {
          return 1;
        } else if (
          b.originDestinationOptionsGroups[0].summary.journeyDurationInMins >
          a.originDestinationOptionsGroups[0].summary.journeyDurationInMins
        ) {
          return -1;
        } else {
          return 0;
        }
      });
    } else if (this.Params.trip === "ROUNDTRIP") {
      array.sort((a, b) => {
        if (
          a.originDestinationOptionsGroups[0].summary.journeyDurationInMins +
            a.originDestinationOptionsGroups[1].summary.journeyDurationInMins >
          b.originDestinationOptionsGroups[0].summary.journeyDurationInMins +
            b.originDestinationOptionsGroups[1].summary.journeyDurationInMins
        ) {
          return 1;
        } else if (
          b.originDestinationOptionsGroups[0].summary.journeyDurationInMins +
            b.originDestinationOptionsGroups[1].summary.journeyDurationInMins >
          a.originDestinationOptionsGroups[0].summary.journeyDurationInMins +
            a.originDestinationOptionsGroups[1].summary.journeyDurationInMins
        ) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    return array;
  }
  checkCheapest(array) {
    array.sort((a, b) => {
      if (
        a.fareInfo.sellingTotalFares.totalPerPassenger.amount >
        b.fareInfo.sellingTotalFares.totalPerPassenger.amount
      ) {
        return 1;
      } else if (
        b.fareInfo.sellingTotalFares.totalPerPassenger.amount >
        a.fareInfo.sellingTotalFares.totalPerPassenger.amount
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    return array;
  }
  checkBest(array) {
    const with0stops = [];
    const with1stops = [];
    const with2stops = [];
    if (this.Params.trip === "ONEWAY") {
      array.forEach((item) => {
        if (item.originDestinationOptionsGroups[0].summary.totalStops === 0) {
          with0stops.push(item);
        } else if (
          item.originDestinationOptionsGroups[0].summary.totalStops === 1
        ) {
          with1stops.push(item);
        } else {
          with2stops.push(item);
        }
      });

      with0stops.sort((a, b) => {
        if (
          a.fareInfo.sellingTotalFares.totalPerPassenger.amount >
          b.fareInfo.sellingTotalFares.totalPerPassenger.amount
        ) {
          return 1;
        } else if (
          b.fareInfo.sellingTotalFares.totalPerPassenger.amount >
          a.fareInfo.sellingTotalFares.totalPerPassenger.amount
        ) {
          return -1;
        } else {
          return 0;
        }
      });
      with1stops.sort((a, b) => {
        if (
          a.fareInfo.sellingTotalFares.totalPerPassenger.amount >
          b.fareInfo.sellingTotalFares.totalPerPassenger.amount
        ) {
          return 1;
        } else if (
          b.fareInfo.sellingTotalFares.totalPerPassenger.amount >
          a.fareInfo.sellingTotalFares.totalPerPassenger.amount
        ) {
          return -1;
        } else {
          return 0;
        }
      });
      with2stops.sort((a, b) => {
        if (
          a.fareInfo.sellingTotalFares.totalPerPassenger.amount >
          b.fareInfo.sellingTotalFares.totalPerPassenger.amount
        ) {
          return 1;
        } else if (
          b.fareInfo.sellingTotalFares.totalPerPassenger.amount >
          a.fareInfo.sellingTotalFares.totalPerPassenger.amount
        ) {
          return -1;
        } else {
          return 0;
        }
      });
    } else if (this.Params.trip === "ROUNDTRIP") {
      array.forEach((item) => {
        if (
          item.originDestinationOptionsGroups[0].summary.totalStops +
            item.originDestinationOptionsGroups[1].summary.totalStops ===
          0
        ) {
          with0stops.push(item);
        } else if (
          item.originDestinationOptionsGroups[0].summary.totalStops +
            item.originDestinationOptionsGroups[1].summary.totalStops ===
          1
        ) {
          with1stops.push(item);
        } else {
          with2stops.push(item);
        }
      });
      with0stops.sort((a, b) => {
        if (
          a.fareInfo.sellingTotalFares.totalPerPassenger.amount >
          b.fareInfo.sellingTotalFares.totalPerPassenger.amount
        ) {
          return 1;
        } else if (
          b.fareInfo.sellingTotalFares.totalPerPassenger.amount >
          a.fareInfo.sellingTotalFares.totalPerPassenger.amount
        ) {
          return -1;
        } else {
          return 0;
        }
      });
      with1stops.sort((a, b) => {
        if (
          a.fareInfo.sellingTotalFares.totalPerPassenger.amount >
          b.fareInfo.sellingTotalFares.totalPerPassenger.amount
        ) {
          return 1;
        } else if (
          b.fareInfo.sellingTotalFares.totalPerPassenger.amount >
          a.fareInfo.sellingTotalFares.totalPerPassenger.amount
        ) {
          return -1;
        } else {
          return 0;
        }
      });
      with2stops.sort((a, b) => {
        if (
          a.fareInfo.sellingTotalFares.totalPerPassenger.amount >
          b.fareInfo.sellingTotalFares.totalPerPassenger.amount
        ) {
          return 1;
        } else if (
          b.fareInfo.sellingTotalFares.totalPerPassenger.amount >
          a.fareInfo.sellingTotalFares.totalPerPassenger.amount
        ) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    array = with0stops.concat(with1stops, with2stops);
    return array;
  }

  checkBestCheapestShortest(tempArray) {
    let minPrice = 0;
    let minDuration = 0;
    let bestMinPrice = 0;
    const with0stops = [];
    const with1stops = [];
    const with2stops = [];
    if (this.Params.trip === "ONEWAY") {
      tempArray.forEach((item, index) => {
        if (item.originDestinationOptionsGroups[0].summary.totalStops === 0) {
          with0stops.push(item);
        } else if (
          item.originDestinationOptionsGroups[0].summary.totalStops === 1
        ) {
          with1stops.push(item);
        } else {
          with2stops.push(item);
        }
        if (index === 0) {
          minPrice = item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
          this.cheapestFlightItem = item;
          minDuration =
            item.originDestinationOptionsGroups[0].summary
              .journeyDurationInMins;
          this.shortestFlightItem = item;
        } else {
          if (
            item.fareInfo.sellingTotalFares.totalPerPassenger.amount < minPrice
          ) {
            minPrice = item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
            this.cheapestFlightItem = item;
          }
          if (
            item.originDestinationOptionsGroups[0].summary
              .journeyDurationInMins < minDuration
          ) {
            minDuration =
              item.originDestinationOptionsGroups[0].summary
                .journeyDurationInMins;
            this.shortestFlightItem = item;
          }
        }
      });
    } else if (this.Params.trip === "ROUNDTRIP") {
      tempArray.forEach((item, index) => {
        if (
          item.originDestinationOptionsGroups[0].summary.totalStops +
            item.originDestinationOptionsGroups[1].summary.totalStops ===
          0
        ) {
          with0stops.push(item);
        } else if (
          item.originDestinationOptionsGroups[0].summary.totalStops +
            item.originDestinationOptionsGroups[1].summary.totalStops ===
          1
        ) {
          with1stops.push(item);
        } else {
          with2stops.push(item);
        }
        if (index === 0) {
          minPrice = item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
          this.cheapestFlightItem = item;
          minDuration =
            item.originDestinationOptionsGroups[0].summary
              .journeyDurationInMins +
            item.originDestinationOptionsGroups[1].summary
              .journeyDurationInMins;
          this.shortestFlightItem = item;
        } else {
          if (
            item.fareInfo.sellingTotalFares.totalPerPassenger.amount < minPrice
          ) {
            minPrice = item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
            this.cheapestFlightItem = item;
          }
          if (
            item.originDestinationOptionsGroups[0].summary
              .journeyDurationInMins +
              item.originDestinationOptionsGroups[1].summary
                .journeyDurationInMins <
            minDuration
          ) {
            minDuration =
              item.originDestinationOptionsGroups[0].summary
                .journeyDurationInMins +
              item.originDestinationOptionsGroups[1].summary
                .journeyDurationInMins;
            this.shortestFlightItem = item;
          }
        }
      });
    }

    if (with0stops.length) {
      with0stops.forEach((item, index) => {
        if (index === 0) {
          bestMinPrice =
            item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
          this.bestFlightItem = item;
        } else {
          if (
            item.fareInfo.sellingTotalFares.totalPerPassenger.amount <
            bestMinPrice
          ) {
            bestMinPrice =
              item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
            this.bestFlightItem = item;
          }
        }
      });
    } else if (with1stops.length) {
      with1stops.forEach((item, index) => {
        if (index === 0) {
          bestMinPrice =
            item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
          this.bestFlightItem = item;
        } else {
          if (
            item.fareInfo.sellingTotalFares.totalPerPassenger.amount <
            bestMinPrice
          ) {
            bestMinPrice =
              item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
            this.bestFlightItem = item;
          }
        }
      });
    } else if (with2stops.length) {
      with2stops.forEach((item, index) => {
        if (index === 0) {
          bestMinPrice =
            item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
          this.bestFlightItem = item;
        } else {
          if (
            item.fareInfo.sellingTotalFares.totalPerPassenger.amount <
            bestMinPrice
          ) {
            bestMinPrice =
              item.fareInfo.sellingTotalFares.totalPerPassenger.amount;
            this.bestFlightItem = item;
          }
        }
      });
    }
  }

  calDurationAverage(flightItem) {
    let timeInMinutes;
    if (this.Params.trip === "ONEWAY") {
      timeInMinutes =
        flightItem.originDestinationOptionsGroups[0].summary
          .journeyDurationInMins;
    } else if (this.Params.trip === "ROUNDTRIP") {
      timeInMinutes =
        (flightItem.originDestinationOptionsGroups[0].summary
          .journeyDurationInMins +
          flightItem.originDestinationOptionsGroups[1].summary
            .journeyDurationInMins) /
        2;
    }
    const hours = timeInMinutes / 60;
    const roundHours = Math.floor(hours);
    const minutes = (hours - roundHours) * 60;
    const roundMinutes = Math.round(minutes);
    return roundHours + "h " + roundMinutes + "m";
  }
  filterChangePolicyFunc() {
    this.filteringActive = true;
  }

  removeFilterItem(itemId, arrayName) {
    setTimeout(() => {
      const arrayItemId = itemId;
      this.filteringActive = true;
      if (arrayName === "time") {
        this.departureSeg1.forEach((item) => {
          if (item.id === arrayItemId) {
            item.isChecked = false;
            this.departSeg1Array = this.departSeg1Array.filter(
              (itemFiltered) => itemFiltered !== item.id
            );
          }
        });
      } else if (arrayName === "timeArrival") {
        this.departureSeg2.forEach((item) => {
          if (item.id === arrayItemId) {
            item.isChecked = false;
            this.departSeg2Array = this.departSeg2Array.filter(
              (itemFiltered) => itemFiltered !== item.id
            );
          }
        });
      } else if (arrayName === "stops") {
        this.stops.forEach((item) => {
          if (item.id === arrayItemId) {
            item.isChecked = false;
            this.stopSelectedArray = this.stopSelectedArray.filter(
              (itemFiltered) => itemFiltered !== item.id
            );
          }
        });
      }
      else if (arrayName === "airlines") {
        this.airlines.forEach((item) => {
          if (item.id === arrayItemId) {
            item.isChecked = false;
            this.airlinesSelectedArray = this.airlinesSelectedArray.filter(
              (itemFiltered) => itemFiltered !== item.title
            );
          }
        });
      }
      else if (arrayName === "airports") {
        this.cityArrayForAirports = [];
        this.airports.forEach((city) => {
          city.airports.forEach((airport) => {
            if (airport.id === arrayItemId) {
              airport.isChecked = false;
              this.airportsSelectedArray = this.airportsSelectedArray.filter(
                (itemFiltered) => itemFiltered !== airport.name
              );
            }

            if (
              airport.isChecked &&
              !this.cityArrayForAirports.includes(city)
            ) {
              this.cityArrayForAirports.push(city);
            }
          });
        });
      }
      this.departureSeg1.forEach((item) => {
        item.isChecked = false;
        item.faded = false;
      });
      this.departureSeg2.forEach((item) => {
        item.isChecked = false;
        item.faded = false;
      });
      this.stops.forEach((item) => {
        item.isChecked = false;
        item.faded = false;
      });
      // this.airlines.forEach((item) => {
      //   item.isChecked = false;
      //   item.faded = false;
      // });
      this.airports.forEach((city) => {
        city.airports.forEach((airport) => {
          airport.isChecked = false;
          airport.faded = false;
        });
      });
      this.Service.scrollTop();
      this.applyfilters();
    }, 500);
  }

  dataChanged(value, index, arrayName) {
    setTimeout(() => {
      const arrayItemId = value.target.attributes.id.nodeValue.replace("Q", "");
      this.filteringActive = true;
      if (value.target.checked === true) {
        if (arrayName === "stops") {
          this.stops.forEach((item) => {
            if (item.id === arrayItemId) {
              item.isChecked = true;
              this.stopSelectedArray.push(item.id);
            }
          });
        }
        else if (arrayName === "airlines") {
          this.airlines.forEach((item) => {
            if (item.id + "" === arrayItemId) {
              item.isChecked = true;
              this.airlinesSelectedArray.push(item.title);
            }
          });
        }
        else if (arrayName === "airports") {
          this.airports.forEach((city) => {
            city.airports.forEach((airport) => {
              if (airport.id + "" === arrayItemId) {
                airport.isChecked = true;
                this.airportsSelectedArray.push(airport.name);
                if (!this.cityArrayForAirports.includes(city)) {
                  this.cityArrayForAirports.push(city);
                }
              }
            });
          });
        } else if (arrayName === "time") {
          this.timeFilter = index;
          this.departureSeg1.forEach((item) => {
            if (item.id === arrayItemId) {
              item.isChecked = true;
              this.departSeg1Array.push(item.id);
            }
          });
        } else if (arrayName === "timeArrival") {
          this.timeFilterArrival = index;
          this.departureSeg2.forEach((item) => {
            if (item.id === arrayItemId) {
              item.isChecked = true;
              this.departSeg2Array.push(item.id);
            }
          });
        }
      } else {
        if (arrayName === "stops") {
          this.stops.forEach((item) => {
            if (item.id === arrayItemId) {
              item.isChecked = false;
              this.stopSelectedArray = this.stopSelectedArray.filter(
                (itemFiltered) => itemFiltered !== item.id
              );
            }
          });
        }
        else if (arrayName === "airlines") {
          this.airlines.forEach((item) => {
            if (item.id + "" === arrayItemId) {
              item.isChecked = false;
              this.airlinesSelectedArray = this.airlinesSelectedArray.filter(
                (itemFiltered) => itemFiltered !== item.title
              );
            }
          });
        }
        else if (arrayName === "airports") {
          this.cityArrayForAirports = [];
          this.airports.forEach((city) => {
            city.airports.forEach((airport) => {
              if (airport.id + "" === arrayItemId) {
                airport.isChecked = false;
                this.airportsSelectedArray = this.airportsSelectedArray.filter(
                  (itemFiltered) => itemFiltered !== airport.name
                );
              }
              if (
                airport.isChecked &&
                !this.cityArrayForAirports.includes(city)
              ) {
                this.cityArrayForAirports.push(city);
              }
            });
          });
        } else if (arrayName === "time") {
          this.timeFilter = index;
          this.departureSeg1.forEach((item) => {
            if (item.id === arrayItemId) {
              item.isChecked = false;
              this.departSeg1Array = this.departSeg1Array.filter(
                (itemFiltered) => itemFiltered !== item.id
              );
            }
          });
        } else if (arrayName === "timeArrival") {
          this.timeFilterArrival = index;
          this.departureSeg2.forEach((item) => {
            if (item.id === arrayItemId) {
              item.isChecked = false;
              this.departSeg2Array = this.departSeg2Array.filter(
                (itemFiltered) => itemFiltered !== item.id
              );
            }
          });
        }
      }

      this.Service.scrollTop();
      this.applyfilters();
      this.filterSideFadedItems(arrayName);
    }, 500);

    this.activecheckbox = index;
  }

   filterSideFadedItems(arrayName) {
  //   if (arrayName === "duration") {
  //     if (this.hoursValue < this.optionsHours.ceil) {
  //       this.stops.forEach((stop) => {
  //         let tempArray = this.filterreditems.filter((item) => {
  //           if (stop.id === "2" && this.Params.trip === "ONEWAY") {
  //             return (
  //               item.originDestinationOptionsGroups[0].summary.totalStops ===
  //                 2 ||
  //               item.originDestinationOptionsGroups[0].summary.totalStops > 2
  //             );
  //           } else if (
  //             this.Params.trip === "ONEWAY" &&
  //             item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
  //               .marketingAirlines.length === 1
  //           ) {
  //             return (
  //               "" +
  //                 item.originDestinationOptionsGroups[0].summary.totalStops ===
  //               stop.id
  //             );
  //           }
  //
  //           if (stop.id === "2" && this.Params.trip === "ROUNDTRIP") {
  //             return (
  //               Math.max(
  //                 item.originDestinationOptionsGroups[0].summary.totalStops,
  //                 item.originDestinationOptionsGroups[1].summary.totalStops
  //               ) === 2 ||
  //               Math.max(
  //                 item.originDestinationOptionsGroups[0].summary.totalStops,
  //                 item.originDestinationOptionsGroups[1].summary.totalStops
  //               ) > 2
  //             );
  //           } else {
  //             return (
  //               "" +
  //                 Math.max(
  //                   item.originDestinationOptionsGroups[0].summary.totalStops,
  //                   item.originDestinationOptionsGroups[1].summary.totalStops
  //                 ) ===
  //               stop.id
  //             );
  //           }
  //         });
  //         setTimeout(() => {
  //           if (tempArray.length === 0) {
  //             stop.faded = true;
  //           } else {
  //             stop.faded = false;
  //           }
  //         }, 300);
  //       });
  //
  //       if (this.Params.trip === "ROUNDTRIP") {
  //         this.departureSeg2.forEach((depart2Item) => {
  //           let tempArray = this.filterreditems.filter((item) => {
  //             let timeArrival = this.datePipe.transform(
  //               item.originDestinationOptionsGroups[1].summary.departureDate,
  //               "hh:mma"
  //             );
  //             let timeArrayArrival = timeArrival.split(":");
  //             let hoursArrival = timeArrayArrival[0];
  //
  //             if (
  //               (timeArrival.includes("AM") || timeArrival.includes("ص")) &&
  //               depart2Item.id === "earlyMorningArrival"
  //             ) {
  //               return Number(hoursArrival) <= 4;
  //             }
  //
  //             if (
  //               (timeArrival.includes("AM") || timeArrival.includes("ص")) &&
  //               depart2Item.id === "morningArrival"
  //             ) {
  //               return Number(hoursArrival) >= 5 && Number(hoursArrival) <= 11;
  //             }
  //
  //             if (
  //               (timeArrival.includes("PM") || timeArrival.includes("م")) &&
  //               depart2Item.id === "afternoonArrival"
  //             ) {
  //               return Number(hoursArrival) <= 5;
  //             }
  //
  //             if (
  //               (timeArrival.includes("PM") || timeArrival.includes("م")) &&
  //               depart2Item.id === "eveningArrival"
  //             ) {
  //               return Number(hoursArrival) >= 6 && Number(hoursArrival) <= 11;
  //             }
  //           });
  //           setTimeout(() => {
  //             if (tempArray.length === 0) {
  //               depart2Item.faded = true;
  //             } else {
  //               depart2Item.faded = false;
  //             }
  //           }, 300);
  //         });
  //       }
  //       this.departureSeg1.forEach((depart1Item) => {
  //         let tempArray = this.filterreditems.filter((item) => {
  //           let time = this.datePipe.transform(
  //             item.originDestinationOptionsGroups[0].summary.departureDate,
  //             "hh:mma"
  //           );
  //           let timeArray = time.split(":");
  //           let hours = timeArray[0];
  //
  //           if (
  //             (time.includes("AM") || time.includes("ص")) &&
  //             depart1Item.id === "earlyMorning"
  //           ) {
  //             return Number(hours) <= 4;
  //           }
  //
  //           if (
  //             (time.includes("AM") || time.includes("ص")) &&
  //             depart1Item.id === "morning"
  //           ) {
  //             return Number(hours) >= 5 && Number(hours) <= 11;
  //           }
  //
  //           if (
  //             (time.includes("PM") || time.includes("م")) &&
  //             depart1Item.id === "afternoon"
  //           ) {
  //             return Number(hours) <= 5;
  //           }
  //
  //           if (
  //             (time.includes("PM") || time.includes("م")) &&
  //             depart1Item.id === "evening"
  //           ) {
  //             return Number(hours) >= 6 && Number(hours) <= 11;
  //           }
  //         });
  //         setTimeout(() => {
  //           if (tempArray.length === 0) {
  //             depart1Item.faded = true;
  //           } else {
  //             depart1Item.faded = false;
  //           }
  //         }, 300);
  //       });
  //
  //       // this.airlines.forEach((airlineItem) => {
  //       //   let tempArray = this.filterreditems.filter((item) => {
  //       //     if (
  //       //       item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
  //       //         .marketingAirlines.length === 1 &&
  //       //       this.Params.trip === "ONEWAY"
  //       //     ) {
  //       //       if (this.stopSelectedArray.includes("2")) {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       } else {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       }
  //       //     }
  //       //     if (
  //       //       item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
  //       //         .marketingAirlines.length === 1 &&
  //       //       item.originDestinationOptionsGroups[1].summary.airlineInfoSummary
  //       //         .marketingAirlines.length === 1 &&
  //       //       this.Params.trip === "ROUNDTRIP"
  //       //     ) {
  //       //       if (this.stopSelectedArray.includes("2")) {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       } else {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       }
  //       //     }
  //       //   });
  //       //   setTimeout(() => {
  //       //     if (tempArray.length === 0) {
  //       //       airlineItem.faded = true;
  //       //     } else {
  //       //       airlineItem.faded = false;
  //       //     }
  //       //   }, 300);
  //       // });
  //
  //       this.airports.forEach((city) => {
  //         city.airports.forEach((airport) => {
  //           let tempArrayAirports = this.filterreditems.filter((item) => {
  //             if (
  //               this.stopSelectedArray.includes("2") &&
  //               this.Params.trip === "ONEWAY"
  //             ) {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             } else if (this.Params.trip === "ONEWAY") {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             }
  //
  //             if (
  //               this.stopSelectedArray.includes("2") &&
  //               this.Params.trip === "ROUNDTRIP"
  //             ) {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             } else {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             }
  //           });
  //
  //           setTimeout(() => {
  //             if (tempArrayAirports.length === 0) {
  //               airport.faded = true;
  //             } else {
  //               airport.faded = false;
  //             }
  //           }, 300);
  //         });
  //       });
  //     } else {
  //       this.stops.forEach((item) => {
  //         item.faded = false;
  //       });
  //       this.departureSeg2.forEach((item) => {
  //         item.faded = false;
  //       });
  //       this.departureSeg1.forEach((item) => {
  //         item.faded = false;
  //       });
  //       // this.airlines.forEach((item) => {
  //       //   item.faded = false;
  //       // });
  //       this.airports.forEach((city) => {
  //         city.airports.forEach((airport) => {
  //           airport.faded = false;
  //         });
  //       });
  //     }
  //   } else if (arrayName === "stops") {
  //     if (this.stopSelectedArray.length) {
  //       if (this.Params.trip === "ROUNDTRIP") {
  //         this.departureSeg2.forEach((depart2Item) => {
  //           let tempArray = this.filterreditems.filter((item) => {
  //             let timeArrival = this.datePipe.transform(
  //               item.originDestinationOptionsGroups[1].summary.departureDate,
  //               "hh:mma"
  //             );
  //             let timeArrayArrival = timeArrival.split(":");
  //             let hoursArrival = timeArrayArrival[0];
  //
  //             if (
  //               (timeArrival.includes("AM") || timeArrival.includes("ص")) &&
  //               depart2Item.id === "earlyMorningArrival"
  //             ) {
  //               return Number(hoursArrival) <= 4;
  //             }
  //
  //             if (
  //               (timeArrival.includes("AM") || timeArrival.includes("ص")) &&
  //               depart2Item.id === "morningArrival"
  //             ) {
  //               return Number(hoursArrival) >= 5 && Number(hoursArrival) <= 11;
  //             }
  //
  //             if (
  //               (timeArrival.includes("PM") || timeArrival.includes("م")) &&
  //               depart2Item.id === "afternoonArrival"
  //             ) {
  //               return Number(hoursArrival) <= 5;
  //             }
  //
  //             if (
  //               (timeArrival.includes("PM") || timeArrival.includes("م")) &&
  //               depart2Item.id === "eveningArrival"
  //             ) {
  //               return Number(hoursArrival) >= 6 && Number(hoursArrival) <= 11;
  //             }
  //           });
  //           setTimeout(() => {
  //             if (tempArray.length === 0) {
  //               depart2Item.faded = true;
  //             } else {
  //               depart2Item.faded = false;
  //             }
  //           }, 300);
  //         });
  //       }
  //       this.departureSeg1.forEach((depart1Item) => {
  //         let tempArray = this.filterreditems.filter((item) => {
  //           let time = this.datePipe.transform(
  //             item.originDestinationOptionsGroups[0].summary.departureDate,
  //             "hh:mma"
  //           );
  //           let timeArray = time.split(":");
  //           let hours = timeArray[0];
  //
  //           if (
  //             (time.includes("ص") || time.includes("AM")) &&
  //             depart1Item.id === "earlyMorning"
  //           ) {
  //             return Number(hours) <= 4;
  //           }
  //
  //           if (
  //             (time.includes("ص") || time.includes("AM")) &&
  //             depart1Item.id === "morning"
  //           ) {
  //             return Number(hours) >= 5 && Number(hours) <= 11;
  //           }
  //
  //           if (
  //             (time.includes("م") || time.includes("PM")) &&
  //             depart1Item.id === "afternoon"
  //           ) {
  //             return Number(hours) <= 5;
  //           }
  //
  //           if (
  //             (time.includes("م") || time.includes("PM")) &&
  //             depart1Item.id === "evening"
  //           ) {
  //             return Number(hours) >= 6 && Number(hours) <= 11;
  //           }
  //         });
  //         setTimeout(() => {
  //           if (tempArray.length === 0) {
  //             depart1Item.faded = true;
  //           } else {
  //             depart1Item.faded = false;
  //           }
  //         }, 300);
  //       });
  //
  //       // this.airlines.forEach((airlineItem) => {
  //       //   let tempArray = this.filterreditems.filter((item) => {
  //       //     if (
  //       //       item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
  //       //         .marketingAirlines.length === 1 &&
  //       //       this.Params.trip === "ONEWAY"
  //       //     ) {
  //       //       if (this.stopSelectedArray.includes("2")) {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       } else {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       }
  //       //     }
  //       //     if (
  //       //       item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
  //       //         .marketingAirlines.length === 1 &&
  //       //       item.originDestinationOptionsGroups[1].summary.airlineInfoSummary
  //       //         .marketingAirlines.length === 1 &&
  //       //       this.Params.trip === "ROUNDTRIP"
  //       //     ) {
  //       //       if (this.stopSelectedArray.includes("2")) {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       } else {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       }
  //       //     }
  //       //   });
  //       //   setTimeout(() => {
  //       //     if (tempArray.length === 0) {
  //       //       airlineItem.faded = true;
  //       //     } else {
  //       //       airlineItem.faded = false;
  //       //     }
  //       //   }, 300);
  //       // });
  //
  //       this.airports.forEach((city) => {
  //         city.airports.forEach((airport) => {
  //           let tempArrayAirports = this.filterreditems.filter((item) => {
  //             if (
  //               this.stopSelectedArray.includes("2") &&
  //               this.Params.trip === "ONEWAY"
  //             ) {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             } else if (this.Params.trip === "ONEWAY") {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             }
  //
  //             if (
  //               this.stopSelectedArray.includes("2") &&
  //               this.Params.trip === "ROUNDTRIP"
  //             ) {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             } else {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             }
  //           });
  //
  //           setTimeout(() => {
  //             if (tempArrayAirports.length === 0) {
  //               airport.faded = true;
  //             } else {
  //               airport.faded = false;
  //             }
  //           }, 300);
  //         });
  //       });
  //     } else {
  //       this.filterSideFadedItems("duration");
  //       this.departureSeg2.forEach((item) => {
  //         item.faded = false;
  //       });
  //       this.departureSeg1.forEach((item) => {
  //         item.faded = false;
  //       });
  //       // this.airlines.forEach((item) => {
  //       //   item.faded = false;
  //       // });
  //       this.airports.forEach((city) => {
  //         city.airports.forEach((airport) => {
  //           airport.faded = false;
  //         });
  //       });
  //     }
  //   } else if (arrayName === "airlines") {
  //     if (this.airlinesSelectedArray.length) {
  //       if (this.Params.trip === "ROUNDTRIP") {
  //         this.departureSeg2.forEach((depart2Item) => {
  //           let tempArray = this.filterreditems.filter((item) => {
  //             let timeArrival = this.datePipe.transform(
  //               item.originDestinationOptionsGroups[1].summary.departureDate,
  //               "hh:mma"
  //             );
  //             let timeArrayArrival = timeArrival.split(":");
  //             let hoursArrival = timeArrayArrival[0];
  //
  //             if (
  //               (timeArrival.includes("AM") || timeArrival.includes("ص")) &&
  //               depart2Item.id === "earlyMorningArrival"
  //             ) {
  //               return Number(hoursArrival) <= 4;
  //             }
  //
  //             if (
  //               (timeArrival.includes("AM") || timeArrival.includes("ص")) &&
  //               depart2Item.id === "morningArrival"
  //             ) {
  //               return Number(hoursArrival) >= 5 && Number(hoursArrival) <= 11;
  //             }
  //
  //             if (
  //               (timeArrival.includes("PM") || timeArrival.includes("م")) &&
  //               depart2Item.id === "afternoonArrival"
  //             ) {
  //               return Number(hoursArrival) <= 5;
  //             }
  //
  //             if (
  //               (timeArrival.includes("PM") || timeArrival.includes("م")) &&
  //               depart2Item.id === "eveningArrival"
  //             ) {
  //               return Number(hoursArrival) >= 6 && Number(hoursArrival) <= 11;
  //             }
  //           });
  //           setTimeout(() => {
  //             if (tempArray.length === 0) {
  //               depart2Item.faded = true;
  //             } else {
  //               depart2Item.faded = false;
  //             }
  //           }, 300);
  //         });
  //       }
  //       this.departureSeg1.forEach((depart1Item) => {
  //         let tempArray = this.filterreditems.filter((item) => {
  //           let time = this.datePipe.transform(
  //             item.originDestinationOptionsGroups[0].summary.departureDate,
  //             "hh:mma"
  //           );
  //           let timeArray = time.split(":");
  //           let hours = timeArray[0];
  //
  //           if (
  //             (time.includes("ص") || time.includes("AM")) &&
  //             depart1Item.id === "earlyMorning"
  //           ) {
  //             return Number(hours) <= 4;
  //           }
  //
  //           if (
  //             (time.includes("ص") || time.includes("AM")) &&
  //             depart1Item.id === "morning"
  //           ) {
  //             return Number(hours) >= 5 && Number(hours) <= 11;
  //           }
  //
  //           if (
  //             (time.includes("م") || time.includes("PM")) &&
  //             depart1Item.id === "afternoon"
  //           ) {
  //             return Number(hours) <= 5;
  //           }
  //
  //           if (
  //             (time.includes("م") || time.includes("PM")) &&
  //             depart1Item.id === "evening"
  //           ) {
  //             return Number(hours) >= 6 && Number(hours) <= 11;
  //           }
  //         });
  //         setTimeout(() => {
  //           if (tempArray.length === 0) {
  //             depart1Item.faded = true;
  //           } else {
  //             depart1Item.faded = false;
  //           }
  //         }, 300);
  //       });
  //
  //       this.stops.forEach((stop) => {
  //         let tempArray = this.filterreditems.filter((item) => {
  //           if (stop.id === "2" && this.Params.trip === "ONEWAY") {
  //             return (
  //               item.originDestinationOptionsGroups[0].summary.totalStops ===
  //                 2 ||
  //               item.originDestinationOptionsGroups[0].summary.totalStops > 2
  //             );
  //           } else if (
  //             this.Params.trip === "ONEWAY" &&
  //             item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
  //               .marketingAirlines.length === 1
  //           ) {
  //             return (
  //               "" +
  //                 item.originDestinationOptionsGroups[0].summary.totalStops ===
  //               stop.id
  //             );
  //           }
  //
  //           if (stop.id === "2" && this.Params.trip === "ROUNDTRIP") {
  //             return (
  //               Math.max(
  //                 item.originDestinationOptionsGroups[0].summary.totalStops,
  //                 item.originDestinationOptionsGroups[1].summary.totalStops
  //               ) === 2 ||
  //               Math.max(
  //                 item.originDestinationOptionsGroups[0].summary.totalStops,
  //                 item.originDestinationOptionsGroups[1].summary.totalStops
  //               ) > 2
  //             );
  //           } else {
  //             return (
  //               "" +
  //                 Math.max(
  //                   item.originDestinationOptionsGroups[0].summary.totalStops,
  //                   item.originDestinationOptionsGroups[1].summary.totalStops
  //                 ) ===
  //               stop.id
  //             );
  //           }
  //         });
  //         setTimeout(() => {
  //           if (tempArray.length === 0) {
  //             stop.faded = true;
  //           } else {
  //             stop.faded = false;
  //           }
  //         }, 300);
  //       });
  //
  //       this.airports.forEach((city) => {
  //         city.airports.forEach((airport) => {
  //           let tempArrayAirports = this.filterreditems.filter((item) => {
  //             if (this.Params.trip === "ONEWAY") {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             }
  //
  //             if (this.Params.trip === "ROUNDTRIP") {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             }
  //           });
  //
  //           setTimeout(() => {
  //             if (tempArrayAirports.length === 0) {
  //               airport.faded = true;
  //             } else {
  //               airport.faded = false;
  //             }
  //           }, 300);
  //         });
  //       });
  //     } else {
  //       this.filterSideFadedItems("duration");
  //       this.departureSeg2.forEach((item) => {
  //         item.faded = false;
  //       });
  //       this.departureSeg1.forEach((item) => {
  //         item.faded = false;
  //       });
  //       this.stops.forEach((item) => {
  //         item.faded = false;
  //       });
  //       this.airports.forEach((city) => {
  //         city.airports.forEach((airport) => {
  //           airport.faded = false;
  //         });
  //       });
  //     }
  //   } else if (arrayName === "airports") {
  //     if (this.airportsSelectedArray.length) {
  //       if (this.Params.trip === "ROUNDTRIP") {
  //         this.departureSeg2.forEach((depart2Item) => {
  //           let tempArray = this.filterreditems.filter((item) => {
  //             let timeArrival = this.datePipe.transform(
  //               item.originDestinationOptionsGroups[1].summary.departureDate,
  //               "hh:mma"
  //             );
  //             let timeArrayArrival = timeArrival.split(":");
  //             let hoursArrival = timeArrayArrival[0];
  //
  //             if (
  //               (timeArrival.includes("AM") || timeArrival.includes("ص")) &&
  //               depart2Item.id === "earlyMorningArrival"
  //             ) {
  //               return Number(hoursArrival) <= 4;
  //             }
  //
  //             if (
  //               (timeArrival.includes("AM") || timeArrival.includes("ص")) &&
  //               depart2Item.id === "morningArrival"
  //             ) {
  //               return Number(hoursArrival) >= 5 && Number(hoursArrival) <= 11;
  //             }
  //
  //             if (
  //               (timeArrival.includes("PM") || timeArrival.includes("م")) &&
  //               depart2Item.id === "afternoonArrival"
  //             ) {
  //               return Number(hoursArrival) <= 5;
  //             }
  //
  //             if (
  //               (timeArrival.includes("PM") || timeArrival.includes("م")) &&
  //               depart2Item.id === "eveningArrival"
  //             ) {
  //               return Number(hoursArrival) >= 6 && Number(hoursArrival) <= 11;
  //             }
  //           });
  //           setTimeout(() => {
  //             if (tempArray.length === 0) {
  //               depart2Item.faded = true;
  //             } else {
  //               depart2Item.faded = false;
  //             }
  //           }, 300);
  //         });
  //       }
  //       this.departureSeg1.forEach((depart1Item) => {
  //         let tempArray = this.filterreditems.filter((item) => {
  //           let time = this.datePipe.transform(
  //             item.originDestinationOptionsGroups[0].summary.departureDate,
  //             "hh:mma"
  //           );
  //           let timeArray = time.split(":");
  //           let hours = timeArray[0];
  //
  //           if (
  //             (time.includes("ص") || time.includes("AM")) &&
  //             depart1Item.id === "earlyMorning"
  //           ) {
  //             return Number(hours) <= 4;
  //           }
  //
  //           if (
  //             (time.includes("ص") || time.includes("AM")) &&
  //             depart1Item.id === "morning"
  //           ) {
  //             return Number(hours) >= 5 && Number(hours) <= 11;
  //           }
  //
  //           if (
  //             (time.includes("م") || time.includes("PM")) &&
  //             depart1Item.id === "afternoon"
  //           ) {
  //             return Number(hours) <= 5;
  //           }
  //
  //           if (
  //             (time.includes("م") || time.includes("PM")) &&
  //             depart1Item.id === "evening"
  //           ) {
  //             return Number(hours) >= 6 && Number(hours) <= 11;
  //           }
  //         });
  //         setTimeout(() => {
  //           if (tempArray.length === 0) {
  //             depart1Item.faded = true;
  //           } else {
  //             depart1Item.faded = false;
  //           }
  //         }, 300);
  //       });
  //
  //       this.stops.forEach((stop) => {
  //         let tempArray = this.filterreditems.filter((item) => {
  //           if (stop.id === "2" && this.Params.trip === "ONEWAY") {
  //             return (
  //               item.originDestinationOptionsGroups[0].summary.totalStops ===
  //                 2 ||
  //               item.originDestinationOptionsGroups[0].summary.totalStops > 2
  //             );
  //           } else if (this.Params.trip === "ONEWAY") {
  //             return (
  //               "" +
  //                 item.originDestinationOptionsGroups[0].summary.totalStops ===
  //               stop.id
  //             );
  //           }
  //
  //           if (stop.id === "2" && this.Params.trip === "ROUNDTRIP") {
  //             return (
  //               this.stopSelectedArray.includes(
  //                 "" +
  //                   Math.max(
  //                     item.originDestinationOptionsGroups[0].summary.totalStops,
  //                     item.originDestinationOptionsGroups[1].summary.totalStops
  //                   )
  //               ) ||
  //               Math.max(
  //                 item.originDestinationOptionsGroups[0].summary.totalStops,
  //                 item.originDestinationOptionsGroups[1].summary.totalStops
  //               ) > 2
  //             );
  //           } else {
  //             return (
  //               "" +
  //                 Math.max(
  //                   item.originDestinationOptionsGroups[0].summary.totalStops,
  //                   item.originDestinationOptionsGroups[1].summary.totalStops
  //                 ) ===
  //               stop.id
  //             );
  //           }
  //         });
  //         setTimeout(() => {
  //           if (tempArray.length === 0) {
  //             stop.faded = true;
  //           } else {
  //             stop.faded = false;
  //           }
  //         }, 300);
  //       });
  //
  //       // this.airlines.forEach((airlineItem) => {
  //       //   let tempArray = this.filterreditems.filter((item) => {
  //       //     if (this.Params.trip === "ONEWAY") {
  //       //       return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //         airlineItem.title
  //       //       );
  //       //     }
  //       //
  //       //     if (this.Params.trip === "ROUNDTRIP") {
  //       //       return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //         airlineItem.title
  //       //       );
  //       //     }
  //       //   });
  //       //   setTimeout(() => {
  //       //     if (tempArray.length === 0) {
  //       //       airlineItem.faded = true;
  //       //     } else {
  //       //       airlineItem.faded = false;
  //       //     }
  //       //   }, 300);
  //       // });
  //     } else {
  //       this.filterSideFadedItems("duration");
  //       this.departureSeg2.forEach((item) => {
  //         item.faded = false;
  //       });
  //       this.departureSeg1.forEach((item) => {
  //         item.faded = false;
  //       });
  //       this.stops.forEach((item) => {
  //         item.faded = false;
  //       });
  //       // this.airlines.forEach((item) => {
  //       //   item.faded = false;
  //       // });
  //     }
  //     //////////////////////////////////////////////////////////////////////////////////////////////////
  //   } else if (arrayName === "time") {
  //     if (this.departSeg1Array.length) {
  //       if (this.Params.trip === "ROUNDTRIP") {
  //         this.departureSeg2.forEach((depart2Item) => {
  //           let tempArray = this.filterreditems.filter((item) => {
  //             let timeArrival = this.datePipe.transform(
  //               item.originDestinationOptionsGroups[1].summary.departureDate,
  //               "hh:mma"
  //             );
  //             let timeArrayArrival = timeArrival.split(":");
  //             let hoursArrival = timeArrayArrival[0];
  //
  //             if (
  //               (timeArrival.includes("AM") || timeArrival.includes("ص")) &&
  //               depart2Item.id === "earlyMorningArrival"
  //             ) {
  //               return Number(hoursArrival) <= 4;
  //             }
  //
  //             if (
  //               (timeArrival.includes("AM") || timeArrival.includes("ص")) &&
  //               depart2Item.id === "morningArrival"
  //             ) {
  //               return Number(hoursArrival) >= 5 && Number(hoursArrival) <= 11;
  //             }
  //
  //             if (
  //               (timeArrival.includes("PM") || timeArrival.includes("م")) &&
  //               depart2Item.id === "afternoonArrival"
  //             ) {
  //               return Number(hoursArrival) <= 5;
  //             }
  //
  //             if (
  //               (timeArrival.includes("PM") || timeArrival.includes("م")) &&
  //               depart2Item.id === "eveningArrival"
  //             ) {
  //               return Number(hoursArrival) >= 6 && Number(hoursArrival) <= 11;
  //             }
  //           });
  //           setTimeout(() => {
  //             if (tempArray.length === 0) {
  //               depart2Item.faded = true;
  //             } else {
  //               depart2Item.faded = false;
  //             }
  //           }, 300);
  //         });
  //       }
  //
  //       this.stops.forEach((stop) => {
  //         let tempArray = this.filterreditems.filter((item) => {
  //           if (stop.id === "2" && this.Params.trip === "ONEWAY") {
  //             return (
  //               item.originDestinationOptionsGroups[0].summary.totalStops ===
  //                 2 ||
  //               item.originDestinationOptionsGroups[0].summary.totalStops > 2
  //             );
  //           } else if (
  //             this.Params.trip === "ONEWAY" &&
  //             item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
  //               .marketingAirlines.length === 1
  //           ) {
  //             return (
  //               "" +
  //                 item.originDestinationOptionsGroups[0].summary.totalStops ===
  //               stop.id
  //             );
  //           }
  //
  //           if (stop.id === "2" && this.Params.trip === "ROUNDTRIP") {
  //             return (
  //               Math.max(
  //                 item.originDestinationOptionsGroups[0].summary.totalStops,
  //                 item.originDestinationOptionsGroups[1].summary.totalStops
  //               ) === 2 ||
  //               Math.max(
  //                 item.originDestinationOptionsGroups[0].summary.totalStops,
  //                 item.originDestinationOptionsGroups[1].summary.totalStops
  //               ) > 2
  //             );
  //           } else {
  //             return (
  //               "" +
  //                 Math.max(
  //                   item.originDestinationOptionsGroups[0].summary.totalStops,
  //                   item.originDestinationOptionsGroups[1].summary.totalStops
  //                 ) ===
  //               stop.id
  //             );
  //           }
  //         });
  //         setTimeout(() => {
  //           if (tempArray.length === 0) {
  //             stop.faded = true;
  //           } else {
  //             stop.faded = false;
  //           }
  //         }, 300);
  //       });
  //
  //       // this.airlines.forEach((airlineItem) => {
  //       //   let tempArray = this.filterreditems.filter((item) => {
  //       //     if (
  //       //       item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
  //       //         .marketingAirlines.length === 1 &&
  //       //       this.Params.trip === "ONEWAY"
  //       //     ) {
  //       //       if (this.stopSelectedArray.includes("2")) {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       } else {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       }
  //       //     }
  //       //     if (
  //       //       item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
  //       //         .marketingAirlines.length === 1 &&
  //       //       item.originDestinationOptionsGroups[1].summary.airlineInfoSummary
  //       //         .marketingAirlines.length === 1 &&
  //       //       this.Params.trip === "ROUNDTRIP"
  //       //     ) {
  //       //       if (this.stopSelectedArray.includes("2")) {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       } else {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       }
  //       //     }
  //       //   });
  //       //   setTimeout(() => {
  //       //     if (tempArray.length === 0) {
  //       //       airlineItem.faded = true;
  //       //     } else {
  //       //       airlineItem.faded = false;
  //       //     }
  //       //   }, 300);
  //       // });
  //
  //       this.airports.forEach((city) => {
  //         city.airports.forEach((airport) => {
  //           let tempArrayAirports = this.filterreditems.filter((item) => {
  //             if (
  //               this.stopSelectedArray.includes("2") &&
  //               this.Params.trip === "ONEWAY"
  //             ) {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             } else if (this.Params.trip === "ONEWAY") {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             }
  //
  //             if (
  //               this.stopSelectedArray.includes("2") &&
  //               this.Params.trip === "ROUNDTRIP"
  //             ) {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             } else {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             }
  //           });
  //
  //           setTimeout(() => {
  //             if (tempArrayAirports.length === 0) {
  //               airport.faded = true;
  //             } else {
  //               airport.faded = false;
  //             }
  //           }, 300);
  //         });
  //       });
  //     } else {
  //       this.filterSideFadedItems("duration");
  //       this.departureSeg2.forEach((item) => {
  //         item.faded = false;
  //       });
  //       this.stops.forEach((item) => {
  //         item.faded = false;
  //       });
  //       // this.airlines.forEach((item) => {
  //       //   item.faded = false;
  //       // });
  //       this.airports.forEach((city) => {
  //         city.airports.forEach((airport) => {
  //           airport.faded = false;
  //         });
  //       });
  //     }
  //   }
  //
  //   ////////////////////////////////////////////////////////////////////////////////
  //   else if (arrayName === "timeArrival") {
  //     if (this.departSeg2Array.length) {
  //       this.departureSeg1.forEach((depart1Item) => {
  //         let tempArray = this.filterreditems.filter((item) => {
  //           let time = this.datePipe.transform(
  //             item.originDestinationOptionsGroups[0].summary.departureDate,
  //             "hh:mma"
  //           );
  //           let timeArray = time.split(":");
  //           let hours = timeArray[0];
  //
  //           if (
  //             (time.includes("ص") || time.includes("AM")) &&
  //             depart1Item.id === "earlyMorning"
  //           ) {
  //             return Number(hours) <= 4;
  //           }
  //
  //           if (
  //             (time.includes("ص") || time.includes("AM")) &&
  //             depart1Item.id === "morning"
  //           ) {
  //             return Number(hours) >= 5 && Number(hours) <= 11;
  //           }
  //
  //           if (
  //             (time.includes("م") || time.includes("PM")) &&
  //             depart1Item.id === "afternoon"
  //           ) {
  //             return Number(hours) <= 5;
  //           }
  //
  //           if (
  //             (time.includes("م") || time.includes("PM")) &&
  //             depart1Item.id === "evening"
  //           ) {
  //             return Number(hours) >= 6 && Number(hours) <= 11;
  //           }
  //         });
  //         setTimeout(() => {
  //           if (tempArray.length === 0) {
  //             depart1Item.faded = true;
  //           } else {
  //             depart1Item.faded = false;
  //           }
  //         }, 300);
  //       });
  //
  //       this.stops.forEach((stop) => {
  //         let tempArray = this.filterreditems.filter((item) => {
  //           if (stop.id === "2" && this.Params.trip === "ONEWAY") {
  //             return (
  //               item.originDestinationOptionsGroups[0].summary.totalStops ===
  //                 2 ||
  //               item.originDestinationOptionsGroups[0].summary.totalStops > 2
  //             );
  //           } else if (
  //             this.Params.trip === "ONEWAY" &&
  //             item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
  //               .marketingAirlines.length === 1
  //           ) {
  //             return (
  //               "" +
  //                 item.originDestinationOptionsGroups[0].summary.totalStops ===
  //               stop.id
  //             );
  //           }
  //
  //           if (stop.id === "2" && this.Params.trip === "ROUNDTRIP") {
  //             return (
  //               Math.max(
  //                 item.originDestinationOptionsGroups[0].summary.totalStops,
  //                 item.originDestinationOptionsGroups[1].summary.totalStops
  //               ) === 2 ||
  //               Math.max(
  //                 item.originDestinationOptionsGroups[0].summary.totalStops,
  //                 item.originDestinationOptionsGroups[1].summary.totalStops
  //               ) > 2
  //             );
  //           } else {
  //             return (
  //               "" +
  //                 Math.max(
  //                   item.originDestinationOptionsGroups[0].summary.totalStops,
  //                   item.originDestinationOptionsGroups[1].summary.totalStops
  //                 ) ===
  //               stop.id
  //             );
  //           }
  //         });
  //         setTimeout(() => {
  //           if (tempArray.length === 0) {
  //             stop.faded = true;
  //           } else {
  //             stop.faded = false;
  //           }
  //         }, 300);
  //       });
  //
  //       // this.airlines.forEach((airlineItem) => {
  //       //   let tempArray = this.filterreditems.filter((item) => {
  //       //     if (
  //       //       item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
  //       //         .marketingAirlines.length === 1 &&
  //       //       this.Params.trip === "ONEWAY"
  //       //     ) {
  //       //       if (this.stopSelectedArray.includes("2")) {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       } else {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       }
  //       //     }
  //       //     if (
  //       //       item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
  //       //         .marketingAirlines.length === 1 &&
  //       //       item.originDestinationOptionsGroups[1].summary.airlineInfoSummary
  //       //         .marketingAirlines.length === 1 &&
  //       //       this.Params.trip === "ROUNDTRIP"
  //       //     ) {
  //       //       if (this.stopSelectedArray.includes("2")) {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       } else {
  //       //         return item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.includes(
  //       //           airlineItem.title
  //       //         );
  //       //       }
  //       //     }
  //       //   });
  //       //   setTimeout(() => {
  //       //     if (tempArray.length === 0) {
  //       //       airlineItem.faded = true;
  //       //     } else {
  //       //       airlineItem.faded = false;
  //       //     }
  //       //   }, 300);
  //       // });
  //
  //       this.airports.forEach((city) => {
  //         city.airports.forEach((airport) => {
  //           let tempArrayAirports = this.filterreditems.filter((item) => {
  //             if (
  //               this.stopSelectedArray.includes("2") &&
  //               this.Params.trip === "ONEWAY"
  //             ) {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             } else if (this.Params.trip === "ONEWAY") {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             }
  //
  //             if (
  //               this.stopSelectedArray.includes("2") &&
  //               this.Params.trip === "ROUNDTRIP"
  //             ) {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             } else {
  //               return (
  //                 item.originDestinationOptionsGroups[0].summary
  //                   .departureAirport.iata === airport.name ||
  //                 item.originDestinationOptionsGroups[0].summary.arrivalAirport
  //                   .iata === airport.name
  //               );
  //             }
  //           });
  //           setTimeout(() => {
  //             if (tempArrayAirports.length === 0) {
  //               airport.faded = true;
  //             } else {
  //               airport.faded = false;
  //             }
  //           }, 300);
  //         });
  //       });
  //     } else {
  //       this.filterSideFadedItems("duration");
  //       this.departureSeg1.forEach((item) => {
  //         item.faded = false;
  //       });
  //       this.stops.forEach((item) => {
  //         item.faded = false;
  //       });
  //       // this.airlines.forEach((item) => {
  //       //   item.faded = false;
  //       // });
  //       this.airports.forEach((city) => {
  //         city.airports.forEach((airport) => {
  //           airport.faded = false;
  //         });
  //       });
  //     }
  //   }
   }

  onClickBody(event) {
    if (
      this.eref.nativeElement.querySelector(".search-label-map") &&
      !this.eref.nativeElement
        .querySelector(".search-label-map")
        .contains(event.target)
    ) {
      this.autoCompleteMenuFilter = false;
    }
  }
  onWindowScroll(event) {
    if (this.Service.mobileView && this.scrollContainer) {
      const currentPosition = this.scrollContainer.nativeElement.offsetTop;
      const scrollFromTop = event.currentTarget.scrollY;

      if (scrollFromTop >= currentPosition) {
        this.activeScroll = true;
      } else {
        this.activeScroll = false;
      }

      if (
        this.activeScroll &&
        this.oldScrollFromTop > event.currentTarget.scrollY
      ) {
        this.sortHeader = true;
      } else {
        this.sortHeader = false;
      }
      this.oldScrollFromTop = event.currentTarget.scrollY;
    }
  }

  onScroll() {
    if (this.filterreditems.length === 0 && this.filteringActive) {
      return;
    }
    if (
      this.flightList &&
      this.numberOfFlights < this.flightList.length &&
      this.filterreditems.length === 0
    ) {
      this.numberOfFlights = this.numberOfFlights + 2;
      this.currentFlightList = this.flightList.slice(0, this.numberOfFlights);
    }
    if (
      this.numberOfFlights < this.filterreditems.length &&
      this.filterreditems.length !== 0
    ) {
      this.numberOfFlights++;
      this.currentFlightList = this.filterreditems.slice(
        0,
        this.numberOfFlights
      );
    }
  }

  withPolicyFilterRemove() {
    this.filterWithinPolicy = false;
    this.applyfilters();
    this.triggerScrollTo();
  }

  triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: "flightListMobile",
      duration: 650,
      easing: "easeOutElastic",
      offset: -150,
    };

    this.scrollToService.scrollTo(config);
  }
  filterWithinPolicyFunc() {
    this.updateFlightList();

    setTimeout(() => {
      this.filteringActive = true;

      this.Service.scrollTop();

      this.applyfilters();
    }, 500);
    this.filterWithinPolicyCheck = true;
  }

  filterInActive() {
    this.Service.flightListLoader = true;
    setTimeout(() => {
      this.Service.flightListLoader = false;
    }, 800);
  }
  updateFlightList() {
    this.filterInActive();
  }

  // filterByDepartureDate(value, date1, date2) {
  //   if (value.target.checked === true) {
  //     this.filterByDepartDate0 = date1;
  //     this.filterByArrivalDate0 = date2;
  //   } else {
  //     this.filterByDepartDate0 = null;
  //     this.filterByArrivalDate0 = null;
  //     this.filterByDepartureDateCheck = false;
  //   }
  //   this.updateFlightList();
  //
  //   setTimeout(() => {
  //     this.filteringActive = true;
  //
  //     this.Service.scrollTop();
  //
  //     this.applyfilters();
  //   }, 500);
  // }
  // filterByReturnDate(value, date1, date2) {
  //   if (value.target.checked === true) {
  //     this.filterByDepartDate1 = date1;
  //     this.filterByArrivalDate1 = date2;
  //   } else {
  //     this.filterByDepartDate1 = null;
  //     this.filterByArrivalDate1 = null;
  //     this.filterByReturnDateCheck = false;
  //   }
  //   this.updateFlightList();
  //
  //   setTimeout(() => {
  //     this.filteringActive = true;
  //
  //     this.Service.scrollTop();
  //
  //     this.applyfilters();
  //   }, 500);
  // }

  applyfilters() {
    this.upSellingLoading = this.flightList.map(() => new BehaviorSubject<boolean>(false));
    this.Service.flightFilter = true;
    this.numberOfFlights = 10;
    this.filterreditems = this.flightList;
    if (this.hoursValue < this.optionsHours.ceil) {
      this.filterreditems = this.filterreditems.filter((item) => {
        let timeInMinutes =
          item.originDestinationOptionsGroups[0].summary.journeyDurationInMins;
        if (this.Params.trip === "ROUNDTRIP") {
          timeInMinutes = Math.max(
            item.originDestinationOptionsGroups[0].summary
              .journeyDurationInMins,
            item.originDestinationOptionsGroups[1].summary.journeyDurationInMins
          );
        }
        const hoursDur = timeInMinutes / 60;
        const roundHoursDur = Math.floor(hoursDur);
        const minutesDur = (hoursDur - roundHoursDur) * 60;
        const roundMinutesDur = Math.round(minutesDur);
        if (
          roundHoursDur < this.hoursValue ||
          (roundHoursDur === this.hoursValue && roundMinutesDur === 0)
        ) {
          return true;
        } else {
          return false;
        }
      });
    }

    // this is only for roundtrip or multicity (search)
    this.filterreditems = this.filterreditems.filter(flight =>
        Object.entries(this.selectedDatesByItinerary).every(([index, selectedDates]) => {
          if (selectedDates.departureDate === null && selectedDates.arrivalDate === null) return true; // Ignore unselected itineraries
          const itinerary = flight.itineraries[index];
          return itinerary &&
              itinerary.searchSegmentSummary.departureDate === selectedDates.departureDate &&
              itinerary.searchSegmentSummary.arrivalDate === selectedDates.arrivalDate;
        })
    );

    //if (this.Params.trip === "ROUNDTRIP") {



    /*  if (
        this.filterByDepartDate0 &&
        this.filterByArrivalDate0 &&
        this.filterByDepartDate1 &&
        this.filterByArrivalDate1
      ) {
        this.filterreditems = this.filterreditems.filter((item) => {
          return (
            item.originDestinationOptionsGroups[0].summary.departureDate ===
              this.filterByDepartDate0 &&
            item.originDestinationOptionsGroups[0].summary.arrivalDate ===
              this.filterByArrivalDate0 &&
            item.originDestinationOptionsGroups[1].summary.departureDate ===
              this.filterByDepartDate1 &&
            item.originDestinationOptionsGroups[1].summary.arrivalDate ===
              this.filterByArrivalDate1
          );
        });
        this.filterByReturnDateCheck = true;
        this.filterByDepartureDateCheck = true;
      } else if (this.filterByDepartDate0 && this.filterByArrivalDate0) {
        this.filterreditems = this.filterreditems.filter((item) => {
          return (
            item.originDestinationOptionsGroups[0].summary.departureDate ===
              this.filterByDepartDate0 &&
            item.originDestinationOptionsGroups[0].summary.arrivalDate ===
              this.filterByArrivalDate0
          );
        });
        this.filterByDepartureDateCheck = true;
      } else if (this.filterByDepartDate1 && this.filterByArrivalDate1) {
        this.filterreditems = this.filterreditems.filter((item) => {
          return (
            item.originDestinationOptionsGroups[1].summary.departureDate ===
              this.filterByDepartDate1 &&
            item.originDestinationOptionsGroups[1].summary.arrivalDate ===
              this.filterByArrivalDate1
          );
        });
        this.filterByReturnDateCheck = true;
      }*/
   // }
    if (this.filterWithinPolicy) {
      this.filterreditems = this.filterreditems.filter((item) => {
        return item.travelerPricings[0].userData.policyViolated === false;
      });
    }
    this.filterWithinPolicyCheck = false;


    if (this.stopSelectedArray.length) {
      this.filterreditems = this.filterFlightsBySelectedStops(this.filterreditems);
      // this.filterreditems = this.filterreditems.filter((item) => {
      //   if (
      //     this.stopSelectedArray.includes("2") &&
      //     this.Params.trip === "ONEWAY"
      //   ) {
      //     return (
      //       this.stopSelectedArray.includes(
      //         "" + item.originDestinationOptionsGroups[0].summary.totalStops
      //       ) || item.originDestinationOptionsGroups[0].summary.totalStops > 2
      //     );
      //   } else if (this.Params.trip === "ONEWAY") {
      //     return this.stopSelectedArray.includes(
      //       "" + item.originDestinationOptionsGroups[0].summary.totalStops
      //     );
      //   }
      //
      //   if (
      //     this.stopSelectedArray.includes("2") &&
      //     this.Params.trip === "ROUNDTRIP"
      //   ) {
      //     return (
      //       this.stopSelectedArray.includes(
      //         "" +
      //           Math.max(
      //             item.originDestinationOptionsGroups[0].summary.totalStops,
      //             item.originDestinationOptionsGroups[1].summary.totalStops
      //           )
      //       ) ||
      //       Math.max(
      //         item.originDestinationOptionsGroups[0].summary.totalStops,
      //         item.originDestinationOptionsGroups[1].summary.totalStops
      //       ) > 2
      //     );
      //   } else {
      //     return this.stopSelectedArray.includes(
      //       "" +
      //         Math.max(
      //           item.originDestinationOptionsGroups[0].summary.totalStops,
      //           item.originDestinationOptionsGroups[1].summary.totalStops
      //         )
      //     );
      //   }
      // });
    }

    if (this.airportsSelectedArray.length) {
      this.filterreditems = this.filterreditems.filter((item) => {
        if (this.Params.trip === "ONEWAY") {
          return (
            this.airportsSelectedArray.includes(
              item.originDestinationOptionsGroups[0].summary.departureAirport
                .iata
            ) ||
            this.airportsSelectedArray.includes(
              item.originDestinationOptionsGroups[0].summary.arrivalAirport.iata
            )
          );
        } else if (this.Params.trip === "ROUNDTRIP") {
          if (this.cityArrayForAirports.length === 1) {
            return (
              this.airportsSelectedArray.includes(
                item.originDestinationOptionsGroups[0].summary.departureAirport
                  .iata
              ) ||
              this.airportsSelectedArray.includes(
                item.originDestinationOptionsGroups[0].summary.arrivalAirport
                  .iata
              ) ||
              this.airportsSelectedArray.includes(
                item.originDestinationOptionsGroups[1].summary.departureAirport
                  .iata
              ) ||
              this.airportsSelectedArray.includes(
                item.originDestinationOptionsGroups[1].summary.arrivalAirport
                  .iata
              )
            );
          } else {
            let arrayOfArrays = [];
            this.cityArrayForAirports.forEach((city, index) => {
              let airportListInOneCity = [];
              city.airports.forEach((airport) => {
                if (airport.isChecked) {
                  airportListInOneCity.push(airport.name);
                }
                if (!arrayOfArrays.includes(airportListInOneCity)) {
                  arrayOfArrays.push(airportListInOneCity);
                }
              });
              //}
            });
            let combinations = [];
            for (let j = 0; j < arrayOfArrays.length - 1; j++) {
              arrayOfArrays[j].forEach((airport1) => {
                arrayOfArrays[j + 1].forEach((airport2) => {
                  let arr = [airport1, airport2];
                  combinations.push(arr);
                });
              });
            }
            let result = false;
            combinations.forEach((element) => {
              if (
                element.includes(
                  item.originDestinationOptionsGroups[0].summary
                    .departureAirport.iata
                ) &&
                element.includes(
                  item.originDestinationOptionsGroups[0].summary.arrivalAirport
                    .iata
                ) &&
                element.includes(
                  item.originDestinationOptionsGroups[1].summary
                    .departureAirport.iata
                ) &&
                element.includes(
                  item.originDestinationOptionsGroups[1].summary.arrivalAirport
                    .iata
                )
              ) {
                result = true;
                return true;
              }
            });
            return result;
          }
        }
      });
    }

    if (this.airlinesSelectedArray.length) {
      this.filterreditems = this.filterBySelectedAirlines(this.filterreditems, this.airlinesSelectedArray);
    /*  this.filterreditems = this.filterreditems.filter((item) => {
        if (
          this.Params.trip === "ONEWAY" &&
          item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
            .marketingAirlines.length === 1
        ) {
          return this.airlinesSelectedArray.some(
            (val) =>
              item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.indexOf(
                val
              ) !== -1
          );
        } else if (
          this.Params.trip === "ROUNDTRIP" &&
          item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
            .marketingAirlines.length === 1 &&
          item.originDestinationOptionsGroups[1].summary.airlineInfoSummary
            .marketingAirlines.length === 1 &&
          item.originDestinationOptionsGroups[0].summary.airlineInfoSummary
            .marketingAirlines[0] ===
            item.originDestinationOptionsGroups[1].summary.airlineInfoSummary
              .marketingAirlines[0]
        ) {
          return this.airlinesSelectedArray.some(
            (val) =>
              item.originDestinationOptionsGroups[0].summary.airlineInfoSummary.marketingAirlines.indexOf(
                val
              ) !== -1
          );
        }
      });*/
    }



    if (this.departSeg1Array.length) {
      this.filterreditems = this.filterreditems.filter((item) => {
        let time = this.datePipe.transform(
          item.originDestinationOptionsGroups[0].summary.departureDate,
          "hh:mma"
        );
        let timeArray = time.split(":");
        let hours = timeArray[0];
        if (
          this.departSeg1Array.includes("earlyMorning") &&
          Number(hours) <= 4 &&
          (time.includes("AM") || time.includes("ص"))
        ) {
          return true;
        } else if (
          this.departSeg1Array.includes("morning") &&
          Number(hours) >= 5 &&
          Number(hours) <= 11 &&
          (time.includes("AM") || time.includes("ص"))
        ) {
          return true;
        } else if (
          this.departSeg1Array.includes("afternoon") &&
          Number(hours) <= 5 &&
          (time.includes("PM") || time.includes("م"))
        ) {
          return true;
        } else if (
          this.departSeg1Array.includes("evening") &&
          Number(hours) >= 6 &&
          Number(hours) <= 11 &&
          (time.includes("PM") || time.includes("م"))
        ) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (this.departSeg2Array.length) {
      this.filterreditems = this.filterreditems.filter((item) => {
        if (this.Params.trip === "ROUNDTRIP") {
          let timeArrival = this.datePipe.transform(
            item.originDestinationOptionsGroups[1].summary.departureDate,
            "hh:mma"
          );
          let timeArrayArrival = timeArrival.split(":");
          let hoursArrival = timeArrayArrival[0];

          if (
            this.departSeg2Array.includes("earlyMorningArrival") &&
            Number(hoursArrival) <= 4 &&
            (timeArrival.includes("AM") || timeArrival.includes("ص"))
          ) {
            return true;
          } else if (
            this.departSeg2Array.includes("morningArrival") &&
            Number(hoursArrival) >= 5 &&
            Number(hoursArrival) <= 11 &&
            (timeArrival.includes("AM") || timeArrival.includes("ص"))
          ) {
            return true;
          } else if (
            this.departSeg2Array.includes("afternoonArrival") &&
            Number(hoursArrival) <= 5 &&
            (timeArrival.includes("PM") || timeArrival.includes("م"))
          ) {
            return true;
          }
          if (
            this.departSeg2Array.includes("eveningArrival") &&
            Number(hoursArrival) >= 6 &&
            Number(hoursArrival) <= 11 &&
            (timeArrival.includes("PM") || timeArrival.includes("م"))
          ) {
            return true;
          } else {
            return false;
          }
        }
      });
    }

    setTimeout(() => {
      const inputElements = Array.from(document.getElementsByTagName("input"));
      let anyChecked = false;
      inputElements.forEach((checkboxItem) => {
        if (checkboxItem.type === "checkbox" && checkboxItem.checked) {
          anyChecked = true;
        }
      });

      if (!anyChecked && this.hoursValue === this.optionsHours.ceil) {
        this.filteringActive = false;
      }
    }, 200);
    this.resetCardDetailsVisibility();
    this.currentFlightList = this.filterreditems.slice(0, this.numberOfFlights);
    this.filterapplied = true;
    this.sortFunc(this.SortType, this.ParentSort);
    this.activecheckbox = undefined;
  }

  resetCardDetailsVisibility() {
    this.flightListService.isCardDetailsVisible.forEach((subject) => subject.next(false));
  }

  filterFlightsBySelectedStops(flights: any[]): any[] {
    console.log(flights);
    console.log(this.stopSelectedArray);
    return flights.filter(flight => {
      // Get the maximum number of stops across all itineraries in this flight
      // The spread operator (...) is used to unpack the array returned by .map() into individual elements.
          const maxStopsInFlight = Math.max(
          ...flight.itineraries.map((itinerary: any) => itinerary.searchSegmentSummary.totalStops)
      );

      // Check if the maxStopsInFlight is included in the selected stop filter array
      return this.stopSelectedArray.includes('0') && maxStopsInFlight === 0 ||
          this.stopSelectedArray.includes('1') && maxStopsInFlight === 1 ||
          this.stopSelectedArray.includes('2') && maxStopsInFlight >= 2;
    });
  }

  // checkOpenItemStillInList() {
  //   if(this.selectedFlightTransactionForUpSelling){
  //     // Check if the currently opened item is still in the filtered list
  //     const isOpenItemStillInList = this.filterreditems.some(
  //         (flight) => flight.transactionId === this.selectedFlightTransactionForUpSelling
  //     );
  //
  //     // If the opened item is not in the filtered result, close it
  //     if (!isOpenItemStillInList) {
  //
  //     }
  //   }
  // }


  filterBySelectedAirlines(
      items: any[],
      airlinesSelectedArray: string[]
  ): any[] {
    // If airlinesSelectedArray is empty or undefined, return all items
    if (!airlinesSelectedArray || airlinesSelectedArray.length === 0) {
      return items;
    }

    return items.filter((item) => {
      // Check if the airline is consistent across all itineraries
      const allItinerariesHaveSingleAirline = item.itineraries.every(
          (itinerary: any) =>
              itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines
                  .length === 1
      );

      if (!allItinerariesHaveSingleAirline) return false;

      // Get the airline for the first itinerary
      const firstItineraryAirline =
          item.itineraries[0].searchSegmentSummary.airlineInfoSummary
              .marketingAirlines[0];

      // Check if the same airline is used in all itineraries
      const isSameAirlineAcrossItineraries = item.itineraries.every(
          (itinerary: any) =>
              itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines[0] ===
              firstItineraryAirline
      );

      // Check if the consistent airline is in the selected array
      return (
          isSameAirlineAcrossItineraries &&
          airlinesSelectedArray.includes(firstItineraryAirline)
      );
    });
  }

  checkTime(time, hours, amOrPm, start, end) {
    if (time.includes(amOrPm)) {
      if (start > end) {
        return Number(hours) >= start || Number(hours) <= end;
      } else {
        return Number(hours) >= start && Number(hours) <= end;
      }
    }
  }
  getUpSellingDetails(index: number, transactionId){
    this.upSellingErrorMsg = '';
    this.selectedFlightIndex = index;
    this.upSellingLoading[index].next(true);
    let upSellingOb = {
      transactionId: transactionId,
      travelers: [
        {
          travelerId: this.travelerId,
          travelerType: "ADULT"
        }
      ]
    };
    this.upSellingSubscription =  this.flightListService.upSelling(upSellingOb).subscribe(
        data => {
          this.fares = null;
          this.upSellingData = data;
          if (this.upSellingData.errorCode === 0) {
            this.flightListService.viewFlightUpSellingSection(index);
            this.fares = this.upSellingData.data;
            console.log(this.fares);
            this.upSellingLoading[index].next(false);
          } else if(this.upSellingData.errorCode === 402) {
            this.flightListService.viewFlightUpSellingSection(index);
            // in case fares = null, we will show fares from the flight itself instead of upselling api (check in html)
            this.upSellingLoading[index].next(false);
            this.upSellingSubscription.unsubscribe();
          } else {
            // here fares section will not open. Only error msg will be shown
            this.upSellingErrorMsg = this.data.message;
          }

        },
        error => {
          this.upSellingLoading[index].next(false);
          this.Service.messages(environment.messages.error, 'error');
        }
    );
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    const currentWidth = window.innerWidth; // Get the current window width

    // Refresh the page if the width crosses 992px
    if ((this.previousWidth < 992 && currentWidth >= 992) || (this.previousWidth >= 992 && currentWidth < 992)) {
      location.reload(); // Refresh the page
    }

    this.previousWidth = currentWidth; // Update the previous width
  }
}
