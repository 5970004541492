import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataService } from "../../../services/data.service";
import { AuthServiceMain } from "../../../auth/auth.service";
import { environment } from "../../../../environments/environment";
declare let gtag: any;
@Component({
  selector: "app-workspace-login-form",
  templateUrl: "./workspace-login-form.component.html",
  styleUrls: ["./workspace-login-form.component.scss"],
})
export class WorkspaceLoginFormComponent implements OnInit {

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    public dataService: DataService,
    public auth: AuthServiceMain,
    public route: ActivatedRoute
  ) {}

  loginForm: FormGroup;
  submitted = false;
  submitting = false;
  data;
  errorMsg = "";
  destination = "destination";

  ngOnInit(): void {
    if (this.auth.isLogedIn) {
      return;
    }
    this.initData();
    this.initLoginForm();
    this.setEmailValue();
  }

  initData() {
    this.dataService.signInErrorMsg = false;
    this.dataService.alerts = [];
  }

  initLoginForm() {
    this.loginForm = this.formBuilder.group(
      {
        password: [
          "",
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(100),
          ],
        ],
        email: ["", Validators.required],
      },
      {}
    );
  }

  setEmailValue() {
    if (this.dataService.companyEmail) {
      this.loginForm.get("email").setValue(this.dataService.companyEmail);
    }
  }

  openForgotPasswordSheet() {
    this.dataService.setBottomSheet("ForgotPassword", "");
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmitLogin() {
    this.errorMsg = "";
    this.submitted = true;
    this.submitting = true;
    if (this.loginForm.invalid) {
      this.submitting = false;
      if (!this.loginForm.controls.password.value.length) {
        return;
      }
      if (this.loginForm.controls.password.value.length < 6) {
        this.errorMsg = $localize`:@@passwordChars:Password must have at least 6 characters`;
        return;
      }
    }
    if (this.router.url !== "/slack/login") {
      if (
        this.dataService.companyDomain !==
        this.loginForm.controls.email.value.substring(
          this.loginForm.controls.email.value.indexOf("@") + 1
        )
      ) {
        this.submitting = false;
        this.errorMsg =
          "Your username or password don't match. Let's try that again.";
        return;
      }
    }
    this.auth
      .login(
        this.loginForm.controls.email.value,
        this.loginForm.controls.password.value
      )
      .subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode !== 0) {
            this.submitting = false;
            this.errorMsg = this.data.message;
          } else {
            gtag("event", "login", {
              method: "Google",
            });
          }
        },
        (error) => {
          this.submitting = false;
          this.dataService.messages(environment.messages.error, "error");
        }
      );
  }
}
