import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataService } from "src/app/services/data.service";
import { AuthServiceMain } from "src/app/auth/auth.service";
import { VariablesService } from "src/app/services/vars.service";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'app-account-preferences-update',
  templateUrl: './account-preferences-update.component.html',
  styleUrls: ['./account-preferences-update.component.scss']
})
export class AccountPreferencesUpdateComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    public dataService: DataService,
    public vars: VariablesService,
    public auth: AuthServiceMain
  ) {}

  updateCompanyInfo: FormGroup;
  data;
  companyFormData;
  sendUserData;
  languagesList;
  currenciesList;
  currentPreferences;
  submitting;
  submitted;
  errorMessage;

  ngOnInit(): void {
    this.initCurrentPreferences();
    this.getPreferences();
    this.initForm();
  }

  getPreferences() {
    this.dataService.getPreferencesList().subscribe(
      (result) => {
        this.data = result;
        if (this.data.errorCode === 0) {
          this.initData();
        } else {
          this.handleError();
        }
      },
      () => {}
    );
  }

  initCurrentPreferences() {
    if (this.dataService.desktopView) {
      this.currentPreferences = this.dataService.rightSideDrawerData;
    } else {
      this.currentPreferences = this.dataService.BottomSheetData;
    }
  }

  initForm() {
    this.updateCompanyInfo = this.formBuilder.group({
      selectedLanguage: [
        this.currentPreferences ? this.currentPreferences.preferredLanguage.code : null,
        Validators.required,
      ],
      selectedCurrency: [
        this.currentPreferences ? this.currentPreferences.preferredCurrency.code : null,
        Validators.required,
      ],
    });
  }

  initData() {
    this.currenciesList = this.data.data.currencies;
    this.languagesList = this.data.data.languages;
  }

  handleError() {
    this.errorMessage = this.data.message;
  }

  get f() {
    return this.updateCompanyInfo.controls;
  }

  onSaveClicked() {
    if (this.updateCompanyInfo.valid) {
      this.updatePreferences();
    }
  }


  updatePreferences() {
    this.submitted = true;
    this.submitting = true;
    if (this.updateCompanyInfo.invalid) {
      this.dataService.scrollTop();
      this.submitting = false;
      return;
    }
    this.companyFormData =
      "?"
    if (this.f.selectedLanguage.value) {
      this.companyFormData += "&preferredLanguageCode=" + this.f.selectedLanguage.value;
    }
    if (this.f.selectedCurrency.value) {
      this.companyFormData +=
        "&preferredCurrencyCode=" + this.f.selectedCurrency.value;
    }
    this.dataService.updateCompany(this.companyFormData).subscribe(
      (data) => {
        this.data = data;
      },
      (error) => {
        this.dataService.messages(environment.messages.error, "error");
        this.dataService.scrollTop();
        this.submitting = false;
      },
      () => {
        if (this.data.errorCode === 0) {
          this.getCompanyNewInfo();
          this.dataService.rightSideDrawerOff();
          this.dataService.BottomSheetOf();
          this.successMessage();
        } else {
          this.dataService.messages(this.data.message, "error");
          this.dataService.scrollTop();
          this.submitting = false;
        }
      }
    );
  }

  getCompanyNewInfo() {
    this.dataService.getCompanyInfo().subscribe(
      (suc) => {
        this.data = suc;
        if (this.data.errorCode === 0) {
          this.auth.companyInfo = {
            id: this.data.data.id,
            domain: this.data.data.domain,
            name: this.data.data.name,
            inactive: this.data.data.inactive,
            ownerUserId: this.data.data.ownerUserId,
            logoUrl: this.data.data.logoUrl,
            numberOfEmployees: this.data.data.numberOfEmployees,
            annualTravelSpend: this.data.data.annualTravelSpend,
            legalCompanyName: this.data.data.legalCompanyName,
            industry: this.data.data.industry,
            address: this.data.data.address,
            paymentMethods: this.data.data.paymentMethods,
            systemPreferences: this.data.data.systemPreferences
          };
          localStorage.setItem(
            "companyInfo",
            JSON.stringify(this.auth.companyInfo)
          );
          this.submitted = false;
          this.submitting = false;
        }
      }
    );
  }

  successMessage() {
      this.dataService.messages($localize`:@@memberInfoUpdated:Account info updated successfully`, 'success');
  }
}