<div class="main-container pb-5" [ngClass]="{'gray-pass-bg' : dataService.desktopView}">
<div class="container mb-5 pt-5 pt-lg-2 pb-5">
  <div class="custom-container workspace-registration-container mx-auto" >
    <div class="text-center pt-lg-5">
      <h4 class="font-24 pt-md-4 mb-3 font-weight-bold text-center" i18n="@@upload1">
        Upload your company logo
      </h4>
    </div>
    <p class="gray-text font-16 pb-4  mb-3 text-center px-4" i18n="@@upload2">Recommended 300x300 pixels image</p>
    <div class="mx-auto px-lg-5 pt-5 corporate-box white-box position-relative">
      <i class="icon-close-light gray-text-dark pointer font-12 p-2 position-absolute" *ngIf="this.imageURL"  (click)="this.files = []; imageURL = ''; errorMsg = ''"></i>
      <div class="drag-drop-container d-flex align-items-center" appDnd (fileDropped)="onFileDropped($event)">
     <div class="w-100">

        <input type="file" #fileDropRef id="fileDropRef"  (change)="fileBrowseHandler($event.target.files)"  accept="image/png, image/jpeg, image/gif, image/bmp" />
        <img *ngIf="imageURL && message === ''" width="55" height="155" loading="lazy"  [src]="imageURL" class="mx-auto" alt="Company Logo">
        <p class="text-danger" *ngIf="message !== ''">{{message}}</p>
        <p class="placeholder-text font-16 pb-0 pb-lg-4 mb-0 mb-lg-3 text-center px-4" *ngIf="(!imageURL && message === '') || message !== ''">
            <span *ngIf="dataService.desktopView && !dataService.corporateLocale.includes('ar')" i18n="@@upload3" [innerHTML]="'Drag and Drop your image &lt;br&gt; to this area or'"> </span>
         <ng-container *ngIf="dataService.desktopView && dataService.corporateLocale.includes('ar')">   <span i18n="@@upload3">Drag and Drop your image &lt;br&gt; to this area or </span>
             <br/></ng-container>
            <span class="change-color" [ngClass]="{'pl-2' : dataService.desktopView && dataService.isSiteLtr()}" i18n="@@upload4">click to browse</span></p>
      </div>
      </div>
    </div>
    <div class="row mt-5 cont-btn justify-content-center" >
     <!-- <div class="col-8 col-md-3 mb-3 mb-lg-0" *ngIf="this.imageURL">
        <button class="mt-3 btn-block btn pass-btn pass-btn-initial-width gray-text btn-outline-gray font-weight-normal rounded-pill font-16 px-5 d-flex ml-auto"
                (click)="this.files = []; imageURL = ''">
          <span class="mx-auto">Cancel</span>
        </button>
      </div>-->
      <div class="col-8 d-flex align-items-end order-sm-last order-first">
        <button [disabled]="!imageURL || message !== ''" [ngClass]="{'m-progress': submitting }"
                class="btn green_btn btn-block font-weight-normal font-14 px-5 mt-auto workspace-btn-padding"
                (click)="uploadLogo()">
          <span class="mx-auto" i18n="@@contBtn">Continue</span>
        <!--  <i class="icon-right-arrow-slider"></i>-->
        </button>
         </div>
    </div>
    <p class="text-danger text-center mt-2" *ngIf="errorMsg">{{errorMsg}}</p>
    <p class="mt-5 text-center"><a class="blue-text font-14" (click)="this.router.navigate(['/']);" i18n="@@skipBtn"> skip</a></p>
  </div>
</div>

</div>



<!--<div class="files-list">
  <div class="single-file" *ngFor="let file of files; let i = index">
   <div *ngIf="i === files.length-1">
    <div class="info">
      <h4 class="name">
        {{ file?.name }}
      </h4>
    </div>
   </div>

  </div>
</div>-->
