import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import { SidenavService} from '../../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../../workspace-dashboard/animations/animations';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-invite-users-page',
  templateUrl: './invite-users-page.component.html',
  styleUrls: ['./invite-users-page.component.scss'],
  animations: [ onMainContentChange ]
})
export class InviteUsersPageComponent implements OnInit {

  constructor( public dataService: DataService, private sidenavService: SidenavService, public router: Router, public auth: AuthServiceMain,
               private titleService: Title) {
    this.titleService.setTitle('Invite member | SafarHub');
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }

  ngOnInit(): void {
    this.dataService.createEditTeamPage = false;
  }

}
