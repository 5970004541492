import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import { SidenavService} from '../../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../../workspace-dashboard/animations/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {Title} from "@angular/platform-browser";
import {UserService} from "../../../../services/user.service";

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  animations: [ onMainContentChange ]
})
export class UserDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              public auth: AuthServiceMain,
              public dataService: DataService,
              private sidenavService: SidenavService,
              public router: Router,
              public userService: UserService,
              public loggedIn: AuthServiceMain, private titleService: Title) {
    this.titleService.setTitle('Member Info | SafarHub');
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
  data;
  urlArray;
  userId;
  previousURL;
  ngOnInit(): void {
    this.urlArray = (this.router.url).split('/');
    this.userId = this.urlArray[this.urlArray.length - 1];
    const currentUrl = this.route.snapshot.url;  // Get the current URL segments
    this.previousURL = currentUrl.slice(0, currentUrl.length - 1).join('/');  // Remove the last segment
    this.dataService.companyUserInfo = null;
    this.dataService.getCompanyUserInfo(this.userId);

  }
  deactivateUser(userId) {
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@deactivateUser:Deactivate User`, 'deactivateUser', userId);
    } else {
      this.router.navigate([this.router.url + '/deactivate']);
    }
  }
  editUser(userId) {
      this.router.navigate([this.router.url + '/edit']);
  }
  reactivateUser(userId) {
    this.dataService.userReactivateId = userId;
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@reactivateUser:User Reactivation Fees`, 'userReactivationFees', '');
    } else {
      this.dataService.setBottomSheet('userReactivationFees', '');
    }
  }

}
