<app-print-flight-booking booking="{{dataService.printData}}" *ngIf="dataService.showPrint"></app-print-flight-booking>

<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
                         [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="pt-5 pb-0 pb-lg-5 mb-5" [ngClass]="{'container': dataService.desktopView}">
        <div class="mx-lg-5">
          <app-manage-flight-booking *ngIf="auth.companyInfo"></app-manage-flight-booking>
        </div>
      </div>



    </div>
  </mat-sidenav-content>
</mat-sidenav-container>







