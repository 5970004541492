<div class="container pt-4 pb-lg-5">
    <form [formGroup]="updateCompanyInfo" id="preferencesForm">

      <div class="row">
        <div class="col-md-9">
          <p class="mb-1 mt-3 required" [ngClass]="{'gray-text': dataService.desktopView, 'gray-primaray-text': dataService.mobileView}" i18n="@@langPlaceHolder">Language</p>
          <div class="form-group">
            <select class="form-control mt-2 select-custom-arrow" formControlName="selectedLanguage" name="selectedLanguage"
                    [ngClass]="{ 'is-invalid': submitted && f.selectedLanguage.errors }" >
              <ng-container *ngFor="let item of languagesList">
                <option [ngValue]="item.code">{{item.name === 'Arabic' ? 'العربية' : item.name}}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-9">
          <p class="mb-1 mt-3 required" [ngClass]="{'gray-text': dataService.desktopView, 'gray-primaray-text': dataService.mobileView}" i18n="@@preferedCurrency">Currency</p>
          <div class="form-group">
            <select class="form-control mt-2 select-custom-arrow" formControlName="selectedCurrency" name="selectedCurrency"
                    [ngClass]="{ 'is-invalid': submitted && f.selectedCurrency.errors }" >
              <ng-container *ngFor="let item of currenciesList">
                <option [ngValue]="item.code">{{item.name}}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>

      <div class="row pt-3 pt-lg-5 mt-lg-5">
        <div class="col-md-6 order-lg-1" [ngClass]="{'pl-lg-2': dataService.isSiteLtr(), 'pr-lg-2': dataService.isSiteRtl()}">
          <button
                  class="mt-3 px-0 btn-block sharp-font bold-500 d-flex px-0" (click)="onSaveClicked()" [ngClass]="{'blue_btn':dataService.desktopView, 'btn btn-outline-blue w-100':dataService.mobileView}">
            <span class="mx-auto font-14" [ngClass]="{'m-progress': submitting}" i18n="@@save">Save</span>
          </button>
        </div>
        <div class="col-md-6 order-lg-0" [ngClass]="{'pr-lg-2': dataService.isSiteLtr(), 'pl-lg-2': dataService.isSiteRtl()}">
          <button (click)="dataService.rightSideDrawerOff(); dataService.BottomSheetOf()"
                  class="mt-3 btn-block btn btn-outline-gray  px-5 d-flex ml-auto">
            <span class="mx-auto gray-text-dark font-14 sharp-font bold-500" i18n="@@cancelBtn">Cancel</span>
          </button>
        </div>
      </div>
      <p class="text-danger">{{this.errorMessage}}</p>
    </form>
  </div>
  