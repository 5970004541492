import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DataService} from "../../../services/data.service";
import {SidenavService} from "../../../corporate-travel/workspace-dashboard/sidenav.service";
import { Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {FlightService} from "../../../services/flight-services/flight.service";

@Component({
  selector: 'app-flight-info',
  templateUrl: './flight-info.component.html',
  styleUrls: ['./flight-info.component.scss']

})
export class FlightInfoComponent implements OnInit {
  travelerId: string;


  constructor(public dataService: DataService,
              private sidenavService: SidenavService,
              public router: Router,
              public flightService: FlightService,
              private titleService: Title) {
  }

  ngOnInit(): void {
    this.titleService.setTitle("Flight information | SafarHub");
  }
}
