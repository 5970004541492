import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { CostManagementService } from 'src/app/services/cost-management.service';
import { CostManagementStateService } from 'src/app/services/states/cost-management-state.service';

@Component({
  selector: 'app-costs-manage',
  templateUrl: './costs-manage.component.html',
  styleUrls: ['./costs-manage.component.scss']
})
export class CostsManageComponent implements OnInit {
  @Input() id: string;
  @Input() name: string = "";
  @Input() description: string = "";
  @Input() isCenter: boolean;
  loader: boolean = false;
  placeholderText: string;
  saveBtnText: string;
  errorMsg;
  validForm: boolean = true;

  constructor(public costState: CostManagementStateService, public dataService: DataService, public costManagementService: CostManagementService) { }

  ngOnInit(): void {
    this.placeholderText = this.isCenter ? $localize`:@@enterCenterName:Enter center name` : $localize`:@@enterLabelName:Enter label name`;
    this.saveBtnText = this.getSaveButtonTxt();
  }

  getSaveButtonTxt() {
    if(!this.id) {
      if(this.isCenter) {
        return $localize`:@@createCenter:Create Center`
      } else {
        return $localize`:@@createLabel:Create Label`
      }
    } else {
      if(this.isCenter) {
        return $localize`:@@updateCenter:Update Center`
      } else {
        return $localize`:@@updateLabel:Update Label`
      }
    }
  }

  changeName() {
    this.validForm = true;
  }

  save(form) {
    if (!form.valid || !this.name.trim()) {
      this.validForm = false;
      return;
    }

    this.validForm = true;
    this.loader = true;

    const service = !this.id ?
      this.isCenter ? this.costManagementService.createCostCenter(this.name, this.description) : this.costManagementService.createLabel(this.name, this.description) :
      this.isCenter ? this.costManagementService.updateCostCenter(this.id, this.name, this.description) : this.costManagementService.updateLabel(this.id, this.name, this.description);

    service.subscribe(
      result => this.handleSuccess(result),
      error => this.handleFailed(error.message)
    );
  }

  handleSuccess(result) {
    if (result.errorCode) {
      this.handleFailed(result.message);
      return;
    }

    if (this.isCenter) {
      this.costState.modifyCostCenters(result.data);
    } else {
      this.costState.modifyLabels(result.data);
    }

    this.loader = false;
    this.cancel();
  }

  handleFailed(error) {
    if (error.includes('Http failure response')) {
      error = environment.messages.error;
    }
    this.errorMsg = error ?? environment.messages.error;

    this.loader = false;
  }

  cancel() {
    if (this.dataService.desktopView) {
      this.dataService.modalOf();
      if (this.isCenter) {
        this.dataService.scrollTop();
      }
    }
    if (this.dataService.mobileView) {
      this.dataService.BottomSheetOf();
    }
  }
}