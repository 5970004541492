<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }" [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    ">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pt-4 pt-lg-5 pb-0 pb-lg-5">
        <div class="mx-lg-5">
          <div class="row">
            <div class="col">
              <div class="pb-4 pb-lg-5 mb-0 mb-lg-3">
                <a class="font-16 blue-text text-underline" (click)="
                    this.router.navigate([
                      '/members'
                    ])
                  " i18n="@@members">Members</a>
                <span class="font-16 gray-text-dark" *ngIf="dataService.companyUserInfo">
                  / {{ dataService.companyUserInfo.fullName }}
                </span>
              </div>
            </div>
          </div>
          <div [ngClass]="{
              'mb-5 rounded border pt-4 workspace-rounded-container':
                dataService.desktopView,
              'pl-4': dataService.desktopView && dataService.isSiteLtr(),
              'pr-4': dataService.desktopView && dataService.isSiteRtl(),
              'pb-5': dataService.mobileView
            }">
            <div class="d-flex gray-text-dark" *ngIf="this.dataService.companyUserInfo">
              <div class="pt-3 details w-100" *ngIf="dataService.companyUserInfo">
                <div class="d-flex">
                  <img width="40" height="40" loading="lazy" *ngIf="dataService.companyUserInfo.profileImage"
                    class="user-img d-inline-block mb-3" [ngClass]="{ 'img-border': userService.imageHasError }"
                    [src]="userService.imageHasError ? userService.fallbackSrc : dataService.companyUserInfo.profileImage"
                    (error)="userService.imageHasError = true" />
                  <div class="name-first-letters small" *ngIf="!dataService.companyUserInfo.profileImage" [ngStyle]="{
                      'background-color':
                        dataService.companyUserInfo
                          .profileImageBackgroundHexColor
                    }">
                    {{
                      dataService.companyUserInfo.firstName
                        .substring(0, 1)
                        .toUpperCase()
                    }}{{
                      dataService.companyUserInfo.lastName
                        .substring(0, 1)
                        .toUpperCase()
                    }}
                  </div>

                  <div [ngClass]="{
                      'ml-0 ml-lg-4 pl-3': dataService.isSiteLtr(),
                      'mr-0 mr-lg-4 pr-3': dataService.isSiteRtl()
                    }">
                    <p class="gray-primaray-text mt-2 mt-lg-0 mb-0">
                      {{ dataService.companyUserInfo.fullName }}
                    </p>
                    <p class="gray-text mb-0 dotted-text dotted-text-direction">
                      {{ dataService.companyUserInfo.email }}
                    </p>
                    <p class="gray-text mb-0">
                      <ng-container i18n="@@joinDate">Join date</ng-container>:&nbsp;<span class="mb-0" [innerHTML]="
                          dataService.formatDateMMMdy(
                            dataService.companyUserInfo.creationDate
                          )
                        "></span>
                    </p>
                    <p class="text-danger font-16 bold-600 mb-0" *ngIf="dataService.companyUserInfo.inactive"
                      i18n="@@deactivated">
                      Deactivated
                    </p>
                  </div>
                </div>

                <p class="gray-text mb-0 mt-5" i18n="@@team">Team</p>
                <p class="font-16 gray-primaray-text mb-5">
                  <span *ngIf="this.dataService.companyUserInfo.teamName">{{
                    dataService.companyUserInfo.teamName
                  }}</span>
                  <span *ngIf="!this.dataService.companyUserInfo.teamName" i18n="@@unassigned">Unassigned</span>
                </p>

                <p class="gray-text mb-0" i18n="@@travelPolicy">
                  Travel Policy
                </p>
                <p class="font-16 gray-primaray-text mb-5">
                  <span *ngIf="this.dataService.companyUserInfo.policyName">{{
                    this.dataService.companyUserInfo.policyName
                  }}</span>
                  <span *ngIf="!this.dataService.companyUserInfo.policyName" i18n="@@unassigned">Unassigned</span>
                </p>

                <p class="gray-text mb-2 mb-lg-1" i18n="@@CompCards">
                  Company Cards
                </p>
                <div class="font-16 gray-primaray-text mb-5">
                  <ng-container *ngIf="this.dataService.companyUserInfo.companyCards">
                    <div *ngFor="
                        let item of this.dataService.companyUserInfo
                          .companyCards
                      ">
                      <p class="mb-lg-0" *ngIf="item.alias">
                        <span [ngClass]="{
                            'payment-item table-header font-12 rounded':
                              dataService.mobileView
                          }">{{ item.alias }}</span>
                      </p>
                      <p class="mb-lg-0" *ngIf="!item.alias">
                        <span [ngClass]="{
                            'payment-item table-header font-12 rounded':
                              dataService.mobileView
                          }">{{ item.brand }} {{ item.lastFour }}</span>
                      </p>
                    </div>
                  </ng-container>
                  <span *ngIf="
                      !this.dataService.companyUserInfo.companyCards ||
                      (this.dataService.companyUserInfo.companyCards &&
                        !this.dataService.companyUserInfo.companyCards.length)
                    " i18n="@@noPayment">No Payment Methods</span>
                </div>

                <p class="gray-text mb-2 mb-lg-1" i18n="@@wallets">
                  Wallets
                </p>
                <div class="font-16 gray-primaray-text mb-5">
                  <ng-container *ngIf="this.userWallets">
                    <div *ngFor="
                          let item of this.userWallets
                        ">
                      <p class="mb-lg-0" *ngIf="item.name">
                        <span [ngClass]="{
                              'payment-item table-header font-12 rounded':
                                dataService.mobileView
                            }">{{ item.name }}</span>
                      </p>
                    </div>
                  </ng-container>
                  <span *ngIf="
                        !this.userWallets ||
                        (this.userWallets &&
                          !this.userWallets.length)
                      " i18n="@@noWallet">No Wallets</span>
                </div>

                <p class="gray-text mb-0" i18n="@@role">Role</p>
                <p class="font-16 gray-primaray-text text-capitalize mb-0">
                  <span *ngIf="dataService.companyUserInfo.role === 'OWNER'" i18n="@@owner">
                    {{ dataService.companyUserInfo.role.toLowerCase().replace("_", " ") }}
                  </span>
                  <span *ngIf="dataService.companyUserInfo.role === 'ADMIN'" i18n="@@admin">
                    {{ dataService.companyUserInfo.role.toLowerCase().replace("_", " ") }}
                  </span>
                  <span *ngIf="dataService.companyUserInfo.role === 'TEAM_MANAGER'" i18n="@@teamManager">
                    {{ dataService.companyUserInfo.role.toLowerCase().replace("_", " ") }}
                  </span>
                  <span *ngIf="dataService.companyUserInfo.role === 'MEMBER'" i18n="@@member">
                    {{ dataService.companyUserInfo.role.toLowerCase().replace("_", " ") }}
                  </span>
                </p>

                <div class="row pt-5 mt-5">
                  <div class="col-lg-2 btn-min-width" *ngIf="dataService.companyUserInfo.inactive">
                    <button class="btn-outline-blue btn py-1 px-4 mb-3 font-14 w-100 sharp-font bold-500"
                      (click)="reactivateUser(dataService.companyUserInfo.id)" i18n="@@activate">
                      Activate
                    </button>
                  </div>
                  <div class="col-lg-2 btn-min-width" *ngIf="!dataService.companyUserInfo.inactive">
                    <button class="btn-outline-red btn py-1 px-4 mb-3 font-14 w-100 sharp-font bold-500" [disabled]="
                        dataService.companyUserInfo.role.toLowerCase() ===
                        'owner'
                      " (click)="deactivateUser(dataService.companyUserInfo.id)" i18n="@@deActivate">
                      Deactivate
                    </button>
                  </div>

                  <div class="col-lg-2 order-sm-last order-first btn-min-width" [ngClass]="{
                      'pl-lg-0': dataService.isSiteLtr(),
                      'pr-lg-0': dataService.isSiteRtl()
                    }">
                    <button
                      class="text-center btn-block btn py-1 btn-outline-blue font-14 px-5 w-100 sharp-font bold-500"
                      (click)="editUser(dataService.companyUserInfo.id)" i18n="@@edit">
                      Edit</button><br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>