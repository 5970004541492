<div *ngIf="booking">
<div class="row grey-bg font-16">
  <div class="col-6">
   <a routerLink="/" class="logo_link ">
    <img  width="145" height="35" loading="lazy" src="assets/img/logo.svg" class="logo-image" alt="SafarHub Logo">
  </a>
  </div>
  <div class="col-6 gray-text" [ngClass]="{'text-right': restApi.isSiteLtr(), 'text-left': restApi.isSiteRtl()}">
    <p class="mb-0">https://www.safarhub.com</p>
    <p>41829 Albrae Street, Fremont, CA 94538</p>
  </div>
</div>
<p class="booking-type border-bottom gray-primaray-text bolder">
  <span *ngIf="!restApi.inactiveWorkspace && bookingType === 'cancelled'" i18n="@@activeBookingCanceled">Your booking is canceled</span>
  <span *ngIf="!restApi.inactiveWorkspace && bookingType === 'completed'" i18n="@@activeBookingCompleted">Your booking is completed</span>
  <span *ngIf="!restApi.inactiveWorkspace && bookingType === 'confirmed'" i18n="@@activeBookingConfirmed">Your booking is confirmed</span>

  <span *ngIf="restApi.inactiveWorkspace && bookingType === 'cancelled'" i18n="@@inActiveBookingCanceled">Booking is canceled</span>
  <span *ngIf="restApi.inactiveWorkspace && bookingType === 'completed'" i18n="@@inActiveBookingCompleted">Booking is completed</span>
  <span *ngIf="restApi.inactiveWorkspace && bookingType === 'confirmed'" i18n="@@inActiveBookingConfirmed">Booking is confirmed</span>
</p>
  <p class="font-16 gray-text my-5" *ngIf="bookingType === 'cancelled'" i18n="@@printCancelHotel">We're confirming your reservation at {{booking.hotelName}} has been cancelled.</p>
  <p class="font-16 gray-text my-5" *ngIf="bookingType !== 'cancelled'"><ng-container i18n="@@weConfirm">We're confirming your reservation at  {{booking.hotelName}}</ng-container> <ng-container i18n="@@printConfirmHotel1"> on {{checkin}}</ng-container> <ng-container i18n="@@printConfirmHotel2">through {{checkout}}.</ng-container></p>
    <p class="font-18 gray-primaray-text bolder" i18n="@@hotelInformation">Hotel Information</p>
<div class="table-color gray-text-dark">
    <div class="row grey-bg font-16 py-2">
      <div class="col-6" i18n="@@hotelName">Hotel Name</div>
      <div class="col-6">{{booking.hotelName}}</div>
    </div>
    <div class="row font-16 py-2">
      <div class="col-6" i18n="@@address">Address</div>
      <div class="col-6">{{booking.hotelAddress? booking.hotelAddress : ''}}</div>
    </div>
    <div class="row grey-bg font-16 py-2">
      <div class="col-6" i18n="@@city">City</div>
      <div class="col-6">{{booking.cityName? booking.cityName : ''}}</div>
    </div>
    <div class="row font-16 py-2" *ngIf="booking.stateName">
      <div class="col-6" i18n="@@state">State</div>
      <div class="col-6">{{booking.stateName}}</div>
    </div>
    <div class="row font-16 py-2" [ngClass]="{'grey-bg' : booking.stateName}">
      <div class="col-6" i18n="@@country">Country</div>
      <div class="col-6">{{booking.countryName? booking.countryName : ''}}</div>
    </div>
</div>

<p class="font-18 gray-primaray-text bolder mt-5" i18n="@@stayInformation">Stay Information</p>
<div class="table-color gray-text-dark">
  <div class="row grey-bg font-16 py-2">
    <div class="col-6" i18n="@@confirmationNumber">Confirmation Number</div>
    <div class="col-6">{{this.booking.referenceNumber}}</div>
  </div>
  <div class="row font-16 py-2">
    <div class="col-6" i18n="@@checkInDate">Check In Date</div>
    <div class="col-6">{{checkin}}</div>
  </div>
  <div class="row grey-bg font-16 py-2">
    <div class="col-6" i18n="@@checkOutDate">Check Out Date</div>
    <div class="col-6">{{checkout}}</div>
  </div>
  <div class="row font-16 py-2">
    <div class="col-6" i18n="@@totalNights">Total Nights</div>
    <div class="col-6">{{nights}}</div>
  </div>
</div>


<p class="font-18 gray-primaray-text bolder mt-5" i18n="@@roomInformation">Room Information</p>
<div class="table-color gray-text-dark">
  <div class="row grey-bg font-16 py-2">
    <div class="col-6" i18n="@@roomName">Room Name</div>
    <div class="col-6">{{booking.roomName}}</div>
  </div>
  <div class="row font-16 py-2">
    <div class="col-6" i18n="@@guestName">Guest Name</div>
    <div class="col-6">
      {{booking.guestFirstName? booking.guestFirstName : ''}} {{booking.guestLastName? booking.guestLastName : ''}}
    </div>
  </div>
  <div class="row grey-bg font-16 py-2">
    <div class="col-6" i18n="@@numberOfAdults">Number of Adults</div>
    <div class="col-6">{{booking.paxes.roomInfos[0].numberOfAdults}}</div>
  </div>
  <div class="row font-16 py-2" *ngIf="booking.paxes.roomInfos[0].childAges.length">
    <div class="col-6" i18n="@@numberOfChildren">Number of Children</div>
    <div class="col-6">{{booking.paxes.roomInfos[0].childAges.length}}</div>
  </div>
  <div class="row font-16 py-2" [ngClass]="{'grey-bg' : booking.paxes.roomInfos[0].childAges.length}">
    <div class="col-6" i18n="@@emailAddress">Email Address</div>
    <div class="col-6">{{booking.emailAddress}}</div>
  </div>
  <div class="row font-16 py-2" [ngClass]="{'grey-bg' : !booking.paxes.roomInfos[0].childAges.length}">
    <div class="col-6" i18n="@@phoneNumber">Phone Number</div>
    <div class="col-6">{{booking.phoneNumber}}</div>
  </div>
</div>


<p class="font-18 gray-primaray-text bolder mt-5" i18n="@@paymentDetails">Payment Details</p>
<div class="table-color gray-text-dark" *ngIf="bookingType !== 'cancelled'">
  <div class="row grey-bg font-16 py-2">
    <div class="col-6" i18n="@@approvalAvgNightlyPrice">Average Nightly Price</div>
    <div class="col-6">${{booking.averageNightlySellingPrice}}</div>
  </div>
  <div class="row font-16 py-2" *ngIf="!booking.taxIncluded && booking.tax">
    <div class="col-6" i18n="@@taxAndFees">Tax and Fees</div>
    <div class="col-6">${{booking.tax}}</div>
  </div>
  <div class="row grey-bg font-16 py-2" *ngIf="booking.userDiscount">
    <div class="col-6" i18n="@@price">Price</div>
    <div class="col-6">${{booking.initialSellingPrice}}</div>
  </div>
  <div class="row font-16 py-2" *ngIf="booking.userDiscount">
    <div class="col-6" i18n="@@discount">Discount</div>
    <div class="col-6">-${{booking.discountAmount}} </div>
  </div>
  <div class="row grey-bg font-16 py-2">
    <div class="col-6 bolder"><ng-container i18n="@@totalPrice">Total Price</ng-container></div>
    <div class="col-6">{{booking.sellingPriceConvertedCurrency}} {{booking.sellingPriceConverted}}</div>
  </div>
  <div class="row font-16 py-2">
    <div class="col-6" i18n="@@paymentMethod">Payment Method</div>
    <div class="col-6" *ngIf="booking.payment.channelProvider === bookingWalletsService.booking.payment.amazon">
      {{booking.payment.brand}} <ng-container i18n="@@endsWith">ends with</ng-container> {{booking.payment.lastFour}}
    </div>
    <div class="col-6" *ngIf="booking.payment.channelProvider === bookingWalletsService.booking.payment.wallet">
       <ng-container i18n="@@companyWallet">Company wallet</ng-container>
    </div>
  </div>
</div>

<div class="table-color gray-text-dark" *ngIf="bookingType === 'cancelled'">
  <div class="row grey-bg font-16 py-2">
    <div class="col-6" i18n="@@totalPaid">Total Paid</div>
    <div class="col-6">{{booking.sellingPriceConvertedCurrency}} {{booking.sellingPriceConverted}}</div>
  </div>
  <div class="row font-16 py-2">
    <div class="col-6" i18n="@@cancelPenalty">Cancellation Penalty</div>
    <div class="col-6">{{booking.penaltyConvertedCurrency}} {{ booking.penaltyConverted }}</div>
  </div>
  <div class="row grey-bg font-16 py-2">
    <div class="col-6" i18n="@@totalRefund">Total refund</div>
    <div class="col-6">{{booking.refundAmountConvertedCurrency}} {{booking.refundAmountConverted}}</div>
  </div>
  <div class="row font-16 py-2">
    <div class="col-6" i18n="@@paymentMethod">Payment Method</div>
    <div class="col-6" *ngIf="booking.payment.channelProvider === bookingWalletsService.booking.payment.amazon">
      {{booking.payment.brand}} <ng-container i18n="@@endsWith">ends with</ng-container> {{booking.payment.lastFour}}
    </div>
    <div class="col-6" *ngIf="booking.payment.channelProvider === bookingWalletsService.booking.payment.wallet">
      <ng-container i18n="@@companyWallet">Company wallet</ng-container>
    </div>
  </div>
</div>


<p class="font-18 gray-primaray-text bolder mt-5" i18n="@@cancellationPolicies">Cancellation Policies</p>
<div *ngIf="booking.refundable">
  <p class="gray-primaray-text font-16" *ngFor="let policy of booking.cancellationPolicies">
    {{policy.description}}
  </p>
</div>

<div class="non-refundable" *ngIf="!booking.refundable" i18n="@@nonrefundable">Non-refundable</div>
</div>
