import { Injectable } from '@angular/core';

declare let ga: Function; // Declare ga as a function

@Injectable()

export class GoogleAnalyticsService {

  constructor() { }


  // create our event emitter to send our data to google analytics
  public eventEmitter(eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
    ga('send', 'event', {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue
    });
  }
  public  addTransaction(id, sellingPrice) {

    const transaction = {
      id: id,
      affiliation: 'Triphop',
      revenue: sellingPrice,
      currency: 'USD'
    };
    ga('ecommerce:addTransaction', transaction);
    ga('ecommerce:send');


  }

  public addItem(id, sellingPrice, ecommerceName) {


    const item  = {
      id: id,
      name: ecommerceName,
      affiliation: 'Triphop',
      sku: 'booking',
      category: 'Hotels',
      price: sellingPrice,
      quantity: '1',

      currency: 'USD'
    };
    ga('ecommerce:addItem', item );
    ga('ecommerce:send');

  }
}
