import { Component, ElementRef, OnInit } from "@angular/core";
import { AuthServiceMain } from "../../../auth/auth.service";
import { onMainContentChange } from "../../workspace-dashboard/animations/animations";
import { DataService } from "../../../services/data.service";
import { SidenavService } from "../../workspace-dashboard/sidenav.service";
import { Router } from "@angular/router";
import { VariablesService } from "../../../services/vars.service";
import { environment } from "../../../../environments/environment";
import { Title } from "@angular/platform-browser";
import {UserService} from "../../../services/user.service";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
  animations: [onMainContentChange],
  host: {
    "(body:click)": "onClickBody($event)",
  },
})
export class UserProfileComponent implements OnInit {
  constructor(
    private titleService: Title,
    private eref: ElementRef,
    public dataService: DataService,
    private sidenavService: SidenavService,
    public router: Router,
    public auth: AuthServiceMain,
    public vars: VariablesService,
    public userService: UserService
  ) {
    this.titleService.setTitle("My profile | SafarHub");
    this.sidenavService.sideNavState$.subscribe((res) => {
      this.dataService.onSideNavChange = res;
    });
  }

  env = environment;
  editLanguage = false;
  editAddress = false;
  submitted = false;
  submitting = false;
  countries = this.vars.countries;
  states = this.vars.states;
  selectedValue = null;
  selectedState = null;
  data;
  addressFormData = [];
  sendAddressData = "";
  noMatch = false;
  errorMsg = "";
  dataUser;
  image = new FormData();
  showActions = false;
  selectedIndex;
  showActionsDoc = false;
  selectedIndexDoc;

  ngOnInit(): void {
    this.dataService.getApplicablePolicyDetails();
    this.dataService.getUserInfo().subscribe(
      (suc) => {
        this.data = suc;
        if (this.data.errorCode === 0) {
          if (this.data.data.address) {
            this.auth.address = {
              address1: this.data.data.address.address1,
              address2: this.data.data.address.address2,
              cityName: this.data.data.address.city,
              zipCode: this.data.data.address.zipCode,
              countryCode: this.data.data.address.countryCode,
              stateCode: this.data.data.address.stateCode,
              countryName: this.data.data.address.countryName,
            };
          }
          this.auth.userInfo = {
            name: this.data.data.firstName,
            imageUrl: this.data.data.profileImage,
            id: this.data.data.id,
            lastName: this.data.data.lastName,
            email: this.data.data.email,
            phoneNumber: this.data.data.phoneNumber,
            phoneNumberCountryCode: this.data.data.phoneNumberCountryCode,
            creationDate: this.data.data.creationDate,
            frequentFlyerPrograms: this.data.data.frequentFlyerPrograms,
            travelDocuments: this.data.data.travelDocuments,
            gender: this.data.data.gender,
            birthDate: this.data.data.birthDate,
            address: this.auth.address,
            inactive: this.data.data.inactive,
            role: this.data.data.role,
            policyId: this.data.data.policyId,
            teamId: this.data.data.teamId,
            teamName: this.data.data.teamName,
            effectivePolicyId: this.data.data.effectivePolicyId,
            effectivePolicyName: this.data.data.effectivePolicyName,
            companyCards: this.data.data.companyCards,
            profileImageBackgroundHexColor:
              this.data.data.profileImageBackgroundHexColor,
            personalPaymentMethodsDisabled:
              this.data.data.personalPaymentMethodsDisabled,
            rewardWallet: this.data.data.rewardWallet,
            systemPreferences: this.data.data.systemPreferences
          };
          this.dataService.userProfileImageWithRandomText =
            this.auth.userInfo.imageUrl + "?" + new Date().getTime();
          localStorage.setItem("leads_email", this.data.data.email);
          localStorage.setItem("userInfo", JSON.stringify(this.auth.userInfo));
        }
      },
    );
    this.dataService.userProfileImageWithRandomText =
      this.auth.userInfo.imageUrl + "?" + new Date().getTime();
  }

  returnPhoneCountryCode() {
    if (Number(this.auth.userInfo.phoneNumberCountryCode)) {
      return this.auth.userInfo.phoneNumberCountryCode;
    } else {
      let selected = this.vars.countries.filter(
        (item) => item.code === this.auth.userInfo.phoneNumberCountryCode
      );
      return selected[0].dial_code;
    }
  }

  editUserInfo() {
    if (this.dataService.desktopView) {
      this.dataService.setRightSideDrawer("memberInfo", "");
    } else {
      this.dataService.setBottomSheet("memberInfo", "");
    }
  }

  editPreferences(data) {
    if (this.dataService.desktopView) {
      this.dataService.setRightSideDrawer("memberPreferences", data);
    } else {
      this.dataService.setBottomSheet("memberPreferences", data);
    }
  }

  editUserPassword() {
    if (this.dataService.desktopView) {
      this.dataService.setRightSideDrawer("memberPassword", "");
    } else {
      this.dataService.setBottomSheet("memberPassword", "");
    }
  }

  addEditTravelDocument(doc) {
    if (this.dataService.desktopView) {
      this.dataService.setRightSideDrawer("travelDocument", doc);
    } else {
      this.dataService.setBottomSheet("travelDocument", doc);
    }
  }

  deleteDoc(id) {
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@removeTravelDoc:Remove Travel Document`,
        "deleteDoc",
        id
      );
    } else {
      this.dataService.setBottomSheet("deleteDoc", id);
    }
  }

  deleteProgram(id) {
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@removeFlyerProg:Remove Flyer Program`,
        "deleteProgram",
        id
      );
    } else {
      this.dataService.setBottomSheet("deleteProgram", id);
    }
  }
  addEditFlyerProgram(program) {
    if (this.dataService.desktopView) {
      this.dataService.setRightSideDrawer("flyerProgram", program);
    } else {
      this.dataService.setBottomSheet("flyerProgram", program);
    }
  }
  onClickBody(event) {
    if (
      this.eref.nativeElement.querySelector(".image-container") &&
      !this.eref.nativeElement
        .querySelector(".image-container")
        .contains(event.target) &&
      this.eref.nativeElement.querySelector(".actions") &&
      !this.eref.nativeElement.querySelector(".actions").contains(event.target)
    ) {
      this.dataService.editProfileImage = false;
    }
    if (
      this.eref.nativeElement.querySelector(
        ".drop-down" + this.selectedIndex
      ) &&
      !this.eref.nativeElement
        .querySelector(".drop-down" + this.selectedIndex)
        .contains(event.target)
    ) {
      this.showActions = false;
    }
    if (
      this.eref.nativeElement.querySelector(
        ".doc-drop-down" + this.selectedIndexDoc
      ) &&
      !this.eref.nativeElement
        .querySelector(".doc-drop-down" + this.selectedIndexDoc)
        .contains(event.target)
    ) {
      this.showActionsDoc = false;
    }
  }

  openProfileMenu() {
    this.dataService.editProfileImage = !this.dataService.editProfileImage;
    if (this.dataService.mobileView) {
      this.dataService.setBottomSheet("profileImageMobileMenu", "");
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.submitting = true;
      if (this.image.get("profileImage")) {
        this.image.delete("profileImage");
      }
      this.image.append("profileImage", event.target.files[0]);
      this.dataService.setProfileImage(this.image).subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.submitting = false;
            this.dataService.showDefaultUserImage = false;
            this.auth.userInfo.imageUrl = this.data.data.profileImage;
            this.dataService.userProfileImageWithRandomText =
              this.auth.userInfo.imageUrl + "?" + new Date().getTime();
            localStorage.setItem(
              "userInfo",
              JSON.stringify(this.auth.userInfo)
            );
            this.dataService.BottomSheetOf();
            this.dataService.modalOf();
            this.dataService.fetchUserData.next();
          } else {
            this.submitting = false;
            this.dataService.messages(this.data.message, "error");
          }
        },
        (error) => {
          this.submitting = false;
          this.dataService.messages(environment.messages.error, "error");
        }
      );
    }
  }

  changeLoc(lang) {
    if (this.dataService.corporateLocale.includes(lang)) {
      return;
    } else {
      this.dataService.changeLocale(lang);
    }
  }
}
