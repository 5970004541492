<h1 class="green-text bolder mb-md-2 mb-3">
  <i class="icon-locked"></i
  ><ng-container i18n="@@secureBooking">Secure Booking</ng-container>&nbsp;<span
    class="smaller"
    ><ng-container i18n="@@almostDone"
      >(You are almost done)</ng-container
    ></span
  >
</h1>

<h4 class="orange-text mb-2">
  <i class="icon-warning"></i>&nbsp;<ng-container i18n="@@actFast"
    >Act fast! Pricing and availability may change!</ng-container
  >
</h4>

<div
  [ngClass]="{
    'text-right': Service.isSiteLtr(),
    'text-left': Service.isSiteRtl()
  }"
>

  <span
    (click)="this.Auth.logout()"
    *ngIf="this.Auth.isLogedIn && this.Auth.userInfo"
    [ngClass]="{ 'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl() }"
    class="pointer blue-text"
    i18n="@@notAndSign"
    >Not {{ this.Auth.userInfo.name }}? Sign out
  </span>
</div>

<form
  (ngSubmit)="onSubmit()"
  [formGroup]="bookingForm"
  class="hotel-booking"
  id="bookingForm"
>
  <div class="section-info radius mb-4 mt-4">
    <div class="section-header">
      <span class="primaray-text font-weight-bold"
        ><i class="icon-user"></i>&nbsp;<ng-container i18n="@@guestDetails"
          >Guest Details</ng-container
        ></span
      >
    </div>

    <div class="section-body p-5">
      <h5 class="bolder text-medium" i18n="@@guestName">Guest Name</h5>
      <div
        *ngIf="this.travelerId && this.travelerId !== 'guest'"
        class="row gutters-10 mb-4"
      >
        <div class="col-sm-6">
          <div class="d-flex align-items-center form-control">
            {{ traveler?.travelerName }}
          </div>
        </div>
      </div>
      <div
        *ngIf="this.travelerId && this.travelerId === 'guest'"
        class="row gutters-10"
      >
        <div
          [ngClass]="{
            'pr-lg-2': Service.isSiteLtr(),
            'pl-lg-2': Service.isSiteRtl()
          }"
          class="form-group col-sm-6 col-md-6"
        >
          <input
            [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }"
            class="form-control"
            formControlName="FirstName"
            i18n-placeholder="@@firstNamePlaceHolder"
            name="FirstName"
            pattern="^[A-Za-z -]+$"
            placeholder="First Name"
            type="text"
          />

          <div
            *ngIf="
              submitted && f.FirstName.errors && f.FirstName.errors.required
            "
            class="invalid-feedback"
            i18n="@@requiredFirstNameError"
          >
            First Name is required
          </div>
        </div>

        <div
          [ngClass]="{
            'pr-lg-2': Service.isSiteLtr(),
            'pl-lg-2': Service.isSiteRtl()
          }"
          class="form-group col-sm-6 col-md-6"
        >
          <input
            [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }"
            class="form-control"
            formControlName="LastName"
            i18n-placeholder="@@lastNamePlaceHolder"
            name="LastName"
            pattern="^[A-Za-z -]+$"
            placeholder="Last Name"
            type="text"
          />
          <div
            *ngIf="submitted && f.LastName.errors && f.LastName.errors.required"
            class="invalid-feedback"
            i18n="@@requiredLastNameError"
          >
            Last name is required
          </div>
        </div>
      </div>
      <h5 class="bolder text-medium mt-2" i18n="@@howContactYou">
        How can we contact you?
      </h5>
      <div class="row gutters-10">
        <div
          *ngIf="this.travelerId && this.travelerId === 'guest'"
          class="col-sm-6"
        >
          <div class="form-group with-icon">
            <input
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              autocomplete="off"
              class="form-control"
              formControlName="email"
              i18n-placeholder="@@emailAddress"
              name="email"
              placeholder="Email address"
              type="text"
            />
            <i class="icon-email"></i>
          </div>
          <div *ngIf="submitted && f.email.errors" class="smaller text-danger">
            <div *ngIf="f.email.errors.required" i18n="@@requiredEmailError">
              email is required
            </div>
            <div *ngIf="f.email.errors.email" i18n="@@invalidEmail">
              Invalid email
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group with-icon">
            <input
              [ngClass]="{
                'is-invalid': submitted && f.phone.errors,
                'text-right': Service.isSiteRtl(),
                'text-left': Service.isSiteLtr()
                }"
              class="form-control"
              formControlName="phone"
              name="phone"
              pattern="[[\+]{0,1}(\d{0,20}|[\(][\+]{0,1}\d{2,}[\20)]*\d{5,20}|\d{2,6}[\-]{1}\d{2,20}[\-]*\d{3,20})]*"
              placeholder="{{ phonePlaceHolder }}"
              type="tel"
            />
            <i class="icon-phone"></i>
          </div>
          <div *ngIf="submitted && f.phone.errors" class="smaller text-danger">
            <div *ngIf="f.phone.errors.required" i18n="@@phoneNumberRequired">
              Phone number is required
            </div>
            <div
              *ngIf="f.phone.errors && !f.phone.errors.required"
              i18n="@@invalidPhoneNumber"
            >
              Invalid phone number
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div
            [ngClass]="{
              'text-right': Service.isSiteRtl(),
              'text-left': Service.isSiteLtr()
            }"
          >
            <span
              (click)="specialReq = !specialReq"
              class="pt-3 blue-text d-inline-block clickable-cursor text-medium mb-2 mb-md-0"
              i18n="@@anySpecialRequests"
              id="special"
            >
              Any special requests?
            </span>
          </div>
        </div>
      </div>

      <div *ngIf="specialReq" class="form-group">
        <textarea
          [ngClass]="{ 'is-invalid': f.specialRequests.errors }"
          class="form-control mt-2 mb-2 ng-pristine"
          formControlName="specialRequests"
          i18n-placeholder="@@specialRequests"
          maxlength="500"
          name="specialRequests"
          placeholder="Special Requests (Optional)"
          rows="4"
        ></textarea>
        <div
          *ngIf="f.specialRequests.errors"
          class="invalid-feedback"
          i18n="@@messageTooLong"
        >
          Your message is too long
        </div>
        <span class="gray-text-dark"
          ><i class="icon-warning"></i>&nbsp;<ng-container
            i18n="@@specialRequestsGuaranteed"
            >Special requests are not guaranteed and subjected to
            availability</ng-container
          ></span
        >
      </div>
    </div>
  </div>

  <div class="section-info radius mb-4">
    <div class="section-header">
      <span class="primaray-text font-weight-bold"
        ><i class="icon-credit-card-alt"></i>&nbsp;<ng-container
          i18n="@@paymentDetails"
          >Payment Details</ng-container
        ></span
      >
    </div>

    <div class="section-body gray-text-dark p-5">
      <div class="row">
        <div
          [ngClass]="{ 'custom-col-width': this.Service.sideNavState }"
          class="col-md-7"
        >
          <h5 i18n="@@choosePaymentMethod">Please choose a payment method</h5>

          <div class="form-type-radio">
            <label class="radio-inline" for="new_card_payment_method">
              <input
                (change)="onItemChange($event)"
                checked="checked"
                class="form-checkbox"
                id="new_card_payment_method"
                name="payment_method"
                type="radio"
                value="new_card"
              />
              <span i18n="@@creditDebitCard">Credit / Debit Card </span></label
            >
          </div>

            <select
                    (focus)="callSavedCardsMethod()"
              (change)="savedCardChange()"
              [(ngModel)]="selectedCard"
              [ngClass]="{
              'is-invalid': noSelectedCard,
                'd-block':
                  this.Auth.isLogedIn &&
                  paymentType === 'new_card'
              }"
              [ngModelOptions]="{ standalone: true }"
              class="form-control mb-3 d-none"
            >
              <option *ngIf="(savedCardList | async)?.length"
                      i18n="@@selectPaymentCard"
                      value="selectPayment"
                      disabled
              >
                Select payment card
              </option>
              <ng-container *ngFor="let method of savedCardList | async; let i = index">
                <option *ngIf="method.primary === false" [value]="method.token">
                  {{ method.alias }} - {{ method.brand }} <ng-container i18n="@@endsWith">ending with</ng-container>
                  {{ method.lastFour }}
                </option>
              </ng-container>
              <ng-container
                      *ngFor="let method of savedCardList | async; let i = index"
              >
                <option
                  *ngIf="method.primary === true"
                  [ngValue]="method.token"
                  [selected]="true"
                >
                  {{ method.alias }} - {{ method.brand }} <ng-container i18n="@@endsWith">ending with</ng-container>
                  {{ method.lastFour }}
                </option>
              </ng-container>
              <option *ngIf="!(savedCardList | async)?.length"
                      i18n="@@noPaymentMethod"
                      value="noPayment"
                      disabled
              >
                You don’t have payment method
              </option>
              <option *ngIf="!Service.isSafari()"
                      [ngClass]="{
                  'd-none':
                    this.travelerId &&
                    this.travelerId !== 'guest' &&
                    this.traveler?.personalPaymentMethodsDisabled
                }"
                class="blue-text"
                i18n="@@newPaymentMethod"
                value="new">
                Add new payment method
              </option>
            </select>

          <a *ngIf="Service.isSafari()"
             href="/user/payment"
             target="_blank"
             i18n="@@newPaymentMethod">Add new payment method</a>

          <div *ngIf="personalCardSelected | async"
                 class="mt-2">
            <div class="form-group">
              <label i18n="@@securityCode">
                CVV
              </label>
              <input  maxlength="4"
                      type="text"
                      name="cvv"
                      formControlName="cvv"
                      class="form-control w-33"
                      placeholder=""
                      autocomplete="off"
                      [ngClass]="{ 'is-invalid': inValidCVV || f.cvv.errors }"
              />
            </div>

        </div>
     <div
            [ngClass]="{ 'd-block': paymentType !== 'new_card' }"
            class="paypal-text mt-2 d-none"
            i18n="@@makeSurePaymDetails">
            Make sure to fill all the details then press the checkout button at
            the bottom, you will be redirected to complete the payment. After
            you complete, you will come back to SafarHub to view your booking
            confirmation.
          </div>
        </div>

        <div
          [ngClass]="{
            'border-left': Service.isSiteLtr(),
            'border-right': Service.isSiteRtl(),
            'custom-col-width': this.Service.sideNavState
          }"
          class="col-md-5 border-sm-0">
          <app-accepted-cards-section></app-accepted-cards-section>
        </div>

      </div>
      <div *ngIf="this.Service.newRate">
        <div
          *ngIf="this.Service.newRate.comment && readMore === true"
          class="border-top mt-4 pt-4"
        >
          <h4 class="mt-0 mb-3 bolder" i18n="@@additionalInfo">
            Additional Info.
          </h4>
          <div
            [innerHTML]="this.Service.newRate.comment"
            class="text-medium"
          ></div>
        </div>
        <div
          *ngIf="this.Service.newRate.comment && readMore !== true"
          class="border-top mt-4 pt-4"
        >
          <h4 class="mt-0 mb-3 bolder" i18n="@@additionalInfo">
            Additional Info.
          </h4>
          <div
            [innerHTML]="this.Service.newRate.comment | slice : 0 : 460"
            class="text-medium"
          ></div>
        </div>
        <p (click)="readMore = !readMore" class="btn-link mb-2">
          <span *ngIf="readMore === false" i18n="@@readMore"> Read More </span>
        </p>

        <div class="border-top mt-4 pt-4">
          <h4 class="mt-0 mb-3 bolder" i18n="@@cancellationPolicies">
            Cancellation Policies
          </h4>
          <div
            *ngIf="
              this.Service.newRate.refundable &&
              this.Service.newRate.cancellationPolicies.length > 0
            "
          >
            <div
              *ngFor="let policy of this.Service.newRate.cancellationPolicies"
              class="text-medium"
            >
              {{ policy.description }}
            </div>
          </div>
          <div
            *ngIf="
              !this.Service.newRate.refundable ||
              this.Service.newRate.cancellationPolicies.length == 0
            "
            class="non-refundable"
            i18n="@@nonrefundable"
          >
            Non-refundable
          </div>
        </div>
        <div class="border-top mt-4 pt-4">
          <h4 class="mt-0 mb-3 bolder" i18n="@@reservationTerms">
            Reservation Terms
          </h4>
          <div class="text-medium">
            <p>
              <ng-container i18n="@@completeBookAgree"
                >By completing this booking, you agree to the</ng-container
              >&nbsp;<a
                    [href]="Service.isLocaleArabic ? env.termsUrlAR : env.termsUrl"
                target="_blank"
                ><ng-container i18n="@@termsAndConditions"
                  >terms and conditions</ng-container
                ><small><i class="fas fa-external-link-alt"></i></small></a
              >&nbsp;<ng-container i18n="@@ofSafarHub"
                >of SafarHub.</ng-container
              >
            </p>
            <p>
              <span i18n="@@youWillCharged">You will be charged </span
              ><span
                >{{ this.Service.newRate.chargeAmountCurrency }}
                {{ this.Service.newRate.chargeAmount }} </span
              >
              <span *ngIf="Service.isSiteRtl()" i18n="@@fromYou">from you</span>
            </p>
          </div>
        </div>
      </div>

      <div *ngIf="this.Service.newRate">
        <div *ngIf="this.Service.newRate.errorCode === 0">
          <div
            *ngIf="
              (this.Service.newRate || this.Service.newRate) &&
              this.Service.newRate.comment
            "
            class="border-top mt-4 pt-4"
          >
            <h4 class="mt-0 mb-3 bolder" i18n="@@additionalInfo">
              Additional Info.
            </h4>
            <div
              [innerHTML]="this.Service.newRate.comment"
              class="text-medium"
            ></div>
          </div>
          <div
            *ngIf="
              this.Service.newRate.refundable &&
              this.Service.newRate.cancellationPolicies.length > 0
            "
            class="border-top mt-4 pt-4"
          >
            <h4 class="mt-0 mb-3 bolder" i18n="@@cancellationPolicies">
              Cancellation Policies
            </h4>
            <div>
              <div
                *ngFor="let policy of this.Service.newRate.cancellationPolicies"
                class="text-medium"
              >
                {{ policy.description }}
              </div>
            </div>
            <div
              *ngIf="
                !this.Service.newRate.refundable ||
                this.Service.newRate.cancellationPolicies.length == 0
              "
              class="non-refundable"
            >
              Non-refundable
            </div>
          </div>
          <div class="border-top mt-4 pt-4">
            <h4 class="mt-0 mb-3 bolder" i18n="@@reservationTerms">
              Reservation Terms
            </h4>
            <div class="text-medium">
              <p>
                <ng-container i18n="@@completeBookAgree"
                  >By completing this booking, you agree to the</ng-container
                >&nbsp;<a
                  [href]="Service.isLocaleArabic ? env.termsUrlAR : env.termsUrl"
                  target="_blank"
                  ><ng-container i18n="@@termsAndConditions"
                    >terms and conditions</ng-container
                  ><small><i class="fas fa-external-link-alt"></i></small></a
                >&nbsp;<ng-container i18n="@@ofSafarHub"
                  >of SafarHub.</ng-container
                >
              </p>
              <p>
                <span i18n="@@youWillCharged">You will be charged </span
                ><span
                  >{{ this.Service.newRate.chargeAmountCurrency }}
                  {{ this.Service.newRate.chargeAmount }} </span
                >
                <span *ngIf="Service.isSiteRtl()" i18n="@@fromYou">from you</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-action">
    <div class="hotel-details-side d-block d-md-none">
      <app-hotel-details></app-hotel-details>
    </div>

    <div
      [ngClass]="{'d-block': !requiresApprovalData || requiresApprovalData }"
      class="book_button_container d-none"
    >
      <div class="row">
        <div
          *ngIf="bookingForm"
          class="col-md-6 max-width-200">
          <div
            [ngClass]="{ 'd-block': paymentType === 'new_card' }"
            class="d-none"
          >
            <button
              [disabled]="
                this.Service.bookingSubmit || this.requiresApprovalData
              "
              [ngClass]="{ 'disabled-button': requiresApprovalData }"
              class="green_btn btn-block"
              i18n="@@confirmBooking"
            >
              CONFIRM BOOKING
            </button>
          </div>

        </div>

        <div *ngIf="this.Service.newRate" class="col-md-6 mt-3 mt-md-0">
          <span
            *ngIf="
              this.Service.newRate && this.Service.newRate.finalSellingPrice
            "
            class="primaray-text font-weight-bold text-big"
          >
            <ng-container i18n="@@Total">Total</ng-container>:&nbsp;<span
              class="value"
              ><ng-container *ngIf="Service.isSiteLtr()">{{
                this.Service.newRate.sellingPriceConvertedCurrency
              }}</ng-container>
              {{ this.Service.newRate.sellingPriceConverted | number }}
              <ng-container *ngIf="Service.isSiteRtl()">{{
                this.Service.newRate.sellingPriceConvertedCurrency
              }}</ng-container>
            </span></span
          >
        </div>
      </div>
    </div>
  </div>



    <div
      *ngIf="
        requiresApprovalData &&
        requiresApprovalData.limitExceeded &&
        !submittedApproval
      "
      class="border rounded mt-5 font-16 px-3 px-lg-5 pt-4 pt-lg-5 pb-4 gray-text-dark"
    >
      <p>
        <ng-container i18n="@@approvalMsg1"
          >This booking exceeds your travel policy. Approval request will be
          sent to</ng-container
        >&nbsp;<span class="font-weight-bold"
          >{{ requiresApprovalData.firstName }}
          {{ requiresApprovalData.lastName }}</span
        ><ng-container i18n="@@approvalMsg2"
          >. Your room will be automatically booked once approved.</ng-container
        >
      </p>

      <p
        *ngIf="traveler?.travellerEffectivePolicyName"
        class="bold-600 mt-4 mb-2"
      >
        <ng-container i18n="@@travelPolicy">Travel Policy</ng-container>&nbsp;
        <span class="font-weight-normal font-14"
          >({{ traveler?.travellerEffectivePolicyName }})</span
        >
      </p>

      <ng-container
        *ngIf="
          requiresApprovalData && !requiresApprovalData.hotelBookingNotAllowed
        "
      >
        <p
          [ngClass]="{
            'mr-0': Service.isSiteLtr(),
            'ml-0': Service.isSiteRtl()
          }"
          class="mb-1 font-16 gray-text"
        >
          <i
            *ngIf="!requiresApprovalData.limitExceeded"
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
            class="icon-check green-text font-12"
          ></i>
          <i
            *ngIf="requiresApprovalData.limitExceeded"
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
            class="icon-close-light text-danger font-12"
          ></i>

          <span i18n="@@nightlyPriceCap">Nightly price cap</span>:<span
            class="px-2"
            >{{ requiresApprovalData.limit }}</span
          >
        </p>
      </ng-container>

      <ng-container
        *ngIf="
          requiresApprovalData && requiresApprovalData.hotelBookingNotAllowed
        "
      >
        <p
          [ngClass]="{
            'mr-0': Service.isSiteLtr(),
            'ml-0': Service.isSiteRtl()
          }"
          class="mb-1"
        >
          <i
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
            class="icon-close-light text-danger font-12"
          ></i>
          <span class="font-16 gray-text" i18n="@@hotelBookingAllowed"
            >Hotel booking allowed</span
          >
        </p>
      </ng-container>

      <div
        [ngClass]="{
          'pl-lg-5 ml-auto': Service.isSiteLtr(),
          'pr-lg-5 mr-auto': Service.isSiteRtl()
        }"
        class="col-sm-5 px-0 mt-5 min-width-220"
      >
        <button
          [ngClass]="{ 'm-progress': submittingApproval }"
          class="blue_btn btn-block font-16 py-1 sharp-font"
          i18n="@@requestapproval"
        >
          Request Approval
        </button>
      </div>
    </div>
    <div
      *ngIf="
        requiresApprovalData &&
        !requiresApprovalData.limitExceeded &&
        !submittedApproval
      "
      class="border rounded mt-5 font-16 px-3 px-lg-5 pt-4 pt-lg-5 pb-4 gray-text-dark"
    >
      <p>
        <ng-container i18n="@@approvalMsg3"
          >Booking approval is required. Approval request will be sent
          to</ng-container
        >&nbsp;
        <span class="font-weight-bold"
          >{{ requiresApprovalData.firstName }}
          {{ requiresApprovalData.lastName }}</span
        ><ng-container i18n="@@approvalMsg2"
          >. Your room will be automatically booked once approved.</ng-container
        >
      </p>

      <ng-container
        *ngIf="
          requiresApprovalData && !requiresApprovalData.hotelBookingNotAllowed
        "
      >
        <p
          [ngClass]="{
            'mr-0': Service.isSiteLtr(),
            'ml-0': Service.isSiteRtl()
          }"
          class="mb-1 font-16 gray-text"
        >
          <i
            *ngIf="!requiresApprovalData.limitExceeded"
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
            class="icon-check green-text font-12"
          ></i>
          <i
            *ngIf="requiresApprovalData.limitExceeded"
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
            class="icon-close-light text-danger font-12"
          ></i>

          <span i18n="@@nightlyPriceCap">Nightly price cap</span>:<span
            class="px-2"
            >${{ requiresApprovalData.limit }}</span
          >
        </p>
      </ng-container>

      <ng-container
        *ngIf="
          requiresApprovalData && requiresApprovalData.hotelBookingNotAllowed
        "
      >
        <p
          [ngClass]="{
            'mr-0': Service.isSiteLtr(),
            'ml-0': Service.isSiteRtl()
          }"
          class="mb-1"
        >
          <i
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
            class="icon-close-light text-danger font-12"
          ></i>
          <span class="font-16 gray-text" i18n="@@hotelBookingAllowed"
            >Hotel booking allowed</span
          >
        </p>
      </ng-container>

      <div
        [ngClass]="{
          'pl-lg-5 ml-auto': Service.isSiteLtr(),
          'pr-lg-5 mr-auto': Service.isSiteRtl()
        }"
        class="col-sm-5 px-0 mt-5 min-width-220"
      >
        <button
          [ngClass]="{ 'm-progress': submittingApproval }"
          class="blue_btn btn-block font-16 py-1 sharp-font"
          i18n="@@requestapproval"
        >
          Request Approval
        </button>
      </div>
    </div>

  <div class="text-danger font-16 pt-3">{{ this.Service.errorMsg }}</div>
</form>
