import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';

import { AuthServiceMain } from './auth.service';
import {DataService} from "../services/data.service";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthServiceMain, public router: Router, public dataService: DataService,) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
    return this.authService.user.pipe(
      take(1),
      map(user => {

        const destinationUrl = router.url.slice(1);
        if (this.authService.isLogedIn) {
          return true;
        } else {
            if(destinationUrl === ''){
                return this.router.parseUrl( '/login');
            } else {
                return this.router.parseUrl( '/login?destination=' + encodeURIComponent(destinationUrl));
            }
        }
      })
    );




  }
}
