<ng-container *ngIf="pricing">
<!--    <div class="white-box p-3 mb-4" *ngIf="!isApprovals"-->
<!--         [ngClass]="{'text-right': dataService.isSiteRtl(),-->
<!--         'mt-3': !addCustomPadding(),-->
<!--         'custom-margin-top': dataService.desktopView}">-->
<!--        <p class="font-20 gray-primaray-text mb-4" i18n="@@fareRules">Fare Rules</p>-->
<!--        <ng-container *ngIf="pricing.flightOffers[0].pricingOptions as pricingOptions">-->
<!--            &lt;!&ndash;<p class="gray-text-dark mt-1">Included Checked Bags Only: {{pricing.flightOffers[0].pricingOptions.includedCheckedBagsOnly}}</p>&ndash;&gt;-->
<!--            <p class="gray-text-dark mt-1">No Restriction Fare: {{pricingOptions.noRestrictionFare}}</p>-->
<!--            <p class="gray-text-dark mt-1">No Penalty Fare: {{pricingOptions.noPenaltyFare}}</p>-->
<!--            <p class="green-text mt-1" *ngIf="pricingOptions.refundableFare === true" i18n="@@refundable">Refundable</p>-->
<!--            <p class="gray-text-dark mt-1" *ngIf="pricingOptions.refundableFare === false" i18n="@@nonrefundable">Non Refundable</p>-->
<!--            <p class="gray-text-dark">Fare Type</p>-->
<!--            <p class="mx-3 mb-0" *ngFor="let fareType of pricingOptions.fareType" >-->
<!--                {{fareType}}-->
<!--            </p>-->
<!--        </ng-container>-->
<!--    </div>-->


        <div class="white-box border-bottom-radius-0 p-3"
             [ngClass]="{'custom-margin-top': isFLightInfo}">
            <ng-container *ngIf="pricingTravelers">
                <ng-container *ngFor="let traveler of pricingTravelers; let travelerIndex = index">
                    <div class="d-flex justify-content-between font-16">
                                            <span>
                                                <ng-container i18n="@@traveler">Traveler</ng-container>{{travelerIndex + 1}}:
                                                <span class="text-capitalize">{{traveler.travelerType.toLowerCase()}}</span>
                                            </span>

                        <span>
                            {{traveler.sellingPriceConverted.currency}} {{traveler.sellingPriceConverted.total | number: '1.2-2'}}
                        </span>
                    </div>
                    <div class="d-flex justify-content-between font-14 gray-text my-2">
                                        <span i18n="@@fare">Fare</span>
                        <span>
                            {{traveler.sellingPriceConverted.currency}} {{traveler.sellingPriceConverted.base | number: '1.2-2'}}
                        </span>
                    </div>
                    <div class="d-flex justify-content-between font-14 gray-text my-2">
                                        <span i18n="@@taxAndFees">Tax and fees</span>
                        <span>
                            {{traveler.sellingPriceConverted.currency}} {{(traveler.sellingPriceConverted.total - traveler.sellingPriceConverted.base) | number: '1.2-2' }}
                         </span>

                    </div>
                </ng-container>

                <ng-container *ngIf="!isFLightInfo">
                <div *ngIf="offerSellingPriceConverted.guestFee && offerSellingPriceConverted.guestFee > 0"
                     class="d-flex justify-content-between font-14 gray-text my-2">
                                        <span i18n="@@guestFees">Guest fees</span>
                    <span>
                            {{offerSellingPriceConverted.guestFeeCurrency}} {{offerSellingPriceConverted.guestFee | number: '1.2-2' }}
                         </span>

                </div>
                <div *ngIf="offerSellingPriceConverted.transactionFee && offerSellingPriceConverted.transactionFee > 0"
                     class="d-flex justify-content-between font-14 gray-text my-2">
                                        <span i18n="@@transactionFees">Transaction fees</span>
                        <span>
                            {{offerSellingPriceConverted.transactionFeeCurrency}} {{offerSellingPriceConverted.transactionFee | number: '1.2-2' }}
                         </span>

                </div>
                </ng-container>

            </ng-container>
        </div>
            <ng-container *ngIf="offerSellingPriceConverted">
                <div class="white-box-footer custom-shadow rounded-bottom p-3 mb-5">
                    <div class="d-flex font-18 justify-content-between">
                        <span class="primaray-text" i18n="@@totalPayNow"> Total</span>
                        <span class="primaray-text bolder">
                            <span class="currency">{{offerSellingPriceConverted.currency}}
                                <ng-container *ngIf="isFLightInfo">{{offerSellingPriceConverted.total | number:'1.0-2'}}</ng-container>
                                <ng-container *ngIf="!isFLightInfo">{{offerSellingPriceConverted.grandTotal | number:'1.0-2'}}</ng-container>
                            </span>
                            <span class="amount"></span>
                        </span>
                    </div>
                </div>
            </ng-container>




</ng-container>


