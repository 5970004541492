import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../../environments/environment';
import {AuthServiceMain} from '../../../../auth/auth.service';

@Component({
  selector: 'app-deactivate-subscription-reasons',
  templateUrl: './deactivate-subscription-reasons.component.html',
  styleUrls: ['./deactivate-subscription-reasons.component.scss']
})
export class DeactivateSubscriptionReasonsComponent implements OnInit {
  data;
  companyDeactivateForm: FormGroup;
  submitted = false;
  submitting = false;
  reasonsArray: any = [
    {id: 0, text: $localize`:@@declineArraySub1:We are no longer traveling`},
    {id: 1, text: $localize`:@@declineArraySub2:The service is expensive`},
    {id: 2, text: $localize`:@@declineArraySub3:Switching to another tools`},
    {id: 3, text: $localize`:@@declineArray4:Other`},
  ];
  urlArray;
  userId;
  errorMsg;
  deactivateReason;
  isWorkspaceTrial;
  constructor(public auth: AuthServiceMain, private formBuilder: FormBuilder, public dataService: DataService, public router: Router) { }
  ngOnInit(): void {
    this.companyDeactivateForm = this.formBuilder.group({
      reasons: ['', Validators.required],
      improveService: [''],
    }, {
    });
  }
  get ctrl() { return this.companyDeactivateForm.controls; }
  deactivateCompany() {
    this.submitted = true;
    this.submitting = true;
    if (this.companyDeactivateForm.invalid) {
      this.submitting = false;
      return;
    }
    if (this.companyDeactivateForm.controls.improveService.value) {
      this.deactivateReason = this.companyDeactivateForm.controls.reasons.value + '. Comment: ' + this.companyDeactivateForm.controls.improveService.value;
    } else  {
      this.deactivateReason = this.companyDeactivateForm.controls.reasons.value;
    }
    this.dataService.deactivateCompany(this.deactivateReason).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
        //  this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/billing']);
       //   this.auth.fetchCompanyData();
          this.dataService.modalOf();
          this.dataService.BottomSheetOf();
          this.submitting = false;
        //  this.auth.logout();
          if(this.data.data.inactive) {
            this.auth.logout();
          } else {
          this.auth.companyInfo.inactive = this.data.data.inactive;
          localStorage.setItem('companyInfo', JSON.stringify(this.auth.companyInfo));
          if (this.router.url !== '/settings/billing') {
            this.router.navigate(['/settings/billing']);
          }
          }
        } else {
          this.errorMsg = this.data.message;
          this.submitting = false;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
        this.submitting = false;
      }
    );
  }
  cancelDeactivate() {
    if (this.dataService.desktopView) {
      this.dataService.modalOf();
    } else {
      this.router.navigate([this.router.url.replace('/reasons' , '')]);
    }
  }
}
