import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { SidenavService } from '../workspace-dashboard/sidenav.service';
import { onMainContentChange } from '../workspace-dashboard/animations/animations';
import { AuthServiceMain } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { VariablesService } from "../../services/vars.service";

@Component({
  selector: 'app-workspace-reports',
  templateUrl: './workspace-reports.component.html',
  styleUrls: ['./workspace-reports.component.scss'],
  animations: [onMainContentChange]
})

export class WorkspaceReportsComponent implements OnInit {
  isDashboard: boolean = true;

  constructor(public vars: VariablesService,
    private titleService: Title, public router: Router, public dataService: DataService, private sidenavService: SidenavService, public auth: AuthServiceMain) {
    this.titleService.setTitle('Reports & Dashboard | SafarHub');
    this.sidenavService.sideNavState$.subscribe(res => {
      this.dataService.onSideNavChange = res;
    });
  }

  ngOnInit(): void {
    //
  }

  setIsDashboard(value: boolean = true) {
    this.isDashboard = value;
  }
}