<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pt-4 pt-lg-5 pb-5 items-full-list billing-history">
        <div class="mx-lg-5">
          <div class="row">
            <div class="col">
              <div class="pb-4 pb-lg-5 mb-3">
                <ng-container
                  *ngIf="
                    auth.companyInfo && auth.companyInfo.inactive === false && auth.companyInfo.companySubscriptionType == ''
                  "
                  ><a
                    class="font-16 blue-text text-underline"
                    (click)="
                      this.router.navigate([
                        '/settings'
                      ])
                    "
                    i18n="@@menuItemSettings"
                    >Settings</a
                  >
                  <span class="font-16 gray-text-dark"> / </span></ng-container
                >
                <a
                  class="font-16 blue-text text-underline"
                  (click)="
                    this.router.navigate([
                      '/settings/billing'
                    ])
                  "
                  i18n="@@menuItemBilling"
                  >Billing</a
                >
                <span class="font-16 gray-text-dark">
                  /
                  <ng-container i18n="@@billingHistory"
                    >Billing History</ng-container
                  >
                </span>
              </div>
            </div>
          </div>

          <p
            class="font-20 gray-primaray-text bold-600 mb-6"
            i18n="@@billingHistory"
          >
            Billing History
          </p>
          <div
            *ngIf="!loaded"
            class="loading_icon_caption text-center mt-5"
          ></div>




          <div *ngIf="historyList && auth.companyInfo.companySubscriptionType == ''">
            <div
              class="row px-0 px-lg-3 mx-lg-0 mt-4 pt-3 pb-3 gray-primaray-text table-header rounded bold-600"
              *ngIf="dataService.desktopView"
            >
              <div class="col-md-3" i18n="@@date">Date</div>
              <div class="col-md-3" i18n="@@number">Number</div>
              <div class="col-md-3" i18n="@@amount">Amount</div>
              <div class="col-md-3"></div>
            </div>
            <div
              class="row px-0 px-lg-3 gray-text-dark hover-row-list mx-0 py-4"
              *ngFor="let item of historyList; let i = index"
              [ngClass]="{
                'border-bottom': dataService.desktopView,
                'rounded border mb-4': dataService.mobileView
              }"
            >
              <div
                class="col-lg-3 py-1"
                [innerHTML]="dataService.formatDateMMMdy(item.creationDate)"
              ></div>
              <div
                class="col-lg-3"
                [ngClass]="{
                  'd-flex pt-4': dataService.mobileView,
                  'py-1': dataService.desktopView
                }"
              >
                <div [ngClass]="{ 'w-65': dataService.mobileView }">
                  {{ item.invoiceNumber }}
                </div>
                <div *ngIf="dataService.mobileView">
                  <span *ngIf="(item.amount + '').indexOf('-') >= 0"
                    >-${{
                      (item.amount + "").replace("-", "") | number : "1.0-2"
                    }}</span
                  >
                  <span *ngIf="(item.amount + '').indexOf('-') < 0"
                    >${{ item.amount | number : "1.0-2" }}</span
                  >
                </div>
              </div>
              <div class="col-lg-3 py-1" *ngIf="dataService.desktopView">
                <span *ngIf="(item.amount + '').indexOf('-') >= 0"
                  >-${{
                    (item.amount + "").replace("-", "") | number : "1.0-2"
                  }}</span
                >
                <span *ngIf="(item.amount + '').indexOf('-') < 0"
                  >${{ item.amount | number : "1.0-2" }}</span
                >
              </div>
              <div class="col-lg-3">
                <p class="action-hide-show" *ngIf="dataService.desktopView">
                  <a
                    class="blue-text"
                    target="_blank"
                    [href]="item.pdfLink"
                    i18n="@@download"
                    >Download</a
                  >
                </p>
                <div class="d-flex" *ngIf="dataService.mobileView">
                  <div
                    class="actions"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                  >
                    <i
                      class="drop-down{{
                        i
                      }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                      (click)="
                        $event.stopPropagation();
                        showActions = !showActions;
                        selectedIndex = i
                      "
                    ></i>
                    <div
                      class="border shadow font-14 position-absolute bg-white drop"
                      *ngIf="showActions && i === selectedIndex"
                    >
                      <p
                        class="pointer py-2"
                        [ngClass]="{
                          'pl-4': dataService.isSiteLtr(),
                          'pr-4': dataService.isSiteRtl()
                        }"
                      >
                        <a
                          class="blue-text"
                          target="_blank"
                          [href]="item.pdfLink"
                          i18n="@@download"
                          >Download</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div *ngIf="historyList && auth.companyInfo.companySubscriptionType == 'MANUAL'">
            <div
              class="row px-0 px-lg-3 mx-lg-0 mt-4 pt-3 pb-3 gray-primaray-text table-header rounded bold-600"
              *ngIf="dataService.desktopView"
            >
              <div class="col-md-3" i18n="@@startDate">Start Date</div>
              <div class="col-md-3" i18n="@@endDate">End Date</div>
              <div class="col-md-3" i18n="@@numberOfMembers"># of members</div>
              <div class="col-md-3" i18n="@@amount">Amount</div>
              <div class="col-md-3"></div>
            </div>
            <div
              class="row px-0 px-lg-3 gray-text-dark hover-row-list mx-0 py-4"
              *ngFor="let item of historyList; let i = index"
              [ngClass]="{
                'border-bottom': dataService.desktopView,
                'rounded border mb-4': dataService.mobileView
              }"
            >
              <div
                class="col-lg-3 py-1"
                [innerHTML]="dataService.formatDateMMMdy(item.startDate)"
              ></div>
              <div
                class="col-lg-3 py-1"
                [innerHTML]="dataService.formatDateMMMdy(item.endDate)"
              ></div>
              <div
                class="col-lg-3"
                [ngClass]="{
                  'd-flex pt-4': dataService.mobileView,
                  'py-1': dataService.desktopView
                }"
              >
                <div [ngClass]="{ 'w-65': dataService.mobileView }">
                  {{ item.maxUserCount }}
                </div>
              </div>
              <div class="col-lg-3 py-1" *ngIf="dataService.desktopView">
                <span *ngIf="(item.amount + '').indexOf('-') >= 0"
                  > {{
                    (item.amount + "").replace("-", "") | number : "1.0-2"
                  }} {{item.currency}}</span
                >
                <span *ngIf="(item.amount + '').indexOf('-') < 0"
                  >{{item.currency}} {{ item.amount | number : "1.0-2" }} </span
                >
              </div>
            </div>
          </div>

          <div *ngIf="loaded && !historyList">
            <div
              class="mt-4"
              [ngClass]="{ 'border p-4 rounded': dataService.desktopView }"
            >
              <p i18n="@@noInvoice">
                You don’t have any invoice under your account.
              </p>
              <p>
                <ng-container i18n="@@contactSupportInvoice"
                  >If you have any questions, contact our customer support
                  at</ng-container
                >
                <a
                  href="mailto:support@safarhub.com?Subject=Question"
                  target="_top"
                  >support&#64;safarhub.com</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
