      <h1 class="primaray-text mb-4 text-center bolder container" [ngClass]="{'mt-5' : this.Service.mobileView }" *ngIf="!Service.isCompany()"> Share your voucher </h1>
      <form [formGroup]="sendVoucherForm" (ngSubmit)="submitForm()" class="mx-5 mx-lg-0" [ngClass]="{'my-5' : this.Service.mobileView && Service.isCompany() }">
        <div class="form-group">
          <input class="form-control" id="email" formControlName="email"  name="email" type="email"
                 placeholder="Recipient's e-mail" required
                 [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
          </div>
        </div>
          <div class="form-actions clearfix">
            <button class=" btn-block btn " [ngClass]="{'m-progress': submitting, 'btn-primary': !Service.isCompany(), 'blue_btn font-14 sharp-font bold-500': Service.isCompany() }">Submit</button>
          </div>
      </form>

