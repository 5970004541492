
    <div class="policy-details position-relative mb-4 py-3 px-4 company-section-with-thick-border border"
         [ngClass]="{'rounded-right': Service.isSiteLtr(),
                     'rounded-left': Service.isSiteRtl() }"  *ngIf="policyDetails && showPolicyDetails">

        <i class="icon-close-light gray-text font-12 pointer position-absolute"
           [ngClass]="{'mr-4 ml-auto': Service.isSiteLtr(), 'ml-4 mr-auto': Service.isSiteRtl()}"
           (click)="showPolicyDetails = false"></i>
        <p class="font-18 bold-600" i18n="@@travelPolicy">Travel Policy</p>
        <p *ngIf="policyDetails.hotelBookingNotAllowed === true" i18n="@@approvalAlwaysRequiredHotel">
            Approval is required for any hotel booking.
        </p>
        <ng-container *ngIf="policyDetails.companyPolicyHotelRules as hotelRules">
            <ng-container *ngIf="!policyDetails.hotelBookingNotAllowed || policyDetails.hotelBookingNotAllowed === false">
                <div class="d-block d-md-flex custom-gap">
                    <p class="mb-2">
                        <span i18n="@@price">Price</span>: <span *ngIf="hotelRules.medianPriceLimitPercentage; else noMedianPriceLimitPercentage" class="gray-text">
                          {{ Math.abs(hotelRules.medianPriceLimitPercentage * 100) }}%
                            <span i18n="@@underMedian" *ngIf="hotelRules.medianPriceLimitPercentage < 0">under median price</span>
                          <span i18n="@@aboveMedian" *ngIf="hotelRules.medianPriceLimitPercentage > 0">above median price</span> <ng-container *ngIf="availabilityMedianPrice"> ({{availabilityMedianCurrency}} {{availabilityMedianPrice | number : '1.0-0'}})</ng-container>
                          </span>
                        <ng-template #noMedianPriceLimitPercentage><span class="gray-text">-</span></ng-template>
                    </p>

                    <p class="mb-2">
                        <span i18n="@@bookingWindow">Booking Window</span>: <span *ngIf="hotelRules.bookingWindowInDays; else noBookingWindowInDays" class="gray-text">{{hotelRules.bookingWindowInDays}}
                        <span i18n="@@oneDayInAdvance" *ngIf="hotelRules.bookingWindowInDays === 1">day in advance</span>
                        <span i18n="@@toDaysInAdvance" *ngIf="hotelRules.bookingWindowInDays === 2">days in advance</span>
                        <span i18n="@@daysInAdvance" *ngIf="hotelRules.bookingWindowInDays > 2">days in advance</span>
                    </span>
                        <ng-template #noBookingWindowInDays><span class="gray-text">-</span></ng-template>
                    </p>
                </div>


                  <div class="d-block d-md-flex custom-gap">
                            <p class="mb-2">
                                        <span class="gray-primaray-text" i18n="@@nightlyPriceLimitDetails">Nightly price limit</span>:

                                        <ng-container *ngIf="hotelRules.avgNightlyPriceLimit && hasException; else noLimitOrNoException">

                                          <span class="gray-text">{{exceptionCurrency}}
                                              {{exceptionValue | number : '1.0-0'}}
                                          </span>

                                        </ng-container>

                                        <ng-template #noLimitOrNoException>
                                            <ng-container *ngIf="hotelRules.avgNightlyPriceLimit && !hasException">
                                                 <span class="gray-text">{{policyCurrency}}
                                                     {{hotelRules.avgNightlyPriceLimit | number : '1.0-0'}}
                                                 </span>
                                            </ng-container>

                                            <ng-container *ngIf="!hotelRules.avgNightlyPriceLimit && !hasException">
                                                <span class="gray-text">-</span>
                                            </ng-container>

                                        </ng-template>
                            </p>

                      <p class="mb-2">
                          <span i18n="@@allowedHotelStars">Allowed hotel stars</span>: <span *ngIf="hotelRules.maxStars; else noMaxStars" class="gray-text">{{hotelRules.maxStars}}
                          <span i18n="@@star" *ngIf="hotelRules.maxStars === 1">star</span>
                          <span i18n="@@stars" *ngIf="hotelRules.maxStars === 2">stars</span>
                          <span i18n="@@stars" *ngIf="hotelRules.maxStars > 2">stars</span>
                         </span>
                          <ng-template #noMaxStars><span class="gray-text">-</span></ng-template>
                      </p>


                  </div>

                <div class="d-block d-md-flex custom-gap">
                    <p class="mb-2">
                        <span class="gray-primaray-text" i18n="@@ApprovalRequiredOn">Approval required on: </span>
                        <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'NO_APPROVAL_REQUIRED'" i18n="@@noApproval">No approval</span>
                        <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'RULES_VIOLATION_APPROVAL_REQUIRED'" i18n="@@outOfPolicy">Out of policy</span>
                        <span class="gray-text" *ngIf="policyDetails.approvalProcessType === 'ALWAYS_APPROVAL_REQUIRED'" i18n="@@allTrips">All trips</span>
                    </p>
                </div>


            </ng-container>
        </ng-container>
        


    </div>


