<div class="teams container py-5" >
  <div class="row mx-lg-4">
    <div class="col-12 teams-link link-underline">
      <a class="blue-text"><i class="icon-left-arrow-slider smaller pr-2"></i><span class="font-18 "
        (click)="this.router.navigate(['/travel-policies']);">Policies</span></a>
    </div>
  </div>
  <div class="row mx-lg-4" *ngIf="this.policyInfo">
    <div class="col-md-6 details">
      <p class="font-24 primaray-text font-weight-bold sharp-font" [ngClass]="{'border-bottom border-padding' : this.dataService.mobileView}">{{policyInfo.policy.name}}</p>
      <div class="row pb-3">
        <div class="gray-text" [ngClass]="{'col-3': dataService.desktopView, 'col-5': dataService.mobileView}">Limit:</div>
        <div  [ngClass]="{'col-9': dataService.desktopView, 'col-7': dataService.mobileView}">
          <p class="mb-0">{{this.policyInfo.policy.avgNightlyPriceLimit}}</p>
        </div>
      </div>

      <div class="row pb-3">
        <div class="gray-text" [ngClass]="{'col-3': dataService.desktopView, 'col-5': dataService.mobileView}">Applied To:</div>
        <div  [ngClass]="{'col-9': dataService.desktopView, 'col-7': dataService.mobileView}">
          <p class="mb-0" >{{this.policyInfo.description}}</p>
        </div>
      </div>


      <div class="row pb-3">
        <div class="gray-text" [ngClass]="{'col-3': dataService.desktopView, 'col-5': dataService.mobileView}">Created:</div>
        <div [ngClass]="{'col-9': dataService.desktopView, 'col-7': dataService.mobileView}">
          <p class="mb-0"  [innerHTML]="formatDate(this.policyInfo.policy.creationDate)"></p>
        </div>
      </div>

    </div>
    <div class="col-md-4 pt-5">
      <button class="blue_btn btn py-1 px-5 mb-3 btn-min-width edit-btn font-12" (click)="editPolicy(this.policyInfo.policy.policyId)" i18n="@@editPolicy">Edit Policy </button><br/>
      <button class="btn-outline-red btn py-1 px-5 mb-3 font-12 btn-min-width" (click)="removePolicy(this.policyInfo.policy.policyId, this.policyInfo.policy.name)" i18n="@@removePolicy">Remove Policy </button>
    </div>
  </div>
</div>
