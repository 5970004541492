import { environment } from "src/environments/environment";
import { VariablesService } from "./vars.service";

export class ServiceBase {
  apiUrl: string;
  filter: any = {};

  constructor(public vars: VariablesService, public serviceURi: string) {
    this.setApiUrl(serviceURi);
  }

  setApiUrl(apiName) {
    this.apiUrl = environment.apiBase + apiName;
  }

  getMethodUrl(endpoint: string): string { 
    let url = `${this.apiUrl}${endpoint}`;
    if (Object.keys(this.filter).length) {
      url+= this.filterToQuery(this.filter);
    }

    this.filter = {};
    return url;
  };

  setfilter(filter: any){
    this.filter = filter;
  }

  filterToQuery(filter): string {
    // this to stop browser cache in case of the get request.
    const buster = "nc" + Math.random() + "=1";
    let params = "?" + buster;
    if (filter) {
      const filterParams = this.toQuery(filter);
      params += "&" + filterParams;
    }
    return params;
  }

  toQuery(data: any) {
    const getValue = (value) => {
      return value instanceof Date ? value.toISOString() : value;
    };
    const params = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(getValue(data[key]))).join('&');
    return params;
  }
}