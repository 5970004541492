<div class="flight-list" (window:scroll)="onWindowScroll($event)"  >
  <div  class="d-flex search-summary list-unstyled  border-bottom p-3" *ngIf="Service.mobileView">
    <div [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}">
      <div class="item location" >
        <span *ngIf="this.Service.flightListLoader" class="loading_icon_caption"></span>
        <span class="gray-primaray-text font-16"  *ngIf="data && data.data && this.data.data.itineraries && this.data.data.captions">{{this.data.data.captions}}</span>
      </div>

      <div *ngIf="data && data.data" class="gray-text"><span *ngIf="data.data.tripType === 'ROUND_TRIP'" i18n="@@roundTripNoDash">Round trip</span><span *ngIf="data.data.tripType === 'ONE_WAY'" i18n="@@oneWayNoDash">One way</span>&nbsp;(<span [innerHTML]="Service.formatDateMMMdd(data.data.searchRequest?.airQueries[0]?.departureDate)"></span> <ng-container *ngIf="data.data.tripType !== 'ONE_WAY'"> -  <span [innerHTML]="Service.formatDateMMMdd(data.data.searchRequest?.airQueries[1]?.departureDate)"></span></ng-container>)</div>
    </div>

    <div class="item button">
      <button [ngClass]="{'active' : !Service.isCollapsedFlight}"  class="btn-link collapseSearch font-weight-bold"  (click)="Service.isCollapsedFlight = !Service.isCollapsedFlight"
              [attr.aria-expanded]="!Service.isCollapsedFlight" aria-controls="collapseSearch" i18n="@@edit">
        Edit
      </button>
    </div>
  </div>
  <div class="top-search" [ngClass]="{'p-0': Service.mobileView}">
    <div class="container">
      <div [ngClass]="{'mx-lg-4': Service.isCompany() && Service.desktopView}">
        <div class="container">
        <div class="row">
          <div class="search_box position-relative" [ngClass]="{'ml-auto': !Service.isCompany(), 'col-10': !Service.isCompany(), 'col-12': Service.isCompany(), 'px-0': Service.mobileView || !Service.isCompany()}"  >
            <app-flights-search-controller *ngIf="this.Service.desktopView"></app-flights-search-controller>

          </div>
        </div>




        </div>


        <div id="collapseSearch" [ngbCollapse]="Service.isCollapsedFlight">
          <div class="row mt-3 justify-content-end">
            <div class="search_box p-md-5 position-relative col-12 pb-5" *ngIf="this.Service.mobileView">
              <app-flights-search-controller-mobile *ngIf="this.Service.mobileView"></app-flights-search-controller-mobile>
            </div>
          </div>



        </div>


    </div>
    </div>
  </div>



  <div [ngClass]="{'container': Service.isCompany() && Service.desktopView}">
    <div [ngClass]="{'mx-lg-4': Service.isCompany() && Service.desktopView}">
      <div>
        <div *ngIf="this.Service.desktopView" class="listing hotels_listing mb-5">
          <div class="container">
            <div class="row">
              <div class="col-md-3 pl-0">
                <app-loaders loaderName="flightLeftSide"></app-loaders>

                <div class="filters" [ngClass]="{'d-none' : errorData || (Service.flightFilter === false && this.Service.flightListLoader)}">
                  <div class="filter_group  mb-5 mt-2">
                  <div class="blue-text filters_clear not-mobile mb-3 font-weight-bold " [ngClass]="{'visible': filteringActive, 'invisible': !filteringActive}" (click)="clearFilters()"><i class="fas fa-times-circle"></i>&nbsp;<ng-container i18n="@@clearAllFilters">Clear all filters</ng-container>
                    </div>

                    </div>


                  <div class="filter_group mb-5 mt-2">
                    <div class="form-item form-type-checkbox mb-4 pb-1" *ngIf="Service.isCompany() && showWithinPolicyFilter" [ngClass]="{'active': filterWithinPolicyCheck}" >
                      <input id="within-policy" type="checkbox" [(ngModel)]="filterWithinPolicy" [checked]="filterWithinPolicy" [ngModelOptions]="{standalone: true}" (change)="filterWithinPolicyFunc()" />
                      <label for="within-policy" class="gray-text-dark" i18n="@@showInPolicyFlightsOnly">Show in-policy flights only</label>
                    </div>

                    <div class="d-flex mt-4 pt-3 mb-2">
                      <h4 class="w-75 mb-0" i18n="@@theStops">Stops</h4>
                      <div class="w-25" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}" i18n="@@starting">Starting</div>
                    </div>
                    <div class="main">
                        <div class="form-checkboxes">
                          <ng-container *ngFor="let stop of stops; let i = index">
                          <div class="d-flex" *ngIf="stop.counter" [ngClass]="{'active': activecheckbox === i }">
                            <div class="form-item form-type-checkbox mb-1 w-75">
                              <input type="checkbox" id="Q{{stop.id}}" [checked]="stop.isChecked" (change)="dataChanged($event, i, 'stops')" [disabled]="stop.faded" />
                              <label class="text-capitalize" [ngClass]="{'gray-text-dark': !stop.faded, 'placeholder-text': stop.faded}"  for="Q{{stop.id}}" >{{stop.title}} ({{stop.counter}})</label>
                            </div>
                            <div class="w-25 font-13" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}">{{stop.currency}} {{stop.price | number : '1.0-0'}}</div>
                          </div>
                          </ng-container>

                        </div>
                    </div>

                    <div class="d-flex mt-4 pt-3 mb-2">
                      <h4 class="w-75 mb-0" i18n="@@airlines">Airlines</h4>
                      <div class="w-25" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}" i18n="@@starting">Starting</div>
                    </div>
                    <div class="main">
                      <div class="form-checkboxes">

                        <div class="d-flex" *ngFor="let airline of airlines| slice : 0 : showMore; let i = index" [ngClass]="{'active': activecheckbox === i }">
                          <div class="form-item form-type-checkbox mb-1 w-75">
                            <input type="checkbox" id="Q{{airline.id}}" [checked]="airline.isChecked" (change)="dataChanged($event, i, 'airlines')" [disabled]="airline.faded" />
                            <label [ngClass]="{'gray-text-dark': !airline.faded, 'placeholder-text': airline.faded}" for="Q{{airline.id}}">{{airline.title}} ({{airline.counter}})</label>
                          </div>
                          <div class="w-25 font-13"
                               [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl(), 'placeholder-text': airline.faded}">
                            {{airline.currency}} {{airline.price | number : '1.0-0'}}
                          </div>
                        </div>
                        <p *ngIf="showMore < airlines.length">
                          <a (click)="showMore = airlines.length" class="blue-text" >
                            Show more </a>
                        </p>
                        <p *ngIf="airlines.length > 4 && showMore === airlines.length">
                          <a (click)="showMore = 4" class="blue-text" i18n="@@showLess">
                            Show less </a>
                        </p>

                      </div>

                    </div>

                    <div class="d-flex mt-4 pt-3 mb-2">
                      <h4 class="w-75 mb-0" i18n="@@airports">Airports</h4>
                      <div class="w-25" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}"></div>
                    </div>
<!--                    <div class="main">-->
<!--                      <div class="form-checkboxes">-->
<!--                        <ng-container *ngFor="let airportItem of airports; let y = index">-->
<!--                          <div class="d-flex mt-2 mb-2">-->
<!--                            <p class="w-75 mb-0">{{airportItem.city}}</p>-->
<!--                            <div class="w-25" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}" i18n="@@starting">Starting</div>-->
<!--                          </div>-->
<!--                        <div class="d-flex" *ngFor="let item of airportItem.airports; let i = index" [ngClass]="{'active': activecheckbox === i }">-->
<!--                          <div class="form-item form-type-checkbox mb-1 w-75">-->
<!--                            <input type="checkbox" id="Q{{item.id}}" [checked]="item.isChecked" (change)="dataChanged($event, i, 'airports')" [disabled]="item.faded" />-->
<!--                            <label [ngClass]="{'gray-text-dark': !item.faded, 'placeholder-text': item.faded}"  for="Q{{item.id}}" >{{item.name}}</label>-->
<!--                          </div>-->
<!--                          <div class="w-25" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl(), 'placeholder-text': item.faded}">${{item.minPrice | number : '1.0-0'}}</div>-->
<!--                        </div>-->
<!--                        </ng-container>-->


<!--                      </div>-->

<!--                    </div>-->





                    <div class="d-flex mt-4 pt-3 mb-2">
                      <h4 class="w-75 mb-0" i18n="@@departureTime">Departure Time</h4>
                      <div class="w-25 blue-text pointer" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}" i18n="@@reset"
                      (click)="clearDepartureFilters()"><!--Reset--></div>
                    </div>
<!--                    <div class="gray-text-dark" *ngIf="this.data && this.data.data && this.data.data.itineraries">-->
<!--                      <span i18n="@@from">From</span> {{this.data.data.itineraries[0].originDestinationOptionsGroups[0].originDestinationOptions[0].flightSegment.departureAirport.city}}-->
<!--                    </div>-->
<!--                    <div class="time-duration-filter">-->
<!--                      <div class="row font-16 mx-0 mt-2">-->
<!--                        <ng-container *ngFor="let depart of departureSeg1; let i = index">-->
<!--                        <div class="w-50 checkbox-radio-item-container col-auto form-group px-0" *ngIf="depart.counter"-->
<!--                             [ngClass]="{'pr-1': Service.isSiteLtr(), 'pl-1': Service.isSiteRtl(), 'active': activecheckbox === i}">-->
<!--                          <input class="d-none" type="checkbox" id="{{depart.id}}" [checked]="depart.isChecked" name="gender" (change)="dataChanged($event, depart.id, 'time')"-->
<!--                                 [disabled]="depart.faded"/>-->
<!--                          <label class="white-box border w-100 d-flex justify-content-center align-items-center mb-0 px-1" for="{{depart.id}}">-->
<!--                            <div class="text-center font-12">-->
<!--                              <span class="d-block">{{depart.title}}</span>-->
<!--                              <span class="d-block font-12 gray-text pt-2">{{depart.time}}</span>-->
<!--                            </div>-->
<!--                          </label>-->
<!--                        </div>-->
<!--                        </ng-container>-->
<!--                      </div>-->
<!--                    </div>-->


<!--                    <ng-container *ngIf="this.queryParams.roundTrip === true">-->
<!--                      <div class="gray-text-dark mt-3" *ngIf="this.data && this.data.data && this.data.data.itineraries">-->
<!--                        <span i18n="@@from">From</span> {{this.data.data.itineraries[0].originDestinationOptionsGroups[1].originDestinationOptions[0].flightSegment.departureAirport.city}}-->
<!--                      </div>-->
<!--                      <div class="time-duration-filter">-->
<!--                        <div class="row font-16 mx-0 mt-2">-->
<!--                        <ng-container *ngFor="let depart of departureSeg2; let i = index">-->
<!--                          <div class="w-50 checkbox-radio-item-container col-auto form-group px-0" *ngIf="depart.counter"-->
<!--                               [ngClass]="{'pr-1': Service.isSiteLtr(), 'pl-1': Service.isSiteRtl(), 'active': activecheckbox === i}">-->
<!--                            <input class="d-none" type="checkbox" id="{{depart.id}}" [checked]="depart.isChecked" name="gender" (change)="dataChanged($event, depart.id, 'timeArrival')"-->
<!--                                   [disabled]="depart.faded" />-->
<!--                            <label class="white-box border w-100 d-flex justify-content-center align-items-center mb-0 px-1" for="{{depart.id}}">-->
<!--                              <div class="text-center font-12">-->
<!--                                <span class="d-block">{{depart.title}}</span>-->
<!--                                <span class="d-block font-12 gray-text pt-2">{{depart.time}}</span>-->
<!--                              </div>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </ng-container>-->

<!--                        </div>-->


<!--                      </div>-->
<!--                    </ng-container>-->



                    <div class="filter_group mb-5 mt-2">
                      <h4 i18n="@@duration">Duration</h4>
<!--                      <div class="d-flex">-->
<!--                        <span class="gray-text-dark" [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}"><span i18n="@@upTo">up to</span>&nbsp;-->
<!--                          <span class="font-weight-bold" *ngIf="hoursValue === 1">{{oneHourValueText}}</span>-->
<!--                           <span class="font-weight-bold" *ngIf="hoursValue === 2">{{twoHourValueText}}</span>-->
<!--                           <span class="font-weight-bold" *ngIf="hoursValue > 2 && hoursValue < 11">{{hoursValue}}{{moreHourValueText}}</span>-->
<!--                          <span class="font-weight-bold" *ngIf="hoursValue >= 11">{{hoursValue}}{{moreThan11Hours}}</span>-->
<!--                        </span>-->

<!--                      </div>-->
<!--                      <div class="d-flex gray-text-dark" *ngIf="optionsHours">-->
<!--                        <span [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}">{{this.optionsHours.floor}}<span i18n="@@hour">h</span></span>-->
<!--                        <span>{{this.optionsHours.ceil}}<span i18n="@@hour">h</span></span>-->

<!--                      </div>-->
<!--                      <div class="custom-slider" *ngIf="optionsHours">-->
<!--                        <ng5-slider [(value)]="hoursValue" [options]="optionsHours"  (userChangeEnd)="hoursChangeEnd($event)"  ></ng5-slider>-->
<!--                      </div>-->
                    </div>










                  </div>




                </div>


              </div>
              <div class="col-md-9" *ngIf="!errorData">



                <app-policy-details-section
                        [showPolicyDetails]="showPolicyDetails"
                        [policyDetails]="policyDetails"
                        [maxNoOfTrips]="maxNoOfTrips"
                        [maxNoInterval]="maxNoInterval"
                        [applicablePolicySelectedClass]="applicablePolicySelectedClass">
                </app-policy-details-section>






                <!--    <div  class="mat-progress-bar-holder">

                      <div class="sorting desktop"  [ngClass]="{'disabled' :  !sortingEnabled}">
                        <div class="item text" i18n="@@sortBy">
                          Sort by
                        </div>
                        <div class="item" [ngClass]="{'active' : ParentSort === 'default'}" (click)="sortFunc('default', 'default')">
                          <a class="reset" i18n="@@ourFavorites">
                            Our Favorites
                          </a>
                        </div>
                        <div class="item with-desc" [ngClass]="{'active' : ParentSort === 'cheapest'}" (click)="sortFunc('cheapest', 'cheapest')">
                          <a class="reset" i18n="@@cheapest">Cheapest</a>
                          <span class="pt-2 gray-text font-12" *ngIf="this.cheapestFlightItem">${{this.cheapestFlightItem.fareInfo.sellingTotalFares.totalPerPassenger.amount | number : '1.0-0'}}<span class="d-inline pl-2"
                                                                                                                                                                                                        [innerHTML]="calDurationAverage(this.cheapestFlightItem)">6h 45m</span></span>
                        </div>
                        <div class="item with-desc" [ngClass]="{'active' : ParentSort === 'shortest'}" (click)="sortFunc('shortest', 'shortest')">
                          <a class="reset" i18n="@@shortest">Shortest</a>
                          <span class="pt-2 gray-text font-12" *ngIf="this.shortestFlightItem">${{this.shortestFlightItem.fareInfo.sellingTotalFares.totalPerPassenger.amount | number : '1.0-0'}}<span class="d-inline pl-2"
                                                                                                                                                                                                        [innerHTML]="calDurationAverage(this.shortestFlightItem)">6h 45m</span></span>
                        </div>
                        <div class="item with-desc" [ngClass]="{'active' : ParentSort === 'best'}" (click)="sortFunc('best', 'best')">
                          <a class="reset" i18n="@@best">Best</a>
                          <span class="pt-2 gray-text font-12" *ngIf="this.bestFlightItem">${{this.bestFlightItem.fareInfo.sellingTotalFares.totalPerPassenger.amount | number : '1.0-0'}}<span class="d-inline pl-2"
                                                                                                                                                                                                [innerHTML]="calDurationAverage(this.bestFlightItem)">6h 45m</span></span>
                        </div>

                      </div>

                    </div>-->


                <app-loaders></app-loaders>
                <div class="infiniteScroll"       infiniteScroll
                     [infiniteScrollDistance]="1"
                     [infiniteScrollThrottle]="500"

                     (scrolled)="onScroll()" >

                  <div  *ngFor="let item of currentFlightList ,  let i = index" >

                    <div class="all-flight-details mb-4">
                      <div class="not-mobile hotel-item rounded">
                        <div class="row">

                          <div class="col-md-7 col-lg-9 custom-col-9-with hotel_image">
                            <div class="pt-4 pb-4 h-100" [ngClass]="{'pl-4': Service.isSiteLtr(), 'pr-4': Service.isSiteRtl()}">

                              <ng-container *ngFor="let itinerary of item.itineraries ,  let itineraryIndex = index; let isLastItinerary = last">
                              <div class="row" [ngClass]="{'pt-3': itineraryIndex > 0}">
                                <div class="col-8">
                                  <div class="row">
                                    <!-------- checkbox is show only if flight NOT oneway ----------->
                                    <div class="col-1 pt-1" [ngClass]="{'pr-0 pl-3': Service.isSiteLtr(), 'pl-0 pr-3': Service.isSiteRtl()}">
                                      <input *ngIf="item.itineraries.length > 1" type="checkbox" [checked]="filterByDateCheck[itineraryIndex]" class="mt-1 pointer" id="check-{{i}}{{itineraryIndex}}"
                                             (click)="toggleDateSelection(itineraryIndex, itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate)"/>

                                    </div>
                                    <div class="col-1" [ngClass]="{'pl-1 pr-0': Service.isSiteLtr(), 'pr-1 pl-0': Service.isSiteRtl()}">
                                      <label *ngIf="item.itineraries.length > 1"  for="check-{{i}}{{itineraryIndex}}">
                                        <img width="26.5" height="27" loading="lazy" class="max-100 initial-height img-min-width pointer" [ngClass]="{'pr-1': Service.isSiteLtr(), 'pl-1': Service.isSiteRtl()}"
                                             [src]="itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlineLogoUrl"  onError="this.src='/assets/img/airline.png'"/>
                                      </label>

                                      <img *ngIf="item.itineraries.length === 1" width="26.5" height="27" loading="lazy" class="max-100 initial-height img-min-width" [ngClass]="{'pr-1': Service.isSiteLtr(), 'pl-1': Service.isSiteRtl()}"
                                           [src]="itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlineLogoUrl"  onError="this.src='/assets/img/airline.png'"/>
                                    </div>
                                    <div class="col-10" [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}">
                                      <p class="font-18 primaray-text font-weight-bold mb-0">
                                        <span class="mb-0 primaray-text" [innerHTML]="this.Service.formatDateTime12Hours(itinerary.searchSegmentSummary.departureDate)">10:15pm</span> -
                                        <span class="mb-0 primaray-text" [innerHTML]="this.Service.formatDateTime12Hours(itinerary.searchSegmentSummary.arrivalDate)">10:15pm</span>
                                        <span class="font-14 font-weight-normal" *ngIf="Service.checkNextDay(itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate) === 1" i18n="@@nextDay"> (Next day)</span>
                                        <span class="font-14 font-weight-normal" *ngIf="Service.checkNextDay(itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate) > 1"> +{{Service.checkNextDay(itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate)}}</span>
                                        <span class="font-14 font-weight-normal" *ngIf="Service.checkPreviousDay(itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate)" i18n="@@prevDay"> (Previous day)</span></p>
                                      <p class="gray-text-dark font-14"><span [ngClass]="{'yellow-bg rounded': airportsSelectedArray.includes(itinerary.searchSegmentSummary.departureAirport.iata)}">{{itinerary.searchSegmentSummary.departureAirport.iata}}</span><span class="super-vertical-align"> ____ </span><span [ngClass]="{'yellow-bg rounded': airportsSelectedArray.includes(itinerary.searchSegmentSummary.arrivalAirport.iata)}">{{itinerary.searchSegmentSummary.arrivalAirport.iata}}</span></p>


                                    </div>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <p class="mb-0 primaray-text"><span [innerHTML]="itinerary.duration | isoToMinutes : flightListService">6h 45m</span>&nbsp;
                                    <span *ngIf="itinerary.searchSegmentSummary.totalStops === 1">(<ng-container i18n="@@oneStop">1 stop</ng-container>)</span>
                                    <span *ngIf="itinerary.searchSegmentSummary.totalStops === 2">(<ng-container i18n="@@twoStops">2 stops</ng-container>)</span>
                                    <span *ngIf="itinerary.searchSegmentSummary.totalStops > 2">({{itinerary.searchSegmentSummary.totalStops}} <ng-container i18n="@@stops">stops</ng-container>)</span>
                                  </p>
                                  <div  *ngFor="let stop of itinerary.searchSegmentSummary.stopSummaries ,  let i = index" >
                                    <p class="gray-text-dark mb-0">
                                      <span [innerHTML]="this.Service.calDuration(stop.stopDurationInMinutes)"></span>&nbsp;<ng-container i18n="@@in">in</ng-container>&nbsp;<span>{{stop.airport.iata}}</span>
                                    </p>
                                  </div>
                                  <p class="green-text" *ngIf="itinerary.searchSegmentSummary.totalStops === 0" i18n="@@nonStop">Nonstop</p>
                                </div>
                              </div>
                              <div class="p-left-10-percent">
                                <p class="mb-0 gray-text-dark" *ngIf="itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines.length === 1">
                                  {{itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines[0]}}
                                </p>
                                <p class="mb-0 gray-text-dark" *ngIf="itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines.length > 1">
                                  <span i18n="@@multipleAirlines">Multiple Airlines: </span>
                                  <span *ngFor="let airlineItem of itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines">
                                    {{airlineItem}}
                                  </span>
                                </p>
                                <ng-container *ngFor="let stop of itinerary.searchSegmentSummary.stopSummaries">
                                  <ng-container *ngIf="stop.airportChangeTitle">
                                    <p class="orange-text font-12 mb-0 mt-2">{{stop.airportChangeTitle}}</p>
                                  </ng-container>
                                </ng-container>
                                <div class="gray-text" *ngIf="itinerary.searchSegmentSummary.airlineInfoSummary.operatedBy.length">
                                  <p class="mb-0" *ngFor="let operator of itinerary.searchSegmentSummary.airlineInfoSummary.operatedBy ,  let indexOperator = index" >
                                    {{operator}}
                                  </p>
                                </div>
                                <p class="blue-text font-14 pointer mb-0 pt-4" *ngIf="isLastItinerary"
                                   (click)="showDetails(item.transactionId)" i18n="@@viewFlightSummary">View flight summary</p>
                              </div>
                              </ng-container>

                            </div>

                          </div>
                          <div class="col-md-5 col-lg-3 custom-col-3-with ">
                            <div class="pt-4 pb-4 h-100" [ngClass]="{'pr-4': Service.isSiteLtr(), 'pl-4': Service.isSiteRtl()}">
                              <div class="row h-100">
                                <div class="col-md-8 col-lg-12 cash-back-cont d-flex align-items-center text-right position-relative">

                                  <div class="w-100">
                                    <p class="gray-text" i18n="@@from">From</p>
                                    <p class="primaray-text font-22 font-weight-bold mb-0">{{item.sellingPriceConverted.currency}} {{item.sellingPriceConverted.total | number : '1.0-0'}}</p>

                                    <!--   <p class="text-danger mb-0">
                                         <span>{{item.numberOfBookableSeats}}
                                           <span *ngIf="item.numberOfBookableSeats === 1" i18n="@@oneSeatLeft">seat left</span>
                                           <span *ngIf="item.numberOfBookableSeats > 1" i18n="@@seatsLeft">seats left</span>
                                         </span>
                                        </p>-->
                                    <div class="book_button not-mobile mb-3 w-100 mt-auto" >
                                      <a *ngIf="!(flightListService.isCardDetailsVisible[i] | async)"  class="green_btn btn-block text-white mt-3"
                                         i18n="@@select"
                                          (click)="getUpSellingDetails(i, item.transactionId)"
                                          [ngClass]="{'m-progress': upSellingLoading[i] | async}">Select</a>
                                      <a  class="close-blue-btn btn-block blue-text mt-3" i18n="@@close"
                                          *ngIf="flightListService.isCardDetailsVisible[i] | async"
                                          (click)="flightListService.hideFlightUpSellingSection(i)">Close</a>
                                    </div>
                                    <div class="text-danger" [ngClass]="{'text-right': Service.isSiteLtr(), 'text-left': Service.isSiteRtl()}"
                                         *ngIf="item.travelerPricings[0].userData.policyViolated" i18n="@@outOfPolicy"
                                         matTooltip="{{this.flightListService.getPolicyToolTip(item.travelerPricings[0].userData)}}">
                                      Out of policy
                                    </div>
                                    <div *ngIf="item.userData && item.userData.rewardAmount" style="min-height: 29px"></div>
                                    <div class="reward-promo d-flex position-absolute" *ngIf="item.userData && item.userData.rewardAmount">
                                      <div class="promo-triangle"></div>
                                      <div class="promo-text text-white font-12 px-3 pt-2" i18n="@@earnInRewardsFlightList">Earn ${{item.userData.rewardAmount  |  number : '1.0-0'}} in Rewards</div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>





                          </div>

                        </div>
                      </div>
                      <!-------- card details ----------->

                      <app-upselling-section
                              [transactionId] = item.transactionId
                              [fares]="fares"
                              [index]="i"
                              [item]="item">
                      </app-upselling-section>

                      <!-------- card details ----------->
                    </div>


                    <div class="text-center" *ngIf="currentFlightList && currentFlightList.length === 0">
                      <div class="centered with_padding">
                        <div class="">
                          <i class="icon-filter font-22 mb-4 primaray-text"></i>
                        </div>
                        <div class="font-18" i18n="@@weAreSorrySearchFlight"> We are sorry, we don't have any flight that is matching your filters </div>
                        <div class="font-18" i18n="@@PleaseClearFliter"> Please tune your filters or clear them </div>
                      </div>
                    </div>






                  </div>

                </div>









                <div *ngIf="!errorData && filteringActive && filterreditems.length === 0">
                  <div class="container custom_container text-center" >

                    <h2 class="primaray-text font-weight-bold" i18n="@@noSearchCriteria">
                      No results match your filter criteria. Please modify your filter and try again
                    </h2>
                    <button  class="font-20 mt-3 font-weight-normal btn blue-text karla-font" (click)="clearFilters()" i18n="@@clearAllFilters">Clear all filter</button>
                    <br />

                    <img width="515" height="368" loading="lazy" class="d-inline-block initial-height" src="assets/img/wait.png" alt="wait">
                  </div>


                </div>













              </div>

              <div class="col-md-9" *ngIf="errorData || (flightList && flightList.length === 0)">

                <div class="container custom_container text-center" >

                  <h2 class="primaray-text font-weight-bold" i18n="@@noSearchCriteria1">
                    No results match your search criteria. Please modify your search and try again
                  </h2>

                  <img width="515" height="368" loading="lazy" class="d-inline-block initial-height" src="assets/img/wait.png" alt="wait">
                </div>


              </div>

            </div>


          </div>
        </div>








        <div *ngIf="this.Service.mobileView" class="listing hotels_listing mb-5 pb-5">


          <div class="container mb-5 pb-5">
            <app-policy-details-section
                    [showPolicyDetails]="showPolicyDetails"
                    [policyDetails]="policyDetails"
                    [maxNoOfTrips]="maxNoOfTrips"
                    [maxNoInterval]="maxNoInterval"
                    [applicablePolicySelectedClass]="applicablePolicySelectedClass">
            </app-policy-details-section>


            <div *ngIf="!errorData">


                  <div class="sort-filter-header " [ngClass]="{'active':activeScroll && !this.Service.flightDetailsInListOn}" >


                    <div class="d-flex pb-3 mb-3 border-bottom sort-header" [ngClass]="{'active':sortHeader}" >

                      <div [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}">

                      </div>
                      <div>
                        <button class="btn-link font-14  font-weight-bold p-2 rounded sort-btn" [ngClass]="{'active': activeSort, 'mr-4': Service.isSiteLtr(), 'ml-4': Service.isSiteRtl()}" (click)="sortPopUp = true; this.Service.disableBodyScroll();">
                          <span i18n="@@sort">Sort </span> <i class="icon-sort"></i>
                        </button>

                        <button class="btn-link font-14  font-weight-bold" (click)="activePopUp = true; filterPopUp = true; this.Service.disableBodyScroll();">
                          <span i18n="@@filter">Filter </span> <i class="icon-filter"></i>
                        </button>
                      </div>
                    </div>
                    <div  #filterMenu>

                      <div *ngIf="filteringActive"  class=" mb-3 filters-slider" >

                        <swiper  [config]="config" >


                       <!--   <span  (click)="freeCancellationRemove()" class="item" *ngIf="filterFreeCancellation">Free cancellation <i class="icon-error-msg"></i></span>-->
                          <span  (click)="withPolicyFilterRemove()" class="item" *ngIf="Service.isCompany() && filterWithinPolicy">Within Policy <i class="icon-error-msg"></i></span>

                          <ng-container *ngFor="let stop of stops; let i = index" >
                          <span class="item text-capitalize" *ngIf="stop.isChecked" (click)="removeFilterItem(stop.id, 'stops')">
                                {{stop.title}}
                              <i class="icon-error-msg"></i>
                          </span>
                          </ng-container>
                          <ng-container *ngFor="let airline of airlines; let i = index" >
                          <span class="item" *ngIf="airline.isChecked" (click)="removeFilterItem(airline.id, 'airlines')">
                                {{airline.title}}
                            <i class="icon-error-msg"></i>
                          </span>
                          </ng-container>


                          <ng-container *ngFor="let airportItem of airports; let y = index">
                            <ng-container *ngFor="let item of airportItem.airports; let i = index" >

                               <span class="item" *ngIf="item.isChecked" (click)="removeFilterItem(item.id, 'airports')">
                                {{airportItem.city}} ({{item.name}})
                                 <i class="icon-error-msg"></i>
                          </span>

                            </ng-container>
                          </ng-container>

                          <ng-container *ngFor="let depart of departureSeg1; let i = index" >
                          <span class="item text-capitalize" *ngIf="depart.isChecked" (click)="removeFilterItem(depart.id, 'time')">
                                {{depart.title}} (Departure)
                            <i class="icon-error-msg"></i>
                          </span>
                          </ng-container>


                          <ng-container *ngFor="let depart of departureSeg2; let i = index" >
                          <span class="item text-capitalize" *ngIf="depart.isChecked" (click)="removeFilterItem(depart.id, 'timeArrival')">
                                {{depart.title}} (Arrival)
                            <i class="icon-error-msg"></i>
                          </span>
                          </ng-container>



                        </swiper>

                      </div>


                    </div>



                  </div>







                <app-loaders></app-loaders>
                <div class="infiniteScroll"       infiniteScroll
                     [infiniteScrollDistance]="1"
                     [infiniteScrollThrottle]="500"

                     (scrolled)="onScroll()" >

                  <div  *ngFor="let item of currentFlightList ,  let i = index" >

                    <div class="all-flight-details mb-4">
                  <div class="not-mobile mobile-item">
                      <div class="row pointer rounded border mx-0">

                        <div class="col-12 hotel_image">
                          <div class="pt-4 pb-4 h-100">
                            <ng-container *ngFor="let itinerary of item.itineraries ,  let itineraryIndex = index; let isLastItinerary = last">
                              <div class="d-flex" [ngClass]="{'pt-3': itineraryIndex > 0}">
                                <div [ngClass]="{'pr-2': Service.isSiteLtr(), 'pl-2': Service.isSiteRtl()}">
                                  <div class="d-flex">
                                    <div class="pt-1" [ngClass]="{'pr-0': Service.isSiteLtr(), 'pl-0': Service.isSiteRtl()}">
                                      <input *ngIf="item.itineraries.length > 1" type="checkbox" [checked]="filterByDateCheck[itineraryIndex]" class="mt-1 pointer" id="check-{{i}}{{itineraryIndex}}"
                                             (click)="toggleDateSelection(itineraryIndex, itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate)"/>
<!--                                      <input *ngIf="item.itineraries.length > 1 && itineraryIndex % 2 === 0" type="checkbox" [checked]="filterByDepartureDateCheck" class="pointer" id="check-depart-{{i}}" (click)="$event.stopPropagation(); filterByDepartureDate($event, itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate)"/>-->
<!--                                      <input *ngIf="item.itineraries.length > 1 && itineraryIndex % 2 !== 0" type="checkbox" [checked]="filterByReturnDateCheck" class="pointer" id="check-return-{{i}}" (click)="$event.stopPropagation(); filterByReturnDate($event, itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate)"/>-->
                                    </div>
                                    <div [ngClass]="{'pr-0': Service.isSiteLtr(), 'pl-0': Service.isSiteRtl()}">
                                      <label *ngIf="item.itineraries.length > 1"  for="check-{{i}}{{itineraryIndex}}">
                                        <img width="26.5" height="27" loading="lazy" class="mobile-img initial-height pointer"
                                             [src]="itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlineLogoUrl"  onError="this.src='/assets/img/airline.png'"/>
                                      </label>
<!--                                      <label *ngIf="item.itineraries.length > 1 && itineraryIndex % 2 === 0"  for="check-depart-{{i}}"  (click)="$event.stopPropagation(); filterByDepartureDate($event, itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate)">-->
<!--                                        <img width="26.5" height="27" loading="lazy" class="mobile-img initial-height pointer" [src]="itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlineLogoUrl"  onError="this.src='/assets/img/airline.png'" />-->
<!--                                      </label>-->
<!--                                      <label *ngIf="item.itineraries.length > 1 && itineraryIndex % 2 !== 0" for="check-return-{{i}}" (click)="$event.stopPropagation(); filterByReturnDate($event, itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate)">-->
<!--                                        <img width="26.5" height="27" loading="lazy" class="mobile-img initial-height pointer" [src]="itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlineLogoUrl"  onError="this.src='/assets/img/airline.png'" />-->
<!--                                      </label>-->
                                      <img *ngIf="item.itineraries.length === 1" width="26.5" height="27" loading="lazy" class="mobile-img initial-height pointer" [src]="itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlineLogoUrl"  onError="this.src='/assets/img/airline.png'" />
                                    </div>
                                    <div [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}">
                                      <p class="primaray-text font-weight-bold mb-0">
                                        <span class="mb-0 primaray-text" [innerHTML]="this.Service.formatDateTime12Hours(itinerary.searchSegmentSummary.departureDate)">10:15pm</span> -
                                        <span class="mb-0 primaray-text" [innerHTML]="this.Service.formatDateTime12Hours(itinerary.searchSegmentSummary.arrivalDate)">10:15pm</span>
                                        <span class="font-12 font-weight-normal" *ngIf="Service.checkNextDay(itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate) === 1" i18n="@@nextDay"> (Next day)</span>
                                        <span class="font-12 font-weight-normal" *ngIf="Service.checkNextDay(itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate) > 1"> +{{Service.checkNextDay(itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate)}}</span>
                                        <span class="font-12 font-weight-normal" *ngIf="Service.checkPreviousDay(itinerary.searchSegmentSummary.departureDate, itinerary.searchSegmentSummary.arrivalDate)" i18n="@@prevDay"> (Previous day)</span></p>
                                      <p class="gray-text-dark"><span>{{itinerary.searchSegmentSummary.departureAirport.iata}}</span><span class="super-vertical-align"> ____ </span><span>{{itinerary.searchSegmentSummary.arrivalAirport.iata}}</span></p>

                                    </div>
                                  </div>
                                </div>
                                <div [ngClass]="{'ml-auto text-right': Service.isSiteLtr(), 'mr-auto text-left': Service.isSiteRtl()}">
                                  <p class="mb-0 primaray-text" [ngClass]="{'max-width-content': Service.isSiteLtr()}"><span [innerHTML]="itinerary.duration | isoToMinutes : flightListService">6h 45m</span>&nbsp;
                                    <span *ngIf="itinerary.searchSegmentSummary.totalStops === 1">(<ng-container i18n="@@oneStop">1 stop</ng-container>)</span>
                                    <span *ngIf="itinerary.searchSegmentSummary.totalStops === 2">(<ng-container i18n="@@twoStops">2 stops</ng-container>)</span>
                                    <span *ngIf="itinerary.searchSegmentSummary.totalStops > 2"> ({{itinerary.searchSegmentSummary.totalStops}}&nbsp;<ng-container i18n="@@stops">stops</ng-container>)</span>
                                  </p>
                                  <div  *ngFor="let stop of itinerary.searchSegmentSummary.stopSummaries ,  let i = index" >
                                    <p class="gray-text-dark mb-0">
                                      <span [innerHTML]="this.Service.calDuration(stop.stopDurationInMinutes)"></span>&nbsp;<ng-container i18n="@@in">in</ng-container>&nbsp;<span>{{stop.airport.iata}}</span>
                                    </p>
                                  </div>
                                  <p class="green-text" *ngIf="itinerary.searchSegmentSummary.totalStops === 0" i18n="@@nonStop">Nonstop</p>
                                </div>
                              </div>
                              <div class="font-12" [ngClass]="{'p-left-round-trip': item.itineraries.length > 1, 'p-left-one-way': item.itineraries.length === 1}">
                                <p class="mb-0 gray-text-dark" *ngIf="itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines.length === 1">
                                  {{itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines[0]}}
                                </p>
                                <p class="mb-0 gray-text-dark" *ngIf="itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines.length > 1">
                                  <span i18n="@@multipleAirlines">Multiple Airlines: </span>
                                  <span *ngFor="let airlineItem of itinerary.searchSegmentSummary.airlineInfoSummary.marketingAirlines">
                                      {{airlineItem}}
                                    </span>
                                </p>
                                <ng-container *ngFor="let stop of itinerary.searchSegmentSummary.stopSummaries">
                                  <ng-container *ngIf="stop.airportChangeTitle">
                                    <p class="orange-text font-12 mb-0 mt-2">{{stop.airportChangeTitle}}</p>
                                  </ng-container>
                                </ng-container>
                                <div class="gray-text" *ngIf="itinerary.searchSegmentSummary.airlineInfoSummary.operatedBy.length">
                                  <p class="mb-0" *ngFor="let operator of itinerary.searchSegmentSummary.airlineInfoSummary.operatedBy ,  let indexOperator = index" >
                                    {{operator}}
                                  </p>
                                </div>
                              </div>
                            </ng-container>



                          </div>

                        </div>
                        <div class="col-12 ">
                          <div class="pb-2 mb-1 h-100">
                            <div class="row">
                              <div class="col-12 d-flex align-items-center position-relative pt-2" [ngClass]="{'text-right': Service.isSiteLtr(), 'text-left': Service.isSiteRtl()}">


                                <p class="blue-text font-14 pointer mb-0 pt-4" (click)="showDetails(item.transactionId)" i18n="@@viewFlightSummary">View flight summary</p>
                                <p class="primaray-text font-22 font-weight-bold mb-0"
                                   [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}">{{item.sellingPriceConverted.currency}} {{item.sellingPriceConverted.total | number : '1.0-0'}}</p>

                              </div>
                              <div class="col-12 d-flex">
                                <div class="text-danger text-right pt-3"
                                     *ngIf="item.travelerPricings[0].userData.policyViolated" i18n="@@outOfPolicy">
                                  Out of policy
                                </div>




                                <div class="reward-promo d-flex position-absolute" *ngIf="item.userData && item.userData.rewardAmount">
                                  <div class="promo-text text-white font-12 px-3 pt-2" i18n="@@earnInRewardsFlightList">Earn ${{item.userData.rewardAmount  |  number : '1.0-0'}} in Rewards</div>
                                  <div class="promo-triangle right-side-triangle"></div>
                                </div>


                                  <a class="green_btn in-mobile text-white px-4 mt-1"
                                      [ngClass]="{'m-progress': (upSellingLoading[i] | async), 'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}"
                                      *ngIf="!(flightListService.isCardDetailsVisible[i] | async)"
                                      (click)="getUpSellingDetails(i, item.transactionId)"
                                       i18n="@@select">Select</a>
                                <div class="text-danger" [ngClass]="{'text-right': Service.isSiteLtr(), 'text-left': Service.isSiteRtl()}"
                                     *ngIf="i === selectedFlightIndex">
                                  {{upSellingErrorMsg}}
                                </div>
                                <a class="close-blue-btn in-mobile blue-text px-4 mt-1" i18n="@@close"
                                    *ngIf="flightListService.isCardDetailsVisible[i] | async"
                                    [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}"
                                    (click)="flightListService.hideFlightUpSellingSection(i)">Close</a>

                              </div>



                            </div>

                          </div>





                        </div>

                      </div>
                    </div>

                      <!-------- card details ----------->
                      <app-upselling-section
                              [transactionId] = item.transactionId
                              [fares]="fares"
                              [index]="i"
                              [item]="item">
                      </app-upselling-section>
                      <!-------- card details ----------->
                    </div>

                    <div class="text-center" *ngIf="currentFlightList && currentFlightList.length === 0">
                      <div class="centered with_padding">
                        <div class="">
                          <i class="icon-filter font-22 mb-4 primaray-text"></i>
                        </div>
                        <div class="font-18" i18n="@@weAreSorrySearchFlight"> We are sorry, we don't have any flight that is matching your filters </div>
                        <div class="font-18" i18n="@@PleaseClearFliter"> Please tune your filters or clear them </div>
                      </div>
                    </div>






                  </div>

                </div>




                <div *ngIf="!errorData && filteringActive && filterreditems.length === 0">
                  <div class="container custom_container text-center" >

                    <h2 class="primaray-text font-weight-bold" i18n="@@noSearchCriteria">
                      No results match your filter criteria. Please modify your filter and try again
                    </h2>
                    <button  class="font-20 mt-3 font-weight-normal btn blue-text karla-font" (click)="clearFilters()" i18n="@@clearAllFilters">Clear all filter</button>
                    <br />

                    <img width="515" height="368" loading="lazy" class="w-100 d-inline-block initial-height" src="assets/img/wait.png" alt="wait">
                  </div>


                </div>













              </div>

              <div  *ngIf="errorData || (flightList && flightList.length === 0)">

                <div class="container custom_container text-center" >

                  <h2 class="primaray-text font-weight-bold" i18n="@@noSearchCriteria1">
                    No results match your search criteria. Please modify your search and try again
                  </h2>

                  <img width="515" height="368" loading="lazy" class="w-100 d-inline-block initial-height" src="assets/img/wait.png" alt="wait">
                </div>


              </div>




          </div>
        </div>





        <div class="sort-popup-cont" [ngClass]="{in : sortPopUp && this.Service.mobileView }" >
          <div class="sort-popup" [ngClass]="{in : sortPopUp }"  >
            <div class="d-flex p-3">

              <div class="title primaray-text font-weight-bold font-16" [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}" i18n="@@sortBy">
                Sort by
              </div>

              <div>
                <i class="icon-close-light primaray-text font-18"  (click)="sortPopUp = false; this.Service.enableBodyScroll()"></i>
              </div>
            </div>


            <div class="sortPopUp">



          <!--    <ul  class="list-unstyled filter-group ">
                <li  [ngClass]="{'active' : ParentSort === 'default'}" (click)="sortFunc('default', 'default'); sortPopUp = false; this.Service.enableBodyScroll();"
                     i18n="@@ourFavorites">
                  Our Favorites
                </li>
                <li (click)="sortFunc('cheapest', 'cheapest'); sortPopUp = false; this.Service.enableBodyScroll();" [ngClass]="{'active' : SortType === 'cheapest'}">
                  <ng-container i18n="@@cheapest">Cheapest</ng-container>&nbsp;(<span class="pt-2 font-12" *ngIf="this.cheapestFlightItem">&nbsp;<ng-container *ngIf="Service.isSiteLtr()">$</ng-container>{{this.cheapestFlightItem.fareInfo.sellingTotalFares.totalPerPassenger.amount | number : '1.0-0'}}<ng-container *ngIf="Service.isSiteRtl()">$</ng-container>&nbsp;<span class="d-inline pl-2" [innerHTML]="calDurationAverage(this.cheapestFlightItem)">6h 45m</span></span>)
                </li>
                <li (click)="sortFunc('shortest', 'shortest'); sortPopUp = false; this.Service.enableBodyScroll();" [ngClass]="{'active' : SortType === 'shortest'}">
                  <ng-container i18n="@@shortest">Shortest</ng-container>&nbsp;(<span class="pt-2 font-12" *ngIf="this.shortestFlightItem">&nbsp;<ng-container *ngIf="Service.isSiteLtr()">$</ng-container>{{this.shortestFlightItem.fareInfo.sellingTotalFares.totalPerPassenger.amount | number : '1.0-0'}}<ng-container *ngIf="Service.isSiteRtl()">$</ng-container>&nbsp;<span class="d-inline pl-2" [innerHTML]="calDurationAverage(this.shortestFlightItem)">6h 45m</span></span>)
                </li>
                <li (click)="sortFunc('best', 'best'); sortPopUp = false; this.Service.enableBodyScroll();"   [ngClass]="{'active' : SortType === 'SavingHigh'}">
                  <ng-container i18n="@@best">Best</ng-container>&nbsp;(<span class="pt-2 font-12" *ngIf="this.bestFlightItem">&nbsp;<ng-container *ngIf="Service.isSiteLtr()">$</ng-container>{{this.bestFlightItem.fareInfo.sellingTotalFares.totalPerPassenger.amount | number : '1.0-0'}}<ng-container *ngIf="Service.isSiteRtl()">$</ng-container>&nbsp;<span class="d-inline pl-2" [innerHTML]="calDurationAverage(this.bestFlightItem)">6h 45m</span></span>)
                </li>
              </ul>-->




            </div>

          </div>
          <div class="overlay" (click)="sortPopUp = false; ; this.Service.enableBodyScroll()" > </div>


        </div>








        <div class="action-popup left-popup"   [ngClass]="{in : filterPopUp }">

          <div class="header">


            <div class="form-name-dates-summary picker-new-header" >
              <div class="title" i18n="@@filter">
                Filter
              </div>


            </div>


          </div>
          <div class="body">


            <div >









              <div class="form-checkboxes">

                <div class="form-item form-type-ccheckbox mb-3" *ngIf="Service.isCompany() && showWithinPolicyFilter" [ngClass]="{'active': filterWithinPolicyCheck}" >
                  <input id="within-policy-mobile" type="checkbox" [(ngModel)]="filterWithinPolicy" [checked]="filterWithinPolicy" [ngModelOptions]="{standalone: true}" (change)="filterWithinPolicyFunc()" />
                  <label for="within-policy-mobile" class="gray-text-dark " [ngClass]="{'ml-2': Service.isSiteLtr(), 'mr-2': Service.isSiteRtl()}" i18n="@@showInPolicyFlightsOnly">Show in-policy flights only</label>

                </div>



              </div>
              <div class="filter_group mb-5 mt-2" >
                <div class="main">
                  <div>
                    <div class="form-checkboxes">

                    <!--  <div class="form-item form-type-checkbox mb-2"  [ngClass]="{'active': filterFreeCancellationCheck}" >
                        <input id="freeCanMobile" type="checkbox" [(ngModel)]="filterFreeCancellation" [checked]="filterFreeCancellation" [ngModelOptions]="{standalone: true}" (change)="filterFreeCancellationFunc()" />
                        <label for="freeCanMobile" class="gray-text-dark"   >Free cancellation</label>

                      </div>-->



                      <div class="d-flex mt-4 pt-3 mb-2">
                        <h4 class="w-75 mb-0" i18n="@@theStops">Stops</h4>
                        <div class="w-25" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}" i18n="@@starting">Starting</div>
                      </div>
                      <div class="main">
                        <div class="form-checkboxes">
                          <ng-container *ngFor="let stop of stops; let i = index">
                            <div class="d-flex" *ngIf="stop.counter" [ngClass]="{'active': activecheckbox === i }">
                              <div class="form-item form-type-checkbox mb-1 w-75">
                                <input type="checkbox" id="Q{{stop.id}}" [checked]="stop.isChecked" (change)="dataChanged($event, i, 'stops')" [disabled]="stop.faded" />
                                <label class="text-capitalize" [ngClass]="{'gray-text-dark': !stop.faded, 'placeholder-text': stop.faded}" for="Q{{stop.id}}" >{{stop.title}} ({{stop.counter}})</label>
                              </div>
                              <div class="w-25" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}">{{stop.currency}}  {{stop.price | number : '1.0-0'}}</div>
                            </div>
                          </ng-container>

                        </div>
                      </div>

                      <div class="d-flex mt-4 pt-3 mb-2">
                        <h4 class="w-75 mb-0" i18n="@@airlines">Airlines</h4>
                        <div class="w-25" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}" i18n="@@starting">Starting</div>
                      </div>
                      <div class="main">
                        <div class="form-checkboxes">

                          <div class="d-flex" *ngFor="let airline of airlines| slice : 0 : showMore; let i = index" [ngClass]="{'active': activecheckbox === i }">
                            <div class="form-item form-type-checkbox mb-1 w-75">
                              <input type="checkbox" id="Q{{airline.id}}" [checked]="airline.isChecked" (change)="dataChanged($event, i, 'airlines')" [disabled]="airline.faded" />
                              <label [ngClass]="{'gray-text-dark': !airline.faded, 'placeholder-text': airline.faded}" for="Q{{airline.id}}" >{{airline.title}} ({{airline.counter}})</label>
                            </div>
                            <div class="w-25" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}">{{airline.currency}} {{airline.price | number : '1.0-0'}}</div>
                          </div>
                          <p *ngIf="showMore < airlines.length">
                            <a (click)="showMore = airlines.length" class="blue-text" i18n="@@showMore">
                              Show more </a>
                          </p>
                          <p *ngIf="airlines.length > 4 && showMore === airlines.length">
                            <a (click)="showMore = 4" class="blue-text" i18n="@@showLess">
                              Show less </a>
                          </p>

                        </div>


                      </div>

                      <div class="d-flex mt-4 pt-3 mb-2">
                        <h4 class="w-75 mb-0" i18n="@@airports">Airports</h4>
                        <div class="w-25" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}"></div>
                      </div>
                      <div class="main">
                        <div class="form-checkboxes">
<!--                          <ng-container *ngFor="let airportItem of airports; let y = index">-->
<!--                            <div class="d-flex mt-2 mb-2">-->
<!--                              <p class="w-75 mb-0">{{airportItem.city}}</p>-->
<!--                              <div class="w-25" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}" i18n="@@starting">Starting</div>-->
<!--                            </div>-->
<!--                            <div class="d-flex" *ngFor="let item of airportItem.airports; let i = index" [ngClass]="{'active': activecheckbox === i }">-->
<!--                              <div class="form-item form-type-checkbox mb-1 w-75">-->
<!--                                <input type="checkbox" id="Q{{item.id}}" [checked]="item.isChecked" (change)="dataChanged($event, i, 'airports')" [disabled]="item.faded" />-->
<!--                                <label [ngClass]="{'gray-text-dark': !item.faded, 'placeholder-text': item.faded}"  for="Q{{item.id}}" >{{item.name}}</label>-->
<!--                              </div>-->
<!--                              <div class="w-25" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}">${{item.minPrice | number : '1.0-0'}}</div>-->
<!--                            </div>-->
<!--                          </ng-container>-->


                        </div>

                      </div>






                      <div class="d-flex mt-4 pt-3 mb-2">
                        <h4 class="w-75 mb-0" i18n="@@departureTime">Departure Time</h4>
                        <div class="w-25 blue-text pointer" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}" i18n="@@reset"
                             (click)="clearDepartureFilters()"><!--Reset--></div>
                      </div>
<!--                      <div class="gray-text-dark" *ngIf="this.data && this.data.data && this.data.data.itineraries">-->
<!--                        From {{this.data.data.itineraries[0].originDestinationOptionsGroups[0].originDestinationOptions[0].flightSegment.departureAirport.city}}-->
<!--                      </div>-->
<!--                      <div class="time-duration-filter">-->
<!--                        <div class="row font-16 mx-0 mt-2">-->
<!--                          <ng-container *ngFor="let depart of departureSeg1; let i = index">-->
<!--                            <div class="w-50 checkbox-radio-item-container col-auto form-group px-0" *ngIf="depart.counter"-->
<!--                                 [ngClass]="{'pr-1': Service.isSiteLtr(), 'pl-1': Service.isSiteRtl(), 'active': activecheckbox === i}">-->
<!--                              <input class="d-none" type="checkbox" id="{{depart.id}}" [checked]="depart.isChecked" name="gender" (change)="dataChanged($event, depart.id, 'time')" />-->
<!--                              <label class="white-box border w-100 d-flex justify-content-center align-items-center mb-0 px-1" for="{{depart.id}}">-->
<!--                                <div class="text-center font-12">-->
<!--                                  <span class="d-block">{{depart.title}}</span>-->
<!--                                  <span class="d-block font-12 gray-text pt-2">{{depart.time}}</span>-->
<!--                                </div>-->
<!--                              </label>-->
<!--                            </div>-->
<!--                          </ng-container>-->
<!--                        </div>-->
<!--                      </div>-->


<!--                      <ng-container *ngIf="this.queryParams.roundTrip === true">-->
<!--                        <div class="gray-text-dark mt-3" *ngIf="this.data && this.data.data && this.data.data.itineraries">-->
<!--                          From {{this.data.data.itineraries[0].originDestinationOptionsGroups[1].originDestinationOptions[0].flightSegment.departureAirport.city}}-->
<!--                        </div>-->
<!--                        <div class="time-duration-filter">-->
<!--                          <div class="row font-16 mx-0 mt-2">-->
<!--                            <ng-container *ngFor="let depart of departureSeg2; let i = index">-->
<!--                              <div class="w-50 checkbox-radio-item-container col-auto form-group px-0" *ngIf="depart.counter"-->
<!--                                   [ngClass]="{'pr-1': Service.isSiteLtr(), 'pl-1': Service.isSiteRtl(), 'active': activecheckbox === i}">-->
<!--                                <input class="d-none" type="checkbox" id="{{depart.id}}" [checked]="depart.isChecked" name="gender" (change)="dataChanged($event, depart.id, 'timeArrival')" />-->
<!--                                <label class="white-box border w-100 d-flex justify-content-center align-items-center mb-0 px-1" for="{{depart.id}}">-->
<!--                                  <div class="text-center font-12">-->
<!--                                    <span class="d-block">{{depart.title}}</span>-->
<!--                                    <span class="d-block font-12 gray-text pt-2">{{depart.time}}</span>-->
<!--                                  </div>-->
<!--                                </label>-->
<!--                              </div>-->
<!--                            </ng-container>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </ng-container>-->

                      <div class="filter_group mb-5 mt-2">
                        <h4 i18n="@@duration">Duration</h4>
                        <div class="d-flex">
                        <span class="gray-text-dark" [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}"><span i18n="@@upTo">up to</span>&nbsp;
                          <span class="font-weight-bold" *ngIf="hoursValue === 1">{{oneHourValueText}}</span>
                           <span class="font-weight-bold" *ngIf="hoursValue === 2">{{twoHourValueText}}</span>
                           <span class="font-weight-bold" *ngIf="hoursValue > 2 && hoursValue < 11">{{hoursValue}}{{moreHourValueText}}</span>
                          <span class="font-weight-bold" *ngIf="hoursValue >= 11">{{hoursValue}}{{moreThan11Hours}}</span>
                        </span>

                        </div>
                        <div class="d-flex gray-text-dark" *ngIf="optionsHours">
                          <span [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}">{{this.optionsHours.floor}}<span i18n="@@hour">h</span></span>
                          <span>{{this.optionsHours.ceil}}<span i18n="@@hour">h</span></span>

                        </div>
                        <div class="custom-slider" *ngIf="optionsHours">
                          <ng5-slider [(value)]="hoursValue" [options]="optionsHours"  (userChangeEnd)="hoursChangeEnd($event)"  ></ng5-slider>
                        </div>
                      </div>





                    </div>

                   </div>

                </div>
              </div>




            </div>


          </div>

          <div class="sticky-footer sorter margin_bottom mobile_only pt-3 pb-3" [ngClass]="{'pl-3 pr-3': Service.isSiteLtr(), 'pr-3 pl-3': Service.isSiteRtl()}">
            <div class=" row gutters-5">

              <div class="col-6 pl-2">

                <div class="btn btn-outline-blue btn-block font-14"  *ngIf="filterPopUp" (click)="clearFilters()" i18n="@@reset">
                  Reset
                </div>


              </div>
              <div class="col-6">
                <div class="btn blue_btn btn-block blue_btn_form font-14" (click)="activePopUp = false; sortPopUp = false; filterPopUp = false; this.Service.enableBodyScroll()" i18n="@@done">
                  Done
                </div>
              </div>
            </div>
          </div>
        </div>

        <button class="back-to-top" type="button" [ngClass]="{'in_range': this.Service.showScrolltop, 'add-bottom-value' : this.Service.showCookiesMsg || (this.Service.unlockRateFromTop && this.Service.hideStickyBottomBannerRates)}" (click)="this.Service.scrollTop()"><i class="icon-arrow-up2"></i></button>
      </div>



    </div>
  </div>


</div>
