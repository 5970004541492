import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import { SidenavService} from '../../workspace-dashboard/sidenav.service';
import { onMainContentChange } from '../../workspace-dashboard/animations/animations';
import {Router} from '@angular/router';
import {environment} from "../../../../environments/environment";
import {AuthServiceMain} from "../../../auth/auth.service";
import {BehaviorSubject, Subscription} from "rxjs";


@Component({
  selector: 'app-workspace-settings',
  templateUrl: './workspace-settings.component.html',
  styleUrls: ['./workspace-settings.component.scss'],
  animations: [ onMainContentChange ]
})
export class WorkspaceSettingsComponent implements OnInit {
 // public onSideNavChange: boolean;
  constructor( public dataService: DataService,
               private sidenavService: SidenavService,
               public router: Router,
               public auth: AuthServiceMain) {
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
  env = environment;
  infoSubscription: Subscription;
  data;
  infoLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  ngOnInit(): void {
    this.infoSubscription = this.dataService.getCompanyInfo().subscribe(
        (suc) => {
          this.data = suc;
          if (this.data.errorCode === 0) {
            this.infoLoading.next(false);
            this.auth.companyInfo = {
              id: this.data.data.id,
              domain: this.data.data.domain,
              name: this.data.data.name,
              inactive: this.data.data.inactive,
              ownerUserId: this.data.data.ownerUserId,
              logoUrl: this.data.data.logoUrl,
              numberOfEmployees: this.data.data.numberOfEmployees,
              annualTravelSpend: this.data.data.annualTravelSpend,
              legalCompanyName: this.data.data.legalCompanyName,
              industry: this.data.data.industry,
              address: this.data.data.address,
              paymentMethods: this.data.data.paymentMethods,
              rewardProgram: this.data.data.rewardProgram,
              systemPreferences: this.data.data.systemPreferences,
              companySubscriptionType: this.data.data.companySubscriptionType ? this.data.data.companySubscriptionType : '',
              taxNumber: this.data.data.taxNumber
            };
            localStorage.setItem("companyInfo", JSON.stringify(this.auth.companyInfo));
          } else {
            this.infoLoading.next(false);
            this.dataService.messages(this.data.message, "error");
          }
        },
        (error) => {
          this.infoLoading.next(false);
          this.dataService.messages(environment.messages.error, "error");
        }
    );
  }

  ngOnDestroy() {
    if(this.infoSubscription){
      this.infoSubscription.unsubscribe();
    }
  }
}
