<footer [ngClass]="{'add-bottom-padding' : this.Service.showCookiesMsg}" *ngIf="this.Service.showFooter && !(router.url.indexOf('mobile') >= 0) || router.url === '/info/mobileapp' " >
  <div class="container" >

    <div class="column-1">
      <div class="logo">
        <a  routerLink="/" class="logo_link">
          <img width="145" height="35" loading="lazy"  [src]="this.Service.desktopView ? 'assets/img/logo.svg'  : 'assets/img/logo-mobile.svg'" class="logo-image" alt="SafarHub Logo">
        </a>
      </div>
      <div class="social_networks_wrap d-none d-lg-block">
        <div class="title bolder primaray-text">
          Follow Us
        </div>
        <ul class="social_networks">
          <li class="item">
            <a target="_blank" href="https://www.facebook.com/triphopapp">
              <i class="icon-facebook"></i>
            </a>
          </li>
          <li class="item">
            <a target="_blank" href="https://www.instagram.com/triphoptravel/">
              <i class="icon-instagram"></i>
            </a>
          </li>
          <li  class="item">
            <a target="_blank" href="https://www.twitter.com/triphopapp"><i class="icon-twitter"></i></a>
          </li>
        </ul>
      </div>
    </div>

    <div class="column-2">
      <ul class="list">

   <!--     <li class="item">
          <a routerLink="/info/how-it-works">How It Works</a>
        </li>

        <li class="item">
          <a routerLink="/info/faq">FAQ</a>
        </li>-->

        <li class="item">
          <a routerLink="/info/mobileapp" target="_blank">Mobile App</a>
        </li>

        <li class="item">
          <a href="https://blog.triphop.com" target="_blank">Blog</a>
        </li>


        <li class="item">
          <a routerLink="/info/best-price-guarantee" target="_blank">Best price guarantee</a>
        </li>

        <li class="item">
          <a [href]="env.companyHost" target="_blank">Business travel</a>
        </li>
        <li class="item">
          <a routerLink="/info/about">About us</a>
        </li>

        <li class="item">
          <a routerLink="/info/contact-us">Contact us</a>
        </li>




        <li class="item">
          <a routerLink="/info/terms">Terms of use</a>
        </li>

        <li class="item">
          <a routerLink="/info/privacy">Privacy policy</a>
        </li>

        <li class="item">
          <a target="_blank" routerLink="/hotels/us/florida.html">Florida</a>
        </li>

        <li class="item">
          <a target="_blank" routerLink="/hotels/us/illinois.html">Illinois</a>
        </li>

        <li class="item">
          <a target="_blank" routerLink="/hotels/us/fl/orlando.html">Orlando</a>
        </li>

        <li class="item">
          <a target="_blank" routerLink="/hotels/us/il/chicago.html">Chicago</a>
        </li>

        <li class="item">
          <a target="_blank" routerLink="/hotels/us/newyork.html">New York</a>
        </li>

        <li class="item">
          <a target="_blank" routerLink="/hotels/us/ny/new-york-city.html">New York City</a>
        </li>
        <li class="item">
          <a target="_blank" routerLink="/hotels/us/california.html">California</a>
        </li>
        <li class="item">
          <a target="_blank" routerLink="/hotels/us/ca/san-diego.html">San Diego</a>
        </li>
        <li class="item">
          <a target="_blank" routerLink="/hotels/us/ca/san-francisco.html">San Francisco</a>
        </li>

        <li class="item">
          <a target="_blank" routerLink="/hotels/us/ca/los-angeles.html">Los Angeles</a>
        </li>

      </ul>
    </div>







  </div>
  <div class="container mb-0">
    <div class="bottom">
      <div class="social_networks_wrap d-lg-none d-flex">
        <div class="title bolder pt-2 gray-text-dark">
          Follow Us
        </div>
        <ul class="social_networks ml-5">
          <li class="item">
            <a target="_blank"  href="https://www.facebook.com/triphopapp">
              <i class="icon-facebook"></i>
            </a>
          </li>
          <li class="item">
            <a target="_blank" href="https://www.instagram.com/triphoptravel/">
              <i class="icon-instagram"></i>
            </a>
          </li>
          <li  class="item">
            <a target="_blank" href="https://www.twitter.com/triphopapp"><i class="icon-twitter"></i></a>
          </li>



        </ul>
      </div>
      <div class="copyrights">
        <p>&copy; {{cYear}} Triphop, Inc. | All rights reserved</p>
      </div>
    </div>

  </div>
</footer>
