import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../../services/data.service";
import { Router } from "@angular/router";
import { AuthServiceMain } from "../../../../auth/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { VariablesService } from "../../../../services/vars.service";
import { environment } from "../../../../../environments/environment";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-account-update",
  templateUrl: "./account-update.component.html",
  styleUrls: ["./account-update.component.scss"],
})
export class AccountUpdateComponent implements OnInit {
  constructor(
    private titleService: Title,
    private formBuilder: FormBuilder,
    public dataService: DataService,
    public router: Router,
    public auth: AuthServiceMain,
    public vars: VariablesService
  ) {
    this.titleService.setTitle("Edit account info | SafarHub");
  }
  updateCompanyInfo: FormGroup;
  submitted = false;
  submitting = false;
  countries = this.vars.countries;
  states = this.vars.states;
  industries = this.vars.industries;
  selectedValue = null;
  selectedState = null;
  selectedIndustry = null;
  selectedTravelSpend = null;
  selectedNumberOfEmployees = null;
  data;
  companyFormData;
  addressFormData = [];
  sendAddressData = "";
  addressRequired;
  mainAddressNameRequired;
  mainAddressNameErrorRequiredMsg;
  addressErrorRequiredMsg;
  addressObj = {};
  ngOnInit(): void {
    this.updateCompanyInfo = this.formBuilder.group(
      {
        displayName: ["", [Validators.required]],
        numberOfEmployees: [""],
        annualTravelSpend: [""],
        legalCompanyName: [""],
        industry: [""],
        vatNumber: [""],
        address1: [""],
        address2: [""],
        countryCode: [""],
        stateCode: [""],
        zipCode: [""],
        city: [""],
        phoneNumber: [""],
        mainAddressName: [""],
      },
      {}
    );

    if (
      this.dataService.rightSideDrawerName === "editCompanyOtherAddress" ||
      this.dataService.BottomSheetName === "editCompanyOtherAddress"
    ) {
      if (this.dataService.rightSideDrawerData) {
        if (this.dataService.rightSideDrawerData.name) {
          Object.assign(this.addressObj, {
            name: this.dataService.rightSideDrawerData.name,
          });
          this.updateCompanyInfo.controls.mainAddressName.setValue(
            this.dataService.rightSideDrawerData.name
          );
        }
        if (this.dataService.rightSideDrawerData.address1) {
          Object.assign(this.addressObj, {
            address1: this.dataService.rightSideDrawerData.address1,
          });
          this.updateCompanyInfo.controls.address1.setValue(
            this.dataService.rightSideDrawerData.address1
          );
        }
        if (this.dataService.rightSideDrawerData.address2) {
          Object.assign(this.addressObj, {
            address2: this.dataService.rightSideDrawerData.address2,
          });
          this.updateCompanyInfo.controls.address2.setValue(
            this.dataService.rightSideDrawerData.address2
          );
        }
        if (
          this.dataService.rightSideDrawerData.countryCode &&
          this.dataService.rightSideDrawerData.countryCode !== "undefined"
        ) {
          this.selectedValue = this.dataService.rightSideDrawerData.countryCode;
        }

        if (
          this.dataService.rightSideDrawerData.stateCode &&
          this.dataService.rightSideDrawerData.stateCode !== null &&
          this.dataService.rightSideDrawerData.stateCode !== "undefined" &&
          this.dataService.rightSideDrawerData.stateCode !== ""
        ) {
          Object.assign(this.addressObj, {
            stateCode: this.dataService.rightSideDrawerData.stateCode,
          });
          this.selectedState = this.dataService.rightSideDrawerData.stateCode;
        }
        if (
          this.dataService.rightSideDrawerData.zipCode &&
          this.dataService.rightSideDrawerData.zipCode !== null &&
          this.dataService.rightSideDrawerData.zipCode !== "undefined"
        ) {
          Object.assign(this.addressObj, {
            zipCode: this.dataService.rightSideDrawerData.zipCode,
          });
          this.updateCompanyInfo.controls.zipCode.setValue(
            this.dataService.rightSideDrawerData.zipCode
          );
        }
        if (
          this.dataService.rightSideDrawerData.city &&
          this.dataService.rightSideDrawerData.city !== null &&
          this.dataService.rightSideDrawerData.city !== "undefined"
        ) {
          Object.assign(this.addressObj, {
            city: this.dataService.rightSideDrawerData.city,
          });
          this.updateCompanyInfo.controls.city.setValue(
            this.dataService.rightSideDrawerData.city
          );
        }
        if (
          this.dataService.rightSideDrawerData.phoneNumber &&
          this.dataService.rightSideDrawerData.phoneNumber !== null &&
          this.dataService.rightSideDrawerData.phoneNumber !== "undefined"
        ) {
          Object.assign(this.addressObj, {
            phoneNumber: this.dataService.rightSideDrawerData.phoneNumber,
          });
          this.updateCompanyInfo.controls.phoneNumber.setValue(
            this.dataService.rightSideDrawerData.phoneNumber
          );
        }
      } else if (this.dataService.BottomSheetData) {
        if (this.dataService.BottomSheetData.name) {
          Object.assign(this.addressObj, {
            name: this.dataService.BottomSheetData.name,
          });
          this.updateCompanyInfo.controls.mainAddressName.setValue(
            this.dataService.BottomSheetData.name
          );
        }
        if (this.dataService.BottomSheetData.address1) {
          Object.assign(this.addressObj, {
            address1: this.dataService.BottomSheetData.address1,
          });
          this.updateCompanyInfo.controls.address1.setValue(
            this.dataService.BottomSheetData.address1
          );
        }
        if (this.dataService.BottomSheetData.address2) {
          Object.assign(this.addressObj, {
            address2: this.dataService.BottomSheetData.address2,
          });
          this.updateCompanyInfo.controls.address2.setValue(
            this.dataService.BottomSheetData.address2
          );
        }
        if (
          this.dataService.BottomSheetData.countryCode &&
          this.dataService.BottomSheetData.countryCode !== "undefined"
        ) {
          this.selectedValue = this.dataService.BottomSheetData.countryCode;
        }

        if (
          this.dataService.BottomSheetData.stateCode &&
          this.dataService.BottomSheetData.stateCode !== null &&
          this.dataService.BottomSheetData.stateCode !== "undefined" &&
          this.dataService.BottomSheetData.stateCode !== ""
        ) {
          Object.assign(this.addressObj, {
            stateCode: this.dataService.BottomSheetData.stateCode,
          });
          this.selectedState = this.dataService.BottomSheetData.stateCode;
        }
        if (
          this.dataService.BottomSheetData.zipCode &&
          this.dataService.BottomSheetData.zipCode !== null &&
          this.dataService.BottomSheetData.zipCode !== "undefined"
        ) {
          Object.assign(this.addressObj, {
            zipCode: this.dataService.BottomSheetData.zipCode,
          });
          this.updateCompanyInfo.controls.zipCode.setValue(
            this.dataService.BottomSheetData.zipCode
          );
        }
        if (
          this.dataService.BottomSheetData.city &&
          this.dataService.BottomSheetData.city !== null &&
          this.dataService.BottomSheetData.city !== "undefined"
        ) {
          Object.assign(this.addressObj, {
            city: this.dataService.BottomSheetData.city,
          });
          this.updateCompanyInfo.controls.city.setValue(
            this.dataService.BottomSheetData.city
          );
        }
        if (
          this.dataService.BottomSheetData.phoneNumber &&
          this.dataService.BottomSheetData.phoneNumber !== null &&
          this.dataService.BottomSheetData.phoneNumber !== "undefined"
        ) {
          Object.assign(this.addressObj, {
            phoneNumber: this.dataService.BottomSheetData.phoneNumber,
          });
          this.updateCompanyInfo.controls.phoneNumber.setValue(
            this.dataService.BottomSheetData.phoneNumber
          );
        }
      }

      this.updateCompanyInfo.get("countryCode").setValue(this.selectedValue);
      this.updateCompanyInfo.get("stateCode").setValue(this.selectedState);
    } else if (
      this.auth.companyInfo &&
      this.dataService.rightSideDrawerName !== "addCompanyOtherAddress" &&
      this.dataService.BottomSheetName !== "addCompanyOtherAddress"
    ) {
      this.updateCompanyInfo.controls.displayName.setValue(
        this.auth.companyInfo.name
      );

      if (this.auth.companyInfo.annualTravelSpend) {
        this.selectedTravelSpend = this.auth.companyInfo.annualTravelSpend;
      }
      this.updateCompanyInfo.controls.annualTravelSpend.setValue(
        this.selectedTravelSpend
      );
      if (this.auth.companyInfo.legalCompanyName) {
        this.updateCompanyInfo.controls.legalCompanyName.setValue(
          this.auth.companyInfo.legalCompanyName
        );
      }
      if (this.auth.companyInfo.taxNumber) {
        this.updateCompanyInfo.controls.vatNumber.setValue(
            this.auth.companyInfo.taxNumber
        );
      }
      if (this.auth.companyInfo.numberOfEmployees) {
        const getNUmber = this.vars.numberOfEmployeesArray.filter(
          (item) => item.id === this.auth.companyInfo.numberOfEmployees.trim()
        );
        this.selectedNumberOfEmployees = getNUmber[0]?.value;
      }
      this.updateCompanyInfo.controls.numberOfEmployees.setValue(
        this.selectedNumberOfEmployees
      );
      if (this.auth.companyInfo.industry) {
        this.selectedIndustry = this.auth.companyInfo.industry;
      }
      this.updateCompanyInfo.controls.industry.setValue(this.selectedIndustry);
      if (this.auth.companyInfo.address) {
        if (this.auth.companyInfo.address.name) {
          Object.assign(this.addressObj, {
            name: this.auth.companyInfo.address.name,
          });
          this.updateCompanyInfo.controls.mainAddressName.setValue(
            this.auth.companyInfo.address.name
          );
        }
        if (this.auth.companyInfo.address.address1) {
          Object.assign(this.addressObj, {
            address1: this.auth.companyInfo.address.address1,
          });
          this.updateCompanyInfo.controls.address1.setValue(
            this.auth.companyInfo.address.address1
          );
        }
        if (this.auth.companyInfo.address.address2) {
          Object.assign(this.addressObj, {
            address2: this.auth.companyInfo.address.address2,
          });
          this.updateCompanyInfo.controls.address2.setValue(
            this.auth.companyInfo.address.address2
          );
        }
        if (
          this.auth.companyInfo.address.countryCode &&
          this.auth.companyInfo.address.countryCode !== "undefined"
        ) {
          this.selectedValue = this.auth.companyInfo.address.countryCode;
          Object.assign(this.addressObj, {
            countryCode: this.auth.companyInfo.address.countryCode,
          });
        }

        if (
          this.auth.companyInfo.address.stateCode &&
          this.auth.companyInfo.address.stateCode !== null &&
          this.auth.companyInfo.address.stateCode !== "undefined" &&
          this.auth.userInfo.address.stateCode !== ""
        ) {
          Object.assign(this.addressObj, {
            stateCode: this.auth.companyInfo.address.stateCode,
          });
          this.selectedState = this.auth.companyInfo.address.stateCode;
        }
        if (
          this.auth.companyInfo.address.zipCode &&
          this.auth.companyInfo.address.zipCode !== null &&
          this.auth.companyInfo.address.zipCode !== "undefined"
        ) {
          Object.assign(this.addressObj, {
            zipCode: this.auth.companyInfo.address.zipCode,
          });
          this.updateCompanyInfo.controls.zipCode.setValue(
            this.auth.companyInfo.address.zipCode
          );
        }
        if (
          this.auth.companyInfo.address.city &&
          this.auth.companyInfo.address.city !== null &&
          this.auth.companyInfo.address.city !== "undefined"
        ) {
          Object.assign(this.addressObj, {
            city: this.auth.companyInfo.address.city,
          });
          this.updateCompanyInfo.controls.city.setValue(
            this.auth.companyInfo.address.city
          );
        }
        if (
          this.auth.companyInfo.address.phoneNumber &&
          this.auth.companyInfo.address.phoneNumber !== null &&
          this.auth.companyInfo.address.phoneNumber !== "undefined"
        ) {
          Object.assign(this.addressObj, {
            phoneNumber: this.auth.companyInfo.address.phoneNumber,
          });
          this.updateCompanyInfo.controls.phoneNumber.setValue(
            this.auth.companyInfo.address.phoneNumber
          );
        }
      }
      this.updateCompanyInfo.get("countryCode").setValue(this.selectedValue);
      this.updateCompanyInfo.get("stateCode").setValue(this.selectedState);
    }
  }
  get c() {
    return this.updateCompanyInfo.controls;
  }
  submitAccountUpdate() {
    this.submitted = true;
    this.submitting = true;
    if (this.updateCompanyInfo.invalid) {
      this.dataService.scrollTop();
      this.submitting = false;
      return;
    }
    this.companyFormData =
      "?name=" + this.updateCompanyInfo.controls.displayName.value;
    if (this.selectedTravelSpend) {
      this.companyFormData += "&annualTravelSpend=" + this.selectedTravelSpend;
    }
    if (this.selectedNumberOfEmployees) {
      this.companyFormData +=
        "&numberOfEmployees=" + this.selectedNumberOfEmployees;
    }
    if (this.selectedIndustry) {
      this.companyFormData += "&industry=" + this.selectedIndustry;
    }
    if (
      this.updateCompanyInfo.controls.legalCompanyName.value &&
      this.updateCompanyInfo.controls.legalCompanyName.value !== ""
    ) {
      this.companyFormData +=
        "&legalCompanyName=" +
        this.updateCompanyInfo.controls.legalCompanyName.value;
    }
    if (
        this.updateCompanyInfo.controls.vatNumber.value &&
        this.updateCompanyInfo.controls.vatNumber.value !== ""
    ) {
      this.companyFormData +=
          "&taxNumber=" +
          this.updateCompanyInfo.controls.vatNumber.value;
    }
    this.dataService.updateCompany(this.companyFormData).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.getCompanyNewInfo();
          this.dataService.rightSideDrawerOff();
          this.dataService.BottomSheetOf();
        } else {
          this.dataService.messages(this.data.message, "error");
          this.dataService.scrollTop();
          this.submitting = false;
        }
      },
      (error) => {
        this.dataService.messages(environment.messages.error, "error");
        this.dataService.scrollTop();
        this.submitting = false;
      }
    );
  }
  updateCompanyAddress(action) {
    this.submitted = true;
    this.submitting = true;
    this.addressRequired = false;
    this.mainAddressNameRequired = false;
    this.mainAddressNameErrorRequiredMsg = "";
    this.addressErrorRequiredMsg = "";
    this.sendAddressData = "";
    this.addressFormData = [];
    if (
      this.updateCompanyInfo.controls.address1.value &&
      this.updateCompanyInfo.controls.mainAddressName.value.trim() !== ""
    ) {
      this.addressFormData.push({
        param: "name",
        value: this.updateCompanyInfo.controls.mainAddressName.value,
      });
    }
    if (
      this.updateCompanyInfo.controls.address1.value &&
      this.updateCompanyInfo.controls.address1.value.trim() !== ""
    ) {
      this.addressFormData.push({
        param: "address1",
        value: this.updateCompanyInfo.controls.address1.value,
      });
    }
    if (
      this.updateCompanyInfo.controls.address2.value &&
      this.updateCompanyInfo.controls.address2.value.trim() !== ""
    ) {
      this.addressFormData.push({
        param: "address2",
        value: this.updateCompanyInfo.controls.address2.value,
      });
    }
    if (
      this.updateCompanyInfo.controls.city.value &&
      this.updateCompanyInfo.controls.city.value.trim() !== ""
    ) {
      this.addressFormData.push({
        param: "city",
        value: this.updateCompanyInfo.controls.city.value,
      });
    }
    if (
      this.updateCompanyInfo.controls.zipCode.value &&
      this.updateCompanyInfo.controls.zipCode.value.trim() !== ""
    ) {
      this.addressFormData.push({
        param: "zipCode",
        value: this.updateCompanyInfo.controls.zipCode.value,
      });
    }
    if (this.selectedValue) {
      this.addressFormData.push({
        param: "countryCode",
        value: this.selectedValue,
      });
    }
    if (
      this.selectedState &&
      this.selectedValue === "US" &&
      this.selectedState
    ) {
      this.addressFormData.push({
        param: "stateCode",
        value: this.selectedState,
      });
    }
    if (
      this.updateCompanyInfo.controls.phoneNumber.value &&
      this.updateCompanyInfo.controls.phoneNumber.value.trim() !== ""
    ) {
      this.addressFormData.push({
        param: "phoneNumber",
        value: encodeURIComponent(
          this.updateCompanyInfo.controls.phoneNumber.value
        ),
      });
    }
    if (
      this.addressFormData.length ||
      this.dataService.rightSideDrawerName === "addCompanyOtherAddress" ||
      this.dataService.BottomSheetName === "addCompanyOtherAddress"
    ) {
      if (
        !this.updateCompanyInfo.controls.mainAddressName.value ||
        this.updateCompanyInfo.controls.mainAddressName.value.trim() === "" ||
        !this.updateCompanyInfo.controls.address1.value ||
        this.updateCompanyInfo.controls.address1.value.trim() === ""
      ) {
        if (
          !this.updateCompanyInfo.controls.mainAddressName.value ||
          this.updateCompanyInfo.controls.mainAddressName.value.trim() === ""
        ) {
          this.mainAddressNameRequired = true;
          this.mainAddressNameErrorRequiredMsg = $localize`:@@officeNameRequired:Office name is required`;
        }
        if (
          !this.updateCompanyInfo.controls.address1.value ||
          this.updateCompanyInfo.controls.address1.value.trim() === ""
        ) {
          this.addressRequired = true;
          this.addressErrorRequiredMsg = $localize`:@@address1Required:Address1 is required`;
        }
        this.submitting = false;
        return;
      }
      this.addressFormData.forEach((element, index) => {
        if (index === 0) {
          this.sendAddressData = "?" + element.param + "=" + element.value;
        } else {
          this.sendAddressData += "&" + element.param + "=" + element.value;
        }
      });
      if (this.dataService.rightSideDrawerData) {
        this.sendAddressData +=
          "&id=" + this.dataService.rightSideDrawerData.id;
      } else if (this.dataService.BottomSheetData) {
        this.sendAddressData += "&id=" + this.dataService.BottomSheetData.id;
      }
      this.dataService
        .companyAddressAction(action, this.sendAddressData)
        .subscribe(
          (data) => {
            this.data = data;
            if (this.data.errorCode === 0) {
              if (
                action === "editCompanyOtherAddress" ||
                action === "addCompanyOtherAddress"
              ) {
                this.dataService.getAllAddressesFun();
              } else {
                this.getCompanyNewInfo();
              }
              this.dataService.rightSideDrawerOff();
              this.dataService.BottomSheetOf();
            } else {
              this.dataService.messages(this.data.message, "error");
              this.submitting = false;
            }
          },
          (error) => {
            this.dataService.messages(environment.messages.error, "error");
            this.submitting = false;
          }
        );
    } else {
      this.submitting = false;
      if (
        action === "editCompanyOtherAddress" ||
        action === "addCompanyOtherAddress"
      ) {
        this.dataService.getAllAddressesFun();
      } else {
        this.getCompanyNewInfo();
      }
      this.router.navigate([this.router.url.replace("edit", "")]);
    }
  }
  getCompanyNewInfo() {
    this.dataService.getCompanyInfo().subscribe(
      (suc) => {
        this.data = suc;
        if (this.data.errorCode === 0) {
          this.auth.companyInfo = {
            id: this.data.data.id,
            domain: this.data.data.domain,
            name: this.data.data.name,
            inactive: this.data.data.inactive,
            ownerUserId: this.data.data.ownerUserId,
            logoUrl: this.data.data.logoUrl,
            numberOfEmployees: this.data.data.numberOfEmployees,
            annualTravelSpend: this.data.data.annualTravelSpend,
            legalCompanyName: this.data.data.legalCompanyName,
            industry: this.data.data.industry,
            address: this.data.data.address,
            paymentMethods: this.data.data.paymentMethods,
            rewardProgram: this.data.data.rewardProgram,
            systemPreferences: this.data.data.systemPreferences,
            companySubscriptionType: this.data.data.companySubscriptionType ? this.data.data.companySubscriptionType : '',
            taxNumber: this.data.data.taxNumber
          };
          localStorage.setItem(
            "companyInfo",
            JSON.stringify(this.auth.companyInfo)
          );
          this.submitted = false;
          this.submitting = false;
        }
      }
    );
  }
  setSelectedValue() {
    this.selectedValue = this.updateCompanyInfo.get("countryCode").value;
  }
  setSelectedState() {
    this.selectedState = this.updateCompanyInfo.get("stateCode").value;
  }
  setSelectedIndustry() {
    this.selectedIndustry = this.updateCompanyInfo.get("industry").value;
  }
  setSelectedTravelSpend() {
    this.selectedTravelSpend =
      this.updateCompanyInfo.get("annualTravelSpend").value;
  }
  setSelectedNumberOfEmployees() {
    this.selectedNumberOfEmployees =
      this.updateCompanyInfo.get("numberOfEmployees").value;
  }
}
