import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { GoogleAnalyticsService } from "../google-analytics.service";
import {BehaviorSubject, Subject} from "rxjs";
import { User } from "./user.model";
import { environment } from "../../environments/environment";
import { DataService } from "../services/data.service";
import { VariablesService } from "../services/vars.service";

declare let fbq: any;

@Injectable({ providedIn: "root" })
export class AuthServiceMain {
  token;
  data;
  OldPrime = false;
  datas;
  LeadEmail;
  userInfo;
  companyInfo;
  Stoken;
  userPrime;
  isLead = false;
  isPrime = false;
  isNewsLetter = false;
  address = {};
  userToken;
  submitting;
  errorMsg;
  apiUrl;
  Semail = "";
  isLogedIn = false;
  user = new BehaviorSubject<User>(null);
  inviterReferralProgram;
  destination = "destination";
  upcomingBookingObj = {
    bookingStatus: "CONFIRMED",
    includeUpcoming: true,
    includePast: false,
  };
  dataUpcoming;
  loginURL;
  logoutURL;
  urlArray;
  userDataLoaded: Subject<boolean> = new Subject();
  companyDataLoaded: Subject<boolean> = new Subject();
  constructor(
    private http: HttpClient,
    public router: Router,
    public restApi: DataService,
    public route: ActivatedRoute,
    public googleAnalyticsService: GoogleAnalyticsService,
    public VarsService: VariablesService
  ) {}

  login(email: string, password: string) {
    email = email.toLowerCase();
    this.LeadEmail = email;
      this.loginURL = `${environment.apiBase}${this.VarsService.apiUrls.companyLogin}`;

    const body = new HttpParams().set("email", email).set("password", password);
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );

    return this.http.post(this.loginURL, body, { headers }).pipe(
      tap(
        (resData) => {
          this.data = resData;
          if (this.data.errorCode === 0) {
            if (
              this.restApi.urlWithoutParams.includes(
                "best-hotel-rates-newsletter/join"
              )
            ) {
              this.googleAnalyticsService.eventEmitter(
                "Newsletter-Subscription",
                "Sign-in",
                "Success",
                1
              );
              this.restApi.emailNewsLetter(this.LeadEmail, "").subscribe();
            }
            if (
              this.restApi.urlWithoutParams.includes(
                "free-lifebox-promotion/join"
              )
            ) {
              this.googleAnalyticsService.eventEmitter(
                "Pass-Lifebox-promo",
                "Sign-in",
                "Success",
                1
              );
            }
            if (
              this.restApi.urlWithoutParams.includes(
                "pass/free-trial-subscription"
              )
            ) {
              this.googleAnalyticsService.eventEmitter(
                "Free-Trial-Subscription",
                "Sign-in",
                "Success",
                1
              );
            }
            this.handleAuthentication(
              (this.token = this.data.data.token.value)
            );
          } else {
            if (
              this.restApi.urlWithoutParams.includes(
                "best-hotel-rates-newsletter/join"
              )
            ) {
              this.googleAnalyticsService.eventEmitter(
                "Newsletter-Subscription",
                "Sign-in",
                "Failed",
                1
              );
            }
            if (
              this.restApi.urlWithoutParams.includes(
                "free-lifebox-promotion/join"
              )
            ) {
              this.googleAnalyticsService.eventEmitter(
                "Pass-Lifebox-promo",
                "Sign-in",
                "Failed",
                1
              );
            }
            if (
              this.restApi.urlWithoutParams.includes(
                "pass/free-trial-subscription"
              )
            ) {
              this.googleAnalyticsService.eventEmitter(
                "Free-Trial-Subscription",
                "Sign-in",
                "Failed",
                1
              );
            }
          }
        },
        (error) => {
          if (
            this.restApi.urlWithoutParams.includes(
              "best-hotel-rates-newsletter/join"
            )
          ) {
            this.googleAnalyticsService.eventEmitter(
              "Newsletter-Subscription",
              "Sign-in",
              "Failed",
              1
            );
          }
          if (this.restApi.urlWithoutParams.includes("pass/login")) {
            this.googleAnalyticsService.eventEmitter(
              "Free-Trial-Subscription",
              "Sign-in",
              "Failed",
              1
            );
          }
        }
      )
    );
  }

  signUp(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    ReferralProgram: string,
    refererId: string
  ) {
    email = email.toLowerCase();
    this.LeadEmail = email;
    let body = new HttpParams();
    body = body.append("email", email);
    body = body.append("password", password);
    if (ReferralProgram !== "") {
      body = body.append("refId", ReferralProgram);
    }

    if (refererId !== "") {
      body = body.append("refererId", refererId);
    }

    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );

    return this.http
      .post(
        `${environment.apiBase}${this.VarsService.apiUrls.signUp}` +
          "?firstName=" +
          firstName +
          "&lastName=" +
          lastName,
        body,
        { headers }
      )
      .pipe(
        tap((resData) => {
          this.data = resData;
          if (this.data.errorCode === 0) {
            this.googleAnalyticsService.eventEmitter(
              "EventStatus",
              "Signup",
              "SuccessfulEmailSignup",
              1
            );

            if (
              this.restApi.urlWithoutParams.includes(
                "best-hotel-rates-newsletter/join"
              )
            ) {
              this.googleAnalyticsService.eventEmitter(
                "Newsletter-Subscription",
                "Signup",
                "Email",
                1
              );
              /*  fbq('track', 'Pass-Newsletter-Subscription-Successful');*/
            }
            if (this.restApi.urlWithoutParams.includes("pass/signup")) {
              this.googleAnalyticsService.eventEmitter(
                "Free-Trial-Subscription",
                "Signup",
                "Email",
                1
              );
            }

            if (
              this.restApi.urlWithoutParams.includes(
                "pass/free-lifebox-promotion"
              )
            ) {
              this.googleAnalyticsService.eventEmitter(
                "Pass-Lifebox-promo",
                "Signup",
                "Email",
                1
              );
            }

            this.handleAuthentication(
              (this.token = this.data.data.token.value)
            );
          }
        })
      );
  }

  removeUser() {
    this.user.next(null);
    localStorage.removeItem("user");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("companyInfo");
    localStorage.removeItem("workspaceApprovalsBadge");
    localStorage.removeItem("isLeftMenuOpen");
    localStorage.removeItem("isLoggedin");
    sessionStorage.removeItem("OldPrime");
    sessionStorage.removeItem("primeInfo");
    sessionStorage.removeItem("isPrime");
    sessionStorage.removeItem("isNewsLetter");
    sessionStorage.removeItem("defaultTeamInfo");
    sessionStorage.removeItem("PreferedDestinations");
    sessionStorage.removeItem("homeCity");
    this.restApi.userHasTravelPref = false;
    this.restApi.inactiveWorkspace = false;
    this.restApi.pendingArrayUsers = [];
    this.restApi.filteredPending = [];
    this.autoLogin();
    this.isLogedIn = false;
    this.userInfo = {};
    this.companyInfo = {};
    this.userPrime = null;
    this.restApi.closeMenu();
    if (!this.isLogedIn && this.router.url.includes("profile")) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/login"]);
    }
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    //this.restApi.tripsBadge = 0;
    sessionStorage.removeItem("primeInfo");
    this.isPrime = false;
    this.OldPrime = false;
    this.isNewsLetter = false;
    localStorage.clear();
    sessionStorage.clear();
  }

  logout() {
    if (this.restApi.isCompany()) {
      this.urlArray = this.router.url.split("/");
      this.logoutURL = `${environment.apiBase}${this.VarsService.apiUrls.companyLogout}`;
    } else {
      this.logoutURL = `${environment.apiBase}${this.VarsService.apiUrls.logOut}`;
    }
    if (this.isLogedIn) {
      this.http.post(this.logoutURL, "").subscribe(
        (suc) => {
          this.removeUser();
          this.restApi.callComponentMethod();
        },
        (err) => {}
      );
    }
  }


  autoLogin() {


    const userDataMain = localStorage.getItem("user");

    if (localStorage.getItem("leads_email") || userDataMain !== null) {
      this.isLead = true;

      this.LeadEmail = localStorage.getItem("leads_email");
    } else {
      this.isLead = false;
    }
    if (!userDataMain) {
      return;
    }
    const loadedUser = new User(userDataMain);

    if (loadedUser.token) {
      this.isPrime = false;
      this.OldPrime = false;
      this.isNewsLetter = false;

      if (
        localStorage.getItem("userInfo") &&
        Array.isArray(JSON.parse(localStorage.getItem("userInfo"))) === false
      ) {
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (sessionStorage.getItem("primeInfo")) {
          this.userPrime = JSON.parse(sessionStorage.getItem("primeInfo"));
          if (sessionStorage.getItem("isPrime")) {
            this.isPrime = true;
          }
          if (sessionStorage.getItem("OldPrime")) {
            this.OldPrime = true;
          }
        } else if (!sessionStorage.getItem("UserLogedIn")) {
          this.fetchUserData();
          sessionStorage.setItem("UserLogedIn", "true");
        }
      } else {
        this.fetchUserData();
      }
      if (
        localStorage.getItem("companyInfo") &&
        Array.isArray(JSON.parse(localStorage.getItem("companyInfo"))) === false
      ) {
        this.companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
      } else {
        if (this.restApi.isCompany()) {
          this.fetchCompanyData();
        }
      }
      this.isLogedIn = true;

      if (sessionStorage.getItem("isNewsLetter")) {
        this.isNewsLetter = true;
      }

    }
  }



  navigateUserToDestination(){
    if (this.router.url.indexOf("destination") > 0) {
      const destination = this.route.snapshot.queryParams['destination'];

      console.log(destination);
      debugger
        if (destination) {


          const encodedURL = decodeURIComponent(destination);
          // if url has parameters
          if (encodedURL.includes("?")) {
            const urlWithoutParams = encodedURL.substring(
                0,
                encodedURL.indexOf("?")
            );
            const onlyParametersUrl = encodedURL.substring(
                encodedURL.indexOf("?") + 1,
                encodedURL.length
            );
            const parametersArray = onlyParametersUrl.split("&");
            const queryParamsObj = {};
            parametersArray.forEach((item) => {
              const keyName = item.substring(0, item.indexOf("="));
              const kayValue = item.substring(
                  item.indexOf("=") + 1,
                  item.length
              );
              queryParamsObj[keyName] = kayValue;
            });
            this.router.navigate([urlWithoutParams], {
              queryParams: queryParamsObj,
            });
          } else {

            this.router.navigate([destination]);
          }

        }

    }
  }

  handleAuthentication(token: string) {
    const user = new User(token);
    this.user.next(user);
    this.userToken = user;
    this.inviterReferralProgram =
      this.restApi.referralPrograms.inviterReferralProgram.substr(
        this.restApi.referralPrograms.inviterReferralProgram.indexOf(":") + 2,
        this.restApi.referralPrograms.inviterReferralProgram.length - 1
      );
    localStorage.setItem("inviterReferralProgram", this.inviterReferralProgram);

    localStorage.setItem("user", user.token);
    localStorage.setItem("isLoggedin", "true");
    localStorage.setItem("isLeftMenuOpen", "true");
    this.autoLogin();
    if (this.restApi.modalOn && !this.restApi.flowFromPopUp) {
      this.restApi.modalOf();
    } else {
      this.restApi.flowFromPopUpCount++;
      this.restApi.flowFromPopUpCountFunc();
    }
    if (this.router.url === "/slack/login") {
      const slackState = sessionStorage.getItem("state");
      this.router.navigate(["/slack/connect"], {
        queryParams: { state: slackState },
      });
      return;
    }
    if (
      !sessionStorage.getItem("PrevURLLogin") &&
      this.restApi.mobileView &&
      this.restApi.urlWithoutParams.includes("/voucher") &&
      !this.restApi.urlWithoutParams.includes("hotel-rates-newsletter/join") &&
      !this.restApi.urlWithoutParams.includes("pass/login")
    ) {
      this.router.navigate(["/"]);
    }

    if (
      (this.router.url === "/user/login" ||
        this.router.url === "/user/register") &&
      this.restApi.desktopView
    ) {
      this.router.navigate(["/"]);
    }
    this.urlArray = this.router.url.split("/");
    if (
      this.restApi.isCompany() &&
      this.urlArray[this.urlArray.length - 1] === "login"
    ) {
      if (this.urlArray[this.urlArray.length - 2] === "slack") {
        this.router.navigate(["/" + "/slack/connect"]);
      } else {
        this.router.navigate(["/"]);
      }
    }
  }

  public fetchUserData() {
    this.restApi.getUserInfo().subscribe(
      (suc) => {
        this.data = suc;
        if (this.data.errorCode === 0) {
          this.address = {};

          if (this.data.data.address) {
            this.address = {
              address1: this.data.data.address.address1,
              address2: this.data.data.address.address2,
              cityName: this.data.data.address.city,
              zipCode: this.data.data.address.zipCode,
              countryCode: this.data.data.address.countryCode,
              stateCode: this.data.data.address.stateCode,
              countryName: this.data.data.address.countryName,
            };
          }
          const travelPreferencesArray = [];
          if (this.data.data.personalizationSettings) {
            for (let i in this.data.data.personalizationSettings) {
              travelPreferencesArray.push(
                this.data.data.personalizationSettings[i]
              );
            }
          }

            this.userInfo = {
              name: this.data.data.firstName,
              imageUrl: this.data.data.profileImage,
              id: this.data.data.id,
              lastName: this.data.data.lastName,
              email: this.data.data.email,
              phoneNumber: this.data.data.phoneNumber,
              phoneNumberCountryCode: this.data.data.phoneNumberCountryCode,
              creationDate: this.data.data.creationDate,
              frequentFlyerPrograms: this.data.data.frequentFlyerPrograms,
              travelDocuments: this.data.data.travelDocuments,
              gender: this.data.data.gender,
              birthDate: this.data.data.birthDate,
              address: this.address,
              inactive: this.data.data.inactive,
              role: this.data.data.role,
              policyId: this.data.data.policyId,
              teamId: this.data.data.teamId,
              teamName: this.data.data.teamName,
              effectivePolicyId: this.data.data.effectivePolicyId,
              effectivePolicyName: this.data.data.effectivePolicyName,
              companyCards: this.data.data.companyCards,
              profileImageBackgroundHexColor:
                this.data.data.profileImageBackgroundHexColor,
              personalPaymentMethodsDisabled:
                this.data.data.personalPaymentMethodsDisabled,
              rewardWallet: this.data.data.rewardWallet,
            };
            this.userDataLoaded.next(true); // Emit value when data is fetched


          this.restApi.userProfileImageWithRandomText =
            this.userInfo.imageUrl + "?" + new Date().getTime();

          localStorage.setItem("leads_email", this.data.data.email);

          localStorage.removeItem("userInfo");

          localStorage.setItem("userInfo", JSON.stringify(this.userInfo));

          if (this.restApi.isCompany()) {
            this.fetchCompanyData();
          }
          if (
            sessionStorage.getItem("PrevURLLogin") &&
            this.restApi.mobileView
          ) {
            this.router.navigateByUrl(sessionStorage.getItem("PrevURLLogin"));

            sessionStorage.removeItem("PrevURLLogin");
          }
          if (sessionStorage.getItem("PrimeURLLogin")) {
            this.router.navigateByUrl(sessionStorage.getItem("PrimeURLLogin"));

            sessionStorage.removeItem("PrimeURLLogin");
          }

          sessionStorage.removeItem("OldPrime");

          sessionStorage.removeItem("primeInfo");

          if (!this.restApi.isCompany() && this.data.data.membership.prime) {
            this.OldPrime = false;
            this.isPrime = true;
            sessionStorage.setItem("isPrime", "true");
          } else {
            this.isPrime = false;
          }

          if (
            !this.restApi.isCompany() &&
            this.data.data.membership.primeMembership
          ) {
            let userPrime: any = this.data.data.membership.primeMembership;
            userPrime.paymentMethods = this.data.data.membership.paymentMethods;
            sessionStorage.setItem("primeInfo", JSON.stringify(userPrime));
            this.userPrime = userPrime;
          }

          if (this.data.data.newsletterSubscribed) {
            this.OldPrime = false;
            this.isNewsLetter = true;
            sessionStorage.setItem("isNewsLetter", "true");
          } else {
            this.isNewsLetter = false;
          }

          if (
            !this.restApi.isCompany() &&
            this.data.data.membership.primeMembership &&
            !this.data.data.membership.prime
          ) {
            sessionStorage.setItem("OldPrime", "true");

            this.OldPrime = true;
          }

          this.restApi.callComponentMethod();
        } else {
          this.logout();
        }
      },
      (err) => {
        this.logout();
      }
    );
  }

  public isManager() {
    return (
      this.userInfo &&
      this.userInfo.role &&
      this.userInfo.role.toLowerCase() === "team_manager"
    );
  }
  public isAdmin() {
    return (
      this.userInfo &&
      this.userInfo.role &&
      this.userInfo.role.toLowerCase() === "admin"
    );
  }
  public isOwner() {
    return (
      this.userInfo &&
      this.userInfo.role &&
      this.userInfo.role.toLowerCase() === "owner"
    );
  }

  checkPagePermission() {
    this.urlArray = this.router.url.split("/");
    if (
      (this.urlArray[this.urlArray.length - 1] === "approvals" ||
        this.router.url.includes("/approvals/")) &&
      !this.isManager() &&
      !this.isAdmin() &&
      !this.isOwner()
    ) {
      this.removeUser();
    } else if (
      (this.urlArray[this.urlArray.length - 1] === "members" ||
        this.router.url.includes("/members/")) &&
      !this.isAdmin() &&
      !this.isOwner()
    ) {
      this.removeUser();
    } else if (
      (this.urlArray[this.urlArray.length - 1] === "teams" ||
        this.router.url.includes("/teams/")) &&
      !this.isManager() &&
      !this.isAdmin() &&
      !this.isOwner()
    ) {
      this.removeUser();
    } else if (
      (this.urlArray[this.urlArray.length - 1] === "travel-policies" ||
        this.router.url.includes("/travel-policies/")) &&
      !this.isAdmin() &&
      !this.isOwner()
    ) {
      this.removeUser();
    } else if (
      this.urlArray[this.urlArray.length - 1] === "reports" &&
      !this.isAdmin() &&
      !this.isOwner()
    ) {
      this.removeUser();
    } else if (
      (this.urlArray[this.urlArray.length - 1] === "settings" ||
        this.router.url.includes("/settings/")) &&
      !this.isAdmin() &&
      !this.isOwner()
    ) {
      this.removeUser();
    }
  }

  public fetchCompanyData() {
    this.restApi.getCompanyInfo().subscribe(
      (suc) => {
        this.data = suc;
        if (this.data.errorCode === 0) {
          this.companyInfo = {
            id: this.data.data.id,
            domain: this.data.data.domain,
            name: this.data.data.name,
            inactive: this.data.data.inactive,
            ownerUserId: this.data.data.ownerUserId,
            logoUrl: this.data.data.logoUrl,
            numberOfEmployees: this.data.data.numberOfEmployees,
            annualTravelSpend: this.data.data.annualTravelSpend,
            legalCompanyName: this.data.data.legalCompanyName,
            industry: this.data.data.industry,
            address: this.data.data.address,
            paymentMethods: this.data.data.paymentMethods,
            rewardProgram: this.data.data.rewardProgram,
            systemPreferences: this.data.data.systemPreferences,
            companySubscriptionType: this.data.data.companySubscriptionType ? this.data.data.companySubscriptionType : '',
            taxNumber: this.data.data.taxNumber
          };
          localStorage.removeItem("companyInfo");
          localStorage.setItem("companyInfo", JSON.stringify(this.companyInfo));
          this.navigateUserToDestination();

          this.companyDataLoaded.next(true); // Emit value when data is fetched
          if (
            !sessionStorage.getItem("defaultTeamInfo") &&
            this.companyInfo &&
            this.companyInfo.inactive === false &&
            (this.isManager() || this.isAdmin() || this.isOwner())
          ) {
            this.restApi.getTeamDetails(0).subscribe(
              (data) => {
                this.data = data;
                if (this.data.errorCode === 0) {
                  sessionStorage.setItem(
                    "defaultTeamInfo",
                    JSON.stringify(this.data.data)
                  );
                } else {
                  this.restApi.messages(this.data.message, "error");
                }
              },
              (error) => {
                this.restApi.messages(environment.messages.error, "error");
              }
            );
          }
          this.restApi.callComponentMethod();
        }
      }
    );
  }

}
