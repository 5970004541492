import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {VariablesService} from "./vars.service";
import {Router} from "@angular/router";
import {DataService} from "./data.service";
@Injectable({
    providedIn: 'root'
})
export class UserService {
    imageHasError: boolean = false;
    fallbackSrc = 'assets/img/default-user.png';
    constructor() {
    }



}











