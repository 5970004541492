import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import * as moment from 'moment';
import {AuthServiceMain} from '../../auth/auth.service';
import {environment} from "../../../environments/environment";
import {Title} from "@angular/platform-browser";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-manage-booking',
  templateUrl: './manage-booking.component.html',
  styleUrls: ['./manage-booking.component.scss']
})
export class ManageBookingComponent implements OnInit {
  retrieveForm: FormGroup;
  feedbackForm: FormGroup;
  submitted = false;
  data;
  dataCancel;
  invalidEmail = false;
  errorMsg = '';
  atpos ;
  dotpos ;
  type = 'LAST_NAME';
  retrieveObj ;

  booking;
  bookingType;
  ratingText;
  ratingValues = [1, 2, 3, 4, 5];
  feedback = {};
  feedbackSubmittedWithErrors = false;
  feedbackSubmitted = false;
  feedbackPosted;
  refFromURL = 'ref';
  refFromURLValue;
  identifierFromURL = 'identifier';
  identifierFromURLValue;
  feedbackComment;
  sendRating;
  email;
  lastName;
  hideForm;
  checkin ;
  checkout ;
  nights;
  allowCancellation = false;
  facilities;
  mapNearBy = {};
  submitting = false;
  submittingFeedback = false;

  constructor(public datePipe: DatePipe, private titleService: Title, private http: HttpClient, public  router: Router,
              private formBuilder: FormBuilder, public restApi: DataService,
              private route: ActivatedRoute,  private logedIn: AuthServiceMain) {
    this.titleService.setTitle('My trip details | SafarHub');
    this.restApi.componentMethodCalled$.subscribe(
      () => {

        if(this.logedIn.isLogedIn && this.restApi.isCompany() && this.logedIn.companyInfo && this.logedIn.companyInfo.inactive) {
          this.restApi.inactiveWorkspace = true;
        }

        if(this.logedIn.isLogedIn && this.restApi.isCompany() && this.logedIn.companyInfo && this.logedIn.companyInfo.inactive === false) {
          this.restApi.showExpensifyInBooking();
        }
      });

  }
  ngOnInit() {
    this.restApi.showHotelPrint = false;
    this.restApi.newRate = null;

    this.restApi.alerts = [];
    this.restApi.alertsOverRoute = [];
    if( this.restApi.sendVoucherMsg !== ''){
      this.restApi.messages(this.restApi.sendVoucherMsg, 'success');
      this.restApi.sendVoucherMsg = '';
    }
    this.retrieveForm = this.formBuilder.group({
      refNumber: ['', Validators.required],
      lastNameOrEmail: ['', Validators.required]
    }, {
    });

    this.feedbackForm = this.formBuilder.group({
      reviewSubject: ['', Validators.required],
      reviewComment: ['', Validators.required],
      rating: ['', Validators.required]
    }, {
    });

    this.route.queryParams.subscribe(params => {
      this.refFromURLValue =  params[this.refFromURL];
      this.identifierFromURLValue =  params[this.identifierFromURL];
    });
    if ( this.refFromURLValue !== null &&  this.identifierFromURLValue !== null && this.refFromURLValue !== undefined &&  this.identifierFromURLValue !== undefined) {
      this.restApi.useForm = false;


      if (this.identifierFromURLValue.indexOf('@') > -1) {
        this.retrieveObj = {
          customerIdentifier: {
            type: 'EMAIL',
            value: this.identifierFromURLValue
          },
          referenceNumber: this.refFromURLValue
        };
      } else{
        this.retrieveObj = {
          customerIdentifier: {
            type: 'LAST_NAME',
            value: this.identifierFromURLValue
          },
          referenceNumber: this.refFromURLValue
        };
      }
      this.getBookingDetails(this.retrieveObj);
    }
  }
cancel() {
    if (this.restApi.isCompany()) {
      this.router.navigate(['/hotel/booking/cancel'],
        { queryParams: { ref: this.refFromURLValue, identifier: this.identifierFromURLValue } });
    } else {
      this.router.navigate(['/bookings/cancel'],
        { queryParams: { ref: this.refFromURLValue, identifier: this.identifierFromURLValue } });
    }
}
 /* formatDailyDate(date) {
    return this.datePipe.transform(date, 'EE MMM d, y');
  }*/
 /* changeRatingText(n) {
    switch (n) {
      case 1:
        this.ratingText = 'Terrible';
        break;
      case 2:
        this.ratingText = 'Poor';
        break;
      case 3:
        this.ratingText = 'Average';
        break;
      case 4:
        this.ratingText = 'Good';
        break;
      case 5:
        this.ratingText = 'Excellent';
        break;
      default:
      case 4:
        this.ratingText = '';
        break;
    }
  }*/
  get f() { return this.retrieveForm.controls; }
  get ctrl() { return this.feedbackForm.controls; }

  print() {
    setTimeout(() => {
      window.print();
    }, 2000);


  }
  mapPopUp() {
    this.mapNearBy = {
      latitude: this.booking.hotelLatitude,
      longitude: this.booking.hotelLongitude
    };
    this.restApi.setModal( this.booking.hotelName, 'hotelMap', this.mapNearBy);
  }
  shareByEmailMobile() {
    if (this.restApi.isCompany()) {
      this.restApi.setBottomSheet('companySendBookingVoucher', '');
    } else {
      this.router.navigate(['/voucher/send'],
        { queryParams: { ref: this.retrieveObj.referenceNumber, identifier: this.retrieveObj.customerIdentifier.value } });
    }
  }
  openSendBookingVoucherPopUp() {
   // this.restApi.setModal( 'share you voucher', 'SendBookingVoucher', '');
    if (this.restApi.isCompany()) {
      if (this.restApi.desktopView) {
        this.restApi.setModal( $localize`:@@shareVoucher:Email to another address`, 'companySendBookingVoucher', '');
      } else {
        this.restApi.setBottomSheet('companySendBookingVoucher', '');
      }
    } else {
      this.restApi.setModal( $localize`:@@shareVoucher:Email to another address`, 'SendBookingVoucher', '');
    }

  }
  submitFeedbackForm() {
    this.restApi.alerts = [];
    this.submittingFeedback = true;
    this.feedbackSubmitted = true;
    this.feedbackSubmittedWithErrors = false;
    this.feedbackPosted = true;

    this.feedbackComment = encodeURIComponent(this.feedbackForm.value.reviewComment);
    this.sendRating = this.feedbackForm.value.rating;
    this.email = this.booking.emailAddress;
    this.lastName = encodeURIComponent(this.booking.guestLastName);

    if (this.feedbackForm.invalid) {
      this.submittingFeedback = false;
      return;
    }
    this.restApi.leaveBookingFeedback(this.feedbackComment, this.sendRating, this.email, this.lastName,
      this.refFromURLValue, this.feedbackForm.value.reviewSubject).subscribe(

      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.errorMsg = '';
          this.hideForm = true;
          this.restApi.messages(environment.messages.THANKS_REVIEW, 'success');
          this.submittingFeedback = false;
          this.restApi.scrollTop();
        } else {
          this.submittingFeedback = false;
          this.restApi.messages(this.data.message, 'error');
          this.restApi.scrollTop();

        }

      },
      error => {
        this.submittingFeedback = false;
        this.restApi.messages(environment.messages.error, 'error');
        this.restApi.scrollTop();
      }
    );
  }

  getBookingDetails(retrieveObj) {

    if(this.logedIn.isLogedIn && this.restApi.isCompany() && this.logedIn.companyInfo && this.logedIn.companyInfo.inactive) {
      this.restApi.inactiveWorkspace = true;
    } else if(this.logedIn.isLogedIn && this.restApi.isCompany() && this.logedIn.companyInfo && this.logedIn.companyInfo.inactive === false) {
      this.restApi.showExpensifyInBooking();
    }
    if(!this.booking){
    this.restApi.retrieve(retrieveObj).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.errorMsg = '';
          this.restApi.isBooking = true;
          this.restApi.useForm = false;
          this.booking = this.data.data;
          this.restApi.printHotelData = JSON.stringify(this.booking);
          this.restApi.expensifyLastModifiedDate  = this.booking.expensifyLastModifiedDate;
          this.restApi.expensifyLastReportName  = this.booking.expensifyLastReportName;
          this.restApi.triphopCardData =  this.data;
          this.checkin = this.datePipe.transform(this.booking.checkinDate, 'EE MMM d');
          this.checkout = this.datePipe.transform(this.booking.checkoutDate, 'EE MMM d');
           let checkin = moment(this.booking.checkinDate).format('ddd') + ' ' + moment(this.booking.checkinDate).format('MMM')
            + ' ' + moment(this.booking.checkinDate).format('DD');
           let checkout = moment(this.booking.checkoutDate).format('ddd') + ' ' + moment(this.booking.checkoutDate).format('MMM')
            + ' ' + moment(this.booking.checkoutDate).format('DD');
          this.nights = Math.round(moment(checkout, 'ddd MMM D').diff(moment(checkin, 'ddd MMM D'), 'days', true));

          if (this.booking && 'hotelFacilities' in this.booking) {
            if (this.booking.hotelFacilities != null && this.booking.hotelFacilities.length > 0) {
              this.facilities = this.booking.hotelFacilities;
            }
          }
          if (this.router.url === '/bookings/manage') {
            this.router.navigate(['/bookings/manage'],
              {queryParams: {ref: retrieveObj.referenceNumber, identifier: retrieveObj.customerIdentifier.value}});
          }
          if (this.booking.bookingStatus === 'CANCELLED') {
            this.bookingType = 'cancelled';
          } else {
            if (this.booking.past) {
              this.bookingType = 'completed';
            } else {
              this.bookingType = 'confirmed';
            }
          }

          if (this.bookingType === 'confirmed') {
            if (!this.restApi.isCompany() || !this.restApi.inactiveWorkspace) {
              this.restApi.checkCancellationFees(retrieveObj).subscribe(
                dataCancel => {
                  this.dataCancel = dataCancel;
                  if (this.dataCancel.errorCode === 0) {
                    this.errorMsg = '';
                    this.allowCancellation = true;
                  } else {
                    this.allowCancellation = false;
                  }

                },
                error => {

                }
              );
            }
          }
          this.submitting = false;

        } else {
          this.submitting = false;
          this.restApi.useForm = true;
          this.errorMsg = this.data.message;
          this.restApi.messages(this.data.message, 'error');

        }

      },
      error => {
        this.submitting = false;
        this.restApi.useForm = true;
        this.restApi.messages(environment.messages.error, 'error');
      }
    );
  }
  }

  submitForm() {
    this.submitting = true;
    if (this.retrieveForm.value.lastNameOrEmail.length > 0 && this.retrieveForm.value.lastNameOrEmail.indexOf('@') > -1) {
    this.atpos = this.retrieveForm.value.lastNameOrEmail.indexOf('@');
    this.dotpos = this.retrieveForm.value.lastNameOrEmail.lastIndexOf('.');
    if (this.atpos < 1 || this.dotpos < this.atpos + 2 || this.dotpos + 2 >= this.retrieveForm.value.lastNameOrEmail.length) {
      this.invalidEmail = true;
    } else {
      this.type = 'EMAIL';
      this.invalidEmail = false;
    }

  } else{
      this.type = 'LAST_NAME';
    }
    this.submitted = true;

    this.retrieveObj = {
      customerIdentifier: {
        type: this.type,
        value: this.retrieveForm.value.lastNameOrEmail
      },
      referenceNumber: this.retrieveForm.value.refNumber
    };
    // stop here if form is invalid
    if (this.retrieveForm.invalid || this.invalidEmail) {
      this.submitting = false;
      return;
    }
    this.getBookingDetails(this.retrieveObj);

  }
  formatDate(date) {
    return $localize`:@@lastUploadDate:'Last upload date: ` + this.datePipe.transform(date, 'd MMM, y');
  }

}
