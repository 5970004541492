export class Wallet {
    id: string;
    name: string;
    hrsCompanyId: string;
    description: string;
    currencyCode: string;
    balance: number;
    creationDate?: Date;
    updatedDate?: Date;
    isInactive: boolean;
}
