<form name="loginForm" [formGroup]="loginForm" class="pt-0 pt-lg-3 px-lg-3" *ngIf="!this.auth.isLogedIn">
  <div class="form-group with-icon position-relative form-type-textfield form-name-email d-flex align-items-center">
    <input type="email" name="email"  class="form-control placeholder-text-field" placeholder="Work Email" formControlName="email" autocomplete="off"
           i18n-placeholder="@@workEmailPlaceHolder" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
    <i  class="icon-email"></i>
    <!--<p class="pl-2 pl-lg-0 gray-text mb-0 mx-auto">{{companyDomain}}</p>-->
  </div>
  <div *ngIf="submitted && f.email.errors" class="text-danger smaller mb-2" [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">
    <div *ngIf="f.email.errors.required" i18n="@@requiredEmailError">Email is required</div>
    <div *ngIf="f.email.errors.email" i18n="@@invalidEmailError">Email must be a valid email address</div>
  </div>
  <div class="form-group with-icon position-relative  form-type-password form-name-password">
    <input id="password" class="form-control placeholder-text-field" formControlName="password" placeholder="Password" autocomplete="new-password"
           i18n-placeholder="@@passwordPlaceHolder" [type]="this.dataService.isTextFieldType ? 'password' : 'text'" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
    <i class="pointer" (click)="this.dataService.togglePasswordFieldType()"  [ngClass]="this.dataService.isTextFieldType ? 'icon-eye' : 'icon-eye-blocked'"></i>
  </div>
  <div *ngIf="submitted && f.password.errors" class="text-danger smaller mb-2" [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">
    <div *ngIf="f.password.errors.required" i18n="@@requiredPasswordError"> Password is required</div>
  </div>

  <div class="row" >
    <div class="col-12 ml-auto">
      <button (click)="onSubmitLogin()"  [ngClass]="{'m-progress': submitting }" class=" btn green_btn btn-block font-weight-normal font-14 px-5 mt-auto workspace-btn-padding">
        <span class="mx-auto" i18n="@@signInBtn">Sign in</span>

      </button>
      <div class="text-danger mt-2" [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">{{this.errorMsg}}</div>
    </div>
    <div class="col-12 d-flex align-items-center" [ngClass]="{'pr-lg-0 text-left': dataService.isSiteLtr(), 'pl-lg-0 text-right': dataService.isSiteRtl()}">
      <div>
        <button class="btn-link mt-4 mb-2 font-weight-normal " *ngIf="dataService.desktopView" (click)="this.dataService.forgotPasswordDialogue()" i18n="@@forgotPassword"> Forgot your password?</button>
        <button class="btn-link mt-4 mb-2 font-weight-normal" *ngIf="dataService.mobileView" (click)="openForgotPasswordSheet()" i18n="@@forgotPassword"> Forgot your password?</button>
       <!-- <p class="mb-0"><a class="blue-text" (click)="this.router.navigate(['/join']);" i18n="@@notMember"> Not a member? Sign up</a></p>-->
      </div>
    </div>

  </div>

</form>







<!--<div class="or-sep pb-3 pt-4">or</div>
<div class="row px-lg-3">
  <div class="col-12">
    <button class="shadow-sm google-btn text-white border-light font-weight-bold btn-block mb-4" (click)="this.auth.socialSignIn('google')">
    <span class="tiny_loading">
									<span  *ngIf="this.auth.submittedGoogle"  class="loading_icon"></span>
								</span>
      <span class="google-btn-text">Sign in with Google</span></button>
  </div>
  <div class="col-12">
    <button class="shadow-sm facebook-button btn-block border-light font-weight-bold d-flex text-white" (click)="this.auth.socialSignIn('facebook')">
      <i class="icon-facebook1"></i>
      <span class="pl-4 pl-lg-0 pt-1 mx-auto">Sign in with Facebook</span>
    </button>
  </div>
</div>-->
<div  class="text-danger  mb-3 px-lg-3" *ngIf="this.auth.errorMsg">
  {{this.auth.errorMsg}}
</div>
<!--<p class="font-12 pt-3 px-lg-3" [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">SafarHub will never post to Facebook without your permission.</p>-->

