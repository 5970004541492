import { environment } from "src/environments/environment";
import { VariablesService } from "../vars.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Wallet } from "src/app/shared/model/payment/wallet";

@Injectable({
  providedIn: "root",
})
export class WalletManagementService {
  constructor(private http: HttpClient, public VarsService: VariablesService) {}

  getWalletList() {
    return this.http.get(
      `${environment.apiBase}${this.VarsService.walletManagementApis.walletList}`
    );
  }

  getActiveWalletList() {
    return this.http.get(
      `${environment.apiBase}${this.VarsService.walletManagementApis.walletList}?isInactive=` +
        false
    );
  }

  createWallet(wallet: Wallet) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.walletManagementApis.createWallet}`,
      wallet
    );
  }

  editWallet(wallet: Wallet) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.walletManagementApis.editWallet}`,
      wallet
    );
  }

  getTeamWallets(teamId: string) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.walletManagementApis.teamWallets}?teamId=` +
        teamId,
      ""
    );
  }

  addWalletsToTeam(teamId, walletIds) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.walletManagementApis.addTeamWallets}?teamId=` +
        teamId +
        "&walletIds=" +
        walletIds,
      ""
    );
  }
  removeWalletsFromTeam(teamId, walletIds) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.walletManagementApis.deleteTeamWallets}?teamId=` +
        teamId +
        "&walletIds=" +
        walletIds,
      ""
    );
  }

  getUserWallets(userId?: string) {
    let param = '';
    if(userId){
        param = '?userId=' + userId;
    }
    return this.http.post(
      `${environment.apiBase}${this.VarsService.walletManagementApis.userWallets}` + param,
      ""
    );
  }

  addWalletsToUser(userId, walletIds) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.walletManagementApis.addUserWallets}?userId=` +
        userId +
        "&walletIds=" +
        walletIds,
      ""
    );
  }
  removeWalletsFromUser(userId, walletIds) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.walletManagementApis.deleteUserWallets}?userId=` +
        userId +
        "&walletIds=" +
        walletIds,
      ""
    );
  }
}
