import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalComponent} from '../../services/modal/modal.component';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {AuthServiceMain} from "../../auth/auth.service";

@Component({
  selector: 'app-send-booking-voucher',
  templateUrl: './send-booking-voucher.component.html',
  styleUrls: ['./send-booking-voucher.component.scss']
})
export class SendBookingVoucherComponent implements OnInit {
  sendVoucherForm: FormGroup;
  refFromURL = 'ref';
  refFromURLValue;
  identifierFromURL = 'identifier';
  identifierFromURLValue;
  emailFromURL = 'email';
  emailFromURLValue;
  data;
  errorMsg;
  customerIdentifier = {};
  submitted = false;
  submitting = false;
  constructor(private http: HttpClient, public  router: Router, public auth: AuthServiceMain,
              private formBuilder: FormBuilder, public Service: DataService,
              private route: ActivatedRoute,  private modal: ModalComponent, private titleService:Title) {
  this.titleService.setTitle('Forward My Voucher | SafarHub');

  }

  ngOnInit() {
    this.sendVoucherForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    }, {
    });
    /*this.route.queryParams.subscribe(params => {
      this.refFromURLValue =  params[this.refFromURL];
      this.identifierFromURLValue =  params[this.identifierFromURL];
    });
  */
    //if (this.identifierFromURLValue === null || this.identifierFromURLValue === undefined) {
    if (('' + this.router.url).indexOf('identifier') > 0) {
      this.identifierFromURLValue = ('' + this.router.url).substring(('' + this.router.url).indexOf('identifier=') + 11);
      this.refFromURLValue = ('' + this.router.url).substring(('' + this.router.url).indexOf('?') + 5, ('' + this.router.url).indexOf('&'));
    } else {
      this.route.queryParams.subscribe(params => {
        this.emailFromURLValue =  params[this.emailFromURL];
      });
      // this.identifierFromURLValue = this.emailFromURLValue;
      this.identifierFromURLValue = ('' + this.router.url).substring(('' + this.router.url).indexOf('&email=') + 7, ('' + this.router.url).indexOf('&lastname'));
      this.refFromURLValue = ('' + this.router.url).substring(('' + this.router.url).indexOf('?') + 5, ('' + this.router.url).indexOf('&'));

    }

  }


// convenience getter for easy access to form fields
  get f() { return this.sendVoucherForm.controls; }

  submitForm() {

    this.Service.alerts = [];
    this.submitted = true;
    this.submitting = true;
    if (this.sendVoucherForm.invalid) {
      this.submitting = false;
      return;
    }
    this.customerIdentifier = {
      type: 'EMAIL',
      value: this.identifierFromURLValue
    };
    this.Service.voucherEmail = this.sendVoucherForm.value.email;
    this.Service.sendBookingVoucher(this.refFromURLValue, this.sendVoucherForm.value.email, this.customerIdentifier).subscribe(
      data => {

        this.data = data;
        if (this.data.errorCode === 0) {
          this.submitting = false;
          this.errorMsg = '';
          if (this.Service.desktopView) {
            this.Service.modalOf();
            this.Service.messages('A voucher has been sent to your ' + this.sendVoucherForm.value.email + ' address.', 'success');
          } else {
            this.Service.sendVoucherMsg = 'A voucher has been sent to your ' + this.sendVoucherForm.value.email + ' address.';
            this.router.navigate(['/bookings/manage'],
              {queryParams: {ref: this.refFromURLValue, identifier: this.identifierFromURLValue}});
          }
        } else {
          this.submitting = false;
          if (this.Service.desktopView) {
            this.Service.modalOf();
          }
          this.Service.messages(this.data.message, 'error');
        }

      }, error => {
        this.submitting = false;
        if (this.Service.desktopView) {
          this.Service.modalOf();
        }
        this.Service.messages(environment.messages.error, 'error');

      }
    );


  }
}
