import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceMain} from "../../../auth/auth.service";
import {environment} from "../../../../environments/environment";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-slack-thank-you-page',
  templateUrl: './slack-thank-you-page.component.html',
  styleUrls: ['./slack-thank-you-page.component.scss']
})
export class SlackThankYouPageComponent implements OnInit {

  constructor(private titleService: Title, public dataService: DataService, public route: ActivatedRoute,
              public router: Router, public auth: AuthServiceMain) {
    this.titleService.setTitle('Integrate your slack workspace | SafarHub');
  }
              env = environment;
  ngOnInit(): void {
  }

}
