import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {AuthServiceMain} from '../../auth/auth.service';
import {Title} from "@angular/platform-browser";
import get = Reflect.get;

@Component({
  selector: 'app-workspace-signup',
  templateUrl: './workspace-signup.component.html',
  styleUrls: ['./workspace-signup.component.scss']
})
export class WorkspaceSignupComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private titleService: Title,
              private formBuilder: FormBuilder,
              public dataService: DataService, public router: Router, public auth: AuthServiceMain) {
    this.titleService.setTitle('Join SafarHub for free | SafarHub');
    this.dataService.componentMethodCalled$.subscribe(
      () => {
        if(this.auth.isLogedIn && this.auth.companyInfo){
          this.router.navigate(['/']);
        }

      }
    );
  }
  signUpForm: FormGroup;
  submitted = false;
  submitting = false;
  showSignUpForm = true;
  verifyEmailMsg = false;
  errorMsg = '';
  data;
  showCompanyInvitationSignUp = false;
  ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
          const code = params['code'];
          if (code) {
                this.showCompanyInvitationSignUp = true;
          }
      });
          this.verifyEmailMsg = false;
    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
   }, {
    });
    if(this.auth.isLogedIn && this.auth.companyInfo){
      this.router.navigate(['/']);
    }
  }
  get f() { return this.signUpForm.controls; }

  sendCompanyVerificationEmail(email) {
    this.dataService.sendCompanyVerificationEmail(email).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
            this.showSignUpForm = false;
            this.dataService.companyEmail = email;
            this.verifyEmailMsg = true;
        } else{
          this.submitting = false;
          this.errorMsg = this.data.message;
        }
      }, error => {
        this.submitting = false;
        this.errorMsg = environment.messages.error;
      }
    );
  }
  onSubmitSignUp() {
    this.submitted = true;
    this.submitting = true;
    if (this.signUpForm.invalid ) {
      this.submitting = false;
      return;
    }
    this.sendCompanyVerificationEmail(this.signUpForm.controls.email.value);
  }


}
