import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {AuthServiceMain} from '../../auth/auth.service';
declare let gtag: any;
@Component({
  selector: 'app-company-account-form',
  templateUrl: './company-account-form.component.html',
  styleUrls: ['./company-account-form.component.scss']
})
export class CompanyAccountFormComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,  public dataService: DataService, private route: ActivatedRoute,
              public auth: AuthServiceMain, public router: Router) { }
  env = environment;
  companyAccountForm: FormGroup;
  submitted = false;
  submitting = false;
  showFrom = true;
  errorMsg = '';
  code = 'code';
  codeValue;
  email;
  domain;
  passwordValue = null;
  data;
  timer = null;
  availableDomain;
  startWithCompanyID = false;
  startWithName = false;
  wrongName;
  wrongPass = '';
  wrongPassFlag = false;
  hostLink  = environment.companyHost;
  letterRegExpression = /^[a-zA-Z\s]*$/;  
  letterNumberRegExpression = /^[a-zA-Z\s0-9-_]+$/;

  ngOnInit(): void {
    this.getCode();
    this.getCompanyCodeEmail(this.codeValue);
  }

  initForm() {
    if(this.data.data.domain || this.data.data.companyId) {
      this.companyAccountForm = this.formBuilder.group({
        password: ['', [Validators.required]],
        companyName: [{value: this.data.data.companyName ? this.data.data.companyName : '', disabled: !!this.data.data.companyName}, [Validators.required]],
        companyId: [{value: this.data.data.companyId ? this.data.data.companyId : '', disabled: !!this.data.data.companyId}, [Validators.required]],
        firstName: ['', [Validators.required, Validators.pattern(this.letterRegExpression), Validators.minLength(1)]],
        lastName: ['', [Validators.required, Validators.pattern(this.letterRegExpression), Validators.minLength(1)]],
      }, {});
    } else {
      this.companyAccountForm = this.formBuilder.group({
        password: ['', [Validators.required]],
        companyName: ['', [Validators.required]],
        companyId: ['', [Validators.required]],
        firstName: ['', [Validators.required, Validators.pattern(this.letterRegExpression), Validators.minLength(1)]],
        lastName: ['', [Validators.required, Validators.pattern(this.letterRegExpression), Validators.minLength(1)]],
      }, {});
    }
  }

  getCode() {
    this.route.queryParams.subscribe(params => {
      this.codeValue =  params[this.code];
    });
  }

  getCompanyCodeEmail(code) {
    this.dataService.getCompanyCodeEmail(code).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.email = this.data.data.email;
          this.domain = this.data.data.domain;
        } else {
          this.errorMsg = this.data.message;
        }
      }, (error) => {
        this.errorMsg = environment.messages.error;
      }, () => {
        this.initForm();
      }
    );
  }

  checkName() {
    if (this.companyAccountForm.controls.companyName.value.match(this.letterNumberRegExpression) && this.companyAccountForm.controls.companyName.value && this.companyAccountForm.controls.companyName.value !== '') {
      this.wrongName = false;
      } else {
      this.wrongName = true;
    }
    if (this.companyAccountForm.controls.companyName.value && this.companyAccountForm.controls.companyName.value !== '') {
      this.startWithName = true;
    } else{
      this.startWithName = false;
    }
  }

  checkId() {
    clearTimeout(this.timer);
    var letterNumber = /^[0-9a-zA-Z]+$/;
    if (this.companyAccountForm.controls.companyId.value.match(letterNumber) && this.companyAccountForm.controls.companyId.value && this.companyAccountForm.controls.companyId.value !== '') {
      this.timer = setTimeout(() => {
        this.dataService.checkCompanyId(this.companyAccountForm.controls.companyId.value).subscribe (
          data => {
            this.data = data;
            if (this.data.errorCode === 0) {
              this.availableDomain = this.data.data.available;
            } else {
              this.errorMsg = this.data.message;
            }
            if (this.companyAccountForm.controls.companyId.value && this.companyAccountForm.controls.companyId.value !== '') {
              this.startWithCompanyID = true;
            } else{
              this.startWithCompanyID = false;
            }
          }, error => {
            this.errorMsg = environment.messages.error;
          }
        );
      }, 1000);
    } else{
      this.availableDomain = false;
    }
  }

  get f() { 
    return this.companyAccountForm.controls; 
  }

  onSubmit() {
    this.wrongPassFlag = false;
    this.errorMsg = '';
    this.submitted = true;
    this.submitting = true;
    if (this.companyAccountForm.invalid) {
      this.submitting = false;
      return;
    }
    this.dataService.createCompany(this.companyAccountForm.controls.companyId.value.toLowerCase(),
        this.companyAccountForm.controls.companyName.value,
        this.codeValue, this.companyAccountForm.controls.password.value,
      this.companyAccountForm.controls.firstName.value,
      this.companyAccountForm.controls.lastName.value).subscribe (
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            gtag('event', "sign_up", {
              method: "Company"
            });
            this.auth.handleAuthentication(
              this.auth.token = this.data.data.token,
            );
            this.auth.fetchUserData();
            this.showFrom = false;
          } else if (this.data.errorCode === 181) {
            this.errorMsg = 'Company ID is already used';
            this.submitting = false;
          } else {
            this.errorMsg = this.data.message;
            this.submitting = false;
          }
        }, error => {
          this.errorMsg = environment.messages.error;
          this.submitting = false;
        }
      );
  }
}
