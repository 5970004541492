import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {DataService} from "../../services/data.service";
import {Title} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.scss']
})
export class RequestDemoComponent implements OnInit {
  contactUsForm: FormGroup;
  submitted = false;
  data;
  errorMsg = '';
  submitting = false;
  sentRequest;
  constructor(private http: HttpClient,
              private formBuilder: FormBuilder, public dataService: DataService) {
      }
  ngOnInit() {
    this.dataService.alerts = [];
    this.contactUsForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: [''],
      lastName: [''],
    }, {
    });
    setTimeout(() => {
      this.dataService.headerOn = false;

    }, 50);
  }
  get f() { return this.contactUsForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.submitting = true;
    if (this.contactUsForm.invalid) {
      this.submitting = false;
      return;
    }

    this.dataService.contactUs(this.contactUsForm.value.email, 'Demo request is submitted.',
      this.contactUsForm.value.firstName + ' ' + this.contactUsForm.value.lastName,
      'SafarHub Demo Request').subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.submitting = false;
          this.errorMsg = '';
          this.submitted = false;
          this.sentRequest = true;
        } else {
          this.submitting = false;
          this.errorMsg = environment.messages.CONTACT_US_ERROR;
        }

      },
      error => {
        this.errorMsg = environment.messages.CONTACT_US_ERROR;
        this.submitting = false;
      }
    );
  }


}
