import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {VariablesService} from "../../services/vars.service";
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-country-code',
  templateUrl: './country-code.component.html',
  styleUrls: ['./country-code.component.scss']
})
export class CountryCode implements OnInit {
  @ViewChild('SearchCode', {static: true}) SearchCode: ElementRef;

  constructor( public Vars: VariablesService, public Service: DataService) { }
countryCodeList;

  Search = '';
  ngOnInit() {

    this.countryCodeList = this.Vars.countries;

    setTimeout(() => {
      if(this.SearchCode.nativeElement){
        this.SearchCode.nativeElement.focus();

      }

    }, 400);
  }


  CountrySearch(){

    if(this.Search.length > 1){
      this.countryCodeList = this.Vars.countries;

      this.countryCodeList = this.countryCodeList.filter(item => {
        const find =   item.name.toLowerCase().indexOf(this.Search.toLowerCase()) && item.code.toLowerCase().indexOf(this.Search.toLowerCase()) && item.dial_code.indexOf(this.Search);

        return find !== -1;


      })


    } else{

      this.countryCodeList = this.Vars.countries;


    }




  }

}
