import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import * as moment from "moment";
import { FormBuilder, FormGroup } from "@angular/forms";
import { VariablesService } from "../../../services/vars.service";
import { environment } from "../../../../environments/environment";
import { AuthServiceMain } from "../../../auth/auth.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-update-user-info',
  templateUrl: './update-user-info.component.html',
  styleUrls: ['./update-user-info.component.scss']
})
export class UpdateUserInfoComponent implements OnInit {

  constructor(public datePipe: DatePipe, private formBuilder: FormBuilder, public dataService: DataService, public vars: VariablesService, public auth: AuthServiceMain) { }
  genderArray: any = [
    { id: 'MALE', name: $localize`:@@male:Male`, isSelected: false },
    { id: 'FEMALE', name: $localize`:@@female:Female`, isSelected: false },
  ];
  minDate = new Date(1900, 1, 1);
  maxDate = new Date();
  dob;
  formatDOB;
  memberInfoForm: FormGroup;
  submitting;
  selectedValue;
  data;
  userFormData = [];
  sendUserData = '';
  errorMessage;
  selectedGender;
  submitted;
  InvalidPhoneNumber: boolean = false;

  returnPhoneCountryCode() {
    if (Number(this.auth.userInfo.phoneNumberCountryCode)) {
      let selected = this.vars.countries.filter(item => item.dial_code === ('+' + this.auth.userInfo.phoneNumberCountryCode));
      return selected[0].code;
    } else {
      return this.auth.userInfo.phoneNumberCountryCode;
    }
  }
  ngOnInit(): void {
    this.memberInfoForm = this.formBuilder.group({
      dob: [this.auth.userInfo.birthDate],
      gender: [this.auth.userInfo.gender],
      contNum: [''],
      phoneNumberCountryCode: ['']
    });
    if (this.auth.userInfo.phoneNumber && this.auth.userInfo.phoneNumber !== null &&
      this.auth.userInfo.phoneNumber !== 'undefined') {
      this.memberInfoForm.controls.contNum.setValue(this.auth.userInfo.phoneNumber);
    }
    if (this.auth.userInfo.phoneNumberCountryCode && this.auth.userInfo.phoneNumberCountryCode !== null &&
      this.auth.userInfo.phoneNumberCountryCode !== 'undefined') {
      if (Number(this.auth.userInfo.phoneNumberCountryCode)) {
        let selected = this.vars.countries.filter(item => item.dial_code === ('+' + this.auth.userInfo.phoneNumberCountryCode));
        this.selectedValue = selected[0].code;
        this.memberInfoForm.controls.phoneNumberCountryCode.setValue(selected[0].code);
      } else {
        this.selectedValue = this.auth.userInfo.phoneNumberCountryCode;
        this.memberInfoForm.controls.phoneNumberCountryCode.setValue(this.auth.userInfo.phoneNumberCountryCode);
      }
    }
    if (this.auth.userInfo.gender && this.auth.userInfo.gender !== null &&
      this.auth.userInfo.gender !== 'undefined') {
      this.selectedGender = this.auth.userInfo.gender;
      this.memberInfoForm.controls.gender.setValue(this.auth.userInfo.gender);
    }
    if (this.auth.userInfo.birthDate && this.auth.userInfo.birthDate !== null &&
      this.auth.userInfo.birthDate !== 'undefined') {
      this.formatDOB = this.datePipe.transform(this.auth.userInfo.birthDate, 'MMM dd, y');
      this.memberInfoForm.controls.dob.setValue(new Date(this.auth.userInfo.birthDate));
    }
  }
  selectChangeHandler(event: any) {
    this.selectedValue = event.target.value;
  }
  selectChangeHandlerGender(event: any) {
    this.selectedGender = event.target.value;
  }
  dobDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dob = moment(event.value).format('YYYY') + '-' + moment(event.value).format('MM') + '-' + moment(event.value).format('DD');
    this.formatDOB = this.datePipe.transform(this.dob, 'MMM dd, y') || this.datePipe.transform(this.auth.userInfo.birthDate, 'MMM dd, y');
  }
  get f() { return this.memberInfoForm.controls; }
  updateInfo() {
    this.dataService.alerts = [];
    this.submitted = true;
    this.errorMessage = '';
    this.submitting = true;
    this.sendUserData = '';
    this.userFormData = [];
    const numberPattern = /^\d+$/;
    if (this.memberInfoForm.controls.contNum.value && this.memberInfoForm.controls.contNum.value.trim() !== '' && !this.selectedValue) {
      this.submitting = false;
      return;
    }
    if (this.selectedValue && (!this.memberInfoForm.controls.contNum.value || this.memberInfoForm.controls.contNum.value.trim() === '')) {
      this.submitting = false;
      return;
    }
    if (!numberPattern.test(this.memberInfoForm?.controls?.contNum?.value)) {
      this.InvalidPhoneNumber = true;
      this.submitting = false;
      return;
    }
    if (this.dob) {
      this.userFormData.push({ param: 'birthDate', value: this.dob });
    }
    if (this.selectedValue) {
      this.userFormData.push({ param: 'phoneNumberCountryCode', value: this.selectedValue });
    }
    if (this.selectedGender) {
      this.userFormData.push({ param: 'gender', value: this.selectedGender });
    }
    if (this.memberInfoForm.controls.contNum.value && this.memberInfoForm.controls.contNum.value.trim() !== '') {
      this.userFormData.push({ param: 'phoneNumber', value: this.memberInfoForm.controls.contNum.value });
    }
    if (this.userFormData.length) {
      this.userFormData.forEach((element, index) => {
        if (index === 0) {
          this.sendUserData = element.param + '=' + element.value;
        } else {
          this.sendUserData += '&' + element.param + '=' + element.value;
        }
      });
      this.dataService.userUpdate(this.sendUserData).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.auth.userInfo.birthDate = this.data.data.birthDate;
            this.auth.userInfo.phoneNumberCountryCode = this.data.data.phoneNumberCountryCode;
            this.auth.userInfo.gender = this.data.data.gender;
            this.auth.userInfo.phoneNumber = this.data.data.phoneNumber;
            localStorage.setItem('userInfo', JSON.stringify(this.auth.userInfo));
            this.submitting = false;
            this.dataService.messages($localize`:@@memberInfoUpdated:Member info updated successfully`, 'success');
            this.dataService.BottomSheetOf();
            this.dataService.rightSideDrawerOff();
            this.dataService.fetchUserData.next();
          } else {
            this.errorMessage = this.data.message;
            this.submitting = false;
          }
        }, error => {
          this.errorMessage = environment.messages.error;
          this.submitting = false;
        }
      );
    } else {
      this.submitting = false;
    }
  }
}
