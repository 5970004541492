<div class="main-dashboard-content" *ngIf="dataService.mobileView">
  <app-messages></app-messages>
  <div class="container pt-4" *ngIf="this.auth.companyInfo">
    <ng-container>
      <a
        class="font-16 blue-text text-underline"
        (click)="
          this.router.navigate(['/members'])
        "
        i18n="@@members"
        >Members</a
      >
      <span class="font-16 gray-text-dark">
        / <ng-container i18n="@@inviteUsers">Invite Users</ng-container>
      </span>
    </ng-container>
  </div>

  <div class="pt-4">
    <app-invite-users></app-invite-users>
  </div>
</div>

<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container
  class="sidenav-container"
  *ngIf="this.dataService.desktopView"
>
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pt-5 pb-5" *ngIf="dataService.desktopView">
        <div class="mx-lg-5">
          <div class="pb-5 mb-3" *ngIf="this.auth.companyInfo">
            <ng-container>
              <a
                class="font-16 blue-text text-underline"
                (click)="
                  this.router.navigate([
                   '/members'
                  ])
                "
                i18n="@@members"
                >Members</a
              >
              <span class="font-16 gray-text-dark">
                / <ng-container i18n="@@inviteUsers">Invite Users</ng-container>
              </span>
            </ng-container>
          </div>
        </div>
        <div class="mx-lg-4">
          <div
            class="mb-5 rounded border pt-4 workspace-rounded-container"
            [ngClass]="{
              'ml-4': dataService.isSiteLtr(),
              'mr-4': dataService.isSiteRtl()
            }"
          >
            <div class="row">
              <div
                class="col-lg-4 min-left-side-col-width"
                [ngClass]="{
                  'ml-2 pr-0': dataService.isSiteLtr(),
                  'mr-2 pl-0': dataService.isSiteRtl()
                }"
              >
                <app-invite-users></app-invite-users>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
