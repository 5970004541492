
<div class="main-container" [ngClass]="{'gray-pass-bg pt-4 border-0' : dataService.desktopView && dataService.isCompany()}">
  <div class="container custom-container mb-5" [ngClass]="{'desktop-design text-center' : dataService.desktopView && dataService.isCompany()}">
    <img width="492" height="422" loading="lazy" class="fixed-img" src="assets/img/reset-password.jpg" *ngIf="dataService.desktopView && dataService.isCompany()">
<div  [ngClass]="{'white-box coporate-container position-relative' : dataService.desktopView && dataService.isCompany()}">

<h1 class="primaray-text mb-4 text-center bolder pt-4" *ngIf="!dataService.isCompany()">Reset password</h1>
  <p class="gray-primaray-text mb-4 text-center pt-5 font-24" *ngIf="dataService.isCompany()">Reset Your Password</p>
<form [formGroup]="resetPasswordForm" (ngSubmit)="submitForm()" class="mx-lg-0" [ngClass]="{'mx-5' : !dataService.isCompany()}">
  <div class="form-group with-icon mb-0  form-type-password form-name-password">
    <input type="password" name="password"  class="form-control" formControlName="password" placeholder="Set your new password" [type]="this.dataService.isTextFieldType ? 'password' : 'text'"
           minlength="6" [ngClass]="{ 'is-invalid': passwordFormSubmitted && d.password.errors }"/>
    <i class="pointer" (click)="this.dataService.togglePasswordFieldType()"  [ngClass]="this.dataService.isTextFieldType ? 'icon-eye' : 'icon-eye-blocked'"></i>
  </div>
  <div *ngIf="passwordFormSubmitted && d.password.errors" class="invalid-feedback">
    <div *ngIf="d.password.errors.required">Password is required</div>
    <div class="password-error-msg" *ngIf="d.password.errors?.minlength">Password is too short</div>
  </div>
  <div class="form-actions mt-3" [ngClass]="{'mb-4' : dataService.desktopView && dataService.isCompany()}">
    <button class="btn-block btn" [ngClass]="{'m-progress': submitting, 'btn-primary': !dataService.isCompany(), 'blue_btn font-14 sharp-font bold-500': dataService.isCompany() }">Submit</button>
  </div>
</form>
</div>
</div>
</div>
