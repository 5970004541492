import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {environment} from '../../../../../environments/environment';
import * as moment from "moment";

@Component({
  selector: 'app-switch-plan',
  templateUrl: './switch-plan.component.html',
  styleUrls: ['./switch-plan.component.scss']
})
export class SwitchPlanComponent implements OnInit {

  constructor(public dataService: DataService) { }
  data;
 // plans;
  submitting;
  // floatAmount;
  switchData;
  planId;
  plans;
  ngOnInit(): void {
    if (this.dataService.desktopView) {
      this.planId = this.dataService.modalData;
    } else {
      this.planId = this.dataService.BottomSheetData;
    }
    if (!this.planId) {
      this.dataService.getWorkspacePlans().subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.plans = this.data.data;
            this.plans.forEach((item) => {
              if (item.name === 'Annual') {
                this.planId = item.planId;
                this.getFess(this.planId);
              }
            });
          } else {
            this.dataService.messages(this.data.message, 'error');
          }
        }, error => {
          this.dataService.messages(environment.messages.error, 'error');
        }
      );
    } else {
      this.getFess(this.planId);
    }
  }
  getFess(planId) {
    this.dataService.changePlanFees(planId).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.switchData = this.data.data;
        } else {
          this.dataService.messages(this.data.message, 'error');
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
      }
    );
  }
  /*formatDate(date) {
    return moment(date).format('MMM') + ' ' + moment(date).format('DD') + ', ' +  moment(date).format('YYYY');
  }*/
  confirm() {
  //  if (this.planId) {
      // here api to change (edit) the current plan
        this.submitting = true;
        this.dataService.changePlan(this.planId).subscribe(
          data => {
            this.data = data;
            if (this.data.errorCode === 0) {
              this.submitting = false;
              this.dataService.getSubscriptionDetails();
              this.dataService.modalOf();
              this.dataService.BottomSheetOf();
            } else {
              this.dataService.messages(this.data.message, 'error');
              this.submitting = false;
            }
          }, error => {
            this.dataService.messages(environment.messages.error, 'error');
            this.submitting = false;
          }
        );
 // } else {
      // here api for switch to annual action
   /*   this.dataService.upgradeSub().subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.submitting = false;
            this.dataService.getSubscriptionDetails();
            this.dataService.modalOf();
            this.dataService.BottomSheetOf();
          } else {
            this.dataService.messages(this.data.message, 'error');
            this.submitting = false;
          }
        }, error => {
          this.dataService.messages(environment.messages.error, 'error');
          this.submitting = false;
        }
      );*/
  //  }
  }

}
