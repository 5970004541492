<div class="mb-2 pt-4" *ngFor="let msg of this.approvalsService.approvalData.operations">
    <div [ngClass]="{ 'chat-max-width': dataService.desktopView }" *ngIf="msg.operationType === 'REQUEST_MORE_INFO'">
        <div class="d-flex" [ngClass]="{ 'mr-5 pr-4': dataService.isSiteLtr(), 'ml-5 pl-4': dataService.isSiteRtl() }">
            <div>
                <img width="35" height="35" loading="lazy" *ngIf="approvalsService.approvalData.approvers[0].profileImageUrl" class="user-img d-inline-block my-3"
                     [ngClass]="{ 'img-border': userService.imageHasError }" [src]="userService.imageHasError ? userService.fallbackSrc : approvalsService.approvalData.approvers[0].profileImageUrl" (error)="userService.imageHasError = true" />
                <div *ngIf="!approvalsService.approvalData.approvers[0].profileImageUrl" class="name-first-letters small my-3" [ngStyle]="{ 'background-color': approvalsService.approvalData.approvers[0].profileImageBackgroundHexColor }">
                    {{ approvalsService.approvalData.approvers[0].firstName.substring(0, 1).toUpperCase() }}{{ approvalsService.approvalData.approvers[0].lastName.substring(0, 1).toUpperCase() }}
                </div>
            </div>
            <div class="pt-4">
                <p class="primaray-text bold-600 font-18 minus-margin-bottom" [ngClass]="{ 'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl() }">
                    <span class="pr-2">{{ this.approvalsService.approvalData.approvers[0].firstName }}</span>{{ this.approvalsService.approvalData.approvers[0].lastName }}
                </p>
            </div>
        </div>
        <div class="border rounded p-4 gray-text-dark">{{ msg.comment }}</div>
    </div>
    <div [ngClass]="{ 'chat-max-width': dataService.desktopView }" *ngIf="msg.operationType === 'SUBMIT_MORE_INFO'">
        <div class="d-flex">
            <div>
                <img width="35" height="35" loading="lazy" *ngIf="approvalsService.approvalData.requester.profileImageUrl" class="user-img d-inline-block my-3"
                     [ngClass]="{ 'img-border': userService.imageHasError }" [src]="userService.imageHasError ? userService.fallbackSrc : approvalsService.approvalData.requester.profileImageUrl" (error)="userService.imageHasError = true" />
                <div *ngIf="!approvalsService.approvalData.requester.profileImageUrl" class="name-first-letters small my-3" [ngStyle]="{ 'background-color': approvalsService.approvalData.requester.profileImageBackgroundHexColor }">
                    {{ approvalsService.approvalData.requester.firstName.substring(0, 1).toUpperCase() }}{{ approvalsService.approvalData.requester.lastName.substring(0, 1).toUpperCase() }}
                </div>
            </div>
            <div class="pt-4">
                <p class="primaray-text bold-600 font-18 minus-margin-bottom" [ngClass]="{ 'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl() }" i18n="@@me">Me</p>
            </div>
        </div>
        <div class="border rounded p-4 gray-text-dark gray-bg">{{ msg.comment }}</div>
    </div>
</div>
