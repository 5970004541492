<div class="container mt-0 mt-lg-5 mb-5" [ngClass]="{'px-0': Service.mobileView}">
  <div class="row"
    [ngClass]="{'no-gutters': Service.mobileView}">

    <div class="col-md-8 custom-col8">
     <app-workspace-flight-booking-form></app-workspace-flight-booking-form>
    </div>

    <div class="col-md-4 custom-col4 hotel-details-side" *ngIf="Service.flightData && Service.desktopView">

    <app-flight-summary [flightSummary]="Service.flightData.details"></app-flight-summary>

      <app-flight-pricing [pricing]="Service.flightPricing"></app-flight-pricing>

    <!--  <div class="white-box p-0"
        *ngIf="this.Service.flightData.details.userData && this.Service.flightData.details.userData.rewardAmount">
        <div class="white-box-purple-header p-3 rounded-top text-white font-18 font-weight-bold">
          <ng-container i18n="@@rewards">Rewards</ng-container>: <ng-container
            *ngIf="Service.isSiteLtr()">$</ng-container>{{this.Service.flightData.details.userData.rewardAmount | number
          : '1.2-2'}}<ng-container *ngIf="Service.isSiteRtl()">$</ng-container>
        </div>
        <div class="px-3 pt-3 pb-4 gray-primaray-text font-16" i18n="@@uponCompletingFlightBooking">
          You will earn ${{this.Service.flightData.details.userData.rewardAmount | number : '1.2-2'}} upon completing
          this trip
        </div>
      </div>-->

      <app-cost-reporting-manage></app-cost-reporting-manage>
    </div>
  </div>
</div>


<app-loaders></app-loaders>
