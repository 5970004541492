import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../../services/data.service";
import {TripsService} from "../../services/trips.service";
import {FlightListService} from "../../services/flight-services/flight-list.service";

@Component({
  selector: 'app-flight-trip-item',
  templateUrl: './flight-trip-item.component.html',
  styleUrls: ['./flight-trip-item.component.scss']
})
export class FlightTripItemComponent implements OnInit {

  constructor(public dataService: DataService,
              public flightListService: FlightListService,
              public tripsService: TripsService) { }
  @Input() flightItem;
  ngOnInit(): void {
  }

}
