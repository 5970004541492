import {Component, Input, OnInit} from '@angular/core';
import {ApprovalsService} from "../../../../services/approvals.service";
import {BookingWalletsService} from "../../../../services/booking/booking-wallets.service";
import {DataService} from "../../../../services/data.service";
import {UserService} from "../../../../services/user.service";
import {VariablesService} from "../../../../services/vars.service";

@Component({
  selector: 'app-hotel-approval-info',
  templateUrl: './hotel-approval-info.component.html',
  styleUrls: ['./hotel-approval-info.component.scss']
})
export class HotelApprovalInfoComponent implements OnInit {
  @Input() isRequester: boolean = false;
  Math = Math;
  constructor(public approvalsService: ApprovalsService,
              public bookingWalletsService: BookingWalletsService,
              public dataService: DataService,
              public userService: UserService,
              public vars: VariablesService) { }

  ngOnInit(): void {
  }

}
