import { Injectable } from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {SearchUser} from "../../shared/model/search-user";
import {environment} from "../../../environments/environment";
import {DataService} from "../data.service";
import {AuthServiceMain} from "../../auth/auth.service";
import {FormControl} from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class SearchUserSelectionService {
    selectedUser;
    travelerId;
    submitted = false;
    userInfoSubscription: Subscription;
    showUsersAutoCompletePopUp = false;
    data;
    userControl = new FormControl('');
    currentLoggedInUser;
    guest: SearchUser = {
        description: $localize`:@@guestSelect:Guest`,
        firstName: "",
        lastName: "",
        id: "guest",
        email: null,
        inactive: false,
        profileImageBackgroundHexColor: "#" + "5aa4e1",
        profileImage: "assets/img/guest.svg",
        teamName: null,
        selected: false,
    };
    disableAction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(public restApi: DataService,
                public Auth: AuthServiceMain) {
    }
    loadTraveler(){
        if (this.Auth.isOwner() || this.Auth.isAdmin()) {
            if (this.travelerId && this.travelerId !== "guest") {
                this.userInfoSubscription = this.restApi.getCompanyUserDetails(this.travelerId).subscribe(
                    (data) => {
                        this.data = data;
                        if (this.data.errorCode === 0) {
                            let element = this.data.data;
                            this.selectedUser = new SearchUser({
                                description: `${element.firstName} ${element.lastName}`,
                                id: element.id,
                                email: element.email,
                                teamName: element.teamName,
                                selected: false,
                                firstName: element.firstName,
                                lastName: element.lastName,
                                inactive: element.inactive,
                                profileImageBackgroundHexColor: element.profileImageBackgroundHexColor,
                                profileImage: element.profileImage,
                            });

                            this.userControl.setValue(this.selectedUser.description);
                        } else {
                            this.restApi.messages(this.data.message, "error");
                        }
                    },
                    (error) => {
                        this.restApi.messages(environment.messages.error, "error");
                    }
                );
            } else if (this.travelerId === "guest") {
                this.selectedUser = this.guest;
                this.userControl.setValue(this.guest.description);
            }
        } else {
            this.selectedUser = this.currentLoggedInUser;
            this.userControl.setValue(this.selectedUser.description);
            this.disableAction.next(true);
            this.userControl.disable();
        }
    }
}











