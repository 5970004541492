import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VariablesService } from "./vars.service";
import { ServiceBase } from './base.service';
import { StatisticsFilter } from '../shared/model/statistics/statistics';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService extends ServiceBase {
  costManegementApis;

  constructor(private http: HttpClient, public vars: VariablesService) {
    super(vars, vars.apiUrls.company);

    this.costManegementApis = vars.statisticsApis
  }

  getBookingsStatistics(filter: StatisticsFilter) {
    this.setfilter(filter);
    const url = this.getMethodUrl(this.costManegementApis.getBookingsStatistics);
    return this.http.post(url, '');
  }
}