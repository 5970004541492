<div class="workspace-home" *ngIf="this.auth.companyInfo">
  <div class="container" *ngIf="dataService.mobileView">
    <div class="mx-lg-5">
      <p
        class="font-weight-bold mt-5 primaray-text font-32-to-24"
        i18n="@@dashboardMsg1"
      >
        Book your next business trip
      </p>
    </div>
  </div>

  <div class="search-container mb-5">
    <div class="container" *ngIf="dataService.desktopView">
      <div class="mx-lg-5 pt-3">
        <p
          class="font-weight-bold text-white font-32-to-24 pt-4 mb-5"
          i18n="@@dashboardMsg1"
        >
          Book your next business trip
        </p>
      </div>
    </div>
    <div [ngClass]="{ 'container search-padding': dataService.desktopView }">
      <div
        class="mx-lg-5"
        [ngClass]="{
          'mobile-tab pt-5': dataService.mobileView,
          'pb-5':
            dataService.mobileView &&
            (!this.dataService.historyArray ||
              !this.dataService.historyArray.length)
        }"
      >
        <ngb-tabset [destroyOnHide]="false">
          <ngb-tab title="{{ hotels }}" id="hotels">
            <ng-template ngbTabContent class="tab-content">
              <div [ngClass]="{ 'd-flex': dataService.desktopView }">
                <div
                  class="search_box p-md-5 px-3 py-5 pt-lg-4 pb-lg-4 px-lg-4 position-relative"
                  *ngIf="this.dataService.desktopView"
                  [ngClass]="{
                    'rounded-bottom-left rounded-right':
                      dataService.isSiteLtr(),
                    'rounded-bottom-right rounded-left': dataService.isSiteRtl()
                  }"
                >
                  <app-search-controller
                    *ngIf="auth.userInfo?.role"
                    [getHistory]="true"
                  ></app-search-controller>
                </div>
                <div
                  class="p-md-5 px-3 py-5 pt-lg-4 pb-lg-4 mx-3 px-lg-4 bg-white position-relative"
                  *ngIf="this.dataService.mobileView"
                >
                  <app-search-controller-mobile
                    *ngIf="auth.userInfo?.role"
                  ></app-search-controller-mobile>
                </div>

                <div
                  class="history-box p-4 p-lg-5 mt-5 mt-lg-0 bg-white"
                  *ngIf="
                    this.dataService.historyArray &&
                    this.dataService.historyArray?.length
                  "
                  [ngClass]="{
                    'rounded w-100 position-relative': dataService.desktopView,
                    'ml-1': dataService.isSiteLtr() && dataService.desktopView,
                    'mr-1': dataService.isSiteRtl() && dataService.desktopView
                  }"
                >
                  <div
                    [ngClass]="{
                      'white-box position-relative': dataService.mobileView
                    }"
                  >
                    <p
                      class="font-16 gray-primaray-text font-weight-bold"
                      i18n="@@dashboardMsg2"
                    >
                      Continue your search
                    </p>
                    <div
                      *ngFor="
                        let item of this.dataService.historyArray;
                        let i = index
                      "
                    >
                      <div
                        class="mb-4 pointer"
                        (click)="openHistoryUrl(item)"
                        *ngIf="i < 3"
                      >
                        <p
                          class="font-16 gray-text-dark mb-1 dotted-text dotted-text-direction"
                        >
                          <i
                            class="icon-hotel-item font-20 gray-text"
                            [ngClass]="{
                              'mr-1': dataService.isSiteLtr(),
                              'ml-1': dataService.isSiteRtl()
                            }"
                          ></i>
                          {{ item.caption }}
                        </p>
                        <p class="font-12 gray-text">
                          {{ dataService.formatDateEEMMMdy(item.checkIn) }}
                          <span i18n="@@to">To</span>
                          {{ dataService.formatDateEEMMMdy(item.checkOut) }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="close-history pointer position-absolute blue-text p-2 font-12"
                      (click)="clearHistory()"
                    >
                      <span i18n="@@clearBtn">Clear</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>

          <ngb-tab title="{{ flight }}" id="flights">
            <ng-template ngbTabContent>
              <div [ngClass]="{ 'd-flex': dataService.desktopView }">
                <div
                  class="search_box p-md-5 px-3 py-5 pt-lg-4 pb-lg-4 px-lg-4 position-relative"
                  [ngClass]="{
                    'rounded-bottom-left rounded-right':
                      dataService.isSiteLtr(),
                    'rounded-bottom-right rounded-left':
                      dataService.isSiteRtl(),
                    'multi-city-width':
                      this.dataService.selectedTrip === 'Multi-city'
                  }"
                  *ngIf="this.dataService.desktopView"
                >
                  <app-flights-search-controller
                    *ngIf="auth.userInfo?.role"
                    [fromHomePage]="true"
                  ></app-flights-search-controller>
                </div>
                <div
                  class="p-md-5 px-3 pb-5 pt-4 mx-3 bg-white position-relative"
                  *ngIf="this.dataService.mobileView"
                >
                  <app-flights-search-controller-mobile
                    *ngIf="auth.userInfo?.role"
                  ></app-flights-search-controller-mobile>
                </div>
                <div
                  class="history-box p-4 p-lg-5 mt-5 mt-lg-0 bg-white"
                  *ngIf="flightHistoryArrayEmpty"
                  [ngClass]="{
                    'rounded w-100 position-relative': dataService.desktopView,
                    'ml-1': dataService.isSiteLtr() && dataService.desktopView,
                    'mr-1': dataService.isSiteRtl() && dataService.desktopView
                  }"
                >
                  <div
                    [ngClass]="{
                      'white-box position-relative': dataService.mobileView
                    }"
                  >
                    <p
                      class="font-16 gray-primaray-text font-weight-bold"
                      i18n="@@dashboardMsg2"
                    >
                      Continue your search
                    </p>
                    <div
                      *ngFor="
                        let item of this.dataService.flightHistoryArray;
                        let i = index
                      "
                    >
                      <div
                        class="mb-4 pointer"
                        (click)="openFlightHistoryUrl(item)"
                        *ngIf="i < 3"
                      >
                        <p
                          class="font-16 gray-text-dark mb-1 dotted-text dotted-text-direction"
                        >
                          <i
                            class="icon-flight-item font-14 gray-text"
                            [ngClass]="{
                              'mr-1': dataService.isSiteLtr(),
                              'ml-1': dataService.isSiteRtl()
                            }"
                          ></i>
                          {{ item.roundTrip ? "Roundtrip" : "One-way" }} ({{
                            item.airQueries[0].departureSearchCode
                          }}
                          - {{ item.airQueries[0].arrivalSearchCode
                          }}<ng-container *ngIf="item.roundTrip">
                            -
                            {{
                              item.airQueries[0].departureSearchCode
                            }}</ng-container
                          >)
                        </p>
                        <p class="font-12 gray-text">
                          {{
                            dataService.formatDateEEMMMdy(
                              item.airQueries[0].departureDate
                            )
                          }}
                          <ng-container *ngIf="item.airQueries[0].returnDate"
                            ><span i18n="@@to">to</span>
                            {{
                              dataService.formatDateEEMMMdy(
                                item.airQueries[0].returnDate
                              )
                            }}</ng-container
                          >
                        </p>
                      </div>
                    </div>
                    <div
                      class="close-history pointer position-absolute blue-text p-2 font-12"
                      (click)="clearFlightHistory()"
                    >
                      <span i18n="@@clearBtn">Clear</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>

  <div class="container mt-14 dashboard-page">
    <div class="mx-lg-5">
      <div
        class="custom-container"
        *ngIf="upcomingBooking && upcomingBooking.length"
      >
        <div class="max-width-600">
          <p class="primaray-text font-26 pt-4 bold-600" i18n="@@dashboardMsg3">
            Your next trips
          </p>

          <div *ngFor="let item of upcomingBooking; let i = index">
            <div *ngIf="item.bookingType === 'HOTEL'">
              <app-hotel-trip-item [item]="item"></app-hotel-trip-item>
            </div>

            <div *ngIf="item.bookingType === 'FLIGHT'">
              <app-flight-trip-item [flightItem]="item"
                                    *ngIf="item.bookingResult.data.flightOffers[0].itineraries[0].searchSegmentSummary">
              </app-flight-trip-item>
            </div>
          </div>
        </div>


      </div>

      <div [ngClass]="{ 'custom-container': dataService.desktopView }">
        <div
          class="mb-5 pb-4"
          *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
        >
          <div
            *ngIf="
              !this.dataService.companyPoliciesList ||
              (this.usersList && this.usersList.length === 1)
            "
          >
            <ng-container
              *ngIf="
                (this.usersList && this.usersList.length === 1) ||
                (!this.dataService.companyPoliciesList &&
                  this.dataService.policyLoader)
              "
            >
              <p
                class="primaray-text font-26 mt-5 pt-4 bold-600"
                *ngIf="dataService.desktopView"
                i18n="@@dashboardMsg4"
              >
                Complete your corporate workspace
              </p>
              <p
                class="primaray-text font-18 mt-5 pt-4 bold-600"
                *ngIf="dataService.mobileView"
                i18n="@@dashboardMsg4"
              >
                Complete your corporate workspace
              </p>
            </ng-container>
            <div
              class="border my-4 gray-primaray-text bold-600 px-4 px-lg-5 py-4 company-section-with-thick-border"
              [ngClass]="{
                'rounded-right': dataService.desktopView,
                'thick-top rounded-bottom': dataService.mobileView
              }"
              *ngIf="this.usersList && this.usersList.length === 1"
            >
              <div
                class="row py-2 h-100 justify-content-center align-items-center"
              >
                <div class="col-md-7">
                  <p
                    class="font-18-to-16 gray-primaray-text bold-600"
                    i18n="@@dashboardMsg5"
                  >
                    Build your team
                  </p>
                  <span
                    class="font-14 font-weight-normal gray-text-dark"
                    i18n="@@dashboardMsg6"
                    >Invite coworkers, create and manage teams to organize your
                    workspace.</span
                  >
                </div>
                <div class="col-md-5 text-right">
                  <a
                    (click)="
                      this.router.navigate([this.router.url + '/members'])
                    "
                    class="btn-block white px-5 py-3 mt-4 mb-3 mt-lg-0 mb-lg-0 rounded text-center primaray-text sharp-font bold-500 bg-white desktop-btn"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                    i18n="@@inviteNow"
                    >Invite Now</a
                  >
                </div>
              </div>
            </div>
            <div
              class="my-4 border px-4 px-lg-5 py-4 company-section-with-thick-border"
              [ngClass]="{
                'rounded-right': dataService.desktopView,
                'thick-top rounded-bottom': dataService.mobileView
              }"
              *ngIf="
                !this.dataService.companyPoliciesList &&
                this.dataService.policyLoader &&
                (this.auth.isAdmin() || this.auth.isOwner())
              "
            >
              <div
                class="row py-2 h-100 justify-content-center align-items-center"
              >
                <div class="col-md-7">
                  <p
                    class="font-18-to-16 gray-primaray-text bold-600"
                    i18n="@@dashboardMsg7"
                  >
                    Set Travel Policies
                  </p>
                  <span
                    class="font-14 font-weight-normal gray-text-dark"
                    i18n="@@dashboardMsg8"
                    >Create your corporate travel policies, set booking limits
                    and approvals.</span
                  >
                </div>
                <div class="col-md-5 text-right">
                  <a
                    (click)="
                      this.router.navigate([
                        this.router.url + '/travel-policies'
                      ])
                    "
                    class="btn-block white px-4 py-3 mt-4 mb-3 mt-lg-0 mb-lg-0 rounded text-center primaray-text sharp-font bold-500 bg-white desktop-btn"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                    i18n="@@createPolicy"
                  >
                    Create Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <app-dashboard></app-dashboard>-->
      </div>
      <ng-container *ngIf="env.rewardsOnSystemLevel !== false">
        <div
          class="mb-5 pb-5 pt-4 rewards-bg"
          *ngIf="
            !this.auth.companyInfo.rewardProgram &&
            (this.auth.isAdmin() || this.auth.isOwner())
          "
        >
          <p
            class="primaray-text font-26 mb-5 pt-4 bold-600"
            *ngIf="dataService.desktopView"
            i18n="@@dashboardMsg9"
          >
            Save more by rewarding your employees
          </p>
          <p
            class="primaray-text font-18 mb-5 pt-4 bold-600"
            *ngIf="dataService.mobileView"
            i18n="@@dashboardMsg9"
          >
            Save more by rewarding your employees
          </p>
          <div class="d-flex mb-5">
            <div
              class="number-circle primaray-text font-16 bold-600 text-center"
              [ngClass]="{
                'mr-3': dataService.isSiteLtr(),
                'ml-3': dataService.isSiteRtl()
              }"
            >
              1
            </div>
            <div>
              <p
                class="gray-primaray-text font-16 mb-0 bold-600"
                i18n="@@dashboardMsg10"
              >
                Set Travel Budget
              </p>
              <p class="gray-text-dark mb-0" i18n="@@dashboardMsg11">
                Set your Travel Policy cap for travel
              </p>
            </div>
          </div>

          <div class="d-flex mb-5">
            <div
              class="number-circle primaray-text font-16 bold-600 text-center"
              [ngClass]="{
                'mr-3': dataService.isSiteLtr(),
                'ml-3': dataService.isSiteRtl()
              }"
            >
              2
            </div>
            <div>
              <p
                class="gray-primaray-text font-16 mb-0 bold-600"
                i18n="@@dashboardMsg12"
              >
                Give Rewards
              </p>
              <p class="gray-text-dark mb-0" i18n="@@dashboardMsg13">
                Incentives your employees to book cheaper travel
              </p>
            </div>
          </div>

          <div class="d-flex mb-4">
            <div
              class="number-circle primaray-text font-16 bold-600 text-center"
              [ngClass]="{
                'mr-3': dataService.isSiteLtr(),
                'ml-3': dataService.isSiteRtl()
              }"
            >
              3
            </div>
            <div>
              <p
                class="gray-primaray-text font-16 mb-0 bold-600"
                i18n="@@dashboardMsg14"
              >
                Save More
              </p>
              <p class="gray-text-dark mb-0" i18n="@@dashboardMsg15">
                Employees book cheaper travel to get percentage of savings
              </p>
            </div>
          </div>
          <a
            *ngIf="dataService.desktopView"
            class="font-16 blue-text mb-5"
            [ngClass]="{
              'ml-5 pl-4': dataService.isSiteLtr(),
              'mr-5 pr-4': dataService.isSiteRtl()
            }"
            (click)="
              this.router.navigate([this.router.url + '/settings/rewards'])
            "
            i18n="@@createProg"
            >Create My Program</a
          >
          <div *ngIf="dataService.mobileView" class="container">
            <button
              class="btn btn-outline-blue font-14 sharp-font px-3 w-100 px-5"
              (click)="
                this.router.navigate([this.router.url + '/settings/rewards'])
              "
              i18n="@@createProg"
            >
              Create My Program
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
