import { Component, OnInit } from '@angular/core';
import {ApprovalsService} from "../../../../../services/approvals.service";
import {DataService} from "../../../../../services/data.service";
import {UserService} from "../../../../../services/user.service";

@Component({
  selector: 'app-request-operations',
  templateUrl: './request-operations.component.html',
  styleUrls: ['./request-operations.component.scss']
})
export class RequestOperationsComponent implements OnInit {

  constructor(public approvalsService: ApprovalsService,
              public dataService: DataService,
              public userService: UserService) { }
  ngOnInit(): void {
  }

}
