<div class="mt-6rem">
    <div class="mx-0 row mx-lg-4 mt-5 px-custom">
        <div class="col-md-8 custom-col8 pt-3">
            <app-flight></app-flight>
        </div>

        <div class="col-md-4 custom-col4 pt-3" [ngClass]="{'more-custom-bottom-margin': dataService.mobileView}">
            <app-flight-pricing [pricing]="(this.flightService.flightPricingDetails | async)" [isFLightInfo]="true"></app-flight-pricing>
            <app-accepted-cards-section [hideSecurityTextInCards]="true"></app-accepted-cards-section>
        </div>
    </div>
</div>


