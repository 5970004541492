<div [ngClass]="{'container pb-5 pt-4': dataService.mobileView}">
  <div *ngIf="this.reactivateDate && this.dataService.workspaceSubscriptionDetails" class="pb-5 mb-3" [ngClass]="{'text-right': dataService.isSiteRtl()}">

    <ng-container i18n="@@reactivate1">By reactivating this account you will be charged</ng-container> ${{reactivateDate.immediate_fees | number: '1.0-2'}} <ng-container i18n="@@reactivate2">and your next bill will be</ng-container> ${{reactivateDate.next_invoice}} <ng-container i18n="@@on">on</ng-container> <span [innerHTML]="dataService.formatDateMMMdy(this.dataService.workspaceSubscriptionDetails.current.nextBillingDate)"></span>
  </div>

  <div class="d-flex justify-content-end" *ngIf="dataService.desktopView && !this.errorMsg">
    <button class="btn-outline-gray btn px-5 font-14" (click)="this.dataService.modalOf(); " i18n="@@cancelBtn"> Cancel </button>
    <button class="blue_btn px-5" [ngClass]="{'m-progress': submitting,
    'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"
            (click)="confirm()" i18n="@@confirmBtn">
      Confirm
    </button>
  </div>
  <div *ngIf="dataService.mobileView && !this.errorMsg">
    <button class="blue_btn px-5 w-100 mb-3" [ngClass]="{'m-progress': submitting }"
            (click)="confirm()" i18n="@@confirmBtn">
      Confirm
    </button>
    <button class="btn-outline-gray btn px-5 font-14 w-100" (click)=" this.dataService.BottomSheetOf();" i18n="@@cancelBtn"> Cancel </button>
  </div>
  <p class="text-danger mt-3">{{ this.errorMsg}}</p>
</div>
