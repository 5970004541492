import {Component, OnInit, ElementRef, ViewChild, Input, OnDestroy} from "@angular/core";
import * as moment from "moment";
import { FormControl, FormBuilder, FormGroup } from "@angular/forms";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthServiceMain } from "../../../auth/auth.service";
import { v4 as uuid } from "uuid";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { GoogleAnalyticsService } from "../../../google-analytics.service";
import { DatePipe } from "@angular/common";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter, NgbDatepicker,
  NgbInputDatepicker,
} from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../../../../environments/environment";
import {SearchUserSelectionService} from "../../../services/search-user-selection/search-user-selection.service";
import {SearchUser} from "../../../shared/model/search-user";


@Component({
  selector: "app-search-controller",
  templateUrl: "./search-controller.component.html",
  styleUrls: ["./search-controller.component.scss"],
  host: {
    "(document:click)": "onClickBody($event)",
  },
})
export class SearchControllerComponent implements OnInit, OnDestroy{
  datePickerErrorMsg: string;
  moreThan30Nights: boolean = false;
  @Input() getHistory: boolean = false;
  hoveredDate: NgbDate | null = null;
  checkout: NgbDate | null;
  checkin: NgbDate | null;
  data;
  home = false;
  oldsearchQuery;
  oldcaption;
  destination = "";
  queryParams;
  autocompletelist;
  CheckoutRangeFormatted;
  onclickActive = true;
  CheckinRangeFormatted;
  decreaseChildrenDisabled = true;
  decreaseAdultDisabled = false;
  CheckoutRange;
  showDropdown = false;
  CheckinRange;
  searchQuery;
  adultCount = 2;
  paxCount = 2;
  childrenCount = 0;
  decreaseDisabled = false;
  increasDisabled = false;
  Query = "";
  staynights;
  entity;
  caption = "";
  children = [];
  ages = [];
  searchForm: FormGroup;
  submitted = false;
  validChild = false;
  viewMode;
  Childrenpax = "";
  childrenList = [];
  availability = false;
  hotel = false;
  SearchTextQuery = new Subject<string>();
  readyToSearch = true;
  submitting = false;



  //@ViewChild("datepicker") datePicker: NgbInputDatepicker;
  @ViewChild('datepicker', { static: false }) datepicker!: NgbDatepicker;
  constructor(
      public Auth: AuthServiceMain,
    public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public datePipe: DatePipe,
    public googleAnalyticsService: GoogleAnalyticsService,
    private formBuilder: FormBuilder,
    public restApi: DataService,
    public router: Router,
    public route: ActivatedRoute,
    private eref: ElementRef,
      public searchUserSelectionService: SearchUserSelectionService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  navigateToMonth() {
    if (this.checkin) {
      this.datepicker.navigateTo(this.checkin); // Navigate to the desired date
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.checkin && !this.checkout) {
      this.checkin = date;
      this.CheckinRange = moment(date).format("YYYY-MM-DD");
    } else if (
      this.checkin &&
      !this.checkout &&
      date &&
      date.after(this.checkin)
    ) {
      this.checkout = date;
      this.CheckoutRange = moment(date).format("YYYY-MM-DD");
    } else {
      this.checkout = null;
      this.checkin = date;
      this.CheckinRange = moment(date).format("YYYY-MM-DD");
    }
    if (this.checkout && this.checkin) {
      this.CheckinRange = moment(this.checkin).format("YYYY-MM-DD");
      this.CheckoutRange = moment(this.checkout).format("YYYY-MM-DD");
      this.CheckinRangeFormatted = this.datePipe.transform(
        this.formatter.format(this.checkin),
        "EE, MMM dd"
      );
      this.CheckoutRangeFormatted = this.datePipe.transform(
        this.formatter.format(this.checkout),
        "EE, MMM dd"
      );
      let checkOutDate = moment([
        this.checkout.year,
        this.checkout.month - 1,
        this.checkout.day,
      ]);
      let checkInDate = moment([
        this.checkin.year,
        this.checkin.month - 1,
        this.checkin.day,
      ]);
      this.staynights = checkOutDate.diff(checkInDate, "days");
      if (this.checkout.month !== this.checkin.month) {
        this.staynights = this.staynights + 1;
      }

      if(this.staynights <= 30){
        this.datePickerErrorMsg = "";
        this.moreThan30Nights = false;
      }

    }
  }

  isHovered(date: NgbDate) {
    return (
      this.checkin &&
      !this.checkout &&
      this.hoveredDate &&
      date.after(this.checkin) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      this.checkout && date.after(this.checkin) && date.before(this.checkout)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.checkin) ||
      (this.checkout && date.equals(this.checkout)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  ngOnInit() {
    this.searchUserSelectionService.disableAction.next(false);
    this.searchUserSelectionService.userControl.enable();
    this.searchUserSelectionService.selectedUser = null;
    this.searchUserSelectionService.userControl.setValue(null);
    this.searchUserSelectionService.submitted = false;
    if(this.router.url.includes('availability')){
      this.route.queryParams.subscribe((params) => {
        this.searchUserSelectionService.travelerId =  params['travelerId'];
        this.searchUserSelectionService.loadTraveler();
      });
    }
    this.searchUserSelectionService.submitted = false;
    this.checkGetHistory();
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("search-page");

    this.searchForm = this.formBuilder.group({}, {});
    if (
      this.restApi.urlWithoutParams === "/availability") {
      this.availability = true;
    }
    if (
      this.restApi.urlWithoutParams === "/availability/hotel") {
      this.hotel = true;
    }
    if (
      this.restApi.urlWithoutParams === "/") {
      this.home = true;
    }
    this.route.queryParams.subscribe((params) => {
      let searchDates = undefined;
      if (JSON.parse(localStorage.getItem("searchDates"))) {
        searchDates = JSON.parse(localStorage.getItem("searchDates"));
        if (moment(searchDates.checkIn).isBefore(moment(), "day")) {
          localStorage.setItem(
            "searchDates",
            JSON.stringify({
              checkIn: moment().add(14, "days").format("YYYY-MM-DD"),
              checkOut: moment().add(17, "days").format("YYYY-MM-DD"),
            })
          );
          searchDates = {
            checkIn: moment().add(14, "days").format("YYYY-MM-DD"),
            checkOut: moment().add(17, "days").format("YYYY-MM-DD"),
          };
        }
      }
      this.queryParams = params;
      if (params.checkinOffset && !params.checkIn) {
        const checkinOffset = Number(params.checkinOffset);
        this.CheckinRange = moment()
          .add(checkinOffset, "days")
          .format("YYYY-MM-DD");
        if (!params.checkOut) {
          this.CheckoutRange = moment()
            .add(3 + checkinOffset, "days")
            .format("YYYY-MM-DD");
        }
      }
      if (params.nights && !params.checkOut) {
        const nights = Number(params.nights);

        if (params.checkIn) {
          this.CheckoutRange = moment(params.checkIn)
            .add(nights, "days")
            .format("YYYY-MM-DD");
          this.CheckinRange = moment(params.checkIn).format("YYYY-MM-DD");
        } else {
          this.CheckoutRange = moment(this.CheckinRange)
            .add(nights, "days")
            .format("YYYY-MM-DD");
        }
      }
      if (!params.nights && !params.checkinOffset && !params.checkIn) {
        if (searchDates) {
          this.CheckinRange = searchDates.checkIn;
          this.CheckoutRange = searchDates.checkOut;
        } else {
          this.CheckinRange = moment().add(14, "days").format("YYYY-MM-DD");

          this.CheckoutRange = moment().add(17, "days").format("YYYY-MM-DD");
        }
      }
      if (
        params.checkOut === "null" ||
        params.checkIn === "null" ||
        moment(params.checkIn).isBefore(moment(), "day") ||
        !moment(params.checkIn).isValid() ||
        !moment(params.checkOut).isValid() ||
        moment.utc(params.checkOut).diff(moment.utc(params.checkIn), "days") <=
          0
      ) {
        if (
          !this.CheckinRange ||
          moment(this.CheckinRange).isBefore(moment(), "day")
        ) {
          this.CheckinRange = moment().add(14, "days").format("YYYY-MM-DD");
        }
        if (
          !this.CheckoutRange ||
          moment(this.CheckoutRange).isBefore(moment(), "day")
        ) {
          this.CheckoutRange = moment().add(17, "days").format("YYYY-MM-DD");
        }
      } else if (
        params.checkIn !== undefined &&
        params.checkOut !== undefined &&
        params.paxes !== undefined
      ) {
        this.CheckoutRange = moment(params.checkOut).format("YYYY-MM-DD");
        this.CheckinRange = moment(params.checkIn).format("YYYY-MM-DD");
      } else {
        if (params.checkIn === undefined && this.CheckinRange === "") {
          if (searchDates) {
            this.CheckinRange = searchDates.checkIn;
          } else {
            this.CheckinRange = moment().add(14, "days").format("YYYY-MM-DD");
          }
        } else if (!this.CheckinRange) {
          this.CheckinRange = moment(params.checkIn).format("YYYY-MM-DD");
        }

        if (params.checkOut === undefined && !this.CheckoutRange) {
          if (searchDates) {
            this.CheckoutRange = searchDates.checkOut;
          } else {
            this.CheckoutRange = moment().add(17, "days").format("YYYY-MM-DD");
          }
        } else if (!this.CheckoutRange) {
          this.CheckoutRange = moment(params.checkOut).format("YYYY-MM-DD");
        }
      }
      const days = moment
        .utc(this.CheckoutRange)
        .diff(moment.utc(this.CheckinRange), "days");
      if (days <= 0) {
        this.CheckinRange = moment().add(14, "days").format("YYYY-MM-DD");
        this.CheckoutRange = moment().add(17, "days").format("YYYY-MM-DD");
      }
      let splitDateIn = this.CheckinRange.split("-");
      let checkInDateObj: any;
      checkInDateObj = {
        year: Number(splitDateIn[0]),
        month: Number(splitDateIn[1]),
        day: Number(splitDateIn[2]),
      };
      this.checkin = this.calendar.getNext(checkInDateObj, "d", 0);

      let splitDateOut = this.CheckoutRange.split("-");
      let checkOutDateObj: any;
      checkOutDateObj = {
        year: Number(splitDateOut[0]),
        month: Number(splitDateOut[1]),
        day: Number(splitDateOut[2]),
      };
      this.checkout = this.calendar.getNext(checkOutDateObj, "d", 0);
      this.CheckinRangeFormatted = this.datePipe.transform(
        this.formatter.format(this.checkin),
        "EE, MMM dd"
      );
      this.CheckoutRangeFormatted = this.datePipe.transform(
        this.formatter.format(this.checkout),
        "EE, MMM dd"
      );
      let checkOutDate = moment([
        this.checkout.year,
        this.checkout.month - 1,
        this.checkout.day,
      ]);
      let checkInDate = moment([
        this.checkin.year,
        this.checkin.month - 1,
        this.checkin.day,
      ]);
      this.staynights = checkOutDate.diff(checkInDate, "days");
      if (this.checkout.month !== this.checkin.month) {
        this.staynights = this.staynights + 1;
      }
      if (this.queryParams.paxes) {
        const childrenArr = this.queryParams.paxes.split(",");
        for (let i = 1; i < childrenArr.length; i++) {
          this.childrenList.push({ childList: childrenArr[i] });
          this.childrenCount++;
          this.decreaseChildrenDisabled = false;
          this.increasCheck();
        }
        this.adultCount = Number(childrenArr[0]);
        this.paxCount = childrenArr.length - 1 + Number(childrenArr[0]);
      }
      //------------------ added to get paxes in dashboard from localStorage
      if (!this.queryParams.paxes) {
        if (localStorage.getItem("hotelPaxes")) {
          const childrenArr = localStorage.getItem("hotelPaxes").split(",");
          for (let i = 1; i < childrenArr.length; i++) {
            this.childrenList.push({ childList: childrenArr[i] });
            this.childrenCount++;

            this.decreaseChildrenDisabled = false;
            this.increasCheck();
          }
          this.adultCount = Number(childrenArr[0]);
          this.paxCount = childrenArr.length - 1 + Number(childrenArr[0]);
        }
      }
      //-------------------------------------------
      if (this.adultCount === 1) {
        this.decreaseAdultDisabled = true;
      }
    });
    this.SearchTextQuery.pipe(
      // get value
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.Query = value;
      this.readyToSearch = false;
      this.searchQuery = undefined;
      this.autoCompleteList();
    });
    for (let i = 1; i < 18; i++) {
      this.ages[i] = i.toString();
    }
    if (
      localStorage.getItem("history_storage") &&
      Array.isArray(JSON.parse(localStorage.getItem("history_storage"))) ===
        false
    ) {
      localStorage.removeItem("history_storage");
    }
    if (localStorage.getItem("history_storage") && !this.Auth.isLogedIn) {
      this.restApi.historyArray = JSON.parse(
        localStorage.getItem("history_storage")
      );
      if (
        this.hotel === false &&
        this.availability === false &&
        this.restApi.historyArray[0]
      ) {
        this.Query = this.restApi.historyArray[0].caption;
        this.caption = this.restApi.historyArray[0].caption;
        setTimeout(() => {
          this.viewMode = this.restApi.historyArray[0].viewMode;
          this.searchQuery = this.restApi.historyArray[0].searchQuery;
        }, 500);
      }
    }
    this.oldcaption = this.restApi.caption;
    this.increasCheck();

  }

  checkGetHistory() {
    if (this.getHistory) {
      this.restApi.fetchSearchHistory("false").subscribe((success) => {
        this.data = success;
        if (this.data.errorCode === 0 && this.data.data) {
          this.restApi.historyArray = this.data.data.searches.slice(0, 5);
          if (
            this.hotel === false &&
            this.availability === false &&
            this.restApi.historyArray[0]
          ) {
            this.Query = this.restApi.historyArray[0].caption;
            this.caption = this.restApi.historyArray[0].caption;
            this.viewMode = this.restApi.historyArray[0].viewMode;
            setTimeout(() => {
              this.searchQuery = this.restApi.historyArray[0].searchQuery;
            }, 500);
          }
        }
      });
    }
  }

  getSearchHistory() {
    if (!this.restApi.historyArray) {
      this.restApi.fetchSearchHistory("false").subscribe((success) => {
        this.data = success;
        if (this.data.errorCode === 0 && this.data.data) {
          this.restApi.historyArray = this.data.data.searches.slice(0, 5);
          if (
            this.hotel === false &&
            this.availability === false &&
            this.restApi.historyArray[0]
          ) {
            this.Query = this.restApi.historyArray[0].caption;
            this.caption = this.restApi.historyArray[0].caption;
            this.viewMode = this.restApi.historyArray[0].viewMode;
            setTimeout(() => {
              this.searchQuery = this.restApi.historyArray[0].searchQuery;
            }, 500);
          }
        }
      });
    }
  }

  get f() {
    return this.searchForm.controls;
  }

  setSearchText(entity, dist) {
    this.Query = entity.caption;
    this.restApi.caption = entity.caption;
    this.caption = entity.caption;
    this.destination = dist;
    this.showDropdown = false;

    setTimeout(() => {
      this.autocompletelist = [];
      this.viewMode = "LIST";
      this.searchQuery = entity.searchQuery;

      this.readyToSearch = true;
      localStorage.setItem("lastSearch", JSON.stringify(entity));
    }, 500);
  }
  onSubmit() {
    this.searchUserSelectionService.submitted = true;
    this.submitted = true;
    this.datePickerErrorMsg = "";
    this.moreThan30Nights = false;
    if(this.staynights > 30){
      this.datePickerErrorMsg =  $localize`:@@maxStay:Maximum stay is 30 nights per reservation`;
      this.moreThan30Nights = true;
    }
   /* if (!this.selectedUser) {
      return;
    }*/
    this.restApi.alertsOverRoute = [];
    this.Childrenpax = "";
    for (let i = 0; i < this.childrenList.length; i++) {
      if (
        this.childrenList.length > 0 &&
        this.childrenList[i].childList === ""
      ) {
        this.validChild = true;
        //return false;
      } else {
        if (
          this.childrenList[i].childList !== "undefined" &&
          this.childrenList[i].childList !== undefined
        ) {
          this.Childrenpax += "," + this.childrenList[i].childList;

          this.validChild = false;
        } else {
          this.validChild = true;
          //return false;
        }
      }
    }
    this.oldsearchQuery = this.restApi.SearchQuery;
    if (this.caption === this.oldcaption || this.caption === "") {
      if (
        this.restApi.caption &&
        (this.readyToSearch || this.availability || this.hotel)
      ) {
        this.caption = this.restApi.caption;

        this.Query = this.restApi.caption;
      }

      if (this.restApi.SearchQuery && (this.availability || this.hotel)) {
        this.searchQuery = this.restApi.SearchQuery;
      } else if (!this.home) {
        this.searchQuery =
          this.restApi.searchCode + ";" + this.restApi.searchType;
      }
    }

    if (
      (this.searchQuery === this.oldsearchQuery ||
        this.searchQuery === undefined) &&
      this.readyToSearch
    ) {
      this.searchQuery = this.restApi.SearchQuery;
    }

    this.submitting = true;
    if (this.availability && this.viewMode === undefined) {
      this.viewMode = "LIST";
    }
    if (this.hotel && this.viewMode === undefined) {
      this.viewMode = "DETAILS";
    }

    if (this.availability || this.hotel) {
      if (
        this.restApi.paxes == this.adultCount + this.Childrenpax &&
        this.oldcaption === this.caption &&
        this.queryParams.checkIn ===
          moment(this.CheckinRange).format("YYYY-MM-DD") &&
        this.queryParams.checkOut ===
          moment(this.CheckoutRange).format("YYYY-MM-DD")
      ) {
        if (this.searchUserSelectionService.travelerId === this.searchUserSelectionService.selectedUser?.id) {
          this.Childrenpax = "";
          this.submitting = false;
          return false;
        }
      }
    }

    this.oldcaption = this.caption;

    let checkInMonth =
      (this.checkin.month < 10 ? "0" : "") + this.checkin.month;
    let checkInDay = (this.checkin.day < 10 ? "0" : "") + this.checkin.day;
    let checkOutMonth =
      (this.checkout.month < 10 ? "0" : "") + this.checkout.month;
    let checkOutDay = (this.checkout.day < 10 ? "0" : "") + this.checkout.day;

    let finalCheckOut =
      this.checkout.year + "-" + checkOutMonth + "-" + checkOutDay;
    let finalCheckIn =
      this.checkin.year + "-" + checkInMonth + "-" + checkInDay;

    // stop here if form is invalid
    let searchType = "";

    if (
      this.caption === "" ||
      this.caption === undefined ||
      this.searchQuery === undefined ||
      this.Query === "" ||
      this.searchQuery === "undefined;undefined" ||
        !this.searchUserSelectionService.selectedUser ||
        this.validChild ||
        this.moreThan30Nights

    ) {
      this.Childrenpax = "";
      this.submitting = false;
      return false;
    }

    // check if type list
    if (!this.restApi.isCompany()) {
      if (this.viewMode === "LIST") {
        searchType = "/availability";
      } else {
        searchType = "/availability/hotel";
      }
    } else {
      if (this.viewMode === "LIST") {
        searchType = "/availability";
      } else {
        searchType = "/availability/hotel";
      }
    }
    if (!this.restApi.historyArray) {
      const historyArr = [];
      historyArr.unshift({
        searchQuery: this.searchQuery,
        caption: this.caption,
        viewMode: this.viewMode,
      });
      localStorage.setItem("history_storage", JSON.stringify(historyArr));
    } else {
      let historyArr = this.restApi.historyArray;
      historyArr = historyArr.filter((cls) => {
        return !this.searchQuery.includes(cls.searchQuery);
      });
      // if(filteredClasses.length === 0) {

      if (historyArr.length === 5) {
        historyArr.splice(-1, 1);
        historyArr.unshift({
          searchQuery: this.searchQuery,
          caption: this.caption,
          viewMode: this.viewMode,
        });
        localStorage.setItem("history_storage", JSON.stringify(historyArr));
      } else {
        historyArr.unshift({
          searchQuery: this.searchQuery,
          caption: this.caption,
          viewMode: this.viewMode,
        });
        localStorage.setItem("history_storage", JSON.stringify(historyArr));
      }

      //  }
    }

    if (
      this.restApi.urlWithoutParams ===
      "/pass/best-hotel-rates-newsletter/welcome"
    ) {
      this.googleAnalyticsService.eventEmitter(
        "Newsletter-Subscription",
        "Search",
        this.destination,
        1
      );
    }
    if (
      this.restApi.urlWithoutParams === "/pass/free-trial-subscription/welcome"
    ) {
      this.googleAnalyticsService.eventEmitter(
        "Free-Trial-Subscription",
        "Search",
        this.destination,
        1
      );
    }
    localStorage.setItem(
      "searchDates",
      JSON.stringify({ checkIn: finalCheckIn, checkOut: finalCheckOut })
    );
    localStorage.setItem("hotelPaxes", this.adultCount + this.Childrenpax);
    this.router.navigate([searchType], {
      queryParams: {
        searchQuery: this.searchQuery,
        checkIn: finalCheckIn,
        checkOut: finalCheckOut,
        paxes: this.adultCount + this.Childrenpax,
        travelerId: this.searchUserSelectionService.selectedUser?.id,
      },
    });

    this.router.onSameUrlNavigation = "reload";

    this.Childrenpax = "";
  }

  decreaseCheck() {
    this.paxCount = this.childrenCount + this.adultCount;
  }
  increasCheck() {
    this.paxCount = this.childrenCount + this.adultCount;

    if (this.childrenCount + this.adultCount === 6) {
      this.increasDisabled = true;
      return true;
    } else {
      this.increasDisabled = false;
    }
  }

  decreaseAdult() {
    this.adultCount--;
    this.paxCount--;
    this.increasDisabled = false;
    this.decreaseCheck();
    if (this.adultCount === 1) {
      this.decreaseAdultDisabled = true;
      return true;
    } else {
      this.decreaseDisabled = false;
    }
  }

  increaseAdult() {
    this.adultCount++;
    this.paxCount++;
    this.decreaseAdultDisabled = false;
    this.increasCheck();
  }

  decreaseChildren() {
    //    this.removeButtonClick();
    this.childrenCount--;
    this.paxCount--;
    this.increasDisabled = false;

    this.childrenList.pop();
    this.decreaseCheck();
    if (this.childrenCount === 0) {
      this.decreaseChildrenDisabled = true;
      return true;
    } else {
      this.decreaseDisabled = false;
    }
  }
  increaseChildren() {
    this.childrenCount++;
    this.paxCount++;
    this.decreaseChildrenDisabled = false;
    this.increasCheck();
    this.childrenList.push({ childList: "" });
    // this.addButtonClick();
  }

  autoCompleteList() {
    if (this.Query && this.Query.length > 2) {
      this.restApi
        .autoComplete(this.Query, sessionStorage.getItem("session-token"))
        .subscribe((success) => {
          this.data = success;
          this.autocompletelist = this.data.data;
        });
    }
  }

  autocompleteOnFocus(event) {
    event.target.select();
    this.showDropdown = true;
    if (!sessionStorage.getItem("session-token")) {
      this.restApi.sessionToken = uuid();

      sessionStorage.setItem("session-token", this.restApi.sessionToken);
    }
  }

  getDynamicCheckOutDate() {
    if (!this.checkout) {
      this.checkout = this.calendar.getNext(this.checkin, "d", 1);
      this.CheckinRangeFormatted = this.datePipe.transform(
        this.formatter.format(this.checkin),
        "EE, MMM dd"
      );
      this.CheckoutRangeFormatted = this.datePipe.transform(
        this.formatter.format(this.checkout),
        "EE, MMM dd"
      );
      let checkOutDate = moment([
        this.checkout.year,
        this.checkout.month - 1,
        this.checkout.day,
      ]);
      let checkInDate = moment([
        this.checkin.year,
        this.checkin.month - 1,
        this.checkin.day,
      ]);
      this.staynights = checkOutDate.diff(checkInDate, "days");
      if (this.checkout.month !== this.checkin.month) {
        this.staynights = this.staynights + 1;
      }

      if(this.staynights <= 30){
        this.datePickerErrorMsg = "";
        this.moreThan30Nights = false;
      }

    }
  }

  onClickBody(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.getDynamicCheckOutDate();
      this.showDropdown = false;
      this.validChild = false;
      this.onclickActive = true;
    }
  }

  keepOnMenu() {
    setTimeout(() => {
      if (this.onclickActive === true) {
        //  this.validChild = true;
        this.onclickActive = false;
      }
    }, 10);
  }

  clearHistory() {
    if (this.Auth.isLogedIn) {
      this.restApi.fetchSearchHistory("true").subscribe();
    }
    if (localStorage.getItem("history_storage")) {
      localStorage.removeItem("history_storage");
    }
    this.restApi.historyArray = [];
  }

  onChangeSearch() {
    this.showDropdown = true;
  }

  ngOnDestroy(): void {
    if(this.searchUserSelectionService.userInfoSubscription){
      this.searchUserSelectionService.userInfoSubscription.unsubscribe();
    }
  }
}
