<div class="container pt-4 pb-lg-5">
  <form [formGroup]="docForm" id="docForm">
    <div class="row">
      <div class="col-md-9">
        <p class="mb-1 mt-3 required" [ngClass]="{'gray-text': dataService.desktopView, 'gray-primaray-text': dataService.mobileView}" i18n="@@passportCountry">Passport issuing country</p>
        <div class="form-group">
          <select class="form-control mt-2 select-custom-arrow" formControlName="selectedCountry" name="selectedCountry" (ngModelChange)="selectChangeHandler($event)"
                  [ngClass]="{ 'is-invalid': submitted && f.selectedCountry.errors }" >
            <ng-container *ngFor="let item of vars.countries">
              <option [ngValue]="item.code">{{item.name}}</option>
            </ng-container>
          </select>
          <div *ngIf="submitted && f.selectedCountry.errors" class="invalid-feedback">
            <div *ngIf="f.selectedCountry.errors.required" i18n="@@passportCountryRequired">Passport issuing country is required</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-9">
        <p class="mb-1 mt-3 required" [ngClass]="{'gray-text': dataService.desktopView, 'gray-primaray-text': dataService.mobileView}" i18n="@@passportNumber">Passport number</p>
        <div class="form-group">
          <input class="form-control" id="passportNumber" name="passportNumber" type="text" formControlName="passportNumber"
                 [ngClass]="{ 'is-invalid': submitted && f.passportNumber.errors }"/>
          <div *ngIf="submitted && f.passportNumber.errors" class="invalid-feedback">
            <div *ngIf="f.passportNumber.errors.required" i18n="@@passportNumberRequired">Passport number is required</div>
          </div>
        </div>
      </div>
    </div>



    <div class="row">
      <div class="col-md-9">
        <p class="mb-1 mt-3 required" [ngClass]="{'gray-text': dataService.desktopView, 'gray-primaray-text': dataService.mobileView}" i18n="@@passportExpiryDate">Passport expiry date</p>
        <div class="form-group">
          <div class="form-control position-relative" [ngClass]="{ 'is-invalid': submitted && f.expiryDate.errors }">
            <input formControlName="expiryDate"  matInput [matDatepicker]="picker" [min]="minDate" class="form-control pointer" autocomplete="off" (click)="picker.open();"
                   (dateChange)="expiryDate('change', $event)" style="opacity: 0">
            <span class="position-absolute format-date" (click)="picker.open();">{{formatExpiry}}</span>
            <mat-datepicker #picker></mat-datepicker>
          </div>
          <div *ngIf="submitted && f.expiryDate.errors" class="invalid-feedback">
            <div *ngIf="f.expiryDate.errors.required" i18n="@@expiryDateRequired">Expiry date is required</div>
          </div>
        </div>
      </div>
    </div>


    <div class="row pt-3 pt-lg-5 mt-lg-5">
      <div class="col-md-6 order-lg-1" [ngClass]="{'pl-lg-2': dataService.isSiteLtr(), 'pr-lg-2': dataService.isSiteRtl()}">
        <button *ngIf="!this.travelDocData"
          class="mt-3 px-0 btn-block sharp-font bold-500 d-flex px-0" (click)="addDoc()" [ngClass]="{'blue_btn':dataService.desktopView, 'btn btn-outline-blue w-100':dataService.mobileView}">
          <span class="mx-auto font-14" [ngClass]="{'m-progress': submitting}" i18n="@@addTravelDoc">Add Travel Document</span>
        </button>
        <button *ngIf="this.travelDocData"
                class="mt-3 px-0 btn-block sharp-font bold-500 d-flex px-0" (click)="editDoc()" [ngClass]="{'blue_btn':dataService.desktopView, 'btn btn-outline-blue w-100':dataService.mobileView}">
          <span class="mx-auto font-14" [ngClass]="{'m-progress': submitting}" i18n="@@save">Save</span>
        </button>
      </div>
      <div class="col-md-6 order-lg-0" [ngClass]="{'pr-lg-2': dataService.isSiteLtr(), 'pl-lg-2': dataService.isSiteRtl()}">
        <button (click)="dataService.rightSideDrawerOff(); dataService.BottomSheetOf()"
                class="mt-3 btn-block btn btn-outline-gray  px-5 d-flex ml-auto">
          <span class="mx-auto gray-text-dark font-14 sharp-font bold-500" i18n="@@cancelBtn">Cancel</span>
        </button>
      </div>
    </div>
    <p class="text-danger">{{this.errorMessage}}</p>
  </form>
</div>
