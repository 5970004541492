import {Component, ElementRef, Input, OnDestroy, OnInit, isDevMode} from '@angular/core';
import {DataService} from '../../../services/data.service';
import * as moment from 'moment';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {FormControl, FormGroup} from "@angular/forms";
import {GoogleAnalyticsService} from "../../../google-analytics.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {FlightListService} from "../../../services/flight-services/flight-list.service";
import {FlightService} from "../../../services/flight-services/flight.service";

@Component({
  selector: 'app-flight',
  templateUrl: './flight.component.html',
  styleUrls: ['./flight.component.scss']
})
export class FlightComponent implements OnInit, OnDestroy {

  constructor(public route: ActivatedRoute,
              public googleAnalyticsService: GoogleAnalyticsService,
              public router: Router,
              public flightListService: FlightListService,
              private flightService: FlightService,
              public dataService: DataService) { }

  itineraries;
  detailsRequestObj;
  detailsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  detailsSubscription: Subscription;
  dataResponse: any;
  //details: any;
  @Input() details: any;
  @Input() flightDataRequestObj;
  isFLightInfo: boolean = false;
  @Input() isVoucher: boolean = false;
  isDataAlreadyExist: boolean = false;
  fares;
  isDevMode: boolean;
  ngOnInit(): void {
    this.isDevMode = isDevMode();
    this.googleAnalyticsService.eventEmitter('FlightStats', 'FlightDetails', 'View', 1);
    this.dataService.errorMsg = '';

    if(this.details){ // show details here directly in the page
      if(this.isVoucher){
        this.itineraries = this.details.itineraries;
        this.fares = this.details.travelerPricings[0].fareDetailsBySegment;
      }
    } else { // show details here in rightSlideDrawer
      this.detailsRequestObj = this.dataService.rightSideDrawerData.requestObj;
      this.isFLightInfo = this.dataService.rightSideDrawerData.isFLightInfo;
      this.isDataAlreadyExist = this.dataService.rightSideDrawerData.isDataAlreadyExist;
      console.log(this.dataService.rightSideDrawerData);

      if(this.isDataAlreadyExist){
        this.details = this.dataService.rightSideDrawerData.flightDetails;
        this.itineraries = this.dataService.rightSideDrawerData.flightDetails.itineraries;
        this.fares = this.dataService.rightSideDrawerData.flightDetails.travelerPricings[0].fareDetailsBySegment;
      } else {
        this.getFlightDetails(this.detailsRequestObj);
      }
    }




    this.dataService.flightBookingPage = false;
    this.dataService.flightBookingSubmit = false;
  }

  getFlightDetails(detailsRequestObj: any){
    this.detailsLoading.next(true);
    this.detailsSubscription =  this.flightService.flightDetails(detailsRequestObj).subscribe(
        data => {
          this.dataResponse = data;
          if (this.dataResponse.errorCode === 0) {

            this.detailsLoading.next(false);
            this.details = this.dataResponse.data;
            this.itineraries = this.details.itineraries;
            this.fares = this.details.travelerPricings[0];

          } else {
            this.detailsLoading.next(false);
            this.dataService.messages(this.dataResponse.message, 'error');
          }
        },
        error => {
          this.detailsLoading.next(false);
          this.dataService.messages(environment.messages.error, 'error');
        }
    );
  }

  formatDate(date) {
    return  moment(date).format('ddd') + ', ' + moment(date).format('MMM') + ' ' + moment(date).format('DD');
  }

  // Check if the value is an object
  isObject(value: any) {
    return value !== null && typeof value === 'object';
  }

  // Get the object keys
  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  // Check if the value is an array
  isArray(value: any): boolean {
    return value instanceof Array;
  }

  bookingFlight() {
    this.googleAnalyticsService.eventEmitter(
        "FlightStats",
        "FareValidation",
        "Successful",
        1
    );


    sessionStorage.setItem(
        "travelerIdFlight",
        this.details.travelerPricings[0].userData.travelerId
    );

      this.dataService.flightData = {
        details: this.details
      };

      sessionStorage.setItem("flightData", JSON.stringify(this.dataService.flightData));

    sessionStorage.setItem("previousUrl", this.router.url);
    this.dataService.rightSideDrawerOff();
    this.router.navigate(["/flights/availability/booking"]);
  }

  showMoreInfo(amenities){
    if (this.dataService.desktopView) {
      this.dataService.setModal('', 'amenities', amenities);
    } else {
      this.dataService.setBottomSheet('amenities', amenities);
    }
  }

  ngOnDestroy() {
    if(this.detailsSubscription){
      this.detailsSubscription.unsubscribe();
    }
  }
}
