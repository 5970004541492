<div class="pb-5" [ngClass]="{'container': dataService.desktopView}">
<form [formGroup]="editAliasForm" (ngSubmit)="submitAliasForm()" >
  <div [ngClass]="{'w-75' : this.dataService.desktopView}">
    <p class="gray-primaray-text mb-2 mt-4" i18n="@@alias" [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">Alias</p>
  <div class="form-group form-type-textfield form-name-alias">
    <input type="text" name="alias" formControlName="alias" class="form-control">
  </div>
  </div>
  <div class="d-flex justify-content-end mt-5" *ngIf="dataService.desktopView">
    <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"  (click)="this.dataService.modalOf(); this.dataService.BottomSheetOf();" i18n="@@cancelBtn">Cancel </button>
    <button class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5"  [ngClass]="{'m-progress': submitting,
    'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" i18n="@@save">Save</button>
  </div>
  <div class="mt-5 pt-5" *ngIf="dataService.mobileView">
    <div class="d-flex justify-content-end">
      <button class="btn blue_btn font-14 sharp-font bold-500 py-1 mb-4 px-5 w-100"  [ngClass]="{'m-progress': submitting}" i18n="@@save">Save</button>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5 w-100"  (click)="this.dataService.modalOf(); this.dataService.BottomSheetOf();" i18n="@@cancelBtn">Cancel </button>
    </div>
  </div>
  <div *ngIf="errorMessage" class="text-danger smaller" [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">
    {{errorMessage}}
  </div>
</form>
</div>
