
<div class=" container custom-container gray-text-dark text-left">

  <h1 class="border-bottom pb-4 mb-4 mt-5 primaray-text sharp-font font-30-to-24">SafarHub Privacy policy</h1>
  <p class="pt-2">Last Updated in November 2020</p>

  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">Introduction</h2>
  <p class="pb-3">SafarHub.com is owned and operated by Triphop Inc. ("SafarHub" or "we"), a company registered in Delaware, USA. The following Privacy Policy describes how we as a data controller collect, use, disclose, and otherwise process personally identifiable information that we collect about individuals ("Personal Data") in connection with the services available from SafarHub through the website located at www.SafarHub.com ("Site"). References to the Site are deemed to include derivatives thereof, including but not necessarily limited to mobile websites and applications. By visiting the Site, you are consenting to the collection, use, disclosure and processing of your Personal Data as described in this Privacy Policy.</p>
  <p class="pb-3">On occasion, SafarHub may revise this Privacy Policy to reflect changes in the law, our Personal Data collection and use practices, the features of our Site, or advances in technology. If we make revisions that change the way we collect or use your Personal Data, those changes will be posted in this Privacy Policy and the effective date will be noted at the beginning of this Privacy Policy. Therefore, you should review this Privacy Policy periodically so that you are up to date on our most current policies and practices. SafarHub will also prominently post such material changes prior to implementing the change. If you do not agree with any changes or modifications to the Privacy Policy, please do not continue using the Site. You will be deemed to have consented to any modification of the Privacy Policy when you use the Site after the effective date of the modification.</p>


  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">Information We Collect	</h2>
  <p class="pb-3">We collect Personal Data about you that you provide to us when using the Site. Personal Data may include your name, address, phone number, credit card details, e-mail address, accommodation name, accommodation location and/or length of stay. In addition to this, when using our Site, even if you do not make a reservation, we may collect other certain information, such as your IP address, geographic data, your nationality and password, search preferences related to specific searches, which browser you’re using, and information about your computer’s operating system, application version, language settings and pages that have been shown to you. If you are using a mobile device, we might also collect data that identifies your mobile device, device-specific settings and characteristics and latitude/longitude details. When you make a reservation, our system registers through which means and from which websites you have made your reservation.</p>

  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">Protecting Your Personal Data</h2>
  <p class="pb-3">To prevent unauthorized access, we maintain reasonable physical, electronic and organizational procedures to protect Personal Data against accidental or unlawful destruction or accidental loss, alteration, or unauthorized disclosure or access. To protect credit card transactions, SafarHub.com currently uses Secure Socket Layer technology to encrypt credit card information during transit.</p>

  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">How We Use the Information We Collect – Credit Card on File</h2>
  <p class="pb-3">General Use: We use Personal Data and other information collected through the Site to (a) register you with the Site and create your user account, (b) provide you with the requested services (mainly to process and confirm your reservation with the accommodation provider or other supplier) after verifying your credentials, (c) provide you with information about our services on the Site or on other websites and to communicate with you to the extent necessary to provide such requested services, (d) build features that will make the services available on the Site easier to use, (e) for an improved user experience, internal training and general optimization purposes, (f) for the detection and prevention of fraud and other illegal or unwanted activities or (g) contact you in case there is any issue with your booking. This includes faster purchase requests, better customer support and timely notice of new services and special offers.</p>
  <p class="pb-3">Marketing Activities & Reminders: We also use your information for marketing activities, as permitted by law. For example: When you make a reservation with us, set up a user account, complete or enter details into a booking form, or provide us with your contact details we may use your contact information to send you news of products and services relevant and useful for travelers. With your consent – if consent is required under local law – we may also send you other regular newsletters by email. You can opt out, or unsubscribe, from marketing communications at any time using the "Unsubscribe" link in each newsletter or communication, or you can administer your subscriptions through your account (if you've created one).</p>
  <p class="pb-3">Based on the information you share with us, individualized offers may be shown to you on the Site, in mobile apps or on third-party websites, including social media sites. These might be offers that you can book directly on the Site, or third-party offers or products we think you might find interesting.</p>
  <p class="pb-3">If you have not finalized a reservation online, we may send you a reminder to continue with your reservation. We believe that this additional service is useful to you because it allows you to carry on with a reservation without having to search for the accommodation again or fill in all the reservation details from scratch.</p>
  <p class="pb-3">Please see the section below titled “Cookies, Page Tags and Data Use” on how we use this data for advertising purposes.</p>
  <p class="pb-3">Please note that the confirmation emails and text messages sent after your booking, as well as the guest review emails sent after your departure, are not marketing messages. These messages are part of your accommodation reservation process. Respectively, they contain information for you to check in at your booked accommodation, and tools to optionally rate that accommodation after your stay. You will continue to receive them, even if you have opted out of our marketing messages.</p>
  <p class="pb-3">Credit Card on File: When logging in, you will have the ability to save credit card details to your personal account for faster future bookings and payments. Your card details are always secure using strong encryption and you can always log in to your personal account to delete, edit or add certain credit card details. For security reasons, the number cannot be edited (but the card details can be deleted). When viewing card details, only the last 4 digits of the number will be visible. Please note that we will store the last 4 digits of your credit card securely upon any booking made (this is required to manage any refund requests for that booking).</p>

  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">Sharing Your Personal Data</h2>
  <p class="pb-3">SafarHub may share your Personal Data with suppliers such as accommodation properties and other third parties we appoint to perform services on our behalf, such as third-party loyalty programs, web hosting, data analysis, marketing (also on websites of such third parties), customer care, credit card and payment services and related services. All such third parties will be under an obligation to maintain the security and confidentiality of the Personal Data, and to process the Personal Data only in accordance with our instructions.</p>
  <p class="pb-3">Save for disclosures mentioned in this Privacy Policy or as required or permitted by law in any relevant jurisdiction as mentioned below and the disclosure of your Personal Data and information required for completing your booking with the relevant accommodation of your choice and the sharing of certain Personal Data with selected partners where relevant (your details will be used for reporting and analysis purposes only), we will not disclose your Personal Data to third parties without your consent.</p>
  <p class="pb-3">However, we reserve the right to disclose your Personal Data to our affiliated companies (in and outside your home country), including our affiliated companies' employees and our trusted agents and representatives who have access to this information with our permission and who need to know or have access to this information to perform the service requested by you (including customer services) and internal (audit/compliance) investigations.</p>
  <p class="pb-3">SafarHub may, in compliance with applicable law, disclose Personal Data to protect ourselves against liability, to respond to subpoenas, judicial processes, legitimate requests, warrants or equivalent by law enforcement officials or authorities, to investigate fraud or other wrongdoing or as otherwise required or necessary in order to comply with applicable law, protect our legitimate interests or to the purchasers in connection with any sale, assignment, or other transfer of all or a part of our business or company. We may also, in compliance with applicable law, disclose Personal Data to enforce or apply the terms and conditions applicable to our services or to protect the rights, property or safety of SafarHub, our users or others.</p>
  <p class="pb-3">In the situations described above, the recipient of the Personal Data may be located in a jurisdiction that may have different standards of data protection compared to the laws in your home jurisdiction.</p>

  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">Data Retention</h2>
  <p class="pb-3">SafarHub will retain your personal data as long as it is necessary in order for you to use our service(s), offer you our services including maintaining a SafarHub account and marketing promotions, to comply with applicable laws, to resolve any disputes, and to allow us to detect fraud and any illegal activities.</p>
  <p class="pb-3">Your personal data will be aggregated and anonymized when it is used by SafarHub for analytical purposes.</p>
  <p class="pb-3">SafarHub implement and use reasonable procedures and safeguard to prevent unauthorized access to and the misuse of the personal data you give us. Only SafarHub’s authorized staff are allowed to access personal data in the course of their work.</p>



  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">Our Policy Concerning Cookies and Page Tags</h2>
  <p class="pb-3">By visiting our Site, you consent to the usage of cookies and tags as described below. Our Site uses session and persistent cookies as well as page tags to collect and store certain information about you. A cookie is a small piece of data that is sent to your computer from the Site and stored on your computer’s hard drive. A page tag is a piece of code which reads and writes cookie data. Cookies or page tags may be from SafarHub and/or third-party vendors we have chosen to work with. Session cookies disappear from your computer when you close the browser. Persistent cookies remain on your computer after the browser has been closed, but can be deleted at any time through your browser settings. The use of these cookies and page tags may vary and are described in the appropriate sections below.</p>

  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">Opting Out</h2>
  <p class="pb-3">If you want to prevent your browser from accepting cookies, if you want to be informed whenever a cookie is stored on your computer or if you want to delete cookies from your computer, please make the necessary changes in your Internet browser settings, typically under the sections “Help” or “Internet Options”. If you disable or delete cookies in your Internet browser settings, you might not be able to access or use important functions or features of this Site, and you will be required to re-enter your log-in details. Please also note that opting out of a third-party cookie (see below) does not mean that you will no longer receive or be subject to online advertising or marketing analysis. It means that the network from which you opted out will no longer deliver ads tailored to your web preferences and usage patterns. Please also note that if you clear all cookies on your browser, or use a different browser or computer, you will need to complete the opt-out procedure again.</p>

  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">Cookies, Page Tags and Data Use</h2>
  <p class="pb-3 font-italic">SafarHub Cookies</p>
  <p class="pb-3">SafarHub uses a session cookie to keep you logged in throughout your visit to the Site.</p>
  <p class="pb-3">SafarHub uses persistent cookies to "personalize" the Site for each user by remembering information about the user's visit to and activities on the Site, e.g., the language you selected, your log-in details so that you do not need to re-enter them at your next visit to the Site.</p>
  <p class="pb-3">SafarHub also uses persistent cookies or page tags to analyze your surfing behavior and your visit to the Site enabling us to improve the Site and our services and to tailor our advertising (via email, on our Site, and on other websites) according to the interests of our users. Such cookies and page tags gather information about the pages you visited on the Site and the searches you performed.</p>
  <p class="pb-3 font-italic">Website Analytics</p>
  <p class="pb-3">Third party companies may use persistent cookies to analyze your surfing behavior on and your visit to this Site as well as other web sites enabling us to improve the Site and our services. Website analytics platforms may be used to collect unidentifiable information for reports such as impression reporting, demographic reporting and interest reporting.</p>
  <p class="pb-3 font-italic">Third Party Cookies, Page Tags, and Hashed Data</p>
  <p class="pb-3">Our third-party advertising companies may place cookies or page tags on some of the pages you visit on the Site. These cookies or page tags are designed to collect non-personally identifiable information in order to analyze your interests and searches when visiting our Site and to serve you advertisements specifically tailored to your interests and searches when you are visiting our Site or other websites or when using mobile applications. The non-personally identifiable information collected through cookies and page tags may be shared with other third parties for purpose of managing and targeting advertisements and for market research analysis. In addition, we may share one-way hashed (undecipherable) data with third party vendors using advertisement targeting products. Such third-party vendors typically use hashed device identifiers to link such identifiers to their own users and to serve custom ads based on users' prior visits to the Site. As a consequence, third-party vendors may show our and/or our partners’ ads on other websites or mobile apps.</p>


  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">Advertising</h2>
  <p class="pb-3">SafarHub may use a combination of the above cookies and page tags to tailor your experience with our Site, our mobile application and our ads to your preferences. We use display and search remarketing to tailor ad content for a better experience.</p>
  <p class="pb-3">Where other advertising may not require the use of cookies or page tags, third party data is used to determine when a SafarHub ad will be shown.</p>
  <p class="pb-3">SafarHub takes reasonable efforts to not collect, and to prevent the use of, personally identifiable information in third party advertising solutions, unless you have authorized this.</p>


  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">Users Rights and Deletion Policy</h2>
  <p class="pb-3">User’s have rights and choices to edit and delete their personal data as described below:</p>
  <p [ngClass]="{'ml-lg-5': restApi.isSiteLtr(), 'mr-lg-5': restApi.isSiteRtl()}"><i class="icon-circle" [ngClass]="{'ml-3 pr-3': restApi.isSiteLtr(), 'mr-3 pl-3': restApi.isSiteRtl()}"></i> Users who created an account with SafarHub will be able to edit their name, email, phone number, address, travel preferences and any other personal information under the account setting. User can edit their personal data by logging to their account on SafarHub.com or SafarHub App</p>
  <p [ngClass]="{'ml-lg-5': restApi.isSiteLtr(), 'mr-lg-5': restApi.isSiteRtl()}"><i class="icon-circle" [ngClass]="{'ml-3 pr-3': restApi.isSiteLtr(), 'mr-3 pl-3': restApi.isSiteRtl()}"></i> Users who created an account with SafarHub will be able to add and delete payment methods (Credit Card information). Upon deleting the payment method, SafarHub will delete all data from SafarHub payment gateway partner.</p>
  <p [ngClass]="{'ml-lg-5': restApi.isSiteLtr(), 'mr-lg-5': restApi.isSiteRtl()}"><i class="icon-circle" [ngClass]="{'ml-3 pr-3': restApi.isSiteLtr(), 'mr-3 pl-3': restApi.isSiteRtl()}"></i> Any user can edit his email promotion by updating his subscription options. Users can opt-out from receiving any email marketing promotions by clicking on the unsubscribe link on our email.</p>
  <p class="pb-3">For our mobile apps, you can view and manage notifications and preferences in the settings menus of the app and of your operating system</p>
  <p class="pb-3">For more questions about your rights, please <a class="blue-text" (click)="this.router.navigate(['/info/contact-us'])">contact us</a></p>
  <p class="pb-3">In certain jurisdictions, you can ask SafarHub to delete your personal information by sending an email to <a href="mailto:support@safarhub.com">support@safarhub.com</a></p>

  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">Advertising and Content on Other Websites</h2>
  <p class="pb-3">SafarHub uses third-party tools to distribute ads on a variety of websites. SafarHub neither supports nor endorses the goals, causes or statements of the websites which display our advertisements.</p>

  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">Mobile Devices</h2>
  <p class="pb-3">We have a free app for a variety of mobile devices and use versions of our regular website that have been optimized for mobile. These apps and mobile websites process the personal details you give us in much the same way as our website does – and they also allow you to use location services to find accommodations nearby. With your consent, we may send you push notifications with information about your reservation, potential future stays or as part of any SafarHub programs that you participate in.</p>

  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">A Special Note About Minors</h2>
  <p class="pb-3">Persons under the age of 18 are not eligible to use the accommodation reservation services on our Site. Minors can normally accompany their parent(s) or legal custodian(s) who have booked an accommodation, except where indicated otherwise in the accommodation property policies.</p>

  <h2 class="pb-3 pt-3 sharp-font gray-primaray-text font-24-to-20">Terms of Use</h2>
  <p class="pb-3">Please consult the SafarHub Terms of Use for more details on using our Site. This Privacy Policy forms an integral part of our Terms of Use.</p>

</div>
