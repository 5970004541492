import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {AuthServiceMain} from '../../../auth/auth.service';
import {GoogleAnalyticsService} from '../../../google-analytics.service';
import {ActivatedRoute, Router} from '@angular/router';
import 'firebase/remote-config';
import { browserRefresh } from '../../../app.component';
import {environment} from "../../../../environments/environment";
import {Subscription} from "rxjs";
import {FlightService} from "../../../services/flight-services/flight.service";
@Component({
  selector: 'app-flight-booking',
  templateUrl: './flight-booking.component.html',
  styleUrls: ['./flight-booking.component.scss']
})
export class FlightBookingComponent implements OnInit, OnDestroy{
  public browserRefresh: boolean;
  userInfo;
  //data;
  params;
  diffInDays;
  flightTransactionIdFromURL: string;
  pricingRequestObj;
  pricingSubscription: Subscription;
  pricingData;
  errorMsg = '';
  constructor(public Service: DataService,
              public Auth: AuthServiceMain,
              public flightService: FlightService,
              private route: ActivatedRoute,
              public googleAnalyticsService: GoogleAnalyticsService,
              public router: Router) {}

  ngOnInit() {
    this.readUrlParameters();
    this.Service.flightRatesExpiry = false;
    this.browserRefresh = browserRefresh;
    this.Service.defultCountry = {};
    this.Service.defultCountry.dial_code = '+1';
    this.Service.defultCountry.code = 'US';
    this.Service.dropDowmBookingForm = false;
    this.Service.flightBookingPage = true;
    if (!sessionStorage.getItem('flightData')) {
      this.router.navigate(['/']);
    }
    this.Service.flightParams = JSON.parse(sessionStorage.getItem('flightParams'));
    if(this.flightTransactionIdFromURL){
      this.getFlightPricing();
    } else {
      this.Service.flightData = JSON.parse(sessionStorage.getItem('flightData'));
      this.Service.flightPricing = JSON.parse(sessionStorage.getItem('flightPricingData'));
    }


    this.userInfo = this.Auth.userInfo;

    this.Service.flightRatesDuration('refreshFlightBooking');
    if (this.browserRefresh) {
   //   this.Service.validateFlight(true, this.Service.flightData.details.userData.travelerId);
    } else {
      sessionStorage.removeItem('flightBookingFormFields');
    }
    this.userInfo = this.Auth.userInfo;
  }

  getFlightPricing(){
    this.pricingSubscription =  this.flightService.flightPricing(this.pricingRequestObj).subscribe(
        data => {
          this.pricingData = data;
          if (this.pricingData.errorCode === 0) {
            sessionStorage.setItem(
                "travelerIdFlight",
                this.pricingData.data.flightOffers[0].travelerPricings[0].travelerId
            );

            this.Service.flightPricing = this.pricingData.data;
            sessionStorage.setItem("flightPricingData", JSON.stringify(this.Service.flightPricing));

            this.Service.flightData = {
              details: this.pricingData.data.flightOffers[0]
            };
            sessionStorage.setItem("flightData", JSON.stringify(this.Service.flightData));

            this.flightService.pricingLoading.next(false);

          } else {
            console.log(this.pricingData.errorCode);
            console.log(sessionStorage.getItem("previousUrl"));
            this.flightService.pricingLoading.next(false);
            this.router.navigateByUrl(sessionStorage.getItem("previousUrl"));
            this.Service.messagesOverRoute(this.pricingData.message, "warning");
          }
        },
        error => {
          this.flightService.pricingLoading.next(false);
          this.errorMsg = environment.messages.error;
        }
    );
  }



  readUrlParameters() {
    this.route.queryParams.subscribe(params => {
      // Extract the transaction ID
      this.flightTransactionIdFromURL = params['id'];
      const transactionId = params['id'];
      console.log('Transaction ID:', transactionId);

      // Extract the travelers
      const travelersFromURL = params['traveler'];
      console.log('Travelers:', travelersFromURL);

      // Prepare an array for traveler objects
      const travelers = [];

      // Check if travelers is an array or a single value
      if (Array.isArray(travelersFromURL)) {
        travelersFromURL.forEach(traveler => {
          const [travelerId, travelerType] = traveler.split(';');
          travelers.push({ travelerId, travelerType });
        });
      } else if (travelersFromURL) {
        // Handle single traveler case
        const [travelerId, travelerType] = travelersFromURL.split(';');
        travelers.push({ travelerId, travelerType });
      }

      // Create the final object
      const result = {
        transactionId: transactionId,
        travelers: travelers
      };
      this.pricingRequestObj = result;

      console.log('Final Object:', result);
    });
  }



  ngOnDestroy() {
    if(this.pricingSubscription){
      this.pricingSubscription.unsubscribe();
    }
  }

}
