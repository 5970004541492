<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pt-4 pt-lg-5 pb-0 pb-5">
        <div class="mx-lg-5">
          <p class="mb-4 font-24 gray-primaray-text bold-600" i18n="@@teams">
            Teams
          </p>
          <div class="pb-0 pb-lg-5">
            <ng-container
              *ngIf="
                (!dataService.companyTeams ||
                  !dataService.companyTeams.length) &&
                !this.dataService.loadingFlag
              "
            >
              <div
                class="mt-5 company-section-with-thick-border border"
                [ngClass]="{
                  'custom-container': dataService.desktopView,
                  'rounded-right':
                    dataService.desktopView && dataService.isSiteLtr(),
                  'rounded-left':
                    dataService.desktopView && dataService.isSiteRtl(),
                  'thick-top rounded-bottom': dataService.mobileView
                }"
              >
                <div
                  class="row mx-0 p-4 h-100 justify-content-center align-items-center"
                >
                  <div class="col-md-8 font-16">
                    <p class="mb-0 gray-primaray-text" i18n="@@teamDesc">
                      Create and manage teams, add members to a team and assign
                      travel policies.
                    </p>
                  </div>
                  <div
                    class="col-md-4"
                    [ngClass]="{
                      'text-right': dataService.isSiteLtr(),
                      'text-left': dataService.isSiteRtl()
                    }"
                    *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
                  >
                    <button
                      (click)="createNewTeam()"
                      class="w-100 btn px-5 py-2 mt-4 mb-3 my-lg-2 rounded text-center sharp-font bold-500 ml-auto font-14 find-btn btn-primary"
                      i18n="@@addTeam"
                    >
                      Add Team
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                dataService.companyTeams && dataService.companyTeams.length
              "
            >
              <div class="row">
                <div class="col-12">
                  <p class="gray-text-dark font-18" i18n="@@teamDesc">
                    Create and manage teams, add members to a team and assign
                    travel policies.
                  </p>
                </div>
              </div>
              <div
                class="my-5 pt-lg-5"
                [ngClass]="{ 'd-flex': dataService.desktopView }"
              >
                <div
                  class="min-width-workspace-btn"
                  [ngClass]="{
                    'mr-lg-3': dataService.isSiteLtr(),
                    'ml-lg-3': dataService.isSiteRtl(),
                    'mb-3': dataService.mobileView
                  }"
                  *ngIf="this.auth.isAdmin() || this.auth.isOwner()"
                >
                  <button
                    (click)="createNewTeam()"
                    class="btn btn-outline-blue font-14 px-5 sharp-font bold-500"
                    [ngClass]="{ 'btn-block': dataService.desktopView }"
                    i18n="@@addTeam"
                  >
                    Add Team
                  </button>
                </div>
              </div>
            </ng-container>
            <div
              *ngIf="
                !this.dataService.companyTeams || this.dataService.loadingFlag
              "
              class="loading_icon_caption text-center mt-5"
            ></div>
            <div
              *ngIf="
                !this.dataService.companyTeams || this.dataService.loadingFlag
              "
              class="loading-message text-center"
              i18n="@@loading"
            >
              Loading
            </div>
            <div class="items-full-list" *ngIf="!this.dataService.loadingFlag">
              <div
                class="row mx-0 px-3 py-3 gray-primaray-text bold-600 font-14 table-header rounded"
                *ngIf="
                  dataService.desktopView &&
                  dataService.companyTeams &&
                  dataService.companyTeams.length
                "
              >
                <div class="col-4" i18n="@@team">Team</div>
                <div class="col-3" i18n="@@manager">Manager</div>
                <div class="col-4" i18n="@@members">Members</div>
                <div class="col-1"></div>
              </div>
              <div
                class="row mx-0 px-0 px-lg-3 py-3 py-lg-4 hover-row-list font-14 pointer gray-text-dark"
                *ngFor="let team of dataService.companyTeams; let i = index"
                (click)="
                  this.router.navigate([this.router.url + '/' + team.id])
                "
                [ngClass]="{
                  'border-bottom': dataService.desktopView,
                  'border rounded shadow-sm mb-4': dataService.mobileView
                }"
              >
                <div class="col-lg-4">
                  <p
                    class="mb-0 py-2"
                    [ngClass]="{
                      'gray-primaray-text font-16': dataService.mobileView
                    }"
                  >
                    {{ team.name }}
                  </p>
                </div>
                <div class="col-lg-3">
                  <p
                    class="gray-primaray-text mb-0 mt-4 pt-1"
                    *ngIf="dataService.mobileView"
                    i18n="@@manager"
                  >
                    Manager
                  </p>
                  <p
                    class="py-0 py-lg-2 mb-0"
                    [ngClass]="{ 'gray-text': dataService.mobileView }"
                  >
                    <span *ngIf="team.teamManager">{{ team.teamManager }}</span>
                    <span *ngIf="!team.teamManager">&mdash;</span>
                  </p>
                </div>
                <div class="col-lg-4">
                  <p
                    class="gray-primaray-text mb-0 mt-4"
                    *ngIf="dataService.mobileView"
                    i18n="@@members"
                  >
                    Members
                  </p>
                  <p class="mb-0 py-2" *ngIf="team.membersDescription">
                    <span
                      [ngClass]="{
                        'text-bg font-12 px-3 py-2': dataService.mobileView
                      }"
                      >{{ team.membersDescription }}</span
                    >
                  </p>
                  <p class="mb-0 py-0 py-lg-2" *ngIf="!team.membersDescription">
                    &mdash;
                  </p>
                </div>
                <div
                  class="col-lg-1"
                  [ngClass]="{ 'ellipsis-col': dataService.desktopView }"
                >
                  <div class="d-flex">
                    <div
                      class="actions"
                      [ngClass]="{
                        'ml-auto': dataService.isSiteLtr(),
                        'mr-auto': dataService.isSiteRtl()
                      }"
                    >
                      <i
                        class="drop-down{{
                          i
                        }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                        [ngClass]="{
                          'with-no-members': !team.membersDescription
                        }"
                        (click)="
                          $event.stopPropagation();
                          approvalActions = !approvalActions;
                          selectedIndex = i
                        "
                      ></i>
                      <div
                        class="border shadow font-14 position-absolute bg-white drop"
                        [ngClass]="{
                          'drop-no-members': !team.membersDescription
                        }"
                        *ngIf="approvalActions && i === selectedIndex"
                      >
                        <p
                          class="pointer py-2"
                          [ngClass]="{
                            'pl-4': dataService.isSiteLtr(),
                            'pr-4': dataService.isSiteRtl()
                          }"
                          (click)="
                            this.router.navigate([
                              this.router.url + '/' + team.id
                            ])
                          "
                          i18n="@@view"
                        >
                          View
                        </p>
                        <p
                          class="pointer py-2"
                          [ngClass]="{
                            'pl-4': dataService.isSiteLtr(),
                            'pr-4': dataService.isSiteRtl()
                          }"
                          (click)="
                            $event.stopPropagation();
                            this.router.navigate([
                              this.router.url + '/' + team.id + '/edit'
                            ])
                          "
                          i18n="@@editTeam"
                        >
                          Edit team
                        </p>
                        <p
                          class="pointer py-2"
                          [ngClass]="{
                            'pl-4': dataService.isSiteLtr(),
                            'pr-4': dataService.isSiteRtl()
                          }"
                          (click)="
                            $event.stopPropagation();
                            removeTeam(team.id, team.name)
                          "
                          i18n="@@deleteBtn"
                        >
                          Delete
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
