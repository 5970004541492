<p
        class="mb-2"
        [ngClass]="{
        'gray-text': teamId,
        'gray-primaray-text': !teamId,
        'font-16 custom-line-height mt-5': dataService.createEditTeamPage,
        'mt-4': !dataService.createEditTeamPage
      }"
>
    <ng-container i18n="@@office">Office</ng-container> &nbsp;<span
        class="font-12 gray-text"
><br *ngIf="dataService.createEditTeamPage" /><span
        *ngIf="!dataService.createEditTeamPage"
>(</span
><ng-container i18n="@@selectAddress"
>Office the team is assigned to</ng-container
><span *ngIf="!dataService.createEditTeamPage">)</span></span
>
</p>
<mat-form-field
        class="w-100 chips-container rounded"
        [ngClass]="{
        'border-0': teamId && !editTeamAddress,
        border: teamId === undefined || editTeamAddress,
        'realtime-loader': addressLoader
      }"
        *ngIf="dataService.desktopView"
        (click)="editTeamAddress = true; addressInput.select()"
>
    <i
            *ngIf="!addressLoader"
            [ngClass]="{
          'gray-text': editTeamAddress,
          'prevent-pointer-event inactive-clear': !editTeamAddress && teamId,
          'prevent-pointer-event text-white': !editTeamAddress && !teamId
        }"
            class="icon-cross small pointer clear-text p-1"
            (click)="applyBlur = false; desktopAddressFocus()"
    ></i>
    <input
            [ngClass]="{
          'pl-4': dataService.isSiteLtr(),
          'pr-4': dataService.isSiteRtl(),
          'edit-field': teamId && !editTeamAddress
        }"
            class="min-height mx-0 my-0"
            id="addressInputHtml"
            #addressInput
            type="text"
            matInput
            [formControl]="addressControl"
            [matAutocomplete]="autoAddress"
            #trigger="matAutocompleteTrigger"
            (keyup.enter)="onEnterAddress(); trigger.closePanel()"
            (blur)="resetAddress()"
            (keyup.escape)="restoreSelectedAddress()"
            (focus)="addressAutoComplete()"
    />
    <mat-autocomplete
        #autoAddress="matAutocomplete"
        [displayWith]="displayFnAddress"
    >
        <mat-option
                style="padding: 0"
                *ngFor="let address of filteredAddresses | async"
                [value]="address"
        >
            <div
                    (click)="optionClickedAddress($event, address)"
                    class="mat-checkbox-container"
            >
                {{ address.name }}<br /><span
                    class="font-12 gray-text"
                    *ngIf="address.city"
            >{{address.city}}, {{ address.countryCode }}</span
            >
            </div>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
<div
        class="form-control rounded pt-1"
        *ngIf="dataService.mobileView"
        [ngClass]="{
        'edit-field': teamId && !editTeamAddress,
        'border-0': teamId && !editTeamAddress,
        border: teamId === undefined || editTeamAddress
      }"
        (click)="showAddressPopUp()"
>
    <p *ngIf="selectedAddress" class="mt-2 pt-1">{{ selectedAddress.name }}</p>
</div>



<div class="justify-content-end create-team">
    <div
            (click)="mobileFocus()"
            [ngClass]="{'in action-popup': showAddressAutoCompletePopUp }">
        <div
                [ngClass]="{header: showAddressAutoCompletePopUp}">
            <div *ngIf="showAddressAutoCompletePopUp">
                <div *ngIf="showAddressAutoCompletePopUp">
                    <div class="info">
            <span *ngIf="showAddressAutoCompletePopUp" i18n="@@office">Office</span>

                    </div>
                </div>
            </div>
            <div
                    class="position-relative mobile-search-field"
                    *ngIf="showAddressAutoCompletePopUp"
            >
                <div class="form-group mb-3">
                    <mat-form-field class="w-100 chips-container border rounded">
                        <input
                                class="bg-white min-height mx-0 my-0"
                                [ngClass]="{
                'pl-4': dataService.isSiteLtr(),
                'pr-4': dataService.isSiteRtl()
              }"
                                type="text"
                                matInput
                                #addressInput
                                id="addressInput"
                                [formControl]="addressControl"
                                [matAutocomplete]="autoAddress"
                                (blur)="resetAddress()"
                        />
                        <mat-autocomplete
                            #autoAddress="matAutocomplete"
                            [displayWith]="displayFnAddress"
                        >
                            <mat-option
                                    style="padding: 0"
                                    *ngFor="let address of filteredAddresses | async"
                                    [value]="address"
                            >
                                <div
                                        (click)="optionClickedAddress($event, address)"
                                        class="mat-checkbox-container"
                                >
                                    {{ address.name }}<br /><span
                                        class="font-12 gray-text"
                                        *ngIf="address.city"
                                >{{address.city}}, {{ address.countryCode }}</span
                                >
                                </div>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>
