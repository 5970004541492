<div [ngClass]="{'container pb-5 pt-4': dataService.mobileView, 'py-4':dataService.desktopView}">
  <div *ngIf="this.switchData" class="pb-5 mb-3">
    <ng-container i18n="@@bySwitching">By switching to {{this.dataService.workspacePlan}} billing, you will be charged</ng-container>&nbsp;<ng-container *ngIf="dataService.isSiteLtr()">$</ng-container>{{this.switchData.value | number: '1.0-2'}}<ng-container *ngIf="dataService.isSiteRtl()">$</ng-container>&nbsp;<ng-container i18n="@@on">on</ng-container>&nbsp;<span  [innerHTML]="dataService.formatDateMMMdy(this.switchData.nextBillingDate)"></span></div>

<div class="d-flex justify-content-end" *ngIf="dataService.desktopView">
  <button class="btn-outline-gray btn px-5 font-14" (click)="this.dataService.modalOf(); "  i18n="@@cancelBtn"> Cancel </button>
  <button class="blue_btn px-5" [ngClass]="{'m-progress': submitting, 'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"
          (click)="confirm()" i18n="@@confirmBtn">
   Confirm
  </button>
</div>
<div *ngIf="dataService.mobileView">
  <button class="blue_btn px-5 w-100 mb-3" [ngClass]="{'m-progress': submitting }"
          (click)="confirm()" i18n="@@confirmBtn">
    Confirm
  </button>
  <button class="btn-outline-gray btn px-5 font-14 w-100" (click)=" this.dataService.BottomSheetOf();" i18n="@@cancelBtn"> Cancel </button>
</div>
</div>
