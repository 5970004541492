import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {VariablesService} from "../vars.service";
@Injectable({
    providedIn: 'root'
})
export class FlightService {
    flightPricingDetails = new BehaviorSubject<any>(null);
    pricingLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient,
                public VarsService: VariablesService) {
    }

    flightDetails(body){
        return this.http.post(
            `${environment.apiBase}${this.VarsService.flightApis.flightDetails}`,
            body
        );
    }

    flightPricing(body){
        return this.http.post(
            `${environment.apiBase}${this.VarsService.flightApis.flightPricing}`,
            body
        );
    }

}











