<div class="main-dashboard-content" *ngIf="dataService.mobileView">
  <app-messages></app-messages>
  <div class="container pt-4 pt-lg-5 pb-5">
    <div class="mx-lg-5">
      <div class="pb-4 pb-lg-5 mb-3" *ngIf="this.auth.companyInfo">
        <ng-container *ngIf="!teamId">
          <a
            class="font-16 blue-text text-underline"
            (click)="
              this.router.navigate(['/teams'])
            "
            i18n="@@teams"
            >Teams</a
          >
          <span class="font-16 gray-text-dark">
            / <ng-container i18n="@@newTeam">New Team</ng-container>
          </span>
        </ng-container>
        <ng-container *ngIf="teamId && this.dataService.teamInfo">
          <a
            class="font-16 blue-text text-underline"
            (click)="
              this.router.navigate(['/teams'])
            "
            i18n="@@teams"
            >Teams</a
          >
          <span class="font-16 gray-text-dark">
            /
            <a
              class="font-16 blue-text text-underline"
              (click)="
                this.router.navigate([
                  '/teams/' + teamId
                ])
              "
              >{{ this.dataService.teamInfo.name }}</a
            >
          </span>
          <span class="font-16 gray-text-dark">
            / <ng-container i18n="@@edit">Edit</ng-container>
          </span>
        </ng-container>
      </div>
    </div>
    <div class="mx-lg-5">
      <div
        [ngClass]="{
          'mb-5 rounded border pt-5 workspace-rounded-container':
            dataService.desktopView,
          'pl-4': dataService.desktopView && dataService.isSiteLtr(),
          'pr-4': dataService.desktopView && dataService.isSiteRtl()
        }"
      >
        <div class="row">
          <div
            class="col-lg-4 min-left-side-col-width"
            [ngClass]="{
              'pl-0': dataService.mobileView,
              'pr-0': dataService.isSiteLtr(),
              'pl-0': dataService.isSiteRtl()
            }"
          >
            <app-create-team></app-create-team>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container
  class="sidenav-container"
  *ngIf="dataService.desktopView"
>
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pt-4 pt-lg-5 pb-5">
        <div class="mx-lg-5">
          <div class="pb-4 pb-lg-5 mb-3" *ngIf="this.auth.companyInfo">
            <ng-container *ngIf="!teamId">
              <a
                class="font-16 blue-text text-underline"
                (click)="
                  this.router.navigate([
                    '/teams'
                  ])
                "
                i18n="@@teams"
                >Teams</a
              >
              <span class="font-16 gray-text-dark">
                /<ng-container i18n="@@newTeam">New Team</ng-container>
              </span>
            </ng-container>
            <ng-container *ngIf="teamId && this.dataService.teamInfo">
              <a
                class="font-16 blue-text text-underline"
                (click)="
                  this.router.navigate([
                   '/teams'
                  ])
                "
                i18n="@@teams"
                >Teams</a
              >
              <span class="font-16 gray-text-dark">
                /
                <a
                  class="font-16 blue-text text-underline"
                  (click)="
                    this.router.navigate([
                     '/teams/' + teamId
                    ])
                  "
                  >{{ this.dataService.teamInfo.name }}</a
                >
              </span>
              <span class="font-16 gray-text-dark">
                / <ng-container i18n="@@edit">Edit</ng-container>
              </span>
            </ng-container>
          </div>
        </div>
        <div class="mx-lg-5">
          <div
            [ngClass]="{
              'pl-4 mb-5 rounded border pt-5 workspace-rounded-container':
                dataService.desktopView
            }"
          >
            <div class="row">
              <div
                class="col-lg-4 min-left-side-col-width"
                [ngClass]="{
                  'pl-0': dataService.mobileView && dataService.isSiteLtr(),
                  'pr-0': dataService.mobileView && dataService.isSiteRtl(),
                  'pr-0': dataService.isSiteLtr(),
                  'pl-0': dataService.isSiteRtl()
                }"
              >
                <app-create-team></app-create-team>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
