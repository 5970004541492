import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {Address} from "../../../shared/model/address";
import {map, startWith} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {DataService} from "../../../services/data.service";
import {AuthServiceMain} from "../../../auth/auth.service";



@Component({
  selector: 'app-office-address',
  templateUrl: './office-address.component.html',
  styleUrls: ['./office-address.component.scss']
})
export class OfficeAddressComponent implements OnInit {
  @Input() teamId: string;
  @ViewChild("addressInput") addressInput: ElementRef;
  addressControl = new FormControl();
  showAddressAutoCompletePopUp = false;
  focusInputMobile;
  selectedAddress: Address;
  filteredAddresses: Observable<any[]>;
  lastFilterAddress: string = "";
  editTeamAddress = false;
  addressLoader;
  NoAddress: Address = {
    name: $localize`:@@unassigned:Unassigned`,
    id: null,
  };
  data: any;
  addressesList = [];
  errorMessage: string;
  applyBlur = true;
  constructor( public dataService: DataService,
               public auth: AuthServiceMain) { }

  ngOnInit(): void {
    this.fetchEditFormData();
    this.addressesList.push(this.NoAddress);
    this.addressAutoComplete();
  }

  addressAutoComplete() {
    this.dataService.getAddresses().subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            if (this.data.data) {
              this.addressesList = [];
              this.addressesList.push(this.NoAddress);
              this.data.data.forEach((element) => {
                this.addressesList.push({
                  id: element.id,
                  name: element.name,
                  city: element.city,
                  countryCode: element.countryCode
                });
                this.filteredAddresses = this.addressControl.valueChanges.pipe(
                    startWith(""),
                    map((value) =>
                        typeof value === "string" ? value : this.lastFilterAddress
                    ),
                    map((name) =>
                        name ? this.filterAddress(name) : this.addressesList.slice()
                    )
                );
              });
            } else {
              this.filteredAddresses = this.addressControl.valueChanges.pipe(
                  startWith(""),
                  map((value) =>
                      typeof value === "string" ? value : this.lastFilterAddress
                  ),
                  map((name) =>
                      name ? this.filterAddress(name) : this.addressesList.slice()
                  )
              );
            }
          } else {
            this.errorMessage = this.data.message;
          }
        },
        (error) => {
          this.errorMessage = environment.messages.error;
        }
    );
  }

  private filterAddress(name: string): Address[] {
    this.lastFilterAddress = name;
    if (name) {
      return this.addressesList.filter(
          (option) =>
              option.name.toLowerCase().indexOf(name.toLowerCase()) === 0 &&
              option.name !== "Unassigned" &&
              option.name !== $localize`:@@unassigned:Unassigned`
      );
    } else {
      return this.addressesList.slice();
    }
  }


  fetchEditFormData() {
    if (this.dataService.teamInfo.addressName) {
      this.selectedAddress = {
        id: this.dataService.teamInfo.addressId,
        name: this.dataService.teamInfo.addressName
      };
    } else {
      this.selectedAddress = this.NoAddress;
    }
    this.addressControl.setValue(this.selectedAddress);
  }

  desktopAddressFocus() {
    if (this.dataService.desktopView) {
      setTimeout(() => {
        this.addressControl.setValue("");
        this.editTeamAddress = true;
        this.applyBlur = true;
      }, 300);
    }
  }

  onEnterAddress() {
    if (typeof this.addressControl.value === "object") {
      this.optionClickedAddress(null, this.addressControl.value);
    } else if (
        this.addressControl.value === null ||
        this.addressControl.value === ""
    ) {
      this.resetAddress();
    }
  }


  resetAddress() {
    setTimeout(() => {
      if (this.applyBlur) {
        if (typeof this.addressControl.value !== "object") {
          if (
              this.addressControl.value === null ||
              this.addressControl.value === ""
          ) {
            if (this.teamId) {
              this.addressLoader = true;
              this.dataService.unAssignTeamAddress(this.teamId).subscribe(
                  (data) => {
                    this.data = data;
                    if (this.data.errorCode === 0) {
                      this.addressLoader = false;
                      this.dataService.getTeamInfo(this.teamId);
                      this.desktopAddressBlur();
                    } else {
                      this.addressLoader = false;
                      this.errorMessage = this.data.message;
                    }
                  },
                  (error) => {
                    this.addressLoader = false;
                    this.errorMessage = environment.messages.error;
                  }
              );
            }
            this.addressInput.nativeElement.value = "";
            this.lastFilterAddress = "";
            this.selectedAddress = this.NoAddress;
            this.addressControl.setValue(this.selectedAddress);
          } else {
            this.addressInput.nativeElement.value = "";
            this.addressControl.setValue(null);
            this.lastFilterAddress = "";
            this.selectedAddress = null;
            if (this.teamId) {
              if (this.dataService.teamInfo.addressName) {
                this.selectedAddress = {
                  id: this.dataService.teamInfo.addressId,
                  name: this.dataService.teamInfo.addressName
                };
              } else {
                this.selectedAddress = this.NoAddress;
              }
              this.addressControl.setValue(this.selectedAddress);
            }
          }
          this.filteredAddresses = this.addressControl.valueChanges.pipe(
              startWith(""),
              map((value) =>
                  typeof value === "string" ? value : this.lastFilterAddress
              ),
              map((name) =>
                  name ? this.filterAddress(name) : this.addressesList.slice()
              )
          );
        }
        this.editTeamAddress = false;
      }
    }, 200);
  }

  optionClickedAddress(event: Event, address: Address) {
    this.selectedAddress = address;
    if (this.dataService.mobileView) {
      this.hidePopUp();
    }
    const addressInputHtml = document.getElementById(
        "addressInputHtml"
    ) as HTMLInputElement;
    if (this.teamId) {
      this.addressLoader = true;
      if (this.selectedAddress && this.selectedAddress.id === null) {
        this.dataService.unAssignTeamAddress(this.teamId).subscribe(
            (data) => {
              this.data = data;
              if (this.data.errorCode === 0) {
                this.addressLoader = false;
                this.dataService.getTeamInfo(this.teamId);
                if (this.dataService.desktopView) {
                  addressInputHtml.blur();
                }
              } else {
                this.addressLoader = false;
                this.errorMessage = this.data.message;
              }
            },
            (error) => {
              this.addressLoader = false;
              this.errorMessage = environment.messages.error;
            }
        );
      } else if (this.selectedAddress && this.selectedAddress.id) {
        this.dataService.assignTeamToAddress(this.teamId, this.selectedAddress.id).subscribe(
            (data) => {
              this.data = data;
              if (this.data.errorCode === 0) {
                this.addressLoader = false;
                this.dataService.getTeamInfo(this.teamId);
                if (this.dataService.desktopView) {
                  addressInputHtml.blur();
                }
              } else {
                this.addressLoader = false;
                this.errorMessage = this.data.message;
              }
            },
            (error) => {
              this.addressLoader = false;
              this.errorMessage = environment.messages.error;
            }
        );
      }
    }
  }

  restoreSelectedAddress() {
    this.addressControl.setValue(this.selectedAddress);
    this.editTeamAddress = false;
    this.desktopAddressBlur();
  }

  desktopAddressBlur() {
    if (this.dataService.desktopView) {
      const input = document.getElementById(
          "addressInputHtml"
      ) as HTMLInputElement;
      if (input) {
        input.blur();
      }
    }
  }

  displayFnAddress(address: Address): string {
    return address && address.name ? address.name : "";
  }

  showAddressPopUp() {
    this.showAddressAutoCompletePopUp = true;
    this.dataService.disableBodyScroll();
    if (this.selectedAddress) {
      this.addressControl.setValue(this.selectedAddress);
    }
    setTimeout(() => {
      const input = document.getElementById("addressInput") as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }

  hidePopUp() {
    this.showAddressAutoCompletePopUp = false;
    this.dataService.enableBodyScroll();
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("members-popup");
  }


  mobileFocus() {
    if (this.showAddressAutoCompletePopUp) {
      setTimeout(() => {
        if (this.showAddressAutoCompletePopUp) {
          this.focusInputMobile = document.getElementById(
              "addressInput"
          ) as HTMLInputElement;
        }
        this.focusInputMobile.focus();
        this.focusInputMobile.select();
      }, 200);
    }
  }

}
