<div class="main-dashboard-content" *ngIf="dataService.mobileView">
  <app-messages></app-messages>
  <app-workspace-dashboard-control *ngIf="this.auth.companyInfo && this.auth.companyInfo.inactive === false"></app-workspace-dashboard-control>
  <app-workspace-deactivated-account *ngIf="this.auth.companyInfo && this.auth.companyInfo.inactive === true"></app-workspace-deactivated-account>

</div>
<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

  <mat-sidenav-container class="sidenav-container" *ngIf="dataService.desktopView">
    <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
      <app-left-menu *ngIf="this.auth.companyInfo && (this.auth.companyInfo.inactive === false || this.auth.companyInfo.inactive === true)"></app-left-menu>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
                         [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
      <div class="main-dashboard-content">
        <app-messages></app-messages>
        <app-workspace-dashboard-control *ngIf="this.auth.companyInfo && this.auth.companyInfo.inactive === false"></app-workspace-dashboard-control>
        <app-workspace-deactivated-account *ngIf="this.auth.companyInfo && this.auth.companyInfo.inactive === true"></app-workspace-deactivated-account>

      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>






