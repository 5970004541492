import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DataService } from "../../../services/data.service";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { AuthServiceMain } from "../../../auth/auth.service";
export class User {
  constructor(
    public description: string,
    public id: string,
    public teamName?: string,
    public selected?: boolean
  ) {
    if (selected === undefined) {
      selected = false;
    }
  }
}
@Component({
  selector: "app-add-team-members",
  templateUrl: "./add-team-members.component.html",
  styleUrls: ["./add-team-members.component.scss"],
})
export class AddTeamMembersComponent implements OnInit {
  constructor(
    public dataService: DataService,
    public router: Router,
    public auth: AuthServiceMain
  ) {}
  userControl = new FormControl();
  submitting = false;
  separatorKeysCodes = [ENTER, COMMA];
  addOnBlur: false;
  data;
  dataUsers;
  showMembersAutoCompletePopUp = false;
  teamDetails;
  users = [];
  selectedUsers: User[] = new Array<User>();
  mobileTempSelectedUsers: User[] = new Array<User>();
  filteredUsers: Observable<User[]>;
  lastFilter: string = "";
  teamId;
  teamName;
  @ViewChild("userInput") userInput: ElementRef;
  urlArray;
  ngOnInit() {
    this.urlArray = this.router.url.split("/");
    if (this.dataService.desktopView) {
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("workspace-modal");
      this.teamId = this.urlArray[this.urlArray.length - 1];
    } else {
      this.teamId = this.urlArray[this.urlArray.length - 2];
    }


    this.dataService.getTeamDetails(this.teamId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.teamName = this.data.data.name;
          this.usersAutoComplete();
        } else {
          this.dataService.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.dataService.messages(environment.messages.error, "error");
      }
    );
  }
  usersAutoComplete() {
    this.dataService.getCompanyUsersList().subscribe(
      (dataList) => {
        this.dataUsers = dataList;
        if (this.dataUsers.errorCode === 0) {
          this.dataUsers = this.dataUsers.data;

          this.dataUsers.forEach((element) => {
            if (
              (element.teamName && element.teamName !== this.teamName) ||
              !element.teamName
            ) {
              this.users.push(
                new User(element.description, element.id, element.teamName)
              );
            }
          });
          this.filteredUsers = this.userControl.valueChanges.pipe(
            startWith<string | User[]>(""),
            map((value) =>
              typeof value === "string" ? value : this.lastFilter
            ),
            map((filter) => this.filter(filter))
          );
        } else {
          this.dataService.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.dataService.messages(environment.messages.error, "error");
      }
    );
  }

  filter(filter: string): User[] {
    this.lastFilter = filter;
    if (filter) {
      return this.users.filter((option) => {
        return option.description.toLowerCase().includes(filter.toLowerCase());
      });
    } else {
      return this.users.slice();
    }
  }
  displayFn(user: User): string {
    return user && user.description ? user.description : "";
  }
  reset() {
    this.userInput.nativeElement.value = "";
    this.userControl.setValue(null);
    this.lastFilter = "";
    this.filteredUsers = this.userControl.valueChanges.pipe(
      startWith<string | User[]>(""),
      map((value) => (typeof value === "string" ? value : this.lastFilter)),
      map((filter) => this.filter(filter))
    );
  }
  resetBlur() {
    this.userInput.nativeElement.value = "";
    this.userControl.setValue(null);
  }
  optionClicked(event: Event, user: User) {
    this.userInput.nativeElement.value = "";
    this.userControl.setValue(null);
    event.stopPropagation();
    this.toggleSelection(user);
  }
  toggleSelection(user: User) {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedUsers.push(user);
    } else {
      this.users.forEach((element) => {
        if (user.id === element.id) {
          element.selected = false;
        }
      });
      const i = this.selectedUsers.findIndex(
        (value) =>
          value.description === user.description && value.id === user.id
      );
      this.selectedUsers.splice(i, 1);
    }
  }
  optionClickedMobile(event: Event, user: User) {
    this.userInput.nativeElement.value = "";
    this.userControl.setValue(null);
    event.stopPropagation();
    this.toggleSelectionTemp(user);
  }
  toggleSelectionTemp(user: User) {
    user.selected = !user.selected;
    if (user.selected) {
      this.mobileTempSelectedUsers.push(user);
    } else {
      const i = this.mobileTempSelectedUsers.findIndex(
        (value) =>
          value.description === user.description && value.id === user.id
      );
      this.mobileTempSelectedUsers.splice(i, 1);
    }
  }
  saveSelectedUsers() {
    this.selectedUsers = [];
    this.mobileTempSelectedUsers.forEach((element) => {
      this.selectedUsers.push(element);
    });
  }
  showMembersPopUp() {
    if (this.dataService.mobileView) {
      this.mobileTempSelectedUsers = [];
      this.showMembersAutoCompletePopUp = true;
      this.dataService.disableBodyScroll();
      this.selectedUsers.forEach((element, index) => {
        this.mobileTempSelectedUsers.push(element);
        this.mobileTempSelectedUsers[index].selected = true;
      });
      setTimeout(() => {
        const input = document.getElementById("userInput") as HTMLInputElement;
        input.focus();
        input.select();
      }, 200);
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("members-popup");
      return;
    }
  }
  hidePopUp() {
    this.showMembersAutoCompletePopUp = false;
    this.dataService.enableBodyScroll();
    this.mobileTempSelectedUsers.forEach((element) => {
      element.selected = false;
    });
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("members-popup");
  }
  cancelAddMembers() {
    if (this.dataService.desktopView) {
      this.dataService.modalOf();
    } else {
      this.router.navigate([this.router.url.replace("/add-members", "")]);
    }
  }
  submitAddMembers() {
    this.submitting = true;
    let mainUsersArray = [];
    let selectedUserArray = [];
    let addUeser = [];
    if (this.dataService.teamMembers) {
      this.dataService.teamMembers.forEach((element) => {
        mainUsersArray.push(element.id);
      });
    }
    this.selectedUsers.forEach((element) => {
      selectedUserArray.push(element.id);
    });
    selectedUserArray.forEach((selected) => {
      if (!mainUsersArray.includes(selected)) {
        addUeser.push(selected);
      }
    });
    if (addUeser.length) {
      let add = "";
      addUeser.forEach((id, index) => {
        if (index === 0) {
          add = id;
        } else {
          add += "&userIds=" + id;
        }
      });
      this.dataService.addUsersToTeam(this.teamId, add).subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.dataService.getCompanyTeamMembers(this.teamId);
            this.dataService.getTeamInfo(this.teamId);
            this.dataService.modalOf();
            this.submitting = false;
          } else {
            this.dataService.messages(this.data.message, "error");
            this.submitting = false;
          }
        },
        (error) => {
          this.dataService.messages(environment.messages.error, "error");
          this.submitting = false;
        }
      );
    }
  }
}
