import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the saved language from local storage or any other source
    let savedLanguage = localStorage.getItem('locale') || 'en'; // Default to 'en' if not set
    if(localStorage.getItem('locale') === 'en-US') {
      savedLanguage = 'en'
    } else if(localStorage.getItem('locale') === 'ar') {
      savedLanguage = 'ar'
    }
    // Clone the request to add the new header
    const clonedRequest = req.clone({
      setHeaders: {
        'Accept-Language': savedLanguage
      }
    });

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }
}