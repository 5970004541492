import {Component, ElementRef, OnDestroy, OnInit} from "@angular/core";
import { DataService } from "../../../services/data.service";
import { SidenavService } from "../../workspace-dashboard/sidenav.service";
import { onMainContentChange } from "../../workspace-dashboard/animations/animations";
import { Router } from "@angular/router";
import { AuthServiceMain } from "../../../auth/auth.service";
import { VariablesService } from "../../../services/vars.service";
import * as moment from "moment";
import { Meta, Title } from "@angular/platform-browser";
import {ApprovalsService} from "../../../services/approvals.service";
import {UserService} from "../../../services/user.service";

@Component({
  selector: "app-approvals-list",
  templateUrl: "./approvals-list.component.html",
  styleUrls: ["./approvals-list.component.scss"],
  animations: [onMainContentChange],
  host: {
    "(body:click)": "onClickBody($event)",
  },
})
export class ApprovalsListComponent implements OnInit, OnDestroy{
  constructor(
    private eref: ElementRef,
    public dataService: DataService,
    private sidenavService: SidenavService,
    public router: Router,
    public auth: AuthServiceMain,
    public vars: VariablesService,
    private titleService: Title,
    private meta: Meta,
    public approvalsService: ApprovalsService,
    public userService: UserService
  ) {
    this.titleService.setTitle("Trip Approvals List | SafarHub");
    this.meta.updateTag({
      name: "description",
      content:
        "List of all travel approvals - pending, approved, declined and expired",
    });
    this.sidenavService.sideNavState$.subscribe((res) => {
      this.dataService.onSideNavChange = res;
    });
  }
  data;
  approvalActions = false;
  selectedIndex;
  flightApprovalActions = false;
  selectedIndexFlight;

  ngOnInit(): void {
    this.approvalsService.pendingArray = null;
    this.approvalsService.disapprovedArray = null;
    this.approvalsService.approvedArray = null;
    this.approvalsService.expiredArray = null;
    this.approvalsService.pendingArrayFlight = null;
    this.approvalsService.disapprovedArrayFlight = null;
    this.approvalsService.approvedArrayFlight = null;
    this.approvalsService.expiredArrayFlight = null;
    if (this.approvalsService.sortType) {
      this.approvalsService.globalListApproversApprovals(
        this.approvalsService.sortType.toUpperCase()
      );
    } else {
      this.approvalsService.globalListApproversApprovals("PENDING_APPROVAL");
    }
  }

  diffInDays(checkOut, checkIn) {
    return moment.utc(checkOut).diff(moment.utc(checkIn), "days");
  }

  onClickBody(event) {
    if (
      this.eref.nativeElement.querySelector(
        ".approval-drop-down" + this.selectedIndex
      ) &&
      !this.eref.nativeElement
        .querySelector(".approval-drop-down" + this.selectedIndex)
        .contains(event.target)
    ) {
      this.approvalActions = false;
    }
    if (
      this.eref.nativeElement.querySelector(
        ".flight-approval-drop-down" + this.selectedIndexFlight
      ) &&
      !this.eref.nativeElement
        .querySelector(".flight-approval-drop-down" + this.selectedIndexFlight)
        .contains(event.target)
    ) {
      this.flightApprovalActions = false;
    }
  }

  filterBy(filter) {
    this.approvalsService.sortType = filter;
    if (filter === "approved") {
      if (
        !this.approvalsService.approvedArray ||
        !this.approvalsService.approvedArrayFlight
      ) {
        this.approvalsService.globalListApproversApprovals(filter.toUpperCase());
      }
      this.approvalsService.filteredApprovalsList =
        this.approvalsService.approvedArray?.slice();
      this.approvalsService.filteredApprovalsFlightList =
        this.approvalsService.approvedArrayFlight?.slice();
    } else if (filter === "pending_approval") {
      if (
        !this.approvalsService.pendingArray ||
        !this.approvalsService.pendingArrayFlight
      ) {
        this.approvalsService.globalListApproversApprovals(filter.toUpperCase());
      }
      this.approvalsService.filteredApprovalsList =
        this.approvalsService.pendingArray?.slice();
      this.approvalsService.filteredApprovalsFlightList =
        this.approvalsService.pendingArrayFlight?.slice();
    } else if (filter === "disapproved") {
      if (
        !this.approvalsService.disapprovedArray ||
        !this.approvalsService.disapprovedArrayFlight
      ) {
        this.approvalsService.globalListApproversApprovals(filter.toUpperCase());
      }
      this.approvalsService.filteredApprovalsList =
        this.approvalsService.disapprovedArray?.slice();
      this.approvalsService.filteredApprovalsFlightList =
        this.approvalsService.disapprovedArrayFlight?.slice();
    } else if (filter === "expired") {
      if (
        !this.approvalsService.expiredArray ||
        !this.approvalsService.expiredArrayFlight
      ) {
        this.approvalsService.globalListApproversApprovals(filter.toUpperCase());
      }
      this.approvalsService.filteredApprovalsList =
        this.approvalsService.expiredArray?.slice();
      this.approvalsService.filteredApprovalsFlightList =
        this.approvalsService.expiredArrayFlight?.slice();
    }
  }

  approve(approvalIdValue, averageNightlySellingPriceValue) {
    const approvalData = {
      approvalId: approvalIdValue,
      ignoreRatePriceChange: "&ignoreRatePriceChange=false",
      averageNightlySellingPrice: averageNightlySellingPriceValue,
      pageType: "list",
    };
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@tripApproval:Trip Approval`,
        "tripApproval",
        approvalData
      );
    } else {
      this.dataService.setBottomSheet("tripApproval", approvalData);
    }
  }

  approveFlight(approvalIdValue, sellingTotalFaresValue) {
    const approvalData = {
      approvalId: approvalIdValue,
      ignoreRatePriceChange: "&ignoreRatePriceChange=false",
      sellingTotalFares: sellingTotalFaresValue,
      isFlight: true,
      pageType: "list",
    };
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@tripApproval:Trip Approval`,
        "tripApproval",
        approvalData
      );
    } else {
      this.dataService.setBottomSheet("tripApproval", approvalData);
    }
  }

  disapprove(approvalIdValue, requesterNameValue) {
    const disapprovalData = {
      approvalId: approvalIdValue,
      requesterName: requesterNameValue,
      commentAndReason: "",
      pageType: "list",
    };
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@declineTrip:Decline trip`,
        "tripDisapproval",
        disapprovalData
      );
    } else {
      this.dataService.setBottomSheet("tripDisapproval", disapprovalData);
    }
  }

  disapproveFlight(approvalIdValue, requesterNameValue) {
    const disapprovalData = {
      approvalId: approvalIdValue,
      requesterName: requesterNameValue,
      commentAndReason: "",
      isFlight: true,
      pageType: "list",
    };
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@declineTrip:Decline trip`,
        "tripDisapproval",
        disapprovalData
      );
    } else {
      this.dataService.setBottomSheet("tripDisapproval", disapprovalData);
    }
  }

  refreshList(status) {
    this.approvalsService.globalListApproversApprovals(status.toUpperCase());
  }

  ngOnDestroy() {
    this.approvalsService.unSubscribeAllApprovalsSubscription()
  }
}
