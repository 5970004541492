import { environment } from "src/environments/environment";
import { VariablesService } from "../vars.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpClient, public VarsService: VariablesService) {}

  getDashboardData() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.dashboardApis.getDashboardData}`,
      ""
    );
  }
}