import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {DataService} from '../../../../services/data.service';
import {AuthServiceMain} from '../../../../auth/auth.service';

@Component({
  selector: 'app-contact-owner-msg',
  templateUrl: './contact-owner-msg.component.html',
  styleUrls: ['./contact-owner-msg.component.scss']
})
export class ContactOwnerMsgComponent implements OnInit {

  constructor(public dataService: DataService, public auth: AuthServiceMain) { }

  data;
  owner;
  ngOnInit(): void {
    this.dataService.getCompanyUsersList().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.owner = this.data.data.filter(item => item.role === 'OWNER');
        } else {
          this.dataService.messages(this.data.message, 'error');
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
      }
    );
  }

}
