import {Component, Input, OnDestroy, OnInit, isDevMode} from '@angular/core';
import {FlightListService} from "../../../services/flight-services/flight-list.service";
import {DataService} from "../../../services/data.service";
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {FlightService} from "../../../services/flight-services/flight.service";
import {environment} from "../../../../environments/environment";
import {Subscription} from "rxjs";
@Component({
  selector: 'app-upselling-section',
  templateUrl: './upselling-section.component.html',
  styleUrls: ['./upselling-section.component.scss']
})
export class UpsellingSectionComponent implements OnInit, OnDestroy {
  @Input() fares;
  @Input() index;
  @Input() item;
  @Input() transactionId;
  travelerId;
  flightDataRequestObj;
  pricingSubscription: Subscription;
  pricingData;
  errorMsg = '';
  selectedFareIndex: number | null = null;
  isDevMode: boolean;
  constructor(public flightListService: FlightListService,
                 private flightService: FlightService,
                 public Service: DataService,
                private location: Location,
                private route: ActivatedRoute,
                public dataService: DataService) { }

  ngOnInit(): void {
    this.isDevMode = isDevMode();
    this.errorMsg = '';
  }



  navigateToFlightInfo(index, transactionId) {
    this.selectedFareIndex = index;
    this.errorMsg = '';
    this.route.queryParams.subscribe((params) => {
      this.travelerId = params['travelerId'];
      this.flightDataRequestObj = {
        requestObj: {
          transactionId: transactionId,
          travelers: [
            {
              travelerId: this.travelerId,
              travelerType: "ADULT"
            }
          ]
        }
      };



      this.flightService.pricingLoading.next(true);
      this.pricingSubscription =  this.flightService.flightPricing(this.flightDataRequestObj.requestObj).subscribe(
          data => {
            this.pricingData = data;
            if (this.pricingData.errorCode === 0) {

              this.flightService.pricingLoading.next(false);
              this.flightService.flightPricingDetails.next(this.pricingData.data);
              sessionStorage.setItem("flightPricingData", JSON.stringify(this.pricingData.data));

              /***************************************/
              // Get existing query parameters
              const queryParams = { ...this.route.snapshot.queryParams };

              // Add or update the flightInfoId
              queryParams['flightInfoId'] = this.transactionId;

              // Build the updated query string
              const queryString = Object.keys(queryParams)
                  .map(key => `${key}=${queryParams[key]}`)
                  .join('&');
              console.log(queryString);

              // Use replaceState to update the URL without reloading the page
              this.location.replaceState('/flights/availability', queryString);

               /***************************************/

              let pricingDetail = {
                flightDetails: this.flightService.flightPricingDetails.value.flightOffers[0],
                isDataAlreadyExist: true,
                isFLightInfo: true
              };


              this.Service.setRightSideDrawer("flightInfo", pricingDetail);

            } else {
              this.flightService.pricingLoading.next(false);
              this.errorMsg = this.pricingData.message;
            }
          },
          error => {
            this.flightService.pricingLoading.next(false);
            this.errorMsg = environment.messages.error;
          }
      );


    });
  }

  showMoreInfo(amenities){
    if (this.dataService.desktopView) {
      this.dataService.setModal('', 'amenities', amenities);
    } else {
      this.dataService.setBottomSheet('amenities', amenities);
    }
  }

  ngOnDestroy() {
    if(this.pricingSubscription){
      this.pricingSubscription.unsubscribe();
    }
  }
}
