import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {environment} from '../../../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.scss']
})
export class PolicyDetailsComponent implements OnInit {

  constructor(public dataService: DataService, public router: Router, public auth: AuthServiceMain) { }
  data;
  policyInfo;
  urlArray;
  policyId;
  removePolicyModalData;
  ngOnInit(): void {
    this.urlArray = (this.router.url).split('/');
    this.policyId = this.urlArray[this.urlArray.length - 1];
    this.getPolicyInfo(this.policyId);
  }
  getPolicyInfo(policyId) {
    this.dataService.getPolicyDetails(policyId).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.policyInfo = this.data.data;
        } else {
          this.dataService.messages(this.data.message, 'error');
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
      }
    );
  }
  formatDate(date) {
    return 'Since ' + moment(date).format('MMMM') + ' ' +  moment(date).format('YYYY');
  }
  editPolicy(policyId) {
    if (this.dataService.desktopView) {
      this.dataService.setModal('Edit Policy', 'editPolicy', policyId);
    } else {
      this.router.navigate([this.router.url + '/' + policyId + '/edit']);
    }
  }
  removePolicy(policyIdParam, policyNameParam) {
    this.removePolicyModalData = {
      policyId: policyIdParam,
      policyName: policyNameParam
    }
    if (this.dataService.desktopView) {
      this.dataService.setModal('Remove Policy', 'removePolicy',  this.removePolicyModalData);
    } else {
      this.dataService.setBottomSheet('removePolicy',  this.removePolicyModalData);
    }
  }
}
