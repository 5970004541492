import { Component, OnInit } from '@angular/core';
import {AuthServiceMain} from '../../../auth/auth.service';
import {onMainContentChange} from '../../workspace-dashboard/animations/animations';
import {DataService} from '../../../services/data.service';
import {SidenavService} from '../../workspace-dashboard/sidenav.service';
import {Router} from '@angular/router';
import {VariablesService} from '../../../services/vars.service';
import {environment} from "../../../../environments/environment";
import * as moment from "moment";

@Component({
  selector: 'app-user-rewards-history',
  templateUrl: './user-rewards-history.component.html',
  styleUrls: ['./user-rewards-history.component.scss']
})
export class UserRewardsHistoryComponent implements OnInit {

  constructor(public dataService: DataService, private sidenavService: SidenavService,
              public router: Router, public auth: AuthServiceMain, public vars: VariablesService) {
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
data;
  historyList;
  ngOnInit(): void {
    this.dataService.getRewardWalletOperations().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.historyList = this.data.data;
        } else {
          this.dataService.messages(this.data.message, 'error');
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
      }
    );
  }
 /* formatDate(date) {
    return  moment(date).format('ddd') + ', ' + moment(date).format('MMM') + ' ' + moment(date).format('DD');
  }*/
}
