import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../../../../services/data.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-flight-terms',
  templateUrl: './flight-terms.component.html',
  styleUrls: ['./flight-terms.component.scss']
})
export class FlightTermsComponent implements OnInit {
@Input() flightPricing;
  env = environment;
  constructor(public dataService: DataService) { }

  ngOnInit(): void {
  }

}
