import {Component, Injectable, OnInit} from '@angular/core';
import {DataService} from '../data.service';

@Component({
  selector: 'app-right-side-drawer',
  templateUrl: './right-side-drawer.component.html',
  styleUrls: ['./right-side-drawer.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class RightSideDrawerComponent {
  constructor(public dataService: DataService) {}
}
