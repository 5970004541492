<div class="fixed-bar bg-white" *ngIf="dataService.showCookiesMsg && !(router.url.indexOf('mobile') >= 0) || router.url === '/info/mobileapp' ">
  <div class="cookies-bar d-lg-flex mx-auto d-block pb-3 pt-0 pt-lg-3 px-3">
    <div class="text-right gray-text-dark font-20 d-block d-lg-none mb-0 position-relative">
      <span class="py-2 px-3 close icon-close-light font-12 bolder gray-text-dark" (click)="hideCookiesMsg()"></span>
    </div>
    <p class="font-16 mt-0 mt-lg-3" [ngClass]="{'mr-auto': dataService.isSiteLtr(), 'ml-auto': dataService.isSiteRtl()}">This website uses cookies to enhance your experience and offer the best service. By using our website, you agree to our
      <a class="gray-text-dark under" href="/info/privacy" target="_blank">Privacy Policy</a> and <a class="gray-text-dark" href="/info/terms" target="_blank">Terms</a> </p>
  <div class="d-flex">
    <button class="btn btn-block gray-text-dark font-16 d-none d-lg-block my-auto" (click)="hideCookiesMsg()">ok</button>
  </div>
  </div>
</div>
