<div class="px-3 pt-3 pb-5">
  <div class="d-flex tabs gray-text font-18 mb-4" *ngIf="dataService.mobileView">
    <div class="tab-item py-3 px-4" (click)="checkCityLimit(1)" [ngClass]="{ active: stepCounter === 1 }"
      i18n="@@flight">
      Flight
    </div>
    <div class="tab-item py-3 px-4" (click)="stepCounter = 2" [ngClass]="{ active: stepCounter === 2 }" i18n="@@hotel">
      Hotel
    </div>
    <div class="tab-item py-3 px-4" (click)="checkCityLimit(3)" [ngClass]="{ active: stepCounter === 3 }"
      i18n="@@approval">
      Approval
    </div>
  </div>
  <form name="createPolicyForm px-lg-3" [formGroup]="createPolicyForm">
    <div [ngClass]="{ 'w-75': dataService.desktopView }">
      <div *ngIf="stepCounter === 1" class="position-relative">
        <p *ngIf="dataService.mobileView">
          <a class="blue-text font-16" (click)="stepCounter = 2" i18n="@@skipFlightRules">Skip flight rules</a>
        </p>
        <div class="d-flex position-relative" [ngClass]="{
            'mb-4': dataService.desktopView,
            'my-4': dataService.mobileView
          }">
          <p class="gray-primaray-text" [ngClass]="{
              'font-18': dataService.desktopView,
              'font-16': dataService.mobileView
            }" i18n="@@flightPolicy">
            Flight Policy
          </p>
          <p class="tooltip d-inline" [ngClass]="{
              'pl-1': dataService.isSiteLtr(),
              'pr-1': dataService.isSiteRtl()
            }">
            <i class="icon-warning-msg mlight-text font-14"></i>
            <span class="tooltip-text border rounded bg-white p-3 font-12" i18n="@@flightInfo">If disabled, approval
              will be required for any flight
              booking.</span>
          </p>
          <mat-slide-toggle formControlName="flightAllowedToggle" [ngClass]="{
              'pl-4': dataService.isSiteLtr(),
              'pr-4': dataService.isSiteRtl()
            }" (click)="
              disableFlightPolicy = !disableFlightPolicy;
              $event.stopPropagation()
            " (change)="editPolicy('flightAllowedToggle', null, null)">
          </mat-slide-toggle>
          <div [ngClass]="{ 'toggle-loader realtime-loader': flightToggleLoader }"></div>
        </div>

        <div class="toggle-container" [ngClass]="{
            disabled:
              (policyId &&
                this.policyInfo &&
                this.policyInfo.flightBookingNotAllowed === true) ||
              (!policyId && disableFlightPolicy === true)
          }">
          <p class="gray-primaray-text mb-2 font-16" i18n="@@fareClass">
            Fare class
          </p>
          <div [ngClass]="{ 'realtime-loader': fairClassLoader }">
            <select #fairClass formControlName="fairClass" class="form-control mt-2 select-custom-arrow mb-3"
              name="fairClass" id="fairClass" [ngClass]="{
                'edit-field': policyId && !editFairClass,
                'no-bg-img': fairClassLoader
              }" (click)="editFairClass = true" (change)="setSelectedFairClass()"
              (keyup.enter)="enterActionFunc('fairClass', null, null)" (keyup.escape)="
                restorePolicy('fairClass', null); fairClass.blur()
              ">
              <ng-container *ngFor="let item of vars.classType">
                <option [value]="selectedFairClass" *ngIf="selectedFairClass === item.value" [selected]="true">
                  {{ item.name }}
                </option>
                <option [value]="item.value" *ngIf="selectedFairClass !== item.value">
                  {{ item.name }}
                </option>
              </ng-container>
            </select>
          </div>

          <div class="d-flex">
          <p class="gray-primaray-text" [ngClass]="{
            'font-18': dataService.desktopView,
            'font-16': dataService.mobileView
          }" i18n="@@dynamicPriceLimit">
            Dynamic price limit
        </p>
        <p class="tooltip d-inline" [ngClass]="{
            'pl-1': dataService.isSiteLtr(),
            'pr-1': dataService.isSiteRtl()
          }">
          <i class="icon-warning-msg mlight-text font-14"></i>
          <span class="tooltip-text border rounded bg-white p-3 font-12" i18n="@@dynamicPriceLimitInfo">Set a dynamic price limit based on the flight route to maintain flexibility in your policy.</span>
        </p>
</div>
<div class = "d-flex">
          <div class="form-group px-0 col-5 amount-field" [ngClass]="{ 'realtime-loader': medianPriceLimitPercentageLoader }">
            <select *ngIf="
                (dataService.desktopView || policyId === undefined)
              " #medianPriceLimitPercentage id="medianPriceLimitPercentage" class="form-control select-custom-arrow"
              formControlName="medianPriceLimitPercentage" name="medianPriceLimitPercentage"
              (blur)="editPolicy('medianPriceLimitPercentage', null, null)"
              (keyup.enter)="enterActionFunc('medianPriceLimitPercentage', null, null)" (keyup.escape)="
                restorePolicy('medianPriceLimitPercentage', null); medianPriceLimitPercentage.blur()
              " [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.medianPriceLimitPercentage &&
                  f.medianPriceLimitPercentage.errors,
                'edit-field': policyId && !editMedianPriceLimitPercentage
              }" (click)="editMedianPriceLimitPercentage = true">
              <ng-container *ngFor="let item of medianPriceLimitPercentageList">
                <option [ngValue]="item.Value">
                  {{ item.Option }}
                </option>
              </ng-container>
            </select>
            <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId"
              (click)="showDynamicPriceLimitPopUp()">
              <p *ngIf="
                  this.policyInfo &&
                  this.policyInfo.companyPolicyFlightRules &&
                  this.policyInfo.companyPolicyFlightRules
                    .medianPriceLimitPercentage
                " class="pt-3">
                {{
                  medianPriceLimitPercentage
                }}
              </p>
            </div>
          </div>

          <div class="form-group col-8 amount-field median-price-max-width"
            [ngClass]="{ 'realtime-loader dynamic-price-loader': medianPriceLimitPercentageCategoryLoader }">
            <select *ngIf="(dataService.desktopView || policyId === undefined)
              " #medianPriceLimitPercentageCategory id="medianPriceLimitPercentageCategory"
              class="form-control select-custom-arrow" formControlName="medianPriceLimitPercentageCategory"
              name="medianPriceLimitPercentageCategory"
              (blur)="editPolicy('medianPriceLimitPercentageCategory', null, null)"
              (keyup.enter)="enterActionFunc('medianPriceLimitPercentageCategory', null, null)" (keyup.escape)="
                restorePolicy('medianPriceLimitPercentageCategory', null); medianPriceLimitPercentageCategory.blur()
              " [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.medianPriceLimitPercentageCategory &&
                  f.medianPriceLimitPercentageCategory.errors,
                'edit-field': policyId && !editMedianPriceLimitPercentageCategory
              }" (click)="editMedianPriceLimitPercentageCategory = true">
              <ng-container *ngFor="let item of medianPriceLimitPercentageOptionsList">
                <option [ngValue]="item.Value">
                  {{ item.Name }}
                </option>
              </ng-container>
            </select>
            <i *ngIf="medianPriceLimitPercentageCategory && medianPriceLimitPercentage" class="icon-close-light gray-text position-absolute max-no-trips delete-item pointer"
            (click)="removeFlightDynamicPriceLimit()"></i>
            <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId"
              (click)="showDynamicPriceLimitPopUp()">
              <p *ngIf="
                  this.policyInfo &&
                  this.policyInfo.companyPolicyFlightRules &&
                  this.policyInfo.companyPolicyFlightRules
                    .medianPriceLimitPercentage
                " class="pt-3">
                {{medianPriceLimitPercentageCategory}}
              </p>
            </div>
          </div>
</div>

          <p class="gray-primaray-text mb-2 font-16" i18n="@@flightPriceCap">
            Flight price cap
          </p>

          <div class="form-group mb-3 amount-field" [ngClass]="{ 'realtime-loader': priceCapLoader }">
            <input #flightLimit *ngIf="
                !priceCap && (dataService.desktopView || policyId === undefined)
              " type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" formControlName="flightPolicyLimit" class="form-control"
              id="flightPolicyLimit" (blur)="editPolicy('flightLimit', null, null)"
              (keyup.enter)="enterActionFunc('flightLimit', null, null)" (keyup.escape)="
                restorePolicy('flightLimit', null); flightLimit.blur()
              " [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.flightPolicyLimit &&
                  f.flightPolicyLimit.errors,
                'edit-field': policyId && !editFlightLimit
              }" (click)="editFlightLimit = true" />
            <input *ngIf="priceCap" type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" class="form-control" disabled />
            <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId && !priceCap"
              (click)="showFlightPolicyLimitPopUp()">
              <p *ngIf="
                  this.policyInfo &&
                  this.policyInfo.companyPolicyFlightRules &&
                  this.policyInfo.companyPolicyFlightRules
                    .internationalPriceLimitPerPassenger
                " class="pt-3">
                {{
                  this.policyInfo.companyPolicyFlightRules
                    .internationalPriceLimitPerPassenger
                }}
              </p>
            </div>
          </div>

          <div class="form-group mb-3 amount-field" [ngClass]="{ 'realtime-loader': priceCapLoader }">
            <select *ngIf="
                !priceCap && (dataService.desktopView || policyId === undefined)
              " #flightPolicyLimitCurrency id="flightPolicyLimitCurrency" class="form-control select-custom-arrow"
              formControlName="flightPolicyLimitCurrency" name="flightPolicyLimitCurrency"
              (blur)="editPolicy('flightLimitCurrency', null, null)"
              (keyup.enter)="enterActionFunc('flightLimitCurrency', null, null)" (keyup.escape)="
                restorePolicy('flightLimitCurrency', null); flightLimit.blur()
              " [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.flightPolicyLimitCurrency &&
                  f.flightPolicyLimitCurrency.errors,
                'edit-field': policyId && !editFlightLimit
              }" (click)="editFlightLimit = true">
              <ng-container *ngFor="let item of currenciesList">
                <option [ngValue]="item.code">
                  {{ item.code }}
                </option>
              </ng-container>
            </select>
            <input *ngIf="priceCap" type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" class="form-control" disabled />
            <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId && !priceCap"
              (click)="showFlightPolicyLimitPopUp()">
              <p *ngIf="
                  this.policyInfo &&
                  this.policyInfo.companyPolicyFlightRules &&
                  this.policyInfo.companyPolicyFlightRules
                    .internationalPriceLimitPerPassengerCurrency
                " class="pt-3">
                {{
                  this.policyInfo.companyPolicyFlightRules
                    .internationalPriceLimitPerPassengerCurrency
                }}
              </p>
            </div>
          </div>

          <div class="form-group font-16 mb-3 gray-text-dark pt-0">
            <input class="mt-2 input-mobile" type="checkbox" [checked]="priceCap" [ngClass]="{
                'mr-2': dataService.isSiteLtr(),
                'ml-2': dataService.isSiteRtl()
              }" id="priceCap" name="priceCap" (change)="showNewFields()" />
            <label class="checkbox-mobile" [ngClass]="{
                'text-nowrap':
                  dataService.desktopView && dataService.isSiteLtr()
              }" for="priceCap" i18n="@@domeAndInterPrice">Set price cap for domestic & International</label>
          </div>
          <div *ngIf="priceCap">
            <p class="gray-text mb-2" [ngClass]="{
                'font-16 mt-2': dataService.createEditPolicyPage,
                'mt-1': dataService.createEditPolicyPage
              }" i18n="@@interFlightPriceCap">
              International flight price cap
            </p>
            <div class="form-group mb-3 amount-field" *ngIf="dataService.desktopView || policyId === undefined"
              [ngClass]="{ 'realtime-loader': internationalPriceCapLoader }">
              <input #internationalLimit type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="internationalPriceCap"
                class="form-control" id="internationalPriceCap" formControlName="internationalPriceCap" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    f.internationalPriceCap &&
                    f.internationalPriceCap.errors,
                  'edit-field': policyId && !editInternationalLimit
                }" (click)="editInternationalLimit = true" (blur)="editPolicy('internationalLimit', null, null)"
                (keyup.enter)="
                  enterActionFunc('internationalLimit', null, null)
                " (keyup.escape)="
                  restorePolicy('internationalLimit', null);
                  internationalLimit.blur()
                " />
            </div>
            <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId"
              (click)="showInternationalPolicyLimitPopUp()">
              <p *ngIf="
                  this.policyInfo &&
                  this.policyInfo.companyPolicyFlightRules &&
                  this.policyInfo.companyPolicyFlightRules
                    .internationalPriceLimitPerPassenger
                " class="pt-3">
                {{
                  this.policyInfo.companyPolicyFlightRules
                    .internationalPriceLimitPerPassenger
                }}
              </p>
            </div>
            <div class="form-group mb-3 amount-field" *ngIf="dataService.desktopView || policyId === undefined"
              [ngClass]="{ 'realtime-loader': internationalPriceCapLoader }">
              <select #internationalPriceCapCurrency id="internationalPriceCapCurrency"
                class="form-control select-custom-arrow" formControlName="internationalPriceCapCurrency"
                name="internationalPriceCapCurrency" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    f.internationalPriceCapCurrency &&
                    f.internationalPriceCapCurrency.errors
                }">
                <ng-container *ngFor="let item of currenciesList">
                  <option [ngValue]="item.code">
                    {{ item.code }}
                  </option>
                </ng-container>
              </select>
            </div>
            <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId"
              (click)="showInternationalPolicyLimitPopUp()">
              <p *ngIf="
                  this.policyInfo &&
                  this.policyInfo.companyPolicyFlightRules &&
                  this.policyInfo.companyPolicyFlightRules
                    .internationalPriceLimitPerPassengerCurrency
                " class="pt-3">
                {{
                  this.policyInfo.companyPolicyFlightRules
                    .internationalPriceLimitPerPassengerCurrency
                }}
              </p>
            </div>
            <p class="gray-text mb-2" [ngClass]="{
                'font-16 mt-2': dataService.createEditPolicyPage,
                'mt-1': dataService.createEditPolicyPage
              }" i18n="@@domPriceCap">
              Domestic flight price cap
            </p>
            <div class="form-group mb-4 amount-field" *ngIf="dataService.desktopView || policyId === undefined"
              [ngClass]="{ 'realtime-loader': domesticPriceCapLoader }">
              <input #domesticLimit type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="domesticPriceCap"
                class="form-control" id="domesticPriceCap" formControlName="domesticPriceCap" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    f.domesticPriceCap &&
                    f.domesticPriceCap.errors,
                  'edit-field': policyId && !editDomesticLimit
                }" (click)="editDomesticLimit = true" (blur)="editPolicy('domesticLimit', null, null)"
                (keyup.enter)="enterActionFunc('domesticLimit', null, null)" (keyup.escape)="
                  restorePolicy('domesticLimit', null); domesticLimit.blur()
                " />
            </div>
            <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId"
              (click)="showDomesticPolicyLimitPopUp()">
              <p *ngIf="
                  this.policyInfo &&
                  this.policyInfo.companyPolicyFlightRules &&
                  this.policyInfo.companyPolicyFlightRules
                    .domesticPriceLimitPerPassenger
                " class="pt-3">
                {{
                  this.policyInfo.companyPolicyFlightRules
                    .domesticPriceLimitPerPassenger
                }}
              </p>
            </div>
            <div class="form-group mb-3 amount-field" *ngIf="dataService.desktopView || policyId === undefined"
              [ngClass]="{ 'realtime-loader': internationalPriceCapLoader }">
              <select #domesticPriceCapCurrency class="form-control select-custom-arrow"
                formControlName="domesticPriceCapCurrency" name="domesticPriceCapCurrency" id="domesticPriceCapCurrency"
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    f.domesticPriceCapCurrency &&
                    f.domesticPriceCapCurrency.errors
                }">
                <ng-container *ngFor="let item of currenciesList">
                  <option [ngValue]="item.code">
                    {{ item.code }}
                  </option>
                </ng-container>
              </select>
            </div>
            <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId"
              (click)="showDomesticPolicyLimitPopUp()">
              <p *ngIf="
                  this.policyInfo &&
                  this.policyInfo.companyPolicyFlightRules &&
                  this.policyInfo.companyPolicyFlightRules
                    .domesticPriceLimitPerPassengerCurrency
                " class="pt-3">
                {{
                  this.policyInfo.companyPolicyFlightRules
                    .domesticPriceLimitPerPassengerCurrency
                }}
              </p>
            </div>
          </div>

          <p class="gray-primaray-text mb-2" [ngClass]="{ 'font-16': dataService.createEditPolicyPage }"
            i18n="@@bookingWindow">
            Booking window
          </p>

          <div class="row" [ngClass]="{ 'mx-0': dataService.mobileView }">
            <div class="d-flex align-items-center mb-4"  *ngIf="dataService.mobileView && policyId" [ngClass]="{
              'pl-0': dataService.isSiteLtr() && dataService.mobileView,
              'pr-0': dataService.isSiteRtl()
            }"><span i18n="@@bookingWindowInDaysNote1">Flight should be booked at least</span>
              <div class=" align-items-center ml-2 mr-2 edit-field"
                [ngClass]="{ 'realtime-loader': bookingWindowInDaysLoader }"
                (click)="showBookingWindowInDaysPopUp()">
                <p class="d-inline px-4 mx-2 bookingWindowInDaysWidth" class="pt-2 pl-4 pr-4">
                  <span *ngIf="
                  this.policyInfo &&
                  this.policyInfo.companyPolicyFlightRules &&
                  this.policyInfo.companyPolicyFlightRules
                    .bookingWindowInDays
                ">
                    {{
                  this.policyInfo.companyPolicyFlightRules
                    .bookingWindowInDays
                }}
                  </span>
                  <span class="pt-5 pl-2 pr-2" *ngIf="
                  this.policyInfo &&
                  this.policyInfo.companyPolicyFlightRules &&
                  !this.policyInfo.companyPolicyFlightRules
                    .bookingWindowInDays
                ">
                  </span>
                </p>
              </div><span i18n="@@bookingWindowInDaysNote2">day(s) in advance</span>
            </div>
            <div class="d-flex align-items-center form-group" *ngIf="dataService.desktopView || policyId === undefined"
              [ngClass]="{ 'realtime-loader': bookingWindowInDaysLoader }">
              <span i18n="@@bookingWindowInDaysNote1">Flight should be booked at least</span>
              <input #bookingWindowInDays type="text" (input)="numbersOnly($event)"  name="bookingWindowInDays"
                class="form-control d-inline px-4 mx-2 bookingWindowInDaysWidth" id="bookingWindowInDays"
                formControlName="bookingWindowInDays" [ngClass]="{
                  'edit-field': policyId && !editBookingWindowInDays,
                  'pr-3': dataService.isSiteLtr(),
                  'pl-3': dataService.isSiteRtl()
                }" (click)="editBookingWindowInDays = true" (blur)="editPolicy('bookingWindowInDays', null, null)"
                (keyup.enter)="
                  enterActionFunc('bookingWindowInDays', null, null)
                " (keyup.escape)="
                  restorePolicy('bookingWindowInDays', null);
                  bookingWindowInDays.blur()
                " /><span i18n="@@bookingWindowInDaysNote2">day(s) in advance</span>
            </div>
          </div>

          <p class="gray-primaray-text mb-2" [ngClass]="{ 'font-16': dataService.createEditPolicyPage }"
            i18n="@@maxNoOfTrips">
            Max number of trips
          </p>

          <div class="row row-max-width" [ngClass]="{ 'mx-0': dataService.mobileView }">
            <div class="col-6" [ngClass]="{
                'pl-0': dataService.isSiteLtr() && dataService.mobileView,
                'pr-0': dataService.isSiteRtl()
              }">
              <div class="form-control edit-field" [ngClass]="{ 'realtime-loader': tripsMaxNoLoader }"
                *ngIf="dataService.mobileView && policyId" (click)="showMaxNoOfTripsPopUp()">
                <p *ngIf="
                    this.policyInfo &&
                    this.policyInfo.companyPolicyFlightRules &&
                    this.policyInfo.companyPolicyFlightRules
                      .maximumNumberOfTrips
                  " class="pt-3">
                  {{
                    this.policyInfo.companyPolicyFlightRules
                      .maximumNumberOfTrips
                  }}
                </p>
              </div>

              <div class="form-group mb-4" *ngIf="dataService.desktopView || policyId === undefined"
                [ngClass]="{ 'realtime-loader': tripsMaxNoLoader }">
                <input #maximumNumberOfTrips type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="maximumNumberOfTrips"
                  class="form-control" id="maximumNumberOfTrips" formControlName="maximumNumberOfTrips" [ngClass]="{
                    'edit-field': policyId && !editMaxNoOfTrips,
                    'pr-3': dataService.isSiteLtr(),
                    'pl-3': dataService.isSiteRtl()
                  }" (click)="editMaxNoOfTrips = true" (blur)="editPolicy('maximumNumberOfTrips', null, null)"
                  (keyup.enter)="
                    enterActionFunc('maximumNumberOfTrips', null, null)
                  " (keyup.escape)="
                    restorePolicy('maximumNumberOfTrips', null);
                    maximumNumberOfTrips.blur()
                  " />
              </div>
            </div>
            <div class="col-6" [ngClass]="{
                'pl-0': dataService.isSiteLtr(),
                'pr-0': dataService.isSiteRtl()
              }">
              <div [ngClass]="{
                  'realtime-loader': maxIntervalLoader,
                  invisible: policyId && !showIntervalList
                }">
                <select #maxInterval formControlName="maxInterval" class="form-control select-custom-arrow mb-3"
                  name="maxInterval" id="maxInterval" [ngClass]="{
                    'edit-field': policyId && !editMaxInterval,
                    'no-bg-img': maxIntervalLoader
                  }" (click)="editMaxInterval = true" (change)="setSelectedMaxInterval()"
                  (keyup.enter)="enterActionFunc('maxInterval', null, null)" (keyup.escape)="
                    restorePolicy('maxInterval', null); maxInterval.blur()
                  ">
                  <ng-container *ngFor="let item of vars.intervals">
                    <option [value]="selectedMaxInterval" *ngIf="selectedMaxInterval === item.value" [selected]="true">
                      {{ item.name }}
                    </option>
                    <option [value]="item.value" *ngIf="selectedMaxInterval !== item.value">
                      {{ item.name }}
                    </option>
                  </ng-container>
                </select>
                <i class="icon-close-light gray-text position-absolute max-no-trips delete-item pointer"
                  (click)="removeMaxNoOfTrips()"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="stepCounter === 2">
        <p *ngIf="dataService.mobileView">
          <a class="blue-text font-16" (click)="stepCounter = 3" i18n="@@skipHotelRules">Skip hotel rules</a>
        </p>

        <div class="d-flex position-relative" [ngClass]="{
            'mb-4': dataService.desktopView,
            'my-4': dataService.mobileView
          }">
          <p class="gray-primaray-text" [ngClass]="{
              'font-18': dataService.desktopView,
              'font-16': dataService.mobileView
            }" i18n="@@hotelPolicy">
            Hotel Policy
          </p>
          <p class="tooltip d-inline" [ngClass]="{
              'pl-1': dataService.isSiteLtr(),
              'pr-1': dataService.isSiteRtl()
            }">
            <i class="icon-warning-msg mlight-text font-14"></i>
            <span class="tooltip-text border rounded bg-white p-3 font-12" i18n="@@hotelInfo">If disabled, approval will
              be required for any hotel
              booking.</span>
          </p>
          <mat-slide-toggle formControlName="hotelAllowedToggle" [ngClass]="{
              'pl-4': dataService.isSiteLtr(),
              'pr-4': dataService.isSiteRtl()
            }" (click)="
              disableHotelPolicy = !disableHotelPolicy; $event.stopPropagation()
            " (change)="editPolicy('hotelAllowedToggle', null, null)">
          </mat-slide-toggle>
          <div [ngClass]="{ 'toggle-loader realtime-loader': hotelToggleLoader }"></div>
        </div>
        <div class="toggle-container" [ngClass]="{
            disabled:
              (policyId &&
                this.policyInfo &&
                this.policyInfo.hotelBookingNotAllowed === true) ||
              (!policyId && disableHotelPolicy === true)
          }">

<div class="d-flex">
  <p class="gray-primaray-text" [ngClass]="{
    'font-18': dataService.desktopView,
    'font-16': dataService.mobileView
  }" i18n="@@dynamicPriceLimit">
    Dynamic price limit
</p>
<p class="tooltip d-inline" [ngClass]="{
    'pl-1': dataService.isSiteLtr(),
    'pr-1': dataService.isSiteRtl()
  }">
  <i class="icon-warning-msg mlight-text font-14"></i>
  <span class="tooltip-text border rounded bg-white p-3 font-12" i18n="@@dynamicPriceLimitHotelInfo"> Set a dynamic price limit based on the hotel destination to maintain flexibility in your policy.</span>
</p>
</div>
<div class="d-flex">
  <div class="form-group px-0 col-5 amount-field" [ngClass]="{ 'realtime-loader': hotelMedianPriceLimitPercentageLoader }">
    <select *ngIf="
        (dataService.desktopView || policyId === undefined)
      " #hotelMedianPriceLimitPercentage id="hotelMedianPriceLimitPercentage" class="form-control select-custom-arrow"
      formControlName="hotelMedianPriceLimitPercentage" name="hotelMedianPriceLimitPercentage"
      (blur)="editPolicy('hotelMedianPriceLimitPercentage', null, null)"
      (keyup.enter)="enterActionFunc('hotelMedianPriceLimitPercentage', null, null)" (keyup.escape)="
        restorePolicy('hotelMedianPriceLimitPercentage', null); hotelMedianPriceLimitPercentage.blur()
      " [ngClass]="{
        'is-invalid':
          submitted &&
          f.hotelMedianPriceLimitPercentage &&
          f.hotelMedianPriceLimitPercentage.errors,
        'edit-field': policyId && !editHotelMedianPriceLimitPercentage
      }" (click)="editHotelMedianPriceLimitPercentage = true">
      <ng-container *ngFor="let item of hotelMedianPriceLimitPercentageList">
        <option [ngValue]="item.Value">
          {{ item.Option }}
        </option>
      </ng-container>
    </select>
    <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId"
      (click)="showHotelDynamicPriceLimitPopUp()">
      <p *ngIf="
          this.policyInfo &&
          this.policyInfo.companyPolicyHotelRules &&
          this.policyInfo.companyPolicyHotelRules 
            .medianPriceLimitPercentage
        " class="pt-3">
        {{
          hotelMedianPriceLimitPercentage
        }}
      </p>
    </div>
  </div>

  <div class="form-group median-price-max-width col-8 amount-field"
    [ngClass]="{ 'realtime-loader dynamic-price-loader': hotelMedianPriceLimitPercentageCategoryLoader }">
    <select *ngIf="(dataService.desktopView || policyId === undefined)
      " #hotelMedianPriceLimitPercentageCategory id="hotelMedianPriceLimitPercentageCategory"
      class="form-control select-custom-arrow" formControlName="hotelMedianPriceLimitPercentageCategory"
      name="hotelMedianPriceLimitPercentageCategory"
      (blur)="editPolicy('hotelMedianPriceLimitPercentageCategory', null, null)"
      (keyup.enter)="enterActionFunc('hotelMedianPriceLimitPercentageCategory', null, null)" (keyup.escape)="
        restorePolicy('hotelMedianPriceLimitPercentageCategory', null); hotelMedianPriceLimitPercentageCategory.blur()
      " [ngClass]="{
        'is-invalid':
          submitted &&
          f.hotelMedianPriceLimitPercentageCategory &&
          f.hotelMedianPriceLimitPercentageCategory.errors,
        'edit-field': policyId && !editHotelMedianPriceLimitPercentageCategory
      }" (click)="editHotelMedianPriceLimitPercentageCategory = true">
      <ng-container *ngFor="let item of hotelMedianPriceLimitPercentageOptionsList">
        <option [ngValue]="item.Value">
          {{ item.Name }}
        </option>
      </ng-container>
    </select>
    <i *ngIf="hotelMedianPriceLimitPercentageCategory && hotelMedianPriceLimitPercentage" class="icon-close-light gray-text position-absolute max-no-trips delete-item pointer"
    (click)="removeHotelDynamicPriceLimit()"></i>
    <div class="form-control edit-field mb-3" *ngIf="dataService.mobileView && policyId"
      (click)="showHotelDynamicPriceLimitPopUp()">
      <p *ngIf="
          this.policyInfo &&
          this.policyInfo.companyPolicyHotelRules &&
          this.policyInfo.companyPolicyHotelRules
            .medianPriceLimitPercentage
        " class="pt-3">
        {{hotelMedianPriceLimitPercentageCategory}}
      </p>
    </div>
  </div>
</div>

          <p class="gray-primaray-text mb-2 font-16 mt-2" i18n="@@avgNightlyPrice">
            Hotel average nightly price limit
          </p>
          <div class="form-group mb-3 amount-field" *ngIf="dataService.desktopView || policyId === undefined"
            [ngClass]="{ 'realtime-loader': limitLoader }">
            <input #limitInput type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="policyLimit"
              formControlName="policyLimit" class="form-control" id="limitInputPolicy" (click)="editPolicyLimit = true"
              (blur)="editPolicy('hotelLimit', null, null)" (keyup.enter)="enterActionFunc('hotelLimit', null, null)"
              (keyup.escape)="
                restorePolicy('hotelLimit', null); limitInput.blur()
              " [ngClass]="{
                'is-invalid': submitted && f.policyLimit.errors,
                'edit-field': policyId && !editPolicyLimit
              }" />
          </div>
          <div class="form-control mb-3 edit-field" *ngIf="dataService.mobileView && policyId"
            (click)="showPolicyLimitPopUp()">
            <p *ngIf="
                this.policyInfo &&
                this.policyInfo.companyPolicyHotelRules &&
                this.policyInfo.companyPolicyHotelRules.avgNightlyPriceLimit
              " class="pt-3">
              {{ this.policyInfo.companyPolicyHotelRules.avgNightlyPriceLimit }}
            </p>
          </div>
          <div class="form-group mb-3 amount-field" *ngIf="dataService.desktopView || policyId === undefined"
            [ngClass]="{ 'realtime-loader': limitLoader }">
            <select class="form-control select-custom-arrow" formControlName="policyLimitCurrency"
              name="policyLimitCurrency" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.policyLimitCurrency &&
                  f.policyLimitCurrency.errors
              }">
              <ng-container *ngFor="let item of currenciesList">
                <option [ngValue]="item.code">
                  {{ item.code }}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="form-control mb-3 edit-field" *ngIf="dataService.mobileView && policyId"
            (click)="showPolicyLimitPopUp()">
            <p *ngIf="
                this.policyInfo &&
                this.policyInfo.companyPolicyHotelRules &&
                this.policyInfo.companyPolicyHotelRules
                  .avgNightlyPriceLimitCurrency
              " class="pt-3">
              {{
                this.policyInfo.companyPolicyHotelRules
                  .avgNightlyPriceLimitCurrency
              }}
            </p>
          </div>
          <p class="font-12 gray-text mt-4" i18n="@@cityExcep">
            You can add an exception per city
          </p>
          <div [ngClass]="{ 'city-field-width-mob': dataService.mobileView }"
            *ngFor="let city of citiesList; let i = index">
            <div class="city-item gray-text mb-3 d-flex position-relative form-control" [ngClass]="{
                'edit-field': policyId
              }">
              <input type="text" value="{{ city.caption }}" class="gray-text input-style pt-3" [ngClass]="{
                  'edit-field': policyId,
                  'pr-3': dataService.mobileView
                }" readonly />
            </div>
            <div class="d-flex position-relative">
              <div class="form-group mb-3 amount-field w-50" *ngIf="dataService.desktopView || policyId === undefined"
                [ngClass]="{
                  'realtime-loader': limitLoader,
                  'mr-1': dataService.isSiteLtr(),
                  'ml-1': dataService.isSiteRtl()
                }">
                <input type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="place-{{ i }}" class="form-control w-100"
                  formControlName="cityLimit{{ i }}" id="place-{{ i }}" [ngClass]="{
                    'is-invalid': cityLimitMobileErrorMsg,
                    'edit-field': policyId && !editCitiesLimit[i]
                  }" (click)="editCitiesLimit[i] = true" (blur)="editPolicy('cityLimit', i, false)"
                  (keyup.enter)="enterActionFunc('cityLimit', i, false)" />
                <div class="text-danger smaller mb-2">
                  {{ cityLimitMobileErrorMsg }}
                </div>
              </div>
              <div class="form-control edit-field mb-3 w-50" [ngClass]="{
                  'realtime-loader': limitLoader,
                  'mr-1': dataService.isSiteLtr(),
                  'ml-1': dataService.isSiteRtl()
                }" *ngIf="dataService.mobileView && policyId" (click)="showCityPolicyLimitPopUp(i)">
                <p *ngIf="
                    this.policyInfo &&
                    this.policyInfo.companyPolicyHotelRules &&
                    this.policyInfo.companyPolicyHotelRules.exceptions[i]
                  " class="pt-3">
                  {{
                    this.policyInfo.companyPolicyHotelRules.exceptions[i]
                      .avgNightlyPriceLimit
                  }}
                </p>
              </div>
              <div class="form-group mb-3 amount-field w-50" *ngIf="dataService.desktopView || policyId === undefined"
                [ngClass]="{
                  'realtime-loader': limitLoader,
                  'ml-1': dataService.isSiteLtr(),
                  'mr-1': dataService.isSiteRtl()
                }">
                <select id="domesticPriceCapCurrency" class="form-control select-custom-arrow w-100"
                  formControlName="cityLimitCurrency{{ i }}" name="cityLimitCurrency" [ngClass]="{
                    'is-invalid':
                      submitted &&
                      f.cityLimitCurrency &&
                      f.cityLimitCurrency.errors
                  }">
                  <ng-container *ngFor="let item of currenciesList">
                    <option [ngValue]="item.code">
                      {{ item.code }}
                    </option>
                  </ng-container>
                </select>
              </div>
              <div class="form-control edit-field mb-3 w-50" [ngClass]="{
                  'realtime-loader': limitLoader,
                  'ml-1': dataService.isSiteLtr(),
                  'mr-1': dataService.isSiteRtl()
                }" *ngIf="dataService.mobileView && policyId" (click)="showCityPolicyLimitPopUp(i)">
                <p *ngIf="
                    this.policyInfo &&
                    this.policyInfo.companyPolicyHotelRules &&
                    this.policyInfo.companyPolicyHotelRules.exceptions[i]
                  " class="pt-3">
                  {{
                    this.policyInfo.companyPolicyHotelRules.exceptions[i]
                      .avgNightlyPriceLimitCurrency
                  }}
                </p>
              </div>
              <i class="icon-close-light gray-text position-absolute delete-item pointer" (click)="removeCity(i)"
                [ngClass]="{ 'realtime-loader city-limit': cityLoaders[i] }"></i>
            </div>
          </div>
          <p *ngIf="dataService.desktopView" class="blue-text pointer font-16" (click)="addRequiredToDefaultValue()"
            i18n="@@addCity">
            Add city
          </p>
          <p *ngIf="dataService.mobileView" class="blue-text pointer font-16">
            <span (click)="addRequiredToDefaultValue()" i18n="@@addCity">Add city</span>
          </p>

          <mat-form-field class="w-100 autoComplete-max-width" *ngIf="showAutoCompleteField">
            <div class="form-group m-0 with-icon">
              <div class="form-control">
                <input (keydown)="onChangeSearch()" (ngModelChange)="this.SearchTextQuery.next($event)" matInput
                  [matAutocomplete]="auto" autocomplete="off" [(ngModel)]="this.caption"
                  [ngModelOptions]="{ standalone: true }" name="list" (focus)="autocompleteOnFocus($event)"
                  placeholder="select" type="text" id="list" class="py-3 mb-3" />
                <span *ngIf="caption !== undefined && caption.length === 0" class="gray-text placeholder-search"
                  i18n="@@enterCityOnly">Enter city name...</span>
              </div>

              <i class="icon-location gray-text"></i>
              <mat-autocomplete #auto="matAutocomplete">
                <ng-container *ngFor="
                    let autocompletetype of autocompletelist;
                    let y = index
                  ">
                  <div *ngIf="y === 0">
                    <ng-container *ngFor="let entity of autocompletetype.entities">
                      <mat-option data-id="{{ entity.code }}" tabindex="-1" [value]="entity.caption" (click)="
                          setSearchText(entity, autocompletetype.name);
                          showAutoCompleteField = false
                        " *ngIf="notSelected(entity.caption)">
                        <span class="d-block">
                          {{ entity.caption }}
                        </span>
                      </mat-option>
                    </ng-container>
                  </div>
                </ng-container>
              </mat-autocomplete>
            </div>
          </mat-form-field>

          <p class="gray-primaray-text mb-2" [ngClass]="{ 'font-16': dataService.createEditPolicyPage }"
          i18n="@@bookingWindow">
          Booking window
        </p>

        <div class="row" [ngClass]="{ 'mx-0': dataService.mobileView }">
          <div class="d-flex align-items-center mb-4"  *ngIf="dataService.mobileView && policyId" [ngClass]="{
            'pl-0': dataService.isSiteLtr() && dataService.mobileView,
            'pr-0': dataService.isSiteRtl()
          }"><span i18n="@@bookingWindowInDaysHotelNote1">Hotel should be booked at least</span>
            <div class=" align-items-center ml-2 mr-2 edit-field"
              [ngClass]="{ 'realtime-loader': hotelBookingWindowInDaysLoader }"
              (click)="showHotelBookingWindowInDaysPopUp()">
              <p class="d-inline px-4 mx-2 bookingWindowInDaysWidth" class="pt-2 pl-4 pr-4">
                <span *ngIf="
                this.policyInfo &&
                this.policyInfo.companyPolicyHotelRules &&
                this.policyInfo.companyPolicyHotelRules
                  .bookingWindowInDays
              ">
                  {{
                this.policyInfo.companyPolicyHotelRules
                  .bookingWindowInDays
              }}
                </span>
                <span class="pt-5 pl-2 pr-2" *ngIf="
                this.policyInfo &&
                this.policyInfo.companyPolicyHotelRules &&
                !this.policyInfo.companyPolicyHotelRules
                  .bookingWindowInDays
              ">
                </span>
              </p>
            </div><span i18n="@@bookingWindowInDaysNote2">day(s) in advance</span>
          </div>
          <div class="d-flex align-items-center form-group" *ngIf="dataService.desktopView || policyId === undefined"
            [ngClass]="{ 'realtime-loader': hotelBookingWindowInDaysLoader }">
            <span i18n="@@bookingWindowInDaysHotelNote1">Hotel should be booked at least</span>
            <input #hotelBookingWindowInDays type="text" (input)="numbersOnly($event)"  name="hotelBookingWindowInDays"
              class="form-control d-inline px-4 mx-2 bookingWindowInDaysWidth" id="hotelBookingWindowInDays"
              formControlName="hotelBookingWindowInDays" [ngClass]="{
                'edit-field': policyId && !editHotelBookingWindowInDays,
                'pr-3': dataService.isSiteLtr(),
                'pl-3': dataService.isSiteRtl()
              }" (click)="editHotelBookingWindowInDays = true" (blur)="editPolicy('hotelBookingWindowInDays', null, null)"
              (keyup.enter)="
                enterActionFunc('hotelBookingWindowInDays', null, null)
              " (keyup.escape)="
                restorePolicy('hotelBookingWindowInDays', null);
                hotelBookingWindowInDays.blur()
              " /><span i18n="@@bookingWindowInDaysNote2">day(s) in advance</span>
          </div>
        </div>
        </div>


        <div class="mb-4">
   <div class="form-item form-type-radios form-name-rating">
    <div class="d-flex">
     <p class="font-16" i18n="@@maximumHotelStars">Maximum hotel stars</p>
     <p class="tooltip d-inline" [ngClass]="{
      'pl-1': dataService.isSiteLtr(),
      'pr-1': dataService.isSiteRtl()
    }">
    <i class="icon-warning-msg mlight-text font-14"></i>
    <span class="tooltip-text border rounded bg-white p-3 font-12" i18n="@@maximumHotelStarsInfo">Define the rule for hotel star ratings. If set to 5 stars, all hotels are permitted.</span>
  </p>
  </div>

     <div class="d-flex"
       [ngClass]="{'mx-0': dataService.mobileView,show: showSelectedSectionHotel === 'hotel',hide: showSelectedSectionHotel !== 'hotel'}"
       *ngIf="dataService.mobileView || policyId === undefined">
       <div class="mb-3">
         <div [ngClass]="{
            'realtime-loader max-stars-loader': hotelMaxStarsLoader,
            'pl-0': dataService.isSiteLtr(),
            'pl-0': dataService.isSiteRtl()
          }">

           <div class="d-flex rate">
             <div class="form-radios mx-0">
               <div class="form-item form-type-star d-inline-block" *ngFor="let n of ratingValues"
                 [ngClass]="{'active': starsNumber >= n}">
                 <input id="rating_{{n}}" name="maxStars" formControlName="maxStars" value="{{n}}" type="radio"
                   (click)="setStarsNumber(n); editPolicy('hotelMaxStars', null, null); editHotelMaxStars = true">
                 <label for="rating_{{n}}"></label>
               </div>
             </div>
           </div>

         </div>
       </div>
     </div>
   </div></div>

      </div>
      <div *ngIf="stepCounter === 3">
        <p class="gray-primaray-text mb-2 font-16" i18n="@@policyName">
          Policy Name
        </p>
        <div class="form-group with-icon position-relative" *ngIf="dataService.desktopView || policyId === undefined"
          [ngClass]="{ 'realtime-loader': nameLoader }">
          <input #policyInput class="form-control" formControlName="policyName" autocomplete="off" type="text"
            id="nameInputPolicy" [ngClass]="{
              'is-invalid': submitted && f.policyName.errors,
              'edit-field': policyId && !editPolicyName
            }" (click)="editPolicyName = true" (blur)="editPolicy('name', null, null)" (focus)="policyInput.select()"
            (keyup.enter)="enterActionFunc('name', null, null)"
            (keyup.escape)="restorePolicy('name', null); policyInput.blur()" />
        </div>
        <div class="form-control edit-field" *ngIf="dataService.mobileView && policyId" (click)="showPolicyNamePopUp()">
          <p *ngIf="this.policyInfo && this.policyInfo.name" class="pt-3">
            {{ this.policyInfo.name }}
          </p>
        </div>
        <p class="gray-primaray-text mb-1 font-16 mt-5" *ngIf="dataService.desktopView" i18n="@@approval">
          Approval
        </p>
        <p class="gray-text font-12" *ngIf="dataService.desktopView"
          [ngClass]="{ 'text-danger': submitted && !selectedId }" i18n="@@requireApproval">
          Choose when travelers require approval
        </p>
        <p class="gray-primaray-text font-16 mt-5" *ngIf="dataService.mobileView"
          [ngClass]="{ 'text-danger': submitted && !selectedId }" i18n="@@requireApproval">
          Choose when travelers require approval
        </p>
        <ng-container *ngIf="dataService.desktopView">
          <div class="form-group mb-0" *ngFor="let option of options; let i = index">
            <input type="radio" id="{{ i }}" name="plan" [checked]="selectedId === option.id"
              (click)="selectedId = option.id; requireApprovalType(option.id)" />
            <label class="mb-0" for="{{ i }}" [ngClass]="{
                'ml-2': dataService.isSiteLtr(),
                'mr-2': dataService.isSiteRtl()
              }">
              <span class="d-block mb-2">{{ option.name }}
                <span *ngIf="option.note">({{ option.note }})</span></span>
            </label>
          </div>
        </ng-container>
        <ng-container *ngIf="dataService.mobileView">
          <div class="font-16 options">
            <div class="checkbox-radio-item-container col form-group px-0"
              *ngFor="let option of options; let i = index">
              <input class="d-none" type="radio" id="{{ i }}" name="plan"
                (click)="selectedId = option.id; requireApprovalType(option.id)" />
              <label class="white-box border w-100 d-flex mb-0" [ngClass]="{
                  'unselected green-text': option.id === selectedId,
                  'realtime-loader in-checkbox':
                    checkboxLoader && selectedId === option.id
                }" for="{{ i }}">
                <div class="text-center">
                  <span class="font-16 mb-2" [ngClass]="{
                      'pr-2': dataService.isSiteLtr(),
                      'pl-2': dataService.isSiteRtl()
                    }">{{ option.name }}</span>
                  <span class="font-14 gray-text" *ngIf="option.note">({{ option.note }})</span>
                </div>
              </label>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
  <ng-container *ngIf="!policyId">
    <div *ngIf="stepCounter === 1">
      <p class="mt-5">
        <a class="blue-text font-16" *ngIf="dataService.desktopView" (click)="stepCounter = 2"
          i18n="@@skipFlightRules">Skip flight rules</a>
      </p>
      <div class="d-flex justify-content-end mt-5 pt-5">
        <button type="button" class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5" [ngClass]="{
            'btn-block': dataService.mobileView,
            'ml-lg-3': dataService.isSiteLtr(),
            'mr-lg-3': dataService.isSiteRtl()
          }" (click)="checkCont1()" i18n="@@contBtn">
          Continue
        </button>
      </div>
    </div>
    <div *ngIf="stepCounter === 2">
      <p class="mt-5">
        <a class="blue-text font-16" *ngIf="dataService.desktopView" (click)="stepCounter = 3"
          i18n="@@skipHotelRules">Skip hotel rules</a>
      </p>
      <div class="justify-content-end mt-5 pt-5" [ngClass]="{ 'd-flex': dataService.desktopView }">
        <button type="button" class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
          [ngClass]="{ 'btn-block mb-3': dataService.mobileView }" (click)="stepCounter = 1" i18n="@@backBtn">
          Back
        </button>
        <button type="button" class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5" [ngClass]="{
            'btn-block': dataService.mobileView,
            'ml-lg-3': dataService.isSiteLtr(),
            'mr-lg-3': dataService.isSiteRtl()
          }" (click)="checkCont2()" i18n="@@contBtn">
          Continue
        </button>
      </div>
    </div>
    <div *ngIf="stepCounter === 3">
      <div class="justify-content-end mt-5 pt-5" [ngClass]="{ 'd-flex': dataService.desktopView }">
        <button type="button" class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
          [ngClass]="{ 'btn-block mb-3': dataService.mobileView }" (click)="stepCounter = 2" i18n="@@backBtn">
          Back
        </button>
        <button type="button" class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5" (click)="createPolicy()"
          [ngClass]="{
            'm-progress': submitting,
            'btn-block': dataService.mobileView,
            'ml-lg-3': dataService.isSiteLtr(),
            'mr-lg-3': dataService.isSiteRtl()
          }" i18n="@@createPolicy">
          Create Policy
        </button>
      </div>
    </div>
  </ng-container>
  <div *ngIf="errorMessage" class="text-danger mt-2 text-right">
    {{ errorMessage }}
  </div>
</div>
<div class="create-team">
  <div (click)="mobileFocus()" [ngClass]="{ 'in action-popup': showSearchPopUpautocompleteFlag }">
    <div class="header">
      <div *ngIf="showSearchPopUpautocompleteFlag">
        <div class="close" *ngIf="showSearchPopUpautocompleteFlag" (click)="hidePopUp()">
          Cancel
        </div>
      </div>
      <div class="position-relative mobile-search-field" *ngIf="showSearchPopUpautocompleteFlag">
        <div class="form-group mb-3">
          <input (keydown)="onChangeSearch()" (ngModelChange)="this.SearchTextQuery.next($event)" matInput
            autocomplete="off" [(ngModel)]="this.caption" [ngModelOptions]="{ standalone: true }" name="list"
            (focus)="autocompleteOnFocus($event)" type="text" i18n-placeholder="@@enterCityPolicy"
            placeholder="Enter city..." id="city-mobile-input" class="py-3 mb-3 form-control mobile" />
        </div>
      </div>
    </div>
    <div class="body" *ngIf="showSearchPopUpautocompleteFlag === true">
      <div class="autocomplete_wrapper">
        <ng-container *ngFor="let autocompletetype of autocompletelist; let y = index">
          <div *ngIf="y === 0">
            <ng-container *ngFor="let entity of autocompletetype.entities">
              <mat-option data-id="{{ entity.code }}" tabindex="-1" [value]="entity.caption" (click)="
                  setSearchText(entity, autocompletetype.name);
                  showSearchPopUpautocompleteFlag = false
                " *ngIf="notSelected(entity.caption)">
                <span class="d-block">
                  {{ entity.caption }}
                </span>
              </mat-option>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="policy-sheet main-bottom-sheet bg-white animate-C px-3 py-4" [ngClass]="{
    active:
      showPolicyNamePopUpFlag ||
      showPolicyLimitPopUpFlag ||
      showCityPolicyLimitPopUpFlag[selectedCityIndex] ||
      showFlightPriceCapFlag ||
      showMedianPercentageFlag ||
      showInternationalPriceCapFlag ||
      showDomesticPriceCapFlag ||
      showMaxNoOfTripsPopUpFlag ||
      showBookingWindowInDaysPopUpFlag ||
      showHotelMedianPercentageFlag ||
      showHotelBookingWindowInDaysPopUpFlag
  }">
  <div *ngIf="showCityPolicyLimitPopUpFlag[selectedCityIndex] === true">
    <p class="gray-primaray-text font-16">
      {{ this.citiesList[selectedCityIndex].caption }}
      <ng-container i18n="@@nightlyPriceLimit">nightly price limit</ng-container>
    </p>
    <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
      <div class="form-group position-relative mb-5">
        <input type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="place-{{ selectedCityIndex }}"
          class="form-control city-item-amount" formControlName="cityLimit{{ selectedCityIndex }}"
          id="place-{{ selectedCityIndex }}" />
        <div class="text-danger smaller mb-2">
          {{ cityLimitMobileErrorMsg }}
        </div>
      </div>
      <div class="form-group position-relative mb-5">
        <select class="form-control select-custom-arrow" formControlName="cityLimitCurrency{{ selectedCityIndex }}"
          name="cityLimitCurrency" [ngClass]="{
            'is-invalid':
              submitted && f.cityLimitCurrency && f.cityLimitCurrency.errors
          }">
          <ng-container *ngFor="let item of currenciesList">
            <option [ngValue]="item.code">
              {{ item.code }}
            </option>
          </ng-container>
        </select>
        <div class="text-danger smaller mb-2">
          {{ cityLimitCurrencyMobileErrorMsg }}
        </div>
      </div>
      <button class="btn-outline-blue btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14 mt-5"
        (click)="editPolicy('cityLimit', selectedCityIndex, null)" i18n="@@edit">
        Update
      </button>
      <button class="btn-outline-gray btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14" (click)="hidePopUp()"
        i18n="@@cancelBtn">
        Cancel
      </button>
    </form>
  </div>
  <div *ngIf="showPolicyNamePopUpFlag === true">
    <p class="gray-primaray-text font-16" i18n="@@policyName">Policy Name</p>
    <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
      <div class="form-group position-relative">
        <input id="nameInput" class="form-control mb-5" formControlName="policyName" autocomplete="off" type="text"
          [ngClass]="{ 'is-invalid': submitted && f.policyName.errors }" />
      </div>
      <button class="btn-outline-blue btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14 mt-5"
        (click)="editPolicy('name', null, null)" i18n="@@edit">
        Update
      </button>
      <button class="btn-outline-gray btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14" (click)="hidePopUp()"
        i18n="@@cancelBtn">
        Cancel
      </button>
    </form>
  </div>
  <div *ngIf="showPolicyLimitPopUpFlag === true">
    <p class="gray-primaray-text font-16" i18n="@@avgNightlyPrice">
      Hotel average nightly price limit
    </p>
    <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
      <div class="form-group position-relative amount-field mb-5">
        <input id="limitInput" type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="policyLimit"
          formControlName="policyLimit" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.policyLimit.errors }" />
      </div>
      <div class="form-group position-relative amount-field mb-5">
        <select class="form-control select-custom-arrow" formControlName="policyLimitCurrency"
          name="policyLimitCurrency" [ngClass]="{
            'is-invalid':
              submitted && f.policyLimitCurrency && f.policyLimitCurrency.errors
          }">
          <ng-container *ngFor="let item of currenciesList">
            <option [ngValue]="item.code">
              {{ item.code }}
            </option>
          </ng-container>
        </select>
      </div>
      <button class="btn-outline-blue btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14 mt-5"
        (click)="editPolicy('hotelLimit', null, null)" i18n="@@edit">
        Update
      </button>
      <button class="btn-outline-gray btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14" (click)="hidePopUp()"
        i18n="@@cancelBtn">
        Cancel
      </button>
    </form>
  </div>
  <div *ngIf="showMaxNoOfTripsPopUpFlag === true">
    <p class="gray-primaray-text font-16" i18n="@@maxNoOfTrips">
      Max number of trips
    </p>
    <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
      <div class="form-group position-relative mb-5">
        <input id="maximumNumberOfTrips" type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="maximumNumberOfTrips"
          formControlName="maximumNumberOfTrips" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.policyLimit.errors }" />
      </div>
      <button class="btn-outline-blue btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14 mt-5"
        (click)="editPolicy('maximumNumberOfTrips', null, null)" i18n="@@edit">
        Update
      </button>
      <button class="btn-outline-gray btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14" (click)="hidePopUp()"
        i18n="@@cancelBtn">
        Cancel
      </button>
    </form>
  </div>

  <div *ngIf="showBookingWindowInDaysPopUpFlag === true">
    <p class="gray-primaray-text font-16" i18n="@@bookingWindow">
      Booking Window
    </p>
    <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
      <div class="form-group position-relative mb-5">
        <input id="bookingWindowInDays" type="text" (input)="numbersOnly($event)" name="bookingWindowInDays" formControlName="bookingWindowInDays"
          class="form-control" [ngClass]="{ 'is-invalid': submitted && f.bookingWindowInDays.errors }" />
      </div>
      <button class="btn-outline-blue btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14 mt-5"
        (click)="editPolicy('bookingWindowInDays', null, null)" i18n="@@edit">
        Update
      </button>
      <button class="btn-outline-gray btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14" (click)="hidePopUp()"
        i18n="@@cancelBtn">
        Cancel
      </button>
    </form>
  </div>

  <div *ngIf="showHotelBookingWindowInDaysPopUpFlag === true">
    <p class="gray-primaray-text font-16" i18n="@@bookingWindow">
      Booking Window
    </p>
    <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
      <div class="form-group position-relative mb-5">
        <input id="hotelBookingWindowInDays" type="text" (input)="numbersOnly($event)" name="hotelBookingWindowInDays" formControlName="hotelBookingWindowInDays"
          class="form-control" [ngClass]="{ 'is-invalid': submitted && f.hotelBookingWindowInDays.errors }" />
      </div>
      <button class="btn-outline-blue btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14 mt-5"
        (click)="editPolicy('hotelBookingWindowInDays', null, null)" i18n="@@edit">
        Update
      </button>
      <button class="btn-outline-gray btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14" (click)="hidePopUp()"
        i18n="@@cancelBtn">
        Cancel
      </button>
    </form>
  </div>

  <div *ngIf="showFlightPriceCapFlag === true">
    <p class="gray-primaray-text font-16" i18n="@@flightPriceCap">
      Flight price cap
    </p>
    <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
      <div class="form-group position-relative amount-field mb-5">
        <input type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" formControlName="flightPolicyLimit" class="form-control"
          id="flightPolicyLimit" [ngClass]="{
            'is-invalid':
              submitted && f.flightPolicyLimit && f.flightPolicyLimit.errors
          }" />
      </div>
      <div class="form-group position-relative amount-field mb-5">
        <select class="form-control select-custom-arrow" formControlName="flightPolicyLimitCurrency"
          name="flightPolicyLimitCurrency" [ngClass]="{
            'is-invalid':
              submitted &&
              f.flightPolicyLimitCurrency &&
              f.flightPolicyLimitCurrency.errors
          }">
          <ng-container *ngFor="let item of currenciesList">
            <option [ngValue]="item.code">
              {{ item.code }}
            </option>
          </ng-container>
        </select>
      </div>
      <button class="btn-outline-blue btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14 mt-5"
        (click)="editPolicy('flightLimit', null, null)" i18n="@@edit">
        Update
      </button>
      <button class="btn-outline-gray btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14" (click)="hidePopUp()"
        i18n="@@cancelBtn">
        Cancel
      </button>
    </form>
  </div>

  <div *ngIf="showMedianPercentageFlag === true">
    <p class="gray-primaray-text font-16" i18n="@@dynamicPriceLimit">
      Dynamic price limit
    </p>
    <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
      <div class="form-group position-relative amount-field mb-5">
        <select class="form-control select-custom-arrow" formControlName="medianPriceLimitPercentage"
          name="medianPriceLimitPercentage" [ngClass]="{
            'is-invalid':
              submitted &&
              f.medianPriceLimitPercentage &&
              f.medianPriceLimitPercentage.errors
          }">
          <ng-container *ngFor="let item of medianPriceLimitPercentageList">
            <option [ngValue]="item.Value">
              {{ item.Option }}
            </option>
          </ng-container>
        </select>
      </div>

      <div class="form-group position-relative amount-field mb-5">
        <select class="form-control select-custom-arrow" formControlName="medianPriceLimitPercentageCategory"
          name="medianPriceLimitPercentageCategory" [ngClass]="{
            'is-invalid':
              submitted &&
              f.medianPriceLimitPercentageCategory &&
              f.medianPriceLimitPercentageCategory.errors
          }">
          <ng-container *ngFor="let item of medianPriceLimitPercentageOptionsList">
            <option [ngValue]="item.Value">
              {{ item.Name }}
            </option>
          </ng-container>
        </select>
      </div>
      <button class="btn-outline-blue btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14 mt-5"
        (click)="editPolicy('medianPriceLimitPercentageCategory', null, null)" i18n="@@edit">
        Update
      </button>
      <button class="btn-outline-gray btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14" (click)="hidePopUp()"
        i18n="@@cancelBtn">
        Cancel
      </button>
    </form>
  </div>

  <div *ngIf="showHotelMedianPercentageFlag === true">
    <p class="gray-primaray-text font-16" i18n="@@dynamicPriceLimit">
      Dynamic price limit
    </p>
    <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
      <div class="form-group position-relative amount-field mb-5">
        <select class="form-control select-custom-arrow" formControlName="hotelMedianPriceLimitPercentage"
          name="hotelMedianPriceLimitPercentage" [ngClass]="{
            'is-invalid':
              submitted &&
              f.hotelMedianPriceLimitPercentage &&
              f.hotelMedianPriceLimitPercentage.errors
          }">
          <ng-container *ngFor="let item of hotelMedianPriceLimitPercentageList">
            <option [ngValue]="item.Value">
              {{ item.Option }}
            </option>
          </ng-container>
        </select>
      </div>

      <div class="form-group position-relative amount-field mb-5">
        <select class="form-control select-custom-arrow" formControlName="hotelMedianPriceLimitPercentageCategory"
          name="hotelMedianPriceLimitPercentageCategory" [ngClass]="{
            'is-invalid':
              submitted &&
              f.hotelMedianPriceLimitPercentageCategory &&
              f.hotelMedianPriceLimitPercentageCategory.errors
          }">
          <ng-container *ngFor="let item of hotelMedianPriceLimitPercentageOptionsList">
            <option [ngValue]="item.Value">
              {{ item.Name }}
            </option>
          </ng-container>
        </select>
      </div>
      <button class="btn-outline-blue btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14 mt-5"
        (click)="editPolicy('hotelMedianPriceLimitPercentageCategory', null, null)" i18n="@@edit">
        Update
      </button>
      <button class="btn-outline-gray btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14" (click)="hidePopUp()"
        i18n="@@cancelBtn">
        Cancel
      </button>
    </form>
  </div>

  <div *ngIf="showInternationalPriceCapFlag === true">
    <p class="gray-primaray-text font-16" i18n="@@interPriceCap">
      International price cap
    </p>
    <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
      <div class="form-group position-relative amount-field mb-5">
        <input type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="internationalPriceCap" class="form-control"
          id="internationalPriceCap" formControlName="internationalPriceCap" [ngClass]="{
            'is-invalid':
              submitted &&
              f.internationalPriceCap &&
              f.internationalPriceCap.errors
          }" />
      </div>
      <div class="form-group position-relative amount-field mb-5">
        <select class="form-control select-custom-arrow" formControlName="internationalPriceCapCurrency"
          name="internationalPriceCapCurrency" [ngClass]="{
            'is-invalid':
              submitted &&
              f.internationalPriceCapCurrency &&
              f.internationalPriceCapCurrency.errors
          }">
          <ng-container *ngFor="let item of currenciesList">
            <option [ngValue]="item.code">
              {{ item.code }}
            </option>
          </ng-container>
        </select>
      </div>
      <button class="btn-outline-blue btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14 mt-5"
        (click)="editPolicy('internationalLimit', null, null)" i18n="@@edit">
        Update
      </button>
      <button class="btn-outline-gray btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14" (click)="hidePopUp()"
        i18n="@@cancelBtn">
        Cancel
      </button>
    </form>
  </div>
  <div *ngIf="showDomesticPriceCapFlag === true">
    <p class="gray-primaray-text font-16" i18n="@@domPriceCap">
      Domestic price cap
    </p>
    <form name="createPolicyForm" [formGroup]="createPolicyForm" class="px-lg-3">
      <div class="form-group position-relative amount-field mb-5">
        <input type="number" pattern="[0-9]+(\.[0-9][0-9]?)?" name="domesticPriceCap" class="form-control"
          id="domesticPriceCap" formControlName="domesticPriceCap" [ngClass]="{
            'is-invalid':
              submitted && f.domesticPriceCap && f.domesticPriceCap.errors
          }" />
      </div>
      <div class="form-group position-relative amount-field mb-5">
        <select class="form-control select-custom-arrow" formControlName="domesticPriceCapCurrency"
          name="domesticPriceCapCurrency" id="domesticPriceCapCurrency" [ngClass]="{
            'is-invalid':
              submitted &&
              f.domesticPriceCapCurrency &&
              f.domesticPriceCapCurrency.errors
          }">
          <ng-container *ngFor="let item of currenciesList">
            <option [ngValue]="item.code">
              {{ item.code }}
            </option>
          </ng-container>
        </select>
      </div>
      <button class="btn-outline-blue btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14 mt-5"
        (click)="editPolicy('domesticLimit', null, null)" i18n="@@edit">
        Update
      </button>
      <button class="btn-outline-gray btn py-1 px-5 mb-3 w-100 sharp-font bold-500 font-14" (click)="hidePopUp()"
        i18n="@@cancelBtn">
        Cancel
      </button>
    </form>
  </div>
</div>
<div *ngIf="
    showPolicyNamePopUpFlag ||
    showPolicyLimitPopUpFlag ||
    showCityPolicyLimitPopUpFlag[selectedCityIndex] ||
    showFlightPriceCapFlag ||
    showInternationalPriceCapFlag ||
    showDomesticPriceCapFlag
  " (click)="hidePopUp()" class="modal-backdrop show"></div>