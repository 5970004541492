import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactUsForm: FormGroup;
  submitted = false;
  data;
  errorMsg = '';
subject;
submitting = false;
  constructor(private http: HttpClient,
              private formBuilder: FormBuilder, public restApi: DataService, private titleService:Title) {
    this.titleService.setTitle('Safarhub Help and Customer Support | SafarHub');

  }
  ngOnInit() {
    this.restApi.alerts = [];
    this.contactUsForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    }, {
    });

  }


  get f() { return this.contactUsForm.controls; }

  onSubmit() {
    this.restApi.alerts = [];

    this.submitted = true;
    this.submitting = true;
    // stop here if form is invalid
    if (this.contactUsForm.invalid) {
      this.submitting = false;
      return;
    }

   // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.contactUsForm.value));
    if (this.restApi.isCompany()) {
      this.subject = '(SafarHub) I have an issue ' + this.contactUsForm.value.subject;
    } else {
      this.subject = this.contactUsForm.value.subject;
    }
    this.restApi.contactUs(this.contactUsForm.value.email, this.contactUsForm.value.message, this.contactUsForm.value.name,
      this.subject).subscribe(

      data => {

        this.data = data;
        if (this.data.errorCode === 0) {
          this.submitting = false;
          this.errorMsg = '';
          // this.restApi.messages(environment.messages.THANKS_CONTACT_US, 'success');
          this.contactUsForm.controls.email.setValue('');
          this.contactUsForm.controls.name.setValue('');
          this.contactUsForm.controls.subject.setValue('');
          this.contactUsForm.controls.message.setValue('');
          this.submitted = false;
          this.restApi.SnakBar(environment.messages.THANKS_CONTACT_US);

        } else {
          this.submitting = false;
          this.restApi.messages(environment.messages.CONTACT_US_ERROR, 'error');
          this.restApi.scrollTop();

        }

      },
      error => {
        this.restApi.messages(environment.messages.CONTACT_US_ERROR, 'error');
        this.submitting = false;
        this.restApi.scrollTop();
      }
    );
  }


}
