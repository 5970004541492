<div class="custom-container container pt-5 pb-4">
  <div class="row justify-content-center font-16" *ngIf="plans">
    <div class="checkbox-radio-item-container col-6 col-md form-group px-4 px-lg-2" *ngFor="let plan of plans, let i = index">
      <input class="d-none" type="radio" id="{{i}}" name="plan" (click)="switchPlan(plan.name, plan.planId)" />
      <label class="white-box border w-100 d-flex justify-content-center align-items-center mb-0"
             [ngClass]="{'unselected green-text': plan.planId ===  currentPlan.currentPlanIdValue}" for="{{i}}">
        <div class="text-center">
        <span class="d-block font-weight-bold font-16 mb-2">{{plan.name}}</span>
        <span class="d-block font-12 text-nowrap" *ngIf="plan.tier.flatAmount && plan.tier.upTo">${{plan.tier.flatAmount}} (up to {{plan.tier.upTo}} members) </span>
          <span class="d-block font-12 text-nowrap" *ngIf="plan.tier.unitAmount">${{plan.tier.unitAmount}}/member</span>
        </div>
      </label>
    </div>
  </div>
</div>





