import { Component, OnInit } from '@angular/core';
import {DataService} from '../data.service';
import {Router} from '@angular/router';
import {AuthServiceMain} from "../../auth/auth.service";
@Component({
  selector: 'app-modal-notification',
  templateUrl: './modal-notification.component.html',
  styleUrls: ['./modal-notification.component.scss']
})
export class ModalNotificationComponent implements OnInit {

  constructor(public Service: DataService, public router: Router, public auth: AuthServiceMain) { }

  ngOnInit() {
  }
  refreshPage() {
    if(this.Service.NotifymodalName === 'bookingFormExpiry') {
      const formValues = this.Service.flightBookingForm?.value;

      if (formValues !== null && formValues !== undefined) {
        const allValues = formValues;
        const phoneNumberCountryCode = {
          dial_code: this.Service.defultCountry.dial_code,
          code: this.Service.defultCountry.code,
      };
        const phoneNumberCountryCodeString = 'phoneNumberCountryCode';
        allValues[phoneNumberCountryCodeString] = phoneNumberCountryCode;
        sessionStorage.setItem('flightBookingFormFields', JSON.stringify(allValues));
      }
    }
 //   if(this.Service.NotifymodalName === 'flightListExpiry'){
      this.Service.NotifymodalOf();
      location.reload();
  /*  } else{
      this.Service.validateFlight(true);
    }*/

  }
}
