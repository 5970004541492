<form name="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()" class="pt-3 px-lg-3">
  <div class="form-group with-icon position-relative form-type-textfield form-name-email">
    <input type="email" name="email"  class="form-control placeholder-text-field" formControlName="email" placeholder="Work Email" autocomplete="off"
           i18n-placeholder="@@workEmailPlaceHolder" [ngClass]="{ 'is-invalid': submitted && c.email.errors }" />
    <i  class="icon-email"></i>
  </div>
  <div *ngIf="submitted && c.email.errors" class="text-danger smaller mb-3" [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">
    <div *ngIf="c.email.errors.required" i18n="@@requiredEmailError">Email is required</div>
    <div *ngIf="c.email.errors.email" i18n="@@invalidEmailError">Email must be a valid email address</div>
  </div>
  <div class="row" >
    <div class="col-12">
      <button  [ngClass]="{'m-progress': submitting }" class="btn green_btn btn-block font-weight-normal font-14 px-5 mt-auto workspace-btn-padding">
        <span class="mx-auto" i18n="@@contBtn">Continue</span>
        <!--  <i class="icon-right-arrow-slider"></i>-->
      </button>
    </div>
    <div class="col-sm-7" [ngClass]="{'text-left pr-lg-0': dataService.isSiteLtr(), 'text-right pl-lg-0': dataService.isSiteRtl()}">
     <!-- <p class="mt-5 mt-lg-4"><a class="blue-text" (click)="this.router.navigate(['/join']);" i18n="@@notMember"> Not a member? Sign up</a></p>-->
    </div>
  </div>
  <p class="text-danger" [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">{{ this.errorMsg}}</p>
</form>
